import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import crypto from 'crypto'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import RefreshIcon from '@material-ui/icons/Refresh'
import MessageBox from 'components/Info/MessageBox'
// apollo
import { Query, Mutation, compose } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Table from 'components/Table/AdvancedTable.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import ScatterChartSimple from 'components/Stats/Charts/ScatterChartSimple.js'
import SimpleBar from 'components/Stats/Charts/SimpleBar'
import ConfidenceGaugeChart from 'components/Stats/Charts/ConfidenceGaugeChart'
import TableHeatMap from 'components/Stats/Charts/TableHeatMap.js'
import DateFormat from 'components/Info/DateFormat'
import LinkButton from 'components/Button/LinkButton'
import FeatureUpgradeNavLink from 'components/FeatureUpgrade/FeatureUpgradeNavLink'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { getTablePaginationVariables } from 'actions/table'
import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import TestSessionProgress from '../TestSessions/TestSessionProgress.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import Text from 'components/Typography/Text.jsx'
import DangerOrSuccessText from 'components/Typography/DangerOrSuccessText'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TESTSET_STATS_QUERY,
  TESTSETCOACHSESSION_QUERY,
  TESTSETCOACHSESSION_SUBSCRIPTION,
  TESTSETFIRSTCOACHSESSION_QUERY,
  TESTSETCOACHSESSIONS_QUERY,
  TESTSETCOACHSESSIONSECTION_QUERY,
  TESTSETCOACHSESSIONSECTION_SUBSCRIPTION,
  CREATE_TESTSETCOACHSESSION,
  DELETE_TESTSETCOACHSESSION
} from './gql'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'
import DropdownCheckbox from 'components/Button/DropdownCheckbox'
import ShowIcon from 'components/Icon/ShowIcon'

const strToColor = (str) => {
  return '#' + crypto.createHash('md5').update(str).digest('hex').substr(0, 6)
}

const pointsToDisplayMax = 2500

export function TestSetInsightsCoachSessionRenderer ({ id, childFunction }) {
  return (<TestSessionProgress
    query={TESTSETCOACHSESSION_QUERY}
    querySelector={data => data.testsetcoachsession}
    subscription={TESTSETCOACHSESSION_SUBSCRIPTION}
    subscriptionSelector={data => data.coachSessionProgress}
    testSession={{ id: id }}>
    {({ testSessionProgress: coachSessionProgress, testSessionProgressLoading: coachSessionProgressLoading }) => {
      if (!coachSessionProgressLoading && coachSessionProgress) {
        return childFunction(coachSessionProgress)
      }
      return null
    }}
  </TestSessionProgress>)
}

class TestSetInsights extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      coachSessionId: null,
      similaritySectionExpanded: 'embeddings',
      loocvSectionExpanded: 'notmatching',
      humanificationSectionExpanded: 'notmatching',
      similarityVisulisation: {
        intentA: null,
        intentB: []
      }
    }
  }

  renderSection(coachSession, section, showWaiting, childFunction) {
    if (_.isNil(showWaiting)) {
      showWaiting = true
    }

    if (section === 'chi2' && coachSession.embeddingsReady && coachSession.embeddingsErr) {
      return <Text danger>{coachSession[`${section}Err`]}</Text>
    }

    return <TestSessionProgress
      key={`${coachSession.id}_${section}`}
      query={TESTSETCOACHSESSIONSECTION_QUERY}
      querySelector={data => (data.testsetcoachsessionsection || '<empty>')}
      subscription={TESTSETCOACHSESSIONSECTION_SUBSCRIPTION}
      subscriptionSelector={data => (data.coachSessionSectionProgress || '<empty>')}
      readySelector={ts => ts && ts !== '<empty>'}
      testSession={{ id: coachSession.id }}
      variables={{ section }}>
      {({ testSessionProgress: coachSessionSectionProgress, testSessionProgressLoading: coachSessionSectionLoading, testSessionProgressErr: coachSessionSectionErr }) => {
        if (coachSessionSectionLoading) return <Text>{showWaiting && <><LoadingIndicator /> Waiting for Training Data Insights. May take some time depending on test set size.</>}</Text>

        if (coachSessionSectionErr) return <ErrorFormat err={coachSessionSectionErr} />

        if (section === 'embeddings' || section === 'chi2') {
          const content = coachSessionSectionProgress !== '<empty>' && JSON.parse(coachSessionSectionProgress)
          if (content && content.status && ((content.method === 'calculate_embeddings' && section === 'embeddings') || (content.method === 'calculate_chi2' && section === 'chi2'))) {
            if (content.status.includes('FAILED')) {
              return <ErrorFormat err={content.statusDescription} />
            }
            return <Text>
              <LoadingIndicator small /> {content.step && <Text bold inline>Step {content.step} / {content.steps}: </Text>}{content.statusDescription}
            </Text>
          }
        }

        if (coachSessionSectionProgress && !coachSessionSectionProgress.id && coachSessionSectionProgress !== '<empty>') {
          try {
            const content = JSON.parse(coachSessionSectionProgress)
            return childFunction(content)
          } catch (err) {
            return <ErrorFormat err={`Failed to retrieve data for section (${err.message})`} />
          }
        }

        return <Text>{showWaiting && <><LoadingIndicator /> Waiting for Training Data Insights Calculations to finish. May take some time depending on test set size.</>}</Text>
      }}
    </TestSessionProgress>
  }

  render() {
    const { getTablePaginationVariables, setAlertSuccessMessage, setAlertErrorMessage, testSetId, user, license, classes } = this.props
    const { coachSessionId } = this.state

    const insightsTableName = `TestSetInsights_CoachSessionsProgress_${testSetId}`

    const query = coachSessionId ? TESTSETCOACHSESSION_QUERY : TESTSETFIRSTCOACHSESSION_QUERY
    const variables = coachSessionId ? { id: coachSessionId } : { testSetId }



    return (
      <Query query={query}
        variables={variables}
        fetchPolicy={'network-only'}
        notifyOnNetworkStatusChange={true}>
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingIndicator large />
          }
          if (error) {
            return <ErrorFormat err={error} />
          }
          const session = data.testsetcoachsessions && data.testsetcoachsessions.length > 0 ? data.testsetcoachsessions[0] : data.testsetcoachsession

          return (<GridContainer>
            {session && <React.Fragment>
              <GridItem xs={12} sm={4}>
                <Card fullheight>
                  <CardHeader color="info">
                    <Text header>Test Set Score</Text>
                    <Text subheader>
                      Overall F1-Score for K-Fold Cross Validation analysis
                    </Text>
                  </CardHeader>
                  <CardBody>
                    <GridContainer >
                      <GridItem xs={12}>
                        <TestSetInsightsCoachSessionRenderer
                          id={session.id}
                          childFunction={data => <>
                            {_.isNumber(data.kfoldF1) && <ConfidenceGaugeChart id={`kfold_gauge_${testSetId}`} value={data.kfoldF1} />}
                            {!_.isNumber(data.kfoldF1) && <Text danger>K-Fold Cross Validation not possible (too less data)</Text>}
                          </>}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Text>
                          K-Fold Cross Validation is an established measure for quality of data sets by dividing the data into K chunks and cross-validate each chunk with data from all the other chunks
                        </Text>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Card fullheight>
                  <CardHeader color="info">
                    <Text header>Utterance Score</Text>
                    <Text subheader>
                      Average Score for Leave-One-Out Cross Validation analysis
                    </Text>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12}>
                        <TestSetInsightsCoachSessionRenderer
                          id={session.id}
                          childFunction={data => <>
                          {_.isNumber(data.loocvScore) && <ConfidenceGaugeChart id={`loocv_gauge_${testSetId}`} value={data.loocvScore} />}
                          {!_.isNumber(data.loocvScore) && <Text danger>Leave-One-Out Cross Validation not possible (too less data)</Text>}
                          </>}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Text>
                          With Leave-One-Out Cross Validation single training examples are cross-validated with the other training examples.
                        </Text>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Card fullheight>
                  <CardHeader color="info">
                    <Text header>Humanification Score</Text>
                    <Text subheader>
                      Overall Score for Humanification Perturbation algorithm
                    </Text>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12}>
                        {license.humanification && <TestSetInsightsCoachSessionRenderer
                          id={session.id}
                          childFunction={data => <>
                            {_.isNumber(data.humanificationF1) && <ConfidenceGaugeChart id={`humanification_gauge_${testSetId}`} value={data.humanificationF1} />}
                            {!_.isNumber(data.humanificationF1) && <Text danger>Humanification Perturbation not possible</Text>}
                          </>}
                        />}
                        {!license.humanification && <Text warning><FeatureUpgradeNavLink>Humanification not available in this Edition</FeatureUpgradeNavLink></Text>}
                      </GridItem>
                      <GridItem xs={12}>
                        <Text>
                          Showing robustness of the training data against human behaviour and typing habits, including typographic errors
                        </Text>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </React.Fragment>}
            {session &&
              <GridItem xs={12} align="center" marginTop floatNo>
                <Text>Insights from: <DateFormat>{session.createdAt}</DateFormat></Text>
              </GridItem>
            }
            {hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE']) && <GridItem xs={12} align="center">
              <Query query={TESTSET_STATS_QUERY} variables={{ id: testSetId }}>
                {({ loading, error, data }) => {
                  if (loading) {
                    return <LoadingIndicator large />
                  }
                  if (error) {
                    return <ErrorFormat err={error} />
                  }

                  if (data && data.testset && data.testset.statsUtterancesRefCount > 1 && data.testset.statsUtterancesCount > 2) {
                    return <Mutation
                      mutation={CREATE_TESTSETCOACHSESSION}
                      onCompleted={data => {
                        setAlertSuccessMessage('Insight analytics started ...')
                        this.setState({ coachSessionId: data.createTestSetCoachSession.id })
                      }}
                      onError={error => {
                        setAlertErrorMessage('Insight analytics failed', error)
                      }}
                      refetchQueries={[
                        {
                          query: TESTSETCOACHSESSIONS_QUERY,
                          variables: { testSetId }
                        },
                        {
                          query: TESTSETFIRSTCOACHSESSION_QUERY,
                          variables: { testSetId },
                        },
                        {
                          query: TESTSET_STATS_QUERY,
                          variables: { id: testSetId }
                        }
                      ]}
                    >
                      {(createTestSetCoachSession, { loading }) => (
                        <Button secondary
                          onClick={() => {
                            createTestSetCoachSession({
                              variables: {
                                testSetId
                              }
                            })
                          }}
                          data-unique="btnTestSetCreateCoachSession"
                        >
                          {loading && <LoadingIndicator alt />}
                          {!loading && <RefreshIcon />}
                          Update Insights with latest test set data
                        </Button>
                      )}
                    </Mutation>
                  } else {
                    return <GridContainer padding>
                      <GridItem xs={false} sm={3} />
                      <GridItem xs={12} sm={6} align="left">
                        <MessageBox title="Unable to show Test Set Insights" text="There are too few intents and utterances in Test Set" />
                      </GridItem>
                      <GridItem xs={false} sm={3} />
                    </GridContainer>
                  }
                }}
              </Query>
            </GridItem>}
            {false && <GridItem xs={12}>
              <Card>
                <CardHeader color="info">
                  <Text header>Hints</Text>
                </CardHeader>
                <CardBody>
                  <Text>
                    To improve the quality of the training phrases for your intents, consider the following approaches:
                    <ul>
                      <li>Find the phrases in different intents with high similarity, and change or remove them</li>
                      <li>Find the phrases with the most similar phrases that belong to different intents</li>
                      <li>Add more training phrases in intents with low cohesion, and investigate training phrases in intents with low separation</li>
                    </ul>
                  </Text>
                </CardBody>
              </Card>
            </GridItem>}
            {session && <GridItem xs={12} marginTop>
              <CustomTabs
                headerColor="info"
                name={`tabTestSetInsights_${testSetId}`}
                tabs={[
                  session.testSet.statsSkipCoachAdvanced ? null : {
                    tabName: 'Utterance Similarity Insights',
                    dataUnique: 'tabTestSetInsightsSimilarity',
                    tabContent: (<GridContainer>
                      <GridItem xs={12}>
                        <CustomTabsSecondary
                          name={`tabTestSetInsights_${testSetId}_Similarity`}
                          tabs={[
                            {
                              tabName: 'Similarity Visualization',
                              tabContent: <>
                                <GridContainer>
                                  <GridItem md={12}>
                                    {session && license.coach && this.renderSection(session, 'embeddings', true, (embeddings) => {
                                      let embeddingsIntentA = null
                                      let separationOverall = {}
                                      let embeddingsIntentB = null
                                      let separationPerSelection = {}
                                      const intentA = this.state.similarityVisulisation.intentA
                                      const intentB = this.state.similarityVisulisation.intentB
                                      let embeddingsFiltered = embeddings.embeddings.filter(series => !intentA || [intentA, ...intentB].includes(series.name))
                                      let pointsDisplayed = 0
                                      let pointsToDisplay = 0
                                      embeddingsFiltered = embeddingsFiltered.map(series => {
                                        let result
                                        if (pointsDisplayed + series.examples.length > pointsToDisplayMax) {
                                          result = Object.assign({}, series, { examples: series.examples.slice(0, pointsToDisplayMax - pointsDisplayed) })
                                        } else {
                                          result = series
                                        }
                                        pointsDisplayed += result.examples.length
                                        pointsToDisplay += series.examples.length
                                        return result
                                      }).filter(series => series.examples.length)
                                      if (embeddings?.separation) {
                                        embeddings.separation.forEach(s => {
                                          const put = (i, separation) => {
                                            separationOverall[i] = _.isNumber(separationOverall[i]) ? Math.min(separationOverall[i], separation) : separation
                                          }
                                          put(s.name1, s.separation)
                                          put(s.name2, s.separation)
                                        })
                                        // sort by separation
                                        // embeddingsIntentA = _.sortBy(embeddings.embeddings, series => separationOverall[series.name])
                                        // sort by name
                                        embeddingsIntentA = _.sortBy(embeddings.embeddings, series => series.name)
                                        if (intentA) {
                                          embeddings.separation.forEach(s => {
                                            if (s.name1 === intentA) {
                                              separationPerSelection[s.name2] = s.separation
                                            }
                                            if (s.name2 === intentA) {
                                              separationPerSelection[s.name1] = s.separation
                                            }
                                          })

                                          embeddingsIntentB = _.sortBy(embeddings.embeddings.filter(series => series.name !== intentA), series => separationPerSelection[series.name])
                                        }
                                      }
                                      return <GridContainer data-unique="grdTestInsightsEmbeddings">
                                        <GridItem xs={12} no>
                                          <Text header> Similarity Visualization Analysis
                                            <a
                                              tabIndex={0}
                                              className={classes.cardHeaderIcon}
                                              aria-label="Help"
                                              title="Help"
                                              data-unique="btnUniversalSentenceEncoderMultilingualLink"
                                              href="https://tfhub.dev/google/universal-sentence-encoder-multilingual/3"
                                              target="blank"
                                            >
                                              <Tooltip title={`
                                                  Using the <em>Universal Sentence Encoder Multilingual</em> the similarity of user examples is visualized on a
                                                  map. The closer the points, the closer the semantical similarity.`}>
                                                <ShowIcon icon="question-circle" />
                                              </Tooltip>
                                            </a>
                                          </Text>
                                        </GridItem>
                                        {embeddings?.embeddings && embeddingsIntentA &&
                                        <div className={classes.filtersPadding}>
                                          <GridItem xs={12} middle>
                                            <DropdownCheckbox link secondary noCaret Border customSelectType filterable
                                              data-unique="ddbtnScatterChartFilter"
                                              items={embeddingsIntentA.map((series, index) => {
                                                return {
                                                  id: series.name,
                                                  label: series.name,
                                                  subLabel: `(Closest Intent: ${separationOverall[series.name].toFixed(4)})`,
                                                  dataUnique: `ddbtnScatterChartFilter${series.name}`,
                                                  input: {
                                                    onChange: e => {
                                                      const { similarityVisulisation } = this.state

                                                      if (e.target.checked) {
                                                        similarityVisulisation.intentA = series.name
                                                      } else {
                                                        similarityVisulisation.intentA = null
                                                      }
                                                      similarityVisulisation.intentB = []
                                                      this.setState( similarityVisulisation )
                                                    },
                                                    checked: intentA === series.name
                                                  }
                                                }
                                              })}
                                            >
                                              <ShowIcon icon="filter" />
                                              Select Intent
                                              <span className={classes.valuebuble}>{intentA ? 1 : 0}</span>
                                              <ShowIcon icon="caret-down" />
                                            </DropdownCheckbox>
                                            <DropdownCheckbox link secondary noCaret Border customSelectType filterable
                                              data-unique="ddbtnScatterChartFilter"
                                              disabled={!intentA || !embeddingsIntentB}
                                              items={(embeddingsIntentB || []).map((series, index) => {
                                                return {
                                                  id: series.name,
                                                  label: `${series.name}`,
                                                  subLabel: `(Intent Separation: ${separationPerSelection[series.name].toFixed(4)})`,
                                                  dataUnique: `ddbtnScatterChartFilter${series.name}`,
                                                  input: {
                                                    onChange: e => {
                                                      const { similarityVisulisation } = this.state
                                                      if (e.target.checked) {
                                                        similarityVisulisation.intentB.push(series.name)
                                                      } else {
                                                        var index = similarityVisulisation.intentB.indexOf(series.name)
                                                        if (index !== -1) {
                                                          similarityVisulisation.intentB.splice(index, 1)
                                                        }
                                                      }
                                                      this.setState({ similarityVisulisation })
                                                    },
                                                    checked: intentB.includes(series.name)
                                                  }
                                                }
                                              })}
                                            >
                                              <ShowIcon icon="filter" />
                                              Select Intents to compare
                                              <span className={classes.valuebuble}>{_.filter(_.keys(intentB), k => intentB[k]).length}</span>
                                              <ShowIcon className={classes.caretdownIcon} icon="caret-down" />
                                            </DropdownCheckbox>
                                          </GridItem>
                                        </div>}
                                        <GridItem xs={12}>
                                          {embeddings.embeddings &&
                                            <>
                                              <div className={classes.scatterChart}>
                                                <ScatterChartSimple
                                                  data={embeddingsFiltered.map(e => ({
                                                    name: e.name,
                                                    color: strToColor(e.name),
                                                    shape: e.name === intentA ? 'cross' : 'circle',
                                                    values: e.examples.map(ex => ({
                                                      x: ex.x,
                                                      y: ex.y,
                                                      phrase: ex.phrase,
                                                      intent: e.name
                                                    }))
                                                  }))}
                                                  tooltipFormatter={payload => ([
                                                    {
                                                      name: 'Intent',
                                                      value: payload[0].payload.intent
                                                    },
                                                    {
                                                      name: 'Example',
                                                      value: payload[0].payload.phrase
                                                    }
                                                  ])}
                                                />
                                              
                                              {pointsDisplayed < pointsToDisplayMax && <Text leftMarginxs>
                                                <Text inline bold>{pointsDisplayed}</Text> utterances are displayed
                                              </Text>}
                                              {pointsDisplayed >= pointsToDisplayMax && <Text>
                                                <Text inline bold>{pointsDisplayed}</Text> utterances of <Text inline bold>{pointsToDisplay}</Text> are displayed. Please filter the results.
                                              </Text>}
                                              </div>
                                            </>
                                          }
                                          {(!embeddings || !embeddings.embeddings) && 'No embeddings available'}
                                        </GridItem>
                                      </GridContainer>
                                    })}
                                  </GridItem>
                                  <GridItem md={12}>
                                    {session && license.coach && this.renderSection(session, 'embeddings', true, (embeddings) => {
                                      const intentA = this.state.similarityVisulisation.intentA
                                      const intentB = this.state.similarityVisulisation.intentB

                                      const similarityFiltered = !intentA ? embeddings.similarity : embeddings.similarity.map(s => {
                                        if (s.name2 === intentA) {
                                          s.name2 = s.name1
                                          s.name1 = intentA
                                          const temp = s.example2
                                          s.example2 = s.example1
                                          s.example1 = temp
                                        }
                                        return s
                                      }).filter(s => s.name1 === intentA && (!intentB || !intentB.length || intentB.includes(s.name2)))
                                      return <React.Fragment>
                                        <GridContainer nounset>
                                          <GridItem xs={12}>
                                            <Text header>Critical Utterances
                                              <Tooltip title={
                                                <React.Fragment>
                                                  <div>Confusing examples: Training examples that have a highly similar meaning to training examples in other intents </div>
                                                  <div>Training examples in different intents that have high similarity value can be confusing to the NLU engine, and could lead to directing the user input to the wrong intent. </div>
                                                  <p>
                                                    <div>- Duplicated user examples: For duplicated or almost identical user examples, remove those that seem unnecessary.</div>
                                                    <div>- Similar user examples: For similar user examples, review the use case for those intents and make sure that they are not accidental additions caused by human error when the training data was created.
                                                    </div>
                                                  </p>
                                                  For large test sets, random sampling is performed to provide quicker results. This list might not include all possible utterance pairs.
                                                </React.Fragment>
                                              }>
                                                <ShowIcon icon="question-circle" />
                                              </Tooltip>
                                            </Text>
                                          </GridItem>
                                          <GridItem xs={12}>
                                            {similarityFiltered.length === 0 && <Text>No critical utterances found</Text>}
                                            {similarityFiltered.length > 0 && <Table
                                              name={`TestSetInsights_Similarity_${testSetId}`}
                                              tableHeaderColor="primary"
                                              tableHead={[
                                                { name: 'Intent A' },
                                                { name: 'Example A', width: 'medium' },
                                                { name: 'Intent B' },
                                                { name: 'Example B', width: 'medium' },
                                                { name: 'Similarity'}
                                              ]}
                                              tableData={
                                                embeddings.similarity && _.sortBy(similarityFiltered, (s) => -1 * s.similarity).map(s => [
                                                  s.name1,
                                                  s.example1,
                                                  s.name2,
                                                  s.example2,
                                                  () => <>
                                                    <DangerOrSuccessText >{s.similarity.toFixed(2).replace('0.', '').replace('.', '')}</DangerOrSuccessText>
                                                  </>
                                                ])
                                              }
                                            />}
                                          </GridItem>
                                        </GridContainer>
                                      </React.Fragment>
                                    })}
                                  </GridItem>
                                </GridContainer>
                              </>
                            },
                            {
                              tabName: 'Correlated Keywords',
                              tabContent: <>
                                {session && license.coach && this.renderSection(session, 'chi2', true, (embeddings) => <React.Fragment>
                                  <GridContainer nounset>
                                    <GridItem xs={12}>
                                      <Text header>Correlated Keyword Analysis</Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Text>
                                        <Text inline bold>Keywords</Text>: terms and pairs of terms that are correlated with each intent in your dataset
                                      </Text>
                                      <Text>
                                        If you identify unusual or anomalous correlated terms such as: numbers, names and so on, which should not be correlated with an intent, consider the following:
                                        <ul>
                                          <li>If you see <Text inline bold>names</Text> appearing amongst correlated unigrams or bigrams, add more variation of names so no specific names will be correlated</li>
                                          <li>If you see <Text inline bold>specific numbers</Text> like 1234 amongst correlated unigrams or bigrams and these are not helpful to the use case, remove or mask these numbers from the examples</li>
                                          <li>If you see <Text inline bold>terms which should never be correlated</Text> to that specific intent, consider adding or removing terms/examples so that domain specific terms are correlated with the correct intent</li>
                                        </ul>
                                      </Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Table
                                        name={`TestSetInsights_Keywords_${testSetId}`}
                                        tableHeaderColor="primary"
                                        tableHead={[
                                          'Intent',
                                          'Keywords (Unigram)',
                                          'Keywords (Bigram)'
                                        ]}
                                        tableData={
                                          embeddings.chi2 && _.sortBy(embeddings.chi2, (s) => s.name).map(s => [
                                            s.name,
                                            s.unigrams,
                                            s.bigrams
                                          ])
                                        }
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </React.Fragment>)}</>,
                              locationPrefix: `/testsets/view/${testSetId}/insights/similarity/correlation`,
                              dataUnique: 'tabTestSetSettingsTestSet'
                            },
                            {
                              tabName: 'Keyword Ambiguity',
                              tabContent: <>
                                {session && license.coach && this.renderSection(session, 'chi2', true, (embeddings) => <React.Fragment>
                                  <GridContainer nounset>
                                    <GridItem xs={12}>
                                      <Text header>Ambiguous Keyword Analysis</Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Text>
                                        <Text inline bold>Ambiguous Keywords</Text>: terms and pairs of terms that are correlated with more than one intent in your dataset
                                      </Text>
                                      <Text>
                                        If you see terms which are correlated with more than 1 intent, review if this seems anomalous based on the use case for that intent. If it seems reasonable, it is probably not an issue.
                                      </Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Table
                                        name={`TestSetInsights_Ambiguous_${testSetId}`}
                                        tableHeaderColor="primary"
                                        tableHead={[
                                          'Intent A',
                                          'Intent B',
                                          'Keyword'
                                        ]}
                                        tableData={
                                          embeddings.chi2_ambiguous_unigrams && _.sortBy(embeddings.chi2_ambiguous_unigrams, (s) => s.keyword).map(s => [
                                            s.name1,
                                            s.name2,
                                            s.keyword
                                          ])
                                        }
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </React.Fragment>)}</>,
                              locationPrefix: `/testsets/view/${testSetId}/insights/similarity/ambiguity`,
                              dataUnique: 'tabTestSetSettingsTestSet'
                            },
                            {
                              tabName: 'Keyword Similarity',
                              tabContent: <>
                                {session && license.coach && this.renderSection(session, 'chi2', true, (embeddings) => <React.Fragment>
                                  <GridContainer nounset>
                                    <GridItem xs={12}>
                                      <Text header>Keyword Similarity</Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Text>
                                        <Text inline bold>Confusing examples</Text>: Training examples that are using same keywords as training examples from different intents
                                      </Text>
                                      <Text>
                                        Training examples in different intents that have high similarity value can be confusing to the NLU engine, and could lead to directing the user input to the wrong intent.
                                        <ul>
                                          <li>Duplicated user examples: For duplicated or almost identical user examples, remove those that seem unnecessary.</li>
                                          <li>Similar user examples: For similar user examples, review the use case for those intents and make sure that they are not accidental additions caused by human error when the training data was created.</li>
                                        </ul>
                                      </Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Table
                                        name={`TestSetInsights_KeywordSimilarity_${testSetId}`}
                                        tableHeaderColor="primary"
                                        tableHead={[
                                          'Intent A',
                                          'Example A',
                                          'Intent B',
                                          'Example B',
                                          'Similarity'
                                        ]}
                                        tableData={
                                          embeddings.chi2_similarity && _.sortBy(embeddings.chi2_similarity, (s) => -1 * s.similarity).map(s => [
                                            s.name1,
                                            s.example1,
                                            s.name2,
                                            s.example2,
                                            () => <SimpleBar inverse progress={s.similarity}>{parseFloat(s.similarity).toFixed(4)}</SimpleBar>
                                          ])
                                        }
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </React.Fragment>)}</>,
                              locationPrefix: `/testsets/view/${testSetId}/insights/similarity/keyword-similarity`,
                              dataUnique: 'tabTestSetSettingsTestSet'
                            },
                            {
                              tabName: 'Intent Separation',
                              tabContent: <>
                                {session && license.coach && this.renderSection(session, 'embeddings', true, (embeddings) => <React.Fragment>
                                  <GridContainer nounset>
                                    <GridItem xs={12}>
                                      <Text header>Intent Separation</Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Text>
                                        <Text inline bold>Separation: </Text> Given two intents, the average distance between each pair of training examples in the two intents.
                                      </Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Table
                                        name={`TestSetInsights_Separation_${testSetId}`}
                                        tableHeaderColor="primary"
                                        tableHead={[
                                          'Intent A',
                                          'Intent B',
                                          'Separation'
                                        ]}
                                        tableData={
                                          embeddings.separation && _.sortBy(embeddings.separation, (s) => s.separation).map(s => [
                                            s.name1,
                                            s.name2,
                                            () => <SimpleBar progress={s.separation}>{s.separation.toFixed(4)}</SimpleBar>
                                          ])
                                        }
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </React.Fragment>)}</>,
                              locationPrefix: `/testsets/view/${testSetId}/insights/similarity/seperation`,
                              dataUnique: 'tabTestSetSettingsTestSet'
                            },
                            {
                              tabName: 'Intent Cohesion',
                              tabContent: <>
                                {session && license.coach && this.renderSection(session, 'embeddings', true, (embeddings) => <React.Fragment>
                                  <GridContainer nounset>
                                    <GridItem xs={12}>
                                      <Text header>Intent Cohesion</Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Text>
                                        <Text inline bold>Cohesion </Text> is the average similarity value between each pair of training examples in the same intent. That value is computed for each intent. The higher the intent cohesion value, the better the intent training examples.
                                      </Text>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <Table
                                        name={`TestSetInsights_Cohesion_${testSetId}`}
                                        tableHeaderColor="primary"
                                        tableHead={[
                                          'Intent',
                                          'Cohesion'
                                        ]}
                                        tableData={
                                          embeddings.cohesion && _.sortBy(embeddings.cohesion, (s) => s.cohesion).map(s => [
                                            s.name,
                                            () => <SimpleBar progress={s.cohesion}>{s.cohesion.toFixed(4)}</SimpleBar>
                                          ])
                                        }
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </React.Fragment>)}</>,
                              locationPrefix: `/testsets/view/${testSetId}/insights/similarity/cohesion`,
                              dataUnique: 'tabTestSetSettingsTestSet'
                            },


                          ].filter(t => t)}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        {!license.coach && <Text warning><FeatureUpgradeNavLink>Utterance Similarity Insights not available in this Edition</FeatureUpgradeNavLink></Text>}
                      </GridItem>
                    </GridContainer>)
                  },
                  {
                    tabName: 'LOOCV Insights',
                    dataUnique: 'tabTestSetInsightsLOOCV',
                    tabContent: (<GridContainer>
                      <GridItem xs={12}>
                        <CustomTabsSecondary
                          name={`tabTestSetInsights_${testSetId}_LOOCV`}
                          tabs={[
                            {
                              tabName: 'Incorrect Predictions',
                              tabContent: <>
                                {session && license.coach && this.renderSection(session, 'loocv', true, (loocv) => <React.Fragment>
                                  <GridContainer>
                                    <GridItem xs={12}>
                                      <Text>
                                        Botium simulates many nearly complete virtual NLP models, trained with all but one user example, and validates if the NLP model can extrapolate to the one missing user example. The simulation output for the sampled user examples are shown here.
                                      </Text>
                                    </GridItem>
                                  </GridContainer>
                                  <Table
                                    name={`TestSetInsights_LOOCV_NonMatching_${testSetId}`}
                                    tableHeaderColor="primary"
                                    tableHead={['Utterance', 'Expected Intent', 'Predicted Intent', 'Prediction Confidence']}
                                    tableData={
                                      loocv.results && _.sortBy(loocv.results.filter(p => !p.match), (s) => -1 * s.score).map(s => [
                                        s.utterance,
                                        s.expectedIntent,
                                        s.predictedIntent,
                                        () => <SimpleBar inverse progress={s.score}>{s.score && s.score.toFixed(4)}</SimpleBar>
                                      ])
                                    }
                                  /></React.Fragment>)}</>,
                              locationPrefix: `/testsets/view/${testSetId}/insights/loocv/nonmatching`,
                              dataUnique: 'tabTestSetSettingsTestSet'
                            },
                            {
                              tabName: 'Correct Predictions',
                              tabContent: <>
                                {session && license.coach && this.renderSection(session, 'loocv', true, (loocv) => <React.Fragment>
                                  <GridContainer>
                                    <GridItem xs={12}>
                                      <Text>
                                        Botium simulates many nearly complete virtual NLP models, trained with all but one user example, and validates if the NLP model can extrapolate to the one missing user example. The simulation output for the sampled user examples are shown here.
                                      </Text>
                                    </GridItem>
                                  </GridContainer>
                                  <Table
                                    name={`TestSetInsights_LOOCV_Matching_${testSetId}`}
                                    tableHeaderColor="primary"
                                    tableHead={['Utterance', 'Expected/Predicted Intent', 'Prediction Confidence']}
                                    tableData={
                                      loocv.results && _.sortBy(loocv.results.filter(p => p.match), (s) => s.score).map(s => [
                                        s.utterance,
                                        s.expectedIntent,
                                        () => <SimpleBar progress={s.score}>{s.score && s.score.toFixed(4)}</SimpleBar>
                                      ])
                                    }
                                  /></React.Fragment>)}</>,
                              locationPrefix: `/testsets/view/${testSetId}/insights/loocv/matching`,
                              dataUnique: 'tabTestSetSettingsTestSet'
                            },


                          ].filter(t => t)}
                        />
                      </GridItem>
                    </GridContainer>)
                  },
                  {
                    tabName: 'Humanification Insights',
                    dataUnique: 'tabTestSetInsightsHumanification',
                    tabContent: (<GridContainer>
                      <GridItem xs={12}>
                        <CustomTabsSecondary
                          name={`tabTestSetInsights_${testSetId}_Humanification`}
                          tabs={[
                            {
                              tabName: 'Incorrect Predictions',
                              tabContent: <>
                                {session && license.coach && this.renderSection(session, 'humanification', true, (humanification) => <React.Fragment>
                                  <GridContainer>
                                    <GridItem xs={12}>
                                      <Text>
                                        Botium simulates typical human behaviour and typing habits, including typographic errors, by perturbating the training data and sending it to a virtual NLP model. The perturbated user examples and the simulation output are shown here.
                                      </Text>
                                    </GridItem>
                                  </GridContainer>
                                  <Table
                                    name={`TestSetInsights_Humanification_NonMatching_${testSetId}`}
                                    tableHeaderColor="primary"
                                    tableHead={['Utterance', 'Expected Intent', 'Predicted Intent', 'Prediction Confidence']}
                                    tableData={
                                      humanification.predictionDetails && _.sortBy(humanification.predictionDetails.filter(p => !p.match), (s) => -1 * s.score).map(s => [
                                        s.utterance,
                                        s.expectedIntent,
                                        s.predictedIntent,
                                        () => <SimpleBar inverse progress={s.score}>{s.score && s.score.toFixed(4)}</SimpleBar>
                                      ])
                                    }
                                  /></React.Fragment>)}</>,
                              locationPrefix: `/testsets/view/${testSetId}/insights/humanification/nonmatching`,
                              dataUnique: 'tabTestSetSettingsTestSet'
                            },
                            {
                              tabName: 'Correct Predictions',
                              tabContent: <>
                                {session && license.coach && this.renderSection(session, 'humanification', true, (humanification) => <React.Fragment>
                                  <GridContainer>
                                    <GridItem xs={12}>
                                      <Text>
                                        Botium simulates typical human behaviour and typing habits, including typographic errors, by perturbating the training data and sending it to a virtual NLP model. The perturbated user examples and the simulation output are shown here.
                                      </Text>
                                    </GridItem>
                                  </GridContainer>
                                  <Table
                                    name={`TestSetInsights_Humanification_Matching_${testSetId}`}
                                    tableHeaderColor="primary"
                                    tableHead={['Utterance', 'Expected/Predicted Intent', 'Prediction Confidence']}
                                    tableData={
                                      humanification.predictionDetails && _.sortBy(humanification.predictionDetails.filter(p => p.match), (s) => s.score).map(s => [
                                        s.utterance,
                                        s.expectedIntent,
                                        () => <SimpleBar progress={s.score}>{s.score && s.score.toFixed(4)}</SimpleBar>
                                      ])
                                    }
                                  /></React.Fragment>)}</>,
                              locationPrefix: `/testsets/view/${testSetId}/insights/humanification/matching`,
                              dataUnique: 'tabTestSetSettingsTestSet'
                            },


                          ].filter(t => t)}
                        />
                      </GridItem>
                    </GridContainer>)
                  },
                  {
                    tabName: 'Term Importance Insights',
                    dataUnique: 'tabTestSetInsightsTermImportance',
                    tabContent: (<GridContainer>
                      <GridItem xs={12} smallMarginTop>
                        <Text>
                          This heat map shows what terms are important for which intents. The darker the color, the more important the term is for the intent.
                        </Text>
                      </GridItem>
                      <GridItem xs={12} smallMarginTop>
                        {session && this.renderSection(session, 'highlight', true, (highlight) => <React.Fragment>
                          {highlight.matrix && <TableHeatMap matrix={highlight.matrix} getRowHeader={r => r.token} getColumns={r => r.weights} filterRowLabel="Enter Term Filter" filterColumnLabel="Enter Intent Filter" />}
                        </React.Fragment>)}
                      </GridItem>
                    </GridContainer>)
                  },
                  {
                    tabName: 'History & Progress',
                    dataUnique: 'tabTestSetInsightsProgress',
                    tabContent: (<GridContainer>
                      <GridItem xs={12}>
                        <Query
                          query={TESTSETCOACHSESSIONS_QUERY}
                          variables={{ testSetId, ...getTablePaginationVariables(insightsTableName) }}
                          fetchPolicy={'network-only'}
                          notifyOnNetworkStatusChange={true}
                        >
                          {({ loading, error, refetch, fetchMore, data }) => {
                            return (
                              <Table
                                name={insightsTableName}
                                dataUnique="tblTestSetCoachSessionsProgress"
                                tableHeaderColor="primary"
                                tableHead={[
                                  'Date',
                                  'Test Set Score',
                                  'Utterance Score',
                                  'Humanification Score',
                                  '',
                                  ''
                                ]}
                                pageLoading={loading}
                                pageErr={error}
                                disableHeader
                                tableData={
                                  data && data.testsetcoachsessions &&
                                  data.testsetcoachsessions.map((ts, tsIndex) => [
                                    () => <DateFormat>{ts.createdAt}</DateFormat>,
                                    () => {
                                      if (ts.kfoldReady && ts.kfoldErr) return <Text danger data-unique={`txtTestSetInsights_Kfold_Err${tsIndex}`}>{ts.kfoldErr}</Text>
                                      else if (ts.kfoldReady && _.isNumber(ts.kfoldF1)) return <SimpleBar data-unique={`txtTestSetInsights_Kfold_Bar${tsIndex}`} progress={ts.kfoldF1}>{ts.kfoldF1.toFixed(4)}</SimpleBar>
                                      else if (ts.kfoldReady) return <Text danger data-unique={`txtTestSetInsights_Kfold_Err${tsIndex}`}>K-Fold Cross Validation not possible (too less data)</Text>
                                      else return <Text info data-unique={`txtTestSetInsights_Kfold_Loading${tsIndex}`}><LoadingIndicator /> Processing ...</Text>
                                    },
                                    () => {
                                      if (ts.loocvReady && ts.loocvErr) return <Text danger data-unique={`txtTestSetInsights_Loocv_Err${tsIndex}`}>{ts.loocvErr}</Text>
                                      else if (ts.loocvReady && _.isNumber(ts.loocvScore)) return <SimpleBar data-unique={`txtTestSetInsights_Loocv_Bar${tsIndex}`} progress={ts.loocvScore}>{ts.loocvScore.toFixed(4)}</SimpleBar>
                                      else if (ts.loocvReady) return <Text danger data-unique={`txtTestSetInsights_Loocv_Err${tsIndex}`}>Leave-One-Out Cross Validation not possible (too less data)</Text>
                                      else return <Text info data-unique={`txtTestSetInsights_Loocv_Loading${tsIndex}`}><LoadingIndicator /> Processing ...</Text>
                                    },
                                    () => {
                                      if (ts.humanificationReady && ts.humanificationErr) return <Text danger data-unique={`txtTestSetInsights_Humanification_Err${tsIndex}`}>{ts.humanificationErr}</Text>
                                      else if (ts.humanificationReady && _.isNumber(ts.humanificationF1)) return <SimpleBar data-unique={`txtTestSetInsights_Humanification_Bar${tsIndex}`} progress={ts.humanificationF1}>{ts.humanificationF1.toFixed(4)}</SimpleBar>
                                      else if (ts.humanificationReady) return <Text danger data-unique={`txtTestSetInsights_Humanification_Err${tsIndex}`}>Humanification Perturbation not possible</Text>
                                      else return <Text info data-unique={`txtTestSetInsights_Humanification_Loading${tsIndex}`}><LoadingIndicator /> Processing ...</Text>
                                    },
                                    () => session && session.id !== ts.id && <LinkButton data-unique={`btnTestSetInsights_Show${tsIndex}`} onClick={() => this.setState({ coachSessionId: ts.id })}>Show these Insights</LinkButton>,
                                    () => session && session.id !== ts.id &&
                                      <Mutation
                                        mutation={DELETE_TESTSETCOACHSESSION}
                                        onCompleted={data => setAlertSuccessMessage('Insights deleted')}
                                        onError={error => setAlertErrorMessage(`Insights deletion failed`, error)}
                                        refetchQueries={[
                                          {
                                            query: TESTSETCOACHSESSIONS_QUERY,
                                            variables: { testSetId }
                                          },
                                          {
                                            query: TESTSETFIRSTCOACHSESSION_QUERY,
                                            variables: { testSetId }
                                          },
                                          {
                                            query: TESTSET_STATS_QUERY,
                                            variables: { id: testSetId }
                                          }
                                        ]}
                                      >
                                        {(deleteTestSetCoachSession, { loading, error }) => <LinkButton data-unique={`btnTestSetInsights_Delete${tsIndex}`} onClick={() => deleteTestSetCoachSession({ variables: { id: ts.id } })}>Delete these Insights</LinkButton>}
                                      </Mutation>
                                  ])
                                }
                                onPageChange={async (skip, first) => {
                                  try {
                                    await fetchMore({
                                      variables: { skip, first },
                                      updateQuery: (prev, { fetchMoreResult }) => {
                                        if (!fetchMoreResult) return prev
                                        return fetchMoreResult
                                      }
                                    })
                                  } catch (err) { console.warn('fetchMore failed', err) }
                                }}
                                onRefresh={() => refetch()}
                              />
                            )
                          }}
                        </Query>
                      </GridItem>
                    </GridContainer>)
                  }
                ].filter(t => t)}
              />
            </GridItem>}
          </GridContainer>)
        }}
      </Query>
    )
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, }),
    { getTablePaginationVariables, setAlertSuccessMessage, setAlertErrorMessage },
  )
)(withRouter(TestSetInsights))
