import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

const style = theme => ({
  bar: {
    backgroundColor: theme.colors.common.secondary
  },
  bar2Buffer: {
    backgroundColor: theme.colors.common.secondary40
  },
  bar1Indeterminate: {
    backgroundColor: theme.colors.common.secondary
  },
  dashedColorPrimary: {
    backgroundImage: 'radial-gradient(rgba(43, 62, 83, 0.1) 50%, rgba(43, 62, 83, 0.1) 50%, transparent 42%)'
  },
  colorPrimaryIndeterminate: {
    backgroundColor: theme.colors.common.primary10
  },
})

function linearProgress({ classes, ...rest }) {
  return <LinearProgress
    {...rest}
    classes={{
      bar: classes.bar,
      bar2Buffer: classes.bar2Buffer,
      bar1Indeterminate: classes.bar1Indeterminate,
      dashedColorPrimary: classes.dashedColorPrimary,
      colorPrimary: (!rest.variant || rest.variant ==='indeterminate') ? classes.colorPrimaryIndeterminate : null,
    }}
  />
}

export default withStyles(style)(linearProgress)
