import { SET_TABLE_SETTINGS, CLEAR_TABLE_SETTINGS } from '../constants'

export default function table(state = {}, action) {
  switch (action.type) {
    case SET_TABLE_SETTINGS:
      return {
        ...state,
        [action.payload.table]: action.payload.settings,
      }
    case CLEAR_TABLE_SETTINGS:
      if (state[action.payload.table]) {
        const clone = { ...state }
        delete clone[action.payload.table]
        return clone
      }
      return state
    default:
      return state
  }
}
