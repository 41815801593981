import React from 'react'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import BotiumBoxLogo from 'components/Typography/BotiumBoxLogo'

const LoadingPage = () => (
  <div style={{ height: '100vh' }}>
    <GridContainer autoHeight>
      <GridItem xs={12} center bottom largePadding>
        <BotiumBoxLogo hideText large />
      </GridItem>
      <GridItem xs={12} center largePadding>
        <GridContainer>
          <GridItem xs={12} center>
            <Text><LoadingIndicator large /></Text>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  </div>
)

export default LoadingPage
