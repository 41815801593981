import _ from 'lodash'

export function extractErrorMessage(err, suppress = false, force = false) {
  if (!err) {
    return ''
  } else if (_.isString(err)) {
    if (!suppress) console.error(err)
    return err
  } else if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    return err.graphQLErrors.map(e => e.message).join('. ')
  } else if (err.networkError && err.networkError.statusCode === 413) {
    return 'Request too large'
  } else if (err.networkError) {
    if (!suppress) {
      if (err.networkError.result && err.networkError.result.errors) {
        console.error(err.networkError.result.errors.map(e => e.message).join('. '))
      }
    }
    if (force && err.networkError.result && err.networkError.result.errors) {
      return err.networkError.result.errors.map(e => e.message).join('. ')
    }
    const errorMsg = 'An error has occurred, we\'re working on a resolution. If issue persists, please contact support.'
    return errorMsg
  } else if (err.message) {
    if (!suppress) console.error(err.message)
    return err.message
  } else if (err.target && err.target.error && err.target.error.message) {
    if (!suppress) console.error(err.target.error.message)
    return err.target.error.message
  } else {
    if (!suppress) console.error(err)
    return JSON.stringify(err)
  }
}

export async function validateUnique (client, query, variables, objName, idToIgnoreOrFn, errMsg) {
  try {
    const { data } = await client.query({
      query,
      fetchPolicy: 'network-only',
      variables: variables || {}
    })
    if (data[objName] && data[objName].length > 0 && data[objName].filter(a => !idToIgnoreOrFn || (_.isFunction(idToIgnoreOrFn) ? idToIgnoreOrFn(a) : (a.id !== idToIgnoreOrFn))).length > 0) {
      return errMsg
    }
  } catch (err) {
    return `Validation failed: ${extractErrorMessage(err, true)}`
  }
}
