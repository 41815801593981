import {CLEAR_NAMESPACE, SET_NAMESPACE} from '../constants'

export function setNamespace(namespace) {
  return {
    type: SET_NAMESPACE,
    payload: {
      selected: namespace
    }
  }
}
export function clearNamespace() {
  return {
    type: CLEAR_NAMESPACE
  }
}
