export const uniqueDataKey = (data, dataKeyField) => {
  if (!data || !dataKeyField) {
    return data
  }
  const dataKeyToCount = {}
  for (const entry of data) {
    const dataKey = entry[dataKeyField]
    if (!dataKeyToCount[dataKey]) {
      dataKeyToCount[dataKey] = 1
    } else {
      dataKeyToCount[dataKey] += 1
    }
  }

  const dataKeyToProcessed = {}
  return data.map(entry => {
    const dataKey = entry[dataKeyField]
    if (dataKeyToCount[dataKey] === 1) {
      return entry
    }
    if (!dataKeyToProcessed[dataKey]) {
      dataKeyToProcessed[dataKey] = 1
    } else {
      dataKeyToProcessed[dataKey] += 1
    }

    return Object.assign({}, entry, {[dataKeyField]: `${entry[dataKeyField]} (${dataKeyToProcessed[dataKey]})` })
  })

}
