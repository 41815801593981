import React from 'react'
import { withRouter } from 'react-router-dom'
import ParaphraserSuggestions from '../TestDataWizard/ParaphraserSuggestions.jsx'

export const FromUtterance = withRouter(function ({ match, history }) {
  return <ParaphraserSuggestions
    testSetId={match.params.id}
    scriptId={match.params.scriptId}
    scriptName={match.params.scriptName}
    onReady={() => history.push(`/testsets/view/${match.params.id}/testcases/viewutterance/${match.params.scriptId}/${encodeURIComponent(match.params.scriptName)}`)}
    onCancel={() => history.push(`/testsets/view/${match.params.id}/testcases/viewutterance/${match.params.scriptId}/${encodeURIComponent(match.params.scriptName)}`)}
  />
})

export const FromScript = withRouter(function ({ match, history }) {
  return <ParaphraserSuggestions
    testSetId={match.params.id}
    scriptId={match.params.scriptId}
    scriptName={match.params.scriptName}
    onReady={() => history.push(`/testsets/view/${match.params.id}/testcases/viewscript/${match.params.scriptId}/${encodeURIComponent(match.params.scriptName)}`)}
    onCancel={() => history.push(`/testsets/view/${match.params.id}/testcases/viewscript/${match.params.scriptId}/${encodeURIComponent(match.params.scriptName)}`)}
  />
})
