import React from 'react'
import config from 'config'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
// apollo
import {Query} from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  url,
  composeValidators,
  renderTextField,
  renderSelect,
  renderCodeArea,
  json,
  prettyPrintJson
} from 'components/Form/Form'

import {APIKEYS_QUERY} from '../../views/Settings/gql'
import { capSetDescription, usedByCapabilitySet } from './Helper'


export function holmesCaps2Form(caps) {
  let apiKey = null
  const serviceUrlCap = caps.find(c => c.name === 'HOLMES_SERVICE_URL')
  if (serviceUrlCap && serviceUrlCap.stringValue) {
    try {
      const parsedServiceUrl = new URL(serviceUrlCap.stringValue)
      apiKey = parsedServiceUrl.searchParams.get('APIKEY')
    } catch (err) {
    }
  }

  return {
    holmes: {
      url: (
        caps.find(c => c.name === 'HOLMES_URL') || { stringValue: '' }
      ).stringValue,
      apiKey: apiKey,
      serviceUrl: (
        caps.find(c => c.name === 'HOLMES_SERVICE_URL') || { stringValue: '' }
      ).stringValue,
      vars: (
        caps.find(c => c.name === 'HOLMES_VARS') || { jsonValue: '' }
      ).jsonValue,
      channel: (
        caps.find(c => c.name === 'HOLMES_CHANNEL') || { jsonValue: '{ "id": "1", "type": "web", "lang": "en" }' }
      ).jsonValue,
      user: (
        caps.find(c => c.name === 'HOLMES_USER') || { stringValue: '' }
      ).stringValue,
      userId: (
        caps.find(c => c.name === 'HOLMES_USER_ID') || { stringValue: '' }
      ).stringValue,
    },
  }
}

const capNamesMap = {
  'holmes.url': 'HOLMES_URL',
  'holmes.serviceUrl': 'HOLMES_SERVICE_URL',
  'holmes.vars': 'HOLMES_VARS',
  'holmes.channel': 'HOLMES_CHANNEL',
  'holmes.user': 'HOLMES_USER',
  'holmes.userId': 'HOLMES_USER_ID',
}

export function holmesForm2caps(values) {
  const capabilities = [
    {
      name: 'HOLMES_URL',
      type: 'STRING',
      stringValue: values.holmes.url || '',
    },
    {
      name: 'HOLMES_SERVICE_URL',
      type: 'STRING',
      stringValue: values.holmes.serviceUrl || '',
    },
    {
      name: 'HOLMES_VARS',
      type: 'JSON',
      jsonValue: values.holmes.vars || '',
    },
    {
      name: 'HOLMES_CHANNEL',
      type: 'JSON',
      jsonValue: values.holmes.channel || '',
    },
    {
      name: 'HOLMES_USER',
      type: 'STRING',
      stringValue: values.holmes.user || '',
    },
    {
      name: 'HOLMES_USER_ID',
      type: 'STRING',
      stringValue: values.holmes.userId || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'holmes' }
  ]

  return capabilities
}

export class WiproHolmesEdit extends React.Component {
  render() {
    const { disabled, capSetCapNames } = this.props
    return (<React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Field
            name="holmes.url"
            component={renderTextField}
            label="Holmes Chatbot Endpoint Url"
            validate={composeValidators(required, url)}
            data-unique="txtWiproHolmesEditUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['holmes.url']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['holmes.url'])}
          />
        </GridItem>
        <GridItem xs={12} />
        <Query query={APIKEYS_QUERY}>
          {({loading, error, data}) => {
            return (<GridItem xs={12} sm={6}>
              <Field
                name="holmes.apiKey"
                component={renderSelect}
                label="Select an API Key for the Service Url"
                data-unique="selWiproHolmesEditApiKey"
                loading={loading}
                error={error}
                items={data && data.apikeys && data.apikeys.map(a => {
                  return { key: a.key, label: a.name }
                })}
                disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['holmes.apiKey']) || disabled}
                helperText={capSetDescription(capSetCapNames, capNamesMap['holmes.apiKey'])}
              />
            </GridItem>)
          }}
        </Query>
        <FormSpy subscription={{ form: true }} render={({ form:  { change }}) => (
          <OnChange name="holmes.apiKey">
            {(value, previous) => {
              if (value) {
                change('holmes.serviceUrl', `${config.api.ext}/inbound?APIKEY=${value}`)
              }
            }}
          </OnChange>
        )} />
        <GridItem xs={12} sm={6}>
          <Field
            name="holmes.serviceUrl"
            component={renderTextField}
            label="Holmes Chatbot Service Url"
            validate={url}
            data-unique="txtWiproHolmesEditServiceUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['holmes.serviceUrl']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['holmes.serviceUrl'])}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="holmes.user"
            component={renderTextField}
            label="Holmes Chatbot User Name"
            data-unique="txtWiproHolmesEditUser"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['holmes.user']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['holmes.user'])}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="holmes.userId"
            component={renderTextField}
            label="Holmes Chatbot User Id"
            data-unique="txtWiproHolmesEditUserId"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['holmes.userId']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['holmes.userId'])}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            className="CapabilitiesShort"
            name="holmes.vars"
            component={renderCodeArea}
            options={{ mode: 'application/json' }}
            label="Holmes Variables (as JSON)"
            codeFormat={prettyPrintJson}
            validate={json}
            data-unique="codeWiproHolmesEditVariables"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['holmes.vars']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['holmes.vars'])}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            className="CapabilitiesShort"
            name="holmes.channel"
            component={renderCodeArea}
            options={{ mode: 'application/json' }}
            label="Holmes Channel Settings (as JSON)"
            codeFormat={prettyPrintJson}
            validate={json}
            data-unique="codeWiproHolmesEditChannelSettings"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['holmes.channel']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['holmes.channel'])}
          />
        </GridItem>
      </GridContainer>
    </React.Fragment>)
  }
}
