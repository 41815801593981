import cardTitleStyle from 'assets/jss/material-dashboard-react/components/cardTitleStyle.jsx'
import headerLinksStyle from 'assets/jss/material-dashboard-react/components/headerLinksStyle.jsx'
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx'

const dashboardStyle = theme => ({
  ratioByDayData: {
    '& .ct-series-1': {
      color: `${theme.colors.primary}!important`,
      //fontWeight: 'bold',
      fontSize: 14
    },
    '& .ct-series-0': {
      color: `${theme.colors.primary}!important`,
      //fontWeight: 'bold',
      fontSize: 14
    },
    '& .ct-series-1::before': {
      backgroundColor: `${theme.colors.common.danger} !important`,
      borderColor: `${theme.colors.common.danger} !important`
    }
    
  },
  successText: {
    color: theme.colors.primary,
    
  },
  dangerText: {
    color: theme.status.danger
  },
  infoText: {
    color: theme.colors.primary,
  },
  cardHeaderIcon: {
    height: 'fit-content',
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 6
    }
  },
  largeText: {
    fontSize: '12px'
  },
  moreButton: {
    float: 'right',
    marginBottom: '15px'
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  dashboardToolbar: {
    paddingRight: theme.spacing.unit,
  },
  dashboardToolbarSpacer: {
    flex: 0.6,
  },
  dashboardToolbarActions: {
    flex: 0.4,
    color: theme.palette.text.secondary,
    ...headerLinksStyle(theme).searchWrapper,
  },
  primaryTestSessionHeader: {
    fontSize: '21px',
    fontWeight: cardTitleStyle(theme).cardTitle.fontWeight
  },
  secondaryTestSessionHeader: {
    fontSize: '14px',
    fontWeight: cardTitleStyle(theme).cardTitle.fontWeight
  },
  stats: {
    color: '#999999',
    display: 'flex',
    fontSize: '11px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      marginRight: '3px',
      marginLeft: '3px',
      verticalAlign: 'middle'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '14px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },

  statsnoflex: {
    color: '#999999',
    display: 'table',
    fontSize: '11px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      marginRight: '3px',
      marginLeft: '3px',
      verticalAlign: 'middle'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '14px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },

  failedBox: {
    backgroundColor: '#f55a4e',
    color: theme.colors.white,
    fontSize: 12,
    padding: 8,
    lineHeight: '16px',
    borderRadius: 5,
    marginTop: 9,
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  testProjectCardHeader: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
    fontWeight: 'bold',
    fontSize: 13,
    textDecoration: 'underline',
    color: theme.colors.primary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.colors.primary,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 5,
      color: theme.colors.primary,
    }
  },
  lastRunLink: {
    fontSize: 12,
    fontWeight: 'normal',
    paddingLeft: 5,
    textDecoration: 'underline',
    color: theme.colors.primary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.colors.primary,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 5,
      color: theme.colors.primary,
    }
  },
  failingCountLink: {
    display: 'block',
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 5,
      color: theme.colors.primary,
    }
  },
  statsDangerTitle: {
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 20px)',
    ...cardTitleStyle(theme).cardTitle,
    fontWeight: 'bold',
    color: theme.colors.primary
  },
  ...cardTitleStyle(theme),
  tableCell: tableStyle(theme).tableCell,
  advacedTableCellLink: {
    '& a': {
      fontWeight: 'bold',
      color: theme.colors.primary,
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      }
    }
  },
  divider: {
    margin: '10px'
  },
  chartLabels: {
    '& .ct-label': {
      color: theme.colors.primary
    },
    '& .ct-grid': {
      stroke: theme.colors.primary
    },
  },
  listItemNone: {
    listStyleType: 'none'
  },
  recentlyFailedCardTimeFrame: {
    position: 'absolute', bottom: 0, right: 0, padding: '10px 15px'
  },
  recentlyFailedCardProgress: {
    position: 'absolute', top: 0, right: 0, padding: 15, color: theme.colors.cardprogress,
  },
  showLink: {
    color: theme.colors.primary,
      textDecoration: 'underline',
    '&:hover': {
      color: theme.colors.primary,
      textDecoration: 'none',
    }
  },
  projectsListEmbeddedTable: {
    '& table': {
      borderCollapse: 'separate!Important',
    }
  },
  textLeftBorderDefault: {
    borderLeft: `2px solid ${theme.colors.borderColor}`
  },
  cardLink: { 
    '& svg': {
      color: `${theme.colors.primary}`,
    }
  },
  scoreChartPosition: {
    paddingTop: 50
  },
  customTooltip: {
    background: 'white',
    border: '1px solid #e0e0e0',
    padding: '10px',
    fontSize: '12px'
  },
})

export default dashboardStyle
