import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Mutation, compose } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import Tooltip from 'components/Tooltip/NativeTooltip'

import { renderCheckbox, FormActionsToolbar } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import { UPDATE_TESTSESSION_GDPRAUDIT, RefetchTestSessionQueries } from './gql'
import GDPRSessionSuggestions, { getIssueCount } from './GDPRSessionSuggestions.jsx'

const tooltips = {
  de: {
    Verantwortlicher: 'Die Informationspflicht trifft den "datenschutzrechtlich Verantwortlichen", das ist derjenige, für den der Chatbot betrieben wird, also meist der "Auftraggeber", und nicht derjenige, durch den der Chatbot betrieben wird, also Chatbot-Dienstleister.',
    Zweck: 'Grundsätzlich "Betrieb eines Chatbots"',
    ZweckRechtsgrundlage: 'Grundsätzlich "Erfüllung der vertraglichen Chatbot-Leistung (Art. 6 Abs. 1 lit. b DSGVO)" und unter Umständen: "die betroffene Person hat ihre Einwilligung zur Verarbeitung für den konkreten Zweck gegeben (Art. 6 Abs. 1 lit. a DSGVO)"',
    Empfaenger: 'Sämtliche Dienstleister und dergleichen, welche Zugang/Zugriff auf die personenbezogenen Daten haben.',
    Dauer: 'Aufgrund des datenschutzrechtlichen Grundsatzes der Speicherbegrenzung müssen personenbezogene Daten so rasch als möglich gelöscht/anonymisiert werden. Dementsprechend ist ein Löschkonzept zu erarbeiten, über welches in der Datenschutzerklärung zu informieren ist.',
    PersonenbezogeneDaten: 'Personenbezogene Daten werden in der DSGVO definiert: alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen; als identifizierbar wird eine Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, psychologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser Person sind, identifiziert werden kann.',
    AngemesseneDatensicherheit: 'Je nachdem, welcher materielle oder immaterielle Schaden bei Missbrauch der betroffenen Daten entstehen kann, muss unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Eintrittswahrscheinlichkeit ein Datensicherheitskonzept erarbeitet werden. Dieses Datensicherheitskonzept muss angemessene Datensicherheit gewährleisten. Das gilt bei Chatbots sowohl am Kommunikationsweg wie auch hinsichtlich aller betroffener/verbundener Systeme.',
    Einwilligung: 'Diese wird in der DSGVO definiert als die von der betroffenen Person freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutig bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.',
    Identitaet: 'Soweit Zweifel an der Identität des Abfragenden bestehen, muss durch Maßnahmen (je höher das Risiko des Missbrauchs desto strengere Maßnahmen, bis hin zur Kopie eines amtlichen Lichtbildausweises) gewährleistet werden, dass der Abfragende tatsächlich derjenige ist, der er behauptet zu sein, und er nur seine Daten erhält.',
    WeitereZwecke: 'Bei "weiteren Zwecken" handelt es sich um solche Datenverarbeitungen, die nicht für die (vertragliche) Chatbot-Service-Erbringung notwendig sind, wie zum Beispiel Verhaltensanalyse, Bonitätsprüfung, oder dergleichen.',
    Profiling: 'Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass die personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser Person zu analysieren oder vorherzusagen.',
    Beeintraechtigt: 'Die vollautomatisierte Entscheidungsfindung durch den Chatbot ist grundsätzlich verboten, etwa, wenn er – etwa bei vollautomatisierter Bonitätsprüfung – entscheidet, ob Verträge geschlossen, aufgelöst, oder nur unter bestimmten Bedingungen geschlossen werden.',
    Rechtsvorschriften: 'Ausdrückliche gesetzliche Ermächtigungen, dass Profiling erfolgen darf.',
    Auftragsverarbeitervertrag: 'Die DSGVO verpflichtet "Auftraggeber" und "Auftragnehmer" dazu, einen schriftlichen Vertrag über die Datenverarbeitung im Auftrag zu schließen (Details in Art. 28 DSGVO)',
    Werbenachrichten: 'Das sind Informationen jeglicher Art, die darauf abzielen, den Absatz von Waren bzw Dienstleistungen zu steigern und nicht unmittelbar auf konkrete Anfrage durch den Chatbot-Nutzer bedingt sind. Also zum Beispiel nicht abgefragte Informationen über Zusatzleistungen oder dergleichen.'
  },
  en: {
    ResponsiblePerson: 'The information obligation applies to the "data protection officer", that is the person for whom the chatbot is operated, i.e. usually the "client", and not the person who operates the chatbot, i.e. chatbot service provider.',
    Purpose: 'Basically "operation of a chatbot"',
    PurposeFulfillment: 'Basic "Fulfillment the contractual chatbot service (Art. 6 Para. 1 lit. b GDPR)" and possibly: "The data subject has given their consent to processing for the specific purpose (Art. 6 Paragraph 1 lit. a GDPR)"',
    Recipient: 'All service providers and the like who have access to the personal data.',
    Duration: 'Due to the principle of storage limitation under data protection law, personal data must be deleted/anonymised as quickly as possible. Accordingly, a deletion concept is to be developed, about which information is to be provided in the data protection declaration.',
    PersonalData: 'Any information relating to an identified or identifiable natural person; an identifiable person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, psychological, genetic, mental, economic, cultural or social identity of that person.',
    AdequateDataSecurity: 'Depending on the material or immaterial damage that can result from misuse of the data concerned, a data security concept must be developed, taking into account the state of the art, the implementation costs and the probability of occurrence. This data security concept must ensure adequate data security. This applies to chatbots both at the communication channel and with regard to all affected/connected systems.',
    Consent: 'This is defined in the GDPR as the expression of will in the form of a statement or other unambiguous affirmative action, given voluntarily by the data subject for the specific case, in an informed manner and unambiguously, by which the data subject indicates that he or she consents to the processing of personal data relating to him or her.',
    Identity: 'Insofar as there are doubts about the identity of the interrogator, measures must be taken (the higher the risk of misuse, the stricter the measures, up to and including a copy of an official photo ID) to ensure that the interrogator is actually who he claims to be and that he only receives his data.',
    OtherPurposes: '"Other purposes" are those data processing operations that are not necessary for the (contractual) chatbot service provision, such as behavioral analysis, credit assessment, or the like.',
    Profiling: 'Profiling is any automated processing of personal data that consists of using personal data to evaluate certain personal aspects relating to a natural person, in particular to analyze or predict aspects relating to that person\'s performance at work, economic situation, health, personal preferences, interests, reliability, behavior, location or change of location.',
    Affected: 'Fully automated decision making by the chatbot is generally prohibited, for example, when it decides - for example, in the case of fully automated credit checks - whether contracts are concluded, terminated, or only concluded under certain conditions.',
    Legislation: 'Explicit legal authorizations that profile may take place',
    ProcessorAgreement: 'The GDPR obliges "client" and "contractor" to conclude a written contract for data processing on behalf of the customer (details in Art. 28 GDPR)',
    PromotionalNews: 'This is information of any kind that aims to increase the sale of goods or services and is not directly related to a specific request by the chatbot user. For example, information about additional services or the like that was not requested.'
  }
}

const auditQuestions = {
  de: {
    header: () => <>GDPR/DSGVO Fragen zu <Tooltip title={tooltips.de.Verantwortlicher} underline>Ihrem Chatbot</Tooltip></>,
    dseExists: {
      label: () => <>1.) Ist zu Ihrem Chatbot eine Datenschutzerklärung (DSE) vorhanden?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn zu Ihrem Chatbot eine Datenschutzerklärung (DSE) vorhanden ist.</>
    },
    dseAvailable: {
      label: () => <>Ist die Datenschutzerklärung (DSE) vor der Erfassung von personenbezogenen Daten und frühestmöglich bei Nutzung Ihres Chatbots zugänglich ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn die Datenschutzerklärung (DSE) laut Fragestellung zugänglich ist.</>
    },
    dseComplete: {
      label: () => <>Enthält die Datenschutzerklärung (DSE) alle Informationen, die laut DSGVO notwendig sind ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn die Datenschutzerklärung (DSE) alle Informationen laut DSGVO, Art. 13, Buchstabe f, enthält.</>
    },
    dseExistsKontaktdaten: {
      label: () => <>Die DSE enthält den Namen und die Kontaktdaten des <Tooltip title={tooltips.de.Verantwortlicher} underline>Verantwortlichen</Tooltip>.</>
    },
    dseExistsZwecke: {
      label: () => <>Die DSE enthält die <Tooltip title={tooltips.de.Zweck} underline>Zwecke</Tooltip>, für die die personenbezogenen Daten verarbeitet werden sollen, sowie die <Tooltip title={tooltips.de.ZweckRechtsgrundlage} underline>Rechtsgrundlage</Tooltip> für die Verarbeitung.</>
    },
    dseExistsEmpfaenger: {
      label: () => <>Die DSE enthält die <Tooltip title={tooltips.de.Empfaenger} underline>Empfänger</Tooltip> der personenbezogenen Daten.</>
    },
    dseExistsDauer: {
      label: () => <>Die DSE enthält die <Tooltip title={tooltips.de.Dauer} underline>Dauer</Tooltip>, für die die personenbezogenen Daten gespeichert werden oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer.</>
    },
    dseExistsAndere: {
      label: () => <>Die DSE enthält alle anderen Informationen laut DSGVO, Art. 13, Buchstabe f (siehe unten).</>
    },    
    datenErhoben: {
      label: () => <>2.) Werden von Ihrem Chatbot <Tooltip title={tooltips.de.PersonenbezogeneDaten} underline>personenbezogene Daten</Tooltip> außer der IP-Adresse erhoben ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot außer der IP-Adresse weitere personenbezogene Daten erhoben werden.</>
    },
    datenErhobenSecurity: {
      label: () => <>Ist <Tooltip title={tooltips.de.AngemesseneDatensicherheit} underline>angemessene Datensicherheit</Tooltip> gewährleistet (zB verschlüsselte Kommunikation) ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn angemessene Datensicherheit gewährleistet ist.</>
    },
    datenErhobenServiceRequired: {
      label: () => <>Ist die Erhebung der <Tooltip title={tooltips.de.PersonenbezogeneDaten} underline>personenbezogenen Daten</Tooltip> für die (vertragliche) Chatbot-Service-Erbringung notwendig ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn die Erhebung der personenbezogenen Daten für die (vertragliche) Chatbot-Service-Erbringung notwendig ist.</>
    },
    datenErhobenEinwilligung: {
      label: () => <>Wird von Ihrem Chatbot eine informierte (einschließlich Info über jederzeitige Widerruflichkeit), konkrete <Tooltip title={tooltips.de.Einwilligung} underline>Einwilligung</Tooltip> eingeholt ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot eine informierte, konkrete Einwilligung eingeholt wird.</>
    },
    datenErhobenDSE: {
      label: () => <>Wird darüber in der Datenschutzerklärung (DSE) transparent informiert?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn dies in der Datenschutzerklärung (DSE) abgedeckt ist.</>
    },
    datenAusgabe: {
      label: () => <>3.) Werden von Ihrem Chatbot <Tooltip title={tooltips.de.PersonenbezogeneDaten} underline>personenbezogene Daten</Tooltip> ausgegeben ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot personenbezogene Daten ausgegeben werden.</>
    },
    datenAusgabeSecurity: {
      label: () => <>Ist <Tooltip title={tooltips.de.AngemesseneDatensicherheit} underline>angemessene Datensicherheit</Tooltip> gewährleistet (zB verschlüsselte Kommunikation) ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn angemessene Datensicherheit gewährleistet ist.</>
    },
    datenAusgabeVerification: {
      label: () => <>Wird vor der Datenausgabe die <Tooltip title={tooltips.de.Identitaet} underline>Identität</Tooltip> des Abfragenden durch Ihren Chatbot verifiziert ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn avor der Datenausgabe die Identität des Abfragenden durch Ihren Chatbot verifiziert wird.</>
    },
    datenAusgabeServiceRequired: {
      label: () => <>Ist die Ausgabe der <Tooltip title={tooltips.de.PersonenbezogeneDaten} underline>personenbezogenen Daten</Tooltip> für die (vertragliche) Chatbot-Service-Erbringung notwendig ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn die Ausgabe der personenbezogenen Daten für die Service-Erbringung zwingend notwendig ist.</>
    },
    datenAusgabeEinwilligung: {
      label: () => <>Wird von Ihrem Chatbot eine informierte (einschließlich Info über jederzeitige Widerruflichkeit), konkrete <Tooltip title={tooltips.de.Einwilligung} underline>Einwilligung</Tooltip> eingeholt ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot eine informierte, konkrete Einwilligung eingeholt wird.</>
    },
    datenAusgabeDSE: {
      label: () => <>Wird darüber in der Datenschutzerklärung (DSE) transparent informiert ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn dies in der Datenschutzerklärung (DSE) abgedeckt ist.</>
    },
    datenAnalyse: {
      label: () => <>4.) Werden von Ihrem Chatbot <Tooltip title={tooltips.de.PersonenbezogeneDaten} underline>personenbezogene Daten</Tooltip> analysiert oder für <Tooltip title={tooltips.de.WeitereZwecke} underline>weitere Zwecke</Tooltip> verarbeitet ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot personenbezogene Daten analysiert oder für weitere Zwecke verarbeitet werden.</>
    },
    datenAnalyseSecurity: {
      label: () => <>Ist <Tooltip title={tooltips.de.AngemesseneDatensicherheit} underline>angemessene Datensicherheit</Tooltip> gewährleistet (zB verschlüsselte Kommunikation) ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn angemessene Datensicherheit gewährleistet ist.</>
    },
    datenAnalyseEinwilligung: {
      label: () => <>Wird von Ihrem Chatbot eine informierte (einschließlich Info über jederzeitige Widerruflichkeit), konkrete <Tooltip title={tooltips.de.Einwilligung} underline>Einwilligung</Tooltip> eingeholt ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot eine informierte, konkrete Einwilligung eingeholt wird.</>
    },
    datenAnalyseDSE: {
      label: () => <>Wird darüber in der Datenschutzerklärung (DSE) transparent informiert ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn dies in der Datenschutzerklärung (DSE) abgedeckt ist.</>
    },
    datenProfiling: {
      label: () => <>5.) Werden von Ihrem Chatbot Entscheidungen getroffen (zb durch <Tooltip title={tooltips.de.Profiling} underline>Profiling</Tooltip>), welche rechtliche Wirkung gegenüber den Nutzern entfaltet oder sie in ähnlicher Weise erheblich <Tooltip title={tooltips.de.Beeintraechtigt} underline>beeinträchtigt</Tooltip> ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot Entscheidungen wie in der Fragestellung getroffen werden.</>
    },
    datenProfilingContract: {
      label: () => <>Sind solche Entscheidungen für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und Ihnen bzw Ihrem Chatbot zwingend erforderlich ?</>
    },
    datenProfilingLegal: {
      label: () => <>Unterliegen Sie oder Ihr Chatbot <Tooltip title={tooltips.de.Rechtsvorschriften} underline>Rechtsvorschriften</Tooltip>, laut denen solche Entscheidungen zulässig sind ?</>,
    },
    datenProfilingEinwilligung: {
      label: () => <>Wird von Ihrem Chatbot eine informierte (einschließlich Info über jederzeitige Widerruflichkeit), konkrete <Tooltip title={tooltips.de.Einwilligung} underline>Einwilligung</Tooltip> eingeholt ?</>,
    },
    datenProfilingDSE: {
      label: () => <>Wird darüber in der Datenschutzerklärung (DSE) transparent informiert ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn dies in der Datenschutzerklärung (DSE) abgedeckt ist.</>
    },
    datenForward: {
      label: () => <>6.) Werden von Ihrem Chatbot <Tooltip title={tooltips.de.PersonenbezogeneDaten} underline>personenbezogene Daten</Tooltip> an Dritte übermittelt ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot personenbezogene Daten an Dritte übermittelt werden.</>
    },
    datenForwardAuftrag: {
      label: () => <>Geschieht dies ausschließlich zur Verarbeitung im Rahmen der obigen zulässigen Verarbeitung (<Tooltip title={tooltips.de.Auftragsverarbeitervertrag} underline>Auftragsverarbeitervertrag</Tooltip> innerhalb des EWR und datenschutzsicherer Drittstaaten) ?</>
    },
    datenForwardEinwilligung: {
      label: () => <>Wird von Ihrem Chatbot eine informierte (einschließlich Info über jederzeitige Widerruflichkeit), konkrete <Tooltip title={tooltips.de.Einwilligung} underline>Einwilligung</Tooltip> eingeholt ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot eine informierte, konkrete Einwilligung eingeholt wird.</>
    },
    datenForwardDSE: {
      label: () => <>Wird darüber in der Datenschutzerklärung (DSE) transparent informiert ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn dies in der Datenschutzerklärung (DSE) abgedeckt ist.</>
    },
    datenWerbung: {
      label: () => <>7.) Werden von Ihrem Chatbot <Tooltip title={tooltips.de.Werbenachrichten} underline>Werbenachrichten</Tooltip> (im weitesten Sinne) an Kunden gesendet?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot Werbenachrichten am Kunden gesendet werden.</>
    },
    datenWerbungEinwilligung: {
      label: () => <>Wird von Ihrem Chatbot eine informierte (einschließlich Info über jederzeitige Widerruflichkeit), konkrete <Tooltip title={tooltips.de.Einwilligung} underline>Einwilligung</Tooltip> eingeholt ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn von Ihrem Chatbot eine informierte, konkrete Einwilligung eingeholt wird.</>
    },
    datenWerbungDSE: {
      label: () => <>Wird darüber in der Datenschutzerklärung (DSE) transparent informiert ?</>,
      helperText: () => <>Aktivieren Sie diese Option, wenn dies in der Datenschutzerklärung (DSE) abgedeckt ist.</>
    }
  },
  en: {
    header: () => <>GDPR/DSGVO questions about <Tooltip title={tooltips.en.ResponsiblePerson} underline>your chatbot</Tooltip></>,
    dseExists: {
      label: () => <>1.) Does your chatbot have a Privacy Policy (PP) ?</>,
      helperText: () => <>Activate this option if your chatbot has a Privacy Policy (PP).</>
    },
    dseAvailable: {
      label: () => <>Is the Privacy Policy (PP) accessible before collecting personal data and at the earliest when using your chatbot ?</>,
      helperText: () => <>Activate this option if the Privacy Policy (PP) is accessible according to the question.</>
    },
    dseComplete: {
      label: () => <>Does the Privacy Policy (PP) contain all information required by the GDPR ?</>,
      helperText: () => <>Activate this option if the Privacy Policy (PP) contains all information according to GDPR, Art. 13, including letter f.</>
    },
    dseExistsKontaktdaten: {
      label: () => <>The PP contains the name and contact details of the <Tooltip title={tooltips.en.ResponsiblePerson} underline>Responsible</Tooltip>.</>
    },
    dseExistsZwecke: {
      label: () => <>The PP contains the <Tooltip title={tooltips.en.Purpose} underline>Purposes</Tooltip> for which the Personal Data is to be processed and the <Tooltip title={tooltips.en.PurposeFulfillment} underline>Legal basis</Tooltip> for the processing.</>
    },
    dseExistsEmpfaenger: {
      label: () => <>The PP contains the <Tooltip title={tooltips.en.Recipient} underline>recipients</Tooltip> of the personal data.</>
    },
    dseExistsDauer: {
      label: () => <>The PP contains the <Tooltip title={tooltips.en.Duration} underline>duration</Tooltip> for which the personal data will be stored or, if this is not possible, the criteria for the determination this duration.</>
    },
    dseExistsAndere: {
      label: () => <>The PP contains all other information according to GDPR, Art. 13, letter f (see below).</>
    },    
    datenErhoben: {
      label: () => <>2.) Does your chatbot collect <Tooltip title={tooltips.en.PersonalData} underline>Personal Data</Tooltip> other than IP address ?</>,
      helperText: () => <>Activate this option if other personal data is collected from your chatbot in addition to the IP address.</>
    },
    datenErhobenSecurity: {
      label: () => <>Is <Tooltip title={tooltips.en.AdequateDataSecurity} underline>adequate data security</Tooltip> guaranteed (e.g. encrypted communication) ?</>,
      helperText: () => <>Activate this option if adequate data security is guaranteed.</>
    },
    datenErhobenServiceRequired: {
      label: () => <>Is it necessary to collect <Tooltip title={tooltips.en.PersonalData} underline>personal data</Tooltip> for the (contractual) chatbot service provision ?</>,
      helperText: () => <>Activate this option if the collection of personal data is necessary for the (contractual) provision of the chatbot service.</>
    },
    datenErhobenEinwilligung: {
      label: () => <>Does your chatbot obtain informed (including revocability information), specific <Tooltip title={tooltips.en.Consent} underline>consent</Tooltip> ?</>,
      helperText: () => <>Enable this option if informed, specific consent is sought from your chatbot.</>
    },
    datenErhobenDSE: {
      label: () => <>Is this transparently informed in the Privacy Policy (PP) ?</>,
      helperText: () => <>Check this option if covered in the Privacy Policy (PP).</>
    },
    datenAusgabe: {
      label: () => <>3.) Does your chatbot give out <Tooltip title={tooltips.en.PersonalData} underline>Personal Data</Tooltip> ?</>,
      helperText: () => <>Activate this option if your chatbot outputs personal data.</>
    },
    datenAusgabeSecurity: {
      label: () => <>Is <Tooltip title={tooltips.en.AdequateDataSecurity} underline>adequate data security</Tooltip> guaranteed (e.g. encrypted communication) ?</>,
      helperText: () => <>Activate this option if adequate data security is guaranteed.</>
    },
    datenAusgabeVerification: {
      label: () => <>Does your chatbot verify the <Tooltip title={tooltips.en.Identity} underline>identity</Tooltip> of the querent before outputting data ?</>,
      helperText: () => <>Activate this option if the identity of the queryer is verified by your chatbot before the data is output.</>
    },
    datenAusgabeServiceRequired: {
      label: () => <>Is the output of <Tooltip title={tooltips.en.PersonalData} underline>personal data</Tooltip> necessary for the (contractual) chatbot service provision ?</>,
      helperText: () => <>Activate this option if the output of personal data is absolutely necessary for the provision of the service.</>
    },
    datenAusgabeEinwilligung: {
      label: () => <>Does your chatbot obtain informed (including revocability information), specific <Tooltip title={tooltips.en.Consent} underline>consent</Tooltip> ?</>,
      helperText: () => <>Enable this option if informed, specific consent is sought from your chatbot.</>
    },
    datenAusgabeDSE: {
      label: () => <>Is this transparently informed in the Privacy Policy (PP) ?</>,
      helperText: () => <>Check this option if covered in the Privacy Policy (PP).</>
    },
    datenAnalyse: {
      label: () => <>4.) Does your chatbot analyze <Tooltip title={tooltips.en.PersonalData} underline>Personal Data</Tooltip> or process it for <Tooltip title={tooltips.en.OtherPurposes} underline>Other Purposes</Tooltip> ?</>,
      helperText: () => <>Activate this option if personal data is analyzed by your chatbot or processed for other purposes.</>
    },
    datenAnalyseSecurity: {
      label: () => <>Is <Tooltip title={tooltips.en.AdequateDataSecurity} underline>Adequate data security</Tooltip> guaranteed (e.g. encrypted communication) ?</>,
      helperText: () => <>Check this option if covered in the Privacy Policy (PP).</>
    },
    datenAnalyseEinwilligung: {
      label: () => <>Does your chatbot obtain informed (including revocability information), specific <Tooltip title={tooltips.en.Consent} underline>consent</Tooltip> ?</>,
      helperText: () => <>Enable this option if informed, specific consent is sought from your chatbot.</>
    },
    datenAnalyseDSE: {
      label: () => <>Is this transparently informed in the Privacy Policy (PP) ?</>,
      helperText: () => <>Check this option if covered in the Privacy Policy (PP).</>
    },
    datenProfiling: {
      label: () => <>5.) Are decisions (e.g. by <Tooltip title={tooltips.en.Profiling} underline>Profiling</Tooltip>) which have legal effect on the users or in a similar way significantly made by your chatbot <Tooltip title={tooltips.en.Affected} underline>degraded</Tooltip> ?</>,
      helperText: () => <>Activate this option if your chatbot makes decisions as mentioned the question.</>
    },
    datenProfilingContract: {
      label: () => <>Are such decisions mandatory for entering into, or the performance of, a contract between the data subject and you or your chatbot ?</>
    },
    datenProfilingLegal: {
      label: () => <>Are you or your chatbot subject to <Tooltip title={tooltips.en.Legislation} underline>legislation</Tooltip> that allows such decisions ?</>,
    },
    datenProfilingEinwilligung: {
      label: () => <>Does your chatbot obtain informed (including revocability information), specific <Tooltip title={tooltips.en.Consent} underline>consent</Tooltip> ?</>
    },
    datenProfilingDSE: {
      label: () => <>Is this transparently informed in the Privacy Policy (PP) ?</>,
      helperText: () => <>Check this option if covered in the Privacy Policy (PP).</>
    },
    datenForward: {
      label: () => <>6.) Does your chatbot transmit <Tooltip title={tooltips.en.PersonalData} underline>Personal Data</Tooltip> to third parties ?</>,
      helperText: () => <>Activate this option if your chatbot transmits personal data to third parties.</>
    },
    datenForwardAuftrag: {
      label: () => <>Is this only for processing within the scope of the above permitted processing (<Tooltip title={tooltips.en.ProcessorAgreement} underline>processor contract</Tooltip> within the EEA and data protection-safe third countries) ?</>
    },
    datenForwardEinwilligung: {
      label: () => <>Does your chatbot obtain informed (including revocability information), specific <Tooltip title={tooltips.en.Consent} underline>consent</Tooltip> ?</>,
      helperText: () => <>Enable this option if informed, specific consent is sought from your chatbot.</>
    },
    datenForwardDSE: {
      label: () => <>Is this transparently informed in the Privacy Policy (PP) ?</>,
      helperText: () => <>Check this option if covered in the Privacy Policy (PP).</>
    },
    datenWerbung: {
      label: () => <>7.) Does your chatbot send <Tooltip title={tooltips.en.PromotionalNews} underline>business messages</Tooltip> (in the broadest sense) to customers ?</>,
      helperText: () => <>Enable this option if your chatbot sends promotional messages to customers.</>
    },
    datenWerbungEinwilligung: {
      label: () => <>Does your chatbot obtain informed (including revocability information), specific <Tooltip title={tooltips.en.Consent} underline>consent</Tooltip> ?</>,
      helperText: () => <>Enable this option if informed, specific consent is sought from your chatbot.</>
    },
    datenWerbungDSE: {
      label: () => <>Is this transparently informed in the Privacy Policy (PP) ?</>,
      helperText: () => <>Check this option if covered in the Privacy Policy (PP).</>
    }
  }
}

class GDPRSessionAudit extends React.Component {
  render() {
    const { testSession, setAlertSuccessMessage, setAlertErrorMessage } = this.props

    if (!testSession) return null
    if (!testSession.gdprTestSessionAudit) return null
    
    if (!auditQuestions[testSession.gdprTestSessionAudit.language]) {
      return <Text warning>No Questionaire available</Text>
    }
    const formValues = testSession.gdprTestSessionAudit.formValues ? JSON.parse(testSession.gdprTestSessionAudit.formValues) : {} 
    const questions = auditQuestions[testSession.gdprTestSessionAudit.language]

    return (<Mutation
      mutation={UPDATE_TESTSESSION_GDPRAUDIT}
      refetchQueries={() => {
        return [
          ...RefetchTestSessionQueries(testSession.id)
        ]
      }}
    >
      {(updateTestSessionGdprAudit) => (<Form
        onSubmit={async (values, form) => {
          try {
            const issueCount = getIssueCount(values)

            await updateTestSessionGdprAudit({
              variables: {
                id: testSession.id,
                formValues: JSON.stringify(values),
                status: issueCount === 0 ? 'READY' : 'FAILED',
                language: testSession.gdprTestSessionAudit.language,
                issueCount
              }
            })
            form.initialize(values)
            setAlertSuccessMessage('Questionaire Saved')
          } catch (error) {
            setAlertErrorMessage(`Saving Questionaire failed`, error)
          }
        }}
        initialValues={formValues}
        render={({ handleSubmit, submitting, invalid, values }) => (
          <form onSubmit={handleSubmit}>
            <UnsavedFormSpy />
            <GridContainer>
              <GridItem xs={12} largePadding>
                <Text header>{React.createElement(questions.header)}</Text>
              </GridItem>
              <GridItem xs={12}>
                <ExpansionPanel expanded={!!values.dseExists}>
                  <ExpansionPanelSummary expandIcon={null}>
                    <Field dense
                      name="dseExists"
                      component={renderCheckbox}
                      label={React.createElement(questions.dseExists.label)}
                      type="checkbox"
                      data-unique="chkTestSetGdprDseExists"
                      helperText={React.createElement(questions.dseExists.helperText)}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GridContainer>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="dseAvailable"
                          component={renderCheckbox}
                          label={React.createElement(questions.dseAvailable.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDseAvailable"
                          helperText={React.createElement(questions.dseAvailable.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="dseComplete"
                          component={renderCheckbox}
                          label={React.createElement(questions.dseComplete.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDseComplete"
                          helperText={React.createElement(questions.dseComplete.helperText)}
                        />
                      </GridItem>
                      {values.dseExists && values.dseComplete && <>
                        <GridItem xs={1}></GridItem>
                        <GridItem xs={11} grey>
                          <GridContainer>
                            <GridItem xs={12}>
                              <Field dense
                                name="dseExistsKontaktdaten"
                                component={renderCheckbox}
                                label={React.createElement(questions.dseExistsKontaktdaten.label)}
                                type="checkbox"
                                data-unique="chkTestSetGdprDseExistsKontaktdaten"
                              />
                            </GridItem>
                            <GridItem xs={12}>
                              <Field dense
                                name="dseExistsZwecke"
                                component={renderCheckbox}
                                label={React.createElement(questions.dseExistsZwecke.label)}
                                type="checkbox"
                                data-unique="chkTestSetGdprDseExistsZwecke"
                              />
                            </GridItem>
                            <GridItem xs={12}>
                              <Field dense
                                name="dseExistsEmpfaenger"
                                component={renderCheckbox}
                                label={React.createElement(questions.dseExistsEmpfaenger.label)}
                                type="checkbox"
                                data-unique="chkTestSetGdprDseEmpfaenger"
                              />
                            </GridItem>
                            <GridItem xs={12}>
                              <Field dense
                                name="dseExistsDauer"
                                component={renderCheckbox}
                                label={React.createElement(questions.dseExistsDauer.label)}
                                type="checkbox"
                                data-unique="chkTestSetGdprDseDauer"
                              />
                            </GridItem>
                            <GridItem xs={12}>
                              <Field dense
                                name="dseExistsAndere"
                                component={renderCheckbox}
                                label={React.createElement(questions.dseExistsAndere.label)}
                                type="checkbox"
                                data-unique="chkTestSetGdprDseAndere"
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </>}
                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </GridItem>
              {/* Datenerhebung  */}
              {values.dseExists && <GridItem xs={12}>
                <ExpansionPanel expanded={!!values.datenErhoben}>
                  <ExpansionPanelSummary expandIcon={null}>
                    <Field dense
                      name="datenErhoben"
                      component={renderCheckbox}
                      label={React.createElement(questions.datenErhoben.label)}
                      type="checkbox"
                      data-unique="chkTestSetGdprDatenErhoben"
                      helperText={React.createElement(questions.datenErhoben.helperText)}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GridContainer>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenErhobenSecurity"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenErhobenSecurity.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenErhobenSecurity"
                          helperText={React.createElement(questions.datenErhobenSecurity.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenErhobenServiceRequired"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenErhobenServiceRequired.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenErhobenServiceRequired"
                          helperText={React.createElement(questions.datenErhobenServiceRequired.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenErhobenEinwilligung"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenErhobenEinwilligung.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenErhobenEinwilligung"
                          helperText={React.createElement(questions.datenErhobenEinwilligung.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenErhobenDSE"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenErhobenDSE.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenErhobenDse"
                          helperText={React.createElement(questions.datenErhobenDSE.helperText)}
                        />
                      </GridItem>
                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </GridItem>}
              {/* Datenausgabe */}
              {values.dseExists && <GridItem xs={12}>
                <ExpansionPanel expanded={!!values.datenAusgabe}>
                  <ExpansionPanelSummary expandIcon={null}>
                    <Field dense
                      name="datenAusgabe"
                      component={renderCheckbox}
                      label={React.createElement(questions.datenAusgabe.label)}
                      type="checkbox"
                      data-unique="chkTestSetGdprDatenAusgabe"
                      helperText={React.createElement(questions.datenAusgabe.helperText)}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GridContainer>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenAusgabeSecurity"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenAusgabeSecurity.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenAusgabeSecurity"
                          helperText={React.createElement(questions.datenAusgabeSecurity.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenAusgabeVerification"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenAusgabeVerification.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenAusgabeVerification"
                          helperText={React.createElement(questions.datenAusgabeVerification.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenAusgabeServiceRequired"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenAusgabeServiceRequired.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenAusgabeServiceRequired"
                          helperText={React.createElement(questions.datenAusgabeServiceRequired.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenAusgabeEinwilligung"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenAusgabeEinwilligung.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenAusgabeEinwilligung"
                          helperText={React.createElement(questions.datenAusgabeEinwilligung.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenAusgabeDSE"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenAusgabeDSE.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenAusgabeDse"
                          helperText={React.createElement(questions.datenAusgabeDSE.helperText)}
                        />
                      </GridItem>                    
                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </GridItem>}
              {/* Datenanalyse */}
              {values.dseExists && <GridItem xs={12}>
                <ExpansionPanel expanded={!!values.datenAnalyse}>
                  <ExpansionPanelSummary expandIcon={null}>
                    <Field dense
                      name="datenAnalyse"
                      component={renderCheckbox}
                      label={React.createElement(questions.datenAnalyse.label)}
                      type="checkbox"
                      data-unique="chkTestSetGdprDatenAnalyse"
                      helperText={React.createElement(questions.datenAnalyse.helperText)}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GridContainer>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenAnalyseSecurity"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenAnalyseSecurity.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenAnalyseSecurity"
                          helperText={React.createElement(questions.datenAnalyseSecurity.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenAnalyseEinwilligung"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenAnalyseEinwilligung.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenAnalyseEinwilligung"
                          helperText={React.createElement(questions.datenAnalyseEinwilligung.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenAnalyseDSE"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenAnalyseDSE.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenAnalyseDse"
                          helperText={React.createElement(questions.datenAnalyseDSE.helperText)}
                        />
                      </GridItem>
                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </GridItem>}
              {/* Profiling */}
              {values.dseExists && <GridItem xs={12}>
                <ExpansionPanel expanded={!!values.datenProfiling}>
                  <ExpansionPanelSummary expandIcon={null}>
                    <Field dense
                      name="datenProfiling"
                      component={renderCheckbox}
                      label={React.createElement(questions.datenProfiling.label)}
                      type="checkbox"
                      data-unique="chkTestSetGdprDatenProfiling"
                      helperText={React.createElement(questions.datenProfiling.helperText)}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GridContainer>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenProfilingContract"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenProfilingContract.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenProfilingContract"
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenProfilingLegal"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenProfilingLegal.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenProfilingLegal"
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenProfilingEinwilligung"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenProfilingEinwilligung.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenProfilingEinwilligung"
                        />
                      </GridItem>                    
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenProfilingDSE"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenProfilingDSE.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenProfilingDse"
                          helperText={React.createElement(questions.datenProfilingDSE.helperText)}
                        />
                      </GridItem>                    
                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </GridItem>}
              {/* Datenweitergabe */}
              {values.dseExists && <GridItem xs={12}>
                <ExpansionPanel expanded={!!values.datenForward}>
                  <ExpansionPanelSummary expandIcon={null}>
                    <Field dense
                      name="datenForward"
                      component={renderCheckbox}
                      label={React.createElement(questions.datenForward.label)}
                      type="checkbox"
                      data-unique="chkTestSetGdprDatenForward"
                      helperText={React.createElement(questions.datenForward.helperText)}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GridContainer>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenForwardAuftrag"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenForwardAuftrag.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenForwardAuftrag"
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenForwardEinwilligung"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenForwardEinwilligung.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenForwardEinwilligung"
                          helperText={React.createElement(questions.datenForwardEinwilligung.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenForwardDSE"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenForwardDSE.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenForwardDse"
                          helperText={React.createElement(questions.datenForwardDSE.helperText)}
                        />
                      </GridItem>                                        
                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </GridItem>}
              {/* Werbung */}
              {values.dseExists && <GridItem xs={12}>
                <ExpansionPanel expanded={!!values.datenWerbung}>
                  <ExpansionPanelSummary expandIcon={null}>
                    <Field dense
                      name="datenWerbung"
                      component={renderCheckbox}
                      label={React.createElement(questions.datenWerbung.label)}
                      type="checkbox"
                      data-unique="chkTestSetGdprDatenWerbung"
                      helperText={React.createElement(questions.datenWerbung.helperText)}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GridContainer>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenWerbungEinwilligung"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenWerbungEinwilligung.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenWerbungEinwilligung"
                          helperText={React.createElement(questions.datenWerbungEinwilligung.helperText)}
                        />
                      </GridItem>
                      <GridItem xs={1}></GridItem>
                      <GridItem xs={11}>
                        <Field dense
                          name="datenWerbungDSE"
                          component={renderCheckbox}
                          label={React.createElement(questions.datenWerbungDSE.label)}
                          type="checkbox"
                          data-unique="chkTestSetGdprDatenWerbungDse"
                          helperText={React.createElement(questions.datenWerbungDSE.helperText)}
                        />
                      </GridItem>                    
                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </GridItem>}
              <GridItem xs={12} largePadding>
                <FormActionsToolbar rightButtons={
                  <Button
                    type="submit"
                    disabled={submitting || invalid}
                    data-unique="btnTestSetGdprSave"
                  >
                    {submitting && <><LoadingIndicator alt /> Saving ...</>}
                    {!submitting && <><ShowIcon icon="save" /> Save</>}
                  </Button>
                } />
              </GridItem>
              <GridItem xs={12}>
                <Card>
                  <CardBody>
                    <GDPRSessionSuggestions testSession={testSession} formValues={values} />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </form>
        )}
      />)}
    </Mutation>)
  }
}

export default compose(
  withStyles(testsessionsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  )
)(GDPRSessionAudit)
