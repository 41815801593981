import React from 'react'

import CompareIntentBase from './CompareIntentBase'

export default class CompareUtterancesAll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {match, history} = this.props
    return (
      <CompareIntentBase fieldToProcess="all" match={match} history={history} filter={(entry) => {
        if (match.params.intentName) {
          return entry.primary && entry.primary.intent && entry.primary.intent.expected  === match.params.intentName
        }
        return true
      }}/>
    )
  }
}