import React from 'react'
import _ from 'lodash'
import config from 'config'
import { connect } from 'react-redux'
import copyToClipboard from 'copy-to-clipboard'
// apollo
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
// @material-ui/core components
import AddIcon from '@material-ui/icons/Add'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { FieldArray } from 'react-final-form-arrays'
import { OnChange } from 'react-final-form-listeners'
// core components
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/Button/Button'
import PopupInformationButton from 'components/Button/PopupInformationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import ShowIcon from 'components/Icon/ShowIcon'
import MediaSelectionDialog from 'components/Dialog/MediaSelectionDialog.jsx'
import {
  renderTextField,
  renderTextArea,
  renderPasswordField,
  renderIntField,
  renderCheckbox,
  renderCodeArea,
  renderSelect,
  required,
  json,
  url,
  mustBeNumber,
  composeValidators,
  parseInteger,
  prettyPrintJson,
  CustomRenderField,
  CustomCodeArea
} from 'components/Form/Form'
import FileSelectorField from 'components/Form/FileSelectorField'

import { extractErrorMessage } from 'helper/graphHelper'
import { APIKEYS_QUERY, SPEECHSYNTHESISPROFILES_QUERY, SPEECHRECOGNITIONPROFILES_QUERY } from '../../views/Settings/gql'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { setAlertSuccessMessage } from '../../actions/alert'

export const RUNCONNECTORQUERY_QUERY = gql`
  query RunConnectorQuery(
    $connectorName: String!
    $field: String!
    $caps: String
  ) {
    runconnectorquery(
      connectorName: $connectorName
      field: $field
      caps: $caps
    ) {
      err
      choices { key name description }
    }
  }
`

export const RUNCONNECTORACTION_QUERY = gql`
  query RunConnectorAction(
    $id: ID
    $connectorName: String!
    $action: String!
    $caps: String
    $args: String
  ) {
    runconnectoraction(
      id: $id
      connectorName: $connectorName
      action: $action
      caps: $caps
      args: $args
    ) {
      found
      err
      result
    }
  }
`

export const renderConnectorQuery = ({ cap, disabled, index, _capSetDescription }, { loading, error, data }) => {
  const err = error ? extractErrorMessage(error) : (data && data.runconnectorquery && data.runconnectorquery.err)
  const choices = data && data.runconnectorquery && data.runconnectorquery.choices

  if (loading || err || !choices || choices.length === 0) {
    let endAdornment = null
    if (loading) endAdornment = <LoadingIndicator />
    else if (err) endAdornment = <Tooltip title={err}><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>
    else endAdornment = <Tooltip title="No option found for selection, please enter value manually."><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>

    return (
      <Field
        name={`descriptor.${cap.name}`}
        component={renderTextField}
        label={cap.label}
        validate={cap.required && required}
        parse={v => v}
        disabled={disabled}
        data-unique={`txtConnectorEditCapabilityString_${index}`}
        endAdornment={endAdornment}
        helperText={cap.description ? _capSetDescription(cap) : 'Please enter value'}
      />
    )
  } else {
    return (
      <Field
        name={`descriptor.${cap.name}`}
        component={renderSelect}
        label={cap.label}
        validate={cap.required && required}
        disabled={disabled}
        data-unique={`selConnectorEditQuery_${index}`}
        items={choices.map(a => {
          return { key: a.key, label: a.name }
        })}
        helperText={cap.description ? _capSetDescription(cap) : 'Please select'}
      />
    )
  }
}

export const renderSpeechRecognitionSelector = ({ features, cap = {}, fieldName, disabled, dataUnique, helperText }) => {
  if (features && features.speechService) {
    return <Query
      fetchPolicy="network-only"
      query={SPEECHRECOGNITIONPROFILES_QUERY}
    >
      {({ data }) => {
        const choices = (data && data.speechRecognitionProfiles) || []
        return (
          <Field
            name={fieldName || `descriptor.${cap.name}`}
            component={renderSelect}
            label={cap.label || 'Speech Recognition Profile'}
            validate={cap.required && required}
            disabled={disabled}
            data-unique={dataUnique || fieldName || cap.name}
            items={choices.map(a => {
              return { key: a.id, label: a.name }
            })}
            helperText={helperText || cap.description || 'Please select'}
          />
        )
      }}
    </Query>
  } else {
    return (
      <Field
        name={`${fieldName || `descriptor.${cap.name}`}_disabled`}
        component={renderTextField}
        label={cap.label || 'Speech Recognition Profile'}
        validate={cap.required && required}
        disabled
        readOnly
        data-unique={dataUnique || fieldName || cap.name}
        endAdornment={<Tooltip title="Speech Service not available"><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>}
        helperText={cap.description || ''}
      />
    )
  }
}

export const renderSpeechSynthesisSelector = ({ features, cap = {}, fieldName, disabled, dataUnique, helperText }) => {
  if (features && features.speechService) {
    return <Query
      fetchPolicy="network-only"
      query={SPEECHSYNTHESISPROFILES_QUERY}
    >
      {({ data }) => {
        const choices = (data && data.speechSynthesisProfiles) || []
        return (
          <Field
            name={fieldName || `descriptor.${cap.name}`}
            component={renderSelect}
            label={cap.label || 'Speech Synthesis Profile'}
            validate={cap.required && required}
            disabled={disabled}
            data-unique={dataUnique || fieldName || cap.name}
            items={choices.map(a => {
              return { key: a.id, label: a.name }
            })}
            helperText={helperText || cap.description || 'Please select'}
          />
        )
      }}
    </Query>
  } else {
    return (
      <Field
        name={`${fieldName || `descriptor.${cap.name}`}_disabled`}
        component={renderTextField}
        label={cap.label || 'Speech Synthesis Profile'}
        validate={cap.required && required}
        disabled
        readOnly
        data-unique={dataUnique || fieldName || cap.name}
        endAdornment={<Tooltip title="Speech Service not available"><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>}
        helperText={cap.description || ''}
      />
    )
  }
}

export function connectorCaps2Form(caps) {
  const result = {
    descriptor: {}
  }
  for (const cap of caps) {
    if (cap.type === 'STRING' || cap.type === 'TEXT' || cap.type === 'JS') result.descriptor[cap.name] = cap.stringValue
    if (cap.type === 'INT') result.descriptor[cap.name] = cap.intValue
    if (cap.type === 'BOOLEAN') result.descriptor[cap.name] = cap.booleanValue
    if (cap.type === 'JSON') {
      result.descriptor[cap.name] = cap.jsonValue || cap.stringValue
      result.descriptor[`${cap.name}_dict`] = []
      if (result.descriptor[cap.name]) {
        try {
          const dict = JSON.parse(result.descriptor[cap.name])
          for (const [key, value] of Object.entries(dict)) {
            result.descriptor[`${cap.name}_dict`].push({ key, value })
          }
        } catch (err) {
        }
      }
    }
  }
  return result
}

export function connectorForm2caps(values) {
  const result = []
  for (const capName of Object.keys(values.descriptor)) {
    if (capName === 'CONTAINERMODE') continue

    if (capName.endsWith('_dict')) continue

    const capValue = values.descriptor[capName]
    if (_.isNil(capValue)) {
      result.push({
        name: capName,
        type: 'STRING',
        stringValue: null
      })
    } else if (_.isInteger(capValue)) {
      result.push({
        name: capName,
        type: 'INT',
        intValue: capValue
      })
    } else if (_.isBoolean(capValue)) {
      result.push({
        name: capName,
        type: 'BOOLEAN',
        booleanValue: capValue
      })
    } else if (!_.isString(capValue)) {
      result.push({
        name: capName,
        type: 'JSON',
        jsonValue: JSON.stringify(capValue)
      })
    } else {
      result.push({
        name: capName,
        type: 'STRING',
        stringValue: `${capValue}`
      })
    }
  }
  return result
}

const setDictValue = (change, values, capName) => {
  const entries = values.descriptor[`${capName}_dict`]
  const dict = {}
  if (entries && entries.length > 0) {
    for (const entry of entries) {
      if (entry.key) {
        dict[entry.key] = entry.value || ''
      }
    }
  }
  change(`descriptor.${capName}`, dict)
}

class ConnectorEditInternal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getQueryCaps(values, exclude = []) {
    const { connectorDesc } = this.props
    if (!values || !values.descriptor) return {}

    const result = connectorDesc.capabilities.reduce((caps, cap) => {
      if (exclude.includes(cap.name)) {
        return caps
      }
      if (values.descriptor[cap.name]) {
        caps[cap.name] = values.descriptor[cap.name]
      }
      return caps
    }, {})
    return result
  }

  render() {
    const { connectorDesc, advanced, disabled, features, setAlertSuccessMessage, capSetCapNames } = this.props

    const _showCapability = cap => advanced ? true : _.isBoolean(cap.advanced) ? !cap.advanced : cap.required

    const _capTypeMdWidth = (type) => {
      if (type === 'json' || type === 'text' || type === 'dictionary') return 12
      else return 6
    }

    const _usedByCapabilitySet = (capName) => { return capSetCapNames && capSetCapNames.includes(capName) }

    const _capSetDescription = cap => (_usedByCapabilitySet(cap.name)) ? <><Text bold inline>Capability set by Capability Set{!_.isNil(cap.description) ? ' | ' : ''}</Text>{cap.description}</> : cap.description

    return (<FormSpy subscription={{ form: true, values: true }} render={({ form: { change }, values }) => (<GridContainer>
      {connectorDesc.helperText && <GridItem xs={12}>
        <Text isHtml>{connectorDesc.helperText}</Text>
      </GridItem>}
      {connectorDesc.capabilities.filter(_showCapability).length === 0 && <GridItem xs={12}><Text>Nothing to configure.</Text></GridItem>}
      {connectorDesc.capabilities.map((cap, index) => {
        if (_showCapability(cap)) {
          return (<GridItem xs={12} sm={6} md={_capTypeMdWidth(cap.type)} key={index}>
            {cap.type === 'string' &&
              <Field
                name={`descriptor.${cap.name}`}
                component={renderTextField}
                label={cap.label}
                helperText={_capSetDescription(cap)}
                validate={cap.required && required}
                parse={v => v}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                data-unique={`txtConnectorEditCapabilityString_${index}`}
              />
            }
            {cap.type === 'text' &&
              <Field
                name={`descriptor.${cap.name}`}
                component={renderTextArea}
                rows={7}
                label={cap.label}
                helperText={_capSetDescription(cap)}
                validate={cap.required && required}
                parse={v => v}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                data-unique={`txtConnectorEditCapabilityText_${index}`}
              />
            }
            {cap.type === 'secret' &&
              <Field
                name={`descriptor.${cap.name}`}
                component={renderPasswordField}
                label={cap.label}
                helperText={_capSetDescription(cap)}
                validate={cap.required && required}
                parse={v => v}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                data-unique={`pwConnectorEditCapabilitySecret_${index}`}
              />
            }
            {cap.type === 'url' &&
              <Field
                name={`descriptor.${cap.name}`}
                component={renderTextField}
                label={cap.label}
                helperText={_capSetDescription(cap)}
                validate={composeValidators(cap.required && required, url)}
                parse={v => v}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                data-unique={`txtConnectorEditCapabilityUrl_${index}`}
              />
            }
            {cap.type === 'file' && <>
              <Field
                name={`descriptor.${cap.name}`}
                component={renderTextField}
                label={cap.label}
                helperText={_capSetDescription(cap)}
                validate={cap.required && required}
                parse={v => v}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                data-unique={`txtConnectorEditCapabilityFile_${index}`}
                endAdornment={<>
                  <Button justIcon dense data-unique={`btnConnectorEditCapabilityFile_${index}_Clear`} onClick={() => change(`descriptor.${cap.name}`, '')}>
                    <ShowIcon icon="times" />
                  </Button>
                  <Button justIcon dense data-unique={`btnConnectorEditCapabilityFile_${index}_Dialog`} onClick={() => this.setState({ [`descriptor.${cap.name}.showDialog`]: true  })}>
                    <ShowIcon icon="folder" />
                  </Button>
                </>}
              />
              <MediaSelectionDialog allowFileSelection
                open={!!this.state[`descriptor.${cap.name}.showDialog`]}
                onCancel={() => this.setState({ [`descriptor.${cap.name}.showDialog`]: false })}
                onOk={async ({ selectedFiles }) => {
                  if (selectedFiles.length > 0) {
                    change(`descriptor.${cap.name}`, selectedFiles[0].join('/'))
                  }
                  this.setState({ [`descriptor.${cap.name}.showDialog`]: false })
                }}
                title="Select File"
              />              
            </>}
            {cap.type === 'inboundurl' &&
              <GridContainer>
                <GridItem xs={12}>
                  <Query query={APIKEYS_QUERY}>
                    {({ loading, error, data }) => {
                      return <Field
                        name="connectoredit.apikey"
                        component={renderSelect}
                        label="Select an API Key for the Inbound Url"
                        helperText={_capSetDescription(cap)}
                        disabled={disabled || _usedByCapabilitySet(cap.name)}
                        data-unique={`selConnectorEditCapabilityInboundUrlApiKey_${index}`}
                        error={error}
                        loading={loading}
                        items={data && data.apikeys && data.apikeys.map(a => {
                          return { key: a.key, label: a.name }
                        })}
                      />
                    }}
                  </Query>
                  <FormSpy subscription={{ form: true }} render={({ form: { change } }) => (
                    <OnChange name="connectoredit.apikey">
                      {(value, previous) => {
                        if (value) {
                          change(`descriptor.${cap.name}`, `${config.api.ext}/inboundb/${value}`)
                        }
                      }}
                    </OnChange>
                  )} />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    readOnly
                    name={`descriptor.${cap.name}`}
                    component={renderTextField}
                    label={cap.label}
                    helperText={_capSetDescription(cap)}
                    validate={composeValidators(cap.required && required, url)}
                    disabled={disabled || _usedByCapabilitySet(cap.name)}
                    data-unique={`txtConnectorEditCapabilityInboundUrl_${index}`}
                    endAdornment={
                      <Button
                        onClick={() => {
                          copyToClipboard(values.descriptor[cap.name])
                          setAlertSuccessMessage(`${cap.label} copied to the clipboard`)
                        }}
                        justIcon
                        data-unique={`btnConnectorEditCapabilityInboundUrlCopy_${index}`}
                      >
                        <FileCopyIcon />
                      </Button>
                    }
                  />
                </GridItem>
              </GridContainer>
            }
            {cap.type === 'int' &&
              <Field
                name={`descriptor.${cap.name}`}
                component={renderIntField}
                label={cap.label}
                parse={parseInteger}
                validate={composeValidators(cap.required && required, mustBeNumber)}
                helperText={_capSetDescription(cap)}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                data-unique={`intConnectorEditCapabilityInt_${index}`}
              />
            }
            {cap.type === 'boolean' &&
              <Field
                name={`descriptor.${cap.name}`}
                component={renderCheckbox}
                label={cap.label}
                type="checkbox"
                helperText={_capSetDescription(cap)}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                data-unique={`chkConnectorEditBoolean_${index}`}
              />
            }
            {cap.type === 'json' &&
              <Field
                name={`descriptor.${cap.name}`}
                component={renderCodeArea}
                options={{ mode: 'application/json' }}
                label={cap.label}
                className="CapabilitiesShort"
                codeFormat={prettyPrintJson}
                helperText={_capSetDescription(cap)}
                parse={v => v}
                validate={composeValidators(cap.required && required, json)}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                data-unique={`codeConnectorEditJson_${index}`}
              />
            }
            {cap.type === 'javascript' &&
              <FileSelectorField
                name={`descriptor.${cap.name}`}
                change={change}
                label={cap.label}
                validate={cap.required && required}
                data-unique={`codeConnectorEditJavascript_${index}`}
                {...(cap.sampleHeader ? {
                  endAdornment: <>
                    <Tooltip title={cap.sampleHeader}>
                      <PopupInformationButton
                        justIcon
                        dense
                        data-unique={`codeConnectorEditJavascript_${index}_code`}
                        popupTitle={cap.sampleHeader}
                        popupBody={<GridContainer>
                          {cap.sampleDescription &&
                            <GridItem xs={12}>
                              {cap.sampleDescription}
                            </GridItem>
                          }
                          {cap.sampleBody &&
                            <GridItem xs={12}>
                              <CustomCodeArea
                                label="Sample Javascript Code"
                                options={{ mode: 'application/javascript' }}
                                input={{
                                  value: cap.sampleBody,
                                  disabled: true,
                                }}
                              />
                            </GridItem>
                          }
                        </GridContainer>}
                      >
                        <ShowIcon icon="file-alt" />
                      </PopupInformationButton>
                    </Tooltip>
                  </>
                } : {})}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                helperText={_capSetDescription(cap)}
                initialPath="resources/scripts"
                extensionFilter={['.js']}
                restrictPath
              />
            }
            {cap.type === 'choice' &&
              <Field
                name={`descriptor.${cap.name}`}
                component={renderSelect}
                label={cap.label}
                helperText={_capSetDescription(cap)}
                validate={cap.required && required}
                disabled={disabled || _usedByCapabilitySet(cap.name)}
                data-unique={`selConnectorEditChoice_${index}`}
                items={(cap.choices || []).map(c => {
                  return { key: c.key, label: c.name }
                })}
              />
            }
            {cap.type === 'query' &&
              <Query
                fetchPolicy="network-only"
                query={RUNCONNECTORQUERY_QUERY}
                variables={{
                  connectorName: connectorDesc.name,
                  field: cap.name,
                  caps: JSON.stringify(this.getQueryCaps(values, cap.name))
                }}
                skip={!values || !values.descriptor}
              >
                {(data) => renderConnectorQuery({ cap, disabled, index, _capSetDescription }, data)}
              </Query>
            }
            {cap.type === 'speechrecognitionprofile' && renderSpeechRecognitionSelector({ features, cap, disabled: disabled || _usedByCapabilitySet(cap.name), helperText: _capSetDescription(cap), dataUnique: `selConnectorEditQuery_${index}` })}
            {cap.type === 'speechsynthesisprofile' && renderSpeechSynthesisSelector({ features, cap, disabled: disabled || _usedByCapabilitySet(cap.name), helperText: _capSetDescription(cap), dataUnique: `selConnectorEditQuery_${index}` })}
            {advanced && cap.type === 'dictionary' &&
              <CustomRenderField label={cap.label} helperText={_capSetDescription(cap)}><Card><CardBody>
                <GridContainer nounset>
                  <OnChange name={`descriptor.${cap.name}_dict`}>
                    {(value, previous) => {
                      setDictValue(change, values, cap.name)
                    }}
                  </OnChange>
                  <FieldArray name={`descriptor.${cap.name}_dict`}>
                    {({ fields }) =>
                      fields.map((name, index) => (<React.Fragment key={`${cap.name}.${index}`}>
                        <OnChange name={`${name}.key`}>
                          {(value, previous) => {
                            setDictValue(change, values, cap.name)
                          }}
                        </OnChange>
                        <OnChange name={`${name}.value`}>
                          {(value, previous) => {
                            setDictValue(change, values, cap.name)
                          }}
                        </OnChange>
                        <GridItem xs={5}>
                          <Field
                            name={`${name}.key`}
                            component={renderTextField}
                            label={`Key #${index + 1}`}
                            validate={required}
                            parse={v => v}
                            disabled={disabled}
                            data-unique={`txtConnectorEditCapabilityKey_${index}`}
                          />
                        </GridItem>
                        <GridItem xs={6}>
                          <Field
                            name={`${name}.value`}
                            component={renderTextField}
                            label={`Value #${index + 1}`}
                            parse={v => v}
                            disabled={disabled}
                            data-unique={`txtConnectorEditCapabilityValue_${index}`}
                          />
                        </GridItem>
                        {!disabled &&
                          <GridItem sm={1}>
                            <Button
                              onClick={() => fields.remove(index)}
                              justIcon
                              inputAlign
                              data-unique="btnConnectorEditCapabilitiesRemove"
                            >
                              <ShowIcon icon="trash" />
                            </Button>
                          </GridItem>
                        }
                      </React.Fragment>))
                    }
                  </FieldArray>
                  {!disabled &&
                    <GridItem xs={12}>
                      <Button
                        onClick={() => this.props.push(`descriptor.${cap.name}_dict`, {})}
                        data-unique="btnConnectorEditCapabilitiesAdd"
                        secondary
                        dashed
                        fullWidth
                      >
                        <AddIcon />
                      </Button>
                    </GridItem>
                  }
                </GridContainer>
              </CardBody></Card></CustomRenderField>}
          </GridItem>)
        }
        return null
      })}
    </GridContainer>)} />)
  }
}

const ConnectorEdit = connect(
  state => ({ features: state.settings.features }),
  { setAlertSuccessMessage }
)(ConnectorEditInternal)
export { ConnectorEdit }