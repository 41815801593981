import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
// @material-ui/core components
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import {Form} from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import {Query, Mutation} from 'react-apollo'
import {gql} from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import {setAlertSuccessMessage, setAlertErrorMessage} from 'actions/alert'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import Divider from 'components/Divider/Divider'

import {
  required,
  renderSelect,
  renderCheckbox,
  renderIntField,
  renderTextField,
  parseInteger,
  composeValidators,
  mustBeNumber,
  FormActionsToolbar
} from 'components/Form/Form'

import {RefetchTestSetQueries, DeleteTestSetScriptsFromCache} from 'views/TestSets/gql'
import TestSetSelector from 'views/TestSets/TestSetSelector'
import {CHATBOT_QUERY} from 'views/Chatbots/gql'
import {RUNCONNECTORACTION_QUERY} from './ConnectorEdit'
import Text from 'components/Typography/Text'

import { composeBotiumCapabilities } from 'botium-box-shared/utils/coreUtils'

const FLOW_TO_CRAWL_LIMITED_DEPTH = 'FLOW_TO_CRAWL_LIMITED_DEPTH'

const IMPORT_INTENTS = gql`
  mutation ImportFromDialogflowCX(
    $chatbotId: ID!,
    $testSetId: ID,
    $newTestSetName: String,
    $createTestProject: Boolean,
    $importMode: ImportOverwriteMode,
    $source: DialogflowCxSource,
    $crawlConvo: Boolean,
    $googleCloudStorage: String,
    $skipWelcomeMessage: Boolean,
    $maxConversationLength: Int,
    $continueOnDuplicatePage:  Boolean,
    $continueOnDuplicateFlow:  Boolean,
    $flowToCrawl: String,
    $flowToCrawlIncludeForeignUtterances: Boolean,
    $maxFlowsAfterEntryFlow: Int
  ) {
    importFromDialogflowCx(
      chatbotId: $chatbotId
      testSetId: $testSetId
      newTestSetName: $newTestSetName,
      createTestProject: $createTestProject,
      importMode: $importMode,
      source: $source,
      crawlConvo: $crawlConvo,
      googleCloudStorage: $googleCloudStorage,
      skipWelcomeMessage: $skipWelcomeMessage,
      maxConversationLength: $maxConversationLength,
      continueOnDuplicatePage: $continueOnDuplicatePage,
      continueOnDuplicateFlow: $continueOnDuplicateFlow,
      flowToCrawl: $flowToCrawl,
      flowToCrawlIncludeForeignUtterances: $flowToCrawlIncludeForeignUtterances,
      maxFlowsAfterEntryFlow: $maxFlowsAfterEntryFlow,
    ) { id scripts { id } }
  }
`

class DialogflowCXImport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      importing: false
    }
    this.empty = {
      testSetId: this.props.testSetId || 'new',
      importMode: 'EXTEND',
      crawlConvoMode: 'TrainingSet',
      stopOnDuplicatePage: true,
      stopOnDuplicateFlow: true,
      maxConversationLength: 10,
      useWelcomeMessage: true,
      flowToCrawl: FLOW_TO_CRAWL_LIMITED_DEPTH,
      flowToCrawlIncludeForeignUtterances: false,
      maxFlowsAfterEntryFlow: 1
    }
  }

  renderFlowCrawlControls(values, chatbot) {
    return <>
      <GridItem xs={6}>
        <Field
          name="useWelcomeMessage"
          component={renderCheckbox}
          label="Include chatbot welcome message in generated convos"
          type="checkbox"
          data-unique="btnDialogFlowCxImportUseWelcomeMessage"
        />
        <Text helperText>
          If your chatbot has welcome message, then you have to activate welcome message in chatbot
          settings or download it here, and add to the convo.
        </Text>
      </GridItem>
      {chatbot?.chatbot?.capabilities && <Query query={RUNCONNECTORACTION_QUERY} variables={{
        connectorName: 'dialogflowcx',
        action: 'GetFlows',
        caps: JSON.stringify(composeBotiumCapabilities(chatbot.chatbot.capabilities)),
        args: JSON.stringify({})
      }}>
        {({error, data}) => {
          if (error || (data.runconnectoraction && data.runconnectoraction.err)) {
            return <GridItem xs={12}><Text danger>Loading flows
              failed: {error || (data.runconnectoraction && data.runconnectoraction.err)}</Text></GridItem>
          }
          const parsedResponse = (data.runconnectoraction && data.runconnectoraction.result) ? JSON.parse(data.runconnectoraction.result) : []

          return <>
            <GridItem xs={6}>
              <Field
                name="flowToCrawl"
                component={renderSelect}
                label="Flow to download"
                data-unique="selDialogFlowCxImportCrawlConvo"
                filterable={false}
                validate={required}
                items={[
                  {key: 'all', label: 'All'},
                  {key: FLOW_TO_CRAWL_LIMITED_DEPTH, label: 'Limited depth'},
                  ...parsedResponse.map(i => {
                    return {key: i.id, label: i.name}
                  })
                ]}
              />
              <Text helperText>
                For NLP testing we suggest to create Test Set just from a flow. (Botium will search the shortest
                conversation leading to the choosen flow.)
              </Text>
            </GridItem>
          </>
        }}
      </Query>}
      <GridItem xs={6}>
        {values.flowToCrawl && values.flowToCrawl !== 'all' && values.flowToCrawl !== FLOW_TO_CRAWL_LIMITED_DEPTH && <>
          <Field
            name="flowToCrawlIncludeForeignUtterances"
            component={renderCheckbox}
            label="Download all user examples outside of the choosen flow"
            type="checkbox"
            data-unique="btnDialogFlowCxImportCrawlConvo"
          />
          <Text helperText>
            Using user examples outside of the choosen flow can lead to much more test cases.
          </Text>
        </>}
        {values.flowToCrawl && values.flowToCrawl === FLOW_TO_CRAWL_LIMITED_DEPTH && <>
          <Field
            name="maxFlowsAfterEntryFlow"
            component={renderIntField}
            label="Number of flows to crawl (excluding Entry Flow)"
            parse={parseInteger}
            validate={composeValidators(mustBeNumber, required)}
            data-unique="efDialogFlowCxMaxFlowsAfterEntryFlow"
          />
          <Text helperText>
            Increasing this can be good for chatbots with dependent flows (example: order and pay flows in a pizza
            chatbot).
          </Text>
        </>}
      </GridItem>
      <GridItem xs={12}>
        <Field
          name="maxConversationLength"
          component={renderIntField}
          label="Maximum conversation length"
          parse={parseInteger}
          validate={composeValidators(mustBeNumber, required)}
          data-unique="efDialogFlowCxMaxConversationLength"
        />
        <Text helperText>
          Using user examples outside of the choosen flow can lead to much more test cases.
        </Text>
      </GridItem>
      <GridItem xs={6}>
        <Field
          name="stopOnDuplicatePage"
          component={renderCheckbox}
          label="End download on duplicate page"
          type="checkbox"
          data-unique="cbDialogFlowCxContinueOnDuplicatePage"
        />
        <Text helperText>
          As default download (crawling) stops if a duplicate transition is found
        </Text>
      </GridItem>
      <GridItem xs={6}>
        <Field
          name="stopOnDuplicateFlow"
          component={renderCheckbox}
          label="End download on duplicate flow"
          type="checkbox"
          data-unique="cbDialogFlowCxContinueOnDuplicateFlow"
        />
        <Text helperText>
          As default download (crawling) stops if a duplicate transition is found
        </Text>
      </GridItem>
    </>
  }

  render() {
    const {importing} = this.state
    const {setAlertSuccessMessage, setAlertErrorMessage, history, license} = this.props
    const {chatbotId} = this.props

    return (<Query query={CHATBOT_QUERY} variables={{id: chatbotId}}>
      {({error, data}) => {
        if (error) return <ErrorFormat err={error}/>

        if (!data.chatbot) return null

        return (
          <Mutation
            mutation={IMPORT_INTENTS}
            onCompleted={data => {
              this.setState({importing: false})
              setAlertSuccessMessage('Test Cases imported')
              history.push(`/testsets/view/${data.importFromDialogflowCx.id}`)
            }}
            onError={error => {
              this.setState({importing: false})
              setAlertErrorMessage(`Test Case import failed`, error)
            }}
            refetchQueries={({data}) => [
              ...RefetchTestSetQueries(data.importFromDialogflowCx.id, license)
            ]}
            update={(store, {data}) => DeleteTestSetScriptsFromCache(store, data.importFromDialogflowCx.scripts.map(s => s.id))}
            awaitRefetchQueries={true}
          >
            {(importFromDialogflow, {loading, error}) => (
              <Form
                onSubmit={values => {
                  this.setState({importing: true})
                  importFromDialogflow({
                    variables: {
                      chatbotId: chatbotId,
                      testSetId: values.newTestSetName ? null : values.testSetId,
                      newTestSetName: values.newTestSetName,
                      createTestProject: !!values.createTestProject,
                      importMode: values.importMode,
                      source: values.crawlConvoMode,
                      crawlConvo: values.crawlConvoMode === 'TrainingSetConvo',
                      googleCloudStorage: values.googleCloudStorage,
                      skipWelcomeMessage: !values.useWelcomeMessage,
                      maxConversationLength: values.maxConversationLength,
                      continueOnDuplicatePage: !values.stopOnDuplicatePage,
                      continueOnDuplicateFlow: !values.stopOnDuplicateFlow,
                      flowToCrawl: ((values.flowToCrawl === 'all' || values.flowToCrawl === FLOW_TO_CRAWL_LIMITED_DEPTH) ? null : values.flowToCrawl) || null,
                      flowToCrawlIncludeForeignUtterances: (values.flowToCrawl === 'all' ? null : values.flowToCrawlIncludeForeignUtterances),
                      maxFlowsAfterEntryFlow: values.maxFlowsAfterEntryFlow
                    }
                  })
                }}
                initialValues={this.empty}
                render={({handleSubmit, submitting, invalid, values}) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <TestSetSelector defaultNewTestSetName={data.chatbot.name + ' - Test Set'} />
                      <GridItem md={12}>
                        <Divider orientation="horizontal"  dividerlgnone />
                        <Text header topMargin>More Options</Text>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name="crawlConvoMode"
                          component={renderSelect}
                          label="Download mode"
                          data-unique="selDialogFlowCxImportDownloadConvo"
                          filterable={false}
                          validate={required}
                          items={[
                            { key: 'TrainingSet', label: 'Utterances from Default Env'},
                            { key: 'TrainingSetUtterance', label: 'All utterances'},
                            { key: 'TrainingSetUtteranceIncluded', label: 'Utterances included in conversations'},
                            { key: 'TrainingSetConvo', label: 'Convos and utterances from Default Env'},
                            { key: 'TestSet', label: 'Convos from Dialogflow Test Sets'},
                          ]}
                        />
                        <Text helperText>
                          To test DialogFlow CX chatbot a conversaton based testset is recommended.
                        </Text>
                      </GridItem>
                      {(values.crawlConvoMode === 'TrainingSetUtterance' || values.crawlConvoMode === 'TrainingSetUtteranceIncluded') && <GridItem xs={12}>
                        <Field
                          name="googleCloudStorage"
                          component={renderTextField}
                          label="Google Cloud Storage Bucket name"
                          data-unique="efDialogFlowCxGoogleCloudStorage"
                        />
                        <Text helperText>
                          GCS is mandatory for chatbots above 10G (Google limitation), and suggested for chatbots above 3G (It can harm Botium Environment).
                        </Text>
                      </GridItem>}
                      {values.crawlConvoMode === 'TrainingSetConvo' && this.renderFlowCrawlControls(values, data)}
                      <GridItem xs={12} largePadding>
                        <FormActionsToolbar rightButtons={
                          <Button
                            type="submit"
                            disabled={importing || submitting || invalid}
                            data-unique="btnDialogFlowCxImportDownload"
                          >
                            {importing && <><LoadingIndicator/> Download is running</>}
                            {!importing && <><CloudDownloadIcon/> Download</>}
                          </Button>
                        }/>
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
              />
            )}
          </Mutation>
        )
      }}
    </Query>)
  }
}

DialogflowCXImport.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  testSetId: PropTypes.string
}

export default withRouter(connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(DialogflowCXImport))
