import React from 'react'
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
// apollo
import {compose, Query, Mutation} from 'react-apollo'
// core components
import Field from 'components/Form/OptionalField'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  composeValidators,
  FormActionsToolbar,
  maxLength,
  renderTextArea,
  renderTextField,
  required
} from 'components/Form/Form'
import ErrorFormat from 'components/Info/ErrorFormat'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import SwitchButton from 'components/Button/SwitchButton'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import {
  CRAWLER_SESSION_UTTERANCE_QUERY,
  CRAWLERSESSION_QUERY, CRAWLERSESSION_TREE_QUERY,
  UPDATE_CRAWLERSESSIONSCRIPTNAME
} from './gql'

import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'
import {hasPermission} from 'botium-box-shared/security/permissions'
import crawlersessionsStyle from 'assets/jss/material-dashboard-react/views/crawlersessionsStyle'
import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle'

class CrawlerSessionUtterance extends React.Component {

  renderForm(crawlerProjectId, crawlerSessionScriptId, utterance) {
    const { historyView, crawlerSession, user, setAlertSuccessMessage, setAlertErrorMessage, onSwitchToUtteranceClick, onSwitchToSourceClick, onRefetch, classes } = this.props

    return (
      <Mutation
        mutation={UPDATE_CRAWLERSESSIONSCRIPTNAME}
        refetchQueries={() => [
          {
            query: CRAWLER_SESSION_UTTERANCE_QUERY,
            variables: { crawlerSessionScriptId },
          },
          {
            query: CRAWLERSESSION_QUERY,
            variables: { id: crawlerSession.id },
          },
          {
            query: CRAWLERSESSION_TREE_QUERY,
            variables: { id: crawlerSession.id },
          }
        ]}
        awaitRefetchQueries={true}
      >
        {(updateCrawlerSessionScriptName) => (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={async (values, form) => {
              try {
                const res = await updateCrawlerSessionScriptName({
                  variables: {
                    id: crawlerSessionScriptId,
                    scriptName: values.name
                  },
                })
                onRefetch()
                form.initialize({
                  name: res.data.updateCrawlerSessionScriptName.name,
                  utterances: values.utterances
                })
                setAlertSuccessMessage('Utterance updated')
              } catch(error) {
                setAlertErrorMessage('Utterance update failed', error)
              }
            }}
            initialValues={utterance}
            render={({
              submitting,
              invalid,
              saving,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit} data-simplelist-container>
                <GridContainer>
                  <GridItem md={12} borderBottom header>
                    <GridContainer>
                      <GridItem md={12} lg={8} className={classes.utteranceListName}>
                        <Field
                          className={classes.inputFlexWidth}
                          name="name"
                          component={renderTextField}
                          disableBorder
                          slimError
                          inlineEdit={!historyView}
                          validate={composeValidators(required, maxLength(255))}
                          disabled={!hasPermission(user, 'CRAWLERPROJECTS_UPDATE') || historyView}
                          data-unique="txtCrawlerSessionUtteranceListName"
                        />
                      </GridItem>
                      <GridItem md={12} lg={4} right top smallMarginTop className={classes.sourceEditorSwitch}>
                        <SwitchButton
                          leftLabel="Source Editor"
                          rightLabel="Utterance Editor"
                          leftDataUnique="btnSourceEditor"
                          rightDataUnique="btnUtteranceEditor"
                          checked={'right'}
                          onRightClick={onSwitchToUtteranceClick}
                          onLeftClick={onSwitchToSourceClick}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer padding>
                  <GridItem>
                    <FieldArray name="utterances">
                      {({ fields }) => (<React.Fragment>
                        <GridItem xs={12}>
                          <GridContainer>
                            {fields.map((name, index) => (<React.Fragment key={`utterance_${index}`}>
                              <GridItem floatLeft key={`utterance_${index}`}>
                                <Card smallMargin>
                                  <CardBody noPadding>
                                    <GridContainer>
                                      <GridItem>
                                        <Field
                                          className={classes.crawlerSessionUtteranceFieldGrid}
                                          name={name}
                                          disableBorder
                                          slimError
                                          flexibleWidth
                                          readOnly={true}
                                          component={renderTextArea}
                                          label={`User Example #${index + 1}`}
                                          data-unique={`txtCrawlerSessionUtterance_${index}`}
                                        />
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </React.Fragment>))}
                          </GridContainer>
                        </GridItem>
                      </React.Fragment>)}
                    </FieldArray>
                  </GridItem>
                </GridContainer>

                <GridContainer padding>
                  <GridItem xs={12}>
                    <FormActionsToolbar
                      rightButtons={
                        <React.Fragment>
                          {hasPermission(user, 'CRAWLERSESSIONS_UPDATE') &&
                          <Button
                            type="submit"
                            disabled={saving || submitting || invalid}
                            data-unique="btnCrawlerUtteranceSave"
                          >
                            {saving && <LoadingIndicator />}
                            {!saving && <ShowIcon icon="save" />}
                            Save
                          </Button>
                          }
                        </React.Fragment>
                      } />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { match } = this.props

    if (match.params && match.params.crawlerSessionScriptId) {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Query
              query={CRAWLER_SESSION_UTTERANCE_QUERY}
              variables={{ crawlerSessionScriptId: match.params.crawlerSessionScriptId }}
            >
              {({ loading, error, data }) => {
                if (loading) return <LoadingIndicator/>
                if (error) return <ErrorFormat err={error} />

                return this.renderForm(match.params.crawlerProjectId, match.params.crawlerSessionScriptId, data.crawlersessionutterance)
              }}
            </Query>
          </GridItem>
        </GridContainer>
      )
    } else {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderForm(match.params.crawlerProjectId, null, null, this.empty)}
          </GridItem>
        </GridContainer>
      )
    }
  }
}

export default compose(
  withStyles(
    (theme) => ({
      ...crawlersessionsStyle(theme),
      ...convoStyle(theme)
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage }
  )
)(CrawlerSessionUtterance)
