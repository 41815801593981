import React from 'react'
import LiveChat from '../Chatbots/LiveChat.jsx'

class TestSetLiveChat extends React.Component {
  render() {
    const {match} = this.props
    return <LiveChat testSetId={match.params.testSetId} chatbotId={match.params.chatbotId} cancelUri={`/testsets/view/${match.params.testSetId}`}/>
  }
}

export default TestSetLiveChat