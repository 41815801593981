const _ = require('lodash')

const _maskReplaceAll = (str, search) => str.split(search).join('*'.repeat(Math.min(10, search.length)))
const _maskLine = (str, search) => str.split('\n').map(l => l.toLowerCase().indexOf(search) >= 0 ? '********** line removed from output as it might contain sensitive data **********' : l).join('\n')

const _cwd = process.cwd()
const _maskFns = [
  process.env.RESOURCESDIR ? (str) => _maskReplaceAll(str, process.env.RESOURCESDIR) : null,
  process.env.BOTIUM_TEMPDIR ? (str) => _maskReplaceAll(str, process.env.BOTIUM_TEMPDIR) : null,
  process.env.TESTSETDIR ? (str) => _maskReplaceAll(str, process.env.TESTSETDIR) : null,
  process.env.BOTIUMBOX_QUEUE_REDISURL ? (str) => _maskReplaceAll(str, process.env.BOTIUMBOX_QUEUE_REDISURL) : null,
  process.env.DATABASE_URL ? (str) => _maskReplaceAll(str, process.env.DATABASE_URL) : null,
  (str) => _maskReplaceAll(str, _cwd),
  (str) => _maskLine(str, 'password'),
  (str) => _maskLine(str, 'passwort'),
  (str) => _maskLine(str, 'secret'),
  (str) => _maskLine(str, 'geheim'),
  (str) => _maskLine(str, 'schlüssel'),
  (str) => _maskLine(str, 'apikey'),
  (str) => _maskLine(str, 'subscriptionKey')
].filter(r => r)

const maskError = (msg, escape = false) => {
  const _mask = (str) => _maskFns.reduce((str, fn) => fn(str), str)
  if (!msg) {
    return msg
  } else if (_.isString(msg)) {
    if (escape) {
      return escapeHtmlChars(_mask(msg))
    } else {
      return _mask(msg)
    }
  } else {
    for (const [key, value] of Object.entries(msg)) {
      try {
        msg[key] = (!value || _.isString(value)) ? value : _mask(JSON.stringify(value))
      } catch (err) {
        console.log(`Failed to stringify ${key} in error message (${err.message})`, msg)
      }
    }
    return msg
  }
}

const escapeHtmlChars = (str) => {
  return _.isString(str) ? str.replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\//g, '&#x2F;')
    .replace(/\\/g, '&#x5C;')
    .replace(/`/g, '&#96;') : str
}

const maskSecret = (secret, { prefixCount = 2, suffixCount = 4 } = {}) => {
  if (secret.length > prefixCount + suffixCount) {
    return `${secret.substring(0, prefixCount)}${'*'.repeat(secret.length - prefixCount - suffixCount)}${secret.substring(secret.length - suffixCount)}`
  } else {
    return '*'.repeat(prefixCount + suffixCount)
  }
}

const maskUrl = (url) => {
  if (!url) return url
  const oUrl = new URL(url)
  if (oUrl.password) {
    oUrl.password = '****'
  }
  return oUrl.toString()
}

module.exports = {
  maskError,
  maskSecret,
  maskUrl,
  escapeHtmlChars
}
