import React from 'react'
import LiveChat from './LiveChat.jsx'

class ChatbotLiveChat extends React.Component {
  render() {
    const {match} = this.props
    return <LiveChat chatbotId={match.params.id} cancelUri={`/chatbots/view/${match.params.id}`}/>
  }
}

export default ChatbotLiveChat