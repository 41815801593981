const featureUpdateStyle = theme => ({
  featureUpdateButton: {
    color: '#00acc1',
    backgroundColor: 'rgba(0,188,212,.1)',
    boxShadow: '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
    border: '1px solid #00acc1',
    '&:hover,&:focus': {
      color: '#00acc1',
      backgroundColor: 'rgba(0,188,212,.1)',
      boxShadow: '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
    },
  },
  entryImage: {
    marginTop: '30px'
  }
})

export default featureUpdateStyle
