  import customInputStyle from './customInputStyle'

const selectStyle = theme => ({
  ...customInputStyle(theme),
  

  filterableSelectRoot: {
    height: 28,
    position: 'relative',
    top: '-1px' 
  },
  filterableSelect: {
    padding: 0,
    height: '25px!Important',
    minWidth: 170
  },
})

export default selectStyle
