import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import CallIcon from '@material-ui/icons/Call'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import arrayMutators from 'final-form-arrays'
// apollo
import { Query, Mutation, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import CapabilitiesEdit from 'components/Capability/CapabilitiesEdit.jsx'
import {
  renderTextField,
  renderTextArea,
  renderTagField,
  renderCheckbox,
  required,
  composeValidators,
  FormActionsToolbar
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import {
  CapabilitiesToGql
} from 'components/Capability/Helper'

import agentsStyle from 'assets/jss/material-dashboard-react/views/agentsStyle.jsx'

import {
  AGENTS_QUERY,
  AGENT_QUERY,
  CREATE_AGENT,
  UPDATE_AGENT,
  DELETE_AGENT,
  PING_AGENT
} from './gql'
import { TAGS_QUERY } from './gql'

import { hasPermission, hasAnyPermission } from 'botium-box-shared/security/permissions'
import { validateAgentNameUnique } from './validators'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class Agent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pingRunning: false
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['AGENTS_CREATE', 'AGENTS_UPDATE'])
  }

  renderForm(agent) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady, user } = this.props
    const { pingRunning } = this.state

    const origCapabilities = [...(agent.capabilities || [])]

    return (
      <Mutation
        mutation={agent.id ? UPDATE_AGENT : CREATE_AGENT}
        refetchQueries={[
          {
            query: AGENTS_QUERY,
          },
          {
            query: TAGS_QUERY
          }
        ]}
      >
        {(mutateAgent, { loading, error }) => (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={async (values, form) => {
              const capabilities = CapabilitiesToGql(
                values.capabilities,
                origCapabilities,
              )

              if (agent.id) {
                try {
                  const res = await mutateAgent({
                    variables: {
                      id: values.id,
                      agent: {
                        name: values.name,
                        description: values.description || null,
                        tags: {
                          set: values.tags,
                        },
                        debug: values.debug,
                        capabilities
                      },
                    },
                  })
                  form.initialize(res.data.updateAgent)
                  setAlertSuccessMessage('Botium Agent updated')
                  onReady(agent.id)
                } catch(error) {
                  setAlertErrorMessage('Botium Agent update failed', error)
                }
              } else {
                try {
                  const res = await mutateAgent({
                    variables: {
                      agent: {
                        name: values.name,
                        description: values.description || null,
                        tags: {
                          set: values.tags,
                        },
                        debug: values.debug,
                        capabilities: {
                          create: capabilities.create,
                        }
                      }
                    }
                  })
                  form.initialize(res.data.createAgent)
                  setAlertSuccessMessage('Botium Agent registered')
                  onReady(res.data.createAgent.id)
                } catch(error) {
                  setAlertErrorMessage('Botium Agent registration failed', error)
                }
              }
            }}
            initialValues={agent}
            render={({
              handleSubmit,
              form: {
                mutators: { push, pop }
              },
              submitting,
              invalid
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Agent Name"
                      validate={composeValidators(required, async (value) => {
                        if (value) {
                          return validateAgentNameUnique(this.props.client, agent.id, value)
                        }
                      })}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtAgentAgentName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="tags"
                      component={renderTagField}
                      label="Tags"
                      disabled={!this.hasWritePermission()}
                      data-unique="tagAgentAgentTags"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="description"
                      component={renderTextArea}
                      label="Agent Description"
                      rows={3}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtAgentAgentDescription"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CapabilitiesEdit
                      push={push}
                      pop={pop}
                      field="capabilities"
                      disabled={!this.hasWritePermission()}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="debug"
                      component={renderCheckbox}
                      label="Enable diagnostics logging"
                      type="checkbox"
                      disabled={!this.hasWritePermission()}
                      data-unique="chkAgentDebug"
                    />
                  </GridItem>
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtons={agent.id && hasPermission(user, 'AGENTS_DELETE') &&
                        <Mutation
                          mutation={DELETE_AGENT}
                          onCompleted={data => {
                            setAlertSuccessMessage('Botium Agent disconnected')
                            onReady()
                          }}
                          onError={error => {
                            setAlertErrorMessage('Botium Agent disconnect failed', error)
                          }}
                          refetchQueries={[
                            {
                              query: AGENTS_QUERY,
                            },
                          ]}
                        >
                          {(deleteAgent, { loading, error }) => (
                            <ConfirmationButton
                              confirmationText={`When disconnecting the Botium Agent "${agent.name}", all configuration settings are removed and the Botium Agent is disconnected from Botium. If the Botium Agent is still running, it will automatically reconnect within a few minutes. Are you sure you want to delete it ?`}
                              requireCheck={true}
                              onClick={() => {
                                deleteAgent({
                                  variables: { id: agent.id }
                                })
                              }}
                              data-unique="btnAgentDelete"
                              secondary
                              danger
                            >
                              <ShowIcon icon="trash" />
                              <Text infoText bold>Disconnect</Text>
                            </ConfirmationButton>
                          )}
                        </Mutation>
                      }
                      rightButtons={<>
                        {agent.id && <Mutation
                          mutation={PING_AGENT}
                          onCompleted={data => {
                            this.setState({ pingRunning: false })
                            setAlertSuccessMessage(data.pingAgent)
                          }}
                          onError={error => {
                            this.setState({ pingRunning: false })
                            setAlertErrorMessage('Botium Agent not responding', error)
                          }}
                          refetchQueries={[
                            {
                              query: AGENTS_QUERY
                            }
                          ]}
                        >
                          {(pingAgent, { loading, error }) => (
                            <Button
                              onClick={() => {
                                this.setState({ pingRunning: true })
                                pingAgent({
                                  variables: { id: agent.id },
                                })
                              }}
                              disabled={pingRunning}
                              data-unique="btnAgentPing"
                              secondary
                            >
                              {pingRunning && <LoadingIndicator alt />}
                              {!pingRunning && <CallIcon />}
                              Ping
                            </Button>
                          )}
                        </Mutation>
                        }
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnAgentSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}
                          Save
                        </Button>
                      </>}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={AGENT_QUERY} variables={{ id }}>
              {(queryResult) => <QueryStatus {...queryResult} query="agent">{(data) => this.renderForm(data.agent)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({})}
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user }),
  { setAlertSuccessMessage, setAlertErrorMessage },
)(withStyles(agentsStyle)(withApollo(Agent)))
