import React from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ChartistGraph from 'react-chartist'
import { Interpolation } from 'chartist'
import ChartistLegend from 'chartist-plugin-legend'
import ChartisAxisTitle from 'chartist-plugin-axistitle'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import withTheme from '@material-ui/core/styles/withTheme'
import { v1 } from 'uuid'

const MAX_SERIES = 15

function LineChart({ ...props }) {
  const { theme, data, options, refresh, tooltipOptions, axisTitleOptions, history, legend = true, grid } = props

  if (data.series.length > MAX_SERIES) {
    data.series = data.series.slice(0, MAX_SERIES)
  }

  const plugins = [
    ChartistTooltip(Object.assign({
      tooltipFnc: (meta, value) => meta
    }, tooltipOptions || {}))
  ]
  if (legend) {
    plugins.push(ChartistLegend({
      classNames: data.series.map((s) => s.className || '')
    }))
  }
  if (axisTitleOptions) {
    plugins.push(
      ChartisAxisTitle({
        axisX: Object.assign({
          axisTitle: '',
          offset: {
            x: 0,
            y: 30
          },
          textAnchor: 'middle'
        }, axisTitleOptions.axisX || {}),
        axisY: Object.assign({
          axisTitle: '',
          offset: {
            x: 20,
            y: 0
          },
          flipTitle: false
        }, axisTitleOptions.axisY || {})
      })
    )
  }

  const chartOptions = Object.assign({}, {
    fullWidth: true,
    chartPadding: {
      right: 10,
    },
    lineSmooth: Interpolation.simple({
      divisor: 2,
      fillHoles: true,
    }),
    plugins,
    low: 0
  }, options || {})

  const listener={
    draw: context => {
      if (!context || !context.element) return
      if (context.type === 'label') {
        const textElement = _.get(context, 'element._node.firstChild')
        if (textElement) {
          textElement.style.color = theme.colors.gray
        }
      }
      if (context.type === 'line' || context.type === 'point') {
        let style = ''
        if (context.series && context.series.stroke) {
          if (_.isString(context.series.stroke)) {
            style += 'stroke: ' + context.series.stroke
          } else {
            style += Object.entries(context.series.stroke).map(kv => `${kv[0]}:${kv[1]}`).join(';')
          }
        }
        if (style) {
          context.element.attr({ style })
        }
      }
      if (context.type === 'area') {
        let style = ''
        if (context.series && context.series.fill) {
          if (_.isString(context.series.fill)) {
            style += 'fill: ' + context.series.fill
          } else {
            if (_.isObject(context.series.fill)) {
              style += Object.entries(context.series.fill).map(kv => `${kv[0]}:${kv[1]}`).join(';')
            }
          }
        }
        if (style) {
          context.element.attr({ style })
        }
      }
      if (context.type === 'grid') {
        let style = ''
        if (grid === 'horizontal') {
          if (context.y1 !== context.y2) {
            style += 'stroke: none'
          }
        } else if (grid === 'vertical') {
          if (context.x1 !== context.x2) {
            style += 'stroke: none'
          }
        } else if (grid === 'none') {
          style += 'stroke: none'
        }
        if (style) {
          context.element.attr({ style })
        } else {
          context.element.attr({ style: 'stroke: ' + theme.colors.gray })
        }
      }
      if (context.type === 'point') {
        if (context.series && context.series.hrefs && context.series.hrefs[context.index]) {
          const hrefs = context.series.hrefs[context.index]
          const href = context.series.href
          if (hrefs.testSessionId && href.testProjectId) {
            context.element._node.onclick = () => history.push(`/regression/projects/view/${href.testProjectId}/results/${hrefs.testSessionId}`)
          }
          if (hrefs.testProjectId) {
            context.element._node.onclick = () => history.push(`/regression/projects/view/${hrefs.testProjectId}`)
          }
          if (hrefs.testSetId) {
            context.element._node.onclick = () => history.push(`/testsets/view/${hrefs.testSetId}`)
          }
          if (hrefs.chatbotId) {
            context.element._node.onclick = () => history.push(`/chatbots/view/${hrefs.chatbotId}`)
          }
          if (hrefs.coachSessionId) {
            context.element._node.onclick = () => history.push(`/nlp/projects/view/${hrefs.testProjectId}/results/${hrefs.coachSessionId}`)
          }
        }
      }
    }
  }

  if (refresh) {
    return <ChartistGraph key={v1()} data={data} options={chartOptions} type="Line" listener={listener}/>
  } else {
    return <ChartistGraph data={data} options={chartOptions} type="Line" listener={listener}/>
  }
}

LineChart.propTypes = {
  data: PropTypes.any,
  options: PropTypes.any,
  refresh: PropTypes.bool,
  tooltipOptions: PropTypes.any,
  axisTitleOptions: PropTypes.any
}

export default withTheme()(withRouter(LineChart))
