import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query } from 'react-apollo'
// core components
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

import {
  composeValidators,
  minValue,
  mustBeNumber,
  url,
  renderIntField,
  renderTextField,
  CustomTextField,
  required, renderSelect
} from 'components/Form/Form'
import config from 'config'
import copyToClipboard from 'copy-to-clipboard'
import { APIKEYS_QUERY } from '../../views/Settings/gql'
import Button from '../Button/Button'
import { setAlertSuccessMessage } from '../../actions/alert'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function fbwebhookCaps2Form(caps) {
  return {
    fbwebhook: {
      webhookUrl: (
        caps.find(c => c.name === 'FBWEBHOOK_WEBHOOKURL') || { stringValue: '' }
      ).stringValue,
      endpoint: (
        caps.find(c => c.name === 'FBWEBHOOK_ENDPOINT') || { stringValue: 'webhook' }
      ).stringValue,
      appSecret: (
        caps.find(c => c.name === 'FBWEBHOOK_APPSECRET') || { stringValue: '' }
      ).stringValue,
      pageId: (
        caps.find(c => c.name === 'FBWEBHOOK_PAGEID') || { stringValue: '' }
      ).stringValue
    },
  }
}

const capNamesMap = {
  'fbwebhook.webhookUrl': 'FBWEBHOOK_WEBHOOKURL',
  'fbwebhook.endpoint': 'FBWEBHOOK_ENDPOINT',
  'fbwebhook.appSecret': 'FBWEBHOOK_APPSECRET',
  'fbwebhook.pageId': 'FBWEBHOOK_PAGEID'
}

export function fbwebhookForm2caps(values) {
  const capabilities = [
    {
      name: 'FBWEBHOOK_WEBHOOKURL',
      type: 'STRING',
      stringValue: values.fbwebhook.webhookUrl,
    },
    {
      name: 'FBWEBHOOK_ENDPOINT',
      type: 'STRING',
      stringValue: values.fbwebhook.endpoint,
    },
    {
      name: 'FBWEBHOOK_APPSECRET',
      type: 'STRING',
      stringValue: values.fbwebhook.appSecret || '',
    },
    {
      name: 'FBWEBHOOK_PAGEID',
      type: 'STRING',
      stringValue: values.fbwebhook.pageId,
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'fbwebhook' }
  ]

  return capabilities
}


export class FacebookWebhookEditUnconnected extends React.Component {
  render() {
    const { disabled, setAlertSuccessMessage, capSetCapNames } = this.props

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => {
      const webhookUrl = `${config.api.ext}/inbound?APIKEY=${values.fbwebhook.apiKey || 'SELECT_API_KEY'}`
      return (
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomTextField
              input={{
                value: webhookUrl,
                readOnly: true,
              }}
              label="Facebook Outgoing Webhook URI (for Copy&Paste)"
              data-unique="txtFacebookWebhookEditInboundUrl"
              endAdornment={
                <Button
                  onClick={() => {
                    copyToClipboard(webhookUrl)
                    setAlertSuccessMessage(`Facebook Outgoing Webhook URI copied to the clipboard`)
                  }}
                  justIcon
                  data-unique="btnFacebookWebhookEditCopy"
                >
                  <FileCopyIcon />
                </Button>
              }
              helperText="You have to configure your Facebook Webhook (the component using the Facebook Messenger Platform to send Outbound messages) to use this as base URL (instead of https://graph.facebook.com), adding API Key as query parameter."
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Query query={APIKEYS_QUERY}>
              {({ loading, error, data }) => {
                return (
                  <Field
                    name="fbwebhook.apiKey"
                    component={renderSelect}
                    label="API Key"
                    data-unique="selFacebookWebhookEditApiKey"
                    loading={loading}
                    error={error}
                    items={data && data.apikeys && data.apikeys.map(a => {
                      return { key: a.key, label: a.name }
                    })}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['fbwebhook.apiKey']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['fbwebhook.apiKey'], 'Choose an API Key to generate the Webhook URI')}
                  />
                )
              }}
            </Query>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="fbwebhook.webhookUrl"
              component={renderTextField}
              label="Facebook Webhook Url"
              validate={composeValidators(required, url)}
              data-unique="txtFacebookWebhookEditWebhookUrl"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['fbwebhook.webhookUrl']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['fbwebhook.webhookUrl'], 'The Url of your Facebook Webhook')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="fbwebhook.appSecret"
              component={renderTextField}
              label="Facebook App Secret"
              data-unique="txtFacebookWebhookEditAppSecret"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['fbwebhook.webhookUrl']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['fbwebhook.webhookUrl'], 'The App Secret is used for request verification by your Facebook Webhook')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="fbwebhook.pageId"
              component={renderIntField}
              label="Facebook Page ID"
              validate={composeValidators(mustBeNumber, minValue(0))}
              data-unique="intFacebookWebhookPageId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['fbwebhook.webhookUrl']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['fbwebhook.webhookUrl'], 'Facebook Page ID as expected by your Facebook Webhook')}
            />
          </GridItem>
        </GridContainer>
      )
    }} />)
  }
}

const FacebookWebhookEdit = connect(
  state => ({}),
  { setAlertSuccessMessage }
)(FacebookWebhookEditUnconnected)
export { FacebookWebhookEdit }