import React from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AddAlert from '@material-ui/icons/AddAlert'
import Snackbar from 'components/Snackbar/Snackbar'
import { extractErrorMessage } from 'helper/graphHelper'

import * as alertActions from 'actions/alert'

class Alert extends React.Component {
  render() {
    const { color, message, autoHideDuration, err, clearAlert, dataUnique } = this.props

    const showMessageParts = []
    if (message) showMessageParts.push(message)
    if (err) {
      if(_.isString(err)) {
        showMessageParts.push(err)
      } else {
        showMessageParts.push(extractErrorMessage(err))
      }
    }
    let showMessage = showMessageParts.map(p => `${p}`).join(' - ')
    if (showMessage) {
      if (color === 'danger') console.error('[ALERT MESSAGE] ' + showMessage)
      else console.log('[ALERT MESSAGE] ' + showMessage)
      showMessage = _.truncate(showMessage, { length: 2000 })

      return (
        <Snackbar
          place="bc"
          color={color}
          icon={AddAlert}
          message={showMessage}
          open={showMessage ? true : false}
          closeNotification={() => clearAlert()}
          autoHideDuration={autoHideDuration || null}
          close
          data-unique={dataUnique || 'msgAlert'}
        />
      )
    } else {
      return null
    }
  }
}

const AlertWithState = connect(
  state => ({
    color: state.alert.color,
    message: state.alert.message,
    autoHideDuration: state.alert.autoHideDuration,
    dataUnique: state.alert.dataUnique,
    err: state.alert.err,
  }),
  dispatch => bindActionCreators(alertActions, dispatch),
)(Alert)

export default AlertWithState
