const _ = require('lodash')

const isLicenseDeviceSetsSupported = (license) => {
  if (!license) return false
  if (!_.isArray(license.connectors)) return true
  if (license.connectors.indexOf('webdriverio') >= 0 || license.connectors.indexOf('webdrivervoice') >= 0) return true
  return false
}

module.exports = {
  isLicenseDeviceSetsSupported
}
