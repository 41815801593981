import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import arrayMutators from 'final-form-arrays'
// apollo
import { Query, Mutation, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import CapabilitiesEdit from 'components/Capability/CapabilitiesEdit.jsx'
import ObjectChips from 'components/Chip/ObjectChips'
import {
  renderTextField,
  required,
  composeValidators,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import {
  CapabilitiesToGql
} from 'components/Capability/Helper'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import {
  CAPABILITYSETS_QUERY,
  CAPABILITYSET_QUERY,
  CREATE_CAPABILITYSET,
  UPDATE_CAPABILITYSET,
  DELETE_CAPABILITYSET
} from './gql'
import { TAGS_QUERY } from './gql'

import { hasPermission, hasAnyPermission } from 'botium-box-shared/security/permissions'
import { validateCapabilitySetNameUnique } from './validators'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Divider from 'components/Divider/Divider'
import Text from 'components/Typography/Text'

class CapabilitySet extends React.Component {

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['CHATBOTS_CREATE', 'CHATBOTS_UPDATE'])
  }

  renderForm(capabilitySet) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady, user } = this.props

    const origCapabilities = [...(capabilitySet.capabilities || [])]

    return (
      <Mutation
        mutation={capabilitySet.id ? UPDATE_CAPABILITYSET : CREATE_CAPABILITYSET}
        refetchQueries={[
          {
            query: CAPABILITYSETS_QUERY,
          },
          {
            query: TAGS_QUERY
          }
        ]}
      >
        {(mutateCapabilitySet, { loading, error }) => (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={async (values, form) => {
              const capabilities = CapabilitiesToGql(
                values.capabilities,
                origCapabilities,
              )

              if (capabilitySet.id) {
                try {
                  const res = await mutateCapabilitySet({
                    variables: {
                      id: values.id,
                      capabilitySet: {
                        name: values.name,
                        capabilities
                      },
                    },
                  })
                  form.initialize(res.data.updateCapabilitySet)
                  setAlertSuccessMessage('Capability Set updated')
                  onReady(capabilitySet.id)
                } catch(error) {
                  setAlertErrorMessage('Capability Set update failed', error)
                }
              } else {
                try {
                  const res = await mutateCapabilitySet({
                    variables: {
                      capabilitySet: {
                        name: values.name,
                        capabilities: {
                          create: capabilities.create,
                        }
                      }
                    }
                  })
                  form.initialize(res.data.createCapabilitySet)
                  setAlertSuccessMessage('Capability Set registered')
                  onReady(res.data.createCapabilitySet.id)
                } catch(error) {
                  setAlertErrorMessage('Capability Set registration failed', error)
                }
              }
            }}
            initialValues={capabilitySet}
            render={({
              handleSubmit,
              form: {
                mutators: { push, pop }
              },
              submitting,
              invalid
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Capability Set Name"
                      validate={composeValidators(required, async (value) => {
                        if (value) {
                          return validateCapabilitySetNameUnique(this.props.client, capabilitySet.id, value)
                        }
                      })}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtCapabilitySetName"
                    />
                  </GridItem>
                  {capabilitySet.chatbots && capabilitySet.chatbots.length > 0 && <><GridItem xs={12}>
                    <Divider />
                  </GridItem>
                  <GridItem xs={12}>
                    <Text header>Chatbots using this Capability Set</Text>
                    <GridContainer>
                    {capabilitySet.chatbots.map(c => (
                     <GridItem grid lg={4} key={`chatbots_in_use_${c.id}`}>
                      <ObjectChips singleChip hideHeader dataUniquePrefix="btnChatbot" chatbot={c} />
                    </GridItem>
                    ))}
                    <GridItem xs={12}>
                      <Divider />
                    </GridItem>
                    </GridContainer>
                  
                  </GridItem></>}
                  <GridItem xs={12}>
                    <Text header>Capabilities</Text>
                    <CapabilitiesEdit
                      push={push}
                      pop={pop}
                      field="capabilities"
                      disabled={!this.hasWritePermission()}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                  <FormActionsToolbar
                      leftButtons={capabilitySet.id && hasPermission(user, 'CHATBOTS_DELETE') &&
                        <Mutation
                          mutation={DELETE_CAPABILITYSET}
                          onCompleted={data => {
                            setAlertSuccessMessage('Capability Set deleted')
                            onReady()
                          }}
                          onError={error => {
                            setAlertErrorMessage('Capabilitly Set deletion failed', error)
                          }}
                          refetchQueries={[
                            {
                              query: CAPABILITYSETS_QUERY,
                            },
                          ]}
                        >
                          {(deleteCapabilitySet, { loading, error }) => (
                            <ConfirmationButton
                              confirmationText={`When removing the Capability Set "${capabilitySet.name}", all configuration settings are removed. Are you sure you want to delete it ?`}
                              requireCheck={true}
                              onClick={() => {
                                deleteCapabilitySet({
                                  variables: { id: capabilitySet.id }
                                })
                              }}
                              data-unique="btnCapabilitySetDelete"
                              secondary
                              danger
                            >
                              <ShowIcon icon="trash" />
                              <Text infoText bold>Delete</Text>
                            </ConfirmationButton>
                          )}
                        </Mutation>
                      }
                      rightButtons={
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnCapabilitySetSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}
                          Save
                        </Button>
                      } />
                  </GridItem>
                  
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={CAPABILITYSET_QUERY} variables={{ id }}>
              {(queryResult) => <QueryStatus {...queryResult} query="capabilitySet">{(data) => this.renderForm(data.capabilitySet)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({})}
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user }),
  { setAlertSuccessMessage, setAlertErrorMessage },
)(withStyles(settingsStyle)(withApollo(CapabilitySet)))
