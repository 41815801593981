const _ = require('lodash')

const getCoreErrDetails = (err) => {
  if (err && err.name === 'BotiumError') {
    if (err.context && err.context.errors) {
      return err.context.errors
    }
    if (err.context) {
      return [err.context]
    }
  }
}
const getCoreErr = (err) => {
  if (err) {
    if (err.context && err.context.message) {
      return err.context.message
    } else if (err.message) {
      return err.message
    } else {
      return `${err}`
    }
  }
  return null
}

const _removeBuffers = obj => {
  _.forOwn(obj, (val, key) => {
    if (_.isBuffer(val)) {
      delete obj[key]
    } else if (_.isArray(val)) {
      val.forEach(el => {
        _removeBuffers(el)
      })
    } else if (_.isObject(val)) {
      _removeBuffers(val)
    }
  })
}

const prepareCoreTranscript = (transcript) => {
  transcript.errDetails = getCoreErrDetails(transcript.err)
  transcript.err = getCoreErr(transcript.err)
  transcript.steps && transcript.steps.forEach(step => {
    step.errDetails = getCoreErrDetails(step.err)
    step.err = getCoreErr(step.err)
  })
  _removeBuffers(transcript)
  return transcript
}

const composeBotiumCapabilities = (caps) => {
  return caps && caps.reduce((botiumCaps, cap) => {
    if (!cap.type || cap.type === 'STRING' || cap.type === 'TEXT' || cap.type === 'JS') {
      botiumCaps[cap.name] = cap.stringValue ? cap.stringValue.replace(/\\n/g, '\n') : (_.isNull(cap.stringValue) ? null : '')
    }
    if (cap.type === 'INT') {
      botiumCaps[cap.name] = parseInt(cap.intValue)
    }
    if (cap.type === 'BOOLEAN') {
      botiumCaps[cap.name] = !!cap.booleanValue
    }
    if (cap.type === 'JSON') {
      botiumCaps[cap.name] = cap.jsonValue || (_.isNull(cap.jsonValue) ? null : '')
    }
    return botiumCaps
  }, {})
}

module.exports = {
  prepareCoreTranscript,
  getCoreErrDetails,
  getCoreErr,
  composeBotiumCapabilities
}
