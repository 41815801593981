export function openDataUriInNewTab (src) {
  const datawin = window.open()
  datawin.document.write('<iframe src="' + src  + '" sandbox="" csp="default-src \'none\'" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
}
export function openInNewTab (url) {
  window.open(url, '_blank').focus()
}
export function openTextInNewTab (text) {
  openDataUriInNewTab(`data:text/plain;charset=utf-8,${encodeURIComponent(text)}`)
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export const recordAudioSupported = () => {
	if(!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
		return false
	}
	if(typeof MediaRecorder === 'undefined') {
		return false
	}
	return true
}

export const recordAudio = () => {
  return new Promise(resolve => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm;codecs=opus' })
        const audioChunks = []

        mediaRecorder.addEventListener('dataavailable', event => {
          audioChunks.push(event.data)
        })

        const start = () => {
          mediaRecorder.start()
        }

        const stop = () => {
          return new Promise(resolve => {
            mediaRecorder.addEventListener('stop', () => {
              const audioBlob = new Blob(audioChunks)
              const audioUrl = URL.createObjectURL(audioBlob)
              const audio = new Audio(audioUrl)
              const mimeType = 'audio/webm'
              const play = () => {
                audio.play()
              }
              const reader = new FileReader()
              reader.readAsDataURL(audioBlob)
              reader.onloadend = () => {
                const base64 = reader.result
                resolve({ audioBlob, audioUrl, play, mimeType, base64 })
              }
            })

						if(mediaRecorder.state === 'recording') {
							mediaRecorder.stop()
						}
          })
        }

        resolve({ start, stop })
      })
  })
}

export const recordAudioStreamingSupported = () => {
	if(!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
		return false
	}
	if(typeof MediaRecorder === 'undefined') {
		return false
	}
  return MediaRecorder.isTypeSupported('audio/webm;codecs=opus')
}

export const recordAudioStreaming = (listener) => {
  return new Promise(resolve => {
    navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: 'default',
        sampleRate: 16000,
        sampleSize: 16,
        channelCount: 1
      }
    })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/webm;codecs=opus',
          audioBitsPerSecond: 16000
        })
        
        mediaRecorder.addEventListener('dataavailable', event => {
          listener(event.data)
        })

        const start = (timeslice) => {
          mediaRecorder.start(timeslice || 1000)
        }

        const stop = () => {
          return new Promise(resolve => {
						if(mediaRecorder.state === 'recording') {
							mediaRecorder.stop()
						}
          })
        }
        resolve({ start, stop })
      })
  })
}
