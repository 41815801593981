const stepperStyle = theme => ({
  stepLabel: {
    color: theme.colors.stepslables,
    fontSize: 15,
    fontWeight: 'Bold',
    position: 'relative',
    top: 5,
    background: 'transparent'
  },
  stepSubLabel: {
    color: theme.colors.stepslables,
    fontSize: 13,
    fontWeight: 400,
    position: 'relative',
    top: -3,
  },
  stepSubLabelError: {
    color: theme.colors.stepslables,
  },
  stepIcon: {
    fontSize: 50,
    color: theme.colors.common.backgroundTabs,
    marginLeft: -22,
  },
  stepIconDone: {
    color: theme.colors.common.secondary
  },
  stepIconError: {
    color: theme.colors.common.secondary

  },
  stepIconLabel: {
    position: 'relative',
    top: 12,
    left: 15,
    fontSize: 20,
    color: theme.colors.common.primary,
    fontWeight: 'bold',
    backgroundColor: '#f4f5f6',
    height: 'fit-content'
  },
  stepperRoot: {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: 20,
  },
  stepConnectorHorizontal: {
    paddingLeft: 30,
    paddingRight: 20,
  },
  stepConnectorLineHorizontal: {
    border: `1px solid ${theme.colors.stepsline}`,
  },
  stepIconLabelActive: {
    color: theme.colors.common.primary,
    backgroundColor: '#FF9800',
    height: 'fit-content'
  }
})

export default stepperStyle