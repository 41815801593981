import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'
import {NavLink} from 'react-router-dom'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {PER_ACTUAL_INTENTS_QUERY, TRAINER_SESSION_ROOT_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'
import {INCOMPREHENSION_IS_BAD} from './helper'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'
import Button from 'components/Button/Button'

const testsessionLabel = (testsession) => {
  if (testsession) {
    return <React.Fragment>{testsession.name}</React.Fragment>
  }
  return null
}

class Intents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getRootPath = () => {
    const path = this.props.location.pathname.split('/').filter(p => p)
    return '/' + path[0] + '/' + path[1] + '/' + path[2] + '/' + path[3]
  }

  renderTable() {
    const {match, intentsData} = this.props

    let progressOrError = renderProgressOrError(intentsData)
    if (progressOrError) {
      return progressOrError
    }
    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Predicted Intent', 'F1-Score', 'Precision Score', 'Recall Score', 'Accuracy Score', 'Prediction Confidence', 'Confidence Deviation', 'Utterances']}
        tableData={intentsData.trainerPerActualIntents.map(entry => {
          return [
            () => <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${match.params.testSessionId}/intentconfidence/intentname/${encodeURIComponent(entry.name)}`} data-unique={`btnCoachIntentsTrainerPerActualIntentsName_${encodeURIComponent(entry.name)}`}>
              {entry.name}
            </NavLink>,
            () => <Text bold rightMarginxs displayInline>{_.isNumber(entry.F1) ? entry.F1.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</Text>,
            () => <Text bold rightMarginxs displayInline>{_.isNumber(entry.precision) ? entry.precision.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</Text>,
            () => <Text bold rightMarginxs displayInline>{_.isNumber(entry.recall) ? entry.recall.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</Text>,
            () => <Text bold rightMarginxs displayInline>{_.isNumber(entry.accuracy) ? entry.accuracy.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</Text>,
            () => <Text bold rightMarginxs displayInline>{_.isNumber(entry.avg) ? entry.avg.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</Text>,
            () => <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${match.params.testSessionId}/intentconfidencedeviation/${encodeURIComponent(entry.name)}`} data-unique={`btnCoachIntentsTrainerPerActualIntentsDeviation_${encodeURIComponent(entry.name)}`}>
              <Text bold rightMarginxs displayInline>{entry.deviation.toFixed(2).replace('0.', '').replace('.', '') || 'N/A'}</Text>
            </NavLink>,
            () => <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${match.params.testSessionId}/intentconfidence/intentname/${encodeURIComponent(entry.name)}`} data-unique={`btnCoachIntentsTrainerPerActualIntentsCount_${encodeURIComponent(entry.name)}`}>
              {entry.count}
            </NavLink>
          ]
        })}
      />
    )
  }

  render() {
    const {testSessionData} = this.props

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <NavLink to={`${this.getRootPath()}/results/${testSessionData.testsession.id}`} data-unique={`btnBack_${testSessionData.testsession.id}`}>
                    <Button justIcon Border >
                       <ShowIcon icon="arrow-left" />
                       </Button> <Text inline>BACK / Test Session: {testSessionData && testSessionData.testsession && testsessionLabel(testSessionData.testsession)}</Text>
                       </NavLink>
                </GridItem>
                <GridItem xs={12}>
                  {this.renderTable()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(PER_ACTUAL_INTENTS_QUERY, {
    props: ({data}) => ({
      intentsData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          orderBy: props.match.params.orderBy,
          incomprehensionIsBad: INCOMPREHENSION_IS_BAD
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      testSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.testSessionId
        },
      }
    }
  })
)(Intents)
