import {useContext} from 'react'
import { getSystemScheme } from 'themeUtils'
import { ThemeContext } from 'themeUtils'

export const isDarkmode = () => {
    if (localStorage.getItem('themeName')) {
        return localStorage.getItem('themeName') === 'darkTheme'
    }
    return getSystemScheme() === 'darkTheme'
}

export function ThemeSetter({ classes, children, ...rest }) {

    const { _setThemeName } = useContext(ThemeContext)

    const setDarkmode = (darkmode) => {
        if (darkmode) {
            localStorage.setItem('themeName', 'darkTheme')
            _setThemeName('darkTheme')
        } else {
            localStorage.setItem('themeName', 'lightTheme')
            _setThemeName('lightTheme')
        }
    }
  
      return children({ setDarkmode })
  }

