import React from 'react'
import LiveChat from '../Chatbots/LiveChat.jsx'

class LiveChatWizard extends React.Component {
  render() {
    const {match} = this.props
    return <LiveChat chatbotId={match.params.chatbotId} cancelUri="/testdatawizards"/>
  }
}

export default LiveChatWizard