import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, graphql } from 'react-apollo'
import ErrorFormat from 'components/Info/ErrorFormat'
import FeatureUpgradeNavLink from 'components/FeatureUpgrade/FeatureUpgradeNavLink'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Button from 'components/Button/Button'

import { CHECKFEATURECONFIGURED_QUERY } from './gql'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import MessageBox from 'components/Info/MessageBox'

const getFeatureName = (feature) => {
  if (feature === 'paraphraser') return 'Paraphraser'
  if (feature === 'aitestwizard') return 'AI-powered Data Generator'
  if (feature === 'crawlerguess') return 'Crawler Response'
  if (feature === 'translation') return 'Translation Service'
}
const getFeatureLink = (feature) => {
  if (feature === 'paraphraser') return '/settings/system/paraphrasing'
  if (feature === 'aitestwizard') return '/settings/system/paraphrasing'
  if (feature === 'crawlerguess') return '/settings/system/paraphrasing'
  if (feature === 'translation') return '/settings/system/translate'
}
const getFeatureNotConfiguredText = (feature) => {
  if (feature === 'paraphraser') return 'Please configure the OpenAI API Key in the System Settings!'
  if (feature === 'aitestwizard') return 'Please configure the OpenAI API Key in the System Settings!'
  if (feature === 'crawlerguess') return 'Please configure the OpenAI API Key in the System Settings!'
  if (feature === 'translation') return 'Please enable Google Translate in the System Settings!'
}

function ClientFeatureSection({ ...props }) {
  const { children, feature, featureData, noLicenseText, notConfiguredText, notConfiguredLink } = props

  const history = useHistory()

  if (featureData.error) {
    return <ErrorFormat err={featureData.error} suppress />
  }
  if (featureData.loading || !featureData.checkfeatureconfigured) {
    return <LoadingIndicator />
  }
  if (!featureData.checkfeatureconfigured.isLicensed) {
    return <Text danger><FeatureUpgradeNavLink>{noLicenseText || `${getFeatureName(feature) || 'Feature'} not available in this edition!`}</FeatureUpgradeNavLink></Text>
  }
  if (!featureData.checkfeatureconfigured.isConfigured) {
    const featureLink = notConfiguredLink || getFeatureLink(feature)
    const configuredText = notConfiguredText || getFeatureNotConfiguredText(feature) || `${getFeatureName(feature) || 'Feature'} not configured!`
    return <MessageBox variant="warninginline" title={configuredText} link={featureLink ? <Button small secondary link onClick={() => history.push(featureLink)}>Settings <KeyboardArrowRight /></Button> : ''} ></MessageBox>
  }
  return children || null
}

export function ClientFeatureMessageBox({ text, link, linkText }) {
  const history = useHistory()
  return <MessageBox variant="warninginline" title={text} link={link ? <Button small secondary link onClick={() => history.push(link)}>{linkText || 'Settings'}<KeyboardArrowRight /></Button> : ''} ></MessageBox>
}

export default compose(
  connect(
    state => ({ license: state.settings.license }),
  ),
  graphql(CHECKFEATURECONFIGURED_QUERY, {
    props: ({ data }) => ({
      featureData: data,
    }),
    options: (props) => {
      const { feature } = props
      return {
        fetchPolicy: 'network-only',
        variables: {
          feature
        }
      }
    }
  })
)(ClientFeatureSection)


