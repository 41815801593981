const cardTitleStyle = theme => ({
  cardCategory: {
    color: '#999999',
    margin: '0',
    fontSize: '12px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardCategoryWhite: {
    color: theme.colors.primary,
    margin: '0',
    fontSize: '11px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitle: {
    color: '#3C4858',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '0px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  cardTitleWhite: {
    color: theme.colors.primary,
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 'bold',
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '0px',
    fontSize: 15,
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
})

export default cardTitleStyle
