import { SET_LOGIN, CLEAR_LOGIN } from '../constants'

function getUserFromState(state) {
  const token = (state && state.token) || {}
  return token.user
}

export function getUser() {
  return (dispatch, getState) => getUserFromState(getState())
}

export function setLogin(expiryDate, user) {
  return {
    type: SET_LOGIN,
    payload: {
      expiryDate,
      user
    },
  }
}

export function clearLogin() {
  return {
    type: CLEAR_LOGIN,
  }
}
