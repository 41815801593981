const cardBodyStyle = theme => ({
  cardCard: {
    border: 0,
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}'Important`
    },
  },
  cardBody: {
    padding: 12,
    flex: '1 1 auto',
    WebkitBoxFlex: '1',
    position: 'relative',
  },
  cardBodyPlain: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  cardBodyProfile: {
    marginTop: '12px',
  },
  cardBodyNoPadding: {
    padding: 0
  },
  cardBodyNoPaddingBottom: {
    paddingBottom: 0
  },
  cardBodyNoPaddingTop: {
    paddingTop: 0
  },
  cardBodySmallPadding: {
    padding: '8px',
  },
  cardBodyLargePadding: {
    padding: '12px 20px',
  },
  cardBodyminimumHeight: {
    minHeight: 65,
    display: 'flex',
    verticalAlign: 'middle' 
  },
  CardBodytopPadding: {
    paddingTop: 25,
  },
  CardBodybottomPadding: {
    paddingBottom: 25,
  }
})

export default cardBodyStyle
