import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import copyToClipboard from 'copy-to-clipboard'
import {Controlled as CodeMirror} from 'react-codemirror2'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from 'components/List/ListItem/ListItemText'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'
// core components
import ShowIcon from 'components/Icon/ShowIcon'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary'
import Chip from 'components/Chip/Chip'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ImageTiles from 'components/Convo/ImageTiles'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import AvatarImage from 'components/Avatar/AvatarImage'
import Button from 'components/Button/Button'
import { extractErrorMessage } from 'helper/graphHelper'
import { removeBuffers } from 'botium-box-shared/utils/bufferUtils'
import config from 'config'

import { setAlertSuccessMessage } from 'actions/alert'

import BotiumIcon from 'assets/img/botium-logo.png'
import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle'
import { safeMessageText, NLPPrimaryIntentChip, NLPAlternateIntentsChip, NLPEntitiesChip, Card, MeButton, BotButton, Form } from './Shared.jsx'
import ComponentChip from './ComponentChip.jsx'
import Text from 'components/Typography/Text.jsx'
import { isDarkmode } from 'components/Darkmode/helper.jsx'
import { ClickAwayListener } from '@material-ui/core'

class Transcript extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      renderSteps: [],
      showAttachmentsIndex: null,
      showAttachmentsAnchorEl: null,
      code: null,
      codeAttachmentsIndex: null
    }
  }

  componentDidMount() {
    const { steps } = this.props
    this.setState({ renderSteps: this.parseSteps(steps) })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { steps } = nextProps
    this.setState({ renderSteps: this.parseSteps(steps) })
  }

  parseSteps(steps) {
    if (steps && steps.length > 0) {
      steps.forEach(step => {
        if (step.actual && _.isString(step.actual)) {
          try {
            step.actual = JSON.parse(step.actual)
          } catch (err) {
          }
        }
        if (step.expected && _.isString(step.expected)) {
          try {
            step.expected = JSON.parse(step.expected)
          } catch (err) {
          }
        }
        if (step.errDetails && _.isString(step.errDetails)) {
          try {
            step.errDetails = JSON.parse(step.errDetails)
          } catch (err) {
          }
        }
      })
    }
    return steps
  }

  renderStepAttachments(index, step) {
    const { attachments } = this.props
    const { actual: msg } = step

    const attachmentTiles = [
      ...((attachments && attachments.filter(a => a.stepIndex === index).map(a => ({
        downloadSrc: `${config.api.base}/attachment/screenshot/${a.id}`,
        id: a.id,
        title: a.name,
        mimeType: a.mimeType
      }))) || []),
      ...((msg && msg.attachments && msg.attachments.map((a, i) => ({
        base64: a.base64,
        mimeType: a.mimeType,
        id: i,
        title: a.name
      }))) || [])
    ]

    return (<ImageTiles
      images={attachmentTiles}
      key={index}
      heading={`${attachmentTiles.length} Attachment(s)`}
      cards
    />)
  }

  renderStepMsg(index, step, nextStep) {
    const { classes, allowHtmlDisplay } = this.props

    const { sender, actual: msg } = step
    const submittedFormData = (nextStep && nextStep.actual && nextStep.actual.sender === 'me') ? (nextStep.actual.forms) : null

    if (sender === 'me') {
      const isButtonInput = msg.buttons && msg.buttons.length > 0

      return (<GridContainer>
        {(isButtonInput || msg.messageText) &&
          <GridItem xs={12}>
            {isButtonInput && <MeButton msg={msg} />}
            {!isButtonInput && msg.messageText && <Chip variant="info" multiline avatarImage={<ShowIcon custom icon="text" />} allowHtmlDisplay={allowHtmlDisplay}  label={safeMessageText(msg.messageText)} />}
          </GridItem>
        }
        <GridItem xs={12}>
          {msg.media && msg.media.length > 0 && !msg.media[0].buffer && <ComponentChip component={{ name: 'MEDIA', args: [msg.media[0].mediaUri]}} />}
          {msg.media && msg.media.length > 0 && msg.media[0].buffer && (
            <ImageTiles
              images={[{
                imageSrc: msg.media[0].buffer,
                title: msg.media[0].altText,
                mimeType: msg.media[0].mimeType,
                id: 'memedia',
              }]}
              cards
              key={'memedia'}
              maxHeight={250}
            />
          )}
        </GridItem>
      </GridContainer>)
    } else if (sender === 'bot') {
      return (<GridContainer>
        <GridItem xs={12}>
          {msg.messageText && <Chip variant="info" multiline avatarImage={<ShowIcon custom icon="text" />} allowHtmlDisplay={allowHtmlDisplay} label={safeMessageText(msg.messageText)} />}
          {msg.buttons && (!msg.forms || msg.forms.length === 0) && msg.buttons.map((button, index) =>
            <BotButton key={'button_' + index} button={button} allowHtmlDisplay={allowHtmlDisplay} />
          )}
        </GridItem>
        <GridItem xs={12}>
          <NLPPrimaryIntentChip msg={msg} />
          <NLPAlternateIntentsChip msg={msg} />
          <NLPEntitiesChip msg={msg} />
        </GridItem>
        <GridItem xs={12}>
          {msg.forms && msg.forms.length > 0 && <Form msg={msg} submittedFormData={submittedFormData} classes={classes} allowHtmlDisplay={allowHtmlDisplay} />}
        </GridItem>

        {msg.media && (<GridItem xs={12}>
          <ImageTiles
            images={msg.media.map((m, index) => ({
              imageSrc: m.buffer || m.mediaUri,
              title: m.altText,
              mimeType: m.mimeType,
              id: index,
            }))}
            cards
            key={'botmedia'}
            maxHeight={250}
          />
        </GridItem>)}
        {msg.cards && (<GridItem xs={12}>
          <GridContainer>
            {msg.cards.map((card, index) => <Card card={card} submittedFormData={submittedFormData} key={index} classes={classes} allowHtmlDisplay={allowHtmlDisplay}/> )}
          </GridContainer>
        </GridItem>)}

      </GridContainer>)
    } else {
      return null
    }
  }

  render() {
    const { classes, chatbotId, avatar, containermode, attachments, err } = this.props
    const { renderSteps, showAttachmentsIndex, showAttachmentsAnchorEl } = this.state

    const getExpectedText = (expected) => {
      if(_.isString(expected)) {
        return expected
      } else if(_.isArray(expected)) {
        return expected.join(' | ')
      } else {
        return JSON.stringify(expected)
      }
    }

    const getActualText = (actual) => {
      if(_.isString(actual)) {
        return actual
      } else if(_.isArray(actual)) {
        return actual.join(' | ')
      } else if(_.isObject(actual)) {
        if(actual.messageText) {
          return actual.messageText
        }
        return JSON.stringify(actual)
      }
    }

    const actualAttachmentTiles = (this.state.codeAttachmentsIndex >= 0 && [
      ...((attachments && attachments.filter(a => a.stepIndex === this.state.codeAttachmentsIndex).map(a => ({
        downloadSrc: `${config.api.base}/attachment/screenshot/${a.id}`,
        id: a.id,
        title: a.name,
        mimeType: a.mimeType
      }))) || []),
      ...((this.state.code && this.state.code.actual && this.state.code.actual.attachments && this.state.code.actual.attachments.map((a, i) => ({
        base64: a.base64,
        id: i,
        title: a.name,
        mimeType: a.mimeType
      }))) || [])
    ]) || []
    const hasAnyAttachments = !!((renderSteps && renderSteps.find(step => step.actual && step.actual.attachments && step.actual.attachments.length > 0)) || (attachments && attachments.filter(a => a.stepIndex >= 0).length > 0))

    return (
      <React.Fragment>
        <List component="div">
          {err &&
            <ListItem>
              <ListItemText tabIndex="0" inset
                className={classNames({
                  [classes.senderErr]: true,
                  [classes.backgroundDanger]: true
                })}
              >
                <ShowIcon icon="exclamation-circle" /> {extractErrorMessage(err, true)}
              </ListItemText>
            </ListItem>
          }
          {renderSteps && renderSteps.map((step, index) => {
            const nextStep = (renderSteps.length > (index + 1)) ? renderSteps[index + 1] : null
            const hasAttachments = (step.actual && step.actual.attachments && step.actual.attachments.length > 0) || (attachments && attachments.find(a => a.stepIndex === index))
            const showAttachments = hasAttachments && showAttachmentsIndex === index

            const botMetadata = []
            let isVoiceBot = false
            if (step.sender === 'bot') {
              if (step.actual && step.actual && _.isObject(step.actual.sourceData) && !_.isNil(step.actual.sourceData.silenceDuration)) {
                isVoiceBot = true
                botMetadata.push({
                  label: 'Voice started in',
                  value: `${parseFloat(step.actual.sourceData.silenceDuration).toFixed(2)}s`
                })
              }
              if (step.actual && step.actual && _.isArray(step.actual.sourceData) && !_.isNil(step.actual.sourceData[0].silenceDuration)) {
                isVoiceBot = true
                botMetadata.push({
                  label: 'Voice started in',
                  value: `${parseFloat(step.actual.sourceData[0].silenceDuration).toFixed(2)}s`
                })
              }
              if (step.actual && step.actual && _.isObject(step.actual.sourceData) && !_.isNil(step.actual.sourceData.voiceDuration)) {
                isVoiceBot = true
                botMetadata.push({
                  label: 'Voice duration',
                  value: `${parseFloat(step.actual.sourceData.voiceDuration).toFixed(2)}s`
                })
              }
              if (step.actual && step.actual && _.isArray(step.actual.sourceData) && !_.isNil(step.actual.sourceData[0].voiceDuration)) {
                isVoiceBot = true
                botMetadata.push({
                  label: 'Voice duration',
                  value: `${parseFloat(step.actual.sourceData[0].voiceDuration).toFixed(2)}s`
                })
              }
              if (!_.isNil(step.stepDuration)) {
                botMetadata.push({
                  label: isVoiceBot ? 'ASR received in' : 'Received in',
                  value: (step.stepDuration >= 100 || step.stepDuration === 0) ? `${(step.stepDuration / 1000).toFixed(2)}s` : `${step.stepDuration}ms`
                })
              }
            }

            return (
              <React.Fragment key={index}>
                {step.actual && <>
                  <ListItem key={index} data-unique={`${index}_${step.sender}`}>
                    {step.sender === 'bot' && (
                      <ListItemIcon>
                         <GridContainer top padding noPaddingBottom>
                        <GridItem>
                        <AvatarImage chatbotId={chatbotId} avatar={avatar} containermode={containermode}/>
                        </GridItem>
                      </GridContainer>

                      </ListItemIcon>
                    )}
                    <ListItemText tabIndex="0" inset
                      className={classNames({
                        [classes.senderMe]: step.sender === 'me',
                        [classes.senderBot]: step.sender !== 'me',
                      })}
                    >
                      {step.actual && this.renderStepMsg(index, step, nextStep)}
                      {step.sender === 'bot' && botMetadata.length > 0 && (
                        <span className={classes.botMetadataBubble} dangerouslySetInnerHTML={{ __html: botMetadata.map(m => `<strong>${m.label}:</strong> ${m.value}`).join(' | ') }} />
                      )}
                    </ListItemText>
                    {step.sender === 'me' && (
                      <ListItemIcon>
                        <GridContainer top padding>
                          <GridItem>
                          <img src={BotiumIcon} alt="botium" width="32" />
                          </GridItem>
                        </GridContainer>
                      </ListItemIcon>
                    )}
                    <ListItemIcon>
                      <Text danger>
                        <Button justIcon aria-label="View Code" onClick={() => this.setState({ code: step, codeAttachmentsIndex: index })}>
                          <ShowIcon icon="code" />
                        </Button>
                      </Text>
                    </ListItemIcon>

                    {hasAnyAttachments && <ListItemIcon>
                      <Button justIcon disabled={!hasAttachments} aria-label="View Attachments" onClick={({ currentTarget }) => showAttachmentsIndex === index ? this.setState({ showAttachmentsIndex: null, showAttachmentsAnchorEl: null }) : this.setState({ showAttachmentsIndex: index, showAttachmentsAnchorEl: currentTarget })}>
                        <ShowIcon icon="paperclip" />
                      </Button>
                    </ListItemIcon>}
                    <Popper open={!!showAttachments} anchorEl={showAttachmentsAnchorEl} modifiers={{computeStyle: {gpuAcceleration: false}}} placement="bottom-end" transition style={{zIndex: 1500}}>
                      {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={() => this.setState({ showAttachmentsIndex: null, showAttachmentsAnchorEl: null })}>
                        <Fade {...TransitionProps} timeout={350}>

                          <Paper className={classes.popupAttachments}>
                            {this.renderStepAttachments(index, step)}
                          </Paper>
                        </Fade>
                    </ClickAwayListener>
                      )}
                    </Popper>


                </ListItem>
                  </>
                }
                {step.sender === 'bot' && step.err && !step.errDetails && step.expected && step.expected.messageText &&
                <ListItem key={`${index}_err`} data-unique={`${index}_err`}>
                  <ListItemIcon>
                  <GridContainer>
                        <GridItem>
                        <AvatarImage chatbotId={chatbotId} avatar={avatar} containermode={containermode} error/>
                        </GridItem>
                      </GridContainer>

                  </ListItemIcon>
                  <ListItemText tabIndex="0" inset
                    className={classNames({
                      [classes.senderBot]: true,
                      [classes.borderDanger]: true
                    })}
                  >
                    {step.not && 'NOT EXPECTED'}{!step.not && 'EXPECTED'}: {step.expected && step.expected.messageText}
                  </ListItemText>
                  <ListItemIcon>
                    <Text danger>
                      <Button justIcon aria-label="View Code" onClick={() => this.setState({code: step})}>
                        <ShowIcon icon="code" />
                      </Button>
                    </Text>
                  </ListItemIcon>
                  {hasAnyAttachments && <ListItemIcon><Button justIcon disabled aria-label="View Attachments" ><ShowIcon icon="paperclip" /></Button></ListItemIcon>}
                </ListItem>
                }
                {step.sender === 'bot' && step.errDetails && (_.isArray(step.errDetails) ? step.errDetails : [step.errDetails]).map((errDetail, errDetailIndex) => (
                  <ListItem key={`${index}_err_${errDetailIndex}`} data-unique={`${index}_err_${errDetailIndex}`} noPaddingVertical>
                    <ListItemIcon>
                    <GridContainer top>
                        <GridItem>
                          <ShowIcon custom icon="error" hidden={errDetailIndex > 0} />
                      </GridItem>
                      </GridContainer>
                    </ListItemIcon>
                    <ListItemText tabIndex="0" inset
                      className={classes.errorBubble}
                    >
                      {errDetail.type !== 'asserter' && errDetail.message &&
                      <React.Fragment><Text>{errDetail.message}</Text></React.Fragment>}
                      {errDetail.type === 'asserter' && <Text white>
                        <div><Text bold>ASSERTION FAILED in {errDetail.source}{errDetail.subtype && ` (${errDetail.subtype})`}</Text></div>
                        {
                          errDetail.cause &&
                          errDetail.cause.expected &&
                          !errDetail.cause.not &&
                          <div>
                            <Text bold inline> - Expected: </Text>
                            <Text inline>{getExpectedText(errDetail.cause.expected)}</Text>
                          </div>
                        }
                        {
                          errDetail.cause &&
                          errDetail.cause.expected &&
                          errDetail.cause.not &&
                          <div>
                            <Text bold inline> - NOT Expected: </Text>
                            <Text inline>{getExpectedText(errDetail.cause.expected)}</Text>
                          </div>
                        }
                        {
                          errDetail.cause &&
                          errDetail.cause.actual &&
                          <div>
                            <Text bold inline> - Actual: </Text>
                            <Text inline>{getActualText(errDetail.cause.actual)}</Text>
                          </div>
                        }
                        {
                          errDetail.cause &&
                          !errDetail.cause.actual &&
                          <div>
                            <Text bold> - Actual:</Text> empty
                          </div>
                        }
                      </Text>}
                      {errDetail.type !== 'asserter' && !errDetail.message && 'Special error (click on Code button for details)'}
                    </ListItemText>
                    <ListItemIcon>
                      <Text danger>
                        <Button justIcon aria-label="View Code" onClick={() => this.setState({code: { errDetail }})}>
                          <ShowIcon icon="code" />
                        </Button>
                      </Text>
                    </ListItemIcon>
                    {hasAnyAttachments && <ListItemIcon><Button justIcon disabled aria-label="View Attachments" ><ShowIcon icon="paperclip" /></Button></ListItemIcon>}
                  </ListItem>
                ))}
              </React.Fragment>
            )
          })}
        </List>
        <ConfirmationDialog
          cancelText="Close"
          open={!!this.state.code}
          onCancel={() => this.setState({ code: null, codeAttachmentsIndex: null })}
          maxWidth="lg"
          extraButton={<>
            {this.state.code && this.state.code.actual && <Button secondary
              onClick={() => {
                const actualBotiumCodeWithoutBuffers = JSON.stringify(removeBuffers(_.omit(this.state.code.actual, ['attachments']), false, false), null, 2)
                copyToClipboard(actualBotiumCodeWithoutBuffers)
              }}
            >
              <ShowIcon icon="clipboard" />
              Copy Actual Code to Clipboard
            </Button>}
            {this.state.code && this.state.code.expected && <Button secondary
              onClick={() => {
                const expectedBotiumCodeWithoutBuffers = JSON.stringify(removeBuffers(this.state.code.expected, false, false), null, 2)
                copyToClipboard(expectedBotiumCodeWithoutBuffers)
              }}
            >
              <ShowIcon icon="copy" />
              Copy Expected Code to Clipboard
            </Button>}
          </>}
          >
          <CustomTabsSecondary
            noCard
            skipPersist
            headerColor="info"
            tabs={[
              this.state.code && this.state.code.actual && {
                tabName: 'Actual Botium Code',
                tabContent: <GridContainer>
                  <GridItem xs={12}>
                    <CodeMirror
                      className={'ExpectedActual'}
                      value={JSON.stringify(removeBuffers(_.omit(this.state.code.actual, ['attachments']), true, true), null, 2)}
                      options={{
                        lineNumbers: true,
                        cursorHeight: 0.8,
                        readOnly: true,
                        mode: 'application/json',
                        theme: isDarkmode() ? 'botium-darkmode' : 'default'
                      }}
                    />
                  </GridItem>
                </GridContainer>
              },
              this.state.code && this.state.code.expected && {
                tabName: 'Expected Botium Code',
                tabContent: <GridContainer>
                  <GridItem xs={12}>
                    <CodeMirror
                      className={'ExpectedActual'}
                      value={JSON.stringify(removeBuffers(this.state.code.expected, true, true), null, 2)}
                      options={{
                        lineNumbers: true,
                        cursorHeight: 0.8,
                        readOnly: true,
                        mode: 'application/json',
                        theme: isDarkmode() ? 'botium-darkmode' : 'default'
                      }}
                    />
                  </GridItem>
                </GridContainer>
              },
              this.state.code && this.state.code.errDetail && {
                tabName: 'Error Message',
                tabContent: <GridContainer>
                  <GridItem xs={12}>
                    <CodeMirror
                      value={JSON.stringify(removeBuffers(this.state.code.errDetail), null, 2)}
                      rows={12}
                      options={{
                        lineNumbers: true,
                        cursorHeight: 0.8,
                        readOnly: true,
                        mode: 'application/json',
                        theme: isDarkmode() ? 'botium-darkmode' : 'default'
                      }}
                    />
                  </GridItem>
                </GridContainer>
              },
              this.state.code && !this.state.code.actual && !this.state.code.expected && !this.state.code.errDetail && {
                tabName: 'Botium Code',
                tabContent: <GridContainer>
                  <GridItem xs={12}>
                    <CodeMirror
                      value={JSON.stringify(removeBuffers(this.state.code), null, 2)}
                      rows={12}
                      options={{
                        lineNumbers: true,
                        cursorHeight: 0.8,
                        readOnly: true,
                        mode: 'application/json',
                        theme: isDarkmode() ? 'botium-darkmode' : 'default'
                      }}
                    />
                  </GridItem>
                </GridContainer>
              },
              actualAttachmentTiles.length > 0 && {
                tabName: 'Attachments',
                tabContent: <GridContainer>
                  <GridItem xs={12}>
                    <ImageTiles
                      images={actualAttachmentTiles}
                      cards
                    />
                </GridItem>
              </GridContainer>
            }].filter(t => t)}
          />
        </ConfirmationDialog>
      </React.Fragment>
    )
  }
}

Transcript.defaultProps = {}

Transcript.propTypes = {
  classes: PropTypes.object.isRequired,
  chatbotId: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.any).isRequired,
  allowHtmlDisplay: PropTypes.bool,
  attachments: PropTypes.arrayOf(PropTypes.any)
}

export default withRouter(
  connect(
    state => state,
    { setAlertSuccessMessage },
  )(withStyles(convoStyle)(Transcript)),
)
