import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import AddIcon from '@material-ui/icons/Add'
import Field from 'components/Form/OptionalField'
import { FieldArray } from 'react-final-form-arrays'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  required
} from 'components/Form/Form'
import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'


class EnvironmentVariablesEdit extends React.Component {
  render() {
    const { field, disabled } = this.props
    const sectionName = 'Environment Variable'

    return (
      <GridContainer>
        <FieldArray name={field}>
          {({ fields }) =>
            fields.map((name, index) => (
              <GridItem xs={12} key={index}>
                <GridContainer>
                  <GridItem sm={3} >
                    <Field
                      name={`${name}.name`}
                      component={renderTextField}
                      label={`${sectionName} #${index + 1}`}
                      validate={required}
                      disabled={disabled}
                      data-unique={`txtEnvironmentVariablesEditName_${index}`}
                    />
                  </GridItem>
                  <GridItem sm={6} >
                      <Field
                        name={`${name}.stringValue`}
                        component={renderTextField}
                        label={`${sectionName} Value`}
                        disabled={disabled}
                        data-unique={`txtEnvironmentVariablesEditValue_${index}`}
                      />
                  </GridItem>
                  {!disabled &&
                    <GridItem sm={1} bottom largePadding>
                      <Tooltip title="Delete" >
                        <Button
                          aria-label="Delete"
                          justIcon
                          onClick={() => fields.remove(index)}
                          data-unique={`btnEnvironmentVariablesEditRemove_${index}`}
                        >
                          <ShowIcon icon="trash" />
                        </Button>
                      </Tooltip>
                    </GridItem>
                  }
                </GridContainer>
              </GridItem>
            ))
          }
        </FieldArray>
        {!disabled &&
          <GridItem xs={12}>
            <Button
              secondary
              dashed
              fullWidth
              noMargin
              onClick={() => this.props.push(field, { })}
            >
              <AddIcon />
              Register new {sectionName}
            </Button>
          </GridItem>
        }
      </GridContainer>
    )
  }
}

EnvironmentVariablesEdit.propTypes = {
  push: PropTypes.func.isRequired,
  pop: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default EnvironmentVariablesEdit
