import { gql } from 'apollo-boost'

export const SEARCH_TESTSESSIONS_QUERY = gql`
  query TestSessionsQuery($searchTerm: String!) {
    testsessions(
      where: {
        name_contains: $searchTerm
      }
      orderBy: updatedAt_DESC
    ) {
      id
    }
  }
`

export const SEARCH_TESTPROJECTS_QUERY = gql`
  query TestProjectsQuery($searchTerm: String!) {
    testprojects(
      where: {
        OR: [
          { name_contains: $searchTerm }
          { code_contains: $searchTerm }
          { description_contains: $searchTerm }
          {
            chatbot: {
              OR: [
                { name_contains: $searchTerm }
                { description_contains: $searchTerm }
              ]
            }
          }
          {
            testSets_some: {
              OR: [
                { name_contains: $searchTerm }
                { description_contains: $searchTerm }
              ]
            }
          }
          {
            deviceSets_some: {
              OR: [
                { name_contains: $searchTerm }
                { description_contains: $searchTerm }
              ]
            }
          }
        ]
      }
      orderBy: updatedAt_DESC
    ) {
      id
    }
  }
`

export const SEARCH_TESTSETS_QUERY = gql`
  query TestSetsQuery($searchTerm: String!) {
    testsets(
      where: {
        OR: [
          { name_contains: $searchTerm }
          { description_contains: $searchTerm }
        ]
      }
      orderBy: updatedAt_DESC
    ) {
      id
      name
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      statsConvoCount
      statsPartialConvoCount
      statsUtterancesRefCount
      statsUtterancesCount
      statsScriptingMemoryCount
    }
  }
`

export const SEARCH_CHATBOTS_QUERY = gql`
  query ChatbotsQuery($searchTerm: String!) {
    chatbots(
      where: {
        OR: [
          { name_contains: $searchTerm }
          { description_contains: $searchTerm }
        ]
      }
      orderBy: updatedAt_DESC
    ) {
      id
      name
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      avatar
      allowHtmlDisplay
      containermode
    }
  }
`
