import React, {useContext} from 'react'
import { CustomCheckbox } from 'components/Form/Form'
import { ThemeContext } from 'themeUtils'
import ShowIcon from '../Icon/ShowIcon'
import themeTogglerStyle from 'assets/jss/material-dashboard-react/components/themeTogglerStyle'
import { withStyles } from '@material-ui/core'
// import { DarkModeToggle } from 'react-dark-mode-toggle-2'

function ThemeToggler({ classes, ...rest }) {

  const { _setThemeName, themeName } = useContext(ThemeContext)

    return <CustomCheckbox 
    formControlProps={{
        fullWidth: false,
        ...rest
    }}
    switchClasses={{
        bar: classes.bar,
        switchBase: classes.switchBase,
        label: classes.labelRoot,
    }}
    icon={<div className={classes.icon}><ShowIcon className={classes.iconColor}  icon="sun" /></div>}
    checkedIcon={<div className={classes.iconChecked}><ShowIcon icon="moon" /></div>}
    input={{
        checked: themeName === 'darkTheme',
        onChange: e => {
            if(themeName === 'darkTheme') {
                localStorage.setItem('themeName', 'lightTheme')
                _setThemeName('lightTheme')
                const event = new CustomEvent('isDarkmode', { detail: false })
                document.dispatchEvent(event)
            } else {
                localStorage.setItem('themeName', 'darkTheme')
                _setThemeName('darkTheme')
                const event = new CustomEvent('isDarkmode', { detail: true })
                document.dispatchEvent(event)
            }
          },
    }} />
}

export default withStyles(themeTogglerStyle)(ThemeToggler)