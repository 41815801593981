import {gql} from 'apollo-boost'

export const VALIDATETESTDATAWIZARDCONVO_QUERY = gql`
  query ValidateTestDataWizardConvoQuery(
    $name: String
    $script: String!
  ) {
    validatetestdatawizardconvo(name: $name, script: $script) {
      name
      description
      script
      steps {
        sender
        messageText
        not
        optional
        asserters { name args not optional }
        logicHooks { name args }
        userInputs { name args }
        utteranceSamples
        utteranceCount
      }
      scriptType
      err
    }
  }
`

export const VALIDATETESTDATAWIZARDUTTERANCES_QUERY = gql`
  query ValidateTestDataWizardUtterancesQuery(
    $name: String
    $script: String!
  ) {
    validatetestdatawizardutterances(name: $name, script: $script) {
      name
      script
      utterances
      scriptType
      err
    }
  }
`

export const COPY_TESTDATAWIZARDSCRIPTSINTOTESTSET = gql`
  mutation CopyTestDataWizardScriptsIntoTestSet(
    $namespace: String,
    $testSetId: ID
    $newTestSetName: String
    $nlpOnly: Boolean!
    $createTestProject: Boolean!
    $chatbotId: ID
    $importMode: TestSetImportMode!
    $testDataWizardScripts: [TestDataWizardScript!]
  ) {
    copyTestDataWizardScriptsIntoTestSet(
      namespace: $namespace
      testSetId: $testSetId
      newTestSetName: $newTestSetName
      nlpOnly: $nlpOnly
      createTestProject: $createTestProject
      chatbotId: $chatbotId
      importMode: $importMode
      testDataWizardScripts: $testDataWizardScripts
    ) {
      testSetId
      skippedNames
      overwrittenNames
      backupNames
      newNames
    }
  }
`

export const TEST_DATA_WIZARD_SAMPLES = gql`
  query TestDataWizardSamples {
    testdatawizardsamples {
      domain
      language
      descriptions
    }
  }
`

export const TEST_DATA_WIZARD_DESCRIPTION = gql`
  query TestDataWizardConvoDescription($chatbotDomain: String!, $chatbotDescription: String!) {
    testdatawizardconvodescription(chatbotDomain: $chatbotDomain, chatbotDescription: $chatbotDescription)
  }
`

export const TEST_DATA_WIZARD_SCRIPT = gql`
  query TestDataWizardScript($chatbotDomain: String!, $chatbotDescription: String!, $convoDescription: String, $scriptType: TestSetScriptType!) {
    testdatawizardscript(chatbotDomain: $chatbotDomain, chatbotDescription: $chatbotDescription, convoDescription: $convoDescription, scriptType: $scriptType)
  }
`

export const TEST_DATA_WIZARD_NLU_CONVERSATION_START_MESSAGES = gql`
  query TestDataWizardConvoDescription($chatbotDomain: String!, $chatbotDescription: String!) {
    testdatawizardconvodescription(chatbotDomain: $chatbotDomain, chatbotDescription: $chatbotDescription)
  }
`
