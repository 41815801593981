const expansionStyle = theme => ({
  ExpansionPanelroot: {
    borderRadius: '6px !Important',
    boxShadow: '0px 0px 0px red',
    border: `1px solid ${theme.colors.expansionborderColor}`,
    background: theme.colors.expansionBGcolor,
    minHeight: 60,
    color: `${theme.colors.expansionbordercontenttext} !Important`,
    fontWeight: 500,
    marginBottom: 15,
    '&:before': {
      height: '0',
    },
    '&:last-child': {
      marginBottom: 25,
    },
  },
  ExpansionPanelSummaryexpandIcon: {
    color: theme.colors.expansionbordercontenttext,
  },
  ExpansionPanelSummaryroot: {
    paddingBottom: '0 !Important',
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 5,
      background: 'transparent'
    },

  },

  ExpansionPanelSummarycontent: {
    //padding: '20px 0  !Important',
    position: 'relative',
    //top: 6,
    marginTop: 15
  },

  ExpansionPanelSummaryexpandexpanded: {

    '& div': {
      //padding: '20px 0  !Important',
      //position: 'relative',
      //top: 0,
    },
    '& div:nth-child(2)': {
      //padding: '20px 0  !Important',
      //position: 'relative',
    },

  },
  ExpansionPanelDetailsroot: {
    borderTop: `1px solid ${theme.colors.expansionborderColor}`,
    padding: '15px 10px'
  },

  ExpansionPanelEntered: {
    display: 'block!Important',
  },
  ExpansionPanelContainer: {
    display: 'none',
  },
  

})

export default expansionStyle
