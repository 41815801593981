import React from 'react'
import {gql} from 'apollo-boost'
import {Query} from 'react-apollo'

import {renderProgressOrError} from '../../../helper'
import {ConfusionMatrixTable} from './ConfusionMatrixTable'
import MessageBox from 'components/Info/MessageBox'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'

const GET_CONFUSION_MATRIX_QUERY = gql`
  query ConfusionMatrixQuery($id: ID!) {
    testsession(id: $id) {
      id
      trainerSession {
        id
        status
        cleanedUp
        confusionMatrix
      }
    }
  }
`

export const renderConfusionMatrix = (testSessionId, testProjectId) => {
  return (<Query
    query={GET_CONFUSION_MATRIX_QUERY}
    variables={{id: testSessionId}}
  >
    {({loading, error, data}) => {
      let progressOrError = renderProgressOrError({loading, error})
      if (progressOrError) {
        return progressOrError
      }

      let confusionMatrix = JSON.parse(data.testsession.trainerSession.confusionMatrix)

      if(confusionMatrix && confusionMatrix.length === 0) {
        return <GridContainer>
          <GridItem xs={false} sm={3} />
          <GridItem xs={12} sm={6}>
            <MessageBox variant="info" title="Botium is not able to display the Confusion Matrix" text="There is no data to show." />
          </GridItem>
          <GridItem xs={false} sm={3} />
        </GridContainer>
      }

      return (<ConfusionMatrixTable testSessionId={testSessionId} confusionMatrix={confusionMatrix} testProjectId={testProjectId}/>)
    }}
  </Query>)

}
