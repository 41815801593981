import React from 'react'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { compose, withApollo } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { gql } from 'apollo-boost'
import * as Sentry from '@sentry/react'
import withStyles from '@material-ui/core/styles/withStyles'

import { clearLogin } from 'actions/token'
import { setAvailableConnectors, setAllConnectors, setLicense, setFeatures, clearAvailableConnectors, clearLicense } from 'actions/settings'
import entryRoutes from 'routes/entry.jsx'
import config from 'config'

import ErrorPage from 'components/Page/ErrorPage'
import LoadingPage from 'components/Page/LoadingPage'

import EntryPage from 'layouts/Entry/Entry.jsx'
import DashboardPage from 'layouts/Dashboard/Dashboard.jsx'

import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx'

import { FULL_LICENSE_FRAGMENT, FULL_CONNECTORDESCRIPTOR_FRAGMENT, FULL_FEATURES_FRAGMENT } from '../views/Settings/gql'
import { Hidden } from '@material-ui/core'
import GridContainer from './Grid/GridContainer'
import GridItem from './Grid/GridItem'
import BotiumBoxLogo from './Typography/BotiumBoxLogo'
import Text from './Typography/Text'

import screenSizeImage from 'assets/img/botium_screen_size_limit.svg'

const SETTINGS_QUERY = gql`
  query SettingsQuery {
    me { type id name }
    license {
      ...FullLicense
    }
    availableconnectors {
      ...FullConnectorDescriptor
    }
    allconnectors {
      ...FullConnectorDescriptor
    }
    featureSwitches {
      ...FullFeatureSwitches
    }
  }
  ${FULL_LICENSE_FRAGMENT}
  ${FULL_CONNECTORDESCRIPTOR_FRAGMENT}
  ${FULL_FEATURES_FRAGMENT}
`

const hist = createBrowserHistory()
if (window.Cypress) {
  window.boxHistory = hist
}

export const IsDirtyContext = React.createContext({
  isDirty: false,
  setDirty: () => {},
})

class RootContainer extends React.Component {
  constructor(props) {
    super(props)

    this.props.clearAvailableConnectors()
    this.props.clearLicense()

    this.setDirty = (value) => {
      this.setState(state => ({
        isDirty: value
      }))
    }
    this.state = {
      isDirty: false,
      setDirty: this.setDirty,
      loadErr: null,
      loadReady: false
    }
  }

  componentDidMount() {
    const { client, setAvailableConnectors, setAllConnectors, setLicense, setFeatures } = this.props

    client.query({
      query: SETTINGS_QUERY,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setAvailableConnectors(data.availableconnectors)
      setAllConnectors(data.allconnectors)
      setLicense(data.license)
      setFeatures(data.featureSwitches)

      if (data.featureSwitches && data.featureSwitches.sentryDsn) {
        console.log('Initializing Sentry Error Reporting')
        Sentry.init({
          dsn: data.featureSwitches.sentryDsn,
          release: `${config.buildBranch}-${config.buildRevision}-${config.buildDate}`
        })
      }
      this.setState({ loadErr: null, loadReady: true })
    }).catch (err => {
      this.setState({ loadErr: err, loadReady: true })
    })

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        if (document.activeElement) {
          if (document.activeElement.tagName === 'BUTTON') return
          const noClickEl = document.activeElement.getAttribute('data-click') === 'false'
          if (noClickEl) return
          const clickEl = document.activeElement.querySelector('[data-click="true"]')
          if (clickEl) {
            clickEl.click()
          } else {
            document.activeElement.click()
          }
        }
      }
    })
  }

  renderWrapperComponent = (children) => {
    const { classes } = this.props
      
    return <>
      <Hidden only={['md', 'xl', 'lg']} implementation="js">
      <div className={classes.wrapper + ' ' + classes.wrapperDark}>{children}</div>
      </Hidden>
      <Hidden only={['xs', 'sm', ]} implementation="js">
      <div className={classes.wrapper}>{children}</div>
      </Hidden>
    </>
  }

  render() {
    const { classes } = this.props
    const { loadErr, loadReady } = this.state

    return (
      <IsDirtyContext.Provider value={this.state} >
        <Helmet titleTemplate="%s | Botium">
          <title>Dashboard</title>
        </Helmet>
        {this.renderWrapperComponent(<>
          {!loadReady && <div className={classes.container}><LoadingPage /></div>}
          {loadReady && loadErr && <div className={classes.container}><ErrorPage error={loadErr} /></div>}
          {loadReady && !loadErr &&
            <Sentry.ErrorBoundary fallback={({ error, resetError }) => <ErrorPage error={error} resetError={resetError} />}>
              <Hidden only={['md', 'xl', 'lg']}>
                <GridContainer darkOnly>
                   <GridItem xs={12} middle center largePadding>
                     <GridContainer>
                        <GridItem xs={12} center>
                          <BotiumBoxLogo white large />
                        </GridItem>
                        <GridItem xs={12} middle center largePadding>
                          <Text white header>Screen size not supported.</Text>
                        </GridItem>
                        <GridItem xs={12} center>
                          <Text white>We are sorry but this screen size is not supported.</Text>
                        </GridItem>
                        <GridItem xs={12} center>
                          <Text white>Please switch to a larger screen size.</Text>
                        </GridItem>
                     </GridContainer>
                   </GridItem>  
                   <GridItem xs={12} middle center largePadding>
                     <GridContainer>
                        <GridItem xs={12} center>
                          <img src={screenSizeImage} alt="screen" />
                        </GridItem>
                     </GridContainer>
                   </GridItem>  
                </GridContainer>
              </Hidden>
              <Hidden only={['xs', 'sm', ]}>
                <Router history={hist}>
                  <Switch>
                    {entryRoutes.map((prop, key) => <Route path={prop.path} component={EntryPage} key={key} />)}
                    <Route path="/" component={DashboardPage} />
                  </Switch>
                </Router>
              </Hidden>
            </Sentry.ErrorBoundary>
          }
        </>)}
      </IsDirtyContext.Provider>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    state => ({ }),
    { setAvailableConnectors, setAllConnectors, setLicense, setFeatures, clearLogin, clearAvailableConnectors, clearLicense }
  )
)(withApollo(RootContainer))
