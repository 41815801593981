import React from 'react'

import Field from 'components/Form/OptionalField'

// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  renderTextField,
} from 'components/Form/Form'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function witaiCaps2Form(caps) {
  return {
    witai: {
      token: (
        caps.find(c => c.name === 'WITAI_TOKEN') || {stringValue: ''}
      ).stringValue,
    }
  }
}

const capNamesMap = {
  'witai.token': 'WITAI_TOKEN'
}

export function witaiForm2caps(values) {
  const capabilities = [
    {name: 'WITAI_TOKEN', type: 'STRING', stringValue: values.witai.token},
    {name: 'CONTAINERMODE', type: 'STRING', stringValue: 'witai'}
  ]
  return capabilities
}

export class WITAIEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { disabled, capSetCapNames } = this.props
    return (
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Field
            name="witai.token"
            component={renderTextField}
            label="Token"
            validate={required}
            data-unique="txtWitAiEditToken"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['witai.token']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['witai.token'], 'Get it from the app\'s Settings tab in the wit.ai console')}
          />
        </GridItem>
      </GridContainer>
    )
  }
}
