import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { putRecentListEntry } from 'actions/activity'
import { decodeURIComponentWeak } from 'views/helper'

class NavbarHeader extends React.Component {

  _putRecent() {
    const { putRecentListEntry, putRecentArgs } = this.props

    if (putRecentArgs) {
      putRecentListEntry(putRecentArgs)
    }
  }
  componentDidMount() {
    this._putRecent()
  }
  componentDidUpdate() {
    this._putRecent()
  }

  render() {
    const { text, title } = this.props
    if (text) {
      return <>
        <Helmet><title>{decodeURIComponentWeak(title || text)}</title></Helmet>
        {decodeURIComponentWeak(text)}
      </>
    }
    return null
  }
}

export default connect(
  () => ({ }),
  { putRecentListEntry }
)(NavbarHeader)
