import React, { createRef } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query, compose } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import SimpleTable from 'components/Table/Table.jsx'

import Tooltip from 'components/Tooltip/NativeTooltip'
import QueryStatus from 'components/Info/QueryStatus'
import ShowIcon from 'components/Icon/ShowIcon'
import Chip from 'components/Chip/Chip'
import Text from 'components/Typography/Text'
import { toFixedDecimals } from 'helper/numberHelper'
import BarArray from 'components/Stats/Charts/BarArray'

import TestSessionProgressPie from './TestSessionProgressPie.jsx'
import { getFallbackTooltip } from './GDPRSessionSuggestions.jsx'

import { TESTSESSION_REPORT_QUERY_GDPR } from './gql'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import { isDarkmode, ThemeSetter } from 'components/Darkmode/helper.jsx'

class GDPRSessionReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      oldDarkmode: null
    }
    this.printRef = createRef()
  }

  renderContent(testsessiontestcaseresults, print = false) {
    const { classes, theme, testSession } = this.props

    const _sections = [
      { section: 'SECURITY', color: theme.colors.gdprSecurity, help: 'Assessing your chatbots ability to answer data security related questions. For example: "How do you protect my data ?"' },
      { section: 'CONTROL', color: theme.colors.gdprControl, help: 'Determining how well customers can exercise their rights while interacting with your chatbot. For example: "Delete my personal data"' },
      { section: 'TRANSPARENCY', color: theme.colors.gdprTransparency, help: 'Assessing your chatbots ability to answer data security related questions. For example: "How do you protect my data ?"' },
      { section: 'PRIVACY', color: theme.colors.gdprPrivacy, help: 'Assessing your chatbots ability to answer data security related questions. For example: "How do you protect my data ?"' },
      { section: 'OTHER', color: 'black', help: 'Other questions from none of the previous categories' }
    ]
    const _renderSectionChip = (section) => {
      const _section = _sections.find(s => s.section === section) || _sections.find(s => s.section === 'OTHER')
      return <Chip label={section} key={section} slimmer variant="white" style={{ background: _section.color }}/>
    }

    const chartData = testSession && testSession.chartData && JSON.parse(testSession.chartData)
    const fallbackTooltip = getFallbackTooltip(testSession)

    const results = (testsessiontestcaseresults || []).map(r => {
      if (r.steps) {
        r.steps.forEach(s => {
          if (s.actual && _.isString(s.actual)) {
            s.actual = JSON.parse(s.actual)
          }
        })
      }
      const meStep = r.steps.find(s => s.sender === 'me')
      const botStep = r.steps.find(s => s.sender === 'bot')
      const _section = _sections.find(s => s.section === r.testcaseName.split('/')[0]) || _sections.find(s => s.section === 'OTHER')

      return {
        section: _section.section,
        success: r.success,
        err: r.err,
        question: (meStep && meStep.actual && meStep.actual.messageText) || '',
        answer: (botStep && botStep.actual && botStep.actual.messageText) || ''
      }
    })

    const errAnswers = _.orderBy(Object.keys(_.groupBy(results.filter(r => !r.success && (r.question || r.answer)), r => r.answer)).map(a => ({
      answer: a,
      sections: _.uniq(results.filter(r => !r.success && r.answer === a).map(r => r.section)),
      count: results.filter(r => !r.success && r.answer === a).length
    })), a => a.count, ['desc'])

    const successAnswers = _.orderBy(Object.keys(_.groupBy(results.filter(r => r.success && (r.question || r.answer)), r => r.answer)).map(a => ({
      answer: a,
      sections: _.uniq(results.filter(r => r.success && r.answer === a).map(r => r.section)),
      count: results.filter(r => r.success && r.answer === a).length
    })), a => a.count, ['desc'])

    const usedSections = _sections.filter(s => results.filter(r => r.section === s.section).length > 0)

    return <div ref={el => print ? this.printRef = el : null}><GridContainer >
      <GridItem xs={12} sm={8} largeMarginTop largeMarginBottom>
        <Text header>Summary</Text>
      </GridItem>
      <GridItem xs={12} sm={4} right>
        {!print && <>
        <ThemeSetter>
          {({ setDarkmode }) => <ReactToPrint
            onBeforeGetContent={() => {
              return new Promise((resolve, reject) => {
                this.setState({ oldDarkmode: isDarkmode() })
                setDarkmode(false)
                setTimeout(() => resolve(), 10)
              })
            }}
            onAfterPrint={() => setDarkmode(this.state.oldDarkmode)}
            trigger={() => <Button><ShowIcon icon="print" /> Print GDPR Report</Button>}
            content={() => this.printRef}
          />}
        </ThemeSetter>
        </>}
      </GridItem>
      <GridItem xs={5} grid>
        <Card>
          <CardHeader>
            <Text header>GDPR Test Results</Text>
          </CardHeader>              
          <CardBody>            
            <GridContainer>
              <GridItem xs={6} center className={classes.pieposition}>
                <TestSessionProgressPie testSession={testSession} />
              </GridItem>
              <GridItem xs={6}>
                <GridContainer  >
                  <GridItem xs={2}>
                    <ShowIcon custom icon="success" />
                  </GridItem>
                  <GridItem xs={10}>
                    <Text mlg bold inline>{!chartData ? 0 : toFixedDecimals(chartData.totalCount > 0 ? chartData.successCount * 100 / chartData.totalCount : 0, 2)} %</Text>
                    <Text md regular>Custom Answers</Text>
                  </GridItem>
                  <GridItem xs={10} smallPadding>
                    {chartData &&
                      <BarArray
                      classes={{
                        root: classes.rootwidthshort,
                      }}
                        withBackground
                        simpleMode
                        bars={[
                          {
                            color: theme.colors.common.success,
                            value: chartData.totalCount > 0 ? chartData.successCount / chartData.totalCount * 100 : 0
                          },
                        ]}
                      />
                    }
                  </GridItem>
                </GridContainer>
                <Tooltip title={fallbackTooltip}>
                  <GridContainer>
                    <GridItem xs={2}>
                      <ShowIcon custom icon="error" />
                    </GridItem>
                    <GridItem xs={10}>
                      <Text mlg bold inline>{!chartData ? 0 : toFixedDecimals(chartData.totalCount > 0 ? chartData.failedCount * 100 / chartData.totalCount : 0, 2)} %</Text>
                      <Text md regular>Fallback Answers</Text>
                    </GridItem>
                    <GridItem xs={10} smallPadding>
                      {chartData &&
                        <BarArray
                        classes={{
                          root: classes.rootwidthshort,
                          
                        }}
                          withBackground
                          simpleMode
                          bars={[
                            {
                              color: theme.colors.common.danger,
                              value: chartData.totalCount > 0 ? chartData.failedCount / chartData.totalCount * 100 : 0
                            },
                          ]}
                        />
                      }
                    </GridItem>
                  </GridContainer>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem sm={7}>
        <Card>
          <CardHeader>
            <Text header>GDPR Question Topics</Text>
          </CardHeader>              
          <CardBody>
            <GridContainer >
              {usedSections.map((s, key) => <React.Fragment key={key}>
                <GridItem xs={12}>
                  <Text subheader>{s.section}</Text>
                </GridItem>
                <GridItem xs={5}>
                  <BarArray classes={{
                      root: classes.rootwidth,
                      bar: classes.barheight,
                      barBackground: classes.barbgheight
                    }}
                    withBackground
                    simpleMode
                    bars={[
                      {
                        color: s.color,
                        value: results.filter(r => r.section === s.section).length / results.length * 100
                      },
                    ]}
                  />
                </GridItem>
                <GridItem xs={1} middle>
                  <Text>{results.filter(r => r.section === s.section).length}</Text>
                </GridItem>
                <GridItem xs >
                  <Text bggrey bgradius>{s.help}</Text>
                  
                </GridItem>
              </React.Fragment>)}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      {(errAnswers.length > 0 || successAnswers.length > 0) && <>
        <GridItem sm={6}>
          <Card>
            <CardHeader>
              <Text header>Fallback Answers</Text>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} noPadding>
                  <SimpleTable
                    tableHead={['Answer', 'Occurences', '']}
                    tableData={errAnswers.map(r => [
                      <Text danger>{r.answer}</Text>,
                      <Text>{r.count}</Text>,
                      <Text>{r.sections.map(s => _renderSectionChip(s))}</Text>
                    ])}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem sm={6}>
          <Card>
            <CardHeader>
              <Text header>Top 5 Custom Answers</Text>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} noPadding>
                  <SimpleTable
                    tableHead={['Answer', 'Occurences', '']}
                    tableData={successAnswers.slice(0, 5).map(r => [
                      <Text success>{r.answer}</Text>,
                      <Text>{r.count}</Text>,
                      <Text>{r.sections.map(s => _renderSectionChip(s))}</Text>
                    ])}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </>}
      {results.filter(r => (r.question || r.answer)).length > 0 && <>
        <GridItem xs={12} largeMarginTop largeMarginBottom>
          <Text header>All Questions and Answers</Text>
        </GridItem>
        {usedSections.map((s, key) => {
          const resultLines = results.filter(r => r.section === s.section && (r.question || r.answer))
          if (resultLines.length === 0) return null

          const _content = (print) => {
              const wrapper = content => print ? 
                <Card><CardBody noPadding>{content}</CardBody></Card> : <GridContainer fullWidth><GridItem noPadding xs={12}>{content}</GridItem></GridContainer>
              return wrapper(<SimpleTable
                print={print}
                tableHead={['Question', 'Answer']}
                tableData={resultLines.map(r => [
                  <Text>{r.question}</Text>,
                  r.success ? <Text success>{r.answer}</Text> : <Text danger>{r.answer}</Text>
                ])
                }
              />)
          }

          if (print) {
            return <GridItem xs={12} key={key} largeMarginTop>
                <Text subheader>{_renderSectionChip(s.section)}</Text>
                {_content(true)}
            </GridItem>
          } else {
            return <GridItem xs={12} key={key} >
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  {_renderSectionChip(s.section)}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {_content(false)}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </GridItem>
          }
        })}
      </>}
    </GridContainer></div>
  }

  render() {
    const { testSession } = this.props

    if (!testSession) return null
    if (!testSession.gdprTestSessionAudit) return null

    return <Query query={TESTSESSION_REPORT_QUERY_GDPR} variables={{ id: testSession.id }}>
      {(queryResult) => <QueryStatus {...queryResult} query="testsessiontestcaseresults" card>{({ testsessiontestcaseresults }) => {
        
        return <>
          {this.renderContent(testsessiontestcaseresults, false)}
          <div style={{ overflow: 'hidden', height: 0 }}>
            {this.renderContent(testsessiontestcaseresults, true)}
          </div>
        </>
      }}</QueryStatus>}
    </Query>
  }
}

export default compose(
  withStyles(testsessionsStyle, { withTheme: true }),
  connect(
    state => ({ user: state.token.user, license: state.settings.license })
  )
)(GDPRSessionReport)
