import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const tooltipStyle = {
  tooltip: {
      marginTop: '5px',
      overflowWrap: 'anywhere',
      hyphens: 'auto',
      display: 'inline-block',
      fontWeight: '400',
  }
}

function CustomTooltip({ children, title, interactive, toolbar, ...props }) {
  const { classes } = props
  const [isHovered, setHovered] = useState(false)
  const [isTooltipHovered, setTooltipHovered] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [timeout, setStateTimeout] = useState(null)

  const onContentMouseMove = () => {
    setOpen(true)
    setHovered(true)
    setTooltipHovered(false)
    clearTimeout(timeout)
  }

  const onContentMouseLeave = () => {
    setHovered(false)
    clearTimeout(timeout)
    setStateTimeout(setTimeout(() => {
      if (!isTooltipHovered) {
        setOpen(false)
      }
    }, 700))
  }

  const onTooltipMouseMove = () => {
    setOpen(true)
    setHovered(false)
    setTooltipHovered(true)
    clearTimeout(timeout)
  }

  const onTooltipMouseLeave = () => {
    setTooltipHovered(false)
    clearTimeout(timeout)
    setStateTimeout(setTimeout(() => {
      if (!isHovered) {
        setOpen(false)
      }
    }, 700))
  }

  if (window.Cypress) {
    return <span data-tooltip-title={title}>{children}</span>
  } else if (children && title) {
    if (interactive) return <Tooltip open={isOpen} classes={{tooltip: classes.tooltip}} placement="bottom-start" title={<div onMouseEnter={onTooltipMouseMove} onMouseLeave={onTooltipMouseLeave}>{title}</div>} {...props}><span onMouseEnter={onContentMouseMove} onMouseLeave={onContentMouseLeave} data-tooltip-title={title}>{children}</span></Tooltip>
    return <Tooltip classes={{tooltip: classes.tooltip}} placement="bottom-start" title={title} {...props}><span data-tooltip-title={title}>{children}</span></Tooltip>
  } else if (children) {
    return <span data-tooltip-title={title}>{children}</span>
  }
  return null
}

export default withStyles(tooltipStyle)(CustomTooltip)
