import React, { useState } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { lightTheme, darkTheme } from 'assets/jss/material-dashboard-react/themes'

const themeMap = {
    lightTheme,
    darkTheme
}

const getThemeByName = theme => {
    return themeMap[theme]
}

export const getSystemScheme = () => {
    if ((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        return 'darkTheme'
    }
    return 'lightTheme'
}

const fallbackTheme = localStorage.getItem('themeName') || getSystemScheme()

export const ThemeContext = React.createContext(getThemeByName(fallbackTheme))

export const CustomThemeProvider = props => {
  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(fallbackTheme)

  // Retrieve the theme object by theme name
  const theme = getThemeByName(themeName)

  return (
        <ThemeContext.Provider value={{_setThemeName, themeName}}>
            <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
        </ThemeContext.Provider>
    )
}
