
module.exports.transcriptToConvo = (cs, types) => {
  if (!cs.asserters) cs.asserters = []

  const rec = (parent, fn) => {
    fn(parent)
    if (parent.cards && parent.cards.length > 0) {
      for (const card of parent.cards) {
        rec(card, fn)
      }
    }
  }

  if (!types || (types && !types.includes('sourceData'))) {
    delete cs.sourceData
  }

  if (types && !types.includes('text')) {
    delete cs.messageText
  }

  if (types && !types.includes('cards') && !types.includes('cards_content')) {
    delete cs.cards
  }

  rec(cs, (msg) => {
    if (!types || (types && !types.includes('sourceData'))) {
      delete msg.sourceData
    }
    if (types && !types.includes('cards')) {
      delete msg.text
      delete msg.subtext
    }
    if (types && !types.includes('cards_content')) {
      delete msg.content
    }
    if (types && !types.includes('buttons')) {
      delete msg.buttons
    }
    if (types && !types.includes('media')) {
      delete msg.media
      delete msg.image
      if (msg.buttons && msg.buttons.length > 0) {
        for (const button of msg.buttons) {
          delete button.imageUri
        }
      }
    }
    if (types && !types.includes('forms')) {
      delete msg.forms
    }
  })

  if (cs.nlp) {
    if ((!types || types.includes('intents')) && cs.nlp.intent && cs.nlp.intent.name) {
      cs.asserters.push({
        name: 'INTENT',
        args: [cs.nlp.intent.name]
      })
    }
    if ((!types || types.includes('entities')) && cs.nlp.entities && cs.nlp.entities.length > 0) {
      const emptyEntityNames = cs.nlp.entities.filter(e => e.name && !e.value).map(e => e.name)
      if (emptyEntityNames.length > 0) {
        cs.asserters.push({
          name: 'ENTITIES',
          args: [...emptyEntityNames, '...']
        })
      }
      for (const entity of cs.nlp.entities.filter(e => e.name && e.value)) {
        cs.asserters.push({
          name: 'ENTITY_CONTENT',
          args: [entity.name, `${entity.value}`]
        })
      }
    }
  }

  return cs
}
