import React from 'react'
import { withRouter } from 'react-router-dom'
import ChatbotSelector from '../TestDataWizard/ChatbotSelector.jsx'

export default withRouter(function ({ match }) {
  return <ChatbotSelector
    link={`/testsets/view/${match.params.id}/testcases/upload/`}
    title="Select Chatbot Connection"
    subtitle="Listing all your Chatbots supporting upload"
    queryVariables={{ connectorFeatureRequired: 'testCaseExport' }}
  />
})
