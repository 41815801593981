import _ from 'lodash'

export const median = (values) => {
  if (!values || values.length === 0) return 0
  const sortedValues = values.slice().sort((a, b) => a - b)
  const lowMiddle = Math.floor((sortedValues.length - 1) / 2)
  const highMiddle = Math.ceil((sortedValues.length - 1) / 2)
  return (sortedValues[lowMiddle] + sortedValues[highMiddle]) / 2
}
export const avg = (values) => {
  if (!values || values.length === 0) return 0
  return values.slice().reduce((previous, current) => current += previous) / values.length
}
export const sum = (values, noDataToNA) => {
  if (!values || values.length === 0) return noDataToNA ? 'N/A' : 0
  return values.slice().reduce((previous, current) => current += previous)
}


const _orderResults = (performancetestsession) => {
  const stepIndexToAggregatedDataOrdered = Array.from(performancetestsession.results || []).sort((a, b) => a.stepIndex - b.stepIndex)

  return stepIndexToAggregatedDataOrdered
}

export const getAggregatedData = (performancetestsession) => {
  const orderedResults = _orderResults(performancetestsession)

  const avgerage = orderedResults.filter(e => e.execDurationSum && e.execCount !== null).map(e => e.execDurationSum / e.execCount)
  const execDurationAvg = avg(avgerage) || 0
  const execDurationMedian = median(avgerage) || 0
  const maxStepIndex = orderedResults.length

  const totalExecCount = sum(orderedResults.filter(e => e.finishedCount != null).map(e => e.finishedCount), true)
  const totalFailedCount = sum(orderedResults.filter(e => e.finishedWithErrorCount != null).map(e => e.finishedWithErrorCount), true) || 0

  const totalExecDuration = sum(orderedResults.filter(e => e.execDurationSum != null).map(e => e.execDurationSum), true)
  const dataStartAtMs = orderedResults.length ? new Date(orderedResults[0].stepStartAt).getTime() : null
  const dataEndAtMs = dataStartAtMs + 1000 * orderedResults.length

  const progressPercent = _.isNil(dataStartAtMs) ? 0 : 100 * (dataEndAtMs - dataStartAtMs) / performancetestsession.tickMaxTime

  return {
    execDurationAvg,
    execDurationMedian,
    maxStepIndex,
    totalExecCount,
    totalFailedCount,
    totalExecDuration,
    progressPercent,
    dataEndAtMs,
    dataStartAtMs
  }
}

export const getChartDataExecDuration = (performancetestsession) => {
  const orderedResultsWithUser = _orderResults(performancetestsession).filter(e => e.userCount)
  const chartDataExecDuration = {
    labels: orderedResultsWithUser.map(e => e.stepIndex),
    series: [
      {
        name:'Convo Duration',
        data: orderedResultsWithUser.map(e => ({ x: e.stepIndex, y: e.execCount ? e.execDurationSum / e.execCount : null }))
      }
    ]
  }
  return chartDataExecDuration
}

export const getChartDataUsers = (performancetestsession) => {
  const orderedResultsWithUser = _orderResults(performancetestsession).filter(e => e.userCount)

  const chartDataUsers = {
    labels: orderedResultsWithUser.map(e => e.stepIndex),
    series: [
      { name:'Actual', data: orderedResultsWithUser.map(e => ({
          x: e.stepIndex,
          y: e.userCount
      }))},
      {
        name: 'Expected', data: orderedResultsWithUser.map(e => {
          // Math.min do not increase expected uses after the test time is over.
          // 1000: data density of aggregated results is 1 sec
          const tickIndex = Math.floor(Math.min(e.stepIndex * 1000 / performancetestsession.tickTime, performancetestsession.tickMaxTime / performancetestsession.tickTime - 1))
          return {
            x: e.stepIndex,
            y: performancetestsession.tickRepeatInitial + performancetestsession.tickRepeatPerTick * tickIndex
          }
        })
      }
    ]
  }
  return chartDataUsers 
}

export const getChartDataFailed = (performancetestsession) => {
  const { totalFailedCount } = getAggregatedData(performancetestsession)

  if (totalFailedCount) {
    const orderedResultsWithUser = _orderResults(performancetestsession).filter(e => e.userCount)

    const chartDataFailed = !!totalFailedCount && {
      labels: orderedResultsWithUser.map(e => e.stepIndex),
      series: [
        {name:'Failed convos', data: orderedResultsWithUser.map(e => ({ x: e.stepIndex, y: e.execCount ? e.runningWithErrorCount / e.execCount : null }))}
      ]
    }
    return chartDataFailed
  }
}
