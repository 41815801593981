import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'

import DeviceProviders from './DeviceProviders.jsx'
import DeviceSets from './DeviceSets.jsx'

import ShowIcon from 'components/Icon/ShowIcon'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'

class DeviceCloudSettings extends React.Component {
  render() {
    const { user } = this.props
    return (<CustomTabsSecondary
      name="tabSettingsDeviceCloud"
      headerColor="info"
      tabs={[
        {
          tabName: 'Device Cloud Providers',
          tabIcon: <ShowIcon icon="cloud" />,
          disabled: !hasAnyPermission(user, ['DEVICEPROVIDERS_SELECT', 'DEVICEPROVIDERS_MANAGE']),
          tabContent: <GridContainer><GridItem xs={12}><DeviceProviders/></GridItem></GridContainer>,
          locationPrefix: '/settings/devicecloud/deviceproviders',
          dataUnique: 'tabSettingsDeviceCloudProviders'
        },
        {
          tabName: 'Device Sets',
          tabIcon: <ShowIcon icon="laptop-code" />,
          disabled: !hasAnyPermission(user, ['DEVICESETS_SELECT', 'DEVICESETS_MANAGE']),
          tabContent: <GridContainer><GridItem xs={12}><DeviceSets/></GridItem></GridContainer>,
          locationPrefix: '/settings/devicecloud/devicesets',
          dataUnique: 'tabSettingsDeviceCloudDeviceSets'
        }
      ]}
    />)
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license })
)(DeviceCloudSettings)
