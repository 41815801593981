import React, { createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from 'components/Menu/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Hidden from '@material-ui/core/Hidden'
import Poppers from '@material-ui/core/Popper'
import ShowIcon from 'components/Icon/ShowIcon'
import Text from 'components/Typography/Text.jsx'

import { SelectNamespace, ALL_NAMESPACES_KEY } from 'components/Form/Form'
// @material-ui/icons
import Person from '@material-ui/icons/Person'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// core components
import Button from 'components/Button/Button.jsx'
import { faDiscourse } from '@fortawesome/free-brands-svg-icons'
// import { GetTagMessage } from 'components/Chip/Chip'

import headerLinksStyle from 'assets/jss/material-dashboard-react/components/headerLinksStyle'

import { clearLogin } from 'actions/token'
import { setNamespace, clearNamespace } from 'actions/namespace'
import { setAlertErrorMessage } from 'actions/alert'
import ThemeToggler from 'components/Darkmode/ThemeToggler'
import HeaderSearch from './HeaderSearch'
import Tooltip from 'components/Tooltip/Tooltip'

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      openSearch: false,
      openHistory: false,
      searchText: props.match.params.searchTerm || '',
      sessionTimeoutMs: null,
      recentStart: 0,
      recentLength: 10
    }
    this.historyPaperRef = createRef()
    this.profilePaperRef = createRef()
  }


  _updateSessionTimeoutInterval() {
    const { expiryDate } = this.props

    clearInterval(this.sessionTimeoutInterval)
    if (expiryDate) {
      this.sessionTimeoutInterval = setInterval(() => {
        const ms = Math.round((new Date(expiryDate) - new Date()))
        if (ms <= 0) {
          this.setState({ sessionTimeoutMs: 0 })
          clearInterval(this.sessionTimeoutInterval)
        } else {
          this.setState({ sessionTimeoutMs: ms })
        }
      }, 1000)
    }
  }

  componentDidMount() {
    this._updateSessionTimeoutInterval()
  }

  componentDidUpdate(prevProps, prevState) {

    const handleHistoryCloseOnEscape = (e) => {
      if (e.key === 'Escape') {
        this.setState({
          openHistory: false
        })
      }
    }

    const handleProfileCloseOnEscape = (e) => {
      if (e.key === 'Escape') {
        this.setState({
          open: false
        })
      }
    }

    const handleSearchCloseOnEscape = (e) => {
      if (e.key === 'Escape') {
        this.setState({
          open: false
        })
      }
    }

    if (prevState.openHistory !== this.state.openHistory) {
      if (this.historyPaperRef && this.historyPaperRef.current) {
          this.historyPaperRef.current.children[0].children[0].focus()
      }
      if (this.state.openHistory) {
        document.addEventListener('keydown', handleHistoryCloseOnEscape)
      } else {
        document.removeEventListener('keydown', handleHistoryCloseOnEscape)
      }
    }
    if (prevState.open !== this.state.open) {
      if (this.profilePaperRef && this.profilePaperRef.current) {
        this.profilePaperRef.current.children[0].children[0].focus()
      }
      if (this.state.open) {
        document.addEventListener('keydown', handleProfileCloseOnEscape)
      } else {
        document.removeEventListener('keydown', handleProfileCloseOnEscape)
      }
    }
    if (prevState.openSearch !== this.state.openSearch) {
      if (this.searchPaperRef && this.searchPaperRef.current) {
        this.searchPaperRef.current.children[0].children[0].focus()
      }
      if (this.state.openSearch) {
        document.addEventListener('keydown', handleSearchCloseOnEscape)
      } else {
        document.removeEventListener('keydown', handleSearchCloseOnEscape)
      }
    }

    this._updateSessionTimeoutInterval()
  }

  componentWillUnmount() {
    clearInterval(this.sessionTimeoutInterval)
  }

  handleToggle() {
    this.setState(state => ({ open: !state.open, openHistory: false, openSearch: false }))
  }

  handleClose(event) {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    this.setState({ open: false })
  }

  handleHistoryToggle() {
    this.setState(state => ({ openHistory: !state.open, open: false, openSearch: false }))
  }

  handleHistoryClose(event) {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    this.setState({ openHistory: false })
  }

  handleSearchToggle() {
    this.setState(state => ({ openSearch: !state.open, open: false, openHistory: false, }))
  }

  handleSearchClose(event) {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    this.setState({ openSearch: false })
  }

  async handleLogout(event) {
    const { history } = this.props
    this.handleClose(event)
    history.push('/logout')
  }

  handleLogin(event) {
    const { history } = this.props
    this.handleClose(event)
    history.push('/login')
  }

  handleProfile(event) {
    const { history } = this.props
    this.handleClose(event)
    history.push('/profile')
  }

  handleSettings(event) {
    const { history } = this.props
    this.handleClose(event)
    history.push('/settings')
  }

  handleRecent(event, url) {
    const { history } = this.props
    this.handleHistoryClose(event)
    history.push(url)
  }

  render() {
    const { classes, user, history, mobile, activity, setNamespace, clearNamespace, namespace } = this.props
    const { open, openHistory, openSearch, searchText, recentStart, recentLength } = this.state

    const mobileString = (mobile) ? 'Mobile' : ''
    const hasActivity = user && activity && activity.recent && activity.recent.length > 0

    return (
      <div>
        <div className={classes.namespaceSelector}>
          <SelectNamespace
            data-unique="selNamespace"
            input={{
              value: namespace?.selected?.name || ALL_NAMESPACES_KEY,
              onChange: (e, child) => {
                if (e.target.value && e.target.value && e.target.value !== ALL_NAMESPACES_KEY) {
                  setNamespace({name: e.target.value})
                } else {
                  clearNamespace()
                }
                return e.target.value
              },
            }}
            label=""
          />
        </div>
        <Tooltip title="Theme Switch" placement="bottom" >
          <ThemeToggler className={classes.ThemeToggler} aria-label="Theme Switch" />
        </Tooltip>
        <div className={classes.manager}>
          <Tooltip title="Search" placement="bottom" >
            <Button
              aria-label="Search"
              buttonRef={node => {
                this.anchorEl = node
              }}
              color={window.innerWidth > 959 ? 'transparent' : 'white'}
              justIcon={window.innerWidth > 959}
              aria-owns={openSearch ? 'menu-list-grow' : null}
              aria-haspopup="true"
              onClick={(e) => this.handleSearchToggle(e)}
              className={classes.buttonLinkHistory}
              data-unique={`btnHeaderLinks${mobileString}Search`}
            >
              <ShowIcon icon="search" className={classes.itemIcon} />
              <Hidden mdUp implementation={window.Cypress ? 'js' : 'css'}>
                <p className={classes.linkText}>
                  Search
                </p>
              </Hidden>
            </Button>
          </Tooltip>
          <Poppers
            open={openSearch}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openSearch }) +
              ' ' +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                tabIndex="0"
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper className={classes.paperflymenu}>
                  <ClickAwayListener onClickAway={(e) => this.handleSearchClose(e)}>
                    <div ref={this.historyPaperRef}>

                    {user && <><HeaderSearch
                          searchText={searchText}
                          onChange={e => this.setState({ searchText: e.target.value })}
                          onButtonClick={e => {
                            if (searchText && searchText.length > 2) {
                                history.push(`/quicksearch/${searchText}`)
                                e.preventDefault()
                            } else {
                            }
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              if (searchText && searchText.length > 2) {
                                history.push(`/quicksearch/${searchText}`)
                              } else {
                                this.setState({showMinCharacterWarning: true})
                              }
                            }
                            if (!searchText || (searchText && searchText.length > 2)) {
                              this.setState({showMinCharacterWarning: false})
                            }
                          }}
                        /></>}
                        {this.state.showMinCharacterWarning && searchText && <Text helperText >Please enter at least 3 characters.</Text>}
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
          <Tooltip title="History" placement="bottom" >
            <Button
              aria-label="History"
              disabled={!hasActivity}
              onKeyPress={(e) => {
                if (!hasActivity) {
                  e.preventDefault()
                }
              }}
              buttonRef={node => {
                this.anchorEl = node
              }}
              color={window.innerWidth > 959 ? 'transparent' : 'white'}
              justIcon={window.innerWidth > 959}
              aria-owns={openHistory ? 'menu-list-grow' : null}
              aria-haspopup="true"
              onClick={(e) => this.handleHistoryToggle(e)}
              className={classes.buttonLinkHistory}
              data-unique={`btnHeaderLinks${mobileString}History`}
            >
              <ShowIcon icon="history" className={classes.itemIcon} />
              <Hidden mdUp implementation={window.Cypress ? 'js' : 'css'}>
                <p className={classes.linkText}>
                  History
                </p>
              </Hidden>
            </Button>
          </Tooltip>
          <Poppers
            open={openHistory}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openHistory }) +
              ' ' +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                tabIndex="0"
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper className={classes.paperflymenu}>
                  <ClickAwayListener onClickAway={(e) => this.handleHistoryClose(e)}>
                    <div ref={this.historyPaperRef}>
                    <MenuList role="menu">
                      {hasActivity && recentStart > 0 && <MenuItem
                        tabIndex="0"
                        key={`recentUp`}
                        onClick={() => this.setState({ recentStart: Math.max(0, recentStart - 10) })}
                        data-unique={`btnHeaderLinksRecentUp`}
                      >
                        <ArrowDropUpIcon />
                      </MenuItem>}
                      {hasActivity && activity.recent.slice(recentStart, recentStart + recentLength).map((entry, index) => <MenuItem
                        key={`recent${index}`}
                        onClick={(e) => this.handleRecent(e, entry.url)}
                        data-unique={`btnHeaderLinksRecent${index}`}
                      >
                        {entry.type === 'Chatbot' && <ShowIcon icon="robot" className={classes.itemIcon} />}
                        {entry.type === 'TestSession' && <ShowIcon icon="tasks" className={classes.itemIcon} />}
                        {entry.type === 'SecuritySession' && <ShowIcon icon="shield-alt" className={classes.itemIcon} />}
                        {entry.type === 'GDPRSession' && <ShowIcon icon="shield-alt" className={classes.itemIcon} />}
                        {entry.type === 'PerformanceTestSession' && <ShowIcon icon="rocket" className={classes.itemIcon} />}
                        {entry.type === 'CoachSession' && <ShowIcon icon="chart-pie" className={classes.itemIcon} />}
                        {entry.type === 'TestProject' && <ShowIcon icon="infinity" className={classes.itemIcon} />}
                        {entry.type === 'TestSet' && <ShowIcon icon="folder-open" className={classes.itemIcon} />}
                        {entry.type === 'CrawlerProject' && <ShowIcon icon="sitemap" className={classes.itemIcon} />}
                        {entry.type === 'CrawlerSession' && <ShowIcon icon="sitemap" className={classes.itemIcon} />}
                        {entry.type === 'FactCheckerSession' && <ShowIcon icon="fa-check-to-slot" className={classes.itemIcon} />}
                        {entry.type === 'FactCheckerProject' && <ShowIcon icon="fa-check-to-slot" className={classes.itemIcon} />}
                        {entry.name}
                      </MenuItem>)}
                      {hasActivity && activity.recent.length > recentStart + recentLength && <MenuItem
                        key={`recentDown`}
                        onClick={() => this.setState({ recentStart: recentStart + 10 })}
                        data-unique={`btnHeaderLinksRecentDown`}
                      >
                        <ArrowDropDownIcon />
                      </MenuItem>}
                    </MenuList>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
          <Tooltip title="User Profile" placement="bottom" >
            <Button
              aria-label="User Profile"
              buttonRef={node => {
                this.anchorEl = node
              }}
              color={window.innerWidth > 959 ? 'transparent' : 'white'}
              justIcon={window.innerWidth > 959}
              aria-owns={open ? 'menu-list-grow' : null}
              aria-haspopup="true"
              onClick={(e) => this.handleToggle(e)}
              className={classes.buttonLinkUser}
              data-unique={`btnHeaderLinks${mobileString}Profile`}
            >
                <Person className={classes.icons} />
                <Hidden mdUp implementation={window.Cypress ? 'js' : 'css'}>
                  <p className={classes.linkText}>
                    User Profile
                  </p>
                </Hidden>
            </Button>
          </Tooltip>
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              ' ' +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper className={classes.paperflymenu}>
                  <ClickAwayListener onClickAway={(e) => this.handleClose(e)}>
                    <div ref={this.profilePaperRef}>
                    <MenuList role="menu">
                      <MenuItem
                        key="forum"
                        data-unique={`btnHeaderLinks${mobileString}Forum`}
                      >
                        <a className={classes.headerLinksLink} style={{ display: 'block' }} href="https://forum.botium.ai" target="_blank" rel="noopener noreferrer">
                          <ShowIcon icon={faDiscourse} className={classes.itemIcon} />
                          Botium Forum
                        </a>
                      </MenuItem>
                      {user && (
                        <MenuItem
                          key="profile"
                          onClick={(e) => this.handleProfile(e)}
                          data-unique={`btnHeaderLinks${mobileString}UserProfile`}
                        >
                          <ShowIcon icon="user" className={classes.itemIcon} />
                          My User Profile
                        </MenuItem>
                      )}
                      {!user && (
                        <MenuItem
                          key="login"
                          onClick={(e) => this.handleLogin(e)}
                          data-unique={`btnHeaderLinks${mobileString}Login`}
                        >
                          <ShowIcon icon="sign-in-alt" className={classes.itemIcon} />
                          Login
                        </MenuItem>
                      )}
                    </MenuList>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
          <Tooltip title="Help" placement="bottom" >
            <Button
              aria-label="Help"
              buttonRef={node => {
                this.anchorEl = node
              }}
              color={window.innerWidth > 959 ? 'transparent' : 'white'}
              justIcon={window.innerWidth > 959}
              aria-owns={open ? 'menu-list-grow' : null}
              aria-haspopup="true"
              onClick={(e) => {
                if (window.zE('webWidget:get', 'display') === 'hidden') {
                  window.zE('webWidget', 'toggle')
                  window.zE('webWidget', 'show')
                } else {
                  window.zE('webWidget', 'toggle')
                  window.zE('webWidget', 'hide')
                }
              }}
              className={classes.buttonLinkUser}
              data-unique={`btnHeaderLinks${mobileString}Help`}
            >
                <ShowIcon icon="circle-question" className={classes.itemIconHelp} />
            </Button>
          </Tooltip>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => {
      return ({ features: state.settings.features, expiryDate: state.token.expiryDate, user: state.token.user, activity: state.activity, namespace: state.namespace })
    },
    dispatch =>
      bindActionCreators(
        { clearLogin, setAlertErrorMessage, setNamespace, clearNamespace },
        dispatch,
      ),
  )(withStyles(headerLinksStyle)(withApollo(HeaderLinks)))
)
