import boxStyle from 'assets/jss/material-dashboard-react/components/boxStyle.jsx'

const performanceTestingStyle = theme => ({
  ...boxStyle(theme),
  sliderContainerRoot: {
    padding: '10px 10px', display: 'flex', alignItems: 'center'
  },
  sliderContainerImage: {
    width: 60, float: 'left', margin: 10
  },
  sliderContainer: {
    width: '100%', margin: 10
  },
  explanationBox: {
    borderRadius: 10,
    color: theme.colors.primary,
    backgroundColor: `${theme.colors.backgroundTableRow} !important`,
    padding: 15
  }
})

export default performanceTestingStyle
