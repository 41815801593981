import CapabilitiesEdit from './CapabilitiesEdit'

export default {
  form2Caps: (values) => {
    return values.capabilities.map(c => {
      return {
        stringValue: c.stringValue,
        intValue: c.intValue,
        booleanValue: c.booleanValue,
        jsonValue: c.jsonValue,
        type: c.type,
        name: c.name
      }
    })},
  caps2Form: null,
  form: CapabilitiesEdit
}
