import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import footerStyle from 'assets/jss/material-dashboard-react/components/footerStyle'
import Text from 'components/Typography/Text'

function Footer({ ...props }) {
  const { classes } = props
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left} />
        <p className={classes.right}>
          <Text inline>
            &copy; {1900 + new Date().getYear()} <a href="https://info.cyara.com/contact-chatbot-testing-and-assurance-with-botium" className={classes.a} target="_blank" rel="noopener noreferrer">Cyara</a>. Customer Smiles. Delivered at Scale.
          </Text>
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(footerStyle)(Footer)
