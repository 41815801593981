import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query, Mutation, compose, withApollo } from 'react-apollo'
// core components
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Text from 'components/Typography/Text'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import {
  NAMESPACES_QUERY,
  NAMESPACE_QUERY,
  DELETE_NAMESPACE
} from './gql'

import { FormActionsToolbar } from 'components/Form/Form'

class Namespace extends React.Component {

  renderForm(ns) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady } = this.props

    return (<GridContainer>
      <GridItem xs={12}>
        <Text header>
          {ns.namespace} Usage Statistics
        </Text>
      </GridItem>
      {ns.entries.filter(e => e.count > 0).map(entry => (<GridItem xs={12}>
        <Text subheader>
          {entry.entity}: {entry.count}
        </Text>
      </GridItem>))}
      {ns.entries.filter(e => e.count > 0).length === 0 && <GridItem xs={12}>
        <Text subheader>
          No records assigned to this namespace
        </Text>
      </GridItem>}
      <GridItem xs={12} largePadding>
        <FormActionsToolbar
          leftButtons={<>
            <Mutation
              mutation={DELETE_NAMESPACE}
              onCompleted={data => {
                setAlertSuccessMessage('Namespace deleted')
                onReady()
              }}
              onError={error => {
                setAlertErrorMessage('Namespace deletion failed', error)
              }}
              refetchQueries={[
                {
                  query: NAMESPACES_QUERY,
                },
              ]}
            >
              {(deleteNamespace, { loading, error }) => (
                <ConfirmationButton
                  confirmationBody={<GridContainer>
                    <GridItem xs={12}><Text>Deleting this namespace will do the following:</Text></GridItem>
                    <GridItem xs={12}>
                      <Text bold>Ungroup the items:</Text>
                      <Text>All items in the namespace (Projects, Results, Test Sets etc.) will become unorganised and will appear globally for your company.</Text>
                    </GridItem>
                    <GridItem xs={12}>
                      <Text bold>Remove associated permissions:</Text>
                      <Text>Everyone in your company will be able to access the items, even if they couldn't before. This means they can read and write the items, so make sure you are ok with this before proceeding.</Text>
                    </GridItem>
                  </GridContainer>}
                  requireCheck={true}
                  onClick={() => {
                    deleteNamespace({
                      variables: { id: ns.id },
                    })
                  }}
                  data-unique="btnNamespaceDelete"
                  secondary
                  danger                                  
                >
                  <ShowIcon icon="trash" />
                  <Text infoText bold>Delete Namespace</Text>
                </ConfirmationButton>
              )}
            </Mutation>
          </>}
          rightButtons={<>
          </>}
        />                      
      </GridItem>
    </GridContainer>)
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={NAMESPACE_QUERY} variables={{ id }} fetchPolicy="network-only">
              {(queryResult) => <QueryStatus {...queryResult} query="namespace">{(data) => this.renderForm(data.namespace)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({})}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(settingsStyle),
  connect(
    state => ({ user: state.token.user }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  withApollo
)(Namespace)
