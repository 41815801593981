
const themeTogglerStyle = theme => ({
    bar: {
        backgroundColor: '#D5D9DD !important',
        height: 22,
        marginTop: -13,
        padding: 2,
    },
    switchBase: {
        width: 50,
        color: theme.colors.common.primary
    },
    labelRoot: {
        marginTop: 0
    },
    iconColor: {
        color: theme.colors.common.white
    },
    icon: {
        backgroundColor: theme.colors.common.primary,
        borderRadius: 5,
        fontSize: 15
    },
    iconChecked: {
        backgroundColor: theme.colors.common.primary,
        borderRadius: 5,
        fontSize: 15
    }
  })
  
  export default themeTogglerStyle
  