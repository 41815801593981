const objectChipsStyles = theme => ({
  cardLink: {
    cursor: 'pointer',
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 6,
    },
  }
})

export default objectChipsStyles
