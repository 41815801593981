import { SET_AVAILABLECONNECTORS, CLEAR_AVAILABLECONNECTORS, SET_ALLCONNECTORS, CLEAR_ALLCONNECTORS, SET_LICENSE, CLEAR_LICENSE, SET_FEATURES, CLEAR_FEATURES } from '../constants'

export function getSettingsFromState(state) {
  return (state && state.settings) || {}
}
export function getConnectorFromSettings(settings, connectorName) {
  return connectorName && settings && settings.allconnectors && settings.allconnectors.find(c => c.name === connectorName)
}

export function getConnector(connectorName) {
  return (dispatch, getState) => {
    const settings = getSettingsFromState(getState())
    return getConnectorFromSettings(settings, connectorName)
  }
}

export function setAvailableConnectors(availableconnectors) {
  return {
    type: SET_AVAILABLECONNECTORS,
    payload: {
      availableconnectors
    }
  }
}
export function clearAvailableConnectors() {
  return {
    type: CLEAR_AVAILABLECONNECTORS,
    payload: {
    }
  }
}
export function setAllConnectors(allconnectors) {
  return {
    type: SET_ALLCONNECTORS,
    payload: {
      allconnectors
    }
  }
}
export function clearAllConnectors() {
  return {
    type: CLEAR_ALLCONNECTORS,
    payload: {
    }
  }
}
export function setLicense(license) {
  return {
    type: SET_LICENSE,
    payload: {
      license
    }
  }
}
export function clearLicense() {
  return {
    type: CLEAR_LICENSE,
    payload: {
    }
  }
}
export function setFeatures(features) {
  return {
    type: SET_FEATURES,
    payload: {
      features
    }
  }
}
export function clearFeatures() {
  return {
    type: CLEAR_FEATURES,
    payload: {
    }
  }
}
