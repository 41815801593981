import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { OnChange } from 'react-final-form-listeners'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form, FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import arrayMutators from 'final-form-arrays'

// apollo
import { withApollo, compose, graphql } from 'react-apollo'
// core components
import DropdownButton from 'components/Button/DropdownButton'
import Button from 'components/Button/Button'
import ShortenedText from 'components/Typography/ShortenedText'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { renderSelect, renderTextField, renderCheckbox, required, FormActionsToolbar } from 'components/Form/Form'
import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'
import CardBody from 'components/Card/CardBody'
import Card from 'components/Card/Card'
import Chip from 'components/Chip/Chip'
import ErrorFormat from 'components/Info/ErrorFormat'
import Text from 'components/Typography/Text'

import { setAlertSuccessMessage} from 'actions/alert'
import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TEST_DATA_WIZARD_SAMPLES,
  TEST_DATA_WIZARD_DESCRIPTION,
  TEST_DATA_WIZARD_SCRIPT,
  VALIDATETESTDATAWIZARDUTTERANCES_QUERY
} from './gql'

import AITestDataSaveDialog from './AITestDataSaveDialog'

import ClientFeatureSection from '../Settings/ClientFeatureSection'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

const INITIAL_VALUES = {
  convos: []
}

const isConvoToSave = (convo) => {
  return convo.desc && !convo.generatingScript && convo.script && convo.selected && !convo.validationError && !convo.scriptGeneratingError
}

const isConvoToGenerate = (convo) => {
  return convo.desc && !convo.generatingScript && convo.selected && !convo.script
}

class TestDataWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      convoDescriptionsError: null,
      convoDescriptionGuessing: false,
      script: '',
      showCopyDialog: false,
      bulbTooltipOpen: false,
      testDataWizardScripts: []
    }
    this.supressValueChangingCheck = false
    this.clientId = 0
  }

  generateScript(chatbotDomain, chatbotDescription, convoDescription) {
    const { client } = this.props
    // fist version we are using booth, even if BL can work with one of them.
    return convoDescription && client.query({
      query: TEST_DATA_WIZARD_SCRIPT,
      variables: {
        chatbotDomain,
        chatbotDescription,
        convoDescription,
        scriptType: 'SCRIPTING_TYPE_UTTERANCES'
      },
      fetchPolicy: 'network-only'
    })
      .then(({ data }) => {
        if (data.testdatawizardscript) {
          return {
            script: data.testdatawizardscript,
            scriptGeneratingError: null
          }
        }
      })
      .catch(err => {
        return {
          script: '',
          scriptGeneratingError: err.message
        }
      })
  }

  setConvoDescriptionsState = async (values, change) => {
    const { setAlertSuccessMessage } = this.props
    this.setState({ convoDescriptionGuessing: true })
    const convoDescriptionsStruct = await this.convoDescriptions(values)
    if (convoDescriptionsStruct && !convoDescriptionsStruct.convoDescriptionsError) {
      const newValues = (convoDescriptionsStruct.convoDescriptions || [])
        .filter(desc => !values.convos.find(entry => entry.desc === desc))
        .map(desc => ({
          desc,
          generatingScript: false,
          clientId: this.clientId++,
          selected: true
        }))
      // keep the last, emptry row as last
      if (newValues.length) {
        const clonedConvos = [...values.convos]
        clonedConvos.splice(clonedConvos.length - 2, 0, ...newValues)
        this.supressValueChangingCheck = true
        change(`convos`, clonedConvos)
        this.supressValueChangingCheck = false
      }
      this.setState({
        convoDescriptionGuessing: false,
        convoDescriptionsError: null
      })
      setAlertSuccessMessage('Test Cases are generated')
    } else {
      this.setState({
        convoDescriptionGuessing: false,
        convoDescriptionsError: convoDescriptionsStruct.convoDescriptionsError
      })
    }
  }

  convoDescriptions({ chatbotDomain, chatbotDescription }) {
    const { client } = this.props
    return chatbotDomain && chatbotDescription && client.query({
      query: TEST_DATA_WIZARD_DESCRIPTION,
      variables: {
        chatbotDomain: chatbotDomain || null,
        chatbotDescription
      },
      fetchPolicy: 'network-only'
    })
      .then(({ data }) => {
        if (data.testdatawizardconvodescription) {
          return {
            convoDescriptions: data.testdatawizardconvodescription,
            convoDescriptionsError: null
          }
        }
      })
      .catch(err => {
        return {
          convoDescriptions: [],
          convoDescriptionsError: err
        }
      })
  }

  /**
   * This is a strange validation function. The script validation result is made async, the results are not give to
   * the results, but back to the values object.
   */
  async validate(values) {
    const { client } = this.props

    const promises = values.convos.map((convo, index) => {
      if (!isConvoToSave(convo)) {
        return Promise.resolve(false)
      }
      return client
        .query({
          query: VALIDATETESTDATAWIZARDUTTERANCES_QUERY,
          variables: {
            script: convo.script || ''
          },
          fetchPolicy: 'no-cache'
        })
        .then(({ data }) => {
          let error = null
          if (!data.validatetestdatawizardutterances) {
            error = 'Test Script compilation failed, check syntax'
          } else if (data.validatetestdatawizardutterances.err) {
            error = data.validatetestdatawizardutterances.err
          } else if (!data.validatetestdatawizardutterances.name) {
            error = 'Test Script name missing'
          }

          if (error) {
            // convo.modify = true
            convo.validatetestdatawizardutterances = null
            // errors[fieldName] = error
            convo.validationError = error
            return
          }

          // validate is called too often. Its not good to close valid convo on validate
          // convo.modify = false
          convo.validatetestdatawizardutterances = data.validatetestdatawizardutterances
          convo.validationError = null

          return
        })
        .catch(err => {
          convo.validationError = `Test Script compilation failed: ${err}`
        })
    })
    await Promise.all(promises)
  }

  async generateScriptForConvo(convo, name, change, batch, values) {
    const clientIdProcessing = convo.clientId
    change(`${name}.generatingScript`, true)
    const { script, scriptGeneratingError } = await this.generateScript(values.chatbotDomain, values.chatbotDescription, convo.desc)
    const index = values.convos.findIndex(convo => convo.clientId === clientIdProcessing)
    // it can be deleted
    if (index >= 0) {
      batch(() => {
        change(`convos[${index}].generatingScript`, false)
        change(`convos[${index}].script`, script)
        change(`convos[${index}].scriptGeneratingError`, scriptGeneratingError)
      })
    }
  }

  renderCopyScriptsDialog(reset, dirty) {
    const { showCopyDialog, testDataWizardScripts } = this.state
    const { history } = this.props

    return <AITestDataSaveDialog
      showCopyDialog={showCopyDialog}
      testDataWizardScripts={testDataWizardScripts}
      nlpOnly={false}
      history={history}
      reset={reset}
      onComplete={() => {
        this.setState({ showCopyDialog: false, testDataWizardScripts: [] })
      }}
      onCancel={() => {
        this.setState({ showCopyDialog: false, testDataWizardScripts: [] })
      }}
      title="Save Test Cases"
      okText="Save Test Cases"
    />
  }

  renderForm() {
    const { wizardSamplesData, setAlertSuccessMessage, classes } = this.props
    const { convoDescriptionGuessing, convoDescriptionsError, bulbTooltipOpen } = this.state

    return (
      <Form
        onSubmit={() => {}}
        mutators={{ ...arrayMutators }}
        initialValues={INITIAL_VALUES}
        validate={(values) => this.validate(values)}
        render={({
          handleSubmit,
          submitting,
          invalid,
          values,
          form: { change, reset, batch, mutators }
        }) => {
          const convosToSave = values.convos.filter(c => isConvoToSave(c))
          const convosToGenerate = values.convos.map((convo, index) => ({ convo: convo, name: `convos[${index}]` })).filter(({ convo }) => isConvoToGenerate(convo))

          const chatbotDomains = (wizardSamplesData && wizardSamplesData.testdatawizardsamples && _.uniq(wizardSamplesData.testdatawizardsamples.map(s => s.domain)).sort()) || []
          const descriptionSamples = (wizardSamplesData && wizardSamplesData.testdatawizardsamples && wizardSamplesData.testdatawizardsamples.filter(w => w.domain === values.chatbotDomain).reduce((agg, a) => {
            return [
              ...agg,
              ...a.descriptions.map(d => ({
                id: a.domain + a.language + d,
                name: d,
                onClick: () => change('chatbotDescription', d)
              }))
            ]
          }, [])) || []

          const chatbotDomainItems = chatbotDomains && chatbotDomains.map(d => {
            return {
              key: d
            }
          })
          chatbotDomainItems.push({
            key: 'Other',
            label: 'Other'
          })

          return <form onSubmit={handleSubmit}>
            {this.renderCopyScriptsDialog(reset)}
            <GridContainer>
              <GridItem md={12}>
                <Card dense><CardBody noPaddingTop noPaddingBottom>
                  <GridContainer>
                    <GridItem md={12} lg={2} >
                      <Field
                        name="chatbotDomain"
                        component={renderSelect}
                        label="Chatbot Domain"
                        data-unique="selTestDataWizardDomain"
                        validate={required}
                        items={chatbotDomainItems}
                        filterable={false}
                      />
                    </GridItem>
                    <FormSpy subscription={{ form: true }} render={({ form:  { change }}) => (
                      <OnChange name="chatbotDomain">
                        {async (value, previous) => {
                          change('chatbotDescription', null)
                          this.setState({ bulbTooltipOpen: true })
                        }}
                      </OnChange>
                    )} />
                    <GridItem md={12} lg={8} >
                      <Field
                        name="chatbotDescription"
                        component={renderTextField}
                        label="Description of the Chatbot"
                        title="Description of the Chatbot"
                        validate={required}
                        data-unique="txtTestDataWizardConvoChatbotDescription"
                        helperText={convoDescriptionsError ? <ErrorFormat err={convoDescriptionsError} /> : 'Example: "Pizza ordering chatbot, english, 10 intents"'}
                        endAdornment={descriptionSamples && descriptionSamples.length > 0 &&
                          <Tooltip title="You can look some example here"
                            placement="top"
                            open={bulbTooltipOpen}
                          >
                              <DropdownButton
                                lable="You can look some example here"
                                title="You can look some example here"
                                onClick={() => this.setState({bulbTooltipOpen : false}) }
                                data-unique="btnTestDataWizardSamples"
                                justIcon
                                noCaret
                                items={descriptionSamples}
                              >
                                <ShowIcon icon="lightbulb" />
                              </DropdownButton>
                          </Tooltip>}
                      />
                    </GridItem>
                    <GridItem md={12} lg={2} middle>
                      <Button
                        onClick={async () => {
                          await this.setConvoDescriptionsState(values, change)
                        }}
                        fullWidth
                        data-unique="btnTestDataWizardConvoDescription"
                        disabled={invalid || submitting || convoDescriptionGuessing}
                        className={classes.generatebuttonposition}
                      >
                        {convoDescriptionGuessing && <><LoadingIndicator alt /> Generating Test Cases ...</>}
                        {!convoDescriptionGuessing && <><ShowIcon icon="magic" /> Generate Test Cases</>}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody></Card>
              </GridItem>
              {values.convos && values.convos.length > 0 && <>
                {(values.convos || []).map((convo, index) => {
                  const name = `convos[${index}]`
                  let convoState = 'UNKNOWN_STATE'
                  if (convo.validationError) {
                    convoState = 'ERROR'
                  } else if (convo.scriptGeneratingError) {
                    convoState = 'ERROR'
                  } else if (convo.generatingScript) {
                    convoState = 'GENERATING_SCRIPT'
                  } else if (convo.saved) {
                    convoState = 'SAVED'
                  } else if (convo.script && !convo.validatetestdatawizardutterances) {
                    convoState = 'VALIDATING_SCRIPT'
                  } else if (convo.script && convo.validatetestdatawizardutterances) {
                    convoState = 'GENERATED'
                  } else {
                    convoState = 'NO_SCRIPT'
                  }
                  const part = `TestDataWizardConvoChatbotScript${name}`

                  return <GridItem xs={12} key={convo.clientId}>
                    <Card dense>
                      <CardBody noPaddingTop noPaddingBottom>
                        <GridContainer >
                          <GridItem md={8} lg={8} middle>
                            <Field
                              name={`${name}.selected`}
                              component={renderCheckbox}
                              type="checkbox"
                              data-unique="chkTestSetContentSelectorLocal"
                            />
                            {/*tooltip does not like to swich from none title to existing title*/}
                            {/*(its an other component, so the contents are always rerendered)*/}
                            <Tooltip title={convo.desc || 'Add new Test Case Description'}>
                              <Field
                                name={`${name}.desc`}
                                disabled={invalid || submitting || ['SAVED'].includes(convoState) || !convo.selected}
                                component={renderTextField}
                                data-unique={`txt${part}Desc`}
                                label="Utterance List Name"
                                placeholder="Add new Test Case Description"
                                disableBorder
                                inlineEdit
                                flexibleWidth
                              />
                            </Tooltip>
                          </GridItem>
                          <OnChange name={`${name}.desc`}>
                            {(value, previous) => {
                              // user will lose focus from desc field
                              // change(`${name}.script`, '')
                              // But this way too...
                              if (!this.supressValueChangingCheck) {
                                convo.script = ''
                                convo.validationError = null
                                convo.scriptGeneratingError = null
                              }
                            }}
                          </OnChange>
                          <GridItem md={4} lg middle right>
                            {convoState === 'DEACTIVATED' && ''}
                            {convoState === 'ERROR' && <Chip label={convo.validationError || convo.scriptGeneratingError} className={classNames(classes.chipDanger, { [classes.chipDisabled]: !convo.selected })} />}
                            {convoState === 'GENERATING_SCRIPT' && <Chip label="Generating user examples" className={classNames(classes.chipDashed, { [classes.chipDisabled]: !convo.selected })} />}
                            {convoState === 'VALIDATING_SCRIPT' && <Chip label="Validating user examples" className={classNames(classes.chipDashed, { [classes.chipDisabled]: !convo.selected })} />}
                            {convoState === 'SAVED' && <Chip label="User examples saved" className={classNames(classes.chipSuccess, { [classes.chipDisabled]: !convo.selected })} />}
                            {convoState === 'GENERATED' && <Chip label="Ready to save user examples" className={classNames(classes.chipInfo, { [classes.chipDisabled]: !convo.selected })} />}
                            {convoState === 'NO_SCRIPT' && <Chip label="Ready to generate user examples" className={classNames(classes.chipWarning, { [classes.chipDisabled]: !convo.selected })} />}
                            {convoState === 'UNKNOWN_STATE' && <Chip label="UNKNOWN_STATE" />}
                          </GridItem>
                          <GridItem md={12} lg middle right>
                            |
                            <Tooltip title={'Regenerate'}>
                              <Button justIcon dense small spaceRight spaceLeft
                                arial-label="Regenerate"
                                data-unique={`btn${part}Regenerate`}
                                disabled={!['ERROR', 'GENERATED'].includes(convoState) || !convo.selected}
                                onClick={async () => {
                                  await this.generateScriptForConvo(convo, name, change, batch, values)
                                }}
                              >
                                {convoState !== 'GENERATING_SCRIPT' && <ShowIcon icon="sync" />}
                                {convoState === 'GENERATING_SCRIPT' && <ShowIcon icon="sync" spin />}
                              </Button>
                            </Tooltip>
                            <Tooltip title={'Duplicate'}>
                              <Button justIcon dense small spaceRight
                                arial-label="Duplicate"
                                data-unique={`btn${part}Duplicate`}
                                disabled={!convo.desc || !(convo.selected || convo.saved)}
                                onClick={async () => {
                                  setTimeout(() => {
                                    // setTimeout is required because so the events risen because change will be invoked
                                    // immediately, between supress flag sets.
                                    this.supressValueChangingCheck = true
                                    const clonedConvos = [...values.convos]
                                    clonedConvos.splice(index + 1, 0, Object.assign(
                                      {},
                                      convo,
                                      {
                                        clientId: this.clientId++,
                                        selected: true,
                                      }
                                    ))
                                    change(`convos`, clonedConvos)
                                    this.supressValueChangingCheck = false
                                  })
                                }}
                              >
                                <ShowIcon icon="copy" />
                              </Button>
                            </Tooltip>
                            <Tooltip title={'Delete'}>
                              <Button justIcon dense small
                                arial-label="Delete"
                                data-unique={`btn${part}Delete`}
                                onClick={async () => {
                                  setTimeout(() => {
                                    // setTimeout is required because so the events risen because change will be invoked
                                    // immediately, between supress flag sets.
                                    this.supressValueChangingCheck = true
                                    const clonedConvos = [...values.convos]
                                    clonedConvos.splice(index, 1)
                                    change(`convos`, clonedConvos)
                                    this.supressValueChangingCheck = false
                                  })
                                }}
                              >
                                <Text><ShowIcon icon="trash" /></Text>
                              </Button>
                            </Tooltip>
                            <Tooltip title={'Show details'}>
                              <Button justIcon dense medium spaceLeft
                                aria-label="View Details"
                                data-unique={`btn${part}ViewDetails`}
                                disabled={!['ERROR', 'GENERATED'].includes(convoState) || !convo.selected}
                                onClick={async () => {
                                  batch(() => {
                                    for (let i = 0; i < values.convos.length; i++) {
                                      const otherName = `convos[${i}]`
                                      if (otherName !== name) {
                                        change(`${otherName}.modify`, false)
                                      }
                                    }
                                    change(`${name}.modify`, !convo.modify)
                                  })
                                }}
                              >
                                {convo.modify ? <ShowIcon icon="chevron-up" /> : <ShowIcon icon="chevron-down" />}
                              </Button>
                            </Tooltip>
                          </GridItem>
                          {convo.modify && <GridItem xs={12} grey block largePadding>
                            <GridContainer>
                              {convo.validatetestdatawizardutterances && (convo.validatetestdatawizardutterances.utterances || []).map((utterance, utteranceindex) => (
                                <GridItem floatLeft key={`utterance_${utteranceindex}`}>
                                  <Card smallMargin>
                                    <CardBody>
                                      <GridContainer>
                                        <GridItem xs={12}><Text muted>User Example #{utteranceindex + 1}</Text></GridItem>
                                        <GridItem xs={12}><Text bold><ShortenedText maxlength={100}>{utterance}</ShortenedText></Text></GridItem>
                                      </GridContainer>
                                    </CardBody>
                                  </Card>
                                </GridItem>
                              ))}
                            </GridContainer>
                          </GridItem>}
                          <OnChange name={`${name}.script`}>
                            {(value, previous) => {
                              convo.validationError = null
                              convo.scriptGeneratingError = null
                            }}
                          </OnChange>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                })}
                <GridItem xs={12} >
                  <Button
                    onClick={() => mutators.push('convos', { desc: '', clientId: this.clientId++, selected: true })}
                    data-unique="btnTestDataWizardAddConvo"
                    secondary
                    dashed
                    fullWidth
                    noMargin
                    marginBottomTop
                  >
                    <ShowIcon icon="plus" />
                    Add New Test Case Description
                  </Button>
                </GridItem>
                <GridItem xs={12}>
                  <FormActionsToolbar
                    leftButtons={<>
                      <Button
                        onClick={async () => {
                          // errors are handled inside
                          const clonedConvos = [...values.convos]
                          for (const convo of clonedConvos) {
                            if (convosToGenerate.find(convoStruct => convoStruct.convo.clientId === convo.clientId)) {
                              convo.scriptGeneratingError = null
                            }
                          }
                          change('convos', clonedConvos)
                          await Promise.all(convosToGenerate.map(({ convo, name }) => this.generateScriptForConvo(convo, name, change, batch, values)))
                          setAlertSuccessMessage('Test Script(s) are generated')
                        }}
                        disabled={invalid || submitting || !convosToGenerate.length}
                        data-unique="btnTestDataWizardConvoGenerate"
                      >
                        <ShowIcon icon="magic" />
                        Generate User Examples ({convosToGenerate.length})
                      </Button>
                    </>}
                    rightButtons={<>
                      <Button secondary
                        onClick={() => reset({ ...INITIAL_VALUES, chatbotDomain: values.chatbotDomain, chatbotDescription: values.chatbotDescription })}
                        disabled={submitting}
                        data-unique="btnGuessConvoReset"
                      >
                        <ShowIcon icon="eraser" />
                        Clear list
                      </Button>
                      <Button
                        disabled={invalid || submitting || !convosToSave.length}
                        onClick={() => {
                          const scripts = convosToSave.map(c => ({
                            name: c.validatetestdatawizardutterances.name,
                            script: c.script,
                            scriptType: 'SCRIPTING_TYPE_UTTERANCES'
                          })).filter(s => s)
                          scripts.push(...convosToSave.map(c => ({
                            name: c.desc,
                            script: c.desc + '\n\n#me\n' + c.validatetestdatawizardutterances.name + '\n\n#bot\n',
                            scriptType: 'SCRIPTING_TYPE_CONVO'
                          })))
                          this.setState({ showCopyDialog: true, testDataWizardScripts: scripts })
                        }}
                        data-unique="btnTestDataWizardConvoSave"
                      >
                        <ShowIcon icon="save" />
                        Save User Examples ({convosToSave.length})
                      </Button>
                    </>}
                  />
                </GridItem>
              </>}
            </GridContainer>
          </form>
        }}
      />
    )
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ClientFeatureSection feature="aitestwizard">
            {this.renderForm()}
          </ClientFeatureSection>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage },
  ),
  graphql(TEST_DATA_WIZARD_SAMPLES, {
    props: ({ data }) => ({
      wizardSamplesData: data,
    })
  }),
  withApollo
)(TestDataWizard)
