import Button from 'components/Button/Button'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ShowIcon from 'components/Icon/ShowIcon'
import React, { useState } from 'react'
import { useRef, useEffect } from 'react'
import wavesurfer from 'wavesurfer.js'

export const VisualAudioPlayer = ({ blob }) => {

  const audioRef = useRef()

  let [audioTrack, setAudioTrack] = useState(null)
  let [audioPlaying, setAudioPlaying] = useState(false)

  useEffect(() => {
    if (audioRef.current){
      const audioTrack = wavesurfer.create({
          container: audioRef.current,
          waveColor: '#2B3E53',
          responsive: true,
          height: 100
      })
      audioTrack.on('interaction', () => {
        audioTrack.pause()
        setAudioPlaying(false)
      })
      audioTrack.on('finish', () => {
        audioTrack.pause()
        setAudioPlaying(false)
      })
      audioTrack.loadBlob(blob)
      setAudioTrack(audioTrack)
    }
  }, [blob])

  return <>
    <div style={{ width: '100px', textAlign: 'center' }}>
    <Button
        justIcon
        Border
        iconDark
        onClick={() => {
            if (!audioTrack.isPlaying()) {
              audioTrack.play()
              setAudioPlaying(true)
            } else {
              audioTrack.pause()
              setAudioPlaying(false)
            }}
        }
        >
          {audioTrack && audioPlaying && <ShowIcon icon="stop" />}
          {audioTrack && !audioPlaying && <ShowIcon icon="play" />}
          {!audioTrack && <LoadingIndicator />}
      </Button>
    </div>
    <div style={{ width: 'calc(100% - 100px)' }}>
        <div ref={audioRef}></div> 
    </div>
  </>
} 