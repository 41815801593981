const dialogStyle = theme => ({
  paper: {
    padding: 10,
    fontSize: 12,
    backgroundColor: theme.colors.backgroundPrimary,
    overflow: 'hidden',
  },
  dangerPaper: {
    fontSize: 12,
    backgroundColor: theme.colors.backgroundPrimary,
    overflow: 'hidden'
  },
  confirmationDialogTitleRoot: {
    padding: '0px !important'
  },
  dialogContentRoot: {
    overflowY: 'hidden',
    flex: '1 1 auto',
    padding: '24px 24px',
    position: 'relative'
  },
  dialogActionsLeft: {
    justifyContent: 'flex-start',
    //paddingLeft: 30
  },
  periodDate: {
    marginTop: 36,
  },
  paperPosition: {
  margin: 'auto', 
  maxWidth: 600, 
  boxShadow: 'none',
  backgroundColor: 'transparent'
} 
})

export default dialogStyle
