import React from 'react'
// @material-ui/core components
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderCheckbox,
  required,
  mustBeNumber,
  renderPasswordField,
  renderIntField,
  renderTextField,
  parseInteger
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function directLineCaps2Form(caps) {
  return {
    directLine: {
      secret: (
        caps.find(c => c.name === 'DIRECTLINE3_SECRET') || { stringValue: '' }
      ).stringValue,
      webSocket: (
        caps.find(c => c.name === 'DIRECTLINE3_WEBSOCKET') || {
          booleanValue: false,
        }
      ).booleanValue,
      pollingInterval: (
        caps.find(c => c.name === 'DIRECTLINE3_POLLINGINTERVAL') || {
          intValue: 1000,
        }
      ).intValue,
      generateUsername: (
        caps.find(c => c.name === 'DIRECTLINE3_GENERATE_USERNAME') || {
          booleanValue: false,
        }
      ).booleanValue,
      buttonType: (
        caps.find(c => c.name === 'DIRECTLINE3_BUTTON_TYPE') || { stringValue: '' }
      ).stringValue,
      buttonValueField: (
        caps.find(c => c.name === 'DIRECTLINE3_BUTTON_VALUE_FIELD') || { stringValue: '' }
      ).stringValue,
    },
  }
}

const capNamesMap = {
  'directLine.secret': 'DIRECTLINE3_SECRET',
  'directLine.webSocket': 'DIRECTLINE3_WEBSOCKET',
  'directLine.pollingInterval': 'DIRECTLINE3_POLLINGINTERVAL',
  'directLine.generateUsername': 'DIRECTLINE3_GENERATE_USERNAME',
  'directLine.buttonType': 'DIRECTLINE3_BUTTON_TYPE',
  'directLine.buttonValueField': 'DIRECTLINE3_BUTTON_VALUE_FIELD',
}

export function directLineForm2caps(values) {
  const capabilities = [
    {
      name: 'DIRECTLINE3_SECRET',
      type: 'STRING',
      stringValue: values.directLine.secret || '',
    },
    {
      name: 'DIRECTLINE3_WEBSOCKET',
      type: 'BOOLEAN',
      booleanValue: values.directLine.webSocket || false,
    },
    {
      name: 'DIRECTLINE3_POLLINGINTERVAL',
      type: 'INT',
      intValue: values.directLine.pollingInterval || 1000,
    },
    {
      name: 'DIRECTLINE3_GENERATE_USERNAME',
      type: 'BOOLEAN',
      booleanValue: values.directLine.generateUsername || false,
    },
    {
      name: 'DIRECTLINE3_BUTTON_TYPE',
      type: 'STRING',
      stringValue: values.directLine.buttonType || '',
    },
    {
      name: 'DIRECTLINE3_BUTTON_VALUE_FIELD',
      type: 'STRING',
      stringValue: values.directLine.buttonValueField || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'directline3' },
  ]

  return capabilities
}

export class DirectLineEdit extends React.Component {
  render() {
    const { advanced, disabled, capSetCapNames } = this.props
    return (<React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Field
            name="directLine.secret"
            component={renderPasswordField}
            label="Direct Line secret"
            validate={required}
            data-unique="pwDirectLineEditSecret"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['directLine.secret']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['directLine.secret'])}
          />
          <Text helperText>
            <a
              href="https://docs.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-direct-line-3-0-authentication?view=azure-bot-service-4.0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation how authentication works in directline
            </a>
          </Text>
        </GridItem>
        <GridItem xs={12} sm={6}>
        </GridItem>
      </GridContainer>
      {advanced && (
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <Field
              name="directLine.generateUsername"
              component={renderCheckbox}
              label="Generate unique username for each conversation"
              type="checkbox"
              data-unique="chkDirectLineEditGenerateUsername"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['directLine.generateUsername']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['directLine.generateUsername'])}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              name="directLine.webSocket"
              component={renderCheckbox}
              label="Enable Websocket Transport"
              type="checkbox"
              data-unique="chkDirectLineEditWebsocket"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['directLine.webSocket']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['directLine.webSocket'])}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              name="directLine.pollingInterval"
              component={renderIntField}
              label="Polling Interval"
              parse={parseInteger}
              validate={mustBeNumber}
              data-unique="intDirectLineEditPollingInterval"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['directLine.webSocket']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['directLine.webSocket'], 'Relevant only if not using Websocket Transport')}
            />
          </GridItem>
        </GridContainer>
      )}
      {advanced && (
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <Field
              name="directLine.buttonType"
              component={renderTextField}
              label="Button Activity Type"
              data-unique="txtDirectLineButtonType"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['directLine.buttonType']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['directLine.buttonType'])}
            />
            <Text helperText>
              Activity type to use for simulating button clicks by the user. Depending on your implementation most likely <em>event</em> or <em>message</em>
            </Text>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              name="directLine.buttonValueField"
              component={renderTextField}
              label="Button Activity Value Field"
              data-unique="txtDirectLineButtonValueField"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['directLine.buttonValueField']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['directLine.buttonValueField'])}
            />
            <Text helperText>
              Activity field to use for payload when simulating button clicks by the user. Depending on your implementation most likely <em>name</em> or <em>value</em>
            </Text>
          </GridItem>
        </GridContainer>
      )}
    </React.Fragment>)
  }
}
