import cardTitleStyle from 'assets/jss/material-dashboard-react/components/cardTitleStyle.jsx'

const settingsStyle = theme => ({
  ...cardTitleStyle(theme),
  filenameButton: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '400px',
    position: 'relative'
  },
  divider: {
    margin: '10px'
  },
  gutters: {
    paddingLeft: '0 !Important'
  }
})

export default settingsStyle
