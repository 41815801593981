import { gql } from 'apollo-boost'
import { deleteListQueryFromCache } from 'helper/cacheHelper'
import { CONNECTORFEATURES_FRAGMENT, NAMESPACES_QUERY } from 'views/Settings/gql'
import { LIST_TESTSESSION_FRAGMENT } from '../TestSessions/gql'
import { TRAINERSESSION_LIST_FRAGMENT } from '../TrainerSessions/gql'

const BASIC_TESTPROJECT_FRAGMENT = gql`
  fragment BasicTestProject on TestProject {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      namespace
      code
      description
      tags
      securityCheck
      nlpAnalytics
      gdprTesting
      performanceTesting
      regressionTesting
      chatbot {
        id
        name
        avatar
        containermode
        description
      }
      testSets {
        id
        name
      }
      deviceSets {
        id
        name
      }
      registeredComponents {
        id
        name
        ref
      }
      agent {
        id
        name
      }
      lastTestSession {
        id
        name
      }
      batchCount
      bail
      cronExpression
      notificationReceiversEmail
      notificationExcludeErrors
      notificationOnSuccess
      notificationForPerformanceTest
      skipTestCasesPatterns
  }
`

export const TESTPROJECTS_QUERY = gql`
  query TestProjectsQuery {
    testprojects {
      ...BasicTestProject
    }
  }
  ${BASIC_TESTPROJECT_FRAGMENT}
`

export const TESTPROJECTS_FAILING_QUERY = gql`
  query ChartFailingTestProjectsQuery($timeFrame: ChartTimeFrame!) {
    chartTestProjectResultCount(timeFrame: $timeFrame, onlyFailed: true) {
      id
      name
      href { testProjectId }
      lastRun
      lastTestSessionId
      testCaseLastTotalCount
      testCaseLastSuccessCount
      testCaseLastSuccessRate
      previousRun
      previousTestSessionId
      testCasePreviousTotalCount
      testCasePreviousSuccessCount
      testCasePreviousSuccessRate
      testCaseTrend
    }
  }
`

export const TESTPROJECTS_COUNT_QUERY = gql`
  query TestProjectsCountQuery {
    testprojectsCount
  }
`

export const TESTPROJECTS_COUNT_PAGINATED_QUERY = gql`
  query TestProjectsCountQuery($where: TestProjectWhereInput) {
    testprojectsCount(where: $where)
  }
`

const BASIC_TESTPROJECT_WITH_STATUS_FRAGMENT = gql`
  fragment BasicTestProjectWithStatus on TestProject {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      name
      namespace
      code
      description
      tags
      securityCheck
      nlpAnalytics
      gdprTesting
      performanceTesting
      regressionTesting
      monitoring
      e2eTesting
      chatbot {
        id
        name
        namespace
        avatar
        containermode
        description
        connector {
          ...ConnectorFeatures
        }
      }
      testSets {
        id
        name
        namespace
      }
      deviceSets {
        id
        name
      }
      registeredComponents {
        id
        name
        ref
      }
      agent {
        id
        name
      }
      lastTestSession {
        id
        name
        namespace
        chartData
        status
        jobs {
          id
          createdAt
          jobId
          progressPercent
          totalCount
          failedCount
          successCount
          status
          err
          started
          finished
        }
      }
      batchCount
      bail
      cronExpression
      notificationReceiversEmail
      notificationExcludeErrors
      notificationOnSuccess
      notificationForPerformanceTest
      skipTestCasesPatterns
  }
  ${CONNECTORFEATURES_FRAGMENT}
`

export const TESTPROJECTS_WITH_STATUS_QUERY = gql`
  query TestProjectsQuery {
    testprojects {
      ...BasicTestProjectWithStatus
    }
  }
  ${BASIC_TESTPROJECT_WITH_STATUS_FRAGMENT}
`

export const TESTPROJECTS_WITH_STATUS_PAGINATED_QUERY = gql`
  query TestProjectsQuery($where: TestProjectWhereInput, $orderBy: TestProjectOrderByInput, $skip: Int, $first: Int) {
    testprojects(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      ...BasicTestProjectWithStatus
    }
  }
  ${BASIC_TESTPROJECT_WITH_STATUS_FRAGMENT}
`

export const TESTPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY = gql`
  query TestProjectsQuery($where: TestProjectWhereInput, $orderBy: TestProjectOrderByInput, $skip: Int, $first: Int) {
    testprojectswithlasttestsession(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      ...BasicTestProjectWithStatus
      lastTestSession {
        ...ListTestSession
        trainerSession {
          ...TrainerSessionList
        }
      }
    }
  }
  ${BASIC_TESTPROJECT_WITH_STATUS_FRAGMENT}
  ${LIST_TESTSESSION_FRAGMENT}
  ${TRAINERSESSION_LIST_FRAGMENT}
`

export const RECENT_TESTPROJECTS_WITH_STATUS_QUERY = gql`
  query TestProjectsQuery($skip: Int, $first: Int) {
    testprojects(where: { NOT: [{lastTestSessionDate: null},{lastTestSession: null}]}, orderBy: lastTestSessionDate_DESC, skip: $skip, first: $first) {
      ...BasicTestProjectWithStatus
    }
  }
  ${BASIC_TESTPROJECT_WITH_STATUS_FRAGMENT}
`

const TESTPROJECT_HISTORY_FRAGMENT = gql`
  fragment TestProjectHistory on TestProjectHistoryTable {
    testsessions { id name createdAt }
    count
    rows {
      status
      testcaseName
      deviceName
      testcaseResults {
        id
        success
        testsession {
          id
          name
          chatbot {
            id
            allowHtmlDisplay
          }
        }
      }
    }
  }
`

const FULL_TESTPROJECT_FRAGMENT = gql`
  fragment FullTestProject on TestProject {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    code
    description
    lastTestSessionDate
    tags
    lastTestSession {
      id
    }
    securityCheck
    nlpAnalytics
    performanceTesting
    gdprTesting
    e2eTesting
    regressionTesting
    monitoring
    minimumAverageConfidence
    maximumConfidenceDeviation
    minimumConfidenceDiff
    minimumUtterancesPerIntent
    minimumUtterancesPerEntity
    chatbot {
      id
      name
      avatar
      containermode
    }
    testSets {
      id
      name
      statsUpdatedAt
      statsConvoCount
      statsPartialConvoCount
      statsUtterancesRefCount
      statsUtterancesCount
      statsScriptingMemoryCount
    }
    deviceSets {
      id
      name
    }
    registeredComponents {
      id
      name
      ref
    }
    agent {
      id
      name
    }
    batchCount
    bail
    cronExpression
    notificationReceiversEmail
    notificationExcludeErrors
    notificationOnSuccess
    notificationForPerformanceTest
    skipTestCasesPatterns
    performanceTestType
    parallelJobCount
    tickRepeatInitial
    tickRepeatPerTick
    tickMaxTime
    tickTime
    requiredPercentOfSuccesfulUsers
    shareContainer
    simpleConvo
    detailedReporting
    waitForBotTimeout
    gdprLanguage
    gdprFallbackIntents
    gdprFallbackResponses
    capabilities {
      id
      createdAt
      updatedAt
      name
      type
      stringValue
      intValue
      booleanValue
      jsonValue
    }
    envs {
      id
      createdAt
      updatedAt
      name
      stringValue
    }
    aggregators {
      id
      createdAt
      updatedAt
      asserter
      exactValue
      minValue
      maxValue
      registeredAggregator {
        id
        name
        label
        type
      }
    }
  }
`

export const TESTPROJECT_QUERY = gql`
  query TestProjectQuery($id: ID!) {
    testproject(id: $id) {
      ...FullTestProject
    }
  }
  ${FULL_TESTPROJECT_FRAGMENT}
`

export const CREATE_TESTPROJECT = gql`
  mutation CreateTestProject($testProject: TestProjectCreateInput!) {
    createTestProject(testProject: $testProject) {
      ...FullTestProject
    }
  }
  ${FULL_TESTPROJECT_FRAGMENT}
`

export const UPDATE_TESTPROJECT = gql`
  mutation UpdateTestProject($id: ID!, $testProject: TestProjectUpdateInput!) {
    updateTestProject(id: $id, testProject: $testProject) {
      ...FullTestProject
    }
  }
  ${FULL_TESTPROJECT_FRAGMENT}
`

export const DELETE_TESTPROJECT = gql`
  mutation DeleteTestProject($id: ID!) {
    deleteTestProject(id: $id)
  }
`

export const START_TESTPROJECT = gql`
  mutation StartTestProject($id: ID!, $debug: Boolean!) {
    startTestProject(id: $id, debug: $debug) {
      ...ListTestSession
    }
  }
  ${LIST_TESTSESSION_FRAGMENT}
`

export const START_TESTPROJECTS = gql`
  mutation StartTestProjects($testProjectIds: [ID!], $debug: Boolean!) {
    startTestProjects(testProjectIds: $testProjectIds, debug: $debug) {
      ...ListTestSession
    }
  }
  ${LIST_TESTSESSION_FRAGMENT}
`

export const TESTPROJECT_HISTORY_QUERY = gql`
  query TestProjectHistoryQuery($testProjectId: ID!, $status: [TestProjectHistoryStatusType!], $filter: String, $skip: Int, $first: Int) {
    testprojectHistory(testProjectId: $testProjectId, status: $status, filter: $filter, skip: $skip, first: $first) @connection(key: "testprojectHistory", filter: ["testProjectId","status","filter"]) {
      ...TestProjectHistory
    }
  }
  ${TESTPROJECT_HISTORY_FRAGMENT}
`

const FULL_TESTPROJECT_AGGREGATOR_FRAGMENT = gql`
  fragment FullTestProjectAggregator on TestProjectAggregator {
    id
    createdAt
    updatedAt
    asserter
    exactValue
    minValue
    maxValue
    registeredAggregator {
      id
      name
      label
      type
    }
  }
`


export const QUICKSTART_TESTPROJECT = gql`
  mutation QuickstartTestProject($testProject: TestProjectCreateInput!, $startProject: Boolean!, $gdprLanguage: String, $gdprFallbackIntents: [String!], $gdprFallbackResponses: [String!], $performanceTest: PerformanceTestSessionCreate ) {
    quickstartTestProject(testProject: $testProject, startProject: $startProject, gdprLanguage: $gdprLanguage, gdprFallbackIntents: $gdprFallbackIntents, gdprFallbackResponses: $gdprFallbackResponses, performanceTest: $performanceTest) {
      projectId
      chatbotId
      testSetIds
      startedSessionId
      startedPerformanceSessionId
    }
  }
`

export const CREATE_TESTPROJECT_AGGREGATOR = gql`
  mutation CreateTestProjectAggregator($testProjectId: ID!, $aggregator: TestProjectAggregatorInput!) {
    createTestProjectAggregator(testProjectId: $testProjectId, aggregator: $aggregator) {
      ...FullTestProjectAggregator
    }
  }
  ${FULL_TESTPROJECT_AGGREGATOR_FRAGMENT}
`

export const UPDATE_TESTPROJECT_AGGREGATOR = gql`
  mutation UpdateTestProjectAggregator($id: ID!, $aggregator: TestProjectAggregatorInput!) {
    updateTestProjectAggregator(id: $id, aggregator: $aggregator) {
      ...FullTestProjectAggregator
    }
  }
  ${FULL_TESTPROJECT_AGGREGATOR_FRAGMENT}
`

export const DELETE_TESTPROJECT_AGGREGATOR = gql`
  mutation DeleteTestProjectAggregator($id: ID!) {
    deleteTestProjectAggregator(id: $id)
  }
`

export const DeleteTestProjectsListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^testprojects/)
  deleteListQueryFromCache(store, /^testprojectswithlasttestsession/)
}

export const RefetchTestProjectQueries = () => [
  {
    query: NAMESPACES_QUERY
  },
  {
    query: TESTPROJECTS_QUERY,
  },
  {
    query: TESTPROJECTS_WITH_STATUS_QUERY,
  },
  {
    query: TESTPROJECTS_COUNT_QUERY,
  },
  {
    query: TESTPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY,
  },
]

export const RefetchTestProjectQueriesOnNewTestSession = (testProjectId) => [
]
