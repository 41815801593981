import React from 'react'
import { NavLink } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'

import Tooltip from 'components/Tooltip/Tooltip'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import Text from 'components/Typography/Text.jsx'

import TestSessionProgress from './TestSessionProgress.jsx'
import {
  TESTSESSIONPROGRESS_GDPR_QUERY, TESTSESSIONPROGRESS_GDPR_SUBSCRIPTION
} from './gql'

class GDPRSessionProgressIcon extends React.Component {

  render() {
    const { testSession, variables, withoutNavLink, classes } = this.props

    return (
      <TestSessionProgress
        query={TESTSESSIONPROGRESS_GDPR_QUERY}
        subscription={TESTSESSIONPROGRESS_GDPR_SUBSCRIPTION}
        testSession={testSession}
        readySelector={(ts => {
          const isSessionDone = ts && (ts.status === 'READY' || ts.status === 'FAILED')
          const isAuditDone = ts && ts.gdprTestSessionAudit && (ts.gdprTestSessionAudit.status === 'READY' || ts.gdprTestSessionAudit.status === 'FAILED')
          return ts && ((isSessionDone && isAuditDone) || ts.status === 'CANCELLED')
        })}
        key={`gicon_${testSession && testSession.id}_${variables && JSON.stringify(variables)}`}
      >
        {({ testSessionProgress }) => {
          const isSessionDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED')
          const isAuditDone = testSessionProgress && testSessionProgress.gdprTestSessionAudit && (testSessionProgress.gdprTestSessionAudit.status === 'READY' || testSessionProgress.gdprTestSessionAudit.status === 'FAILED')
          let dataStatus = 'READY'
          let resultIcon = ''

          if (!isSessionDone || !isAuditDone) {
            dataStatus = 'RUNNING'
            resultIcon = <Tooltip title={'Running'}>
                <Text warningBg bgradius iconRotation icon="circle-notch">RUNNING</Text>
            </Tooltip>
          }
          if (testSessionProgress && (testSessionProgress.status === 'FAILED' || (testSessionProgress.gdprTestSessionAudit && testSessionProgress.gdprTestSessionAudit.status === 'FAILED'))) {
            dataStatus = 'FAILED'
            resultIcon = <>{false && <Tooltip title={'Failed'}>
                  <Text dangerBg bgradius icon="triangle-exclamation">FAILED</Text>
              </Tooltip>}</>
          }
          if(withoutNavLink) {
            return <div className={classes.resultIconPositionNavLink} data-unique={`btnGDPRSessionProgressIcon_${testSession.id}`}
                        data-status={dataStatus}>
              {resultIcon}
            </div>
          } else {
            return <NavLink tabIndex={-1} to={`/gdpr/projects/view/${testSession.testProject.id}/results/${testSession.id}`}
                            data-unique={`btnGDPRSessionProgressIcon_${testSession.id}`}
                            data-status={dataStatus}>
              {resultIcon}
            </NavLink>
          }
        }}
      </TestSessionProgress>
    )
  }
}

export default withStyles(testsessionsStyle)(GDPRSessionProgressIcon)
