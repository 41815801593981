import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Query, withApollo } from 'react-apollo'
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Chip from 'components/Chip/Chip'
import QueryStatus from 'components/Info/QueryStatus'
import { safeGetNamespaceFilteredList } from '../helper'

import {
  renderSelect,
  renderCheckbox,
  required
} from 'components/Form/Form'
import { TESTSET_COMPILEDUTTERANCES_QUERY, TESTSET_COMPILEDCONVOS_QUERY, TESTSETS_DROPDOWN_QUERY, TESTSET_QUERY } from './gql'

class TestSetContentSelector extends React.Component {
  constructor(props) {
    super(props)

    if (props.addFormValidate) {
      props.addFormValidate((values) => this._validateCount(values))
    }
  }

  _getContentFilter(values) {
    return {
      local: !!values.local,
      repositoryIds: values.repositories || [],
      folderIds: values.folders || [],
      downloadlinkIds: values.downloadlinks || []
    }
  }

  async _validateCount(values) {
    const { client, showUtterances, showConvos } = this.props

    if (values.testSetId) {
      try {
        const countsPromises = []
        if (showUtterances) {
          countsPromises.push(
            client.query({
              query: TESTSET_COMPILEDUTTERANCES_QUERY,
              variables: { testSetId: values.testSetId, content: this._getContentFilter(values), skip: 0, first: 1 },
              fetchPolicy: 'no-cache'
            })
            .then(({ data }) => (data && data.testsetcompiledutterances && data.testsetcompiledutterances.length) || 0)
          )
        }
        if (showConvos) {
          countsPromises.push(
            client.query({
              query: TESTSET_COMPILEDCONVOS_QUERY,
              variables: { testSetId: values.testSetId, content: this._getContentFilter(values), skip: 0, first: 1 },
              fetchPolicy: 'no-cache'
            })
            .then(({ data }) => (data && data.testsetcompiledconvos && data.testsetcompiledconvos.length) || 0)
          )
        }
        const count = (await Promise.all(countsPromises)).reduce((sum, c) => sum + c, 0)
        if (count === 0) {
          return { content: `No content selected` }
        }
      } catch (err) {
        return { content: `Content query failed (${err.message})` }
      }
    }
    return {}
  }

  render() {
    const { allowChangeTestSet, allowChangeLocal, showUtterances, showConvos } = this.props

    const selectionText = showUtterances && showConvos ? 'user examples and convos' : showUtterances && !showConvos ? 'user examples' : !showUtterances && showConvos ? 'convos' : 'data'

    return <FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => (<>
      {allowChangeTestSet &&
        <GridItem xs={12}>
          <Query query={TESTSETS_DROPDOWN_QUERY}>
            {(queryResult) => <QueryStatus {...queryResult} query="testsets">{({ testsets }) => {
              return (
                <Field
                  name="testSetId"
                  component={renderSelect}
                  label="Select Test Set"
                  validate={required}
                  data-unique="selTestSetContentSelectorTestSetId"
                  items={ safeGetNamespaceFilteredList(testsets, this.props.namespace).map(a => {
                    return { key: a.id, label: a.name }
                  })}
                />
              )
            }}
            </QueryStatus>}
          </Query>
        </GridItem>
      }
      <GridItem xs={12}>
        <OnChange name="testSetId">
          {(value, previous) => {
            if (value !== previous) {
              change('repositories', [])
              change('folders', [])
              change('downloadlinks', [])
            }
          }}
        </OnChange>
        {values.testSetId &&
          <Query query={TESTSET_QUERY} variables={{ id: values.testSetId }}>
            {(queryResult) => <QueryStatus {...queryResult} query="testset">{({ testset }) => {
              return (<GridContainer>
                {allowChangeLocal &&
                  <GridItem xs={12}>
                    <Field
                      name="local"
                      component={renderCheckbox}
                      label={`Include ${selectionText} from Botium test cases`}
                      type="checkbox"
                      data-unique="chkTestSetContentSelectorLocal"
                    />
                  </GridItem>
                }
                {testset.repositories.length > 0 &&
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="repositories"
                      component={renderSelect}
                      label={`Include ${selectionText} from Git Repositories`}
                      items={testset.repositories.map(a => {
                        return { key: a.id, label: a.name }
                      })}
                      multiple
                      data-unique="selTestSetContentSelectorRepositorySelection"
                    />
                  </GridItem>
                }
                {testset.folders.length > 0 &&
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="folders"
                      component={renderSelect}
                      label={`Include ${selectionText} from Shared Folders`}
                      items={testset.folders.map(a => {
                        return { key: a.id, label: a.name }
                      })}
                      multiple
                      data-unique="selTestSetContentSelectorFolderSelection"
                    />
                  </GridItem>
                }
                {testset.downloadlinks.length > 0 &&
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="downloadlinks"
                      component={renderSelect}
                      label={`Include ${selectionText} from Download Links`}
                      items={testset.downloadlinks.map(a => {
                        return { key: a.id, label: a.name }
                      })}
                      multiple
                      data-unique="selTestSetContentSelectorDownloadLinkSelection"
                    />
                  </GridItem>
                }
              </GridContainer>)
            }}
            </QueryStatus>}
          </Query>
        }
      </GridItem>
      <GridItem xs={12} grey largePadding>
        <GridContainer><GridItem xs={12}>
          {values.testSetId && showUtterances &&
            <Query
              query={TESTSET_COMPILEDUTTERANCES_QUERY}
              variables={{ testSetId: values.testSetId, content: this._getContentFilter(values), skip: 0, first: 21 }}>
              {(queryResult) => <QueryStatus {...queryResult} query="testsetcompiledutterances">{({ testsetcompiledutterances }) => {
                const content = testsetcompiledutterances || []

                const combinedContent = _.uniq(content.map(c => c.name)).map(uttName => ({
                  name: uttName,
                  uttSamples: content.filter(c => c.name === uttName && c.uttSamples).reduce((agg, utt) => [...agg, ...utt.uttSamples], []),
                  uttCount: content.filter(c => c.name === uttName && c.uttCount).reduce((agg, utt) => agg + utt.uttCount, 0)
                }))
                return (<React.Fragment>
                  {_.sortBy(combinedContent, [u => u.name.toLowerCase()]).slice(0, 20).map((u, i) => (
                    <Chip key={i} variant="utterance" label={u.name} badge={u.uttCount} tooltip={(u.uttSamples || []).join(' | ')} />
                  ))}
                  {combinedContent.length > 20 && <Chip variant="utterance" label={`...`} />}
                </React.Fragment>)
              }}
              </QueryStatus>}
            </Query>
          }
          {values.testSetId && showConvos &&
            <Query
              query={TESTSET_COMPILEDCONVOS_QUERY}
              variables={{ testSetId: values.testSetId, content: this._getContentFilter(values), skip: 0, first: 21 }}>
              {(queryResult) => <QueryStatus {...queryResult} query="testsetcompiledconvos">{({ testsetcompiledconvos }) => {
                const content = testsetcompiledconvos || []
                return (<React.Fragment>
                  {_.sortBy(content, [c => c.name.toLowerCase()]).slice(0, 20).map((c, i) => (
                    <Chip key={i} variant="convo" label={c.name} badge={c.stepCount} tooltip={(c.stepSamples || []).join(' | ')} />
                  ))}
                  {content.length > 20 && <Chip variant="convo" label={`...`} />}
                </React.Fragment>)
              }}
              </QueryStatus>}
            </Query>
          }
        </GridItem></GridContainer>
      </GridItem>
      <GridItem xs={12}>



      </GridItem>
    </>)}
    />
  }
}

TestSetContentSelector.propTypes = {
  allowChangeTestSet: PropTypes.bool,
  allowChangeLocal: PropTypes.bool,
  showUtterances: PropTypes.bool,
  showConvos: PropTypes.bool
}
TestSetContentSelector.defaultProps = {
  allowChangeTestSet: false,
  allowChangeLocal: false,
  showUtterances: true,
  showConvos: false
}

export default connect(
  state => ({ user: state.token.user, namespace: state.namespace })
)(withApollo(TestSetContentSelector))
