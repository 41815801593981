import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Snack from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
// @material-ui/icons
import Close from '@material-ui/icons/Close'
import OpenInNew from '@material-ui/icons/OpenInNew'
// core components
import snackbarContentStyle from 'assets/jss/material-dashboard-react/components/snackbarContentStyle.jsx'

function Snackbar({ ...props }) {
  const {
    classes,
    message,
    color,
    close,
    link,
    icon,
    place,
    open,
    autoHideDuration,
    closeNotification,
    ...rest
  } = props
  var action = []
  const messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined,
  })
  if (close) {
    action.push(
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => closeNotification && closeNotification()}
      >
        <Close className={classes.close} />
      </IconButton>,
    )
  }
  if (link) {
    action.push(
      <IconButton
        className={classes.iconButton}
        key="open"
        aria-label="Open"
        color="inherit"
        onClick={() => window.open(link, '_blank')}
      >
        <OpenInNew />
      </IconButton>,
    )
  }
  return (
    <Snack
      anchorOrigin={{
        vertical: place.indexOf('t') === -1 ? 'bottom' : 'top',
        horizontal:
          place.indexOf('l') !== -1
            ? 'left'
            : place.indexOf('c') !== -1
              ? 'center'
              : 'right',
      }}
      open={open}
      message={
        <div>
          {icon !== undefined ? <props.icon className={classes.icon} /> : null}
          <span className={messageClasses}>{message}</span>
        </div>
      }
      action={action}
      ContentProps={{
        classes: {
          root: classes.root + ' ' + classes[color],
          message: classes.message,
        },
      }}
      onClose={() => close && closeNotification && closeNotification()}
      autoHideDuration={autoHideDuration}
      {...rest}
    />
  )
}

Snackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary']),
  close: PropTypes.bool,
  icon: PropTypes.func,
  place: PropTypes.oneOf(['tl', 'tr', 'tc', 'br', 'bl', 'bc']),
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number,
}

export default withStyles(snackbarContentStyle)(Snackbar)
