import React from 'react'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'

import {
  TESTSESSION_PROGRESS_QUERY,
  TESTSESSIONPROGRESS_SUBSCRIPTION,
} from './gql'

class TestSessionProgress extends React.Component {
  _isMounted = false

  state = {
    subscription: null,
    testSessionProgress: null,
    testSessionProgressLoading: true,
    testSessionProgressErr: null
  }

  componentDidMount() {
    this._isMounted = true
    this.subscribe()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.testSession || prevProps.testSession.id !== this.props.testSession.id || JSON.stringify(prevProps.variables || {}) !== JSON.stringify(this.props.variables || {})) {
      this.unsubscribe()
      this.subscribe()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.unsubscribe()
  }

  subscribe() {
    const { client, testSession } = this.props
    const { query, subscription: subscriptionQuery, variables } = this.props

    if (!testSession || !testSession.id) return

    const thisProps = this.props
    const setStateIfMounted = (...args) => {
      if (!this._isMounted) return
      this.setState(...args)
    }
    client
      .query({
        query: query || TESTSESSION_PROGRESS_QUERY,
        variables: Object.assign({}, { id: testSession.id }, variables),
        fetchPolicy: 'no-cache'
      })
      .then(r => {
        if (!this._isMounted) return
        const qSel = this.props.querySelector || (data => data.testsession)
        const data = qSel(r.data)
        if (data != null) {
          setStateIfMounted({
            testSessionProgress: data,
            testSessionProgressLoading: false,
            testSessionProgressErr: null
          })
          const readySel = this.props.readySelector || (ts => ts && (ts.status === 'READY' || ts.status === 'FAILED' || ts.status === 'CANCELLED'))
          if (readySel(data)) return

          const subscription = client
            .subscribe({
              query: subscriptionQuery || TESTSESSIONPROGRESS_SUBSCRIPTION,
              variables: Object.assign({}, { id: testSession.id }, variables)
            })
            .subscribe({
              next(s) {
                const sSel = thisProps.subscriptionSelector || (data => data.testSessionProgress)
                if (s && s.data && sSel(s.data) != null) {
                  setStateIfMounted({
                    testSessionProgressLoading: false,
                    testSessionProgressErr: null,
                    testSessionProgress: sSel(s.data)
                  })
                }
              },
              error(err) {
                setStateIfMounted({
                  testSessionProgressLoading: false,
                  testSessionProgressErr: err
                })
              },
            })
          setStateIfMounted({
            subscription
          })
        } else {
          setStateIfMounted({
            testSessionProgressLoading: false,
            testSessionProgressErr: null
          })
        }
      })
      .catch(err => {
        setStateIfMounted({
          testSessionProgressLoading: false,
          testSessionProgressErr: err
        })
      })
  }

  unsubscribe() {
    if (this.state.subscription) {
      this.state.subscription.unsubscribe()
      this._isMounted && this.setState({
        subscription: null,
        testSessionProgress: null,
        testSessionProgressLoading: true,
        testSessionProgressErr: null
      })
    }
  }

  render () {
    const { testSessionProgress, testSessionProgressLoading, testSessionProgressErr } = this.state
    const { testSession } = this.props

    return this.props.children({
      testSessionProgress: testSessionProgress == null ? testSession : testSessionProgress,
      testSessionProgressLoading,
      testSessionProgressErr,
      subscribe: () => this.subscribe(),
      unsubscribe: () => this.unsubscribe()
    })
  }
}

TestSessionProgress.propTypes = {
  query: PropTypes.any,
  querySelector: PropTypes.func,
  subscription: PropTypes.any,
  subscriptionSelector: PropTypes.func,
  readySelector: PropTypes.func,
  testSession: PropTypes.any,
  variables: PropTypes.any,
  children: PropTypes.func.isRequired
}

export default withApollo(TestSessionProgress)
