import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Controlled as CodeMirror} from 'react-codemirror2'
import copyToClipboard from 'copy-to-clipboard'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from 'components/List/ListItem/ListItemText'
import BotiumIcon from 'assets/img/botium-logo.png'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
// apollo
import { Subscription } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Chip from 'components/Chip/Chip'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ImageTiles from 'components/Convo/ImageTiles'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import AvatarImage from 'components/Avatar/AvatarImage'
import Button from 'components/Button/Button'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary'
import { setAlertSuccessMessage } from 'actions/alert'
import { removeBuffers } from 'botium-box-shared/utils/bufferUtils'
import ShowIcon from 'components/Icon/ShowIcon'
import Text from 'components/Typography/Text.jsx'

import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle'
import { safeMessageText, NLPPrimaryIntentChip, NLPAlternateIntentsChip, NLPEntitiesChip, Card, MeButton, Form, BotButton } from './Shared.jsx'
import { isDarkmode } from 'components/Darkmode/helper.jsx'
import { ClickAwayListener } from '@material-ui/core'
import { dialogflowCxChips } from './DialogflowCxConvo'

const CONVOSTEPS_SUBSCRIPTION = gql`
  subscription LiveChatConvoStepAdded($conversationId: String!) {
    liveChatConvoStepAdded(conversationId: $conversationId) { event err convoStep }
  }
`

class Convo extends React.Component {
  state = {
    messagesList: [],
    code: null,
    showAttachmentsIndex: null,
    showAttachmentsAnchorEl: null
  }

  renderSubscription() {
    const { subscribeToConversationId, onSubscriptionReceivedData } = this.props

    if (subscribeToConversationId) {
      return (<React.Fragment>
        <Subscription
          subscription={CONVOSTEPS_SUBSCRIPTION}
          variables={{ conversationId: subscribeToConversationId }}
          onSubscriptionData={({ subscriptionData }) => {
            if (!subscriptionData.data.liveChatConvoStepAdded) return

            if (subscriptionData.data.liveChatConvoStepAdded.convoStep) {
              this.setState({
                messagesList: [
                  ...this.state.messagesList,
                  JSON.parse(subscriptionData.data.liveChatConvoStepAdded.convoStep),
                ],
              })
            }
            if (onSubscriptionReceivedData) onSubscriptionReceivedData(subscriptionData.data.liveChatConvoStepAdded)
          }}
        />
      </React.Fragment>)
    }
  }

  componentDidMount() {
    const { messages } = this.props
    this.setMessages(messages)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { messages, subscribeToConversationId } = nextProps
    if (
      this.props.subscribeToConversationId &&
      this.props.subscribeToConversationId !== subscribeToConversationId
    ) {
      this.setState({
        messagesList: [],
      })
    } else {
      this.setMessages(messages)
    }
  }

  setMessages(messages) {
    if (messages) {
      let messagesList = []
      if (_.isArray(messages)) {
        messagesList = messages
      } else if (_.isString(messages)) {
        try {
          messagesList = JSON.parse(messages)
          if (!_.isArray(messagesList)) return
        } catch (err) {
          return
        }
      }
      messagesList = messagesList.map(m => {
        if (_.isString(m)) return JSON.parse(m)
        return m
      })
      this.setState({
        messagesList,
      })
    }
  }

  renderStepAttachments(index, msg) {
    const attachmentTiles = (msg && msg.attachments && msg.attachments.map((a, i) => ({
      base64: a.base64,
      mimeType: a.mimeType,
      id: i,
      title: a.name
    }))) || []

    return (<ImageTiles
      images={attachmentTiles}
      key={index}
      heading={`${attachmentTiles.length} Attachment(s)`}
      cards
    />)
  }

  render() {
    const { messagesList, showAttachmentsIndex, showAttachmentsAnchorEl } = this.state
    const { classes, chatbotId, onButtonClick, onSubmit, allowHtmlDisplay } = this.props

    const botiumScriptWithoutBuffers = (this.state.code && JSON.stringify(removeBuffers(_.omit(this.state.code, ['attachments']), false, false), null, 2)) || ''
    const botiumScriptForDisplay = removeBuffers(_.omit(this.state.code, ['attachments']), true, true)
    const botiumScript = (this.state.code && JSON.stringify(botiumScriptForDisplay, null, 2)) || ''
    return (
      <React.Fragment>
        {this.renderSubscription()}
        <List component="div">
          {messagesList.map((msg, index) => {
            return <ListItem key={index} data-unique={`${index}_${msg.sender}`} >
              {msg.sender === 'bot' && (
                <ListItemIcon >
                  <GridContainer top>
                        <GridItem>
                  <AvatarImage chatbotId={chatbotId} />
                  </GridItem>
                  </GridContainer>
                </ListItemIcon>
              )}
              <ListItemText style={{paddingLeft: 24}}
                className={
                  msg.sender === 'me' ? classes.senderMe : classes.senderBot
                }
              >
                <MeButton msg={msg} />
                {msg.sender === 'me' && (!msg.buttons || msg.buttons.length === 0) && msg.messageText && <Chip variant="info" multiline avatarImage={<ShowIcon custom icon="text" />} label={safeMessageText(msg.messageText)} allowHtmlDisplay={allowHtmlDisplay} />}
                {msg.sender === 'me' &&
                  msg.media && (
                    <ImageTiles
                      images={msg.media.map((m, index) => ({
                        imageSrc: m.buffer || m.mediaUri,
                        title: m.altText,
                        mimeType: m.mimeType,
                        id: index,
                      }))}
                      cards
                      key={'images'}
                      maxHeight={250}
                    />
                  )}
                {msg.sender === 'bot' && msg.messageText && <Chip variant="info" multiline avatarImage={<ShowIcon custom icon="text" />} label={safeMessageText(msg.messageText)} allowHtmlDisplay={allowHtmlDisplay} />}
                {msg.sender === 'bot' && msg.buttons && (!msg.forms || msg.forms.length === 0) && msg.buttons.map((button, index) =>
                  <BotButton key={'button_' + index} button={button} allowHtmlDisplay={allowHtmlDisplay} onButtonClick={onButtonClick} />
                )}
                <NLPPrimaryIntentChip msg={msg} />
                <NLPAlternateIntentsChip msg={msg} />
                <NLPEntitiesChip msg={msg} />
                {msg.forms && msg.forms.length > 0 && <Form msg={msg} classes={classes} onButtonClick={onButtonClick} onSubmit={onSubmit} allowHtmlDisplay={allowHtmlDisplay} />}
                {msg.sender === 'bot' && msg.media && (
                  <ImageTiles
                    images={msg.media.map((m, index) => ({
                      imageSrc: m.buffer || m.mediaUri,
                      title: m.altText,
                      mimeType: m.mimeType,
                      id: index,
                    }))}
                    cards
                    key={'images'}
                    maxHeight={250}
                  />
                )}

                {msg.sender === 'bot' && msg.cards && (
                  <GridContainer>
                    {msg.cards.map((card, index) => <Card card={card} key={index} index={index} classes={classes} onButtonClick={onButtonClick} onSubmit={onSubmit} allowHtmlDisplay={allowHtmlDisplay}/> )}
                  </GridContainer>
                )}
                <>
                  {dialogflowCxChips({ msg })}
                </>

              </ListItemText>
              {msg.sender === 'me' && (
                <ListItemIcon>
                  <GridContainer top>
                        <GridItem>
                  <Text primary>
                    <img src={BotiumIcon} alt="botium" width="32"/>
                  </Text>
                  </GridItem>
                  </GridContainer>
                </ListItemIcon>
              )}
              <ListItemIcon>
                <Text primary>
                  <Button justIcon aria-label="View Code" onClick={() => this.setState({ code: msg })}>
                    <ShowIcon icon="code" />
                  </Button>
                </Text>
              </ListItemIcon>

              <ListItemIcon>
                <Button justIcon disabled={!msg.attachments || msg.attachments.length === 0} aria-label="View Attachments" onClick={({ currentTarget }) => showAttachmentsIndex === index ? this.setState({ showAttachmentsIndex: null, showAttachmentsAnchorEl: null }) : this.setState({ showAttachmentsIndex: index, showAttachmentsAnchorEl: currentTarget })}>
                <ShowIcon icon="paperclip" />
                </Button>
              </ListItemIcon>

              <Popper open={showAttachmentsIndex === index} anchorEl={showAttachmentsAnchorEl} modifiers={{computeStyle: {gpuAcceleration: false}}} placement="bottom-end" transition style={{zIndex: 1000}}>
                {({ TransitionProps }) => (
                  <ClickAwayListener onClickAway={() => this.setState({ showAttachmentsIndex: null, showAttachmentsAnchorEl: null })}>
                  <Fade {...TransitionProps} timeout={350}>

                    <Paper className={classes.popupAttachments}>
                      {this.renderStepAttachments(showAttachmentsIndex, messagesList[showAttachmentsIndex])}
                    </Paper>

                  </Fade>
                  </ClickAwayListener>
                )}
              </Popper>
            </ListItem>
          })}
        </List>
        <ConfirmationDialog
          cancelText="Close"
          open={!!this.state.code}
          onCancel={() => this.setState({ code: null })}
          maxWidth="lg"
          extraButton={
            <Button secondary
              onClick={() => {
                copyToClipboard(botiumScriptWithoutBuffers)
              }}
            >
              <ShowIcon icon="clipboard" />
              Copy Code to Clipboard
            </Button>
          }
          >
          <CustomTabsSecondary
            noCard
            skipPersist
            headerColor="info"
            tabs={[
              {
                tabName: 'Botium Code',
                tabContent: <GridContainer>
                  <GridItem xs={12}>
                    <CodeMirror
                      value={botiumScript}
                      options={{
                        lineNumbers: true,
                        cursorHeight: 0.8,
                        readOnly: true,
                        mode: 'application/json',
                        theme: isDarkmode() ? 'botium-darkmode' : 'default'
                      }}
                    />
                  </GridItem>
                </GridContainer>
              },
              this.state.code && this.state.code.attachments && this.state.code.attachments.length > 0 && {
                tabName: 'Attachments',
                tabContent: <GridContainer>
                  <GridItem xs={12}>
                    <ImageTiles
                      images={this.state.code.attachments.map((a, i) => ({
                        base64: a.base64,
                        id: i,
                        title: a.name,
                        mimeType: a.mimeType
                      }))}
                      cards
                    />
                  </GridItem>
                </GridContainer>
              }
            ].filter(t => t)}
          />
        </ConfirmationDialog>
      </React.Fragment>
    )
  }
}

Convo.defaultProps = {}

Convo.propTypes = {
  classes: PropTypes.object.isRequired,
  chatbotId: PropTypes.string,
  messages: PropTypes.any,
  subscribeToConversationId: PropTypes.string,
  onSubscriptionReceivedData: PropTypes.func,
  onButtonClick: PropTypes.func,
  onSubmit: PropTypes.func,
  allowHtmlDisplay: PropTypes.bool
}

export default withRouter(
  connect(
    state => state,
    { setAlertSuccessMessage },
  )(withStyles(convoStyle)(Convo)),
)
