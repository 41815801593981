const statsStyle = theme => ({
    root: {
      display: 'inline-table',
      margin: '2px 2px'
    },
    clickable: {
      cursor: 'pointer'
    },
    primaryText: {
        fontSize: 25,
        fontWeight: 'bold',
        color: theme.colors.primary,
        marginLeft: 2
        
    },
    primaryTextGroupList: {
      float: 'left',
    },
    primaryTextSmall: {
      fontSize: 18,
      fontWeight: '400',
    },    
    primarySuffix: {
        fontSize: 13,
        fontWeight: 'bold',
        color: theme.colors.primary,
        lineHeight: 2,
    },
    primaryTextError: {
        color: theme.colors.primary
    },
    primarySuffixError: {
        color: theme.colors.primary
    },
    primaryTextSuccess: {
        color: theme.colors.primary
    },
    primarySuffixSuccess: {
        color: theme.colors.primary
    },
    primaryTextWarning: {
      color: theme.colors.primary
    },
    primarySuffixWarning: {
        color: theme.colors.primary
    },
    secondaryText: {
        fontSize: 11,
        lineHeight: 1.2,
        fontWeight: '400',
        color: `${theme.colors.HelperText}`,

    },
    secondaryTextError: {
      color: theme.colors.primary
    },
    secondaryTextSuccess: {
      color: theme.colors.primary
    },
    secondaryTextWarning: {
      color: theme.colors.primary
    },
    primaryTextDatePosition: {
      position: 'relative',
      top: '-6px'
    },
    secondaryTextGroup: {
        fontSize: 12,
        lineHeight: 1.2,
        fontWeight: '400',
        color: `${theme.colors.primary}`,
        //float: 'left',
        position: 'relative',
        //top: 16,
        left: 3,
        display: 'table-cell'
    }
})
  
  export default statsStyle
  