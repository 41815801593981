import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle.jsx'
import cardTitleStyle from 'assets/jss/material-dashboard-react/components/cardTitleStyle.jsx'
import typographyStyle from 'assets/jss/material-dashboard-react/components/typographyStyle.jsx'
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle'
import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import dashboardStyle from './dashboardStyle'
import {dangerColor, warningColor} from '../../material-dashboard-react'

const crawlersessionsStyle = theme => {
  return {
    ...convoStyle(theme),
    ...tableStyle,
    ...cardTitleStyle,
    ...typographyStyle,
    ...dashboardStyle(theme),
    ...testsessionsStyle(theme),
    crawlerscriptfilter: {
      marginLeft: 9
    },
    successConvos: {
      color: `${theme.colors.primary} !Important`,
      fontWeight: 400,
      '& span': {
        color: `${theme.colors.primary} !Important`,
      }
    },
    warningConvos: {
      color: `${warningColor} !Important`,
      fontWeight: 400,
      '& span': {
        color: `${warningColor} !Important`,
      }
    },
    failedConvos: {
      color: `${dangerColor} !Important`,
      fontWeight: 400,
      '& span': {
        color: `${dangerColor} !Important`,
      }
    },
    darkLabel: {
      color: theme.colors.primary
    },
    crawlersessionlistitem: {
      padding: 0,
      margin: 0,
    }
  }
}

export default crawlersessionsStyle
