import React from 'react'
import _ from 'lodash'
import { extractErrorMessage } from 'helper/graphHelper'
import withStyles from '@material-ui/core/styles/withStyles'
import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/NativeTooltip'
import typographyStyle from 'assets/jss/material-dashboard-react/components/typographyStyle.jsx'

class ErrorFormat extends React.Component {
  render() {
    const { classes, prefix, err, maxLength, suppress, split, ...rest } = this.props

    if (!err) return null

    const errs = []

    if (split && _.isString(err)) {
      errs.push(...err.split('\n').map(t => t.trim()).filter(t => t && t.length > 0))
    } else if (_.isArray(err)) {
      errs.push(...err)
    } else {
      errs.push(err)
    }

    return errs.map((err, index) => {
      const errorMessage = `${prefix ? prefix + ' ' : '' }${extractErrorMessage(err, suppress)}`

      if (errorMessage && !suppress) console.error('[ALERT MESSAGE] ' + errorMessage)

      if (!maxLength || errorMessage.length < maxLength) {
        return <div className={classes.dangerBox} {...rest} key={index}>
          <ShowIcon icon="exclamation-circle" /> {errorMessage}
        </div>
      } else {
        return <Tooltip title={errorMessage} key={index}>
          <div className={classes.dangerBox} {...rest}>
            <ShowIcon icon="exclamation-circle" /> {_.truncate(errorMessage, { length: maxLength })}
          </div>
        </Tooltip>
      }
    })
  }
}
export default withStyles(typographyStyle)(ErrorFormat)
