import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import { showFeatureUpgrade } from 'actions/alert'

import featureUpdateStyle from 'assets/jss/material-dashboard-react/components/featureUpdateStyle.jsx'

function FeatureUpgradeNavLink({ ...props }) {
  const { license, children, classes, showFeatureUpgrade } = props

  let fullWidth = props.fullWidth
  if (_.isNull(fullWidth) || _.isUndefined(fullWidth)) fullWidth = true

  if (!license) return children || 'Not supported in this edition'
  return <Button className={classes.featureUpdateButton} fullWidth={fullWidth} onClick={() => showFeatureUpgrade(true)}>
    <ShowIcon icon="arrow-alt-circle-up" />
    {children || 'Not supported in this edition'}
  </Button>
}

export default connect(
  state => ({ license: state.settings.license }),
  { showFeatureUpgrade }
)(withStyles(featureUpdateStyle)(FeatureUpgradeNavLink))