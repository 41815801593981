import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, withApollo, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import MediaSelectionDialog from 'components/Dialog/MediaSelectionDialog.jsx'
import { renderTextField, renderCheckbox, required, FormActionsToolbar } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import { RefetchTestSetQueries } from './gql'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'

const FULL_TESTSETFOLDER_FRAGMENT = gql`
  fragment FullTestSetFolder on TestSetFolder {
    id
    createdAt
    updatedAt
    name
    path
    globFilter
    skip
    testSet {
      id
      name
    }
  }
`

const TESTSETFOLDER_QUERY = gql`
  query TestSetFolderQuery($id: ID!) {
    testsetfolder(id: $id) {
      ...FullTestSetFolder
    }
  }
  ${FULL_TESTSETFOLDER_FRAGMENT}
`

const CREATE_TESTSETFOLDER = gql`
  mutation CreateTestSetFolder($testSetFolder: TestSetFolderCreateInput!) {
    createTestSetFolder(testSetFolder: $testSetFolder) {
      ...FullTestSetFolder
    }
  }
  ${FULL_TESTSETFOLDER_FRAGMENT}
`

const UPDATE_TESTSETFOLDER = gql`
  mutation UpdateTestSetFolder(
    $id: ID!
    $testSetFolder: TestSetFolderUpdateInput!
  ) {
    updateTestSetFolder(id: $id, testSetFolder: $testSetFolder) {
      ...FullTestSetFolder
    }
  }
  ${FULL_TESTSETFOLDER_FRAGMENT}
`

const DELETE_TESTSETFOLDER = gql`
  mutation DeleteTestSetFolder($id: ID!) {
    deleteTestSetFolder(id: $id)
  }
`

const VALIDATEFOLDER_QUERY = gql`
  query ValidateFolderQuery(
    $testSetId: ID!
    $path: String!
  ) {
    validatefolder(testSetId: $testSetId, path: $path) {
      err
    }
  }
`

class TestSetFolder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDirectoryDialog: false
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  validate(testSetId, values) {
    const { client } = this.props
    return this.hasWritePermission() && values.path && client.query({
      query: VALIDATEFOLDER_QUERY,
      variables: {
        testSetId: testSetId,
        path: values.path
      },
      fetchPolicy: 'no-cache'
    })
    .then(({ data }) => {
      if (data.validatefolder && data.validatefolder.err) {
        return { path: data.validatefolder.err }
      }
    })
    .catch(err => {
      return { path: err.message }
    })
  }

  renderForm(testsetFolder, testSetId) {
    const { showDirectoryDialog } = this.state
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props

    return (
      <Mutation
        mutation={
          testsetFolder.id ? UPDATE_TESTSETFOLDER : CREATE_TESTSETFOLDER
        }
        refetchQueries={[
          ...RefetchTestSetQueries(testSetId, license)
        ]}
      >
        {(mutateTestSetFolder, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (testsetFolder.id) {
                try {
                  const res = await mutateTestSetFolder({
                    variables: {
                      id: values.id,
                      testSetFolder: {
                        name: values.name,
                        path: values.path,
                        globFilter: values.globFilter || null,
                        skip: !!values.skip
                      },
                    },
                  })
                  form.initialize(res.data.updateTestSetFolder)
                  history.push(`/testsets/view/${testSetId}/settings/remote/viewfolder/${res.data.updateTestSetFolder.id}`)
                  setAlertSuccessMessage('Shared Folder ready for use')
                } catch(error) {
                  setAlertErrorMessage(`Shared Folder update failed`, error)
                }
              } else {
                try {
                  const res = await mutateTestSetFolder({
                    variables: {
                      testSetFolder: {
                        name: values.name,
                        path: values.path,
                        globFilter: values.globFilter || null,
                        skip: !!values.skip,
                        testSet: {
                          connect: {
                            id: testSetId,
                          },
                        },
                      },
                    },
                  })
                  form.initialize(res.data.createTestSetFolder)
                  history.push(`/testsets/view/${testSetId}/settings/remote/viewfolder/${res.data.createTestSetFolder.id}`)
                  setAlertSuccessMessage('Shared Folder ready for use')
                } catch(error) {
                  setAlertErrorMessage(`Shared Folder registration failed`, error)
                }
              }
            }}
            initialValues={testsetFolder}
            validate={this.validate.bind(this, testSetId)}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values,
              form: { change }
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Folder Name"
                      validate={required}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetFolderName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="skip"
                      component={renderCheckbox}
                      label="Ignore when running Test Sessions"
                      type="checkbox"
                      disabled={!this.hasWritePermission()}
                      data-unique="chkTestSetFolderSkip"
                    />                    
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="path"
                      component={renderTextField}
                      label="Folder Path"
                      validate={required}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetFolderAbsoluteFolderPath"
                      endAdornment={<>
                        <Button justIcon dense disabled={!testsetFolder.id || !values.path} data-unique="btnTestSetOpenFolderSelectionDialog" onClick={() => history.push(`/settings/filebrowser?path=${values.path}`)}>
                          <ShowIcon icon="folder-open" />
                        </Button>
                        <Button justIcon dense disabled={!this.hasWritePermission()} data-unique="btnTestSetOpenFolderSelectionDialog" onClick={() => this.setState({ showDirectoryDialog: true })}>
                          <ShowIcon icon="folder" />
                        </Button>
                      </>}                      
                    />
                    {this.hasWritePermission() && 
                      <MediaSelectionDialog allowFolderSelection
                        initialPath={values.path ? values.path.split('/') : ['testsets']}
                        open={!!showDirectoryDialog}
                        onCancel={() => this.setState({ showDirectoryDialog: false })}
                        onOk={({ selectedFolders }) => {
                          this.setState({ showDirectoryDialog: false })
                          const selectedPathJoined = selectedFolders[0].join('/')
                          change('path', selectedPathJoined)
                          if (!values.name) {
                            change('name', selectedPathJoined)
                          }
                        }}
                        title="Select Folder"
                      />
                    }
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      optional
                      name="globFilter"
                      component={renderTextField}
                      label="Filename Filter (&quot;glob&quot;)"
                      helperText="Botium uses &quot;glob&quot; style filename filters to restrict the set of read files, for example &quot;**/*.en.txt&quot;"
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetFolderGlobFilter"
                    />
                  </GridItem>
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtons= {testsetFolder.id && this.hasWritePermission() && (
                        <Mutation
                          mutation={DELETE_TESTSETFOLDER}
                          onCompleted={data => {
                            this.props.history.push(`/testsets/view/${testSetId}/settings/remote`)
                            setAlertSuccessMessage('Shared Folder unregistered')
                          }}
                          onError={error => {
                            setAlertErrorMessage(
                              `Shared Folder unregistration failed`,
                              error,
                            )
                          }}
                          refetchQueries={[
                            ...RefetchTestSetQueries(testSetId, license)
                          ]}
                        >
                          {(deleteTestSetFolder, { loading, error }) => (
                            <Button
                              secondary
                              danger
                              onClick={() => {
                                deleteTestSetFolder({
                                  variables: { id: testsetFolder.id },
                                })
                              }}
                              data-unique="btnTestSetExcelUnregister"
                            >
                              <ShowIcon icon="trash" />
                              Unregister Shared Folder
                            </Button>
                          )}
                        </Mutation>
                      )}
                      rightButtons={this.hasWritePermission() &&
                        <Button
                          type="submit"
                          disabled={invalid | submitting}
                          data-unique="btnTestSetFolderSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}                      
                          Save
                        </Button>
                      }
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { match } = this.props
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {match.params &&
            match.params.id && (
              <Query
                query={TESTSETFOLDER_QUERY}
                variables={{ id: match.params.id }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <LoadingIndicator />
                  if (error) return <ErrorFormat err={error} />

                  return this.renderForm(
                    data.testsetfolder,
                    data.testsetfolder.testSet.id,
                  )
                }}
              </Query>
            )}
          {(!match.params || !match.params.id) &&
            this.renderForm({ gitbranch: 'master' }, match.params.testSetId)}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  withApollo
)(TestSetFolder)
