import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import withStyles from '@material-ui/core/styles/withStyles'
import StatusIcon from '@material-ui/icons/Favorite'
import Tooltip from 'components/Tooltip/Tooltip'

import typographyStyle from 'assets/jss/material-dashboard-react/components/typographyStyle.jsx'

class AgentStatusIcon extends React.Component {

  render () {
    const { agent, classes } = this.props

    const getInnerStatus = () => {
      if (agent && agent.connectionStatus === 'ONLINE') return (<StatusIcon className={classes.successText}/>)
      if (agent && agent.connectionStatus === 'OFFLINE') return (<StatusIcon className={classes.dangerText}/>)
      if (agent && agent.connectionStatus === 'PENDING') return (<StatusIcon className={classes.warningText}/>)
      return (<StatusIcon className={classes.mutedText}/>)
    }
    const getStatusDesc = () => {
      let result = []
      if (agent.heartbeat) result.push('Heartbeat: ' + moment(agent.heartbeat).format('lll'))
      if (agent.heartbeatInfo) result.push(agent.heartbeatInfo)
      return result.join(' | ')
    }    

    return <Tooltip title={getStatusDesc()}>{getInnerStatus()}</Tooltip>
  }
}

AgentStatusIcon.propTypes = {
  agent: PropTypes.object.isRequired
}  
export default withStyles(typographyStyle)(AgentStatusIcon)
