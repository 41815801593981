import React from 'react'
import {gql} from 'apollo-boost'

import {Query} from 'react-apollo'

import {renderProgressOrError} from '../../../helper'
import {ConfusionMatrixTable} from './ConfusionMatrixTable'
import MessageBox from 'components/Info/MessageBox'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'

const UTTERANCE_LIST = gql`
  query TrainerUtteranceLists($where: TrainerUtteranceListWhereInput!, $orderBy: TrainerUtteranceListOrderByInput) {
    trainerUtteranceLists(where: $where, orderBy: $orderBy) {
      id
      testSet {
        id
      }
      testSetScript {
        id
      }
      testSetName
      testSetScriptName
      correct
      incorrect
      recall
    }
  }
`

export const renderConfusionMatrixUtteranceList = (testSessionId) => {
  return (<Query
    query={UTTERANCE_LIST}
    variables={{where: {trainerSession: {testSession: {id: testSessionId}}}}}
  >
    {({loading, error, data}) => {
      let progressOrError = renderProgressOrError({loading, error})
      if (progressOrError) {
        return progressOrError
      }

      if(data.trainerUtteranceLists && data.trainerUtteranceLists.length === 0) {
        return <GridContainer>
          <GridItem xs={false} sm={3} />
          <GridItem xs={12} sm={6}>
            <MessageBox variant="info" title="Botium is not able to display the Confusion Matrix" text={`Botium Asserter, or Incomprehension Utterance (see Test Set settings) is required`} />
          </GridItem>
          <GridItem xs={false} sm={3} />
        </GridContainer>
      }

      const convertedToConfusionMatrix = data.trainerUtteranceLists.map(e => {
        return {
          intent: e.testSetScriptName,
          utteranceList: {
            testSetName: e.testSetName,
            testSetId: e.testSet ? e.testSet.id : null,
            testScriptName: e.testSetScriptName,
            testScriptId: e.testSetScript ? e.testSetScript.id : null,
          },
          row: {
            [e.testSetScriptName]: e.correct,
            '<<incomprehension>>': e.incorrect
          },
          score: {
            recall: e.recall
          }
        }
      })

      convertedToConfusionMatrix.push({
        intent: '<<incomprehension>>',
        utteranceList: {
          incomprehension: true
        },
        row: {
        },
        score: {
        }
      })
      return (<ConfusionMatrixTable testSessionId={testSessionId} confusionMatrix={convertedToConfusionMatrix} utteranceList={true}/>)
    }}
  </Query>)

}
