import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Mutation, withApollo } from 'react-apollo'
import arrayMutators from 'final-form-arrays'
// core components
import ExpansionPanel from 'components/Expansion/ExpansionPanel.jsx'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary.jsx'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails.jsx'
import Divider from 'components/Divider/Divider.js'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/Button/Button'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import AvatarImage from 'components/Avatar/AvatarImage'
import LastChange from 'components/Info/LastChange'
import DropdownButton from 'components/Button/DropdownButton.jsx'
import SimpleConfirmationDialog from 'components/Dialog/SimpleConfirmationDialog.jsx'
import SayHello from './SayHello'

import chatbotsStyle from 'assets/jss/material-dashboard-react/views/chatbotsStyle.jsx'
import { ImportBotiumJson } from 'components/Capability/Helper'
import { renderFileUpload, required, TableActionsToolbar, FormActionsToolbar, CustomTextField } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { getConnector } from 'actions/settings'
import { removeRecentListEntry } from 'actions/activity'
import {
  RefetchChatbotQueries,
  CHATBOTS_COUNT_PAGINATED_QUERY,
  CREATE_CHATBOT,
  DELETE_CHATBOT,
  CHATBOTS_WITH_STATUS_PAGINATED_QUERY,
  DeleteChatbotsListsFromCache
} from './gql'
import { isPostwoman, importPostwoman, isPostman, importPostman } from './helper'
import {
  ConnectorSelector,
  ConnectorSwitch,
  ConnectorPrepareForm,
  ConnectorPrepareCaps,
  CapabilitiesToGql
} from 'components/Capability/Helper'

import ShowIcon from 'components/Icon/ShowIcon'

import { hasPermission } from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemText from 'components/List/ListItem/ListItemText'
import { addNamespaceToWhere } from '../helper'
import Tooltip from 'components/Tooltip/Tooltip'
import { getDefaultTestTypes, getTechnologyTypeIcon } from 'views/QuickStart/Helper'


class Chatbots extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newChatbotExpanded: false,
      emptyChatbot: {
        name: '',
        containermode: 'echo',
        connector: 'echo',
        capabilities: [{
          name: 'CONTAINERMODE',
          type: 'STRING',
          stringValue: 'echo'
        }],
        sources: [],
        envs: []
      },
      showCapabilities: false,
      confirmDeleteChatbot: null,
      serverSidePagingKey: 0
    }
    ConnectorPrepareForm(this.props.settings.allconnectors, this.state.emptyChatbot, this.state.emptyChatbot.capabilities)
  }
  render() {
    const { getConnector, license, setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry, history, user, client, classes, namespace } = this.props

    return (
      <GridContainer>
        <GridItem xs={12}>
          {hasPermission(user, 'CHATBOTS_CREATE') &&
            <ExpansionPanel expanded={this.state.newChatbotExpanded} data-unique="pnlNewChatbot">
              <ExpansionPanelSummary onClick={() => this.setState({ newChatbotExpanded: !this.state.newChatbotExpanded })}>
                <Text header>Create new Chatbot</Text>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <GridContainer nounset justify="space-between">
                  <GridItem xs={5}>
                    <Mutation
                      mutation={CREATE_CHATBOT}
                      refetchQueries={() => {
                        return [
                          ...RefetchChatbotQueries()
                        ]
                      }}
                      update={DeleteChatbotsListsFromCache}
                    >
                      {(createChatbot, { loading, error }) => (
                        <Form
                          onSubmit={async (values, form) => {
                            try {
                              const res = await createChatbot({
                                variables: {
                                  chatbot: {
                                    name: values.name,
                                    mediaDownload: true,
                                    description: values.description || null,
                                    capabilities: {
                                      create: values.capabilities,
                                    },
                                    sources: {
                                      create: values.sources,
                                    },
                                    envs: {
                                      create: values.envs,
                                    }
                                  }
                                }
                              })
                              form.initialize(res.data.createChatbot)
                              history.push(`/chatbots/view/${res.data.createChatbot.id}/settings`)
                            } catch(error) {
                              setAlertErrorMessage('Chatbot registration failed', error)
                            }
                          }}
                          render={({
                            handleSubmit,
                            values,
                            form: { change }
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <UnsavedFormSpy />
                              <Field
                                name="fileupload"
                                component={renderFileUpload}
                                data-unique="fileChatbotsJson"
                                accept=".json"
                                label="Import Botium Core File (botium.json), or Postwoman/Postman collection"
                                helperText="Save the Postwoman/Postman request to a collection and export it. The first request from the root folder of the collection will be imported."
                                values={values}
                                change={change}
                                onFileLoaded={(filename, filecontent) => {
                                  let asJson = null
                                  try {
                                    asJson = JSON.parse(atob(filecontent))
                                  } catch (err) {
                                    setAlertErrorMessage(`Reading JSON from file ${filename} failed`, err)
                                    return
                                  }
                                  if (isPostwoman(asJson)) {
                                    try {
                                      const fields = importPostwoman(asJson)

                                      change('name', filename)
                                      change('capabilities', [
                                        { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'simplerest' },
                                        { name: 'SIMPLEREST_URL', type: 'STRING', stringValue: fields['simpleRestConnector.url'] },
                                        { name: 'SIMPLEREST_METHOD', type: 'STRING', stringValue: fields['simpleRestConnector.method'] },
                                        { name: 'SIMPLEREST_HEADERS_TEMPLATE', type: 'JSON', jsonValue: fields['simpleRestConnector.headersTemplate'] },
                                        { name: 'SIMPLEREST_BODY_TEMPLATE', type: 'JSON', jsonValue: fields['simpleRestConnector.bodyTemplate'] },
                                        { name: 'SIMPLEREST_RESPONSE_JSONPATH', type: 'STRING', stringValue: '$' }
                                      ])
                                      handleSubmit()
                                    } catch (err) {
                                      setAlertErrorMessage(`Reading Postwoman request from file ${filename} failed`, err)
                                    }
                                  } else if (isPostman(asJson)) {
                                    try {
                                      const fields = importPostman(asJson)

                                      change('name', filename)
                                      change('capabilities', [
                                        { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'simplerest' },
                                        { name: 'SIMPLEREST_URL', type: 'STRING', stringValue: fields['simpleRestConnector.url'] },
                                        { name: 'SIMPLEREST_METHOD', type: 'STRING', stringValue: fields['simpleRestConnector.method'] },
                                        { name: 'SIMPLEREST_HEADERS_TEMPLATE', type: 'JSON', jsonValue: fields['simpleRestConnector.headersTemplate'] },
                                        { name: 'SIMPLEREST_BODY_TEMPLATE', type: 'JSON', jsonValue: fields['simpleRestConnector.bodyTemplate'] },
                                        { name: 'SIMPLEREST_RESPONSE_JSONPATH', type: 'STRING', stringValue: '$' }
                                      ])
                                      handleSubmit()
                                    } catch (err) {
                                      setAlertErrorMessage(`Reading Postman request from file ${filename} failed`, err)
                                    }
                                  } else {
                                    try {
                                      const { capabilities, sources, envs } = ImportBotiumJson(asJson)
                                      const capsFiltered = capabilities.filter(cap => cap.name !== 'PROJECTNAME' && cap.name !== 'TESTSESSIONNAME')
                                      change('capabilities', capsFiltered)
                                      change('sources', sources)
                                      change('envs', envs)

                                      const projectName = capabilities.find(cap => cap.name === 'PROJECTNAME')
                                      if (projectName) {
                                        change('name', projectName.stringValue)
                                      } else {
                                        change('name', filename)
                                      }
                                      handleSubmit()
                                    } catch (err) {
                                      setAlertErrorMessage(`Reading capabilities from file ${filename} failed`, err)
                                    }
                                  }
                                  change('fileupload', null)
                                }}
                              />
                            </form>
                          )}
                        />
                      )}
                    </Mutation>
                  </GridItem>
                  <GridItem xs={2} center>
                    <Divider text="or" orientation="vertical" secondary />
                  </GridItem>
                  <GridItem xs={5}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <Text header>Register Chatbot</Text>
                      </GridItem>
                      <GridItem xs={12} left>
                        <TableActionsToolbar>
                          <CustomTextField
                            data-unique="txtNewChatbotName"
                            input={{
                              name: 'txtNewChatbotName',
                              placeholder: 'Enter Chatbot Name',
                              title: 'Register Chatbot',
                              value: this.state.newChatbotName || '',
                              onChange: ev => {
                                this.setState({ newChatbotName: ev.target.value })
                              }
                            }}
                          />
                          <Button
                            smallTop
                            aria-label="Save"
                            title="Save"
                            data-unique="btnChatbotRegister"
                            disabled={!this.state.newChatbotName}
                            onClick={() => {
                              this.setState({ showCapabilities: true })
                            }}
                            justIconSolid
                          >
                            <ShowIcon icon="plus" />
                          </Button>
                        </TableActionsToolbar>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  {this.state.showCapabilities && <GridItem xs={12}>
                    <Divider orientation="horizontal" />
                    <Mutation
                      mutation={CREATE_CHATBOT}
                      refetchQueries={[
                        ...RefetchChatbotQueries()
                      ]}
                    >
                      {(mutateChatbot, { loading, error }) => (
                        <Form
                          mutators={{ ...arrayMutators }}
                          onSubmit={async (values, form) => {
                            ConnectorPrepareCaps(
                              getConnector(values.connector) || { name: values.connector },
                              values,
                              values.capabilities,
                            )

                            const capabilities = CapabilitiesToGql(values.capabilities)
                            try {
                              const res = await mutateChatbot({
                                  variables: {
                                    chatbot: {
                                      name: this.state.newChatbotName,
                                      namespace: namespace.selected ? namespace.selected.name : undefined,
                                      mediaDownload: true,
                                      capabilities: {
                                        create: capabilities.create,
                                      }
                                    }
                                  }
                                })
                                form.initialize(res.data.createChatbot)
                                setAlertSuccessMessage('Chatbot registered')
                                history.push(`/chatbots/view/${res.data.createChatbot.id}`)
                              } catch(error) {
                                setAlertErrorMessage(`Chatbot registration failed`, error)
                              }
                            }
                          }
                          initialValues={this.state.emptyChatbot}
                          render={({
                            handleSubmit,
                            submitting,
                            invalid,
                            form: {
                              mutators: { push, pop },
                              change
                            },
                            values
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <UnsavedFormSpy />
                              <GridContainer>
                                <GridItem md={12} lg={3}>
                                  <ConnectorSelector advanced={false} validate={required} />
                                </GridItem>
                                <GridItem md={12} lg={1} center>
                                  <Divider orientation="vertical"   dividerlgnone/>
                                  <Divider orientation="horizontal"  dividermdnone />
                                </GridItem>
                                <GridItem md={12} lg={8}>
                                  <GridContainer>
                                    <GridItem xs={12}>
                                      <ConnectorSwitch push={push} pop={pop} change={change} values={values} />
                                    </GridItem>
                                    <GridItem xs={12} largePadding>
                                      <FormActionsToolbar
                                        leftButtons={<>
                                          <SayHello buttonProps={{ 'data-unique': 'btnChatbotRegisterSayHello', disabled: submitting || invalid }} />
                                          <Button
                                            secondary
                                            disabled={submitting || invalid}
                                            data-unique="btnChatbotRegisterCancel"
                                            onClick={() => this.setState({ showCapabilities: false })}
                                          >
                                            <ShowIcon icon="times" /> Cancel
                                          </Button>
                                        </>}
                                        rightButtons={<>
                                          <Button
                                            type="submit"
                                            disabled={submitting || invalid}
                                            data-unique="btnChatbotRegisterSave"
                                          >
                                            {submitting && <LoadingIndicator alt />}
                                            {!submitting && <ShowIcon icon="save" />}
                                            Save
                                          </Button>
                                        </>}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                            </form>
                          )}
                        />
                      )}
                    </Mutation>
                  </GridItem>}
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          }
          <Card>
            <CardHeader>
              <Text header>Chatbots</Text>
              <Text subheader>
                Listing all your Chatbots and environments
              </Text>
            </CardHeader>
            <CardBody noPadding className={classes.tablePosition}>
              <GridContainer>
                {this.state.confirmDeleteChatbot &&
                  <SimpleConfirmationDialog danger
                    showConfirmationDialog={!!this.state.confirmDeleteChatbot}
                    confirmationText={`When deleting the Chatbot "${this.state.confirmDeleteChatbot.name}", all configuration settings are removed. If it has been used in Test Sessions or Test Projects already, those will miss configuration as well. Are you sure you want to delete it ?`}
                    requireCheck={true}
                    onOk={async () => {
                      try {
                        await client.mutate({
                          mutation: DELETE_CHATBOT,
                          variables: {
                            id: this.state.confirmDeleteChatbot.id
                          },
                          fetchPolicy: 'no-cache',
                          refetchQueries: [ ...RefetchChatbotQueries() ]
                        })
                        removeRecentListEntry({ url: `/chatbots/view/${this.state.confirmDeleteChatbot.id}` })
                        setAlertSuccessMessage('Chatbot deleted')
                        this.setState({ serverSidePagingKey: this.state.serverSidePagingKey + 1 })
                      } catch(error) {
                        setAlertErrorMessage('Chatbot deletion failed', error)
                      }
                      this.setState({ confirmDeleteChatbot: null })
                    }}
                    onCancel={() => this.setState({ confirmDeleteChatbot: null })}
                  />
                }
                <GridItem xs={12} skipTableSpace className={classes.chatbotServerSidePagingTable}>
                  <ServerSidePagingTable key={`ChatbotsKey_${namespace?.selected?.name}_${this.state.serverSidePagingKey}`}
                    gqlQuery={{
                      query: CHATBOTS_WITH_STATUS_PAGINATED_QUERY
                    }}
                    gqlCountQuery={{
                      query: CHATBOTS_COUNT_PAGINATED_QUERY,
                      countValue: data => data.chatbotsCount
                    }}
                    gqlVariables={(filterString) => addNamespaceToWhere(namespace,filterString && ({
                      where: {name_contains: filterString },
                      connectorFilter: filterString
                    }))}
                    name="Chatbots"
                    tableHeaderColor="primary"
                    tableHead={[
                      { name: 'Name', orderByField: 'name', orderByDefault: 'asc' },
                      { name: 'Namespace', orderByField: 'namespace', orderByDefault: 'asc' },
                      { name: 'Last Change', orderByField: 'updatedAt' },
                      'Connector / Chatbot Technology',
                      { name: 'Supported Test Type'},
                      { name: 'Actions', right: true }
                    ]}
                    tableData={data =>
                      data && data.chatbots &&
                      data.chatbots.map(a => [
                      {
                        value: <NavLink className={classes.projectLink} to={`/chatbots/view/${a.id}/dashboard`}>{a.name}</NavLink>,
                      },
                      a.namespace ? {
                        value: <div className={classes.namespace}>{a.namespace}</div>,
                        href: `/namespaces/${a.namespace}`,
                      } : null,
                      () => <LastChange {...a} />,
                      {
                        value: <ListItem small>
                          <AvatarImage avatar={a.avatar} containermode={a.containermode} chatbotId={a.id}/>
                          <ListItemText>{a.connector ? (a.connector.description || a.connector.name) : 'Custom Connector'}</ListItemText>
                        </ListItem>
                      },
                      () => <><div>{getDefaultTestTypes(license, a.connector).map(type => {
                        return getTechnologyTypeIcon(type)
                        })}</div></>,
                      () => <Tooltip title="Actions">
                      <DropdownButton
                      dots
                      items={[
                        {
                          id: 'open',
                          icon: 'robot',
                          name: 'Open',
                          onClick: () => history.push(`/chatbots/view/${a.id}/dashboard`)
                        },
                        {
                          id: 'settings',
                          icon: 'cog',
                          name: 'Configuration',
                          onClick: () => history.push(`/chatbots/view/${a.id}/settings`)
                        },
                        {
                          id: 'livechat',
                          icon: 'play-circle',
                          name: 'Live Chat',
                          onClick: () => history.push(`/chatbots/view/${a.id}/livechat`)
                        },
                        {
                          id: 'delete',
                          icon: 'trash',
                          name: 'Delete',
                          onClick: () => this.setState({ confirmDeleteChatbot: a })
                        }
                      ]}></DropdownButton></Tooltip>
                    ])}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, settings: state.settings, namespace: state.namespace }),
  { getConnector, setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry }
)(withStyles(chatbotsStyle)(withApollo(Chatbots)))
