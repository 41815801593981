import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import MenuItem from 'components/Menu/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'

import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  renderSelect,
  renderTextArea,
  renderCheckbox,
  required,
  jsonpath,
  composeValidators,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import {
  REGISTEREDAGGREGATORS_QUERY,
  REGISTEREDAGGREGATOR_QUERY,
  CREATE_REGISTEREDAGGREGATOR,
  UPDATE_REGISTEREDAGGREGATOR,
  DELETE_REGISTEREDAGGREGATOR,
} from './gql'

import { hasPermission } from 'botium-box-shared/security/permissions'
import { validateRegisteredAggregatorNameUnique } from './validators'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class RegisteredAggregator extends React.Component {

  isEditable (registeredaggregator) {
    const { user } = this.props

    if (!hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')) return false
    if (registeredaggregator.type === 'INTERNAL') return false
    return true
  }

  renderForm(registeredaggregator) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady } = this.props

    return (
      <Mutation
        mutation={registeredaggregator.id ? UPDATE_REGISTEREDAGGREGATOR : CREATE_REGISTEREDAGGREGATOR}
        refetchQueries={[
          {
            query: REGISTEREDAGGREGATORS_QUERY,
          },
        ]}
      >
        {(mutateRegisteredAggregator, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (registeredaggregator.id) {
                try {
                  const res = await mutateRegisteredAggregator({
                    variables: {
                      id: registeredaggregator.id,
                      aggregator: {
                        name: values.name,
                        label: values.label,
                        description: values.description || null,
                        global: !!values.global,
                        type: values.type,
                        function: values.function || null,
                        context: values.context || null,
                        jsonPathFilterExpression: values.jsonPathFilterExpression || null,
                        jsonPathFilterValue: values.jsonPathFilterValue || null,
                        jsonPathExtractExpression: values.jsonPathExtractExpression || null
                      },
                    },
                  })
                  form.initialize(res.data.updateRegisteredAggregator)
                  setAlertSuccessMessage('Test metric updated')
                  onReady(registeredaggregator.id)
                } catch(error) {
                  setAlertErrorMessage('Test metric update failed', error)
                }
              } else {
                try {
                  const res = await mutateRegisteredAggregator({
                    variables: {
                      aggregator: {
                        name: values.name,
                        label: values.label,
                        description: values.description || null,
                        global: !!values.global,
                        type: values.type,
                        function: values.function || null,
                        context: values.context || null,
                        jsonPathFilterExpression: values.jsonPathFilterExpression || null,
                        jsonPathFilterValue: values.jsonPathFilterValue || null,
                        jsonPathExtractExpression: values.jsonPathExtractExpression || null
                      },
                    },
                  })
                  form.initialize(res.data.createRegisteredAggregator)
                  setAlertSuccessMessage('Test metric registered')
                  onReady(res.data.createRegisteredAggregator.id)
                } catch(error) {
                  setAlertErrorMessage('Test metric register failed', error)
                }
              }
            }}
            initialValues={registeredaggregator}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values
            }) => {

              const aggregateTypes = []
              if(values.type === 'INTERNAL') aggregateTypes.push({ key: 'INTERNAL', label: 'Internal Test Metric' })
              aggregateTypes.push({ key: 'JSONPATH', label: 'JSONPath Expression(s)' })

              return <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Test Metric Name"
                      validate={composeValidators(required, async (value) => {
                        if (this.isEditable(values) && value) {
                          return validateRegisteredAggregatorNameUnique(this.props.client, registeredaggregator.id, value)
                        }
                      })}
                      disabled={!this.isEditable(values)}
                      data-unique="txtRegisteredAggregatorName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="label"
                      component={renderTextField}
                      label="Test Metric Label"
                      validate={required}
                      disabled={!this.isEditable(values)}
                      data-unique="txtRegisteredAggregatorLabel"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="global"
                      component={renderCheckbox}
                      label="Always calculate this metric"
                      helperText="Calculate this metric for every single test session - use carefully!"
                      type="checkbox"
                      disabled={!this.isEditable(values)}
                      data-unique="chkRegisteredAggregatorGlobal"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="type"
                      component={renderSelect}
                      label="Test Metric Type"
                      validate={required}
                      disabled={!this.isEditable(values)}
                      data-unique="selRegisteredAggregatorType"
                      items={aggregateTypes}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}></GridItem>
                  {values.type !== 'INTERNAL' && <>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="context"
                        component={renderSelect}
                        label="Aggregation Context"
                        validate={required}
                        disabled={!this.isEditable(values)}
                        data-unique="selRegisteredAggregatorContext"
                        items={[
                          {
                            key: 'RESULTS',
                            label: 'RESULTS (extract from full test result list)'
                          },
                          {
                            key: 'TRANSCRIPT',
                            label: 'TRANSCRIPT (extract for each test case)'
                          },
                          {
                            key: 'STEP',
                            label: 'STEP (extract for each test step)'
                          },
                        ]}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="function"
                        component={renderSelect}
                        label="Aggregation Function"
                        validate={required}
                        disabled={!this.isEditable(values)}
                        data-unique="selRegisteredAggregatorFunction"
                        items={[
                          { key: 'NONE' },
                          { key: 'COUNT' },
                          { key: 'AVG' },
                          { key: 'SUM' },
                          { key: 'MIN' },
                          { key: 'MAX' }
                        ]}
                      >
                        <MenuItem key="NONE" value="NONE">NONE</MenuItem>
                        <MenuItem key="COUNT" value="COUNT">COUNT</MenuItem>
                        <MenuItem key="AVG" value="AVG">AVG</MenuItem>
                        <MenuItem key="SUM" value="SUM">SUM</MenuItem>
                        <MenuItem key="MIN" value="MIN">MIN</MenuItem>
                        <MenuItem key="MAX" value="MAX">MAX</MenuItem>
                      </Field>
                    </GridItem>
                  </>}
                  {values.type === 'JSONPATH' && <>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="jsonPathFilterExpression"
                        component={renderTextField}
                        label="JSONPath Filter Expression"
                        helperText="JSONPath Expression to filter the input context"
                        disabled={!this.isEditable(values)}
                        validate={jsonpath}
                        data-unique="txtRegisteredAggregatorJsonPathFilterExpression"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="jsonPathFilterValue"
                        component={renderTextField}
                        label="JSONPath Filter Value"
                        helperText="Value to filter the input context"
                        disabled={!this.isEditable(values)}
                        data-unique="txtRegisteredAggregatorJsonPathFilterValue"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="jsonPathExtractExpression"
                        component={renderTextField}
                        label="JSONPath Extraction Expression"
                        validate={jsonpath}
                        helperText="JSONPath Expression to extract the test metric"
                        disabled={!this.isEditable(values)}
                        data-unique="txtRegisteredAggregatorJsonPathExtractExpression"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}></GridItem>
                  </>}
                  <GridItem xs={12}>
                    <Field
                      name="description"
                      component={renderTextArea}
                      label="Description"
                      rows={4}
                      disabled={!this.isEditable(values)}
                      data-unique="txtRegisteredAggregatorDescription"
                    />
                  </GridItem>
                </GridContainer>
                {this.isEditable(values) &&
                  <GridContainer>
                    <GridItem xs={12} largePadding>
                      <FormActionsToolbar
                        leftButtons={<>
                          {registeredaggregator.id && (
                            <Mutation
                              mutation={DELETE_REGISTEREDAGGREGATOR}
                              onCompleted={data => {
                                setAlertSuccessMessage('Test metric deleted')
                                onReady()
                              }}
                              onError={error => {
                                setAlertErrorMessage('Test metric deletion failed', error)
                              }}
                              refetchQueries={[
                                {
                                  query: REGISTEREDAGGREGATORS_QUERY,
                                },
                              ]}
                            >
                              {(deleteRegisteredAggregator, { loading, error }) => (
                                <ConfirmationButton
                                  confirmationText={`Test Projects using this metric will be affected when you delete this metric. Are you sure you want to delete it ?`}
                                  requireCheck={true}
                                  onClick={() => {
                                    deleteRegisteredAggregator({
                                      variables: { id: registeredaggregator.id },
                                    })
                                  }}
                                  data-unique="btnRegisteredAggregatorUnregister"
                                  secondary
                                  danger
                                >
                                  <ShowIcon icon="trash" />
                                  Delete Metric
                                </ConfirmationButton>
                              )}
                            </Mutation>
                          )}
                        </>}
                        rightButtons={<>
                          <Button
                            type="submit"
                            disabled={submitting || invalid}
                            data-unique="btnRegisteredAggregatorSave"
                          >
                            {submitting && <LoadingIndicator alt />}
                            {!submitting && <ShowIcon icon="save" />}                  
                            Save
                          </Button>                        
                        </>}
                      />
                    </GridItem>
                  </GridContainer>
                }
              </form>
            }}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={REGISTEREDAGGREGATOR_QUERY} variables={{ id }} fetchPolicy="network-only">
              {(queryResult) => <QueryStatus {...queryResult} query="registeredaggregator">{(data) => this.renderForm(data.registeredaggregator)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({ type: 'JSONPATH', global: false, script: 'module.exports = 1;' })}
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage },
)(withStyles(settingsStyle)(withApollo(RegisteredAggregator)))
