import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactFlow, {Controls, Handle, isNode, isEdge, ControlButton} from 'react-flow-renderer'
import dagre from 'dagre'
import _ from 'lodash'
import domtoimage from 'dom-to-image-more'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'
import LowPriorityIcon from '@material-ui/icons/LowPriority'
import CloseIcon from '@material-ui/icons/Close'

// apollo
import {compose} from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import Chip from 'components/Chip/Chip'
import AvatarImage from 'components/Avatar/AvatarImage'
import { renderUtteranceText } from 'components/Convo/Shared.jsx'
import ComponentChip from 'components/Convo/ComponentChip.jsx'
import Button from 'components/Button/Button'
import ShowIcon from '../Icon/ShowIcon'
import { setTableSettings } from 'actions/table'

import convosTreeStyle from 'assets/jss/material-dashboard-react/views/convosTreeStyle.jsx'
import botiumLogo from '../../assets/img/botium-logo.png'
import defaultAvatar from 'assets/img/botium-connector-default-white.png'
import { CustomTextField } from 'components/Form/Form'

const defaultTextStyle = {
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
}
const defaultHandleStyle = {
  pointerEvents: 'none'
}

const nodeWidth = 400
const nodeHeight = 70


class ConvosTree extends React.Component {
  constructor(props) {
    super(props)

    this.defaultEdgeStyle = {
      strokeWidth: 3,
      pointerEvents: 'all'
    }
    this.pathSelectedEdgeStyle = {
      ...this.defaultEdgeStyle,
      strokeWidth: 4,
      stroke: 'red'
    }

    this.state = {
      rfInstance: null,
      building: true,
      elements: [],
      showFilterInput: false,
      exportHeight: null,
      exportWidth: null,
      nodeSizes: {}
    }
    this.rfRef = React.createRef()
    setTimeout(() => {
      const elements = this._layoutElements()
      this._highlightElements(this.getSettings().searchTerm, elements)
      this.setState({ elements: [...elements], building: false })
    }, 0)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(prevProps.tree) !== JSON.stringify(this.props.tree) ) {
      this.setState({ building: true })
      const elements = this._layoutElements()
      this._highlightElements(this.getSettings().searchTerm, elements)
      this.setState({ elements: [...elements], building: false })
    }
    if((Object.keys(this.state.nodeSizes).length > 0) && (Object.keys(prevState.nodeSizes).length === 0)) {
      const elements = this._layoutElements()
      this._highlightElements(this.getSettings().searchTerm, elements)
      this.setState({ elements: [...elements], building: false })
    }
  }

  getNodeSize = node => {
    return {
      width: parseInt(window.getComputedStyle(node, null).getPropertyValue('width').replace('px', '')),
      height: parseInt(window.getComputedStyle(node, null).getPropertyValue('height').replace('px', ''))
    }
  }

  getLayoutedElements = (elements, settings) => {
    const dagreGraph = new dagre.graphlib.Graph()
    dagreGraph.setDefaultEdgeLabel(() => ({}))

    const layerHeights = {}
    for (const el of elements) {
      if (isNode(el) && Object.keys(this.state.nodeSizes).length > 0) {
        const elHeight = (Object.keys(this.state.nodeSizes).includes(el.id) && this.state.nodeSizes[el.id].height) || 0
        const elLayer = el.data.msg.index
        layerHeights[elLayer] = Math.max(layerHeights[elLayer] || 0, elHeight)
      }
    }

    dagreGraph.setGraph({ rankdir: 'TB' }) //, ranker: 'longest-path' })
    elements.forEach((el) => {
      if (isNode(el)) {
        if (this.state.nodeSizes && Object.keys(this.state.nodeSizes).includes(el.id)) {
          dagreGraph.setNode(el.id, { width: nodeWidth, height: layerHeights[el.data.msg.index] })
        } else {
          dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight })
        }
      } else {
        dagreGraph.setEdge(el.source, el.target)
      }
    })
    dagre.layout(dagreGraph)

    return elements.map((el) => {
      if (isNode(el)) {
        el.data.msg.height = layerHeights[el.data.msg.index]
        if (settings[el.id]) {
          el.position = {
            x: settings[el.id].x,
            y: settings[el.id].y
          }
        } else {
          const nodeWithPosition = dagreGraph.node(el.id)
          let yPos = 0
          for (let i = 0; i < el.data.msg.index; i++) {
            yPos += layerHeights[i] + 100
          }
          if (this.state.nodeSizes && Object.keys(this.state.nodeSizes).includes(el.id)) {
            el.position = {
              x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
              y: yPos
            }
          } else {
            el.position = {
              x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
              y: nodeWithPosition.y - nodeHeight / 2,
            }
          }
        }
      }
      return el
    })
  }

  saveSettings(newSettings) {
    const { setTableSettings, name } = this.props
    const settings = this.getSettings()

    setTableSettings(name || window.location.pathname, {
      ...settings,
      ...newSettings
    })
  }

  getSettings() {
    const { tableSettings, name } = this.props

    return tableSettings[name || window.location.pathname] || {}
  }

  handleSearchTermChange = _.debounce(searchTerm => {
    const { elements } = this.state

    this.saveSettings({ searchTerm })
    this.setState({ building: true })
    setTimeout(() => {
      this._highlightElements(searchTerm, elements)
      this.setState({ elements: [...elements], building: false })
    }, 0)
  }, 1000)

  _highlightElements (searchTerm, elements) {

    const isSearchResult = (node) => {
      if (node.convoNodes.find(c => (c.messageText && c.messageText.indexOf(searchTerm) >= 0) || (c.utteranceSamples && c.utteranceSamples[0].indexOf(searchTerm) >= 0))) {
        return true
      } else if (node.convos.find(c => c.header.name.indexOf(searchTerm) >= 0)) {
        return true
      }
      return false
    }

    elements.forEach(e => {
      if (isNode(e)) e.type = e.data.sender
      e.isHidden = false
    })
    if (searchTerm) {
      elements.forEach(e => {
        e.isHidden = true
      })
      const enabledElements = elements.filter(e => isNode(e) && isSearchResult(e.data))
      enabledElements.forEach(e => {
        e.type = `${e.data.sender}Highlighted`
      })

      const connectedElementIds = new Set(enabledElements.map(e => e.id))
      const connectedEdgeIds = new Set()
      const getConnected = (element, up, down) => {
        const upEdges = up ? elements.filter(e => isEdge(e) && e.target === element.id) : []
        const downEdges = down ? elements.filter(e => isEdge(e) && e.source === element.id) : []

        for (const upEdge of upEdges) {
          connectedEdgeIds.add(upEdge.id)
          if (!connectedElementIds.has(upEdge.source)) {
            connectedElementIds.add(upEdge.source)
            getConnected(elements.find(e => e.id === upEdge.source), true, false)
          }
        }
        for (const downEdge of downEdges) {
          connectedEdgeIds.add(downEdge.id)
          if (!connectedElementIds.has(downEdge.target)) {
            connectedElementIds.add(downEdge.target)
            getConnected(elements.find(e => e.id === downEdge.target), false, true)
          }
        }
      }
      enabledElements.forEach(e => {
        getConnected(e, true, true)
      })
      connectedElementIds.forEach(id => {
        elements.find(e => e.id === id).isHidden = false
      })
      connectedEdgeIds.forEach(id => {
        elements.find(e => e.id === id).isHidden = false
      })
    }
  }

  _layoutElements (elements) {
    const { classes, tree } = this.props

    const defaultEdge = {
      animated: true,
      className: classes.graphEdge,
      style: this.defaultEdgeStyle
    }

    const initialElements = []

    const walkTree = (node) => {
      if (node.ref) return

      const element = {
        id: node.key,
        type: node.sender,
        data: {
          sender: node.sender,
          msg: node.convoNodes.length > 0 ? node.convoNodes[0] : null,
          convoNodes: node.convoNodes,
          convos: node.convos
        }
      }
      if (!node.childNodes || node.childNodes.length === 0) {
        element.data.leaf = true
      }
      initialElements.push(element)
      if (node.childNodes) {
        node.childNodes.forEach(childNode => {
          if (childNode.ref) {
            initialElements.push(Object.assign({
              id: `E_${node.key}-${childNode.ref}`,
              source: node.key,
              target: childNode.ref },
            defaultEdge))
          } else {
            initialElements.push(Object.assign({
              id: `E_${node.key}-${childNode.key}`,
              source: node.key,
              target: childNode.key },
            defaultEdge))
            walkTree(childNode)
          }
        })
      }
    }
    for (const rootNode of (tree || [])) {
      walkTree(rootNode)
    }
    return this.getLayoutedElements(elements || initialElements, this.getSettings(), this.rfRef)
  }

  _handleSelectionChange (selectedNodes) {
    const { elements } = this.state

      for (const edge of elements.filter(e => isEdge(e))) {
        edge.style = this.defaultEdgeStyle
      }

      const connectedElementIds = new Set()
      const getPathSelected = (element) => {
        const upEdges = elements.filter(e => isEdge(e) && e.target === element.id)

        for (const upEdge of upEdges) {
          upEdge.style = this.pathSelectedEdgeStyle
          if (!connectedElementIds.has(upEdge.source)) {
            connectedElementIds.add(upEdge.source)
            getPathSelected(elements.find(e => e.id === upEdge.source))
          }
        }
      }
      for (const selectedNode of selectedNodes || []) {
        getPathSelected(selectedNode)
      }
      const lelements = this._layoutElements(elements)
      this._highlightElements(this.getSettings().searchTerm, lelements)
      this.setState({ elements: [...lelements] })
  }



  defaultMeNode = ({ selected, data: { msg } }, highlighted) => {
    const { classes } = this.props
    return (
      <div className={classes.graphMe + ' ' + (highlighted ? classes.graphMeHighlighted : '') + ' ' + (selected ? classes.graphSelected : '')}>
        <Handle type="target" position="top" className={classes.graphHandle} style={defaultHandleStyle} />
        <AvatarImage className={classes.graphAvatarMe} avatar={botiumLogo} />
        {msg && msg.messageText && renderUtteranceText('primary', msg, defaultTextStyle)}
        {msg && msg.userInputs && msg.userInputs.map((a, ai) => <ComponentChip key={ai} component={a} truncate={'100%'} />)}
        {msg && msg.logicHooks && msg.logicHooks.map((a, ai) => <ComponentChip key={ai} component={a} truncate={'100%'} />)}
        <Handle type="source" position="bottom" className={classes.graphHandle} style={defaultHandleStyle} />
      </div>
    )
  }

  defaultBotNode = ({ selected, data: { msg } }, highlighted) => {
    const { classes } = this.props
    return (
      <div className={classes.graphBot + ' ' + (highlighted ? classes.graphBotHighlighted : '') + ' ' + (selected ? classes.graphSelected : '')}>
        <Handle type="target" position="top" className={classes.graphHandle} style={defaultHandleStyle} />
        <AvatarImage className={classes.graphAvatarBot} avatar={defaultAvatar} />
        {msg && msg.messageText && msg.not && renderUtteranceText('danger', msg, defaultTextStyle)}
        {msg && msg.messageText && !msg.not && renderUtteranceText('white', msg, defaultTextStyle)}
        {msg && msg.optional &&
        <Tooltip title="Optional" key="optional">
          <Chip avatar={<Avatar><LowPriorityIcon/></Avatar>} label=""/>
        </Tooltip>
        }
        {msg && msg.asserters && msg.asserters.map((a, ai) => {
          if ((a.name === 'BUTTONS' || a.name === 'MEDIA') && a.args && a.args.length > 1) return a.args.map((arg, argi) => <ComponentChip key={`${ai}-${argi}`} component={{ name: a.name, args: [arg]}} truncate={'100%'}/>)
          return <ComponentChip key={ai} component={a} truncate={'100%'}/>
        })}
        {msg && msg.logicHooks && msg.logicHooks.map((a, ai) => <ComponentChip key={ai} component={a} truncate={15} />)}
        <Handle type="source" position="bottom" className={classes.graphHandle} style={defaultHandleStyle} />
      </div>
    )
  }

  _onNodeContextMenu = (event, element) => {
    const { onNodeClick } = this.props
    event.preventDefault()
    if (isNode(element) && onNodeClick) {
      onNodeClick(element.data.convos[0])
    }
  }
  _onMove = (transform) => {
    this.saveSettings({ transform })
  }
  _onNodeDragStop = (event, node) => {
    this.saveSettings({
      [node.id]: node.position
    })
  }
  _onSelectionChange = (elements) => this._handleSelectionChange(elements)
  _onLoad = (rfInstance) => {
    this.setState({ rfInstance })
    setTimeout(() => {
      const obj = {}
      if (document.querySelector('.react-flow__nodes')) {
        for (const c of document.querySelector('.react-flow__nodes').children) {
          obj[c.dataset.id] = this.getNodeSize(c)
        }
        this.setState({
          nodeSizes: obj,
          elements: this.state.elements
        })
      }
    }, 500)
  }

  render() {
    const { classes, meNode, botNode, autoHeight, externalControls, fileName} = this.props
    const { building, elements, showFilterInput } = this.state

    const settings = this.getSettings()

    const MeNode = meNode ? meNode : this.defaultMeNode
    const BotNode = botNode ? botNode : this.defaultBotNode

    const nodeTypes = {
      me: (args) => MeNode(args, false),
      meHighlighted: (args) => MeNode(args, true),
      bot: (args) => BotNode(args, false),
      botHighlighted: (args) => BotNode(args, true)
    }

    const getNodePosition = node => {
      return {
        x: parseInt(window.getComputedStyle(node, null).getPropertyValue('transform').replace('matrix(', '').replace(')', '').split(', ')[4]),
        y: parseInt(window.getComputedStyle(node, null).getPropertyValue('transform').replace('matrix(', '').replace(')', '').split(', ')[5])
      }
    }

    return (<GridContainer>
       <GridItem xs={12} right middle smallPadding smallMarginRight >
        {externalControls}
      </GridItem>
      <GridItem xs={12}>
        <div className={classes.graphContainer} style={autoHeight ? { height: this.state.exportHeight ? this.state.exportHeight : 'calc(100vh - 280px)', width: this.state.exportWidth ? this.state.exportWidth : '100%' } : {}}>
          <ReactFlow
            onNodeContextMenu={this._onNodeContextMenu.bind(this)}
            onMove={this._onMove.bind(this)}
            onNodeDragStop={this._onNodeDragStop.bind(this)}
            onSelectionChange={this._onSelectionChange.bind(this)}
            minZoom={0}
            maxZoom={2}
            defaultZoom={settings && settings.transform ? settings.transform.zoom : 1}
            defaultPosition={settings && settings.transform ? [settings.transform.x, settings.transform.y] : [0, 0]}
            nodeTypes={nodeTypes}
            elements={elements}
            nodesDraggable={true}
            nodesConnectable={false}
            onLoad={this._onLoad.bind(this)}
            ref={this.rfRef}
          >
            <Controls className={classes.convosTreeControls} showZoom={true} showFitView={true} showInteractive={false}>
              <Tooltip title="Save as PNG picture">
                <ControlButton
                  onClick={async () => {
                    const oldState = this.state.rfInstance.toObject()
                    this.state.rfInstance.setTransform({ x: 0, y: 0, zoom: 1 })

                    let calcHeight = 0
                    let calcWidth = 0
                    for (const c of this.rfRef.current.children[0].children[0].children) {
                      calcHeight = Math.max(getNodePosition(c).y + this.getNodeSize(c).height, calcHeight)
                      calcWidth = Math.max(getNodePosition(c).x + this.getNodeSize(c).width, calcWidth)
                    }

                    this.setState({
                      exportHeight: calcHeight,
                      exportWidth: calcWidth,
                    })

                    this.state.rfInstance.setTransform({ x: 0, y: 0, zoom: 1 })
                    setTimeout(async () => {
                      const dataUrl = await domtoimage.toPng(this.rfRef.current, {
                        height: parseInt(window.getComputedStyle(this.rfRef.current, null).getPropertyValue('height').replace('px', '')),
                        width: parseInt(window.getComputedStyle(this.rfRef.current, null).getPropertyValue('width').replace('px', '')),
                        bdcolor: 'white'
                      })
                      const link = document.createElement('a')
                      link.download = fileName || 'botium-flow-chart.png'
                      link.href = dataUrl
                      link.click()

                      this.setState({
                        exportHeight: null,
                        exportWidth: null
                      })
                      this.state.rfInstance.setTransform({ x: oldState.position[0], y: oldState.position[1], zoom: oldState.zoom })
                    }, 1000)
                  }}
                >
                  <ShowIcon icon="image" />
                </ControlButton>
              </Tooltip>
              {this.props.refetch &&
                <Tooltip title="Refresh tree">
                  <ControlButton
                    data-unique="btnRefresh"
                    onClick={async () => this.props.refetch()}
                    disabled={building}
                  >
                    <ShowIcon icon="redo" />
                  </ControlButton>
                </Tooltip>
              }
              {!showFilterInput &&
                <Tooltip title="Filter">
                  <ControlButton
                    data-unique="btnGraphFilter"
                    onClick={async () => this.setState({showFilterInput: true})}
                  >
                    <ShowIcon icon="filter" />
                  </ControlButton>
                </Tooltip>
              }
              {showFilterInput &&
                <Tooltip title="Filter">
                  <CustomTextField
                    style={{width: '100%', marginTop: 0}}
                    inputProps={{

                    }}
                    disableBorder
                    input={{
                      name: 'txtGraphFilter',
                      placeholder: 'Filter ...',
                      onChange: ev => this.handleSearchTermChange(ev.target.value)
                    }}
                    endAdornment={(
                      <Button
                        simple
                        small
                        justIcon
                        onClick={async () => this.setState({showFilterInput: false})}
                        data-unique="btnGraphCloseFilter"
                      >
                        <CloseIcon/>
                      </Button>
                    )}
                    disabled={building}
                    defaultValue={settings.searchTerm}
                    data-unique="txtGraphFilter"
                  />
                </Tooltip>
              }
            </Controls>
          </ReactFlow>
        </div>
      </GridItem>
    </GridContainer>)
  }
}

export default compose(
  withStyles(convosTreeStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, tableSettings: state.table }),
    { setTableSettings },
  )
)(withRouter(ConvosTree))
