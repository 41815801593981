import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import getLicensePageStyle from 'assets/jss/material-dashboard-react/components/getLicensePageStyle.jsx'

class GetLicensePage extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Please get a Botium License</h4>
            </CardHeader>
            <CardBody>
              <center>
                <h1>
                  Please get a Botium License by <a href="https://info.cyara.com/contact-chatbot-testing-and-assurance-with-botium" target="_blank" rel="noopener noreferrer">finishing registration</a>!
                </h1>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles(getLicensePageStyle)(GetLicensePage)
