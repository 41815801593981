const graphqlEndpoint = process.env.REACT_APP_API_HTTP_ENDPOINT || '/graphql'
const graphqlWsEndpoint =
  process.env.REACT_APP_API_WS_ENDPOINT ||
  (window.location.protocol === 'https:' ? 'wss://' : 'ws://') +
    window.location.host +
    '/subscriptions'
const apiEndpoint =
  process.env.REACT_APP_API_BASE || graphqlEndpoint.replace('/graphql', '/api')
const extApiEndpoint =
  process.env.REACT_APP_API_BASE ||
  (process.env.REACT_APP_API_HTTP_ENDPOINT &&
    process.env.REACT_APP_API_HTTP_ENDPOINT.replace('/graphql', '/api')) ||
  window.location.origin + '/api'

const dev = {
  graphql: {
    uri: graphqlEndpoint,
    ws: graphqlWsEndpoint,
  },
  api: {
    base: apiEndpoint,
    ext: extApiEndpoint,
  },
  buildBranch: process.env.REACT_APP_GIT_BRANCH || '',
  buildRevision: process.env.REACT_APP_GIT_REVISION || '',
  buildDate: process.env.REACT_APP_BUILDDATE || '',
}

const prod = {}

const config =
  process.env.REACT_APP_STAGE === 'production' ? Object.assign(dev, prod) : dev

export default {
  ...config
}
