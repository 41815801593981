import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Mutation } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import LinkButton from 'components/Button/LinkButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Hidden from '@material-ui/core/Hidden'
import {
  renderPasswordField,
  required,
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { clearLogin } from 'actions/token'

import ShowIcon from 'components/Icon/ShowIcon'

import userStyle from 'assets/jss/material-dashboard-react/views/userStyle.jsx'

import { RESET_PASSWORD } from 'views/User/gql'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class ResetPassword extends React.Component {
  render() {
    const { setAlertSuccessMessage, setAlertErrorMessage, clearLogin, history, match, mode } = this.props

    const resetToken = match.params && match.params.resetToken

    return (<GridContainer>
      <Hidden xsDown>
        <GridItem sm={2} md={3} />
      </Hidden>
      <GridItem xs={12} sm={8} md={6}>
        <Mutation mutation={RESET_PASSWORD}>{(resetPassword, { loading }) => (
          <Form
            onSubmit={async (values, form) => {
              try {
                const res = await resetPassword({
                  variables: {
                    resetToken: resetToken,
                    newPassword: values.newPassword
                  },
                })
                if (res.data.resetPassword) {
                  clearLogin()
                  form.initialize(res.data.resetPassword)
                  if (mode === 'expired') setAlertSuccessMessage('Password has been changed. Please login now.')
                  else setAlertSuccessMessage('Password has been reset. Please login now.')
                  history.push('/login')
                }
              } catch(error) {
                if (mode === 'expired') setAlertErrorMessage('Changing password failed', error)
                else setAlertErrorMessage('Resetting password failed', error)
              }
            }}
            initialValues={{ newPassword: '', newPasswordCheck: ''}}
            validate={values => {
              const errors = {}
              if (values.newPassword !== values.newPasswordCheck) {
                errors.newPasswordCheck = 'Must match'
              }
              return errors
            }}
            render={({
              handleSubmit,
              submitting,
              invalid
            }) => (
              <form  onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} largePadding center>
                    <Text header>{mode === 'expired' ? 'Change Expired Password' : 'Reset Password'}</Text>
                  </GridItem>
                  <GridItem xs={12} noPadding center>
                    <Field
                      name="newPassword"
                      component={renderPasswordField}
                      label="New Password"
                      validate={required}
                      data-unique="pwResetPasswordNewPassword"
                    />
                  </GridItem>
                  <GridItem xs={12} noPadding center>
                    <Field
                      name="newPasswordCheck"
                      component={renderPasswordField}
                      label="Confirm new Password"
                      validate={required}
                      data-unique="pwResetPasswordNewPasswordCheck"
                    />
                  </GridItem>
                  <GridItem xs={12} noPadding right>
                    <Text muted>
                      <LinkButton onClick={() => history.push('/login')}>Go to Login</LinkButton>
                    </Text>
                  </GridItem>
                  <GridItem xs={12} smallPadding center>
                    <Button
                      type="submit"
                      disabled={submitting || invalid}
                      data-unique="btnProfileResetPassword"
                      fullWidth
                    >
                      
                      {(loading || submitting) && <LoadingIndicator />}
                      {(!loading && !submitting) && <ShowIcon icon="save" />}
                      {mode === 'expired' ? 'Change Password' : 'Reset Password'}
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}</Mutation>
      </GridItem>
    </GridContainer>)
  }
}

export default connect(
  state => ({ }),
  { clearLogin, setAlertSuccessMessage, setAlertErrorMessage },
)(withStyles(userStyle)(ResetPassword))
