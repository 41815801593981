import React from 'react'
import { connect } from 'react-redux'
import copyToClipboard from 'copy-to-clipboard'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query } from 'react-apollo'
// core components
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  renderTextField,
  CustomTextField,
  renderSelect, renderCodeArea, prettyPrintJson, json
} from 'components/Form/Form'

import { APIKEYS_QUERY } from '../../views/Settings/gql'
import Button from '../Button/Button'
import { setAlertSuccessMessage } from '../../actions/alert'
import config from 'config'
import { capSetDescription, usedByCapabilitySet } from './Helper'

const USER_PROFILE_SAMPLE = JSON.stringify({
  authenticatedData:{
    lp_sdes:[
      {
        type:'ctmrinfo',
        info:{
          socialId:'1234567890',
          companyBranch:'BotiumBrand'
        }
      },
      {
        type:'personal',
        personal:{
          firstname:'John',
          lastname:'Doe',
          gender:'MALE'
        }
      }
    ]
  }
}, null, 2)

const CLIENT_PROPERTIES_SAMPLE = JSON.stringify({
  type: 'ClientProperties',
  features: ['AUTO_MESSAGES']
}, null, 2)

export function livepersonCaps2Form(caps) {
  return {
    liveperson: {
      clientId: (
        caps.find(c => c.name === 'LIVEPERSON_CLIENT_ID') || { stringValue: '' }
      ).stringValue,
      clientSecret: (
        caps.find(c => c.name === 'LIVEPERSON_CLIENT_SECRET') || { stringValue: '' }
      ).stringValue,
      accountId: (
        caps.find(c => c.name === 'LIVEPERSON_ACCOUNT_ID') || { stringValue: '' }
      ).stringValue,
      extConsumerId: (
        caps.find(c => c.name === 'LIVEPERSON_EXT_CONSUMER_ID') || { stringValue: '' }
      ).stringValue,
      campaignId: (
        caps.find(c => c.name === 'LIVEPERSON_CAMPAIGN_ID') || { stringValue: '' }
      ).stringValue,
      engagementId: (
        caps.find(c => c.name === 'LIVEPERSON_ENGAGEMENT_ID') || { stringValue: '' }
      ).stringValue,
      userProfile: (
        caps.find(c => c.name === 'LIVEPERSON_USER_PROFILE') || { jsonValue: '' }
      ).jsonValue,
      clientProperties: (
        caps.find(c => c.name === 'LIVEPERSON_CLIENT_PROPERTIES') || { jsonValue: '' }
      ).jsonValue
    }
  }
}

const capNamesMap = {
  'liveperson.clientId': 'LIVEPERSON_CLIENT_ID',
  'liveperson.clientSecret': 'LIVEPERSON_CLIENT_SECRET',
  'liveperson.accountId': 'LIVEPERSON_ACCOUNT_ID',
  'liveperson.extConsumerId': 'LIVEPERSON_EXT_CONSUMER_ID',
  'liveperson.campaignId': 'LIVEPERSON_CAMPAIGN_ID',
  'liveperson.engagementId': 'LIVEPERSON_ENGAGEMENT_ID',
  'liveperson.userProfile': 'LIVEPERSON_USER_PROFILE',
  'liveperson.clientProperties': 'LIVEPERSON_CLIENT_PROPERTIES'
}

export function livepersonForm2caps(values) {
  return [
    {
      name: 'LIVEPERSON_CLIENT_ID',
      type: 'STRING',
      stringValue: values.liveperson.clientId || '',
    },
    {
      name: 'LIVEPERSON_CLIENT_SECRET',
      type: 'STRING',
      stringValue: values.liveperson.clientSecret || '',
    },
    {
      name: 'LIVEPERSON_ACCOUNT_ID',
      type: 'STRING',
      stringValue: values.liveperson.accountId || '',
    },
    {
      name: 'LIVEPERSON_EXT_CONSUMER_ID',
      type: 'STRING',
      stringValue: values.liveperson.extConsumerId || '',
    },
    {
      name: 'LIVEPERSON_CAMPAIGN_ID',
      type: 'STRING',
      stringValue: values.liveperson.campaignId || '',
    },
    {
      name: 'LIVEPERSON_ENGAGEMENT_ID',
      type: 'STRING',
      stringValue: values.liveperson.engagementId || '',
    },
    {
      name: 'LIVEPERSON_USER_PROFILE',
      type: 'JSON',
      jsonValue: values.liveperson.userProfile || '',
    },
    {
      name: 'LIVEPERSON_CLIENT_PROPERTIES',
      type: 'JSON',
      jsonValue: values.liveperson.clientProperties || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'liveperson' }
  ]
}

class LivePersonEditUnconnected extends React.Component {
  render() {
    const { disabled, setAlertSuccessMessage, capSetCapNames } = this.props

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => {
      const webhookUrl = `${config.api.ext}/inbound?APIKEY=${values.liveperson.apiKey || 'SELECT_API_KEY'}`

      return (
        <GridContainer>
          <GridItem xs={10} sm={6}>
            <CustomTextField
              input={{
                value: webhookUrl,
                readOnly: true,
              }}
              label="Live Person Webhook Endpoint (for Copy&Paste)"
              data-unique="txtLivePersonEditWebhookEndpoint"
              endAdornment={
                <Button
                  onClick={() => {
                    copyToClipboard(webhookUrl)
                    setAlertSuccessMessage(`Live Person Webhook Endpoint copied to the clipboard`)
                  }}
                  justIcon
                  data-unique="btnLivePersonWebhookEditCopy"
                >
                  <FileCopyIcon />
                </Button>
              }
              helperText="You have to fill the 'Webhook endpoint' field of your Installed Live Person Application to use this URL as webhook endpoint, adding API Key as query parameter."
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Query query={APIKEYS_QUERY}>
              {({ loading, error, data }) => {
                return (
                  <Field
                    name="liveperson.apiKey"
                    component={renderSelect}
                    label="API Key"
                    data-unique="selLivePersonEditApiKey"
                    loading={loading}
                    error={error}
                    items={data && data.apikeys && data.apikeys.map(a => {
                      return { key: a.key, label: a.name }
                    })}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['liveperson.apiKey']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['liveperson.apiKey'], 'Choose an API Key to generate the Outgoing Webhook URI')}
                  />)
              }}
            </Query>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="liveperson.clientId"
              component={renderTextField}
              label="Client ID"
              validate={required}
              data-unique="txtLivePersonEditClientId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['liveperson.clientId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['liveperson.clientId'], 'The Application Id in your Live Person installed Application list')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="liveperson.clientSecret"
              component={renderTextField}
              label="Client Secret Key"
              validate={required}
              data-unique="txtLivePersonEditClientSecret"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['liveperson.clientSecret']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['liveperson.clientSecret'], 'The Application Secret in your Live Person installed Application list')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="liveperson.accountId"
              component={renderTextField}
              label="Account ID"
              validate={required}
              data-unique="txtLivePersonEditAccountId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['liveperson.accountId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['liveperson.accountId'])}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="liveperson.extConsumerId"
              component={renderTextField}
              label="External Consumer ID"
              data-unique="txtLivePersonEditExtConsumerId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['liveperson.extConsumerId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['liveperson.extConsumerId'])}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="liveperson.campaignId"
              component={renderTextField}
              label="Campaign ID"
              data-unique="txtLivePersonEditCampaignId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['liveperson.campaignId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['liveperson.campaignId'], 'Campaign ID together with Engagement ID will route the conversation to the specific bot.')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="liveperson.engagementId"
              component={renderTextField}
              label="Engagement ID"
              data-unique="txtLivePersonEditEngagementId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['liveperson.engagementId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['liveperson.engagementId'], 'Engagement ID together with Campaign ID will route the conversation to the specific bot.')}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              className="CapabilitiesShort"
              name="liveperson.clientProperties"
              component={renderCodeArea}
              options={{ mode: 'application/json' }}
              label="Client Properties"
              codeFormat={prettyPrintJson}
              validate={json}
              data-unique="codeLivePersonEditClientProperties"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['liveperson.clientProperties']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['liveperson.clientProperties'], 'Optionally you are able to set client properties into the header of the open conversation request')}
            />
            <Button data-unique="btnLivePersonEditClientProperties" link
                    onClick={() => change('liveperson.clientProperties', CLIENT_PROPERTIES_SAMPLE)}>Insert sample profile</Button>
          </GridItem>
          <GridItem xs={12}>
            <Field
              className="CapabilitiesShort"
              name="liveperson.userProfile"
              component={renderCodeArea}
              options={{ mode: 'application/json' }}
              label="User profile"
              codeFormat={prettyPrintJson}
              validate={json}
              data-unique="codeLivePersonEditUserProfile"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['liveperson.userProfile']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['liveperson.userProfile'], 'Optionally you are able to set properties that represent the user, like firstName and LastName etc...')}
            />
            <Button data-unique="btnLivePersonEditProfile" link
              onClick={() => change('liveperson.userProfile', USER_PROFILE_SAMPLE)}>Insert sample profile</Button>
          </GridItem>
        </GridContainer>
      )
    }} />)
  }
}

const LivePersonEdit = connect(
  state => ({}),
  { setAlertSuccessMessage }
)(LivePersonEditUnconnected)
export { LivePersonEdit }
