import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import GridItem from '../Grid/GridItem.jsx'
import GridContainer from '../Grid/GridContainer.jsx'
import ConfirmationDialog from './ConfirmationDialog.jsx'
import { CustomCheckbox } from 'components/Form/Form'
import Text from 'components/Typography/Text'

function SimpleConfirmationDialog({ ...props }) {
  const {
    showConfirmationDialog,
    confirmationTitle,
    confirmationText,
    requireCheck,
    requireCheckText,
    onOk,
    onCancel,
    danger
  } = props

  const [checked, setChecked] = useState(false)
  const prevShowConfirmationDialog = useRef({ showConfirmationDialog }).current

  useEffect(() => {
    if (!prevShowConfirmationDialog.showConfirmationDialog && showConfirmationDialog) {
      setChecked(false)
    }

    return () => { 
      prevShowConfirmationDialog.showConfirmationDialog = showConfirmationDialog
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfirmationDialog])

  return (<React.Fragment>
    <ConfirmationDialog
      danger={danger}
      closeDisabled
      open={showConfirmationDialog}
      okDisabled={requireCheck && !checked}
      okText="Yes, I am sure. Do it!"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onOk}
      title={confirmationTitle}
    >
      <GridContainer>
        <GridItem xs={12} center largeMargin>
          <Text header center>{confirmationText}</Text>
        </GridItem>
        {requireCheck &&
          <GridItem xs={12} center>
            <CustomCheckbox
              input={{
                onChange: e => {
                  setChecked(e.target.checked)
                },
                checked
              }}
              label={requireCheckText}
              data-unique="chkConfirmationCheck"
            />
          </GridItem>
        }
      </GridContainer>
    </ConfirmationDialog>
  </React.Fragment>)
}

SimpleConfirmationDialog.propTypes = {
  showConfirmationDialog: PropTypes.any,
  confirmationTitle: PropTypes.string,
  confirmationText: PropTypes.string,
  requireCheck: PropTypes.bool,
  requireCheckText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired
}
SimpleConfirmationDialog.defaultProps = {
  confirmationTitle: 'Are you sure ?',
  requireCheck: false,
  requireCheckText: 'I am sure.'
}

export default SimpleConfirmationDialog
