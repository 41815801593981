import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, withApollo, compose } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { renderTextField, renderCodeArea } from 'components/Form/Form'
import ErrorFormat from 'components/Info/ErrorFormat'
import SwitchButton from 'components/Button/SwitchButton'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import { CRAWLERSESSIONSCRIPT_QUERY } from './gql'
import crawlerprojectsStyle from '../../assets/jss/material-dashboard-react/views/crawlerprojectsStyle'

class CrawlerSessionScript extends React.Component {
  renderForm(script, crawlerSessionScriptId) {
    const { onSwitchToEditorClick, onSwitchToSourceClick, classes } = this.props
    return (
      <Form
        onSubmit={async () => {}}
        initialValues={script}
        render={({
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} data-simplelist-container>
            <GridContainer>
              <GridItem md={12} borderBottom >
                <GridContainer>
                  <GridItem md={12} lg={8} className={classes.scriptTypeFild}>
                    <Field
                      name="scriptType"
                      component={renderTextField}
                      label="Script Type"
                      disabled={true}
                      data-unique="selCrawlerSessionScriptScriptType"
                    />
                  </GridItem>
                  <GridItem md={12} lg={4} right middle className={classes.sourceEditorSwitch}>
                    <SwitchButton
                      leftLabel="Source Editor"
                      rightLabel={script.scriptType === 'SCRIPTING_TYPE_UTTERANCES' ? 'Utterance Editor' : 'Convo Editor'}
                      leftDataUnique="btnSourceEditor"
                      rightDataUnique="btnEditor"
                      checked="left"
                      onRightClick={() => onSwitchToEditorClick(script)}
                      onLeftClick={() => onSwitchToSourceClick()}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="script"
                  component={renderCodeArea}
                  label="Crawler Session Script"
                  options={{
                    lineNumbers: true,
                    cursorHeight: 0.8,
                    readOnly: true,
                    mode: 'application/json'
                  }}
                  data-unique="codeCrawlerSessionScriptScript"
                />
              </GridItem>
            </GridContainer>
          </form>
        )}
      />
    )
  }

  render() {
    const { match } = this.props

    if (match.params && match.params.crawlerSessionScriptId) {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Query
              query={CRAWLERSESSIONSCRIPT_QUERY}
              variables={{ id: match.params.crawlerSessionScriptId }}
            >
              {({ loading, error, data }) => {
                if (loading) return <LoadingIndicator/>
                if (error) return <ErrorFormat err={error} />

                return this.renderForm(data.crawlersessionscript, match.params.crawlerSessionScriptId)
              }}
            </Query>
          </GridItem>
        </GridContainer>
      )
    } else {
      const scriptType = 'SCRIPTING_TYPE_CONVO'
      const script = ''
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderForm({ scriptType, script })}
          </GridItem>
        </GridContainer>
      )
    }
  }
}


export default compose(
  withStyles(crawlerprojectsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license  })
  ),
  withApollo
)(CrawlerSessionScript)
