import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from 'components/Tooltip/Tooltip'
// core components
import { CustomTextField, CustomCheckbox } from 'components/Form/Form'
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle'
import ShowIcon from 'components/Icon/ShowIcon'
import Button from 'components/Button/Button'
import Text from 'components/Typography/Text.jsx'

const getMatrixCell = (colName, weight, rowIndex, colIndex, classes, minWeight, maxWeight) => {
  const size = 20

  if (!_.isNumber(weight)) {
    return (<TableCell className={classes.tableMatrixCell} style={{ width: size, height: size }} key={colIndex}>
      <div style={{ display: 'inline-block', lineHeight: `${size}px`, width: size, height: size }}></div>
    </TableCell>)
  }

  const progress = (weight - minWeight) / (maxWeight - minWeight)
  const progressColor = 220 - 220 * progress
  const color = `rgb(255, ${progressColor}, ${progressColor})`
  const cell = <div style={{
    display: 'block',
    lineHeight: `${size}px`,
    width: '100%',
    height: '100%',
    backgroundColor: `${color}`,
    padding: '0px',
    margin: '0px',
    border: '1px solid #333',
    textAlign: 'center'
  }}></div>

  return (<TableCell className={classes.tableMatrixCell} key={colIndex} style={{ width: size, height: size }}>
    <Tooltip title={`${weight.toFixed(4)} / ${colName}`}>{cell}</Tooltip>
  </TableCell>)
}

const getMatrixHeader = (entry) => {
  return <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    writingMode: 'vertical-lr',
    width: '100%'
  }}>{entry}</div>
}

export default withStyles(theme => {
  const tableStyles = tableStyle(theme)
  tableStyles.tableMatrixCell = {
    ...tableStyle.tableCell,
    padding: '0px',
    borderWidth: 1,
    borderColor: tableStyles.grayTableHeader.color,
    borderStyle: 'solid',
  }
  tableStyles.tableCell = {
    ...tableStyle.tableCell,
    padding: '0px 0px'
  }
  tableStyles.tableHeadCellMatrix = {
    ...tableStyle.tableHeadCell,
    padding: '0px 0px',
    borderWidth: 1,
    borderColor: tableStyles.grayTableHeader.color,
    borderStyle: 'solid'
  }
  tableStyles.tableRow = {
    height: 'auto',
    lineHeight: '1em'
  }
  tableStyles.tableMatrixCellFilters = {
    padding: 10,
    width: 100
  }
  tableStyles.tableHeadCellMatrixColNav = {
    textAlign: 'center',
    width: 25
  }
  tableStyles.tableHeadCellMatrixRowNav = {
    textAlign: 'center',
    height: 25,
    padding: 0
  }
  tableStyles.navButton = {
    padding: 0,
    margin: 0
  }
  return tableStyles
})(class TableHeatMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideEmptyRows: true,
      hideEmptyCols: true,
      rowFilter: '',
      columnFilter: '',
      hideShortTerms: true,
      colPage: 0,
      rowPage: 0,
      rowPageSize: 100,
      colCount: 25,
      isLastPage: false,
      startCol: 0,
      maxColCount: 0
    }
    this.tableRef = React.createRef()
    this.interval = null
    this.updateWidth = null
    this.timeout = null

    this.state.minWeight = props.matrix.reduce((min, row) => {
      return Math.min(
        min,
        ...(Object.values(props.getColumns(row)))
      )
    }, 1000)
    this.state.maxWeight = props.matrix.reduce((max, row) => {
      return Math.max(
        max,
        ...(Object.values(props.getColumns(row)))
      )
    }, -1000)
    this.state.allColNames = _.sortBy(_.uniq(props.matrix.reduce((all, row) => all.concat(Object.keys(props.getColumns(row))), [])))
  }

  componentDidMount() {

    this.updateWidth = () => {
      if (this.tableRef && this.tableRef.current) {
        this.setState({
          colCount: parseInt((this.tableRef.current.offsetWidth - 400) / 25) < 3 ? 3 : parseInt((this.tableRef.current.offsetWidth - 400) / 25)
        })
        if (this.interval) {
          clearInterval(this.interval)
        }
      }
      if (this.state.isLastPage) {
        this.setState({
          startCol: Math.max(0, this.state.maxColCount - this.state.colCount)
        })
      } else if (this.state.startCol > 0 && this.state.colCount >= this.state.maxColCount) {
        this.setState({
          startCol: 0
        })
      }
    }

    this.interval = setInterval(() => {
      this.updateWidth()
    }, 100)

    window.addEventListener('resize', this.updateWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth)
  }

  render() {
    const { matrix, classes, getRowHeader, getColumns, filterRowLabel, filterColumnLabel } = this.props
    const { hideEmptyRows, hideEmptyCols, rowFilter, columnFilter, hideShortTerms, rowPage, rowPageSize, colCount, startCol, minWeight, maxWeight, allColNames } = this.state

    const _cleanedColumnFilter = columnFilter ? columnFilter.split(/[,|\s]+/).map(t => t.trim()).filter(t => t) : null
    const _cleanedRowFilter = rowFilter ? rowFilter.split(/[,|\s]+/).map(t => t.trim()).filter(t => t) : null

    let filteredColNames = (columnFilter ? allColNames.filter(c => c && _cleanedColumnFilter.findIndex(mf => c.indexOf(mf) >= 0) >= 0) : allColNames)
    let filteredRows = matrix.filter(row => {
      if (!row) return false
      const rowHeader = getRowHeader(row)
      if (rowFilter) {
        if (_cleanedRowFilter.findIndex(mf => rowHeader.indexOf(mf) >= 0) < 0) return false
      }
      if (hideShortTerms) {
        if (rowHeader.length <= 4) return false
      }
      return true
    })
    if (hideEmptyRows) {
      filteredRows = filteredRows.filter(row => {
        const cols = getColumns(row)
        if (filteredColNames.findIndex(colName => _.isNumber(cols[colName])) >= 0) return true
        else return false
      })
    }
    if (hideEmptyCols) {
      filteredColNames = filteredColNames.filter(colName => {
        if (filteredRows.findIndex(row => _.isNumber(getColumns(row)[colName])) >= 0) return true
        else return false
      })
    }

    const filteredColNamesPage = filteredColNames.slice(startCol, startCol + colCount)
    const colNamesHasPrev = filteredColNames.slice(Math.max(0, startCol - colCount), startCol).length > 0
    const colNamesHasNext = filteredColNames.slice(startCol + colCount, startCol + colCount + 1).length > 0

    if (startCol !== 0 && !colNamesHasNext) {
      setTimeout(() => this.setState({
        isLastPage: true,
        maxColCount: filteredColNames.length
      }), 0)
    } else {
      if (this.state.isLastPage) {
        setTimeout(() => {
          this.setState({
            isLastPage: false
          })
        }, 0)
      }
    }

    const filteredRowsPage = filteredRows.slice(rowPage * rowPageSize, (rowPage + 1) * rowPageSize)
    const rowsHasPrev = rowPage > 0
    const rowsHasNext = filteredRows.length > (rowPage + 1) * rowPageSize

    return (<div className={classes.tableResponsive} ref={this.tableRef}>
      <Table className={classes.table}>
        <TableHead className={classes.primaryTableHeader}>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableMatrixCell + ' ' + classes.tableMatrixCellFilters} key="filter">
              {allColNames.length > 0 && <CustomTextField
                placeholder={filterColumnLabel || 'Filter Columns'}
                input={{
                  name: 'columnFilter',
                  value: columnFilter,
                  onChange: (e) => this.setState({ columnFilter: e.target.value, colPage: 0, rowPage: 0 })
                }}
                data-unique="txtHeatMapColumnFilter"
              />}
              {matrix.length > 0 && <CustomTextField
                placeholder={filterRowLabel || 'Filter Rows'}
                input={{
                  name: 'rowFilter',
                  value: rowFilter,
                  onChange: (e) => this.setState({ rowFilter: e.target.value, colPage: 0, rowPage: 0 })
                }}
                data-unique="txtHeatMapRowFilter"
              />}
              {matrix.length > 0 && <CustomCheckbox
                label="Hide Short Terms"
                input={{
                  name: 'hideShortTerms',
                  checked: hideShortTerms,
                  onChange: (e) => this.setState({ hideShortTerms: !hideShortTerms, colPage: 0, rowPage: 0 })
                }}
                data-unique="chkHeatMapHideShortTerms"
              />}
            </TableCell>
            {colNamesHasPrev &&
              <TableCell className={classes.tableCell + ' ' + classes.tableHeadCellMatrix + ' ' + classes.tableHeadCellMatrixColNav} key="colPrev">
                <Button className={classes.navButton} onClick={() => this.setState({ startCol: Math.max(0, startCol - colCount) })}><ShowIcon icon="angle-left" /></Button>
              </TableCell>
            }
            {filteredColNamesPage.map((colName, colIndex) =>
              <TableCell className={classes.tableCell + ' ' + classes.tableHeadCellMatrix} key={colIndex}>
                <Text bold>{getMatrixHeader(colName)}</Text>
              </TableCell>
            )}
            {colNamesHasNext &&
              <TableCell className={classes.tableCell + ' ' + classes.tableHeadCellMatrix + ' ' + classes.tableHeadCellMatrixColNav} key="colNext">
                <Button className={classes.navButton} onClick={() => this.setState({ startCol: startCol + colCount })}><ShowIcon icon="angle-right" /></Button>
              </TableCell>
            }
            <TableCell className={classes.tableMatrixCell} key="empty"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsHasPrev &&
            <TableRow className={classes.tableRow} key={'rowPrev'}>
              <TableCell variant="head" className={classes.tableCell + ' ' + classes.tableHeadCellMatrix + ' ' + classes.tableHeadCellMatrixRowNav} key="prev0">
                <Button className={classes.navButton} justIcon onClick={() => this.setState({ rowPage: rowPage - 1 })}><ShowIcon icon="angle-up" /></Button>
              </TableCell>
              {colNamesHasPrev && <TableCell className={classes.tableMatrixCell} key="prevEmpty0"></TableCell>}
              {filteredColNamesPage.map((colName, colIndex) => <TableCell className={classes.tableMatrixCell} key={colIndex}></TableCell>)}
              {colNamesHasNext && <TableCell className={classes.tableMatrixCell} key="prevEmpty1"></TableCell>}
              <TableCell variant="head" className={classes.tableCell + ' ' + classes.tableHeadCellMatrix + ' ' + classes.tableHeadCellMatrixRowNav} key="prev1">
                <Button className={classes.navButton} justIcon onClick={() => this.setState({ rowPage: rowPage - 1 })}><ShowIcon icon="angle-up" /></Button>
              </TableCell>
            </TableRow>
          }
          {filteredRowsPage.map((row, rowIndex) => {
            const cols = getColumns(row)
            return (
              <TableRow className={classes.tableRow} key={rowIndex}>
                <TableCell className={classes.tableMatrixCell} key={`rowheader1_${rowIndex}`}>
                  <Text bold>{getRowHeader(row)}</Text>
                </TableCell>
                {colNamesHasPrev && <TableCell className={classes.tableMatrixCell} key="dataEmpty0"></TableCell>}
                {filteredColNamesPage.map((colName, colIndex) => getMatrixCell(colName, cols[colName], rowIndex, colIndex, classes, minWeight, maxWeight))}
                {colNamesHasNext && <TableCell className={classes.tableMatrixCell} key="dataEmpty1"></TableCell>}
                <TableCell className={classes.tableMatrixCell} key={`rowheader2_${rowIndex}`}>
                  <Text bold>{getRowHeader(row)}</Text>
                </TableCell>
              </TableRow>
            )
          })}
          {rowsHasNext &&
            <TableRow className={classes.tableRow} key={'rowNext'}>
              <TableCell variant="head" className={classes.tableCell + ' ' + classes.tableHeadCellMatrix + ' ' + classes.tableHeadCellMatrixRowNav} key="next0">
                <Button className={classes.navButton} justIcon onClick={() => this.setState({ rowPage: rowPage + 1 })}><ShowIcon icon="angle-down" /></Button>
              </TableCell>
              {colNamesHasPrev && <TableCell variant="head" className={classes.tableMatrixCell} key="nextEmpty0"></TableCell>}
              {filteredColNamesPage.map((colName, colIndex) => <TableCell className={classes.tableMatrixCell} key={colIndex}></TableCell>)}
              {colNamesHasNext && <TableCell variant="head" className={classes.tableMatrixCell} key="nextEmpty1"></TableCell>}
              <TableCell variant="head" className={classes.tableCell + ' ' + classes.tableHeadCellMatrix + ' ' + classes.tableHeadCellMatrixRowNav} key="next1">
                <Button className={classes.navButton} justIcon onClick={() => this.setState({ rowPage: rowPage + 1 })}><ShowIcon icon="angle-down" /></Button>
              </TableCell>
            </TableRow>
          }
        </TableBody>
        <TableHead className={classes.primaryTableHeader}>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableMatrixCell} key="empty1"></TableCell>
            {colNamesHasPrev &&
              <TableCell variant="head" className={classes.tableCell + ' ' + classes.tableHeadCellMatrix + ' ' + classes.tableHeadCellMatrixColNav} key="colPrev2">
                <Button className={classes.navButton} justIcon onClick={() => this.setState({ startCol: Math.max(0, startCol - colCount) })}><ShowIcon icon="angle-left" /></Button>
              </TableCell>
            }
            {filteredColNamesPage.map((colName, colIndex) =>
              <TableCell className={classes.tableCell + ' ' + classes.tableHeadCellMatrix} key={colIndex}>
                <Text bold>{getMatrixHeader(colName)}</Text>
              </TableCell>
            )}
            {colNamesHasNext &&
              <TableCell variant="head" className={classes.tableCell + ' ' + classes.tableHeadCellMatrix + ' ' + classes.tableHeadCellMatrixColNav} key="colNext2">
                <Button className={classes.navButton} justIcon onClick={() => this.setState({ startCol: startCol + colCount })}><ShowIcon icon="angle-right" /></Button>
              </TableCell>
            }
            <TableCell className={classes.tableMatrixCell} key="empty2"></TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </div>)
  }
})
