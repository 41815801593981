import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import logoWhite from 'assets/img/cyara_logo_white.svg'
import logoPrimary from 'assets/img/cyara_logo_primary.svg'
import { isDarkmode } from 'components/Darkmode/helper'

const style = (theme) => ({
  logoLink: {
    textTransform: 'uppercase',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  logoWhite: {
    '&,&:hover': {
      color: '#FFFFFF'
    }
  },
  logoPrimary: {
    '&,&:hover': {
      color: theme.colors.primary
    }
  },
  logoImage: {
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '10px',
    marginRight: '15px',
  },
  logoText: {
    display: 'inline-block',
    lineHeight: 1,
    overflow: 'hidden',
    //width: 'calc(100% - 60px)',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoSidebar: {
    width: '100%',
    paddingLeft: 8
  },
  logoInline: {
    height: 25,
    transition: 'height 2s',
    border: '0',
  },
  logoLarge: {
    height: 33
  },
  logoSidebarLarge: {
    top: '16px'
  }
})

function BotiumBoxLogo({ classes, className, sidebar, sidebarLarge, hideText, large, white, whiteicon }) {
  return (
    <a href="https://cyara.com/botium-box/" className={classNames({
      [classes.logoLink]: true,
      [classes.logoWhite]: !!white,
      [classes.logoPrimary]: !white,
      [classes.logoIcon]: !whiteicon,
      ...(className ? { [className]: true } : {})
    })} target="_blank" rel="noopener noreferrer">
      <div className={classes.logoImage + ' ' + large ? classes.logoLarge : ''}>
        <img src={white ? logoWhite : isDarkmode() ? logoWhite : logoPrimary} alt="Cyara Botium" className={classNames({
          [classes.logoInline]: true,
          [classes.logoSidebar]: sidebar,
          [classes.logoSidebarLarge]: sidebarLarge,
          [classes.logoLarge]: large
        })} />
      </div>
    </a>
  )
}

export default withStyles(style)(BotiumBoxLogo)
