import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import classNames from 'classnames'

const imageIconStyles = theme => ({
    xs: {
        maxWidth: 16
    },
    sm: {
        maxWidth: 24
    },
    md: {
        maxWidth: 32
    },
    lg: {
        maxWidth: 64
    }
})

function ImageIcon({ ...props }) {
  const { classes, alt, src, xs, sm, md, lg } = props

  return <img src={src} alt={alt} className={classNames({
      [classes.xs]: xs,
      [classes.sm]: sm,
      [classes.md]: md,
      [classes.lg]: lg,
  })} />
}

export default withStyles(imageIconStyles)(ImageIcon)