import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import prettyBytes from 'pretty-bytes'
import { Query, Mutation } from 'react-apollo'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from 'components/Button/Button.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import DateFormat from 'components/Info/DateFormat'
import LinkButton from 'components/Button/LinkButton'
import ConfirmationDialog from './ConfirmationDialog.jsx'
import ShowFileIcon from 'components/Dialog/ShowFileIcon'
import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'
import AudioButton from 'components/Button/AudioButton'
import Table from 'components/Table/AdvancedTable'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import Text from 'components/Typography/Text'
import { renderFileUpload, renderTextField, TableActionsToolbar, CustomCheckbox } from 'components/Form/Form'
import { setAlertSuccessMessage } from 'actions/alert'
import { extractErrorMessage } from 'helper/graphHelper'
import { isAudioFileItem } from 'helper/audioHelper'
import { canWriteBotiumFolder } from 'botium-box-shared/security/permissions'
import { openInNewTab } from 'helper/browserHelper'


import { FILESYSTEM_QUERY, FILESYSTEM_UPLOAD_CONTENT, FILESYSTEM_CREATE_FOLDER } from 'views/Settings/gql'

import config from 'config'
import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'

const _splitPath = (p) => {
  if (p) {
    if (p.join) return p
    if (p.split) return p.split('/')
  }
  return null
}

class MediaSelectionDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPath: _splitPath(props.initialPath) || ['testsets'],
      selectedFiles: [],
      selectedFolders: [],
      mediaSelectionError: null
    }
    this.state.basePath = this.state.currentPath
  }

  reset() {
    if (this.props.initialPath) {
      this.setState({
        selectedFiles: [],
        selectedFolders: [],
        currentPath: _splitPath(this.props.initialPath)
      })
    } else {
      this.setState({
        selectedFiles: [],
        selectedFolders: []
      })
    }
  }

  handleGoto(dirNames) {
    this.setState({ currentPath: dirNames, filter: '' })
  }

  isFileSelected(path) {
    const joinedPath = path.join('/')
    return this.state.selectedFiles.filter(c => c.join('/') === joinedPath).length > 0
  }

  handleFileSelection(path) {
    const { multiple, allowFileSelection } = this.props
    if (!multiple || !allowFileSelection) return

    const joinedPath = path.join('/')
    if (this.isFileSelected(path)) {
      const newState = this.state.selectedFiles.filter(c => c.join('/') !== joinedPath)
      this.setState({
        selectedFiles: newState
      })
    } else {
      this.setState({
        selectedFiles: [...this.state.selectedFiles, path]
      })
    }
  }

  isFolderSelected(path) {
    const joinedPath = path.join('/')
    return this.state.selectedFolders.filter(c => c.join('/') === joinedPath).length > 0
  }

  handleFolderSelection(path) {
    const { multiple, allowFolderSelection } = this.props
    if (!multiple || !allowFolderSelection) return

    const joinedPath = path.join('/')
    if (this.isFolderSelected(path)) {
      const newState = this.state.selectedFolders.filter(c => c.join('/') !== joinedPath)
      this.setState({
        selectedFolders: newState
      })
    } else {
      this.setState({
        selectedFolders: [...this.state.selectedFolders, path]
      })
    }
  }

  extractFileExtension(filename) {
    const splitted = filename.split('.')
    return `.${splitted[splitted.length - 1]}`
  }

  truncateName(filename, maxChars) {
    if (filename.length > maxChars) {
      const diff = filename.length - maxChars
      const index = (filename.length / 2) - (diff / 2)
      return filename.substr(0, index) + '...' + filename.substr(index + diff)
    }
    return filename
  }

  render() {
    const { classes, setAlertSuccessMessage, user, onOk, onCancel, extensionFilter, title, restrictPath, initialPath, multiple, allowFileSelection, allowFolderSelection, showError, clearError, ...rest } = this.props
    const { currentPath, basePath, selectedFiles, selectedFolders, mediaSelectionError } = this.state

    const hasWritePermission = () => {
      return canWriteBotiumFolder(user, currentPath)
    }

    let selectionHeader = title
    if (!selectionHeader) {
      if (allowFileSelection && allowFolderSelection && multiple) {
        selectionHeader = 'Select Files or/and Folders'
      } else if (allowFileSelection && allowFolderSelection && !multiple) {
        selectionHeader = 'Select File or/and Folder'
      } else if (allowFileSelection && multiple) {
        selectionHeader = 'Select Files'
      } else if (allowFileSelection && !multiple) {
        selectionHeader = 'Select File'
      } else if (allowFolderSelection && multiple) {
        selectionHeader = 'Select Folders'
      } else if (allowFolderSelection && !multiple) {
        selectionHeader = 'Select Folder'
      }
    }
    if (extensionFilter) {
      selectionHeader = `${selectionHeader} (only ${extensionFilter.join(', ')} allowed)`
    }

    let selectionText = 'Select'
    if (selectedFiles.length > 0 && selectedFolders.length > 0) {
      selectionText = `Select ${selectedFiles.length} file${selectedFiles.length > 1 ? 's' : ''} and ${selectedFolders.length} folder${selectedFolders.length > 1 ? 's' : ''}`
    } else if (selectedFiles.length > 0) {
      selectionText = `Select ${selectedFiles.length} file${selectedFiles.length > 1 ? 's' : ''}`
    } else if (selectedFolders.length > 0) {
      selectionText = `Select ${selectedFolders.length} folder${selectedFolders.length > 1 ? 's' : ''}`
    } else if (allowFolderSelection) {
      selectionText = 'Select folder'
    }

    const selectionEnabled = allowFolderSelection || selectedFiles.length > 0

    let selectionOk = null
    if (multiple) {
      selectionOk = () => {
        if (selectedFiles.length === 0 && selectedFolders.length === 0) {
          onOk({ selectedFiles: [], selectedFolders: [currentPath] })
        } else {
          onOk({ selectedFiles, selectedFolders })
        }
        this.reset()
      }
    } else if (allowFolderSelection) {
      selectionOk = () => {
        onOk({ selectedFiles: [], selectedFolders: [currentPath] })
        this.reset()
      }
    }

    return (<ConfirmationDialog
      closeDisabled
      maxWidth="lg"
      okDisabled={!selectionEnabled}
      okText={selectionText}
      onOk={selectionOk}
      onCancel={() => {
        onCancel && onCancel()
        this.reset()
      }}
      showError={showError || mediaSelectionError || null}
      clearError={() => {
        this.setState({ mediaSelectionError: null })
        if (clearError) clearError()
      }}
      extraButton={(selectedFiles.length > 0 || selectedFolders.length > 0) &&
        <Button secondary data-unique="btnMediaSelectionClear" onClick={() => this.setState({ selectedFiles: [], selectedFolders: [] })}>
          <ShowIcon icon="ban" /> Clear Selection
        </Button>
      }
      {...rest}
      >
        <GridContainer>
          {allowFileSelection && <GridItem xs={12} sm={3}>
            <Mutation
              mutation={FILESYSTEM_UPLOAD_CONTENT}
              onCompleted={data => {
                setAlertSuccessMessage('File(s) uploaded')
                if (this.refetch) this.refetch()
              }}
              onError={err => this.setState({ mediaSelectionError: `File upload failed: ${extractErrorMessage(err, true)}` })}
            >
              {(uploadContent, { loading, error }) => (
                <Form
                  onSubmit={values => {
                    uploadContent({
                      variables: {
                        path: currentPath,
                        filename: values.filename,
                        filecontent: values.filecontent,
                        unzip: false
                      }
                    })
                  }}
                  render={({
                    handleSubmit,
                    form: {
                      change,
                      reset
                    },
                    values,
                  }) => (
                    <form onSubmit={async event => {
                      await handleSubmit(event)
                      setTimeout(reset, 0)
                    }}>
                      <GridContainer>
                        <GridItem xs={11} >
                          <Text header>Upload file</Text>
                          <Field
                            name="fileupload"
                            component={renderFileUpload}
                            data-unique="fileMediaSelectionDialogUpload"
                            label="Select/Drop Files to upload to Botium"
                            values={values}
                            change={change}
                            multiple={false}
                            disabled={!hasWritePermission()}
                            onFileLoaded={async (filename, filecontent) => {
                              change('filename', filename)
                              change('filecontent', filecontent)
                              await handleSubmit()
                              setTimeout(reset, 0)
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </form>)}
              />)}
          </Mutation>
        </GridItem>}
        <GridItem xs={12} sm={allowFileSelection ? 9 : 12} borderLeft={!!allowFileSelection}>
          <Query
            query={FILESYSTEM_QUERY}
            variables={{ path: currentPath }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, refetch }) => {
              this.refetch = refetch

              const allContentList = [].concat((data && data.fileBrowserGetContent && data.fileBrowserGetContent.content) || []).filter(c => {
                if (!c.name || c.name === '..') return false
                if (!allowFileSelection && c.type === 'FILE') return false

                if (extensionFilter && extensionFilter.length > 0 && c.type === 'FILE') {
                  if (!extensionFilter.includes(this.extractFileExtension(c.name))) return false
                }
                return true
              })

              const _nestedUnique = (du) => this.props.dataUnique ? `${this.props.dataUnique}_${du}` : du

              return (
                <GridContainer>
                  <GridItem xs={12}>
                    <Text header>{selectionHeader}</Text>
                    <Table dense
                      dataUnique={_nestedUnique('tblMediaSelection')}
                      customActions={<GridContainer smallPadding>{[restrictPath ? <Text muted inline>Botium</Text> : <LinkButton gray data-unique={_nestedUnique('btnMediaSelectionGoto_root')} onClick={() => this.handleGoto([])}>Botium</LinkButton>].concat(
                        currentPath && currentPath.map((p, i) => {
                          const dirNames = currentPath.slice(0, i + 1)
                          if (restrictPath && !dirNames.join('/').startsWith(basePath.join('/'))) {
                            return <Text muted inline>{p}</Text>
                          }
                          return <LinkButton gray data-unique={_nestedUnique(`btnMediaSelectionGoto_${p}`)} onClick={() => this.handleGoto(dirNames)}>{p}</LinkButton>
                        }))
                        .reduce((result, item, i) => result.concat([<Text muted inline> / </Text>, item]), [])
                        .map((item, i) => <GridItem key={i} floatLeft noPadding >{item}</GridItem>)
                      }</GridContainer>}
                      tableHeaderColor="primary"
                      tableHead={[
                        { name: 'Name', orderByField: 'name', orderByDefault: 'asc' },
                        { name: 'Date Modified', orderByField: 'updatedAt' },
                        { name: 'Size', orderByField: 'size', right: true },
                        ''
                      ]}
                      tableData={({ orderByField, orderByOrder }) => {
                        if (error) return [[() => <Text danger>{extractErrorMessage(error)}</Text>, null, null, null]]
                        if (loading) return [[() => <LoadingIndicator />, null, null, null]]
                        if (allContentList.length === 0 && allowFileSelection) return [[() => <Text muted>No files or folders found</Text>, null, null, null]]
                        if (allContentList.length === 0 && !allowFileSelection) return [[() => <Text muted>No folders found</Text>, null, null, null]]

                        return _.orderBy(allContentList,
                          [(l) => {
                            if (orderByField === 'updatedAt') return l.updatedAt
                            if (orderByField === 'size') return _.isNil(l.size) ? -1 : l.size
                            return l.name.toLowerCase()
                          }],
                          [orderByOrder || 'asc']).map((a, index) => ([
                            () => <>
                              {a.type === 'FOLDER' && <GridContainer><GridItem middle>
                                {multiple && allowFolderSelection &&
                                  <CustomCheckbox useCheckbox dense
                                    input={{
                                      onChange: () => this.handleFolderSelection([...currentPath, a.name]),
                                      checked: this.isFolderSelected([...currentPath, a.name])
                                    }}
                                    key={`chkSelection_${a.name}`}
                                    data-unique={_nestedUnique(`chkFolderSelection_${a.name}`)}
                                  />
                                }
                                {multiple && !allowFolderSelection && allowFileSelection &&
                                  <CustomCheckbox useCheckbox dense disabled input={{}} />
                                }
                                <LinkButton gray data-unique={_nestedUnique(a.name)} onClick={() => this.handleGoto(a.goto || [...currentPath, a.name])}>
                                  <ShowIcon icon="folder" size="lg" /> {a.name}
                                </LinkButton>
                              </GridItem></GridContainer>}
                              {a.type === 'FILE' && <GridContainer fullWidth><GridItem md={12} middle>
                                {multiple && allowFileSelection &&
                                  <CustomCheckbox
                                    useCheckbox
                                    dense
                                    input={{
                                      onChange: () => this.handleFileSelection([...currentPath, a.name]),
                                      checked: this.isFileSelected([...currentPath, a.name])
                                    }}
                                    key={`chkSelection_${a.name}`}
                                    data-unique={_nestedUnique(`chkFileSelection_${a.name}`)}
                                  />
                                }
                                {multiple && !allowFileSelection && allowFolderSelection &&
                                  <CustomCheckbox useCheckbox dense disabled input={{}} />
                                }
                                  <GridContainer fullWidth>
                                    <GridItem md={10}>
                                      <Tooltip title="Select File">
                                        <LinkButton gray data-unique={_nestedUnique(a.name)} onClick={() => onOk({ selectedFiles: [[...currentPath, a.name]], selectedFolders: [] })} >
                                          <ShowFileIcon item={a} size="lg" /> {a.name}
                                        </LinkButton>
                                      </Tooltip>  
                                    </GridItem>
                                    <GridItem md={2} right>
                                      <Tooltip title="Download File">
                                        {!multiple && allowFileSelection && <LinkButton gray data-unique={_nestedUnique(`${a.name}_Select`)} onClick={async () => {
                                          try {
                                            openInNewTab(`${config.api.base}/filebrowser/${currentPath.join('/')}/${a.name}`)
                                          } catch (err) {
                                            this.setState({ mediaSelectionError: extractErrorMessage(err, true) })
                                          }
                                        }}>
                                          <ShowIcon icon="download" size="lg" />
                                        </LinkButton>}
                                      </Tooltip>
                                    </GridItem>
                                  </GridContainer>
                                  
                               
                                
                              </GridItem></GridContainer>}
                            </>,
                            () => <Text>{a.updatedAt && <DateFormat fromNow>{a.updatedAt}</DateFormat>}</Text>,
                            () => <Text>{!_.isNil(a.size) ? prettyBytes(a.size) : ''}</Text>,
                            () => isAudioFileItem(a) ?
                              <AudioButton
                                data-unique={`btnMediaSelectionDialogPlayFile_${a.name}`}
                                small
                                audioSrc={`${config.api.base}/filebrowser/${currentPath.join('/')}/${a.name}`}
                                mimeType={a.mimeType}
                                onError={err => this.setState({ mediaSelectionError: `Playing audio stream failed: ${extractErrorMessage(err, true)}` })}
                              /> : null
                          ]))
                      }}
                      onRefresh={() => refetch()}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} left largePaddingLeft>
                    {hasWritePermission() && <>
                      <Mutation mutation={FILESYSTEM_CREATE_FOLDER}>
                        {(createFolder) => (
                          <Form onSubmit={async (values, form) => {
                            try {
                              await createFolder({
                                variables: {
                                  path: currentPath,
                                  dirname: values.dirname
                                }
                              })
                              setAlertSuccessMessage('Folder created')
                              form.initialize({})
                              refetch()
                            } catch (err) {
                              this.setState({ mediaSelectionError: `Folder creation failed: ${extractErrorMessage(err, true)}` })
                            }
                          }}
                            render={({
                              handleSubmit,
                              submitting,
                              pristine,
                              invalid
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <TableActionsToolbar>
                                  <Field
                                    name="dirname"
                                    component={renderTextField}
                                    placeholder="Enter new Folder Name"
                                    data-unique="txtMediaSelectionNewFolderName"
                                    noLabel
                                  />
                                  <Button type="submit" disabled={submitting || pristine || invalid} round justIcon data-unique="btnMediaSelectionCreateFolder">
                                    {submitting && <LoadingIndicator />}
                                    {!submitting && <ShowIcon icon="folder-plus" />}
                                  </Button>
                                </TableActionsToolbar>
                              </form>
                            )}
                          />
                        )}
                      </Mutation>
                    </>}
                  </GridItem>
                </GridContainer>
              )
            }}
          </Query>
        </GridItem>
      </GridContainer>
    </ConfirmationDialog>)
  }
}

MediaSelectionDialog.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string.isRequired,
  initialPath: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  extensionFilter: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  allowFileSelection: PropTypes.bool,
  allowFolderSelection: PropTypes.bool,
  restrictPath: PropTypes.bool
}
MediaSelectionDialog.defaultProps = {
  multiple: false,
  allowFileSelection: false,
  allowFolderSelection: false
}

export default connect(
  state => ({ user: state.token.user }),
  { setAlertSuccessMessage },
)(withStyles(settingsStyle, { withTheme: true })(MediaSelectionDialog))
