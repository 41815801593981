import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import expansionStyle from 'assets/jss/material-dashboard-react/components/expansionStyle'
import ExpansionPanelDetailsCore from '@material-ui/core/ExpansionPanelDetails'

function ExpansionPanelDetails({ ...props }) {
  const { classes, ...rest } = props
  return <ExpansionPanelDetailsCore classes={{
    root: classes.ExpansionPanelDetailsroot,
    
  }} {...rest} />
}

export default withStyles(expansionStyle)(ExpansionPanelDetails)
