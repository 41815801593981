  import customInputStyle from './customInputStyle'

const selectStyle = theme => ({
  ...customInputStyle(theme),
  

  inputLabel: {
    paddingLeft: 25,  
    color: theme.colors.inputlabel,
    fontWeight: 500,
    position: 'relative',
    top: 12
  },

  inputposition: {
    paddingBottom: 23,
    '&,&:hover,&:focus': {
      backgroundColor: 'transparent !important',
      cursor: 'normal',
    },
  },

  paper: {
    padding: '0 10px 0 10px',
    background: theme.colors.backgroundPrimary,
    borderRadius: 6,
    minWidth: '350px !important',
  },

  underline: {
    margin: '5px 0 !important',
    '&:after': {
      borderWidth: 0
    },
    '&:before': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
  },
 
  filterableSelect: {
    fontSize: 13,
    paddingRight: 5,
    marginRight: '0!Important',
    paddingLeft: '0!Important',
    height: 30,
    fontWeight: 500,
    color: theme.colors.primary,
    '&:focus': {
      backgroundColor: 'transparent !important',
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 5
    },
  },
  filterableSelectMultiple: {
    marginTop: -3,
    marginLeft: 3
  },

  inputFilter: {
    '&:after': {
      borderWidth: 0
    },
    '&:before': {
      borderWidth: 0,
      borderBottom: '0 !Important'
    },
    '&:hover': {
      '&::after': {
        borderWidth: 0,
        borderBottom: '0px solid transparent !Important'
      },
      '&:before': {
        borderWidth: 0,
        borderBottom: '0px solid transparent !Important'
      },
    }
  },

  

  filterableSelectRoot: {
    height: 45,  
    position: 'relative',
    border: `1px solid ${theme.colors.borderColor}`,
    borderRadius: 6,
    color: theme.colors.primary,
    '&:before': {
      borderWidth: 0,
    },
    '&:hover': {
      border: `1px solid ${theme.colors.borderColorhover}`,
      '&:before': {
        borderWidth: '0 !Important',
      }
    },
    '& ul': {
      position: 'relative',
      top: -8,
      left: 3,
      '& span': {
        fontSize: '13px !important',
        fontWeight: 500,
        color: theme.colors.primary,
      }
    },

  },

  filterableSelectRootdisabled: {
    '& svg': {
      opacity: 0.5,
    }
  },

  filterableSelectdisabled: {
      opacity: '0.5!Important',
  },

  selectMenuItem: {
    paddingBottom: 18
  },

  multipleSelectInputText: {
    color: theme.colors.primary,
    height: 43,
    fontSize: 13,
    boxSizing: 'border-box',
    fontWeight: 500,
    paddingLeft: 12,
    paddingTop: 8
  },

  selectMenuItemWithImage: {
    paddingBottom: 23
  },

  filterableSelectIcon: {
    marginRight: 10,
    color: theme.colors.primary,
  },

  filterableSelectClearButtonString: {
    position: 'absolute',
    right: 25,
    top: 22,
  },
  clearButtonDesabled: {
    display: 'none'
  },
  filterableSelectClearButtonArray: {
    position: 'absolute',
    right: 25,
    top: 24,
  },
  filterableSelectClearButtonIcon: {
    color: theme.colors.primary
  },
  noEntriesText: {
    position: 'absolute',
    top: 32,
    left: 20,
    opacity: `0.3 !Important`,
    color: theme.colors.primary,
    fontWeight: 500
  },



  advancedSelect: {
    width: '95%',
    fontSize: 13,
    paddingRight: 5,
    height: 35,
    position: 'relative',
    //padding: '11px 0 0 20px',
    fontWeight: 500,
    top: '-5px',
    
    color: theme.colors.common.primary,
    '&:focus': {
      //backgroundColor: 'transparent !important',
    },
  },
  advancedselectselectMenu: {
    
  },
  advancedSelectRoot: {
    height: 43,
    margin: '5px 0 !Important',   
    position: 'relative',
    border: `1px solid ${theme.colors.borderColor}`,
    color: theme.colors.primary,
    borderRadius: 6,
    '&:before': {
      borderWidth: 0,
    },
    '&:hover': {
      border: `1px solid ${theme.colors.borderColorhover}`,
      '&:before': {
        borderWidth: '0 !Important',
      }
    },
    '& div': {
      color: theme.colors.primary,
    }
  },
  advancedSelectIcon: {
      marginRight: 10,
      color: theme.colors.primary,
  },
  visuallyhidden: {
    overflow: 'hidden', 
    position: 'absolute', 
    border: 0, 
    clip: 'rect(0 0 0 0)', 
    height: 1, 
    margin: -1, 
    padding: 0, 
    width: 1 
  },
  selectMenuItemMarginTopBottom: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 25,
      height: 20
  },
  filterMenuItem: {
    padding: ' 20px 0 25px 8px',
    '&:hover': {
      backgroundColor: 'transparent !important',
    }
  }
})

export default selectStyle
