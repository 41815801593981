import React from 'react'

import _ from 'lodash'
import {withStyles} from '@material-ui/core/styles'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'

import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Table from 'components/Table/AdvancedTable'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'

const Coverage = withStyles(testsessionsStyle)((props) => {
  const {
    testsession,
    transitionRouteCoverageAvailable,
    transitionRouteCoveragePercent,
    transitionRouteCoverageCount,
    transitionRouteCoverageStruct,
    intentCoverageAvailable,
    intentCoveragePercent,
    intentCoverageCount,
    intentCoverageStruct,
  } = props

  const renderTransitions = () => {
    return <GridContainer>
      <GridItem xs={12} sm={12}>
        <Text header>Transition</Text>
        <Text mlg data-unique="txtDialogflowCxCoverageTransitionCoverage" >
          {Math.round(transitionRouteCoveragePercent)}%, {transitionRouteCoverageCount}/{transitionRouteCoverageStruct.transitionRouteCoverage.length} Coverage
        </Text>
      </GridItem>
      <GridItem xs={12} sm={12}>
        <Table
          tableHeaderColor="primary"
          tableHead={[
            { name: 'Source', orderByField: 'source' },
            { name: 'Intent/Condition/Event' },
            { name: 'Target', orderByField: 'target' },
            { name: 'Covered', orderByField: 'covered' }
          ]}
          tableData={({ orderByField, orderByOrder }) => {
            return _.orderBy(transitionRouteCoverageStruct.transitionRouteCoverage,
              [(entry) => {
                if (orderByField === 'source') return entry.source.toLowerCase()
                if (orderByField === 'target') return entry.target?.toLowerCase()
                if (orderByField === 'covered') return (entry.reachable ? '1' : 0) + (entry.covered ? '1' : '0' )
                return entry.source.toLowerCase()
              }],
              [orderByOrder || 'asc']).map(entry => {
              return [
                () => <Text>{entry.source}</Text>,
                () => <>
                <Tooltip title={entry.intentId ? `Intent: ${intentCoverageStruct?.intentCoverage?.find(c => c.id === entry.intentId)?.displayName}` : null}>
                  <ShowIcon icon={entry.intentId ? 'check' : 'minus'} />
                </Tooltip>
                  &nbsp;
                <Tooltip title={entry.condition ? `Condition: ${entry.condition}` : null}>
                  <ShowIcon icon={entry.condition ? 'check' : 'minus'} />
                </Tooltip>
                  &nbsp;
                <Tooltip title={entry.event ? `Event: ${entry.event}` : null}>
                  <ShowIcon icon={entry.event ? 'check' : 'minus'} />
                </Tooltip>
                </>,
                () => <Text>{entry.target}</Text>,
                () => <>
                  <Text icon="exclamation-triangle" inline lg success={entry.covered} danger={!entry.covered}>&nbsp;</Text>
                  <Text inline>{!entry.reachable ? 'Not reachable' : entry.covered ? 'Yes' : 'No'}</Text>
                </>
              ]
            })}
          }
        />

      </GridItem>
    </GridContainer>
  }
  const renderIntents = () => {
    return <GridContainer>
      <GridItem xs={12} sm={12}>
        <Text header>Intents</Text>
        <Text mlg data-unique="txtDialogflowCxCoverageIntentCoverage">
          {Math.round(intentCoveragePercent)}%, {intentCoverageCount}/{intentCoverageStruct.intentCoverage.length} Coverage
        </Text>
      </GridItem>
      <GridItem xs={12} sm={12}>
        <Table
          tableHeaderColor="primary"
          tableHead={[
            { name: 'Intent', orderByField: 'displayName', orderByDefault: 'asc' },
            { name: 'Covered', orderByField: 'covered' }
          ]}
          tableData={({ orderByField, orderByOrder }) => {
            return _.orderBy(intentCoverageStruct.intentCoverage,
              [(entry) => {
                if (orderByField === 'displayName') return entry.displayName.toLowerCase()
                if (orderByField === 'covered') return (entry.used ? '1' : '0') + (entry.reachable ? '1' : '0') + (entry.covered ? '1' : '0' )
                return entry.displayName.toLowerCase()
              }],
              [orderByOrder || 'asc']).map(entry => {
              return [
                () => <Text>{entry.displayName}</Text>,
                () => <>
                  <Text icon="exclamation-triangle" inline lg success={entry.covered} danger={!entry.covered}>&nbsp;</Text>
                  <Text inline>{!entry.used ? 'Not included in transition' : !entry.reachable ? 'Not reachable' : entry.covered ? 'Yes' : 'No'}</Text>
                </>
              ]
            })}
          }
        />

      </GridItem>
    </GridContainer>
  }
  if (intentCoverageAvailable && transitionRouteCoverageAvailable) {
    return (
      <CustomTabsSecondary
        name={`tabTestSessionsCustomTab_${testsession.id}_Coverage`}
        tabs={[
          {
            tabName: 'Transitions',
            tabContent: renderTransitions(),
            locationPrefix: `/regression/projects/view/${testsession.testProject.id}/results/${testsession.id}/dialogflowcxtransitioncoverage`,
            dataUnique: 'tabDialogflowCxCoverageTransitions'
          },
          {
            tabName: 'Intents',
            tabContent: renderIntents(),
            locationPrefix: `/regression/projects/view/${testsession.testProject.id}/results/${testsession.id}/dialogflowcxintentcoverage`,
            dataUnique: 'tabDialogflowCxCoverageIntents'
          }
        ]}
      />)
  } else if (intentCoverageAvailable) {
    return renderIntents()
  } else if (transitionRouteCoverageAvailable) {
    return renderTransitions()
  } else {
    return 'No coverage data'
  }
})

export default (testsession) => {
  let intentCoverageStruct = testsession.aggregatedResults.find(r => r.aggregatorName === 'botium:coverage:dialogflowcxintent')?.details
  let transitionRouteCoverageStruct = testsession.aggregatedResults.find(r => r.aggregatorName === 'botium:coverage:dialogflowcxtransitionroutes')?.details
  let intentCoverageCount
  let intentCoveragePercent
  let transitionRouteCoveragePercent
  let transitionRouteCoverageCount
  let intentCoverageAvailable
  let transitionRouteCoverageAvailable

  try {
    if (intentCoverageStruct) {
      intentCoverageStruct = JSON.parse(intentCoverageStruct)
      if (intentCoverageStruct?.intentCoverage?.length) {
        // If the intent is not added yet (to a transition) then we ignore it from results
        // intentCoverageStruct.intentCoverage = intentCoverageStruct.intentCoverage.filter(entry => entry.used)
        intentCoverageAvailable = true
        intentCoverageCount = intentCoverageStruct.intentCoverage.filter(c => c.covered).length
        intentCoveragePercent = intentCoverageCount / intentCoverageStruct.intentCoverage.length * 100
      } else {
        console.log(`No intent coverage, empty aggregated data`)
      }
    } else {
      console.log(`No intent coverage, no aggregated data`)
    }
  } catch (err) {
    console.log(`No intent coverage, invalid format: ${intentCoverageStruct}`)
    intentCoverageStruct = null
  }

  try {
    if (transitionRouteCoverageStruct) {
      transitionRouteCoverageStruct = JSON.parse(transitionRouteCoverageStruct)
      const { transitionRouteCoverage, flowIdToFlow, pageIdToPage } = transitionRouteCoverageStruct
      if (transitionRouteCoverage?.length) {
        transitionRouteCoverageAvailable = true
        transitionRouteCoverageCount = transitionRouteCoverage.filter(c => c.covered).length
        transitionRouteCoveragePercent = transitionRouteCoverageCount / transitionRouteCoverage.length * 100
        for (const route of transitionRouteCoverage) {
          let source
          try {
            if (route.flowId) {
              source = `${flowIdToFlow[route.flowId].displayName} - Start Page`
            } else if (route.pageId) {
              const page = pageIdToPage[route.pageId]
              source = `${flowIdToFlow[page.flowId].displayName} - ${page.displayName}`
            }
          } catch (err) {
            source = (err.message || err) + JSON.stringify(route)
          }

          let target
          try {
            if (route.targetFlowId) {
              target = `Flow: ${flowIdToFlow[route.targetFlowId].displayName}`
            } else if (route.targetPageId) {
              target = `Page: ${pageIdToPage[route.targetPageId].displayName}`
            } else if (route.targetCommand) {
              target = `Command: ${route.targetCommand}`
            }
          } catch (err) {
            target = (err.message || err) + JSON.stringify(route) + JSON.stringify(pageIdToPage)
          }
          route.source = source
          route.target = target
        }
      } else {
        console.log(`No transition coverage, empty aggregated data`)
      }
    } else {
      console.log(`No transition coverage, no aggregated data`)
    }
  } catch (err) {
    console.log(`No transition coverage, invalid format: ${intentCoverageStruct}`)
    transitionRouteCoverageStruct = null
  }

  let coveragePercent
  let available = true
  if (intentCoverageAvailable && transitionRouteCoverageAvailable) {
    coveragePercent = (intentCoveragePercent + transitionRouteCoveragePercent) / 2
  } else if (intentCoverageAvailable) {
    coveragePercent = intentCoveragePercent
  } else if (transitionRouteCoverageAvailable) {
    coveragePercent = transitionRouteCoveragePercent
  } else {
    available = false
  }

  const props = {
    testsession,
    intentCoverageAvailable, intentCoverageCount, intentCoverageStruct, intentCoveragePercent,
    transitionRouteCoverageAvailable, transitionRouteCoverageCount, transitionRouteCoverageStruct, transitionRouteCoveragePercent,
  }

  return {
    available,
    name: 'dialogflowcx-coverage',
    label: `Coverage (${Math.round(coveragePercent)}%)`,
    render: () => {
      return <Coverage
        {...props}
      />
    }
  }
}
