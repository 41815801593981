import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
import queryString from 'query-string'
// @material-ui/core components
import { withApollo, Query } from 'react-apollo'
import CheckIcon from '@material-ui/icons/Check'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import Button from 'components/Button/Button'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import RefreshIcon from '@material-ui/icons/Refresh'
// core components
import LinkButton from 'components/Button/LinkButton'
import Chip from 'components/Chip/Chip'
import Tooltip from 'components/Tooltip/NativeTooltip'
import ErrorFormat from 'components/Info/ErrorFormat'
import ShowIcon from 'components/Icon/ShowIcon'
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle'

import { clearTableSettings, setTableSettings, defaultTableSettings } from 'actions/table'
import { CustomCheckbox, CustomTextField, TableActionsToolbar } from 'components/Form/Form'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

export const renderCell = (classes, prop, { truncateChip, truncateText }) => {
  if (prop && prop.href) {
    return <NavLink className={classes.tableCellBodyLink} to={prop.href} style={{ display: 'inline-block', minWidth: prop.width ? prop.width : 0 }} data-entry={prop.dataEntry}>{renderCellValue(prop, { truncateChip, truncateText })}</NavLink>
  } else if (prop && prop.tags) {
    if (prop.tags && prop.tags.length > 0) {
      return prop.tags.map((t, i) => {
        return <Chip truncate={truncateChip} key={i} label={t} />
      })
    } else {
      return null
    }
  } else {
    return renderCellValue(prop, { truncateChip, truncateText })
  }
}

const renderCellValue = (prop, { truncateChip, truncateText }) => {
  let cellValue =
    prop && _.isObject(prop) && !_.isArray(prop) && !_.isFunction(prop)
      ? prop.value
      : prop
  if (_.isFunction(cellValue)) {
    return cellValue()
  } else if (_.isBoolean(cellValue)) {
    if (cellValue) return <CheckIcon color="primary" />
    else return null
  } else if (_.isArray(cellValue)) {
    return cellValue.map((c, i) => {
      if (c.href) {
        return <NavLink to={c.href} key={i}><Chip tooltip={c.value} truncate={truncateChip} label={c.value} clickable/></NavLink>
      } else {
        return <Chip tooltip={c} truncate={truncateChip} label={c} key={i}/>
      }
    })
  } else if (_.isString(cellValue)) {
    if (truncateText && cellValue.length > truncateText) {
      return <Tooltip title={cellValue}>{_.truncate(cellValue, { length: truncateText })}</Tooltip>
    } else {
      return cellValue
    }
  } else {
    return cellValue
  }
}

class AdvancedCustomTablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0)
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    )
  }

  render() {
    const { classes, count, hasMore, pageLoading, page } = this.props

    return (
      <div className={classes.tablePaginationActions}>
        <Button
          onClick={this.handleFirstPageButtonClick}
          disabled={pageLoading || page === 0}
          aria-label="First Page"
          round
          justIcon
        >
          <FirstPageIcon />
        </Button>
        <Button
          onClick={this.handleBackButtonClick}
          disabled={pageLoading || page === 0}
          aria-label="Previous Page"
          round
          justIcon
        >
          <KeyboardArrowLeft />
        </Button>
        <Button
          onClick={this.handleNextButtonClick}
          disabled={pageLoading || !hasMore}
          aria-label="Next Page"
          round
          justIcon
        >
          <KeyboardArrowRight />
        </Button>
        {count >= 0 && (
          <Button
            onClick={this.handleLastPageButtonClick}
            disabled={pageLoading || !hasMore}
            aria-label="Last Page"
            round
            justIcon
          >
            <LastPageIcon />
          </Button>
        )}
      </div>
    )
  }
}

AdvancedCustomTablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number,
  hasMore: PropTypes.bool,
  onChangePage: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
const AdvancedCustomTablePaginationActionsWrapped = withStyles(tableStyle)(
  AdvancedCustomTablePaginationActions,
)

class AdvancedCustomTableToolbar extends React.Component {
  handleFilterChange = _.debounce(filterText => {
    const { onFilterChange } = this.props
    if (onFilterChange) {
      onFilterChange(filterText)
    }
  }, 500)

  render() {
    const { customActions, customActionsRight, filterText, onRefresh, onFilterChange, dataUnique, addNoPaddingTableActionsToolbar } = this.props
    return (
      <TableActionsToolbar addNoPaddingTableActionsToolbar={addNoPaddingTableActionsToolbar} customActionsRight={customActionsRight ? _.isFunction(customActionsRight) ? customActionsRight({ onRefresh }) : customActionsRight : null}  customActions={customActions ? _.isFunction(customActions) ? customActions({ onRefresh }) : customActions : null}>
        {onFilterChange && (
          <CustomTextField
            input={{
              name: 'txtServerSidePagingTableFilterInput',
              placeholder: 'Filter',
              title: 'filter',
              onChange: ev => this.handleFilterChange(ev.target.value)
            }}
            defaultValue={filterText}
            data-unique={dataUnique && `${dataUnique}_txtFilter`}
          />
        )}
        {onRefresh && (
          <Button
            noMarginRight
            smallTop
            tertiary
            aria-label="Refresh"
            title="Refresh"
            onClick={onRefresh} data-unique={dataUnique && `${dataUnique}_btnRefresh`}>
            <RefreshIcon />
          </Button>
        )}
      </TableActionsToolbar>
    )
  }
}

AdvancedCustomTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  customActions: PropTypes.any,
  customActionsRight: PropTypes.any,
  filterText: PropTypes.string,
  onRefresh: PropTypes.func,
  onFilterChange: PropTypes.func,
  dataUnique: PropTypes.string
}

const AdvancedCustomTableToolbarWrapped = withStyles(tableStyle)(
  AdvancedCustomTableToolbar,
)

class ServerSidePagingTable extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      pageData: [],
      totalCount: 0,
      hasMore: false,
      pageLoading: true,
      pageErr: null,
      selectedItemIds: [],
      allItemIds: []
    }
  }

  componentDidMount() {
    this.getPageData()
  }

  handleSelection = (id) => {
    const { selectedItemIds } = this.state
    const { onSelectionChange } = this.props

    if (selectedItemIds.includes(id)) {
      selectedItemIds.splice(selectedItemIds.indexOf(id), 1)
    } else {
      selectedItemIds.push(id)
    }
    this.setState({ selectedItemIds })
    onSelectionChange && onSelectionChange(selectedItemIds)
  }

  setTableSettings = (newSettings) => {
    const { name, dataUnique, settings, setTableSettings } = this.props

    setTableSettings(name || dataUnique || window.location.pathname, {
      ...settings,
      ...newSettings
    })
  }

  handleChangePage = (event, page) => {
    const { settings, onPageChange, onSelectionChange } = this.props

    settings.page = page
    this.setTableSettings({ page: 0 })
    this.setState({ selectedItemIds: [] })
    onSelectionChange && onSelectionChange([])
    if (onPageChange) {
      onPageChange(settings.page * settings.rowsPerPage, settings.rowsPerPage + 1, settings.filterText)
    } else {
      this.getPageData()
    }
  }

  handleChangeRowsPerPage = event => {
    const { settings, onPageChange, onSelectionChange } = this.props

    settings.rowsPerPage = event.target.value
    this.setTableSettings({ rowsPerPage: event.target.value })
    onSelectionChange && onSelectionChange([])
    if (onPageChange) {
      onPageChange(settings.page * settings.rowsPerPage, event.target.value + 1, settings.filterText)
    } else {
      this.getPageData()
    }
  }

  handleFilterChange = filterText => {
    const { settings, onPageChange, onSelectionChange } = this.props

    settings.filterText = filterText
    settings.page = 0
    this.setTableSettings({ page: 0, filterText })
    onSelectionChange && onSelectionChange([])
    if (onPageChange) {
      onPageChange(settings.page * settings.rowsPerPage, settings.rowsPerPage + 1, filterText)
    } else {
      this.getPageData()
    }
  }

  getPageData(withPageLoading = true) {
    const { client, gqlQuery, gqlCountQuery, gqlVariables, gqlOrderByVariables, tableData, settings, mapSelectIds, onSelectionChange } = this.props

    this.setState({ selectedItemIds: [] })
    onSelectionChange && onSelectionChange([])

    if (withPageLoading) {
      this.setState({
        pageLoading: true
      })
    }

    const query = Object.assign({}, gqlQuery, {
      variables: _.merge({},
        gqlVariables ? (gqlVariables(settings.filterText) || {}) : { filterString: settings.filterText || '' },
        settings.orderByField ? (gqlOrderByVariables ? gqlOrderByVariables(settings.orderByField, settings.orderByOrder) : { orderBy: `${settings.orderByField}_${settings.orderByOrder.toUpperCase()}` }) : {},
        {
          skip: settings.page * settings.rowsPerPage,
          first: settings.rowsPerPage
        },
        gqlQuery.variables || {}),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    })

    const countQuery = Object.assign({}, gqlCountQuery, {
      variables: _.merge({},
        gqlVariables ? (gqlVariables(settings.filterText) || {}) : { filterString: settings.filterText || '' },
        gqlCountQuery.variables || gqlQuery.variables || {}),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    })

    client.query(query).then(({ data }) => {
      const refresh = () => setTimeout(() => this.getPageData(false), 0)
      const pageData = tableData(data, refresh)
      if (mapSelectIds) {
        this.setState({
          allItemIds: mapSelectIds(data)
        })
      }
      client.query(countQuery).then(({ data }) => {
        const countValue = gqlCountQuery.countValue(data)
        this.setState({
          pageData: pageData,
          hasMore: countValue > (settings.page + 1) * settings.rowsPerPage,
          pageLoading: false,
          totalCount: countValue
        })
      }, error => {
        this.setState({
          pageErr: error
        })
      })

    }, error => {
      this.setState({
        pageErr: error
      })
    })
  }

  render() {
    const { pageData, hasMore, totalCount, pageLoading, pageErr } = this.state
    const {
      classes,
      settings,
      tableHead,
      tableHeaderColor,
      onPageChange,
      mapSelectIds,
      onSelectionChange,
      maxCount,
      disableHeader,
      disableFilter,
      disableOrderBy,
      disableFooter,
      disableFooterIfNotHasMore,
      disablePageSize,
      dense,
      customActions,
      customActionsRight,
      truncateChip = 200,
      truncateText = 100,
      rowClassName,
      gqlCountQuery,
      gqlVariables,
      gqlQuery,
      noDataComponent,
      noDataAllComponent,
      addNoPaddingTableActionsToolbar,
    } = this.props
    const dataUnique = this.props.dataUnique || this.props.name

    const countAllQuery = Object.assign({}, gqlCountQuery, {
      variables: _.merge({},
        gqlVariables ? (gqlVariables() || {}) : { filterString: '' },
        gqlCountQuery.variables || gqlQuery.variables || {}),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    })

    return (
      <React.Fragment>
        {!disableHeader && (
          <AdvancedCustomTableToolbarWrapped
            onRefresh={() => this.getPageData()}
            customActions={customActions}
            addNoPaddingTableActionsToolbar={addNoPaddingTableActionsToolbar}
            customActionsRight={customActionsRight}
            filterText={settings.filterText}
            onFilterChange={disableFilter ? null : filterText => this.handleFilterChange(filterText)}
            dataUnique={dataUnique}
          />
        )}
        <div className={classes.tableResponsive}>
          <Table className={classNames({ [classes.table]: true, [classes.tableDense]: dense })} data-unique={dataUnique} data-length={(!pageErr && !pageLoading && pageData.length) || 0} data-loading={pageLoading ? 'loading' : ''}>
            {tableHead !== undefined ? (
              <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
                <TableRow>
                {mapSelectIds && <TableCell
                        className={classNames({
                          [classes.tableCell]: true,
                          [classes.tableHeadCell]: true,
                        })}
                        data-unique={dataUnique && `${dataUnique}`}
                      >
                   <CustomCheckbox
                    useCheckbox
                    dense
                    data-unique={`chk${dataUnique}_SelectAll`}
                    input={{
                      onChange: () => {
                          if (this.state.selectedItemIds.length === this.state.allItemIds.length) {
                            this.setState({
                              selectedItemIds: []
                            })
                          } else {
                            this.setState({
                              selectedItemIds: this.state.allItemIds.map(a => a)
                            })
                          }
                          onSelectionChange && setTimeout(() => onSelectionChange(this.state.selectedItemIds), 0)
                      },
                      checked: this.state.selectedItemIds.length === this.state.allItemIds.length
                    }}
                  /></TableCell>}
                  {tableHead.map((prop, colIndex) => {
                    return (
                      <TableCell
                        className={classNames({
                          [classes.tableCell]: true,
                          [classes.tableHeadCell]: true,
                          [classes.tableCellRight]: !!prop.right,
                          [classes.nameColumn]: !!prop.nameColumn,
                          [classes.tableCellWidthLarge]: prop.width === 'large',
                          [classes.tableCellWidthMedium]: prop.width === 'medium',
                          [classes.tableCellWidthSmall]: prop.width === 'small',

                        })}
                        key={colIndex}
                        data-unique={dataUnique && `${dataUnique}_H${colIndex}`}
                      >
                        {!disableOrderBy && prop.orderByField && <LinkButton data-click="false" onClick={() => {
                          if (settings.orderByField === prop.orderByField) {
                            if (settings.orderByOrder === 'asc') {
                              this.setTableSettings({ orderByOrder: 'desc', page: 0 })
                            } else {
                              this.setTableSettings({ orderByOrder: 'asc', page: 0 })
                            }
                          } else {
                            this.setTableSettings({ orderByField: prop.orderByField, orderByOrder: prop.orderByDirection || 'asc', page: 0 })
                          }
                          setTimeout(() => this.getPageData(false), 0)
                        }}>
                          {prop.name}
                          {settings.orderByField && settings.orderByField === prop.orderByField && <>
                            {settings.orderByOrder === 'desc' && <ShowIcon icon="sort-down" />}
                            {settings.orderByOrder === 'asc' && <ShowIcon icon="sort-up" />}
                          </>}
                          {settings.orderByField && settings.orderByField !== prop.orderByField && <ShowIcon icon="sort-up" hidden />}
                          {!settings.orderByField && prop.orderByDefault === 'desc' && <ShowIcon icon="sort-down" />}
                          {!settings.orderByField && prop.orderByDefault === 'asc' && <ShowIcon icon="sort-up" />}
                        </LinkButton>}

                        {(disableOrderBy || !prop.orderByField) && prop.onClick && <LinkButton onClick={prop.onClick}>{prop.name}</LinkButton>}
                        {(disableOrderBy || !prop.orderByField) && !prop.onClick && (prop.name || prop)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {!pageErr && pageLoading && <TableRow><TableCell className={classes.tableCell} classes={{ body: classes.tableCellBody }} colSpan={mapSelectIds ? tableHead && tableHead.length + 1 : tableHead && tableHead.length}><LoadingIndicator box large /></TableCell></TableRow>}

              {!pageErr && !pageLoading && pageData.length === 0 && <Query {...countAllQuery}>
                {({ loading, error, data }) => {
                  const countAllValue = gqlCountQuery.countValue(data)
                  if (countAllValue) {
                    return <TableRow><TableCell  className={classes.tableCellNoListitem} classes={{ body: classes.tableCellBody }} colSpan={mapSelectIds ? tableHead && tableHead.length + 1 : tableHead && tableHead.length} style={{textAlign: 'center'}}>{noDataComponent || 'No list items match the filter criteria!'}</TableCell></TableRow>
                  }
                  if (noDataAllComponent) {
                    return <TableRow><TableCell  className={classes.tableCellNoListitem} classes={{ body: classes.tableCellBody }} colSpan={mapSelectIds ? tableHead && tableHead.length + 1 : tableHead && tableHead.length} style={{textAlign: 'center'}}>{noDataAllComponent}</TableCell></TableRow>
                  }

                  return null
                }}
              </Query>}
              {pageErr && <TableRow><TableCell className={classes.tableCell} classes={{ body: classes.tableCellBody }} colSpan={mapSelectIds ? tableHead && tableHead.length + 1 : tableHead && tableHead.length}><ErrorFormat err={pageErr} /></TableCell></TableRow>}
              {!pageErr && !pageLoading && pageData.map((prop, rowIndex) => {
                if (_.isArray(prop)) {
                  return (
                    <TableRow key={rowIndex} data-unique={dataUnique && `${dataUnique}_${rowIndex}`} className={classNames({
                      [classes.projectTable]: rowClassName && rowClassName(prop) === 'projectTable',
                      [classes.latesResultseparation]: mapSelectIds && rowClassName && rowClassName(prop) === 'projectTable',
                      [classes.latesResultseparationNoCheckbox]: !mapSelectIds && rowClassName && rowClassName(prop) === 'projectTable',
                    })}>
                      {mapSelectIds && <TableCell
                        className={classNames({
                          [classes.tableCell]: true,
                          [classes.tableHeadCell]: true,
                        })}
                        data-unique={dataUnique && `${dataUnique}`}
                      >
                   <CustomCheckbox
                    useCheckbox
                    dense
                    data-unique={`chk${dataUnique}_Select_${rowIndex}`}
                    input={{
                      onChange: () => this.handleSelection(this.state.allItemIds[rowIndex]),
                      checked: this.state.selectedItemIds.filter(id => this.state.allItemIds[rowIndex] === id).length > 0
                    }}
                  /></TableCell>}
                      {prop.map((prop, colIndex) => {
                        const head = tableHead[colIndex]
                        if (!head) {
                          console.log(`Missing head for column: ${colIndex}, prop: ${JSON.stringify(prop)}`)
                        }
                        return (
                          <TableCell className={classNames({ [classes.tableCell]: true, [classes.nameColumn]: head?.nameColumn, [classes.tableCellRight]: !!head?.right })} classes={{ body: classes.tableCellBody }} key={colIndex} data-unique={dataUnique && `${dataUnique}_${rowIndex}_${colIndex}`}>
                            <div className={classes.tableCellContent}>{renderCell(classes, prop, { truncateChip, truncateText })}</div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                } else {
                  // the prop must be an already rendered row
                  return prop
                }
              })}
            </TableBody>
          </Table>
        </div>
        {!disableFooter && (!disableFooterIfNotHasMore || hasMore) && (
          <TablePagination
            classes={{
              selectRoot: classes.selectMenuPagination,
              caption: classes.captionPagination,
              select: classes.selectPagination,
              selectIcon: classes.selectIconPagination,
            }}
            component="div"
            className={classes.pagginationNumberpage}
            count={onPageChange ? (maxCount || -1) : totalCount}
            labelDisplayedRows={
              onPageChange
                ? ({ from, to, count }) => (maxCount ? `${from}-${from + pageData.length - 1} of ${maxCount}` :  `${from}-${from + pageData.length - 1}`)
                : ({ from, to, count }) => `${from}-${to} of ${count}`

            }
            rowsPerPage={settings.rowsPerPage}
            rowsPerPageOptions={disablePageSize ? [] : [5, 10, 25, 50, 100]}
            page={settings.page}
            onChangePage={() => ({})}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={() => (
              <AdvancedCustomTablePaginationActionsWrapped
                count={onPageChange ? (maxCount || -1) : totalCount}
                rowsPerPage={settings.rowsPerPage}
                hasMore={hasMore}
                pageLoading={pageLoading}
                page={settings.page}
                onChangePage={this.handleChangePage}
              />
            )}
          />
        )}
      </React.Fragment>)
  }
}

ServerSidePagingTable.defaultProps = {
  tableHeaderColor: 'gray',
}

ServerSidePagingTable.propTypes = {
  name: PropTypes.string,
  dataUnique: PropTypes.string,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.any),
  tableData: PropTypes.func,
  onRefresh: PropTypes.func,
  mapSelectIds: PropTypes.func,
  onSelectionChange: PropTypes.func,
  pageLoading: PropTypes.bool,
  pageErr: PropTypes.any,
  onPageChange: PropTypes.func,
  maxCount: PropTypes.number,
  pageSize: PropTypes.number,
  disableHeader: PropTypes.bool,
  disableFilter: PropTypes.bool,
  disableOrderBy: PropTypes.bool,
  disableFooter: PropTypes.bool,
  disableFooterIfNotHasMore: PropTypes.bool,
  disablePageSize: PropTypes.bool,
  dense: PropTypes.bool
}

export default connect(
  (state, ownProps) => ({
    settings: Object.assign(
      {},
      defaultTableSettings,
      state.table[ownProps.name || ownProps.dataUnique || window.location.pathname],
      ownProps.pageSize ? { rowsPerPage: ownProps.pageSize } : {},
      (ownProps.location && ownProps.location.search && queryString.parse(ownProps.location.search).filter) ? { filterText: queryString.parse(ownProps.location.search).filter } : {}
    ),
  }),
  { clearTableSettings, setTableSettings },
)(withApollo(withStyles(tableStyle)(ServerSidePagingTable)))
