import { SET_TESTCASE_FILTER } from '../constants'

export function setTestCaseFilter(filter, value) {
  return {
    type: SET_TESTCASE_FILTER,
    payload: {
      filter, 
      value
    },
  }
}
