import {
  lightGrayColor,
  warningColor
} from 'assets/jss/material-dashboard-react.jsx'

const boxStyles = theme => ({
  explanationBox: {
    borderRadius: 10,
    backgroundColor: lightGrayColor,
    padding: 15
  },
  warningBox: {
    borderColor: warningColor,
    borderRadius: 5,
    borderWidth: 2,
    borderStyle: 'solid',
    padding: 10,
    color: theme.colors.primary,
  },
  warningBoxIcon: {
    color: warningColor,
    margin: 10
  }
})

export default boxStyles
