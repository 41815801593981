import { gql } from 'apollo-boost'
import { validateUnique } from 'helper/graphHelper'

const CHECK_AGENT_NAME_QUERY = gql`
query CheckAgentNameQuery($where: AgentWhereInput!) {
  agents(where: $where) {
    id
  }
}
`
export async function validateAgentNameUnique (client, agentId, agentName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_AGENT_NAME_QUERY, { where: { name: agentName } }, 'agents', agentId, errMsg)
}

const CHECK_APIKEY_NAME_QUERY = gql`
query CheckApiKeyNameQuery($where: ApiKeyWhereInput!) {
  apikeys(where: $where) {
    id
  }
}
`
export async function validateApiKeyNameUnique (client, apiKeyId, apiKeyName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_APIKEY_NAME_QUERY, { where: { name: apiKeyName } }, 'apikeys', apiKeyId, errMsg)
}

const CHECK_DEVICEPROVIDER_NAME_QUERY = gql`
query CheckDeviceProviderNameQuery($where: DeviceProviderWhereInput!) {
  deviceproviders(where: $where) {
    id
  }
}
`
export async function validateDeviceProviderNameUnique (client, deviceProviderId, deviceProviderName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_DEVICEPROVIDER_NAME_QUERY, { where: { name: deviceProviderName } }, 'deviceproviders', deviceProviderId, errMsg)
}

const CHECK_REGISTEREDCOMPONENT_NAME_QUERY = gql`
query CheckRegisteredComponentNameQuery($where: RegisteredComponentWhereInput!) {
  registeredcomponents(where: $where) {
    id
  }
}
`
export async function validateRegisteredComponentNameUnique (client, registeredComponentId, registeredComponentName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_REGISTEREDCOMPONENT_NAME_QUERY, { where: { name: registeredComponentName } }, 'registeredcomponents', registeredComponentId, errMsg)
}

const CHECK_REGISTEREDAGGREGATOR_NAME_QUERY = gql`
query CheckRegisteredAggregatorNameQuery($where: RegisteredAggregatorFilterInput!) {
  registeredaggregators(where: $where) {
    id
  }
}
`
export async function validateRegisteredAggregatorNameUnique (client, registeredAggregatorId, registeredAggregatorName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_REGISTEREDAGGREGATOR_NAME_QUERY, { where: { name: registeredAggregatorName } }, 'registeredaggregators', registeredAggregatorId, errMsg)
}

const CHECK_CAPABILITYSET_NAME_QUERY = gql`
query CheckCapabilitySetNameQuery($where: CapabilitySetWhereInput!) {
  capabilitySets(where: $where) {
    id
  }
}
`
export async function validateCapabilitySetNameUnique (client, capabilitySetId, capabilitySetName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_CAPABILITYSET_NAME_QUERY, { where: { name: capabilitySetName } }, 'capabilitySets', capabilitySetId, errMsg)
}

const CHECK_USERROLE_NAME_QUERY = gql`
query CheckUserRoleNameQuery($where: UserRoleWhereInput!) {
  userroles(where: $where) {
    id
  }
}
`
export async function validateUserRoleNameUnique (client, userRoleId, userRoleName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_USERROLE_NAME_QUERY, { where: { name: userRoleName } }, 'userroles', userRoleId, errMsg)
}

const CHECK_USER_QUERY = gql`
query CheckUserNameQuery($where: UserWhereInput!) {
  users(where: $where) {
    id
  }
}
`
export async function validateUserNameUnique (client, userId, userName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_USER_QUERY, { where: { name: userName } }, 'users', userId, errMsg)
}
export async function validateUserEmailUnique (client, userId, userEmail, errMsg = 'Please choose another email, this one is already used') {
  return validateUnique(client, CHECK_USER_QUERY, { where: { email: userEmail } }, 'users', userId, errMsg)
}

const CHECK_SPEECHRECOGNITION_NAME_QUERY = gql`
query CheckSpeechRecognitionNameQuery($where: SpeechRecognitionProfileFilterInput) {
  speechRecognitionProfiles(where: $where) {
    id
  }
}
`
export async function validateSpeechRecognitionNameUnique (client, speechRecognitionId, speechRecognitionName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_SPEECHRECOGNITION_NAME_QUERY, { where: { name: speechRecognitionName } }, 'speechRecognitionProfiles', speechRecognitionId, errMsg)
}

const CHECK_SPEECHSYNTHESIS_NAME_QUERY = gql`
query CheckSpeechSynthesisNameQuery($where: SpeechSynthesisProfileFilterInput) {
  speechSynthesisProfiles(where: $where) {
    id
  }
}
`
export async function validateSpeechSynthesisNameUnique (client, speechSynthesisId, speechSynthesisName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_SPEECHSYNTHESIS_NAME_QUERY, { where: { name: speechSynthesisName } }, 'speechSynthesisProfiles', speechSynthesisId, errMsg)
}

const CHECK_SPEECHNOISE_NAME_QUERY = gql`
query CheckSpeechNoiseNameQuery($where: SpeechNoiseProfileFilterInput) {
  speechNoiseProfiles(where: $where) {
    id
  }
}
`
export async function validateSpeechNoiseNameUnique (client, speechNoiseId, speechNoiseName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_SPEECHNOISE_NAME_QUERY, { where: { name: speechNoiseName } }, 'speechNoiseProfiles', speechNoiseId, errMsg)
}