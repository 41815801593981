import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import {Form} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
// apollo
import {compose, Query, Mutation} from 'react-apollo'
// core components
import Field from 'components/Form/OptionalField'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  composeValidators,
  FormActionsToolbar,
  maxLength,
  renderTextField,
  required
} from 'components/Form/Form'
import ErrorFormat from 'components/Info/ErrorFormat'
import SwitchButton from 'components/Button/SwitchButton'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'

import CrawlerConvoEditor from './CrawlerConvoEditor'

import {
  CRAWLER_SESSION_CONVO_QUERY, CRAWLERSESSION_QUERY, CRAWLERSESSION_TREE_QUERY, UPDATE_CRAWLERSESSIONSCRIPTNAME,
} from './gql'

import {setAlertSuccessMessage, setAlertErrorMessage} from 'actions/alert'
import {hasPermission} from 'botium-box-shared/security/permissions'
import crawlersessionsStyle from 'assets/jss/material-dashboard-react/views/crawlersessionsStyle'
import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class CrawlerSessionConvo extends React.Component {

  renderForm(crawlerSession, crawlerSessionScriptId, convo) {
    const { historyView, user, setAlertSuccessMessage, setAlertErrorMessage, onRefetch, onSwitchToConvoClick, onSwitchToSourceClick, classes } = this.props

    return (
      <Mutation
        mutation={UPDATE_CRAWLERSESSIONSCRIPTNAME}
        refetchQueries={() => [
          {
            query: CRAWLER_SESSION_CONVO_QUERY,
            variables: { crawlerSessionScriptId },
          },
          {
            query: CRAWLERSESSION_QUERY,
            variables: { id: crawlerSession.id },
          },
          {
            query: CRAWLERSESSION_TREE_QUERY,
            variables: { id: crawlerSession.id },
          }
        ]}
        awaitRefetchQueries={true}
      >
        {(updateCrawlerSessionScriptName) => (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={async values => {
              try {
                await updateCrawlerSessionScriptName({
                  variables: {
                    id: crawlerSessionScriptId,
                    scriptName: values.name
                  },
                })
                onRefetch()
                setAlertSuccessMessage('Convo updated')
              } catch(error) {
                setAlertErrorMessage('Convo update failed', error)
              }
            }}
            initialValues={convo}
            render={({
               handleSubmit,
               submitting,
               invalid,
               saving,
               values,
               form: {
                 change,
               }
            }) => (

              <form onSubmit={handleSubmit} data-simplelist-container>
                 <GridContainer>
                    <GridItem md={12} borderBottom header >
                      <GridContainer>
                        <GridItem md={12} lg={8} className={classes.testCaseName}>
                          <Field
                            name="name"
                            component={renderTextField}
                            disableBorder
                            flexibleWidth
                            slimError
                            inlineEdit={!historyView}
                            validate={composeValidators(required, maxLength(255))}
                            disabled={!hasPermission(user, 'CRAWLERPROJECTS_UPDATE') || historyView}
                            data-unique="txtCrawlerSessionConvoName"
                          />
                        </GridItem>
                        <GridItem md={12} lg={4} right smallMarginTop className={classes.sourceConvoEditor}>
                          <SwitchButton
                            leftLabel="Source Editor"
                            rightLabel="Convo Editor"
                            leftDataUnique="btnSourceEditor"
                            rightDataUnique="btnConvoEditor"
                            checked={'right'}
                            onRightClick={onSwitchToConvoClick}
                            onLeftClick={onSwitchToSourceClick}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                </GridContainer>
                <CrawlerConvoEditor
                  disabled={true}
                  hideName={true}
                  values={values}
                  change={change}
                  crawlerSession={crawlerSession}
                  historyView={historyView}
                />
                <GridContainer padding>
                  <GridItem xs={12}>
                    <FormActionsToolbar
                      rightButtons={
                        <React.Fragment>
                          {hasPermission(user, 'CRAWLERSESSIONS_UPDATE') &&
                          <Button
                            type="submit"
                            disabled={saving || submitting  || invalid}
                            data-unique="btnCrawlerConvoSave"
                          >
                            {saving && <><LoadingIndicator alt /> Saving ...</>}
                            {!saving && <><ShowIcon icon="save" /> Save</>}
                          </Button>
                          }
                        </React.Fragment>
                      } />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { match, crawlerSession } = this.props

    if (match.params && match.params.crawlerSessionScriptId) {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Query
              query={CRAWLER_SESSION_CONVO_QUERY}
              variables={{ crawlerSessionScriptId: match.params.crawlerSessionScriptId}}
              fetchPolicy={'network-only'}
            >
              {({ loading, error, data }) => {
                if (loading) return <LoadingIndicator/>
                if (error) return <ErrorFormat err={error} />

                return this.renderForm(crawlerSession, match.params.crawlerSessionScriptId, data.crawlersessionconvo)
              }}
            </Query>
          </GridItem>
        </GridContainer>
      )
    } else {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderForm(crawlerSession, null, null, this.empty)}
          </GridItem>
        </GridContainer>
      )
    }
  }
}

export default compose(
  withStyles(
    (theme) => ({
      ...crawlersessionsStyle(theme),
      ...convoStyle(theme)
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage }
  )
)(CrawlerSessionConvo)
