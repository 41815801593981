import cardTitleStyle from 'assets/jss/material-dashboard-react/components/cardTitleStyle.jsx'
import typographyStyle from 'assets/jss/material-dashboard-react/components/typographyStyle.jsx'
import dashboardStyle from './dashboardStyle.jsx'
import tableStyle from '../components/tableStyle.jsx'



const quicksearchStyle = theme => {
  return {
  ...cardTitleStyle,
  ...typographyStyle,
  ...dashboardStyle(theme),
  ...tableStyle(theme),

    testSessionsEmbeddedTable: {
      '& table': {
        '& tr': {
          height: 80,
        },
        '& tr:last-child td': {
          borderBottom: `0px solid ${theme.colors.tableRowBorder}`
        },
      }
    },
    expansionPanel: {
      backgroundColor: `${theme.colors.backgroundPrimary}`,
      border: `1px solid ${theme.colors.tableRowBorder}`,
      borderRadius: 3,
    },

  }
}

export default quicksearchStyle
