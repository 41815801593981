import React from 'react'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  renderTextField,
  renderCodeArea,
  renderCheckbox,
  required,
  jsonpath,
  json, renderSelect, prettyPrintJson,
  CustomCodeArea
} from 'components/Form/Form'
import FileSelectorField from 'components/Form/FileSelectorField'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import { capSetDescription, ExtractBooleanCapabilityValue, ExtractJsonCapabilityValue, ExtractStringCapabilityValue, usedByCapabilitySet } from './Helper'
import Button from '../Button/Button'
import _ from 'lodash'
import Tooltip from '../Tooltip/Tooltip'
import ShowIcon from '../Icon/ShowIcon'

const START_HOOK = `
module.exports = ({ socketOptions, botium, msg }) => {
  socketOptions.conversationId = botium.conversationId;
}
`.trim()

const SESSION_REQUEST_HOOK = `
module.exports = ({ sessionRequestData, botium, msg }) => {
  sessionRequestData.session_id = botium.conversationId;
}
`.trim()

const STOP_HOOK = `
module.exports = ({ socket, botium }) => {

}
`.trim()

const USER_SAYS_EVENT_HOOK = `
module.exports = ({ socketArgs, botium, msg }) => {
  socketArgs.stepId = botium.stepId;
  socketArgs.message = msg.messageText;
}
`.trim()

const BOT_SAYS_EVENT_HOOK = `
module.exports = ({ botMsg }) => {
  if (!botMsg.sourceData.intent) {
    botMsg.nlp = {
      intent: {
        incomprehension: true,
        confidence: 1
      }
    }
  } else {
    botMsg.nlp = {
      intent: {
        name: botMsg.sourceData.intent,
        confidence: (botMsg.sourceData.score || 0) / 100
      }
    }
  }
}
`.trim()


export function simpleSocketioConnectorCaps2Form(caps) {
  return {
    socketio: {
      socketioMajorVersion: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_SERVER_MAJOR_VERSION', '2'),
      socketioClientOptions: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'SIMPLESOCKETIO_CLIENT_OPTIONS')),
      useWebsocketTransport: ExtractBooleanCapabilityValue(caps, 'SIMPLESOCKETIO_USE_WEBSOCKET_TRANSPORT', true),
      url: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_ENDPOINTURL', ''),
      path: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_ENDPOINTPATH', ''),
      userSaysEvent: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_EVENT_USERSAYS', ''),
      botSaysEvent: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_EVENT_BOTSAYS', ''),
      sendTextField: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_SENDTEXT_FIELD', ''),
      sendMediaField: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_SENDMEDIA_FIELD', ''),
      sendButtonField: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_SENDBUTTON_FIELD', ''),
      receiveTextJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_RECEIVETEXT_JSONPATH', ''),
      receiveAttachmentsJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_RECEIVEATTACHMENTS_JSONPATH', ''),
      receiveButtonsJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_RECEIVEBUTTONS_JSONPATH', ''),
      receiveCardsJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_RECEIVECARDS_JSONPATH', ''),
      receiveCardTextJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_RECEIVECARD_CARD_TEXT_JSONPATH', ''),
      receiveCardSubtextJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_RECEIVECARD_CARD_SUBTEXT_JSONPATH', ''),
      receiveCardButtonsJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_RECEIVECARD_CARD_BUTTONS_JSONPATH', ''),
      receiveCardAttacmentsJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_RECEIVECARD_CARD_ATTACHMENTS_JSONPATH', ''),
      startHook: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_START_HOOK', ''),
      startHookEnabled: !!ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_START_HOOK', ''),
      userSaysEventHook: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_USERSAYS_EVENT_HOOK', ''),
      userSaysEventHookEnabled: !!ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_USERSAYS_EVENT_HOOK', ''),
      botSaysEventHook: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_BOTSAYS_EVENT_HOOK', ''),
      botSaysEventHookEnabled: !!ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_BOTSAYS_EVENT_HOOK', ''),
      stopHook: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_STOP_HOOK', ''),
      stopHookEnabled: !!ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_STOP_HOOK', ''),
      sessionRequestHook: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_SESSION_REQUEST_HOOK', ''),
      sessionRequestHookEnabled: !!ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_SESSION_REQUEST_HOOK', ''),
      emitSessionRequestEvent: ExtractStringCapabilityValue(caps, 'SIMPLESOCKETIO_EMIT_SESSION_REQUEST_EVENT', ''),
    }
  }
}

const capNamesMap = {
  'socketio.socketioMajorVersion': 'SIMPLESOCKETIO_SERVER_MAJOR_VERSION',
  'socketio.socketioClientOptions': 'SIMPLESOCKETIO_CLIENT_OPTIONS',
  'socketio.useWebsocketTransport': 'SIMPLESOCKETIO_USE_WEBSOCKET_TRANSPORT',
  'socketio.url': 'SIMPLESOCKETIO_ENDPOINTURL',
  'socketio.path': 'SIMPLESOCKETIO_ENDPOINTPATH',
  'socketio.userSaysEvent': 'SIMPLESOCKETIO_EVENT_USERSAYS',
  'socketio.botSaysEvent': 'SIMPLESOCKETIO_EVENT_BOTSAYS',
  'socketio.sendTextField': 'SIMPLESOCKETIO_SENDTEXT_FIELD',
  'socketio.sendMediaField': 'SIMPLESOCKETIO_SENDMEDIA_FIELD',
  'socketio.sendButtonField': 'SIMPLESOCKETIO_SENDBUTTON_FIELD',
  'socketio.receiveTextJsonPath': 'SIMPLESOCKETIO_RECEIVETEXT_JSONPATH',
  'socketio.receiveAttachmentsJsonPath': 'SIMPLESOCKETIO_RECEIVEATTACHMENTS_JSONPATH',
  'socketio.receiveButtonsJsonPath': 'SIMPLESOCKETIO_RECEIVEBUTTONS_JSONPATH',
  'socketio.receiveCardsJsonPath': 'SIMPLESOCKETIO_RECEIVECARDS_JSONPATH',
  'socketio.receiveCardTextJsonPath': 'SIMPLESOCKETIO_RECEIVECARD_CARD_TEXT_JSONPATH',
  'socketio.receiveCardSubtextJsonPath': 'SIMPLESOCKETIO_RECEIVECARD_CARD_SUBTEXT_JSONPATH',
  'socketio.receiveCardButtonsJsonPath': 'SIMPLESOCKETIO_RECEIVECARD_CARD_BUTTONS_JSONPATH',
  'socketio.receiveCardAttacmentsJsonPath': 'SIMPLESOCKETIO_RECEIVECARD_CARD_ATTACHMENTS_JSONPATH',
  'socketio.startHook': 'SIMPLESOCKETIO_START_HOOK',
  'socketio.startHookEnabled': 'SIMPLESOCKETIO_START_HOOK',
  'socketio.userSaysEventHook': 'SIMPLESOCKETIO_USERSAYS_EVENT_HOOK',
  'socketio.userSaysEventHookEnabled': 'SIMPLESOCKETIO_USERSAYS_EVENT_HOOK',
  'socketio.botSaysEventHook': 'SIMPLESOCKETIO_BOTSAYS_EVENT_HOOK',
  'socketio.botSaysEventHookEnabled': 'SIMPLESOCKETIO_BOTSAYS_EVENT_HOOK',
  'socketio.stopHook': 'SIMPLESOCKETIO_STOP_HOOK',
  'socketio.stopHookEnabled': 'SIMPLESOCKETIO_STOP_HOOK',
  'socketio.sessionRequestHook': 'SIMPLESOCKETIO_SESSION_REQUEST_HOOK',
  'socketio.sessionRequestHookEnabled': 'SIMPLESOCKETIO_SESSION_REQUEST_HOOK',
  'socketio.emitSessionRequestEvent': 'SIMPLESOCKETIO_EMIT_SESSION_REQUEST_EVENT',
}

export function simpleSocketioConnectorForm2caps(values) {
  const capabilities = [
    {
      name: 'SIMPLESOCKETIO_SERVER_MAJOR_VERSION',
      type: 'STRING',
      stringValue: values.socketio.socketioMajorVersion,
    },
    {
      name: 'SIMPLESOCKETIO_ENDPOINTURL',
      type: 'STRING',
      stringValue: values.socketio.url,
    },
    {
      name: 'SIMPLESOCKETIO_ENDPOINTPATH',
      type: 'STRING',
      stringValue: values.socketio.path,
    },
    {
      name: 'SIMPLESOCKETIO_EVENT_USERSAYS',
      type: 'STRING',
      stringValue: values.socketio.userSaysEvent,
    },
    {
      name: 'SIMPLESOCKETIO_EVENT_BOTSAYS',
      type: 'STRING',
      stringValue: values.socketio.botSaysEvent,
    },
    {
      name: 'SIMPLESOCKETIO_SENDTEXT_FIELD',
      type: 'STRING',
      stringValue: values.socketio.sendTextField,
    },
    {
      name: 'SIMPLESOCKETIO_SENDMEDIA_FIELD',
      type: 'STRING',
      stringValue: values.socketio.sendMediaField,
    },
    {
      name: 'SIMPLESOCKETIO_SENDBUTTON_FIELD',
      type: 'STRING',
      stringValue: values.socketio.sendButtonField,
    },
    {
      name: 'SIMPLESOCKETIO_RECEIVETEXT_JSONPATH',
      type: 'STRING',
      stringValue: values.socketio.receiveTextJsonPath,
    },
    {
      name: 'SIMPLESOCKETIO_RECEIVEATTACHMENTS_JSONPATH',
      type: 'STRING',
      stringValue: values.socketio.receiveAttachmentsJsonPath,
    },
    {
      name: 'SIMPLESOCKETIO_RECEIVEBUTTONS_JSONPATH',
      type: 'STRING',
      stringValue: values.socketio.receiveButtonsJsonPath,
    },
    {
      name: 'SIMPLESOCKETIO_RECEIVECARDS_JSONPATH',
      type: 'STRING',
      stringValue: values.socketio.receiveCardsJsonPath,
    },
    {
      name: 'SIMPLESOCKETIO_CLIENT_OPTIONS',
      type: 'JSON',
      jsonValue: values.socketio.socketioClientOptions
    },
    {
      name: 'SIMPLESOCKETIO_USE_WEBSOCKET_TRANSPORT',
      type: 'BOOLEAN',
      booleanValue: !!values.socketio.useWebsocketTransport
    }
  ]

  const _addIfEnabled = (cap, fieldName) => {
    if (_.get(values, `${fieldName}Enabled`)) {
      capabilities.push(cap)
    } else {
      capabilities.push({ name: cap.name, type: 'DELETE' })
    }
  }
  _addIfEnabled({
    name: 'SIMPLESOCKETIO_START_HOOK',
    type: 'JS',
    stringValue: values.socketio.startHook || null
  }, 'socketio.startHook')

  _addIfEnabled({
    name: 'SIMPLESOCKETIO_USERSAYS_EVENT_HOOK',
    type: 'JS',
    stringValue: values.socketio.userSaysEventHook || null
  }, 'socketio.userSaysEventHook')

  _addIfEnabled({
    name: 'SIMPLESOCKETIO_BOTSAYS_EVENT_HOOK',
    type: 'JS',
    stringValue: values.socketio.botSaysEventHook || null
  }, 'socketio.botSaysEventHook')

  _addIfEnabled({
    name: 'SIMPLESOCKETIO_STOP_HOOK',
    type: 'JS',
    stringValue: values.socketio.stopHook || null
  }, 'socketio.stopHook')

  _addIfEnabled({
    name: 'SIMPLESOCKETIO_SESSION_REQUEST_HOOK',
    type: 'JS',
    stringValue: values.socketio.sessionRequestHook || null
  }, 'socketio.sessionRequestHook')


  if (_.get(values, 'socketio.sessionRequestHookEnabled')) {
    capabilities.push({
      name: 'SIMPLESOCKETIO_EMIT_SESSION_REQUEST_EVENT',
      type: 'STRING',
      stringValue: values.socketio.emitSessionRequestEvent,
    })
  } else {
    capabilities.push({ name: 'SIMPLESOCKETIO_EMIT_SESSION_REQUEST_EVENT', type: 'DELETE' })
  }

  if (_.get(values, 'socketio.receiveCardsJsonPath')) {
    capabilities.push({
      name: 'SIMPLESOCKETIO_RECEIVECARD_CARD_TEXT_JSONPATH',
      type: 'STRING',
      stringValue: values.socketio.receiveCardTextJsonPath,
    })
    capabilities.push({
      name: 'SIMPLESOCKETIO_RECEIVECARD_CARD_SUBTEXT_JSONPATH',
      type: 'STRING',
      stringValue: values.socketio.receiveCardSubtextJsonPath,
    })
    capabilities.push({
      name: 'SIMPLESOCKETIO_RECEIVECARD_CARD_BUTTONS_JSONPATH',
      type: 'STRING',
      stringValue: values.socketio.receiveCardButtonsJsonPath,
    })
    capabilities.push({
      name: 'SIMPLESOCKETIO_RECEIVECARD_CARD_ATTACHMENTS_JSONPATH',
      type: 'STRING',
      stringValue: values.socketio.receiveCardAttacmentsJsonPath,
    })
  } else {
    capabilities.push({ name: 'SIMPLESOCKETIO_RECEIVECARD_CARD_TEXT_JSONPATH', type: 'DELETE' })
    capabilities.push({ name: 'SIMPLESOCKETIO_RECEIVECARD_CARD_SUBTEXT_JSONPATH', type: 'DELETE' })
    capabilities.push({ name: 'SIMPLESOCKETIO_RECEIVECARD_CARD_BUTTONS_JSONPATH', type: 'DELETE' })
    capabilities.push({ name: 'SIMPLESOCKETIO_RECEIVECARD_CARD_ATTACHMENTS_JSONPATH', type: 'DELETE' })
  }

  return capabilities
}

export class SimpleSocketIOConnectorEdit extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      socketIOEndpointConfigExpanded: false,
      socketIOReqBuilderExpanded: false,
      socketIOResEvalExpanded: false,
      sampleCodeOpen: false,
      sampleCode: '',
      sampleCodeVars: ''
    }
  }

  render() {
    const { disabled, advanced, capSetCapNames } = this.props
    const { socketIOEndpointConfigExpanded, socketIOReqBuilderExpanded, socketIOResEvalExpanded } = this.state
    const { sampleCode, sampleCodeOpen, sampleCodeVars } = this.state

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => (
      <GridContainer>
        {!advanced && <React.Fragment>
          <GridItem xs={12} sm={2}>
            <Field
              name="socketio.socketioMajorVersion"
              component={renderSelect}
              label="Socket.io version"
              validate={required}
              data-unique="selSocketIOConnectorEditSocketioMajorVersion"
              items={[
                { key: '2', label: 'v2' },
                { key: '3', label: 'v3' },
                { key: '4', label: 'v4' }
              ]}
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.socketioMajorVersion']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.socketioMajorVersion'], 'The major version of socket.io server package on the bot side.')}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              name="socketio.url"
              component={renderTextField}
              label="Host url"
              validate={required}
              data-unique="txtSocketIOConnectorEditUrl"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.url']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.url'])}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="socketio.path"
              component={renderTextField}
              label="Endpoint path"
              data-unique="txtSocketIOConnectorEditPath"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.path']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.path'])}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="socketio.userSaysEvent"
              component={renderTextField}
              label="#me event name"
              data-unique="txtSocketIOConnectorEditUserSaysEvent"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.userSaysEvent']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.userSaysEvent'])}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="socketio.botSaysEvent"
              component={renderTextField}
              label="#bot event name"
              data-unique="txtSocketIOConnectorEditBotSaysEvent"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.botSaysEvent']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.botSaysEvent'])}
            />
          </GridItem>
        </React.Fragment>}
        {advanced && <GridItem xs={12}>
          <ExpansionPanel expanded={socketIOEndpointConfigExpanded} onChange={() => this.setState({ socketIOEndpointConfigExpanded: !socketIOEndpointConfigExpanded })} data-unique="pnlSocketIOEndPointConfig">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Socket.io connection configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.url"
                    component={renderTextField}
                    label="Host url"
                    validate={required}
                    data-unique="txtSocketIOConnectorEditUrl"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.url']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.url'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.path"
                    component={renderTextField}
                    label="Endpoint path"
                    data-unique="txtSocketIOConnectorEditPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.path']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.path'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.socketioMajorVersion"
                    component={renderSelect}
                    label="Socket.io version"
                    validate={required}
                    data-unique="selSocketIOConnectorEditSocketioMajorVersion"
                    items={[
                      { key: '2', label: 'v2' },
                      { key: '3', label: 'v3' },
                      { key: '4', label: 'v4' }
                    ]}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.socketioMajorVersion']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.socketioMajorVersion'], 'The major version of socket.io server package on the bot side.')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.useWebsocketTransport"
                    component={renderCheckbox}
                    label="Use websocket transport"
                    type="checkbox"
                    data-unique="btnSocketIOConnectorEditUseWebsocketTransport"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.useWebsocketTransport']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.useWebsocketTransport'])}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="socketio.socketioClientOptions"
                    label="Socket.io client-options"
                    component={renderCodeArea}
                    options={{ mode: 'application/json' }}
                    validate={json}
                    data-unique="codeSocketIOConnectorEditSocketioClientOptions"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.useWebsocketTransport']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.useWebsocketTransport'], 'Define a client-option json object according to socket.io client-options documentation')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.startHookEnabled"
                    component={renderCheckbox}
                    label="Enable start hook"
                    type="checkbox"
                    data-unique="btnSocketIOConnectorEditEnableStartHookEnabled"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.startHookEnabled']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.startHookEnabled'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.stopHookEnabled"
                    component={renderCheckbox}
                    label="Enable stop hook"
                    type="checkbox"
                    data-unique="btnSocketIOConnectorEditEnableStopHookEnabled"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.stopHookEnabled']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.stopHookEnabled'])}
                  />
                </GridItem>

                <GridItem xs={12} sm={6}>
                  {_.get(values, 'socketio.startHookEnabled') &&
                    <GridItem xs={12}>
                      <FileSelectorField
                        name="socketio.startHook"
                        change={change}
                        label="Start Hook (Javascript File)"
                        validate={required}
                        data-unique="codeSocketIOConnectorEditStartHook"
                        endAdornment={<>
                          <Tooltip title="Show Sample Code">
                            <Button justIcon dense data-unique="codeSocketIOConnectorEditStartHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: START_HOOK, sampleCodeVars: 'Variables available: socketOptions, container, context, botium' })}>
                              <ShowIcon icon="file-alt" />
                            </Button>
                          </Tooltip>
                        </>}
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.startHook']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.startHook'], '')}
                        initialPath="resources/scripts"
                        extensionFilter={['.js']}
                        restrictPath
                      />
                    </GridItem>}
                </GridItem>
                <GridItem xs={12} sm={6}>
                  {_.get(values, 'socketio.stopHookEnabled') &&
                    <React.Fragment>
                      <GridItem xs={12}>
                        <FileSelectorField
                          name="socketio.stopHook"
                          change={change}
                          label="Stop Hook (Javascript File)"
                          validate={required}
                          data-unique="codeSocketIOConnectorEditStopHook"
                          endAdornment={<>
                            <Tooltip title="Show Sample Code">
                              <Button justIcon dense data-unique="codeSocketIOConnectorEditStopHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: STOP_HOOK, sampleCodeVars: 'Variables available: socket, container, context, botium' })}>
                                <ShowIcon icon="file-alt" />
                              </Button>
                            </Tooltip>
                          </>}
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.stopHook']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.stopHook'], '')}
                          initialPath="resources/scripts"
                          extensionFilter={['.js']}
                          restrictPath
                        />
                      </GridItem>
                    </React.Fragment>}
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="socketio.sessionRequestHookEnabled"
                    component={renderCheckbox}
                    label="Enable session request emit"
                    type="checkbox"
                    data-unique="btnSocketIOConnectorEditEnableStopHookEnabled"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.sessionRequestHookEnabled']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.sessionRequestHookEnabled'])}
                  />
                </GridItem>
                <GridItem xs={12}>
                  {_.get(values, 'socketio.sessionRequestHookEnabled') &&
                    <React.Fragment>
                      <GridItem xs={12}>
                        <Field
                          name="socketio.emitSessionRequestEvent"
                          component={renderTextField}
                          label="Session Request Event"
                          data-unique="txtSocketIOConnectorEmitSessionRequestEvent"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.emitSessionRequestEvent']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.emitSessionRequestEvent'], 'The session request event will be emitted right after connect')}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <FileSelectorField
                          name="socketio.sessionRequestHook"
                          change={change}
                          label="Session Request Hook (Javascript File)"
                          validate={required}
                          data-unique="codeSocketIOConnectorEditSessionRequestHook"
                          endAdornment={<>
                            <Tooltip title="Show Sample Code">
                              <Button justIcon dense data-unique="codeSocketIOConnectorEditSessionRequestHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: SESSION_REQUEST_HOOK, sampleCodeVars: 'Variables available: sessionRequestData, container, context, botium' })}>
                                <ShowIcon icon="file-alt" />
                              </Button>
                            </Tooltip>
                          </>}
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.sessionRequestHookEnabled']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.sessionRequestHookEnabled'], '')}
                          initialPath="resources/scripts"
                          extensionFilter={['.js']}
                          restrictPath
                        />
                      </GridItem>
                    </React.Fragment>}
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={socketIOReqBuilderExpanded} onChange={() => this.setState({ socketIOReqBuilderExpanded: !socketIOReqBuilderExpanded })} data-unique="pnlSocketIOReqBuilder">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Socket.io request builder
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.userSaysEvent"
                    component={renderTextField}
                    label="#me event name"
                    data-unique="txtSocketIOConnectorEditUserSaysEvent"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.userSaysEvent']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.userSaysEvent'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.sendTextField"
                    component={renderTextField}
                    label="Payload field for text input"
                    data-unique="txtSocketIOConnectorEditSendTextField"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.sendTextField']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.sendTextField'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.sendMediaField"
                    component={renderTextField}
                    label="Payload field for media attachment"
                    data-unique="txtSocketIOConnectorEditSendMediaField"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.sendMediaField']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.sendMediaField'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.sendButtonField"
                    component={renderTextField}
                    label="Payload field for button"
                    data-unique="txtSocketIOConnectorEditSendButtonField"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.sendButtonField']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.sendButtonField'])}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="socketio.userSaysEventHookEnabled"
                    component={renderCheckbox}
                    label="Enable user says event hook"
                    type="checkbox"
                    data-unique="btnSocketIOConnectorEditEnableUserSaysEventHookEnabled"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.userSaysEventHookEnabled']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.userSaysEventHookEnabled'])}
                  />
                </GridItem>
                {_.get(values, 'socketio.userSaysEventHookEnabled') && <>
                  <GridItem xs={12} sm={6}>
                    <FileSelectorField
                      name="socketio.userSaysEventHook"
                      change={change}
                      label="User Says Event Hook (Javascript File)"
                      validate={required}
                      data-unique="codeSocketIOConnectorEditUserSaysEventHook"
                      endAdornment={<>
                        <Tooltip title="Show Sample Code">
                          <Button justIcon dense data-unique="codeSocketIOConnectorEditUserSaysEventHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: USER_SAYS_EVENT_HOOK, sampleCodeVars: 'Variables available: socketArgs, msg, container, context, botium' })}>
                            <ShowIcon icon="file-alt" />
                          </Button>
                        </Tooltip>
                      </>}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.userSaysEventHook']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.userSaysEventHook'], '')}
                      initialPath="resources/scripts"
                      extensionFilter={['.js']}
                      restrictPath
                    />
                  </GridItem>
                </>}
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={socketIOResEvalExpanded} onChange={() => this.setState({ socketIOResEvalExpanded: !socketIOResEvalExpanded })} data-unique="pnlSocketIOResEval">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              SocketIO response evaluation
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.botSaysEvent"
                    component={renderTextField}
                    label="#bot event name"
                    data-unique="txtSocketIOConnectorEditBotSaysEvent"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.botSaysEvent']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.botSaysEvent'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.receiveTextJsonPath"
                    component={renderTextField}
                    label="JSON-Path for text response"
                    validate={jsonpath}
                    data-unique="txtSocketIOConnectorEditReceiveTextJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.receiveTextJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.receiveTextJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.receiveButtonsJsonPath"
                    component={renderTextField}
                    label="JSON-Path for quick-reply recognition"
                    validate={jsonpath}
                    data-unique="txtSocketIOConnectorEditReceiveButtonsJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.receiveButtonsJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.receiveButtonsJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="socketio.receiveAttachmentsJsonPath"
                    component={renderTextField}
                    label="JSON-Path for media attachments"
                    validate={jsonpath}
                    data-unique="txtSocketIOConnectorEditReceiveAttachmentsJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.receiveAttachmentsJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.receiveAttachmentsJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="socketio.receiveCardsJsonPath"
                    component={renderTextField}
                    label="JSON-Path for cards root objects"
                    validate={jsonpath}
                    data-unique="txtSocketIOConnectorEditReceiveCardsJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.receiveCardsJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.receiveCardsJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                {_.get(values, 'socketio.receiveCardsJsonPath') && <>
                  <GridItem xs={1}>
                  </GridItem>
                  <GridItem xs={11}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Field
                          name="socketio.receiveCardTextJsonPath"
                          component={renderTextField}
                          label="JSON-Path for card text"
                          validate={jsonpath}
                          data-unique="txtSocketIOConnectorEditReceiveCardTextJsonPath"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.receiveCardTextJsonPath']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.receiveCardTextJsonPath'], 'Relative JSON-Path from cards root, which should return a string')}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <Field
                          name="socketio.receiveCardSubtextJsonPath"
                          component={renderTextField}
                          label="JSON-Path for card sub text"
                          validate={jsonpath}
                          data-unique="txtSocketIOConnectorEditReceiveCardSubTextJsonPath"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.receiveCardSubtextJsonPath']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.receiveCardSubtextJsonPath'], 'Relative JSON-Path from cards root, which should return a string')}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <Field
                          name="socketio.receiveCardButtonsJsonPath"
                          component={renderTextField}
                          label="JSON-Path for card buttons"
                          validate={jsonpath}
                          data-unique="txtSocketIOConnectorEditReceiveCardButtonsJsonPath"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.receiveCardButtonsJsonPath']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.receiveCardButtonsJsonPath'], 'Relative JSON-Path from cards root, which should return a string')}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <Field
                          name="socketio.receiveCardAttacmentsJsonPath"
                          component={renderTextField}
                          label="JSON-Path for card media attachments"
                          validate={jsonpath}
                          data-unique="txtSocketIOConnectorEditReceiveCardAttachmentsJsonPath"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.receiveCardAttacmentsJsonPath']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.receiveCardAttacmentsJsonPath'], 'Relative JSON-Path from cards root, which should return a string')}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>}
                <GridItem xs={12}>
                  <Field
                    name="socketio.botSaysEventHookEnabled"
                    component={renderCheckbox}
                    label="Enable bot says event hook"
                    type="checkbox"
                    data-unique="btnSocketIOConnectorEditEnableBotSaysEventHookEnabled"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.botSaysEventHookEnabled']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.botSaysEventHookEnabled'])}
                  />
                </GridItem>
                {_.get(values, 'socketio.botSaysEventHookEnabled') && <>
                  <GridItem xs={12} sm={6}>
                    <FileSelectorField
                      name="socketio.botSaysEventHook"
                      change={change}
                      label="Bot Says Event Hook (Javascript File)"
                      validate={required}
                      data-unique="codeSocketIOConnectorEditBotSaysEventHook"
                      endAdornment={<>
                        <Tooltip title="Show Sample Code">
                          <Button justIcon dense data-unique="codeSocketIOConnectorEditBotSaysEventHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: BOT_SAYS_EVENT_HOOK, sampleCodeVars: 'Variables available: botMsg, container, context, botium' })}>
                            <ShowIcon icon="file-alt" />
                          </Button>
                        </Tooltip>
                      </>}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['socketio.botSaysEventHook']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['socketio.botSaysEventHook'], '')}
                      initialPath="resources/scripts"
                      extensionFilter={['.js']}
                      restrictPath
                    />
                  </GridItem>
                </>}
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </GridItem>}
        <ConfirmationDialog
          cancelText="Close"
          open={!!sampleCodeOpen}
          onCancel={() => this.setState({ sampleCodeOpen: false })}
          title="Sample Javascript Code">
          <GridContainer>
            <GridItem xs={12}>
              {sampleCodeVars}
            </GridItem>
            <GridItem xs={12}>
              <CustomCodeArea
                label="Sample Javascript Code"
                options={{ mode: 'application/javascript' }}
                input={{
                  value: sampleCode,
                  disabled: true,
                }}
              />
            </GridItem>
          </GridContainer>
        </ConfirmationDialog>
      </GridContainer>
    )} />)
  }
}
