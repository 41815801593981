import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import Field from 'components/Form/OptionalField'

import { renderSelect, CustomCheckbox } from 'components/Form/Form'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import EnvironmentVariablesEdit from './EnvironmentVariablesEdit.jsx'
import { getConnector } from 'actions/settings'

import Text from 'components/Typography/Text.jsx'

import DefaultConnector from './DefaultConnector.js'
import DynamicConnector from './DynamicConnector.js'
import ConnectorsWithUI from './ConnectorsWithUI.js'

const DefaultConnectorForm = DefaultConnector.form
const DynamicConnectorForm = DynamicConnector.form

export const usedByCapabilitySet = (capSetCapNames, cap) => capSetCapNames && capSetCapNames.includes(cap)

export const capSetDescription = (capSetCapNames, cap, desc) => usedByCapabilitySet(capSetCapNames, cap) ? <><Text bold inline>Capability set by Capability Set{!_.isNil(desc) ? ' | ' : ''}</Text>{desc}</> : desc

export function CapabilityObjectClean(cap) {
  const result = _.pick(cap, [
    'name',
    'type',
    'stringValue',
    'intValue',
    'booleanValue',
    'jsonValue',
  ])
  result.stringValue = _.isUndefined(result.stringValue) ? null : result.stringValue
  result.intValue = _.isUndefined(result.intValue) ? null : result.intValue
  result.booleanValue = _.isUndefined(result.booleanValue) ? null : result.booleanValue
  result.jsonValue = _.isUndefined(result.jsonValue) ? null : result.jsonValue
  return result
}

export function CapabilitiesToGql(caps, orig) {
  const capabilities = {
    create: [],
    update: [],
    delete: [],
  }
  if (caps) {
    caps.forEach(cap => {
      if (cap.id) {
        capabilities.update.push({
          where: { id: cap.id },
          data: CapabilityObjectClean(cap),
        })
      } else {
        capabilities.create.push(cap)
      }
    })
  }
  if (orig) {
    orig.filter(cap => cap.id).forEach(cap => {
      if (!caps || !caps.find(vc => vc.id && cap.id === vc.id)) {
        capabilities.delete.push({ id: cap.id })
      }
    })
  }
  return capabilities
}

export function EnvironmentVariableObjectClean(cap) {
  return _.pick(cap, [
    'name',
    'stringValue'
  ])
}

export function EnvironmentVariablesToGql(envs, orig) {
  const envvars = {
    create: [],
    update: [],
    delete: [],
  }
  if (envs) {
    envs.forEach(env => {
      if (env.id) {
        envvars.update.push({
          where: { id: env.id },
          data: EnvironmentVariableObjectClean(env),
        })
      } else {
        envvars.create.push(env)
      }
    })
  }
  if (orig) {
    orig.filter(env => env.id).forEach(env => {
      if (!envs || !envs.find(vc => vc.id && env.id === vc.id)) {
        envvars.delete.push({ id: env.id })
      }
    })
  }
  return envvars
}

export function ConnectorPrepareForm(allconnectors, form, caps, change) {

  const assign = (connCaps) => {
    Object.assign(form, connCaps)
    change && Object.keys(connCaps).forEach(propName => {
      change(propName, connCaps[propName])
    })
  }

  Object.values(ConnectorsWithUI).forEach(desc => {
    assign(desc.caps2Form(caps || [], allconnectors))
  })

  assign(DynamicConnector.caps2Form(caps || [], allconnectors))
}

export function ConnectorPrepareCaps(connector, values, caps, connectorsettingsadvancedmode = false) {
  const update = (current) => {
    if (connectorsettingsadvancedmode) {
      const capsToRemove = caps.filter(ec => !current.find(newCap => ec.name === newCap.name)).map(ec => ec.name)
      capsToRemove.forEach(capName => {
        caps.splice(caps.findIndex(ec => ec.name === capName), 1)
      })
    } else {
      current.push({ name: 'CONTAINERMODE', type: 'STRING', stringValue: connector.name })
    }

    current.forEach(newCap => {
      const existingCap = caps.find(cap => cap.name === newCap.name)
      if (existingCap) {
        if (newCap.type !== 'DELETE') {
          Object.assign(existingCap, newCap)
        } else {
          caps.splice(caps.findIndex(cap => cap.name === newCap.name), 1)
        }
      } else {
        if (newCap.type !== 'DELETE') {
          caps.push(newCap)
        }
      }
    })
  }

  if (connectorsettingsadvancedmode) {
    update(DefaultConnector.form2Caps(values, connector))
  } else if (ConnectorsWithUI[connector.name]) {
    update(ConnectorsWithUI[connector.name].form2Caps(values, connector))
  } else {
    update(DynamicConnector.form2Caps(values, connector))
  }
}

export const ConnectorSelector = connect(
  state => ({ settings: state.settings })
)(({ settings, forcedConnector, connectorField, advanced, selector, setconnectorsettingsadvancedmode, connectorsettingsadvancedmode = false, ...rest }) => {

  let selectItems = []
  if (settings && settings.availableconnectors) {
    selectItems = settings.availableconnectors.filter(c => {
      if (selector) return selector(c)
      return true
    })
    if (forcedConnector) {
      settings.allconnectors.forEach(c => {
        if (c.name === forcedConnector && selectItems.filter(c2 => c2.name === c.name).length === 0) selectItems.push(c)
      })
    }
    selectItems = selectItems.map(c => {
      return {
        key: c.name,
        chatbot: c
      }
    })
  }

  return (
    <GridContainer>
      <GridItem xs={advanced ? 6 : 12}>
        <Field
          name={connectorField || 'connector'}
          component={renderSelect}
          label="Connector/Chatbot Technology"
          data-unique={`selConnectorSelector_${connectorField || 'connector'}`}
          items={selectItems}
          {...rest}
        />
      </GridItem>
      {advanced &&
        <GridItem xs={6} right bottom>
          <CustomCheckbox
            input={{
              onChange: e => {
                setconnectorsettingsadvancedmode(e.target.checked)
              },
              checked: connectorsettingsadvancedmode
            }}
            label="Advanced Mode"
            data-unique="chkConnectorSelector_AdvancedMode"
          />
        </GridItem>
      }
    </GridContainer>
  )
})

export const ConnectorSwitch = connect(
  state => ({}),
  { getConnector }
)(({ getConnector, connectorField, push, pop, advanced, capabilitiesField, disabled, change, connectorsettingsadvancedmode = false, values, capSetCapNames }) => {

  const name = values[connectorField || 'connector']
  const connectorDesc = getConnector(name)

  const hasStaticUI = !!ConnectorsWithUI[name]
  const hasDynamicUI = connectorDesc && connectorDesc.capabilities

  if (connectorsettingsadvancedmode && advanced) {
    return (<DefaultConnectorForm
      push={push}
      pop={pop}
      field={capabilitiesField || 'capabilities'}
      disabled={disabled}
      capSetCapNames={capSetCapNames}
    />)
  } else if (!hasStaticUI && !hasDynamicUI) {
    return <>Please switch to the "Advanced Mode" to continue configuration!</>
  } else if (hasStaticUI) {
    const ConnectorForm = ConnectorsWithUI[name].form
    return <ConnectorForm advanced={advanced} disabled={disabled} capSetCapNames={capSetCapNames} />
  } else if (hasDynamicUI) {
    return (<DynamicConnectorForm
      push={push}
      pop={pop}
      advanced={advanced}
      connectorDesc={connectorDesc}
      capSetCapNames={capSetCapNames}
      disabled={disabled}
    />)
  }
  return null
})

export function SourcesSwitch({ ...props }) {
  const { push, pop, sourcesField, disabled } = props

  return (
    <React.Fragment>
      <Text>Chatbot sources are only required for certain connectors, for example "docker"</Text>
      <DefaultConnectorForm
        push={push}
        pop={pop}
        field={sourcesField || 'sources'}
        disabled={disabled}
      />
    </React.Fragment>
  )
}

export function EnvsSwitch({ ...props }) {
  const { push, pop, envsField, disabled } = props

  return (
    <React.Fragment>
      <EnvironmentVariablesEdit push={push} pop={pop} field={envsField || 'envs'} disabled={disabled} />
    </React.Fragment>
  )
}

export function ImportBotiumJson(config) {
  if (!config || !config.botium) throw new Error('No botium tag found in configuration')
  if (!config.botium.Capabilities) throw new Error('No Capabilities tag found in configuration')
  if (!config.botium.Capabilities.CONTAINERMODE) {
    throw new Error('No CONTAINERMODE capability found in configuration')
  }

  const match = (from) => {
    return Object.keys(from).map(capName => {
      const newCap = {
        name: capName
      }
      if (_.isNumber(from[capName])) {
        newCap.type = 'INT'
        newCap.intValue = from[capName]
      } else if (_.isBoolean(from[capName])) {
        newCap.type = 'BOOLEAN'
        newCap.booleanValue = from[capName]
      } else if (_.isObject(from[capName])) {
        newCap.type = 'JSON'
        newCap.jsonValue = JSON.stringify(from[capName])
      } else {
        newCap.type = 'STRING'
        newCap.stringValue = from[capName]
      }
      return newCap
    })
  }
  const matchEnv = (from) => {
    return Object.keys(from).map(capName => {
      const newCap = {
        name: capName,
        stringValue: from[capName]
      }
      return newCap
    })
  }
  return {
    capabilities: (config.botium.Capabilities && match(config.botium.Capabilities)) || [],
    sources: (config.botium.Sources && match(config.botium.Sources)) || [],
    envs: (config.botium.Envs && matchEnv(config.botium.Envs)) || []
  }
}

export function ExportBotiumJson({ capabilities, sources, envs }) {
  const match = (entries) => {
    const result = {}
    entries.forEach(entry => {
      if (entry.type === 'INT') {
        result[entry.name] = entry.intValue
      } else if (entry.type === 'BOOLEAN') {
        result[entry.name] = !!entry.booleanValue
      } else if (entry.type === 'JSON') {
        if (entry.jsonValue) {
          result[entry.name] = JSON.parse(entry.jsonValue)
        } else {
          result[entry.name] = ''
        }
      } else {
        result[entry.name] = entry.stringValue
      }
    })
    return result
  }
  return {
    botium: {
      Capabilities: (capabilities && match(capabilities)) || [],
      Sources: (sources && match(sources)) || [],
      Envs: (envs && match(envs)) || []
    }
  }
}

export function ExtractJsonCapabilityValue(capabilities, name, defaultValue) {
  const cap = capabilities.find(c => c.name === name)
  const value = (cap && (cap.jsonValue || cap.stringValue))
  if (_.isUndefined(value)) return defaultValue
  else return value
}
export function ExtractStringCapabilityValue(capabilities, name, defaultValue) {
  const cap = capabilities.find(c => c.name === name)
  const value = (cap && cap.stringValue)
  if (_.isUndefined(value)) return defaultValue
  else return value
}
export function ExtractBooleanCapabilityValue(capabilities, name, defaultValue) {
  const cap = capabilities.find(c => c.name === name)
  const value = (cap && cap.booleanValue)
  if (_.isUndefined(value)) return defaultValue
  else return value
}
export const GENESYS_LANGUAGES = [
  { key: 'it-it', label: 'Italian (Italy)' },
  { key: 'de-de', label: 'German (Germany)' },
  { key: 'pt-pt', label: 'Portuguese (Portugal)' },
  { key: 'en-us', label: 'English (US)' },
  { key: 'pt-br', label: 'Portuguese (Brazil)' },
  { key: 'es-es', label: 'Spanish (Spain)' },
  { key: 'en-au', label: 'English (Australia)' },
  { key: 'es-us', label: 'Spanish (US)' },
  { key: 'fr-fr', label: 'French (France)' },
  { key: 'fr-ca', label: 'French (Canada)' },
  { key: 'es-mx', label: 'Spanish (Mexico)' },
  { key: 'en-gb', label: 'English (UK)' }
]
export const AZURE_CLU_LANGUAGES = [
  { key: 'en-us', label: 'English (US)' },
  { key: 'af', label: 'Afrikaans' },
  { key: 'am', label: 'Amharic' },
  { key: 'ar', label: 'Arabic' },
  { key: 'as', label: 'Assamese' },
  { key: 'az', label: 'Azerbaijani' },
  { key: 'be', label: 'Belarusian' },
  { key: 'bg', label: 'Bulgarian' },
  { key: 'bn', label: 'Bengali' },
  { key: 'br', label: 'Breton' },
  { key: 'bs', label: 'Bosnian' },
  { key: 'ca', label: 'Catalan' },
  { key: 'cs', label: 'Czech' },
  { key: 'cy', label: 'Welsh' },
  { key: 'da', label: 'Danish' },
  { key: 'de', label: 'German' },
  { key: 'el', label: 'Greek' },
  { key: 'en-gb', label: 'English (UK)' },
  { key: 'eo', label: 'Esperanto' },
  { key: 'es', label: 'Spanish' },
  { key: 'et', label: 'Estonian' },
  { key: 'eu', label: 'Basque' },
  { key: 'fa', label: 'Persian (Farsi)' },
  { key: 'fi', label: 'Finnish' },
  { key: 'fr', label: 'French' },
  { key: 'fy', label: 'Western Frisian' },
  { key: 'ga', label: 'Irish' },
  { key: 'gd', label: 'Scottish Gaelic' },
  { key: 'gl', label: 'Galician' },
  { key: 'gu', label: 'Gujarati' },
  { key: 'ha', label: 'Hausa' },
  { key: 'he', label: 'Hebrew' },
  { key: 'hi', label: 'Hindi' },
  { key: 'hr', label: 'Croatian' },
  { key: 'hu', label: 'Hungarian' },
  { key: 'hy', label: 'Armenian' },
  { key: 'id', label: 'Indonesian' },
  { key: 'it', label: 'Italian' },
  { key: 'ja', label: 'Japanese' },
  { key: 'jv', label: 'Javanese' },
  { key: 'ka', label: 'Georgian' },
  { key: 'kk', label: 'Kazakh' },
  { key: 'km', label: 'Khmer' },
  { key: 'kn', label: 'Kannada' },
  { key: 'ko', label: 'Korean' },
  { key: 'ku', label: 'Kurdish (Kurmanji)' },
  { key: 'ky', label: 'Kyrgyz' },
  { key: 'la', label: 'Latin' },
  { key: 'lo', label: 'Lao' },
  { key: 'lt', label: 'Lithuanian' },
  { key: 'lv', label: 'Latvian' },
  { key: 'mg', label: 'Malagasy' },
  { key: 'mk', label: 'Macedonian' },
  { key: 'ml', label: 'Malayalam' },
  { key: 'mn', label: 'Mongolian' },
  { key: 'mr', label: 'Marathi' },
  { key: 'ms', label: 'Malay' },
  { key: 'my', label: 'Burmese' },
  { key: 'ne', label: 'Nepali' },
  { key: 'nl', label: 'Dutch' },
  { key: 'nb', label: 'Norwegian (Bokmal)' },
  { key: 'or', label: 'Oriya' },
  { key: 'pa', label: 'Punjabi' },
  { key: 'pl', label: 'Polish' },
  { key: 'ps', label: 'Pashto' },
  { key: 'pt-br', label: 'Portuguese (Brazil)' },
  { key: 'pt-pt', label: 'Portuguese (Portugal)' },
  { key: 'ro', label: 'Romanian' },
  { key: 'ru', label: 'Russian' },
  { key: 'sa', label: 'Sanskrit' },
  { key: 'sd', label: 'Sindhi' },
  { key: 'si', label: 'Sinhala' },
  { key: 'sk', label: 'Slovak' },
  { key: 'sl', label: 'Slovenian' },
  { key: 'so', label: 'Somali' },
  { key: 'sq', label: 'Albanian' },
  { key: 'sr', label: 'Serbian' },
  { key: 'su', label: 'Sundanese' },
  { key: 'sv', label: 'Swedish' },
  { key: 'sw', label: 'Swahili' },
  { key: 'ta', label: 'Tamil' },
  { key: 'te', label: 'Telugu' },
  { key: 'th', label: 'Thai' },
  { key: 'tl', label: 'Filipino' },
  { key: 'tr', label: 'Turkish' },
  { key: 'ug', label: 'Uyghur' },
  { key: 'uk', label: 'Ukrainian' },
  { key: 'ur', label: 'Urdu' },
  { key: 'uz', label: 'Uzbek' },
  { key: 'vi', label: 'Vietnamese' },
  { key: 'xh', label: 'Xhosa' },
  { key: 'yi', label: 'Yiddish' },
  { key: 'zh-hans', label: 'Chinese (Simplified)' },
  { key: 'zh-hant', label: 'Chinese (Traditional)' },
  { key: 'zu', label: 'Zulu' }
]
