import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import SimpleList from 'components/List/SimpleList.jsx'
import Button from 'components/Button/Button'
import { getComponentIcon } from 'components/Convo/ComponentChip.jsx'

import ShowIcon from 'components/Icon/ShowIcon'

import RegisteredComponent from './RegisteredComponent.jsx'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { REGISTEREDCOMPONENTS_QUERY } from './gql'

import { hasPermission } from 'botium-box-shared/security/permissions'

class RegisteredComponents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCounter: 0
    }
  }
  render() {
    const { location, history, license, user } = this.props

    return (
      <GridContainer>
        {license.registerComponents && hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE') && <GridItem xs={12} borderBottom middle>
          <Button
            secondary
            data-unique="btnNew"
            onClick={() => {
              this.setState({ newCounter: this.state.newCounter + 1, id: 'new' })
              history.push('/settings/components/registeredcomponents/new')
            }} 
            >
              <ShowIcon icon="plus" /> New
            </Button>
        </GridItem>}        
        <GridItem xs={12} sm={4} md={3} noPadding >
          <Query query={REGISTEREDCOMPONENTS_QUERY} notifyOnNetworkStatusChange={true}>
            {({ loading, error, data, refetch }) => {
              return <SimpleList
                name="tblRegisteredComponents"
                listData={(data && data.registeredcomponents && data.registeredcomponents.map(r => ({
                    id: r.id,
                    name: r.name,
                    secondary: `${r.ref} - ${r.type}`,
                    icon: getComponentIcon(r.ref),
                    selected: location.pathname.indexOf(r.id) >= 0,
                    onClick: () => history.push(`/settings/components/registeredcomponents/${r.id}`)
                  }))) || []}
                onRefresh={() => refetch()}
                pageLoading={loading}
              />
            }}
          </Query>
        </GridItem>
        <GridItem xs={12} sm={8} md={9} borderLeft>
        <Switch>
            <Route
              path="/settings/components/registeredcomponents/new"
              render={props => <RegisteredComponent key={`new_${this.state.newCounter}`} onReady={(id) => history.push(`/settings/components/registeredcomponents/${id}`)} {...props}/>}
            />
            <Route
              path="/settings/components/registeredcomponents/:id"
              render={props => <RegisteredComponent id={props.match.params.id} key={props.match.params.id} onReady={(id) => !id && history.push('/settings/components/registeredcomponents')} {...props}/>}
            />
          </Switch>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license })
)(withStyles(settingsStyle)(withRouter(RegisteredComponents)))
