//import { secondaryColor } from 'assets/jss/material-dashboard-react'
//import { whiteColor } from 'assets/jss/material-dashboard-react'

const customTabsStyle = theme => ({
  cardTitle: {
    float: 'left',
    padding: '10px 10px 10px 0px',
    lineHeight: '24px',
    
  },
  cardTitleSmall: {
    padding: '8px 10px 8px 0px',
  },
  cardTitleRTL: {
    float: 'right',
    padding: '10px 0px 10px 10px !important',
  },
  displayNone: {
    display: 'none !important',
  },
  scrollButtons: {
    flexBasis: '20px',
    backgroundColor: theme.colors.modalBg,
    '& svg': {
      fill: theme.colors.primary,
      paddingTop: 2,
    }
  },
  scrollButtonsSecondary: {
    flexBasis: '0px'
  },
  tabsRoot: {
    overflowX: 'visible',
    background: theme.colors.modalBg,
    borderRadius: 6,
    paddingTop: 6,
    minHeight: 55,
  },
  tabsSecondaryRoot: {
    //minHeight: 'unset !important',
    overflowX: 'visible',
    minHeight: 60,
    background: theme.colors.backgroundPrimary,
    borderRadius: 0,
  },
  tabsRootSmall: {
    //minHeight: 'unset !important',
    minHeight: '42px',
    marginTop: 6,
    marginBottom: 3
  },
  scrollable: {
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    overflowStyle: 'none',  /* IE and Edge */
    scrollbarWidth: 'none',  /* Firefox */
    position: 'relative',
  },
  tabRootButton: {
    minHeight: 'unset !important',
    minWidth: 'unset !important',
    width: 'unset !important',
    height: 'unset !important',
    maxWidth: 'unset !important',
    maxHeight: 'unset !important',
    padding: '15px 16px 14px 16px',
    borderRadius: '6px',
    //lineHeight: '24px',
    border: '0',
    opacity: 1,
    color: theme.colors.primary,
    marginLeft: '4px',
    fontSize: '12px !important',
    '&:last-child': {
      marginLeft: '0px',
    },
    '&:hover': {
      backgroundColor: theme.colors.topmenuHover,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },
  tabSecondaryRootButton: {
    minHeight: 'unset !important',
    minWidth: 'unset !important',
    width: 'unset !important',
    height: 'unset !important',
    maxWidth: 'unset !important',
    maxHeight: 'unset !important',
    padding: '22px 16px 18px 16px',
    borderRadius: '0px',
    //lineHeight: '24px',
    border: '0 !important',
    opacity: 1,
    boxSizing: 'border-box',
    color: theme.colors.primary,
    borderBottom: `5px solid transparent!important`,
    marginRight: '4px',
    //paddingTop: 16,
    //paddingBottom: 18,
    '&:last-child': {
      marginLeft: '0px',
    },
    '& svg' :{
      display: 'none',
    },
    '&:hover': {
      borderBottom: `5px solid ${theme.colors.common.secondary}!important`,
      
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!important`,
      borderRadius: '6px',

    },
  },
  tabRootButtonSmall: {
    padding: '1px 6px !important',
  },
  tabRootButtonRight: {
    position: 'absolute',
    right: 0,
  },
  tabRootButtonRightMd: {
    position: 'absolute',
    right: 0,
  },
  '@media (max-width: 1600px)': {
    tabRootButtonRightMd: {
      position: 'relative',
      right: 0,
    },
  },
  tabLabelContainer: {
    padding: '0px',
  },
  tabLabel: {
    fontWeight: '400',
    fontSize: '12px',
  },
  tabSecondaryLabel: {
    fontWeight: '400',
    fontSize: '12px',
    color: theme.colors.primary
    
  },
  tabSelected: {
    backgroundColor: theme.colors.menubuttontext,
    color: theme.colors.common.primary,
    cursor: 'default',
    transition: 'unset',
    '&:hover,&:focus': {
      backgroundColor: theme.colors.menubuttontext,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },
  tabSecondarySelected: {
    backgroundColor: theme.colors.backgroundPrimary,
    borderBottom: `5px solid ${theme.colors.common.secondary} !important`,
    transition: 'unset',
    boxSizing: 'border-box',
    //paddingTop: 22,
    //paddingBottom: 20,
    borderRadius: 0,
    '& span' :{
      color: theme.colors.primary,
    },
    '& svg' :{
      display: 'none',
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },
  tabWrapper: {
    display: 'inline-block',
    minHeight: 'unset !important',
    minWidth: 'unset !important',
    width: 'unset !important',
    height: 'unset !important',
    maxWidth: 'unset !important',
    maxHeight: 'unset !important',
    '& > svg,& > .material-icons': {
      verticalAlign: 'middle',
      margin: '-1px 5px 0 0',
    },
  },
  forceHeight: {
    marginBottom: '0px !important'
  }
})

export default customTabsStyle
