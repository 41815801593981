import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'

import {
  renderFileUpload
} from 'components/Form/Form'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

class TestSetImport extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      uploading: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.testsetUploading !== this.props.testsetUploading) {
      this.setState({
        uploading: this.props.testsetUploading
      })
    }
  }

  render() {
    const { onFileLoaded, dataUnique, importOnDrop } = this.props

    return (
      <FormSpy
        subscription={{ values: true, form: true }}
        render={({ values, form: { change } }) => (
          <Field
            name="fileupload"
            component={renderFileUpload}
            data-unique={dataUnique}
            label={`Select/Drop File`}
            values={values}
            change={change}
            processingFiles={this.state.uploading}
            onDrop={() => {
              if (importOnDrop) {
                this.setState({
                  uploading: true
                })
              }}
            }
            onFileLoaded={(filename, filecontent) => {
              change('filename', filename)
              change('filecontent', filecontent)
              if (onFileLoaded) onFileLoaded(filename, filecontent)
            }}
          />
        )}
      />
    )
  }
}

TestSetImport.defaultProps = {
  importOnDrop: true
}

export default connect(
  state => ({ token: state.token.token, user: state.token.user, license: state.settings.license })
)(withStyles(testsetsStyle)(TestSetImport))
