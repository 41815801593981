import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import deburr from 'lodash/deburr'
import keycode from 'keycode'
import Downshift from 'downshift'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from 'components/Menu/MenuItem'
import List from '@material-ui/core/List'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemText from 'components/List/ListItem/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Chip from 'components/Chip/Chip'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import { showError, DangerSpan } from 'components/Form/Form'

import downshiftMultipleStyle from 'assets/jss/material-dashboard-react/components/downshiftMultipleStyle.jsx'

function reorder (list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

function insert (list, item, position) {
  const result = Array.from(list || [])
  if (position >= 0) result.splice(position, 0, item)
  else result.push(item)
  return result
}

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          input: classes.input,
        },
        ...InputProps
      }}
      {...other}
    />
  )
}

function renderSuggestion({
  suggestion,
  keyFunc,
  labelFunc,
  iconFunc,
  iconAvatar,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  inputStyle
}) {
  const isHighlighted = highlightedIndex === index
  const isSelected =
    (selectedItem || []).findIndex(i => keyFunc(i) === keyFunc(suggestion)) > -1

  return (
    <MenuItem
      {...itemProps}
      key={keyFunc(suggestion)}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        ...inputStyle
      }}
      data-unique={keyFunc(suggestion)}
      data-value={labelFunc(suggestion)}
    >
      {iconFunc &&
        <List dense>
          <ListItem dense>
            {iconFunc(suggestion)}
            <ListItemText primary={labelFunc(suggestion)} />
          </ListItem>
        </List>
      }
      {!iconFunc && labelFunc(suggestion)}
    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.object.isRequired,
  keyFunc: PropTypes.func.isRequired,
  labelFunc: PropTypes.func.isRequired,
}

class DownshiftMultiple extends React.Component {
  state = {
    inputValue: '',
    inputPosition: -1,
    visided: false,
    active: false,
    anchorElement: null
  }

  getSuggestions = inputValue => {
    const { numsuggestions, labelFunc, addFunc, value } = this.props
    let { suggestions } = this.props

    const ownSuggestions = []
    if (addFunc) {
      const suggestionLabels = (suggestions || []).map(e => labelFunc(e))
      for (const entry of value) {
        if (!suggestionLabels.includes(entry)) {
          ownSuggestions.push(addFunc(entry))
        }
      }
    }

    suggestions = [...(suggestions || []), ...ownSuggestions]
    if (suggestions.length === 0) return []

    inputValue = deburr(inputValue.trim()).toLowerCase()
    const inputLength = inputValue.length

    const parts = inputValue.split(' ')

    return inputLength === 0
      ? suggestions.slice(0, numsuggestions)
      : suggestions.filter(suggestion => {
          let count = 0
          const keep =
            count < (numsuggestions || 10) &&
            (labelFunc(suggestion)
              .slice(0, inputLength)
              .toLowerCase() === inputValue ||
              (inputLength >= 2 &&
                labelFunc(suggestion)
                  .toLowerCase()
                  .indexOf(inputValue) >= 0) ||
              parts.reduce(
                (acc, part) =>
                  !acc
                    ? false
                    : labelFunc(suggestion)
                        .toLowerCase()
                        .indexOf(part) >= 0,
                true,
              ))

          if (keep) {
            count += 1
          }
          return keep
        })
  }

  getSuggestion = inputValue => {
    const { suggestions, labelFunc } = this.props

    if (!suggestions || suggestions.length === 0) return null

    inputValue = deburr(inputValue.trim()).toLowerCase()
    const inputLength = inputValue.length

    if (!inputLength) return null

    const result = suggestions.find(e => labelFunc(e).toLowerCase() === inputValue)

    return result || null
  }

  handleKeyDown = (event, suggestionsLength, highlightedIndex) => {

    const { onChange, value, addFunc, labelFunc } = this.props

    const { inputValue, inputPosition } = this.state
    if (value.length && !inputValue.length && keycode(event) === 'backspace') {
      onChange(value.slice(0, value.length - 1))
      event.preventDefault()
    }
    if (inputValue.trim().length && (keycode(event) === 'enter' || keycode(event) === 'tab')) {
      if (highlightedIndex >= suggestionsLength || _.isNil(highlightedIndex)) {
        if (addFunc) {
          onChange(insert(value, addFunc(inputValue.trim()), inputPosition))
        } else {
          if(keycode(event) !== 'tab') {
            const matchingSuggestion = this.getSuggestion(inputValue.trim())
            if (matchingSuggestion) {
              onChange(insert(value, labelFunc(matchingSuggestion), inputPosition))
            }
          }
        }
        this.setState({
          inputValue: '',
          inputPosition: -1
        })
        if(keycode(event) !== 'tab') {
          event.preventDefault()
        }
      }
    }

    if (keycode(event) === 'enter') {
      event.preventDefault()
    }
  }

  handleBlur = event => {
    const { onChange, value, addFunc, labelFunc } = this.props

    const { inputValue, inputPosition } = this.state
    if (inputValue.trim().length) {
      if (addFunc) {
        onChange(insert(value, addFunc(inputValue.trim()), inputPosition))
      } else {
        const matchingSuggestion = this.getSuggestion(inputValue.trim())
        if (matchingSuggestion) {
          onChange(insert(value, labelFunc(matchingSuggestion), inputPosition))
        } else {
          onChange(value)
        }
      }
      this.setState({
        inputValue: '',
        inputPosition: -1
      })
    }
  }

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value })
  }

  handleChange = item => {
    const { onChange, value, keyFunc, unique } = this.props
    const { inputPosition } = this.state

    if (item && (!value || !value.findIndex || !unique || value.length === 0 || value.findIndex(i => keyFunc(i) === keyFunc(item)) === -1)) {
      onChange(insert(value, item, inputPosition))
    }

    this.setState({
      inputValue: '',
      inputPosition: -1
    })
  }

  handleDelete = (item, index) => () => {
    const { onChange, value } = this.props
    const selectedItem = [...value]
    selectedItem.splice(index, 1)
    onChange(selectedItem)
    return { selectedItem }
  }

  handleClick = (item, index) => () => {
    const { onClick, onChange, value, addFunc, labelFunc } = this.props

    if (onClick) return onClick(item, index)

    if (!addFunc) return

    const selectedItem = [...value]
    selectedItem.splice(index, 1)
    onChange(selectedItem)

    this.setState({
      inputValue: labelFunc(item),
      inputPosition: index
    })
  }

  onDragEnd(result) {
    const { onChange, value } = this.props
    if (!value) return
    if (!result.destination) return

    const newValue = reorder(
      value,
      result.source.index,
      result.destination.index
    )
    onChange(newValue)
  }

  render() {
    const { classes, helperText, formControlProps, hasRequired, meta, name, label, value, keyFunc, labelFunc, iconFunc, tooltipFunc, suggestions, unique, inputStyle, placeholder, error, ...rest } = this.props
    const { inputValue } = this.state

    return (
      <FormControl
        {...formControlProps}
        error={!!showError(meta) || inputValue.length > 0}
        fullWidth
        margin="normal"
        className={classes.customformContor}
      >
      <Downshift
        id={name}
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={value}
        itemToString={labelFunc}
        error={error || inputValue.length > 0}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex,
        }) => (
          <div className={classes.container} style={{overflow: 'auto'}}>
            <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
              {renderInput({
                onClick: e => {
                  this.setState({
                    anchorElement: e.currentTarget
                  })
                },
                error: error || inputValue.length > 0,
                fullWidth: true,
                classes: {
                  input: classes.input,
                },
                inputProps: {
                  style: { ...inputStyle },
                  placeholder: 'Press Enter to add text',
                  onFocus: () => this.setState({ visited: true, active: true }),
                  onBlur: () => this.setState({ active: false })
                },
                InputLabelProps: {
                  shrink: true,
                  htmlFor: name + '-input',
                  classes: {
                    root: classes.inputLabelRoot,
                    shrink: classes.inputLabelShrink,
                    formControl: classes.inputLabelformControl
                  }
                },
                InputProps: getInputProps({
                  startAdornment: <Droppable droppableId="droppable"  direction="horizontal">{(provided, snapshot) => (
                    <span {...provided.droppableProps} ref={provided.innerRef} className={classes.customdraggable}>
                      {value && value.map && value.map((item, index) => (
                        <Draggable key={index} draggableId={`${unique ? keyFunc(item) : index}`} index={index}>{(provided, snapshot) => (
                          <span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Chip
                              avatar={iconFunc && <Avatar>{iconFunc(item)}</Avatar>}
                              tooltip={(tooltipFunc && tooltipFunc(item)) || labelFunc(item)}
                              key={index}
                              data-unique={`chip_${unique ? keyFunc(item) : index}`}
                              tabIndex={-1}
                              label={labelFunc(item)}
                              className={classes.chip}
                              onDelete={this.handleDelete(item, index)}
                              onClick={this.handleClick(item, index)}
                            />
                          </span>
                        )}</Draggable>
                      ))}
                      {provided.placeholder}
                      </span>
                    )}</Droppable>,
                    onChange: this.handleInputChange,
                    onKeyDown: event => this.handleKeyDown(event, this.getSuggestions(inputValue2).length, highlightedIndex),
                    onBlur: this.handleBlur,
                    'data-unique': rest['data-unique'],
                    'data-loading-state': !_.isUndefined(suggestions) && suggestions.length > 0 ? 'READY' : 'EMPTY',
                  }),
                  label,
                  placeholder
                })
              }
            </DragDropContext>
            {(suggestions.length > 0 && this.getSuggestions(inputValue2).length > 0 && isOpen && this.state.active) ? (
              <Popper placement="bottom-start" style={{ zIndex: 9999 }} id={`${rest['data-unique']}-popper`} open={isOpen} anchorEl={this.state.anchorElement} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.paper} style={{overflow:'auto', maxHeight:'250px', position: 'relative'}} square>
                    {this.getSuggestions(inputValue2).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        keyFunc,
                        labelFunc,
                        iconFunc,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem: selectedItem2,
                        inputStyle
                      }),
                    )}
                  </Paper>
                </Fade>
              )}
              </Popper>
            ) : null}
          </div>
        )}
      </Downshift>
      {showError(meta) && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}>{((inputValue.length > 0) ? <><DangerSpan>Press Enter to add text!</DangerSpan><br /><br/><DangerSpan>{meta.error || meta.submitError}</DangerSpan></> : <DangerSpan>{meta.error || meta.submitError}</DangerSpan>)}</FormHelperText>}
      {!showError(meta) && (helperText || (this.state.visited && !this.state.active && hasRequired && value && value.length === 0)) && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}>{hasRequired && value && value.length === 0 ? ((inputValue.length > 0) ? <><DangerSpan>Press Enter to add text!</DangerSpan><br /><br /><DangerSpan>{meta.error || meta.submitError}</DangerSpan></> : <DangerSpan>{meta.error || meta.submitError}</DangerSpan>) : ((inputValue.length > 0) ? <><DangerSpan>Press Enter to add text!</DangerSpan><br /><br />{helperText}</> : helperText)}</FormHelperText>}
    </FormControl>
    )
  }
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  keyFunc: PropTypes.func.isRequired,
  labelFunc: PropTypes.func.isRequired,
  iconFunc: PropTypes.func,
  tooltipFunc: PropTypes.func,
  addFunc: PropTypes.func,
  numsuggestions: PropTypes.number,
  unique: PropTypes.bool
}

DownshiftMultiple.defaultProps = {
  suggestions: [],
  numsuggestions: 1,
  keyFunc: s => s,
  labelFunc: s => s,
  addFunc: s => s,
  unique: false
}

export default withStyles(downshiftMultipleStyle)(DownshiftMultiple)
