import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { compose, graphql, Query, withApollo } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import SimpleList from 'components/List/SimpleList.jsx'
import Button from 'components/Button/Button'

import ShowIcon from 'components/Icon/ShowIcon'

import SpeechSynthesisProfile from './SpeechSynthesisProfile.jsx'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { CLONE_SPEECHSYNTHESISPROFILE, DELETE_SPEECHSYNTHESISPROFILE, SPEECHSYNTHESISPROFILES_QUERY } from './gql'
import { downloadfileformpost } from 'helper/downloadHelper.js'
import config from 'config.js'
import { setAlertErrorMessage, setAlertSuccessMessage } from 'actions/alert.js'
import SimpleConfirmationDialog from 'components/Dialog/SimpleConfirmationDialog.jsx'

class SpeechSynthesisProfiles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCounter: 0
    }
  }
  render() {
    const { history, location, cloneSpeechSynthesisProfile, deleteSpeechSynthesisProfile, setAlertErrorMessage, setAlertSuccessMessage, token } = this.props
    return (
      <GridContainer>
        <GridItem xs={12} borderBottom middle>
          <Button
            secondary
            data-unique="btnNew"
            onClick={() => {
              this.setState({ newCounter: this.state.newCounter + 1, id: 'new' })
              history.push('/settings/voice/speechsynthesis/new')
            }} 
            >
              <ShowIcon icon="plus" /> New
            </Button>
        </GridItem>       
        <GridItem xs={12} sm={4} md={3} noPadding>
          <Query query={SPEECHSYNTHESISPROFILES_QUERY} notifyOnNetworkStatusChange={true}>
            {({ loading, error, data, refetch }) => {
              return <SimpleList
                name="tblProfiles"
                listData={(data && data.speechSynthesisProfiles && data.speechSynthesisProfiles.map(a => ({
                  id: a.id,
                  name: a.name,
                  secondary: `${a.engine} - ${a.language || '(no language)'} - ${a.voice || '(no voice)'}`,
                  icon: <ShowIcon icon="comment" />,
                  selected: location.pathname.indexOf(a.id) >= 0,
                  onClick: () => history.push(`/settings/voice/speechsynthesis/${a.id}`),
                  actions: [
                    {
                      id: `delete_${a.id}`,
                      icon: 'trash',
                      name: 'Delete',
                      dataUnique: `btnProfile_${a.name}_Delete`,
                      onClick: () => {
                        this.setState({
                          confirmDelete: true,
                          confirmDeleteProfile: a
                        })
                      }
                    },
                    {
                      id: `clone_${a.id}`,
                      icon: 'clone',
                      name: 'Clone',
                      dataUnique: `btnProfile_${a.name}_Clone`,
                      onClick: async () => {
                        await cloneSpeechSynthesisProfile({
                          variables: { id: a.id },
                          refetchQueries: [ { query: SPEECHSYNTHESISPROFILES_QUERY } ]
                        })
                      }
                    },
                    {
                      id: `export_${a.id}`,
                      icon: 'external-link',
                      name: 'Export',
                      dataUnique: `btnProfile_${a.name}_Export`,
                      onClick: () => {
                        downloadfileformpost(`${config.api.base}/modelexport/speechSynthesisProfile/${a.id}`, token).then(() => setAlertSuccessMessage('Export successful')).catch(err => setAlertErrorMessage(err.message))
                      }
                    }
                  ],
                }))) || []}
                onRefresh={() => refetch()}
                pageLoading={loading}
              />
            }}
          </Query>
          <SimpleConfirmationDialog danger
              showConfirmationDialog={!!this.state.confirmDelete}
              confirmationText={`Are you sure you want to delete profile '${this.state.confirmDeleteProfile && this.state.confirmDeleteProfile.name}' ?`}
              requireCheck={true}
              onOk={async () => {
                try {
                  await deleteSpeechSynthesisProfile({
                    variables: {
                      id: this.state.confirmDeleteProfile.id
                    },
                    refetchQueries: [ { query: SPEECHSYNTHESISPROFILES_QUERY } ]
                  })
                  setAlertSuccessMessage('Profile deleted')
                } catch(error) {
                  setAlertErrorMessage('Profile deletion failed', error)
                }
                this.setState({ confirmDeleteProfile: null, confirmDelete: false })
              }}
              onCancel={() => this.setState({ confirmDeleteProfile: null, confirmDelete: false })}
            />
        </GridItem>
        <GridItem xs={12} sm={8} md={9} borderLeft>
        <Switch>
            <Route
              path="/settings/voice/speechsynthesis/new"
              render={props => <SpeechSynthesisProfile key={`new_${this.state.newCounter}`} onReady={(id) => history.push(`/settings/voice/speechsynthesis/${id}`)} {...props}/>}
            />
            <Route
              path="/settings/voice/speechsynthesis/:id"
              render={props => <SpeechSynthesisProfile id={props.match.params.id} key={props.match.params.id} onReady={(id) => !id && history.push('/settings/voice/speechsynthesis')} {...props}/>}
            />
          </Switch>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withRouter(
  compose(
    withStyles(settingsStyle, { withTheme: true }),
    connect(
      state => ({ user: state.token.user, token: state.token.token }),
      { setAlertSuccessMessage, setAlertErrorMessage },
    ),
    graphql(CLONE_SPEECHSYNTHESISPROFILE, {
      props: ({ mutate }) => ({
        cloneSpeechSynthesisProfile: args => mutate(args)
      }),
    }),
    graphql(DELETE_SPEECHSYNTHESISPROFILE, {
      props: ({ mutate }) => ({
        deleteSpeechSynthesisProfile: args => mutate(args)
      }),
    }),
  )
    (withApollo(SpeechSynthesisProfiles)),
)
