import { SET_FEED, CLEAR_FEED } from '../constants'

export function clearFeed(feed) {
  return {
    type: CLEAR_FEED,
    payload: {
      feed
    }
  }
}

export function setFeed(feed, href, entries, date) {
  return {
    type: SET_FEED,
    payload: {
      feed,
      href,
      entries,
      date
    }
  }
}
