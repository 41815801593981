import React from 'react'
import { connect } from 'react-redux'
import { Mutation, Query } from 'react-apollo'
import { Form } from 'react-final-form'
import config from 'config'
import moment from 'moment'
// @material-ui/core components
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import Field from 'components/Form/OptionalField'
import Button from 'components/Button/Button'
import {
  renderIntField,
  renderCheckbox,
  minValue,
  parseInteger,
  FormActionsToolbar
} from 'components/Form/Form'
import QueryStatus from 'components/Info/QueryStatus'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Divider from 'components/Divider/Divider'
import { downloadfileformpost } from 'helper/downloadHelper'

import Users from './Users.jsx'
import UserRoles from './UserRoles.jsx'
import NamespacePermissions from './NamespacePermissions.jsx'
import Namespaces from './Namespaces.jsx'
import ApiKeys from './ApiKeys.jsx'

import { hasPermission, hasAnyPermission } from 'botium-box-shared/security/permissions'

import ShowIcon from 'components/Icon/ShowIcon'

import {
  SYSTEMSETTINGS_QUERY,
  UPDATE_SYSTEMSETTINGS
} from './gql'

class SecuritySettings extends React.Component {

  renderPasswordPolicyForm(systemsettings) {
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props

    return <Mutation
      mutation={UPDATE_SYSTEMSETTINGS}
      refetchQueries={[
        {
          query: SYSTEMSETTINGS_QUERY
        }
      ]}
    >
      {(mutateSystemSettings, { loading, error }) => (
        <Form
          onSubmit={async (values, form) => {
            try {
              await mutateSystemSettings({
                variables: {
                  systemSettings: {
                    passwordPolicyMinimumLength: values.passwordPolicyMinimumLength || null,
                    passwordPolicyMinNumbers: values.passwordPolicyMinNumbers || null,
                    passwordPolicyMinSpecial: values.passwordPolicyMinSpecial || null,
                    passwordPolicyMinLowerCase: values.passwordPolicyMinLowerCase || null,
                    passwordPolicyMinUpperCase: values.passwordPolicyMinUpperCase || null,
                    passwordPolicyExpiryDays: values.passwordPolicyExpiryDays || null,
                    passwordPolicyLastX: values.passwordPolicyLastX || null
                  }
                },
              })
              setAlertSuccessMessage('Password policy updated')
            } catch (error) {
              setAlertErrorMessage('Password policy update failed', error)
            }
          }}
          initialValues={systemsettings}
          render={({
            handleSubmit,
            submitting,
            invalid
          }) => (<form onSubmit={handleSubmit}>
            <UnsavedFormSpy />
            <GridContainer>
              <GridItem xs={12} lg={8}>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="passwordPolicyMinimumLength"
                      component={renderIntField}
                      validate={minValue(0)}
                      label="Minimum Length"
                      parse={parseInteger}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="passwordPolicyMinNumbers"
                      component={renderIntField}
                      validate={minValue(0)}
                      label="Minimum Digit Count (0-9)"
                      parse={parseInteger}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="passwordPolicyMinSpecial"
                      component={renderIntField}
                      validate={minValue(0)}
                      label="Minimum Special Characters Count"
                      parse={parseInteger}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="passwordPolicyMinLowerCase"
                      component={renderIntField}
                      validate={minValue(0)}
                      label="Minimum Lower-Case Characters Count"
                      parse={parseInteger}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="passwordPolicyMinUpperCase"
                      component={renderIntField}
                      validate={minValue(0)}
                      label="Minimum Upper-Case Characters Count"
                      parse={parseInteger}
                    />
                  </GridItem>
                  <GridItem sm={4}></GridItem>
                  <GridItem xs={12}><Divider /></GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="passwordPolicyExpiryDays"
                      component={renderIntField}
                      validate={minValue(0)}
                      label="Password Expiry Days"
                      helperText="User is forced to change password when expired"
                      parse={parseInteger}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="passwordPolicyLastX"
                      component={renderIntField}
                      validate={minValue(0)}
                      label="Different than last X Passwords"
                      helperText="User is forced to use another password than the X last used passwords"
                      parse={parseInteger}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} lg={8} largePadding>
                <FormActionsToolbar
                  leftButtons={<>
                  </>}
                  rightButtons={<>
                    <Button
                      type="submit"
                      disabled={submitting || invalid}
                    >
                      {submitting && <LoadingIndicator alt />}
                      {!submitting && <ShowIcon icon="save" />}
                      Save Password Policy
                    </Button>
                  </>}
                />
              </GridItem>
            </GridContainer>
          </form>)
          }
        />)
      }
    </Mutation>
  }

  renderUserActivityForm(systemsettings) {
    const { setAlertSuccessMessage, setAlertErrorMessage, token } = this.props

    return <Mutation
      mutation={UPDATE_SYSTEMSETTINGS}
      refetchQueries={[
        {
          query: SYSTEMSETTINGS_QUERY
        }
      ]}
    >
      {(mutateSystemSettings, { loading, error }) => (
        <Form
          onSubmit={async (values, form) => {
            try {
              await mutateSystemSettings({
                variables: {
                  systemSettings: {
                    skipUserActivityLog: !!values.skipUserActivityLog,
                    keepUserActivityLogDays: values.keepUserActivityLogDays || null
                  }
                },
              })
              setAlertSuccessMessage('Activity logging policy updated')
            } catch (error) {
              setAlertErrorMessage('Activity logging policy update failed', error)
            }
          }}
          initialValues={systemsettings}
          render={({
            handleSubmit,
            submitting,
            invalid
          }) => (<form onSubmit={handleSubmit}>
            <UnsavedFormSpy />
            <GridContainer>
              <GridItem xs={12} lg={8}>
                <GridContainer>
                  <GridItem sm={4}>
                    <Field
                      name="skipUserActivityLog"
                      component={renderCheckbox}
                      label="Disable User Activity Logging"
                      type="checkbox"
                    />
                  </GridItem>
                  <GridItem sm={4}>
                    <Field
                      name="keepUserActivityLogDays"
                      component={renderIntField}
                      validate={minValue(0)}
                      label="Keep User Activity Logs (in days)"
                      parse={parseInteger}
                    />
                  </GridItem>
                  <GridItem sm={4}></GridItem>
                  <GridItem xs={12}><Divider /></GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} lg={8} largePadding>
                <FormActionsToolbar
                  rightButtons={<>
                    <Button
                      type="submit"
                      disabled={submitting || invalid}
                    >
                      {submitting && <LoadingIndicator alt />}
                      {!submitting && <ShowIcon icon="save" />}
                      Save Activity Logging Policy
                    </Button>
                  </>}
                />
              </GridItem>
              <GridItem xs={12} lg={8} largePadding>
                <FormActionsToolbar
                  rightButtons={<>
                    <Button data-unique="btnUserActivityLogDownloadAll"
                      secondary
                      onClick={() => {
                        downloadfileformpost(`${config.api.base}/useractivitylog`, token).catch(err => setAlertErrorMessage(err.message))
                      }}>
                      <ShowIcon icon="cloud-download-alt" /> Download Full Log
                    </Button>
                    <Button data-unique="btnUserActivityLogDownloadMonth"
                      secondary
                      onClick={() => {
                        downloadfileformpost(`${config.api.base}/useractivitylog/${moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD')}`, token).catch(err => setAlertErrorMessage(err.message))
                      }}>
                      <ShowIcon icon="cloud-download-alt" /> Download Last 30 Days
                    </Button>
                    <Button data-unique="btnUserActivityLogDownloadWeek"
                      secondary
                      onClick={() => {
                        downloadfileformpost(`${config.api.base}/useractivitylog/${moment().startOf('day').subtract(7, 'days').format('YYYY-MM-DD')}`, token).catch(err => setAlertErrorMessage(err.message))
                      }}>
                      <ShowIcon icon="cloud-download-alt" /> Download Last 7 Days
                    </Button>
                    <Button data-unique="btnUserActivityLogDownloadDay"
                      secondary
                      onClick={() => {
                        downloadfileformpost(`${config.api.base}/useractivitylog/${moment().startOf('day').format('YYYY-MM-DD')}`, token).catch(err => setAlertErrorMessage(err.message))
                      }}>
                      <ShowIcon icon="cloud-download-alt" /> Download Today
                    </Button>
                  </>}
                />
              </GridItem>              
            </GridContainer>
          </form>)
          }
        />)
      }
    </Mutation>
  }

  render() {
    const { user, license } = this.props
    return (<CustomTabsSecondary
      name="tabSettingsSecurity"
      headerColor="info"
      tabs={[
        {
          tabName: 'Users',
          tabIcon: <ShowIcon icon="user" />,
          disabled: !hasPermission(user, 'USERS_MANAGE'),
          tabContent: <GridContainer><GridItem xs={12}><Users/></GridItem></GridContainer>,
          locationPrefix: '/settings/security/users',
          dataUnique: 'tabSettingsSecurityUsers'
        },
        {
          tabName: 'Roles and Permissions',
          tabIcon: <ShowIcon icon="shield-alt" />,
          disabled: !hasPermission(user, 'USERS_MANAGE'),
          tabContent: <GridContainer><GridItem xs={12}><UserRoles/></GridItem></GridContainer>,
          locationPrefix: '/settings/security/userroles',
          dataUnique: 'tabSettingsSecurityUserRoles'
        },
        {
          tabName: 'Namespace Permissions',
          tabIcon: <ShowIcon icon="key" />,
          disabled: !hasPermission(user, 'USERS_MANAGE'),
          tabContent: <GridContainer><GridItem xs={12}><NamespacePermissions/></GridItem></GridContainer>,
          locationPrefix: '/settings/security/namespacepermissions',
          dataUnique: 'tabSettingsSecurityNamespacePermissions'
        },
        {
          tabName: 'Namespaces',
          tabIcon: <ShowIcon icon="folder" />,
          disabled: !hasPermission(user, 'USERS_MANAGE'),
          tabContent: <GridContainer><GridItem xs={12}><Namespaces/></GridItem></GridContainer>,
          locationPrefix: '/settings/security/namespaces',
          dataUnique: 'tabSettingsSecurityNamespaces'
        },
        {
          tabName: 'API Keys',
          tabIcon: <ShowIcon icon="key" />,
          disabled: !hasAnyPermission(user, ['APIKEYS_SELECT', 'APIKEYS_MANAGE']),
          tabContent: <GridContainer><GridItem xs={12}><ApiKeys/></GridItem></GridContainer>,
          locationPrefix: '/settings/security/apikeys',
          dataUnique: 'tabSettingsSecurityApiKeys'
        },
        {
          tabName: 'Password Policy',
          tabIcon: <ShowIcon icon="wrench" />,
          disabled: !hasPermission(user, 'SYSTEMSETTINGS_MANAGE'),
          tabContent: <Query query={SYSTEMSETTINGS_QUERY}>
            {(queryResult) => <QueryStatus {...queryResult} query="systemsettings" card>{(data) => this.renderPasswordPolicyForm(data.systemsettings)}</QueryStatus>}
          </Query>,
          locationPrefix: '/settings/security/passwordpolicy',
          dataUnique: 'tabSettingsSecurityPasswordPolicy'
        },
        license.userActivity ? {
          tabName: 'Activity Logging',
          tabIcon: <ShowIcon icon="wrench" />,
          disabled: !hasPermission(user, 'SYSTEMSETTINGS_MANAGE'),
          tabContent: <Query query={SYSTEMSETTINGS_QUERY}>
            {(queryResult) => <QueryStatus {...queryResult} query="systemsettings" card>{(data) => this.renderUserActivityForm(data.systemsettings)}</QueryStatus>}
          </Query>,
          locationPrefix: '/settings/security/activitylogging',
          dataUnique: 'tabSettingsSecurityActivityLogging'
        } : null
      ].filter(t => t)}
    />)
  }
}

export default connect(
  state => ({ token: state.token.token, user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage },
)(SecuritySettings)
