import React from 'react'
import ChatbotSelector from './ChatbotSelector'

export default function () {
  return <ChatbotSelector
    link="/testdatawizards/nlpdownload/"
    title="Conversation Model Downloader"
    subtitle="Listing all your Chatbots supporting download"
    queryVariables={{ connectorFeatureRequired: 'testCaseGeneration' }}
  />
}
