import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, compose, graphql, withApollo } from 'react-apollo'
// core components
import DateFormat from 'components/Info/DateFormat'
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { renderTextField, renderPasswordField, renderAutoSuggest, required, email, composeValidators, FormActionsToolbar } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'
import { validateUserNameUnique, validateUserEmailUnique } from './validators'

import {
  USERS_QUERY,
  USERROLES_QUERY,
  USER_QUERY,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  SEND_USER_WELCOME_EMAIL,
  EXPIRE_PASSWORD,
  RefetchUserQueries
} from './gql'

import LoadingIndicator from 'components/Icon/LoadingIndicator'

class User extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emptyUser: { roles: [ ] }
    }
  }

  renderForm(user) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady } = this.props
    const { userRolesData } = this.props

    return (
      <Mutation
        mutation={user.id ? UPDATE_USER : CREATE_USER}
        refetchQueries={[
          ...RefetchUserQueries(user.id)
        ]}
      >
        {(mutateUser, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (user.id) {
                try {
                  const res = await mutateUser({
                    variables: {
                      id: user.id,
                      user: {
                        name: values.name,
                        email: values.email,
                        password: values.password,
                        roles: {
                          set: (values.roles || []).map(r => ({ id: r.id }))
                        }
                      },
                    },
                  })
                  form.initialize(res.data.updateUser)
                  setAlertSuccessMessage(`User updated`)
                  onReady(user.id)
                } catch(error) {
                  setAlertErrorMessage(`User update failed.`, error)
                }
              } else {
                try {
                  const res = await mutateUser({
                    variables: {
                      user: {
                        name: values.name,
                        email: values.email,
                        password: values.password,
                        roles: {
                          connect: (values.roles || []).map(r => ({ id: r.id }))
                        }
                      },
                    },
                  })
                  form.initialize(res.data.createUser)
                  setAlertSuccessMessage(`User registered`)
                  onReady(res.data.createUser.id)
                } catch(error) {
                  setAlertErrorMessage(`User register failed.`, error)
                }
              }
            }}
            initialValues={user}
            render={({
              handleSubmit,
              submitting,
              invalid
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="User Name"
                      validate={composeValidators(required, async (value) => {
                        if (value) {
                          return validateUserNameUnique(this.props.client, user.id, value)
                        }
                      })}
                      disabled={user.sys}
                      data-unique="txtUserUserName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="email"
                      component={renderTextField}
                      label="Email Address"
                      validate={composeValidators(required, (value) => user.sys ? null: email(value), async (value) => {
                        if (value) {
                          return validateUserEmailUnique(this.props.client, user.id, value)
                        }
                      })}
                      disabled={user.sys}
                      data-unique="txtUserEmail"
                    />
                  </GridItem>
                  {!user.sys &&
                    <GridItem xs={12}>
                    {userRolesData &&
                      userRolesData.loading && <LoadingIndicator large />}
                    {userRolesData && userRolesData.error && <ErrorFormat err={userRolesData.error} />}
                    {userRolesData &&
                      userRolesData.userroles && (
                        <Field
                          name="roles"
                          component={renderAutoSuggest}
                          label="Select Role(s)"
                          suggestions={userRolesData.userroles}
                          numsuggestions={20}
                          keyFunc={s => s.id}
                          labelFunc={s => s.description || s.name}
                          validate={required}
                          data-unique="asUserRoleSelection"
                        />
                      )}
                    </GridItem>
                  }
                  {!user.sys && user.id && (
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="password"
                        component={renderPasswordField}
                        label="Enter new password"
                        data-unique="pwUserPassword"
                      />
                    </GridItem>
                  )}
                  {!user.sys && !user.id && (
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="password"
                        component={renderPasswordField}
                        label="Enter initial Password"
                        data-unique="pwUserPassword"
                      />
                    </GridItem>
                  )}
                  {!user.sys && user.id && (
                    <GridItem xs={12}>
                      <Text muted>Password last changed: {user.passwordUpdatedAt ? <DateFormat>{user.passwordUpdatedAt}</DateFormat> : 'never'}</Text>
                    </GridItem>
                  )}
                  {!user.sys && user.id && user.passwordExpired && (
                    <GridItem xs={12}>
                      <Text muted>Password is expired (required to change on next login)</Text>
                    </GridItem>
                  )}
                  {!user.sys &&
                    <GridItem md={12} largePadding>
                      <FormActionsToolbar
                        leftButtons={<>
                          {user.id && (
                            <Mutation
                              mutation={DELETE_USER}
                              onCompleted={data => {
                                setAlertSuccessMessage(`User "${user.name}" deleted`)
                                onReady()
                              }}
                              onError={error => {
                                setAlertErrorMessage('User deletion failed.', error)
                              }}
                              refetchQueries={[
                                {
                                  query: USERS_QUERY,
                                },
                              ]}
                            >
                              {(deleteUser, { loading, error }) => (
                                <ConfirmationButton
                                  confirmationText={`Are you sure you want to delete this user ?`}
                                  requireCheck={true}
                                  onClick={() => {
                                    deleteUser({
                                      variables: { id: user.id },
                                    })
                                  }}
                                  data-unique="btnUserDelete"
                                  secondary
                                  danger                                   
                                >
                                  <ShowIcon icon="trash"  />
                                  <Text infoText bold>Delete User</Text>
                                </ConfirmationButton>
                              )}
                            </Mutation>
                          )}                      
                        </>}
                        rightButtons={<>
                          {user.id && (
                            <Mutation
                              mutation={EXPIRE_PASSWORD}
                              onCompleted={data => {
                                setAlertSuccessMessage('Password is expired (required to change on next login)')
                              }}
                              onError={error => {
                                setAlertErrorMessage('Setting password to expired failed.', error)
                              }}
                              refetchQueries={[
                                ...RefetchUserQueries(user.id)
                              ]}
                            >
                              {(send, { loading, error }) => (
                                <Button
                                  onClick={() => {
                                    send({
                                      variables: { id: user.id },
                                    })
                                  }}
                                  data-unique="btnUserExpirePassword"
                                  secondary
                                >
                                  <ShowIcon icon="clock" />
                                  Expire Password
                                </Button>
                              )}
                            </Mutation>
                          )}                        
                          {user.id && (
                            <Mutation
                              mutation={SEND_USER_WELCOME_EMAIL}
                              onCompleted={data => {
                                setAlertSuccessMessage(`Send password reset email to "${user.name}"`)
                              }}
                              onError={error => {
                                setAlertErrorMessage('Sending password reset email failed.', error)
                              }}
                            >
                              {(send, { loading, error }) => (
                                <Button
                                  onClick={() => {
                                    send({
                                      variables: { id: user.id },
                                    })
                                  }}
                                  data-unique="btnUserSendPasswordResetEmail"
                                  secondary
                                >
                                  <ShowIcon icon="envelope" />
                                  Send Password Reset Email
                                </Button>
                              )}
                            </Mutation>
                          )}
                          <Button
                            type="submit"
                            disabled={submitting || invalid}
                            data-unique="btnUserSave"
                          >
                            {submitting && <LoadingIndicator alt />}
                            {!submitting && <ShowIcon icon="save" />}                  
                            Save
                          </Button>                      
                        </>}
                      />
                    </GridItem>
                  }
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={USER_QUERY} variables={{ id }} fetchPolicy="network-only">
              {(queryResult) => <QueryStatus {...queryResult} query="user">{(data) => this.renderForm(data.user)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm(this.state.emptyUser)}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(settingsStyle),
  connect(
    state => ({ license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(USERROLES_QUERY, {
    props: ({ data }) => ({
      userRolesData: data,
    }),
  }),
  withApollo
)(User)
