import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import statsStyle from 'assets/jss/material-dashboard-react/components/statsStyle'
import classNames from 'classnames'

function StatsText({ classes, primaryText,secondaryTextGroup, secondaryText, primarySuffix, small, error, success, warning, href, history, staticContext, datePosition, ...rest }) {

  return <div {...rest} {...(href ? { onClick: () => history.push(href) } : {})} className={classNames({[classes.root]: true, [classes.clickable]: href || rest.onClick })}>
      <div className={classNames({
          [classes.primaryText]: true,
          [classes.primaryTextError]: error,
          [classes.primaryTextSuccess]: success,
          [classes.primaryTextWarning]: warning,
          [classes.primaryTextSmall]: small,
          [classes.primaryTextDatePosition]: datePosition,
        })}>
          {primaryText}
          <span className={classNames({
            [classes.primarySuffix]: true,
            [classes.primarySuffixError]: error,
            [classes.primarySuffixSuccess]: success,
            [classes.primarySuffixWarning]: warning,
            
          })}>{primarySuffix}</span>
      </div>
      <div className={classNames({
          [classes.secondaryText]: true,
          [classes.secondaryTextError]: error,
          [classes.secondaryTextSuccess]: success,
          [classes.secondaryTextWarning]: warning,
          [classes.primaryTextDatePosition]: datePosition,
        })}>
          {secondaryText}
        </div>
        <div className={classNames({
          [classes.secondaryTextGroup]: true,
        })}>
          {secondaryTextGroup}  
      </div>
  </div>
}

export default withStyles(statsStyle)(withRouter(StatsText))
