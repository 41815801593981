import React from 'react'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import WatsonImport from 'components/Capability/WatsonImport.jsx'
import DialogFlowImport from 'components/Capability/DialogFlowImport.jsx'
import LexImport from 'components/Capability/LexImport.jsx'
import AlexaSMAPIImport from 'components/Capability/AlexaSMAPIImport.jsx'
import RasaImport from 'components/Capability/RasaImport.jsx'
import LUISImport from 'components/Capability/LUISImport.jsx'
import QnAMakerImport from 'components/Capability/QnAMakerImport.jsx'
import InbentaImport from 'components/Capability/InbentaImport.jsx'
import CognigyImport from 'components/Capability/CognigyImport.jsx'
import DialogFlowCxImport from 'components/Capability/DialogFlowCxImport.jsx'
import GenericImport from 'components/Capability/GenericImport.jsx'
import AzureCLUImport from 'components/Capability/AzureCLUImport.jsx'
import AzureCQAImport from 'components/Capability/AzureCQAImport.jsx'
import GenesysImport from 'components/Capability/GenesysImport'

import Text from 'components/Typography/Text.jsx'
import StatsText from 'components/Stats/StatsText.jsx'
import AvatarImage from 'components/Avatar/AvatarImage'




import { CHATBOT_QUERY } from '../Chatbots/gql'

class NLPDownloadWizard extends React.Component {
  render() {
    const { match, testSetId, chatbotData, settings } = this.props

    const wizardTestSetId = testSetId || (match && match.params && match.params.testSetId)
    const chatbot = chatbotData && chatbotData.chatbot

    const connector = settings && chatbot && settings.allconnectors.find(c => (
      c.name === chatbot.containermode
    ))

    return (<GridContainer>
      {chatbot && (
        <GridItem md={12} lg={8}>
          <Card>
            <CardBody>
            <GridContainer>
                <GridItem md={12}>
                  <Text header>Chatbot Overview</Text>
                  <Text subheader>A short Overview of this Chatbot</Text>
                  <GridContainer paddingTop>
                    <GridItem md={1} bottom>
                        <StatsText primaryText={<AvatarImage variant="medium" avatar={chatbot.avatar} containermode={chatbot.containermode}  chatbotId={chatbot.id} />} secondaryText="Chatbot" />
                    </GridItem>
                    <GridItem md={6} bottom>
                      <StatsText primarySuffix={connector ? (connector.description || connector.name) : 'Custom Connector'} secondaryText="Connector / Chatbot Technology" />
                    </GridItem>
                  </GridContainer>
                </GridItem>
             </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {chatbot.containermode === 'watson' && <WatsonImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'dialogflow' && <DialogFlowImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'lex' && <LexImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'alexa-smapi' && <AlexaSMAPIImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'rasa' && <RasaImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'luis' && <LUISImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'qnamaker' && <QnAMakerImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'echo' && <GenericImport chatbotId={chatbot.id} testSetId={wizardTestSetId} connectorNameSuffix="Echo" connectorName="Echo" />}
              {chatbot.containermode === 'inbenta' && <InbentaImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'cognigy' && <CognigyImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'dialogflowcx' && <DialogFlowCxImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'nlpjs' && <GenericImport chatbotId={chatbot.id} testSetId={wizardTestSetId} connectorNameSuffix="Nlpjs" connectorName="NLP.js" />}
              {chatbot.containermode === 'azure-clu' && <AzureCLUImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'azure-cqa' && <AzureCQAImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'genesys' && <GenesysImport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
              {chatbot.containermode === 'nuance' && <GenericImport chatbotId={chatbot.id} testSetId={wizardTestSetId} connectorNameSuffix="Nuance" connectorName="Nuance" />}
              {chatbot.containermode === 'koreai-webhook' && <GenericImport chatbotId={chatbot.id} testSetId={wizardTestSetId} connectorNameSuffix="KoreaiWebhook" connectorName="Kore AI Webhook" />}
            </CardBody>
          </Card>
        </GridItem>
      )}
    </GridContainer>)
  }
}

export default compose(
  connect(
    state => ({
      user: state.token.user,
      license: state.settings.license,
      settings: state.settings
     }),
  ),
  graphql(CHATBOT_QUERY, {
    props: ({ data }) => ({
      chatbotData: data
    }),
    options: (props) => {
      const { match, chatbotId } = props
      return {
        variables: {
          id: chatbotId || (match && match.params && match.params.chatbotId)
        },
      }
    },
    skip: (props) => {
      const { match, chatbotId } = props
      return !chatbotId && (!match || !match.params || !match.params.chatbotId)
    }
  })
)(NLPDownloadWizard)
