import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
// core components

import headerSearchStyle from 'assets/jss/material-dashboard-react/components/headerSearchStyle.jsx'
import { CustomTextField } from 'components/Form/Form'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'

function HeaderSearch({ ...props }) {
  const { classes, searchText, onChange, onKeyPress, onButtonClick } = props

  return (<>
    <CustomTextField className={classes.topsearchblock}
        input={{
            name: 'searchText',
            onChange: e=>onChange(e),
            onKeyPress: e=>onKeyPress(e),
            placeholder: 'Search',
            value: searchText,
            className: classes.searchInput,
            
        }}
        inputProps={{
            inputProps: {
                'aria-label': 'Search',
                style: {
                    fontSize: 13
                },
            },
           
        }}
        />
        <Tooltip title="Quick Search" placement="bottom" >
            <Button justIcon  data-unique="btnHeaderQuicksearchSearch" color="white" aria-label="Quick Search" className={classes.searchbutton} onClick={e => {
                onButtonClick(e)
                }}>
                <ShowIcon icon="search" />
            </Button>
        </Tooltip>
    </>
  )
}

export default withRouter(withStyles(headerSearchStyle)(HeaderSearch))
