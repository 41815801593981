import React from 'react'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  url,
  composeValidators,
  renderTextField,
  renderTextArea,
  renderSelect,
  json,
  prettyPrintJson,

} from 'components/Form/Form'
import { capSetDescription, ExtractJsonCapabilityValue, ExtractStringCapabilityValue, usedByCapabilitySet } from './Helper'
import Text from 'components/Typography/Text'

const httpVerbs = ['GET', 'POST', 'PUT']

export function botkitCaps2Form(caps) {
  return {
    botkit: {
      version: ExtractStringCapabilityValue(caps, 'BOTKIT_VERSION', 'BOTKIT_4_0'),
      // old stack
      serverUrl: ExtractStringCapabilityValue(caps, 'BOTKIT_SERVER_URL', ''),
      // new stack. Copied from simplerest. + BODY_TEMPLATE and RESPONSE_JSONPATH default
      initContext: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'BOTKIT_4_0_INIT_CONTEXT')),
      initText: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_INIT_TEXT', ''),
      url: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_URL', ''),
      method: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_METHOD', 'POST'),
      pingUrl: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_PING_URL', ''),
      headersTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'BOTKIT_4_0_HEADERS_TEMPLATE')),
      bodyTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'BOTKIT_4_0_BODY_TEMPLATE', '{"text": "{{msg.messageText}}", "user": "{{botium.conversationId}}", "type": "message"}')),
      contextJsonPath: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_CONTEXT_JSONPATH', ''),
      responseJsonPath: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_RESPONSE_JSONPATH', '$.*.text'),
      buttonJsonPath: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_BUTTONS_JSONPATH', ''),
      mediaJsonPath: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_MEDIA_JSONPATH', ''),
      requestHook: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_REQUEST_HOOK', ''),
      responseHook: ExtractStringCapabilityValue(caps, 'BOTKIT_4_0_RESPONSE_HOOK', '')
    },
  }
}

const capNamesMap = {
  'botkit.version': 'BOTKIT_VERSION',
  'botkit.serverUrl': 'BOTKIT_SERVER_URL',
  'botkit.url': 'BOTKIT_4_0_URL',
  'botkit.method': 'BOTKIT_4_0_METHOD',
  'botkit.pingUrl': 'BOTKIT_4_0_PING_URL',
  'botkit.headersTemplate': 'BOTKIT_4_0_HEADERS_TEMPLATE',
  'botkit.bodyTemplate': 'BOTKIT_4_0_BODY_TEMPLATE',
  'botkit.contextJsonPath': 'BOTKIT_4_0_CONTEXT_JSONPATH',
  'botkit.responseJsonPath': 'BOTKIT_4_0_RESPONSE_JSONPATH',
  'botkit.buttonJsonPath': 'BOTKIT_4_0_BUTTONS_JSONPATH',
  'botkit.mediaJsonPath': 'BOTKIT_4_0_MEDIA_JSONPATH',
  'botkit.requestHook': 'BOTKIT_4_0_REQUEST_HOOK',
  'botkit.responseHook': 'BOTKIT_4_0_RESPONSE_HOOK',
  'botkit.initContext': 'BOTKIT_4_0_INIT_CONTEXT',
  'botkit.initText': 'BOTKIT_4_0_INIT_TEXT',
}

export function botkitForm2caps(values) {
  const capabilities = [
    { name: 'BOTKIT_VERSION', type: 'STRING', stringValue: values.botkit.version },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'botkit' },

    // old stack
    {
      name: 'BOTKIT_SERVER_URL',
      type: 'STRING',
      stringValue: values.botkit.serverUrl || '',
    },

    // new stack. Copied from simplerest, renamed, CONTAINERMODE removed
    {
      name: 'BOTKIT_4_0_URL',
      type: 'STRING',
      stringValue: values.botkit.url,
    },
    {
      name: 'BOTKIT_4_0_METHOD',
      type: 'STRING',
      stringValue: values.botkit.method || 'POST',
    },
    {
      name: 'BOTKIT_4_0_RESPONSE_JSONPATH',
      type: 'STRING',
      stringValue: values.botkit.responseJsonPath,
    },
    {
      name: 'BOTKIT_4_0_BODY_TEMPLATE',
      type: 'JSON',
      jsonValue: values.botkit.bodyTemplate,
    }
  ]

  if (values.botkit.pingUrl) {
    capabilities.push({
      name: 'BOTKIT_4_0_PING_URL',
      type: 'STRING',
      stringValue: values.botkit.pingUrl,
    })
  }
  if (values.botkit.initContext) {
    capabilities.push({
      name: 'BOTKIT_4_0_INIT_CONTEXT',
      type: 'JSON',
      jsonValue: values.botkit.initContext,
    })
  }
  if (values.botkit.initText) {
    capabilities.push({
      name: 'BOTKIT_4_0_INIT_TEXT',
      type: 'STRING',
      stringValue: values.botkit.initText,
    })
  }
  if (values.botkit.mediaJsonPath) {
    capabilities.push({
      name: 'BOTKIT_4_0_MEDIA_JSONPATH',
      type: 'STRING',
      stringValue: values.botkit.mediaJsonPath,
    })
  }
  if (values.botkit.buttonJsonPath) {
    capabilities.push({
      name: 'BOTKIT_4_0_BUTTONS_JSONPATH',
      type: 'STRING',
      stringValue: values.botkit.buttonJsonPath,
    })
  }
  if (values.botkit.contextJsonPath) {
    capabilities.push({
      name: 'BOTKIT_4_0_CONTEXT_JSONPATH',
      type: 'STRING',
      stringValue: values.botkit.contextJsonPath,
    })
  }
  if (values.botkit.headersTemplate) {
    capabilities.push({
      name: 'BOTKIT_4_0_HEADERS_TEMPLATE',
      type: 'JSON',
      jsonValue: values.botkit.headersTemplate,
    })
  }
  if (values.botkit.requestHook) {
    capabilities.push({
      name: 'BOTKIT_4_0_REQUEST_HOOK',
      type: 'STRING',
      stringValue: values.botkit.requestHook,
    })
  }
  if (values.botkit.responseHook) {
    capabilities.push({
      name: 'BOTKIT_4_0_RESPONSE_HOOK',
      type: 'STRING',
      stringValue: values.botkit.responseHook,
    })
  }

  return capabilities
}

export class BotkitEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }  
  render() {
    const { disabled, advanced, capSetCapNames } = this.props

    return (
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          return (
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="botkit.version"
                  component={renderSelect}
                  label="Botkit stack"
                  validate={required}
                  data-unique="selBotkitEditVersion"
                  items={[
                    { key: 'ANYWHERE_AND_BOTKIT_0_7_AND_BELOW', label: 'Botkit Anywhere & Botkit Core 0.7' },
                    { key: 'BOTKIT_4_0', label: 'Botkit Server with Yeoman template & Botkit 4.0' },
                  ]}
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.version']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.version'])}
                />
              </GridItem>

              {values.botkit.version === 'BOTKIT_4_0' && <>
                <GridItem xs={12}>
                  <Field
                    name="botkit.url"
                    component={renderTextField}
                    label="HTTP(S) endpoint of your chatbot"
                    validate={required}
                    data-unique="txtBotKitUrl"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.url']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.url'], 'Moustache template variables supported')}
                  />
                </GridItem>
                {advanced && <>
                  <GridItem xs={12}>
                    <ExpansionPanel expanded={!!this.state.endpointExpanded} onChange={() => this.setState({ endpointExpanded: !this.state.endpointExpanded })} data-unique="pnlBotKitEndpoint">
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        HTTP(S) Endpoint Configuration
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <GridContainer nounset>
                          <GridItem xs={12}>
                            <Text muted>
                              <p>For each conversation, this connector holds state which can be used in constructing a request and updated after each response:
                                <br />
                                <b>msg</b> holds the current user input to send
                                <br />
                                <b>context</b> holds the current session variables (may or may not apply to your chatbot)
                                <br />
                                <b>botium</b> holds a "conversationId" (generated guid for each conversation) and a "stepId" (generated guid for each conversation step)
                              </p>
                              <p>
                                All of them are available in the <a href="https://mustache.github.io/" target="_blank" rel="noopener noreferrer">Moustache templates</a> below.
                              </p>
                              <p>
                                All of them are updated by defining <a href="http://goessner.net/articles/JsonPath/" target="_blank" rel="noopener noreferrer">JSON Path expressions</a> on the response body.
                              </p>
                              <p>
                                More information available in the <a href="https://github.com/codeforequity-at/botium-connector-botkit" target="_blank" rel="noopener noreferrer">Github Repository</a>.
                              </p>
                            </Text>
                          </GridItem>
                          <GridItem xs={12} sm={4}>
                            <Field
                              name="botkit.method"
                              component={renderSelect}
                              label="HTTP Method"
                              data-unique="selBotkitEditMethod"
                              items={httpVerbs.map(v => {
                                return { key: v }
                              })}
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.method']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.method'])}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <Field
                              name="botkit.pingUrl"
                              component={renderTextField}
                              label="HTTP(S) Endpoint to Ping Before Start"
                              data-unique="txtBotKitPingUrl"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.pingUrl']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.pingUrl'])}
                            />
                          </GridItem>
                        </GridContainer>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </GridItem>
                  <GridItem xs={12}>
                    <ExpansionPanel expanded={!!this.state.initializationExpanded} onChange={() => this.setState({ initializationExpanded: !this.state.initializationExpanded })} data-unique="pnlBotKitInitialization">
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        State Initialization
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <GridContainer nounset>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="botkit.initContext"
                              component={renderTextArea}
                              multiline
                              rows={3}
                              label="Initial Conversation Context"
                              validate={json}
                              data-unique="txtBotKitInitContext"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.initContext']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.initContext'])}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="botkit.initText"
                              component={renderTextField}
                              label="Initial Conversation Text"
                              data-unique="txtBotKitInitText"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.initText']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.initText'], 'User input sent before actual conversation starts')}
                            />
                          </GridItem>
                        </GridContainer>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </GridItem>
                  <GridItem xs={12}>
                    <ExpansionPanel expanded={!!this.state.requestExpanded} onChange={() => this.setState({ requestExpanded: !this.state.requestExpanded })} data-unique="pnlBotKitRequest">
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        HTTP(S) Request Builder
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <GridContainer nounset>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="botkit.headersTemplate"
                              component={renderTextArea}
                              multiline
                              rows={5}
                              label="Header Template"
                              validate={json}
                              data-unique="txtBotKitHeadersTemplate"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.headersTemplate']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.headersTemplate'], 'Moustache template variables supported')}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="botkit.bodyTemplate"
                              component={renderTextArea}
                              multiline
                              rows={5}
                              label="Body Template"
                              validate={composeValidators(required, json)}
                              data-unique="txtBotKitBodyTemplate"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.bodyTemplate']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.bodyTemplate'], 'Moustache template variables supported')}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <Field
                              name="botkit.requestHook"
                              component={renderTextArea}
                              multiline
                              rows={5}
                              label="Request Hook"
                              data-unique="txtBotKitRequestHook"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.requestHook']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.requestHook'])}
                            />
                          </GridItem>

                        </GridContainer>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </GridItem>
                  <GridItem xs={12}>
                    <ExpansionPanel expanded={!!this.state.responseExpanded} onChange={() => this.setState({ responseExpanded: !this.state.responseExpanded })} data-unique="pnlBotKitResponse">
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        HTTP(S) Response Evaluation
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <GridContainer nounset>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="botkit.contextJsonPath"
                              component={renderTextField}
                              label="JSON-Path for Conversation Context"
                              data-unique="txtBotKitContextJsonPath"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.contextJsonPath']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.contextJsonPath'], 'Should return JSON struct with attributes')}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="botkit.responseJsonPath"
                              component={renderTextField}
                              label="JSON-Path for Bot Response"
                              validate={required}
                              data-unique="txtBotKitResponseJsonPath"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.responseJsonPath']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.responseJsonPath'], 'Should return string or array of strings (separate multiple expressions with comma)')}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="botkit.mediaJsonPath"
                              component={renderTextField}
                              label="JSON-Path for Media Attachments"
                              data-unique="txtBotKitMediaJsonPath"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.mediaJsonPath']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.mediaJsonPath'], 'Should return string or array of strings (separate multiple expressions with comma)')}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="botkit.buttonJsonPath"
                              component={renderTextField}
                              label="JSON-Path for Quick-Reply Recognition"
                              data-unique="txtBotKitButtonJsonPath"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.buttonJsonPath']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.buttonJsonPath'], 'Should return string or array of strings (separate multiple expressions with comma)')}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <Field
                              name="botkit.responseHook"
                              component={renderTextArea}
                              multiline
                              rows={5}
                              label="Response Hook"
                              data-unique="txtBotKitResponseHook"
                              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.responseHook']) || disabled}
                              helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.responseHook'])}
                            />
                          </GridItem>
                        </GridContainer>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </GridItem>
                </>}
              </>}

              {values.botkit.version === 'ANYWHERE_AND_BOTKIT_0_7_AND_BELOW' && <>
                <GridItem xs={12}>
                  <Field
                    name="botkit.serverUrl"
                    component={renderTextField}
                    label="Botkit Server Url"
                    validate={composeValidators(required, url)}
                    data-unique="txtBotKitServerUrl"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkit.serverUrl']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['botkit.serverUrl'], 'The Botkit Server Url (without any path, just http/https, servername, port)')}
                  />
                </GridItem>
              </>}
            </GridContainer>
          )
        }}
      />
    )
  }
}
