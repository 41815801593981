import { SET_LIST_SETTINGS, CLEAR_LIST_SETTINGS } from '../constants'

export default function list(state = {}, action) {
  switch (action.type) {
    case SET_LIST_SETTINGS:
      return {
        ...state,
        [action.payload.list]: action.payload.settings,
      }
    case CLEAR_LIST_SETTINGS:
      if (state[action.payload.list]) {
        const clone = { ...state }
        delete clone[action.payload.list]
        return clone
      }
      return state
    default:
      return state
  }
}
