import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'
import ShowIcon from 'components/Icon/ShowIcon'
import Button from 'components/Button/Button'
import Text from 'components/Typography/Text'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import Utterance from './UtteranceSummary'
import LinkButton from 'components/Button/LinkButton'

import NavbarHeader from 'components/Header/NavbarHeader'
import DangerOrSuccessText from 'components/Typography/DangerOrSuccessText'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {UTTERANCES_FOR_INTENT_QUERY, TRAINER_SESSION_ROOT_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'
import {decodeURIComponentWeak} from 'views/helper'

const testsessionLabel = (testsession) => {
  if (testsession) {
    return <React.Fragment>{testsession.name}</React.Fragment>
  }
  return null
}

class IntentConfidence extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  getRootPath = () => {
    const path = this.props.location.pathname.split('/').filter(p => p)
    return '/' + path[0] + '/' + path[1] + '/' + path[2] + '/' + path[3]
  }

  renderTable() {
    const {utterancesData, match} = this.props
    const { testSessionId } = match.params

    let progressOrError = renderProgressOrError(utterancesData)
    if (progressOrError) {
      return progressOrError
    }

    let data = _.sortBy(utterancesData.trainerUtterances, ['intent.confidenceReal', 'utterance', 'intent.expected', 'intent.actual'])

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Utterance', 'Expected Intent', 'Predicted Intent', 'Prediction Confidence']}
        tableData={data.map(entry => {
          return [
            () => <LinkButton onClick={() => this.setState({ open: true, utterance: entry.utterance, testSessionId: testSessionId })} data-unique={`btnCoachIntentConfidenceUtterance_${encodeURIComponent(entry.utterance)}`}>{entry.utterance}</LinkButton>,
            () => <>{entry.intent.expected}</>,
            () => <>{entry.intent.actual}</>,
            () =>
              <DangerOrSuccessText successful={entry.intent.matches}>
                {!_.isNil(entry.intent.confidenceReal) ? entry.intent.confidenceReal.toFixed(2) : 'N/A'}
              </DangerOrSuccessText>
          ]
        })}
      />
    )
  }

  render() {
    const { match, history, testSessionData} = this.props

    console.log('testSessionData', testSessionData)
    return (<>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
            <GridContainer>
                <GridItem xs={12}>
                    <Button justIcon Border onClick={() => history.goBack()} >
                       <ShowIcon icon="arrow-left" />
                   </Button> <Text inline>BACK / Test Session: {testSessionData && testSessionData.testsession && testsessionLabel(testSessionData.testsession)}</Text>
                </GridItem>
                <GridItem xs={12}>
                  {this.renderTable()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ConfirmationDialog
      maxWidth="lg"
      open={this.state.open}
      onOk={() => this.setState({ open: false })}
      title={`Utterance name: ${this.state.utterance}`}
    >
      <Utterance utterance={this.state.utterance} testSessionId={this.state.testSessionId} testProjectId={match.params.testProjectId} />
    </ConfirmationDialog>
    </>)
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(UTTERANCES_FOR_INTENT_QUERY, {
    props: ({data}) => ({
      utterancesData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          intent: decodeURIComponentWeak(props.match.params.intentName),
          expectedIntent: decodeURIComponentWeak(props.match.params.expectedIntentName),
          correctedIntent: decodeURIComponentWeak(props.match.params.correctedIntentName),
          actualIntent: decodeURIComponentWeak(props.match.params.actualIntentName)
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      testSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.testSessionId
        },
      }
    }
  })
)(IntentConfidence)

export const IntentConfidenceNavbarHeader = ({ match }) => {
  const {intentName, expectedIntentName, correctedIntentName, actualIntentName} = match.params
  return <NavbarHeader text={`Confidence Score for Intent "${intentName || expectedIntentName || correctedIntentName || actualIntentName || ''}"`} />
}
