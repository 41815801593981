import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

// core components
import listStyle from 'assets/jss/material-dashboard-react/components/listStyle.jsx'

function CustomListItemAvatar({ ...props }) {
  const { children, classes, ...rest } = props
  return (
    <ListItemAvatar classes={{
      root: classes.listItemAvatarSmallRoot
    }} {...rest}>
      {children}
    </ListItemAvatar>
  )
}

export default withStyles(listStyle)(CustomListItemAvatar)
