import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import listStyle from 'assets/jss/material-dashboard-react/components/listStyle.jsx'

function CustomListItemIcon({ ...props }) {
  const { children, classes, ...rest } = props
  return (
    <span classes={{
      root: classes.listItemIconSmallRoot
    }} {...rest}>
      {children}
    </span>
  )
}

export default withStyles(listStyle)(CustomListItemIcon)
