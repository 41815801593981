import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
// apollo
import { Query } from 'react-apollo'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import styles from 'assets/jss/material-dashboard-react/components/convoStyle'
import defaultAvatar from 'assets/img/botium-connector-default.png'
import { CHATBOT_QUERY } from 'views/Chatbots/gql'

class AvatarImage extends React.Component {

  renderAvatar(avatar) {
    const { onClick, success, error, classes, className, style, variant, autoHeight } = this.props

    const size = variant === 'original' ? 150 : ({
      small: 16,
      medium: 24,
      full: 200,
      stretch: '100%'
    })[variant || 'medium']

    const radius = variant === 'original' ? {} : { borderRadius: `${size}px` }

    if(avatar) {
      if (_.isString(avatar)) {
        const src = avatar.startsWith('data') ? avatar : `data:image/png;base64,${avatar}`

        return (<img alt="Chatbot Avatar" className={`${classNames({
          [classes.borderSuccess]: success,
          [classes.borderDanger]: error
        })} ${className || ''}`} style={{maxWidth: `${size}px`, ...radius, ...style}} width={size} height={autoHeight ? 'auto' : size} onClick={onClick} src={src} />)
      } else {
        return (<div className={`${classNames({
          [classes.borderSuccess]: success,
          [classes.borderDanger]: error
        })} ${className || ''}`} style={{maxWidth: `${size}px`, ...radius, ...style}} width={size} height={autoHeight ? 'auto' : size} onClick={onClick}>
          {React.createElement(avatar)}
        </div>)
      }
    }
    return <img alt="Chatbot Avatar" className={`${classNames({
      [classes.borderSuccess]: success,
      [classes.borderDanger]: error
    })} ${className || ''}`} style={{borderRadius: `${size}px`, maxWidth: `${size}px`,...style}} width={size} height={autoHeight ? 'auto' : size} onClick={onClick} src={defaultAvatar} />
  }

  render() {
    const { chatbotId, avatar, containermode, settings } = this.props

    if (avatar) {
      return this.renderAvatar(avatar)
    } else if (containermode) {
      const connector = settings.allconnectors.find(ac => ac.name === containermode)
      if (connector) {
        return this.renderAvatar(connector.avatar)
      }
      return this.renderAvatar()
    } else if (chatbotId) {
      return (<Query
        query={CHATBOT_QUERY}
        variables={{ id: chatbotId }}
      >
        {({ loading, error, data }) => {
          if (loading) return this.renderAvatar()
          if (error) return this.renderAvatar()

          if (data.chatbot && data.chatbot.avatar) {
            return this.renderAvatar(data.chatbot.avatar)
          }

          const connector = settings.allconnectors.find(ac => ac.name === data.chatbot.containermode)
          if (connector) {
            return this.renderAvatar(connector.avatar)
          }
          return this.renderAvatar()
        }}
      </Query>)
    } else {
      return this.renderAvatar()
    }
  }
}

AvatarImage.propTypes = {
  chatbotId: PropTypes.string,
  avatar: PropTypes.any,
  containermode: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'medium', 'full', 'original', 'stretch'])
}

export default connect(
  state => ({ settings: state.settings })
)(withStyles(styles)(AvatarImage))
