import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  renderTextField,
  renderCheckbox,
  renderSelect,
  required,
  renderCodeArea, prettyPrintJson, json, renderIntField
} from 'components/Form/Form'

import { renderSpeechRecognitionSelector, renderSpeechSynthesisSelector } from './ConnectorEdit.jsx'

import { ExtractStringCapabilityValue, ExtractBooleanCapabilityValue, ExtractJsonCapabilityValue, usedByCapabilitySet, capSetDescription } from './Helper'
import Text from 'components/Typography/Text.jsx'

export function voipConnectorCaps2Form(caps) {
  return {
    voip: {
      url: ExtractStringCapabilityValue(caps, 'VOIP_WORKER_URL', 'https://'),
      apiKey: ExtractStringCapabilityValue(caps, 'VOIP_WORKER_APIKEY', ''),
      calleeUri: ExtractStringCapabilityValue(caps, 'VOIP_SIP_CALLEE_URI', 'sip:'),
      sttProfile: ExtractStringCapabilityValue(caps, 'VOIP_STT', ''),
      ttsProfile: ExtractStringCapabilityValue(caps, 'VOIP_TTS', ''),
      enableIce: ExtractBooleanCapabilityValue(caps, 'VOIP_ICE_ENABLE', true),
      stunServers: ExtractStringCapabilityValue(caps, 'VOIP_ICE_STUN_SERVERS', ''),
      enableTurnServer: ExtractBooleanCapabilityValue(caps, 'VOIP_ICE_TURN_ENABLE', false),
      turnServer: ExtractStringCapabilityValue(caps, 'VOIP_ICE_TURN_SERVER', ''),
      turnUser: ExtractStringCapabilityValue(caps, 'VOIP_ICE_TURN_USER', ''),
      turnPassword: ExtractStringCapabilityValue(caps, 'VOIP_ICE_TURN_PASSWORD', ''),
      sipProxy: ExtractStringCapabilityValue(caps, 'VOIP_SIP_PROXY', ''),
      enableSipPoolCaller: ExtractBooleanCapabilityValue(caps, 'VOIP_SIP_POOL_CALLER_ENABLE', true),
      sipCallerRegistrarUri: ExtractStringCapabilityValue(caps, 'VOIP_SIP_CALLER_REGISTRAR_URI', ''),
      sipCallerAddress: ExtractStringCapabilityValue(caps, 'VOIP_SIP_CALLER_ADDRESS', ''),
      sipCallerUsername: ExtractStringCapabilityValue(caps, 'VOIP_SIP_CALLER_USERNAME', ''),
      sipCallerPassword: ExtractStringCapabilityValue(caps, 'VOIP_SIP_CALLER_PASSWORD', ''),
      sipRegHeaders: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'VOIP_SIP_REG_HEADERS', '{}')),
      sipInviteHeaders: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'VOIP_SIP_INVITE_HEADERS', '{}')),
      botMsgHandlingMethod: ExtractStringCapabilityValue(caps, 'VOIP_STT_MESSAGE_HANDLING', 'ORIGINAL'),
      botMsgHandlingTimeout: ExtractStringCapabilityValue(caps, 'VOIP_STT_MESSAGE_HANDLING_TIMEOUT', '500'),
      botMsgHandlingDelimiter: ExtractStringCapabilityValue(caps, 'VOIP_STT_MESSAGE_HANDLING_DELIMITER', '. '),
      botMsgHandlingPunctuation: ExtractStringCapabilityValue(caps, 'VOIP_STT_MESSAGE_HANDLING_PUNCTUATION', '.!?'),
      silenceDurationTimeoutStart: ExtractStringCapabilityValue(caps, 'VOIP_SILENCE_DURATION_TIMEOUT_START', '1000'),
      silenceDurationTimeoutStartEnable: ExtractBooleanCapabilityValue(caps, 'VOIP_SILENCE_DURATION_TIMEOUT_START_ENABLE', false),
      silenceDurationTimeout: ExtractStringCapabilityValue(caps, 'VOIP_SILENCE_DURATION_TIMEOUT', '2500'),
      silenceDurationTimeoutEnable: ExtractBooleanCapabilityValue(caps, 'VOIP_SILENCE_DURATION_TIMEOUT_ENABLE', false),
    }
  }
}

const capNamesMap = {
  'voip.url': 'VOIP_WORKER_URL',
  'voip.apiKey': 'VOIP_WORKER_APIKEY',
  'voip.calleeUri': 'VOIP_SIP_CALLEE_URI',
  'voip.sttProfile': 'VOIP_STT',
  'voip.ttsProfile': 'VOIP_TTS',
  'voip.enableIce': 'VOIP_ICE_ENABLE',
  'voip.stunServers': 'VOIP_ICE_STUN_SERVERS',
  'voip.enableTurnServer': 'VOIP_ICE_TURN_ENABLE',
  'voip.turnServer': 'VOIP_ICE_TURN_SERVER',
  'voip.turnUser': 'VOIP_ICE_TURN_USER',
  'voip.turnPassword': 'VOIP_ICE_TURN_PASSWORD',
  'voip.sipProxy': 'VOIP_SIP_PROXY',
  'voip.enableSipPoolCaller': 'VOIP_SIP_POOL_CALLER_ENABLE',
  'voip.sipCallerRegistrarUri': 'VOIP_SIP_CALLER_REGISTRAR_URI',
  'voip.sipCallerAddress': 'VOIP_SIP_CALLER_ADDRESS',
  'voip.sipCallerUsername': 'VOIP_SIP_CALLER_USERNAME',
  'voip.sipCallerPassword': 'VOIP_SIP_CALLER_PASSWORD',
  'voip.sipRegHeaders': 'VOIP_SIP_REG_HEADERS',
  'voip.sipInviteHeaders': 'VOIP_SIP_INVITE_HEADERS',
  'voip.botMsgHandlingMethod': 'VOIP_STT_MESSAGE_HANDLING',
  'voip.botMsgHandlingTimeout': 'VOIP_STT_MESSAGE_HANDLING_TIMEOUT',
  'voip.botMsgHandlingDelimiter': 'VOIP_STT_MESSAGE_HANDLING_DELIMITER',
  'voip.botMsgHandlingPunctuation': 'VOIP_STT_MESSAGE_HANDLING_PUNCTUATION',
  'voip.silenceDurationTimeoutStart': 'VOIP_SILENCE_DURATION_TIMEOUT_START',
  'voip.silenceDurationTimeoutStartEnable': 'VOIP_SILENCE_DURATION_TIMEOUT_START_ENABLE',
  'voip.silenceDurationTimeout': 'VOIP_SILENCE_DURATION_TIMEOUT',
  'voip.silenceDurationTimeoutEnable': 'VOIP_SILENCE_DURATION_TIMEOUT_ENABLE',
}

export function voipConnectorForm2caps(values) {
  const capabilities = [
    {
      name: 'VOIP_SIP_CALLEE_URI',
      type: 'STRING',
      stringValue: values.voip.calleeUri
    },
    {
      name: 'VOIP_WORKER_URL',
      type: 'STRING',
      stringValue: values.voip.url,
    },
    {
      name: 'VOIP_WORKER_APIKEY',
      type: 'STRING',
      stringValue: values.voip.apiKey,
    },
    {
      name: 'VOIP_STT',
      type: 'STRING',
      stringValue: values.voip.sttProfile,
    },
    {
      name: 'VOIP_TTS',
      type: 'STRING',
      stringValue: values.voip.ttsProfile,
    },
    {
      name: 'VOIP_ICE_ENABLE',
      type: 'BOOLEAN',
      booleanValue: !!values.voip.enableIce,
    },
    {
      name: 'VOIP_ICE_STUN_SERVERS',
      type: 'STRING',
      stringValue: values.voip.stunServers,
    },
    {
      name: 'VOIP_ICE_TURN_ENABLE',
      type: 'BOOLEAN',
      booleanValue: !!values.voip.enableTurnServer,
    },
    {
      name: 'VOIP_ICE_TURN_SERVER',
      type: 'STRING',
      stringValue: values.voip.turnServer,
    },
    {
      name: 'VOIP_ICE_TURN_USER',
      type: 'STRING',
      stringValue: values.voip.turnUser,
    },
    {
      name: 'VOIP_ICE_TURN_PASSWORD',
      type: 'STRING',
      stringValue: values.voip.turnPassword,
    },
    {
      name: 'VOIP_SIP_POOL_CALLER_ENABLE',
      type: 'BOOLEAN',
      booleanValue: !!values.voip.enableSipPoolCaller,
    },
    {
      name: 'VOIP_SIP_PROXY',
      type: 'STRING',
      stringValue: values.voip.sipProxy,
    },
    {
      name: 'VOIP_SIP_CALLER_REGISTRAR_URI',
      type: 'STRING',
      stringValue: values.voip.sipCallerRegistrarUri,
    },
    {
      name: 'VOIP_SIP_CALLER_ADDRESS',
      type: 'STRING',
      stringValue: values.voip.sipCallerAddress,
    },
    {
      name: 'VOIP_SIP_CALLER_USERNAME',
      type: 'STRING',
      stringValue: values.voip.sipCallerUsername,
    },
    {
      name: 'VOIP_SIP_CALLER_PASSWORD',
      type: 'STRING',
      stringValue: values.voip.sipCallerPassword,
    },
    {
      name: 'VOIP_SIP_REG_HEADERS',
      type: 'JSON',
      jsonValue: values.voip.sipRegHeaders,
    },
    {
      name: 'VOIP_SIP_INVITE_HEADERS',
      type: 'JSON',
      jsonValue: values.voip.sipInviteHeaders,
    },
    {
      name: 'VOIP_STT_MESSAGE_HANDLING',
      type: 'STRING',
      stringValue: values.voip.botMsgHandlingMethod,
    },
    {
      name: 'VOIP_STT_MESSAGE_HANDLING_TIMEOUT',
      type: 'STRING',
      stringValue: values.voip.botMsgHandlingTimeout,
    },
    {
      name: 'VOIP_STT_MESSAGE_HANDLING_DELIMITER',
      type: 'STRING',
      stringValue: values.voip.botMsgHandlingDelimiter,
    },
    {
      name: 'VOIP_STT_MESSAGE_HANDLING_PUNCTUATION',
      type: 'STRING',
      stringValue: values.voip.botMsgHandlingPunctuation,
    },
    {
      name: 'VOIP_SILENCE_DURATION_TIMEOUT_START',
      type: 'STRING',
      stringValue: values.voip.silenceDurationTimeoutStart,
    },
    {
      name: 'VOIP_SILENCE_DURATION_TIMEOUT_START_ENABLE',
      type: 'BOOLEAN',
      booleanValue: !!values.voip.silenceDurationTimeoutStartEnable,
    },
    {
      name: 'VOIP_SILENCE_DURATION_TIMEOUT',
      type: 'STRING',
      stringValue: values.voip.silenceDurationTimeout,
    },
    {
      name: 'VOIP_SILENCE_DURATION_TIMEOUT_ENABLE',
      type: 'BOOLEAN',
      booleanValue: !!values.voip.silenceDurationTimeoutEnable,
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'voip' },
  ]

  return capabilities
}

class VoipConnectorEditInternal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      wsEndpointConfigExpanded: false,
      httpReqBuilderExpanded: false,
      wsResEvalExpanded: false,
      iceConfigExpanded: false,
      botMessageHandlingExpanded: false,
      silenceTimeoutExpanded: false
    }
  }

  render() {
    const { disabled, advanced, features, capSetCapNames } = this.props
    const { sipConfigExpanded, iceConfigExpanded, botMessageHandlingExpanded,silenceTimeoutExpanded } = this.state

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => (
      <GridContainer>
        <React.Fragment>
          <GridItem xs={12} sm={6}>
            <Field
              name="voip.url"
              component={renderTextField}
              label="HTTP endpoint of VOIP Worker"
              validate={required}
              data-unique="txtVoipConnectorWorkerUrl"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.url']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['voip.url'])}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="voip.apiKey"
              component={renderTextField}
              label="VOIP Worker API Key"
              validate={required}
              data-unique="txtVoipConnectorWorkerApiKey"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.apiKey']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['voip.apiKey'])}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              name="voip.calleeUri"
              component={renderTextField}
              label="SIP Address to call"
              validate={required}
              data-unique="txtVoipConnectorCalleeUri"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.calleeUri']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['voip.calleeUri'])}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            {renderSpeechRecognitionSelector({
              features,
              cap: {
                required: true
              },
              fieldName: 'voip.sttProfile',
              disabled: usedByCapabilitySet(capSetCapNames, capNamesMap['voip.sttProfile']) || disabled,
              dataUnique: 'selVoipConnectorSttProfile',
              helperText: capSetDescription(capSetCapNames, capNamesMap['voip.sttProfile'])
            })}
          </GridItem>
          <GridItem xs={12} sm={6}>
            {renderSpeechSynthesisSelector({
              features,
              fieldName: 'voip.ttsProfile',
              disabled: usedByCapabilitySet(capSetCapNames, capNamesMap['voip.ttsProfile']) || disabled,
              helperText: capSetDescription(capSetCapNames, capNamesMap['voip.ttsProfile']),
              dataUnique: 'selVoipConnectorTtsProfile'
            })}
          </GridItem>
          <GridItem xs={12} sm={2}/>
        </React.Fragment>
      {advanced && <React.Fragment>
        <GridItem xs={12}>
          <ExpansionPanel expanded={sipConfigExpanded} onChange={() => this.setState({sipConfigExpanded: !sipConfigExpanded})} data-unique="pnlVoipIceConfig">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              SIP Configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12}>
                  <Field
                    name="voip.enableSipPoolCaller"
                    component={renderCheckbox}
                    label="Use a pre-configured SIP-Address from VOIP Worker Pool"
                    type="checkbox"
                    data-unique="txtVoipConnectorExnableSipPoolCaller"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.enableSipPoolCaller']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.enableSipPoolCaller'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.sipProxy"
                    component={renderTextField}
                    label="SIP Proxy / Server"
                    data-unique="txtVoipConnectorSipProxy"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.sipProxy']) || disabled || values.voip.enableSipPoolCaller}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.sipProxy'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.sipCallerRegistrarUri"
                    component={renderTextField}
                    label="SIP Registrar Uri"
                    data-unique="txtVoipConnectorSipCallerRegistrarUri"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.sipCallerRegistrarUri']) || disabled || values.voip.enableSipPoolCaller}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.sipCallerRegistrarUri'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.sipCallerAddress"
                    component={renderTextField}
                    label="SIP Address"
                    data-unique="txtVoipConnectorSipCallerAddress"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.sipCallerAddress']) || disabled || values.voip.enableSipPoolCaller}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.sipCallerAddress'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.sipCallerUsername"
                    component={renderTextField}
                    label="SIP Username"
                    data-unique="txtVoipConnectorSipUser"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.sipCallerUsername']) || disabled || values.voip.enableSipPoolCaller}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.sipCallerUsername'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.sipCallerPassword"
                    component={renderTextField}
                    label="SIP Password"
                    data-unique="txtVoipConnectorSipPassword"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.sipCallerPassword']) || disabled || values.voip.enableSipPoolCaller}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.sipCallerPassword'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} />
                <GridItem xs={12} sm={6}>
                <Field
                  className="Capabilities"
                  name="voip.sipRegHeaders"
                  component={renderCodeArea}
                  options={{mode: 'application/json'}}
                  label="Custom SIP Registration Headers"
                  codeFormat={prettyPrintJson}
                  validate={json}
                  data-unique="codeVoipConnectorSipRegHeaders"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.sipRegHeaders']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['voip.sipRegHeaders'])}
                />
                </GridItem>
                <GridItem xs={12} sm={6}>
                <Field
                  className="Capabilities"
                  name="voip.sipInviteHeaders"
                  component={renderCodeArea}
                  options={{mode: 'application/json'}}
                  label="Custom SIP Invite Headers"
                  codeFormat={prettyPrintJson}
                  validate={json}
                  data-unique="codeVoipConnectorSipInviteHeaders"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.sipInviteHeaders']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['voip.sipInviteHeaders'])}
                />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </GridItem>
        <GridItem xs={12}>
          <ExpansionPanel expanded={iceConfigExpanded} onChange={() => this.setState({iceConfigExpanded: !iceConfigExpanded})} data-unique="pnlVoipIceConfig">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              ICE Configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12}>
                  <Field
                    name="voip.enableIce"
                    component={renderCheckbox}
                    label="Enable ICE Protocol"
                    type="checkbox"
                    data-unique="chkVoipConnectorIceEnable"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.enableIce']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.enableIce'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.stunServers"
                    component={renderTextField}
                    label="Stun Servers"
                    data-unique="txtVoipConnectorIceStunServers"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.stunServers']) || disabled || !values.voip.enableIce}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.stunServers'])}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="voip.enableTurnServer"
                    component={renderCheckbox}
                    label="Enable Turn Server"
                    type="checkbox"
                    data-unique="chkVoipConnectorIceTurnServerEnable"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.enableTurnServer']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.enableTurnServer'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.turnServer"
                    component={renderTextField}
                    label="Turn Server"
                    data-unique="txtVoipConnectorIceTurnServer"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.turnServer']) || disabled || !values.voip.enableTurnServer}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.turnServer'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.turnServerUser"
                    component={renderTextField}
                    label="Turn Server Username"
                    data-unique="txtVoipConnectorIceTurnServerUser"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.turnServerUser']) || disabled || !values.voip.enableTurnServer}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.turnServerUser'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.turnServerPassword"
                    component={renderTextField}
                    label="Turn Server Password"
                    data-unique="txtVoipConnectorIceTurnServerPassword"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.turnServerPassword']) || disabled || !values.voip.enableTurnServer}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.turnServerPassword'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.turnServerProtocol"
                    component={renderSelect}
                    label="Turn Server Protocol"
                    data-unique="selVoipConnectorIceTurnServerProtocol"
                    items={[
                      { key: 'UDP' },
                      { key: 'TCP' }
                    ]}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.turnServerProtocol']) || disabled || !values.voip.enableTurnServer}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.turnServerProtocol'])}
                    />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </GridItem>
        <GridItem xs={12}>
          <ExpansionPanel expanded={botMessageHandlingExpanded} onChange={() => this.setState({botMessageHandlingExpanded: !botMessageHandlingExpanded})} data-unique="pnlVoipBotMessageHandlingConfig">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Speech Recognizion / ASR Bot Message Handling
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12}>
                  <Text bold>Depending on the ASR provider you use, the results can be sometimes flaky in terms of message counts. We provide methods to handle such behaviours. Use the JOIN method to join messages depending on the silence duration. Use the SPLIT method to split sentences by characters.</Text>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.botMsgHandlingMethod"
                    component={renderSelect}
                    label="Handling Method"
                    data-unique="txtVoipConnectorBotMessageHandlingMethod"
                    items={[
                      { key: 'ORIGINAL' },
                      { key: 'JOIN' },
                      { key: 'SPLIT' }
                    ]}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.botMsgHandlingMethod']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.botMsgHandlingMethod'])}
                    />
                </GridItem>
                {values.voip.botMsgHandlingMethod === 'JOIN' && <><GridItem xs={12} sm={6}>
                  <Field
                    name="voip.botMsgHandlingDelimiter"
                    component={renderTextField}
                    label="Delimiter"
                    data-unique="txtVoipConnectorBotMessageHandlingDelimiter"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.botMsgHandlingDelimiter']) || disabled || values.voip.botMsgHandlingMethod !== 'JOIN'}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.botMsgHandlingDelimiter'], 'Delimiter for joining the bot messages')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.botMsgHandlingTimeout"
                    component={renderIntField}
                    label="Silence Duration Threshold (in ms)"
                    data-unique="txtVoipConnectorBotMessageHandlingTimeout"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.botMsgHandlingTimeout']) || disabled || values.voip.botMsgHandlingMethod !== 'JOIN'}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.botMsgHandlingTimeout'], 'Bot messages with a silence duration below the threshold are joined together')}
                    />
                </GridItem></>}
                {values.voip.botMsgHandlingMethod === 'SPLIT' && <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.botMsgHandlingPunctuation"
                    component={renderTextField}
                    label="Punctuation"
                    data-unique="txtVoipConnectorBotMessageHandlingPunctuation"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.botMsgHandlingPunctuation']) || disabled || values.voip.botMsgHandlingMethod !== 'SPLIT'}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.botMsgHandlingPunctuation'], 'Punctuation characters with which is separated')}
                  />
                </GridItem>}
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </GridItem>
        <GridItem xs={12}>
          <ExpansionPanel expanded={silenceTimeoutExpanded} onChange={() => this.setState({silenceTimeoutExpanded: !silenceTimeoutExpanded})} data-unique="pnlVoipSilenceTimeoutConfig">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Silence Duration Timeouts
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12}>
                  <Text bold>Usually the Bot Response Timeout is used to check if a Bot Response is received within a certain time, but in the VOIP Connector the Bot Response Timeout is related to the final ASR results (which usually come with delays). In order to assert on the real silence durations use the settings below.</Text>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.silenceDurationTimeoutStartEnable"
                    component={renderCheckbox}
                    label="Enable Initial Silence Duration Timeout"
                    type="checkbox"
                    data-unique="chkVoipConnectorSilenceDurationTimeoutStartEnable"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.silenceDurationTimeoutStartEnable']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.silenceDurationTimeoutStartEnable'], 'Checks if the silence duration before the first bot message exceeds  the given threshold')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.silenceDurationTimeoutStart"
                    component={renderTextField}
                    label="Silence Duration Timeout (in ms)"
                    data-unique="txtVoipConnectorSilenceDurationTimeoutStart"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.silenceDurationTimeoutStart']) || disabled || !values.voip.silenceDurationTimeoutStartEnable}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.silenceDurationTimeoutEnable"
                    component={renderCheckbox}
                    label="Enable Silence Duration Timeout in General"
                    type="checkbox"
                    data-unique="chkVoipConnectorSilenceDurationTimeoutEnable"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.silenceDurationTimeoutEnable']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.silenceDurationTimeoutEnable'], 'Checks if any silence duration within the Convo exceeds the given threshold')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="voip.silenceDurationTimeout"
                    component={renderTextField}
                    label="General Silence Duration Timeout (in ms)"
                    data-unique="txtVoipConnectorSilenceDurationTimeout"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['voip.silenceDurationTimeout']) || disabled || !values.voip.silenceDurationTimeoutEnable}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['voip.silenceDurationTimeout'])}
                  />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </GridItem>
        </React.Fragment>}
      </GridContainer>
    )}/>)
  }
}

const VoipConnectorEdit = connect(
  state => ({ features: state.settings.features })
)(VoipConnectorEditInternal)
export { VoipConnectorEdit }