import configureStore from './configureStore'

const defaultStoreContent = {
  dashboard: {
    showDataByExpected: false
  }
}
if (window.Cypress) {
  Object.assign(defaultStoreContent, {
    supportpopup: {
      show: false
    }
  })
}

export default configureStore(defaultStoreContent)
