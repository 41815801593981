import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import {NavLink} from 'react-router-dom'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import _ from 'lodash'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {CHART_ENTITY_QUERY} from '../gql'
import {
  mergeArrays
} from 'botium-box-shared/trainer/helper'
import {renderProgressOrError} from '../../helper'
import {renderTrendingText} from './helper'

class EntityConfidenceRisks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTable() {
    const {entitiesData, secondaryEntitiesData, match} = this.props
    const testSessionId = match.params.testSessionId

    let progressOrError = renderProgressOrError(entitiesData) || renderProgressOrError(secondaryEntitiesData)
    if (progressOrError) {
      return progressOrError
    }

    const idToPerActualEntity = mergeArrays(entitiesData.trainerChartEntity, secondaryEntitiesData ? secondaryEntitiesData.trainerChartEntity : null, 'name', false)

    let data = Object.values(idToPerActualEntity)
    data = data.map((e) => ({
        id: e.primary ? e.primary.id : null,
        name: e.primary ? e.primary.name : e.secondary.name,
        primaryAvg: (e.primary && !_.isNil(e.primary.avg)) ? e.primary.avg.toFixed(4) : 'N/A',
        secondaryAvg: (e.secondary && !_.isNil(e.secondary.avg)) ? e.secondary.avg.toFixed(4) : 'N/A',
        primaryCount: e.primary ? e.primary.count : 0,
        secondaryCount: e.secondary ? e.secondary.count : null,
        substractedAvg: (e.primary && e.secondary && !_.isNil(e.secondary.avg) && !_.isNil(e.primary.avg)) ? (e.primary.avg - e.secondary.avg) : null,
        substractedCount: (e.primary && e.secondary) ? (e.primary.count - e.secondary.count) : null,
      }))
    data = _.sortBy(data, (result) => result.primaryAvg)

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Entity', 'Average Confidence', 'Utterance Count']}
        tableData={data.map(entry => {
          return [
            () => (<NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/entityconfidence/actualentityid/${entry.id}`} data-unique={`btnCoachEntityConfidenceRisksName_${entry.id}`}>
              {entry.name}
              </NavLink>),
            () => renderTrendingText(entry.primaryAvg, !_.isNil(entry.substractedAvg) ? entry.substractedAvg : 0, false, 4, entry.secondaryCount),
            () => renderTrendingText(entry.primaryCount, !_.isNil(entry.substractedCount) ? entry.substractedCount: 0, false, 0, entry.secondaryCount)
          ]
        })}
      />
    )
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{this.renderTable()}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

// dont use CHART_ENTITY_SECONDARY_QUERY here, because this is simplier
// as side effect it is possible that an entity is displayed if it is just in secondary.
// And that is good
export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(CHART_ENTITY_QUERY, {
    props: ({data}) => ({
      entitiesData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          entityFilter: '',
          orderBy: 'avg_ASC'
        },
      }
    }
  }),
  graphql(CHART_ENTITY_QUERY, {
    props: ({data}) => ({
      secondaryEntitiesData: data
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.secondaryTestSessionId,
          entityFilter: '',
          orderBy: 'avg_ASC'
        },
      }
    },
    skip: (props) => !props.match.params.secondaryTestSessionId
  }),
)(EntityConfidenceRisks)
