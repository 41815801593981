import React from 'react'

import CompareIntentBase from './CompareIntentBase'

export default class CompareIntentWrongNowCorrect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {match, history} = this.props
    return (
      <CompareIntentBase fieldToProcess="wrongToCorrect" match={match} history={history}/>
    )
  }
}
