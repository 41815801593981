import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

const style = theme => ({
  grid: {
    margin: '0 -12px !important',
    width: 'unset',
  },
  gridNoUnset: {
    margin: '0 -15px !important'
  },
  noMargin: {
    margin: '0 0px !important'
  },
  darkOnly: {
    backgroundColor: theme.colors.common.backgroundDark
  },
  padding: {
    padding: 20
  },
  noPaddingBottom: {
    paddingBottom: 0
  },
  noPadding: {
    padding: '0!Important'
  },
  autoHeight: {
    height: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  top: {
    alignSelf: 'flex-start'
  },
  cursor: {
    cursor: 'pointer'
  },
  paddingTop: {
    paddingTop: 25,
  },
  flexWrapinherit: {
    flexWrap: 'inherit'
  },
  border: {
    border: `1px solid ${theme.colors.tableRow}`,
  },
  borderRadius: {
    borderRadius: 6,
  },
  paddingLeft: {
    paddingLeft: 15,
  },
  '@media (max-width: 1280px)': {
    flexWrapinherit: {
      flexWrap: 'wrap-reverse'
    }
  }
})

function GridContainer(props) {
  const { classes, 
    children, 
    nounset, 
    padding,
    noPadding, 
    noPaddingBottom, 
    smallPadding, 
    autoHeight, 
    noMargin, 
    fullWidth, 
    className, 
    darkOnly, 
    top, 
    paddingTop, 
    flexWrapinherit, 
    cursor,
    border,
    borderRadius,
    paddingLeft, 
    ...rest } = props

  const classNames = [
    nounset ? classes.gridNoUnset : classes.grid,
    top ? classes.top : '',
    padding ? classes.padding : '',
    noPaddingBottom ? classes.noPaddingBottom : '',
    autoHeight ? classes.autoHeight : '',
    noMargin ? classes.noMargin : '',
    fullWidth ? classes.fullWidth : '',
    darkOnly ? classes.darkOnly : '',
    cursor ? classes.cursor : '',
    paddingTop ? classes.paddingTop : '',
    flexWrapinherit ? classes.flexWrapinherit : '',
    smallPadding ? classes.smallPadding : '',
    noPadding ? classes.noPadding: '',
    border ? classes.border: '',
    borderRadius ? classes.borderRadius: '',
    paddingLeft ? classes.paddingLeft: '',
    className ? className : ''
  ].join(' ')

  return (
    <Grid container {...rest} className={classNames}>
      {children}
    </Grid>
  )
}

export default withStyles(style)(GridContainer)
