import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import StatsText from 'components/Stats/StatsText'

import ShowIcon from 'components/Icon/ShowIcon'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import Text from 'components/Typography/Text'

const auditSuggestions = {
  de: {
    header: () => <>Auswertung und Empfehlungen</>,
    detailsHeader: () => <>Detailempfehlungen</>,
    dseExists: {
      text: () => <>Da zumindest die IP-Adresse (als i.d.R. personenbezogenes Datum) verarbeitet wird, bedarf es jedenfalls einer Datenschutzerklärung (DSE)!</>
    },
    dseAvailable: {
      text: () => <>Machen Sie die Datenschutzerklärung (DSE) vor der Erfassung von personenbezogenen Daten und frühestmöglich bei Nutzung Ihres Chatbots zugänglich!</>
    },
    dseComplete: {
      text: () => <>Die Datenschutzerklärung (DSE) muss alle Informationen laut DSGVO, Art. 13, Buchstabe f enthalten.</>,
      details: () => <>
Die Datenschutzerklärung (DSE) muss alle Informationen laut DSGVO, Art. 13, Buchstabe f enthalten, zumindest:
<ul>
<li>den Namen und die Kontaktdaten des Verantwortlichen</li>
<li>die Zwecke, für die die personenbezogenen Daten verarbeitet werden sollen, sowie die Rechtsgrundlage für die Verarbeitung</li>
<li>die Empfänger der personenbezogenen Daten</li>
<li>die Dauer, für die die personenbezogenen Daten gespeichert werden oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer</li>
<li>das Bestehen eines Rechts auf Auskunft seitens des Verantwortlichen über die betreffenden personenbezogenen Daten sowie auf Berichtigung oder Löschung oder auf Einschränkung der Verarbeitung oder eines Widerspruchsrechts gegen die Verarbeitung sowie des Rechts auf Datenübertragbarkeit; und das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
<li>ob die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für einen Vertragsabschluss erforderlich ist, ob die betroffene Person verpflichtet ist, die personenbezogenen Daten bereitzustellen, und welche mögliche Folgen die Nichtbereitstellung hätte</li>
</ul>
wenn Daten "automatisch oder bei Dritten erhoben" (also nicht vom Nutzer eingegeben werden):
<ul>
<li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
<li>aus welcher Quelle die personenbezogenen Daten stammen und gegebenenfalls ob sie aus öffentlich zugänglichen Quellen stammen.</li>
</ul>      
      </>
    },
    datenErhobenNot: {
      text: () => <>Da die IP-Adresse für Chatbots technisch notwendig ist, darf diese jedenfalls (zum Chatbot-Betrieb) verarbeitet werden.</>
    },
    datenErhobenSecurity: {
      text: () => <>Bei der Erhebung personenbezogener Daten muss angemessene Datensicherheit gewährleistet sein (zB verschlüsselte Kommunikation, Vertraulichkeit, Integrität, Verfügbarkeit, Belastbarkeit und Wiederherstellbarkeit der Systeme).</>
    },
    datenErhobenServiceRequired: {
      text: () => <>Die Erhebung personenbezogener Daten, die zur Vertragserfüllung notwendig sind, ist zulässig. Zur rechtmäßigen Verarbeitung personenbezogener Daten im Rahmen des Chatbots müssen insbesondere die folgenden Fragen positiv beantwortet werden.</>
    },
    datenErhobenEinwilligung: {
      text: () => <>Soweit nicht ohnedies schon durch Vertragserfüllung gedeckt, ist die Erhebung personenbezogener Daten bis zum Widerruf zulässig, wenn eine informierte, konkrete Einwilligung eingeholt wurde.</>
    },
    datenErhobenDSE: {
      text: () => <>Die Erhebung personenbezogener Daten muss auch entsprechend in der Datenschutzerklärung (DSE) abgebildet sein.</>
    },
    datenAusgabeNot: {
      text: () => <>Von Ihrem Chatbot werden keine personenbezogenen Daten ausgegeben.</>
    },
    datenAusgabeSecurity: {
      text: () => <>Bei der Ausgabe personenbezogener Daten muss angemessene Datensicherheit gewährleistet sein (zB verschlüsselte Kommunikation, Vertraulichkeit, Integrität, Verfügbarkeit, Belastbarkeit und Wiederherstellbarkeit der Systeme).</>
    },
    datenAusgabeVerification: {
      text: () => <>Bei der Ausgabe personenbezogener Daten muss die Identität des Abfragenden durch Ihren Chatbot verifiziert werden.</>
    },
    datenAusgabeServiceRequired: {
      text: () => <>Die Ausgabe personenbezogener Daten zur Vertragserfüllung ist zulässig. Zur rechtmäßigen Verarbeitung personenbezogener Daten im Rahmen des Chatbots müssen insbesondere die folgenden Fragen positiv beantwortet werden.</>
    },
    datenAusgabeEinwilligung: {
      text: () => <>Soweit nicht ohnedies schon durch Vertragserfüllung gedeckt, ist die Ausgabe personenbezogener Daten bis zum Widerruf zulässig, wenn eine informierte, konkrete Einwilligung eingeholt wurde.</>
    },
    datenAusgabeDSE: {
      text: () => <>Die Ausgabe personenbezogener Daten muss auch entsprechend in der Datenschutzerklärung (DSE) abgebildet sein.</>
    },
    datenAnalyseNot: {
      text: () => <>Von Ihrem Chatbot werden keine personenbezogenen Daten analysiert oder für weitere Zwecke verarbeitet.</>
    },
    datenAnalyseSecurity: {
      text: () => <>Bei der Analyse personenbezogener Daten muss angemessene Datensicherheit gewährleistet sein (zB verschlüsselte Kommunikation, Vertraulichkeit, Integrität, Verfügbarkeit, Belastbarkeit und Wiederherstellbarkeit der Systeme).</>
    },
    datenAnalyseEinwilligung: {
      text: () => <>Die Analyse personenbezogener Daten ist bis zum Widerruf zulässig, wenn eine informierte, konkrete Einwilligung eingeholt wurde.</>
    },
    datenAnalyseDSE: {
      text: () => <>Die Analyse personenbezogener Daten muss auch entsprechend in der Datenschutzerklärung (DSE) abgebildet sein.</>
    },
    datenProfilingNot: {
      text: () => <>Von Ihrem Chatbot werden keine Entscheidungen getroffen (zb durch Profiling), welche rechtliche Wirkung gegenüber den Nutzern entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt.</>
    },
    datenProfilingContract: {
      text: () => <>Von Ihrem Chatbot werden Entscheidungen getroffen (zb durch Profiling), welche rechtliche Wirkung gegenüber den Nutzern entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt.</>,
      details: () => <>
Von Ihrem Chatbot werden Entscheidungen getroffen (zb durch Profiling), welche rechtliche Wirkung gegenüber den Nutzern entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt (etwa Ablehnung von Vertragsabschlüssen, automatische Kündigung oder dergleichen).
<br/>
Unter <i>Profiling</i> versteht sich jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
<br/>
Dies ist zulässig, wenn:
<ul>
<li>
solche Entscheidungen für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen des Chatbots zwingend erforderlich ist
</li>
<li>
oder aufgrund von Rechtsvorschriften, denen der Verantwortliche unterliegt, dies zulässig ist
</li>
<li>
oder die ausdrückliche Einwilligung des Nutzers vorliegt
</li>
</ul>
Selbst bei Zulässigkeit muss es für den Nutzer die Möglichkeit der Erwirkung des Eingreifens einer Person seitens des Verantwortlichen geben, sodass der Nutzer den eigenen Standpunkts darlegen und die Entscheidung anfechten kann.
      </>
    },
    datenProfilingDSE: {
      text: () => <>Das Treffen von Entscheidungen (zb durch Profiling) muss auch entsprechend in der Datenschutzerklärung (DSE) abgebildet sein.</>
    },
    datenForwardNot: {
      text: () => <>Von Ihrem Chatbot werden keine personenbezogenen Daten an Dritte übermittelt.</>
    },
    datenForwardAuftrag: {
      text: () => <>Von Ihrem Chatbot werden personenbezogene Daten an Dritte übermittelt.</>,
      details: () => <>
Von Ihrem Chatbot werden personenbezogene Daten an Dritte übermittelt.
Dies ist zulässig, wenn:
<ul>
<li>
dies ausschließlich zur Verarbeitung im Rahmen der obigen zulässigen Verarbeitung (Auftragsverarbeitervertrag) geschieht
</li>
<li>
oder die ausdrückliche Einwilligung des Nutzers vorliegt
</li>
</ul>      
      </>
    },
    datenForwardDSE: {
      text: () => <>Die Übermittlung personenbezogener Daten an Dritte muss auch entsprechend in der Datenschutzerklärung (DSE) abgebildet sein.</>
    },
    datenWerbungNot: {
      text: () => <>Von Ihrem Chatbot werden keine Werbenachrichten an Kunden gesendet.</>
    },
    datenWerbungEinwilligung: {
      text: () => <>Von Ihrem Chatbot werden Werbenachrichten an Kunden gesendet.</>,
      details: () => <>
Von Ihrem Chatbot werden Werbenachrichten an Kunden gesendet.
Dies ist zulässig, wenn:
<ul>
<li>
die ausdrückliche Einwilligung des Nutzers vorliegt
</li>
</ul>      
      </>
    },
    datenWerbungDSE: {
      text: () => <>Das Senden von Werbenachrichten an Kunden muss auch entsprechend in der Datenschutzerklärung (DSE) abgebildet sein.</>
    }
  },
  en: {
    header: () => <>Evaluation and Recommendations</>,
    detailsHeader: () => <>Detailed Suggestions</>,
    dseExists: {
      text: () => <>Since at least the IP address (usually personal data) is processed, a Privacy Policy (PP) is required!</>
    },
    dseAvailable: {
      text: () => <>Make the Privacy Policy (PP) accessible before collecting personal data and as soon as possible when using your chatbot!</>
    },
    dseComplete: {
      text: () => <>The Privacy Policy (PP) must contain all information according to DSGVO, Art. 13, letter f included.</>,
      details: () => <>
The data protection act (DPA) must contain all information according to GDPR, Art. 13, containing letter f, at least:
<ul>
<li>the name and contact details of the person responsible</li>
<li>the purposes for which the personal data are to be processed and the legal basis for the processing</li>
<li>the recipients of the personal data</li>
<li>the period for which the personal data will be stored or, if this is not possible, the criteria used to determine that period</li>
<li>The existence of a right to information on the part of the person responsible for the personal data concerned and to correction or deletion or to restriction of processing or a right to object to processing and the right to data portability; and the existence of a right of appeal to a supervisory authority</li>
<li>whether the provision of the personal data is required by law or contract or is necessary for the conclusion of a contract, whether the data subject is obliged to provide the personal data and what the possible consequences of not providing it would be</li>
</ul>
if data is "collected automatically or from third parties" (i.e. not entered by the user):
<ul>
<li>the categories of personal data being processed</li>
<li>The source from which the personal data originates and, if applicable, whether it originates from publicly accessible sources.</li>
</ul>
      </>
    },
    datenErhobenNot: {
      text: () => <>Since the IP address is technically necessary for chatbots, it may in any case be processed (for chatbot operation).</>
    },
    datenErhobenSecurity: {
      text: () => <>Adequate data security must be guaranteed when personal data is collected (e.g. encrypted communication, confidentiality, integrity, availability, resilience and recoverability of the systems).</>
    },
    datenErhobenServiceRequired: {
      text: () => <>The collection of personal data that is necessary for the fulfillment of the contract is permitted. For the lawful processing of personal data in the context of the chatbot, the following questions in particular must be answered positively.</>
    },
    datenErhobenEinwilligung: {
      text: () => <>Insofar as this is not already covered by the fulfillment of the contract, the collection of personal data is permitted until revoked if informed, specific consent has been obtained.</>
    },
    datenErhobenDSE: {
      text: () => <>The collection of personal data must also be reflected in the Privacy Policy (PP).</>
    },
    datenAusgabeNot: {
      text: () => <>Your chatbot does not output any personal data.</>
    },
    datenAusgabeSecurity: {
      text: () => <>When personal data is issued, appropriate data security must be guaranteed (e.g. encrypted communication, confidentiality, integrity, availability, resilience and recoverability of the systems).</>
    },
    datenAusgabeVerification: {
      text: () => <>When issuing personal data, the identity of the querent must be verified by your chatbot.</>
    },
    datenAusgabeServiceRequired: {
      text: () => <>The output of personal data for the fulfillment of the contract is permitted. For the lawful processing of personal data in the context of the chatbot, the following questions in particular must be answered positively.</>
    },
    datenAusgabeEinwilligung: {
      text: () => <>Insofar as this is not already covered by the fulfillment of the contract, the output of personal data is permitted until revoked if informed, specific consent has been obtained.</>
    },
    datenAusgabeDSE: {
      text: () => <>The output of personal data must also be reflected accordingly in the Privacy Policy (PP).</>
    },
    datenAnalyseNot: {
      text: () => <>Your chatbot does not analyze or process any personal data for other purposes.</>
    },
    datenAnalyseSecurity: {
      text: () => <>Adequate data security must be guaranteed when analyzing personal data (e.g. encrypted communication, confidentiality, integrity, availability, resilience and recoverability of the systems).</>
    },
    datenAnalyseEinwilligung: {
      text: () => <>The analysis of personal data is permitted until revoked if informed, specific consent has been obtained.</>
    },
    datenAnalyseDSE: {
      text: () => <>The analysis of personal data must also be reflected accordingly in the Privacy Policy (PP).</>
    },
    datenProfilingNot: {
      text: () => <>Your chatbot does not make any decisions (e.g. through profiling) that have legal effect on the users or significantly affect them in a similar way.</>
    },
    datenProfilingContract: {
      text: () => <>Your chatbot makes decisions (e.g. through profiling) that have legal effects on users or significantly affect them in a similar way.</>,
      details: () => <>
Your chatbot makes decisions (e.g. through profiling) that have a legal effect on the users or significantly affect them in a similar way (e.g. refusal to conclude contracts, automatic termination or similar).
<br/>
<i>Profiling</i> means any type of automated processing of personal data that consists of using them to evaluate certain personal aspects relating to a natural person, in particular to analyze or predict aspects relating to that natural person's job performance, economic situation, health, personal preferences, interests, reliability, behavior, location or change of location.
<br/>
This is allowed if:
<ul>
<li>
such decisions are mandatory for entering into, or the performance of, a contract between the data subject and the person responsible for the chatbot
</li>
<li>
or due to legal regulations to which the person responsible is subject, this is permissible
</li>
<li>
or the user has given his or her consent
</li>
</ul>
Even if it is admissible, the user must be able to obtain human intervention on the part of the controller so that the user can present his or her point of view and contest the decision.
      </>
    },
    datenProfilingDSE: {
      text: () => <>Making decisions (e.g. through profiling) must also be reflected accordingly in the Privacy Policy (PP).</>
    },
    datenForwardNot: {
      text: () => <>Your chatbot does not transmit any personal data to third parties.</>
    },
    datenForwardAuftrag: {
      text: () => <>Your chatbot transmits personal data to third parties.</>,
      details: () => <>
Your chatbot transmits personal data to third parties.
This is allowed if:
<ul>
<li>
this is done exclusively for processing within the scope of the above permissible processing (processor contract).
</li>
<li>
or the user has given his or her consent
</li>
</ul>   
      </>
    },
    datenForwardDSE: {
      text: () => <>The transmission of personal data to third parties must also be reflected accordingly in the Privacy Policy (PP).</>
    },
    datenWerbungNot: {
      text: () => <>Your chatbot does not send promotional messages to customers.</>
    },
    datenWerbungEinwilligung: {
      text: () => <>Your chatbot sends promotional messages to customers.</>,
      details: () => <>
Promotional messages are sent to customers from your chatbot.
This is allowed if:
<ul>
<li>
the express consent of the user has been obtained
</li>
</ul>
      </>
    },
    datenWerbungDSE: {
      text: () => <>The sending of advertising messages to customers must also be reflected accordingly in the Privacy Policy (PP).</>
    }
  }
}

export const getIssueCount = (values) => {
  let issueCount = 0

  if (!values.dseExists) issueCount++

  if (values.dseExists) {
    if (!values.dseAvailable) issueCount++
    if (!values.dseComplete || !values.dseExistsKontaktdaten || !values.dseExistsZwecke || !values.dseExistsEmpfaenger || !values.dseExistsDauer || !values.dseExistsAndere) issueCount++

    if (values.datenErhoben) {
      if (!values.datenErhobenSecurity) issueCount++
      if (!values.datenErhobenServiceRequired) issueCount++
      if (!values.datenErhobenEinwilligung) issueCount++
      if (!values.datenErhobenDSE) issueCount++
    }
    if (values.datenAusgabe) {
      if (!values.datenAusgabeSecurity) issueCount++
      if (!values.datenAusgabeVerification) issueCount++
      if (!values.datenAusgabeServiceRequired) issueCount++
      if (!values.datenAusgabeEinwilligung) issueCount++
      if (!values.datenAusgabeDSE) issueCount++
    }
    if (values.datenAnalyse) {
      if (!values.datenAnalyseSecurity) issueCount++
      if (!values.datenAnalyseEinwilligung) issueCount++
      if (!values.datenAnalyseDSE) issueCount++
    }
    if (values.datenProfiling) {
      if (!values.datenProfilingContract || !values.datenProfilingLegal || !values.datenProfilingEinwilligung) issueCount++
      if (!values.datenProfilingDSE) issueCount++
    }
    if (values.datenForward) {
      if (!values.datenForwardAuftrag || !values.datenForwardEinwilligung) issueCount++
      if (!values.datenForwardDSE) issueCount++
    }
    if (values.datenWerbung) {
      if (!values.datenWerbungEinwilligung) issueCount++
      if (!values.datenWerbungDSE) issueCount++
    }    
  }
  return issueCount
}

export const getFallbackTooltip = (testsession) => {
  let fallbackTooltip = null
  if (testsession && testsession.gdprTestSessionAudit && testsession.gdprTestSessionAudit.fallbackIntents && testsession.gdprTestSessionAudit.fallbackIntents.length > 0) {
    const fallbackTooltipLines = []
    testsession.gdprTestSessionAudit.fallbackIntents.filter(f => f.intent).forEach(f => {
      fallbackTooltipLines.push(`NLU Fallback intent "${f.intent}"`)
    })
    testsession.gdprTestSessionAudit.fallbackIntents.filter(f => f.messageText).forEach(f => {
      fallbackTooltipLines.push(`Text response "${f.messageText}"`)
    })
    fallbackTooltip = `Chatbot answered with one of the following: ${fallbackTooltipLines.join(' | ')}`
  }
  return fallbackTooltip
}

class GDPRSessionSuggestions extends React.Component {
  renderSuggestion(suggestion, ok) {
    const { testSession } = this.props
    const suggestions = auditSuggestions[testSession.gdprTestSessionAudit.language]

    if (ok) {
      return (<>
        <GridItem xs={1}>
          <StatsText success primaryText={<ShowIcon icon="check-square" />} />
        </GridItem>
        <GridItem xs={11}>
          <Text>{React.createElement(suggestion.text)}</Text>
        </GridItem>
        {suggestion.details &&
          <GridItem xs={12} largePadding>
            <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Text>{React.createElement(suggestions.detailsHeader)}</Text>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Text>{React.createElement(suggestion.details)}</Text>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </GridItem>
        }
      </>)
    } else {
      return (<>
        <GridItem xs={1}>
          <StatsText error primaryText={<ShowIcon icon="exclamation-triangle" />} />
        </GridItem>
        <GridItem xs={11}>
          <Text>{React.createElement(suggestion.text)}</Text>
        </GridItem>      
        {suggestion.details &&
          <GridItem xs={12} largePadding>
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Text>{React.createElement(suggestions.detailsHeader)}</Text>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Text>{React.createElement(suggestion.details)}</Text>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </GridItem>
        }
      </>)
    }
  }

  render() {
    const { testSession, formValues } = this.props

    if (!testSession) return null
    if (!testSession.gdprTestSessionAudit || !auditSuggestions[testSession.gdprTestSessionAudit.language]) {
      return <Text warning>No Suggestions available</Text>
    }

    const suggestions = auditSuggestions[testSession.gdprTestSessionAudit.language]
    const values = formValues || (testSession.gdprTestSessionAudit.formValues && JSON.parse(testSession.gdprTestSessionAudit.formValues)) || {}

    const issuesCount = getIssueCount(values)

    return <GridContainer>
      <GridItem xs={12} largePadding>
        <Text header data-unique="txtSuggestionsHeader" data-status={`issue-count-${issuesCount}`}>{React.createElement(suggestions.header)} ({issuesCount === 0 ? 'No Issues' : issuesCount === 1 ? '1 Issue' : issuesCount + ' Issues'})</Text>
      </GridItem>
      {this.renderSuggestion(suggestions.dseExists, values.dseExists)}
      {values.dseExists && this.renderSuggestion(suggestions.dseAvailable, values.dseAvailable)}
      {values.dseExists && this.renderSuggestion(suggestions.dseComplete, (values.dseComplete && values.dseExistsKontaktdaten && values.dseExistsZwecke && values.dseExistsEmpfaenger && values.dseExistsDauer && values.dseExistsAndere))}

      {values.dseExists && !values.datenErhoben && this.renderSuggestion(suggestions.datenErhobenNot, true)}
      {values.dseExists && values.datenErhoben && this.renderSuggestion(suggestions.datenErhobenSecurity, values.datenErhobenSecurity)}
      {values.dseExists && values.datenErhoben && this.renderSuggestion(suggestions.datenErhobenServiceRequired, values.datenErhobenServiceRequired)}
      {values.dseExists && values.datenErhoben && this.renderSuggestion(suggestions.datenErhobenEinwilligung, values.datenErhobenEinwilligung)}
      {values.dseExists && values.datenErhoben && this.renderSuggestion(suggestions.datenErhobenDSE, values.datenErhobenDSE)}

      {values.dseExists && !values.datenAusgabe && this.renderSuggestion(suggestions.datenAusgabeNot, true)}
      {values.dseExists && values.datenAusgabe && this.renderSuggestion(suggestions.datenAusgabeSecurity, values.datenAusgabeSecurity)}
      {values.dseExists && values.datenAusgabe && this.renderSuggestion(suggestions.datenAusgabeVerification, values.datenAusgabeVerification)}
      {values.dseExists && values.datenAusgabe && this.renderSuggestion(suggestions.datenAusgabeServiceRequired, values.datenAusgabeServiceRequired)}
      {values.dseExists && values.datenAusgabe && this.renderSuggestion(suggestions.datenAusgabeEinwilligung, values.datenAusgabeEinwilligung)}
      {values.dseExists && values.datenAusgabe && this.renderSuggestion(suggestions.datenAusgabeDSE, values.datenAusgabeDSE)}

      {values.dseExists && !values.datenAnalyse && this.renderSuggestion(suggestions.datenAnalyseNot, true)}
      {values.dseExists && values.datenAnalyse && this.renderSuggestion(suggestions.datenAnalyseSecurity, values.datenAnalyseSecurity)}
      {values.dseExists && values.datenAnalyse && this.renderSuggestion(suggestions.datenAnalyseEinwilligung, values.datenAnalyseEinwilligung)}
      {values.dseExists && values.datenAnalyse && this.renderSuggestion(suggestions.datenAnalyseDSE, values.datenAnalyseDSE)}

      {values.dseExists && !values.datenProfiling && this.renderSuggestion(suggestions.datenProfilingNot, true)}
      {values.dseExists && values.datenProfiling && this.renderSuggestion(suggestions.datenProfilingContract, values.datenProfilingContract && values.datenProfilingLegal && values.datenProfilingEinwilligung)}
      {values.dseExists && values.datenProfiling && this.renderSuggestion(suggestions.datenProfilingDSE, values.datenProfilingDSE)}

      {values.dseExists && !values.datenForward && this.renderSuggestion(suggestions.datenForwardNot, true)}
      {values.dseExists && values.datenForward && this.renderSuggestion(suggestions.datenForwardAuftrag, values.datenForwardAuftrag && values.datenForwardEinwilligung)}
      {values.dseExists && values.datenForward && this.renderSuggestion(suggestions.datenForwardDSE, values.datenForwardDSE)}

      {values.dseExists && !values.datenWerbung && this.renderSuggestion(suggestions.datenWerbungNot, true)}
      {values.dseExists && values.datenWerbung && this.renderSuggestion(suggestions.datenWerbungEinwilligung, values.datenWerbungEinwilligung)}
      {values.dseExists && values.datenWerbung && this.renderSuggestion(suggestions.datenWerbungDSE, values.datenWerbungDSE)}
    </GridContainer>
  }
}

export default withStyles(testsessionsStyle)(GDPRSessionSuggestions)