import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BadgeCore from '@material-ui/core/Badge'

export default withStyles(theme => ({
  root: {
    position: 'absolute'
  },
  badge: {
    borderRadius: '0.2rem',
    height: 17,
    margin: '-2px'
  },
  colorPrimary: {
    backgroundColor: theme.colors.common.backgroundBadge,
    color: theme.colors.common.primary
  },
  colorSecondary: {
    backgroundColor: theme.colors.borderColorhover,
    color: theme.colors.common.secondary,
    fontWeight: 900,
  }
}))(({ classes, children, ...props }) => {
  return <BadgeCore classes={classes} {...props}>{children || ''}</BadgeCore>
})
