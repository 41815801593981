import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Mutation, withApollo, compose, graphql } from 'react-apollo'

import { setAlertSuccessMessage } from 'actions/alert'
import crawlerprojectsStyle from 'assets/jss/material-dashboard-react/views/crawlerprojectsStyle.jsx'
import {
  CRAWLER_SESSION_CONVO_QUERY,
  CRAWLERGUESSUSERRESPONSE_QUERY,
  CRAWLERSESSION_QUERY,
  CRAWLERSESSION_TREE_QUERY,
  UPSERT_CRAWLERANSWERS
} from './gql'
import {CHECKFEATURECONFIGURED_QUERY} from '../Settings/gql'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { renderAutoSuggest } from 'components/Form/Form'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import { getConvoTxtWithResolvedUtterance } from './helper'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import {extractErrorMessage} from '../../helper/graphHelper'

class CrawlerAnswerDialog extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      userResponseGuessing: false,
      showError: null
    }
  }

  render () {
    const { showCrawlerAnswerDialog, scriptData, crawlerSessionId, onComplete, onCancel, setAlertSuccessMessage, crawlerguessData } = this.props
    const crawlerAnswer = scriptData.crawlerAnswer
    const { userResponseGuessing, showError } = this.state
    const crawlerGuessUserresponseAvailable = crawlerguessData && crawlerguessData.checkfeatureconfigured && crawlerguessData.checkfeatureconfigured.isConfigured && crawlerguessData.checkfeatureconfigured.isLicensed

    const refetchQueries = [
      {
        query: CRAWLERSESSION_QUERY,
        variables: { id: crawlerSessionId },
      },
      {
        query: CRAWLERSESSION_TREE_QUERY,
        variables: { id: crawlerSessionId },
      }
    ]
    if (scriptData.scriptId) {
      refetchQueries.push({
        query: CRAWLER_SESSION_CONVO_QUERY,
          variables: { crawlerSessionScriptId: scriptData.scriptId },
      })
    }

    let guessUserResponseSteps = scriptData.steps
    if (guessUserResponseSteps) {
      if (!guessUserResponseSteps.length) {
        guessUserResponseSteps = null
      }
    }

    return (
      <Mutation
        mutation={
          UPSERT_CRAWLERANSWERS
        }
        onCompleted={data => {
          onComplete()
          setAlertSuccessMessage('User responses saved')
        }}
        onError={error => {
          this.setState({ showError: error })
        }}
        refetchQueries={({ data }) => {
          return refetchQueries
        }}
      >
        {(mutateCrawlerAnswer, { loading, error }) => (
          <Form
            onSubmit={values => {
              mutateCrawlerAnswer({
                variables: {
                  crawlerAnswer: {
                    crawlerSessionId,
                    paths: scriptData.paths,
                    answers: {
                      set: values.answers,
                    },
                  }
                }
              })
            }}
            initialValues={crawlerAnswer ? crawlerAnswer : {}}
            render={({ handleSubmit, values, form: { change } }) => (
              <ConfirmationDialog
                open={showCrawlerAnswerDialog}
                onCancel={() => onCancel()}
                onOk={() => handleSubmit()}
                showError={showError && extractErrorMessage(showError, true)}
                clearError={() => this.setState({ showError: null })}
                extraButton={
                  <>
                    {guessUserResponseSteps && crawlerGuessUserresponseAvailable && <Button
                      secondary
                      data-unique="btnCrawlerGuessUserResponse"
                      disabled={!!userResponseGuessing}
                      onClick={() => this.guessUserResponse(values, change, guessUserResponseSteps)}>
                      {!userResponseGuessing && <ShowIcon icon="magic"/>}
                      {userResponseGuessing && <LoadingIndicator alt />}
                      Guess User Response
                    </Button>}
                  </>
                }
                title={'User responses'}
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        name="answers"
                        component={renderAutoSuggest}
                        disabled={!!userResponseGuessing}
                        label="User responses"
                        helperText="A list of user responses on the last open-ended question."
                        data-unique="txtCrawlerAnswersAnswers"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  async guessUserResponse (values, change, guessUserResponseSteps) {
    const { client } = this.props
    this.setState({ userResponseGuessing: true })

    try {
      if (guessUserResponseSteps && guessUserResponseSteps.length) {
        const script = await getConvoTxtWithResolvedUtterance(guessUserResponseSteps)
        const {data} = await client.query({
          query: CRAWLERGUESSUSERRESPONSE_QUERY,
          variables: {
            script,
            reservedUserResponses: values.answers
          },
          fetchPolicy: 'network-only'
        })

        if (data.crawlerguessuserresponse) {
          if (!data.crawlerguessuserresponse.userResponse) {
            this.setState({showError: 'Not possible to generate more user responses for this conversation'})
          } else {
            change('answers', (values.answers || []).concat([data.crawlerguessuserresponse.userResponse]))
          }
        }
      } else {
        this.setState({showError: 'User response guessing failed, because there are no convo steps'})
      }
    } catch(error) {
      this.setState({showError: `User response guessing failed: ${error}`})
    } finally {
      this.setState({ userResponseGuessing: false })
    }
  }
}

export default compose(
  withStyles(
    (theme) => ({
      ...crawlerprojectsStyle(theme),
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, features: state.settings.features }),
    { setAlertSuccessMessage }
  ),
  graphql(CRAWLER_SESSION_CONVO_QUERY, {
    props: ({ data }) => ({
      crawlerConvoData: data,
    }),
    options: (props) => {
      return {
        variables: {
          crawlerSessionScriptId: props.scriptData.scriptId
        }
      }
    },
    skip: (props) => !(props.scriptData.steps && props.scriptData.steps.length)
  }),
  graphql(CHECKFEATURECONFIGURED_QUERY, {
    props: ({ data }) => ({
      crawlerguessData: data,
    }),
    options: (props) => {
      return {
        variables: {
          feature: 'crawlerguess'
        }
      }
    }
  }),
  withApollo
)(CrawlerAnswerDialog)
