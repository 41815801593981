import {
  container,
  defaultFont,
  defaultBoxShadow,
} from 'assets/jss/material-dashboard-react.jsx'

const headerStyle = theme => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    //borderBottom: '0',
    //marginBottom: '0',
    position: 'relative',
    width: '100%',
    //zIndex: '1029',
    color: theme.colors.primary,
    border: 0,
    //borderRadius: '3px',
    padding: '10px 0 0 0',
    transition: 'all 150ms ease 0s',
    display: 'block',
  },
  container: {
    ...container,
    minHeight: '80px',
    alignItems: 'start',
    display: 'block',
  },
  breadcrumb: {
    ...defaultFont,
    fontSize: 12,
    paddingTop: 22,
    color: theme.colors.primary,
    '& a': {
      padding: 5,
      fontWeight: 'bold',
      textDecoration: 'underline',
      color: theme.colors.primary,
      '&:hover': {
        textDecoration: 'none',
        color: theme.colors.primary,
      },
      '&:focus-visible': {
        border: `2px solid ${theme.colors.visibleborderColor}`,
        borderRadius: 5
      }
    },
    '& a:last-child':{
      fontWeight: 'normal'
    }
  },
  breadcrumbActive: {
    color: theme.colors.primary,
    marginRight: 12,

  },
  flex: {
    flex: 1,
    width: '32%',
  },
  smDown: {
  },
  mdUp: {
  },
  title: {
    ...defaultFont,
    width: '100%',
    lineHeight: '30px',
    fontSize: '21px',
    fontWeight: 'bold',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    margin: '0',
    paddingTop: '10px',
    paddingLeft: 0,
    paddingRight: '20px',
    '&:hover,&:focus': {
      background: 'transparent',
    },
    display: 'inline-block',
    //whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  appResponsive: {
    top: '8px',
  },
  menuButton: {
  },
  primary: {
    backgroundColor: theme.colors.primary,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: theme.colors.info,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: theme.colors.success,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: theme.colors.warning,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: theme.colors.danger,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  col3: {
    paddingLeft: 20
  },
  '@media (max-width: 1280px)': {
    col1: {
      order: 2,
      flexBasis: '100%!Important',
      maxWidth: '100%!Important'
    },
    col2: {
      order: 1,
      flexBasis: '100%!Important',
      maxWidth: '100%!Important'
    },
    col3: {
      order: 3,
    },
  }
})

export default headerStyle
