import React from 'react'
import { connect } from 'react-redux'
import { gitUrlParse } from './git-url-parse.jsx'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from 'components/Tooltip/Tooltip'
import { Form, FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
// apollo
import { Mutation, withApollo, compose, graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  renderCheckbox,
  renderPasswordField,
  required,
  renderSelect,
  gitUrl,
  isGitUrl,
  composeValidators,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {GITREPOSITORYBRANCHES_QUERY, RefetchTestSetQueries} from './gql'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import DirectorySelectionDialogWithoutFileSystemQuery from '../../components/Dialog/DirectorySelectionDialogWithoutFileSystemQuery'
import Text from 'components/Typography/Text.jsx'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'

const FULL_TESTSETREPOSITORY_FRAGMENT = gql`
  fragment FullTestSetRepository on TestSetRepository {
    id
    createdAt
    updatedAt
    name
    giturl
    gitbranch
    gitdir
    gituser
    globFilter
    skip
    testSet {
      id
      name
    }
  }
`

const TESTSETREPOSITORY_QUERY = gql`
  query TestSetRepositoryQuery($id: ID!) {
    testsetrepository(id: $id) {
      ...FullTestSetRepository
    }
  }
  ${FULL_TESTSETREPOSITORY_FRAGMENT}
`

const CREATE_TESTSETREPOSITORY = gql`
  mutation CreateTestSetRepository(
    $testSetRepository: TestSetRepositoryCreateInput!
  ) {
    createTestSetRepository(testSetRepository: $testSetRepository) {
      ...FullTestSetRepository
    }
  }
  ${FULL_TESTSETREPOSITORY_FRAGMENT}
`

const UPDATE_TESTSETREPOSITORY = gql`
  mutation UpdateTestSetRepository(
    $id: ID!
    $testSetRepository: TestSetRepositoryUpdateInput!
  ) {
    updateTestSetRepository(id: $id, testSetRepository: $testSetRepository) {
      ...FullTestSetRepository
    }
  }
  ${FULL_TESTSETREPOSITORY_FRAGMENT}
`

const DELETE_TESTSETREPOSITORY = gql`
  mutation DeleteTestSetRepository($id: ID!) {
    deleteTestSetRepository(id: $id)
  }
`

export const GITREPOSITORYDIRSTRUCTURE_QUERY = gql`
  query GitRepositoryDirStructureQuery(
    $testSetId: ID!
    $id: ID
    $giturl: String!
    $gitbranch: String!
    $gituser: String
    $gitpassword: String
  ) {
    gitrepositorydirstructure(testSetId: $testSetId, id: $id giturl: $giturl, gitbranch: $gitbranch, gituser: $gituser, gitpassword: $gitpassword) {
      directoryStructure
      err
    }
  }
`

class TestSetRepository extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      deleting: false,
      validateResult: null,
      showRepositoryDirectoryDialog: false,
      gitRepositoryDirStructureFetching: false,
      directoryStructure: undefined,
      remoteBranches: undefined
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  validate = _.debounce((testSetId, values) => {
    const { client } = this.props
    return this.hasWritePermission() && values.giturl && isGitUrl(values.giturl) && client.query({
      query: GITREPOSITORYBRANCHES_QUERY,
      variables: {
        testSetId,
        id: values.id,
        giturl: values.giturl,
        gituser: values.gituser,
        gitpassword: values.gitpassword
      }
    })
      .then(({ data }) => {
        if (!data.gitrepositorybranches) {
          return { giturl: 'Git Repository validation failed' }
        }
        if (data.gitrepositorybranches.err) {
          return { giturl: `Git Repository validation failed: ${data.gitrepositorybranches.err}` }
        }
      })
      .catch(err => {
        return { giturl: `Git Repository validation failed: ${err.message}` }
      })
  }, 500)

  setGitRepositoryBranchesState = _.debounce(async (testSetId, values) => {
    const gitRepositoryBranches = await this.repositoryBranches(testSetId, values)
    if(gitRepositoryBranches && !gitRepositoryBranches.err) {
      this.setState({ remoteBranches: gitRepositoryBranches.remoteBranches})
    } else {
      this.setState({ remoteBranches: []})
    }
  }, 500)

  repositoryBranches(testSetId, values) {
    const { client } = this.props
    return this.hasWritePermission() && values.giturl && values.gitbranch && client.query({
      query: GITREPOSITORYBRANCHES_QUERY,
      variables: {
        testSetId: testSetId,
        id: values.id,
        giturl: values.giturl,
        gituser: values.gituser,
        gitpassword: values.gitpassword
      }
    })
      .then(({ data }) => {
        if (data.gitrepositorybranches) {
          return {
            remoteBranches: data.gitrepositorybranches.remoteBranches,
            err: data.gitrepositorybranches.err
          }
        }
      })
      .catch(err => {
        return {
          err
        }
      })
  }

  async setGitRepositoryDirStructureState(testSetId, values, setAlertSuccessMessage, setAlertErrorMessage) {
    this.setState({gitRepositoryDirStructureFetching: true})
    const gitRepositoryDirStructure = await this.repositoryDirectoryStructure(testSetId, values)
    if(gitRepositoryDirStructure && !gitRepositoryDirStructure.err) {
      this.setState({directoryStructure: gitRepositoryDirStructure.directoryStructure})
      setAlertSuccessMessage('Directory structure is fetched from git repository')
    } else {
      setAlertErrorMessage(`Can't fetch directory structure from git repository.`, new Error(gitRepositoryDirStructure.err))
    }
    this.setState({gitRepositoryDirStructureFetching: false})
  }

  repositoryDirectoryStructure(testSetId, values) {
    const { client } = this.props
    return this.hasWritePermission() && values.giturl && values.gitbranch && client.query({
      query: GITREPOSITORYDIRSTRUCTURE_QUERY,
      variables: {
        testSetId: testSetId,
        id: values.id,
        giturl: values.giturl,
        gitbranch: values.gitbranch,
        gituser: values.gituser,
        gitpassword: values.gitpassword,
      }
    })
      .then(({data}) => {
        if (data.gitrepositorydirstructure) {
          return {
            directoryStructure: JSON.parse(data.gitrepositorydirstructure.directoryStructure),
            err: data.gitrepositorydirstructure.err
          }
        }
      })
      .catch(err => {
        return {
          err
        }
      })
  }

  renderForm(testsetrepository, testSetId) {
    const { saving, deleting } = this.state
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props

    return (
      <Mutation
        mutation={
          testsetrepository.id
            ? UPDATE_TESTSETREPOSITORY
            : CREATE_TESTSETREPOSITORY
        }
        refetchQueries={[
          ...RefetchTestSetQueries(testSetId, license)
        ]}
      >
        {(mutateTestSetRepository, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              this.setState({ saving: true })
              const data = {
                name: values.name,
                giturl: values.giturl,
                gitbranch: values.gitbranch || null,
                gitdir: values.gitdir || null,
                gituser: values.gituser || null,
                globFilter: values.globFilter || null,
                skip: !!values.skip
              }
              if (values.gitpassword === 'X') {
                data.gitpassword = null
              } else if (values.gitpassword && values.gitpassword.length > 0) {
                data.gitpassword = values.gitpassword
              }
              if (testsetrepository.id) {
                try {
                  const res = await mutateTestSetRepository({
                    variables: {
                      id: values.id,
                      testSetRepository: data,
                    },
                  })
                  form.initialize(res.data.updateTestSetRepository)
                  history.push(`/testsets/view/${testSetId}/settings/remote/viewrepository/${res.data.updateTestSetRepository.id}`)
                  setAlertSuccessMessage('Git Repository ready for use')
                } catch(error) {
                  setAlertErrorMessage(`Git Repository update failed`, error)
                }
              } else {
                data.testSet =  {
                  connect: {
                    id: testSetId,
                  },
                }

                try {
                  const res = await mutateTestSetRepository({
                    variables: {
                      testSetRepository: data,
                    },
                  })
                  form.initialize(res.data.createTestSetRepository)
                  history.push(`/testsets/view/${testSetId}/settings/remote/viewrepository/${res.data.createTestSetRepository.id}`)
                  setAlertSuccessMessage('Git Repository ready for use')
                } catch(error) {
                  setAlertErrorMessage(`Git Repository registration failed`, error)
                }
              }
              this.setState({ saving: false })
            }}
            validate={this.validate.bind(this, testSetId)}
            initialValues={testsetrepository}
            render={({
              handleSubmit,
              submitting,
              invalid,
              validating,
              values,
              form: { change }
            }) => {
              const gitBranches = this.state.remoteBranches ?
                this.state.remoteBranches.map(a => {
                  return { key: a }
                }) :
                _.get(this.props, 'gitRepositoryBranchesResponse.gitrepositorybranches.remoteBranches') ?
                  this.props.gitRepositoryBranchesResponse.gitrepositorybranches.remoteBranches.map(a => {
                    return { key: a }
                  }) : [ { key: 'master' } ]

              return <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Repository Name"
                      validate={required}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetRepositoryName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="skip"
                      component={renderCheckbox}
                      label="Ignore when running Test Sessions"
                      type="checkbox"
                      disabled={!this.hasWritePermission()}
                      data-unique="chkTestSetRepositorySkip"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="giturl"
                      component={renderTextField}
                      label="Git Clone Url"
                      validate={composeValidators(required, gitUrl)}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetRepositoryGitUrl"
                      endAdornment={validating && <LoadingIndicator />}
                    />
                  </GridItem>
                  <FormSpy subscription={{ form: true }} render={({ form:  { change }}) => (
                    <OnChange name="giturl">
                      {async (value, previous) => {
                        if (value) {
                          change('gitbranch', null)
                          if (!values.name) {
                            const gitUrlObject = gitUrlParse(value)
                            change('name', gitUrlObject.name)
                          }
                          if(isGitUrl(value)){
                            await this.setGitRepositoryBranchesState(testSetId, values)
                            this.setState({ directoryStructure: undefined })
                          } else {
                            this.setState({ remoteBranches: [], directoryStructure: undefined })
                          }
                        } else {
                          this.setState({ remoteBranches: [], directoryStructure: undefined})
                        }
                      }}
                    </OnChange>
                  )} />
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="gituser"
                      component={renderTextField}
                      label="Git User"
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetRepositoryGitUser"
                    />
                  </GridItem>
                  <FormSpy subscription={{ form: true }} render={({ form:  { change }}) => (
                    <OnChange name="gituser">
                      {async (value, previous) => {
                        await this.setGitRepositoryBranchesState(testSetId, values)
                        this.setState({ directoryStructure: undefined })
                      }}
                    </OnChange>
                  )} />
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="gitpassword"
                      component={renderPasswordField}
                      label={testsetrepository.id ? 'Change Git Password or Access Token' : 'Git Password or Access Token' }
                      disabled={!this.hasWritePermission()}
                      data-unique="pwTestSetRepositoryGitPassword"
                      helperText="Based on the git repository management application it can be different. E.g. in GitHub and GitLab it is called access token, in Bitbucket it is called app password, etc."
                    />
                  </GridItem>
                  <FormSpy subscription={{ form: true }} render={({ form:  { change }}) => (
                    <OnChange name="gitpassword">
                      {async (value, previous) => {
                        await this.setGitRepositoryBranchesState(testSetId, values)
                        this.setState({ gitRepositoryDirStructureDirty: true, directoryStructure: undefined })
                      }}
                    </OnChange>
                  )} />
                  <GridItem xs={12}>
                    <Text muted>Only specify Git credentials for write access to this repository.</Text>
                  </GridItem>
                  {this.state.remoteBranches && this.state.remoteBranches.length === 0 ?
                    (<GridItem xs={12} sm={6}>
                      <Field
                        name="gitbranch"
                        component={renderTextField}
                        label="Git Branch"
                        validate={required}
                        disabled={!this.hasWritePermission()}
                        data-unique="txtTestSetRepositoryGitBranch"
                      />
                    </GridItem>) :
                    (<GridItem xs={12} sm={6}>
                        <Field
                          name="gitbranch"
                          component={renderSelect}
                          label="Git Branch"
                          validate={required}
                          disabled={!this.hasWritePermission()}
                          data-unique="selTestSetRepositoryGitBranch"
                          items={gitBranches}
                        />
                      </GridItem>)
                  }
                  <FormSpy subscription={{ form: true }} render={({ form:  { change }}) => (
                    <OnChange name="gitbranch">
                      {async (value, previous) => {
                        this.setState({ directoryStructure: undefined })
                      }}
                    </OnChange>
                  )} />
                  <GridItem xs={12} sm={6}/>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="gitdir"
                      component={renderTextField}
                      label="Relative Path in Repository"
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetRepositoryGitDir"
                      endAdornment={<>
                        <Button justIcon dense
                          disabled={!this.state.directoryStructure || this.state.directoryStructure.length === 0}
                          data-unique="btnTestSetRepositoryOpenFolderSelectionDialog"
                          onClick={() => this.setState({ showRepositoryDirectoryDialog: true  })}>
                          <ShowIcon icon="folder" />
                        </Button>
                        <Tooltip title="Fetch directory structure from git repository. (It may take some time.)">
                          <Button justIcon dense
                            disabled={this.state.gitRepositoryDirStructureFetching || !values.giturl || !values.gitbranch}
                            data-unique="btnTestSetRepositoryRepositoryInfo"
                            onClick={() => this.setGitRepositoryDirStructureState(testSetId, values, setAlertSuccessMessage, setAlertErrorMessage)}>
                            {this.state.gitRepositoryDirStructureFetching && <LoadingIndicator alt />}
                            {!this.state.gitRepositoryDirStructureFetching && <ShowIcon icon="sync" />}
                          </Button>
                        </Tooltip>
                      </>}
                    />
                    <DirectorySelectionDialogWithoutFileSystemQuery
                      open={this.state.showRepositoryDirectoryDialog}
                      onCancel={() => this.setState({ showRepositoryDirectoryDialog: false })}
                      onOk={(selectedPath) => {
                        change('gitdir', selectedPath.join('/'))
                        this.setState({ showRepositoryDirectoryDialog: false })
                      }}
                      directoryTree={this.state.directoryStructure}
                      rootDirName={values.name ? `${values.name}-workingdir` : 'workingdir'}
                      title="Select Folder"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      optional
                      name="globFilter"
                      component={renderTextField}
                      label="Filename Filter (&quot;glob&quot;)"
                      helperText="Botium uses &quot;glob&quot; style filename filters to restrict the set of read files, for example &quot;**/*.en.txt&quot;"
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetRepositoryGlobFilter"
                    />
                  </GridItem>
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtons={<>
                        {testsetrepository.id && this.hasWritePermission() && (
                          <Mutation
                            mutation={DELETE_TESTSETREPOSITORY}
                            onCompleted={data => {
                              this.setState({ deleting: false })
                              this.props.history.push(`/testsets/view/${testSetId}/settings/remote`)
                              setAlertSuccessMessage('Git Repository unregistered')
                            }}
                            onError={error => {
                              this.setState({ deleting: false })
                              setAlertErrorMessage(
                                `Git Repository unregistration failed`,
                                error,
                              )
                            }}
                            refetchQueries={[
                              ...RefetchTestSetQueries(testSetId, license)
                            ]}
                          >
                            {(deleteTestSetRepository, { loading, error }) => (
                              <Button
                                secondary
                                danger
                                onClick={() => {
                                  this.setState({ deleting: true })
                                  deleteTestSetRepository({
                                    variables: { id: testsetrepository.id },
                                  })
                                }}
                                disabled={deleting}
                                data-unique="btnTestSetRepositoryUnregister"
                              >
                                {deleting && <><LoadingIndicator alt /> Deleting</>}
                                {!deleting && <><ShowIcon icon="trash" /> Delete</>}
                              </Button>
                            )}
                          </Mutation>
                        )}
                      </>}
                      rightButtons={this.hasWritePermission() &&
                        <Button
                          type="submit"
                          disabled={invalid || saving || submitting}
                          data-unique="btnTestSetRepositorySave"
                        >
                          {saving && <><LoadingIndicator alt /> Saving ...</>}
                          {!saving && <><ShowIcon icon="save" /> Save</>}
                        </Button>
                      }
                    />
                  </GridItem>
                </GridContainer>
              </form>
            }}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { match, testSetRepoQueryResponse } = this.props

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {testSetRepoQueryResponse && testSetRepoQueryResponse.loading && <LoadingIndicator />}
          {testSetRepoQueryResponse && testSetRepoQueryResponse.error && <ErrorFormat err={testSetRepoQueryResponse.error}/>}
          {testSetRepoQueryResponse && testSetRepoQueryResponse.testsetrepository && this.renderForm(
            testSetRepoQueryResponse.testsetrepository,
            testSetRepoQueryResponse.testsetrepository.testSet.id,
          )}
          {(!match.params || !match.params.id) &&
          this.renderForm({ gitbranch: 'master' }, match.params.testSetId)}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ token: state.token.token, user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  withApollo,
  graphql(TESTSETREPOSITORY_QUERY, {
    props: ({data}) => ({
      testSetRepoQueryResponse: data,
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.id,
        }
      }
    },
    skip: (props) => !props.match.params.id
  }),
  graphql(GITREPOSITORYBRANCHES_QUERY, {
    props: ({data}) => ({
      gitRepositoryBranchesResponse: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSetId: props.testSetRepoQueryResponse.testsetrepository.testSet.id,
          id: props.testSetRepoQueryResponse.testsetrepository.id,
          giturl: props.testSetRepoQueryResponse.testsetrepository.giturl,
          gituser:  props.testSetRepoQueryResponse.testsetrepository.gituser,
          gitpassword:  props.testSetRepoQueryResponse.testsetrepository.gitpassword
        }
      }
    },
    skip: (props) => !props.testSetRepoQueryResponse || !props.testSetRepoQueryResponse.testsetrepository

  })
)(TestSetRepository)
