import React from 'react'
import ShowIcon from 'components/Icon/ShowIcon'
import {faGitSquare} from '@fortawesome/free-brands-svg-icons'

export const MATCHING_MODES = [
  { key: 'MATCHING_MODE_WILDCARD', label: 'Wildcard matching with * (case sensitive)' },
  { key: 'MATCHING_MODE_WILDCARDIGNORECASE', label: 'Wildcard matching with * (case insensitive)' },
  { key: 'MATCHING_MODE_WILDCARDEXACT', label: 'Exact Wildcard matching with * (case sensitive)' },
  { key: 'MATCHING_MODE_WILDCARDEXACTIGNORECASE', label: 'Exact Wildcard matching with * (case insensitive)' },
  { key: 'MATCHING_MODE_REGEXP', label: 'Use regular expressions (case sensitive)' },
  { key: 'MATCHING_MODE_REGEXPIGNORECASE', label: 'Use regular expressions (case insensitive)' },
  { key: 'MATCHING_MODE_INCLUDE', label: 'Substring matching (case sensitive)' },
  { key: 'MATCHING_MODE_INCLUDEIGNORECASE', label: 'Substring matching (case insensitive)' },
  { key: 'MATCHING_MODE_EQUALS', label: 'Exact string matching (case sensitive)' },
  { key: 'MATCHING_MODE_EQUALSIGNORECASE', label: 'Exact string matching (case insensitive)' },
  { key: 'MATCHING_MODE_WER', label: 'Word Error Rate' },
]

export const getShortenedArray = (a, maxLength) => {
  if (!a || a.length === 0) return []
  else if (a.length > maxLength) return [...a.slice(0, maxLength), '...']
  else return a
}

export const getCompiledItemLink = (testSetId, list, record, name) => {
  const st = {}
  if (record.sourceTag) {
    try {
      Object.assign(st, JSON.parse(record.sourceTag))
    } catch (err) {
    }
  }
  const result = {
    href: getCompiledItemHref(testSetId, list, record)
  }
  if (record.script && record.script.scriptType === 'SCRIPTING_TYPE_UTTERANCES') {
    result.value = name || 'Utterance'
  } else if (record.script && record.script.scriptType === 'SCRIPTING_TYPE_CONVO') {
    result.value = name || 'Convo'
  } else if (record.script) {
    result.value = name || 'Botium File'
  } else if (record.excel) {
    result.value = name || st.filename || 'Excel File'
  } else if (record.repository) {
    result.value = name || st.filename || 'Git Repository'
  } else if (record.folder) {
    result.value = name || st.filename || 'Shared Folder'
  } else if (record.downloadlink) {
    result.value = name || 'Download Link'
  } else {
    return name || record.name
  }
  return result
}

export const getCompiledItemIconLink = (testSetId, list, record) => {
  const result = {
    href: getCompiledItemHref(testSetId, list, record)
  }
  if (record.script) {
    result.value = <ShowIcon icon="list-ul"/>
  } else if (record.excel) {
    result.value = <ShowIcon icon="file-excel"/>
  } else if (record.repository) {
    result.value = <ShowIcon icon={faGitSquare}/>
  } else if (record.folder) {
    result.value = <ShowIcon icon="share-alt-square"/>
  } else if (record.downloadlink) {
    result.value = <ShowIcon icon="link"/>
  } else {
    return record.name
  }
  return result
}

export const getCompiledItemHref = (testSetId, list, record) => {
  if (record.testSetScriptId || record.script) {
    if (list === 'convo') {
      if (!record.script || record.script.scriptType === 'SCRIPTING_TYPE_CONVO' || record.script.scriptType === 'SCRIPTING_TYPE_PCONVO') {
        return `/testsets/view/${record.testSetId || testSetId}/testcases/viewconvo/${record.testSetScriptId || record.script.id}/${encodeURIComponent(record.name)}`
      } else {
        return `/testsets/view/${record.testSetId || testSetId}/testcases/viewscript/${record.testSetScriptId || record.script.id}`
      }
    } else if (list === 'utterance') {
      if (!record.script || record.script.scriptType === 'SCRIPTING_TYPE_UTTERANCES') {
        return `/testsets/view/${record.testSetId || testSetId}/testcases/viewutterance/${record.testSetScriptId || record.script.id}/${encodeURIComponent(record.name)}`
      } else {
        return `/testsets/view/${record.testSetId || testSetId}/testcases/viewscript/${record.testSetScriptId || record.script.id}`
      }
    } else if (list === 'scriptingMemory') {
      return `/testsets/view/${testSetId}/testcases/viewscript/${record.testSetScriptId || record.script.id}`
    }
  } else if (record.testSetExcelId || record.excel) {
    return `/testsets/view/${record.testSetId || testSetId}/testcases/viewexcel/${record.testSetExcelId || record.excel.id}`
  } else if (record.testSetRepositoryId || record.repository) {
    return `/testsets/view/${record.testSetId || testSetId}/settings/remote/viewrepository/${record.testSetRepositoryId || record.repository.id}`
  } else if (record.testSetFolderId || record.folder) {
    return `/testsets/view/${record.testSetId || testSetId}/settings/remote/viewfolder/${record.testSetFolderId || record.folder.id}`
  } else if (record.testSetDownloadLinkId || record.downloadlink) {
    return `/testsets/view/${record.testSetId || testSetId}/settings/remote/viewdownloadlink/${record.testSetDownloadLinkId || record.downloadlink.id}`
  }
}

export const splitDateInfo = (fromNow) => {
	if (fromNow === 'a few seconds') {
  	return {
    	prefix: '<1',
      suffix: 'min.'
    }
  }
  const match = fromNow.match(/(a|\d+)(.*)/i)
  if(match) {
    return {
      prefix: match[1] === 'a' ? '1' : match[1],
      suffix: match[2].replace('minutes', 'min.').replace('seconds', 'sec.')
    }
  }
  return {
  	prefix: '',
    suffix: fromNow
  }
}


