import React from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import {compose, graphql, withApollo} from 'react-apollo'
import {FormSpy} from 'react-final-form'
import Button from 'components/Button/Button'
import DropdownButton from 'components/Button/DropdownButton'
import ShowIcon from 'components/Icon/ShowIcon'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import Transcript from 'components/Convo/Transcript.jsx'
import ImageTiles from 'components/Convo/ImageTiles'
import { openTextInNewTab } from 'helper/browserHelper'

import { hasPermission } from 'botium-box-shared/security/permissions'
import { isLicenseDeviceSetsSupported } from 'botium-box-shared/security/licenseSupport'

import { CHATBOTHELLO_QUERY } from './gql'
import { DEVICESETS_QUERY } from '../Settings/gql'

import { ConnectorPrepareCaps } from 'components/Capability/Helper'
import { getConnector } from 'actions/settings'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class SayHello extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showHelloRunning: false,
      showHelloDialog: false,
      showHelloConvo: null,
      showHelloLogs: null,
      showHelloAttachments: null,
      showHelloError: null
    }
  }

  async runHello(values, deviceId) {
    const { getConnector, client, chatbotId } = this.props

    const chatbotSettings = getConnector(values.connector) || { name: values.connector }
    const text = (chatbotSettings && chatbotSettings.features && chatbotSettings.features.helloText) || 'Hello'
    const variables = { deviceId, text, capabilities: [] }

    if (chatbotId) {
      variables.id = chatbotId
      ConnectorPrepareCaps(
        chatbotSettings,
        values,
        variables.capabilities
      )
    } else if (values.chatbotId && values.chatbotId !== 'new') {
      variables.id = values.chatbotId
    } else {
      ConnectorPrepareCaps(
        chatbotSettings,
        values,
        variables.capabilities
      )
    }

    this.setState({
      showHelloRunning: true,
      showHelloDialog: false
    })

    try {
      const { data } = await client.query({
        query: CHATBOTHELLO_QUERY,
        variables,
        fetchPolicy: 'network-only'
      })
      const parsedConvo = JSON.parse(data.chatbothello.convo)
      this.setState({
        showHelloRunning: false,
        showHelloDialog: true,
        showHelloConvo: parsedConvo,
        showHelloLogs: data.chatbothello.logs,
        showHelloAttachments: data.chatbothello.attachments,
        showHelloError: data.chatbothello.err || (parsedConvo.length === 1 && parsedConvo[0].err)
      })
    } catch (err) {
      this.setState({
        showHelloRunning: false,
        showHelloDialog: true,
        showHelloConvo: null,
        showHelloLogs: null,
        showHelloAttachments: null,
        showHelloError: err.message
      })
    }
  }

  render() {
    const { license, user, chatbotId, allowHtmlDisplay, avatar, buttonProps, getConnector, deviceSetsData } = this.props
    const { showHelloRunning, showHelloConvo, showHelloAttachments, showHelloError, showHelloLogs } = this.state

    const { disabled, ...otherButtonProps } = buttonProps || {}

    const availableDevices = (containermode) => {
      const connector = getConnector(containermode)

      if (!deviceSetsData || !deviceSetsData.devicesets || deviceSetsData.devicesets.length === 0) return []
      return deviceSetsData.devicesets.reduce((agg, ds) => {
        if (connector && connector.features && connector.features.deviceProviderTypes && connector.features.deviceProviderTypes.length > 0) {
          if (connector.features.deviceProviderTypes.indexOf(ds.provider.type) < 0) return agg
        }
        return [
          ...agg,
          ...ds.devices.map(d => ({
            id: d.id,
            name: `${ds.name} / ${d.name}`
          }))
        ]
      }, [])
    }

    const hasDeviceSet = (containermode) => {
      const connector = getConnector(containermode)
      return !!(connector && connector.features && connector.features.deviceSetCapability)
    }

    const endAttachments = (showHelloAttachments && showHelloAttachments.filter(a => _.isNil(a.stepIndex))) || []

    if (hasPermission(user, 'CHATBOTS_LIVECHAT')) {
      return <>
        <ConfirmationDialog
          cancelText="Close"
          open={this.state.showHelloDialog}
          onCancel={() => this.setState({showHelloDialog: false})}
          title="Say Hello (Check Connectivity)"
          extraButton={license.detailedReporting && showHelloLogs && showHelloLogs.length > 0 &&
            <Button secondary data-unique="btnShowHelloLogs" onClick={() => {
              openTextInNewTab(showHelloLogs.filter(l => l).map(l => l.trim()).join('\n'))
            }}>
              Show Logs
            </Button>
          }
        >
          {(showHelloConvo || showHelloError) && <Transcript err={showHelloError} steps={showHelloConvo || []} attachments={showHelloAttachments} chatbotId={chatbotId} allowHtmlDisplay={allowHtmlDisplay} avatar={avatar}/>}
          {endAttachments &&  <ImageTiles
            images={endAttachments.map((a, index) => ({
              base64: a.base64,
              id: index,
              title: a.name,
              mimeType: a.mimeType
            }))}
            columns={{xs: 12, sm: 3}}
          />}
        </ConfirmationDialog>
        <FormSpy subscription={{ values: true }} render={({ values }) => {
          if (hasDeviceSet(values.connector)) {
            const devices = availableDevices(values.connector)

            return <DropdownButton secondary
              items={availableDevices(values.connector).map(device => ({
                id: device.id,
                name: device.name,
                onClick: () => this.runHello(values, device.id)
              }))}
              showFilter
              disabled={disabled || showHelloRunning || devices.length === 0}
              {...otherButtonProps}
            >
              {showHelloRunning && <LoadingIndicator alt />}
              <ShowIcon icon="paper-plane" />
              {devices.length === 0 ? '(No Device Available)' : 'Say Hello  (Check Connectivity)'}
            </DropdownButton>
          } else {
            return <Button secondary onClick={() => { this.runHello(values)}} disabled={disabled || showHelloRunning} {...otherButtonProps}>
              {showHelloRunning && <LoadingIndicator alt />}
              {!showHelloRunning && <ShowIcon icon="paper-plane" />}
              Say Hello  (Check Connectivity)
            </Button>
          }
        }}/>
      </>
    } else {
      return (
        <Button secondary {...otherButtonProps} disabled>
          <ShowIcon icon="paper-plane" />
          Say Hello (Check Connectivity)
        </Button>
      )
    }
  }
}

export default compose(
  connect(
    state => ({ user: state.token.user, settings: state.settings, license: state.settings.license }),
    { getConnector }
  ),
  graphql(DEVICESETS_QUERY, {
    skip: (props) => !isLicenseDeviceSetsSupported(props.license),
    props: ({ data }) => ({
      deviceSetsData: data,
    })
  }),
  withApollo
)(SayHello)
