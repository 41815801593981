import { gql } from 'apollo-boost'
import { deleteListQueryFromCache } from 'helper/cacheHelper'
import { FULL_CONNECTORDESCRIPTOR_FRAGMENT, CONNECTORFEATURES_FRAGMENT, NAMESPACES_QUERY } from '../Settings/gql'

export const CHATBOTS_DROPDOWN_QUERY = gql`
  query ChatbotsQuery($where: ChatbotWhereInput, $connectorFilter: String, $connectorFeatureRequired: String, $connectorFeatureExcluded: String, $orderBy: ChatbotOrderByInput, $skip: Int, $first: Int) {
    chatbots(where: $where, connectorFilter: $connectorFilter, connectorFeatureRequired: $connectorFeatureRequired, connectorFeatureExcluded: $connectorFeatureExcluded, orderBy: $orderBy, skip: $skip, first: $first) {
      id
      name
      namespace
      avatar
      containermode
      allowHtmlDisplay
      connector {
        ...ConnectorFeatures
      }
    }
  }
  ${CONNECTORFEATURES_FRAGMENT}
`

export const CHATBOTS_COUNT_QUERY = gql`
  query ChatbotsCountQuery {
    chatbotsCount
  }
`

export const CHATBOTS_WITH_STATUS_PAGINATED_QUERY = gql`
  query ChatbotsQuery($where: ChatbotWhereInput, $connectorFilter: String, $connectorFeatureRequired: String, $connectorFeatureExcluded: String, $orderBy: ChatbotOrderByInput, $skip: Int, $first: Int) {
    chatbots(where: $where, connectorFilter: $connectorFilter, connectorFeatureRequired: $connectorFeatureRequired, connectorFeatureExcluded: $connectorFeatureExcluded, orderBy: $orderBy, skip: $skip, first: $first) {
      id
      name
      namespace
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      description
      tags
      avatar
      allowHtmlDisplay
      containermode
      connector {
        ...ConnectorFeatures
      }
      lastTestSession {
        id
        name
        namespace
        chartData
        status
        jobs {
          id
          createdAt
          jobId
          progressPercent
          totalCount
          failedCount
          successCount
          status
          err
          started
          finished
        }
      }
      lastTestSessionDate
    }
  }
  ${CONNECTORFEATURES_FRAGMENT}
`

export const CHATBOTS_COUNT_PAGINATED_QUERY = gql`
  query ChatbotsCountQuery($where: ChatbotWhereInput, $connectorFilter: String, $connectorFeatureRequired: String) {
    chatbotsCount(where: $where, connectorFilter: $connectorFilter, connectorFeatureRequired: $connectorFeatureRequired)
  }
`

const FULL_CHATBOT_FRAGMENT = gql`
  fragment FullChatbot on Chatbot {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    description
    tags
    avatar
    allowHtmlDisplay
    containermode
    capabilitySets {
      id
      name
      capabilities {
        id
        name
      }
    }
    connector {
      ...FullConnectorDescriptor
    }
    retryUserSaysNumRetries
    retryUserSaysFactor
    retryUserSaysMinTimeout
    retryUserSaysOnErrorRegexp
    retryConvoNumRetries
    retryConvoFactor
    retryConvoMinTimeout
    retryConvoOnErrorRegexp
    retryStartNumRetries
    retryStartFactor
    retryStartMinTimeout
    retryStartOnErrorRegexp
    rateLimitUserSaysPerSecond
    rateLimitUserSaysPerMinute
    rateLimitUserSaysPerHour
    rateLimitGroup
    waitForBotTimeout
    mediaDownload
    capabilities {
      id
      createdAt
      updatedAt
      name
      type
      stringValue
      intValue
      booleanValue
      jsonValue
    }
    sources {
      id
      createdAt
      updatedAt
      name
      type
      stringValue
      intValue
      booleanValue
      jsonValue
    }
    envs {
      id
      createdAt
      updatedAt
      name
      stringValue
    }
  }
  ${FULL_CONNECTORDESCRIPTOR_FRAGMENT}
`

const LIVECHAT_LOGS_FRAGMENT = gql`
  fragment LiveChatLogs on LiveChatLogsResult {
    conversationId
    logs
  }
`

export const CHATBOT_QUERY = gql`
  query ChatbotQuery($id: ID!) {
    chatbot(id: $id) {
      ...FullChatbot
    }
  }
  ${FULL_CHATBOT_FRAGMENT}
`

export const CHATBOTHELLO_QUERY = gql`
  query ChatbotHelloQuery($id: ID, $deviceId: ID, $capabilities: [CapabilityUpdateInput!], $text: String!) {
    chatbothello(id: $id, deviceId: $deviceId, capabilities: $capabilities, text: $text) {
      convo
      logs
      err
      attachments { name base64 mimeType }
    }
  }
`

export const LIVECHAT_LOGS_QUERY = gql`
  query LivechatLogQuery($conversationId: String!) {
    liveChatLogs(conversationId: $conversationId) {
      ...LiveChatLogs
    }
  }
  ${LIVECHAT_LOGS_FRAGMENT}
`

export const CREATE_CHATBOT = gql`
  mutation CreateChatbot($chatbot: ChatbotCreateInput!) {
    createChatbot(chatbot: $chatbot) {
      ...FullChatbot
    }
  }
  ${FULL_CHATBOT_FRAGMENT}
`

export const UPDATE_CHATBOT = gql`
  mutation UpdateChatbot($id: ID!, $chatbot: ChatbotUpdateInput!) {
    updateChatbot(id: $id, chatbot: $chatbot) {
      ...FullChatbot
    }
  }
  ${FULL_CHATBOT_FRAGMENT}
`

export const DELETE_CHATBOT = gql`
  mutation DeleteChatbot($id: ID!) {
    deleteChatbot(id: $id)
  }
`

export const RESET_CHATBOT_RATES = gql`
  mutation ResetChatbotRates($id: ID!) {
    resetChatbotRates(id: $id)
  }
`

export const CHATBOT_TESTPROJECTS_QUERY = gql`
  query ChatbotTestProjectsQuery($chatbotId: ID!, $skip: Int, $first: Int) {
    testprojects(where: { chatbot: { id: $chatbotId } }, skip: $skip, first: $first) {
      id
      name
      namespace
      tags
      chatbot {
        id
        name
        namespace
        avatar
        containermode
      }
      testSets {
        id
        name
        namespace
      }
      deviceSets {
        id
        name
      }
    }
  }
`

export const SPEECH_RUN_EFFECT = gql`
  mutation SpeechRunEffect($convData: SpeechEffectData!) {
    speechRunEffect(convData: $convData)
  }
`

export const DeleteChatbotsListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^chatbots/)
}

export const RefetchChatbotQueriesOnNewTestSession = (chatbotId) => [
]

export const RefetchChatbotQueries = (chatbotId) => [
  {
    query: NAMESPACES_QUERY
  },
  {
    query: CHATBOTS_DROPDOWN_QUERY,
    fetchPolicy: 'no-cache'
  },
  {
    query: CHATBOTS_COUNT_QUERY,
    fetchPolicy: 'no-cache'
  },
  ...(chatbotId ? [
    {
      query: CHATBOT_QUERY,
      variables: { id: chatbotId }
    }
  ] : [])
]
