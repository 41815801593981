import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import {Mutation, compose, graphql} from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  FormActionsToolbar,
  renderIntField, parseInteger, renderSlider, renderAutoSuggest, email, required
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import ShowIcon from 'components/Icon/ShowIcon'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import {
  CONSUMPTION_SETTINGS_QUERY,
  UPDATE_CONSUMPTION_SETTINGS
} from './gql'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ErrorFormat from '../../components/Info/ErrorFormat'

class ConsumptionSettings extends React.Component {
  render() {
    const { consumptionSettingsData, setAlertSuccessMessage, setAlertErrorMessage } = this.props
    if(!consumptionSettingsData || consumptionSettingsData.loading) {
      return (
        <p>Loading ...</p>
      )
    }
    if(consumptionSettingsData && consumptionSettingsData.error)  {
      return (<ErrorFormat err={consumptionSettingsData.error} />)
    }


    return (
      <Mutation
        mutation={UPDATE_CONSUMPTION_SETTINGS}
        refetchQueries={() => [
          {
            query: CONSUMPTION_SETTINGS_QUERY,
          }
        ]}
      >
        {(updateConsumptionSettings, { loading, error }) => (
          <Form
            onSubmit={async values => {
              try {
                await updateConsumptionSettings({
                  variables: {
                    consumptionSettings:{
                      consumptionLimit: values.consumptionLimit,
                      consumptionNotification: values.consumptionNotification,
                      notificationReceiversEmail: values.notificationReceiversEmail
                    },
                  }
                })
                setAlertSuccessMessage('Consumption Settings are updated')
              } catch(error) {
                setAlertErrorMessage('Consumption Settings are failed to update', error)
              }
            }}
            initialValues={
              {
                ...consumptionSettingsData.consumptionSettings
              }
            }
            render={({
              handleSubmit,
              form,
              submitting,
              invalid,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem lg={12}>
                      <Field
                        name="consumptionLimit"
                        component={renderIntField}
                        label="Consumption Warning Limit($)"
                        parse={parseInteger}
                        data-unique="txtConsumptionSettingsConsumptionLimit"
                        helperText="Set a limit to receive an email when your testing activity is approaching a specific threshold."
                      />
                  </GridItem>
                  <GridItem lg={12}>
                    <Field
                      name="consumptionNotification"
                      component={renderSlider}
                      label="Notification at % of Consumption Limit"
                      helperText={values.consumptionNotification ? `Email notification will be sent when ${values.consumptionNotification}% of consumption limit is exceeded` : 'Set a % value to get email notification'}
                      parse={parseInteger}
                      min={0}
                      max={100}
                      step={1}
                      form={form}
                      validate={required}
                      default-value={0}
                      data-unique="txtConsumptionSettingsConsumptionNotification"
                      showInputField
                    />
                  </GridItem>
                  <GridItem lg={12}>
                    <Field
                      name="notificationReceiversEmail"
                      component={renderAutoSuggest}
                      label="Notification Email Recipients"
                      helperText={`Enter a list of email addresses to be notified on consumption is exceeded${values.consumptionNotification ? ` the ${values.consumptionNotification}% of the limit` : ' the limit'}`}
                      validate={email}
                      data-unique="asConsumptionSettingsNotificationReceiverEmailSelection"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <FormActionsToolbar
                      rightButtons={<>
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnImportPrismaImportFromUrl"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}
                          Save Settings
                        </Button>
                      </>}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }
}

export default compose(
  withStyles(settingsStyle),
  connect(
    state => state,
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  // it would be more elegant to use some general purpose query, like FEATURES_QUERY
  graphql(CONSUMPTION_SETTINGS_QUERY, {
    props: ({ data }) => ({
      consumptionSettingsData: data,
    }),
  }),
)(ConsumptionSettings)
