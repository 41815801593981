import React from 'react'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  renderTextField,
  renderCodeArea,
  renderCheckbox,
  required,
  json,
  jsonpath,
  prettyPrintJson,
  CustomCodeArea
} from 'components/Form/Form'
import FileSelectorField from 'components/Form/FileSelectorField'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import ShowIcon from 'components/Icon/ShowIcon'

import { ExtractJsonCapabilityValue, ExtractStringCapabilityValue, ExtractBooleanCapabilityValue, usedByCapabilitySet, capSetDescription } from './Helper'
import Button from '../Button/Button'

const HEADERS_TEMPLATE = JSON.stringify({
  'X-Conversation-Id': '{{botium.conversationId}}'
}, null, 2)

const START_TEMPLATE = JSON.stringify({
  event: 'chat/session/setup'
}, null, 2)

const BODY_TEMPLATE = JSON.stringify({
  event: 'chat/session/send',
  text: '{{msg.messageText}}'
}, null, 2)

const REQHOOK = `
module.exports = ({ requestOptions, msg }) => {
  requestOptions.body.origMessageText = msg.messageText;
  requestOptions.body.sender = msg.sender;
}
`.trim()

const RESHOOK = `
module.exports = ({ botMsg }) => {
  if (!botMsg.sourceData.intent) {
    botMsg.nlp = {
      intent: {
        incomprehension: true,
        confidence: 1
      }
    }
  } else {
    botMsg.nlp = {
      intent: {
        name: botMsg.sourceData.intent,
        confidence: (botMsg.sourceData.score || 0) / 100
      }
    }
  }
}
`.trim()

export function websocketConnectorCaps2Form(caps) {
  return {
    websocket: {
      url: ExtractStringCapabilityValue(caps, 'WEBSOCKET_URL', ''),
      headersTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'WEBSOCKET_HEADERS_TEMPLATE', '')),
      startTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'WEBSOCKET_START_BODY_TEMPLATE', '')),
      bodyTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'WEBSOCKET_REQUEST_BODY_TEMPLATE', '')),
      textsJsonPath: ExtractStringCapabilityValue(caps, 'WEBSOCKET_RESPONSE_TEXTS_JSONPATH', ''),
      buttonJsonPath: ExtractStringCapabilityValue(caps, 'WEBSOCKET_RESPONSE_BUTTONS_JSONPATH', ''),
      mediaJsonPath: ExtractStringCapabilityValue(caps, 'WEBSOCKET_RESPONSE_MEDIA_JSONPATH', ''),
      requestHook: ExtractStringCapabilityValue(caps, 'WEBSOCKET_REQUEST_HOOK', ''),
      responseHook: ExtractStringCapabilityValue(caps, 'WEBSOCKET_RESPONSE_HOOK', ''),
      ignoreEmpty: ExtractBooleanCapabilityValue(caps, 'WEBSOCKET_RESPONSE_IGNORE_EMPTY', true)
    }
  }
}

const capNamesMap = {
  'websocket.url': 'WEBSOCKET_URL',
  'websocket.headersTemplate': 'WEBSOCKET_HEADERS_TEMPLATE',
  'websocket.startTemplate': 'WEBSOCKET_START_BODY_TEMPLATE',
  'websocket.bodyTemplate': 'WEBSOCKET_REQUEST_BODY_TEMPLATE',
  'websocket.textsJsonPath': 'WEBSOCKET_RESPONSE_TEXTS_JSONPATH',
  'websocket.buttonJsonPath': 'WEBSOCKET_RESPONSE_BUTTONS_JSONPATH',
  'websocket.mediaJsonPath': 'WEBSOCKET_RESPONSE_MEDIA_JSONPATH',
  'websocket.requestHook': 'WEBSOCKET_REQUEST_HOOK',
  'websocket.responseHook': 'WEBSOCKET_RESPONSE_HOOK',
  'websocket.ignoreEmpty': 'WEBSOCKET_RESPONSE_IGNORE_EMPTY'
}

export function websocketConnectorForm2caps(values) {
  const capabilities = [
    {
      name: 'WEBSOCKET_URL',
      type: 'STRING',
      stringValue: values.websocket.url,
    },
    {
      name: 'WEBSOCKET_HEADERS_TEMPLATE',
      type: 'JSON',
      jsonValue: values.websocket.headersTemplate
    },
    {
      name: 'WEBSOCKET_START_BODY_TEMPLATE',
      type: 'JSON',
      jsonValue: values.websocket.startTemplate
    },
    {
      name: 'WEBSOCKET_REQUEST_BODY_TEMPLATE',
      type: 'JSON',
      jsonValue: values.websocket.bodyTemplate
    },
    {
      name: 'WEBSOCKET_RESPONSE_TEXTS_JSONPATH',
      type: 'STRING',
      stringValue: values.websocket.textsJsonPath,
    },
    {
      name: 'WEBSOCKET_RESPONSE_IGNORE_EMPTY',
      type: 'BOOLEAN',
      booleanValue: !!values.websocket.ignoreEmpty,
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'websocket' },
  ]

  capabilities.push({
    name: 'WEBSOCKET_RESPONSE_MEDIA_JSONPATH',
    type: 'STRING',
    stringValue: values.websocket.mediaJsonPath || null
  })
  capabilities.push({
    name: 'WEBSOCKET_RESPONSE_BUTTONS_JSONPATH',
    type: 'STRING',
    stringValue: values.websocket.buttonJsonPath || null
  })
  capabilities.push({
    name: 'WEBSOCKET_REQUEST_HOOK',
    type: 'STRING',
    stringValue: values.websocket.requestHook || null
  })
  capabilities.push({
    name: 'WEBSOCKET_RESPONSE_HOOK',
    type: 'STRING',
    stringValue: values.websocket.responseHook || null
  })

  return capabilities
}

export class WebsocketConnectorEdit extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      wsEndpointConfigExpanded: false,
      httpReqBuilderExpanded: false,
      wsResEvalExpanded: false,
      sampleCodeOpen: false,
      sampleCode: '',
      sampleCodeVars: ''
    }
  }

  render() {
    const { disabled, advanced, capSetCapNames } = this.props
    const { wsEndpointConfigExpanded, httpReqBuilderExpanded, wsResEvalExpanded } = this.state
    const { sampleCode, sampleCodeOpen, sampleCodeVars } = this.state

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => (
      <GridContainer>
        {!advanced && <React.Fragment>
          <GridItem xs={12} sm={10}>
            <Field
              name="websocket.url"
              component={renderTextField}
              label="Websocket endpoint of your chatbot, starting with ws:// or wss://"
              validate={required}
              data-unique="txtWebsocketConnectorEditUrl"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.automation']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.automation'], 'Moustache template variables supported: msg')}
            />
          </GridItem>
          <GridItem xs={12} sm={2} />
          <GridItem xs={12} sm={10}>
            <Field
              className="CapabilitiesShort"
              name="websocket.bodyTemplate"
              component={renderCodeArea}
              options={{ mode: 'application/json' }}
              label="Body Template (JSON structure)"
              validate={json}
              codeFormat={prettyPrintJson}
              data-unique="codeWebsocketConnectorEditBodyTemplate"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.automation']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.automation'], 'Moustache template variables supported: msg. If empty, plain text is sent to the Websocket endpoint.')}
            />
            <Button data-unique="btnWebsocketConnectorEditBodyTemplate" link onClick={() => change('websocket.bodyTemplate', BODY_TEMPLATE)}>Insert sample body template</Button>
          </GridItem>
          <GridItem xs={12} sm={2} />
          <GridItem xs={12} sm={10}>
            <Field
              name="websocket.textsJsonPath"
              component={renderTextField}
              label="JSON-Path for bot response"
              validate={jsonpath}
              data-unique="txtWebsocketConnectorEditTextsJsonPath"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.textsJsonPath']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.textsJsonPath'], 'Should return string or array of strings. If empty, the response is treated as plain text.')}
            />
          </GridItem>
          <GridItem xs={12} sm={2} />
        </React.Fragment>}
        {advanced && <GridItem xs={12}>
          <ExpansionPanel expanded={wsEndpointConfigExpanded} onChange={() => this.setState({ wsEndpointConfigExpanded: !wsEndpointConfigExpanded })} data-unique="pnlWebsocketEndPointConfig">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Websocket Endpoint Configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12}>
                  <Field
                    name="websocket.url"
                    component={renderTextField}
                    label="Websocket endpoint of your chatbot, starting with ws:// or wss://"
                    validate={required}
                    data-unique="txtWebsocketConnectorEditUrl"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.url']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.url'], 'Moustache template variables supported: msg')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    className="CapabilitiesShort"
                    name="websocket.headersTemplate"
                    component={renderCodeArea}
                    options={{ mode: 'application/json' }}
                    label="Headers Template (JSON structure)"
                    validate={json}
                    codeFormat={prettyPrintJson}
                    data-unique="codeWebsocketConnectorEditHeadersTemplate"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.headersTemplate']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.headersTemplate'], 'Moustache template variables supported: botium.conversationId, context, msg.')}
                  />
                  <Button data-unique="btnWebsocketConnectorEditHeadersTemplate" link onClick={() => change('websocket.headersTemplate', HEADERS_TEMPLATE)}>Insert sample headers template</Button>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    className="CapabilitiesShort"
                    name="websocket.startTemplate"
                    component={renderCodeArea}
                    options={{ mode: 'application/json' }}
                    label="Start Message Template (JSON structure)"
                    validate={json}
                    codeFormat={prettyPrintJson}
                    data-unique="codeWebsocketConnectorEditStartTemplate"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.startTemplate']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.startTemplate'], 'Moustache template variables supported: botium.conversationId, context, msg.')}
                  />
                  <Button data-unique="btnWebsocketConnectorEditStartTemplate" link onClick={() => change('websocket.startTemplate', START_TEMPLATE)}>Insert sample start template</Button>
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={httpReqBuilderExpanded} onChange={() => this.setState({ httpReqBuilderExpanded: !httpReqBuilderExpanded })} data-unique="pnlWebsocketReqBuilder">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              HTTP(S) request builder
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={6}>
                  <Field
                    className="CapabilitiesShort"
                    name="websocket.bodyTemplate"
                    component={renderCodeArea}
                    options={{ mode: 'application/json' }}
                    label="Body Template (JSON structure)"
                    validate={json}
                    codeFormat={prettyPrintJson}
                    data-unique="codeWebsocketConnectorEditBodyTemplate"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.bodyTemplate']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.bodyTemplate'], 'Moustache template variables supported: msg. If empty, plain text is sent to the Websocket endpoint.')}
                  />
                  <Button data-unique="btnWebsocketConnectorEditBodyTemplate" link onClick={() => change('websocket.bodyTemplate', BODY_TEMPLATE)}>Insert sample body template</Button>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <FileSelectorField
                    name="websocket.requestHook"
                    change={change}
                    label="Request hook (Javascript File)"
                    data-unique="codeWebsocketConnectorEditRequestHook"
                    endAdornment={<>
                      <Tooltip title="Show Sample Code">
                        <Button justIcon dense data-unique="codeWebsocketConnectorEditRequestHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: REQHOOK, sampleCodeVars: 'Variables available: requestOptions, msg' })}>
                          <ShowIcon icon="file-alt" />
                        </Button>
                      </Tooltip>
                    </>} 
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.requestHook']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.requestHook'], '')}
                    initialPath="resources/scripts"
                    extensionFilter={['.js']}
                    restrictPath
                  />                  
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={wsResEvalExpanded} onChange={() => this.setState({ wsResEvalExpanded: !wsResEvalExpanded })} data-unique="pnlWebsocketResEval">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Websocket response evaluation
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="websocket.textsJsonPath"
                    component={renderTextField}
                    label="JSON-Path for bot response"
                    validate={jsonpath}
                    data-unique="txtWebsocketConnectorEditTextsJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.textsJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.textsJsonPath'], 'Should return string or array of strings. If empty, the response is treated as plain text.')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="websocket.mediaJsonPath"
                    component={renderTextField}
                    label="JSON-Path for media"
                    validate={jsonpath}
                    data-unique="txtWebsocketConnectorEditMediaJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.textsJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.textsJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="websocket.buttonJsonPath"
                    component={renderTextField}
                    label="JSON-Path for Quick-Reply Recognition"
                    validate={jsonpath}
                    data-unique="txtWebsocketConnectorEditButtonJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.buttonJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.buttonJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="websocket.ignoreEmpty"
                    component={renderCheckbox}
                    label="Ignore empty responses and events"
                    type="checkbox"
                    data-unique="chkWebsocketConnectorEditIgnoreEmpty"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.ignoreEmpty']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.ignoreEmpty'], 'Activate to ignore empty responses and events (no text, button or media attachment)')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <FileSelectorField
                    name="websocket.responseHook"
                    change={change}
                    label="Response hook (Javascript File)"
                    data-unique="codeWebsocketConnectorEditResponseHook"
                    endAdornment={<>
                      <Tooltip title="Show Sample Code">
                        <Button justIcon dense data-unique="codeWebsocketConnectorEditResponseHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: RESHOOK, sampleCodeVars: 'Variables available: botMsg, msg' })}>
                          <ShowIcon icon="file-alt" />
                        </Button>
                      </Tooltip>
                    </>} 
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['websocket.responseHook']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['websocket.responseHook'], '')}
                    initialPath="resources/scripts"
                    extensionFilter={['.js']}
                    restrictPath
                  />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ConfirmationDialog
            cancelText="Close"
            open={!!sampleCodeOpen}
            onCancel={() => this.setState({ sampleCodeOpen: false })}
            title="Sample Javascript Code">
            <GridContainer>
              <GridItem xs={12}>
                {sampleCodeVars}
              </GridItem>
              <GridItem xs={12}>
                <CustomCodeArea
                  label="Sample Javascript Code"
                  options={{ mode: 'application/javascript' }}
                  input={{
                    value: sampleCode,
                    disabled: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </ConfirmationDialog>
        </GridItem>}
      </GridContainer>
    )} />)
  }
}
