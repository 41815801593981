import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import expansionStyle from 'assets/jss/material-dashboard-react/components/expansionStyle'
import ExpansionPanelSummaryCore from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ShowIcon from 'components/Icon/ShowIcon'
import Text from 'components/Typography/Text'

function ExpansionPanelSummary({ ...props }) {
  const { classes, children, indicator, ...rest } = props

  return <ExpansionPanelSummaryCore expandIcon={<ExpandMoreIcon  />} classes={{
    root: classes.ExpansionPanelSummaryroot,
    content: classes.ExpansionPanelSummarycontent,
    expandIcon: classes.ExpansionPanelSummaryexpandIcon,
    expanded: classes.ExpansionPanelSummaryexpandexpanded,
}} {...rest}>
  {indicator === 'error' && <div style={props.expanded ? { position: 'absolute', top: '-30px', left: '-32px' } : { position: 'absolute', top: '-25px', left: '-32px' }}><Text danger><ShowIcon icon="circle-exclamation" /></Text></div>}
  {children}
  </ExpansionPanelSummaryCore>
}

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary'

export default withStyles(expansionStyle)(ExpansionPanelSummary)
