import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import NumberFormat from 'react-number-format'

const barArrayStyle = theme => ({
    bar: {
      borderRadius: 20,
      height: 14,
      
    },
    barSimplemode: {
      borderRadius: 20,
      height: 6,
      
    },
    barText: {
      position: 'relative',
      top: '-2px',
      left: 5,
      minWidth: 30,
    },
    barName: {
      textAlign: 'right',
      fontWeight: 'normal'
    },
    barBackground: {
      borderRadius: 20,
      height: 14,
      margin: 2,
      
    },
    barBackgroundSimplemode: {
      borderRadius: 20,
      height: 6,
      margin: 2,
      
    },
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
})

function BarArray({ ...props }) {
  const { bars, width, classes, withBackground, theme, simpleMode } = props

  return <div className={classes.root} style={{ width: width ? width : 100 }}>
         
      {bars.map((b, index) => {       
        let valueBar
        if (simpleMode) valueBar = <div key={index} className={classes.barSimplemode} style={{ backgroundColor: b.color, width: `${b.value}%` }}></div>
        else valueBar = <div key={index} className={classes.bar} style={{ backgroundColor: b.color, width: `${b.value}%` }}>
            <div className={classes.barText} style={{ color: b.barTextcolor}}>
              <NumberFormat value={b.value || 0} displayType={'text'} decimalScale={1}/>{b.valueType}
            </div>
          </div>
        
        if (simpleMode) return  <div key={index} className={classes.barBackgroundSimplemode} style={{ backgroundColor: withBackground ? (_.isString(withBackground) ? withBackground : theme.colors.common.backgroundTabs) : 'transparent', width: '100%' }}>{valueBar}</div>
        else return  <GridContainer key={index}>
            <GridItem xs={5}>
              <div className={classes.barName}>{b.barName}</div>
            </GridItem>
            <GridItem xs={7}>
              <div key={index} className={classes.barBackground} style={{ backgroundColor: withBackground ? (_.isString(withBackground) ? withBackground : theme.colors.common.backgroundTabs) : 'transparent', width: '100%' }}>{valueBar}</div>
            </GridItem>
          </GridContainer>
      })}
  </div>
}

BarArray.propTypes = {
  bars: PropTypes.any,
  width: PropTypes.any,
  withBackground: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  simpleMode: PropTypes.bool
}

export default withStyles(barArrayStyle, { withTheme: true })(BarArray)
