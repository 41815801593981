import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import {
  successColor,
  dangerColor,
  grayColor
} from 'assets/jss/material-dashboard-react.jsx'

import TestSessionProgress from './TestSessionProgress.jsx'
import DonutChart from 'components/Stats/Charts/DonutChart'

class TestSessionProgressPie extends React.Component {

  render() {
    const { testSession, variables } = this.props

    return (
      <TestSessionProgress {...(this.props)} key={`pie_${testSession && testSession.id}_${variables && JSON.stringify(variables)}`}>
        {({ testSessionProgress }) => {
          if (testSessionProgress && testSessionProgress.chartData) {
            const chartData = JSON.parse(testSessionProgress.chartData)
            let expectedTotalTestCaseCount = 0
            if(chartData) {
              expectedTotalTestCaseCount = chartData.expectedTotalTestCaseCount || chartData.successCount + chartData.failedCount
            }

            const avgProgress = Math.max(
              testSessionProgress.jobs.reduce(
                (sum, j) => sum + (j.progressPercent || 0),
                0,
              ) / testSessionProgress.jobs.length,
              0
            )

            const series = []
            if (expectedTotalTestCaseCount > 0 && avgProgress > 0) {
              const totalCountPercent = expectedTotalTestCaseCount * 100 / avgProgress
              const successPercent = chartData.successCount * 100 / totalCountPercent
              const failedPercent = chartData.failedCount * 100 / totalCountPercent
              series.push(successPercent)
              series.push(failedPercent)
              series.push(100 - (successPercent + failedPercent))
            } else {
              series.push(0)
              series.push(0)
              series.push(100)
            }

            return (
              <DonutChart
                data={{
                  fill: [successColor, dangerColor, grayColor],
                  series
                }}
                options={{
                  total: 100,
                  labelInterpolationFnc: (value, index) =>
                    index !== 2 && value !== undefined && value > 0 && value.toFixed(1) + '%',
                }}
              />
            )
          } else {
            const series = []
            series.push(0)
            series.push(0)
            series.push(100)

            return (
              <DonutChart
                data={{
                  fill: [successColor, dangerColor, grayColor],
                  series
                }}
                options={{
                  labelInterpolationFnc: (value, index) =>
                    index !== 2 && value !== undefined && value > 0 && value.toFixed(1) + '%',
                }}
              />
            )
          }
        }}
      </TestSessionProgress>
    )
  }
}

export default withStyles(testsessionsStyle)(TestSessionProgressPie)
