import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import LinkButton from 'components/Button/LinkButton'

function ShortenedText({ maxlength, children }) {
  const [more, setMore] = useState(false)

  if (children && _.isString(children) && children.length > maxlength) {
    if (more) {
      return <React.Fragment>
        {children} <LinkButton onClick={(e) => { e.stopPropagation(); setMore(false)}}>&lt;&lt;</LinkButton>
      </React.Fragment>
    } else {
      return <React.Fragment>
        {_.truncate(children, { length: maxlength })} <LinkButton onClick={(e) => { e.stopPropagation(); setMore(true)}}>&gt;&gt;</LinkButton>
      </React.Fragment>
    }
  }
  return children
}

ShortenedText.propTypes = {
  maxlength: PropTypes.number.isRequired
}

export default ShortenedText
