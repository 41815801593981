import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { compose, graphql } from 'react-apollo'
// core components
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable.jsx'
import DropdownButton from 'components/Button/DropdownButton'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import {
  START_TESTPROJECT,
  RefetchTestProjectQueriesOnNewTestSession,
  START_TESTPROJECTS,
  DeleteTestProjectsListsFromCache
} from './gql'
import { TESTPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY, TESTPROJECTS_COUNT_PAGINATED_QUERY } from './gql'

import { RefetchTestSessionQueries, DeleteTestSessionListsFromCache } from '../TestSessions/gql'

import { hasPermission, canReadNamespace, canWriteNamespace } from 'botium-box-shared/security/permissions'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import { getConnector } from 'actions/settings'
import ShowIcon from 'components/Icon/ShowIcon'
import { getNamespaceWhereClause } from '../helper'
import { isDarkmode } from 'components/Darkmode/helper'
import {NewResultStats} from '../TestSessions/helper'
import DateFormat from 'components/Info/DateFormat'
import { TestSessionType } from 'views/TestSessions/helper.jsx'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import {projectTypeFlagsToUrl, PROJECT_TYPE_URLS} from './helper'
import { NewResultIcon }  from 'views/TestSessions/helper.jsx'
import Text from 'components/Typography/Text'

class TestProjectsEmbeddedTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedItems: [],
      newTestSessionCount: 0,
    }
  }

  getProjectType() {
    const raw = this.props.location.pathname.split('/').filter(p => p)[0]
    if (PROJECT_TYPE_URLS.includes(raw)) {
      return raw
    }
  }

  render () {
    const { classes, variables, name, user, mutateStartTestProject, mutateStartTestProjects, history, namespace, projectFlags, ...rest } = this.props

    const _gqlVariables = (filterString) => {
      filterString = filterString && filterString.replace('_', '\\_')
      const where = { AND: [ {...variables } ] }
      if (filterString) {
        where.AND.push({ OR: [ {name_contains: filterString}, { chatbot: {name_contains: filterString} }, { testSets_some: {name_contains: filterString} } ] })
      }
      if (namespace?.selected?.name) {
        where.AND.push(getNamespaceWhereClause(namespace?.selected?.name))
      }

      return  { where }
    }

    const _link = (ts, tp) => {
      if (ts.type === 'nlp') {
        return `/nlp/projects/view/${tp.id}/results/${ts.id}`
      } else if (ts.type === 'security') {
        return `/security/projects/view/${tp.id}/results/${ts.id}`
      } else if (ts.type === 'gdpr') {
        return `/gdpr/projects/view/${tp.id}/results/${ts.id}`
      } else if (ts.type === 'factchecker') {
        return `/factcheck/projects/view/${tp.factCheckerSession.project.id}/results/${ts.id}`
      } else if (ts.type === 'misusechecker') {
        return `/misusecheck/projects/view/${tp.misuseCheckerSession.project.id}/results/${ts.id}`
      } else if (ts.type === 'performance' && ts.performanceTestSession) {
        return `/performance/projects/view/${tp.id}/results/${ts.performanceTestSession.id}`
      }  else if (ts.type === 'e2e') {
        return `/e2e/projects/view/${tp.id}/results/${ts.id}/dashboard`
      } else if (ts.type === 'monitoring') {
        return `/scheduledtests/projects/view/${tp.id}/results/${ts.id}/dashboard`
      }
      return `/regression/projects/view/${tp.id}/results/${ts.id}/dashboard`
    }

    const _assignSessionTypes = ( testprojectswithlasttestsession ) => {
      testprojectswithlasttestsession && testprojectswithlasttestsession.forEach(tp => {
        if (tp.lastTestSession) {
          tp.lastTestSession.type =
            tp.lastTestSession.nlpAnalytics ? 'nlp' :
              tp.lastTestSession.securityCheck ? 'security' :
                tp.lastTestSession.performanceTesting ? 'performance' :
                  tp.lastTestSession.gdprTesting ? 'gdpr' : null
          if (!tp.lastTestSession.type) {
            if (tp.regressionTesting) {
              tp.lastTestSession.type = 'regression'
            } else if (tp.e2eTesting) {
              tp.lastTestSession.type = 'e2e'
            }
          }
          if (!tp.lastTestSession.type) {
            console.error('Cannot determine session type', tp)
            tp.lastTestSession.type = 'regression'
          }
        }
      })

      return testprojectswithlasttestsession
    }

    const _showBulkActions = () => {
      return projectFlags && !!(projectFlags.nlpAnalytics || projectFlags.securityCheck || projectFlags.e2eTesting || projectFlags.regressionTesting || projectFlags.monitoring)
    }

    return <ServerSidePagingTable
      className={classes.projectTable}
      key={`${name}_${namespace?.selected?.name}_${isDarkmode() ? 'dark' : 'light'}_${this.state.newTestSessionCount}`}
      name={`${name}_${this.props.location.pathname.split('/').filter(p => p)[0]}`}
      gqlQuery={{
        query: TESTPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY,
        notifyOnNetworkStatusChange: true
      }}
      addNoPaddingTableActionsToolbar={this.props.addNoPaddingTableActionsToolbar}
      rowClassName={(row) => 'projectTable'}
      gqlCountQuery={{
        query: TESTPROJECTS_COUNT_PAGINATED_QUERY,
        countValue: data => data.testprojectsCount
      }}
      gqlVariables={(filterString) => _gqlVariables(filterString)}
      tableHeaderColor="primary"
      noDataAllComponent="Get started creating test will be displayed on this page."
      tableHead={[
        { name: 'Project Name', width: 'small' },
        { name: 'Actions', right: true},
        { name: 'Latest Test' },
        ' ',
        ' ',
      ]}
      mapSelectIds={_showBulkActions() ? (data => data && data.testprojectswithlasttestsession &&
        data.testprojectswithlasttestsession.map(t => t.id)) : undefined}
      onSelectionChange={selectedItems => this.setState({ selectedItems })}
      customActionsRight={_showBulkActions() ?
        <DropdownButton
          className={classes.dropdownButtonPosition}
          customSelectType
          disabled={this.state.selectedItems.length === 0}
          data-unique="btnTestProjectsBulkAction"
          items={[
            {
              id: 'start',
              icon: 'play',
              name: 'Start Test Session',
              dataUnique: 'btnTestProjectsBulkActionStart',
              onClick: async () => {
                await mutateStartTestProjects({
                  variables: {testProjectIds: this.state.selectedItems, debug: false}
                })
                this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
              }
            },
            {
              id: 'start_debug',
              icon: 'bug',
              name: 'Start Test Session (Extended Logging)',
              dataUnique: 'btnTestProjectsBulkActionStartDebug',
              onClick: async () => {
                await mutateStartTestProjects({
                  variables: {testProjectIds: this.state.selectedItems, debug: true}
                })
                this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
              }
            },
          ]}
        >
          <ShowIcon icon="redo" /> Bulk Action
        </DropdownButton>
        : undefined}

      tableData={data =>
        data.testprojectswithlasttestsession &&
        _assignSessionTypes(data.testprojectswithlasttestsession).map((t, tIndex) => {
          const projectUrl = () => {
            // maybe use lastTestSession.type here?

            // it should be same as projectFlags checked in the next if. But to be sure...
            if (this.getProjectType()) {
              return `/${this.getProjectType()}`
            }
            // projectFlags: if we come from a specific project type page, we need to keep the project type
            // lastTestSession: if it is not forced from outside, use the type from last test session
            // t: if somehow we dont have last test session (or it is e2e, or regression)
            // default: /regression (should not happen, but just in case)
            return projectTypeFlagsToUrl(projectFlags || {}) ||
              projectTypeFlagsToUrl(t.lastTestSession || {}) ||
              projectTypeFlagsToUrl(t) ||
              '/regression'
          }
          return [
            {
              value: <>
                <div className={classes.navlinkPosition}><NavLink className={classes.projectLink} to={`${projectUrl()}/projects/view/${t.id}/dashboard`}>{t.name}</NavLink></div>
                {t.namespace ? <NavLink to={`/namespaces/${t.namespace}`}><div className={classes.namespace}>{t.namespace}</div></NavLink> : <div className={classes.namespaceempty}></div>}
              </>
            },
            () => <DropdownButton
              dots
              aria-label="Actions"
              tooltipTitle="Actions"
              items={[
                {
                  id: 'open',
                  icon: 'infinity',
                  name: 'Open',
                  onClick: () => history.push(`${projectUrl()}/projects/view/${t.id}/dashboard`)
                },
                t.lastTestSession && !['e2e', 'regression', 'performance'].some(type => t.lastTestSession.type === type) && {
                  id: 'openbase',
                  icon: 'magnifying-glass',
                  name: 'Open Latest Base Test Session',
                  onClick: () => history.push(`/regression/projects/view/${t.id}/results/${t.lastTestSession.id}`)
                },
                canReadNamespace(user, user.namespacePermissions, t.namespace) && {
                  id: 'settings',
                  icon: 'cog',
                  name: 'Configuration',
                  onClick: () => history.push(`${projectUrl()}/projects/view/${t.id}/settings`)

                },
                ...((hasPermission(user, 'TESTSESSIONS_CREATE') && canWriteNamespace(user, user.namespacePermissions, t.namespace) && !t.performanceTesting && !t.gdprTesting) ? [
                  {
                    id: 'start',
                    icon: 'play',
                    name: 'Start Test Session',
                    onClick: async () => {
                      await mutateStartTestProject({
                        variables: {id: t.id, debug: false}
                      })
                      this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
                    }
                  },
                  {
                    id: 'start_debug',
                    icon: 'bug',
                    name: 'Start Test Session (Extended Logging)',
                    onClick: async () => {
                      await mutateStartTestProject({
                        variables: {id: t.id, debug: true}
                      })
                      this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
                    }
                  }
                ] : [])
              ].filter(t => t)}>
            </DropdownButton>,
            () => t.lastTestSession && <><div className={classes.resultListTestType}><TestSessionType ts={t.lastTestSession} /></div> <div className={classes.newResultIcon}><NewResultIcon ts={t.lastTestSession} /></div></>,
            () => t.lastTestSession && <><Text regular smLineHeight>Latest Test Run</Text><NavLink className={classes.projectLink} key={`${tIndex}`} to={_link(t.lastTestSession, t)} data-unique={`btnTestProjectMoveto_${tIndex}_${t.lastTestSession.type}`}><DateFormat>{t.lastTestSession.updatedAt}</DateFormat></NavLink></>,
            t.lastTestSession ? {
              value: <div><NewResultStats ts={t.lastTestSession} /></div>
            } : null,
          ]})}
      {...rest}
    />
  }
}

export default compose(
  withRouter,
  withStyles(testsessionsStyle),
  connect(
    state => ({
      user: state.token.user,
      license: state.settings.license,
      namespace: state.namespace
    }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage }
  ),
  graphql(START_TESTPROJECT, {
    props: ({ mutate }) => ({
      mutateStartTestProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('Test session started ...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestProjectQueriesOnNewTestSession(data.startTestProject.testProject.id),
        ...RefetchTestSessionQueries(data.startTestProject.id),
        ...(props.refetchQueriesForTestSession ? props.refetchQueriesForTestSession(data.startTestProject.id) : [])
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
  graphql(START_TESTPROJECTS, {
    props: ({ mutate }) => ({
      mutateStartTestProjects: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('Test Sessions started ...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Starting Test Sessions failed', error)
      },
      update: DeleteTestProjectsListsFromCache
    })
  }),
)(TestProjectsEmbeddedTable)
