import cardTitleStyle from 'assets/jss/material-dashboard-react/components/cardTitleStyle.jsx'
import typographyStyle from 'assets/jss/material-dashboard-react/components/typographyStyle.jsx'

const namespaceStyle = {
  ...cardTitleStyle,
  ...typographyStyle,
  namespaceEmbeddedTable: {
    '& table': {
      //borderCollapse: 'collapse',
      '& tr': {
        height: 70
      },
      '& tr:last-child td': {
        borderBottom: `0px solid red`
      },
    }
  },
}

export default namespaceStyle
