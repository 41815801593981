import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import GridItem from '../Grid/GridItem.jsx'
import GridContainer from '../Grid/GridContainer.jsx'
import RegularButton from 'components/Button/Button'
import ConfirmationDialog from './ConfirmationDialog.jsx'
import { CustomCheckbox } from 'components/Form/Form'

function CheckboxDialog({ ...props }) {
  const {
    confirmationTitle,
    confirmationText,
    confirmationTextFn,
    open,
    checkText,
    onConfirmed,
    onCancel,
    children,
    danger,
    ...rest
  } = props
  const [checked, setChecked] = useState(false)
  const prevOpen = useRef({ open }).current

  useEffect(() => {
    if (!prevOpen.open && open) {
      setChecked(false)
    }

    return () => { 
      prevOpen.open = open
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (<React.Fragment>
    <ConfirmationDialog
      danger={danger}
      open={open}
      okDisabled={!checked}
      closeDisabled
      okText="Yes, I am sure. Do it!"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        if (checked) {
          onConfirmed()
        }
      }}
      title={confirmationTitle}
      {...rest}
    >
      <GridContainer>
        <GridItem xs={12} center>
          <CustomCheckbox
            input={{
              onChange: e => {
                setChecked(e.target.checked)
              },
              checked
            }}
            label={checkText}
            data-unique="chkConfirmationCheck"
          />
        </GridItem>
      </GridContainer>
    </ConfirmationDialog>
  </React.Fragment>)
}

CheckboxDialog.propTypes = {
  confirmationTitle: PropTypes.string,
  confirmationText: PropTypes.string,
  confirmationTextFn: PropTypes.func,
  checkText: PropTypes.string,
  onConfirmed: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  ...RegularButton.propTypes
}
CheckboxDialog.defaultProps = {
  confirmationTitle: 'Are you sure ?',
  checkText: 'I am sure.'
}

export default CheckboxDialog
