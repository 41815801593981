import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import SimpleList from 'components/List/SimpleList.jsx'
import Button from 'components/Button/Button'

import ShowIcon from 'components/Icon/ShowIcon'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { CAPABILITYSETS_DROPDOWN_QUERY } from './gql'

import { hasPermission } from 'botium-box-shared/security/permissions'
import CapabilitySet from './CapabilitySet.jsx'

class CapabilitySets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCounter: 0
    }
  }
  render() {
    const { history, location, user } = this.props
    return (<Card><CardBody>
      <GridContainer>
        {hasPermission(user, 'CHATBOTS_CREATE') && <GridItem xs={12} borderBottom middle>
          <Button
            secondary
            data-unique="btnNew"
            onClick={() => {
              this.setState({ newCounter: this.state.newCounter + 1, id: 'new' })
              history.push('/settings/capabilitysets/new')
            }}
            >
              <ShowIcon icon="plus" /> New
            </Button>
        </GridItem>}
        <GridItem xs={12} sm={4} md={3} noPadding>
          <Query query={CAPABILITYSETS_DROPDOWN_QUERY} notifyOnNetworkStatusChange={true}>
            {({ loading, error, data, refetch }) => {
              return <SimpleList
                name="tblApiKeys"
                listData={(data && data.capabilitySets && data.capabilitySets.map(a => ({
                  id: a.id,
                  name: a.name,
                  selected: location.pathname.indexOf(a.id) >= 0,
                  icon: <ShowIcon icon="book" />,
                  secondary: a.chatbots && a.chatbots.length > 0 ? `Used by ${a.chatbots.length} Chatbot(s)` : `Not used by any Chatbot`,
                  onClick: () => history.push(`/settings/capabilitysets/${a.id}`)
                }))) || []}
                onRefresh={() => refetch()}
                pageLoading={loading}
              />
            }}
          </Query>
        </GridItem>
        <GridItem xs={12} sm={8} md={9} borderLeft>
          <Switch>
            <Route
              path="/settings/capabilitysets/new"
              render={props => <CapabilitySet key={`new_${this.state.newCounter}`} onReady={(id) => history.push(`/settings/capabilitysets/${id}`)} {...props}/>}
            />
            <Route
              path="/settings/capabilitysets/:id"
              render={props => <CapabilitySet id={props.match.params.id} key={props.match.params.id} onReady={(id) => !id && history.push('/settings/capabilitysets')} {...props}/>}
            />
          </Switch>
        </GridItem>
      </GridContainer>
    </CardBody></Card>)
  }
}

export default connect(
    state => ({ user: state.token.user }),
)(withStyles(settingsStyle)(withRouter(CapabilitySets)))
