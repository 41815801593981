import React from 'react'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import LinkButton from 'components/Button/LinkButton'
import ConfirmationDialog from './ConfirmationDialog.jsx'

class DirectorySelectionDialogWithoutFileSystemQuery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPath: []
    }
  }

  handleGoto(dirNames) {
    this.setState({ currentPath: dirNames })
  }

  getContentList(directoryTree) {
    const { currentPath } = this.state
    if(!directoryTree || directoryTree.length === 0) {
      return []
    }
    let actualContentList = directoryTree[0].children
    for(const item of currentPath) {
      const foundElement = actualContentList.find(c => c.name === item)
      if(!foundElement) {
        actualContentList = undefined
        break
      }
      actualContentList = foundElement.children
    }
    if (!actualContentList) {
      return directoryTree.children
    }
    return actualContentList
  }

  render () {
    const { onOk, directoryTree, rootDirName, ...rest } = this.props
    const { currentPath } = this.state
    const contentList = this.getContentList(directoryTree)

    return (<ConfirmationDialog okDisabled={currentPath.length === 0} okText={currentPath.length > 0 ? `Select ${currentPath.join('/')}` : 'Select'} onOk={() => onOk(currentPath)} {...rest}>
      <GridContainer>
        <GridItem xs={12}>
          {[ <LinkButton key={'/'} onClick={() => this.handleGoto([])}>{rootDirName || 'Botium'}</LinkButton> ].concat(
            currentPath && currentPath.map((p, i) => {
              const dirNames = currentPath.slice(0, i + 1)
              return <LinkButton key={p} onClick={() => this.handleGoto(dirNames)}>{p}</LinkButton>
            }))
            .reduce((result, item, i) => result.concat([ <React.Fragment key={'/' + i}> / </React.Fragment>, item]), [])}
        </GridItem>
        <GridItem xs={12}>
          <ul>
            {!contentList || contentList.map(a => <li key={a.name}>
              <LinkButton onClick={() => this.handleGoto( [...currentPath, a.name])} data-unique={`btnDirectory_${a.name}`}>{a.name}</LinkButton>
              {a.name !== '..' && <React.Fragment> | (<LinkButton onClick={() => onOk([...currentPath, a.name])} data-unique={`btnSelect_${a.name}`}>Select</LinkButton>)</React.Fragment>}
            </li>)}
          </ul>
        </GridItem>
      </GridContainer>
   </ConfirmationDialog>)
  }
}

export default DirectorySelectionDialogWithoutFileSystemQuery
