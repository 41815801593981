import React from 'react'

// core components
import Text from './Text'

function DangerOrSuccess({ ...props }) {
  const { successful, ...rest } = props
  return  successful ?  <Text successBg {...rest}/> :  <Text dangerBg {...rest}/>
}

export default DangerOrSuccess
