import { SET_ALERT, CLEAR_ALERT } from '../constants'

export default function alert(state = {}, action) {
  switch (action.type) {
    case SET_ALERT:
      return action.payload
    case CLEAR_ALERT:
      return {}
    default:
      return state
  }
}
