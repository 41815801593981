import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import config from 'config'
import copyToClipboard from 'copy-to-clipboard'

// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
// apollo
import { Query } from 'react-apollo'
// core components
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Text from 'components/Typography/Text'


import {
  required,
  renderTextField,
  renderPasswordField,
  renderSelect
} from 'components/Form/Form'
import Button from '../Button/Button'

import { APIKEYS_QUERY } from '../../views/Settings/gql'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function twilioSMSCaps2Form(caps) {
  return {
    twiliosms: {
      accountSid: (caps.find(c => c.name === 'TWILIO_SMS_ACCOUNT_SID') || { stringValue: '' }).stringValue,
      authToken: (caps.find(c => c.name === 'TWILIO_SMS_AUTH_TOKEN') || { stringValue: '' }).stringValue,
      from: (caps.find(c => c.name === 'TWILIO_SMS_FROM') || { stringValue: '' }).stringValue,
      to: (caps.find(c => c.name === 'TWILIO_SMS_TO') || { stringValue: '' }).stringValue,
      publicUrl: `${config.api.ext}/twiliosms/sms`
    }
  }
}

export function twilioSMSForm2caps(values) {
  const capabilities = [
    {
      name: 'TWILIO_SMS_ACCOUNT_SID',
      type: 'STRING',
      stringValue: values.twiliosms.accountSid,
    },
    {
      name: 'TWILIO_SMS_AUTH_TOKEN',
      type: 'STRING',
      stringValue: values.twiliosms.authToken,
    },
    {
      name: 'TWILIO_SMS_FROM',
      type: 'STRING',
      stringValue: values.twiliosms.from,
    },
    {
      name: 'TWILIO_SMS_TO',
      type: 'STRING',
      stringValue: values.twiliosms.to,
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'twilio-sms' }
  ]
  return capabilities
}

const capNamesMap = {
  'twiliosms.accountSid': 'TWILIO_SMS_ACCOUNT_SID',
  'twiliosms.authToken': 'TWILIO_SMS_AUTH_TOKEN',
  'twiliosms.from': 'TWILIO_SMS_FROM',
  'twiliosms.to': 'TWILIO_SMS_TO'
}

class TwilioEditUnconnected extends React.Component {
  render() {
    const { setAlertSuccessMessage, disabled, capSetCapNames } = this.props

    return (<React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Field
            name="twiliosms.accountSid"
            component={renderTextField}
            label="Twilio Account SID"
            validate={required}
            data-unique="txtTwilioEditAccountSid"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twiliosms.accountSid']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twiliosms.accountSid'], 'Account SID from Twilio account')}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="twiliosms.authToken"
            component={renderPasswordField}
            label="Twilio Auth Token"
            validate={required}
            data-unique="txtTwilioEditAuthToken"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twiliosms.authToken']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twiliosms.authToken'], 'Auth Token from Twilio account')}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="twiliosms.from"
            component={renderTextField}
            label="Receiving SMS from"
            validate={required}
            data-unique="txtTwilioEditFrom"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twiliosms.from']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twiliosms.from'], 'Purchased or Verified phone number from Twilio account, in international format including country code')}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="twiliosms.to"
            component={renderTextField}
            label="Sending SMS to"
            validate={required}
            data-unique="txtTwilioEditTo"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twiliosms.to']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twiliosms.to'], 'Phone number to call, in international format including country code')}
          />
        </GridItem>
        <GridItem xs={12}>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Query query={APIKEYS_QUERY}>
            {({ loading, error, data }) => {
              return (<Field
                name="twiliosms.apiKey"
                component={renderSelect}
                label="Select an API Key for the Webhook Url"
                data-unique="selTwilioEditApiKey"
                loading={loading}
                error={error}
                items={data && data.apikeys && data.apikeys.map(a => {
                  return { key: a.key, label: a.name }
                })}
                disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twiliosms.apiKey']) || disabled}
                helperText={capSetDescription(capSetCapNames, capNamesMap['twiliosms.apiKey'], 'Select an API Key for the Webhook Url')}
              />)
            }}
          </Query>
          <FormSpy subscription={{ form: true }} render={({ form: { change }, values }) => (
            <OnChange name="twiliosms.apiKey">
              {(value, previous) => {
                if (value) {
                  const url = `${config.api.ext}/twiliosms/sms?apikey=${value}`
                  change('twiliosms.publicUrl', url)
                }
              }}
            </OnChange>
          )} />
        </GridItem>
        <GridItem xs={11} sm={6}>
          <Field
            name="twiliosms.publicUrl"
            component={renderTextField}
            label="Webhook Url for Twilio Console"
            readOnly
            data-unique="txtTwilioSmsEditPublicUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twiliosms.publicUrl']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twiliosms.publicUrl'], 'Select an API Key for the Webhook Url')}
            endAdornment={
              <FormSpy
                subscription={{ values: true }}
                render={({ values }) => (
                  <Button
                    onClick={() => {
                      copyToClipboard(values.twiliosms.publicUrl)
                      setAlertSuccessMessage(`Webhook Url for Twilio Console copied to clipboard`)
                    }}
                    justIcon
                    data-unique="btnTwilioSmsPublicURLEditCopy"
                  >
                    <FileCopyIcon />
                  </Button>
                )}
              />
            }
          />
          <Text helperText>Public accessible Webhook Url. The Webhook endpoint has to be registered to Twilio number "Receiving SMS from" using <a href="https://www.twilio.com/console/phone-numbers" target="_blank" rel="noopener noreferrer">Twilio Console</a></Text>
        </GridItem>
      </GridContainer>
    </React.Fragment>)
  }
}

const TwilioSMSEdit = withRouter(connect(
  state => ({ settings: state.settings })
)(TwilioEditUnconnected))
export { TwilioSMSEdit }
