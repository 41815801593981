const _ = require('lodash')

module.exports.MINIMUM_AVERAGE_CONFIDENCE = 0.7
module.exports.MAXIMUM_CONFIDENCE_DEVIATION = 0.2
module.exports.MINIMUM_CONFIDENCE_DIFF = 0.2
module.exports.MINIMUM_UTTERANCES_PER_INTENT = 15
module.exports.MINIMUM_UTTERANCES_PER_ENTITY = 5
module.exports.ENTITY_WITHOUT_NAME = '<no name>'

module.exports.WHERE_TEST_SESSION_WITH_TRAINER_SESSION = {
  nlpAnalytics: true,
  trainerSession: {
    status: 'READY'
  }
}

module.exports.sortAndFilterData = ({ data, sortBy, filter }) => {
  if (sortBy === 'name') {
    data = _.sortBy(data, (result) => result.name)
  } else {
    data = _.sortBy(data, (result) => result.confidenceCorrected)
  }

  if (!filter) {
    return { results: data }
  }

  if (filter && filter.filterText) {
    const filterRegExp = RegExp(filter.filterText, 'i')
    data = data.filter(
      r => filterRegExp.test(r.testcaseName)
    )
  }

  const lastPage = Math.max(0, Math.ceil(data.length / filter.rowsPerPage) - 1)
  const currentPage = Math.min(lastPage, filter.page)
  const resultsPage = data.slice(currentPage * filter.rowsPerPage, (currentPage + 1) * filter.rowsPerPage)
  const hasMore = data.length > (currentPage + 1) * filter.rowsPerPage

  return { results: data, lastPage, currentPage, resultsPage, hasMore }
}

module.exports.mergeArrays = (primary, secondary, idField = 'id', justSameFields = true) => {
  const idToMerged = {}
  for (const entry of primary) {
    idToMerged[entry[idField]] = { [idField]: entry[idField], primary: entry }
  }
  if (secondary) {
    for (const entry of secondary) {
      idToMerged[entry[idField]] = Object.assign({ [idField]: entry[idField], secondary: entry }, idToMerged[entry[idField]] || {})
    }

    if (justSameFields) {
      return _.pickBy(idToMerged, (value, key) => value.primary && value.secondary)
    }
  }

  return idToMerged
}

// result is sortable & value is displayed in tooltip
module.exports.mergeArraysDeep = (primary, secondary, valueField, idField = 'id', defaultValue = 0, justSameFields = true) => {
  let merged = {}
  for (const entry of primary) {
    merged[entry[idField]] = { name: entry[idField], primary: entry[valueField] }
  }
  for (const entry of secondary) {
    merged[entry[idField]] = Object.assign({ name: entry[idField], secondary: entry[valueField] }, merged[entry[idField]] || {})
  }

  if (justSameFields) {
    merged = _.pickBy(merged, (value, key) => !_.isNil(value.primary) && !_.isNil(value.secondary))
  }

  for (const key of Object.keys(merged)) {
    merged[key].primary = merged[key].primary || defaultValue
    merged[key].secondary = merged[key].secondary || defaultValue
  }

  return Object.values(merged)
}

// delete this function!!!
/**
 * accepts just correct entries with correct expected and actual intent
 * @param calculated
 * @param calculatedSecondary
 * @returns {{idToPerUtterances}}
 */
module.exports.diffIntents = (calculated, calculatedSecondary) => {
  const justPrimary = []
  const justPrimaryWrong = []
  const justSecondary = []
  // previously-wrong-now-correct
  const wrongToCorrect = []
  const correctToWrong = []
  const wrongToWrongWithOtherIntent = []
  const wrongToWrongWithSameIntent = []
  // previously-vs-now
  const sameIntent = []
  const otherIntent = []

  const idToPerUtterances = module.exports.mergeArrays(calculated.perUtterance, calculatedSecondary.perUtterance, 'utteranceKey')
  for (const entry of Object.values(idToPerUtterances)) {
    if (!entry.primary) {
      justSecondary.push(entry)
    } else if (!entry.secondary) {
      justPrimary.push(entry)
      const priCorrect = entry.primary.intent.expected === entry.primary.intent.actual
      if (!priCorrect) {
        justPrimaryWrong.push(entry.primary)
      }
    } else {
      if (entry.primary.intent.expected && entry.secondary.intent.expected) {
        const priCorrect = entry.primary.intent.expected === entry.primary.intent.actual
        const secCorrect = entry.secondary.intent.expected === entry.secondary.intent.actual

        if (priCorrect && !secCorrect) {
          correctToWrong.push(entry)
        } else if (!priCorrect && secCorrect) {
          wrongToCorrect.push(entry)
        } else if (!priCorrect && !secCorrect) {
          const same = entry.secondary.intent.actual === entry.primary.intent.actual
          if (same) {
            wrongToWrongWithSameIntent.push(entry)
          } else {
            wrongToWrongWithOtherIntent.push(entry)
          }
        }
      }
      if (entry.primary.intent.actual !== entry.secondary.intent.actual) {
        otherIntent.push(entry)
      } else {
        sameIntent.push(entry)
      }
    }
  }

  return {
    idToPerUtterances,
    justPrimary,
    justSecondary,
    wrongToCorrect,
    correctToWrong,
    wrongToWrongWithOtherIntent,
    wrongToWrongWithSameIntent,
    sameIntent,
    otherIntent
  }
}

// TODO delete it
/**
 * accepts just correct entries with correct expected and actual intent
 * @param calculated
 * @param calculatedSecondary
 * @returns {{idToPerUtterancedss}}
 */
module.exports.diffEntities = (calculated, calculatedSecondary) => {
  const correctToWrong = []
  const wrongOrNothingToWrong = []

  const idToPerUtterances = module.exports.mergeArrays(calculated.perUtterance, calculatedSecondary.perUtterance, 'utterance')
  for (const entry of Object.values(idToPerUtterances)) {
    if (entry.primary) {
      for (const entity of entry.primary.entity.actual) {
        if (!entity.matches) {
          const toPush = { utterance: entry, entity }
          if (entry.secondary) {
            let opposite = entry.secondary.entity.actual.find((e) => e.entityKey === entity.entityKey)
            opposite = (opposite && opposite.length) ? opposite[0] : null
            if (opposite && opposite.match) {
              correctToWrong.push(toPush)
            } else {
              wrongOrNothingToWrong.push(toPush)
            }
          } else {
            wrongOrNothingToWrong.push(toPush)
          }
        }
      }
    }
  }

  return {
    correctToWrong,
    wrongOrNothingToWrong
  }
}

module.exports.isNameAcceptedByFilter = (name, filter) => {
  if (!filter) {
    return true
  }

  if (!name) {
    return false
  }

  return name.includes(filter)
}
