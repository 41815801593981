import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {OnChange} from 'react-final-form-listeners'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Tooltip from 'components/Tooltip/Tooltip'
import ShowIcon from 'components/Icon/ShowIcon'
import { getConnector } from 'actions/settings'

import {
  renderTextField,
  renderCheckbox,
  renderFileUpload,
  renderPasswordField,
  renderSelect,
  renderCodeArea,
  required,
  isRsaKey,
  json,
  prettyPrintJson,
  composeValidators,
  renderIntField,
  parseInteger,
  mustBeNumber,
} from 'components/Form/Form'
import { extractErrorMessage } from 'helper/graphHelper'
import { RUNCONNECTORQUERY_QUERY } from './ConnectorEdit'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { capSetDescription, usedByCapabilitySet } from './Helper'

const WELCOME_TEXT_TYPE_NONE = 'NONE'
const WELCOME_TEXT_TYPE_TEXT = 'TEXT'
const WELCOME_TEXT_TYPE_BUTTON = 'BUTTON'

export function dialogFlowCxCaps2Form(caps) {
  const getQueryParamsField = (caps, fieldName, def, subfield) => {
    let cap = caps.find(c => c.name === 'DIALOGFLOWCX_QUERY_PARAMS')

    if (!cap) {
      return def
    }

    try {
      if (!cap.stringValue) {
        return def
      }

      // just to be sure, maybe the string is already a json
      if (!cap[fieldName]) {
        cap = JSON.parse(cap.stringValue)
      }

      if (!cap[fieldName]) {
        return def
      }

      if (subfield) {
        return cap[fieldName][subfield]
      }

      return _.isObject(cap[fieldName]) ? JSON.stringify(cap[fieldName], null, 2) : cap[fieldName]
    } catch (err) {
      console.log(`Failed to parse ${cap.stringValue}`, err)
      return def
    }
  }

  const welcomeTextRaw = (caps.find(c => c.name === 'DIALOGFLOWCX_WELCOME_TEXT') || {
    stringValue: null,
  }).stringValue

  let welcomeText
  let welcomeTextType
  if (!welcomeTextRaw) {
    welcomeTextType = WELCOME_TEXT_TYPE_NONE
    welcomeText = ''
  } else {
    try {
      const messageTextAsButtonPayload = JSON.parse(welcomeTextRaw).buttons[0].payload
      welcomeTextType = WELCOME_TEXT_TYPE_BUTTON
      welcomeText = messageTextAsButtonPayload
    } catch (err) {
      welcomeTextType = WELCOME_TEXT_TYPE_TEXT
      welcomeText = welcomeTextRaw
    }

  }

  return {
    dialogFlowCx: {
      clientEmail: (
        caps.find(c => c.name === 'DIALOGFLOWCX_CLIENT_EMAIL') || {
          stringValue: '',
        }
      ).stringValue,
      privateKey: (
        caps.find(c => c.name === 'DIALOGFLOWCX_PRIVATE_KEY') || {
          stringValue: '',
        }
      ).stringValue,
      location: (
        caps.find(c => c.name === 'DIALOGFLOWCX_LOCATION') || {
          stringValue: '',
        }
      ).stringValue,
      projectId: (
        caps.find(c => c.name === 'DIALOGFLOWCX_PROJECT_ID') || {
          stringValue: '',
        }
      ).stringValue,
      agent: (
        caps.find(c => c.name === 'DIALOGFLOWCX_AGENT_ID') || {
          stringValue: '',
        }
      ).stringValue,
      environment: (
        caps.find(c => c.name === 'DIALOGFLOWCX_ENVIRONMENT') || {
          stringValue: '',
        }
      ).stringValue,
      languageCode: (
        caps.find(c => c.name === 'DIALOGFLOWCX_LANGUAGE_CODE') || {
          stringValue: 'en',
        }
      ).stringValue,
      queryParamsTimeZone: getQueryParamsField(caps, 'timeZone', ''),
      queryParamsGeoLocation: getQueryParamsField(caps, 'geoLocation', '{}'),
      queryParamsSessionEntityTypes: getQueryParamsField(caps, 'sessionEntityTypes', '[]'),
      queryParamsPayload: getQueryParamsField(caps, 'payload', '{}'),
      queryParamsParameters: getQueryParamsField(caps, 'parameters', '{}'),
      queryParamsCurrentPage: getQueryParamsField(caps, 'currentPage', ''),
      queryParamsDisableWebhook: getQueryParamsField(caps, 'disableWebhook', false),
      queryParamsAnalyzeQueryTextSentiment: getQueryParamsField(caps, 'analyzeQueryTextSentiment', false),
      queryParamsWebhookHeaders: getQueryParamsField(caps, 'webhookHeaders', '{}'),
      queryParamsFlowVersions: getQueryParamsField(caps, 'flowVersions', '[]'),
      queryParamsChannel: getQueryParamsField(caps, 'channel', ''),
      queryParamsSessionTtl: getQueryParamsField(caps, 'sessionTtl', '', 'seconds'),
      welcomeTextType: welcomeTextType,
      welcomeText: welcomeText,
      // it is not displayed on the UI. It is just a backward compatibility, for old chatbots it will be null,
      // but if someone creates a new, or writes an old one, then this will be true. (thats why default is true)
      // It is possible to change it
      processWelcomeTextResponse: (
        caps.find(c => c.name === 'DIALOGFLOWCX_PROCESS_WELCOME_TEXT_RESPONSE') || {
          booleanValue: true,
        }
      ).booleanValue,
      extractTestCoverage: (
        caps.find(c => c.name === 'DIALOGFLOWCX_EXTRACT_TEST_COVERAGE') || {
          booleanValue: false,
        }
      ).booleanValue,
    }
  }
}

const capNamesMap = {
  'dialogFlowCx.clientEmail': 'DIALOGFLOWCX_CLIENT_EMAIL',
  'dialogFlowCx.privateKey': 'DIALOGFLOWCX_PRIVATE_KEY',
  'dialogFlowCx.location': 'DIALOGFLOWCX_LOCATION',
  'dialogFlowCx.projectId': 'DIALOGFLOWCX_PROJECT_ID',
  'dialogFlowCx.agent': 'DIALOGFLOWCX_AGENT_ID',
  'dialogFlowCx.environment': 'DIALOGFLOWCX_ENVIRONMENT',
  'dialogFlowCx.languageCode': 'DIALOGFLOWCX_LANGUAGE_CODE',
  'dialogFlowCx.queryParams': 'DIALOGFLOWCX_QUERY_PARAMS',
  'dialogFlowCx.welcomeText': 'DIALOGFLOWCX_WELCOME_TEXT',
  'dialogFlowCx.extractTestCoverage': 'DIALOGFLOWCX_EXTRACT_TEST_COVERAGE',
}

export function dialogFlowCxForm2caps(values) {
  const capabilities = [
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'dialogflowcx' },
    {
      name: 'DIALOGFLOWCX_CLIENT_EMAIL',
      type: 'STRING',
      stringValue: values.dialogFlowCx.clientEmail || '',
    },
    {
      name: 'DIALOGFLOWCX_PRIVATE_KEY',
      type: 'STRING',
      stringValue: values.dialogFlowCx.privateKey || '',
    },
    {
      name: 'DIALOGFLOWCX_LOCATION',
      type: 'STRING',
      stringValue: values.dialogFlowCx.location || '',
    },
    {
      name: 'DIALOGFLOWCX_PROJECT_ID',
      type: 'STRING',
      stringValue: values.dialogFlowCx.projectId || '',
    },
    {
      name: 'DIALOGFLOWCX_AGENT_ID',
      type: 'STRING',
      stringValue: values.dialogFlowCx.agent || '',
    },
    {
      name: 'DIALOGFLOWCX_ENVIRONMENT',
      type: 'STRING',
      stringValue: values.dialogFlowCx.environment || '',
    },
    {
      name: 'DIALOGFLOWCX_LANGUAGE_CODE',
      type: 'STRING',
      stringValue: values.dialogFlowCx.languageCode || '',
    },
    // it is not displayed on the UI. It is just a backward compatibility, for old chatbots it will be null,
    // but if someone creates a new, or writes an old one, then this will be true. (thats why default is true)
    // It is possible to change it
    {
      name: 'DIALOGFLOWCX_PROCESS_WELCOME_TEXT_RESPONSE',
      type: 'BOOLEAN',
      booleanValue: values.dialogFlowCx.processWelcomeTextResponse || true,
    },
    {
      name: 'DIALOGFLOWCX_EXTRACT_TEST_COVERAGE',
      type: 'BOOLEAN',
      booleanValue: values.dialogFlowCx.extractTestCoverage || false,
    },
  ]

  // query parameters
  const queryParams = {}

  if (values.dialogFlowCx.queryParamsTimeZone) {
    queryParams.timeZone = values.dialogFlowCx.queryParamsTimeZone
  }

  const queryParamsGeoLocation = values.dialogFlowCx.queryParamsGeoLocation && JSON.parse(values.dialogFlowCx.queryParamsGeoLocation)
  if (queryParamsGeoLocation && Object.keys(queryParamsGeoLocation).length) {
    queryParams.geoLocation = queryParamsGeoLocation
  }
  const queryParamsSessionEntityTypes = values.dialogFlowCx.queryParamsSessionEntityTypes && JSON.parse(values.dialogFlowCx.queryParamsSessionEntityTypes)
  if (queryParamsSessionEntityTypes && queryParamsSessionEntityTypes.length) {
    queryParams.sessionEntityTypes = queryParamsSessionEntityTypes
  }
  const queryParamsPayload = values.dialogFlowCx.queryParamsPayload && JSON.parse(values.dialogFlowCx.queryParamsPayload)
  if (queryParamsPayload && Object.keys(queryParamsPayload).length) {
    queryParams.payload = queryParamsPayload
  }
  const queryParamsParameters = values.dialogFlowCx.queryParamsParameters && JSON.parse(values.dialogFlowCx.queryParamsParameters)
  if (queryParamsParameters && Object.keys(queryParamsParameters).length) {
    queryParams.parameters = queryParamsParameters
  }
  if (values.dialogFlowCx.queryParamsCurrentPage) {
    queryParams.currentPage = values.dialogFlowCx.queryParamsCurrentPage
  }
  if (!_.isNil(values.dialogFlowCx.queryParamsDisableWebhook)) {
    queryParams.disableWebhook = values.dialogFlowCx.queryParamsDisableWebhook
  }
  if (!_.isNil(values.dialogFlowCx.queryParamsAnalyzeQueryTextSentiment)) {
    queryParams.analyzeQueryTextSentiment = values.dialogFlowCx.queryParamsAnalyzeQueryTextSentiment
  }
  const queryParamsWebhookHeaders = values.dialogFlowCx.queryParamsWebhookHeaders && JSON.parse(values.dialogFlowCx.queryParamsWebhookHeaders)
  if (queryParamsWebhookHeaders && Object.keys(queryParamsWebhookHeaders).length) {
    queryParams.webhookHeaders = queryParamsWebhookHeaders
  }
  const queryParamsFlowVersions = values.dialogFlowCx.queryParamsFlowVersions && JSON.parse(values.dialogFlowCx.queryParamsFlowVersions)
  if (queryParamsFlowVersions && queryParamsFlowVersions.length) {
    queryParams.flowVersions = queryParamsFlowVersions
  }
  if (values.dialogFlowCx.queryParamsChannel) {
    queryParams.channel = values.dialogFlowCx.queryParamsChannel
  }
  if (values.dialogFlowCx.queryParamsSessionTtl) {
    queryParams.sessionTtl = { seconds: values.dialogFlowCx.queryParamsSessionTtl }
  }

  capabilities.push({
    name: 'DIALOGFLOWCX_QUERY_PARAMS',
    type: 'STRING',
    stringValue: JSON.stringify(queryParams),
  })

  // welcome text/button
  if (values.dialogFlowCx.welcomeTextType === WELCOME_TEXT_TYPE_TEXT) {
    capabilities.push({
        name: 'DIALOGFLOWCX_WELCOME_TEXT',
        type: 'STRING',
        stringValue: values.dialogFlowCx.welcomeText || '',
      }
    )
  } else if (values.dialogFlowCx.welcomeTextType === WELCOME_TEXT_TYPE_BUTTON && values.dialogFlowCx.welcomeText) {
    capabilities.push({
        name: 'DIALOGFLOWCX_WELCOME_TEXT',
        type: 'STRING',
        stringValue: `{"buttons": [{"payload": "${values.dialogFlowCx.welcomeText}"}]}`,
      }
    )
  } else {
    capabilities.push({
        name: 'DIALOGFLOWCX_WELCOME_TEXT',
        type: 'STRING',
        stringValue: null,
      }
    )
  }

  return capabilities
}

class DialogFlowCxEditUnconnected extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      projectExpanded: true,
      credentialsExpanded: false,
      agentExpanded: false,
      contextExpanded: false,
      parseError: null,
      parseOk: null
    }
  }

  render() {
    const { advanced, disabled, getConnector, capSetCapNames } = this.props
    const { projectExpanded, credentialsExpanded, agentExpanded, contextExpanded, parseError, parseOk } = this.state

    const connector = getConnector('dialogflowcx')
    const capAgent = connector.capabilities.find(c => c.name === 'DIALOGFLOWCX_AGENT_ID')
    const capLanguage = connector.capabilities.find(c => c.name === 'DIALOGFLOWCX_LANGUAGE_CODE')
    const capLocation = connector.capabilities.find(c => c.name === 'DIALOGFLOWCX_LOCATION')
    const capProjectId = connector.capabilities.find(c => c.name === 'DIALOGFLOWCX_PROJECT_ID')
    const capClientEmail = connector.capabilities.find(c => c.name === 'DIALOGFLOWCX_CLIENT_EMAIL')
    const capPrivateKey = connector.capabilities.find(c => c.name === 'DIALOGFLOWCX_PRIVATE_KEY')
    const capEnvironment = connector.capabilities.find(c => c.name === 'DIALOGFLOWCX_ENVIRONMENT')
    const capWelcomeText = connector.capabilities.find(c => c.name === 'DIALOGFLOWCX_WELCOME_TEXT')
    const capExtractTestCoverage = connector.capabilities.find(c => c.name === 'DIALOGFLOWCX_EXTRACT_TEST_COVERAGE')

    const loadJsonFile = (change, filename, filecontent) => {
      try {
        const cred = JSON.parse(atob(filecontent))
        if (!cred.project_id || !cred.client_email || !cred.private_key || _.isString(isRsaKey(cred.private_key))) {
          this.setState({
            parseError: `${filename} is not a Service Account Credentials File (JSON fields project_id/client_email/private_key are required)`,
            parseOk: null
          })
        } else {
          change('dialogFlowCx.projectId', cred.project_id)
          change('dialogFlowCx.clientEmail', cred.client_email)
          change('dialogFlowCx.privateKey', cred.private_key)
          this.setState({
            parseError: null,
            parseOk: `Project Id, Client Email and Private Key read from file ${filename}`,
          })
        }
      } catch (err) {
        this.setState({
          parseError: `Reading credentials from file ${filename} failed: ${err}`,
          parseOk: null
        })
      }
    }

    return (
      <FormSpy
        subscription={{ values: true, form: true }}
        render={({ values, form: { change } }) => {
          const renderAgent = () => {
            return <Query
              fetchPolicy="network-only"
              query={RUNCONNECTORQUERY_QUERY}
              variables={{
                connectorName: 'dialogflowcx',
                field: 'DIALOGFLOWCX_AGENT_ID',
                caps: JSON.stringify({
                  DIALOGFLOWCX_PROJECT_ID: values.dialogFlowCx && values.dialogFlowCx.projectId,
                  DIALOGFLOWCX_LOCATION: values.dialogFlowCx && values.dialogFlowCx.location,
                  DIALOGFLOWCX_CLIENT_EMAIL: values.dialogFlowCx && values.dialogFlowCx.clientEmail,
                  DIALOGFLOWCX_PRIVATE_KEY: values.dialogFlowCx && values.dialogFlowCx.privateKey
                })
              }}
            >
              {({ loading, error, data }) => {
                const err = error ? extractErrorMessage(error) : (data && data.runconnectorquery && data.runconnectorquery.err)
                const choices = data && data.runconnectorquery && data.runconnectorquery.choices

                if (loading || err || !choices || choices.length === 0) {
                  let endAdornment = null
                  if (loading) endAdornment = <LoadingIndicator />
                  else if (err) endAdornment = <Tooltip title={err}><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>
                  else endAdornment = <Tooltip title="No agents for selection."><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>

                  return <Field
                    name="dialogFlowCx.agent"
                    component={renderTextField}
                    label="Agent Id"
                    validate={required}
                    parse={v => v}
                    data-unique="txtDialogFlowCxAgent"
                    endAdornment={endAdornment}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.agent']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.agent'])}
                  />
                } else {
                  return <Field
                    name="dialogFlowCx.agent"
                    component={renderSelect}
                    label="Agent Id"
                    validate={required}
                    data-unique="selDialogFlowCxAgent"
                    items={choices.map(a => {
                      return { key: a.key, label: a.name }
                    })}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.agent']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.agent'])}
                  />
                }
              }}
            </Query>
          }

          if (advanced) {
            return (
              <GridContainer>
                <GridItem xs={12}>
                  <ExpansionPanel expanded={projectExpanded} onChange={() => this.setState({projectExpanded: !projectExpanded})} data-unique="pnlDialogFlowCxProject">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Project Configuration
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer nounset>
                        <GridItem xs={12}>
                          <Text info>
                            For the Dialogflow CX are quotas applied on the number of calls per minute. Tune these quotas in the <em>Retry &amp; Quotas</em> settings.
                          </Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlowCx.projectId"
                            component={renderTextField}
                            label="Project Id"
                            validate={required}
                            data-unique="txtDialogFlowCxProjectId"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.projectId']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.projectId'])}
                          />
                          <Text helperText isHtml>{capProjectId.description}</Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlowCx.location"
                            component={renderSelect}
                            label="Location"
                            data-unique="selDialogFlowApiEndpoint"
                            items={[
                              { label: 'Global', key: 'global' },
                              { label: 'us-central1 (Americas/Iowa)', key: 'us-central1' },
                              { label: 'northamerica-northeast1 (Americas/Montréal)', key: 'northamerica-northeast1' },
                              { label: 'us-east1 (Americas/South Carolina)', key: 'us-east1' },
                              { label: 'us-west1 (Americas/Oregon)', key: 'us-west1' },
                              { label: 'europe-west1 (Europe/Belgium)', key: 'europe-west1' },
                              { label: 'europe-west2 (Europe/London)', key: 'europe-west2' },
                              { label: 'europe-west3 (Europe/Frankfurt)', key: 'europe-west3' },
                              { label: 'australia-southeast1 (Asia Pacific/Sydney)', key: 'australia-southeast1' },
                              { label: 'asia-northeast1 (Asia Pacific/Tokyo)', key: 'asia-northeast1' },
                              { label: 'asia-south1 (Asia Pacific/Mumbai)', key: 'asia-south1' },
                              { label: 'asia-southeast1 (Asia Pacific/Singapore)', key: 'asia-southeast1' }
                            ]}
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.location']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.location'])}
                          />
                          <Text helperText isHtml>{capLocation.description}</Text>
                        </GridItem>
                      </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel expanded={credentialsExpanded} onChange={() => this.setState({credentialsExpanded: !credentialsExpanded})} data-unique="pnlDialogFlowCxCredentials">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Credentials
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer nounset>
                        <GridItem xs={12} sm={5}>
                          <Field
                            name="dialogFlowCx.clientEmail"
                            component={renderTextField}
                            label="Client Email"
                            validate={required}
                            data-unique="txtDialogFlowCxClientEmail"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.clientEmail']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.clientEmail'])}
                          />
                          <Text helperText isHtml>{capClientEmail.description}</Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Field
                            name="dialogFlowCx.privateKey"
                            component={renderPasswordField}
                            label="Private Key"
                            validate={composeValidators(required, isRsaKey)}
                            data-unique="txtDialogFlowCxPrivateKey"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.privateKey']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.privateKey'])}
                          />
                          <Text helperText isHtml>{capPrivateKey.description}</Text>
                        </GridItem>
                        {!disabled && (<React.Fragment>
                          <GridItem xs={12}>
                            <Field
                              name="dialogFlowCx.fileupload"
                              component={renderFileUpload}
                              accept=".json"
                              values={values}
                              change={change}
                              label="Select/Drop Service Account Credentials JSON File"
                              onFileLoaded={(filename, filecontent) => {
                                loadJsonFile(change, filename, filecontent)
                                change('dialogFlowCx.fileupload', null)
                              }}
                              data-unique="fileDialogFlowCredentials"
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            {parseError && <Text danger data-unique="fileDialogFlowCredentialsErr">{parseError}</Text>}
                            {parseOk && <Text success data-unique="fileDialogFlowCredentialsOk">{parseOk}</Text>}
                          </GridItem>
                        </React.Fragment>)}
                        </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel expanded={agentExpanded} onChange={() => this.setState({agentExpanded: !agentExpanded})} data-unique="pnlDialogFlowCxAgent">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Agent Settings
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer nounset>
                        <GridItem xs={12} sm={4}>
                          {renderAgent()}
                          <OnChange name="dialogFlowCx.agent">
                            {(value, previous) => {
                              change('dialogFlowCx.environment', undefined)
                            }}
                          </OnChange>

                          <Text helperText isHtml>{capAgent.description}</Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Query
                            fetchPolicy="network-only"
                            query={RUNCONNECTORQUERY_QUERY}
                            variables={{
                              connectorName: 'dialogflowcx',
                              field: 'DIALOGFLOWCX_ENVIRONMENT',
                              caps: JSON.stringify({
                                DIALOGFLOWCX_PROJECT_ID: values.dialogFlowCx && values.dialogFlowCx.projectId,
                                DIALOGFLOWCX_CLIENT_EMAIL: values.dialogFlowCx && values.dialogFlowCx.clientEmail,
                                DIALOGFLOWCX_PRIVATE_KEY: values.dialogFlowCx && values.dialogFlowCx.privateKey,
                                DIALOGFLOWCX_LOCATION: values.dialogFlowCx && values.dialogFlowCx.location,
                                DIALOGFLOWCX_AGENT_ID: values.dialogFlowCx && values.dialogFlowCx.agent
                              })
                            }}
                          >
                            {({ loading, error, data }) => {
                              const err = error ? extractErrorMessage(error) : (data && data.runconnectorquery && data.runconnectorquery.err)
                              const choices = data && data.runconnectorquery && data.runconnectorquery.choices

                              if (loading || err || !choices || choices.length === 0) {
                                let endAdornment = null
                                if (loading) endAdornment = <LoadingIndicator />
                                else if (err) endAdornment = <Tooltip title={err}><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>
                                else endAdornment = <Tooltip title="No environments for selection."><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>

                                return <Field
                                  key={`dialogFlowCx.environment_static_${loading}${error}${!choices || choices.length === 0}${values.dialogFlowCx.environment}`}
                                  name="dialogFlowCx.environment"
                                  component={renderTextField}
                                  label="Environment Name"
                                  parse={v => v}
                                  data-unique="txtDialogFlowCxEnvironment"
                                  endAdornment={endAdornment}
                                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.environment']) || disabled}
                                  helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.environment'])}
                                />
                              } else {
                                return <Field
                                  key="dialogFlowCx.environment_dynamic"
                                  name="dialogFlowCx.environment"
                                  component={renderSelect}
                                  label="Environment Name"
                                  data-unique="selDialogFlowCxEnvironment"
                                  items={choices.map(a => {
                                    return { key: a.key, label: a.name }
                                  })}
                                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.environment']) || disabled}
                                  helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.environment'])}
                                />
                              }
                            }}
                          </Query>
                          <Text helperText isHtml>{capEnvironment.description}</Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Query
                            fetchPolicy="network-only"
                            query={RUNCONNECTORQUERY_QUERY}
                            variables={{
                              connectorName: 'dialogflowcx',
                              field: 'DIALOGFLOWCX_LANGUAGE_CODE',
                              caps: JSON.stringify({
                                DIALOGFLOWCX_PROJECT_ID: values.dialogFlowCx && values.dialogFlowCx.projectId,
                                DIALOGFLOWCX_LOCATION: values.dialogFlowCx && values.dialogFlowCx.location,
                                DIALOGFLOWCX_CLIENT_EMAIL: values.dialogFlowCx && values.dialogFlowCx.clientEmail,
                                DIALOGFLOWCX_PRIVATE_KEY: values.dialogFlowCx && values.dialogFlowCx.privateKey,
                                DIALOGFLOWCX_AGENT_ID: values.dialogFlowCx && values.dialogFlowCx.agent
                              })
                            }}
                          >
                            {({ loading, error, data }) => {
                              const err = error ? extractErrorMessage(error) : (data && data.runconnectorquery && data.runconnectorquery.err)
                              const choices = data && data.runconnectorquery && data.runconnectorquery.choices

                              if (loading || err || !choices || choices.length === 0) {
                                let endAdornment = null
                                if (loading) endAdornment = <LoadingIndicator />
                                else if (err) endAdornment = <Tooltip title={err}><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>
                                else endAdornment = <Tooltip title="No language codes found for selection, please enter value manually."><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>

                                return <Field
                                  name="dialogFlowCx.languageCode"
                                  component={renderTextField}
                                  label="Language"
                                  parse={v => v}
                                  data-unique="txtDialogFlowCxLanguageCode"
                                  endAdornment={endAdornment}
                                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.languageCode']) || disabled}
                                  helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.languageCode'])}
                                />
                              } else {
                                return <Field
                                  name="dialogFlowCx.languageCode"
                                  component={renderSelect}
                                  label="Language"
                                  data-unique="selDialogFlowCxLanguageCode"
                                  items={choices.map(a => {
                                    return { key: a.key, label: a.name }
                                  })}
                                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.languageCode']) || disabled}
                                  helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.languageCode'])}
                                  />
                              }
                            }}
                          </Query>
                          <Text helperText isHtml>{capLanguage.description || 'The language of the Dialogflow CX Agent'}</Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlowCx.welcomeTextType"
                            component={renderSelect}
                            label="Welcome Message Type"
                            validate={required}
                            data-unique="selDialogFlowCxWelcomeTextType"
                            items={[
                              {
                                key: WELCOME_TEXT_TYPE_NONE,
                                label: 'None'
                              },
                              {
                                key: WELCOME_TEXT_TYPE_TEXT,
                                label: 'Text'
                              },
                              {
                                key: WELCOME_TEXT_TYPE_BUTTON,
                                label: 'Button or Event'
                              },
                            ]}
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.welcomeText']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.welcomeText'])}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlowCx.welcomeText"
                            component={renderTextField}
                            label="Welcome Message"
                            data-unique="txtDialogFlowCxWelcomeText"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.welcomeText']) || disabled || !values.dialogFlowCx.welcomeTextType || values.dialogFlowCx.welcomeTextType === WELCOME_TEXT_TYPE_NONE}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.welcomeText'])}
                          />
                          <Text helperText isHtml>{capWelcomeText.description}</Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlowCx.extractTestCoverage"
                            component={renderCheckbox}
                            label="Check Test Coverage"
                            type="checkbox"
                            data-unique="cbDialogFlowCxExtractTestCoverage"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.extractTestCoverage']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.extractTestCoverage'])}
                          />
                          <Text helperText isHtml>{capExtractTestCoverage.description}</Text>
                        </GridItem>
                      </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel expanded={contextExpanded} onChange={() => this.setState({contextExpanded: !contextExpanded})} data-unique="pnlDialogFlowCxQueryParams">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Query Parameters
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer nounset>
                        <GridItem xs={12}>
                          <Text info>The Query Parameters are defined by <a href="https://cloud.google.com/dialogflow/cx/docs/reference/rest/v3/QueryParameters" target="_blank" rel="noopener noreferrer">Dialogflow CX API</a></Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsPayload"
                            className="CapabilitiesShort"
                            component={renderCodeArea}
                            options={{ mode: 'application/json' }}
                            label="Payload"
                            codeFormat={prettyPrintJson}
                            parse={v => v}
                            validate={json}
                            data-unique="txtDialogFlowCxQueryParamsPayload"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>This field can be used to pass custom data into the webhook associated with the agent. Arbitrary JSON objects are supported.</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsParameters"
                            className="CapabilitiesShort"
                            component={renderCodeArea}
                            options={{ mode: 'application/json' }}
                            label="Parameters"
                            codeFormat={prettyPrintJson}
                            parse={v => v}
                            validate={json}
                            data-unique="txtDialogFlowCxQueryParamsParameters"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>Additional parameters to be put into [session parameters][SessionInfo.parameters]. To remove a parameter from the session, clients should explicitly set the parameter value to null.</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsChannel"
                            component={renderTextField}
                            label="Channel"
                            data-unique="txtDialogFlowCxChannel"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>
                            <div>
                              <p>The channel which this query is for.</p>
                              <p>If specified, only the ResponseMessage associated with the channel will be returned. If no ResponseMessage is associated with the channel, it falls back to the ResponseMessage with unspecified channel.</p>
                              <p>If unspecified, the ResponseMessage with unspecified channel will be returned.</p>
                            </div>
                          </Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsTimeZone"
                            component={renderTextField}
                            label="Time Zone"
                            data-unique="txtDialogFlowCxTimeZone"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>The time zone of this conversational query from the time zone database, e.g., America/New_York, Europe/Paris. If not provided, the time zone specified in the agent is used.</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsSessionEntityTypes"
                            className="CapabilitiesShort"
                            component={renderCodeArea}
                            options={{ mode: 'application/json' }}
                            label="Session Entity Types"
                            codeFormat={prettyPrintJson}
                            parse={v => v}
                            validate={json}
                            data-unique="txtDialogFlowCxQueryParamsSessionEntityTypes"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>Additional session entity types to replace or extend developer entity types with. The entity synonyms apply to all languages and persist for the session of this query.</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsGeoLocation"
                            className="CapabilitiesShort"
                            component={renderCodeArea}
                            options={{ mode: 'application/json' }}
                            label="Geo Location"
                            codeFormat={prettyPrintJson}
                            parse={v => v}
                            validate={json}
                            data-unique="txtDialogFlowCxQueryParamsGeoLocation"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>{'The geo location of this conversational query. The following JSON format is accepted: {"latitude": number, "longitude": number}.'}</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsDisableWebhook"
                            component={renderCheckbox}
                            label="Disable Webhook"
                            type="checkbox"
                            data-unique="cbDialogCxFlowDisableWebhook"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>Whether to disable webhook calls for this request.</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsWebhookHeaders"
                            className="CapabilitiesShort"
                            component={renderCodeArea}
                            options={{ mode: 'application/json' }}
                            label="Webhook Headers"
                            codeFormat={prettyPrintJson}
                            parse={v => v}
                            validate={json}
                            data-unique="txtDialogFlowCxQueryParamsWebhookHeaders"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled  || values.dialogFlowCx.queryParamsDisableWebhook}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>This field can be used to pass HTTP headers for a webhook call. These headers will be sent to webhook along with the headers that have been configured through Dialogflow web console. The headers defined within this field will overwrite the headers configured through Dialogflow console if there is a conflict.</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsFlowVersions"
                            className="CapabilitiesShort"
                            component={renderCodeArea}
                            options={{ mode: 'application/json' }}
                            label="Flow Versions"
                            codeFormat={prettyPrintJson}
                            parse={v => v}
                            validate={json}
                            data-unique="txtDialogFlowCxQueryParamsFlowVersions"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>A list of flow versions to override for the request</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsCurrentPage"
                            component={renderTextField}
                            label="Current Page"
                            data-unique="txtDialogFlowCurrentPage"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>The unique identifier of the page to override the [current page][QueryResult.current_page] in the session.</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsAnalyzeQueryTextSentiment"
                            component={renderCheckbox}
                            label="Analyze Query Text Sentiment"
                            type="checkbox"
                            data-unique="cbDialogFlowAnalyzeQueryTextSentiment"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>Configures whether sentiment analysis should be performed.</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="dialogFlowCx.queryParamsSessionTtl"
                            component={renderIntField}
                            label="Session TTL"
                            parse={parseInteger}
                            validate={mustBeNumber}
                            data-unique="txtDialogFlowSessionTtl"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlowCx.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.queryParams'])}
                          />
                          <Text helperText>
                            <div>
                              <p>Sets Dialogflow session life time. By default, a Dialogflow session remains active and its data is stored for 30 minutes after the last request is sent for the session. This value should be no longer than 1 day.</p>
                              <p>A duration in seconds.</p>
                            </div>
                          </Text>
                        </GridItem>
                      </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </GridItem>
              </GridContainer>
            )
          } else {
            return (
              <GridContainer>
                <GridItem xs={12}>
                  <Text helperText>
                    You have to download your
                    <a href="https://cloud.google.com/docs/authentication/getting-started" target="_blank" rel="noopener noreferrer">Google credentials</a>
                    for accessing your Dialogflow CX Agent first. The IAM roles Dialogflow API-Administrator and Dialogflow API-Client are required.
                    Project Id, Agent Id and Location can be found in the
                    <a href="https://cloud.google.com/dialogflow/cx/docs/quick/api" target="_blank" rel="noopener noreferrer">Dialogflow CX Console</a>.
                  </Text>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="dialogFlowCx.clientEmail"
                    component={renderTextField}
                    label="Client Email"
                    validate={required}
                    data-unique="txtDialogFlowCxClientEmail"
                    helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlowCx.clientEmail'])}
                  />
                  <Text helperText isHtml>{capClientEmail.description}</Text>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="dialogFlowCx.privateKey"
                    component={renderPasswordField}
                    label="Private Key"
                    validate={composeValidators(required, isRsaKey)}
                    data-unique="txtDialogFlowCxPrivateKey"
                  />
                  <Text helperText isHtml>{capPrivateKey.description}</Text>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="dialogFlowCx.location"
                    component={renderSelect}
                    label="Location"
                    data-unique="selDialogFlowApiEndpoint"
                    items={[
                      { label: 'Global', key: 'global' },
                      { label: 'us-central1 (Americas/Iowa)', key: 'us-central1' },
                      { label: 'northamerica-northeast1 (Americas/Montréal)', key: 'northamerica-northeast1' },
                      { label: 'us-east1 (Americas/South Carolina)', key: 'us-east1' },
                      { label: 'us-west1 (Americas/Oregon)', key: 'us-west1' },
                      { label: 'europe-west1 (Europe/Belgium)', key: 'europe-west1' },
                      { label: 'europe-west2 (Europe/London)', key: 'europe-west2' },
                      { label: 'europe-west3 (Europe/Frankfurt)', key: 'europe-west3' },
                      { label: 'australia-southeast1 (Asia Pacific/Sydney)', key: 'australia-southeast1' },
                      { label: 'asia-northeast1 (Asia Pacific/Tokyo)', key: 'asia-northeast1' },
                      { label: 'asia-south1 (Asia Pacific/Mumbai)', key: 'asia-south1' },
                      { label: 'asia-southeast1 (Asia Pacific/Singapore)', key: 'asia-southeast1' }
                    ]}
                  />
                  <Text helperText isHtml>{capLocation.description}</Text>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="dialogFlowCx.projectId"
                    component={renderTextField}
                    label="Project Id"
                    validate={required}
                    data-unique="txtDialogFlowCxProjectId"
                  />
                  <Text helperText isHtml>{capLocation.description}</Text>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  {renderAgent()}
                </GridItem>

              </GridContainer>
            )
          }
        }}
      />
    )
  }
}

const DialogFlowCxEdit = withRouter(connect(
  state => ({ settings: state.settings }),
  { getConnector }
)(DialogFlowCxEditUnconnected))
export { DialogFlowCxEdit }
