import _ from 'lodash'

import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { Query, Mutation, compose, graphql, withApollo } from 'react-apollo'
import { Switch, Route } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from 'components/List/ListItem/ListItemText'
import InfoIcon from '@material-ui/icons/Info'

import { gql } from 'apollo-boost'

// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import ShowIcon from 'components/Icon/ShowIcon'
import SimpleList from 'components/List/SimpleList'
import DateFormat from 'components/Info/DateFormat'
import Tooltip from 'components/Tooltip/Tooltip'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import Text from 'components/Typography/Text'
import SwitchButton from 'components/Button/SwitchButton'
import Chip from 'components/Chip/Chip'
import AdvancedTable from 'components/Table/AdvancedTable'
import ObjectChips from 'components/Chip/ObjectChips'

import CrawlerSettingsForm from './CrawlerSettingsForm'
import CrawlerResultTree from './CrawlerResultTree'
import CrawlerSessionConvo from './CrawlerSessionConvo.jsx'
import CrawlerSessionUtterance from './CrawlerSessionUtterance.jsx'
import CrawlerSessionScript from './CrawlerSessionScript'
import StartCrawlerSession from './StartCrawlerSession'
import CrawlerSessionProgress from './CrawlerSessionProgress'

import MaxConversationSteps from 'assets/img/crawler/maxconversationsteps.svg'
import NumberOfWelcomeMessages from 'assets/img/crawler/numberofwelcome.svg'
import WaitForBot from 'assets/img/crawler/waitforbot.svg'
import ConversationStartMessages from 'assets/img/crawler/conversationstart.svg'
import ExitCriteria from 'assets/img/crawler/exitcriteria.svg'
import IgnoreButtons from 'assets/img/crawler/ignorebuttons.svg'
import GenerateUtterances from 'assets/img/crawler/generateutt.svg'
import MergeUtterances from 'assets/img/crawler/mergeutt.svg'

import MaxConversationStepsDarkmode from 'assets/img/crawler/darkmode/maxconversationsteps.svg'
import NumberOfWelcomeMessagesDarkmode from 'assets/img/crawler/darkmode/numberofwelcome.svg'
import WaitForBotDarkmode from 'assets/img/crawler/darkmode/waitforbot.svg'
import ConversationStartMessagesDarkmode from 'assets/img/crawler/darkmode/conversationstart.svg'
import ExitCriteriaDarkmode from 'assets/img/crawler/darkmode/exitcriteria.svg'
import IgnoreButtonsDarkmode from 'assets/img/crawler/darkmode/ignorebuttons.svg'
import GenerateUtterancesDarkmode from 'assets/img/crawler/darkmode/generateutt.svg'
import MergeUtterancesDarkmode from 'assets/img/crawler/darkmode/mergeutt.svg'

import {
  renderTextField,
  required,
  renderSelect,
  renderCheckbox, CustomCheckbox,
} from 'components/Form/Form'

import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { putRecentListEntry, removeRecentListEntry } from 'actions/activity'
import {
  CRAWLERSESSION_QUERY,
  CRAWLERSESSION_TREE_QUERY,
  CRAWLERSESSIONJOB_LOGS_QUERY,
  CRAWLERSESSIONS_QUERY,
  CRAWLERPROJECT_QUERY,
  CRAWLERSESSIONSCRIPTSFIlTERED_QUERY
} from './gql'
import { AGENTS_DROPDOWN_QUERY } from '../Settings/gql'
import { RefetchTestProjectQueries } from '../TestProjects/gql'
import { RefetchTestSetQueries, TESTSETS_DROPDOWN_QUERY, TESTSETSOURCES_SCRIPTS_QUERY } from '../TestSets/gql'
import { hasPermission, hasAnyPermission } from 'botium-box-shared/security/permissions'
import config from '../../config'
import crawlersessionsStyle from 'assets/jss/material-dashboard-react/views/crawlersessionsStyle'
import { redirectToConvoEditorView, redirectToSourceEditorView } from './helper'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { isDarkmode } from 'components/Darkmode/helper'
import {extractErrorMessage} from '../../helper/graphHelper'
import { safeGetNamespaceFilteredList } from '../helper'

const CRAWLERSCRIPTS_COPY = gql`
  mutation CrawlerScriptsCopyIntoTestSet(
    $chatbotId: ID
    $crawlerSessionId: ID!
    $namespace: String,
    $testSetId: ID
    $newTestSetName: String
    $createTestProject: Boolean!
    $importMode: TestSetImportMode!
  ) {
    copyCrawlerScriptsIntoTestSet(
      chatbotId: $chatbotId
      crawlerSessionId: $crawlerSessionId
      namespace: $namespace
      testSetId: $testSetId
      newTestSetName: $newTestSetName
      createTestProject: $createTestProject,
      importMode: $importMode
    ) {
      testSetId
      skippedNames
      overwrittenNames
      backupNames
      newNames
    }
  }
`


class CrawlerSession extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sectionExpanded: 'basic',
      showCopyDialog: false,
      scriptSourceView: false,
      showSessionJobsDialog: false,
      jobOpen: {},
      dashboardExpanded: true,
      successConvos: true,
      warningConvos: true,
      failedConvos: true,
      utterances: true,
      scriptViewProgressKey: 0,
      showCopyIntoTestSetError: null
    }
  }

  renderCrawlerScriptsViewTab(crawlerProject, crawlerSession, crawlerSessionData, historyView) {
    const { user, location, history, classes } = this.props
    const { scriptSourceView, successConvos, warningConvos, failedConvos, utterances, scriptViewProgressKey } = this.state

    return (<GridContainer>
      <GridItem xs={12}>
        {this.renderDashboardView(crawlerSession, crawlerProject, historyView)}
      </GridItem>
      {crawlerSession && <GridItem xs={12}>
        <CrawlerSessionProgress
          query={CRAWLERSESSIONSCRIPTSFIlTERED_QUERY}
          querySelector={data => data.crawlersessionscriptsfiltered}
          readySelector={data => {
            const csScripts = data.crawlersessionscriptsfiltered
            if (csScripts) {
              if (csScripts.length === 0) {
                return true
              }
              const cs = csScripts[0].crawlerSession
              if (cs && (cs.status === 'READY' || cs.status === 'FAILED' || cs.status === 'PARTIALLY_FAILED' || cs.status === 'CANCELLED')) {
                return true
              }
            }
            return false
          }}
          subscriptionSelector={data => data.crawlerSessionProgress && data.crawlerSessionProgress.scripts}
          crawlerSession={crawlerSession}
          variables={{
            crawlerSessionId: crawlerSession.id,
            orderBy: 'createdAt_DESC',
            successConvos,
            warningConvos,
            failedConvos,
            utterances
          }}
          key={scriptViewProgressKey}
        >
          {({ crawlerSessionProgress, crawlerSessionProgressErr, crawlerSessionProgressLoading, refetch }) => {
            if (crawlerSessionProgressLoading) {
              return <LoadingIndicator />
            }
            if (crawlerSessionProgressErr) {
              return <ErrorFormat err={crawlerSessionProgressErr} />
            }

            let scripts = []
            if (crawlerSessionProgress) {
              crawlerSessionProgress.forEach(s => {
                const icon = s.err
                  ? <b><Text danger><ShowIcon custom icon="convoError" /></Text></b>
                  : s.stucked && !s.markedWithEndOfConversation
                    ? <b><Text warning><ShowIcon custom icon="convoWarning" /></Text></b>
                    : s.scriptType === 'SCRIPTING_TYPE_UTTERANCES'
                      ? <b><Text muted><ShowIcon custom icon="utterance" /></Text></b>
                      : <b><Text success><ShowIcon custom icon="convo" /></Text></b>
                const script = {
                  id: s.id,
                  name: s.name,
                  icon,
                  selected: location.pathname.indexOf(s.id) > 0,
                  dataUnique: `liCrawlerScript${s.name}`,
                  scriptType: s.scriptType,
                  onClick: () => {
                    if (scriptSourceView) {
                      redirectToSourceEditorView(history, crawlerSession, historyView, s.id)
                    } else {
                      redirectToConvoEditorView(history, crawlerSession, historyView, s.id, s.scriptType)
                    }
                  },
                }
                if (s.err) {
                  script.secondary = s.err
                } else if (s.markedWithEndOfConversation) {
                  script.secondary = 'Conversation is marked with end of conversation'
                } else if (s.exitCriteriaMatch) {
                  script.secondary = 'Conversation has a match with one of the exit criteria'
                } else if (s.stucked) {
                  script.secondary = 'Conversation is stopped before reaching the maximum conversation depth'
                }
                scripts.push(script)
              })
            }
            scripts = _.sortBy(scripts, [s => s.name.toLowerCase()])
            return (
              <Card noMargin>
                <CardBody noPaddingTop noPaddingBottom>
                  <GridContainer>
                    <GridItem md={12} lg={8} borderBottom middle>
                      <React.Fragment>
                        <GridContainer>
                          <GridItem xs={3} borderRight middle noMargin>
                            <CustomCheckbox
                              formControlProps={{ className: classes.crawlerscriptfilter }}
                              switchClasses={{
                                label: classes.successConvos,
                                checked: classes.successConvos
                              }}
                              useCheckbox
                              input={{
                                onChange: e => this.setState({
                                  successConvos: e.target.checked,
                                  warningConvos: e.target.checked,
                                  failedConvos: e.target.checked,
                                  utterances: e.target.checked
                                }),
                                checked: successConvos && warningConvos && failedConvos && utterances
                              }}
                              label="View All"
                            />
                          </GridItem>
                          <GridItem xs={2}>
                            <CustomCheckbox
                              formControlProps={{ className: classes.crawlerscriptfilter }}
                              switchClasses={{
                                label: classes.successConvos,
                                checked: classes.successConvos
                              }}
                              useCheckbox
                              input={{
                                onChange: e => this.setState({ successConvos: e.target.checked }),
                                checked: successConvos
                              }}
                              label="Success"
                            />
                          </GridItem>
                          <GridItem xs={2}>
                            <CustomCheckbox
                              formControlProps={{ className: classes.crawlerscriptfilter }}
                              switchClasses={{
                                label: classes.darkLabel,
                                checked: classes.warningConvos
                              }}
                              useCheckbox
                              input={{
                                onChange: e => this.setState({ warningConvos: e.target.checked }),
                                checked: warningConvos
                              }}
                              label="Warning"
                            />
                          </GridItem>
                          <GridItem xs={2}>
                            <CustomCheckbox
                              formControlProps={{ className: classes.crawlerscriptfilter }}
                              switchClasses={{
                                label: classes.darkLabel,
                                checked: classes.failedConvos
                              }}
                              useCheckbox
                              input={{
                                onChange: e => this.setState({ failedConvos: e.target.checked }),
                                checked: failedConvos
                              }}
                              label="Failed"
                            />
                          </GridItem>
                          <GridItem xs={2}>
                            <CustomCheckbox
                              formControlProps={{ className: classes.crawlerscriptfilter }}
                              switchClasses={{
                                label: classes.successConvos,
                                checked: classes.successConvos
                              }}
                              useCheckbox
                              input={{
                                onChange: e => this.setState({ utterances: e.target.checked }),
                                checked: utterances
                              }}
                              label="Utterances"
                            />
                          </GridItem>
                        </GridContainer>
                      </React.Fragment>
                    </GridItem>
                    <GridItem right md={12} lg={4} borderBottom middle>
                      {this.renderCopyScriptsDialog(crawlerSession, crawlerSession.id)}
                      {hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE']) &&
                        <Button
                          mini
                          disabled={!scripts || scripts.length === 0 || (crawlerSession.status !== 'READY' && crawlerSession.status !== 'FAILED' && crawlerSession.status !== 'PARTIALLY_FAILED')}
                          onClick={() => this.setState({ showCopyDialog: true })}
                          data-unique="btnCrawlerScriptsSaveTestCase"
                        >
                          <ShowIcon icon="copy" />
                          Copy Test Scripts into Test Set
                        </Button>
                      }
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3} noPadding>
                      <SimpleList
                        name="CrawlerScripts"
                        listData={scripts}
                        pageLoading={crawlerSessionProgressLoading}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={8} md={9} borderLeft>
                      <Switch>
                        <Route
                          path={historyView ? '/testdatawizards/crawlerprojects/view/:crawlerProjectId/history/:crawlerSessionId/scriptview/scripts/viewconvo/:crawlerSessionScriptId'
                            : '/testdatawizards/crawlerprojects/view/:crawlerProjectId/scriptview/scripts/viewconvo/:crawlerSessionScriptId'}
                          render={props => <CrawlerSessionConvo crawlerSession={crawlerSession}
                            key={'convo' + props.match.params.crawlerSessionScriptId}
                            historyView={historyView}
                            onRefetch={() => refetch()}
                            onSwitchToConvoClick={() => {
                              redirectToConvoEditorView(history, crawlerSession, historyView, props.match.params.crawlerSessionScriptId, 'SCRIPTING_TYPE_CONVO')
                              this.setState({ scriptSourceView: false })
                            }}
                            onSwitchToSourceClick={() => {
                              redirectToSourceEditorView(history, crawlerSession, historyView, props.match.params.crawlerSessionScriptId)
                              this.setState({ scriptSourceView: true })
                            }}
                            {...props} />}
                        />
                        <Route
                          path={historyView ? '/testdatawizards/crawlerprojects/view/:crawlerProjectId/history/:crawlerSessionId/scriptview/scripts/viewutterance/:crawlerSessionScriptId'
                            : '/testdatawizards/crawlerprojects/view/:crawlerProjectId/scriptview/scripts/viewutterance/:crawlerSessionScriptId'}
                          render={props => <CrawlerSessionUtterance crawlerSession={crawlerSession}
                            key={'utterance' + props.match.params.crawlerSessionScriptId}
                            historyView={historyView}
                            onRefetch={() => refetch()}
                            onSwitchToUtteranceClick={() => {
                              redirectToConvoEditorView(history, crawlerSession, historyView, props.match.params.crawlerSessionScriptId, 'SCRIPTING_TYPE_UTTERANCES')
                              this.setState({ scriptSourceView: false })
                            }}
                            onSwitchToSourceClick={() => {
                              redirectToSourceEditorView(history, crawlerSession, historyView, props.match.params.crawlerSessionScriptId)
                              this.setState({ scriptSourceView: true })
                            }}
                            {...props} />}
                        />
                        <Route
                          path={historyView ? '/testdatawizards/crawlerprojects/view/:crawlerProjectId/history/:crawlerSessionId/scriptview/scripts/viewscript/:crawlerSessionScriptId'
                            : '/testdatawizards/crawlerprojects/view/:crawlerProjectId/scriptview/scripts/viewscript/:crawlerSessionScriptId'}
                          render={props => <CrawlerSessionScript crawlerSession={crawlerSession}
                            key={'script' + props.match.params.crawlerSessionScriptId}
                            historyView={historyView}
                            onRefetch={() => refetch()}
                            onSwitchToEditorClick={(script) => {
                              redirectToConvoEditorView(history, crawlerSession, historyView, props.match.params.crawlerSessionScriptId, script.scriptType)
                              this.setState({ scriptSourceView: false })
                            }}
                            onSwitchToSourceClick={(script) => {
                              redirectToSourceEditorView(history, crawlerSession, historyView, props.match.params.crawlerSessionScriptId)
                              this.setState({ scriptSourceView: true })
                            }}
                            {...props} />}
                        />
                        <Route component={() => <Text>Please select an item in the menu on the right</Text>} />
                      </Switch>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            )
          }}
        </CrawlerSessionProgress>
      </GridItem>}
    </GridContainer>)
  }

  renderJobs(crawlerSession) {
    const { classes, user } = this.props

    return (
      <CrawlerSessionProgress crawlerSession={crawlerSession}>
        {({ crawlerSessionProgress }) => {
          return (
            <GridContainer>
              <GridItem xs={10} center middle largePadding>
                <Text header primary bold>{crawlerSessionProgress.jobs && crawlerSessionProgress.jobs.length} JOBS</Text>
                <Text primary padding>|</Text>
                <Text header info bold>{crawlerSessionProgress.jobs && crawlerSessionProgress.jobs.filter(j => j.status !== 'READY' && j.status !== 'FAILED').length} RUNNING</Text>
                <Text primary padding>|</Text>
                <Text icon="square" danger  inline contentCenter>&nbsp;</Text> <Text header bold>{crawlerSessionProgress.jobs && crawlerSessionProgress.jobs.filter(j => j.status === 'FAILED').length} FAILED</Text>
                <Text primary padding>|</Text>
                <Text icon="square" success inline contentCenter>&nbsp;</Text> <Text header bold>{crawlerSessionProgress.jobs && crawlerSessionProgress.jobs.filter(j => j.status === 'READY').length} READY</Text>
              </GridItem>
              <GridItem xs={2} right noPadding>
                <Tooltip title={'Download logs for each jobs merged into one log file.'}>
                  <Button link secondary data-unique={`btnCrawlerSessionDownloadAllJobLogs`} onClick={() => window.open(`${config.api.base}/testdatawizards/crawleralljoblogdata/${crawlerSession.id}`)}>
                    <ShowIcon icon="download" /> Download Logs
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={12}>
                <List component="nav" key="jobs">
                  {crawlerSessionProgress.jobs &&
                    crawlerSessionProgress.jobs.map(job => {
                      const onClick=() => {
                        if (hasPermission(user, 'CRAWLERSESSIONS_REPORTS')) {
                          const jobOpen = this.state.jobOpen
                          jobOpen[job.id] = !jobOpen[job.id]
                          this.setState({ jobOpen })
                        }
                      }
                      return (
                        <React.Fragment key={job.id}>
                          <ExpansionPanel expanded={this.state.jobOpen[job.id]}>
                            <ExpansionPanelSummary
                              onClick={() => {
                                onClick()
                              }}
                              onKeyDown={(e) => {
                                if (e.keyCode === 32){
                                  onClick()
                                }
                              }}
                            >
                              <ListItem

                                className={classes.crawlersessionlistitem}
                                key={job.id}
                              >
                                {job.status === 'READY' && <ListItemIcon className={classes.successText}><ShowIcon custom icon="success" /></ListItemIcon>}
                                {job.status === 'FAILED' && <ListItemIcon className={classes.dangerText}><ShowIcon custom icon="error" /></ListItemIcon>}
                                {job.status !== 'READY' && job.status !== 'FAILED' && <Text info><LoadingIndicator /></Text>}
                                {job.started && <ListItemText style={{ display: 'block', flex: 'unset' }}
                                  primary={
                                    <React.Fragment>
                                      {job.started &&
                                        <React.Fragment>Started: <DateFormat seconds>{job.started}</DateFormat></React.Fragment>}
                                    </React.Fragment>
                                  }
                                />}
                                {job.finished && <ListItemText style={{ display: 'block', flex: 'unset' }}
                                  primary={
                                    <React.Fragment>
                                      {job.finished &&
                                        <React.Fragment>Finished: <DateFormat seconds>{job.finished}</DateFormat></React.Fragment>}
                                    </React.Fragment>
                                  }
                                  secondary={
                                    <React.Fragment>

                                      Status: <Text icon="square" danger={job.status === 'FAILED'} success={job.status === 'READY'} info={job.status !== 'READY' && job.status !== 'FAILED'}  inline>&nbsp;</Text> <Text inline >
                                        {job.status || 'RUNNING'}
                                      </Text>
                                    </React.Fragment>
                                  }
                                  secondaryTypographyProps={{ component: 'span' }}
                                />}
                                {job.jobName && <ListItemText  style={{ display: 'block', flex: 'unset' }}
                                  primary={job.jobName}
                                />}
                              </ListItem>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <GridContainer nounset>
                                <GridItem xs={12}>
                                  <Query
                                    query={CRAWLERSESSIONJOB_LOGS_QUERY}
                                    variables={{ jobId: job.id, first: 26 }}
                                    fetchPolicy="network-only"
                                  >
                                    {({ loading, error, data, refetch }) => {
                                      if (error) {
                                        return <ErrorFormat err={error} />
                                      }
                                      if (loading) {
                                        return <LoadingIndicator large />
                                      }
                                      const rd = (data && data.crawlersessionjoblogs) || []
                                      return (
                                        <GridContainer>
                                          {job.err && (
                                            <GridItem xs={12}>
                                              <ErrorFormat err={job.err} suppress split />
                                            </GridItem>
                                          )}
                                          {rd && rd.length > 0 && <>
                                            <GridItem xs={12} right middle>
                                              <Button link secondary data-unique={`btnCrawlerSessionRefreshJobLogs_${job.id}`} onClick={() => refetch()}>
                                                <ShowIcon icon="redo" /> Refresh
                                              </Button> <Text primary>|</Text> <Button link secondary data-unique={`btnCrawlerSessionReadMoreJobLogs_${job.id}`} onClick={() => window.open(`${config.api.base}/testdatawizards/crawlerjoblog/${job.id}`, '_blank')}>
                                                <ShowIcon icon="eye" /> Full View
                                              </Button>
                                            </GridItem>
                                            <GridItem xs={12}>
                                              <AdvancedTable
                                                disableFooter
                                                disableHeader
                                                tableData={[...rd.slice(0, 25).map(l => {
                                                  const logLines = l.log ? l.log.split('\n') : []
                                                  return [
                                                    () =>
                                                      <Tooltip title={l.createdAt}>
                                                        <DateFormat seconds>
                                                          {l.createdAt}
                                                        </DateFormat>
                                                      </Tooltip>,
                                                    () => l.log &&
                                                      logLines.map((line, i) => (
                                                        <React.Fragment key={i}>
                                                          <Text pre linebreak>{line}</Text>
                                                          {i < logLines.length - 1 && (
                                                            <br />
                                                          )}
                                                        </React.Fragment>
                                                      ))
                                                  ]
                                                }), rd.length > 25 && [
                                                  () => '...',
                                                  () => null
                                                ]]} />
                                            </GridItem>
                                            {rd.length > 25 &&
                                              <GridItem xs={12} right middle>
                                                <Button link secondary data-unique={`btnCrawlerSessionRefreshJobLogs_${job.id}`} onClick={() => refetch()}>
                                                  <ShowIcon icon="redo" /> Refresh
                                                </Button> <Text primary>|</Text> <Button link secondary data-unique={`btnCrawlerSessionReadMoreJobLogs_${job.id}`} onClick={() => window.open(`${config.api.base}/testdatawizards/crawlerjoblog/${job.id}`, '_blank')}>
                                                  <ShowIcon icon="eye" /> Full View
                                                </Button>
                                              </GridItem>
                                            }
                                          </>}
                                        </GridContainer>
                                      )
                                    }}
                                  </Query>
                                </GridItem>
                              </GridContainer>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </React.Fragment>
                      )
                    })}
                </List>
              </GridItem>
            </GridContainer>
          )
        }}
      </CrawlerSessionProgress>
    )
  }

  renderCopyScriptsDialog(crawlersession) {
    const { setAlertSuccessMessage, history, user, license, namespace } = this.props
    const { showCopyIntoTestSetError } = this.state

    let openTestSet = false

    return (
      <Mutation
        mutation={CRAWLERSCRIPTS_COPY}
        onCompleted={data => {
          setAlertSuccessMessage('Test Scripts copied')
          this.setState({ showCopyDialog: false })
          if (openTestSet) {
            history.push(`/testsets/view/${data.copyCrawlerScriptsIntoTestSet.testSetId}`)
          }
        }}
        onError={error => {
          this.setState({ showCopyIntoTestSetError: error})
        }}
        refetchQueries={({ data }) => {
          return [
            ...RefetchTestProjectQueries(),
            ...RefetchTestSetQueries(data.copyCrawlerScriptsIntoTestSet.testSetId, license)
          ]
        }}
      >
        {(copyScripts, { loading }) => (
          <Form
            onSubmit={values => {
              copyScripts({
                variables: {
                  chatbotId: crawlersession.chatbot ? crawlersession.chatbot.id : null,
                  crawlerSessionId: crawlersession.id,
                  namespace: namespace.selected ? namespace.selected.name : undefined,
                  testSetId: values.newTestSetName ? null : values.testSetId,
                  newTestSetName: values.newTestSetName,
                  createTestProject: !!(values.newTestSetName && values.createTestProject),
                  importMode: values.importMode
                },
              })
            }}
            initialValues={{
              testSetId: 'new',
              importMode: 'BACKUP'
            }}
            render={({ handleSubmit, values, form: { change } }) => (
              <ConfirmationDialog
                open={this.state.showCopyDialog}
                isWorking={loading}
                onCancel={() => this.setState({ showCopyDialog: false })}
                okText="Copy Test Scripts"
                onOk={() => {
                  openTestSet = false
                  handleSubmit()
                }}
                showError={showCopyIntoTestSetError && `Copying Test Scripts failed: ${extractErrorMessage(showCopyIntoTestSetError, true)}`}
                clearError={() => this.setState({ showCopyIntoTestSetError: null })}
                extraButton={
                  <Button data-unique="btnCopyAndOpenTestSet"
                    secondary
                    disabled={loading}
                    onClick={() => {
                      openTestSet = true
                      handleSubmit()
                    }}>
                    {loading && <LoadingIndicator alt />}
                    Copy Test Scripts and Open Test Set
                  </Button>
                }
                title="Copy Scripts into Test Set"
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Query query={TESTSETS_DROPDOWN_QUERY}>
                        {({ loading, error, data }) => {
                          if (loading) {
                            return <LoadingIndicator large />
                          }
                          if (error) {
                            return <ErrorFormat err={error} />
                          }
                          if (values.testSetId === '') {
                            change('testSetId', 'new')
                          }

                          const items = []
                          if (hasPermission(user, 'TESTSETS_CREATE')) {
                            items.push({
                              key: 'new',
                              label: '--- Register new Test Set ---'
                            })
                          }
                          safeGetNamespaceFilteredList(data.testsets, this.props.namespace).forEach(a => items.push({
                            key: a.id,
                            label: a.name
                          }))

                          return (
                            <Field
                              name="testSetId"
                              component={renderSelect}
                              label="Select Test Set"
                              disabled={!!values.newTestSetName}
                              data-unique="selCrawlerScriptsTestSetId"
                              items={items}
                            />
                          )
                        }}
                      </Query>
                    </GridItem>
                    {hasPermission(user, 'TESTSETS_CREATE') && values.testSetId === 'new' && <React.Fragment>
                      <GridItem xs={12} sm={8}>
                        <Field
                          name="newTestSetName"
                          component={renderTextField}
                          label="New Test Set Name"
                          validate={required}
                          data-unique="txtCrawlerScriptsNewTestSetName"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} middle>
                        <Field
                          name="createTestProject"
                          component={renderCheckbox}
                          label="New Test Project"
                          type="checkbox"
                          disabled={!!(!hasPermission(user, 'TESTPROJECTS_CREATE') || !values.newTestSetName)}
                          data-unique="chkCrawlerScriptsCreateTestProject"
                        />
                      </GridItem>
                    </React.Fragment>}
                    {values.testSetId && values.testSetId !== 'new' && <React.Fragment>
                      <GridItem xs={12}>
                        <Field
                          name="importMode"
                          component={renderSelect}
                          label="Action for convos and utterance lists"
                          validate={required}
                          data-unique="selCrawlerScriptsAction"
                          items={[
                            {
                              key: 'DUMP',
                              label: 'Dump all convos and utterance lists from test set repository before copying'
                            },
                            {
                              key: 'SKIP',
                              label: 'Only copy new convos and utterance lists to test set repository'
                            },
                            {
                              key: 'OVERWRITE',
                              label: 'Overwrite convos and utterance lists'
                            },
                            {
                              key: 'BACKUP',
                              label: 'Backup convos and utterance lists in test set repository before copying'
                            }
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Query query={TESTSETSOURCES_SCRIPTS_QUERY} variables={{ testSetId: values.testSetId }}>
                          {({ loading, error, data }) => {
                            if (loading) {
                              return <LoadingIndicator large />
                            }
                            if (error) {
                              return <ErrorFormat err={error} />
                            }
                            data.testsetscripts = data.testsetscripts || []

                            let dumpScripts = []
                            let newScripts = []
                            let overwriteScripts = []
                            let skipScripts = []

                            if (values.importMode === 'DUMP') {
                              dumpScripts = data.testsetscripts.filter(s => s.scriptType === 'SCRIPTING_TYPE_CONVO' || s.scriptType === 'SCRIPTING_TYPE_UTTERANCES').map(s => s.name)
                              newScripts = crawlersession.scripts.map(s => s.name)
                            } else if (values.importMode === 'SKIP') {
                              newScripts = crawlersession.scripts.filter(s => data.testsetscripts.findIndex(es => s.name === es.name && s.scriptType === es.scriptType) < 0).map(s => s.name)
                              skipScripts = crawlersession.scripts.filter(s => data.testsetscripts.findIndex(es => s.name === es.name && s.scriptType === es.scriptType) >= 0).map(s => s.name)
                            } else if (values.importMode === 'OVERWRITE') {
                              overwriteScripts = crawlersession.scripts.filter(s => data.testsetscripts.findIndex(es => s.name === es.name && s.scriptType === es.scriptType) >= 0).map(s => s.name)
                              newScripts = crawlersession.scripts.filter(s => data.testsetscripts.findIndex(es => s.name === es.name && s.scriptType === es.scriptType) < 0).map(s => s.name)
                            } else if (values.importMode === 'BACKUP') {
                              newScripts = crawlersession.scripts.map(s => s.name)
                            }

                            return <>
                              {dumpScripts.length > 0 ? <Tooltip title={dumpScripts.join(' ')}><Text danger>Dumping {dumpScripts.length} script(s)</Text></Tooltip> : <Text muted>Dumping no scripts</Text>}
                              {newScripts.length > 0 ? <Tooltip title={newScripts.join(' ')}><Text success>Creating {newScripts.length} new script(s)</Text></Tooltip> : <Text muted>Creating no scripts</Text>}
                              {overwriteScripts.length > 0 ? <Tooltip title={overwriteScripts.join(' ')}><Text warning>Overwriting {overwriteScripts.length} script(s)</Text></Tooltip> : <Text muted>Overwriting no scripts</Text>}
                              {skipScripts.length > 0 ? <Tooltip title={skipScripts.join(' ')}><Text warning>Skipping {skipScripts.length} script(s)</Text></Tooltip> : <Text muted>Skipping no scripts</Text>}
                            </>
                          }}
                        </Query>
                      </GridItem>
                    </React.Fragment>}
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  renderFlowViewTab(crawlerProject, crawlerSession, historyView) {
    return (
      <Query query={CRAWLERSESSION_TREE_QUERY}
        variables={{ id: crawlerSession.id }}>
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <LoadingIndicator box large />
          }
          if (error) {
            return <ErrorFormat err={error} />
          }
          const tree = data.crawlersessiontree && JSON.parse(data.crawlersessiontree)

          return (<GridContainer>
            <GridItem xs={12}>
              {tree && tree.err && <ErrorFormat err={tree.err} />}
              {tree && !tree.err && <CrawlerResultTree tree={tree} crawlerSession={crawlerSession} crawlerProject={crawlerProject} refetch={refetch} historyView={historyView} onRefetch={() => this.setState({ scriptViewProgressKey: this.state.scriptViewProgressKey + 1 })} />}
            </GridItem>
          </GridContainer>)
        }}
      </Query>
    )
  }

  renderDashboardView(crawlerSession, crawlerProject, historyView) {
    const { user, history, classes } = this.props

    let chartData = {}
    if (crawlerSession && crawlerSession.chartData) {
      chartData = JSON.parse(crawlerSession.chartData)
    }

    return (<GridContainer>
      <GridItem xs={12} right middle smallPadding smallMarginRight>
        {!historyView && hasPermission(user, 'CRAWLERSESSIONS_CREATE') &&
          <StartCrawlerSession
            crawlerSession={crawlerSession}
            crawlerProject={crawlerProject}
            history={history}
            onStarting={() => this.setState({successConvos: true, warningConvos: true, failedConvos: true, utterances: true})}
            redirectUrl={`/testdatawizards/crawlerprojects/view/${crawlerProject.id}/scriptView`}
          />
        }
      </GridItem>
      <GridItem xs={12}>
        <ExpansionPanel expanded={this.state.dashboardExpanded}>
          <ExpansionPanelSummary onClick={() => this.setState({ dashboardExpanded: !this.state.dashboardExpanded })}>
            {crawlerSession &&
              <Text header>
                {crawlerSession.name}
              </Text>
            }
            {!crawlerSession && crawlerProject &&
              <Text subheader>
                <InfoIcon />
                After set all the required values in the settings you can start your first crawler session
              </Text>
            }
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <GridContainer nounset>
              <GridItem md={12} lg={8}>
                <GridContainer>
                  <GridItem md={6} lg grid>
                    <Card>
                      <CardBody smallPadding>
                        <div className={classes.overviewCard}><ShowIcon custom icon="convo" /></div>
                        <Text md regular>Detected Conversations</Text>
                        <Text extralg bold>{chartData.convoCount || 0}</Text>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem md={6} lg grid>
                    <Card>
                      <CardBody smallPadding>
                        <div className={classes.overviewCard}><ShowIcon custom icon="warning" /></div>
                        <Text md regular warning={chartData.warningConvoCount > 0}>Detected Warnings</Text>
                        {chartData.warningConvoCount > 0 && <Text extralg bold warning>{chartData.warningConvoCount}</Text>}
                        {(chartData.warningConvoCount || 0) === 0 && <Text extralg bold>0</Text>}
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem md={6} lg grid>
                    <Card>
                      <CardBody smallPadding>
                        <div className={classes.overviewCard}><ShowIcon custom icon="error" /></div>
                        <Text md regular >Detected Failures</Text>
                        {chartData.failedConvoCount > 0 && <Text extralg bold danger>{chartData.failedConvoCount}</Text>}
                        {(chartData.failedConvoCount || 0) === 0 && <Text extralg bold>0</Text>}
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem md={6} lg grid>
                    <Card>
                      <CardBody smallPadding>
                        <div className={classes.overviewCard}><ShowIcon custom icon="utterance" /></div>
                        <Text md regular>Detected Utterances</Text>
                        <Text extralg bold>{chartData.utteranceCount || 0}</Text>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </GridItem>
      {crawlerProject && crawlerProject.chatbot && crawlerProject.chatbot.id &&
        <GridItem xs={12}>
          <ObjectChips singleChip dataUniquePrefix="btnTestSession" chatbot={crawlerProject.chatbot} />
        </GridItem>
      }
    </GridContainer>)
  }

  renderParametersTab(crawlerSession) {
    return (
      <GridContainer>
        <GridItem xs={4} grid>
          <Card>
            <CardBody>
              <GridContainer alignItems="center" justify="center" autoHeight>
                <GridItem xs={4} largePadding center>
                  <img src={isDarkmode() ? MaxConversationStepsDarkmode : MaxConversationSteps} alt="Maximum Conversation Depth" />
                </GridItem>
                <GridItem xs={8}>
                  <Text primary>
                    Maximum Conversation Depth<br />
                    <strong>{crawlerSession.depth}</strong>
                  </Text>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {crawlerSession.dynamicWelcomeMessages &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? NumberOfWelcomeMessagesDarkmode : NumberOfWelcomeMessages} alt="Dynamic Welcome Messages" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Dynamic Welcome Messages<br />
                      <strong>Yes</strong>
                    </Text>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        {!crawlerSession.dynamicWelcomeMessages && crawlerSession.numberOfWelcomeMessages > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? NumberOfWelcomeMessagesDarkmode : NumberOfWelcomeMessages} alt="Number of Welcome Messages" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Number of Welcome Messages<br />
                      <strong>{crawlerSession.numberOfWelcomeMessages}</strong>
                    </Text>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        <GridItem xs={4} grid>
          <Card>
            <CardBody>
              <GridContainer alignItems="center" justify="center" autoHeight>
                <GridItem xs={4} largePadding center>
                  <img src={isDarkmode() ? WaitForBotDarkmode : WaitForBot} alt="Wait for Chatbot Messages" />
                </GridItem>
                <GridItem xs={8}>
                  <Text primary>
                    Wait for Chatbot Messages<br />
                    <strong>{crawlerSession.waitForPrompt} ms</strong>
                  </Text>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {crawlerSession.entryPoints && crawlerSession.entryPoints.length > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? ConversationStartMessagesDarkmode : ConversationStartMessages} alt="Conversation Entrypoints" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Conversation Entrypoints
                    </Text>
                    <React.Fragment>
                      {
                        crawlerSession.entryPoints && crawlerSession.entryPoints.map(v => (
                          <Chip key={v} label={v} />
                        ))
                      }
                    </React.Fragment>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        {crawlerSession.exitCriteria && crawlerSession.exitCriteria.length > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? ExitCriteriaDarkmode : ExitCriteria} alt="Exit Criteria" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Exit criteria
                    </Text>
                    <React.Fragment>
                      {
                        crawlerSession.exitCriteria && crawlerSession.exitCriteria.map(v => (
                          <Chip key={v} label={v} />
                        ))
                      }
                    </React.Fragment>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        {crawlerSession.ignoreButtons && crawlerSession.ignoreButtons.length > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? IgnoreButtonsDarkmode : IgnoreButtons} alt="Ignore Buttons" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Ignore buttons
                    </Text>
                    <React.Fragment>
                      {
                        crawlerSession.ignoreButtons && crawlerSession.ignoreButtons.map(v => (
                          <Chip key={v} label={v} />
                        ))
                      }
                    </React.Fragment>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        <GridItem xs={4} grid>
          <Card>
            <CardBody>
              <GridContainer alignItems="center" justify="center" autoHeight>
                <GridItem xs={4} largePadding center>
                  <img src={isDarkmode() ? GenerateUtterancesDarkmode : GenerateUtterances} alt="Generate utterances" />
                </GridItem>
                <GridItem xs={8}>
                  <Text primary>
                    Generate utterances<br />
                    <strong>{!!crawlerSession.generateUtterances ? 'Yes' : 'No'}</strong>
                  </Text>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={4} grid>
          <Card>
            <CardBody>
              <GridContainer alignItems="center" justify="center" autoHeight>
                <GridItem xs={4} largePadding center>
                  <img src={isDarkmode() ? MergeUtterancesDarkmode : MergeUtterances} alt="Merge utterances" />
                </GridItem>
                <GridItem xs={8}>
                  <Text primary>
                    Merge utterances<br />
                    <strong>{!!crawlerSession.mergeUtterances ? 'Yes' : 'No'}</strong>
                  </Text>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }


  renderTabs(crawlerProject, crawlerSession, crawlerSessionData, historyView) {
    const { history } = this.props
    return (
      <CustomTabs
        name={`tabCrawlerProject_${crawlerProject.id}`}
        tabs={[
          {
            tabName: 'Overview',
            tabIcon: <ShowIcon icon="tasks" />,
            tabContent: this.renderCrawlerScriptsViewTab(crawlerProject, crawlerSession, crawlerSessionData, historyView),
            locationPrefix: historyView ?
              `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/history/${crawlerSession.id}/scriptview` :
              `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/scriptview`,
            dataUnique: 'tabCrawlerSessionOverview'
          },
          crawlerSession ?
            {
              tabName: 'Flow view',
              tabIcon: <ShowIcon icon="project-diagram" />,
              tabContent: this.renderFlowViewTab(crawlerProject, crawlerSession, historyView),
              locationPrefix: historyView ?
                `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/history/${crawlerSession.id}/flowview` :
                `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/flowview`,
              dataUnique: 'tabCrawlerSessionFlowView'
            } : null,
          crawlerSession ?
            {
              tabName: 'Parameters',
              tabIcon: <ShowIcon icon="wrench" />,
              tabContent: this.renderParametersTab(crawlerSession),
              locationPrefix: historyView ?
                `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/history/${crawlerSession.id}/parameters` :
                `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/parameters`,
              dataUnique: 'tabCrawlerSessionParameters'
            } : null,
          crawlerSession ?
            {
              tabName: 'Session Jobs',
              tabIcon: <ShowIcon icon="cogs" />,
              tabContent: this.renderJobs(crawlerSession, crawlerSessionData),
              locationPrefix: historyView ?
                `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/history/${crawlerSession.id}/jobs` :
                `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/jobs`,
              dataUnique: 'tabCrawlerSessionJobs'
            } : null,
          !historyView ? {
            tabName: 'Configuration',
            tabRight: true,
            tabIcon: <ShowIcon icon="wrench" />,
            tabContent: <CrawlerSettingsForm crawlerProject={crawlerProject} crawlerSession={crawlerSession} history={history} />,
            locationPrefix: `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/settings`,
            dataUnique: 'tabCrawlerSessionSettings'
          } : null
        ].filter(t => t)}
      />
    )
  }

  render() {
    const { crawlerSessionDataLatest, crawlerSessionDataHistory, crawlerProjectData, classes, history, location, match } = this.props
    const historyView = location.pathname.includes('history')
    const crawlerSessionData = historyView ? crawlerSessionDataHistory : crawlerSessionDataLatest
    const crawlerProject = crawlerProjectData && crawlerProjectData.crawlerproject
    let crawlerSession
    if (historyView) {
      crawlerSession = (crawlerSessionDataHistory && crawlerSessionDataHistory.crawlersession) || undefined
      putRecentListEntry({ name: (crawlerProject && crawlerProject.name && `${crawlerProject.name} - History`) || 'CrawlerProject - History', url: `/testdatawizards/crawlerprojects/view/${match.params.projectId}/history`, type: 'CrawlerProject' })
    } else {
      crawlerSession = (crawlerSessionDataLatest && crawlerSessionDataLatest.crawlersessions && crawlerSessionDataLatest.crawlersessions[0]) || undefined
      putRecentListEntry({ name: (crawlerProject && crawlerProject.name) || 'CrawlerProject', url: `/testdatawizards/crawlerprojects/view/${match.params.projectId}`, type: 'CrawlerProject' })
    }


    return (
      <GridContainer>
        <Helmet>
          <title>Crawler Project ...</title>
        </Helmet>
        <GridItem xs={12} sm={12} md={12}>
          {(!crawlerSessionData || crawlerSessionData.loading || !crawlerProjectData || crawlerProjectData.loading) &&
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>
                  Crawler Project
                </h4>
                <p className={classes.cardCategoryWhite}>Loading ...</p>
              </CardHeader>
              <CardBody>
                <LoadingIndicator />
              </CardBody>
            </Card>
          }

          {crawlerSessionData && crawlerSessionData.error && <ErrorFormat err={crawlerSessionData.error} />}
          {crawlerProjectData && crawlerProjectData.error && <ErrorFormat err={crawlerProjectData.error} />}

          {crawlerProject && crawlerSessionData && !crawlerSessionData.error && !crawlerSessionData.loading &&
            <React.Fragment>
              <GridContainer>
                <GridItem xs={9}>
                  <Helmet>
                    <title>
                      {crawlerProject && crawlerProject.name}
                    </title>
                  </Helmet>
                </GridItem>
                <GridItem right xs={3}>
                  <SwitchButton
                    leftLabel="Latest Result"
                    rightLabel="History"
                    checked={historyView ? 'right' : 'left'}
                    onRightClick={() => {
                      history.push(`/testdatawizards/crawlerprojects/view/${crawlerProject.id}/history`)
                    }}
                    onLeftClick={() => {
                      history.push(`/testdatawizards/crawlerprojects/view/${crawlerProject.id}`)
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  {this.renderTabs(crawlerProject, crawlerSession, crawlerSessionData, historyView)}
                </GridItem>
              </GridContainer>
            </React.Fragment>
          }
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(crawlersessionsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace }),
    { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
  ),
  graphql(CRAWLERPROJECT_QUERY, {
    props: ({ data }) => ({
      crawlerProjectData: data,
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.projectId,
        }
      }
    },
    skip: (props) => !props.match.params.projectId
  }),
  graphql(CRAWLERSESSION_QUERY, {
    props: ({ data }) => ({
      crawlerSessionDataHistory: data,
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.id,
        }
      }
    },
    skip: (props) => !props.match.params.id
  }),
  graphql(CRAWLERSESSIONS_QUERY, {
    props: ({ data }) => ({
      crawlerSessionDataLatest: data,
    }),
    options: (props) => {
      return {
        variables: {
          crawlerProjectId: props.match.params.projectId,
          skip: 0,
          first: 1,
          orderBy: 'createdAt_DESC'
        }
      }
    },
    skip: (props) => !props.match.params.projectId
  }),
  graphql(AGENTS_DROPDOWN_QUERY, {
    skip: (props) => props.license.shared,
    options: {
      fetchPolicy: 'network-only'
    },
    props: ({ data }) => ({
      agentsData: data,
    }),
  }),
  withApollo
)(CrawlerSession)
