import React from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import { NavLink } from 'react-router-dom'
import { v1 } from 'uuid'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import QueryStatus from 'components/Info/QueryStatus'
import Avatar from '@material-ui/core/Avatar'
// apollo
import { Mutation, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import AudioButton from 'components/Button/AudioButton'

import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import MediaSelectionDialog from 'components/Dialog/MediaSelectionDialog.jsx'
import DropdownButton from 'components/Button/DropdownButton'
import Convo from 'components/Convo/Convo.jsx'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { getConnector } from 'actions/settings'
import { CustomTextField, renderCheckbox, renderSelect, renderTextField, required, composeValidators } from 'components/Form/Form'
import { downloadfileb64 } from 'helper/downloadHelper'
import { recordAudio, recordAudioSupported } from 'helper/browserHelper'
//import Divider from 'components/Divider/Divider.js'
import ShowIcon from 'components/Icon/ShowIcon'
import Text from 'components/Typography/Text'

import config from 'config'

import chatbotsStyle from 'assets/jss/material-dashboard-react/views/chatbotsStyle.jsx'

import { CHATBOT_QUERY, SPEECH_RUN_EFFECT } from './gql'
import { TESTPROJECT_QUERY, RefetchTestProjectQueries } from '../TestProjects/gql'
import { TESTSETS_DROPDOWN_QUERY, RefetchTestSetQueries } from '../TestSets/gql'
import { DEVICESETS_QUERY } from '../Settings/gql'

import { hasPermission, hasAnyPermission } from 'botium-box-shared/security/permissions'
import { isLicenseDeviceSetsSupported } from 'botium-box-shared/security/licenseSupport'
import { validateTestProjectCodeUnique } from '../TestProjects/validators'
import { validateConvoNameUnique } from '../TestSets/validators'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Chip from 'components/Chip/Chip'
import { OnChange } from 'react-final-form-listeners'
import MessageBox from 'components/Info/MessageBox'

import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'

import AvatarImage from 'components/Avatar/AvatarImage'
import StatsText from 'components/Stats/StatsText.jsx'
import Divider from 'components/Divider/Divider'

import Tooltip from 'components/Tooltip/Tooltip'
import { safeGetNamespaceFilteredList } from '../helper'

const START_CHATBOT = gql`
  mutation LiveChatStartBot($chatbotId: ID!, $conversationId: String, $deviceId: ID) {
    liveChatStartBot(chatbotId: $chatbotId, conversationId: $conversationId, deviceId: $deviceId)
  }
`
const SENDTO_CHATBOT = gql`
  mutation LiveChatSendToBot($chatbotId: ID!, $conversationId: String!, $convoStep: String!) {
    liveChatSendToBot(chatbotId: $chatbotId, conversationId: $conversationId, convoStep: $convoStep)
  }
`
const STOP_CHATBOT = gql`
  mutation LiveChatStopBot($chatbotId: ID!, $conversationId: String!) {
    liveChatStopBot(chatbotId: $chatbotId, conversationId: $conversationId)
  }
`
const CONVOSTEPS_SAVE = gql`
  mutation LiveChatSaveConvoSteps(
    $chatbotId: ID!
    $conversationId: String!
    $namespace: String,
    $testSetId: ID
    $testProjectId: ID
    $newTestSetName: String
    $mediaBaseDir: String
    $testCaseName: String!
    $splitToConvoAndUtterancesMe: Boolean!
    $splitToConvoAndUtterancesBot: Boolean!
    $types: [String!]!
    $newTestProjectType: TestProjectType
    $deviceSetId: ID
  ) {
    liveChatSaveConvoSteps(
      chatbotId: $chatbotId
      conversationId: $conversationId
      namespace: $namespace
      testSetId: $testSetId
      testProjectId: $testProjectId
      newTestSetName: $newTestSetName
      mediaBaseDir: $mediaBaseDir
      testCaseName: $testCaseName
      splitToConvoAndUtterancesMe: $splitToConvoAndUtterancesMe
      splitToConvoAndUtterancesBot: $splitToConvoAndUtterancesBot
      types: $types
      newTestProjectType: $newTestProjectType
      deviceSetId: $deviceSetId
    )
  }
`

const EXPORT_TYPES = {
  text: 'Text Content',
  buttons: 'Buttons and Quick-Replies',
  media: 'Media Attachments (Pictures and Voice)',
  cards: 'Carousel Structure and Headers',
  cards_content: 'Carousel Card Content',
  forms: 'Form Elements (Input Fields)',
  intents: 'Recognized NLP Intents',
  entities: 'Recognized NLP Entities'
}

const EXPORT_TYPES_DEFAULT = Object.keys(EXPORT_TYPES).filter(e => e !== 'cards_content')

class LiveChat extends React.Component {
  constructor(props) {
    super(props)

    this.recordingInterval = null

    this.state = {
      conversationId: null,
      showSelectedTechnology: true,
      status: 'DISCONNECTED',
      textToSend: '',
      showSaveDialog: false,
      showSaveDialogErr: null,
      showMediaSelectionDialog: false,
      showMediaSelectionErr: null,
      mediaItems: [],
      mediaItemsSource: null,
      isRecording: false,
      recordingSecond: 0,
      showSameTestCaseName: false,
      emptySaveTestCaseForm: {
        testCaseName: '',
        newTestSetName: `${props.chatbot.name} - Test Set`,
        testSetId: (props.testproject && props.testproject.testSets.length > 0) ? props.testproject.testSets[0].id : 'new',
        splitToConvoAndUtterancesMe: false,
        splitToConvoAndUtterancesBot: false,
        newTestProjectType: props.testProjectId ? 'dontcreate' : 'regressionTesting',
        types: EXPORT_TYPES_DEFAULT.reduce((p, t) => ({ [t]: true, ...p }), {})
      }
    }
    this.recorder = null
    this.liveChatAreaRef = React.createRef()
    this.messageTextInputRef = React.createRef()
    this.componentCleanup = this.componentCleanup.bind(this)
  }

  async initRecorder() {
    this.recorder = await recordAudio()
  }

  getContainerMode() {
    const { chatbot } = this.props
    return chatbot.containermode
  }

  componentWillUnmount() {

    this.componentCleanup()
    window.removeEventListener('beforeunload', this.componentCleanup)

  }

  async componentDidMount() {

    const { getConnector, setAlertErrorMessage, setAlertSuccessMessage, chatbot } = this.props

    const containerMode = this.getContainerMode()
    const connector = getConnector(containerMode)
    const hasDeviceSet = !!(connector && connector.features && connector.features.deviceSetCapability)
    if (hasDeviceSet) return

    const conversationId = v1()

    const { mutateStartChatbot } = this.props
    this.setState({ conversationId, status: 'CONNECTING' })
    try {
      const response = await mutateStartChatbot({
        variables: { chatbotId: chatbot.id, conversationId },
      })
      setAlertSuccessMessage('Chatbot connected')
      this.setState({ conversationId: response.data.liveChatStartBot, status: 'CONNECTED' })
    } catch (error) {
      setAlertErrorMessage(`Chatbot connection failed`, error)
      this.setState({ conversationId, status: 'DISCONNECTED' })
    }

    window.addEventListener('beforeunload', this.componentCleanup)

    // new PerfectScrollbar(this.liveChatAreaRef.current)
  }

  async componentCleanup() {

    const { mutateStopChatbot, chatbot } = this.props
    const { conversationId, status } = this.state

    if (!conversationId) return
    if (status === 'CANCELLED') return

    mutateStopChatbot({
      variables: {
        chatbotId: chatbot.id,
        conversationId
      }
    })

    delete this.recorder
  }



  scrollChatBottom() {
    const scroller = this.liveChatAreaRef.current
    if (scroller) {
      if (scroller.scrollTo) {
        scroller.scrollTo({
          top: scroller.scrollHeight,
          behavior: 'smooth'
        })
      } else {
        scroller.scrollTop = scroller.scrollHeight
      }
    }
  }

  renderChatWindow() {
    const { setAlertErrorMessage, chatbot } = this.props
    const { classes, settings, getConnector, mutateSpeechRunConversion } = this.props
    const containerMode = this.getContainerMode()

    const audioInputSupport = () => {
      const attachmentSupportConnectors = settings.availableconnectors.filter(c => {
        return (c.features && c.features.audioInput)
      }).map(c => c.name)

      return attachmentSupportConnectors.includes(containerMode)
    }

    const attachmentSupport = () => {
      const attachmentSupportConnectors = settings.availableconnectors.filter(c => {
        return (c.features && c.features.sendAttachments)
      }).map(c => c.name)

      return attachmentSupportConnectors.includes(containerMode)
    }

    const supportsAudioInput = audioInputSupport()
    const supportsSendingAttachments = attachmentSupport()


    const filterSupportedFileExtensions = () => {
      const connectorData = getConnector(containerMode)

      if (connectorData && connectorData.features && connectorData.features.supportedFileExtensions) {
        return connectorData.features.supportedFileExtensions
      }
      return null
    }

    return (
      <Mutation
        mutation={SENDTO_CHATBOT}
        onCompleted={data => {
          this.setState({ textToSend: '', mediaItems: [], mediaItemsSource: null })
          this.messageTextInputRef && this.messageTextInputRef.current && this.messageTextInputRef.current.focus()
        }}
        onError={error => {
          setAlertErrorMessage(`Chatbot message failed`, error)
          this.messageTextInputRef && this.messageTextInputRef.current && this.messageTextInputRef.current.focus()
        }}
      >
        {(sendtoChatbot, { loading, error }) => {
          const handleSubmit = e => {
            if (e) e.preventDefault()
            if (!this.state.conversationId) return

            sendtoChatbot({
              variables: {
                chatbotId: chatbot.id,
                conversationId: this.state.conversationId,
                convoStep: JSON.stringify({
                  sender: 'me',
                  media: this.state.mediaItems || [],
                  messageText: this.state.textToSend || '',

                }),
              },
            })


          }
          const handleButtonClick = (e, button) => {
            e.preventDefault()

            if (button.payload && _.isString(button.payload) && (button.payload.startsWith('http://') || button.payload.startsWith('https://'))) {
              window.open(button.payload, '_blank')
            } else {
              if (!this.state.conversationId) return

              sendtoChatbot({
                variables: {
                  chatbotId: chatbot.id,
                  conversationId: this.state.conversationId,
                  convoStep: JSON.stringify({
                    sender: 'me',
                    messageText: button.payload || button.text,
                    buttons: [button]
                  }),
                },
              })
            }
          }

          const handleInternalFormSubmit = ({ data, text }) => {

            sendtoChatbot({
              variables: {
                chatbotId: chatbot.id,
                conversationId: this.state.conversationId,
                convoStep: JSON.stringify({
                  sender: 'me',
                  messageText: text,
                  buttons: [{ text, payload: text }],
                  forms: !data ? [] : Object.entries(data).map(([name, value]) => ({ name, value })).filter(entry => entry.name !== 'id')
                }),
              },
            })
          }

          return (<>
            <GridContainer>
              <GridItem xs={12}>
                <div className={classes.liveChatArea} ref={this.liveChatAreaRef} id="liveChatArea">
                  <Convo
                    subscribeToConversationId={this.state.conversationId}
                    onSubscriptionReceivedData={({ event, err, convoStep }) => {
                      if (event === 'DISCONNECTED') {
                        this.setState({ status: 'DISCONNECTED', showSaveDialog: false, showMediaSelectionDialog: false, showMediaSelectionErr: null })
                      }
                      if (err) {
                        setAlertErrorMessage(err)
                      } else if (convoStep) {
                        this.scrollChatBottom()
                        if (!this.state.emptySaveTestCaseForm.testCaseName) {
                          if (_.isString(convoStep)) convoStep = JSON.parse(convoStep)
                          if (convoStep.sender === 'me' && convoStep.messageText) {
                            this.setState({
                              emptySaveTestCaseForm: {
                                ...this.state.emptySaveTestCaseForm,
                                testCaseName: `TC ${convoStep.messageText}`
                              }
                            })
                          }
                        }
                      }
                    }}
                    onButtonClick={handleButtonClick}
                    onSubmit={handleInternalFormSubmit}
                    allowHtmlDisplay={chatbot.allowHtmlDisplay}
                    chatbotId={chatbot.id}
                  />
                </div>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={8} middle>
                    <CustomTextField
                      fullWidth
                      //disableBorder
                      className={classes.CustomTextField}
                      disabled={loading || !this.state.conversationId || this.state.status !== 'CONNECTED'}
                      margin="dense"
                      input={{
                        name: 'messageText',
                        placeholder: 'Text Message',
                        value: this.state.textToSend || '',
                        onChange: ({ target: { value } }) => this.setState({ textToSend: value }),
                        onKeyPress: (e) => { !e.shiftKey && e.key === 'Enter' && handleSubmit(e) },
                        ref: this.messageTextInputRef

                      }}
                      data-unique="txtLiveChatMessageText"
                      endAdornment={<>
                        {this.state.textToSend && <Tooltip title="Clear" >
                          <Button justIcon dense aria-label="Clear" data-unique="btnLiveChatMessageClear" onClick={() => this.setState({ textToSend: '' })}>
                            <ShowIcon icon="times" />
                          </Button>
                        </Tooltip>}
                      </>}
                    />
                  </GridItem>
                  <GridItem xs={4} middle>
                    <GridContainer fullWidth>
                      <GridItem md={4} right middle>
                        {this.state.isRecording ?
                          <Text className={classes.timePosition}>
                            {`${Math.trunc(this.state.recordingSecond / 60).toString().padStart(2, '0')}:${(this.state.recordingSecond % 60).toString().padStart(2, '0')}`}
                          </Text> : ''
                        }
                      </GridItem>
                      <GridItem md={3} middle>
                        {(supportsAudioInput || supportsSendingAttachments) &&
                          <Tooltip title={!this.state.isRecording ? 'Record Audio' : 'stop'} placement="bottom">
                            <Button
                              aria-label="Record Audio"
                              disabled={!recordAudioSupported() || loading || !this.state.conversationId || this.state.status !== 'CONNECTED' || this.state.mediaItemsSource === 'attachment'}
                              onClick={async () => {

                                if (!this.state.isRecording) {
                                  this.setState({ recordingSecond: 0 })
                                  this.recordingInterval = setInterval(() => {
                                    this.setState({ recordingSecond: this.state.recordingSecond + 1 })
                                  }, 1000)

                                  await this.initRecorder()
                                  this.recorder.start()
                                  this.setState({
                                    isRecording: true,
                                    mediaItems: [],
                                    mediaItemsSource: 'record'
                                  })
                                } else {
                                  const data = await this.recorder.stop()
                                  clearInterval(this.recordingInterval)
                                  try {
                                    const response = await mutateSpeechRunConversion({
                                      variables: {
                                        convData: {
                                          base64: data.base64,
                                          mimeType: data.mimeType,
                                          effects: ['WEBMTOMONOWAV']
                                        }
                                      }
                                    })
                                    console.log('info', response)
                                    this.setState({
                                      isRecording: false,
                                      mediaItems: [{
                                        buffer: 'data:audio/wav;base64,' + response.data.speechRunEffect,
                                        mimeType: 'audio/wav'
                                      }]
                                    })
                                  } catch (error) {
                                    setAlertErrorMessage(`Conversion failed`, error)
                                  }
                                }
                              }}
                              title={'Record Audio'}
                              data-unique="btnLiveChatRecordAudio"
                              justIcon small dense>
                              {!this.state.isRecording && <ShowIcon icon="microphone" />}
                              {this.state.isRecording && <ShowIcon icon="stop" />}
                            </Button>
                          </Tooltip>
                        }
                        {this.state.mediaItems && this.state.mediaItems.length > 0 && this.state.mediaItemsSource === 'record' &&
                          <Chip
                            avatar={<Avatar><ShowIcon icon="microphone" /></Avatar>}
                            label={this.state.mediaItems.length}
                            onDelete={() => this.setState({ mediaItems: [], mediaItemsSource: null })}
                            className={this.state.mediaItems.length > 0 ? classes.mediaChip : classes.mediaChipHide}
                          />
                        }
                      </GridItem>
                      <GridItem md={3} middle>
                        {(supportsAudioInput || supportsSendingAttachments) &&
                          <Tooltip title="Upload Attachment" placement="bottom">
                            <Button
                              justIcon small dense
                              aria-label="Upload Attachment"
                              onClick={() => {
                                this.setState({
                                  showMediaSelectionDialog: true,
                                  showMediaSelectionErr: null
                                })
                              }}
                              disabled={
                                (!supportsAudioInput && !supportsSendingAttachments) ||
                                loading ||
                                !this.state.conversationId || this.state.status !== 'CONNECTED' || this.state.mediaItemsSource === 'record'
                              }
                              title="Upload Attachment"
                              data-unique="btnLiveChatSelectMedia"
                              >
                              <ShowIcon icon="paperclip" />
                            </Button>
                          </Tooltip>
                        }
                        {this.state.mediaItems && this.state.mediaItems.length > 0 && this.state.mediaItemsSource === 'attachment' &&
                          <Chip
                            key={this.state.mediaItems.length}
                            //icon={'paperclip'}
                            avatar={<Avatar><ShowIcon icon="paperclip" /></Avatar>}
                            label={this.state.mediaItems.length}
                            onClick={() => {
                              this.setState({
                                showMediaSelectionDialog: true,
                                showMediaSelectionErr: null
                              })
                            }}
                            onDelete={() => this.setState({ mediaItems: [], mediaItemsSource: null })}
                            className={this.state.mediaItems.length > 0 ? classes.mediaChip : classes.mediaChipHide}
                          />
                        }
                        
                      </GridItem>
                      <GridItem md={2} middle>
                        <Tooltip title="Send" placement="bottom">
                          <Button
                            aria-label="Send"
                            onClick={handleSubmit}
                            disabled={
                              loading ||
                              !this.state.conversationId || this.state.status !== 'CONNECTED'
                            }
                            data-unique="btnLiveChatSave"
                            justIconSolid small
                          >
                            <ShowIcon icon="paper-plane" />
                          </Button>
                        </Tooltip>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} textCenter>
                    {this.state.mediaItems.length > 0 && <>
                      <Divider />
                      {this.state.mediaItems.map((f, index) => (
                        <React.Fragment key={index}>
                          <AudioButton
                            aria-label="Play Audio File"
                            data-unique="btnRecordPlay"
                            justIcon
                            audioBase64={f.buffer}
                            mimeType={f.mimeType}
                            className={classes.audioPlayButton}
                          />
                          <Chip
                            avatar={<Avatar>&nbsp;</Avatar>}
                            label={f.altText === undefined ? 'Recorded Audio' : f.altText}
                            onDelete={() => {
                              const newMediaItems = [...this.state.mediaItems]
                              newMediaItems.splice(index, 1)
                              this.setState({ mediaItems: newMediaItems && newMediaItems.length > 0 ? newMediaItems : [], mediaItemsSource: newMediaItems && newMediaItems.length > 0 ? 'attachment' : null })

                            }}
                            className={classes.audioChip}
                          />
                        </React.Fragment>
                      ))}
                      <Divider />
                    </>}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <MediaSelectionDialog allowFileSelection multiple
              extensionFilter={filterSupportedFileExtensions()}
              open={this.state.showMediaSelectionDialog}
              showError={this.state.showMediaSelectionErr}
              clearError={() => this.setState({ showMediaSelectionErr: null })}
              onCancel={() => this.setState({ showMediaSelectionDialog: false, showMediaSelectionErr: null })}
              onOk={async ({ selectedFiles }) => {
                try {
                  const uris = selectedFiles.map(c => `${config.api.base}/filebrowser/${c.join('/')}`)
                  const mediaItems = await Promise.all(uris.map(async (uri, index) => {
                    const b64 = await downloadfileb64(uri)
                    return {
                      buffer: b64,
                      mimeType: b64.split(';')[0].split(':')[1],
                      mediaUri: `${selectedFiles[index].join('/')}`,
                      altText: selectedFiles[index].join('/'),
                    }
                  }))
                  this.setState({
                    showMediaSelectionDialog: false,
                    showMediaSelectionErr: null,
                    mediaItems: [...this.state.mediaItems, ...mediaItems],
                    mediaItemsSource: 'attachment'
                  })
                } catch (err) {
                  this.setState({
                    showMediaSelectionErr: err.message
                  })
                }
              }}
              title="Select Files"
            />
          </>)
        }}
      </Mutation>
    )
  }

  renderSaveTestCaseDialog() {
    const { setAlertSuccessMessage, testSetId, testProjectId, testsets, testproject, chatbot, user, license, history, namespace } = this.props
    const { emptySaveTestCaseForm } = this.state

    let navigateToTestSet = false

    return (
      <Mutation
        mutation={CONVOSTEPS_SAVE}
        onCompleted={data => {
          this.setState({ showSaveDialog: false, showSaveDialogErr: null })
          if (navigateToTestSet) {
            history.push(`/testsets/view/${data.liveChatSaveConvoSteps}/testcases`)
          } else {
            setAlertSuccessMessage('Test Case saved')
          }
        }}
        onError={error => {
          this.setState({ showSaveDialogErr: error })
        }}
        refetchQueries={({ data }) => {
          return [
            ...RefetchTestProjectQueries(),
            ...RefetchTestSetQueries(data.liveChatSaveConvoSteps, license)
          ]
        }}
      >
        {(saveConvoSteps, { loading, error }) => (
          <Form
            keepDirtyOnReinitialize
            onSubmit={values => {
              saveConvoSteps({
                variables: {
                  chatbotId: chatbot.id,
                  conversationId: this.state.conversationId,
                  namespace: namespace.selected ? namespace.selected.name : undefined,
                  testSetId: testSetId ? testSetId : ((values.testSetId === 'new' ? null : values.testSetId) || null),
                  newTestSetName: testSetId ? null : ((values.testSetId === 'new' && hasPermission(user, 'TESTSETS_CREATE') && values.newTestSetName) || null),
                  testProjectId: testProjectId || null,
                  newTestProjectType: testProjectId ? null : hasPermission(user, 'TESTPROJECTS_CREATE') && values.newTestSetName && values.newTestProjectType && values.newTestProjectType !== 'dontcreate' ? values.newTestProjectType : null,
                  testCaseName: values.testCaseName,
                  splitToConvoAndUtterancesMe: values.splitToConvoAndUtterancesMe,
                  splitToConvoAndUtterancesBot: values.splitToConvoAndUtterancesBot,
                  types: Object.keys(values.types || {}).filter(t => values.types[t]),
                  deviceSetId: this.state.deviceSetId || null
                },
              })
            }}
            initialValues={emptySaveTestCaseForm}
            render={({ handleSubmit, values, submitting, form: { change } }) => (
              <ConfirmationDialog
                open={this.state.showSaveDialog}
                onOk={() => handleSubmit()}
                okButtonIcon="save"
                okText={this.state.showSameTestCaseName ? 'Overwrite Conversation' : 'Save Conversation'}
                title="Save Conversation as Test Case"
                isWorking={loading || submitting}
                okDisabled={loading || submitting}
                showError={this.state.showSaveDialogErr}
                clearError={() => this.setState({ showSaveDialogErr: null })}
                extraButton={<>
                  <Button secondary
                    data-unique="btnLiveChatSaveCancel"
                    onClick={() => this.setState({ showSaveDialog: false })}>
                    <ShowIcon icon="times" />
                    Cancel
                  </Button>
                  <Button secondary
                    data-unique="btnLiveChatSaveAndOpen"
                    onClick={() => {
                      navigateToTestSet = true
                      handleSubmit()
                    }}>
                    {!loading && !submitting && <ShowIcon icon="save" />}
                    {(loading || submitting) && <LoadingIndicator alt />}
                    {this.state.showSameTestCaseName ? 'Overwrite Conversation and Open Test Set' : 'Save Conversation and Open Test Set'}
                  </Button>
                </>}
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <Text header>General Action</Text>
                        </GridItem>
                        {!testSetId && <>
                          <GridItem xs={12}>
                            {!testProjectId &&
                              <Field
                                name="testSetId"
                                component={renderSelect}
                                label="Select Test Set"
                                validate={required}
                                data-unique="selLiveChatTestSetId"
                                items={[
                                  hasPermission(user, 'TESTSETS_CREATE') && {
                                    key: 'new',
                                    label: '--- Register new Test Set ---'
                                  },
                                  ...(safeGetNamespaceFilteredList(testsets || [], this.props.namespace).map(t => ({ key: t.id, label: t.name })))
                                ].filter(i => i)}
                              />
                            }
                            {testProjectId &&
                              <Field
                                name="testSetId"
                                component={renderSelect}
                                label="Select Test Set"
                                validate={required}
                                data-unique="selLiveChatTestSetId"
                                items={[
                                  hasPermission(user, 'TESTSETS_CREATE') && hasPermission(user, 'TESTPROJECTS_UPDATE') && {
                                    key: 'new',
                                    label: '--- Register new Test Set ---'
                                  },
                                  ...(testproject.testSets || []).map(t => ({ key: t.id, label: t.name }))
                                ].filter(i => i)}
                              />
                            }
                            <OnChange name="testSetId">
                              {async (value, previous) => {
                                const { client } = this.props
                                if (values.testSetId && value) {
                                  const sameNameResult = await validateConvoNameUnique(client, values.testSetId, null, values.testCaseName)
                                  if (sameNameResult) {
                                    this.setState({
                                      showSameTestCaseName: true
                                    })
                                  } else {
                                    this.setState({
                                      showSameTestCaseName: false
                                    })
                                  }
                                }
                              }}
                            </OnChange>
                          </GridItem>
                          {hasPermission(user, 'TESTSETS_CREATE') && values.testSetId === 'new' &&
                            <GridItem xs={12}>
                              <Field
                                name="newTestSetName"
                                component={renderTextField}
                                label="New Test Set Name"
                                validate={composeValidators(required, async (value) => {
                                  const { client } = this.props
                                  if (values.testSetId === 'new' && values.newTestProjectType && values.newTestProjectType !== 'dontcreate' && value) {
                                    return validateTestProjectCodeUnique(client, value, undefined, {[values.newTestProjectType]: true})
                                  }
                                })}
                                data-unique="txtLiveChatNewTestSetName"
                              />
                            </GridItem>
                          }
                        </>}
                        <GridItem xs={12}>
                          <Field
                            name="testCaseName"
                            component={renderTextField}
                            label="Test Case Name"
                            validate={composeValidators(required)}
                            data-unique="txtLiveChatTestCaseName"
                          />
                          <OnChange name="testCaseName">
                            {async (value, previous) => {
                              const { client } = this.props
                              if (values.testSetId && value) {
                                const sameNameResult = await validateConvoNameUnique(client, values.testSetId, null, value)
                                if (sameNameResult) {
                                  this.setState({
                                    showSameTestCaseName: true
                                  })
                                } else {
                                  this.setState({
                                    showSameTestCaseName: false
                                  })
                                }
                              }
                            }}
                          </OnChange>
                        </GridItem>
                        <Divider orientation="horizontal" dividerlgnone />
                        <GridItem xs={12} sm={6} >
                          <Field
                            name="splitToConvoAndUtterancesMe"
                            component={renderCheckbox}
                            label="Place user messages in separate Utterance files"
                            type="checkbox"
                            data-unique="chkLiveChatSplitToConvoAndUtterancesMe"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} >
                          <Field

                            name="splitToConvoAndUtterancesBot"
                            component={renderCheckbox}
                            label="Place bot responses in separate Utterance files"
                            type="checkbox"
                            data-unique="chkLiveChatSplitToConvoAndUtterancesBot"
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={1}></GridItem>
                    <GridItem xs={5}>
                      <GridItem xs={12}>
                        <Text header>Select Chat Content Types</Text>
                      </GridItem>
                      <GridItem xs={12}>
                        <GridContainer>
                          {Object.keys(EXPORT_TYPES).map(t => <GridItem key={t} floatLeft>
                            <Field
                              dense
                              name={`types.${t}`}
                              component={renderCheckbox}
                              label={EXPORT_TYPES[t]}
                              type="checkbox"
                              data-unique={`chkLiveChatTypes_${t}`}
                            />
                          </GridItem>)}
                        </GridContainer>
                      </GridItem>
                    </GridItem>
                    {this.state.showSameTestCaseName && <GridItem xs={12}>
                      <MessageBox
                        variant="warning"
                        title="Warning: Test Case Name exists already!"
                        text={'There is already a Test Case with the same name in the selected Test Set. The Test Case will be overwritten.'}
                      />
                    </GridItem>}
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  getCancelUri() {
    const { cancelUri, chatbot, testSetId, testProjectId } = this.props
    if (cancelUri) return cancelUri

    return testProjectId ? `/regression/projects/view/${testProjectId}` : testSetId ? `/testsets/view/${testSetId}` : `/chatbots/view/${chatbot.id}`
  }

  render() {
    const { status } = this.state
    const { getConnector, setAlertSuccessMessage, setAlertErrorMessage, license, history, user, mutateStartChatbot, chatbot, deviceSetsData, classes } = this.props
    const cancelUri = this.getCancelUri()
    const containerMode = this.getContainerMode()

    const connector = getConnector(containerMode)

    const devices = (!deviceSetsData || !deviceSetsData.devicesets || deviceSetsData.devicesets.length === 0) ? [] :
      deviceSetsData.devicesets.reduce((agg, ds) => {
        if (connector && connector.features && connector.features.deviceProviderTypes && connector.features.deviceProviderTypes.length > 0) {
          if (connector.features.deviceProviderTypes.indexOf(ds.provider.type) < 0) return agg
        }
        return [
          ...agg,
          ...ds.devices.map(d => ({
            id: d.id,
            name: `${ds.name} / ${d.name}`,
            deviceSetId: ds.id
          }))
        ]
      }, [])

    const hasDeviceSet = !!(connector && connector.features && connector.features.deviceSetCapability)
    const conversationId = v1()


    return (
      <GridContainer>
        <GridItem md={10} lg={8}>
          <ExpansionPanel expanded={this.state.showSelectedTechnology}>
            <ExpansionPanelSummary onClick={() => this.setState({ showSelectedTechnology: !this.state.showSelectedTechnology })}>
              <Text header>Selected Chatbot Technology</Text>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer noPadding fullWidth classes={{ grid: classes.leftDatacards }}>
                <GridItem xs={1}>
                  <StatsText primaryText={<AvatarImage variant="medium" avatar={chatbot.avatar} containermode={chatbot.containermode} chatbotId={chatbot.id} />} secondaryText="Chatbot" />
                </GridItem>
                <GridItem xs={4}>
                  <StatsText primarySuffix={connector ? (connector.description || connector.name) : 'Custom Connector'} secondaryText="Connector / Chatbot Technology" />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </GridItem>
        <GridItem md={12} lg={8}>
          <GridContainer>
            <GridItem xs={12}>
              {this.renderChatWindow()}
            </GridItem>
            <GridItem xs={12} noPadding>
              <GridContainer fullWidth noMargin paddingTop>
                <GridItem md={6}>
                  {(status === 'DISCONNECTED' || status === 'CONNECTING' || status === 'CANCELLED') &&
                    <Mutation
                      mutation={START_CHATBOT}
                      onCompleted={data => {
                        setAlertSuccessMessage('Chatbot connected')
                        this.setState({ conversationId: data.liveChatStartBot, status: 'CONNECTED' })
                      }}
                      onError={error => {
                        setAlertErrorMessage(`Chatbot connection failed`, error)
                        this.setState({ conversationId, status: 'DISCONNECTED' })
                      }}
                    >
                      {(startChatbot, { loading, error }) => {
                        if (hasDeviceSet) {
                          return <DropdownButton
                            secondary
                            items={devices.map(device => ({
                              id: device.id,
                              name: device.name,
                              onClick: () => {
                                this.setState({ deviceSetId: device.deviceSetId, deviceId: device.id, conversationId, status: 'CONNECTING' })
                                startChatbot({
                                  variables: { chatbotId: chatbot.id, conversationId, deviceId: device.id }
                                })
                              }
                            }))}
                            showFilter
                            disabled={devices.length === 0}
                            data-unique="ddbtnLiveChatStart"
                          >
                            {status === 'CONNECTING' && <LoadingIndicator alt />}
                            {(status === 'DISCONNECTED' || status === 'CANCELLED') && <><ShowIcon icon="play-circle" /> {devices.length === 0 ? '(No Device Available)' : 'Connect Device'}</>}
                          </DropdownButton>
                        } else {
                          return <Button
                            secondary
                            link
                            onClick={() => {
                              const conversationId = v1()
                              this.setState({ conversationId, status: 'CONNECTING' })
                              startChatbot({
                                variables: { chatbotId: chatbot.id, conversationId }
                              })
                            }}
                            disabled={loading}
                            data-unique="btnLiveChatStart"
                          >
                            {status === 'CONNECTING' && <><LoadingIndicator alt /> Connecting ...</>}
                            {(status === 'DISCONNECTED' || status === 'CANCELLED') && <><ShowIcon icon="play-circle" /> Connect</>}
                          </Button>
                        }
                      }}
                    </Mutation>
                  }
                  {status === 'CONNECTED' &&
                    <Mutation
                      mutation={STOP_CHATBOT}
                      onCompleted={async data => {
                        setAlertSuccessMessage('Chatbot disconnected')
                        const conversationId = v1()
                        this.setState({ conversationId, textToSend: '', status: 'CONNECTING' })
                        try {
                          const response = await mutateStartChatbot({
                            variables: { chatbotId: chatbot.id, conversationId },
                          })
                          setAlertSuccessMessage('Chatbot connected')
                          this.setState({ conversationId: response.data.liveChatStartBot, status: 'CONNECTED' })
                        } catch (error) {
                          setAlertErrorMessage(`Chatbot connection failed`, error)
                          this.setState({ conversationId, status: 'DISCONNECTED' })
                        }

                      }}
                      onError={error => {
                        setAlertErrorMessage(`Chatbot disconnection failed (maybe due to inactivity)`, error)
                        this.setState({ textToSend: '', status: 'DISCONNECTED' })
                      }}
                    >
                      {(stopChatbot, { loading, error }) => (
                        <Button
                          secondary
                          link
                          onClick={() => {
                            stopChatbot({
                              variables: {
                                chatbotId: chatbot.id,
                                conversationId: this.state.conversationId,
                              },
                            })
                          }}
                          disabled={loading}
                          data-unique="btnLiveChatReconnect"
                        >
                          <ShowIcon icon="redo" />
                          Reconnect
                        </Button>
                      )}
                    </Mutation>
                  }
                  {license.detailedReporting &&
                    <Button
                      secondary
                      link
                      onClick={() => window.open(`${config.api.base}/livechatlog/${this.state.conversationId}`, '_blank')}
                      disabled={!this.state.conversationId}
                      data-unique="btnLiveChatShowLog"
                    >
                      <ShowIcon icon="file" />
                      Show Logs
                    </Button>
                  }
                </GridItem>
                <GridItem md={6} right >
                  {!this.state.conversationId &&
                    <NavLink to={cancelUri} data-unique="btnChatbotLiveChatCancel">
                      <Button secondary>
                        <ShowIcon icon="window-close" />
                        Cancel
                      </Button>
                    </NavLink>
                  }
                  {this.state.conversationId &&
                    <Mutation
                      mutation={STOP_CHATBOT}
                      onCompleted={data => {
                        setAlertSuccessMessage('Chatbot disconnected')
                        history.push(cancelUri)
                      }}
                      onError={error => {
                        setAlertErrorMessage(`Chatbot disconnection failed (maybe due to inactivity)`, error)
                      }}
                    >
                      {(stopChatbot, { loading, error }) => (
                        <Button
                          secondary
                          onClick={() => {
                            this.setState({ status: 'CANCELLED' })
                            stopChatbot({
                              variables: {
                                chatbotId: chatbot.id,
                                conversationId: this.state.conversationId,
                              },
                            })
                          }}
                          disabled={
                            loading || this.state.conversationId ? false : true
                          }
                          data-unique="btnChatbotLiveChatCancel"
                        >
                          Cancel
                        </Button>
                      )}
                    </Mutation>
                  }
                  {hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE']) && <>
                    {this.renderSaveTestCaseDialog()}
                    <Button
                      onClick={() => this.setState({ showSaveDialog: true })}
                      disabled={status === 'DISCONNECTED' || status === 'CONNECTING' || status === 'CANCELLED' || !this.state.conversationId}
                      data-unique="btnLiveChatSaveTestCase"
                    >
                      <ShowIcon icon="save" />
                      Save
                    </Button>
                  </>}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  }
}

const LiveChatWithGraphql = compose(
  withRouter,
  withStyles(chatbotsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, features: state.settings.features, settings: state.settings, namespace: state.namespace }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(STOP_CHATBOT, {
    props: ({ mutate }) => ({
      mutateStopChatbot: args => mutate(args),
    }),
  }),
  graphql(START_CHATBOT, {
    props: ({ mutate }) => ({
      mutateStartChatbot: args => mutate(args)
    }),
    options: (props) => ({
      onCompleted: props.onCompleted,
      onError: props.onError,
    })
  }),
  graphql(SPEECH_RUN_EFFECT, {
    props: ({ mutate }) => ({
      mutateSpeechRunConversion: args => mutate(args)
    }),
  }),
  graphql(DEVICESETS_QUERY, {
    skip: (props) => !isLicenseDeviceSetsSupported(props.license),
    props: ({ data }) => ({
      deviceSetsData: data,
    })
  }),
  withApollo
)(LiveChat)

class LiveChatLoader extends React.Component {
  render() {
    const { chatbotData, testsetsData, testprojectData } = this.props

    const queries = [chatbotData]
    const query = ['chatbot']

    if (testsetsData) {
      queries.push(testsetsData)
      query.push('testsets')
    }
    if (testprojectData) {
      queries.push(testprojectData)
      query.push('testproject')
    }
    return (
      <GridContainer>
        <GridItem xs={12}>
          <QueryStatus queries={queries} query={query} card>{({ chatbot, testsets, testproject }) => <LiveChatWithGraphql {...this.props} chatbot={chatbot} testsets={testsets} testproject={testproject} />}</QueryStatus>
        </GridItem>
      </GridContainer>
    )
  }
}

const LiveChatLoaderWithGraphql = compose(
  withStyles(chatbotsStyle),
  graphql(CHATBOT_QUERY, {
    props: ({ data }) => ({
      chatbotData: data
    }),
    options: (props) => {
      const { match, chatbotId } = props
      return {
        variables: {
          id: chatbotId || (match && match.params && match.params.id)
        },
      }
    }
  }),
  graphql(TESTSETS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      testsetsData: data
    }),
    skip: (props) => !!props.testProjectId
  }),
  graphql(TESTPROJECT_QUERY, {
    props: ({ data }) => ({
      testprojectData: data
    }),
    skip: (props) => !props.testProjectId,
    options: (props) => ({
      variables: {
        id: props.testProjectId
      }
    })
  }))(LiveChatLoader)

export default LiveChatLoaderWithGraphql
