import { gql } from 'apollo-boost'
import { deleteListQueryFromCache } from 'helper/cacheHelper'

export const DEEP_PERFORMANCETESTSESSION_FRAGMENT = gql`
  fragment DeepPerformanceTestSession on PerformanceTestSession {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    description
    tags
    parallelJobCount
    tickRepeatInitial
    tickRepeatPerTick
    tickMaxTime
    tickTime
    requiredPercentOfSuccesfulUsers
    shareContainer
    simpleConvo
    detailedReporting
    waitForBotTimeout
    notification
    testSession {
      id
      name
      namespace
    }
    testProject {
      id
      name
    }
    chatbot {
      id
      name
    }
    testSets {
      id
      name
    }
    registeredComponents {
      id
      name
      ref
    }
    results {
      id
      stepIndex
      execCount
      execDurationMin
      execDurationMax
      execDurationSum
      finishedWithErrorCount
      runningWithErrorCount
      finishedCount
      userCount
      stepStartAt
    }
    jobs {
      id
      createdAt
      jobId
      status
      err
      started
      finished
      agentName
    }
  }
`

export const PERFORMANCETESTSESSION_PROGRESS_FRAGMENT = gql`
  fragment PerformanceTestSessionProgress on PerformanceTestSession {
    id
    name
    parallelJobCount
    tickRepeatInitial
    tickRepeatPerTick
    tickMaxTime
    tickTime
    requiredPercentOfSuccesfulUsers
    shareContainer
    simpleConvo
    results {
      id
      stepIndex
      execCount
      execDurationMin
      execDurationMax
      execDurationSum
      finishedWithErrorCount
      runningWithErrorCount
      finishedCount
      userCount
      stepStartAt
    }
    jobs {
      id
      status
      err
    }
  }
`

export const PERFORMANCETESTSESSION_QUERY = gql`
  query PerformanceTestSessionQuery($id: ID!) {
    performancetestsession(id: $id) {
      ...DeepPerformanceTestSession
    }
  }
  ${DEEP_PERFORMANCETESTSESSION_FRAGMENT}
`

export const PERFORMANCETESTSESSION_STATUS_QUERY = gql`
  query PerformanceTestSessionStatusQuery($id: ID!) {
    performancetestsessionstatus(id: $id) {
      id status
    }
  }
`

export const PERFORMANCETESTSESSION_PROGRESS_QUERY = gql`
  query PerformanceTestSessionProgressQuery($id: ID!) {
    performancetestsession(id: $id) {
      ...PerformanceTestSessionProgress
    }
  }
  ${PERFORMANCETESTSESSION_PROGRESS_FRAGMENT}
`

export const PERFORMANCETESTSESSION_PROGRESS_SUBSCRIPTION = gql`
  subscription PerformanceTestSessionProgressSubscription($id: ID!) {
    performanceSessionProgress(performanceTestSessionId: $id) {
      ...PerformanceTestSessionProgress
    }
  }
  ${PERFORMANCETESTSESSION_PROGRESS_FRAGMENT}
`

export const DELETE_PERFORMANCETESTSESSION = gql`
  mutation DeletePerformanceTestSession($id: ID!) {
    deletePerformanceTestSession(id: $id)
  }
`

export const CANCEL_PERFORMANCETESTSESSION = gql`
  mutation CancelPerformanceTestSession($id: ID!) {
    cancelPerformanceTestSession(id: $id)
  }
`

export const PERFORMANCETESTSESSIONTREND_QUERY = gql`
  query PerformanceTestSessionTrendQuery($id: ID!) {
    performancetestsessiontrend(id: $id) {
      performanceTestSessionId
      previousPerformanceTestSessionId
      previousPerformanceTestSessionName
      previousPerformanceTestSessionCreatedAt
      nextPerformanceTestSessionId
      nextPerformanceTestSessionName
      nextPerformanceTestSessionCreatedAt
    }
  }
`

export const START_PERFORMANCETEST = gql`
  mutation StartPerformanceTestProject($testProjectId: ID!, $options: PerformanceTestSessionCreate!) {
    createPerformanceTestSession(testProjectId: $testProjectId, options: $options) {
      id
      name
      testProject {
        id
        name
      }
      chatbot {
        id
        name
      }      
      testSession {
        id
        name
      }
    }
  }
`

export const DeletePerformanceTestSessionsListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^performancetestsessions/)
}
