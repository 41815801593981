import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'

function DateFormat({ ...props }) {
  const { format, seconds, fromNow } = props

  if (props.children) {
    if (fromNow) {
      return <Moment fromNow>{props.children}</Moment>
    } else {
      return <Moment format={format || seconds ? 'll LTS': 'lll'}>{props.children}</Moment>
    }
  }
  else return null
}

DateFormat.propTypes = {
  format: PropTypes.string,
  fromNow: PropTypes.bool
}

export default DateFormat
