import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'

import linkStyle from 'assets/jss/material-dashboard-react/components/linkStyle.jsx'
function LinkButton({ ...props }) {
  const {
    classes,
    children,
    className,
    gray,
    grey,
    noUnderline,
    hoverUnderline,
    onClick,
    ...rest
  } = props
  const linkClasses = classNames({
    [classes.link]: true,
    [classes.gray]: gray || grey,
    [classes.noUnderline]: noUnderline,
    [classes.hoverUnderline]: hoverUnderline,
    ...(className ? { [className]: true } : {})
  })
  const handleKeypress = e => {
    if (e.key === 'Enter') {
      onClick()
    }
  }
  return (<div tabIndex={0} className={linkClasses} onClick={onClick} onKeyDown={onClick ? handleKeypress : null} {...rest}>
    {children}
  </div>)
}

export default withStyles(linkStyle)(LinkButton)




