import dashboardStyle from './dashboardStyle'

const fontWeight = 400
const fontSize = 16

const reportsStyle = theme => {
  return {
    regressiontext: {
      color: `${theme.colors.regressiontext} !Important`,
      fontWeight,
      fontSize,
      '& span': {
        color: `${theme.colors.regressiontext} !Important`,
      }
    },
    e2etext: {
      color: `${theme.colors.regressiontext} !Important`,
      fontWeight,
      fontSize,
      '& span': {
        color: `${theme.colors.regressiontext} !Important`,
      }
    },
    nlptext: {
      color: `${theme.colors.regressiontext} !Important`,
      fontWeight,
      fontSize,
      '& span': {
        color: `${theme.colors.regressiontext} !Important`,
      }
    },
    performancetext: {
      color: `${theme.colors.regressiontext} !Important`,
      fontWeight,
      fontSize,
      '& span': {
        color: `${theme.colors.regressiontext} !Important`,
      }
    },
    securitytext: {
      color: `${theme.colors.regressiontext} !Important`,
      fontWeight,
      fontSize,
      '& span': {
        color: `${theme.colors.regressiontext} !Important`,
      }
    },
    gdprtext: {
      color: `${theme.colors.regressiontext} !Important`,
      fontWeight,
      fontSize,
      '& span': {
        color: `${theme.colors.regressiontext} !Important`,
      }
    },
    dropdownSelectDate: {
      height: 'auto',
      padding: '6px 0 6px 10px',
      '& svg':{
        height: '16px',
        paddingRight: 0
      }
    },
    dropdownSelectFilter: {
        top: 0,
    },
    testtypefilterBlock: {
        marginTop: 0,
        '& div': {
          marginTop: 0,
        }
    },
    textFieldDate: {
      '& label, &:focus-within label':{
        color: `${theme.colors.primary}`,
        background: 'transparent'
      },
      '& input[type="date"], &:focus-within input':{
        color: `${theme.colors.primary}`,
        background: 'transparent'
      },
      '& input[type="date"]::-webkit-calendar-picker-indicator':{
        cursor: 'pointer',
        filter: `${theme.colors.calendarPickerindicator}`
      },
      '& div':{
        '&:hover':{
          borderBottom: `1px solid ${theme.colors.primary}`,
        },
        '&::before, &:focus-within::before':{
          borderBottom: `1px solid ${theme.colors.primary}`,
        },
      }
    },
    barChartBlock:{
      '& svg':{
        fill: `${theme.colors.primary}`
      },
      '& text, & tspan':{
        fill: `${theme.colors.primary}`
      }
    },
    ...dashboardStyle(theme)
  }
}

export default reportsStyle
