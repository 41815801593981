import React from 'react'
import { connect } from 'react-redux'
import copyToClipboard from 'copy-to-clipboard'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query } from 'react-apollo'
// core components
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  renderTextField,
  renderSelect,
  CustomTextField
} from 'components/Form/Form'

import { APIKEYS_QUERY } from '../../views/Settings/gql'
import Button from '../Button/Button'
import { setAlertSuccessMessage } from '../../actions/alert'
import { capSetDescription, ExtractStringCapabilityValue, usedByCapabilitySet } from './Helper'
import Text from 'components/Typography/Text'
import config from 'config'

export function landbotCaps2Form(caps) {
  return {
    landbot: {
      token: ExtractStringCapabilityValue(caps, 'LANDBOT_TOKEN', ''),
      apiToken: ExtractStringCapabilityValue(caps, 'LANDBOT_API_TOKEN', ''),
      queryResult: ExtractStringCapabilityValue(caps, 'LANDBOT_QUERY_RESULT', ''),
    }
  }
}

const capNamesMap = {
  'landbot.token': 'LANDBOT_TOKEN',
  'landbot.apiToken': 'LANDBOT_API_TOKEN',
  'landbot.queryResult': 'LANDBOT_QUERY_RESULT',
}

export function landbotForm2caps(values) {
  return [
    {
      name: 'LANDBOT_TOKEN',
      type: 'STRING',
      stringValue: values.landbot.token || '',
    },
    {
      name: 'LANDBOT_API_TOKEN',
      type: 'STRING',
      stringValue: values.landbot.apiToken || '',
    },
    {
      name: 'LANDBOT_QUERY_RESULT',
      type: 'STRING',
      stringValue: values.landbot.queryResult || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'landbot' }
  ]
}

class LandbotEditUnconnected extends React.Component {
  render() {
    const { disabled, setAlertSuccessMessage, capSetCapNames } = this.props

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => {
      const webhookUrl = `${config.api.ext}/inbound?APIKEY=${values.landbot.apiKey || 'SELECT_API_KEY'}`
      return (
        <GridContainer>
          <GridItem xs={10} sm={6}>
            <CustomTextField
              input={{
                value: webhookUrl,
                readOnly: true,
              }}
              label="Landbot Outgoing Webhook URI (for Copy&Paste)"
              data-unique="txtLandbotOutgoingUri"
              endAdornment={
                <Button
                  onClick={() => {
                    copyToClipboard(webhookUrl)
                    setAlertSuccessMessage(`Landbot Outgoing Webhook URI copied to the clipboard`)
                  }}
                  justIcon
                  data-unique="btnLandbotWebhookEditCopy"
                >
                  <FileCopyIcon />
                </Button>
              }
              helperText="You have to configure in your Landbot app the corresponding ApiChatbot Channel to use this URL as HOOK URL, adding API Key as query parameter."
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Query query={APIKEYS_QUERY}>
              {({ loading, error, data }) => {
                return (
                  <Field
                    name="landbot.apiKey"
                    component={renderSelect}
                    label="API Key"
                    data-unique="selLandbotEditApiKey"
                    loading={loading}
                    error={error}
                    items={data && data.apikeys && data.apikeys.map(a => {
                      return { key: a.key, label: a.name }
                    })}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['landbot.apiKey']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['landbot.apiKey'], 'Choose an API Key to generate the Outgoing Webhook URI')}
                  />
                )
              }}
            </Query>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="landbot.token"
              component={renderTextField}
              label="Auth Token"
              validate={required}
              data-unique="txtLandbotEditToken"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['landbot.token']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['landbot.token'])}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="landbot.apiToken"
              component={renderTextField}
              label="API Token"
              data-unique="txtLandbotEditApiToken"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['landbot.apiToken']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['landbot.apiToken'])}
            />
            <Text helperText>
              You can find API token field under your account settings. <i>This field is only required if you have a dialogflow integration in your bot, and you would like to process these data.</i>
            </Text>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="landbot.queryResult"
              component={renderTextField}
              label="Query Result"
              data-unique="txtLandbotEditQueryResult"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['landbot.queryResult']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['landbot.queryResult'])}
            />
            <Text helperText>
              The same value has to be set here as in the Query Response field in your dialogflow integration. <i>This field is only required if you have a dialogflow integration in your bot, and you would like to process these data.</i>
            </Text>
          </GridItem>
        </GridContainer>
      )
    }} />)
  }
}

const LandbotEdit = connect(
  state => ({}),
  { setAlertSuccessMessage }
)(LandbotEditUnconnected)
export { LandbotEdit }