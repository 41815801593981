import React, { useState } from 'react'
import PropTypes from 'prop-types'
import RegularButton from './Button.jsx'
import ConfirmationDialog from '../Dialog/ConfirmationDialog.jsx'

function PopupInformationButton({ ...props }) {
  const {
    popupTitle,
    popupBody,
    children,
    ...rest
  } = props

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  return (<React.Fragment>
    <RegularButton {...rest}
      onClick={async () => {
        setShowConfirmationDialog(true)
      }}>
      {children}
    </RegularButton>
    <ConfirmationDialog
      open={showConfirmationDialog}
      closeDisabled
      okText="OK"
      onOk={() => setShowConfirmationDialog(false)}
      title={popupTitle}
    >
      {popupBody}
    </ConfirmationDialog>
  </React.Fragment>)
}

PopupInformationButton.propTypes = {
  popupTitle: PropTypes.string.isRequired,
  popupBody: PropTypes.any.isRequired,
  ...RegularButton.propTypes
}
PopupInformationButton.defaultProps = {
}

export default PopupInformationButton
