import React from 'react'
import { FixedScaleAxis } from 'chartist'

import LineChart from './LineChart'

function LineChartMini({ data, ...props }) {
  return <LineChart
    data={data}
    legend={false}
    grid="horizontal"
    options={{
      height: 50,
      showPoint: false,
      chartPadding: 3,
      fullWidth: true,
      showLabel: false,
      axisX: {
        type: FixedScaleAxis,
        low: 0,
        high: data.labels.length,
        divisor: 5,
        showLabel: false,
        offset: 3
      },
      axisY: {
        showLabel: false,
        offset: 3
      }
    }} 
    {...props}
  />
}
      
export default LineChartMini