import { SET_LIST_SETTINGS, CLEAR_LIST_SETTINGS } from '../constants'

export function clearListSettings(list) {
  return {
    type: CLEAR_LIST_SETTINGS,
    payload: {
      list,
    },
  }
}

export function setListSettings(list, settings) {
  return {
    type: SET_LIST_SETTINGS,
    payload: {
      list,
      settings,
    },
  }
}
