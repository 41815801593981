import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { Form } from 'react-final-form'
// apollo
import { Query, Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { FormActionsToolbar } from 'components/Form/Form'

import { RefetchTestSetQueries, DeleteTestSetScriptsFromCache } from 'views/TestSets/gql'
import TestSetSelector from 'views/TestSets/TestSetSelector'
import { CHATBOT_QUERY } from 'views/Chatbots/gql'
import LoadingIndicator from 'components/Icon/LoadingIndicator'




const IMPORT_INTENTS = (connectorNameSuffix) => gql`
  mutation ImportFrom${connectorNameSuffix}(
    $chatbotId: ID!
    $testSetId: ID
    $newTestSetName: String,
    $createTestProject: Boolean,
    $importMode: ImportOverwriteMode
  ) {
    importFrom${connectorNameSuffix}(
      chatbotId: $chatbotId
      testSetId: $testSetId
      newTestSetName: $newTestSetName,
      createTestProject: $createTestProject,
      importMode: $importMode
    ) { id scripts { id } }
  }
`

class GenericImport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      importing: false
    }
    this.empty = {
      testSetId: this.props.testSetId || 'new',
      importMode: 'EXTEND'
    }
  }

  render() {
    const { importing } = this.state
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { chatbotId, connectorNameSuffix } = this.props

    
    
    return (<Query query={CHATBOT_QUERY} variables={{ id: chatbotId }}>
      
      {({ error, data }) => {
        if (error) return <ErrorFormat err={error} />
        if (!data.chatbot) return null
        
        return (
          <Mutation
            mutation={IMPORT_INTENTS(connectorNameSuffix)}
            onCompleted={data => {
              this.setState({ importing: false })
              setAlertSuccessMessage('Test Cases imported')
              history.push(`/testsets/view/${data[`importFrom${connectorNameSuffix}`].id}`)
            }}
            onError={error => {
              this.setState({ importing: false })
              setAlertErrorMessage(`Test Case import failed`, error)
            }}
            refetchQueries={({ data }) => [
              ...RefetchTestSetQueries(data[`importFrom${connectorNameSuffix}`].id, license)
            ]}
            update={(store, { data }) => DeleteTestSetScriptsFromCache(store, data[`importFrom${connectorNameSuffix}`].scripts.map(s => s.id))}
            awaitRefetchQueries={true}
          >
            
            {(importFromX, { loading, error }) => (
              <Form
                onSubmit={values => {
                  this.setState({ importing: true })
                  const variables = {
                    chatbotId: chatbotId,
                    testSetId: values.newTestSetName ? null : values.testSetId,
                    newTestSetName: values.newTestSetName,
                    createTestProject: !!values.createTestProject,
                    importMode: values.importMode,
                  }

                  importFromX({
                    variables
                  })
                }}
                initialValues={this.empty}
                
                render={({ handleSubmit, submitting, invalid }) => (
                  
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <TestSetSelector defaultNewTestSetName={data.chatbot.name + ' - Test Set'} />
                      <GridItem xs={12} largePadding>
                        <FormActionsToolbar rightButtons={
                          <Button
                            type="submit"
                            disabled={importing || submitting || invalid}
                            data-unique={`btn${connectorNameSuffix}ImportDownload`}
                          >
                            {importing && <><LoadingIndicator /> Download is running</>}
                            {!importing && <><CloudDownloadIcon /> Download</>}
                          </Button>
                        }/>
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
              />
            )}
          </Mutation>
        )
      }}
    </Query>)
  }
}

GenericImport.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  testSetId: PropTypes.string,
  connectorNameSuffix: PropTypes.string.isRequired,
  connectorName: PropTypes.string.isRequired
}

export default withRouter(connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(GenericImport))
