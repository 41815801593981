import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
import reactToString from 'react-to-string'
// @material-ui/core components
import CheckIcon from '@material-ui/icons/Check'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import Button from 'components/Button/Button'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import RefreshIcon from '@material-ui/icons/Refresh'
import ShowIcon from 'components/Icon/ShowIcon'
// core components
import LinkButton from 'components/Button/LinkButton'
import Chip from 'components/Chip/Chip'
import Tooltip from 'components/Tooltip/NativeTooltip'
import ErrorFormat from 'components/Info/ErrorFormat'
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle'

import { clearTableSettings, setTableSettings, defaultTableSettings } from 'actions/table'
import { CustomTextField, TableActionsToolbar } from 'components/Form/Form'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

export const renderCell = (prop, { truncateChip, truncateText } = {}) => {
  if (prop && prop.href) {
    return <NavLink to={prop.href} style={{ display: 'inline-block', minWidth: prop.width ? prop.width : 0 }} data-entry={prop.dataEntry}>{renderCellValue(prop, { truncateChip, truncateText })}</NavLink>
  } else if (prop && prop.tags) {
    if (prop.tags && prop.tags.length > 0) {
      return prop.tags.map((t, i) => {
        return <Chip truncate={truncateChip} key={i} label={t} />
      })
    } else {
      return null
    }
  } else {
    return renderCellValue(prop, { truncateChip, truncateText })
  }
}

const renderCellValue = (prop, { truncateChip, truncateText }) => {
  let cellValue =
    prop && _.isObject(prop) && !_.isArray(prop) && !_.isFunction(prop)
      ? prop.value
      : prop
  if (_.isFunction(cellValue)) {
    return cellValue()
  } else if (_.isBoolean(cellValue)) {
    if (cellValue) return <CheckIcon color="primary" />
    else return null
  } else if (_.isArray(cellValue)) {
    return cellValue.map((c, i) => {
      if (c.href) {
        return <NavLink to={c.href} key={i}><Chip tooltip={c.value} truncate={truncateChip} label={c.value} clickable/></NavLink>
      } else {
        return <Chip tooltip={c} truncate={truncateChip} label={c} key={i}/>
      }
    })
  } else if (_.isString(cellValue)) {
    if (truncateText && cellValue.length > truncateText) {
      return <Tooltip title={cellValue}>{_.truncate(cellValue, { length: truncateText })}</Tooltip>
    } else {
      return cellValue
    }
  } else {
    return cellValue
  }
}

class AdvancedCustomTablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0)
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    )
  }

  render() {
    const { classes, count, hasMore, pageLoading, page } = this.props

    return (
      <div className={classes.tablePaginationActions}>
        <Button
          onClick={this.handleFirstPageButtonClick}
          disabled={pageLoading || page === 0}
          aria-label="First Page"
          round
          justIcon
        >
          <FirstPageIcon />
        </Button>
        <Button
          onClick={this.handleBackButtonClick}
          disabled={pageLoading || page === 0}
          aria-label="Previous Page"
          round
          justIcon
        >
          <KeyboardArrowLeft />
        </Button>
        <Button
          onClick={this.handleNextButtonClick}
          disabled={pageLoading || !hasMore}
          aria-label="Next Page"
          round
          justIcon
        >
          <KeyboardArrowRight />
        </Button>
        {count >= 0 && (
          <Button
            onClick={this.handleLastPageButtonClick}
            disabled={pageLoading || !hasMore}
            aria-label="Last Page"
            round
            justIcon
          >
            <LastPageIcon />
          </Button>
        )}
      </div>
    )
  }
}

AdvancedCustomTablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number,
  hasMore: PropTypes.bool,
  onChangePage: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
const AdvancedCustomTablePaginationActionsWrapped = withStyles(tableStyle)(
  AdvancedCustomTablePaginationActions,
)

class AdvancedCustomTableToolbar extends React.Component {
  handleFilterChange = _.debounce(filterText => {
    const { onFilterChange } = this.props
    if (onFilterChange) {
      onFilterChange(filterText)
    }
  }, 500)

  render() {
    const { customActions, filterText, onRefresh, onFilterChange, dataUnique } = this.props

    return (
      <TableActionsToolbar right customActions={customActions ? _.isFunction(customActions) ? customActions({ onRefresh }) : customActions : null}>
        {onFilterChange && (
          <CustomTextField
            input={{
              name: 'txtAdvancedTableFilterInput',
              placeholder: 'Filter',
              style: {
                fontSize: 13
              },
              onChange: ev => this.handleFilterChange(ev.target.value)
            }}
            defaultValue={filterText}
            data-unique={dataUnique ? `${dataUnique}_txtFilter` : 'txtFilter'}
          />
        )}
        {onRefresh && (
          <Button smallTop round justIcon title="Refresh" aria-label="Refresh" onClick={onRefresh} data-unique={dataUnique ? `${dataUnique}_btnRefresh` : 'btnRefresh'}>
            <RefreshIcon />
          </Button>
        )}
      </TableActionsToolbar>
    )
  }
}

AdvancedCustomTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  customActions: PropTypes.any,
  filterText: PropTypes.string,
  onRefresh: PropTypes.func,
  onFilterChange: PropTypes.func,
  dataUnique: PropTypes.string
}

const AdvancedCustomTableToolbarWrapped = withStyles(tableStyle)(
  AdvancedCustomTableToolbar,
)

class AdvancedCustomTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageData: [],
      totalCount: 0,
      hasMore: false,
    }
  }

  componentDidMount() {
    this.getPageData()
  }

  componentDidUpdate(prevProps) {
    const { tableData } = this.props

    if (tableData !== prevProps.tableData) {
      this.getPageData()
    }
  }

  setTableSettings = (newSettings) => {
    const { name, dataUnique, settings, setTableSettings } = this.props

    setTableSettings(name || dataUnique || window.location.pathname, {
      ...settings,
      ...newSettings
    })
  }

  handleChangePage = (event, page) => {
    const { settings, onPageChange } = this.props

    settings.page = page
    this.setTableSettings({ page })
    if (onPageChange) {
      onPageChange(settings.page * settings.rowsPerPage, settings.rowsPerPage + 1, settings.filterText)
    } else {
      this.getPageData()
    }
  }

  handleChangeRowsPerPage = event => {
    const { settings, onPageChange } = this.props

    settings.rowsPerPage = event.target.value
    this.setTableSettings({ rowsPerPage: event.target.value  })
    if (onPageChange) {
      onPageChange(settings.page * settings.rowsPerPage, event.target.value + 1, settings.filterText)
    } else {
      this.getPageData()
    }
  }

  handleFilterChange = filterText => {
    const { settings, onPageChange } = this.props

    settings.filterText = filterText
    this.setTableSettings({ filterText })
    if (onPageChange) {
      onPageChange(settings.page * settings.rowsPerPage, settings.rowsPerPage + 1, filterText)
    } else {
      this.getPageData()
    }
  }

  getPageData() {
    const { settings, onPageChange, disableFooter } = this.props

    const tableData = _.isFunction(this.props.tableData) ? this.props.tableData(settings) : this.props.tableData
    const getPage = Math.max(0, settings.page)

    if (onPageChange) {
      if (disableFooter) {
        this.setState({
          pageData: tableData || [],
          hasMore: false,
          totalCount: tableData || [],
        })
      } else {
        this.setState({
          pageData: (tableData || []).slice(0, settings.rowsPerPage),
          hasMore: (tableData || []).length > settings.rowsPerPage,
        })
      }
    } else {
      let filteredData = tableData || []
      if (settings.filterText) {
        const isCellAccepted = (cell) => {
          const cellValue = cell.value || cell
          let asString
          if (_.isFunction(cellValue)) {
            const cellAsResult = cellValue()
            asString = cellAsResult ? reactToString(cell()) : null
          } else if (_.isObject(cellValue)) {
            asString = reactToString(cellValue)
          } else {
            try {
              asString = JSON.stringify(cellValue)
            } catch (err) {}
          }

          return asString && asString
          .toLowerCase()
          .indexOf(settings.filterText.toLowerCase()) >= 0
        }
        filteredData = (tableData || []).filter(
          td => {
            let row = td
            if (!_.isArray(row)) {
              row = [row]
            }

            for (const cell of row) {
              if (cell && isCellAccepted(cell)) {
                return true
              }
            }
            return false
          },
        )
      }
      if (disableFooter) {
        this.setState({
          pageData: filteredData,
          hasMore: false,
          totalCount: filteredData.length,
        })
      } else {
        const getFilteredPage = Math.min(getPage, filteredData.length === 0 ? 0 : Math.ceil(filteredData.length / settings.rowsPerPage) - 1)

        if (getFilteredPage !== settings.page) {
          this.setTableSettings({ page: getFilteredPage })
        }
        this.setState({
          pageData: filteredData.slice(
            getFilteredPage * settings.rowsPerPage,
            getFilteredPage * settings.rowsPerPage + settings.rowsPerPage,
          ),
          hasMore: filteredData.length > (getFilteredPage + 1) * settings.rowsPerPage,
          totalCount: filteredData.length,
        })
      }
    }
  }

  render() {
    const { pageData, hasMore, totalCount } = this.state
    const {
      classes,
      settings,
      tableHead,
      tableHeaderColor,
      onRefresh,
      pageLoading,
      pageErr,
      onPageChange,
      maxCount,
      disableHeader,
      disableFilter,
      disableFooter,
      disablePageSize,
      dense,
      customActions,
      additionalHeader,
      additionalFooter,
      truncateChip = 200,
      truncateText = 100
    } = this.props

    const dataUnique = this.props.dataUnique || this.props.name
    return (
      <React.Fragment>
        {!disableHeader && (
          <AdvancedCustomTableToolbarWrapped
            onRefresh={onRefresh || undefined}
            customActions={customActions}
            filterText={settings.filterText}
            onFilterChange={disableFilter ? null : filterText => this.handleFilterChange(filterText)}
            dataUnique={dataUnique}
          />
        )}
        {additionalHeader}
        <div className={classes.tableResponsive}>
          <Table className={classNames({ [classes.table]: true, [classes.tableDense]: dense })} data-unique={dataUnique} data-length={(!pageErr && !pageLoading && pageData.length) || 0} data-loading={pageLoading ? 'loading' : ''}>
            {tableHead !== undefined ? (
              <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
                <TableRow>
                  {tableHead.map((prop, colIndex) => {
                    return (
                      <TableCell
                        className={classNames({
                          [classes.tableCell]: true,
                          [classes.tableHeadCell]: true,
                          [classes.tableCellRight]: !!prop.right,
                          [classes.tableCellWidthLarge]: prop.width === 'large',
                          [classes.tableCellWidthMedium]: prop.width === 'medium',
                          [classes.tableCellWidthSmall]: prop.width === 'small',
                          [classes.tableCellWidthSmallSecondary]: prop.width === 'smallsecondary',
                        })}
                        key={colIndex}
                        data-unique={dataUnique && `${dataUnique}_H${colIndex}`}
                      >
                        {prop.orderByField && <LinkButton onClick={() => {
                          if (settings.orderByField === prop.orderByField) {
                            if (settings.orderByOrder === 'asc') {
                              this.setTableSettings({ orderByOrder: 'desc', page: 0 })
                            } else {
                              this.setTableSettings({ orderByOrder: 'asc', page: 0 })
                            }
                          } else {
                            this.setTableSettings({ orderByField: prop.orderByField, orderByOrder: prop.orderByDirection || 'asc', page: 0 })
                          }
                          setTimeout(() => this.getPageData(), 0)
                        }}>
                          {prop.name}
                          {settings.orderByField && settings.orderByField === prop.orderByField && <>
                            {settings.orderByOrder === 'desc' && <ShowIcon icon="sort-down" />}
                            {settings.orderByOrder === 'asc' && <ShowIcon icon="sort-up" />}
                          </>}
                          {settings.orderByField && settings.orderByField !== prop.orderByField && <ShowIcon icon="sort-up" hidden />}
                          {!settings.orderByField && prop.orderByDefault === 'desc' && <ShowIcon icon="sort-down" />}
                          {!settings.orderByField && prop.orderByDefault === 'asc' && <ShowIcon icon="sort-up" />}
                        </LinkButton>}

                        {!prop.orderByField && prop.onClick && <LinkButton onClick={prop.onClick}>{prop.name}</LinkButton>}
                        {!prop.orderByField && !prop.onClick && (prop.name || prop)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {!pageErr && pageLoading && <TableRow><TableCell className={classes.tableCell} classes={{ body: classes.tableCellBody }} colSpan={tableHead && tableHead.length}><LoadingIndicator box large /></TableCell></TableRow>}
              {pageErr && <TableRow><TableCell className={classes.tableCell} classes={{ body: classes.tableCellBody }} colSpan={tableHead && tableHead.length}><ErrorFormat err={pageErr} /></TableCell></TableRow>}
              {!pageErr && !pageLoading && pageData.map((prop, rowIndex) => {
                if (_.isArray(prop)) {
                  return (
                    <TableRow key={rowIndex} data-unique={dataUnique && `${dataUnique}_${rowIndex}`}>
                      {prop.map((prop, colIndex) => {
                        return (
                          <TableCell className={classNames({ [classes.tableCell]: true, [classes.tableCellRight]: !!(tableHead && tableHead[colIndex].right) })} classes={{ body: classes.tableCellBody }} key={colIndex} data-unique={dataUnique && `${dataUnique}_${rowIndex}_${colIndex}`}>
                            {renderCell(prop, { truncateChip, truncateText })}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                } else {
                  // the prop must be an already rendered row
                  return prop
                }
              })}
            </TableBody>
          </Table>
        </div>
        {!disableFooter && (<>
          {additionalFooter}
          <TablePagination
            classes={{
              selectRoot: classes.selectMenuPagination,
              caption: classes.captionPagination,
              select: classes.selectPagination,
              selectIcon: classes.selectIconPagination,
            }}
            component="div"
            count={onPageChange ? (maxCount || -1) : totalCount}
            labelDisplayedRows={
              onPageChange
                ? ({ from, to, count }) => (maxCount ? `${from}-${from + pageData.length - 1} of ${maxCount}` :  `${from}-${from + pageData.length - 1}`)
                : ({ from, to, count }) => `${from}-${to} of ${count}`
            }
            rowsPerPage={settings.rowsPerPage}
            rowsPerPageOptions={disablePageSize ? [] : [5, 10, 25, 50, 100]}
            page={settings.page}
            onChangePage={() => ({})}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={() => (<>
              <AdvancedCustomTablePaginationActionsWrapped
                count={onPageChange ? (maxCount || -1) : totalCount}
                rowsPerPage={settings.rowsPerPage}
                hasMore={hasMore}
                pageLoading={pageLoading}
                page={settings.page}
                onChangePage={this.handleChangePage}
              />
            </>)}
          />
        </>)}
      </React.Fragment>
    )
  }
}

AdvancedCustomTable.defaultProps = {
  tableHeaderColor: 'gray',
}

AdvancedCustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  dataUnique: PropTypes.string,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.any),
  tableData: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.func]),
  onRefresh: PropTypes.func,
  pageLoading: PropTypes.bool,
  pageErr: PropTypes.any,
  onPageChange: PropTypes.func,
  maxCount: PropTypes.number,
  disableHeader: PropTypes.bool,
  disableFilter: PropTypes.bool,
  disableFooter: PropTypes.bool,
  disablePageSize: PropTypes.bool,
  dense: PropTypes.bool
}

export default connect(
  (state, ownProps) => ({
    settings: Object.assign(
      {},
      defaultTableSettings,
      state.table[ownProps.name || ownProps.dataUnique || window.location.pathname],
    ),
  }),
  { clearTableSettings, setTableSettings },
)(withStyles(tableStyle)(AdvancedCustomTable))
