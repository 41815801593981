import React from 'react'
import withTheme from '@material-ui/core/styles/withTheme'
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer, Surface, Symbols } from 'recharts'
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent'
import _ from 'lodash'
import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import { compose } from 'react-apollo'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomTooltip = props => {
  if (!props.active) {
    return null
  }
  const newPayload = props.tooltipFormatter(props.payload)
  return <DefaultTooltipContent {...props} payload={newPayload} />
}

class ScatterChartComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: []
    }
  }

  handleCustomizedLegendClick (dataKey) {
    const { disabled } = this.state

    if (_.includes(disabled, dataKey)) {
      this.setState({
        disabled: disabled.filter(obj => obj !== dataKey)
      })
    } else {
      this.setState({ disabled: disabled.concat(dataKey) })
    }
  }

  renderCustomizedLegend ({ payload }) {
    const { theme } = this.props
    const { disabled } = this.state
    const style = {
      marginRight: 10,
      whiteSpace: 'nowrap',
      color: theme.colors.primary,
      cursor: 'pointer'
    }

    return (
      <div className="customized-legend" style={{ height: 100, overflow: 'auto' }}>
        {_.sortBy(payload, e => e.dataKey).map((entry, index) => {
          const { dataKey, color } = entry
          const seriesDisabled = _.includes(disabled, dataKey)
          const legendItemStyle = {
            ...style,
            color: seriesDisabled ? theme.colors.primary50 : theme.colors.primary
          }

          return (
            <span className="legend-item" onClick={() => this.handleCustomizedLegendClick(dataKey)} style={legendItemStyle} key={index}>
              <Surface width={10} height={10}>
                {!seriesDisabled && <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />}
                {seriesDisabled && <Symbols cx={5} cy={5} type="circle" size={25} fill={theme.colors.primary50}/>}
              </Surface>
              <span style={{ marginLeft: 3 }}>{dataKey}</span>
            </span>
          )
        })}
        <span className="legend-item" onClick={() => this.setState({ disabled: [] })} style={style} key={'show_all'}>
          Show All
        </span>
        <span className="legend-item" onClick={() => this.setState({ disabled: payload.map(e => e.dataKey) })} style={style} key={'show_none'}>
          Hide All
        </span>
      </div>
    )
  }

  render() {
    const { labelField, tooltipFormatter, data } = this.props
    const hasSize = true//data.find(entry => entry.values.find(value => _.isNumber(value.size)))
    const { disabled } = this.state

    return (<>
      <ResponsiveContainer width="100%" minHeight={300}>
        <ScatterChart>
          <CartesianGrid strokeDasharray="2 2"/>
          <XAxis type="number" dataKey="x" name="x"  hide/>
          <YAxis type="number" dataKey="y" name="y" hide/>
          {hasSize && <ZAxis dataKey={'size'} range={[50, 200]} name="size"  />}
          {tooltipFormatter && <Tooltip cursor={{ strokeDasharray: '2 2' }} content={<CustomTooltip tooltipFormatter={tooltipFormatter}/>}/>}
          {!tooltipFormatter && <Tooltip cursor={{ strokeDasharray: '2 2' }} />}
          {data.filter(series => !_.includes(disabled, series.name)).map((series, index) =>
            <Scatter name={series.name} data={series.values} fill={series.color}  shape={series.shape || 'circle'} key={index}>
              {labelField && <LabelList dataKey={labelField} />}
            </Scatter>
          )}
        </ScatterChart>
      </ResponsiveContainer>
     </>
    )
  }
}

export default compose(
  withStyles(testsetsStyle),
) (withTheme()(ScatterChartComponent))
