import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import AvatarImage from 'components/Avatar/AvatarImage'
import LastChange from 'components/Info/LastChange'

import chatbotsStyle from 'assets/jss/material-dashboard-react/views/chatbotsStyle.jsx'
import {
  CHATBOTS_COUNT_PAGINATED_QUERY,
  CHATBOTS_WITH_STATUS_PAGINATED_QUERY
} from '../Chatbots/gql'
import Text from 'components/Typography/Text'
import {addNamespaceToWhere} from '../helper'

class ChatbotSelector extends React.Component {
  render() {
    const { title, subtitle, link, queryVariables, namespace } = this.props

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <Text header>{title || 'Chatbots'}</Text>
              <Text subheader>
                {subtitle || 'Listing all your Chatbots'}
              </Text>
            </CardHeader>
              <CardBody noPadding>
                <GridContainer>
                  <GridItem xs={12}>
                    <ServerSidePagingTable
                      gqlQuery={{
                        query: CHATBOTS_WITH_STATUS_PAGINATED_QUERY,
                        variables: queryVariables || {}
                      }}
                      gqlCountQuery={{
                        query: CHATBOTS_COUNT_PAGINATED_QUERY,
                        countValue: data => data.chatbotsCount
                      }}
                      gqlVariables={(filterString) => addNamespaceToWhere(namespace,filterString && ({
                        where: {name_contains: filterString },
                        connectorFilter: filterString
                      }))}
                      name="Chatbots"
                      tableHeaderColor="primary"
                      tableHead={[
                        { name: 'Name', orderByField: 'name', orderByDefault: 'asc' },
                        { name: 'Last Change', orderByField: 'updatedAt' },
                        { name: 'Namespace', orderByField: 'namespace', orderByDefault: 'asc' },
                        'Chatbot'
                      ]}
                      tableData={data =>
                        data && data.chatbots &&
                        data.chatbots.map(a => [
                        {
                          value: a.name,
                          href: link + a.id,
                        },
                        () => <LastChange {...a} />,
                        a.namespace ? {
                          value: a.namespace,
                          href: `/namespaces/${a.namespace}`,
                        } : null,
                        {
                          value: <AvatarImage avatar={a.avatar} containermode={a.containermode} chatbotId={a.id}/>,
                          href: '/chatbots/view/' + a.id,
                        }
                      ])}
                    />
                  </GridItem>
                </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, namespace: state.namespace })
)(withStyles(chatbotsStyle)(ChatbotSelector))
