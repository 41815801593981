import _ from 'lodash'

export const isConfidence = (maybeNumber) => !_.isNil(maybeNumber) && _.isNumber(maybeNumber)
export const toConfidence = (maybeNumber) => toFixedDecimals(maybeNumber, 4)

export const toFixedDecimals = (maybeNumber, numDecimals) => {
  if (_.isNil(maybeNumber)) {
    return 'N/A'
  }
  if (!_.isNumber(maybeNumber)) {
    return 'Illegal number format!'
  }
  return maybeNumber.toFixed(numDecimals).replace(/(\.0+|0+)$/, '')
}
