import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from 'components/Tooltip/Tooltip'
import Field from 'components/Form/OptionalField'
import { Form } from 'react-final-form'
// apollo
import { Mutation, withApollo } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Chip from 'components/Chip/Chip'
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/Button/Button'
import SimpleBar from 'components/Stats/Charts/SimpleBar'
import LastChange from 'components/Info/LastChange'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import TestSetImport from './TestSetImport.jsx'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import { removeRecentListEntry } from 'actions/activity'

import ShowIcon from 'components/Icon/ShowIcon'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  RefetchTestSetQueries,
  TESTSETS_COUNT_PAGINATED_QUERY,
  QUICKCREATE_TESTSET,
  CREATE_TESTSET,
  TESTSETS_WITH_STATUS_PAGINATED_QUERY,
  DELETE_TESTSET
} from './gql'
import { TAGS_QUERY } from '../Settings/gql'

import { hasPermission, hasAnyPermission } from 'botium-box-shared/security/permissions'
import ExpansionPanel from 'components/Expansion/ExpansionPanel.jsx'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary.jsx'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails.jsx'
import Divider from 'components/Divider/Divider.js'
import arrayMutators from 'final-form-arrays'

import {
  renderTextField,
  required,
  TableActionsToolbar
} from 'components/Form/Form'
import DropdownButton from 'components/Button/DropdownButton.jsx'
import Text from 'components/Typography/Text.jsx'
import SimpleConfirmationDialog from 'components/Dialog/SimpleConfirmationDialog.jsx'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'
import { addNamespaceToWhere } from '../helper'

class TestSets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newTestSetExpanded: false,
      confirmDeleteTestset: null,
      serverSidePagingKey: 0,
      testsetUploading: false
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  render() {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, user, client, classes, namespace, removeRecentListEntry } = this.props

    return (
      <GridContainer>
        <GridItem xs={12}>
          {hasPermission(user, 'TESTSETS_CREATE') &&
            <ExpansionPanel expanded={this.state.newTestSetExpanded} data-unique="pnlNewTestSet">
              <ExpansionPanelSummary data-click="false" onClick={() => this.setState({ newTestSetExpanded: !this.state.newTestSetExpanded })}>
                <Text header>Create new Test Set</Text>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <GridContainer nounset justify="space-between">
                  <GridItem xs={5}>
                    <Mutation
                      mutation={QUICKCREATE_TESTSET}
                      refetchQueries={() => {
                        return [
                          ...RefetchTestSetQueries()
                        ]
                      }}
                    >
                      {(quickCreateTestSet, { loading, error }) => (
                        <Form
                          onSubmit={async (values, form) => {
                            try {
                              this.setState({
                                testsetUploading: true
                              })
                              const res = await quickCreateTestSet({
                                variables: {
                                  filename: values.filename,
                                  filecontent: values.filecontent,
                                  namespace: namespace.selected ? namespace.selected.name : undefined
                                },
                              })
                              this.setState({
                                testsetUploading: false
                              })
                              form.initialize(res.data.quickCreateTestSet)
                              setAlertSuccessMessage('File uploaded')
                              history.push(`/testsets/view/${res.data.quickCreateTestSet.id}`)
                            } catch (error) {
                              this.setState({
                                testsetUploading: false
                              })
                              setAlertErrorMessage('File upload failed', error)
                            }
                          }}
                          render={({
                            handleSubmit
                          }) => (
                            <form onSubmit={handleSubmit} >
                              <UnsavedFormSpy />
                              <TestSetImport testsetUploading={this.state.testsetUploading} dataUnique="fileTestSetQuickCreateTestCaseFile" onFileLoaded={() => {
                                handleSubmit()
                              }} />
                            </form>
                          )}
                        />)}
                    </Mutation>
                  </GridItem>
                  <GridItem xs={2} center>
                    <Divider text="or" orientation="vertical" secondary />
                  </GridItem>
                  <GridItem xs={5}>
                    <Mutation
                      mutation={CREATE_TESTSET}
                      refetchQueries={[
                        ...RefetchTestSetQueries(),
                        {
                          query: TAGS_QUERY
                        }
                      ]}
                    >
                      {(mutateTestSet, { loading, error }) => (
                        <Form
                          mutators={{ ...arrayMutators }}
                          onSubmit={async (values, form) => {
                            try {
                              const res = await mutateTestSet({
                                variables: {
                                  testSet: {
                                    name: values.name,
                                    namespace: namespace.selected ? namespace.selected.name : undefined
                                  }
                                }
                              })
                              form.initialize(res.data.createTestSet)
                              setAlertSuccessMessage('Test Set registered')
                              history.push(`/testsets/view/${res.data.createTestSet.id}/settings`)
                            } catch (error) {
                              setAlertErrorMessage(`Test Set update failed`, error)
                            }
                          }}
                          render={({
                            handleSubmit,
                            submitting,
                            invalid,
                            form: { change }
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <UnsavedFormSpy />
                              <GridContainer>
                                <GridItem xs={12}>
                                  <Text header>New Empty Test Set</Text>
                                </GridItem>
                                <GridItem xs={12} left>
                                  <TableActionsToolbar>
                                    <Field
                                      name="name"
                                      component={renderTextField}
                                      placeholder="Enter Test Set Name"
                                      label="New Empty Test Set"
                                      validate={required}
                                      data-unique="txtTestSetSlimTestSetName"
                                      noLabel
                                    />
                                      <Button
                                        title="Save"
                                        aria-label="Save"
                                        disabled={invalid || submitting}
                                        data-unique="btnTestSetSaveAndContinueCustom"
                                        onClick={() => {
                                          change('type', 'custom')
                                          handleSubmit()
                                        }}
                                        justIconSolid
                                      >
                                        {submitting && <LoadingIndicator alt />}
                                        {!submitting && <ShowIcon icon="plus" />}
                                      </Button>
                                  </TableActionsToolbar>
                                </GridItem>
                              </GridContainer>
                            </form>
                          )}
                        />
                      )}
                    </Mutation>
                  </GridItem>
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          }
          <Card>
            <CardHeader>
              <Text header>Test Sets</Text>
              <Text subheader>
                Listing all your available Test Sets
              </Text>
            </CardHeader>
            <CardBody noPadding className={classes.tablePosition}>
              <GridContainer >
                {this.state.confirmDeleteTestset &&
                  <SimpleConfirmationDialog danger
                    showConfirmationDialog={!!this.state.confirmDeleteTestset}
                    confirmationText={`When deleting the Test Set "${this.state.confirmDeleteTestset.name}", all configuration settings are removed. If it has been used in Test Sessions or Test Projects already, those will miss configuration as well. Are you sure you want to delete it ?`}
                    requireCheck={true}
                    onOk={async () => {
                      try {
                        await client.mutate({
                          mutation: DELETE_TESTSET,
                          variables: {
                            id: this.state.confirmDeleteTestset.id
                          },
                          fetchPolicy: 'no-cache',
                          refetchQueries: [...RefetchTestSetQueries()]
                        })
                        removeRecentListEntry({ url: `/testsets/view/${this.state.confirmDeleteTestset.id}` })
                        setAlertSuccessMessage('Test Set deleted')
                        this.setState({ serverSidePagingKey: this.state.serverSidePagingKey + 1 })
                      } catch (error) {
                        setAlertErrorMessage('Test Set deletion failed', error)
                      }
                      this.setState({ confirmDeleteTestset: null })
                    }}
                    onCancel={() => this.setState({ confirmDeleteTestset: null })}
                  />
                }
                <GridItem xs={12} className={classes.testsetServerSidePagingTable}>
                  <ServerSidePagingTable key={`TestSetsKey_${namespace?.selected?.name}_${this.state.serverSidePagingKey}`}
                    gqlQuery={{
                      query: TESTSETS_WITH_STATUS_PAGINATED_QUERY,
                      notifyOnNetworkStatusChange: true
                    }}
                    gqlCountQuery={{
                      query: TESTSETS_COUNT_PAGINATED_QUERY,
                      countValue: data => data.testsetsCount
                    }}
                    gqlVariables={(filterString) => addNamespaceToWhere(namespace,filterString && ({ where: { name_contains: filterString } }))}
                    name="TestSets"
                    tableHeaderColor="primary"
                    tableHead={[
                      { name: 'Name', orderByField: 'name', orderByDefault: 'asc' },
                      { name: 'Namespace', orderByField: 'namespace', orderByDefault: 'asc' },
                      { name: 'Last Change', orderByField: 'updatedAt' },
                      { name: 'Convos', orderByField: 'statsConvoCount' },
                      { name: 'Utterances', orderByField: 'statsUtterancesCount' },
                      { name: 'Test Parameter Stores', orderByField: 'statsScriptingMemoryCount' },
                      'Errors',
                      'Warnings',
                      { name: 'Actions', right: true }
                    ]}
                    tableData={data =>
                      data && data.testsets &&
                      data.testsets.map(t => [
                        {
                          value: t.name,
                          href: `/testsets/view/${t.id}/dashboard`,
                          width: 160
                        },
                        t.namespace ? {
                          value: <div className={classes.namespace}>{t.namespace}</div>,
                          href: `/namespaces/${t.namespace}`,
                        } : null,
                        () => <LastChange {...t} />,
                        () => <Tooltip title={`${t.statsConvoCount || '0'} Convos`} key={'convos'}><Chip variant="convo" label={t.statsConvoCount || 0} /></Tooltip>,
                        () => <Tooltip title={`${t.statsUtterancesCount || '0'} Utterances`} key={'utterances'}><Chip variant="utterance" label={t.statsUtterancesCount || 0} /></Tooltip>,
                        () => <Tooltip title={`${t.statsScriptingMemoryCount || '0'} Test Parameter Stores`} key={'scriptingmemories'}><Chip variant="scripting" label={t.statsScriptingMemoryCount || 0} /></Tooltip>,
                        () => (t.statsWarnings || []).filter(w => w.severity === 'ERROR').length > 0 && <Tooltip title={_.uniq((t.statsWarnings || []).filter(w => w.severity === 'ERROR').map(w => w.name)).join(' | ')} key={'errors'}><Chip variant="error" label={(t.statsWarnings || []).filter(w => w.severity === 'ERROR').length} /></Tooltip>,
                        () => (t.statsWarnings || []).filter(w => w.severity === 'WARNING').length > 0 && <Tooltip title={_.uniq((t.statsWarnings || []).filter(w => w.severity === 'WARNING').map(w => w.name)).join(' | ')} key={'warnings'}><Chip variant="warning" label={(t.statsWarnings || []).filter(w => w.severity === 'WARNING').length} /></Tooltip>,
                        () => <Tooltip title="Action"><DropdownButton
                          dots
                          aria-label="Actions"
                          items={[
                            {
                              id: 'open',
                              icon: 'folder-open',
                              name: 'Open',
                              onClick: () => history.push(`/testsets/view/${t.id}/dashboard`)
                            },
                            {
                              id: 'settings',
                              icon: 'cog',
                              name: 'Configuration',
                              onClick: () => history.push(`/testsets/view/${t.id}/settings`)
                            },
                            {
                              id: 'delete',
                              icon: 'trash',
                              name: 'Delete',
                              onClick: () => this.setState({ confirmDeleteTestset: t })
                            }]}></DropdownButton></Tooltip>
                      ])}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  renderLastTestSetInsights(lastCoachSessions) {
    const session = lastCoachSessions && lastCoachSessions.length > 0 ? lastCoachSessions[0].coachSession : undefined
    if (session) {
      if (session.kfoldReady && session.kfoldF1) {
        return <SimpleBar width={100} progress={session.kfoldF1} maxRed={0.65}
          minGreen={0.85}>{`${Math.round(session.kfoldF1 * 100)}/100`}</SimpleBar>
      }
    }
    return null
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace }),
  { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry }
)(withStyles(testsetsStyle)(withApollo(TestSets)))
