import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import TablePagination from 'components/Table/CustomTablePagination'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import List from '@material-ui/core/List'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from 'components/List/ListItem/ListItemIcon'
import ListItemText from 'components/List/ListItem/ListItemText'
// apollo
import { compose, graphql, Query } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import DropdownButton from 'components/Button/DropdownButton'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import DateFormat from 'components/Info/DateFormat'
import ImageTiles from 'components/Convo/ImageTiles'
import Transcript from 'components/Convo/Transcript.jsx'
import { CustomCheckbox, CustomSelect, CustomTextField } from 'components/Form/Form'
import ErrorFormat from 'components/Info/ErrorFormat'
import ShowIcon from 'components/Icon/ShowIcon'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import { getRootPath } from './helper'
import Chip from 'components/Chip/Chip'


import config from 'config'

import TestSessionProgress from './TestSessionProgress.jsx'
import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'

import {
  TESTSESSION_RESULTCOUNT_QUERY,
  TESTSESSION_RESULTCOUNT_SUBSCRIPTION,
  RETRY_TESTSESSION,
  TESTCASERESULT_DETAILS_QUERY,
  TESTSESSION_RESULTS_QUERY,
  TESTSESSION_RESULTS_SUBSCRIPTION,
  DeleteTestSessionListsFromCache,
  RefetchTestSessionQueries,
  TESTSESSIONTREND_QUERY
} from './gql'

import {
  START_TESTPROJECT,
  RefetchTestProjectQueriesOnNewTestSession
} from '../TestProjects/gql'


import Text from 'components/Typography/Text'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { decodeURIComponentWeak } from 'views/helper'
import { hasPermission, canWriteNamespace } from 'botium-box-shared/security/permissions.js'
import { connect } from 'react-redux'
import TestSessionProgressIcon from './TestSessionProgressIcon.jsx'
import Tooltip from 'components/Tooltip/Tooltip.js'
import Divider from 'components/Divider/Divider.js'
import { START_FACTCHECKERPROJECT } from 'views/LLMprojects/gql.js'



export const sortTypes = {
  TESTCASENAME_ASC: {
    display: 'Test Case Name',
    orderBy: 'testcaseName_ASC'
  },
  DURATION_DESC: {
    display: 'Duration (longest first)',
    orderBy: 'duration_DESC'
  },
  DURATION_ASC: {
    display: 'Duration (quickest first)',
    orderBy: 'duration_ASC'
  },
  CREATE_AT_DESC: {
    display: 'Timestamp (latest first)',
    orderBy: 'createdAt_DESC'
  },
  CREATE_AT_ASC: {
    display: 'Timestamp (oldest first)',
    orderBy: 'createdAt_ASC'
  }
}
Object.keys(sortTypes).forEach(k => sortTypes[k].key = k)

const addFilterMode = (filterMode, where) => {
  if (filterMode === filterModes.NOT_CONTAINS) {
    return {
      NOT: where
    }
  }
  return where
}

// if you want to add filter logic just put it here :)
export const filterTypes = {
  TEST_CASENAME: {
    getWhere: ({ filterText, filterMode }) => {
      return addFilterMode(filterMode, {
        testcaseName_contains: filterText
      })
    },
    display: 'Test Case'
  },
  TESTSET_NAME: {
    getWhere: ({ filterText, filterMode }) => {
      return addFilterMode(filterMode, {
        testSet: {
          name_contains: filterText
        }
      })
    },
    display: 'Test Set'
  },
  DEVICE_SET: {
    getWhere: ({ filterText, filterMode }) => {
      return addFilterMode(filterMode, {
        deviceSet: {
          name_contains: filterText
        }
      })
    },
    display: 'Device Set'
  },
  DEVICE_NAME: {
    getWhere: ({ filterText, filterMode }) => {
      return addFilterMode(filterMode, {
        deviceName_contains: filterText
      })
    },
    display: 'Device Name'
  },
  TESTSESSIONJOB_NAME: {
    getWhere: ({ filterText, filterMode }) => {
      return addFilterMode(filterMode, {
        testSessionJob: {
          jobName_contains: filterText
        }
      })
    },
    display: 'Test Session Job'
  },
  ERROR_MESSAGE: {
    getWhere: ({ filterText, filterMode }) => {
      return addFilterMode(filterMode, {
        err_contains: filterText
      })
    },
    display: 'Error Message'
  }
}
Object.keys(filterTypes).forEach(k => filterTypes[k].key = k)

export const filterModes = {
  CONTAINS: {
    display: 'contains'
  },
  NOT_CONTAINS: {
    display: 'doesn\'t contain'
  }
}
Object.keys(filterModes).forEach(k => filterModes[k].key = k)

class TestSessionTestCases extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testSessionProgressKey: 0,
      filter: {
        showFailed: true,
        showSucceeded: true,
        filterType: filterTypes.TEST_CASENAME,
        filterMode: filterModes.CONTAINS,
        filterText: '',
        sortType: sortTypes.TESTCASENAME_ASC,
        page: 0,
        rowsPerPage: 25,
        ...(props.filter || {})
      }
    }
    if (props.location && props.location.search) {
      const query = queryString.parse(props.location.search)
      if (query.tc) {
        this.state.filter.filterText = decodeURIComponentWeak(query.tc)
      }
    }
  }

  getRootPath = (mode) => {
    return getRootPath(this.props.location, mode === 'testsession' ? 6 : mode === 'testproject' ? 4 : null)
  }

  render() {
    const { testSession, onOpenJobId, classes, user, mutateRetryTestSession, mutateStartTestProject, mutateStartFactCheckerProject, license } = this.props
    const { filter } = this.state

    const where = { AND: [] }
    const orderBy = filter.sortType.orderBy || 'testcaseName_ASC'

    if (!filter.showFailed) {
      where.AND.push({
        success: true
      })
    }
    if (!filter.showSucceeded) {
      where.AND.push({
        success: false
      })
    }
    if (filter.filterType.getWhere && filter.filterText) {
      where.AND.push(filter.filterType.getWhere(filter))
    }

    const currentPage = filter.page || 0
    const skip = currentPage * filter.rowsPerPage
    const first = filter.rowsPerPage

    return (<TestSessionProgress
      query={TESTSESSION_RESULTCOUNT_QUERY}
      querySelector={data => data.testsessiontestcaseresultcount}
      subscription={TESTSESSION_RESULTCOUNT_SUBSCRIPTION}
      subscriptionSelector={data => data.testSessionProgressResultCount}
      variables={{ where }}
      testSession={testSession}
    >{({ testSessionProgress: testSessionResultCount }) => (
      <TestSessionProgress
        query={TESTSESSION_RESULTS_QUERY}
        querySelector={data => data.testsessiontestcaseresults}
        subscription={TESTSESSION_RESULTS_SUBSCRIPTION}
        subscriptionSelector={data => data.testSessionProgressResults}
        variables={{
          where, orderBy, skip, first
        }}
        testSession={testSession}>
        {({ testSessionProgress: testSessionResults, testSessionProgressLoading }) => {
          const resultCount = _.isNumber(testSessionResultCount) ? testSessionResultCount : 0

          const lastPage = Math.max(0, Math.ceil(resultCount / filter.rowsPerPage) - 1)
          const hasMore = resultCount > (currentPage + 1) * filter.rowsPerPage

          return (
            <GridContainer>
              <GridItem xs={12} right middle smallPadding>
                <>
                  {testSession.id && testSession.testProject && <>
                    <Text primary>Recent Test Sessions</Text>
                    <Query query={TESTSESSIONTREND_QUERY} variables={{ id: testSession.id }} fetchPolicy={'network-only'}>
                      {({ data }) => (<React.Fragment>
                        {data && data.testsessiontrend && data.testsessiontrend.nextTestSessionId &&
                          <NavLink to={`${this.getRootPath('testproject')}/results/${data.testsessiontrend.nextTestSessionId}/testcases`}>
                            <Tooltip title={data.testsessiontrend.nextTestSessionName}>
                              <Button justIcon Border>
                                <ShowIcon icon="angle-left" />
                              </Button>
                            </Tooltip>
                          </NavLink>
                        }
                        {data && data.testsessiontrend && !data.testsessiontrend.nextTestSessionId && <Button Border justIcon disabled><ShowIcon icon="angle-left" /></Button>}
                        {data && data.testsessiontrend && data.testsessiontrend.previousTestSessionId &&
                          <NavLink to={`${this.getRootPath('testproject')}/results/${data.testsessiontrend.previousTestSessionId}/testcases`}>
                            <Tooltip title={data.testsessiontrend.previousTestSessionName}>
                              <Button justIcon Border>
                                <ShowIcon icon="angle-right" />
                              </Button>
                            </Tooltip>
                          </NavLink>
                        }
                        {data && data.testsessiontrend && !data.testsessiontrend.previousTestSessionId && <Button Border justIcon disabled><ShowIcon icon="angle-right" /></Button>}
                      </React.Fragment>)}
                    </Query></>
                  }
                </>
                {hasPermission(user, 'TESTSESSIONS_CREATE') && canWriteNamespace(user, user.namespacePermissions, testSession.namespace) &&
                  <TestSessionProgress testSession={testSession} key={this.state.testSessionProgressKey}>
                    {({ testSessionProgress }) => {
                      const repeatItems = []

                      if (testSession.testProject && testSession.testProject.id) {
                        repeatItems.push({
                          id: 'repeatTestSession',
                          name: 'Repeat Full Test Session',
                          dataUnique: 'ddbtniRepeatFull',
                          disabled: testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED',
                          icon: 'play-circle',
                          onClick: () => {
                            mutateStartTestProject({
                              variables: { id: testSession.testProject.id, debug: false }
                            })
                          }
                        })
                        if (license.detailedReporting) {
                          repeatItems.push({
                            id: 'repeatTestSessionDebug',
                            name: 'Repeat Full Test Session (Extended Logging)',
                            dataUnique: 'ddbtniRepeatLog',
                            disabled: testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED',
                            icon: 'bug',
                            onClick: () => {
                              mutateStartTestProject({
                                variables: { id: testSession.testProject.id, debug: true }
                              })
                            }
                          })
                        }
                      }
                      if (testSession.factCheckerSession && testSession.factCheckerSession.project && license.detailedReporting) {
                        repeatItems.push({
                          id: 'repeatTestSessionDebug',
                          name: 'Repeat Full Test Session (Extended Logging)',
                          dataUnique: 'ddbtniRepeatLog',
                          disabled: testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED',
                          icon: 'bug',
                          onClick: () => {
                            mutateStartFactCheckerProject({
                              variables: { id: testSession.factCheckerSession.project.id, debug: true }
                            })
                          }
                        })
                      }
                      if (testSessionProgress.status !== 'READY') {
                        repeatItems.push({
                          id: 'retryTestSession',
                          name: 'Retry Failed Test Cases Only',
                          dataUnique: 'ddbtniRepeatFailed',
                          disabled: testSessionProgress.status !== 'FAILED',
                          icon: 'redo',
                          onClick: async () => {
                            await mutateRetryTestSession({
                              variables: { id: testSession.id },
                            })
                            this.setState({ testSessionProgressKey: this.state.testSessionProgressKey + 1 })
                          }
                        })
                      }
                      return <>{((testSession.testProject && testSession.testProject.id) || (testSession.factCheckerSession && testSession.factCheckerSession.project)) && <><DropdownButton
                        mini
                        data-unique="ddbtnRepeatTestSession"
                        disabled={repeatItems.length === 0}
                        items={repeatItems}
                      >
                        <ShowIcon icon="redo" /> Repeat Test Session
                      </DropdownButton></>}</>
                    }}
                  </TestSessionProgress>
                }
              </GridItem>
              <GridItem xs={12}>
                <Card noMargin>
                    <CardBody noPaddingTop noPaddingBottom>
                      <GridContainer>
                        <GridItem md={12} lg={10} borderRight className={classes.col1}>
                        <GridContainer>
                          <GridItem md={12}>
                            {testSessionProgressLoading && <LoadingIndicator large />}
                            <GridItem xs={false} noPaddingRight>
                              {!testSessionProgressLoading && <TestSessionProgressIcon testSession={testSession} hideEndStatus />}
                            </GridItem>
                            {testSessionResults && testSessionResults.length > 0 &&
                              <List component="nav" key={`testcases_${currentPage}`}>
                                {testSessionResults.map(r => {
                                  return (
                                    <React.Fragment key={r.id}>
                                      <ExpansionPanel>
                                        <ExpansionPanelSummary>
                                          <ListItem small>
                                            {r.success ?
                                              (<Text success><ShowIcon custom icon="success" /></Text>)
                                              :
                                              (<Text danger><ShowIcon custom icon="error" /></Text>)
                                            }
                                            <ListItemText
                                              primary={
                                                <Text bold>{r.testcaseName}</Text>
                                              }
                                              secondary={
                                                <Text inline>
                                                  <DateFormat seconds>{r.createdAt}</DateFormat>{r.duration && ` - ${r.duration}ms `}
                                                </Text>
                                              } />
                                          </ListItem>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                          <Query
                                            query={TESTCASERESULT_DETAILS_QUERY}
                                            variables={{ id: r.id }}
                                            fetchPolicy={'network-only'}
                                          >
                                            {({ loading, error, data }) => {
                                              if (error) {
                                                return <ErrorFormat err={error} />
                                              }
                                              if (loading) {
                                                return <LoadingIndicator large />
                                              }
                                              const rd = (data && data.testsessiontestcaseresult) || {}
                                              if (rd.attachments) {
                                                rd.attachments = _.orderBy(rd.attachments, [a => _.isNil(a.stepIndex) ? 99999 : a.stepIndex], ['asc'])
                                              }
                                              return (
                                                <GridContainer noMargin fullWidth>
                                                  <GridItem md={12}>
                                                    <GridContainer fullWidth noMargin>
                                                    {rd.testSet && (
                                                      <React.Fragment>
                                                        <GridItem md={5} lg={3} grid  >
                                                         <NavLink
                                                            tabIndex={0}
                                                            className={classes.cardLinkConnections}
                                                            to={`/testsets/view/${rd.testSet.id}`} data-unique={`btnTestSessionListTestSet_${rd.testSet.id}`}
                                                          >
                                                            <Card hoverdark noMargin borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding  small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                      <div><Text inline subheader>Test Set </Text></div>
                                                                      <Text inline bold wordBreak>{rd.testSet.name}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                      <Text primary>
                                                                        <ShowIcon moveleft icon="angle-right" />
                                                                      </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                            </Card>
                                                        </NavLink>
                                                        </GridItem>
                                                        &nbsp;
                                                      </React.Fragment>
                                                    )}
                                                    {rd.testSetScript && ['SCRIPTING_TYPE_CONVO', 'SCRIPTING_TYPE_PCONVO'].indexOf(rd.testSetScript.scriptType) >= 0 &&
                                                      rd.testSet && (
                                                        <React.Fragment>
                                                          <GridItem md={5} lg={3} grid>
                                                            <NavLink
                                                              tabIndex={0}
                                                              className={classes.cardLinkConnections}
                                                              to={`/testsets/view/${rd.testSet.id}/testcases/viewconvo/${rd.testSetScript.id}/${encodeURIComponent(rd.testSetScript.name)}`}
                                                              data-unique={`btnTestSessionListConvo_${rd.testSetScript.id}_${rd.testSetScript.name}`}
                                                            >
                                                              <Card hoverdark noMargin borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding  small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                      <div><Text inline subheader>Test Case</Text></div>
                                                                      <Text inline bold wordBreak>{rd.testSetScript.name}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                      <Text primary>
                                                                        <ShowIcon moveleft icon="angle-right" />
                                                                      </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                          &nbsp;
                                                        </React.Fragment>
                                                      )}
                                                    {rd.testSetScript && rd.testSetScript.scriptType === 'SCRIPTING_TYPE_UTTERANCES' &&
                                                      rd.testSet && (
                                                        <React.Fragment>
                                                          <GridItem md={5} lg={3} grid>
                                                            <NavLink
                                                              tabIndex={0}
                                                              className={classes.cardLinkConnections}
                                                              to={`/testsets/view/${rd.testSet.id
                                                              }/testcases/viewutterance/${rd.testSetScript.id}/${encodeURIComponent(rd.testSetScript.name)}`}
                                                            data-unique={`btnTestSessionListUtterance_${rd.testSetScript.id}_${rd.testSetScript.name}`}
                                                            >
                                                              <Card hoverdark noMargin borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding  small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                      <div><Text inline subheader>Utterances List</Text></div>
                                                                      <Text inline bold wordBreak>{rd.testSetScript.name}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                      <Text primary>
                                                                        <ShowIcon moveleft icon="angle-right" />
                                                                      </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                          &nbsp;
                                                        </React.Fragment>
                                                      )}
                                                    {rd.testSetScript && ['SCRIPTING_TYPE_CONVO', 'SCRIPTING_TYPE_PCONVO', 'SCRIPTING_TYPE_UTTERANCES'].indexOf(rd.testSetScript.scriptType) < 0 &&
                                                      rd.testSet && (
                                                        <React.Fragment>
                                                          <GridItem md={5} lg={3} grid>
                                                            <NavLink
                                                              tabIndex={0}
                                                              className={classes.cardLinkConnections}
                                                              to={`/testsets/view/${rd.testSet.id
                                                              }/testcases/viewscript/${rd.testSetScript.id}`}
                                                            data-unique={`btnTestSessionListScript_${rd.testSetScript.id}`}
                                                            >
                                                              <Card hoverdark noMargin borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding  small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                      <div><Text inline subheader>Script</Text></div>
                                                                      <Text inline bold wordBreak>{rd.testSetScript.name}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                      <Text primary>
                                                                        <ShowIcon moveleft icon="angle-right" />
                                                                      </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                          &nbsp;
                                                        </React.Fragment>
                                                      )}
                                                    {rd.testSetRepository &&
                                                      rd.testSet && (
                                                        <React.Fragment>
                                                          <GridItem md={5} lg={3} grid>
                                                            <NavLink
                                                              tabIndex={0}
                                                              className={classes.cardLinkConnections}
                                                              to={`/testsets/view/${rd.testSet.id
                                                              }/settings/remote/viewrepository/${rd.testSetRepository.id}`}
                                                            data-unique={`btnTestSessionListRepository_${rd.testSetRepository.id}`}
                                                            >
                                                              <Card hoverdark noMargin borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding  small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                      <div><Text inline subheader>Repository</Text></div>
                                                                      <Text inline bold wordBreak>{rd.testSetRepository.name}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                      <Text primary>
                                                                        <ShowIcon moveleft icon="angle-right" />
                                                                      </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                          &nbsp;
                                                        </React.Fragment>
                                                      )}
                                                    {rd.testSetFolder &&
                                                      rd.testSet && (
                                                        <React.Fragment>
                                                          <GridItem md={5} lg={3} grid>
                                                            <NavLink
                                                              tabIndex={0}
                                                              className={classes.cardLinkConnections}
                                                              to={`/testsets/view/${rd.testSet.id
                                                              }/settings/remote/viewfolder/${rd.testSetFolder.id}`}
                                                            data-unique={`btnTestSessionListFolder_${rd.testSetFolder.id}`}
                                                            >
                                                              <Card hoverdark noMargin borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding  small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                      <div><Text inline subheader>Folder</Text></div>
                                                                      <Text inline bold wordBreak>{rd.testSetFolder.name}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                      <Text primary>
                                                                        <ShowIcon moveleft icon="angle-right" />
                                                                      </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                          &nbsp;
                                                        </React.Fragment>
                                                      )}
                                                    {rd.testSetDownloadLink &&
                                                      rd.testSet && (
                                                        <React.Fragment>
                                                          <GridItem md={5} lg={3} grid>
                                                            <NavLink
                                                              tabIndex={0}
                                                              className={classes.cardLinkConnections}
                                                              to={`/testsets/view/${rd.testSet.id
                                                              }/settings/remote/viewdownloadlink/${rd.testSetDownloadLink.id}`}
                                                            data-unique={`btnTestSessionListDownloadLink_${rd.testSetDownloadLink.id}`}
                                                            >
                                                              <Card hoverdark noMargin borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding  small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                      <div><Text inline subheader>Download Link</Text></div>
                                                                      <Text inline bold wordBreak>{rd.testSetDownloadLink.name}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                      <Text primary>
                                                                        <ShowIcon moveleft icon="angle-right" />
                                                                      </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                          &nbsp;
                                                        </React.Fragment>
                                                      )}
                                                    {rd.testSetExcel &&
                                                      rd.testSet && (
                                                        <React.Fragment>
                                                          <GridItem md={5} lg={3} grid>
                                                            <NavLink
                                                              tabIndex={0}
                                                              className={classes.cardLinkConnections}
                                                              to={`/testsets/view/${rd.testSet.id
                                                              }/testcases/viewexcel/${rd.testSetExcel.id}`}
                                                            data-unique={`btnTestSessionListExcel_${rd.testSetExcel.id}`}
                                                            >
                                                              <Card hoverdark noMargin borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding  small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                      <div><Text inline subheader>Excel</Text></div>
                                                                      <Text inline bold wordBreak>{rd.testSetExcel.name}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                      <Text primary>
                                                                        <ShowIcon moveleft icon="angle-right" />
                                                                      </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                          &nbsp;
                                                        </React.Fragment>
                                                      )}
                                                    {rd.testSessionJob && (
                                                      <React.Fragment>
                                                        <GridItem md={5} lg={3} grid>
                                                            <NavLink
                                                              tabIndex={0}
                                                              className={classes.cardLinkConnections}
                                                              to={`${this.getRootPath('testsession')}/jobs`}
                                                              data-unique={`btnTestSessionJobs_${rd.testSessionJob.id}`}
                                                              onClick={() => {
                                                                onOpenJobId(rd.testSessionJob.id)
                                                              }}
                                                            >
                                                              <Card hoverdark noMargin borderSecondary>
                                                                <CardBody noPadding>
                                                                  <GridContainer autoHeight>
                                                                    <GridItem xs={12} middle>
                                                                      <ListItem compactPadding small>
                                                                        <ListItemIcon>
                                                                          <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="fa-gears" />} />
                                                                        </ListItemIcon>
                                                                        <ListItemText>
                                                                          <div><Text inline subheader>Test Session Job</Text></div>
                                                                          <Text inline bold wordBreak>{rd.testSessionJob.jobName}</Text>
                                                                        </ListItemText>
                                                                        <ListItemIcon>
                                                                          <Text primary>
                                                                            <ShowIcon moveleft icon="angle-right" />
                                                                          </Text>
                                                                        </ListItemIcon>
                                                                      </ListItem>
                                                                    </GridItem>
                                                                  </GridContainer>
                                                                </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                          &nbsp;

                                                      </React.Fragment>
                                                    )}
                                                    {rd.testSetFilename && (
                                                      <React.Fragment>
                                                        <GridItem md={5} lg={3} grid>
                                                            <Card hoverdark noMarginTop borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                        <div><Text inline subheader>File</Text></div>
                                                                        <Text inline bold>{rd.testSetFilename}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                        <Text primary>
                                                                          <ShowIcon moveleft icon="angle-right" />
                                                                        </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                            </Card>
                                                          </GridItem>

                                                        &nbsp;
                                                      </React.Fragment>
                                                    )}
                                                    {rd.deviceSet && (
                                                      <React.Fragment>
                                                        <GridItem md={5} lg={3} grid>
                                                            <NavLink
                                                              style={{ cursor: 'pointer', display: 'contents' }}
                                                              to={`/settings/devicecloud/devicesets/${rd.deviceSet.id}`}
                                                              data-unique={`btnTestSessionListDeviceSet_${rd.deviceSet.id}`}
                                                            >
                                                              <Card hoverdark noMarginTop borderSecondary>
                                                                <CardBody noPadding>
                                                                  <GridContainer autoHeight>
                                                                    <GridItem xs={12} middle>
                                                                      <ListItem compactPadding small>
                                                                        <ListItemIcon>
                                                                          <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                        </ListItemIcon>
                                                                        <ListItemText>
                                                                          <div><Text inline subheader>Device Set</Text></div>
                                                                          <Text inline bold>{rd.deviceSet.name}</Text>
                                                                        </ListItemText>
                                                                        <ListItemIcon>
                                                                          <Text primary>
                                                                            <ShowIcon moveleft icon="angle-right" />
                                                                          </Text>
                                                                        </ListItemIcon>
                                                                      </ListItem>
                                                                    </GridItem>
                                                                  </GridContainer>
                                                                </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                        &nbsp;
                                                      </React.Fragment>
                                                    )}
                                                    {rd.deviceName && (
                                                      <React.Fragment>
                                                         <GridItem md={5} lg={3} grid>
                                                            <Card hoverdark noMarginTop borderSecondary>
                                                              <CardBody noPadding>
                                                                <GridContainer autoHeight>
                                                                  <GridItem xs={12} middle>
                                                                    <ListItem compactPadding small>
                                                                      <ListItemIcon>
                                                                        <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                                                                      </ListItemIcon>
                                                                      <ListItemText>
                                                                        <div><Text inline subheader>Device</Text></div>
                                                                        <Text inline bold>{rd.deviceName}</Text>
                                                                      </ListItemText>
                                                                      <ListItemIcon>
                                                                        <Text primary>
                                                                          <ShowIcon moveleft icon="angle-right" />
                                                                        </Text>
                                                                      </ListItemIcon>
                                                                    </ListItem>
                                                                  </GridItem>
                                                                </GridContainer>
                                                              </CardBody>
                                                            </Card>
                                                          </GridItem>
                                                        </React.Fragment>
                                                      )}
                                                    </GridContainer>
                                                    <Divider ></Divider>
                                                  </GridItem>
                                                  <GridItem md={12} >
                                                    <List component="div" disablePadding>
                                                      <Transcript
                                                        steps={rd.steps && _.orderBy(rd.steps, 'step')}
                                                        key={rd.id + '_steps'}
                                                        allowHtmlDisplay={(testSession && testSession.chatbot && testSession.chatbot.allowHtmlDisplay) || false}
                                                        chatbotId={(testSession && testSession.chatbot && testSession.chatbot.id) || null}
                                                        avatar={(testSession.chatbot && testSession.chatbot.avatar) || null}
                                                        containermode={(testSession.chatbot && testSession.chatbot.containermode) || null}
                                                        attachments={rd.attachments}
                                                        err={rd.err}
                                                      />
                                                      {!rd.steps && rd.testcaseSource && <ListItem key={rd.id + '_source'}>
                                                        <ListItemText>
                                                          <Text info>{rd.testcaseSource}</Text>
                                                        </ListItemText>
                                                      </ListItem>}
                                                      {rd.attachments && rd.attachments.length > 0 && <ListItem key={rd.id + '_att'}>
                                                        <ListItemIcon>
                                                          <GridContainer top>
                                                            <GridItem>
                                                              <div style={{ marginLeft: 8 }}>
                                                                <ShowIcon custom icon="attachment" />
                                                              </div>
                                                            </GridItem>
                                                          </GridContainer>
                                                        </ListItemIcon>
                                                        <ListItemText inset className={classes.bubbleAttachments}>
                                                          <Text bold>Attachments</Text>
                                                          <ImageTiles
                                                            images={rd.attachments.map(a => ({
                                                              downloadSrc: `${config.api.base}/attachment/screenshot/${a.id}`,
                                                              id: a.id,
                                                              title: a.name,
                                                              mimeType: a.mimeType
                                                            }))}
                                                            cards
                                                          />
                                                        </ListItemText>
                                                      </ListItem>}
                                                    </List>
                                                  </GridItem>
                                                </GridContainer>
                                              )
                                            }}
                                          </Query>
                                        </ExpansionPanelDetails>
                                      </ExpansionPanel>
                                    </React.Fragment>
                                  )
                                })}
                              </List>
                            }
                          </GridItem>
                          <GridItem md={12} right>
                            <TablePagination
                              component="div"
                              count={resultCount}
                              labelDisplayedRows={
                                ({ from, to, count }) => `${from}-${to} of ${count}`
                              }
                              rowsPerPage={filter.rowsPerPage}
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              page={currentPage}
                              onChangePage={() => ({})}
                              onChangeRowsPerPage={(event) => this.setState({ filter: { ...filter, rowsPerPage: event.target.value } })}
                              ActionsComponent={() => (
                                <React.Fragment>
                                  <Button
                                    justIcon round
                                    onClick={() => this.setState({ filter: { ...filter, page: 0 } })}
                                    disabled={currentPage === 0}
                                    aria-label="First Page"
                                  >
                                    <FirstPageIcon />
                                  </Button>
                                  <Button
                                    justIcon round
                                    onClick={() => this.setState({ filter: { ...filter, page: currentPage - 1 } })}
                                    disabled={currentPage === 0}
                                    aria-label="Previous Page"
                                  >
                                    <KeyboardArrowLeft />
                                  </Button>
                                  <Button
                                    justIcon round
                                    onClick={() => this.setState({ filter: { ...filter, page: currentPage + 1 } })}
                                    disabled={!hasMore}
                                    aria-label="Next Page"
                                  >
                                    <KeyboardArrowRight />
                                  </Button>
                                  <Button
                                    justIcon round
                                    onClick={() => this.setState({ filter: { ...filter, page: lastPage } })}
                                    disabled={!hasMore}
                                    aria-label="Last Page"
                                  >
                                    <LastPageIcon />
                                  </Button>
                                </React.Fragment>
                              )}
                            />
                          </GridItem>
                        </GridContainer>
                        </GridItem>
                        <GridItem md={12} lg={2} className={classes.col2}>
                          <GridContainer>
                            <GridItem md={8} lg={12}>
                              <CustomCheckbox
                                input={{
                                  onChange: e =>
                                    this.setState({
                                      filter: { ...filter, showFailed: e.target.checked },
                                    }),
                                  checked: filter.showFailed,
                                }}
                                label={<><Text icon="square"  danger contentCenter inline>&nbsp;</Text> <Text inline bold>Show failed</Text></>}
                                data-unique="chkTestSessionShowFailed"
                              />
                              <CustomCheckbox
                                dense
                                input={{
                                  onChange: e =>
                                    this.setState({
                                      filter: { ...filter, showSucceeded: e.target.checked },
                                    }),
                                  checked: filter.showSucceeded
                                }}
                                label={<><Text icon="square"  success contentCenter inline>&nbsp;</Text> <Text inline bold>Show succeeded</Text></>}
                                data-unique="chkTestSessionShowSucceed"
                              />
                            </GridItem>
                            <GridItem md={4} lg={12}>
                              <CustomSelect
                                data-unique="selTestSessionFilterType"
                                input={{
                                  name: 'filterType',
                                  value: filter.filterType.key,
                                  onChange: (e, child) => {
                                    this.setState({
                                      filter: { ...filter, filterType: filterTypes[e.target.value] }
                                    })
                                    return e.target.value
                                  },
                                }}
                                multiple={false}
                                filterable={false}
                                items={
                                  Object.entries(filterTypes)
                                    .map(([key, value]) => {
                                      return {
                                        key: key,
                                        label: value.display
                                      }
                                    })
                                }
                                label="Filter By"
                              />
                            </GridItem>
                            <GridItem md={4} lg={12}>
                              <CustomSelect
                                data-unique="selTestSessionFilterMode"
                                input={{
                                  name: 'filterMode',
                                  value: filter.filterMode.key,
                                  onChange: (e, child) => {
                                    this.setState({
                                      filter: { ...filter, filterMode: filterModes[e.target.value] }
                                    })
                                    return e.target.value
                                  },
                                }}
                                multiple={false}
                                filterable={false}
                                items={
                                  Object.entries(filterModes)
                                    .map(([key, value]) => {
                                      return {
                                        key: key,
                                        label: value.display
                                      }
                                    })
                                }
                                label="Filtering Mode"
                              />
                            </GridItem>
                            <GridItem md={4} lg={12}>
                              <CustomTextField
                                input={{
                                  onChange: e =>
                                    this.setState({
                                      filter: { ...filter, filterText: e.target.value },
                                    }),
                                  value: filter.filterText,
                                }}
                                label={filter.filterType.getWhere ? `Filter by ${filter.filterType.display}` : 'No Filter'}
                                disabled={!filter.filterType.getWhere}
                                data-unique="txtTestProjectStartProjectLink"
                              />
                            </GridItem>
                            <GridItem md={4} lg={12}>
                              <CustomSelect
                                data-unique="selTestSessionSortType"
                                input={{
                                  name: 'sortType',
                                  value: filter.sortType.key,
                                  onChange: (e, child) => {
                                    this.setState({
                                      filter: { ...filter, sortType: sortTypes[e.target.value] }
                                    })
                                    return e.target.value
                                  },
                                }}
                                multiple={false}
                                filterable={false}
                                items={
                                  Object.entries(sortTypes)
                                    .map(([key, value]) => {
                                      return {
                                        key: key,
                                        label: value.display
                                      }
                                    })
                                }
                                label="Sort By"
                              />
                            </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )
        }}
      </TestSessionProgress>)}
    </TestSessionProgress>)
  }
}

const TestSessionTestCasesWithGraphql = compose(
  withRouter,
  graphql(START_TESTPROJECT, {
    props: ({ mutate }) => ({
      mutateStartTestProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.startTestProject.id
        props.setAlertSuccessMessage('Test session started ...')
        props.history.push(`${getRootPath(props.location, 4)}/results/${testSessionId}/testcases`)
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestProjectQueriesOnNewTestSession(data.startTestProject.testProject.id),
        ...RefetchTestSessionQueries(data.startTestProject.id)
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
  graphql(START_FACTCHECKERPROJECT, {
    props: ({ mutate }) => ({
      mutateStartFactCheckerProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.startFactCheckerProject.id
        props.setAlertSuccessMessage('Test session started ...')
        props.history.push('/testsessions/view/' + testSessionId)
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestProjectQueriesOnNewTestSession(data.startFactCheckerProject.factCheckerSession.project.id),
        ...RefetchTestSessionQueries(data.startFactCheckerProject.id)
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
  graphql(RETRY_TESTSESSION, {
    props: ({ mutate }) => ({
      mutateRetryTestSession: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('Test session restarted ...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session restart failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestSessionQueries(data.retryTestSession.id)
      ]
    })
  }))(TestSessionTestCases)


export default connect(
  state => ({ token: state.token.token, user: state.token.user, license: state.settings.license, features: state.settings.features }),
  { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
)(withStyles(testsessionsStyle, { withTheme: true })(TestSessionTestCasesWithGraphql))
