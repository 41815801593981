import moment from 'moment'

export function formatLastChange ({ updatedAt, updatedBy }) {
  if (updatedAt && updatedBy && updatedBy.name) {
    return `Changed ${moment(updatedAt).fromNow()} by ${updatedBy.name}`
  } else if (updatedAt) {
    return `Changed ${moment(updatedAt).fromNow()}`
  }
}

export function formatCreatedBy ({ createdAt, createdBy }) {
  if (createdAt && createdBy && createdBy.name) {
    return `Created ${moment(createdAt).fromNow()} by ${createdBy.name}`
  } else if (createdAt) {
    return `Created ${moment(createdAt).fromNow()}`
  }
}

export function formatCreatedByAndLastChange (args) {
  return [
    formatCreatedBy(args),
    formatLastChange(args)
  ].filter(s => s).join(' | ')
}