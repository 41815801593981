const menuStyle = theme => ({
  menuItemSmallRoot: {
    fontSize: 12,
    height: 12,
    fontWeight: '500 !important',
    borderRadius: 6,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: `transparent`,
    color: theme.colors.common.primary,
    '&:hover': {
      backgroundColor: `${theme.colors.dropdownListHover} !important`,
    },
    '& span': {
      color: theme.colors.primary,
      fontWeight: 500,
    },
    '&:focus': {
      color: theme.colors.primary,
    },
    '&:focus > a': {
      color: theme.colors.primary,
    },
    marginBottom: 2,
    '-webkit-tap-highlight-color': theme.colors.visibleborderColor,
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },

  custumSelected: {
    '& span': {
      color: theme.colors.dropdownListActive,
      fontWeight: 500,
    },
    backgroundColor: `${theme.colors.dropdownListHover} !important`,
    '-webkit-tap-highlight-color': theme.colors.visibleborderColor,
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },

  

})

export default menuStyle
