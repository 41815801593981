import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Chip from 'components/Chip/Chip'
import Tooltip from 'components/Tooltip/Tooltip'
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import LastChange from 'components/Info/LastChange'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import { TESTSETS_COUNT_PAGINATED_QUERY, TESTSETS_WITH_STATUS_PAGINATED_QUERY } from '../TestSets/gql'
import Text from 'components/Typography/Text'

class TestSetSelector extends React.Component {
  render() {
    const { title, subtitle, link, queryVariables } = this.props

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>        
          <Card>
            <CardHeader>
              <Text header>{title || 'Test Sets'}</Text>
              <Text subheader>
                {subtitle || 'Listing all your available Test Sets'}
              </Text>
            </CardHeader>
            <CardBody noPadding>
              <GridContainer >
                <GridItem xs={12} skipTableSpace>
                  <ServerSidePagingTable 
                    gqlQuery={{
                      query: TESTSETS_WITH_STATUS_PAGINATED_QUERY,
                      variables: queryVariables || {},
                      notifyOnNetworkStatusChange: true
                    }}
                    gqlCountQuery={{
                      query: TESTSETS_COUNT_PAGINATED_QUERY,
                      countValue: data => data.testsetsCount
                    }}
                    gqlVariables={(filterString) => filterString && ({ where: { name_contains: filterString } })}
                    name="TestSets"
                    tableHeaderColor="primary"
                    tableHead={[
                      { name: 'Name', orderByField: 'name', orderByDefault: 'asc' },
                      { name: 'Last Change', orderByField: 'updatedAt' },
                      { name: 'Namespace', orderByField: 'namespace', orderByDefault: 'asc' },
                      { name: 'Convos', orderByField: 'statsConvoCount' },
                      { name: 'Utterances', orderByField: 'statsUtterancesCount' },
                      { name: 'Test Parameter Stores', orderByField: 'statsScriptingMemoryCount' },
                    ]}
                    tableData={data =>
                      data && data.testsets &&
                      data.testsets.map(t => [
                        {
                          value: t.name,
                          href: link + t.id,
                          width: 250
                        },
                        () => <LastChange {...t} />,
                        t.namespace ? {
                          value: t.namespace,
                          href: `/namespaces/${t.namespace}`,
                        } : null,
                        () => <Tooltip title={`${t.statsConvoCount || '0'} Convos`} key={'convos'}><Chip variant="convo" label={t.statsConvoCount || 0} /></Tooltip>,
                        () => <Tooltip title={`${t.statsUtterancesCount || '0'} Utterances`} key={'utterances'}><Chip variant="utterance" label={t.statsUtterancesCount || 0} /></Tooltip>,
                        () => <Tooltip title={`${t.statsScriptingMemoryCount || '0'} Test Parameter Stores`} key={'scriptingmemories'}><Chip variant="scripting" label={t.statsScriptingMemoryCount || 0} /></Tooltip>,
                      ])}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles(testsetsStyle)(TestSetSelector)
