import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons

// core components
import cardStyle from 'assets/jss/material-dashboard-react/components/cardStyle.jsx'

function Card({ ...props }) {
  const { classes, 
    className, 
    children, 
    plain, 
    dense, 
    profile, 
    chart, 
    secondary, 
    noBorder, 
    fullheight, 
    topbottomMargin, 
    borderbottom, 
    noMargin,
    noPadding, 
    smallPadding, 
    smallMargin, 
    cursor, 
    hoverlight, 
    hoverdark, 
    noMarginTop, 
    smallMarginTop, 
    noMarginBottom, 
    cardWidthAuto,
    borderRadiusRight,
    borderRadiusLeft,
    noBorderRadius,  
    cardFloat,
    borderSecondary,
    ...rest } = props
    
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardDense]: dense,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [classes.cardSecondary]: secondary,
    [classes.cardNoMargin]: noMargin,
    [classes.cardNoPadding]: noPadding,
    [classes.cardSmallMargin]: smallMargin,
    [classes.cardSmallPadding]: smallPadding,
    [classes.cardNoBorder]: noBorder,
    [classes.cardBorderBottom]: borderbottom,
    [classes.cardFullHeight]: fullheight,
    [classes.topbottomMargin]: topbottomMargin,
    [classes.cursor]: cursor,
    [classes.hoverlight]: hoverlight,
    [classes.hoverdark]: hoverdark,
    [classes.noMarginTop]: noMarginTop,
    [classes.noMarginBottom]: noMarginBottom,
    [classes.smallMarginTop]: smallMarginTop,
    [classes.cardWidthAuto]: cardWidthAuto,
    [classes.borderRadiusRight]: borderRadiusRight,
    [classes.borderRadiusLeft]: borderRadiusLeft,
    [classes.noBorderRadius]: noBorderRadius, 
    [classes.cardFloat]: cardFloat,
    [classes.borderSecondary]: borderSecondary,
    [className]: className !== undefined,
  })
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  )
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  dense: PropTypes.bool,
  profile: PropTypes.bool,
  chart: PropTypes.bool,
  secondary: PropTypes.bool,
  noMargin: PropTypes.bool,
  noBorder: PropTypes.bool,
  borderbottom: PropTypes.bool,
  fullheight: PropTypes.bool,
  smallMargin: PropTypes.bool,
  smallPadding: PropTypes.bool,
  topbottomMargin: PropTypes.bool,
  cursor: PropTypes.bool
}

export default withStyles(cardStyle)(Card)
