import { ADD_RECENT_ACTIVITY, REMOVE_RECENT_ACTIVITY } from '../constants'

export function putRecentListEntry({ url, name, type }) {
  return {
    type: ADD_RECENT_ACTIVITY,
    payload: {
      url,
      name,
      type
    }
  }
}

export function removeRecentListEntry({ url }) {
  return {
    type: REMOVE_RECENT_ACTIVITY,
    payload: {
      url
    }
  }
}
