const {watsonCaps2Form, watsonForm2caps, WatsonEdit} = require('./WatsonEdit')
const {webdriverCaps2Form, webdriverForm2caps, WebdriverEdit} = require('./WebdriverEdit')
const {webdrivervoiceCaps2Form, webdrivervoiceForm2caps, WebdriverVoiceEdit} = require('./WebdriverVoiceEdit')
const {dialogFlowCaps2Form, dialogFlowForm2caps, DialogFlowEdit} = require('./DialogFlowEdit')
const {dialogFlowCxCaps2Form, dialogFlowCxForm2caps, DialogFlowCxEdit} = require('./DialogFlowCxEdit')
const DialogFlowCxTestSessionCoverage = require('./DialogFlowCxTestSessionCoverage').default

const {
  simpleRestConnectorCaps2Form,
  simpleRestConnectorForm2caps,
  SimpleRestConnectorEdit
} = require('./SimpleRestConnectorEdit')
const {
  simpleSocketioConnectorCaps2Form,
  simpleSocketioConnectorForm2caps,
  SimpleSocketIOConnectorEdit
} = require('./SimpleSocketIOConnectorEdit')
const {directLineCaps2Form, directLineForm2caps, DirectLineEdit} = require('./DirectlineEdit')
const {dockerCaps2Form, dockerForm2caps, DockerEdit} = require('./DockerEdit')
const {botkitCaps2Form, botkitForm2caps, BotkitEdit} = require('./BotkitEdit')
const {botkitWebsocketCaps2Form, botkitWebsocketForm2caps, BotkitWebsocketEdit} = require('./BotkitWebsocketEdit')
const {botpressCaps2Form, botpressForm2caps, BotpressEdit} = require('./BotpressEdit')
const {alexaavsCaps2Form, alexaavsForm2caps, AlexaAVSEdit} = require('./AlexaAVSEdit')
const {alexasmapiCaps2Form, alexasmapiForm2caps, AlexaSMAPIEdit} = require('./AlexaSMAPIEdit')
const {wechatCaps2Form, wechatForm2caps, WechatEdit} = require('./WechatEdit')
const {holmesCaps2Form, holmesForm2caps, WiproHolmesEdit} = require('./WiproHolmesEdit')
const {witaiCaps2Form, witaiForm2caps, WITAIEdit} = require('./WITAIEdit')
const {fbwebhookCaps2Form, fbwebhookForm2caps, FacebookWebhookEdit} = require('./FacebookWebhookEdit')
const {whatsappCaps2Form, whatsappForm2caps, WhatsappEdit} = require('./WhatsappEdit')
const {
  websocketConnectorCaps2Form,
  websocketConnectorForm2caps,
  WebsocketConnectorEdit
} = require('./WebsocketConnectorEdit')
const {googleAssistantCaps2Form, googleAssistantForm2caps, GoogleAssistantEdit} = require('./GoogleAssistantEdit')
const {oracledaCaps2Form, oracledaForm2caps, OracleDigitalAssistantEdit} = require('./OracleDigitalAssistantEdit')
const {twilioCaps2Form, twilioForm2caps, TwilioEdit} = require('./TwilioEdit')
const {grpcCaps2Form, grpcForm2caps, GRPCConnectorEdit} = require('./GRPCConnectorEdit')
const {landbotCaps2Form, landbotForm2caps, LandbotEdit} = require('./LandbotEdit')
const {twilioSMSCaps2Form, twilioSMSForm2caps, TwilioSMSEdit} = require('./TwilioSMSEdit')
const {chatlayerCaps2Form, chatlayerForm2caps, ChatlayerEdit} = require('./ChatlayerEdit')
const {livepersonCaps2Form, livepersonForm2caps, LivePersonEdit} = require('./LivePersonEdit')
const {voipConnectorCaps2Form, voipConnectorForm2caps, VoipConnectorEdit} = require('./VoipConnectorEdit')
const {genesysCaps2Form, genesysForm2caps, GenesysEdit} = require('./GenesysEdit')
const {copilotCaps2Form, copilotForm2caps, CopilotEdit} = require('./CopilotEdit')

export default {
  'watson': {
    caps2Form: watsonCaps2Form,
      form2Caps: watsonForm2caps,
      form: WatsonEdit
  },
  'webdriverio': {
    caps2Form: webdriverCaps2Form,
      form2Caps: webdriverForm2caps,
      form: WebdriverEdit
  },
  'webdrivervoice': {
    caps2Form: webdrivervoiceCaps2Form,
      form2Caps: webdrivervoiceForm2caps,
      form: WebdriverVoiceEdit
  },
  'dialogflow': {
    caps2Form: dialogFlowCaps2Form,
      form2Caps: dialogFlowForm2caps,
      form: DialogFlowEdit
  },
  'dialogflowcx': {
    caps2Form: dialogFlowCxCaps2Form,
      form2Caps: dialogFlowCxForm2caps,
      form: DialogFlowCxEdit,
      testSessionTabs: (testsession) => {
      return [DialogFlowCxTestSessionCoverage(testsession)]
    }
  },
  'simplerest': {
    caps2Form: simpleRestConnectorCaps2Form,
      form2Caps: simpleRestConnectorForm2caps,
      form: SimpleRestConnectorEdit
  },
  'simple-socketio': {
    caps2Form: simpleSocketioConnectorCaps2Form,
      form2Caps: simpleSocketioConnectorForm2caps,
      form: SimpleSocketIOConnectorEdit
  },
  'directline3': {
    caps2Form: directLineCaps2Form,
      form2Caps: directLineForm2caps,
      form: DirectLineEdit
  },
  'docker': {
    caps2Form: dockerCaps2Form,
      form2Caps: dockerForm2caps,
      form: DockerEdit
  },
  'botkit': {
    caps2Form: botkitCaps2Form,
      form2Caps: botkitForm2caps,
      form: BotkitEdit
  },
  'botkit-websocket': {
    caps2Form: botkitWebsocketCaps2Form,
      form2Caps: botkitWebsocketForm2caps,
      form: BotkitWebsocketEdit
  },
  'botpress': {
    caps2Form: botpressCaps2Form,
      form2Caps: botpressForm2caps,
      form: BotpressEdit
  },
  'alexa-avs': {
    caps2Form: alexaavsCaps2Form,
      form2Caps: alexaavsForm2caps,
      form: AlexaAVSEdit
  },
  'alexa-smapi': {
    caps2Form: alexasmapiCaps2Form,
      form2Caps: alexasmapiForm2caps,
      form: AlexaSMAPIEdit
  },
  'wechat': {
    caps2Form: wechatCaps2Form,
      form2Caps: wechatForm2caps,
      form: WechatEdit
  },
  'holmes': {
    caps2Form: holmesCaps2Form,
      form2Caps: holmesForm2caps,
      form: WiproHolmesEdit
  },
  'witai': {
    caps2Form: witaiCaps2Form,
      form2Caps: witaiForm2caps,
      form: WITAIEdit
  },
  'fbwebhook': {
    caps2Form: fbwebhookCaps2Form,
      form2Caps: fbwebhookForm2caps,
      form: FacebookWebhookEdit
  },
  'whatsapp': {
    caps2Form: whatsappCaps2Form,
      form2Caps: whatsappForm2caps,
      form: WhatsappEdit
  },
  'websocket': {
    caps2Form: websocketConnectorCaps2Form,
      form2Caps: websocketConnectorForm2caps,
      form: WebsocketConnectorEdit
  },
  'google-assistant': {
    caps2Form: googleAssistantCaps2Form,
      form2Caps: googleAssistantForm2caps,
      form: GoogleAssistantEdit
  },
  'oracleda': {
    caps2Form: oracledaCaps2Form,
      form2Caps: oracledaForm2caps,
      form: OracleDigitalAssistantEdit
  },
  'twilio-ivr': {
    caps2Form: twilioCaps2Form,
      form2Caps: twilioForm2caps,
      form: TwilioEdit
  },
  'grpc': {
    caps2Form: grpcCaps2Form,
      form2Caps: grpcForm2caps,
      form: GRPCConnectorEdit
  },
  'landbot': {
    caps2Form: landbotCaps2Form,
      form2Caps: landbotForm2caps,
      form: LandbotEdit
  },
  'twilio-sms': {
    caps2Form: twilioSMSCaps2Form,
      form2Caps: twilioSMSForm2caps,
      form: TwilioSMSEdit
  },
  'chatlayer': {
    caps2Form: chatlayerCaps2Form,
      form2Caps: chatlayerForm2caps,
      form: ChatlayerEdit
  },
  'liveperson': {
    caps2Form: livepersonCaps2Form,
      form2Caps: livepersonForm2caps,
      form: LivePersonEdit
  },
  'voip': {
    caps2Form: voipConnectorCaps2Form,
      form2Caps: voipConnectorForm2caps,
      form: VoipConnectorEdit
  },
  'genesys': {
    caps2Form: genesysCaps2Form,
    form2Caps: genesysForm2caps,
    form: GenesysEdit
  },
  'copilot': {
    caps2Form: copilotCaps2Form,
    form2Caps: copilotForm2caps,
    form: CopilotEdit
  },
}
