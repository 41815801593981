import React from 'react'
import { compose, graphql } from 'react-apollo'
import _ from 'lodash'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import DehazeIcon from '@material-ui/icons/Dehaze'

import withStyles from '@material-ui/core/styles/withStyles'

import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'

import ShowIcon from 'components/Icon/ShowIcon'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'

import {
  MINIMUM_UTTERANCES_PER_INTENT,
  MINIMUM_UTTERANCES_PER_ENTITY,
  MINIMUM_AVERAGE_CONFIDENCE
} from 'botium-box-shared/trainer/helper'
import {
  hasConfusionMatrix
} from 'botium-box-shared/trainer/score'

import {
  toFixedSafe
} from '../helper'

// core components
import { renderProgressOrError } from '../../../../views/helper'
import {
  TRAINER_SESSION_ROOT_QUERY,
  UTTERANCES_QUERY,
  PER_CORRECTED_INTENT_QUERY,
  PER_ACTUAL_ENTITY_QUERY,
  UTTERANCES_INCOMPREHENSION_QUERY,
  UTTERANCES_NOT_MATCHED_INTENT_QUERY
} from '../../gql'
import Text from 'components/Typography/Text'

const MAX_RECORDS = 5

const decorateHasMore = (data, length, dataField) => {
  if (data[dataField] && data[dataField].length > length) {
    data.hasMore = true
    data[dataField] = data[dataField].slice(0, length)
  }
  return data
}

class AdvicesComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      trainerSessionData,
      utterancesNoAsserterData,
      correctedIntentsData,
      actualEntitiesData,
      utterancesIncomprehensionData,
      utterancesNotMatchedData,
      correctedIntentConfidenceData,
      actualEntityConfidenceData,
      classes
    } = this.props
    let progressOrError =
      renderProgressOrError(trainerSessionData) ||
      renderProgressOrError(utterancesNoAsserterData) ||
      renderProgressOrError(correctedIntentsData, true) ||
      renderProgressOrError(actualEntitiesData, true) ||
      renderProgressOrError(utterancesIncomprehensionData) ||
      renderProgressOrError(utterancesNotMatchedData) ||
      renderProgressOrError(correctedIntentConfidenceData) ||
      renderProgressOrError(actualEntityConfidenceData)
    if (progressOrError) {
      return progressOrError
    }

    const renderItem = (info, actions, dataLabel, data, hasMore) => {

      return (<Text info>
        <ul>
          <li className={classes.listItemNone}>
            <HelpOutlineIcon /> {info}
          </li>
          {actions.map((a, i) => <li key={i} className={classes.listItemNone}><PlayCircleOutlineIcon /> {a}</li>)}
          <li className={classes.listItemNone}><DehazeIcon /> {dataLabel}
            <ul>
              {data.map((e, i) => <li key={i} className={classes.listItemNone}>"{e}"</li>)}
              {hasMore && <li className={classes.listItemNone}>...</li>}
            </ul>
          </li>
        </ul>
      </Text>)
    }

    // const overallStat = trainerSessionData.testsession.trainerSession.overallStat
    // const hasAsserterAtAll = overallStat.stepsWithIntent !== 0
    // const minimumUtterancesPerIntent = trainerSessionData.testsession.testProject.minimumUtterancesPerIntent
    // const minimumUtterancesPerEntity = trainerSessionData.testsession.testProject.minimumUtterancesPerEntity

    const covAsserter = utterancesNoAsserterData.trainerUtterances.length > 0
    const covIntents = correctedIntentsData.trainerpercorrectedintents.length > 0
    const covEntities = actualEntitiesData.trainerperactualentities.length > 0
    const cov = covAsserter || covIntents || covEntities

    const corIncomp = utterancesIncomprehensionData.trainerUtterances.length > 0
    const corNotMatched = hasConfusionMatrix(trainerSessionData.testsession) ? utterancesNotMatchedData.trainerUtterances.length > 0 : false
    const cor = corIncomp || corNotMatched

    const preIntents = trainerSessionData.testsession.trainerSession.overallStat.intentConfidenceSupported ? correctedIntentConfidenceData.trainerpercorrectedintents.length > 0 : false
    const preEntities = trainerSessionData.testsession.trainerSession.overallStat.entityConfidenceSupported ? actualEntityConfidenceData.trainerperactualentities.length > 0 : false
    const precision = preIntents || preEntities

    return (<GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <Text header {...(cor ? { warning: true } : { success: true })}>
              {cor && <><ShowIcon custom icon="error" inline/> <Text inline lg info>There are possible issues with the CORRECTNESS</Text></>}
              {!cor && <><ShowIcon custom icon="success" inline/> <Text inline lg info>No issues with the CORRECTNESS found</Text></>}
            </Text>
            <ul>

              {/* Correctness, not matched */}
              <li className={classes.listItemNone}>
                {corNotMatched && <><ShowIcon custom icon="error" xxs inline/> <Text inline>Unexpected intent predicted for some user examples</Text></>}
                {!corNotMatched && <><ShowIcon custom icon="success" xxs inline/> <Text inline>No unexpected intent was predicted</Text></>}
                {corNotMatched && renderItem(
                  'In general it requires your attention if a prediction doesn\'t match the expected intent. It depends on the use case if a low number of prediction failures is acceptable or not.',
                  [
                    'Check the confusion matrix for the precision score of every intent and evaluate the similarities of the mismatched user examples',
                    'Check the mismatch probability risks to identify the intents with a high probability of mismatch',
                    'Depending on the use case, you may increase the confidence threshold to make more user examples be classified as incomprehension',
                    'Refine and annotate test data so Botium knows the expected intent for all user examples'
                  ],
                  utterancesNotMatchedData.hasMore ? 'Some of the user examples with unexpected intent' : 'User examples with unexpected intent',
                  utterancesNotMatchedData.trainerUtterances.map(e => `${e.utterance} (${e.intent.expected} vs ${e.intent.actual || '<empty>'})`),
                  utterancesNotMatchedData.hasMore
                )}
              </li>

              {/* Correctness, incomprehension */}
              <li className={classes.listItemNone}>
                {corIncomp && <><ShowIcon custom icon="error" xxs inline/> <Text inline>Some user examples resulted in an incomprehension</Text></>}
                {!corIncomp && <><ShowIcon custom icon="success" xxs inline/> <Text inline>No incomprehension found</Text></>}
                {corIncomp && renderItem(
                  'To get the most out of Botium, incomprehension responses should be avoided (expect when explicitly testing for incomprehension).',
                  [
                    'Refine the training data and add the user examples',
                    'Check the confusion matrix for the recall score of every intent to see the incomprehension possibility',
                    'Depending on the use case, you may decrease the confidence threshold to make less user examples be classified as incomprehension'
                  ],
                  utterancesIncomprehensionData.hasMore ? 'Some of the user examples resulting in incomprehension' : 'User examples resulting in incomprehension',
                  utterancesIncomprehensionData.trainerUtterances.map(e => e.utterance),
                  utterancesIncomprehensionData.hasMore
                )}
              </li>

            </ul>

          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <Text header {...(precision ? { warning: true } : { success: true })}>
              {precision && <><ShowIcon custom icon="error" inline/> <Text inline lg info>There are possible issues with the PRECISION</Text></>}
              {!precision && <><ShowIcon custom icon="success" inline/> <Text inline lg info>No issues with the PRECISION found</Text></>}
            </Text>

            <ul>
              {/* Precision, average intent confidence */}
              {trainerSessionData.testsession.trainerSession.overallStat.intentConfidenceSupported &&
                <li className={classes.listItemNone}>
                  {preIntents && <><ShowIcon custom icon="error" xxs inline/> <Text inline>Found intents with low average confidence score</Text></>}
                  {!preIntents && <><ShowIcon custom icon="success" xxs inline/> <Text inline>All intents have sufficient average confidence score</Text></>}
                  {preIntents && renderItem(
                    'Low average confidence score is indicator for a broad range of problems.',
                    [
                      'Use the Botium Paraphraser to generate additional user examples for an intent and use it as additional training data',
                      'Check your training data for weak user examples not matching the intent',
                      'Keep in mind that the user examples itself may be fine, but the intent structure is not. Splitting or combining intents may be a solution'
                    ],
                    correctedIntentConfidenceData.hasMore ? 'Some of the intents with low average confidence' : 'Intents with low average confidence',
                    correctedIntentConfidenceData.trainerpercorrectedintents.map(e => `${e.name} (${toFixedSafe(e.avg)})`),
                    correctedIntentConfidenceData.hasMore
                  )}
                </li>
              }

              {/* Precision, average entity confidence */}
              {trainerSessionData.testsession.trainerSession.overallStat.entityConfidenceSupported &&
                <li className={classes.listItemNone}>
                  {preEntities && <><ShowIcon custom icon="error" xxs inline/> <Text inline>Found entities with low average confidence score</Text></>}
                  {!preEntities && <><ShowIcon custom icon="success" xxs inline/> <Text inline> All entities have sufficient average confidence score</Text></>}
                  {preEntities && renderItem(
                    'How to deal with the entity recognition logic highly depends on chatbot engine and the entity type.',
                    [
                      'Use the Botium Paraphraser to generate additional user examples for an entity and use it as additional training data',
                      'Check the documentation of the entity type'
                    ],
                    actualEntityConfidenceData.hasMore ? 'Some of the entities with low average confidence' : 'Entities with low average confidence',
                    actualEntityConfidenceData.trainerperactualentities.map(e => `${e.name} (${toFixedSafe(e.avg)})`),
                    actualEntityConfidenceData.hasMore
                  )}
                </li>
              }
            </ul>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <CardBody>
            <Text header {...(cov ? { warning: true } : { success: true })}>
              {cov && <><ShowIcon custom icon="error" inline/> <Text inline lg info>There are possible issues with the TEST COVERAGE</Text></>}
              {!cov && <><ShowIcon custom icon="success" inline/> <Text inline lg info>No issues with the TEST COVERAGE found</Text></>}
            </Text>
            <ul>

              {/*** Coverage, utterances for intent ***/}
              <li className={classes.listItemNone}>
                {covIntents && <><ShowIcon custom icon="error" xxs inline/> <Text inline>Low number of user examples for intents</Text></>}
                {!covIntents && <><ShowIcon custom icon="success" xxs inline/> <Text inline>All intents have sufficient user examples</Text></>}
                {covIntents && renderItem(
                  'Low number of user examples for an intent is an indicator for the lack of training data. This only applies if your are using your training data for testing with Botium.',
                  [
                    'Use the Botium Paraphraser to generate additional user examples for an intent',
                    'Refine and annotate test data so Botium knows the expected intent for all user examples'
                  ],
                  `Intents with low number of user examples (less than ${numberWithDefault(_.isNil(trainerSessionData.testsession.testProject) ? null : trainerSessionData.testsession.testProject.minimumUtterancesPerIntent, MINIMUM_UTTERANCES_PER_INTENT)})`,
                  correctedIntentsData.trainerpercorrectedintents.map(e => `${e.name} (${e.count})`),
                  correctedIntentsData.hasMore
                )}
              </li>

              {/*** Coverage, utterances for entity ***/}
              <li className={classes.listItemNone}>
                {covEntities && <><ShowIcon custom icon="error" xxs inline/> <Text inline>Low number of user examples for entities</Text></>}
                {!covEntities && <><ShowIcon custom icon="success" xxs inline/> <Text inline>All entities have sufficient user examples</Text></>}
                {covEntities && renderItem(
                  'Low number of user examples for an entity is an indicator for the lack of training data. This only applies if your are using your training data for testing with Botium.',
                  [
                    'Use the Botium Paraphraser to generate additional user examples for an entity.',
                    'Refine and annotate test data so Botium knows the expected entities for all user examples'
                  ],
                  `Entities with low number of user examples (less than ${numberWithDefault(_.isNil(trainerSessionData.testsession.testProject) ? null : trainerSessionData.testsession.testProject.minimumUtterancesPerEntity, MINIMUM_UTTERANCES_PER_ENTITY)})`,
                  actualEntitiesData.trainerperactualentities.map(e => `${e.name} (${e.count})`),
                  actualEntitiesData.hasMore
                )}
              </li>

              {/*** Coverage, utterance without asserter ***/}
              <li className={classes.listItemNone}>
                {covAsserter && <><ShowIcon custom icon="error" xxs inline/> <Text inline>Found conversation steps or utterances without expected intent</Text></>}
                {!covAsserter && <><ShowIcon custom icon="success" xxs inline/> <Text inline>All conversation steps and utterances have an expected intent</Text></>}
                {covAsserter && renderItem(
                  'If Botium doesn\'t know the expected intent, the CORRECTNESS of the test cases cannot be asserted, resulting in low TEST COVERAGE',
                  [
                    'Make sure that the utterance names match the expected intent names and check the "Use Utterance Name as NLU intent" option in the Advanced Scripting Settings',
                    'Or add an INTENT asserter to all conversation steps'
                  ],
                  utterancesNoAsserterData.hasMore ? 'Some of the user examples without expected intents' : 'User examples without expected intents',
                  utterancesNoAsserterData.trainerUtterances.map(e => e.utterance),
                  utterancesNoAsserterData.hasMore
                )}
              </li>

            </ul>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    )
  }
}

const getFromTestProjectSafe = (props, fieldName, def) => {
  return numberWithDefault(_.isNil(props.trainerSessionData.testsession.testProject) ? def : props.trainerSessionData.testsession.testProject[fieldName], def)
}

const AdvicesWrapper = compose(
  withStyles(dashboardStyle),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({ data }) => ({
      trainerSessionData: data,
    }),
    options: (props) => {
      return {
        variables: {
          id: props.testSessionId
        },
      }
    }
  }),
  graphql(UTTERANCES_QUERY, {
    props: ({ data }) => ({
      utterancesNoAsserterData: decorateHasMore(data, MAX_RECORDS, 'trainerUtterances'),
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.testSessionId,
          expectedIntentIsNull: true,
          first: MAX_RECORDS + 1
        },
      }
    }
  }),
  graphql(PER_CORRECTED_INTENT_QUERY, {
    props: ({ data }) => ({
      correctedIntentsData: decorateHasMore(data, MAX_RECORDS, 'trainerpercorrectedintents'),
    }),
    options: (props) => {
      return {
        variables: {
          where: {
            trainerSession: {
              testSession: {
                id: props.testSessionId
              }
            },
            count_lt: getFromTestProjectSafe(props, 'minimumUtterancesPerIntent', MINIMUM_UTTERANCES_PER_INTENT)
          },
          first: MAX_RECORDS + 1,
          orderBy: 'count_ASC'
        },
      }
    },
    skip: (props) => !props.trainerSessionData || !props.trainerSessionData.testsession
  }),
  graphql(PER_ACTUAL_ENTITY_QUERY, {
    props: ({ data }) => ({
      actualEntitiesData: decorateHasMore(data, MAX_RECORDS, 'trainerperactualentities'),
    }),
    options: (props) => {
      return {
        variables: {
          where: {
            trainerSession: {
              testSession: {
                id: props.testSessionId
              }
            },
            count_lt: getFromTestProjectSafe(props, 'minimumUtterancesPerEntity', MINIMUM_UTTERANCES_PER_ENTITY)
          },
          first: MAX_RECORDS + 1,
          orderBy: 'count_ASC'
        },
      }
    },
    skip: (props) => !props.trainerSessionData || !props.trainerSessionData.testsession
  }),
  graphql(UTTERANCES_INCOMPREHENSION_QUERY, {
    props: ({ data }) => ({
      utterancesIncomprehensionData: decorateHasMore(data, MAX_RECORDS, 'trainerUtterances'),
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.testSessionId,
          first: MAX_RECORDS + 1
        },
      }
    },
  }),
  graphql(UTTERANCES_NOT_MATCHED_INTENT_QUERY, {
    props: ({ data }) => ({
      utterancesNotMatchedData: decorateHasMore(data, MAX_RECORDS, 'trainerUtterances'),
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.testSessionId,
          hasNotMatchedIntent: true,
          first: MAX_RECORDS + 1
        },
      }
    },
  }),
  graphql(PER_CORRECTED_INTENT_QUERY, {
    props: ({ data }) => ({
      correctedIntentConfidenceData: decorateHasMore(data, MAX_RECORDS, 'trainerpercorrectedintents'),
    }),
    options: (props) => {
      return {
        variables: {
          where: {
            trainerSession: {
              testSession: {
                id: props.testSessionId
              }
            },
            avg_lt: getFromTestProjectSafe(props, 'minimumAverageConfidence', MINIMUM_AVERAGE_CONFIDENCE)
          },
          first: MAX_RECORDS + 1,
          orderBy: 'avg_ASC'
        },
      }
    },
    skip: (props) => !props.trainerSessionData || !props.trainerSessionData.testsession
  }),
  graphql(PER_ACTUAL_ENTITY_QUERY, {
    props: ({ data }) => ({
      actualEntityConfidenceData: decorateHasMore(data, MAX_RECORDS, 'trainerperactualentities'),
    }),
    options: (props) => {
      return {
        variables: {
          where: {
            trainerSession: {
              testSession: {
                id: props.testSessionId
              }
            },
            avg_lt: getFromTestProjectSafe(props, 'minimumAverageConfidence', MINIMUM_AVERAGE_CONFIDENCE)
          },
          first: MAX_RECORDS + 1,
          orderBy: 'avg_ASC'
        },
      }
    },
    skip: (props) => !props.trainerSessionData || !props.trainerSessionData.testsession
  }),

)(AdvicesComponent)

const numberWithDefault = (maybeNumber, def) => {
  if (_.isNumber(maybeNumber)) {
    return maybeNumber
  }

  return def
}
export const renderAdvices = (testSessionId) => {
  return (<AdvicesWrapper testSessionId={testSessionId} />)
}
