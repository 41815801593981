import { gql } from 'apollo-boost'
import _ from 'lodash'
import slugify from 'slugify'
import { validateUnique } from 'helper/graphHelper'

const CHECK_TESTPROJECT_NAMES_QUERY = gql`
query CheckTestProjectNameQuery($where: TestProjectWhereInput!) {
  testprojects(where: $where) {
    id
  }
}
`

export async function validateTestProjectCodeUnique (client, testProjectNames = [], errMsg = 'Please choose another Test Project Name, this one is already used', whereClause) {
  if (_.isString(testProjectNames)) {
    testProjectNames = [testProjectNames]
  }
  if (testProjectNames.length > 0) {
    let where = {}
    if (testProjectNames.length > 1) {
      where.OR = testProjectNames.map(n => ({ code: slugify(n)}))
    } else {
      const code = slugify(testProjectNames[0]) +
        (whereClause?.securityCheck ? '-security' : '') +
        (whereClause?.nlpAnalytics ? '-nlp' : '') +
        (whereClause?.performanceTesting ? '-performance' : '') +
        (whereClause?.gdprTesting ? '-gdpr' : '') +
        (whereClause?.e2eTesting ? '-e2e' : '') +
        (whereClause?.regressionTesting ? '-regression' : '')

      where.OR = [
        { code },
        // for the old data where the type is not part of the code
        {
          code: slugify(testProjectNames[0])
        }
      ]
    }
    // the project type is part of the code, so in most cases this is not needed.
    // But good for legacy data, where the project type is not part of the code.
    if (whereClause) {
      where = {
        AND: [where, whereClause]
      }
    }
    return validateUnique(client, CHECK_TESTPROJECT_NAMES_QUERY, { where }, 'testprojects', null, errMsg)
  }
}
