import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'

import CrawlerSessionProgress from './CrawlerSessionProgress'
import crawlerprojectsStyle from 'assets/jss/material-dashboard-react/views/crawlerprojectsStyle.jsx'
import _ from 'lodash'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class CrawlerSessionIcon extends React.Component {

  _getTooltip(crawlerSessionProgress, status) {
    const { extendedTooltip } = this.props
    if (extendedTooltip) {
      if (crawlerSessionProgress && crawlerSessionProgress.chartData) {
        const chartData = JSON.parse(crawlerSessionProgress.chartData)
        return <div>
          <span style={{ fontSize: '12px' }}><b>{status}</b></span>
          <br />
          <span>Success Convos: {chartData.convoCount}</span>
          <br />
          <span>Utterances: {chartData.utteranceCount}</span>
          <br />
          <span>Failed Convos: {!_.isNil(chartData.failedConvoCount) ? chartData.failedConvoCount : '?'}</span>
        </div>
      } else {
        return status
      }
    } else {
      return status
    }
  }

  render() {
    const { crawlerSession } = this.props
    return (
      <CrawlerSessionProgress {...(this.props)} key={`icon_${crawlerSession && crawlerSession.id}`}>
        {
          ({ crawlerSessionProgress }) => {
            if (crawlerSessionProgress.status === 'RUNNING') {
              return <Tooltip title={this._getTooltip(crawlerSessionProgress, 'Running')}>
                <Text warning inline padding data-type="crawlersession" data-status={crawlerSessionProgress.status}>
                  <LoadingIndicator />
                </Text>
              </Tooltip>
            } else if (crawlerSessionProgress.status === 'READY') {
              return <Tooltip title={this._getTooltip(crawlerSessionProgress, 'Finished')}>
                <Text success inline padding data-type="crawlersession" data-status={crawlerSessionProgress.status}>
                  <ShowIcon custom md icon="success" />
                </Text>
              </Tooltip>
            } else if (crawlerSessionProgress.status === 'FAILED') {
              return <Tooltip title={this._getTooltip(crawlerSessionProgress, 'Failed')}>
                <Text danger inline padding data-type="crawlersession" data-status={crawlerSessionProgress.status}>
                  <ShowIcon custom md icon="error" />
                </Text>
              </Tooltip>
            } else if (crawlerSessionProgress.status === 'PARTIALLY_FAILED') {
              return <Tooltip title={this._getTooltip(crawlerSessionProgress, 'Partially Failed')}>
                <Text warning inline padding data-type="crawlersession" data-status={crawlerSessionProgress.status}>
                  <ShowIcon custom md icon="warning" />
                </Text>
              </Tooltip>
            } else if (crawlerSessionProgress.status === 'CANCELLED') {
              return <Tooltip title={this._getTooltip(crawlerSessionProgress, 'Cancelled')}>
                <Text warning inline padding data-type="crawlersession" data-status={crawlerSessionProgress.status}>
                  <ShowIcon custom md icon="stop" />
                </Text>
              </Tooltip>
            }
            return <Tooltip title={this._getTooltip(crawlerSessionProgress, 'Pending')}>
              <Text warning inline padding data-type="crawlersession" data-status={crawlerSessionProgress.status}>
                <ShowIcon md icon="question-circle" />
              </Text>
            </Tooltip>
          }}
      </CrawlerSessionProgress>)
  }
}

export default withStyles(crawlerprojectsStyle)(CrawlerSessionIcon)
