import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'

import SecuritySettings from './SecuritySettings.jsx'
import VoiceSettings from './VoiceSettings.jsx'
import DeviceCloudSettings from './DeviceCloudSettings.jsx'
import ComponentSettings from './ComponentSettings.jsx'
import SystemSettings from './SystemSettings.jsx'
import Version from './Version.jsx'
import FileBrowser from './FileBrowser.jsx'
import Agents from './Agents.jsx'
import CapabilitySets from './CapabilitySets.jsx'
import Reports from './Reports.jsx'


import ShowIcon from 'components/Icon/ShowIcon'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { hasPermission, hasAnyPermission } from 'botium-box-shared/security/permissions'
import { isLicenseDeviceSetsSupported } from 'botium-box-shared/security/licenseSupport'


class Settings extends React.Component {
  render() {
    const { user, license, features } = this.props

    const e2eSupported = isLicenseDeviceSetsSupported(license)

    const optionalTab = (permission, json) => {
      if (_.isArray(permission)) {
        return hasAnyPermission(user, permission) ? json : null
      } else {
        return hasPermission(user, permission) ? json : null
      }
    }
    return (
      <GridContainer>
        <GridItem xs={12}>
          <CustomTabs
            name="tabsSettings"
            headerColor="info"
            tabs={[
              optionalTab(['USERS_MANAGE', 'APIKEYS_SELECT', 'APIKEYS_MANAGE'], {
                tabName: 'Users & Permissions',
                tabIcon: <ShowIcon icon="shield-alt" />,
                tabContent: <SecuritySettings />,
                locationPrefix: '/settings/security',
                dataUnique: 'tabSettingsSecurity'
              }),
              features.speechService ?
                optionalTab('SYSTEMSETTINGS_MANAGE', {
                  tabName: 'Voice Services',
                  tabIcon: <ShowIcon icon="phone" />,
                  tabContent: <VoiceSettings />,
                  locationPrefix: '/settings/voice',
                  dataUnique: 'tabSettingsVoice'
                }) : null,
              e2eSupported ?
                optionalTab(['DEVICESETS_SELECT', 'DEVICESETS_MANAGE', 'DEVICEPROVIDERS_SELECT', 'DEVICEPROVIDERS_MANAGE'], {
                  tabName: 'Browser & Device Cloud',
                  tabIcon: <ShowIcon icon="cloud" />,
                  tabContent: <DeviceCloudSettings />,
                  locationPrefix: '/settings/devicecloud',
                  dataUnique: 'tabSettingsDeviceCloud'
                }) : null,
              !license.shared ?
                optionalTab(['AGENTS_SELECT', 'AGENTS_MANAGE'], {
                  tabName: 'Botium Agents',
                  tabIcon: <ShowIcon icon="network-wired" />,
                  tabContent: <Agents />,
                  locationPrefix: '/settings/agents',
                  dataUnique: 'tabSettingsAgents'
                }) : null,
              optionalTab(['CHATBOTS_SELECT', 'CHATBOTS_MANAGE'], {
                tabName: 'Capability Sets',
                tabIcon: <ShowIcon icon="book" />,
                tabContent: <CapabilitySets />,
                locationPrefix: '/settings/capabilitysets',
                dataUnique: 'tabSettingsCapabilitySets'
              }),
              optionalTab(['REGISTEREDCOMPONENTS_SELECT', 'REGISTEREDCOMPONENTS_MANAGE'], {
                tabName: 'Components',
                tabIcon: <ShowIcon icon="puzzle-piece" />,
                tabContent: <ComponentSettings />,
                locationPrefix: '/settings/components',
                dataUnique: 'tabSettingsComponents'
              }),
              {
                tabName: 'File Browser',
                tabIcon: <ShowIcon icon="folder-open" />,
                tabContent: <FileBrowser />,
                locationPrefix: '/settings/filebrowser',
                dataUnique: 'tabSettingsFilebrowser'
              },
              optionalTab('SYSTEMSETTINGS_MANAGE', {
                tabName: 'System Settings',
                tabIcon: <ShowIcon icon="wrench" />,
                tabContent: <SystemSettings />,
                locationPrefix: '/settings/system',
                dataUnique: 'tabSettingsSystem'
              }),
              optionalTab('REPORTS', {
                tabName: 'Reports',
                tabIcon: <ShowIcon icon="magnifying-glass-chart" />,
                tabContent: <Reports />,
                locationPrefix: '/settings/reports',
                dataUnique: 'tabSettingsReports'
              }),
              {
                tabName: 'About',
                tabIcon: <ShowIcon icon="question" />,
                tabContent: <Version />,
                locationPrefix: '/settings/version',
                dataUnique: 'tabSettingsVersion'
              }
            ].filter(t => t)}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ token: state.token.token, user: state.token.user, license: state.settings.license, features: state.settings.features })
)(withStyles(settingsStyle)(Settings))
