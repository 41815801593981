import { SET_AVAILABLECONNECTORS, CLEAR_AVAILABLECONNECTORS, SET_ALLCONNECTORS, CLEAR_ALLCONNECTORS, SET_LICENSE, CLEAR_LICENSE, SET_FEATURES, CLEAR_FEATURES } from '../constants'

export default function settings(state = {}, action) {
  switch (action.type) {
    case SET_AVAILABLECONNECTORS:
      return {
        ...state,
        availableconnectors: action.payload.availableconnectors
      }
    case CLEAR_AVAILABLECONNECTORS:
      if (state.availableconnectors) {
        const clone = { ...state }
        delete clone.availableconnectors
        return clone
      }
      return state
    case SET_ALLCONNECTORS:
      return {
        ...state,
        allconnectors: action.payload.allconnectors
      }
    case CLEAR_ALLCONNECTORS:
      if (state.allconnectors) {
        const clone = { ...state }
        delete clone.allconnectors
        return clone
      }
      return state
    case SET_LICENSE:
      return {
        ...state,
        license: action.payload.license
      }
    case CLEAR_LICENSE:
      if (state.license) {
        const clone = { ...state }
        delete clone.license
        return clone
      }
      return state
    case SET_FEATURES:
      return {
        ...state,
        features: action.payload.features
      }
    case CLEAR_FEATURES:
      if (state.features) {
        const clone = { ...state }
        delete clone.features
        return clone
      }
      return state
    default:
      return state
  }
}
