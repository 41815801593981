module.exports = {
  wildcard: 'MATCHING_MODE_WILDCARD',
  wildcardIgnoreCase: 'MATCHING_MODE_WILDCARDIGNORECASE',
  wildcardExact: 'MATCHING_MODE_WILDCARDEXACT',
  wildcardExactIgnoreCase: 'MATCHING_MODE_WILDCARDEXACTIGNORECASE',
  regexp: 'MATCHING_MODE_REGEXP',
  regexpIgnoreCase: 'MATCHING_MODE_REGEXPIGNORECASE',
  include: 'MATCHING_MODE_INCLUDE',
  includeIgnoreCase: 'MATCHING_MODE_INCLUDEIGNORECASE',
  // includeIgnoreCase: 'MATCHING_MODE_INCLUDELOWERCASE',
  equals: 'MATCHING_MODE_EQUALS',
  equalsIgnoreCase: 'MATCHING_MODE_EQUALSIGNORECASE',
  wer: 'MATCHING_MODE_WER'
}
