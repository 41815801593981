import React from 'react'
import { connect } from 'react-redux'
import { OnChange } from 'react-final-form-listeners'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, withApollo, compose } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import Transcript from 'components/Convo/Transcript.jsx'
import { renderTextField, renderCheckbox, renderCodeArea, renderSelect, required, FormActionsToolbar } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import { openTextInNewTab } from 'helper/browserHelper'

import ShowIcon from 'components/Icon/ShowIcon'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TESTSETSCRIPT_QUERY,
  VALIDATETESTSETSCRIPT_QUERY,
  CREATE_TESTSETSCRIPT,
  UPDATE_TESTSETSCRIPT,
  RUN_TESTSETSCRIPT
} from './gql'
import { RefetchTestSetQueries, TESTSET_EDITABLE_CONVO_QUERY, TESTSET_EDITABLE_UTTERANCE_QUERY } from './gql'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import SwitchButton from 'components/Button/SwitchButton.jsx'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import {deleteListQueryFromCache} from '../../helper/cacheHelper'

const DEFAULT_SCRIPTS = {
  SCRIPTING_TYPE_YAML: `convos:
- name: Greet the bot
  description: some optional description
  steps:
    - me:
        - GREETING
    - bot:
        - hello
        - INTENT:
          - intent_of_the_greeting
utterances:
  GREETING:
    - hi
    - hello!`,
  SCRIPTING_TYPE_JSON: `{
  "convos": [
    {
      "name": "Greet the bot",
      "description": "some optional description",
      "steps": [
        {
          "me": [
            "GREETING"
          ]
        },
        {
          "bot": [
            "hello",
            { "asserter": "INTENT", "args": "intent_of_the_greeting" }
          ]
        }
      ]
    }
  ],
  "utterances": {
    "GREETING": [
      "hi",
      "hello!"
    ]
  }
}`,
  SCRIPTING_TYPE_CONVO: `My Convo

#me
hello

#bot
hello`,
  SCRIPTING_TYPE_PCONVO: `My Partial Convo

#me
hello

#bot
hello`,
  SCRIPTING_TYPE_UTTERANCES: `UTT_HELLO
hi
hello
nice day`,
  SCRIPTING_TYPE_SCRIPTING_MEMORY: `               |product1          |product2
$productName   |Hamburger         |Frankfurter
$customer      |Joe               |John`,
  SCRIPTING_TYPE_CSV: `conversationId,sender,text
Greet the Bot,me,hello
Greet the Bot,bot,hello`
}

class TestSetScript extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deleting: false,
      copying: false,
      moving: false,
      showParaphrasesDialog: false,
      showParaphrasesFor: null,
      showRunConvoRunning: false,
      showRunConvoDialog: false,
      showRunConvoTranscript: null,
      showRunConvoLogs: null,
      showRunConvoError: null,
      showRunConvoChatbotId: null
    }
  }

  codeMirrorOptions(scriptType) {
    switch (scriptType) {
      case 'SCRIPTING_TYPE_YAML':
        return { mode: 'yaml' }
      case 'SCRIPTING_TYPE_JSON':
        return { mode: 'application/json' }
      default:
        return {}
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  validate(testSetId, testSetScriptId, values) {
    if (!values.scriptType || !values.script) return {}

    return new Promise((resolve) => setTimeout(() => {
      const { client } = this.props
      return this.hasWritePermission() && client
        .query({
          query: VALIDATETESTSETSCRIPT_QUERY,
          variables: {
            testSetId,
            testSetScriptId,
            name: values.name || '',
            script: values.script || '',
            scriptType: values.scriptType,
          }
        })
        .then(({ data }) => {
          if (!data.validatetestsetscript) {
            resolve({ script: 'Test Script compilation failed, check syntax' })
          }
          if (data.validatetestsetscript.err) {
            resolve({ script: `${data.validatetestsetscript.err}` })
          }
          if (!data.validatetestsetscript.name) {
            resolve({ script: 'Test Script name missing' })
          }
          this.validatetestsetscript = data.validatetestsetscript
          resolve()
        })
        .catch(err => {
          resolve({ script: `${err.message}` })
        })
    }, 0))
  }

  renderForm(testsetscript, testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, setParentState, client } = this.props

    return (
      <Mutation
        mutation={
          testsetscript.id ? UPDATE_TESTSETSCRIPT : CREATE_TESTSETSCRIPT
        }
        refetchQueries={({ data }) => {
          const result = RefetchTestSetQueries(testSetId, license)

          const testSetScriptId = (data.updateTestSetScript && data.updateTestSetScript.id) || (data.createTestSetScript && data.createTestSetScript.id)
          const name = (data.updateTestSetScript && data.updateTestSetScript.name) || (data.createTestSetScript && data.createTestSetScript.name)
          const scriptType = (data.updateTestSetScript && data.updateTestSetScript.scriptType) || (data.createTestSetScript && data.createTestSetScript.scriptType)

          if (scriptType === 'SCRIPTING_TYPE_UTTERANCES') {
            result.push({
              query: TESTSET_EDITABLE_UTTERANCE_QUERY,
              variables: { testSetScriptId, name }
            })
          }
          if (scriptType === 'SCRIPTING_TYPE_CONVO' || scriptType === 'SCRIPTING_TYPE_PCONVO') {
            result.push({
              query: TESTSET_EDITABLE_CONVO_QUERY,
              variables: { testSetScriptId, name }
            })
          }
          return result
        }}
        onCompleted={data => {
          deleteListQueryFromCache(client.store.cache, /^testsetscriptreferences/)
        }}
      >
        {(mutateTestSetScript, { loading }) => (
          <Form
            onSubmit={async (values, form) => {
              if (testsetscript.id) {
                try {
                  const res = await mutateTestSetScript({
                    variables: {
                      id: values.id,
                      testSetScript: {
                        name: this.validatetestsetscript.name,
                        description: this.validatetestsetscript.description || null,
                        script: values.script,
                        scriptType: values.scriptType,
                        skip: !!values.skip
                      },
                    },
                  })
                  form.initialize(res.data.updateTestSetScript)
                  setAlertSuccessMessage('Test Script ready for use')
                  history.push(`/testsets/view/${testSetId}/testcases/viewscript/${res.data.updateTestSetScript.id}`)
                } catch (error) {
                  setAlertErrorMessage(`Test Script compilation failed`, error)
                }
              } else {
                try {
                  const res = await mutateTestSetScript({
                    variables: {
                      testSetScript: {
                        name: this.validatetestsetscript.name,
                        description: this.validatetestsetscript.description,
                        script: values.script,
                        scriptType: values.scriptType,
                        skip: !!values.skip,
                        testSet: {
                          connect: {
                            id: testSetId,
                          },
                        },
                      },
                    },
                  })
                  form.initialize(res.data.createTestSetScript)
                  setAlertSuccessMessage('Test Script ready for use')
                  history.push(`/testsets/view/${testSetId}/testcases/viewscript/${res.data.createTestSetScript.id}`)
                } catch (error) {
                  setAlertErrorMessage(`Test Script compilation failed`, error)
                }
              }
            }}
            initialValues={testsetscript}
            validate={(values) => loading ? null : this.validate(testSetId, testsetscript.id, values)}
            render={({
              handleSubmit,
              submitting,
              validating,
              invalid,
              values,
              form: { change }
            }) => (
              <form onSubmit={handleSubmit} data-simplelist-container>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={12} borderBottom>
                        <GridContainer autoHeight>
                          <GridItem xs={12} right middle header>
                            {values.scriptType === 'SCRIPTING_TYPE_CONVO' &&
                              <SwitchButton leftLabel="Source Editor" rightLabel="Convo Editor" leftDataUnique="btnSourceEditor" rightDataUnique="btnConvoEditor" checked="left" onRightClick={() => {
                                setParentState({ testCaseViewMode: 'ui' })
                                if (testsetscript.id) {
                                  history.push(`/testsets/view/${testSetId}/testcases/viewconvo/${testsetscript.id}/${encodeURIComponent(testsetscript.name)}`)
                                } else {
                                  history.push(`/testsets/view/${testSetId}/testcases/registerconvo`)
                                }
                              }} />
                            }
                            {values.scriptType === 'SCRIPTING_TYPE_PCONVO' &&
                              <SwitchButton leftLabel="Source Editor" rightLabel="Convo Editor" leftDataUnique="btnSourceEditor" rightDataUnique="btnConvoEditor" checked="left" onRightClick={() => {
                                setParentState({ testCaseViewMode: 'ui' })
                                if (testsetscript.id) {
                                  history.push(`/testsets/view/${testSetId}/testcases/viewpconvo/${testsetscript.id}/${encodeURIComponent(testsetscript.name)}`)
                                } else {
                                  history.push(`/testsets/view/${testSetId}/testcases/registerpconvo`)
                                }
                              }} />
                            }
                            {values.scriptType === 'SCRIPTING_TYPE_UTTERANCES' &&
                              <SwitchButton leftLabel="Source Editor" rightLabel="Utterance Editor" leftDataUnique="btnSourceEditor" rightDataUnique="btnUtteranceEditor" checked="left" onRightClick={() => {
                                setParentState({ testCaseViewMode: 'ui' })
                                if (testsetscript.id) {
                                  history.push(`/testsets/view/${testSetId}/testcases/viewutterance/${testsetscript.id}/${encodeURIComponent(testsetscript.name)}`)
                                } else {
                                  history.push(`/testsets/view/${testSetId}/testcases/registerutterance`)
                                }
                              }} />
                            }
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="scriptType"
                      component={renderSelect}
                      label="Script Type"
                      validate={required}
                      disabled={!this.hasWritePermission()}
                      data-unique="selTestSetScriptScriptType"
                      items={[
                        { key: 'SCRIPTING_TYPE_CONVO', label: 'Convo File (*.convo.txt)' },
                        { key: 'SCRIPTING_TYPE_PCONVO', label: 'Partial Convo File (*.pconvo.txt)' },
                        { key: 'SCRIPTING_TYPE_UTTERANCES', label: 'Utterances File (*.utterances.txt)' },
                        { key: 'SCRIPTING_TYPE_SCRIPTING_MEMORY', label: 'Test Parameter Store (*.scriptingmemory.txt)' },
                        { key: 'SCRIPTING_TYPE_YAML', label: 'Combined YAML File (*.yaml)' },
                        { key: 'SCRIPTING_TYPE_JSON', label: 'Combined JSON File (*.json)' },
                        { key: 'SCRIPTING_TYPE_CSV', label: 'CSV File (*.csv)' },
                      ]}
                    />
                    <OnChange name="scriptType">
                      {() => {
                        const defaultscript = DEFAULT_SCRIPTS[values.scriptType] ? (DEFAULT_SCRIPTS[values.scriptType].script || DEFAULT_SCRIPTS[values.scriptType]) : ''

                        if ((!values.script || values.script === this.scriptBySystem) && defaultscript) {
                          if (defaultscript) {
                            this.scriptBySystem = defaultscript
                          } else {
                            this.scriptBySystem = ''
                          }
                          change('script', this.scriptBySystem)
                        }
                      }}
                    </OnChange>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <Field
                      name="skip"
                      component={renderCheckbox}
                      label="Ignore when running Test Sessions"
                      type="checkbox"
                      disabled={!this.hasWritePermission()}
                      data-unique="chkTestSetScriptSkip"
                    />
                  </GridItem>
                  {(values.scriptType === 'SCRIPTING_TYPE_YAML' || values.scriptType === 'SCRIPTING_TYPE_JSON' || values.scriptType === 'SCRIPTING_TYPE_CSV' || values.scriptType === 'SCRIPTING_TYPE_SCRIPTING_MEMORY') && (
                    <GridItem xs={12}>
                      <Field
                        name="name"
                        component={renderTextField}
                        label="Test Case Script Name"
                        validate={required}
                        disabled={!this.hasWritePermission()}
                        data-unique="txtTestSetScriptTestCaseScriptName"
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12}>
                    <Field
                      name="script"
                      component={renderCodeArea}
                      options={this.codeMirrorOptions(values.scriptType)}
                      label="Test Case Script"
                      validate={required}
                      disabled={!this.hasWritePermission()}
                      data-unique="codeTestSetScriptScript"
                    />
                  </GridItem>
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtons={
                        <React.Fragment>
                          {license.paraphrasing && this.hasWritePermission() && values.scriptType === 'SCRIPTING_TYPE_UTTERANCES' && testsetscript.id && this.renderParaphraseButtons(testSetId, testsetscript.id, testsetscript.name)}
                        </React.Fragment>
                      }
                      rightButtons={
                        <React.Fragment>
                          {this.hasWritePermission() &&
                            <Button
                              type="submit"
                              disabled={submitting || validating || invalid}
                              data-unique="btnTestSetScriptSave"
                            >
                              {validating && <><LoadingIndicator alt /> Validating ...</>}
                              {!validating && submitting && <><LoadingIndicator alt /> Saving ...</>}
                              {!validating && !submitting && <><ShowIcon icon="save" /> Save</>}
                            </Button>
                          }
                        </React.Fragment>
                      } />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  renderParaphraseButtons(testSetId, scriptId, scriptName) {
    return <Button
      secondary
      dashed
      onClick={() => this.props.history.push(`/testsets/view/${testSetId}/testcases/viewscript/${scriptId}/${encodeURIComponent(scriptName)}/paraphraser`)}
      data-unique="btnTestSetUtteranceParaphrase"
    >
      Paraphrase it!
    </Button>
  }

  async runConvo(values, chatbotId, testSetId) {
    const { client } = this.props

    const variables = {
      chatbotId: chatbotId,
      testSetId: testSetId,
      script: values.script,
      scriptType: values.scriptType,
      scriptName: null
    }

    this.setState({
      showRunConvoChatbotId: chatbotId,
      showRunConvoRunning: true,
      showRunConvoDialog: false
    })

    try {
      const { data } = await client.query({
        query: RUN_TESTSETSCRIPT,
        variables,
        fetchPolicy: 'network-only'
      })
      this.setState({
        showRunConvoRunning: false,
        showRunConvoDialog: true,
        showRunConvoTranscript: JSON.parse(data.runtestsetscript.convo),
        showRunConvoLogs: data.runtestsetscript.logs,
        showRunConvoError: data.runtestsetscript.err
      })
    } catch (err) {
      this.setState({
        showRunConvoRunning: false,
        showRunConvoDialog: true,
        showRunConvoTranscript: null,
        showRunConvoLogs: null,
        showRunConvoError: err
      })
    }
  }

  renderRunConvoDialog() {
    const { license } = this.props
    const { showRunConvoChatbotId, showRunConvoTranscript, showRunConvoLogs, showRunConvoError } = this.state

    return (
      <ConfirmationDialog
        cancelText="Close"
        open={this.state.showRunConvoDialog}
        onCancel={() => this.setState({ showRunConvoDialog: false })}
        title="Convo Transcript"
        extraButton={license.detailedReporting && showRunConvoLogs && showRunConvoLogs.length > 0 &&
          <Button secondary data-unique="btnShowRunConvoLogs" onClick={() => {
            openTextInNewTab(showRunConvoLogs.filter(l => l).map(l => l.trim()).join('\n'))
          }}>
            Show Logs
          </Button>
        }
      >
        {(showRunConvoTranscript || showRunConvoError) && <Transcript err={showRunConvoError} steps={showRunConvoTranscript} chatbotId={showRunConvoChatbotId} />}
      </ConfirmationDialog>
    )
  }

  render() {
    const { match } = this.props

    if (match.params && match.params.id) {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Query
              query={TESTSETSCRIPT_QUERY}
              variables={{ id: match.params.id }}
              fetchPolicy="network-only"
            >
              {({ loading, error, data }) => {
                if (loading) return <LoadingIndicator />
                if (error) return <ErrorFormat err={error} />

                if (data.testsetscript) {
                  return this.renderForm(
                    data.testsetscript,
                    data.testsetscript.testSet.id,
                  )
                }
                return null
              }}
            </Query>
          </GridItem>
        </GridContainer>
      )
    } else {
      // This render is buggy. It is called not just fist time, but after the script type is changed
      // and in this case nothin here has sense, we should not use those default settings.
      // there was a bug because scriptBySystem, its workarounded here with the check
      let scriptType = 'SCRIPTING_TYPE_CONVO'
      if (match && match.params && match.params.type) {
        if (match.params.type === 'convo') scriptType = 'SCRIPTING_TYPE_CONVO'
        if (match.params.type === 'pconvo') scriptType = 'SCRIPTING_TYPE_PCONVO'
        if (match.params.type === 'utterance') scriptType = 'SCRIPTING_TYPE_UTTERANCES'
        if (match.params.type === 'scripting') scriptType = 'SCRIPTING_TYPE_SCRIPTING_MEMORY'
        if (match.params.type === 'yaml') scriptType = 'SCRIPTING_TYPE_YAML'
        if (match.params.type === 'csv') scriptType = 'SCRIPTING_TYPE_CSV'
        if (match.params.type === 'json') scriptType = 'SCRIPTING_TYPE_JSON'
      }
      const script = DEFAULT_SCRIPTS[scriptType]

      this.scriptBySystem = this.scriptBySystem || script

      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderForm({ scriptType, script }, match.params.testSetId)}
          </GridItem>
        </GridContainer>
      )
    }
  }
}


export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  withApollo
)(TestSetScript)
