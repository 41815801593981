import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { lookup as mimeLookup, extension as mimeExtension } from 'mime-types'
import { withStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import LinkButton from 'components/Button/LinkButton'
import ComponentChip from 'components/Convo/ComponentChip'
import { DownloadWrapper } from 'helper/downloadHelper'
import { openDataUriInNewTab } from 'helper/browserHelper'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import AudioButton from 'components/Button/AudioButton'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import { VisualAudioPlayer } from './VisualAudioPlayer'
import classNames from 'classnames'
import _ from 'lodash'

const styles = theme => ({
  mediaCardIcon: {
    position: 'absolute',
    top: 4,
    left: 4
  },
  mediaCardDownload: {
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 100
  },
  mediaCardContainer: {
    marginRight: 15,
    marginLeft: 15,
  },
  mediaCardContainerFull: {
    display: 'contents'
  },
  mediaCard: {
    marginTop: 10,
    border: '1px solid #ccc',
    background: 'white',
    width: 100,
    borderRadius: 6,
    overflow: 'hidden'
  },
  mediaCardContent: {
    height: 100,
    width: '100%',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  mediaCardFooter: {
    width: '100%',
    backgroundColor: theme.colors.common.backgroundDark,
    textAlign: 'center'
  }

})

function ImageTiles({ ...props }) {
  const { classes, images, columns, forceMimeType, maxHeight, noStyling, cards, mimeTypeFilter, heading, white } = props

  const [ mediaDialogOpen, setMediaDialogOpen ] = useState(false)
  const [ mediaData, setMediaData ] = useState(null)
  const [ mediaMimeType, setMediaMimeType ] = useState(null)

  const mapMimeType = (mimeType) => {
    if (mimeType === 'audio/mpeg3') return 'audio/mpeg'
    if (mimeType === 'audio/wave') return 'audio/wav'
    return mimeType
  }

  const genFilename = (image) => {
    if (_.isString(image.title)) return image.title
    if (image.imageSrc.startsWith('data:')) {
      const mimeType = getMimeType(image)
      const extension = mimeExtension(mimeType)
      return `download.${extension}`
    }
    return null
  }

  const renderMediaCard = (mimeType, image, blob) => {

    if (mimeType && mimeType.startsWith('audio') && image.title === 'full_record.wav') {
      return <div className={classes.mediaCard + ' ' + classes.mediaCardContainer} data-unique={`mediaCard_${image.title ? image.title : image.index}`} style={{ width: '100%' }}>
      <GridContainer>
        <GridItem xs={12} center middle>
        <div className={classes.mediaCardContent}>
          <div className={classes.mediaCardIcon}>
            <ShowIcon custom icon="attachmentAudio" />
          </div>
          <div className={classes.mediaCardDownload}>
            <a href={image.imageSrc} download={image.title} title="Download" target="_blank" rel="noopener noreferrer"><Text primary common><ShowIcon icon="download" /></Text></a>
          </div>
          <VisualAudioPlayer blob={blob} />
          </div>
        </GridItem>
        <GridItem xs={12} grey>
          <div className={classes.mediaCardFooter}>
            <Text white>Full Audio Record</Text>
          </div>
        </GridItem>
      </GridContainer>
    </div>
    }

    return <div className={classes.mediaCard} data-unique={`mediaCard_${image.title ? image.title : image.index}`}>
      <GridContainer>
        <GridItem xs={12} center middle>
        <div className={classes.mediaCardContent}>
          <div className={classes.mediaCardIcon}>
            {mimeType && mimeType.startsWith('audio') ? <ShowIcon custom icon="attachmentAudio" /> : ''}
            {mimeType && mimeType.startsWith('image') ? <ShowIcon custom icon="attachmentImage" /> : ''}
            {mimeType && mimeType.startsWith('video') ? <ShowIcon custom icon="attachmentVideo" /> : ''}
            {mimeType && !mimeType.startsWith('image') && !mimeType.startsWith('audio') && !mimeType.startsWith('video') ? <Text primary common><ShowIcon icon="file" /></Text> : ''}
          </div>
          {!_.isNil(genFilename(image)) && <div className={classes.mediaCardDownload}>
            <a download={genFilename(image)} href={image.imageSrc} title="Download" target="_blank" rel="noopener noreferrer"><Text primary common><ShowIcon icon="download" /></Text></a>
          </div>}
          {mimeType && mimeType.startsWith('image') ? <Button link onClick={() => { setMediaData(image); setMediaMimeType(mimeType); setMediaDialogOpen(true)}}><img src={image.imageSrc} alt={image.title} /></Button> : null}
          {mimeType && mimeType.startsWith('audio') ? <AudioButton Border iconDark audioBase64={image.imageSrc} /> : null}
          {mimeType && mimeType.startsWith('video') ? <Button justIcon Border iconDark onClick={() => { setMediaData(image); setMediaMimeType(mimeType); setMediaDialogOpen(true)}}><ShowIcon icon="play" /></Button> : null}
          {mimeType && !mimeType.startsWith('image') && !mimeType.startsWith('audio') && !mimeType.startsWith('video') ? cardLinkButton(image.imageSrc, <>{'Show File'}</>) : ''}
          </div>
        </GridItem>
        <GridItem xs={12} grey>
          <div className={classes.mediaCardFooter}>
            <Text white>
          {mimeType && mimeType.startsWith('audio') ? 'Audio' : ''}
          {mimeType && mimeType.startsWith('video') ? 'Video' : ''}
          {mimeType && mimeType.startsWith('image') ? 'Image' : ''}
          {mimeType && !mimeType.startsWith('image') && !mimeType.startsWith('audio') && !mimeType.startsWith('video') ? 'File' : ''}
          </Text>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  }

  const getMimeType = (image) => {
    return mapMimeType(forceMimeType || image.mimeType || (image.imageSrc && mimeLookup(image.imageSrc)))
  }

  const linkButton = (src, children) => {
    return <LinkButton style={!noStyling ? {marginBottom: 10, marginTop: 10, padding: 10, backgroundColor: 'white', display: 'inline-block', border: '1px #ccc solid', borderRadius: 10} : {}} onClick={() => {
      openDataUriInNewTab(src)
    }}>{children}</LinkButton>
  }

  const cardLinkButton = (src, children) => {
    return <LinkButton onClick={() => {
      openDataUriInNewTab(src)
    }}>{children}</LinkButton>
  }

  const filteredMedia = (mimeTypeFilter) ? images.filter(e => {
    const mimeType = getMimeType(e)
    return (mimeType.startsWith(mimeTypeFilter))
  }) : images

  if (filteredMedia.length === 0) return null

  return (
    <div className={classes.root}>
      <ConfirmationDialog
          cancelText="Close"
          open={mediaDialogOpen}
          onCancel={() => {setMediaDialogOpen(false)}}
          title={mediaData && mediaData.title}>
            <GridContainer>
              <GridItem xs={12} center>
                {mediaData && mediaMimeType && mediaMimeType.startsWith('image') && linkButton(mediaData.imageSrc, <img src={mediaData.imageSrc} alt={mediaData.title} />)}
                {mediaData && mediaMimeType && mediaMimeType.startsWith('video') && linkButton(mediaData.videoSrc, <video id={mediaData.id} controls={true} height={200} width={250} title={mediaData.title}>
                    <source src={mediaData.imageSrc} type={mediaMimeType}></source>
                    <a href={mediaData.imageSrc} target="_blank" rel="noopener noreferrer">{mediaData.title || 'Download'}</a>
                  </video>)}
              </GridItem>
            </GridContainer>
        </ConfirmationDialog>
      {heading && !white && <Text primary>{heading}</Text>}
      {heading && white && <Text white>{heading}</Text>}
      <GridContainer >
        {filteredMedia.map((image, index) => {
          image.index = index

          const mimeType = getMimeType(image) || 'application/unknown'
          const cols = Object.assign({}, { xs: 12 } ,(!mimeType.startsWith('audio')) ? columns || {} : {})

          if (cards) {
            if (image.downloadSrc) {
              return (
                <div className={classNames({
                  [classes.mediaCardContainer]: true,
                  [classes.mediaCardContainerFull]: image.title === 'full_record.wav'
                })} {...{ key: image.id }}>
                  <DownloadWrapper url={image.downloadSrc}>
                    {({ loading, src, blob, err }) => {
                      if (loading) return <LoadingIndicator large />
                      if (err) return <ErrorFormat err={err} />
                      if (!src) return null

                      image.imageSrc = src
  
                      if (mimeType && mimeType.startsWith('image')) {
                          return renderMediaCard(mimeType, image)
                      }
                      else if (mimeType && mimeType.startsWith('audio')) {
                          return renderMediaCard(mimeType, image, blob)                    
                      }
                      else if (mimeType && mimeType.startsWith('video')) {
                          return renderMediaCard(mimeType, image)
                      }
                      else {
                        return renderMediaCard(mimeType, image)
                      }
  
                      
                    }}
                  </DownloadWrapper>
                </div>
              )
            } else {
              if (image.base64) {
                image.imageSrc = `data:${mimeType};base64,${image.base64}`
              }
              if (image.href && !image.imageSrc) {
                image.imageSrc = image.href
              }
  
              if (image.imageSrc && (image.imageSrc.startsWith('http://') || image.imageSrc.startsWith('https://') || image.imageSrc.startsWith('data:'))) {
                return (
                  <div className={classes.mediaCardContainer} {...{ key: image.id }}>
                    {mimeType && mimeType.startsWith('image') &&
                      renderMediaCard(mimeType, image)
                    }
                    {mimeType && mimeType.startsWith('audio') &&
                      renderMediaCard(mimeType, image)  
                    }
                    {mimeType && mimeType.startsWith('video') &&
                      renderMediaCard(mimeType, image)
                    }
                    {mimeType && !mimeType.startsWith('image') && !mimeType.startsWith('audio') && !mimeType.startsWith('video') &&
                      renderMediaCard(mimeType, image)
                    }
                  </div>
                )
              } else {
                return (
                  <GridItem {...{ key: image.id, ...cols }}>
                    <ComponentChip component={{ name: 'MEDIA', args: [image.imageSrc] }} />
                  </GridItem>
                )
              }
            }
          }

          if (image.downloadSrc) {
            return (
              <GridItem {...{ key: image.id, ...cols }}>
                <DownloadWrapper url={image.downloadSrc}>
                  {({ loading, src, err }) => {
                    if (loading) return <LoadingIndicator large />
                    if (err) return <ErrorFormat err={err} />
                    if (!src) return null

                    image.imageSrc = src

                    if (mimeType && mimeType.startsWith('image')) {
                        return renderMediaCard(mimeType, image)
                    }
                    if (mimeType && mimeType.startsWith('audio')) {
                        return <audio id={image.id} controls={true} title={image.title}>
                          <source src={src} type={mimeType}></source>
                          <div><a href={src} target="_blank" rel="noopener noreferrer">{image.title || 'Download'}</a></div>
                        </audio>
                    }
                    if (mimeType && mimeType.startsWith('video')) {
                        return <video id={image.id} controls={true} height={200} width={250} title={image.title}>
                        <source src={src} type={mimeType}></source>
                        <a href={src} target="_blank" rel="noopener noreferrer">{image.title || 'Download'}</a>
                      </video>
                    }

                    return linkButton(src, <>{image.title || 'Show'}</>)
                  }}
                </DownloadWrapper>
              </GridItem>
            )
          } else {
            if (image.base64) {
              image.imageSrc = `data:${mimeType};base64,${image.base64}`
            }
            if (image.href && !image.imageSrc) {
              image.imageSrc = image.href
            }

            if (image.imageSrc && (image.imageSrc.startsWith('http://') || image.imageSrc.startsWith('https://') || image.imageSrc.startsWith('data:'))) {
              return (
                <GridItem {...{ key: image.id, ...cols }}>
                  {mimeType && mimeType.startsWith('image') &&
                    <>
                      {cards ? 
                        renderMediaCard(mimeType, image)
                      :
                        linkButton(image.imageSrc, <img src={image.imageSrc} alt={image.title} style={maxHeight ? {maxHeight: maxHeight, width: 'auto'} : {}} />)}
                    </>
                  }
                  {mimeType && mimeType.startsWith('audio') &&
                  <>
                  {cards ? 
                    renderMediaCard(mimeType, image)
                  :
                  <audio id={image.id} controls={true} title={image.title}>
                  <source src={image.imageSrc} type={mimeType}></source>
                  <a href={image.imageSrc} target="_blank" rel="noopener noreferrer">{image.title || 'Download'}</a>
                </audio>}
                  </>
                    
                  }
                  {mimeType && mimeType.startsWith('video') &&
                  <>
                  {cards ? 
                    renderMediaCard(mimeType, image)
                  :
                    <video id={image.id} controls={true} height={200} width={250} title={image.title}>
                    <source src={image.imageSrc} type={mimeType}></source>
                    <a href={image.imageSrc} target="_blank" rel="noopener noreferrer">{image.title || 'Download'}</a>
                  </video>}
                </>
                    
                  }
                  {mimeType && !mimeType.startsWith('image') && !mimeType.startsWith('audio') && !mimeType.startsWith('video') &&
                    linkButton(image.imageSrc, <>{image.title || 'Show'}</>)
                  }
                </GridItem>
              )
            } else {
              return (
                <GridItem {...{ key: image.id, ...cols }}>
                  <ComponentChip component={{ name: 'MEDIA', args: [image.imageSrc] }} />
                </GridItem>
              )
            }
          }
        })}
      </GridContainer>
    </div>
  )
}

ImageTiles.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      downloadSrc: PropTypes.string,
      imageSrc: PropTypes.string,
      base64: PropTypes.string,
      mimeType: PropTypes.string,
      id: PropTypes.any.isRequired,
      title: PropTypes.string,
    }),
  ),
  columns: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
  forceMimeType: PropTypes.string
}

export default connect(
  state => ({ })
)(withStyles(styles)(ImageTiles))
