import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { withApollo, Mutation } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { renderFileUpload, renderTextField, composeValidators, url, required, FormActionsToolbar } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import ShowIcon from 'components/Icon/ShowIcon'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { IMPORT_PRISMA, IMPORT_PRISMA_FROM_URL } from './gql'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { decodeURIComponentWeak } from 'views/helper'

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponentWeak(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

class ImportPrisma extends React.Component {
  render() {
    const { setAlertSuccessMessage, setAlertErrorMessage, client, disableHelper, label, disableUrlUpload, uploadOnDrop, allowedModel } = this.props

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Mutation mutation={IMPORT_PRISMA}>
            {(importPrisma, { loading, error }) => (
              <Form
                onSubmit={async values => {
                  try {
                    await importPrisma({
                      variables: {
                        prismaFile: values.filecontent,
                        allowedModel: allowedModel
                      }
                    })
                    await client.resetStore()
                    setAlertSuccessMessage('File imported')
                  } catch(error) {
                    setAlertErrorMessage('File import failed', error)
                  }
                }}
                render={({
                  handleSubmit,
                  form: {
                    change,
                    submit
                  },
                  submitting,
                  invalid,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12}>
                        <Field
                          name="fileupload"
                          component={renderFileUpload}
                          data-unique="fileImportPrismaUpload"
                          accept=".json,.zip"
                          label={label ? label : 'Select/Drop Botium Import File (*.json) or Zip Archive (*.zip)'}
                          values={values}
                          change={change}
                          validate={required}
                          onFileLoaded={(filename, filecontent) => {
                            if (filename.endsWith('.json')) {
                              try {
                                const importJson = JSON.parse(b64DecodeUnicode(filecontent))
                                change('filename', filename)
                                change('filecontent', JSON.stringify(importJson))

                                setAlertSuccessMessage(`Read JSON from file ${filename}`)
                                if (uploadOnDrop) submit()
                              } catch (err) {
                                setAlertErrorMessage(`Reading JSON from file ${filename} failed`, err)
                                change('fileupload', null)
                                if (uploadOnDrop) submit()
                              }
                            } else if (filename.endsWith('.zip')) {
                              change('filename', filename)
                              change('filecontent', filecontent)
                            } else {
                              setAlertErrorMessage(`Only *.json and *.zip files allowed`)
                              change('fileupload', null)
                            }
                          }}
                        />
                        <Text muted>
                          {!disableHelper && 'Depending on your database backend, there may be size restrictions for uploaded files.'}
                        </Text>
                      </GridItem>
                    </GridContainer>
                    {!uploadOnDrop && <GridContainer>
                      <GridItem xs={12}>
                        <FormActionsToolbar
                          rightButtons={<>
                            <Button
                              type="submit"
                              disabled={submitting || invalid}
                              data-unique="btnImportPrismaImportFromFile"
                            >
                              {submitting && <LoadingIndicator alt />}
                              {!submitting && <ShowIcon icon="save" />}                  
                              Import from File
                            </Button>
                          </>}
                        />                        
                      </GridItem>
                    </GridContainer>}
                  </form>
                )}
              />
            )}
          </Mutation>
        </GridItem>
        {!disableUrlUpload && <GridItem xs={12}>
          <Mutation
            mutation={IMPORT_PRISMA_FROM_URL}
          >
            {(importPrismaFromUrl, { loading, error }) => (
              <Form
                onSubmit={async (values, form) => {
                  try {
                    await importPrismaFromUrl({
                      variables: {
                        prismaUrl: values.prismaUrl
                      }
                    })
                    await client.resetStore()
                    setAlertSuccessMessage('File imported')
                  } catch(error) {
                    setAlertErrorMessage('File import failed', error)
                  }
                }}
                render={({
                  handleSubmit,
                  submitting,
                  invalid
                }) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12}>
                        <Field
                          name="prismaUrl"
                          component={renderTextField}
                          label="Or enter HTTP URL to download the file to import from"
                          validate={composeValidators(url, required)}
                          data-unique="txtImportPrismaUrl"
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12}>
                        <FormActionsToolbar
                          rightButtons={<>
                            <Button
                              type="submit"
                              disabled={submitting || invalid}
                              data-unique="btnImportPrismaImportFromUrl"
                            >
                              {submitting && <LoadingIndicator alt />}
                              {!submitting && <ShowIcon icon="save" />}                  
                              Import from URL
                            </Button>
                          </>}
                        />
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
              />
            )}
          </Mutation>
        </GridItem>}
      </GridContainer>
    )
  }
}

export default connect(
  state => state,
  { setAlertSuccessMessage, setAlertErrorMessage },
)(withStyles(settingsStyle)(withApollo(ImportPrisma)))
