import React from 'react'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  renderSelect,
  renderIntField,
  renderCheckbox,
  required,
  minValue,
  parseInteger
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function dockerCaps2Form(caps) {
  const formValues = {
    docker: {
      startcmd: (caps.find(c => c.name === 'STARTCMD') || { stringValue: '' })
        .stringValue,
      dockerimage: (
        caps.find(c => c.name === 'DOCKERIMAGE') || { stringValue: '' }
      ).stringValue,
      healthcheckpath: (
        caps.find(c => c.name === 'BOT_HEALTH_CHECK_PATH') || {
          stringValue: '',
        }
      ).stringValue,
      healthcheckverb: (
        caps.find(c => c.name === 'BOT_HEALTH_CHECK_VERB') || {
          stringValue: '',
        }
      ).stringValue,
      healthstatus: (caps.find(c => c.name === 'BOT_HEALTH_STATUS') || {})
        .intValue,
      facebookapi: (caps.find(c => c.name === 'FACEBOOK_API') || {})
        .booleanValue,
      facebookwebhookpath: (
        caps.find(c => c.name === 'FACEBOOK_WEBHOOK_PATH') || {
          stringValue: '',
        }
      ).stringValue,
      facebookwebhookport: (
        caps.find(c => c.name === 'FACEBOOK_WEBHOOK_PORT') || {}
      ).intValue,
      slackapi: (caps.find(c => c.name === 'SLACK_API') || {}).booleanValue,
      slackeventpath: (
        caps.find(c => c.name === 'SLACK_EVENT_PATH') || { stringValue: '' }
      ).stringValue,
      slackeventport: (caps.find(c => c.name === 'SLACK_EVENT_PORT') || {})
        .intValue,
      slackoauthpath: (
        caps.find(c => c.name === 'SLACK_OAUTH_PATH') || { stringValue: '' }
      ).stringValue,
      slackoauthport: (caps.find(c => c.name === 'SLACK_OAUTH_PORT') || {})
        .intValue,
      botframeworkapi: (caps.find(c => c.name === 'BOTFRAMEWORK_API') || {})
        .booleanValue,
      botframeworkwebhookpath: (
        caps.find(c => c.name === 'BOTFRAMEWORK_WEBHOOK_PATH') || {
          stringValue: '',
        }
      ).stringValue,
      botframeworkwebhookport: (
        caps.find(c => c.name === 'BOTFRAMEWORK_WEBHOOK_PORT') || {}
      ).intValue,
      botframeworkappid: (
        caps.find(c => c.name === 'BOTFRAMEWORK_APP_ID') || { stringValue: '' }
      ).stringValue,
      botframeworkchannelid: (
        caps.find(c => c.name === 'BOTIUM_BOTFRAMEWORK_CHANNEL_ID') || {
          stringValue: '',
        }
      ).stringValue,
    },
  }
  return formValues
}

const capNamesMap = {
  'docker.startcmd': 'STARTCMD',
  'docker.dockerimage': 'DOCKERIMAGE',
  'docker.healthcheckpath': 'BOT_HEALTH_CHECK_PATH',
  'docker.healthcheckverb': 'BOT_HEALTH_CHECK_VERB',
  'docker.healthstatus': 'BOT_HEALTH_STATUS',
  'docker.facebookapi': 'FACEBOOK_API',
  'docker.facebookwebhookpath': 'FACEBOOK_WEBHOOK_PATH',
  'docker.facebookwebhookport': 'FACEBOOK_WEBHOOK_PORT',
  'docker.slackapi': 'SLACK_API',
  'docker.slackeventpath': 'SLACK_EVENT_PATH',
  'docker.slackeventport': 'SLACK_EVENT_PORT',
  'docker.slackoauthpath': 'SLACK_OAUTH_PATH',
  'docker.slackoauthport': 'SLACK_OAUTH_PORT',
  'docker.botframeworkapi': 'BOTFRAMEWORK_API',
  'docker.botframeworkwebhookpath': 'BOTFRAMEWORK_WEBHOOK_PATH',
  'docker.botframeworkwebhookport': 'BOTFRAMEWORK_WEBHOOK_PORT',
  'docker.botframeworkappid': 'BOTFRAMEWORK_APP_ID',
  'docker.botframeworkchannelid': 'BOTIUM_BOTFRAMEWORK_CHANNEL_ID',
}

export function dockerForm2caps(values) {
  const capabilities = [
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'docker' },
    { name: 'STARTCMD', type: 'STRING', stringValue: values.docker.startcmd },
    {
      name: 'DOCKERIMAGE',
      type: 'STRING',
      stringValue: values.docker.dockerimage,
    },
    {
      name: 'BOT_HEALTH_CHECK_PATH',
      type: 'STRING',
      stringValue: values.docker.healthcheckpath,
    },
    {
      name: 'BOT_HEALTH_CHECK_VERB',
      type: 'STRING',
      stringValue: values.docker.healthcheckverb,
    },
    {
      name: 'BOT_HEALTH_STATUS',
      type: 'INT',
      intValue: values.docker.healthstatus,
    },
    {
      name: 'FACEBOOK_API',
      type: 'BOOLEAN',
      booleanValue: values.docker.facebookapi,
    },
    values.docker.facebookapi && {
      name: 'FACEBOOK_WEBHOOK_PATH',
      type: 'STRING',
      stringValue: values.docker.facebookwebhookpath,
    },
    values.docker.facebookapi && {
      name: 'FACEBOOK_WEBHOOK_PORT',
      type: 'INT',
      intValue: values.docker.facebookwebhookport,
    },
    {
      name: 'SLACK_API',
      type: 'BOOLEAN',
      booleanValue: values.docker.slackapi,
    },
    values.docker.slackapi && {
      name: 'SLACK_EVENT_PATH',
      type: 'STRING',
      stringValue: values.docker.slackeventpath,
    },
    values.docker.slackapi && {
      name: 'SLACK_EVENT_PORT',
      type: 'INT',
      intValue: values.docker.slackeventport,
    },
    values.docker.slackapi && {
      name: 'SLACK_OAUTH_PATH',
      type: 'STRING',
      stringValue: values.docker.slackoauthpath,
    },
    values.docker.slackapi && {
      name: 'SLACK_OAUTH_PORT',
      type: 'INT',
      intValue: values.docker.slackoauthport,
    },
    {
      name: 'BOTFRAMEWORK_API',
      type: 'BOOLEAN',
      booleanValue: values.docker.botframeworkapi,
    },
    values.docker.botframeworkapi && {
      name: 'BOTFRAMEWORK_WEBHOOK_PATH',
      type: 'STRING',
      stringValue: values.docker.botframeworkwebhookpath,
    },
    values.docker.botframeworkapi && {
      name: 'BOTFRAMEWORK_WEBHOOK_PORT',
      type: 'INT',
      intValue: values.docker.botframeworkwebhookport,
    },
    values.docker.botframeworkapi && {
      name: 'BOTFRAMEWORK_APP_ID',
      type: 'STRING',
      stringValue: values.docker.botframeworkappid,
    },
    values.docker.botframeworkapi && {
      name: 'BOTIUM_BOTFRAMEWORK_CHANNEL_ID',
      type: 'STRING',
      stringValue: values.docker.botframeworkchannelid,
    },
  ]
  return capabilities.filter(c => c)
}

export class DockerEdit extends React.Component {
  render() {
    const { advanced, disabled, capSetCapNames } = this.props
    return (
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => (
          <React.Fragment>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <Field
                  name="docker.dockerimage"
                  component={renderTextField}
                  label="Docker Image"
                  validate={required}
                  data-unique="txtDockerEditDockerImage"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.dockerimage']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['docker.dockerimage'])}
                />
                <Text helperText>
                  This docker image is used as base image for hosting your
                  chatbot code. Choose one from{' '}
                  <a
                    href="https://hub.docker.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Docker Hub
                  </a>{' '}
                  or another docker image registry.
                </Text>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="docker.startcmd"
                  component={renderTextField}
                  label="Chatbot Start Command"
                  validate={required}
                  data-unique="txtDockerEditStartCmd"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.startcmd']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['docker.startcmd'])}
                />
                <Text helperText>
                  This command is used to start your chatbot. For Node.js
                  projects, this is typically similar to "npm start", for C# it
                  might be something like "dotnet run".
                </Text>
              </GridItem>
            </GridContainer>
            {advanced && (
              <React.Fragment>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="docker.healthcheckpath"
                      component={renderTextField}
                      label="Health Check URL Path"
                      data-unique="txtDockerEditHealthCheckPath"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.healthcheckpath']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['docker.healthcheckpath'])}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="docker.healthcheckverb"
                      component={renderSelect}
                      label="Health Check HTTP Verb"
                      data-unique="selDockerEditHealthCheckVerb"
                      items={[
                        { key: 'GET' },
                        { key: 'POST' },
                        { key: 'PUT' },
                      ]}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.healthcheckverb']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['docker.healthcheckverb'])}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="docker.healthstatus"
                      component={renderIntField}
                      parse={parseInteger}
                      label="Health Check HTTP Status"
                      validate={minValue(1)}
                      data-unique="intDockerEditHealthStatus"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.healthstatus']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['docker.healthstatus'])}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <Text helperText>
                      Optionally configure how the health check of your chatbot
                      code is performed (Botium will only start after your
                      chatbot is active).
                    </Text>
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            )}
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="docker.facebookapi"
                  component={renderCheckbox}
                  label="Enable Facebook Messenger API Mocker"
                  type="checkbox"
                  data-unique="chkDockerEditFacebookApi"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.facebookapi']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['docker.facebookapi'])}
                />
              </GridItem>
            </GridContainer>
            {advanced &&
              values.docker.facebookapi && (
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="docker.facebookwebhookpath"
                      component={renderTextField}
                      label="Facebook Webhook URL Path"
                      data-unique="txtDockerEditFacebookWebhookPath"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.facebookwebhookpath']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['docker.facebookwebhookpath'])}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="docker.facebookwebhookport"
                      component={renderIntField}
                      parse={parseInteger}
                      label="Facebook Webhook Port"
                      validate={minValue(1)}
                      data-unique="intDockerEditFacebookWebhookPort"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.facebookwebhookport']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['docker.facebookwebhookport'])}
                    />
                  </GridItem>
                </GridContainer>
              )}
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="docker.slackapi"
                  component={renderCheckbox}
                  label="Enable Slack API Mocker"
                  type="checkbox"
                  data-unique="chkDockerEditSlackApi"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.slackapi']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['docker.slackapi'])}
                />
              </GridItem>
            </GridContainer>
            {advanced &&
              values.docker.slackapi && (
                <React.Fragment>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="docker.slackeventpath"
                        component={renderTextField}
                        label="Slack Event URL Path"
                        data-unique="txtDockerEditSlackEventPath"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.slackeventpath']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['docker.slackeventpath'])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="docker.slackeventport"
                        component={renderIntField}
                        parse={parseInteger}
                        label="Slack Event Port"
                        validate={minValue(1)}
                        data-unique="intDockerEditSlackEventPort"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.slackeventport']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['docker.slackeventport'])}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="docker.slackoauthpath"
                        component={renderTextField}
                        label="Slack OAuth URL Path"
                        data-unique="txtDockerEditSlackOAuthPath"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.slackoauthpath']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['docker.slackoauthpath'])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="docker.slackoauthport"
                        component={renderIntField}
                        parse={parseInteger}
                        label="Slack OAuth Port"
                        validate={minValue(1)}
                        data-unique="intDockerEditSlackOAuthPort"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.slackoauthport']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['docker.slackoauthport'])}
                      />
                    </GridItem>
                  </GridContainer>
                </React.Fragment>
              )}
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="docker.botframeworkapi"
                  component={renderCheckbox}
                  label="Enable Bot Framework API Mocker"
                  type="checkbox"
                  data-unique="chkDockerEditBotFrameworkApi"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.botframeworkapi']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['docker.botframeworkapi'])}
                />
              </GridItem>
            </GridContainer>
            {advanced &&
              values.docker.botframeworkapi && (
                <React.Fragment>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="docker.botframeworkwebhookpath"
                        component={renderTextField}
                        label="Bot Framework Webhook URL Path"
                        data-unique="txtDockerEditBotFrameworkWebhookPath"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.botframeworkwebhookpath']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['docker.botframeworkwebhookpath'])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="docker.botframeworkwebhookport"
                        component={renderIntField}
                        parse={parseInteger}
                        label="Bot Framework Webhook Port"
                        validate={minValue(1)}
                        data-unique="intDockerEditBotFrameworkWebhookPort"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.botframeworkwebhookport']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['docker.botframeworkwebhookport'])}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="docker.botframeworkappid"
                        component={renderTextField}
                        label="Use Bot Framework App Id"
                        data-unique="txtDockerEditBotFrameworkAppId"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.botframeworkappid']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['docker.botframeworkappid'])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="docker.botframeworkchannelid"
                        component={renderTextField}
                        label="Use Bot Framework Channel Id"
                        data-unique="txtDockerEditBotFrameworkChannelId"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['docker.botframeworkchannelid']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['docker.botframeworkchannelid'])}
                      />
                    </GridItem>
                  </GridContainer>
                </React.Fragment>
              )}
          </React.Fragment>
        )}
      />
    )
  }
}
