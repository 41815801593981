import { gql } from 'apollo-boost'
import { deleteListQueryFromCache } from 'helper/cacheHelper'
import { NAMESPACES_QUERY } from '../Settings/gql'

const BASIC_CRAWLERPROJECT_FRAGMENT = gql`
  fragment BasicCrawlerProject on CrawlerProject {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    chatbot {
      id
      name
      namespace
      avatar
      containermode
    }
    tags
    entryPoints
    crawlerSessions {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
    }
  }
`

export const CRAWLERPROJECTS_QUERY = gql`
  query CrawlerProjectsQuery {
    crawlerprojects {
      ...BasicCrawlerProject
    }
  }
  ${BASIC_CRAWLERPROJECT_FRAGMENT}
`

export const CRAWLERPROJECTS_PAGINATED_QUERY = gql`
  query CrawlerProjectsQuery($where: CrawlerProjectWhereInput, $orderBy: CrawlerProjectOrderByInput, $skip: Int, $first: Int) {
    crawlerprojects(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      ...BasicCrawlerProject
    }
  }
  ${BASIC_CRAWLERPROJECT_FRAGMENT}
  `

export const CRAWLERPROJECTS_COUNT_PAGINATED_QUERY = gql`
  query CrawlerProjectsCountQuery($where: CrawlerProjectWhereInput) {
    crawlerprojectsCount(where: $where)
  }
`

const FULL_CRAWLERPROJECT_FRAGMENT = gql`
  fragment FullCrawlerProject on CrawlerProject {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    description
    tags
    chatbot {
      id
      name
      namespace
      avatar
      containermode
    }
    agent {
      id
      name
    }
    device {
      id
      name
    }
    entryPoints
    exitCriteria
    ignoreButtons
    dynamicWelcomeMessages
    numberOfWelcomeMessages
    depth
    waitForPrompt
    generateUtterances
    mergeUtterances
    notificationReceiversEmail
    searchAndReplaceRules {
      id
      search
      replace
    }
  }
`

export const CRAWLERPROJECT_QUERY = gql`
  query CrawlerProjectQuery($id: ID!) {
    crawlerproject(id: $id) {
      ...FullCrawlerProject
    }
  }
  ${FULL_CRAWLERPROJECT_FRAGMENT}
`

export const CREATE_CRAWLERPROJECT = gql`
  mutation CreateCrawlerProject($crawlerProject: CrawlerProjectCreateInput!) {
    createCrawlerProject(crawlerProject: $crawlerProject) {
      ...FullCrawlerProject
    }
  }
  ${FULL_CRAWLERPROJECT_FRAGMENT}
`

export const UPDATE_CRAWLERPROJECT = gql`
  mutation UpdateCrawlerProject($id: ID!, $crawlerProject: CrawlerProjectUpdateInput!, $applySearchAndReplace: Boolean) {
    updateCrawlerProject(id: $id, crawlerProject: $crawlerProject, applySearchAndReplace: $applySearchAndReplace) {
      ...FullCrawlerProject
    }
  }
  ${FULL_CRAWLERPROJECT_FRAGMENT}
`

export const DELETE_CRAWLERPROJECT = gql`
  mutation DeleteCrawlerProject($id: ID!) {
    deleteCrawlerProject(id: $id)
  }
`

export const START_CRAWLERPROJECT = gql`
  mutation StartCrawlerProject($id: ID!, $debug: Boolean!, $crawlerSessionId: ID) {
    startCrawlerProject(id: $id, debug: $debug, crawlerSessionId: $crawlerSessionId) {
      id
    }
  }
`

const FULL_CRAWLERSESSION_FRAGMENT = gql`
  fragment FullCrawlerSession on CrawlerSession {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    tags
    description
    chatbot {
      id
      name
      namespace
      avatar
      containermode
    }
    agent {
      id
      name
    }
    status
    finished
    crawlerProject {
      id
      name
      namespace
    }
    entryPoints
    exitCriteria
    ignoreButtons
    dynamicWelcomeMessages
    numberOfWelcomeMessages
    depth
    waitForPrompt
    generateUtterances
    mergeUtterances
    scripts {
      id
      name
      path
      stucked
      markedWithEndOfConversation
      exitCriteriaMatch
      script
      scriptType
      err
    }
    jobs {
      id
      jobName
      started
      finished
      status
      err
    }
    chartData
  }
`

export const CRAWLERSESSIONS_QUERY = gql`
  query CrawlerSessionsQuery($crawlerProjectId: ID!, $orderBy: CrawlerSessionOrderByInput!, $skip: Int, $first: Int) {
    crawlersessions(crawlerProjectId: $crawlerProjectId, orderBy: $orderBy, skip: $skip, first: $first) {
      ...FullCrawlerSession
    }
  }
  ${FULL_CRAWLERSESSION_FRAGMENT}
`

export const CRAWLERSESSION_QUERY = gql`
  query CrawlerSessionQuery($id: ID!) {
    crawlersession(id: $id) {
      ...FullCrawlerSession
    }
  }
  ${FULL_CRAWLERSESSION_FRAGMENT}
`

export const CRAWLERSESSION_PROGRESS_SUBSCRIPTION = gql`
  subscription CrawlerSessionProgressSubscription($id: ID!) {
    crawlerSessionProgress(crawlerSessionId: $id) {
      ...FullCrawlerSession
    }
  }
  ${FULL_CRAWLERSESSION_FRAGMENT}
`

export const CRAWLERSESSION_TREE_QUERY = gql`
  query CrawlerSessionTreeQuery($id: ID!) {
    crawlersessiontree(id: $id)
  }
`

const FULL_CRAWLERSESSIONSCRIPT_FRAGMENT = gql`
  fragment FullCrawlerSessionScript on CrawlerSessionScript {
    id
    name
    path
    stucked
    markedWithEndOfConversation
    exitCriteriaMatch
    script
    scriptType
    err
    crawlerSession {
      crawlerProject {
        id
      }
    }
  }
`

export const CRAWLERSESSIONSCRIPTSFIlTERED_QUERY = gql`
  query CrawlerSessionScriptsFilteredQuery($crawlerSessionId: ID!, $orderBy: CrawlerSessionScriptOrderByInput!, $skip: Int, $first: Int, $successConvos: Boolean, $warningConvos: Boolean, $failedConvos: Boolean, $utterances: Boolean) {
    crawlersessionscriptsfiltered(crawlerSessionId: $crawlerSessionId, orderBy: $orderBy, skip: $skip, first: $first, successConvos: $successConvos, warningConvos: $warningConvos, failedConvos: $failedConvos, utterances: $utterances) {
      ...FullCrawlerSessionScript
    }
  }
  ${FULL_CRAWLERSESSIONSCRIPT_FRAGMENT}
`

export const CRAWLERSESSIONSCRIPT_QUERY = gql`
  query CrawlerSessionScriptQuery($id: ID!) {
    crawlersessionscript(id: $id) {
      ...FullCrawlerSessionScript
    }
  }
  ${FULL_CRAWLERSESSIONSCRIPT_FRAGMENT}
`

export const UPDATE_CRAWLERSESSIONSCRIPTNAME = gql`
  mutation UpdateCrawlerSessionScriptName($id: ID!, $scriptName: String!) {
    updateCrawlerSessionScriptName(id: $id, scriptName: $scriptName) {
      ...FullCrawlerSessionScript
    }
  }
  ${FULL_CRAWLERSESSIONSCRIPT_FRAGMENT}
`

const FULL_EDITABLE_CONVO_FRAGMENT = gql`
  fragment FullEditableCrawlerConvo on EditorConvo {
    scriptId
    name
    description
    path
    stucked
    markedWithEndOfConversation
    script
    partial
    begin {
      sender
      messageText
      not
      logicHooks { name args }
    }
    steps {
      sender
      messageText
      not
      optional
      asserters { name args not optional }
      logicHooks { name args }
      userInputs { name args }
      utteranceSamples
      utteranceCount
      path
      crawlerAnswer {
        id
        path
        answers
      }
      endOfConversation
      exitCriteriaMatch
      err
      errDetails
    }
    end {
      sender
      messageText
      not
      asserters { name args not }
      logicHooks { name args }
    }
  }
`

export const CRAWLER_SESSION_CONVO_QUERY = gql`
  query CrawlerSessionConvoQuery($crawlerSessionScriptId: ID!) {
    crawlersessionconvo(id: $crawlerSessionScriptId) {
      ...FullEditableCrawlerConvo
    }
  }
  ${FULL_EDITABLE_CONVO_FRAGMENT}
`

const CRAWLER_FULL_EDITABLE_UTTERANCE_FRAGMENT = gql`
  fragment CrawlerFullEditableUtterance on EditorUtterance {
    scriptId
    name
    utterances
  }
`

export const CRAWLER_SESSION_UTTERANCE_QUERY = gql`
  query CrawlerSessionUtteranceQuery($crawlerSessionScriptId: ID!) {
    crawlersessionutterance(id: $crawlerSessionScriptId) {
      ...CrawlerFullEditableUtterance
    }
  }
  ${CRAWLER_FULL_EDITABLE_UTTERANCE_FRAGMENT}
`

export const CRAWLERSESSIONJOB_LOGS_QUERY = gql`
  query CrawlerSessionJobLogsQuery($jobId: ID!) {
    crawlersessionjoblogs(
      jobId: $jobId
      orderBy: createdAt_DESC
      skip: 0
      first: 50
    ) {
      id
      createdAt
      log
    }
  }
`

export const DELETE_CRAWLERSESSION = gql`
  mutation DeleteCrawlerSession($id: ID!) {
    deleteCrawlerSession(id: $id)
  }
`

export const CANCEL_CRAWLERSESSION = gql`
  mutation CancelCrawlerSession($id: ID!) {
    cancelCrawlerSession(id: $id)
  }
`

export const DeleteCrawlerSessionListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^crawlersessions/)
}
export const DeleteCrawlerProjectsListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^crawlerprojects/)
}

export const UPSERT_CRAWLERANSWERS = gql`
  mutation UpsertCrawlerAnswer($crawlerAnswer: CrawlerAnswerOnPaths!) {
    upsertCrawlerAnswers(crawlerAnswer: $crawlerAnswer)
  }
`

export const CREATE_CRAWLERENDOFCONVERSATIONS = gql`
  mutation CreateCrawlerEndOfConversation($crawlerEndOfConversation: CrawlerEndOfConversationOnPaths!) {
    createCrawlerEndOfConversations(crawlerEndOfConversation: $crawlerEndOfConversation)
  }
`

export const DELETE_CRAWLERENDOFCONVERSATIONS = gql`
  mutation DeleteCrawlerEndOfConversation($crawlerEndOfConversation: CrawlerEndOfConversationOnPaths!) {
    deleteCrawlerEndOfConversations(crawlerEndOfConversation: $crawlerEndOfConversation)
  }
`

export const CRAWLERGUESSUSERRESPONSE_QUERY = gql`
  query CrawlerGuessUserResponseQuery($script: String!, $reservedUserResponses: [String!]) {
    crawlerguessuserresponse(script: $script, reservedUserResponses: $reservedUserResponses) {
      userResponse
    }
  }
`

export const RefetchCrawlerProjectQueries = (crawlerProjectId) => [
  {
    query: NAMESPACES_QUERY
  },
  {
    query: CRAWLERPROJECTS_QUERY,
  },
  ...(crawlerProjectId ? [
    {
      query: CRAWLERPROJECT_QUERY,
      variables: { id: crawlerProjectId },
    }
  ] : [])
]
