import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import {NavLink} from 'react-router-dom'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {CHART_INTENT_QUERY} from '../gql'
import {mergeArrays} from 'botium-box-shared/trainer/helper'
import {renderProgressOrError} from '../../helper'
import { decodeURIComponentWeak } from 'views/helper'
import {renderTrendingText, toFixedSafe} from './helper'

class IntentConfidenceRisks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTable() {
    const {intentsData, secondaryIntentsData, match} = this.props
    const testSessionId = match.params.testSessionId
    const secondaryTestSessionId = match.params.secondaryTestSessionId

    let progressOrError = renderProgressOrError(intentsData) || renderProgressOrError(secondaryIntentsData)
    if (progressOrError) {
      return progressOrError
    }

    const idToPerExpectedIntent = mergeArrays(intentsData.trainerChartIntent, secondaryIntentsData ? secondaryIntentsData.trainerChartIntent : null, 'name', false)

    let data = Object.values(idToPerExpectedIntent)
    .map((e) => {
      return {
        name: e.primary ? e.primary.name : e.secondary.name,
        primaryAvg: e.primary ? e.primary.avg : 0,
        secondaryAvg: e.secondary ? e.secondary.avg : 0,
        primaryCount: e.primary ? e.primary.count : 0,
        secondaryCount: e.secondary ? e.secondary.count : 0
      }
    })

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Intent', 'Average Confidence', 'Utterance Count']}
        tableData={data.map(entry => {
          return [
            () => (<NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(entry.name)}`} data-unique={`btnCoachConfidenceRisksName_${encodeURIComponent(entry.name)}`}>
              {entry.name}
              </NavLink>),
            () => renderTrendingText(toFixedSafe(entry.primaryAvg), secondaryTestSessionId ? entry.primaryAvg - entry.secondaryAvg : 0, false, 4, secondaryTestSessionId),
            () => renderTrendingText(entry.primaryCount, secondaryTestSessionId ? entry.primaryCount - entry.secondaryCount : 0, false, 0, secondaryTestSessionId)
          ]
        })}
      />
    )
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{this.renderTable()}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(CHART_INTENT_QUERY, {
    props: ({data}) => ({
      intentsData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          showDataByExpected: props.match.params.showDataByExpected === 'expected',
          intentFilter: decodeURIComponentWeak(props.match.params.intentFilter),
          orderBy: 'avg_ASC'
        },
      }
    }
  }),
  graphql(CHART_INTENT_QUERY, {
    props: ({data}) => ({
      secondaryIntentsData: data
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.secondaryTestSessionId,
          showDataByExpected: props.match.params.showDataByExpected === 'expected',
          intentFilter: decodeURIComponentWeak(props.match.params.intentFilter),
          orderBy: 'avg_ASC'
        },
      }
    },
    skip: (props) => !props.match.params.secondaryTestSessionId
  })
)(IntentConfidenceRisks)
