import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Mutation, compose, graphql } from 'react-apollo'
// core components
import { OnChange } from 'react-final-form-listeners'
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Text from 'components/Typography/Text.jsx'

import {
  renderTextField,
  renderSlider,
  parseInteger,
  composeValidators,
  required,
  minValue,
  maxValue,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import TestSetContentSelector from '../TestSets/TestSetContentSelector.jsx'
import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TESTSET_QUERY,
  SPLIT_TESTSET,
  RefetchTestSetQueries,
} from '../TestSets/gql'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'
import Divider from 'components/Divider/Divider.js'

class HumanificationWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emptySplitter: {
        testSetId: props.testSetId || props.match.params.testSetId,
        percentage1: 80,
        percentage2: 20,
        outputToFolder: false,
        local: true,
        repositories: [],
        folders: [],
        downloadlinks: []
      }
    }
  }

  render() {
    const { testSetData, setAlertSuccessMessage, setAlertErrorMessage } = this.props
    const { emptySplitter, splitterRunning, splitterResult } = this.state

    return (<GridContainer>
      <GridItem md={12} lg={8}>
        <Card><CardBody><QueryStatus {...testSetData} query="testset">{({ testset }) => {
          if (!emptySplitter.name1) emptySplitter.name1 = `${testset.name} - Training`
          if (!emptySplitter.name2) emptySplitter.name2 = `${testset.name} - Test`

          return <Mutation
            mutation={SPLIT_TESTSET}
            refetchQueries={() => {
              return [
                ...RefetchTestSetQueries()
              ]
            }}
          >
            {(splitTestSet, { loading, error }) => (
              <Form
                onSubmit={async (values, form) => {
                  this.setState({ splitterRunning: true, splitterResult: null })
                  try {
                    const res = await splitTestSet({
                      variables: {
                        id: testset.id,
                        testSetNames: [values.name1, values.name2],
                        percentages: [values.percentage1, values.percentage2],
                        output: values.outputToFolder ? 'FOLDER' : 'LOCAL',
                        content: {
                          local: !!values.local,
                          repositoryIds: values.repositories || [],
                          folderIds: values.folders || [],
                          downloadlinkIds: values.downloadlinks || []
                        }
                      },
                    })
                    form.initialize(emptySplitter)
                    this.setState({ splitterRunning: false, splitterResult: res.data.splitTestSet })
                    setAlertSuccessMessage('Splitting Test Set completed')
                  } catch (error) {
                    this.setState({ splitterRunning: false, splitterResult: null })
                    setAlertErrorMessage(`Splitting Test Set failed`, error)
                  }
                }}
                initialValues={emptySplitter}
                validate={(values) => this.state.contentValidate ? this.state.contentValidate(values) : null}
                render={({ handleSubmit, submitting, form, invalid }) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                        
                        <GridItem xs={12}>
                          <Text submitting bottomMarginsm bold500 labelText>Utterances</Text>
                        </GridItem>
                        <TestSetContentSelector allowChangeTestSet={false} showConvos={true} showUtterances={true} addFormValidate={(fn) => this.setState({ contentValidate: fn })} />
                        <GridItem xs={12} largeMarginTop>
                          <Text header>Configuration</Text>
                        </GridItem>
                      <GridItem md={12} lg={6} largeMarginTop>
                        <Field
                          name="name1"
                          component={renderTextField}
                          label="Test Set Name, Part 1"
                          validate={required}
                          data-unique="txtTestSetSplitterTestSetName1"
                        />
                      </GridItem>
                      <GridItem md={12} lg={6} largeMarginTop>
                        <Field
                          name="percentage1"
                          component={renderSlider}
                          label="Percentage"
                          parse={parseInteger}
                          min={0}
                          max={100}
                          validate={composeValidators(
                            required,
                            minValue(0),
                            maxValue(100),
                          )}
                          step={1}
                          form={form}
                          data-unique="intTestSetPercentage1"
                          showInputField
                        />
                        <OnChange name="percentage1">
                          {(value, previous) => {
                            if (value && value !== previous) {
                              form.change('percentage2', 100 - value)
                            }
                          }}
                        </OnChange>
                      </GridItem>
                      <GridItem md={12} lg={6}>
                        <Field
                          name="name2"
                          component={renderTextField}
                          label="Test Set Name, Part 2"
                          validate={required}
                          data-unique="txtTestSetSplitterTestSetName2"
                        />
                      </GridItem>
                      <GridItem md={12} lg={6}>
                        <Field
                          name="percentage2"
                          component={renderSlider}
                          label="Percentage"
                          parse={parseInteger}
                          min={0}
                          max={100}
                          validate={composeValidators(
                            required,
                            minValue(0),
                            maxValue(100),
                          )}
                          step={1}
                          form={form}
                          data-unique="intTestSetPercentage2"
                          showInputField
                        />
                        <OnChange name="percentage2">
                          {(value, previous) => {
                            if (value && value !== previous) {
                              form.change('percentage1', 100 - value)
                            }
                          }}
                        </OnChange>
                      </GridItem>
                      <GridItem xs={12} largePadding largeMarginTop>
                        <FormActionsToolbar rightButtons={
                          <Button
                            type="submit"
                            disabled={splitterRunning || submitting || invalid}
                            data-unique="btnTestSetDownloadAndSplit"
                          >
                            {splitterRunning && <><LoadingIndicator alt /> Test Set Splitting is running ...</>}
                            {!splitterRunning && <><ShowIcon icon="play-circle" /> Split</>}
                          </Button>
                        } />
                      </GridItem>
                        
                      
                      {splitterResult && splitterResult.length > 0 && splitterResult.map(r => (<>
                        <Divider orientation="horizontal" small />
                        <GridItem md={8} middle>
                          <Text>{r.name}</Text>
                        </GridItem>
                        <GridItem md={4} right middle>
                          <NavLink to={`/testsets/view/${r.id}`} data-unique={`btnTestSetSplitterTestSet_${r.id}`}>
                            <Button small>Open Test Set</Button>
                          </NavLink>
                        </GridItem>
                      </>
                      ))}
                    </GridContainer>
                  </form>
                )}
              />
            )}
          </Mutation>
        }}</QueryStatus></CardBody></Card>
      </GridItem>
    </GridContainer>)
  }

}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, settings: state.settings }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(TESTSET_QUERY, {
    options: (props) => ({
      variables: {
        id: props.testSetId || props.match.params.testSetId
      },
    }),
    props: ({ data }) => ({
      testSetData: data,
    }),
  })
)(HumanificationWizard)
