export const DESTROY_STORE = 'DESTROY_STORE'

export const SET_TOKEN = 'SET_TOKEN'
export const SET_LOGIN = 'SET_LOGIN'
export const CLEAR_LOGIN = 'CLEAR_LOGIN'

export const SET_ALERT = 'SET_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'
export const SHOW_FEATURE_UPGRADE = 'SHOW_FEATURE_UPGRADE'
export const SHOW_SUPPORT_POPUP = 'SHOW_SUPPORT_POPUP'
export const SHOW_TRIAL_COUNTDOWN = 'SHOW_TRIAL_COUNTDOWN'
export const HIDE_MAINTENANCE_ID = 'HIDE_MAINTENANCE_ID'

export const SET_AVAILABLECONNECTORS = 'SET_AVAILABLECONNECTORS'
export const CLEAR_AVAILABLECONNECTORS = 'CLEAR_AVAILABLECONNECTORS'

export const SET_ALLCONNECTORS = 'SET_ALLCONNECTORS'
export const CLEAR_ALLCONNECTORS = 'CLEAR_ALLCONNECTORS'

export const SET_LICENSE = 'SET_LICENSE'
export const CLEAR_LICENSE = 'CLEAR_LICENSE'

export const SET_FEATURES = 'SET_FEATURES'
export const CLEAR_FEATURES = 'CLEAR_FEATURES'

export const SET_TABLE_SETTINGS = 'SET_TABLE_SETTINGS'
export const CLEAR_TABLE_SETTINGS = 'CLEAR_TABLE_SETTINGS'

export const SET_LIST_SETTINGS = 'SET_LIST_SETTINGS'
export const CLEAR_LIST_SETTINGS = 'CLEAR_LIST_SETTINGS'

export const SET_DASHBOARD_SETTINGS = 'SET_DASHBOARD_SETTINGS'
export const CLEAR_DASHBOARD_SETTINGS = 'CLEAR_DASHBOARD_SETTINGS'

export const SET_FEED = 'SET_FEED'
export const CLEAR_FEED = 'CLEAR_FEED'

export const DISABLE_TUTORIAL = 'DISABLE_TUTORIAL'
export const ENABLE_TUTORIALS = 'ENABLE_TUTORIALS'

export const ADD_RECENT_ACTIVITY = 'ADD_RECENT_ACTIVITY'
export const REMOVE_RECENT_ACTIVITY = 'REMOVE_RECENT_ACTIVITY'
export const RECENT_ACTIVITY_LENGTH = 50

export const SET_TEST_DATA_WIZARD_DATA = 'SET_TEST_DATA_WIZARD_DATA'
export const CLEAR_TEST_DATA_WIZARD_DATA = 'CLEAR_TEST_DATA_WIZARD_DATA'

export const SET_TESTCASE_FILTER = 'SET_TESTCASE_FILTER'

export const SET_NAMESPACE = 'SET_NAMESPACE'
export const CLEAR_NAMESPACE = 'CLEAR_NAMESPACE'
