import React from 'react'
import GaugeChart from 'react-gauge-chart'
import {
  successColor,
  warningColor,
  dangerColor,
} from 'assets/jss/material-dashboard-react.jsx'
import { isDarkmode } from 'components/Darkmode/helper'

export default class ConfidenceGaugeChart extends React.Component {


  _getTextColor(value) {
    if (isDarkmode()) {
      return value < 0.6 ? '#D5D9DD' : value < 0.85 ? '#D5D9DD' : '#D5D9DD'
    } else {
      return value < 0.6 ? '#2B3E53' : value < 0.85 ? '#2B3E53' : '#2B3E53'
    }
  }

  render() {
    const { id, value } = this.props

    const textColor = this._getTextColor(value)

    //const textColor = value < 0.6 ? dangerColor : value < 0.85 ? warningColor : successColor

    return <GaugeChart id={id}
      nrOfLevels={3}
      arcsLength={[ 0.6, 0.25, 0.15 ]}
      colors={[dangerColor, warningColor, successColor]}
      textColor={textColor}
      percent={value}
      needleColor={isDarkmode() ? '#D5D9DD' : '#2B3E53'}
      needleBaseColor={isDarkmode() ? '#D5D9DD' : '#2B3E53'}
      formatTextValue={() => `${(value * 100).toFixed(0)}/100`}
    />
  }
}
