import React from 'react'
import { connect } from 'react-redux'
import {Form, FormSpy} from 'react-final-form'
import {OnChange} from 'react-final-form-listeners'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// apollo
import { compose, graphql, Mutation, Query, withApollo } from 'react-apollo'

import crawlerprojectsStyle from 'assets/jss/material-dashboard-react/views/crawlerprojectsStyle.jsx'

import { RefetchTestProjectQueries } from '../TestProjects/gql'
import { RefetchTestSetQueries, TESTSETS_DROPDOWN_QUERY, TESTSETSOURCES_SCRIPTS_QUERY } from '../TestSets/gql'
import { COPY_TESTDATAWIZARDSCRIPTSINTOTESTSET } from './gql'
import { CHATBOTS_DROPDOWN_QUERY } from '../Chatbots/gql'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/Button/Button'
import ErrorFormat from 'components/Info/ErrorFormat'
import { hasPermission } from 'botium-box-shared/security/permissions'
import MenuItem from 'components/Menu/MenuItem'
import Field from 'components/Form/OptionalField'
import { renderSelect, renderTextField, required, composeValidators } from 'components/Form/Form'
import Tooltip from 'components/Tooltip/Tooltip'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { validateTestProjectCodeUnique } from '../TestProjects/validators'
import { safeGetNamespaceFilteredList } from '../helper'
import Divider from 'components/Divider/Divider'


class TestDataSaveDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSaveDialogErr: null
    }
		this.recorder = null
  }
  render() {
    const { showCopyDialog, onCancel, onComplete, reset, history, user, license, testDataWizardScripts, nlpOnly, testDataWizardChatbotId, chatbotsData, title, okText, namespace } = this.props

    let openTestSet = false

    return (
      <Mutation
        mutation={COPY_TESTDATAWIZARDSCRIPTSINTOTESTSET}
        onCompleted={data => {
          reset()
          onComplete()
          if (openTestSet) {
            history.push(`/testsets/view/${data.copyTestDataWizardScriptsIntoTestSet.testSetId}`)
          }
        }}
        onError={error => {
          this.setState({ showSaveDialogErr: error })
        }}
        refetchQueries={({ data }) => {
          return [
            ...RefetchTestProjectQueries(),
            ...RefetchTestSetQueries(data.copyTestDataWizardScriptsIntoTestSet.testSetId, license)
          ]
        }}
      >
        {(copyScripts, { loading }) => (
          <Form
            onSubmit={values => {
              copyScripts({
                variables: {
                  namespace: namespace.selected ? namespace.selected.name : undefined,
                  testSetId: values.newTestSetName ? null : values.testSetId,
                  newTestSetName: values.newTestSetName,
                  nlpOnly: !!nlpOnly,
                  createTestProject: !!(values.newTestSetName && values.createTestProject),
                  chatbotId: values.chatbotId,
                  importMode: values.importMode,
                  testDataWizardScripts
                },
              })
            }}
            initialValues={{
              testSetId: 'new',
              importMode: 'OVERWRITE',
              chatbotId: testDataWizardChatbotId || null
            }}
            render={({ handleSubmit, values, form: { change } }) => (
              <ConfirmationDialog
                open={showCopyDialog}
                isWorking={loading}
                onCancel={onCancel}
                okText={okText || 'Copy Test Scripts to Test Set'}
                onOk={() => {
                  openTestSet = false
                  handleSubmit()
                }}
                title={title || 'Copy Scripts into Test Set'}
                showError={this.state.showSaveDialogErr}
                clearError={() => this.setState({ showSaveDialogErr: null })}
                extraButton={
                  <Button data-unique="btnCopyAndOpenTestSet"
                    secondary
                    disabled={loading}
                    onClick={() => {
                      openTestSet = true
                      handleSubmit()
                    }}>
                    {loading && <LoadingIndicator alt />}
                    Copy Test Scripts and Open Test Set
                  </Button>
                }
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    {hasPermission(user, 'TESTSETS_CREATE') && <React.Fragment>
                      <GridItem xs={12}>
                        <Field
                          name="newTestSetName"
                          component={renderTextField}
                          label="New Test Set Name"
                          disabled={values.testSetId !== 'new' }
                          validate={values.testSetId !== 'new'
                            ? async (value) => {
                                const { client } = this.props
                                if (values.createTestProject && value) {
                                  // we just create reggression tests from here
                                  return validateTestProjectCodeUnique(client, value,undefined,  { regressionTesting: true })
                                }
                              }
                            : composeValidators(required, async (value) => {
                              const { client } = this.props
                              if (values.createTestProject && value) {
                                return validateTestProjectCodeUnique(client, value, undefined, { regressionTesting: true })
                              }
                            })
                          }
                          data-unique="txtTestDataWizardScriptsNewTestSetName"
                        />
                      </GridItem>
                      {values.createTestProject && <GridItem md={12}>
                        <Field
                          name="chatbotId"
                          component={renderSelect}
                          label="Select a Chatbot"
                          data-unique={`selTestDataWizardScriptsChatbot`}
                          validate={required}
                          items={chatbotsData && chatbotsData.chatbots && chatbotsData.chatbots.map(c => {
                            return {
                              key: c.id,
                              chatbot: c
                            }
                          })}
                        />
                      </GridItem>}
                    </React.Fragment>}

                    <GridItem md={12}>
                      <Divider text="or" orientation="horizontal"  />
                    </GridItem>
                    <GridItem md={12}>
                      <Query query={TESTSETS_DROPDOWN_QUERY}>
                        {({ loading, error, data }) => {
                          if (loading) {
                            return <LoadingIndicator large />
                          }
                          if (error) {
                            return <ErrorFormat err={error} />
                          }
                          if (values.testSetId === '') {
                            change('testSetId', 'new')
                          }

                          const items = []
                          if (hasPermission(user, 'TESTSETS_CREATE')) {
                            items.push({
                              key: 'new',
                              label: 'Register new Test Set',
                              render: (<MenuItem key="new" value="new">
                                Register new Test Set
                              </MenuItem>)
                            })
                          }
                          safeGetNamespaceFilteredList(data.testsets, this.props.namespace).forEach(a => items.push({
                            key: a.id,
                            label: a.name
                          }))

                          return (
                            <Field
                              name="testSetId"
                              component={renderSelect}
                              label="Select Test Set"
                              validate={required}
                              data-unique="selTestDataWizardScriptsTestSetId"
                              items={items}
                            />
                          )
                        }}
                      </Query>
                    </GridItem>
                    <FormSpy subscription={{ form: true }} render={({ form:  { change }}) => (
                      <OnChange name="testSetId">
                        {async (value) => {
                          if(value !== 'new') {
                            change('chatbotId', null)
                            change('createTestProject', false)
                            change('newTestSetName', null)
                          }
                        }}
                      </OnChange>
                    )} />
                    {values.testSetId && values.testSetId !== 'new' && <React.Fragment>
                      <GridItem md={12}>
                        <Field
                          name="importMode"
                          component={renderSelect}
                          label="Action for convos and utterance lists"
                          validate={required}
                          data-unique="selTestDataWizardScriptsAction"
                          items={[
                            {
                              key: 'DUMP',
                              label: 'Dump all convos and utterance lists from test set repository before copying'
                            },
                            {
                              key: 'SKIP',
                              label: 'Only copy new convos and utterance lists to test set repository'
                            },
                            {
                              key: 'OVERWRITE',
                              label: 'Overwrite convos and utterance lists'
                            },
                            {
                              key: 'BACKUP',
                              label: 'Backup convos and utterance lists in test set repository before copying'
                            },
                          ]}
                        />
                      </GridItem>
                      <GridItem md={12}>
                        <Query query={TESTSETSOURCES_SCRIPTS_QUERY} variables={{ testSetId: values.testSetId }}>
                          {({ loading, error, data }) => {
                            if (loading) {
                              return <LoadingIndicator large />
                            }
                            if (error) {
                              return <ErrorFormat err={error} />
                            }
                            data.testsetscripts = data.testsetscripts || []

                            let dumpScripts = []
                            let newScripts = []
                            let overwriteScripts = []
                            let skipScripts = []

                            if (values.importMode === 'DUMP') {
                              dumpScripts = data.testsetscripts.filter(s => s.scriptType === 'SCRIPTING_TYPE_CONVO' || s.scriptType === 'SCRIPTING_TYPE_UTTERANCES').map(s => s.name)
                              newScripts = testDataWizardScripts.map(s => s.name)
                            } else if (values.importMode === 'SKIP') {
                              newScripts = testDataWizardScripts.filter(s => data.testsetscripts.findIndex(es => s.name === es.name && s.scriptType === es.scriptType) < 0).map(s => s.name)
                              skipScripts = testDataWizardScripts.filter(s => data.testsetscripts.findIndex(es => s.name === es.name && s.scriptType === es.scriptType) >= 0).map(s => s.name)
                            } else if (values.importMode === 'OVERWRITE') {
                              overwriteScripts = testDataWizardScripts.filter(s => data.testsetscripts.findIndex(es => s.name === es.name && s.scriptType === es.scriptType) >= 0).map(s => s.name)
                              newScripts = testDataWizardScripts.filter(s => data.testsetscripts.findIndex(es => s.name === es.name && s.scriptType === es.scriptType) < 0).map(s => s.name)
                            } else if (values.importMode === 'BACKUP') {
                              newScripts = testDataWizardScripts.map(s => s.name)
                            }

                            return <> <GridContainer>
                              <GridItem md={3}>
                                {dumpScripts.length > 0 ? <Tooltip title={dumpScripts.join(' ')}><Text danger>Dumping {dumpScripts.length} script(s)</Text></Tooltip> : <Text muted>Dumping no scripts</Text>}
                              </GridItem>
                              <GridItem md={3}>
                                {newScripts.length > 0 ? <Tooltip title={newScripts.join(' ')}><Text success>Creating {newScripts.length} new script(s)</Text></Tooltip> : <Text muted>Creating no scripts</Text>}
                              </GridItem>
                              <GridItem md={3}>
                                {overwriteScripts.length > 0 ? <Tooltip title={overwriteScripts.join(' ')}><Text warning>Overwriting {overwriteScripts.length} script(s)</Text></Tooltip> : <Text muted>Overwriting no scripts</Text>}
                              </GridItem>
                              <GridItem md={3}>
                                {skipScripts.length > 0 ? <Tooltip title={skipScripts.join(' ')}><Text warning>Skipping {skipScripts.length} script(s)</Text></Tooltip> : <Text muted>Skipping no scripts</Text>}
                              </GridItem>
                            </GridContainer>
                            </>
                          }}
                        </Query>
                      </GridItem>
                    </React.Fragment>}
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }
}

export default compose(
  withStyles(
    (theme) => ({
      ...crawlerprojectsStyle(theme),
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace }),
  ),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
  }),
  withApollo
)(TestDataSaveDialog)
