import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'
import {NavLink} from 'react-router-dom'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import ShowIcon from 'components/Icon/ShowIcon'
import Text from 'components/Typography/Text'
import Button from 'components/Button/Button'

import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {PER_ACTUAL_INTENTS_QUERY, TRAINER_SESSION_ROOT_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'
import {INCOMPREHENSION_IS_BAD} from './helper'
import DangerOrSuccessText from 'components/Typography/DangerOrSuccessText'


const testsessionLabel = (testsession) => {
  if (testsession) {
    return <React.Fragment>{testsession.name}</React.Fragment>
  }
  return null
}

class CompareIntents extends React.Component {
  getRootPath = () => {
    const path = this.props.location.pathname.split('/').filter(p => p)
    return '/' + path[0] + '/' + path[1] + '/' + path[2] + '/' + path[3]
  }

  renderTable() {
    const {intentsData, secondaryIntentsData, testSessionData, secondaryTestSessionData} = this.props

    const progressOrError = renderProgressOrError(intentsData)
    if (progressOrError) {
      return progressOrError
    }
    const secondaryProgressOrError = renderProgressOrError(secondaryIntentsData)
    if (secondaryProgressOrError) {
      return secondaryProgressOrError
    }

    const getSortVal = (i, orderBy) => {
      const val = _.get(i, orderBy)
      if (_.isString(val)) return val.toLowerCase()
      if (_.isNumber(val)) return val
      return 0.0
    }

    const data = intentsData.trainerPerActualIntents.map(i => ({
      primary: i,
      secondary: secondaryIntentsData.trainerPerActualIntents.find(s => s.name === i.name)
    }))

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={[
          { name: 'Predicted Intent', orderByField: 'primary.name' },
          { name: 'F1-Score', orderByField: 'primary.F1', orderByDefault: 'asc' },
          { name: 'Precision Score', orderByField: 'primary.precision' },
          { name: 'Recall Score', orderByField: 'primary.recall' },
          { name: 'Prediction Confidence', orderByField: 'primary.avg' },
        ]}
        tableData={({ orderByField, orderByOrder }) => _.orderBy(data, [(i) => getSortVal(i, orderByField || 'primary.F1')], [orderByOrder || 'asc']).map(entry => {
          return [
            () => <NavLink to={`${this.getRootPath()}/results/${testSessionData.testsession.id}/compareintentutterances/${secondaryTestSessionData.testsession.id}/${encodeURIComponent(entry.primary.name)}`} data-unique={`btnCompareIntents_${testSessionData.testsession.id}_${secondaryTestSessionData.testsession.id}_${encodeURIComponent(entry.primary.name)}`}>
              {entry.primary.name}
            </NavLink>,
            () => <>
                <Text md bold rightMarginxs displayInline>{_.isNumber(entry.primary.F1) ? entry.primary.F1.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</Text>
                {entry.secondary && <Text topMarginsm inline md regular>
                  <ShowIcon icon="exchange" /> {_.isNumber(entry.secondary.F1) ? entry.secondary.F1.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}
                </Text>}
                </>,
            () => <>
            <Text md bold rightMarginxs displayInline>{_.isNumber(entry.primary.precision) ? entry.primary.precision.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</Text>
            {entry.secondary && <Text topMarginsm inline md regular>
              <ShowIcon icon="exchange" /> {_.isNumber(entry.secondary.precision) ? entry.secondary.precision.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}
            </Text>}
            </>,
            () => <>
            <Text md bold rightMarginxs displayInline>{_.isNumber(entry.primary.recall) ? entry.primary.recall.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</Text>
            {entry.secondary && <Text topMarginsm inline md regular>
              <ShowIcon icon="exchange" /> {_.isNumber(entry.secondary.recall) ? entry.secondary.recall.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}
            </Text>}
            </>,
            () => <>
            <DangerOrSuccessText successful={entry.primary.avg >= 0}>{_.isNumber(entry.primary.avg) ? entry.primary.avg.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</DangerOrSuccessText>
            {entry.secondary && <>
              <ShowIcon icon="exchange" /> <DangerOrSuccessText successful={entry.secondary.avg >= 0}>{_.isNumber(entry.secondary.avg) ? entry.secondary.avg.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</DangerOrSuccessText>
            </>}
            </>,
          ]
        })}
      />
    )
  }

  render() {
    const {testSessionData, secondaryTestSessionData} = this.props
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <NavLink to={`${this.getRootPath()}/results/${testSessionData.testsession.id}`} data-unique={`btnBack_${testSessionData.testsession.id}`} >
                    <Button justIcon Border >
                       <ShowIcon icon="arrow-left" />
                    </Button> <Text inline>BACK / Test Sessions: {testSessionData && testSessionData.testsession && testsessionLabel(testSessionData.testsession)}</Text>
                     <Text inline > <ShowIcon icon="exchange" /> {secondaryTestSessionData && secondaryTestSessionData.testsession && testsessionLabel(secondaryTestSessionData.testsession)}</Text>
                  </NavLink>
                </GridItem>
                <GridItem xs={12}>
                  {this.renderTable()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(PER_ACTUAL_INTENTS_QUERY, {
    props: ({data}) => ({
      intentsData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          incomprehensionIsBad: INCOMPREHENSION_IS_BAD
        },
      }
    }
  }),
  graphql(PER_ACTUAL_INTENTS_QUERY, {
    props: ({data}) => ({
      secondaryIntentsData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.secondaryTestSessionId,
          incomprehensionIsBad: INCOMPREHENSION_IS_BAD
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      testSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.testSessionId
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      secondaryTestSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.secondaryTestSessionId
        },
      }
    },
    skip: (props) => !props.match.params.secondaryTestSessionId
  })
)(CompareIntents)
