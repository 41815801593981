import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import arrayMutators from 'final-form-arrays'
// apollo
import { Query, Mutation, withApollo, compose, graphql } from 'react-apollo'
// core components
import Field from 'components/Form/OptionalField'
import DropdownButton from 'components/Button/DropdownButton'
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import Transcript from 'components/Convo/Transcript.jsx'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import Table from 'components/Table/AdvancedTable'
import { openTextInNewTab } from 'helper/browserHelper'

import ShowIcon from 'components/Icon/ShowIcon'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'
import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle'

import { formToUpsertConvo, dbConvoToForm, TestSetConvoEditor } from './TestSetConvoEditor.jsx'
import { validateConvoNameUnique } from 'views/TestSets/validators'

import {
  TESTSET_EDITABLE_CONVO_QUERY,
  TESTSET_EDITABLE_CONVO_UPSERT,
  TESTSETSCRIPT_QUERY,
  TESTSETS_DROPDOWN_QUERY,
  RUN_TESTSET_EDITABLECONVO,
  SPEECH_APPLY_ON_CONVO,
  RefetchTestSetQueries, TESTSETSCRIPTREFERENCES_QUERY
} from './gql'
import { CHATBOTS_DROPDOWN_QUERY } from '../Chatbots/gql'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text.jsx'
import { renderSelect, renderTextField, required, FormActionsToolbar } from '../../components/Form/Form.js'
import { Form } from 'react-final-form'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'

import { SpeechTTSSelectionPanel, SpeechEffectsSelectionPanel, SpeechJobsTable } from '../TestDataWizard/VoiceHelperComponents'
import {deleteListQueryFromCache} from '../../helper/cacheHelper'
import { safeGetNamespaceFilteredList } from '../helper'

class TestSetConvo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      deleting: false,
      copying: false,
      moving: false,
      showRunConvoRunning: false,
      showRunConvoDialog: false,
      showRunConvoTranscript: null,
      showRunConvoLogs: null,
      showRunConvoError: null,
      showRunConvoChatbot: null,
      showApplySpeechDialog: false,
      applySpeechInitialData: {
        location: 'COPY'
      }
    }
    this.empty = { name: props.partial ? 'My Partial Convo' : 'My Convo', partial: !!props.partial,
      steps: [
        {
          sender: 'me',
          messageText: 'hello',
          not: false,
          optional: false,
          asserters: [],
          logicHooks: [],
          userInputs: [],
        },
        {
          sender: 'bot',
          messageText: 'hello',
          not: false,
          optional: false,
          asserters: [],
          logicHooks: [],
          userInputs: [],
        }
      ]}
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  renderForm(testSetId, testSetScriptId, convo, warnings) {
    const { setAlertSuccessMessage, setAlertErrorMessage, chatbotsData, history, license, features, setParentState, partial, client } = this.props
    const { showRunConvoRunning, saving } = this.state

    return (<>
      <Mutation
        mutation={TESTSET_EDITABLE_CONVO_UPSERT}
        refetchQueries={({ data }) => [
          {
            query: TESTSET_EDITABLE_CONVO_QUERY,
            variables: { testSetScriptId: data.upsertEditableConvo.scriptId, name: data.upsertEditableConvo.name }
          },
          {
            query: TESTSETSCRIPT_QUERY,
            variables: { id: data.upsertEditableConvo.scriptId },
          },
          ...RefetchTestSetQueries(testSetId, license)
        ]}
        awaitRefetchQueries={true}
        onCompleted={data => {
          deleteListQueryFromCache(client.store.cache, /^testsetscriptreferences/)
        }}
      >
        {(upsertConvo, { loading, error }) => (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={async (values, form) => {
              this.setState({ saving: true })
              try {
                const res = await upsertConvo({
                  variables: {
                    testSetId,
                    testSetScriptId,
                    name: values.name,
                    convo: formToUpsertConvo(values, convo)
                  }
                })

                form.initialize(dbConvoToForm(res.data.upsertEditableConvo))
                history.push(partial ? `/testsets/view/${testSetId}/testcases/viewpconvo/${res.data.upsertEditableConvo.scriptId}/${encodeURIComponent(res.data.upsertEditableConvo.name)}`
                  : `/testsets/view/${testSetId}/testcases/viewconvo/${res.data.upsertEditableConvo.scriptId}/${encodeURIComponent(res.data.upsertEditableConvo.name)}`)
                setAlertSuccessMessage('Test case ready for use')
              } catch(error) {
                setAlertErrorMessage(`Saving test case failed`, error)
              }
              this.setState({ saving: false })
            }}
            initialValues={convo}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values
            }) => (
              <form onSubmit={handleSubmit} data-simplelist-container>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12}>
                    <TestSetConvoEditor setTestSetState={setParentState} testSetId={testSetId} disabled={!this.hasWritePermission()} warnings={warnings}/>
                  </GridItem>
                  <GridItem xs={12} largePadding>
                  <FormActionsToolbar
                    leftButtons={<>
                      {this.hasWritePermission() && convo && !convo.partial && <>
                        {this.renderRunConvoDialog()}
                        <DropdownButton
                          secondary
                          dashed
                          data-unique="ddbtnTestSetConvoRunConvo"
                          showFilter
                          disabled={saving || submitting || showRunConvoRunning}
                          items={(chatbotsData.chatbots  && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace).map(c => ({
                            id: c.id,
                            name: `... with ${c.name}`,
                            chatbot: c,
                            onClick: () => {
                              this.runConvo(values, c, testSetId, convo)
                            }
                          }))) || []
                        }
                        >
                          {showRunConvoRunning && <LoadingIndicator alt />}
                          {!showRunConvoRunning && <ShowIcon icon="play-circle" />}
                          {showRunConvoRunning && 'Convo is running ...'}
                          {!showRunConvoRunning && 'Run Convo ...'}
                        </DropdownButton>
                      </>}
                      {this.hasWritePermission() && features && features.speechService && convo && <>
                        <Button
                          secondary
                          dashed
                          data-unique="btnTestSetConvoApplySpeech"
                          disabled={saving || submitting}
                          onClick={() => {
                            this.setState({
                              showApplySpeechDialog: true,
                              speechTestSetId: testSetId,
                              speechTestSetScriptId: testSetScriptId,
                              speechName: values.name,
                              speechConvo: formToUpsertConvo(values, convo),
                              speechApplyOnConvoResult: null,
                              speechApplyOnConvoErr: null,
                              applySpeechInitialData: {
                                ...this.state.applySpeechInitialData,
                                newTestCaseName: `${values.name} Voice`
                              }
                            })
                          }}
                        >
                          <ShowIcon icon="comment" />
                          Transform to Voice
                        </Button>
                      </>}
                    </>}
                    rightButtons={this.hasWritePermission() &&
                      <Button
                        type="submit"
                        disabled={saving || submitting || invalid}
                        data-unique="btnTestSetConvoSave"
                      >
                        {saving && <><LoadingIndicator alt /> Saving ...</>}
                        {!saving && <><ShowIcon icon="save" /> Save</>}
                      </Button>
                    }
                    />
                  </GridItem>
                  {partial && testSetScriptId && <GridItem xs={12}>
                    <Query query={TESTSETSCRIPTREFERENCES_QUERY} variables={{ testSetScriptId }}>
                      {({ loading, error, data }) => {
                        return (
                          <GridContainer>
                            <GridItem xs={12}>
                              <Text topMargin header>Convos where partial convo is in use</Text>
                            </GridItem>
                            <GridItem xs={12}>
                              <Table
                                disableFilter
                                tableHeaderColor="primary"
                                tableHead={[
                                  'Convo',
                                  'Test Set',
                                  { name: 'Actions', right: true }
                                ]}
                                tableData={
                                  data.testsetscriptreferences && data.testsetscriptreferences.map(ref => [
                                    () => (
                                      <GridContainer>
                                        <GridItem middle xs={2}>{ref.scriptType === 'SCRIPTING_TYPE_PCONVO' ? <ShowIcon custom icon="partialConvo" /> : <ShowIcon custom icon="convo" />}</GridItem>
                                        <GridItem middle xs={10}><Text>{ref.name}</Text></GridItem>
                                      </GridContainer>),
                                    () => (<GridItem middle xs={10}><Text>{ref.testSet.name}</Text></GridItem>),
                                    () => (
                                      <Button justIcon data-unique={`btnShowReference_${ref.id}`} onClick={() => ref.scriptType === 'SCRIPTING_TYPE_PCONVO' ? history.push(`/testsets/view/${ref.testSet.id}/testcases/viewpconvo/${ref.id}/${ref.name}`) : history.push(`/testsets/view/${ref.testSet.id}/testcases/viewconvo/${ref.id}/${ref.name}`)}>
                                        <ShowIcon icon="eye" />
                                      </Button>)
                                  ])
                                }
                              />
                            </GridItem>
                          </GridContainer>
                        )}}
                    </Query>
                  </GridItem>}
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
      {this.hasWritePermission() && features && features.speechService && convo && this.renderSpeechApplyDialog()}
    </>)
  }
  async runConvo(values, chatbot, testSetId, convo) {
    const { client } = this.props
    const variables =  {
      chatbotId: chatbot.id,
      testSetId: testSetId,
      convo: formToUpsertConvo(values, convo)
    }

    this.setState({
      showRunConvoChatbot: chatbot,
      showRunConvoRunning: true,
      showRunConvoDialog: false
    })

    try {
      const { data } = await client.query({
        query: RUN_TESTSET_EDITABLECONVO,
        variables,
        fetchPolicy: 'network-only'
      })
      this.setState({
        showRunConvoRunning: false,
        showRunConvoDialog: true,
        showRunConvoTranscript: JSON.parse(data.runtestseteditableconvo.convo),
        showRunConvoLogs: data.runtestseteditableconvo.logs,
        showRunConvoError: data.runtestseteditableconvo.err
      })
    } catch (err) {
      this.setState({
        showRunConvoRunning: false,
        showRunConvoDialog: true,
        showRunConvoTranscript: null,
        showRunConvoLogs: null,
        showRunConvoError: err
      })
    }
  }

  renderRunConvoDialog() {
    const { license } = this.props
    const { showRunConvoChatbot, showRunConvoTranscript, showRunConvoLogs, showRunConvoError } = this.state

    return (
      <ConfirmationDialog
        cancelText="Close"
        open={this.state.showRunConvoDialog}
        onCancel={() => this.setState({showRunConvoDialog: false})}
        title="Convo Transcript"
        extraButton={license.detailedReporting && showRunConvoLogs && showRunConvoLogs.length > 0 &&
          <Button secondary data-unique="btnShowRunConvoLogs" onClick={() => {
            openTextInNewTab(showRunConvoLogs.filter(l => l).map(l => l.trim()).join('\n'))
          }}>
            Show Logs
          </Button>
        }
      >
        {(showRunConvoTranscript || showRunConvoError) && <Transcript err={showRunConvoError} steps={showRunConvoTranscript} chatbotId={showRunConvoChatbot.id} allowHtmlDisplay={showRunConvoChatbot.allowHtmlDisplay} />}
      </ConfirmationDialog>
    )
  }

  renderSpeechApplyDialog() {
    const { mutateSpeechApplyOnConvo, testSetsData, history } = this.props
    const { speechTestSetId, speechTestSetScriptId, speechName, speechConvo, speechApplyOnConvoResult, speechApplyOnConvoErr, applySpeechInitialData } = this.state

    const _resetInitialData = (values) => {
      if (!speechApplyOnConvoResult) return
      const initialData = { ...applySpeechInitialData }
      initialData.testSetId = speechApplyOnConvoResult.testSetId
      initialData.newTestSetName = null
      this.setState({ applySpeechInitialData: initialData })
    }

    return (<Form
      onSubmit={async (values) => {
        try {
          const input = {}
          if (values.location === 'COPY') {
            if (values.newTestSetName) {
              input.targetTestSetName = values.newTestSetName
            } else if (values.testSetId) {
              input.targetTestSetId = values.testSetId
            } else {
              input.targetTestSetId = speechTestSetId
            }
            input.targetName = values.newTestCaseName
          } else {
            input.targetTestSetId = speechTestSetId
            input.targetTestSetScriptId = speechTestSetScriptId
            input.targetName = speechName
          }
          const { data } = await mutateSpeechApplyOnConvo({
            variables: {
              input: {
                ...input,
                convo: speechConvo,
                profileId: values.profile,
                language: values.language,
                voices: [values.voices],
                effectsProfileId: values.effectsProfile,
                effects: values.effects
              }
            }
          })
          this.setState({ speechApplyOnConvoErr: null, speechApplyOnConvoResult: data.speechApplyOnConvo })
        } catch (err) {
          this.setState({ speechApplyOnConvoErr: err, speechApplyOnConvoResult: null })
        }
      }}
      initialValues={applySpeechInitialData}
      validate={async (values) => {
        if (speechApplyOnConvoResult) return

        if (!values.newTestSetName && values.newTestCaseName && (values.testSetId || speechTestSetId)) {
          return {
            newTestCaseName: (await validateConvoNameUnique(this.props.client, values.testSetId || speechTestSetId, null, values.newTestCaseName))
          }
        }
        return null
      }}
      render={({
        handleSubmit,
        submitting,
        values,
        form
      }) => (
        <ConfirmationDialog
          cancelText="Close"
          open={this.state.showApplySpeechDialog}
          onCancel={() => {
            if (speechApplyOnConvoResult) {
              _resetInitialData(values)
            }
            this.setState({ showApplySpeechDialog: false })
          }}
          onOk={() => {
            if (speechApplyOnConvoResult) {
              _resetInitialData(values)
              this.setState({ showApplySpeechDialog: false })
              history.push(`/testsets/view/${speechApplyOnConvoResult.testSetId}/testcases/viewconvo/${speechApplyOnConvoResult.testSetScriptId}/${speechApplyOnConvoResult.name}`)
            } else {
              handleSubmit()
            }
          }}
          okText={speechApplyOnConvoResult ? 'Open Voice Test Case' : 'Start Transform'}
          okDisabled={!!submitting}
          title="Transform to Voice Test Case"
        >
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <Field
                  name="location"
                  component={renderSelect}
                  validate={required}
                  label="Where do you want to save the Voice Test Case ?"
                  filterable={false}
                  data-unique="selTestSetConvoLocation"
                  disabled={!!speechApplyOnConvoResult}
                  items={[
                    { key: 'OVERWRITE', label: 'Transform and Replace this Test Case' },
                    { key: 'COPY', label: 'Transform and Save to new Voice Test Case' }
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                {values.location === 'COPY' &&
                  <Field
                    name="testSetId"
                    component={renderSelect}
                    label="Save to Test Set"
                    data-unique="selTestSetConvoTestSetId"
                    disabled={!!speechApplyOnConvoResult || !!values.newTestSetName}
                    items={testSetsData && testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => {
                      return {
                        key: t.id,
                        label: t.name
                      }
                    })}
                  />
                }
              </GridItem>
              {values.location === 'COPY' && <>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="newTestCaseName"
                    component={renderTextField}
                    label="Test Case Name"
                    validate={required}
                    disabled={!!speechApplyOnConvoResult}
                    data-unique="txtTestSetConvoNewTestCaseName"
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="newTestSetName"
                    component={renderTextField}
                    label="Save to new Test Set"
                    disabled={!!speechApplyOnConvoResult || !!values.testSetId}
                    data-unique="txtTestSetConvoNewTestSetName"
                  />
                </GridItem>
              </>}
              <GridItem xs={12}>
               <SpeechTTSSelectionPanel form={form} values={values} disabled={!!speechApplyOnConvoResult} multiple={false}/>
              </GridItem>
              <GridItem xs={12}>
                <SpeechEffectsSelectionPanel form={form} values={values} disabled={!!speechApplyOnConvoResult}/>
              </GridItem>
              {speechApplyOnConvoResult && speechApplyOnConvoResult.jobIds &&
                <GridItem xs={12}>
                  <SpeechJobsTable ids={speechApplyOnConvoResult.jobIds} />
                </GridItem>
              }
              {speechApplyOnConvoErr &&
                <GridItem xs={12}>
                  <ErrorFormat err={speechApplyOnConvoErr} suppress />
                </GridItem>
              }
            </GridContainer>
          </form>
        </ConfirmationDialog>
      )}
    />)
  }

  render() {
    const { match, partial, history } = this.props

    if (match.params && match.params.testSetScriptId && match.params.name) {
      return (
        <GridContainer >
          <GridItem xs={12} sm={12} md={12} >
            <Query
              query={TESTSET_EDITABLE_CONVO_QUERY}
              variables={{ testSetScriptId: match.params.testSetScriptId, name: match.params.name }}
              fetchPolicy="network-only"
              onCompleted={(data) => {
                // Maybe it sould be done on the server (TESTSET_EDITABLE_CONVO_QUERY), or even in the compilerTXT
                // I was not able to do it deeper in the client. Somehow it was executed more times
                // (the first render updated the data, but the changes where somehow reflected in apollo cache.
                // So the update caused by second render was executed on a data already updated)
                // And:
                // Even this is not enough. On second render apollo goes to the server, and calls this function, but not with the data
                // coming from the server, but by old data. "onCompletedDone" flag is to avoid update
                if (data?.testseteditableconvo && !data.testseteditableconvo.onCompletedDone) {
                  dbConvoToForm(data.testseteditableconvo)
                  data.testseteditableconvo.onCompletedDone = true
                }
              }}
            >
              {({ loading, error, data }) => {
                if (loading) return <Text padding><LoadingIndicator/></Text>
                if (error) return <ErrorFormat err={error} />
                if(!data.testseteditableconvo) {
                  if (partial) history.push(`/testsets/view/${match.params.testSetId}/testcases/registerpconvo`)
                  else history.push(`/testsets/view/${match.params.testSetId}/testcases/registerconvo`)
                  return this.renderForm(match.params.testSetId, null, this.empty)
                }
                return this.renderForm(match.params.testSetId, match.params.testSetScriptId, data.testseteditableconvo, data.testseteditableconvowarnings)
              }}
            </Query>
          </GridItem>
        </GridContainer>
      )
    } else {
      return (
        <GridContainer >
          <GridItem xs={12} sm={12} md={12}>
            {this.renderForm(match.params.testSetId, null, this.empty)}
          </GridItem>
        </GridContainer>
      )
    }
  }
}

export default compose(
  withStyles(
    (theme) => ({
      ...testsetsStyle(theme),
      ...convoStyle(theme)
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace, features: state.settings.features }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
  }),
  graphql(TESTSETS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      testSetsData: data,
    }),
  }),
  graphql(SPEECH_APPLY_ON_CONVO, {
    props: ({ mutate }) => ({
      mutateSpeechApplyOnConvo: args => mutate(args)
    }),
    options: (props) => ({
      refetchQueries: ({ data }) => [
        {
          query: TESTSET_EDITABLE_CONVO_QUERY,
          variables: { testSetScriptId: data.speechApplyOnConvo.testSetScriptId, name: data.speechApplyOnConvo.name }
        },
        {
          query: TESTSETSCRIPT_QUERY,
          variables: { id: data.speechApplyOnConvo.testSetScriptId },
        },
        ...RefetchTestSetQueries(data.speechApplyOnConvo.testSetId, props.license),
      ]
    })
  }),
  withApollo
)(TestSetConvo)
