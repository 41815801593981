import React from 'react'
import { connect } from 'react-redux'
import { Mutation } from 'react-apollo'
import { Form } from 'react-final-form'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import TestSetImport from '../TestSets/TestSetImport.jsx'
import { RefetchTestSetQueries, QUICKCREATE_TESTSET } from '../TestSets/gql'

import Text from 'components/Typography/Text.jsx'


class FileUploadWizard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      testsetUploading: false
    }
  }

  render() {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, namespace } = this.props

    return (<GridContainer>
      <GridItem xs={12}>
        <Mutation
          mutation={QUICKCREATE_TESTSET}
          refetchQueries={() => {
            return [
              ...RefetchTestSetQueries()
            ]
          }}
        >
          {(quickCreateTestSet, { loading, error }) => (
            <Form
              onSubmit={async (values, form) => {
                try {
                  this.setState({
                    testsetUploading: true
                  })
                  const res = await quickCreateTestSet({
                    variables: {
                      filename: values.filename,
                      filecontent: values.filecontent,
                      namespace: namespace.selected ? namespace.selected.name : undefined
                    },
                  })
                  this.setState({
                    testsetUploading: false
                  })
                  form.initialize(res.data.quickCreateTestSet)
                  setAlertSuccessMessage('File uploaded')
                  history.push(`/testsets/view/${res.data.quickCreateTestSet.id}`)
                } catch (error) {
                  this.setState({
                    testsetUploading: false
                  })
                  setAlertErrorMessage('File upload failed', error)
                }
              }}
              render={({
                handleSubmit
              }) => (
                <form onSubmit={handleSubmit} >
                  <UnsavedFormSpy />
                  <GridContainer>
                    <GridItem md={12} lg={8}>
                      <Card>
                        <CardBody bottomPadding>
                        <TestSetImport testsetUploading={this.state.testsetUploading} dataUnique="fileTestSetQuickCreateTestCaseFile" onFileLoaded={() => {
                    handleSubmit()
                  }} />
                          <Text>Import your existing tests from other sources (ZIP-File, Excel, CSV, ...)</Text>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </form>
              )}
            />)}
        </Mutation>
      </GridItem>
    </GridContainer>)
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(FileUploadWizard)
