import React from 'react'
import LoginPage from 'components/Page/LoginPage'
import LogoutPage from 'components/Page/LogoutPage'
import ResetPasswordPage from 'components/Page/ResetPasswordPage'

const entryRoutes = [
  {
    path: '/login',
    navbarName: 'Login',
    component: LoginPage
  },
  {
    path: '/logout',
    navbarName: 'Logout',
    component: LogoutPage
  },
  {
    path: '/resetpassword/:resetToken',
    navbarName: 'Reset Password',
    component: (props) => <ResetPasswordPage mode="reset" {...props} />
  },
  {
    path: '/passwordexpired/:resetToken',
    navbarName: 'Password Expired',
    component: (props) => <ResetPasswordPage mode="expired" {...props} />
  },
]

export default entryRoutes
