import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { compose, graphql, Query, withApollo } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import SimpleList from 'components/List/SimpleList.jsx'
import Button from 'components/Button/Button'

import ShowIcon from 'components/Icon/ShowIcon'

import SpeechNoiseProfile from './SpeechNoiseProfile.jsx'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { SPEECHNOISEPROFILES_QUERY, CLONE_SPEECHNOISEPROFILE, DELETE_SPEECHNOISEPROFILE } from './gql'
import { downloadfileformpost } from 'helper/downloadHelper.js'
import config from 'config.js'
import { setAlertErrorMessage, setAlertSuccessMessage } from 'actions/alert.js'
import SimpleConfirmationDialog from 'components/Dialog/SimpleConfirmationDialog.jsx'

class SpeechNoiseProfiles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCounter: 0
    }
  }
  render() {
    const { history, location, cloneSpeechNoiseProfile, deleteSpeechNoiseProfile, setAlertErrorMessage, setAlertSuccessMessage, token } = this.props
    return (
      <GridContainer>
        <GridItem xs={12} borderBottom middle>
          <Button
            secondary
            data-unique="btnNew"
            onClick={() => {
              this.setState({ newCounter: this.state.newCounter + 1, id: 'new' })
              history.push('/settings/voice/noise/new')
            }}
            >
              <ShowIcon icon="plus" /> New
            </Button>
        </GridItem>
        <GridItem xs={12} sm={4} md={3} noPadding>
          <Query query={SPEECHNOISEPROFILES_QUERY} notifyOnNetworkStatusChange={true}>
            {({ loading, error, data, refetch }) => {
              return <SimpleList
                name="tblProfiles"
                listData={(data && data.speechNoiseProfiles && data.speechNoiseProfiles.map(a => ({
                    id: a.id,
                    name: a.name,
                    secondary: (a.steps || []).map(s => s.effect).join(' | '),
                    icon: <ShowIcon icon="phone" />,
                    selected: location.pathname.indexOf(a.id) >= 0,
                    onClick: () => history.push(`/settings/voice/noise/${a.id}`),
                    actions: [
                      {
                        id: `delete_${a.id}`,
                        icon: 'trash',
                        name: 'Delete',
                        dataUnique: `btnProfile_${a.name}_Delete`,
                        onClick: () => {
                          this.setState({
                            confirmDelete: true,
                            confirmDeleteProfile: a
                          })
                        }
                      },
                      {
                        id: `clone_${a.id}`,
                        icon: 'clone',
                        name: 'Clone',
                        dataUnique: `btnProfile_${a.name}_Clone`,
                        onClick: async () => {
                          await cloneSpeechNoiseProfile({
                            variables: { id: a.id },
                            refetchQueries: [ { query: SPEECHNOISEPROFILES_QUERY } ]
                          })
                        }
                      },
                      {
                        id: `export_${a.id}`,
                        icon: 'external-link',
                        name: 'Export',
                        dataUnique: `btnProfile_${a.name}_Export`,
                        onClick: () => {
                          downloadfileformpost(`${config.api.base}/modelexport/speechNoiseProfile/${a.id}`, token).then(() => setAlertSuccessMessage('Export successful')).catch(err => setAlertErrorMessage(err.message))
                        }
                      }
                    ],
                  }))) || []}
                onRefresh={() => refetch()}
                pageLoading={loading}
              />
            }}
          </Query>
          <SimpleConfirmationDialog danger
              showConfirmationDialog={!!this.state.confirmDelete}
              confirmationText={`Are you sure you want to delete profile '${this.state.confirmDeleteProfile && this.state.confirmDeleteProfile.name}' ?`}
              requireCheck={true}
              onOk={async () => {
                try {
                  await deleteSpeechNoiseProfile({
                    variables: {
                      id: this.state.confirmDeleteProfile.id
                    },
                    refetchQueries: [ { query: SPEECHNOISEPROFILES_QUERY } ]
                  })
                  setAlertSuccessMessage('Profile deleted')
                } catch(error) {
                  setAlertErrorMessage('Profile deletion failed', error)
                }
                this.setState({ confirmDeleteProfile: null, confirmDelete: false })
              }}
              onCancel={() => this.setState({ confirmDeleteProfile: null, confirmDelete: false })}
            />
        </GridItem>
        <GridItem xs={12} sm={8} md={9} borderLeft>
        <Switch>
            <Route
              path="/settings/voice/noise/new"
              render={props => <SpeechNoiseProfile key={`new_${this.state.newCounter}`} onReady={(id) => history.push(`/settings/voice/noise/${id}`)} {...props}/>}
            />
            <Route
              path="/settings/voice/noise/:id"
              render={props => <SpeechNoiseProfile id={props.match.params.id} key={props.match.params.id} onReady={(id) => !id && history.push('/settings/voice/noise')} {...props}/>}
            />
          </Switch>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withRouter(
  compose(
    withStyles(settingsStyle, { withTheme: true }),
    connect(
      state => ({ user: state.token.user, token: state.token.token }),
      { setAlertSuccessMessage, setAlertErrorMessage },
    ),
    graphql(CLONE_SPEECHNOISEPROFILE, {
      props: ({ mutate }) => ({
        cloneSpeechNoiseProfile: args => mutate(args)
      }),
    }),
    graphql(DELETE_SPEECHNOISEPROFILE, {
      props: ({ mutate }) => ({
        deleteSpeechNoiseProfile: args => mutate(args)
      }),
    }),
  )
    (withApollo(SpeechNoiseProfiles)),
)
