import React, { PureComponent } from 'react'

const MIN_GREEN = 0.7
const MAX_RED = 0.3

export default class SimpleBar extends PureComponent {

  getProgressColor(progress, minGreen, maxRed) {
    if (progress < 0) {
      return 'lightgrey'
    }
    const normalized = (progress < 0 ? 0 : (progress > 1 ? 1 : progress))

    let gradient
    if (normalized < maxRed) {
      gradient = 0
    } else if (normalized > minGreen) {
      gradient = 1
    } else {
      gradient = (normalized - maxRed) / (minGreen - maxRed)
    }
    const red = 255 - Math.floor(255 * gradient)
    const green = 155 - Math.floor(155 * (red/255))

    return '#' + red.toString(16).padStart(2, '0') + green.toString(16).padStart(2, '0') + '00'
  }

  percentAsHTML(progress) {
    return `${Math.round(progress <= 0 ? 0 : (progress > 1 ? 1 : progress) * 100)}%`
  }

  render () {
    const { children, progress, inverse, width, fullBackground, minGreen = MIN_GREEN, maxRed = MAX_RED, ...rest } = this.props

    // 200% is workaround against white line on the bottom
    return <div style={{
      display: 'inline-block',
      width: width ? width : '200px',
      maxWidth: '100%',
      //background: 
      background: fullBackground ? `${this.getProgressColor(inverse ? 1 - progress: progress, minGreen, maxRed)}` : `#D5D9DD linear-gradient(to right, ${this.getProgressColor(inverse ? 1 - progress: progress, minGreen, maxRed)} 100%, green 0%) no-repeat`,
      backgroundSize: `${this.percentAsHTML(progress)} 200%`,
      border: '0px solid #D5D9DD',
      borderRadius: '6px',
      paddingLeft: '7px',
      paddingRight: '7px',
      color: '#fff'
    }} {...rest}>{children}</div>
  }
}
