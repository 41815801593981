import { SET_FEED, CLEAR_FEED } from '../constants'

export default function feed(state = {}, action) {
  switch (action.type) {
    case SET_FEED:
      return {
        ...state,
        [action.payload.feed]: {
          href: action.payload.href,
          entries: action.payload.entries,
          date: action.payload.date
        }
      }
    case CLEAR_FEED:
      if (state[action.payload.feed]) {
        const clone = { ...state }
        delete clone[action.payload.feed]
        return clone
      }
      return state
    default:
      return state
  }
}
