import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { OAuth2Client } from 'google-auth-library'

import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import Text from 'components/Typography/Text'

import {
  renderTextField,
  renderPasswordField,
  renderFileUpload,
  required
} from 'components/Form/Form'
import { capSetDescription, usedByCapabilitySet } from './Helper'

const _fromCaps = (caps, capsName, def = '') => {
  return (
    caps.find(c => c.name === capsName) || { stringValue: def }
  ).stringValue
}

const _toCaps = (caps, capsName, values, fieldName) => {
  caps.push({
    name: capsName,
    type: 'STRING',
    stringValue: values.googleAssistant[fieldName],
  })
}

export function googleAssistantCaps2Form(caps) {
  return {
    googleAssistant: {
      clientId: _fromCaps(caps, 'GOOGLE_ASSISTANT_CLIENT_ID'),
      clientSecret: _fromCaps(caps, 'GOOGLE_ASSISTANT_CLIENT_SECRET'),
      refreshToken: _fromCaps(caps, 'GOOGLE_ASSISTANT_REFRESH_TOKEN'),
      type: _fromCaps(caps, 'GOOGLE_ASSISTANT_TYPE', 'authorized_user'),
      startUtterance: _fromCaps(caps, 'GOOGLE_ASSISTANT_START_UTTERANCE'),
      endUtterance: _fromCaps(caps, 'GOOGLE_ASSISTANT_END_UTTERANCE', 'Cancel'),
    }
  }
}

const capNamesMap = {
  'googleAssistant.clientId': 'GOOGLE_ASSISTANT_CLIENT_ID',
  'googleAssistant.clientSecret': 'GOOGLE_ASSISTANT_CLIENT_SECRET',
  'googleAssistant.refreshToken': 'GOOGLE_ASSISTANT_REFRESH_TOKEN',
  'googleAssistant.type': 'GOOGLE_ASSISTANT_TYPE',
  'googleAssistant.startUtterance': 'GOOGLE_ASSISTANT_START_UTTERANCE',
  'googleAssistant.endUtterance': 'GOOGLE_ASSISTANT_END_UTTERANCE',
}

export function googleAssistantForm2caps(values) {
  const caps = [{ name: 'CONTAINERMODE', type: 'STRING', stringValue: 'google-assistant' }]
  _toCaps(caps, 'GOOGLE_ASSISTANT_CLIENT_ID', values, 'clientId')
  _toCaps(caps, 'GOOGLE_ASSISTANT_CLIENT_SECRET', values, 'clientSecret')
  _toCaps(caps, 'GOOGLE_ASSISTANT_REFRESH_TOKEN', values, 'refreshToken')
  _toCaps(caps, 'GOOGLE_ASSISTANT_TYPE', values, 'type')
  _toCaps(caps, 'GOOGLE_ASSISTANT_START_UTTERANCE', values, 'startUtterance')
  _toCaps(caps, 'GOOGLE_ASSISTANT_END_UTTERANCE', values, 'endUtterance')

  return caps
}

const CLOSED_REGISTER_DIALOG = {
  show: false,
  oauth2Client: null,
  authUrl: null
}
class GoogleAssistantEditInternal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      parseError: null,
      parseOk: null,
      registerDialog: CLOSED_REGISTER_DIALOG
    }
  }

  renderRegisterDialog({ clientId, clientSecret, change }) {
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props
    return (
      <Form
        onSubmit={async values => {
          try {
            const { tokens } = await this.state.registerDialog.oauth2Client.getToken(values.code)
            change('googleAssistant.refreshToken', tokens.refresh_token)
            this.setState({ registerDialog: CLOSED_REGISTER_DIALOG })
            setAlertSuccessMessage('Refresh token acquired')
          } catch (e) {
            setAlertErrorMessage('Failed to acquire refresh token', e)
          }
        }}

        render={({ handleSubmit }) => {
          return <ConfirmationDialog
            open={this.state.registerDialog.show}
            onCancel={() => this.setState({ registerDialog: CLOSED_REGISTER_DIALOG })}
            onOk={() => handleSubmit()}
            title="Register and acquire refresh token"
          >
            <form onSubmit={handleSubmit}>
              <UnsavedFormSpy />
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <Text bold>
                    Botium registers itself with the Google Assistant SDK as "virtual device" to talk to your Google Action. Please give the requested permissions, then copy the generated authorization code into the text field below.
                  </Text>
                </GridItem>
                <GridItem xs={12}>
                  <Button
                    secondary
                    onClick={() => window.open(this.state.registerDialog.authUrl, '_blank')}
                    data-unique="btnGoogleAssistentEditRegisterAsVirtualDevice"
                  >
                    Register Botium as Virtual Device (opens new browser window)
                  </Button>
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="code"
                    component={renderTextField}
                    label="Authorization Code"
                    validate={required}
                    data-unique="txtGoogleAssistentEditCode"
                    helperText={'Copy & paste from the "code" URL parameter in the browser navigation bar.'}
                  />
                </GridItem>
              </GridContainer>
            </form>
          </ConfirmationDialog>
        }}
      />
    )
  }

  render() {
    const { disabled, capSetCapNames } = this.props

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Text bold>
              Watch our video tutorial <a href="https://youtu.be/B7-j8VPC79M" target="_blank" rel="noopener noreferrer">Setting Up a Google Assistant Project in Botium</a> to get started!
            </Text>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <Field
              name="googleAssistant.fileuploadGoogleAssistant"
              component={renderFileUpload}
              accept=".json"
              values={values}
              change={change}
              label="Select/Drop Google Config JSON File to autofill Client ID and Client Secret"
              onFileLoaded={(filename, filecontent) => {
                try {
                  const cred = JSON.parse(atob(filecontent))
                  if (cred.installed) {
                    change('googleAssistant.clientId', cred.installed.client_id)
                    change('googleAssistant.clientSecret', cred.installed.client_secret)
                  }

                  this.setState({
                    ...this.state,
                    parseError: null,
                    parseOk: `Read credentials from file ${filename}`,
                  })
                } catch (err) {
                  this.setState({
                    ...this.state,
                    parseError: `Reading credentials from file ${filename} failed: ${err}`,
                    parseOk: null
                  })
                }
                change('googleAssistant.fileuploadGoogleAssistant', null)
              }}
            />
            {this.state.parseError && <Text danger>{this.state.parseError}</Text>}
            {this.state.parseOk && <Text success>{this.state.parseOk}</Text>}
          </GridItem>
          <GridItem xs={6} sm={6}>
            <Field
              name="googleAssistant.clientId"
              component={renderTextField}
              label="Client ID"
              validate={required}
              data-unique="txtGoogleAssistentEditClientId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['googleAssistant.clientId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['googleAssistant.clientId'])}
            />
          </GridItem>
          <GridItem xs={6} sm={6}>
            <Field
              name="googleAssistant.clientSecret"
              component={renderPasswordField}
              label="Client Secret"
              validate={required}
              data-unique="txtGoogleAssistentEditClientSecret"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['googleAssistant.clientSecret']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['googleAssistant.clientSecret'])}
            />
          </GridItem>
          <GridItem xs={6} sm={6}>
            <Field
              name="googleAssistant.refreshToken"
              component={renderTextField}
              label="Access/Refresh Token"
              validate={required}
              disabled={true}
              data-unique="txtGoogleAssistentEditRefreshToken"
              helperText={capSetDescription(capSetCapNames, capNamesMap['googleAssistant.refreshToken'])}
            />
          </GridItem>
          <GridItem xs={6} sm={6}>
            {this.renderRegisterDialog({
              clientId: values.googleAssistant.clientId,
              clientSecret: values.googleAssistant.clientSecret,
              change
            })}
            <Button secondary inputAlign
              onClick={() => {
                const oauth2Client = new OAuth2Client(
                  values.googleAssistant.clientId,
                  values.googleAssistant.clientSecret,
                  'http://localhost:1/' //'urn:ietf:wg:oauth:2.0:oob'
                )
                const authUrl = oauth2Client.generateAuthUrl({
                  // 'online' (default) or 'offline' (gets refresh_token)
                  access_type: 'offline',

                  // If you only need one scope you can pass it as a string
                  scope: [
                    'https://www.googleapis.com/auth/assistant-sdk-prototype'
                  ]
                })

                this.setState({
                  registerDialog: {
                    ...this.state.registerDialog,
                    show: true,
                    oauth2Client,
                    authUrl,

                  }
                })
              }}
              disabled={disabled || !values.googleAssistant.clientId || !values.googleAssistant.clientSecret}
              data-unique="btnGoogleAssistentEditAcquireAccessToken"
            >
              Acquire Access/Refresh Token with Google Authentication
            </Button>
          </GridItem>
          <GridItem xs={6} sm={6}>
            <Field
              name="googleAssistant.startUtterance"
              component={renderTextField}
              label="Utterance to launch/activate your Google Action"
              data-unique="txtGoogleAssistentEditStartUtterance"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['googleAssistant.startUtterance']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['googleAssistant.startUtterance'], 'Typically something like "Talk to my app". If empty, Botium will launch Google Assistant without any Google Action opened.')}
            />
          </GridItem>
          <GridItem xs={6} sm={6}>
            <Field
              name="googleAssistant.endUtterance"
              component={renderTextField}
              label="Utterance to end your Google Action"
              validate={required}
              disabled={disabled}
              data-unique="txtGoogleAssistentEditEndUtterance"
              helperText={capSetDescription(capSetCapNames, capNamesMap['googleAssistant.endUtterance'])}
            />
          </GridItem>
        </GridContainer>
      )
    }} />)
  }
}

const GoogleAssistantEdit = withRouter(connect(
  state => ({ user: state.token.user }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(GoogleAssistantEditInternal))
export { GoogleAssistantEdit }
