import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import Text from 'components/Typography/Text.jsx'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import { renderCheckbox, FormActionsToolbar } from 'components/Form/Form'

import TestSetContentSelector from 'views/TestSets/TestSetContentSelector'
import { RefetchChatbotQueries, CHATBOT_QUERY } from 'views/Chatbots/gql'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

const EXPORT_INTENTS = gql`
  mutation ExportToNuance(
    $chatbotId: ID!,
    $testSetId: ID!,
    $local: Boolean,
    $repositoryIds: [String!],
    $folderIds: [String!],
    $downloadlinkIds: [String!],
    $uploadmode: String!
  ) {
    exportToNuance(
      chatbotId: $chatbotId
      testSetId: $testSetId
      local: $local
      repositoryIds: $repositoryIds
      folderIds: $folderIds
      downloadlinkIds: $downloadlinkIds
      uploadmode: $uploadmode
    ) { chatbotId log }
  }
`

class NuanceExport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
      uploadResult: { }
    }
    this.empty = {
      local: true,
      repositories: [],
      folders: [],
      downloadlinks: [],
      replace: false,
      testSetId: this.props.testSetId
    }
  }

  render() {
    const { uploading, uploadResult } = this.state
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props
    const { chatbotId } = this.props

    return (<Query query={CHATBOT_QUERY} variables={{ id: chatbotId }}>
      {({ error, data }) => {
        if (error) return <ErrorFormat err={error} />

        if (!data.chatbot) return null

        return (
          <Mutation
            mutation={EXPORT_INTENTS}
            onCompleted={data => {
              this.setState({ uploading: false, uploadResult: data.exportToNuance })
              setAlertSuccessMessage('Conversation model exported')
            }}
            onError={error => {
              this.setState({ uploading: false })
              setAlertErrorMessage(`Exporting conversation model failed`, error)
            }}
            refetchQueries={[
              ...RefetchChatbotQueries()
            ]}
          >
            {(exportToNuance, { loading, error }) => (
              <Form
                onSubmit={values => {
                  this.setState({ uploading: true, uploadResult: {} })
                  const variables = {
                    chatbotId: chatbotId,
                    testSetId: values.testSetId,
                    local: !!values.local,
                    repositoryIds: values.repositories || [],
                    folderIds: values.folders || [],
                    downloadlinkIds: values.downloadlinks || [],
                    uploadmode: values.replace ? 'replace' : 'append'
                  }
                  exportToNuance({ variables })
                }}
                initialValues={this.empty}
                validate={(values) => this.state.contentValidate ? this.state.contentValidate(values) : null}
                render={({ handleSubmit, submitting, invalid }) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <TestSetContentSelector addFormValidate={(fn) => this.setState({ contentValidate: fn })}/>
                      <GridItem xs={12}>
                        <Field
                          name="replace"
                          component={renderCheckbox}
                          label="Replace Nuance intents and user examples"
                          helperText="Leave this unchecked to make Botium append user examples and intents instead of replacing the app data"
                          type="checkbox"
                          data-unique="chkNuanceExportReplace"
                        />
                      </GridItem>
                      <GridItem xs={12} largePadding>
                        <FormActionsToolbar rightButtons={
                          <Button
                            type="submit"
                            disabled={uploading || submitting || invalid}
                            data-unique="btnNuanceExportUploadUserExamples"
                          >
                            {uploading && <><LoadingIndicator /> Upload to Nuance is running ...</>}
                            {!uploading && <><CloudUploadIcon /> Upload user examples to Nuance</>}
                          </Button>
                        }/>
                      </GridItem>
                      {uploadResult && uploadResult.log && uploadResult.log.map((l, i) => <GridItem xs={12} key={i}><Text bold>{l}</Text></GridItem>)}
                    </GridContainer>
                  </form>
                )}
              />
            )}
          </Mutation>
        )
      }}
    </Query>)
  }
}

NuanceExport.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  testSetId: PropTypes.string
}

export default withRouter(connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(NuanceExport))
