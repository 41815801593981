import React from 'react'
import config from 'config'
import { compose, graphql } from 'react-apollo'
import { connect } from 'react-redux'
import _ from 'lodash'
import copyToClipboard from 'copy-to-clipboard'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import DateFormat from 'components/Info/DateFormat'
import ErrorFormat from 'components/Info/ErrorFormat'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/Button/Button'
import Text from 'components/Typography/Text'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { LICENSE_QUERY, PACKAGE_VERSIONS_QUERY } from './gql'

class Version extends React.Component {
  render() {
    const { licenseData, packageVersionsData } = this.props

    return (<Card><CardBody>
      <GridContainer>
        <GridItem xs={12}><Text>Build Branch: {config.buildBranch}</Text></GridItem>
        <GridItem xs={12}><Text>Build Revision: {config.buildRevision}</Text></GridItem>
        <GridItem xs={12}><Text>Build Date: {config.buildDate}</Text></GridItem>

        {licenseData.error && <GridItem xs={12}><ErrorFormat err={licenseData.error} /></GridItem>}
        {licenseData.license && <>
          <GridItem xs={12}><Text>Licensed Edition: {licenseData.license.edition} {licenseData.license.trial ? 'TRIAL' : ''}</Text></GridItem>
          {licenseData.license.email && <GridItem xs={12}><Text>License Email: {licenseData.license.email}</Text></GridItem>}
          {licenseData.license.hostnames && <GridItem xs={12}><Text>License Hosts: {licenseData.license.hostnames}</Text></GridItem>}
          <GridItem xs={12}><Text>License Validity: {licenseData.license.validity && <DateFormat format="ll">{licenseData.license.validity}</DateFormat>}{!licenseData.license.validity && 'no restrictions'}</Text></GridItem>
          <GridItem xs={12}><Text>License Operability: {licenseData.license.operability && <DateFormat format="ll">{licenseData.license.operability}</DateFormat>}{!licenseData.license.operability && 'no restrictions'}</Text></GridItem>
        </>}

        {packageVersionsData.error && <GridItem xs={12}><ErrorFormat err={packageVersionsData.error} /></GridItem>}
        {packageVersionsData.packageVersions && <>
          {packageVersionsData.packageVersions.map((entry) => <GridItem xs={12} key={entry.name}><Text muted>{entry.name}: {entry.version}</Text></GridItem>)}
        </>}

        <GridItem xs={12}>{this.renderExportVersionsButton()}</GridItem>
      </GridContainer>
    </CardBody></Card>)
  }

  renderExportVersionsButton() {
    const { setAlertSuccessMessage, licenseData, packageVersionsData } = this.props

    return <Button
      onClick={() => {
        const versions = {
          build: {
            buildBranch: config.buildBranch,
            buildRevision: config.buildRevision,
            buildDate: config.buildDate
          }
        }
        if (licenseData.error) versions.license = licenseData.error
        else if (licenseData.license) versions.license = _.omit(licenseData.license, '__typename')
        else versions.license = 'No license info found'

        if (packageVersionsData.error) versions.packages = packageVersionsData.error
        else if (packageVersionsData.packageVersions) versions.packages = packageVersionsData.packageVersions.reduce((a, v) => ({ ...a, [v.name]: v.version }), {})
        else versions.packages = 'No package info found'

        copyToClipboard(JSON.stringify(versions, null, 2))
        setAlertSuccessMessage(`Successful`)
      }}
      data-unique="btnVersionCopyToClipboard"
    >
      Copy to clipboard
    </Button>
  }
}

export default compose(
  withStyles(settingsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license })
  ),
  graphql(LICENSE_QUERY, {
    props: ({ data }) => ({
      licenseData: data,
    }),
  }),
  graphql(PACKAGE_VERSIONS_QUERY, {
    props: ({ data }) => ({
      packageVersionsData: data,
    }),
  })
)(Version)
