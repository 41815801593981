import React from 'react'
import { NavLink } from 'react-router-dom'

import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
// core components
import { CustomCheckbox, CustomTextField } from 'components/Form/Form'
import LinkButton from 'components/Button/LinkButton'
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle'
import Button from 'components/Button/Button'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SimpleTable from '../../../../components/Table/Table'
import Tooltip from 'components/Tooltip/Tooltip'


const NavLinkWeak = (props) => {
  if (props.to) {
    return <NavLink {...props} />
  }

  return props.children
}


const getUrl = (testSessionId, testProjectId, expectedIntentName, actualIntentName, confusionMatrixEntry = {}) => {
  if (confusionMatrixEntry.utteranceList) {
    const u = confusionMatrixEntry.utteranceList
    if (u.testSetId && u.testScriptId && u.testScriptName) {
      return `/testsets/view/${u.testSetId}/testcases/viewutterance/${u.testScriptId}/${encodeURIComponent(u.testScriptName)}`
    }
    return null
  }
  expectedIntentName = expectedIntentName ? encodeURIComponent(expectedIntentName) : null
  actualIntentName = actualIntentName ? encodeURIComponent(actualIntentName) : null
  let url = `/nlp/projects/view/${testProjectId}/results/${testSessionId}/intentconfidence`
  if (expectedIntentName) {
    url += `/expectedintentname/${expectedIntentName}`
  }
  if (actualIntentName) {
    url += `/actualintentname/${actualIntentName}`
  }

  return url
}

export const ConfusionMatrixTable = withStyles(theme => {

  const tableStyles = tableStyle(theme)
  tableStyles.table = {
    '& td': {
      background: 'none',
      borderBottom: 'none',
    },
    '& th': {
      borderBottom: 'none',
    }
  }
  tableStyles.tableMatrixCell = {
    ...tableStyle.tableCell,
    padding: '2px 2px',
  }
  tableStyles.tableMatrixCellScore = {
    width: 100
  }
  tableStyles.tableCalculationCell = {
    ...tableStyle.tableCell,
    lineHeight: '1.5em'
  }
  tableStyles.tableCell = {
    ...tableStyle.tableCell,
    padding: '0px 0px',
  }

  tableStyles.tableHeadCellMatrix = {
    ...tableStyle.tableHeadCell,
    padding: '10px 5px',
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: 11
  }
  tableStyles.tableHeadCellCalculation = {
    ...tableStyle.tableHeadCell,
    '& svg': {
      verticalAlign: 'middle'
    },
    '& a': {
      cursor: 'pointer'
    }
  }
  tableStyles.tableRow = {
    height: 'auto',
    lineHeight: '1em',
  }




  return tableStyles
}, { withTheme: true })(class ConfusionMatrix extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      useState: null,
      matrixFilter: '',
      showOnlyErrors: false,
      orderBy: 'intent',
      orders: 'asc',
      hoverColumn: null,
      hoverRow: null,
      showCluster: {
        column: 0,
        row: 0
      },
    }
  }


  getMatrixCell(entry, rowIndex, columnIndex, testProjectId, classes, cellWidth, cellHeight, maxTruePositive, maxMistakes, expectedIntentName, actualIntentName, testSessionId, utteranceList) {
    const isHiglighted = rowIndex === this.state.hoverRow || columnIndex === this.state.hoverColumn
    const style = { width: cellWidth, height: cellHeight, textAlign: 'center' }

    if (isHiglighted) {
      style.background = this.props.theme.colors.testsetseditorbg
    }
    if (!_.isNumber(entry)) {
      return (<TableCell
        className={classes.tableMatrixCell + ' ' + classes.onlyBorder}
        style={style}
        key={columnIndex}
        onMouseEnter={() => this.setState({ hoverRow: rowIndex, hoverColumn: columnIndex })}
      >
        <div style={{ display: 'inline-block', width: cellWidth, height: cellHeight }}>{entry}</div>
      </TableCell>)
    }

    let color
    if (rowIndex === columnIndex) {
      color = `${this.props.theme.colors.common.greenColor1}`
    } else {
      color = `${this.props.theme.colors.common.danger}`
    }
    const sizeBubble = 10 + entry
    const cell = <Tooltip title={`User Example: ${entry}`} placement="top"><div style={{
      display: 'inline-block',
      backgroundColor: `${color}`,
      margin: '0px',
      textAlign: 'center',
      borderRadius: '50%',
      width: sizeBubble,
      height: sizeBubble,
      maxHeight: 40,
      maxWidth: 40,
      color: `${this.props.theme.colors.primary}`
    }}>&nbsp;</div></Tooltip>


    return (<TableCell
      className={classes.tableMatrixCell + ' ' + classes.onlyBorder}
      key={columnIndex}
      style={style}
      onMouseEnter={() => this.setState({ hoverRow: rowIndex, hoverColumn: columnIndex })}
    >
      {!utteranceList && <NavLinkWeak to={getUrl(testSessionId, testProjectId, expectedIntentName, actualIntentName)} data-unique={`btnCoachConfusionMatrixTableCell_${testSessionId}_${expectedIntentName}_${actualIntentName}`}>
        {cell}
      </NavLinkWeak>}
      {utteranceList && cell}
    </TableCell>)
  }

  getSimpleBar(classes, score, scoreFieldName, url, forceEmptyCell, rowIndex, style) {
    const getSimpleBarUndecorated = () => {
      return <div>{hasScore ? score[scoreFieldName].toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</div>
    }
    const getCell = () => {
      if (forceEmptyCell) {
        return ''
      }
      if (hasScore) {
        return <NavLinkWeak className={classes.navLinkColor} to={url} data-unique={`btnCoachConfusionMatrixTableSimpleBar`}>
          {getSimpleBarUndecorated()}
</NavLinkWeak>
      }
      if (!hasScore) {
        return <div className={classes.navLinkColor + ' ' + classes.navLinkColorOpacity}>{getSimpleBarUndecorated()}</div>
      }
    }

    const hasScore = _.isNumber(score[scoreFieldName])
    return <TableCell
      className={classes.tableCell + ' ' + classes.tableCalculationCell + ' ' + classes.tableMatrixCellScore}
      key={scoreFieldName}
      style={style}
      onMouseEnter={() => this.setState({ hoverRow: rowIndex, hoverColumn: null })}
    >
      {getCell()}
    </TableCell>
  }



  render() {
    const { testSessionId, classes, utteranceList, testProjectId } = this.props
    const { matrixFilter, showOnlyErrors, orderBy, orders, showCluster } = this.state

    const setMatrixFilter = (s) => this.setState({ matrixFilter: s })
    const setShowOnlyErrors = (s) => this.setState({ showOnlyErrors: s })
    const setOrderBy = (s) => this.setState({ orderBy: s })
    const setOrders = (s) => this.setState({ orders: s })

    const getSortVal = (i) => {
      const val = _.get(i, orderBy)
      if (_.isString(val)) return val.toLowerCase()
      if (_.isNumber(val)) return val
      return 0.0
    }
    const buildHeader = (field, text) => (<LinkButton
      hoverUnderline
      data-unique={`btnCoachConfusionMatrixSetOrder_${field}`}
      onClick={() => {
        setOrderBy(field)
        setOrders(orders === 'asc' ? 'desc' : 'asc')
      }}>
      {text}
      {orderBy === field && orders === 'desc' && <ShowIcon icon="sort-down" />}
      {orderBy === field && orders === 'asc' && <ShowIcon icon="sort-up" />}
      {orderBy !== field && <ShowIcon icon="sort-up" hidden />}
    </LinkButton>)

    const _cleanedMatrixFilters = matrixFilter ? matrixFilter.split(/[,|\s]+/).map(t => t.trim()).filter(t => t) : null
    const _activeIntent = matrixFilter ? (intent) => _cleanedMatrixFilters.findIndex(mf => intent.indexOf(mf) >= 0) >= 0 : () => true
    const _activeRow = intent => {
      if (!_activeIntent(intent)) return false
      if (showOnlyErrors && !intentsWithMistakes.includes(intent)) return false
      return true
    }

    const _activeColumn = intent => {
      if (matrixFilter && !intentsWithColumnData.includes(intent)) return false
      if (showOnlyErrors && !intentsWithMistakes.includes(intent)) return false

      return true
    }

    const confusionMatrix = _.orderBy(this.props.confusionMatrix, [entry => getSortVal(entry)], [orders])

    let maxTruePositive = 0
    let maxMistakes = 0
    let intentsWithColumnData = []
    let intentsWithMistakes = []
    for (const { intent, row } of confusionMatrix) {
      for (const [actual, count] of Object.entries(row)) {
        if (!count) {
          continue
        }
        if (_activeIntent(intent)) {
          intentsWithColumnData.push(actual)
        }
        if (intent === actual) {
          maxTruePositive = Math.max(row[actual], maxTruePositive)
        } else {
          maxMistakes = Math.max(row[actual], maxMistakes)
          intentsWithMistakes.push(intent)
          intentsWithMistakes.push(actual)
        }
      }
    }
    intentsWithColumnData = _.uniq(intentsWithColumnData)
    intentsWithMistakes = _.uniq(intentsWithMistakes)

    const intentToClusterIndexColumn = {}
    const intentToClusterIndexRow = {}
    let activeColumnCount = 0
    let activeRowCount = 0

    confusionMatrix.forEach((entry) => {
      if (_activeRow(entry.intent)) {
        activeRowCount++
      }
      if (_activeColumn(entry.intent)) {
        activeColumnCount++
      }
    })

    const clusterSize = Math.max(activeColumnCount, activeRowCount) <= 100 ? 10 : 20

    let row = 0
    let column = 0
    confusionMatrix.forEach((entry) => {
      if (_activeRow(entry.intent)) {
        intentToClusterIndexRow[entry.intent] = Math.trunc(row / clusterSize)
        row++
      }
      if (_activeColumn(entry.intent)) {
        intentToClusterIndexColumn[entry.intent] = Math.trunc(column / clusterSize)
        column++
      }
    })

    const activeRowCountClustered = Math.ceil((activeRowCount + 1) / clusterSize)
    const activeColumnCountClustered = Math.ceil((activeColumnCount + 1) / clusterSize)
    const _showRowByCluster = intent => {
      return intentToClusterIndexRow[intent] === showCluster.row
    }

    const _showColumnByCluster = intent => {
      return intentToClusterIndexColumn[intent] === showCluster.column
    }

    const confusionMatrixClustered = {}
    confusionMatrix.forEach((entry, rowIndex) => {
      const intentColumn = entry.intent
      for (const intentRow of Object.keys(entry.row || {})) {
        const column = intentToClusterIndexColumn[intentColumn]
        const row = intentToClusterIndexRow[intentRow]
        if (_.isNil(column) || _.isNil(row)) {
          continue
        }
        if (!confusionMatrixClustered[column]) {
          confusionMatrixClustered[column] = {}
        }
        if (!confusionMatrixClustered[column][row]) {
          confusionMatrixClustered[column][row] = { correct: 0, incorrect: 0 }
        }
        confusionMatrixClustered[column][row][intentColumn === intentRow ? 'correct' : 'incorrect'] += entry.row[intentRow]
      }
    })

    const visibleColumnCount = Math.min(activeColumnCount - clusterSize * showCluster.column, clusterSize)
    const visibleRowCount = Math.min(activeRowCount - clusterSize * showCluster.row, clusterSize)

    const cellWidth = 400 / visibleColumnCount
    const cellHeight = 400 / visibleRowCount

    const clusterCellWidth = 270 / activeColumnCountClustered
    const clusterCellHeight = 270 / activeRowCountClustered

    const clusterHitWidth = 35 / activeColumnCountClustered * 2
    const clusterHitHeight = 35 / activeRowCountClustered * 2



    return (<GridContainer>
      <GridItem md={12} >
        <GridContainer>
          <GridItem md={4} lg={2} middle key="empty">
            {confusionMatrix.length > 0 && <CustomTextField
              placeholder="Filter"
              label="Filter Expected Intent Label"
              input={{
                name: 'matrixFilter',
                value: matrixFilter,
                onChange: (e) => {
                  setMatrixFilter(e.target.value)
                },
              }}
              data-unique="txtCoachConfusionMatrixFilterMatrix"
            />
            }
          </GridItem>
          <GridItem md={8} lg={3} middle>
            {confusionMatrix.length > 0 && <CustomCheckbox
              label="Show Issues Only"
              disabled={intentsWithMistakes.length === 0}
              input={{
                name: 'showOnlyErrors',
                checked: showOnlyErrors,
                onChange: (e) => {
                  setShowOnlyErrors(!showOnlyErrors)
                },
              }}
              data-unique="chkConfusionMatrixTableShowOnlyErrors"
            />
            }
          </GridItem>
          <GridItem md={12} lg={7} middle right>
            <Text primary rightMarginmd ><div className={classes.correctLabelIcon + ' ' + classes.labelIconLighter}></div><div className={classes.correctLabelIcon + ' ' + classes.labelIconLight}></div><div className={classes.correctLabelIcon}></div> Correct Prediction Frequency </Text>
            <Text primary rightMarginmd ><div className={classes.incorrectLabelIcon + ' ' + classes.labelIconLighter}></div><div className={classes.incorrectLabelIcon + ' ' + classes.labelIconLight}></div><div className={classes.incorrectLabelIcon}></div> Incorrect Prediction Frequency </Text>
            <Text primary rightMarginmd ><div className={classes.hitLabelIcon + ' ' + classes.labelIconLighter}></div><div className={classes.hitLabelIcon + ' ' + classes.labelIconLight}></div><div className={classes.hitLabelIconSmall}></div> Most Predictions </Text>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem md={12} marginTop>
        <GridContainer>
        {activeColumnCountClustered > 1 &&
          <GridItem md={4} lg={3} className={classes.gridWidthLeft}>

                <div className={classes.viewClaster}>
                  <div className={classes.viewColumn}>
                    <div><Button secondary small noMargin disabled={showCluster.column <= 0} onClick={() => { this.setState({ showCluster: { column: showCluster.column - 1, row: showCluster.row } }) }}><ShowIcon icon="angle-left" /></Button></div>
                    <Text center style={{ paddingTop: 5 }}>Column #{showCluster.column + 1}</Text>
                    <div><Button secondary small floatRight noMargin disabled={showCluster.column >= activeColumnCountClustered - 1} onClick={() => { this.setState({ showCluster: { column: showCluster.column + 1, row: showCluster.row } }) }}><ShowIcon icon="angle-right" /></Button></div>
                  </div>
                  <div className={classes.viewRow}>
                    <div><Button secondary small noMargin disabled={showCluster.row <= 0} onClick={() => { this.setState({ showCluster: { column: showCluster.column, row: showCluster.row - 1 } }) }}><ShowIcon icon="angle-up" /></Button></div>
                    <Text center ><div>Row #{showCluster.row + 1}</div></Text>
                    <div><Button secondary small floatRight noMargin disabled={showCluster.row >= activeRowCountClustered - 1} onClick={() => { this.setState({ showCluster: { column: showCluster.column, row: showCluster.row + 1 } }) }}><ShowIcon icon="angle-down" /></Button></div>
                  </div>
                  <div className={classes.viewParent} >
                    <SimpleTable
                      tableHeaderColor="primary"
                      tableData={Object.values(confusionMatrixClustered).map((entry, row) => {
                        return Object.keys(confusionMatrixClustered).map((entry2, column) => {
                          const cellData = entry[column]
                          return <div style={{ width: clusterCellWidth, height: clusterCellHeight }} className={classes.viewChildren + ' ' + ((showCluster.column === column && showCluster.row === row) ? classes.activeCell : '')} onClick={() => { this.setState({ showCluster: { column: column, row: row } }) }}>
                            {cellData?.correct || cellData?.incorrect ? <div style={{width: clusterHitWidth, height: clusterHitHeight}} className={classes.hitLabelIcon + ' ' + classes.labelIconCenter}></div> : null}
                          </div>
                        })
                      })}
                    />
                  </div>
                </div>

          </GridItem>
           }
          <GridItem md={8} lg={9} className={classes.gridWidthRight}>
            <Table className={classes.table}>
              <TableHead className={classes.primaryTableHeader}>
                <TableRow className={classes.tableRow}>
                  <TableCell key="empty1" className={classes.tableHeadCellMatrix + ' ' + classes.tableHeadCellMatrixLeft} >{buildHeader('intent', 'Expected')}</TableCell>
                  {confusionMatrix.map((confusionMatrixEntry, colIndex) => {
                    const { intent } = confusionMatrixEntry
                    if (!_activeColumn(intent) || !_showColumnByCluster(intent)) {
                      return null
                    }

                    const isHiglightedColumn = colIndex === this.state.hoverColumn
                    const style = {}
                    if (isHiglightedColumn) {
                      style.background = this.props.theme.colors.testsetseditorbg
                    }
                    return (<TableCell
                      className={classes.tableCell + ' ' + classes.tableHeadCellMatrix + ' ' + classes.onlyBorder}
                      key={colIndex}
                      style={style}
                      onMouseEnter={() => this.setState({ hoverRow: null, hoverColumn: colIndex })}
                    >
                      <Tooltip title={'Predicted: ' + confusionMatrixEntry.intent} placement="top-start"><NavLinkWeak to={getUrl(testSessionId, testProjectId, null, intent, confusionMatrixEntry)} data-unique={`btnCoachConfusionMatrixTable_${confusionMatrixEntry.utteranceList && confusionMatrixEntry.utteranceList.testScriptId}_${confusionMatrixEntry.utteranceList && confusionMatrixEntry.utteranceList.testScriptName}_${confusionMatrixEntry.intent}`}>{colIndex}</NavLinkWeak></Tooltip>

                    </TableCell>)
                  })}

                  <TableCell className={classes.tableCell + ' ' + classes.tableMatrixScore}  >&nbsp;</TableCell>
                  {!utteranceList && <TableCell className={classes.tableCell + ' ' + classes.tableHeadCellCalculation + ' ' + classes.tableMatrixScore}
                    key="accuracy">{buildHeader('+score.accuracy', 'Accuracy')}</TableCell>}
                  {!utteranceList && <TableCell className={classes.tableCell + ' ' + classes.tableHeadCellCalculation + ' ' + classes.tableMatrixScore}
                    key="Precision">{buildHeader('score.precision', 'Precision')}</TableCell>}
                  <TableCell className={classes.tableCell + ' ' + classes.tableHeadCellCalculation + ' ' + classes.tableMatrixScore}
                    key="Recall">{buildHeader('score.recall', 'Recall')}</TableCell>
                  {!utteranceList && <TableCell className={classes.tableCell + ' ' + classes.tableHeadCellCalculation + ' ' + classes.tableMatrixScore}
                    key="F1">{buildHeader('score.F1', 'F1-Score')}</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {confusionMatrix.map((confusionMatrixEntry, rowIndex) => {
                  if (!_activeRow(confusionMatrixEntry.intent) || !_showRowByCluster(confusionMatrixEntry.intent)) {
                    return null
                  }
                  const rowUrl = getUrl(testSessionId, testProjectId, confusionMatrixEntry.intent, null, confusionMatrixEntry)

                  const isHiglightedRow = rowIndex === this.state.hoverRow
                  const style = {}
                  if (isHiglightedRow) {
                    style.background = this.props.theme.colors.testsetseditorbg
                  }
                  return (
                    <TableRow className={classes.tableRow} key={rowIndex}>
                      <TableCell
                        className={classes.tableMatrixCell + ' ' + classes.tableMatrixCellLable + ' ' + classes.onlyBorder}
                        key="intent"
                        style={style}
                        onMouseEnter={() => this.setState({ hoverRow: rowIndex, hoverColumn: null })}
                      >
                        <Tooltip
                          classes={{
                            popper: classes.htmlPopper,
                            tooltip: classes.htmlTooltip,
                          }}
                          PopperProps={{
                            popperOptions: {
                              modifiers: {
                                arrow: {
                                  enabled: Boolean(this.state.arrowRef),
                                  element: this.state.arrowRef,
                                },
                              },
                            },
                          }}
                          title={
                            <React.Fragment>
                                  <div>{confusionMatrixEntry.intent}</div>
                                  <div style={{width: 200}}><div style={{width: 80, float: 'left'}}>Accuracy:</div>  <b>{!utteranceList && this.getSimpleBar(classes, confusionMatrixEntry.score, 'accuracy', rowUrl, false, rowIndex)}</b></div>
                                  <div style={{width: 200}}><div style={{width: 80, float: 'left'}}>Precision:</div>  <b>{!utteranceList && this.getSimpleBar(classes, confusionMatrixEntry.score, 'precision', rowUrl, false, rowIndex)}</b></div>
                                  <div style={{width: 200}}><div style={{width: 80, float: 'left'}}>Recall:</div>  <b>{this.getSimpleBar(classes, confusionMatrixEntry.score, 'recall', rowUrl, confusionMatrixEntry.utteranceList && confusionMatrixEntry.utteranceList.incomprehension, rowIndex)}</b></div>
                                  <div style={{width: 200, paddingBottom: 20}}><div style={{width: 80, float: 'left'}}>F1-Score:</div>  <b>{!utteranceList && this.getSimpleBar(classes, confusionMatrixEntry.score, 'F1', rowUrl, false, rowIndex)}</b></div>
                                </React.Fragment>
                              }
                            >

                          <NavLinkWeak to={rowUrl} data-unique={`btnCoachConfusionMatrixTableIntent_${confusionMatrixEntry.utteranceList && confusionMatrixEntry.utteranceList.testScriptId}_${confusionMatrixEntry.utteranceList && confusionMatrixEntry.utteranceList.testScriptName}_${confusionMatrixEntry.intent}`}>
                            <div className={classes.intentNameCut}>{confusionMatrixEntry.intent}</div>
                            <div className={classes.rowIndexShow}>{rowIndex}</div>
                          </NavLinkWeak>
                        </Tooltip>
                      </TableCell>
                      {confusionMatrix.map(({ intent }, columnIndex) => {
                        if (!_activeColumn(intent) || !_showColumnByCluster(intent)) {
                          return null
                        }

                        return this.getMatrixCell(confusionMatrixEntry.row[intent], rowIndex, columnIndex, testProjectId, classes, cellWidth, cellHeight, maxTruePositive, maxMistakes, confusionMatrixEntry.intent, intent, testSessionId, utteranceList)
                      })}
                      <TableCell
                        className={classes.tableMatrixCell + ' ' + classes.tableMatrixEmpty + ' ' + classes.tableMatrixScore}
                        style={style}
                        onMouseEnter={() => this.setState({ hoverRow: rowIndex, hoverColumn: null })}
                      >&nbsp;</TableCell>
                      <TableCell
                        className={classes.tableMatrixScore}
                        style={style}
                        onMouseEnter={() => this.setState({ hoverRow: rowIndex, hoverColumn: null })}
                      >
                          {!utteranceList && this.getSimpleBar(classes, confusionMatrixEntry.score, 'accuracy', rowUrl, false, style)}
                      </TableCell>
                      <TableCell
                        className={classes.tableMatrixScore}
                        style={style}
                        onMouseEnter={() => this.setState({ hoverRow: rowIndex, hoverColumn: null })}
                      >
                        {!utteranceList && this.getSimpleBar(classes, confusionMatrixEntry.score, 'precision', rowUrl, false, style)}
                      </TableCell>
                      <TableCell
                        className={classes.tableMatrixScore}
                        style={style}
                        onMouseEnter={() => this.setState({ hoverRow: rowIndex, hoverColumn: null })}
                      >
                        {this.getSimpleBar(classes, confusionMatrixEntry.score, 'recall', rowUrl, confusionMatrixEntry.utteranceList && confusionMatrixEntry.utteranceList.incomprehension, style)}
                      </TableCell>
                      <TableCell
                        className={classes.tableMatrixScore}
                        style={style}
                        onMouseEnter={() => this.setState({ hoverRow: rowIndex, hoverColumn: null })}
                      >
                        {!utteranceList && this.getSimpleBar(classes, confusionMatrixEntry.score, 'F1', rowUrl, false, style)}
                      </TableCell>
                    </TableRow>)
                })}
              </TableBody>
            </Table>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>)
  }
})