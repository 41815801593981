import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

import {
  required,
  renderTextField,
  renderSelect,
  renderPasswordField
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function alexasmapiCaps2Form(caps) {
  return {
    alexasmapi: {
      api: (
        caps.find(c => c.name === 'ALEXA_SMAPI_API') || { stringValue: '' }
      ).stringValue,
      skillId: (
        caps.find(c => c.name === 'ALEXA_SMAPI_SKILLID') || { stringValue: '' }
      ).stringValue,
      locale: (
        caps.find(c => c.name === 'ALEXA_SMAPI_LOCALE') || { stringValue: '' }
      ).stringValue,
      refreshToken: (
        caps.find(c => c.name === 'ALEXA_SMAPI_REFRESHTOKEN') || { stringValue: '' }
      ).stringValue,
      endpointRegion: (
        caps.find(c => c.name === 'ALEXA_SMAPI_ENDPOINTREGION') || { stringValue: '' }
      ).stringValue,
      simulationPhrase: (
        caps.find(c => c.name === 'ALEXA_SMAPI_SIMULATION_PHRASE') || { stringValue: '' }
      ).stringValue,
    },
  }
}

const capNamesMap = {
  'alexasmapi.api': 'ALEXA_SMAPI_API',
  'alexasmapi.skillId': 'ALEXA_SMAPI_SKILLID',
  'alexasmapi.locale': 'ALEXA_SMAPI_LOCALE',
  'alexysmapi.refreshToken': 'ALEXA_SMAPI_REFRESHTOKEN',
  'alexasmapi.endpointRegion': 'ALEXA_SMAPI_ENDPOINTREGION',
  'alexasmapi.simulationPhrase': 'ALEXA_SMAPI_SIMULATION_PHRASE'
}

export function alexasmapiForm2caps(values) {
  const capabilities = [
    {
      name: 'ALEXA_SMAPI_API',
      type: 'STRING',
      stringValue: values.alexasmapi.api || '',
    },
    {
      name: 'ALEXA_SMAPI_SKILLID',
      type: 'STRING',
      stringValue: values.alexasmapi.skillId || '',
    },
    {
      name: 'ALEXA_SMAPI_LOCALE',
      type: 'STRING',
      stringValue: values.alexasmapi.locale || '',
    },
    {
      name: 'ALEXA_SMAPI_REFRESHTOKEN',
      type: 'STRING',
      stringValue: values.alexasmapi.refreshToken || '',
    },
    {
      name: 'ALEXA_SMAPI_ENDPOINTREGION',
      type: 'STRING',
      stringValue: values.alexasmapi.endpointRegion || '',
    },
    {
      name: 'ALEXA_SMAPI_SIMULATION_PHRASE',
      type: 'STRING',
      stringValue: values.alexasmapi.simulationPhrase || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'alexa-smapi' }
  ]

  return capabilities
}

const api = [
  { name: 'Skill Simulation API', value: 'simulation' },
  { name: 'Skill Invocation API', value: 'invocation' }
]
const locales = [
  'en-US', 'en-GB', 'en-CA', 'en-AU', 'de-DE', 'fr-FR', 'en-IN', 'ja-JP'
]
const regions = [
  'default', 'NA', 'EU', 'FE'
]

class AlexaSMAPIEditUnconnected extends React.Component {
  render() {
    const { disabled, capSetCapNames } = this.props
    return (
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          return (<React.Fragment>
            <GridContainer>
              <GridItem xs={12}>
                <Text muted>
                  See the <a href="https://github.com/codeforequity-at/botium-connector-alexa-smapi" target="_blank" rel="noopener noreferrer">Github Repository</a> of the connector for detailed instructions.
                </Text>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <Field
                  name="alexasmapi.api"
                  component={renderSelect}
                  label="Skill Management API"
                  validate={required}
                  data-unique="selSMAPIEditApi"
                  items={api.map(a => {
                    return { key: a.value, label: a.name }
                  })}
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexasmapi.api']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['alexasmapi.api'])}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Text muted>
                  See <a href="https://developer.amazon.com/de/docs/smapi/skill-testing-operations.html" rel="nofollow">Skill Management API</a>
                  <ul>
                    <li><strong>Skill Simulation API</strong> handles plain text input (including intent resolution)</li>
                    <li><strong>Skill Invocation API</strong> handles structured input (intents and slots, no intent resolution done) and is therefore harder to use than the Simulation API</li>
                  </ul>
                </Text>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <Field
                  name="alexasmapi.skillId"
                  component={renderTextField}
                  label="Alexa Skill ID"
                  validate={required}
                  data-unique="txtAlexaSMAPIEditSkillId"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexasmapi.skillId']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['alexasmapi.skillId'])}
                />
                <Text helperText>See Alexa Developer Console</Text>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Field
                  name="alexasmapi.locale"
                  component={renderSelect}
                  label="Simulated Locale"
                  validate={required}
                  data-unique="selSMAPIEditLocale"
                  items={locales.map(l => {
                    return { key: l }
                  })}
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexasmapi.locale']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['alexasmapi.locale'])}
                />
                <Text helperText>Locale for the virtual device used in the simulation</Text>
              </GridItem>
              {values.alexasmapi.api === 'invocation' &&
                <GridItem xs={12} sm={4}>
                  <Field
                    name="alexasmapi.endpointRegion"
                    component={renderSelect}
                    label="Endpoint Region Code"
                    validate={required}
                    data-unique="selSMAPIEditEndpointRegion"
                    items={regions.map(r => {
                      return { key: r }
                    })}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexasmapi.endpointRegion']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['alexasmapi.endpointRegion'])}
                  />
                  <Text helperText>Region of endpoint to be called</Text>
                </GridItem>
              }
              {values.alexasmapi.api === 'simulation' &&
                <GridItem xs={12} sm={4}>
                  <Field
                    name="alexasmapi.simulationPhrase"
                    component={renderTextField}
                    label="Simulation Phrase"
                    data-unique="txtAlexaSMAPIEditSimulationPhrase"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexasmapi.simulationPhrase']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['alexasmapi.simulationPhrase'])}
                  />
                  <Text helperText>This phrase is prepended to each Alexa call (useful for NLU testing - it will call the skill with a specific intent)</Text>
                </GridItem>
              }
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="alexasmapi.refreshToken"
                  component={renderPasswordField}
                  label="AWS Refresh Token"
                  validate={required}
                  data-unique="pwAlexaSMAPIEditRefreshToken"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexasmapi.refreshToken']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['alexasmapi.refreshToken'])}
                />
                <Text helperText>
                  See the section <b>Connecting Amazon Alexa Skills API to Botium</b> in the <a href="https://github.com/codeforequity-at/botium-connector-alexa-smapi" target="_blank" rel="noopener noreferrer">Github Repository</a> of the connector for instructions.
                </Text>
              </GridItem>
            </GridContainer>
          </React.Fragment>)
        }}
      />
    )
  }
}

const AlexaSMAPIEdit = withRouter(connect(
  state => ({ user: state.token.user })
)(AlexaSMAPIEditUnconnected))
export { AlexaSMAPIEdit }
