import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Form } from 'react-final-form'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import {Query, Mutation, compose, graphql, withApollo} from 'react-apollo'
// core components
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import Field from 'components/Form/OptionalField'
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import {
  renderIntField,
  renderTextArea,
  renderTextField,
  renderPasswordField,
  renderCodeArea,
  renderCheckbox,
  minValue,
  parseInteger,
  required,
  json,
  prettyPrintJson,
  FormActionsToolbar,
} from 'components/Form/Form'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import ExpansionPanel from 'components/Expansion/ExpansionPanel.jsx'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary.jsx'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails.jsx'
import ShowIcon from 'components/Icon/ShowIcon'
import Text from 'components/Typography/Text'
import QueryStatus from 'components/Info/QueryStatus'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ListItem from 'components/List/ListItem/ListItem'
import Divider from 'components/Divider/Divider'

import ImportPrisma from './ImportPrisma'
import ConsumptionSettings from './ConsumptionSettings'
import config from 'config'
import { downloadfile } from 'helper/downloadHelper'
import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import {
  SYSTEMSETTINGS_QUERY,
  UPDATE_SYSTEMSETTINGS,
  SEND_TEST_EMAIL,
  EXIT_SERVER,
  FLUSH_CACHES,
  SYSTEMSETTINGSOPTIONS_QUERY,
  CHECKFEATURECONFIGURED_QUERY,
  EXECUTE_PARAPHRASER_POST_PROCESSING_QUERY
} from './gql'

class SystemSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showTestEmailDialog: false,
      showTestEmailError: null,
      openaiParaphraserPostProcessingTest: false
    }
  }

  renderFormTab(systemsettings, sectionExpanded, fnForm, fnButtons) {
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props

    return <Mutation
      mutation={UPDATE_SYSTEMSETTINGS}
      refetchQueries={[
        {
          query: SYSTEMSETTINGS_QUERY
        },
        {
          query: CHECKFEATURECONFIGURED_QUERY,
          variables: {
            feature: 'translation'
          }
        }
      ]}
    >
      {(mutateSystemSettings, { loading, error }) => (
        <Form
          onSubmit={async (values, form) => {
            try {
              await mutateSystemSettings({
                variables: {
                  systemSettings: {
                    keepTestCaseSuccessScreenshotsDays: values.keepTestCaseSuccessScreenshotsDays || null,
                    keepTestCaseSuccessConversationDays: values.keepTestCaseSuccessConversationDays || null,
                    keepTestCaseFailedScreenshotsDays: values.keepTestCaseFailedScreenshotsDays || null,
                    keepTestCaseFailedConversationDays: values.keepTestCaseFailedConversationDays || null,
                    keepTrainerSessionDetailsDays: values.keepTrainerSessionDetailsDays || null,
                    smtpServer: values.smtpServer || null,
                    smtpPort: values.smtpPort || null,
                    smtpUser: values.smtpUser || null,
                    smtpPassword: values.smtpPassword || null,
                    smtpSecure: !!values.smtpSecure,
                    smtpFrom: values.smtpFrom || null,
                    graphQLDebugging: values.graphQLDebugging,
                    quillbotApiKey: values.quillbotApiKey || null,
                    quillbotApiSettings: values.quillbotApiSettings || null,
                    openaiParaphraserApiKey: values.openaiParaphraserApiKey || null,
                    openaiParaphraserApiSettings: values.openaiParaphraserApiSettings || null,
                    openaiParaphraserLanguage: values.openaiParaphraserLanguage || null,
                    openaiParaphraserPostProcessing: values.openaiParaphraserPostProcessing || null,
                    openaiParaphraserPostProcessingTestUtterances: values.openaiParaphraserPostProcessingTestUtterances || null,
                    googleTranslateCredentials: values.googleTranslateCredentials || null
                  }
                },
              })
              setAlertSuccessMessage('System Settings updated')
            } catch (error) {
              setAlertErrorMessage('System Settings update failed', error)
            }
          }}
          initialValues={systemsettings}
          render={({
            handleSubmit,
            submitting,
            invalid,
            ...rest
          }) => (<form onSubmit={handleSubmit}>
            <UnsavedFormSpy />
            <GridContainer>
              <GridItem xs={12} lg={8}>
                {fnForm({ submitting, invalid, ...rest })}
              </GridItem>
              <GridItem xs={12} lg={8} largePadding>
                <FormActionsToolbar
                  leftButtons={<>
                  </>}
                  rightButtons={<>
                    {fnButtons && fnButtons()}
                    <Button
                      type="submit"
                      disabled={submitting || invalid}
                    >
                      {submitting && <LoadingIndicator alt />}
                      {!submitting && <ShowIcon icon="save" />}
                      Save Settings
                    </Button>
                  </>}
                />
              </GridItem>
            </GridContainer>
          </form>)
          }
        />)
      }
    </Mutation>
  }

  renderForm(systemsettings) {
    const { setAlertSuccessMessage, setAlertErrorMessage, license, token, client } = this.props
    const _renderEmailTemplateDownloadCard = (header, subheader, name, filetype) => <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={10}>
            <Text subheader>{subheader}</Text>
            <Text header>{header}</Text>
          </GridItem>
          <GridItem xs={2} middle noPadding>
            <Button justIcon onClick={() => {
              downloadfile(`${config.api.base}/emailtemplates/${name}/${filetype}`, token).catch(err => setAlertErrorMessage(err.message))
            }}><ShowIcon icon="download" /></Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>

    return (<CustomTabsSecondary
      name="tabSettingsSystem"
      headerColor="info"
      tabs={[
        {
          tabName: 'Cleanup Jobs',
          tabContent: this.renderFormTab(systemsettings, 'cleanup', () => <GridContainer nounset>
            <GridItem xs={12} sm={6}>
              <Field
                name="keepTestCaseSuccessScreenshotsDays"
                component={renderIntField}
                validate={minValue(0)}
                label="Keep screenshots of successful Test Cases  (in days)"
                parse={parseInteger}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="keepTestCaseFailedScreenshotsDays"
                component={renderIntField}
                validate={minValue(0)}
                label="Keep screenshots of failed Test Cases  (in days)"
                parse={parseInteger}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="keepTestCaseSuccessConversationDays"
                component={renderIntField}
                validate={minValue(0)}
                label="Keep details of successful Test Cases  (in days)"
                parse={parseInteger}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="keepTestCaseFailedConversationDays"
                component={renderIntField}
                validate={minValue(0)}
                label="Keep details of failed Test Cases  (in days)"
                parse={parseInteger}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="keepTrainerSessionDetailsDays"
                component={renderIntField}
                validate={minValue(0)}
                label="Keep NLP Analytics details  (in days)"
                parse={parseInteger}
              />
            </GridItem>
          </GridContainer>),
          locationPrefix: '/settings/system/cleanup',
          dataUnique: 'tabSettingsSystemCleanup'
        },
        {
          tabName: 'Email Settings',
           tabContent: this.renderFormTab(systemsettings, 'smtp', ({ values }) => <GridContainer>
            <GridItem xs={12} sm={6}>
              <Field
                name="smtpServer"
                component={renderTextField}
                label="SMTP Server Host"
                data-unique="txtSystemSettingsSmtpServerHost"
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Field
                name="smtpPort"
                component={renderIntField}
                label="SMTP Server Port"
                parse={parseInteger}
                data-unique="txtSystemSettingsSmtpServerPort"
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Field
                name="smtpSecure"
                component={renderCheckbox}
                label="Enable TLS/SSL"
                type="checkbox"
                data-unique="chkSystemSettingsSmtpSecure"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="smtpUser"
                component={renderTextField}
                label="SMTP Username"
                data-unique="txtSystemSettingsSmtpUser"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="smtpPassword"
                component={renderPasswordField}
                label="SMTP Password"
                data-unique="pwSystemSettingsSmtpPassword"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="smtpFrom"
                component={renderTextField}
                label="Sender Email Address"
                data-unique="txtSystemSettingsSmtpSenderEmail"
              />
            </GridItem>
            {this.renderTestEmailDialog(values)}
            <GridItem xs={12}>
              <ExpansionPanel expanded={this.state.emailTemplatesExpanded}>
                <ExpansionPanelSummary onClick={() => this.setState({ emailTemplatesExpanded: !this.state.emailTemplatesExpanded })}>
                  <Text header>Email Notification Templates</Text>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <GridContainer nounset>
                    <GridItem xs={12}>
                      <Text>
                        The notification templates can be customized by downloading a file below, and uploading the changed file to the <NavLink to="/settings/filebrowser?path=resources/">resources folder</NavLink>. Please make sure to keep the original filename.
                      </Text>
                      <Text>
                        The markup in the notification templates is <a href="https://pugjs.org/" target="_blank" rel="noopener noreferrer">PUG</a>.
                      </Text>
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS HTML', 'Welcome User', 'welcomeuser', 'html')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS TEXT', 'Welcome User', 'welcomeuser', 'text')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('SUBJECT', 'Welcome User', 'welcomeuser', 'subject')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS HTML', 'Password Reset', 'resetpassword', 'html')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS TEXT', 'Password Reset', 'resetpassword', 'text')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('SUBJECT', 'Password Reset', 'resetpassword', 'subject')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS HTML', 'Test Result', 'testresult', 'html')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS TEXT', 'Test Result', 'testresult', 'text')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('SUBJECT', 'Test Result', 'testresult', 'subject')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS HTML', 'Performance Test Result', 'performancetestresult', 'html')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS TEXT', 'Performance Test Result', 'performancetestresult', 'text')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('SUBJECT', 'Performance Test Result', 'performancetestresult', 'subject')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS HTML', 'Crawler Result', 'crawlerresult', 'html')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('AS TEXT', 'Crawler Result', 'crawlerresult', 'text')}
                    </GridItem>
                    <GridItem xs={4}>
                      {_renderEmailTemplateDownloadCard('SUBJECT', 'Crawler Result', 'crawlerresult', 'subject')}
                    </GridItem>
                  </GridContainer>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </GridItem>
          </GridContainer>, () => <Button
            onClick={() => this.setState({ showTestEmailDialog: true, showTestEmailError: null })}
            data-unique="btnSystemSettingsSmtpSendTestEmail"
            secondary
          >
            <ShowIcon icon="envelope" />
            Send Test Email
          </Button>),
          locationPrefix: '/settings/system/smtp',
          dataUnique: 'tabSettingsSystemSmtp'
        },
        {
          tabName: 'Data Augmentation and Paraphrasing',
          tabContent: this.renderFormTab(systemsettings, 'paraphrasing', ({ form, values }) => {
            const utterancesToPostProcessTest = (values.openaiParaphraserPostProcessingTestUtterances || '').split('\n').map(u => u.trim()).filter(u => u.length > 0)
            return <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="openaiParaphraserApiKey"
                  component={renderTextField}
                  label="OpenAI API Key"
                  data-unique="txtSystemSettingsOpenaiParaphraserApiKey"
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  className="CapabilitiesShort"
                  name="openaiParaphraserApiSettings"
                  component={renderCodeArea}
                  options={{mode: 'application/json'}}
                  label="OpenAI Request Configuration (as JSON)"
                  codeFormat={prettyPrintJson}
                  validate={json}
                  data-unique="codeSystemSettingsOpenaiParaphraserApiSettings"
                />
              </GridItem>
              <GridItem xs={12} largeMarginBottom>
                <Divider />
                <Text header>OpenAI Command</Text>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="openaiParaphraserPostProcessing"
                  component={renderTextField}
                  label="Command to post-process the paraphrased utterances"
                  helperText={<Text helperText>OpenAI command to clean the paraphrased utterances, for example '<Text inline italic>Remove utterances shorter than 8 characters.</Text>' or '<Text inline italic>Make neutral utterances polite.</Text>'</Text>}
                  data-unique="txtSystemSettingsOpenaiParaphraserPostProcessing"
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="openaiParaphraserPostProcessingTestUtterances"
                  component={renderTextArea}
                  label="Utterances to test the post-processing command"
                  helperText={<Text helperText>Utterances to try out the OpenAi command. Has no impact on paraphrase.</Text>}
                  data-unique="txtSystemSettingsOpenaiParaphraserPostProcessingTestUtterances"
                  rows={5}
                />
              </GridItem>
              <GridItem xs={12}>
                {!this.state.openaiParaphraserPostProcessingTest && <Button
                  disabled={!values.openaiParaphraserPostProcessing || utterancesToPostProcessTest.length === 0}
                  onClick={async () => {
                    this.setState({ openaiParaphraserPostProcessingTest: true })
                    try {
                      const { data } = await client.query({
                        query: EXECUTE_PARAPHRASER_POST_PROCESSING_QUERY,
                        variables: {
                          utterances: utterancesToPostProcessTest,
                          openaiCommand: values.openaiParaphraserPostProcessing
                        }
                      })
                      this.setState({openaiParaphraserPostProcessingTest: false})
                      form.change('openaiParaphraserPostProcessingTestResult', data.executeparaphraserpostprocessing.join('\n'))
                    } catch (error) {
                      this.setState({openaiParaphraserPostProcessingTest: false})
                      setAlertErrorMessage('Testing Post Processing failed', error)
                    }
                  }}
                  small
                  minWidth
                  data-unique="btnSystemSettingsOpenaiParaphraserPostProcessingTest"
                >
                  Test
                </Button>}
                {this.state.openaiParaphraserPostProcessingTest && <LoadingIndicator alt />}

              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="openaiParaphraserPostProcessingTestResult"
                  component={renderTextArea}
                  label="Test result"
                  data-unique="txtSystemSettingsOpenaiParaphraserPostProcessingTestResult"
                  disabled
                  rows={5}
                />
              </GridItem>
              <GridItem xs={12}>
              </GridItem>
            </GridContainer>
          }),
          locationPrefix: '/settings/system/paraphrasing',
          dataUnique: 'tabSettingsSystemParaphrasing'
      },
      {
          tabName: 'Translation Services',
          tabContent: this.renderFormTab(systemsettings, 'translate', () => <GridContainer>
            <GridItem xs={12}>
              <Field
                name="googleTranslateCredentials"
                component={renderCodeArea}
                options={{ mode: 'application/json' }}
                label="Google Translate Service Account Key (as JSON)"
                codeFormat={prettyPrintJson}
                validate={json}
                data-unique="codeGoogleTranslateCredentials"
              />
            </GridItem>
          </GridContainer>),
          locationPrefix: '/settings/system/translate',
          dataUnique: 'tabSettingsSystemTranslate'
        },
        !license.shared && {
          tabName: 'Debugging',
          tabContent: this.renderFormTab(systemsettings, 'debugging', () => <GridContainer>
            <GridItem xs={12}>
              <Field
                name="graphQLDebugging"
                component={renderCheckbox}
                label="Enable GraphQL Debugging (shown in Botium server logs)"
                type="checkbox"
                data-unique="chkSystemSettingsGraphQLDebugging"
              />
            </GridItem>
          </GridContainer>),
          locationPrefix: '/settings/system/debugging',
          dataUnique: 'tabSettingsSystemDebugging'
        },
        {
          tabName: 'Botium Import',
          tabIcon: <ShowIcon icon="file-import" />,
          tabContent: <GridContainer>
            <GridItem xs={12} lg={8}>
              <ImportPrisma />
            </GridItem>
          </GridContainer>,
          locationPrefix: '/settings/system/import',
          dataUnique: 'tabSettingsSystemImport'
        },
        {
          tabName: 'Consumption',
          tabContent: <GridContainer>
            <GridItem xs={12} lg={8}>
              <ConsumptionSettings />
            </GridItem>
          </GridContainer>,
          locationPrefix: '/settings/system/consumption',
          dataUnique: 'tabSettingsSystemConsumption'
        },
        {
          tabName: 'Danger Zone',
          tabIcon: <ShowIcon icon="exclamation-triangle" />,
          tabContent:
            <GridContainer key="danger">
              <React.Fragment>
                <GridItem md={8} lg={4}>
                  <ListItem>
                    <Text lg danger padding><ShowIcon icon="trash" /></Text>

                    <GridContainer>
                      <GridItem md={12}><Text bold>Flush Botium Caches </Text></GridItem>
                      <GridItem md={12}><Text>Refreshes Charts, Device Lists and More</Text></GridItem>
                    </GridContainer>
                    <Mutation
                      mutation={FLUSH_CACHES}
                      onCompleted={data => {
                        setAlertSuccessMessage('Botium Caches flushed.')
                      }}
                      onError={error => {
                        setAlertErrorMessage('Botium Caches flushing failed', error)
                      }}
                    >
                      {(flushCaches, { loading, error }) => (
                        <Button
                          onClick={() => {
                            flushCaches()
                          }}
                          danger
                          small
                          minWidth
                          data-unique="btnSystemSettingsFlushCaches"
                        >
                          Flush
                        </Button>
                      )}
                    </Mutation>
                  </ListItem>
                </GridItem>
                <GridItem md={8}></GridItem>
              </React.Fragment>
              {!license.shared &&
                <React.Fragment>
                  <GridItem md={8} lg={4}><Divider dense /></GridItem>
                  <GridItem md={12} lg={8}></GridItem>
                </React.Fragment>
              }
              {!license.shared &&
                <React.Fragment>
                  <GridItem md={8} lg={4}>
                    <ListItem>
                      <Text lg danger padding><ShowIcon icon="trash" /></Text>

                      <GridContainer>
                        <GridItem md={12}><Text bold>Restart Botium Server </Text></GridItem>
                        <GridItem md={12}><Text>Restarts the Botium Server processes</Text></GridItem>
                      </GridContainer>
                      <Mutation
                        mutation={EXIT_SERVER}
                        onCompleted={data => {
                          setAlertSuccessMessage('Botium Server is restarting.')
                        }}
                        onError={error => {
                          setAlertErrorMessage('Botium Server restarting failed', error)
                        }}
                      >
                        {(exitServer, { loading, error }) => (
                          <ConfirmationButton
                            confirmationText="Are you sure you want to restart the Botium server ?"
                            requireCheck={true}
                            danger
                            small
                            minWidth
                            onClick={() => {
                              exitServer()
                            }}
                            data-unique="btnSystemSettingsRestartBox"
                          >
                            Restart
                          </ConfirmationButton>
                        )}
                      </Mutation>
                    </ListItem>
                  </GridItem>
                  <GridItem xs={8}></GridItem>
                </React.Fragment>}
            </GridContainer>,
          locationPrefix: '/settings/system/danger',
          dataUnique: 'tabSettingsSystemDanger'
        }
      ].filter(t => t)}
    />)
  }

  renderTestEmailDialog(sysValues) {
    const { setAlertSuccessMessage } = this.props

    return (
      <Mutation
        mutation={SEND_TEST_EMAIL}
        onCompleted={data => {
          this.setState({ showTestEmailDialog: false, showTestEmailError: null })
          setAlertSuccessMessage('Test email sent, watch your Inbox')
        }}
        onError={error => {
          this.setState({ showTestEmailError: error })
        }}
      >
        {(sendTestEmail, { loading, error }) => (
          <Form
            onSubmit={values => {
              sendTestEmail({
                variables: {
                  receiver: values.receiver,
                  systemSettings: {
                    smtpServer: sysValues.smtpServer || null,
                    smtpPort: sysValues.smtpPort || null,
                    smtpUser: sysValues.smtpUser || null,
                    smtpPassword: sysValues.smtpPassword || null,
                    smtpSecure: !!sysValues.smtpSecure,
                    smtpFrom: sysValues.smtpFrom || null
                  }
                }
              })
            }}
            render={({ handleSubmit }) => (
              <ConfirmationDialog
                open={this.state.showTestEmailDialog}
                isWorking={loading}
                onCancel={() => this.setState({ showTestEmailDialog: false })}
                onOk={() => handleSubmit()}
                showError={this.state.showTestEmailError || null}
                clearError={() => this.setState({ showTestEmailError: null })}
                title="Send Test Email"
              >
                <form onSubmit={handleSubmit}>
                  <UnsavedFormSpy />
                  <GridContainer>
                    <GridItem xs={12}>
                      Make sure to save any settings before sending a test email!
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name="receiver"
                        component={renderTextField}
                        label="Receiver Email Address"
                        validate={required}
                        data-unique="txtSystemSettingsSmtpReceiverEmail"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Query query={SYSTEMSETTINGS_QUERY}>
            {(queryResult) => <QueryStatus {...queryResult} query="systemsettings" card>{(data) => this.renderForm(data.systemsettings)}</QueryStatus>}
          </Query>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(settingsStyle),
  connect(
    state => ({ token: state.token.token, user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  withApollo,
// it would be more elegant to use some general purpose query, like FEATURES_QUERY
  graphql(SYSTEMSETTINGSOPTIONS_QUERY, {
    props: ({ data }) => ({
      systemSettingsOptionsData: data,
    }),
  }),
  graphql(CHECKFEATURECONFIGURED_QUERY, {
    props: ({ data }) => ({
      featureData: data,
    }),
    options: (props) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          feature: 'translation'
        }
      }
    }
  })
)(SystemSettings)
