import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { recordAudioSupported, recordAudio } from 'helper/browserHelper'
import { extractErrorMessage} from 'helper/graphHelper'
// apollo
import {Mutation, compose, graphql} from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import MediaSelectionDialog from 'components/Dialog/MediaSelectionDialog.jsx'
import AudioButton from 'components/Button/AudioButton'
import Chip from 'components/Chip/Chip'
import Avatar from '@material-ui/core/Avatar'
import { ClientFeatureMessageBox } from 'views/Settings/ClientFeatureSection'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Text from 'components/Typography/Text'
import Divider from 'components/Divider/Divider'


import {
  renderTextField,
  renderTextArea,
  renderCustom,
  composeValidators,
  required,
  minLength,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import ShowIcon from 'components/Icon/ShowIcon'
import SwitchButton from 'components/Button/SwitchButton.jsx'


import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TESTSET_QUERY,
  SPEECH_JOBS,
  SPEECH_START_TTS_JOB,
  SPEECH_START_EFFECT_JOB
} from '../TestSets/gql'
import { FILESYSTEM_UPLOAD_CONTENT } from '../Settings/gql'
import { SPEECH_RUN_EFFECT } from '../Chatbots/gql'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'

import { SpeechEffectsSelectionPanel, SpeechTTSSelectionPanel, SpeechJobsTable } from './VoiceHelperComponents'
import Tooltip from 'components/Tooltip/Tooltip'

class VoiceWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'left',
      recordingSecond: 0,
    }
  }


  async initRecorder() {
		this.recorder = await recordAudio()
	}


  renderLiveVoiceRecorder(testSet) {

    const { setAlertSuccessMessage, setAlertErrorMessage, classes} = this.props

    const { mutateSpeechRunConversion } = this.props

    return <Mutation
      mutation={FILESYSTEM_UPLOAD_CONTENT}
    >
      {(uploadContent) => (
        <Form
          onSubmit={async (values, form) => {
            try {
              await uploadContent({
                variables: {
                  path: [...testSet.mediaBaseDir.split('/'), ...(values.uploadDirectory ? values.uploadDirectory.split('/') : [])],
                  filename: values.filename,
                  filecontent: values.filecontent,
                  unzip: false
                }
              })
              setAlertSuccessMessage('Audio Recording uploaded')
              form.initialize({ uploadDirectory: values.uploadDirectory, profile: values.profile, effects: values.effects })
            } catch (err) {
              setAlertErrorMessage('Upload Audio Recording failed', err)
            }
          }}
          initialValues={{ uploadDirectory: '/' }}
          render={({
            handleSubmit,
            form,
            submitting,
            values
          }) => {
            console.log(values)
            return <form onSubmit={handleSubmit}>
              <GridContainer paddingTop>
                <GridItem xs={12} textCenter largeMarginTop largeMarginBottom>
                  <Text bottomMargin bold header>Create Live Voice File</Text>
                  {this.state.isRecording &&
                    <Text bottomMargin bold header>
                      {`${Math.trunc(this.state.recordingSecond / 60).toString().padStart(2, '0')}:${(this.state.recordingSecond % 60).toString().padStart(2, '0')}`}
                    </Text>
                  }
                  {values.filecontent &&
                    <GridItem xs={12} largeMarginBottom>
                      <AudioButton
                        aria-label="Play Audio File"
                        data-unique="btnRecordPlay"
                        justIcon
                        audioBase64={values.filecontent}
                        mimeType="audio/wav"
                        className={classes.audioPlayButton}
                      />
                      <Chip
                        avatar={<Avatar>&nbsp;</Avatar>}
                        key={1}
                        data-unique={`chip_${1}`}
                        tabIndex={-1}
                        label={'Recorded Audio File'}
                        onDelete={() => form.change('filecontent', null)}
                        className={classes.audioChip}
                      />
                    </GridItem>
                  }
                  <Button
                    disabled={!recordAudioSupported()}
                    onClick={async () => {
                      if (!this.state.isRecording) {
                        this.setState({
                          isRecording: true,
                          recordingSecond: 0
                        })

                        this.recordingInterval = setInterval(() => {
                          this.setState({ recordingSecond: this.state.recordingSecond + 1 })
                        }, 1000)
                        await this.initRecorder()
                        this.recorder.start()
                        form.change('filecontent', '')
                      } else {
                        const data = await this.recorder.stop()
                        clearInterval(this.recordingInterval)
                        try {
                          const response = await mutateSpeechRunConversion({
                            variables: {
                              convData: {
                                base64: data.base64,
                                mimeType: data.mimeType,
                                profileId: values.effectsProfile || null,
                                effects: ['WEBMTOMONOWAV', ...(values.effects || [])]
                              }
                            }
                          })
                          form.change('filecontent', 'data:audio/wav;base64,' + response.data.speechRunEffect)
                        } catch (err) {
                          setAlertErrorMessage(`Audio Recording failed: ${extractErrorMessage(err)}`)
                        }
                        this.setState({
                          isRecording: false
                        })
                      }
                    }}
                    title={'Record Audio'}
                    data-unique="btnLiveChatRecordAudio"
                  >
                    {!this.state.isRecording && <><ShowIcon icon="microphone" /> RECORD</>}
                    {this.state.isRecording && <><ShowIcon icon="stop" /> STOP</>}
                  </Button>
                </GridItem>
                <GridItem xs={12}>
                  <Divider orientation="horizontal"  dividerlgnone />
                  <SpeechEffectsSelectionPanel form={form} values={values} />
                  <Divider orientation="horizontal"  dividerlgnone />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="filename"
                    component={renderTextField}
                    validate={required}
                    label="Audio File Name"
                    placeholder="Audio File Name"
                    data-unique="txtRecordName"
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="uploadDirectory"
                    component={renderTextField}
                    validate={required}
                    label="Upload Folder for Audio Recordings"
                    data-unique="txtUploadDirectory"
                    helperText="If no output folder is given, the new files are placed in the same folder as the input file"
                    readOnly
                    endAdornment={<>
                    <Tooltip title="Clear">
                      <Button aria-label="clear" justIcon dense data-unique="btnTestSetOpenFolderClear" onClick={() => form.change('uploadDirectory', '')}>
                        <ShowIcon icon="times" />
                      </Button>
                      </Tooltip>
                      <Tooltip title="Folder">
                      <Button aria-label="Folder" justIcon dense data-unique="btnTestSetOpenFolderSelectionDialog" onClick={() => this.setState({ showLiveRecorderMediaDirectoryDialog: true })}>
                        <ShowIcon icon="folder" />
                      </Button>
                      </Tooltip>
                    </>}
                  />
                  <MediaSelectionDialog allowFolderSelection
                    initialPath={testSet.mediaBaseDir.split('/')}
                    restrictPath
                    open={!!this.state.showLiveRecorderMediaDirectoryDialog}
                    onCancel={() => this.setState({ showLiveRecorderMediaDirectoryDialog: false })}
                    onOk={({ selectedFolders }) => {
                      this.setState({ showLiveRecorderMediaDirectoryDialog: false })
                      const selectedPathJoined = selectedFolders[0].slice(testSet.mediaBaseDir.split('/').length).join('/') + '/'
                      form.change('uploadDirectory', selectedPathJoined)
                      this.setState({ showLiveRecorderMediaDirectoryDialog: false })
                    }}
                    title="Select Folder"
                  />
                </GridItem>
                <GridItem xs={12} sm={6}></GridItem>
                <GridItem xs={12} right marginTop>
                  <Button
                      disabled={!values.filecontent || !values.filename || submitting}
                      data-unique="btnRecordSave"
                      onClick={async () => {
                        if (values.filename && !values.filename.endsWith('.wav')) {
                          form.change('filename', `${values.filename}.wav`)
                        }
                        const parts = values.filecontent.split(',')
                        if (parts.length > 1) {
                          form.change('filecontent', parts[1])
                        }
                        await handleSubmit()
                      }}>
                      {submitting && <LoadingIndicator alt />}
                      {!submitting && <ShowIcon icon="save" />}
                      Save
                    </Button>
                </GridItem>
              </GridContainer>
            </form>}
          }
        />)}
    </Mutation>
  }

  renderVoiceSynthesis(testSet){

    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props

    return <>
    <Mutation
      mutation={SPEECH_START_TTS_JOB}
      onCompleted={data => {
        setAlertSuccessMessage(`Voice Synthesis for ${data.speechStartTTSJob.length} voice(s) started.`)
      }}
      onError={error => {
        setAlertErrorMessage(`Voice Synthesis failed`, error)
      }}
      refetchQueries={[
        {
          query: SPEECH_JOBS,
          variables: { jobType: 'TTS', testSetId: testSet.id, ids: null }
        }
      ]}
    >
      {(startTTSJob, {loading, error}) => (
        <Form
          onSubmit={values => {
            startTTSJob({
              variables: {
                job: {
                  testSetId: testSet.id,
                  text: values.text,
                  profileId: values.profile || null,
                  language: values.language || null,
                  voices: values.voices || [],
                  effectsProfileId: values.effectsProfile || null,
                  effects: values.effects || null,
                  outputNamePrefix: values.outputNamePrefix,
                  outputPath: [ ...testSet.mediaBaseDir.split('/'), ...(values.outputPath ? values.outputPath.split('/') : [])]
                }
              }
            })
          }}
          render={({
            handleSubmit,
            submitting,
            values,
            form
          }) => (
            <form onSubmit={handleSubmit}>
              <GridContainer paddingTop>
                <GridItem xs={12} textCenter largeMarginTop largeMarginBottom>
                  <Text bold header>Create Voice File From Text</Text>
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="text"
                    component={renderTextArea}
                    label="Enter text to synthesize voice for"
                    rows={3}
                    data-unique="txtTestSetVoiceSynthesisText"
                    helperText="Enter multiple lines to synthesize multiple audio files (one by line)"
                    validate={composeValidators(minLength(5), required)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SpeechTTSSelectionPanel form={form} values={values} />
                </GridItem>
                <GridItem xs={12}>
                  <Divider orientation="horizontal"  dividerlgnone />
                  <SpeechEffectsSelectionPanel form={form} values={values} />
                  <Divider orientation="horizontal"  dividerlgnone />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="outputNamePrefix"
                    component={renderTextField}
                    label="Output File Prefix"
                    helperText="File name is composed of this prefix and the selected voice. If empty, the text is used as prefix."
                    data-unique="txtTestSetVoiceSynthesisOutputNamePrefix"
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="outputPath"
                    component={renderTextField}
                    label="Output Folder"
                    data-unique="txtTestSetVoiceSynthesisOutputFolder"
                    helperText={'If no output folder is given, the new files are placed in the same folder as the input file'}
                    readOnly
                    endAdornment={<>
                      <Button justIcon dense data-unique="btnTestSetVoiceSynthesisOutputFolderClear" onClick={() => form.change('outputPath', '')}>
                        <ShowIcon icon="times" />
                      </Button>
                      <Button justIcon dense data-unique="btnTestSetVoiceSynthesisOutputFolderSelectionDialog" onClick={() => this.setState({ showVoiceSynthesisOutputFolderDialog: true  })}>
                        <ShowIcon icon="folder" />
                      </Button>
                    </>}
                  />
                  <MediaSelectionDialog allowFolderSelection
                    restrictPath
                    initialPath={testSet.mediaBaseDir.split('/')}
                    open={!!this.state.showVoiceSynthesisOutputFolderDialog}
                    onCancel={() => this.setState({ showVoiceSynthesisOutputFolderDialog: false })}
                    onOk={({ selectedFolders }) => {
                      const selectedPathJoined = selectedFolders[0].slice(testSet.mediaBaseDir.split('/').length).join('/') + '/'
                      form.change('outputPath', selectedPathJoined)
                      console.log('outputPath', selectedPathJoined.length)
                      this.setState({ showVoiceSynthesisOutputFolderDialog: false })
                    }}
                    title="Select Output Folder"
                  />
                </GridItem>
                <GridItem xs={12} largePadding>
                  <FormActionsToolbar
                    rightButtons={<>
                      <Button
                        type="submit"
                        disabled={loading || submitting}
                        data-unique="btnTestSetVoiceSynthesisStart"
                      >
                        {submitting && <LoadingIndicator alt />}
                        {!submitting && <ShowIcon icon="magic" />}
                        Voice Synthesis
                      </Button>
                    </>}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )}
        />
      )}
    </Mutation>
  </>
  }

  renderVoiceEffect(testSet){

    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props

    return <>
    <Mutation
      mutation={SPEECH_START_EFFECT_JOB}
      onCompleted={data => {
        setAlertSuccessMessage(`Applying Voice Effects for ${data.speechStartEffectJob.length} file(s) started.`)
      }}
      onError={error => {
        setAlertErrorMessage(`Apply Voice Effects failed`, error)
      }}
      refetchQueries={[
        {
          query: SPEECH_JOBS,
          variables: { jobType: 'EFFECT', testSetId: testSet.id, ids: null }
        }
      ]}
    >
      {(startEffectJob, {loading, error}) => (
        <Form
          onSubmit={values => {
            startEffectJob({
              variables: {
                job: {
                  testSetId: testSet.id,
                  profileId: values.effectsProfile || null,
                  effects: values.effects || null,
                  files: values.files,
                  outputNamePrefix: values.outputNamePrefix,
                  outputPath: values.outputPath && values.outputPath.split('/')
                }
              }
            })
          }}
          render={({
            handleSubmit,
            submitting,
            values,
            form
          }) => {
            return <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12} textCenter largeMarginTop largeMarginBottom>
                  <Field
                    name="files"
                    component={renderCustom}
                    validate={required}
                    data-unique="txtTestSetVoiceEffectFiles"
                  >
                    <GridContainer>
                      <GridItem xs={12}>
                        <Text bottomMargin bold header>Select File(s) / Folder(s)</Text>
                        <GridItem xs={12} largeMarginBottom >
                          {values.files && values.files.map((f, index) => <>
                            <Chip
                              key={index}
                              data-unique={`chip_${index}`}
                              tabIndex={-1}
                              label={f}
                              onDelete={() => form.change('files', values.files.filter((v, i) => i !== index))}
                            />
                            </>
                          )}
                        </GridItem>
                        <Button data-unique="btnShowTestSetVoiceEffectMediaFileDialog" onClick={() => this.setState({ showTestSetVoiceEffectMediaFileDialog: true })}>
                          <ShowIcon icon="paperclip" /> Selection
                        </Button>
                      </GridItem>
                      <GridItem xs={12}>

                      </GridItem>
                    </GridContainer>
                  </Field>
                  <Divider orientation="horizontal"  dividerlgnone />
                  <MediaSelectionDialog multiple allowFileSelection allowFolderSelection restrictPath
                    initialPath={testSet.mediaBaseDir}
                    open={!!this.state.showTestSetVoiceEffectMediaFileDialog}
                    onCancel={() => this.setState({ showTestSetVoiceEffectMediaFileDialog: false })}
                    onOk={async ({ selectedFiles, selectedFolders }) => {
                      const files = values.files || []
                      if (selectedFiles && selectedFiles.length > 0) {
                        selectedFiles.forEach(m => files.push(m.join('/')))
                      }
                      if (selectedFolders && selectedFolders.length > 0) {
                        selectedFolders.forEach(m => files.push(m.join('/')))
                      }
                      form.change('files', _.uniq(files))
                      this.setState({ showTestSetVoiceEffectMediaFileDialog: false })
                    }}
                    title="Voice Wizard File/Folder Selection"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SpeechEffectsSelectionPanel form={form} values={values} selectEffectsRequired />
                  <Divider orientation="horizontal"  dividerlgnone />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="outputNamePrefix"
                    component={renderTextField}
                    label="Output File Prefix"
                    helperText="File name is composed of this prefix and the original file name. If empty, the effect is appended to the original file name."
                    data-unique="txtTestSetVoiceEffectOutputNamePrefix"
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="outputPath"
                    component={renderTextField}
                    label="Output Folder"
                    data-unique="txtTestSetVoiceEffectOutputFolder"
                    helperText="If no output folder is given, the new files are placed in the same folder as the input file"
                    readOnly
                    endAdornment={<>
                      <Button justIcon dense data-unique="btnTestSetVoiceEffectOutputFolderClear" onClick={() => form.change('outputPath', '')}>
                        <ShowIcon icon="times" />
                      </Button>
                      <Button justIcon dense data-unique="btnTestSetVoiceEffectOutputFolderSelectionDialog" onClick={() => this.setState({ showVoiceEffectOutputFolderDialog: true  })}>
                        <ShowIcon icon="folder" />
                      </Button>
                    </>}
                  />
                  <MediaSelectionDialog allowFolderSelection
                    initialPath={testSet.mediaBaseDir.split('/')}
                    open={!!this.state.showVoiceEffectOutputFolderDialog}
                    onCancel={() => this.setState({ showVoiceEffectOutputFolderDialog: false })}
                    onOk={({ selectedFolders }) => {
                      form.change('outputPath', selectedFolders[0].join('/'))
                      this.setState({ showVoiceEffectOutputFolderDialog: false })
                    }}
                    title="Select Output Folder"
                  />
                </GridItem>
                <GridItem xs={12} largePadding>
                  <FormActionsToolbar
                    rightButtons={<>
                      <Button
                        type="submit"
                        disabled={loading || submitting}
                        data-unique="btnTestSetVoiceEffectStart"
                      >
                        {submitting && <LoadingIndicator alt />}
                        {!submitting && <ShowIcon icon="magic" />}
                        Apply
                      </Button>
                    </>}
                  />
                </GridItem>
              </GridContainer>
            </form>
          }}
        />
      )}
    </Mutation>
  </>
  }


  render() {
    const { testSetData} = this.props

    const testSet = testSetData && testSetData.testset

    return (<GridContainer>
      {testSet && !testSet.mediaBaseDir &&
        <GridItem xs={12}>
          <ClientFeatureMessageBox text="Please select the folder for attachment files before using the Voice Wizard" link={`/testsets/view/${testSet.id}/settings/media`} linkText="Test Set Configuration" />
        </GridItem>
      }
      {testSet && testSet.mediaBaseDir &&
        <GridItem xs={8}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} right>
                  <SwitchButton
                  leftLabel="Live Voice Recorder"
                  leftDataUnique="tabVoiceWizardLiveVoiceRecorder"
                  centerLabel="Upload / Select Audio"
                  centerDataUnique="tabVoiceWizardUploadSelectAudio"
                  rightLabel="Text to Voice"
                  rightDataUnique="tabVoiceWizardTextToVoice"
                  checked={this.state.activeTab}
                    onRightClick={() => {
                      this.setState({activeTab: 'right' })
                    }}
                    onCenterClick={() => {
                      this.setState({activeTab: 'center' })
                    }}
                    onLeftClick={() => {
                      this.setState({activeTab: 'left' })
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  {this.state.activeTab === 'left' && this.renderLiveVoiceRecorder(testSet) }
                  {this.state.activeTab === 'center' && this.renderVoiceEffect(testSet) }
                  {this.state.activeTab === 'right' && this.renderVoiceSynthesis(testSet) }
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Divider orientation="horizontal"  dividerlgnone />
                  <SpeechJobsTable name="tblTestSetSpeechJobs" />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      }
    </GridContainer>)
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, settings: state.settings, features: state.settings.features }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(TESTSET_QUERY, {
    options: (props) => ({
      variables: {
        id: props.testSetId || props.match.params.testSetId
      },
    }),
    props: ({ data }) => ({
      testSetData: data,
    }),
  }),
  graphql(SPEECH_RUN_EFFECT, {
    props: ({ mutate }) => ({
      mutateSpeechRunConversion: args => mutate(args)
    }),
  })
)(VoiceWizard)
