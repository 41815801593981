import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'
import {NavLink} from 'react-router-dom'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {UTTERANCES_NOT_MATCHED_INTENT_QUERY, TRAINER_SESSION_ROOT_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'
import {toFixedSafe} from './helper'
import DangerOrSuccessText from 'components/Typography/DangerOrSuccessText'
import ShowIcon from 'components/Icon/ShowIcon'
import Button from 'components/Button/Button'
import Text from 'components/Typography/Text'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import Utterance from './UtteranceSummary'
import LinkButton from 'components/Button/LinkButton'

const testsessionLabel = (testsession) => {
  if (testsession) {
    return <React.Fragment>{testsession.name}</React.Fragment>
  }
  return null
}

class ResolvedToWrongIntent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  renderTable() {
    const {utterancesData, match} = this.props
    let testSessionId = match.params.testSessionId

    let progressOrError = renderProgressOrError(utterancesData)
    if (progressOrError) {
      return progressOrError
    }

    const data = _.sortBy(utterancesData.trainerUtterances, ['intent.actual', 'intent.expected'])

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Utterance', 'Predicted Intent', 'Expected Intent', 'Prediction Confidence']}
        tableData={data.map(entry => {
          return [
            () => <LinkButton onClick={() => this.setState({ open: true, utterance: entry.utterance, testSessionId: testSessionId })} data-unique={`btnCoachIntentConfidenceUtterance_${encodeURIComponent(entry.utterance)}`}>{entry.utterance}</LinkButton>,
            () => <>{entry.intent.actual}</>,
            () => <>{entry.intent.expected}</>,
            () => <DangerOrSuccessText successful={entry.intent.expected.matches}>{toFixedSafe(entry.intent.confidenceReal)}</DangerOrSuccessText>
          ]
        })}
      />
    )
  }

  render() {
    const { match, testSessionData} = this.props

    const { testSessionId } = match.params
    return (<>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
            <GridContainer>
                <GridItem xs={12}>
                  <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}`} data-unique={`btnBack_${testSessionId}`}>
                    <Button justIcon Border >
                       <ShowIcon icon="arrow-left" />
                   </Button> <Text inline>BACK / Test Session 5: {testSessionData && testSessionData.testsession && testsessionLabel(testSessionData.testsession)}</Text>
                  </NavLink>
                </GridItem>
                <GridItem xs={12}>
                  {this.renderTable()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ConfirmationDialog
      maxWidth="lg"
      open={this.state.open}
      onOk={() => this.setState({ open: false })}
      title={`Utterance name: ${this.state.utterance}`}
    >
      <Utterance utterance={this.state.utterance} testSessionId={this.state.testSessionId} testProjectId={match.params.testProjectId} />
    </ConfirmationDialog>
    </>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(UTTERANCES_NOT_MATCHED_INTENT_QUERY, {
    props: ({data}) => ({
      utterancesData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          hasNotMatchedIntent: true
        },
      }
    }
  }),

  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      testSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.secondaryTestSessionId,
          id: props.match.params.testSessionId
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      secondaryTestSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.secondaryTestSessionId
        },
      }
    },
    skip: (props) => !props.match.params.secondaryTestSessionId
  })
)(ResolvedToWrongIntent)
