import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Card from 'components/Card/Card'
// @material-ui/icons

// core components
import cardStyle from 'assets/jss/material-dashboard-react/components/cardStyle.jsx'

class SelectableCard extends React.Component {
    render() {
        const { children, selected, classes, footer, noPadding, onClick, topBar, className, disabled, ...rest } = this.props
        return <Card tabIndex="0" className={classNames({ [disabled ? classes.selectableCardDisabled : classes.selectableCard]: true, [classes.selectableCardSelected]: selected, ...(className ? { [className]: true } : {})})} style={footer ? {
            paddingBottom: 15
        } : {}} {...rest}>
            <div className={classNames({
                [classes.selectableCheck]: true,
                [classes.selectableCheckSelected]: selected
            })}>
            </div>
            {topBar && <div className={classes.selectableCardTopBar}>
                {topBar}
            </div>}
            <div data-click="true" onClick={disabled ? null : onClick} className={classes.selectableCardContent} style={noPadding ? { paddingLeft: 0 } : {}}>
                {children}
            </div>
            {footer && <div className={classes.selectableCardFooter}>
                {footer}
            </div>}
        </Card>
    }
}

export default withStyles(cardStyle)(SelectableCard)
