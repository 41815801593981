import React from 'react'
import _ from 'lodash'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import { BrokenLinkError } from 'components/Page/ErrorPage'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Text from 'components/Typography/Text.jsx'

function QueryStatus({ children, card, plain, loading, error, data, query, queries, loadable, nullable, ...rest }) {
  const _status = (s) => {
    if (card) return <Card><CardBody><GridContainer><GridItem xs={12} middle>{s}</GridItem></GridContainer></CardBody></Card>
    else if (plain) return s
    else return <GridContainer><GridItem xs={12} middle>{s}</GridItem></GridContainer>
  }

  const _getError = () => {
    if (error) return error
    if (queries) {
      for (const q of queries) {
        if (q.error) return q.error
      }
    }
    return null
  }
  const _hasLoading = () => {
    if (loading) return true
    if (queries) {
      for (const q of queries) {
        if (q.loading) return true
      }
    }
    return false
  }
  const _getPayload = () => {
    const payload = {}
    if (query && !_.isArray(query)) {
      payload[query] = (data && data[query]) || rest[query] || null
      if (_.isNil(payload[query])) return null
    }
    if (queries) {
      for (const [i, q] of queries.entries()) {
        const qName = q.query || (_.isArray(query) && query[i])
        if (qName) {
          payload[qName] = (q.data && q.data[qName]) || q[qName] || null
          if (_.isNil(payload[qName])) return null
        }
      }
    }
    return payload
  }

  const errCombined = _getError()
  const loadingCombined = _hasLoading()
  const payloadCombined = _getPayload()

  if (errCombined) {
    return _status(<ErrorFormat err={errCombined} />)
  } else if (loadingCombined && !loadable) {
    return _status(<><LoadingIndicator />&nbsp;<Text bold>Loading...</Text></>)
  } else if (!loadingCombined && _.isNil(payloadCombined) && !nullable) {
    throw new BrokenLinkError('Broken link - Query result empty')
  } else {
    return _.isFunction(children) ? children({ ...(payloadCombined || {}), loading: loadingCombined, err: errCombined }) : children
  }
}

export default QueryStatus