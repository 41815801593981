import {
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
} from 'assets/jss/material-dashboard-react.jsx'

const tableStyle = theme => ({
  warningTableHeader: {
    color: warningColor,
  },
  primaryTableHeader: {
    color: theme.colors.primary,
  },
  dangerTableHeader: {
    color: dangerColor,
  },
  successTableHeader: {
    color: successColor,
  },
  infoTableHeader: {
    color: infoColor,
  },
  roseTableHeader: {
    color: roseColor,
  },
  grayTableHeader: {
    color: grayColor,
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    borderSpacing: '0 15px',
    borderCollapse: 'collapse',
    overflow: 'hidden',
    '& tr': {

    },
    '& tr:last-child td': {
      //borderBottom: `0px solid ${theme.colors.tableRowBorder}`
    },
    '& tr:first-child td': {
      borderTop: `1px solid ${theme.colors.tableRowBorder}`
    },
    '& td': {
      borderBottom: `1px solid ${theme.colors.tableRowBorder}`,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    '& tbody':{
      '& tr:hover':{
        background: theme.colors.tableRowHover,
      }
    }
  },
  tablePrint: {
    width: '1400px',
    '& tr:nth-child(odd) td': {
      backgroundColor: theme.colors.backgroundTableRow
    },
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    whiteSpace: 'nowrap',
    fontSize: '13px',
    padding: '4px 10px',
    '& svg': {
      verticalAlign: 'middle'
    },
    borderBottom: 'none'
  },
  tableCell: {
    paddingLeft: 15,
    paddingRight: 15,
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '&:last-child': {
      paddingRight: '10px!Important',
    },
    '& .primaryText':{
      position: 'relative',
      top: '-6px'
    }
  },
  tableCellRight: {
    textAlign: 'right'
  },
  nameColumn: {
    width: 400,
    maxWidth: 400
  },
  tableCellBody: {
    fontSize: 13,
    fontWeight: 400,
    color: theme.colors.primary,
    minHeight: 64,
    '& a': {
      color: theme.colors.primary,
      fontWeight: 'bold'
    },
    '& img':{
      maxWidth: '34px !Important',
      width: 34,
      height: 34,
    },
    '& container': {
      //minHeight: 66,
    }
  },
  tableCellBodyLink: {
    //marginTop: 20,
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.colors.primary,
    '&:hover': {
      color: theme.colors.primaryHover,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      borderRadius: 5
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.unit,
    overflowX: 'auto',
    paddingRight: 1
  },
  tablePaginationActions: {
    flexShrink: 0,
    color: theme.colors.primary,
    marginLeft: theme.spacing.unit * 2.5,
    paddingTop: 20,
  },
  tablePaginationToolbar: {
    alignItems: 'baseline'
  },
  captionPagination: {
    color: theme.colors.primary,
    paddingTop: 20,
  },
  selectPagination: {
    color: theme.colors.primary,
    fontWeight: '500',
    paddingLeft: 10,
    '&:hover,&:focus': {
      //background: 'none !Important'
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 6,
      padding: '5px'
    },
  },
  tablePaginationButton: {
    color: theme.colors.primary,
  },
  tablePaginationButtonDisabled: {
    color: `${theme.colors.primary} !important`,
    opacity: '0.65',
    pointerEvents: 'none',
  },
  selectIconPagination: {
    color: theme.colors.primary,
    paddingTop: 20,
    right: '-8px',

  },
  selectMenuPagination: {
    color: theme.colors.primary,
    paddingTop: 20,
  },
  tableToolbar: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  tableToolbarSpacer: {
    //flex: 1,
  },
  tableToolbarActions: {
    color: theme.colors.secondary,
    display: 'flex',
  },
  tableToolbarCustomActions: {
    marginRight: '12px',
    position: 'relative',
    top: 15
  },
  guttersNoPadding: {
    paddingLeft: 0,
  },
  tableToolbarActionsItem: {
    display: 'flex',
    '& div' : {
      marginLeft: '0px',
      marginRight: '12px',
      minWidth: 245,
      paddingLeft: 4
    },
    '& button' : {
      marginLeft: '0px',
      marginRight: '12px',
      border: `1px solid ${theme.colors.borderColor}`,
      borderRadius: 6,
      height: 45,
      width: 45,
      position: 'relative',
      top: '20px',
      '& svg':{
        width: 32,
        height: 21,
      }
    }
  },
  tableCellWidthLarge: {
    width: 'auto',
  },
  tableCellWidthMedium: {
    width: 516,
  },
  tableCellWidthSmall: {
    width: 300,
  },
  tableCellNoListitem: {
    height: 70,
  },
  projectTable: {
    borderCollapse: 'separate!Important',
    borderRadius: 6,
    height: 80,
    '& td':{
      borderTop: `1px solid ${theme.colors.tableRowBorder}`,
    },
    '& td:first-of-type':{
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
      borderLeft: `1px solid ${theme.colors.tableRowBorder}`
    },
    '& td:last-of-type':{
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      borderRight: `1px solid ${theme.colors.tableRowBorder}`
    }
  },
  latesResultseparation: {
    '& td:nth-child(3)':{
      borderRight: `1px solid ${theme.colors.tableRowBorder}`
    }
  },
  latesResultseparationNoCheckbox: {
    '& td:nth-child(2)':{
      borderRight: `1px solid ${theme.colors.tableRowBorder}`
    }
  },
  tableCellWidthSmallSecondary: {
    width: 200,
  },
  navLinkColor: {
    color: theme.colors.primary,
    '&:hover': {
      color: `${theme.colors.primary}!Important`,
      textDecoration: 'underline'
    }
  },
  navLinkColorOpacity: {
    opacity: .6
  },
  rowIndexShow: {
    display: 'inline-block',
    float: 'right',
    fontSize: 11
  },
  tableMatrixCellLable: {
    width: 250,
    padding: '0 10px',
  },
  intentNameCut: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 200,
    whiteSpace: 'nowrap',
    display: 'inline-block',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    }
  },
  incorrectLabelIcon: {
    width: 6,
    height: 6,
    display: 'inline-block',
    background: theme.colors.common.danger,
    borderRadius: '50%',
    marginRight: 1
  },
  correctLabelIcon: {
    width: 6,
    height: 6,
    display: 'inline-block',
    background: theme.colors.common.greenColor1,
    borderRadius: '50%',
    marginRight: 1
  },
  hitLabelIcon: {
    display: 'inline-block',
    background: theme.colors.common.secondary,
    borderRadius: '50%',
    maxWidth: 20,
    maxHeight: 20
  },
  hitLabelIconSmall: {
    width: 6,
    height: 6,
    display: 'inline-block',
    background: theme.colors.common.secondary,
    borderRadius: '50%',
  },
  UndecoratedLabelIcon: {
    width: 6,
    height: 6,
    display: 'inline-block',
    background: 'transparent',
    borderRadius: '50%',
    marginRight: 1
  },
  labelIconLight: {
    opacity: 0.6,
    width: 6,
    height: 6,
  },
  labelIconLighter: {
    opacity: 0.3,
    width: 6,
    height: 6,
  },
  labelIconLeftBottom: {
    position: 'absolute',
    bottom: '10%',
    left: '10%',
  },
  labelIconCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  labelIconRightBottom: {
    position: 'absolute',
    bottom: '10%',
    right: '10%',
  },
  activeCell: {
    border: `1px solid ${theme.colors.primary}!Important`
  },
  NALabel: {
    color: theme.colors.primary,
    display: 'inline-block'
  },
  tableMatrixEmpty: {
    minWidth: 40
  },
  viewClaster: {
    marginTop: 10,
    display: 'inline-block',
    '& button': {
      '& svg': {
        marginRight: '0!Important'
      }
    }
  },
  viewParent: {
    width: 'auto',
    height: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0,
    justifyContent: 'center',
    alignItems: 'stretch',
    float: 'left',
    position: 'relative',
    '& div': {
      marginTop: 0,
      '& table': {
        borderSpacing: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 1,
        '& tr': {
          height: '0',
          width: 'auto'
        },
        '& td': {
          background: 'none',
          padding: '0',
          '&:last-child': {
            paddingRight: '0!Important',
          },
        }
      }
    }
  },
  viewChildren: {
    border: `1px solid ${theme.colors.borderColor}`,
    position: 'relative',
    cursor: 'pointer'
  },
  viewRow: {
    float: 'left',
    display: 'grid',
    justifyContent: 'space-around',
    height: 320,
    marginTop: 3,
    '& div': {
      '& div': {
        transform: 'rotate(270deg)',
        height: 40,
        width: 40,
      },
      width: 25
    },
    '&.centerText':{
      height: 'auto'
    }
  },
  viewColumn: {
    Width: 235,
    marginLeft: 27,
    display: 'flex',
    justifyContent: 'space-between',
  },
  onlyBorder: {
    border: `1px solid ${theme.colors.borderColor}!Important`,
    '& a': {
      color: theme.colors.primary
    }
  },
  tableHeadCellMatrixLeft: {
    textAlign: 'left!Important',
    padding: '10px!Important'
  },
  htmlTooltip: {
    maxWidth: 300,
    fontSize: 14,
    '& b': {
      fontWeight: 'bold',
    }
  },
  tableMatrixScore: {
    padding: 0,
  },
  '@media (max-width: 1680px)': {
    nameColumn: {
      width: 300,
      maxWidth: 300
    },
    tableMatrixCellLable: {
      width: 160,
    },
    intentNameCut: {
      width: 125,
    },
  },
  '@media (max-width: 1536px)': {
    tableMatrixScore: {
      display: 'none'
    },
    nameColumn: {
      width: 300,
      maxWidth: 300
    },
    tableMatrixCellLable: {
      width: 200,
    },
    intentNameCut: {
      width: 125,
    },
    gridWidthLeft: {
        maxWidth: '35%',
        flexBasis: '35%'
    },
    gridWidthRight: {
      maxWidth: '65%',
      flexBasis: '65%'
  },
  },
  '@media (max-width: 1366px)': {
    nameColumn: {
      width: 240,
      maxWidth: 240
    },
    tableMatrixCellLable: {
      width: 180,
      fontSize: 11
    },
    intentNameCut: {
      width: 75,
    },
    tableCellWidthSmall: {
      width: 'auto',
    },
    tableCellWidthMedium: {
      width: 'auto',
    }
  },
  '@media (max-width: 1280px)': {
    nameColumn: {
      width: 250,
      maxWidth: 250
    }
  },
  '@media (max-width: 1180px)': {
    gridWidthLeft: {
      maxWidth: '100%',
      flexBasis: '100%'
  },
  gridWidthRight: {
    maxWidth: '100%',
    flexBasis: '100%'
},
  }

})

export default tableStyle
