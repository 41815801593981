import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, withApollo, compose } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Chip from 'components/Chip/Chip'
import {
  renderTextField,
  renderCheckbox,
  renderFileUpload,
  required,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { downloadfile } from 'helper/downloadHelper'
import { getShortenedArray } from './helper'

import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'

import config from 'config'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import { RefetchTestSetQueries, VALIDATEFILE_GETSTATS_QUERY } from './gql'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import { TESTSETEXCEL_QUERY, CREATE_TESTSETEXCEL, UPDATE_TESTSETEXCEL } from './gql'

class TestSetExcel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filecontent: null,
      validateResult: null
    }
    this.emptyExcel = {}
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  tryLoadFileContent(testSetId, filename, filecontent) {
    const { client } = this.props
    this.hasWritePermission() && filename && filecontent && client.query({
      query: VALIDATEFILE_GETSTATS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        testSetId,
        filename,
        filecontent
      }
    })
    .then(({ data }) => {
      this.setState({ validateResult: data.validatefilegetstats })
    })
    .catch(err => {
      this.setState({ validateResult: { err: err.message } })
    })
  }

  validate(testSetId, values) {
    const { client } = this.props
    return this.hasWritePermission() && values.filename && values.filecontent && client.query({
      query: VALIDATEFILE_GETSTATS_QUERY,
      variables: {
        testSetId: testSetId,
        filename: values.filename,
        filecontent: values.filecontent
      },
      fetchPolicy: 'no-cache'
    })
    .then(({ data }) => {
      if (!data.validatefilegetstats) {
        return { fileupload: 'Excel validation failed' }
      }
      if (data.validatefilegetstats.err) {
        return { fileupload: `${data.validatefilegetstats.err}` }
      }
      if (data.validatefilegetstats.convoCount === 0 && data.validatefilegetstats.partialConvoCount === 0 && data.validatefilegetstats.uttCount === 0 && data.validatefilegetstats.scriptingMemoryCount === 0) {
        return { fileupload: 'No Botium content identified.', filestructure: 'failed' }
      }
    })
    .catch(err => {
      return { fileupload: `${err.message}` }
    })
  }

  renderForm(testsetExcel, testSetId) {
    const { validateResult } = this.state
    const {
      token,
      setAlertSuccessMessage,
      setAlertErrorMessage,
      history,
      license
    } = this.props

    return (
      <Mutation
        mutation={testsetExcel.id ? UPDATE_TESTSETEXCEL : CREATE_TESTSETEXCEL}
        refetchQueries={[
          ...RefetchTestSetQueries(testSetId, license)
        ]}
      >
        {(mutateTestSetExcel, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (testsetExcel.id) {
                try {
                  const res = await mutateTestSetExcel({
                    variables: {
                      id: values.id,
                      testSetExcel: {
                        name: values.name,
                        filename: values.filename,
                        filecontent: values.filecontent,
                        skip: !!values.skip
                      },
                    },
                  })
                  form.initialize(res.data.updateTestSetExcel)
                  history.push(`/testsets/view/${testSetId}/testcases/viewexcel/${res.data.updateTestSetExcel.id}`)
                  setAlertSuccessMessage('Excel ready for use')
                } catch(error) {
                  setAlertErrorMessage(`Excel update failed`, error)
                }
              } else {
                try {
                  const res = await mutateTestSetExcel({
                    variables: {
                      testSetExcel: {
                        name: values.name,
                        filename: values.filename,
                        filecontent: values.filecontent,
                        skip: !!values.skip,
                        testSet: {
                          connect: {
                            id: testSetId,
                          },
                        },
                      },
                    },
                  })
                  form.initialize(res.data.createTestSetExcel)
                  history.push(`/testsets/view/${testSetId}/testcases/viewexcel/${res.data.createTestSetExcel.id}`)
                  setAlertSuccessMessage('Excel ready for use')
                } catch(error) {
                  setAlertErrorMessage(`Excel registration failed`, error)
                }
              }
            }}
            initialValues={testsetExcel}
            validate={this.validate.bind(this, testSetId)}
            render={({
              handleSubmit,
              submitting,
              invalid,
              validating,
              values,
              form: { change },
              errors
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Excel Name"
                      validate={required}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetExcelName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="skip"
                      component={renderCheckbox}
                      label="Ignore when running Test Sessions"
                      type="checkbox"
                      disabled={!this.hasWritePermission()}
                      data-unique="chkTestSetExcelSkip"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="filename"
                      component={renderTextField}
                      label="Filename"
                      disabled
                      validate={required}
                      data-unique="txtTestSetExcelFilename"
                    />
                  </GridItem>
                  {this.hasWritePermission() &&
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="fileupload"
                        component={renderFileUpload}
                        data-unique="fileTestSetExcelUpload"
                        accept=".xlsx,.xlsm"
                        label="Select/Drop new Botium Excel File"
                        values={values}
                        change={change}
                        validate={!testsetExcel.id && required}
                        onFileLoaded={(filename, filecontent) => {
                          change('filename', filename)
                          change('filecontent', filecontent)
                          if (!values.name) {
                            change('name', filename)
                          }
                          this.tryLoadFileContent(testSetId, filename, filecontent)
                        }}
                      />
                    </GridItem>
                  }
                  {validateResult && !validateResult.err &&
                    <GridItem xs={12}>
                      {validateResult.convoCount > 0 && <Chip variant="convo" label="Convos" badge={validateResult.convoCount} tooltip={`${validateResult.convoCount} Convos: ${getShortenedArray(validateResult.convoNames, 10).join(' | ')}`} />}
                      {validateResult.partialConvoCount > 0 && <Chip variant="convo" label="Partial Convos" badge={validateResult.convoCount} tooltip={`${validateResult.partialConvoCount} Partial Convos: ${getShortenedArray(validateResult.partialConvoNames, 10).join(' | ')}`} />}
                      {validateResult.uttCount > 0 && <Chip variant="utterance" label="Utterance Lists" badge={validateResult.uttCount} tooltip={`${validateResult.uttCount} Utterance Lists: ${getShortenedArray(validateResult.uttNames, 10).join(' | ')}`} />}
                      {validateResult.scriptingMemoryCount > 0 && <Chip variant="scripting" label="Test Parameter Stores" badge={validateResult.scriptingMemoryCount} tooltip={`${validateResult.scriptingMemoryCount} Test Parameter Stores: ${getShortenedArray(validateResult.scriptingMemoryNames, 10).join(' | ')}`} />}
                    </GridItem>
                  }
                  {((errors && errors.filestructure) || (validateResult && !validateResult.err && validateResult.convoCount === 0 && validateResult.partialConvoCount === 0 && validateResult.uttCount === 0 && validateResult.scriptingMemoryCount === 0)) &&
                    <GridItem xs={12}>
                      <Text danger>No Botium content identified. Please check the <NavLink to={`/testsets/view/${testSetId}/settings/excel`}>worksheet names and the table structure!</NavLink></Text>
                    </GridItem>
                  }
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtons={<>
                        {testsetExcel.id && (
                          <Button data-unique="btnTestSetExcelDownload" secondary
                            onClick={() => {
                              downloadfile(`${config.api.base}/uploads/excel/${testsetExcel.id}`, token).catch(err => setAlertErrorMessage(err.message))
                            }}>
                            <ShowIcon icon="cloud-download-alt" />
                            Download
                          </Button>
                        )}                      
                      </>}
                      rightButtons={<>
                        {this.hasWritePermission() &&
                          <Button
                            type="submit"
                            disabled={invalid || submitting || validating}
                            data-unique="btnTestSetExcelSave"
                          >
                            {submitting && <><LoadingIndicator alt /> Saving ...</>}
                            {!submitting && <><ShowIcon icon="save" /> Save</>}
                          </Button>
                        }
                      </>}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { match } = this.props
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {match.params &&
            match.params.id && (
              <Query
                query={TESTSETEXCEL_QUERY}
                variables={{ id: match.params.id }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <LoadingIndicator />
                  if (error) return <ErrorFormat err={error} />

                  return this.renderForm(
                    data.testsetexcel,
                    data.testsetexcel.testSet.id,
                  )
                }}
              </Query>
            )}
          {(!match.params || !match.params.id) &&
            this.renderForm(
              this.emptyExcel,
              match.params.testSetId,
            )}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ token: state.token.token, user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  withApollo
)(TestSetExcel)
