import React from 'react'
import PropTypes from 'prop-types'
import ChartistGraph from 'react-chartist'
import { withStyles } from '@material-ui/core'

const style = theme => ({
  chartContainer: {
    border: `1px solid ${theme.colors.borderColor}`,
    padding: 5
  }
})

function DonutChart({ ...props }) {
  const { data, size, options, onDraw, classes } = props

  const chartSize = size || 122

  const chartOptions = {
    fullWidth: true,
    donut: true,
    donutWidth: 15,
    donutSolid: true,
    showLabel: false,
    ...(options || {}),
  }

  const listener = {
    draw: context => {
      if (!context || !context.element) return
      if (context.type !== 'slice') return

      let style = ''
      if (data.stroke) {
        style += 'stroke: ' + data.stroke[context.index] + ';'
      }
      if (data.fill) {
        style += 'fill: ' + data.fill[context.index] + ';'
      }
      if (style) {
        context.element.attr({ style })
      }
      onDraw && onDraw(context)
    },
  }

  return (
    <div className={classes.chartContainer} style={{ width: chartSize + 5, height: chartSize + 5, borderRadius: chartSize + 5 }}>
    <ChartistGraph
      data={data}
      options={chartOptions}
      listener={listener}
      type="Pie"
    />
    </div>
  )
}

DonutChart.propTypes = {
  data: PropTypes.any,
  options: PropTypes.any,
  onDraw: PropTypes.func,
}

export default withStyles(style)(DonutChart)
