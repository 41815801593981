import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { FieldArray } from 'react-final-form-arrays'
import { OnChange } from 'react-final-form-listeners'
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/Button/Button'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Tooltip from 'components/Tooltip/Tooltip'
import ShowIcon from 'components/Icon/ShowIcon'
import { getConnector } from 'actions/settings'

import {
  renderTextField,
  renderCheckbox,
  renderFileUpload,
  renderPasswordField,
  renderSelect,
  renderIntField,
  renderCodeArea,
  parseInteger,
  required,
  isRsaKey,
  json,
  prettyPrintJson,
  composeValidators,
} from 'components/Form/Form'
import { extractErrorMessage } from 'helper/graphHelper'
import { RUNCONNECTORQUERY_QUERY } from './ConnectorEdit'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function dialogFlowCaps2Form(caps) {
  const result = {
    dialogFlow: {
      projectId: (
        caps.find(c => c.name === 'DIALOGFLOW_PROJECT_ID') || {
          stringValue: '',
        }
      ).stringValue,
      environment: (
        caps.find(c => c.name === 'DIALOGFLOW_ENVIRONMENT') || {
          stringValue: '',
        }
      ).stringValue,
      clientEmail: (
        caps.find(c => c.name === 'DIALOGFLOW_CLIENT_EMAIL') || {
          stringValue: '',
        }
      ).stringValue,
      privateKey: (
        caps.find(c => c.name === 'DIALOGFLOW_PRIVATE_KEY') || {
          stringValue: '',
        }
      ).stringValue,
      languageCode: (
        caps.find(c => c.name === 'DIALOGFLOW_LANGUAGE_CODE') || {
          stringValue: 'en',
        }
      ).stringValue,
      queryParams: (
        caps.find(c => c.name === 'DIALOGFLOW_QUERY_PARAMS') || {
          stringValue: '',
        }
      ).stringValue,
      apiEndpoint: (
        caps.find(c => c.name === 'DIALOGFLOW_API_ENDPOINT') || {
          stringValue: '',
        }
      ).stringValue,
      outputPlatform: (
        caps.find(c => c.name === 'DIALOGFLOW_OUTPUT_PLATFORM') || {
          stringValue: '',
        }
      ).stringValue,
      buttonAsEvent: (
        caps.find(c => c.name === 'DIALOGFLOW_BUTTON_EVENTS') || {
          booleanValue: true,
        }
      ).booleanValue,
      audioEncoding: (
        caps.find(c => c.name === 'DIALOGFLOW_AUDIOINPUT_ENCODING') || {
          stringValue: '',
        }
      ).stringValue,
      audioSampleRateHertz: (
        caps.find(c => c.name === 'DIALOGFLOW_AUDIOINPUT_SAMPLERATEHERTZ') || {}
      ).intValue,
      audioChannels: (
        caps.find(c => c.name === 'DIALOGFLOW_AUDIOINPUT_CHANNELS') || {}
      ).intValue,
      audioRecognitionPerChannel: (
        caps.find(c => c.name === 'DIALOGFLOW_AUDIOINPUT_RECOGNITION_PER_CHANNEL') || {
          booleanValue: false,
        }
      ).booleanValue
    }
  }

  const inputContextSuffixes = caps.filter(c => c.name.startsWith('DIALOGFLOW_INPUT_CONTEXT_NAME')).map(c => c.name.substring('DIALOGFLOW_INPUT_CONTEXT_NAME'.length))
  result.dialogFlow.inputContext = inputContextSuffixes.map(suffix => ({
    name: (caps.find(c => c.name === `DIALOGFLOW_INPUT_CONTEXT_NAME${suffix}`) || { stringValue: '' }).stringValue,
    lifespanCount: (caps.find(c => c.name === `DIALOGFLOW_INPUT_CONTEXT_LIFESPAN${suffix}`) || { intValue: null }).intValue,
    parameters: (caps.find(c => c.name === `DIALOGFLOW_INPUT_CONTEXT_PARAMETERS${suffix}`) || { jsonValue: null }).jsonValue,
  }))

  return result
}

const capNamesMap = {
  'dialogFlow.projectId': 'DIALOGFLOW_PROJECT_ID',
  'dialogFlow.environment': 'DIALOGFLOW_ENVIRONMENT',
  'dialogFlow.clientEmail': 'DIALOGFLOW_CLIENT_EMAIL',
  'dialogFlow.privateKey': 'DIALOGFLOW_PRIVATE_KEY',
  'dialogFlow.languageCode': 'DIALOGFLOW_LANGUAGE_CODE',
  'dialogFlow.queryParams': 'DIALOGFLOW_QUERY_PARAMS',
  'dialogFlow.apiEndpoint': 'DIALOGFLOW_API_ENDPOINT',
  'dialogFlow.outputPlatform': 'DIALOGFLOW_OUTPUT_PLATFORM',
  'dialogFlow.buttonAsEvent': 'DIALOGFLOW_BUTTON_EVENTS',
  'dialogFlow.audioEncoding': 'DIALOGFLOW_AUDIOINPUT_ENCODING',
  'dialogFlow.audioSampleRateHertz': 'DIALOGFLOW_AUDIOINPUT_SAMPLERATEHERTZ',
  'dialogFlow.audioChannels': 'DIALOGFLOW_AUDIOINPUT_CHANNELS',
  'dialogFlow.audioRecognitionPerChannel': 'DIALOGFLOW_AUDIOINPUT_RECOGNITION_PER_CHANNEL',
}

export function dialogFlowForm2caps(values) {
  const capabilities = [
    {
      name: 'DIALOGFLOW_PROJECT_ID',
      type: 'STRING',
      stringValue: values.dialogFlow.projectId || '',
    },
    {
      name: 'DIALOGFLOW_ENVIRONMENT',
      type: 'STRING',
      stringValue: values.dialogFlow.environment || '',
    },
    {
      name: 'DIALOGFLOW_CLIENT_EMAIL',
      type: 'STRING',
      stringValue: values.dialogFlow.clientEmail || '',
    },
    {
      name: 'DIALOGFLOW_PRIVATE_KEY',
      type: 'STRING',
      stringValue: values.dialogFlow.privateKey || '',
    },
    {
      name: 'DIALOGFLOW_API_ENDPOINT',
      type: 'STRING',
      stringValue: values.dialogFlow.apiEndpoint || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'dialogflow' },
    {
      name: 'DIALOGFLOW_LANGUAGE_CODE',
      type: 'STRING',
      stringValue: values.dialogFlow.languageCode || '',
    },
    {
      name: 'DIALOGFLOW_QUERY_PARAMS',
      type: 'STRING',
      stringValue: values.dialogFlow.queryParams || '',
    },
    {
      name: 'DIALOGFLOW_OUTPUT_PLATFORM',
      type: 'STRING',
      stringValue: values.dialogFlow.outputPlatform || '',
    },
    {
      name: 'DIALOGFLOW_BUTTON_EVENTS',
      type: 'BOOLEAN',
      booleanValue: values.dialogFlow.buttonAsEvent || false,
    },
    {
      name: 'DIALOGFLOW_AUDIOINPUT_ENCODING',
      type: 'STRING',
      stringValue: values.dialogFlow.audioEncoding || '',
    },
    {
      name: 'DIALOGFLOW_AUDIOINPUT_SAMPLERATEHERTZ',
      type: 'INT',
      intValue: values.dialogFlow.audioSampleRateHertz,
    },
    {
      name: 'DIALOGFLOW_AUDIOINPUT_CHANNELS',
      type: 'INT',
      intValue: values.dialogFlow.audioChannels,
    },
    {
      name: 'DIALOGFLOW_AUDIOINPUT_RECOGNITION_PER_CHANNEL',
      type: 'BOOLEAN',
      booleanValue: values.dialogFlow.audioRecognitionPerChannel || false,
    }
  ]

  let maxInputContextIndex = -1
  if (values.dialogFlow.inputContext && values.dialogFlow.inputContext.length > 0) {
    for (const [index, inputContext] of values.dialogFlow.inputContext.entries()) {
      capabilities.push({ name: `DIALOGFLOW_INPUT_CONTEXT_NAME_${index}`, type: 'STRING', stringValue: inputContext.name || '' })
      capabilities.push({ name: `DIALOGFLOW_INPUT_CONTEXT_LIFESPAN_${index}`, type: 'INT', intValue: inputContext.lifespanCount })
      if (inputContext.parameters) {
        capabilities.push({ name: `DIALOGFLOW_INPUT_CONTEXT_PARAMETERS_${index}`, type: 'JSON', jsonValue: inputContext.parameters || '' })
      } else {
        capabilities.push({ name: `DIALOGFLOW_INPUT_CONTEXT_PARAMETERS_${index}`, type: 'DELETE' })
      }
      maxInputContextIndex = index
    }
  }
  for (let index = maxInputContextIndex + 1; index < 100; index++) {
    capabilities.push({ name: `DIALOGFLOW_INPUT_CONTEXT_NAME_${index}`, type: 'DELETE' })
    capabilities.push({ name: `DIALOGFLOW_INPUT_CONTEXT_LIFESPAN_${index}`, type: 'DELETE' })
    capabilities.push({ name: `DIALOGFLOW_INPUT_CONTEXT_PARAMETERS_${index}`, type: 'DELETE' })
  }
  return capabilities
}

class DialogFlowEditUnconnected extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      projectExpanded: true,
      credentialsExpanded: false,
      agentExpanded: false,
      audioExpanded: false,
      contextExpanded: false,
      parseError: null,
      parseOk: null
    }
  }

  render() {
    const { advanced, disabled, getConnector, capSetCapNames } = this.props
    const { projectExpanded, credentialsExpanded, agentExpanded, audioExpanded, contextExpanded, parseError, parseOk } = this.state

    const connector = getConnector('dialogflow')
    const capApiEndpoint = connector.capabilities.find(c => c.name === 'DIALOGFLOW_API_ENDPOINT')
    const capLanguage = connector.capabilities.find(c => c.name === 'DIALOGFLOW_LANGUAGE_CODE')
    const capOutputPlatform = connector.capabilities.find(c => c.name === 'DIALOGFLOW_OUTPUT_PLATFORM')
    const capAudioInputEncoding = connector.capabilities.find(c => c.name === 'DIALOGFLOW_AUDIOINPUT_ENCODING')

    const loadJsonFile = (change, filename, filecontent) => {
      try {
        const cred = JSON.parse(atob(filecontent))
        if (!cred.project_id || !cred.client_email || !cred.private_key || _.isString(isRsaKey(cred.private_key))) {
          this.setState({
            parseError: `${filename} is not a Service Account Credentials File (JSON fields project_id/client_email/private_key are required)`,
            parseOk: null
          })
        } else {
          change('dialogFlow.projectId', cred.project_id)
          change('dialogFlow.clientEmail', cred.client_email)
          change('dialogFlow.privateKey', cred.private_key)
          this.setState({
            parseError: null,
            parseOk: `Read credentials from file ${filename}`,
          })
        }
      } catch (err) {
        this.setState({
          parseError: `Reading credentials from file ${filename} failed: ${err}`,
          parseOk: null
        })
      }
    }

    return (
      <FormSpy
        subscription={{ values: true, form: true }}
        render={({ values, form: { change, mutators: { push, pop } } }) => {

          if (advanced) {
            return (
              <GridContainer>
                <GridItem xs={12}>
                  <ExpansionPanel expanded={projectExpanded} onChange={() => this.setState({projectExpanded: !projectExpanded})} data-unique="pnlDialogFlowProject">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Project Configuration
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer nounset>
                        <GridItem xs={12}>
                          <Text warning>
                            For the Dialogflow Standard/Free/Trial plans there are quotas applied on the number of calls per minute. Tune these quotas in the <em>Retry &amp; Quotas</em> settings.
                          </Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlow.projectId"
                            component={renderTextField}
                            label="Project Id"
                            validate={required}
                            data-unique="txtDialogFlowProjectId"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.projectId']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.projectId'])}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlow.apiEndpoint"
                            component={renderSelect}
                            label="Region"
                            data-unique="selDialogFlowApiEndpoint"
                            items={capApiEndpoint && capApiEndpoint.choices.map(u => {
                              return { key: u.key, label: u.name }
                            })}
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.apiEndpoint']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.apiEndpoint'])}
                          />
                          <Text helperText isHtml>{capApiEndpoint.description}</Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlow.environment"
                            component={renderTextField}
                            label="Environment Name"
                            data-unique="txtDialogFlowEnvironment"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.environment']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.environment'])}
                          />
                        </GridItem>
                      </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel expanded={credentialsExpanded} onChange={() => this.setState({credentialsExpanded: !credentialsExpanded})} data-unique="pnlDialogFlowCredentials">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Credentials
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer nounset>
                        <GridItem xs={12} sm={5}>
                          <Field
                            name="dialogFlow.clientEmail"
                            component={renderTextField}
                            label="Client Email"
                            validate={required}
                            data-unique="txtDialogFlowClientEmail"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.clientEmail']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.clientEmail'])}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <Field
                            name="dialogFlow.privateKey"
                            component={renderPasswordField}
                            label="Private Key"
                            validate={composeValidators(required, isRsaKey)}
                            data-unique="txtDialogFlowPrivateKey"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.privateKey']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.privateKey'])}
                          />
                        </GridItem>
                        {!disabled && (<React.Fragment>
                          <GridItem xs={12}>
                            <Field
                              name="dialogFlow.fileupload"
                              component={renderFileUpload}
                              accept=".json"
                              values={values}
                              change={change}
                              label="Select/Drop Service Account Credentials JSON File"
                              onFileLoaded={(filename, filecontent) => {
                                loadJsonFile(change, filename, filecontent)
                                change('dialogFlow.fileupload', null)
                              }}
                              data-unique="fileDialogFlowCredentials"
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            {parseError && <Text danger data-unique="fileDialogFlowCredentialsErr">{parseError}</Text>}
                            {parseOk && <Text success data-unique="fileDialogFlowCredentialsOk">{parseOk}</Text>}
                          </GridItem>
                        </React.Fragment>)}
                        </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel expanded={agentExpanded} onChange={() => this.setState({agentExpanded: !agentExpanded})} data-unique="pnlDialogFlowAgent">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Agent Settings
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer nounset>
                        <GridItem xs={12} sm={4}>
                          <Query
                            fetchPolicy="network-only"
                            query={RUNCONNECTORQUERY_QUERY}
                            variables={{
                              connectorName: 'dialogflow',
                              field: 'DIALOGFLOW_LANGUAGE_CODE',
                              caps: JSON.stringify({
                                DIALOGFLOW_CLIENT_EMAIL: values.dialogFlow.clientEmail,
                                DIALOGFLOW_PRIVATE_KEY: values.dialogFlow.privateKey,
                                DIALOGFLOW_PROJECT_ID: values.dialogFlow.projectId,
                                DIALOGFLOW_API_ENDPOINT: values.dialogFlow.apiEndpoint
                              })
                            }}
                          >
                            {({ loading, error, data }) => {
                              const err = error ? extractErrorMessage(error) : (data && data.runconnectorquery && data.runconnectorquery.err)
                              const choices = data && data.runconnectorquery && data.runconnectorquery.choices

                              if (loading || err || !choices || choices.length === 0) {
                                let endAdornment = null
                                if (loading) endAdornment = <LoadingIndicator />
                                else if (err) endAdornment = <Tooltip title={err}><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>
                                else endAdornment = <Tooltip title="No language codes found for selection, please enter value manually."><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>
                            
                                return <Field
                                  name="dialogFlow.languageCode"
                                  component={renderTextField}
                                  label="Language"
                                  parse={v => v}
                                  data-unique="txtDialogFlowLanguageCode"
                                  endAdornment={endAdornment}
                                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.languageCode']) || disabled}
                                  helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.languageCode'])}
                                />
                              } else {
                                return <Field
                                  name="dialogFlow.languageCode"
                                  component={renderSelect}
                                  label="Language"
                                  data-unique="selDialogFlowLanguageCode"
                                  items={choices.map(a => {
                                    return { key: a.key, label: a.name }
                                  })}
                                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.languageCode']) || disabled}
                                  helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.languageCode'])}
                                  />
                              }
                            }}
                          </Query>
                          <Text helperText isHtml>{capLanguage.description}</Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlow.outputPlatform"
                            component={renderSelect}
                            label="Output Platform"
                            data-unique="selDialogFlowOutputPlatform"
                            items={(capOutputPlatform && capOutputPlatform.choices.map(u => {
                              return { key: u.key, label: u.name }
                            })) || []}
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.outputPlatform']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.outputPlatform'])}
                          />
                          <Text helperText isHtml>{capOutputPlatform.description}</Text>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlow.buttonAsEvent"
                            component={renderCheckbox}
                            label="Enable Button Click Events"
                            type="checkbox"
                            data-unique="chkDialogFlowButtonAsEvent"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.outputPlatform']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.outputPlatform'], 'Activate if your Dialogflow Agent expects button clicks as events instead of text input')}
                          />
                        </GridItem>
                      </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel expanded={audioExpanded} onChange={() => this.setState({audioExpanded: !audioExpanded})} data-unique="pnlDialogFlowAudio">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Audio Settings
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer nounset>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlow.audioEncoding"
                            component={renderSelect}
                            label="Audio Input Encoding"
                            data-unique="selDialogFlowAudioEncoding"
                            items={(capAudioInputEncoding && capAudioInputEncoding.choices.map(u => {
                              return { key: u.key, label: u.name }
                            })) || []}
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.audioEncoding']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.audioEncoding'])}
                          />
                          <Text helperText isHtml>{capAudioInputEncoding.description}</Text>
                        </GridItem>
                        <OnChange name="dialogFlow.audioEncoding">
                          {(value, previous) => {
                            if (value === 'AUDIO_ENCODING_AMR') {
                              change('dialogFlow.audioSampleRateHertz', 8000)
                            } else if (value === 'AUDIO_ENCODING_AMR_WB' || value === 'AUDIO_ENCODING_OGG_OPUS' || value === 'AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE') {
                              change('dialogFlow.audioSampleRateHertz', 16000)
                            }
                          }}
                        </OnChange>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlow.audioSampleRateHertz"
                            component={renderIntField}
                            parse={parseInteger}
                            label="Audio Input Sample Rate in Hertz"
                            data-unique="intDialogFlowAudioSampleRateHertz"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.audioSampleRateHertz']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.audioSampleRateHertz'])}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlow.audioChannels"
                            component={renderIntField}
                            parse={parseInteger}
                            label="Audio Input Channel Count"
                            data-unique="intDialogFlowAudioChannels"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.audioChannels']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.audioChannels'])}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Field
                            name="dialogFlow.audioRecognitionPerChannel"
                            component={renderCheckbox}
                            type="checkbox"
                            label="Audio Input: Recognition per Channel"
                            data-unique="intDialogFlowAudioRecognitionPerChannel"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.audioRecognitionPerChannel']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.audioRecognitionPerChannel'])}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          To receive response as audio from Dialogflow, you have to adapt the <em>Speech Settings</em> in Dialogflow, see <a href="https://cloud.google.com/dialogflow/es/docs/agents-settings" target="_blank" rel="noopener noreferrer">Dialogflow Documentation</a>
                        </GridItem>
                      </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel expanded={contextExpanded} onChange={() => this.setState({contextExpanded: !contextExpanded})} data-unique="pnlDialogFlowContext">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Context Parameters and Query Settings
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer nounset>
                        <FieldArray name="dialogFlow.inputContext">
                          {({ fields }) => <>
                            {fields.map((name, index) => (
                              <GridItem xs={12} key={index}>
                                <GridContainer>
                                  <GridItem sm={3}>
                                    <Field
                                      name={`${name}.name`}
                                      component={renderTextField}
                                      label={`Context Parameter Name #${index + 1}`}
                                      validate={required}
                                      disabled={disabled}
                                      data-unique={`txtDialogFlowInputContextName_${index}`}
                                    />
                                  </GridItem>
                                  <GridItem sm={2}>
                                    <Field
                                      name={`${name}.lifespanCount`}
                                      component={renderIntField}
                                      parse={parseInteger}
                                      label="Lifespan"
                                      validate={required}
                                      disabled={disabled}
                                      data-unique={`txtDialogFlowInputContextLifespan_${index}`}
                                    />
                                  </GridItem>
                                  <GridItem sm={6}>
                                    <Field
                                      className="CapabilitiesShort"
                                      name={`${name}.parameters`}
                                      label="Context Parameters (JSON structure)"
                                      component={renderCodeArea}
                                      options={{ mode: 'application/json' }}
                                      validate={json}
                                      codeFormat={prettyPrintJson}
                                      disabled={disabled}
                                      data-unique={`txtDialogFlowInputContextParameters_${index}`}
                                    />
                                  </GridItem>
                                  {!disabled &&
                                    <GridItem sm={1}>
                                      <Button onClick={() => fields.remove(index)} justIcon data-unique={`btnDialogFlowInputContextRemove_${index}`}>
                                        <RemoveIcon />
                                      </Button>
                                    </GridItem>
                                  }
                                </GridContainer>
                              </GridItem>
                            ))}
                            {!disabled &&
                              <GridItem xs={12}>
                                <Button onClick={() => fields.push({ name: '', lifespanCount: null, parameters: null })} data-unique="btnDialogFlowInputContextAdd">
                                  <AddIcon /> Add New Input Context Parameter
                                </Button>
                              </GridItem>
                            }
                          </>}
                        </FieldArray>
                        <GridItem xs={12}>
                          <Field
                            name="dialogFlow.queryParams"
                            className="CapabilitiesShort"
                            component={renderCodeArea}
                            options={{ mode: 'application/json' }}
                            label="Query Parameters"
                            codeFormat={prettyPrintJson}
                            parse={v => v}
                            validate={json}
                            data-unique="txtDialogFlowQueryParams"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['dialogFlow.queryParams']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['dialogFlow.queryParams'])}
                          />
                        </GridItem>
                      </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </GridItem>
              </GridContainer>
            )
          } else {
            return (
              <GridContainer>
                <GridItem xs={12}>
                  <Field
                    name="dialogFlow.fileupload"
                    component={renderFileUpload}
                    accept=".json"
                    values={values}
                    change={change}
                    label="Select/Drop Service Account Credentials JSON File"
                    onFileLoaded={(filename, filecontent) => {
                      loadJsonFile(change, filename, filecontent)
                      change('dialogFlow.fileupload', null)
                    }}
                    data-unique="fileDialogFlowCredentials"
                  />
                </GridItem>
                <GridItem xs={12}>
                  {parseError && <Text danger data-unique="fileDialogFlowCredentialsErr">{parseError}</Text>}
                  {parseOk && <Text success data-unique="fileDialogFlowCredentialsOk">{parseOk}</Text>}
                </GridItem>
                <GridItem xs={12}>
                  <Field name="dialogFlow.projectId" validate={required} render={() => null}/>
                </GridItem>
              </GridContainer>
            )
          }
        }}
      />
    )
  }
}

const DialogFlowEdit = withRouter(connect(
  state => ({ settings: state.settings }),
  { getConnector }
)(DialogFlowEditUnconnected))
export { DialogFlowEdit }
