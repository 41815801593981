import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import Tooltip from 'components/Tooltip/Tooltip'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'

import TestSessionProgress from './TestSessionProgress.jsx'
import Text from 'components/Typography/Text.jsx'
import { NavLink } from 'react-router-dom'


class TestSessionProgressIcon extends React.Component {

  render() {
    const { testSession, variables, hideEndStatus, withoutNavLink, classes } = this.props

    return (
      <TestSessionProgress {...(this.props)} key={`icon_${testSession && testSession.id}_${variables && JSON.stringify(variables)}`}>
        {({ testSessionProgress }) => {

          if (hideEndStatus && testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')) {
            return <></>
          }

          let resultIcon

          if (
            (testSessionProgress && testSessionProgress.status === 'READY')
          ) {
            resultIcon = ''
          } else if (
            (testSessionProgress && testSessionProgress.status === 'CANCELLED')
          ) {
            resultIcon = <Tooltip title="Cancelled">
              <Text dangerBg bgradius icon="ban">CANCELLED</Text>
            </Tooltip>
          } else if (
            (testSessionProgress && testSessionProgress.status === 'FAILED')
          ) {
            resultIcon = <>{false && <Tooltip title="Failed">
              <Text dangerBg bgradius icon="triangle-exclamation">FAILED</Text>
            </Tooltip>}</>
          } else if (testSessionProgress) {
            const avgProgress = Math.max(
              testSessionProgress.jobs.reduce(
                (sum, j) => sum + (j.progressPercent || 0),
                0,
              ) / testSessionProgress.jobs.length,
              0
            )

            let tooltipText = `${Math.round(avgProgress)}%`
            if (testSessionProgress.jobs.length > 1) {
              tooltipText =
                tooltipText +
                ' (' +
                testSessionProgress.jobs.map(j => `${j.progressPercent || 0}%`).join('|') +
                ')'
            }
            resultIcon = <Tooltip title={tooltipText}>
              <Text warningBg bgradius iconRotation icon="circle-notch">RUNNING</Text>
            </Tooltip>
          } else {
            resultIcon = <Tooltip title="Pending">
                <Text warningBg bgradius icon="hourglass-start">PENDING</Text>
              </Tooltip>
          }

          if(withoutNavLink) {
            return <div className={classes.resultIconPositionNavLink} data-unique={`btnTestSessionProgressIcon_${testSessionProgress.status}`} data-status={testSessionProgress.status}>
              {resultIcon}
            </div>
          } else {
            return (<NavLink tabIndex={-1} to={`/regression/projects/view/${testSession.testProject?.id}/results/${testSessionProgress.id}`} data-unique={`btnTestSessionProgressIcon_${testSessionProgress.status}`} data-status={testSessionProgress.status}>
              {resultIcon}
            </NavLink>)
          }
        }}
      </TestSessionProgress>
    )
  }
}

export default withStyles(testsessionsStyle)(TestSessionProgressIcon)
