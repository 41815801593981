import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import config from 'config'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
// apollo
import {Query} from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  url,
  composeValidators,
  renderTextField,
  renderPasswordField,
  renderSelect,
  renderCheckbox,
  renderCodeArea,
  json,
  prettyPrintJson
} from 'components/Form/Form'
import { getConnector } from 'actions/settings'

import { APIKEYS_QUERY } from '../../views/Settings/gql'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function twilioCaps2Form(caps) {
  let apiKey = null
  const urlParamsCap = caps.find(c => c.name === 'TWILIO_IVR_PUBLICURLPARAMS')
  if (urlParamsCap && urlParamsCap.jsonValue) {
    try {
      const params = JSON.parse(urlParamsCap.jsonValue)
      apiKey = params.APIKEY
    } catch (err) {
    }
  }

  return {
    twilioivr: {
      accountSid: (caps.find(c => c.name === 'TWILIO_IVR_ACCOUNT_SID') || { stringValue: '' }).stringValue,
      authToken: (caps.find(c => c.name === 'TWILIO_IVR_AUTH_TOKEN') || { stringValue: '' }).stringValue,
      from: (caps.find(c => c.name === 'TWILIO_IVR_FROM') || { stringValue: '' }).stringValue,
      to: (caps.find(c => c.name === 'TWILIO_IVR_TO') || { stringValue: '' }).stringValue,
      languageCode: (caps.find(c => c.name === 'TWILIO_IVR_LANGUAGE_CODE') || { stringValue: 'en-US' }).stringValue,
      publicUrl: (caps.find(c => c.name === 'TWILIO_IVR_PUBLICURL') || { stringValue: `${config.api.ext}/twilio` }).stringValue,
      publicUrlParams: (caps.find(c => c.name === 'TWILIO_IVR_PUBLICURLPARAMS') || { jsonValue: JSON.stringify({ APIKEY: '' }) }).jsonValue,
      apiKey: apiKey,
      record: (caps.find(c => c.name === 'TWILIO_IVR_RECORD') || { booleanValue: false }).booleanValue
    }
  }
}

const capNamesMap = {
  'twilioivr.accountSid': 'TWILIO_IVR_ACCOUNT_SID',
  'twilioivr.authToken': 'TWILIO_IVR_AUTH_TOKEN',
  'twilioivr.from': 'TWILIO_IVR_FROM',
  'twilioivr.to': 'TWILIO_IVR_TO',
  'twilioivr.languageCode': 'TWILIO_IVR_LANGUAGE_CODE',
  'twilioivr.publicUrl': 'TWILIO_IVR_PUBLICURL',
  'twilioivr.publicUrlParams': 'TWILIO_IVR_PUBLICURLPARAMS',
  'twilioivr.record': 'TWILIO_IVR_RECORD'
}

export function twilioForm2caps(values) {
  const capabilities = [
    {
      name: 'TWILIO_IVR_ACCOUNT_SID',
      type: 'STRING',
      stringValue: values.twilioivr.accountSid,
    },
    {
      name: 'TWILIO_IVR_AUTH_TOKEN',
      type: 'STRING',
      stringValue: values.twilioivr.authToken,
    },
    {
      name: 'TWILIO_IVR_FROM',
      type: 'STRING',
      stringValue: values.twilioivr.from,
    },
    {
      name: 'TWILIO_IVR_TO',
      type: 'STRING',
      stringValue: values.twilioivr.to,
    },
    {
      name: 'TWILIO_IVR_LANGUAGE_CODE',
      type: 'STRING',
      stringValue: values.twilioivr.languageCode,
    },
    {
      name: 'TWILIO_IVR_PUBLICURL',
      type: 'STRING',
      stringValue: values.twilioivr.publicUrl,
    },
    {
      name: 'TWILIO_IVR_PUBLICURLPARAMS',
      type: 'JSON',
      jsonValue: values.twilioivr.publicUrlParams || '',
    },
    {
      name: 'TWILIO_IVR_RECORD',
      type: 'BOOLEAN',
      booleanValue: !!values.twilioivr.record,
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'twilio-ivr' }
  ]
  return capabilities
}

class TwilioEditUnconnected extends React.Component {
  render() {
    const { advanced, disabled, getConnector, capSetCapNames } = this.props

    const connector = getConnector('twilio-ivr')
    const capLanguage = connector.capabilities.find(c => c.name === 'TWILIO_IVR_LANGUAGE_CODE')

    return (<React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={4}>
          <Field
            name="twilioivr.accountSid"
            component={renderTextField}
            label="Twilio Account SID"
            validate={required}
            data-unique="txtTwilioEditAccountSid"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twilioivr.accountSid']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twilioivr.accountSid'], 'Account SID from Twilio account')}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="twilioivr.authToken"
            component={renderPasswordField}
            label="Twilio Auth Token"
            validate={required}
            data-unique="txtTwilioEditAuthToken"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twilioivr.authToken']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twilioivr.authToken'], 'Auth Token from Twilio account')}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="twilioivr.from"
            component={renderTextField}
            label="Caller Id"
            validate={required}
            data-unique="txtTwilioEditFrom"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twilioivr.from']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twilioivr.from'], 'Purchased or Verified phone number from Twilio account, in international format including country code')}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="twilioivr.to"
            component={renderTextField}
            label="IVR Phone Number"
            validate={required}
            data-unique="txtTwilioEditTo"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twilioivr.to']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twilioivr.to'], 'Phone number to call, in international format including country code')}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="twilioivr.languageCode"
            component={renderSelect}
            label="Language"
            validate={required}
            data-unique="selTwilioEditLanguage"
            items={capLanguage && capLanguage.choices.map(u => {
              return { key: u.key, label: `${u.name}${advanced && ` (${u.key})`}` }
            })}
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twilioivr.to']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twilioivr.to'], 'Language of the IVR System (for Speech Recognition)')}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="twilioivr.record"
            component={renderCheckbox}
            label="Record Calls"
            type="checkbox"
            data-unique="chkTwilioEditRecord"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twilioivr.record']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twilioivr.record'], 'Call recordings will be attached to the Botium conversations after hangup.')}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="twilioivr.publicUrl"
            component={renderTextField}
            label="Webhook Url"
            validate={composeValidators(required, url)}
            data-unique="txtTwilioEditPublicUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twilioivr.publicUrl']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twilioivr.publicUrl'], 'Public accessible Webhook Url')}
          />
        </GridItem>
        <GridItem xs={12} sm={8}></GridItem>
        <GridItem xs={12} sm={6}>
          <Query query={APIKEYS_QUERY}>
            {({loading, error, data}) => {
              return (<Field
                name="twilioivr.apiKey"
                component={renderSelect}
                label="Select an API Key for the Webhook Url"
                data-unique="selTwilioEditApiKey"
                loading={loading}
                error={error}
                items={data && data.apikeys && data.apikeys.map(a => {
                  return { key: a.key, label: a.name }
                })}
                disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twilioivr.apiKey']) || disabled}
                helperText={capSetDescription(capSetCapNames, capNamesMap['twilioivr.apiKey'])}
              />)
            }}
          </Query>
          <FormSpy subscription={{ form: true }} render={({ form: { change }}) => (
            <OnChange name="twilioivr.apiKey">
              {(value, previous) => {
                if (value) {
                  change('twilioivr.publicUrlParams', JSON.stringify({ APIKEY: value }))
                }
              }}
            </OnChange>
          )} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            className="CapabilitiesShort"
            name="twilioivr.publicUrlParams"
            component={renderCodeArea}
            options={{ mode: 'application/json' }}
            label="Webhook Url Params"
            codeFormat={prettyPrintJson}
            validate={json}
            data-unique="codeTwilioEditPublicUrlParams"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['twilioivr.apiKey']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['twilioivr.apiKey'], 'Additional Webhook Url Parameters (as JSON)')}
          />
        </GridItem>
      </GridContainer>
    </React.Fragment>)
  }
}

const TwilioEdit = withRouter(connect(
  state => ({ settings: state.settings }),
  { getConnector }
)(TwilioEditUnconnected))
export { TwilioEdit }
