import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemAvatar from 'components/List/ListItem/ListItemAvatar'
import ListItemText from 'components/List/ListItem/ListItemText'
import LowPriorityIcon from '@material-ui/icons/LowPriority'
import Field from 'components/Form/OptionalField'
import { FieldArray } from 'react-final-form-arrays'
// core components
import Tooltip from 'components/Tooltip/Tooltip'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { renderTextField, required } from 'components/Form/Form'
import AvatarImage from 'components/Avatar/AvatarImage'
import Chip from 'components/Chip/Chip'
import ComponentChip from 'components/Convo/ComponentChip'
import DropdownButton from 'components/Button/DropdownButton'
import ShowIcon from 'components/Icon/ShowIcon'
import { renderUtteranceText } from 'components/Convo/Shared'
import Text from 'components/Typography/Text'

import CrawlerAnswerDialog from './CrawlerAnswerDialog'

import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle'
import botiumLogo from 'assets/img/botium-logo.png'
import { setAlertSuccessMessage } from '../../actions/alert'

import { hasPermission } from 'botium-box-shared/security/permissions'

import {
  CRAWLER_SESSION_CONVO_QUERY,
  CRAWLERSESSION_TREE_QUERY,
  CREATE_CRAWLERENDOFCONVERSATIONS,
  DELETE_CRAWLERENDOFCONVERSATIONS
} from './gql'

class CrawlerConvoEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCrawlerAnswerDialog: false,
      convoStep: {}
    }
  }

  onDragEnd(result, fields) {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    fields.move(result.source.index, result.destination.index)
  }

  renderCrawlerAnswerDialog(crawlerSession) {
    const { showCrawlerAnswerDialog, convoStep } = this.state

    if (convoStep && convoStep.stepIndex >= 0) {
      return <CrawlerAnswerDialog key={convoStep.stepIndex}
        showCrawlerAnswerDialog={showCrawlerAnswerDialog}
        scriptData={convoStep}
        crawlerSessionId={crawlerSession.id}
        onComplete={() => {
          this.setState({ showCrawlerAnswerDialog: false, convoStep: {} })
        }}
        onCancel={() => this.setState({ showCrawlerAnswerDialog: false, convoStep: {} })}
      />
    }
    return null
  }

  render() {
    const { values, crawlerSession, historyView, disabled, hideName, setParentState, classes, user, setAlertSuccessMessage } = this.props

    return <GridContainer>
      {this.renderCrawlerAnswerDialog(crawlerSession)}
      {!hideName &&
        <GridItem xs={12} sm={4}>
          <Field
            name="name"
            component={renderTextField}
            label={values.partial ? 'Partial Convo Name' : 'Test Case Name'}
            validate={required}
            disabled={disabled}
          />
        </GridItem>
      }
      <FieldArray name="steps">
        {({ fields }) => (<React.Fragment>
          <GridItem xs={12}>
            <List component="div">
              {fields.map((name, stepIndex) => {
                const msg = values.steps[stepIndex]
                if (!msg) return null
                return (
                  <GridContainer key={stepIndex}>
                    <ListItem style={msg.sender === 'bot' && !msg.path ? { marginLeft: '37px', paddingRight: '49px' } : {}} key={name}>

                      <Field name={`${name}.messageText`}>{() => null}</Field>
                      <Field name={`${name}.not`}>{() => null}</Field>
                      <Field name={`${name}.userInputs`}>{() => null}</Field>
                      <Field name={`${name}.asserters`}>{() => null}</Field>

                      {msg.sender === 'bot' && (
                        <ListItemAvatar>
                          <React.Fragment>
                            <Mutation
                              mutation={msg.endOfConversation ? DELETE_CRAWLERENDOFCONVERSATIONS : CREATE_CRAWLERENDOFCONVERSATIONS}
                              onCompleted={() => {
                                setAlertSuccessMessage(msg.endOfConversation ? 'Unmarked as end of conversation successful' : 'Marked as end of conversation successful')
                              }}
                              refetchQueries={() => {
                                return [
                                  {
                                    query: CRAWLERSESSION_TREE_QUERY,
                                    variables: { id: crawlerSession.id },
                                  },
                                  {
                                    query: CRAWLER_SESSION_CONVO_QUERY,
                                    variables: { crawlerSessionScriptId: values.scriptId },
                                  }
                                ]
                              }}
                            >
                              {(mutateCrawlerEndOfConversation) => <>
                                {msg.path && !historyView && (
                                  <DropdownButton
                                    dots
                                    data-unique={`ddbtnCrawlerResultTreeThreeDot_Step${stepIndex}`}
                                    items={[
                                      {
                                        id: 'userResponses',
                                        name: 'Add/Edit user responses',
                                        dataUnique: 'ddbtniUserResponses',
                                        icon: <ShowIcon icon="comment" />,
                                        disabled: !hasPermission(user, 'CRAWLERPROJECTS_UPDATE'),
                                        onClick: () => {
                                          this.setState({
                                            showCrawlerAnswerDialog: true,
                                            convoStep: {
                                              crawlerAnswer: msg.crawlerAnswer,
                                              scriptId: values.scriptId,
                                              paths: [msg.path],
                                              steps: values.steps.slice(0, stepIndex + 1),
                                              stepIndex
                                            }
                                          })
                                        }
                                      },
                                      {
                                        id: 'endOfConversation',
                                        name: msg.endOfConversation ? 'Unmark as end of conversation' : 'Mark as end of conversation',
                                        dataUnique: 'ddbtniEndOfConversation',
                                        icon: msg.endOfConversation ? <ShowIcon icon="undo" /> : <ShowIcon icon="hand-paper" />,
                                        disabled: !hasPermission(user, 'CRAWLERSESSIONS_UPDATE'),
                                        onClick: () => {
                                          mutateCrawlerEndOfConversation({
                                            variables: {
                                              crawlerEndOfConversation: {
                                                crawlerSessionId: crawlerSession.id,
                                                paths: [msg.path]
                                              }
                                            }
                                          })
                                        }
                                      }
                                    ]}
                                  >
                                  </DropdownButton>
                                )}
                                <AvatarImage />
                              </>}
                            </Mutation>
                          </React.Fragment>
                        </ListItemAvatar>
                      )}
                      <ListItemText
                        data-unique={`liTestSetCrawlerConvoEditorStep_${stepIndex}`}
                        className={
                          msg.sender === 'me' ? classes.senderMe : msg.sender === 'bot' ? classes.senderBot : msg.sender === 'include' ? classes.senderInclude : classes.senderNone
                        }
                        style={disabled ? {} : { cursor: 'pointer' }}
                        onClick={() => !disabled && setParentState && setParentState({ editStep: msg, editStepName: name, editStepIndex: stepIndex })}
                      >

                        {msg.sender === 'include' && [msg.channel, ...((msg.messageText && msg.messageText.split('\n')) || [])].filter(s => s).map((a, ai) => <ComponentChip component={{ name: 'INCLUDE', args: [a] }} key={`include_${ai}`} />)}
                        {msg.sender === 'me' && msg.messageText && renderUtteranceText('primary', msg, { fontWeight: 'bold' })}
                        {msg.sender === 'me' && msg.userInputs && msg.userInputs.map((a, ai) => <ComponentChip component={a} key={`userinput_${ai}`} />)}
                        {msg.sender === 'bot' && msg.messageText && msg.not && renderUtteranceText('danger', msg, { fontWeight: 'bold' })}
                        {msg.sender === 'bot' && msg.messageText && !msg.not && renderUtteranceText('white', msg, { fontWeight: 'bold' })}
                        {msg.optional &&
                          <Tooltip title="Optional" key="optional">
                            <Chip avatar={<Avatar><LowPriorityIcon /></Avatar>} label="" />
                          </Tooltip>
                        }
                        {msg.sender === 'bot' && msg.asserters && msg.asserters.map((a, ai) => <ComponentChip component={a} key={`asserter_${ai}`}/>)}
                        {msg.sender === 'bot' && msg.crawlerAnswer && msg.crawlerAnswer.answers.length > 0 && msg.crawlerAnswer.answers.map(ua =>
                          <Chip variant="info" avatar={<Avatar><ShowIcon custom icon="userResponse" /></Avatar>}
                            key={ua}
                            data-unique={`chipUserResponsesStep_${ua}_${stepIndex}`}
                            label={ua}
                          />
                        )
                        }
                        {msg.logicHooks && msg.logicHooks.map((a, ai) => <ComponentChip component={a} key={`logichook_${ai}`} />)}
                        {msg.endOfConversation && (
                          <Tooltip title="Marked as end of conversation">
                            <Chip justIcon variant="info" avatar={<Avatar><ShowIcon custom icon="endConvo" /></Avatar>} data-unique={`chipEndOfConversationStep_${stepIndex}`} />
                          </Tooltip>
                        )}
                      </ListItemText>
                      {msg.sender === 'me' && (
                        <ListItemIcon>
                          <AvatarImage avatar={botiumLogo} />
                        </ListItemIcon>
                      )}
                    </ListItem>
                    {msg.sender === 'bot' && msg.err && (
                      <ListItem style={{ marginLeft: '37px', paddingRight: '49px', paddingLeft: '23px' }} key={`${stepIndex}_err`} data-unique={`${stepIndex}_err`}>
                        <ListItemIcon>
                          <GridContainer top>
                            <GridItem>
                              <ShowIcon custom icon="error" />
                            </GridItem>
                          </GridContainer>
                        </ListItemIcon>
                        <ListItemText inset
                          className={classes.errorBubble}
                        >
                          <Text bold>{msg.err}</Text>
                        </ListItemText>
                      </ListItem>
                    )
                    }
                  </GridContainer>)

              })}
            </List>
          </GridItem>
        </React.Fragment>)}
      </FieldArray>
    </GridContainer>
  }

}

CrawlerConvoEditor.defaultProps = {}

CrawlerConvoEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  setParentState: PropTypes.func,
  crawlerSession: PropTypes.object.isRequired,
  hideName: PropTypes.bool,
  disabled: PropTypes.bool
}

export default withRouter(
  connect(
    state => ({ user: state.token.user }),
    { setAlertSuccessMessage }
  )(withStyles(convoStyle)(CrawlerConvoEditor)),
)
