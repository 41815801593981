import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose, withApollo } from 'react-apollo'
import { TRAINERSESSIONTREND_QUERY } from '../gql'
import { setDashboardSettings } from 'actions/dashboard'

import Dashboard from './Dashboard.jsx'

export default compose(
  withApollo,
  connect(
    (state, ownProps) => ({
      dashboard: state.dashboard
    }),
    { setDashboardSettings }
  )
)(({ match, dashboard, client, setDashboardSettings }) => {

  const { testSessionId } = match.params
  const { secondaryTestSessionId, testSessionSwitch, keepTestSessionIds } = dashboard

  useEffect(() => {
    const initSecondaryTestSession = async () => {
      try {
        const { data } = await client.query({
          query: TRAINERSESSIONTREND_QUERY,
          variables: { testSessionId }
        })
        setDashboardSettings('secondaryTestSessionId', data.trainersessiontrend.previousTestSessionId || data.trainersessiontrend.nextTestSessionId || '')
      } catch (err) {
        console.error(err)
        setDashboardSettings('secondaryTestSessionId', '')
      }
    }

    if(!keepTestSessionIds) {
      setDashboardSettings('keepTestSessionIds', true)
      setDashboardSettings('secondaryTestSessionId', '')
      setDashboardSettings('testSessionSwitch', '')
      initSecondaryTestSession()
    }
  }, [testSessionId, setDashboardSettings, keepTestSessionIds, client])

  if (testSessionSwitch) {
    return <Dashboard testSessionId={secondaryTestSessionId} secondaryTestSessionId={testSessionId} />
  }

  return <Dashboard testSessionId={testSessionId} secondaryTestSessionId={secondaryTestSessionId} />
})
