import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { getConnector } from 'actions/settings'
import ShowIcon from 'components/Icon/ShowIcon'

import {
  renderTextField,
  renderCodeArea,
  renderPasswordField,
  renderSelect,
  renderCheckbox,
  renderIntField,
  required,
  json,
  parseInteger,
  minValue,
  prettyPrintJson
} from 'components/Form/Form'

import { capSetDescription, ExtractJsonCapabilityValue, usedByCapabilitySet } from './Helper'
import { extractErrorMessage } from 'helper/graphHelper'
import { RUNCONNECTORQUERY_QUERY } from './ConnectorEdit'
import Text from 'components/Typography/Text'

export function watsonCaps2Form(caps) {
  return {
    watson: {
      sdk: (caps.find(c => c.name === 'WATSON_ASSISTANT_VERSION') || { stringValue: 'V1' }).stringValue,
      url: (caps.find(c => c.name === 'WATSON_URL') || { stringValue: '' }).stringValue,
      apikey: (caps.find(c => c.name === 'WATSON_APIKEY') || { stringValue: '' }).stringValue,
      user: (caps.find(c => c.name === 'WATSON_USER') || { stringValue: '' }).stringValue,
      pwd: (caps.find(c => c.name === 'WATSON_PASSWORD') || { stringValue: '' }).stringValue,
      assistantId: (caps.find(c => c.name === 'WATSON_ASSISTANT_ID') || { stringValue: '' }).stringValue,
      workspaceId: (caps.find(c => c.name === 'WATSON_WORKSPACE_ID') || { stringValue: '' }).stringValue,
      copyWorkspace: (caps.find(c => c.name === 'WATSON_COPY_WORKSPACE') || { booleanValue: false }).booleanValue,
      haswelcome: !!caps.find(c => c.name === 'WATSON_WELCOME_MESSAGE'),
      welcome: (caps.find(c => c.name === 'WATSON_WELCOME_MESSAGE') || { stringValue: '' }).stringValue,
      timeout: (caps.find(c => c.name === 'WATSON_TIMEOUT') || { intValue: null }).intValue,
      userId: (caps.find(c => c.name === 'WATSON_ASSISTANT_USER_ID') || { stringValue: '' }).stringValue,
      initialContext: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'WATSON_INITIAL_CONTEXT', '')),
    }
  }
}

const capNamesMap = {
  'watson.sdk': 'WATSON_ASSISTANT_VERSION',
  'watson.url': 'WATSON_URL',
  'watson.apikey': 'WATSON_APIKEY',
  'watson.user': 'WATSON_USER',
  'watson.pwd': 'WATSON_PASSWORD',
  'watson.assistantId': 'WATSON_ASSISTANT_ID',
  'watson.workspaceId': 'WATSON_WORKSPACE_ID',
  'watson.copyWorkspace': 'WATSON_COPY_WORKSPACE',
  'watson.haswelcome': 'WATSON_WELCOME_MESSAGE',
  'watson.welcome': 'WATSON_WELCOME_MESSAGE',
  'watson.timeout': 'WATSON_TIMEOUT',
  'watson.userId': 'WATSON_ASSISTANT_USER_ID',
  'watson.initialContext': 'WATSON_INITIAL_CONTEXT'
}

export function watsonForm2caps(values) {
  const caps = [
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'watson' },
    { name: 'WATSON_ASSISTANT_VERSION', type: 'STRING', stringValue: values.watson.sdk },
    { name: 'WATSON_URL', type: 'STRING', stringValue: values.watson.url },
    { name: 'WATSON_APIKEY', type: 'STRING', stringValue: values.watson.apikey || null },
    { name: 'WATSON_USER', type: 'STRING', stringValue: values.watson.user || null },
    { name: 'WATSON_PASSWORD', type: 'STRING', stringValue: values.watson.pwd || null },
    {
      name: 'WATSON_ASSISTANT_ID',
      type: 'STRING',
      stringValue: values.watson.assistantId,
    },
    {
      name: 'WATSON_WORKSPACE_ID',
      type: 'STRING',
      stringValue: values.watson.workspaceId,
    },
    {
      name: 'WATSON_COPY_WORKSPACE',
      type: 'BOOLEAN',
      booleanValue: values.watson.copyWorkspace,
    },
    {
      name: 'WATSON_INITIAL_CONTEXT',
      type: 'JSON',
      jsonValue: values.watson.initialContext
    }
  ]
  if (values.watson.haswelcome) {
    caps.push({ name: 'WATSON_WELCOME_MESSAGE', type: 'STRING', stringValue: values.watson.welcome || '' })
  } else {
    caps.push({ name: 'WATSON_WELCOME_MESSAGE', type: 'DELETE' })
  }
  if (values.watson.timeout) {
    caps.push({ name: 'WATSON_TIMEOUT', type: 'INT', intValue: values.watson.timeout })
  } else {
    caps.push({ name: 'WATSON_TIMEOUT', type: 'DELETE' })
  }
  if (values.watson.userId) {
    caps.push({ name: 'WATSON_ASSISTANT_USER_ID', type: 'STRING', stringValue: values.watson.userId })
  } else {
    caps.push({ name: 'WATSON_ASSISTANT_USER_ID', type: 'DELETE' })
  }
  return caps
}

class WatsonEditUnconnected extends React.Component {
  renderWorkspaceSelector() {
    const { disabled, capSetCapNames } = this.props
    return (
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          if (values.watson.sdk === 'V2') {
            return (
              <GridItem xs={12}>
                <Field
                  name="watson.assistantId"
                  component={renderTextField}
                  label="Watson Assistant Id"
                  validate={required}
                  data-unique="txtWatsonEditAssistentId"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.assistantId']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['watson.assistantId'])}
                />
              </GridItem>
            )
          } else if (values.watson.sdk === 'V1') {
            return (<Query
              fetchPolicy="network-only"
              query={RUNCONNECTORQUERY_QUERY}
              variables={{
                connectorName: 'watson',
                field: 'WATSON_WORKSPACE_ID',
                caps: JSON.stringify({
                  WATSON_USER: values.watson.user,
                  WATSON_PASSWORD: values.watson.pwd,
                  WATSON_APIKEY: values.watson.apikey,
                  WATSON_URL: values.watson.url
                })
              }}
            >
              {({ loading, error, data }) => {
                if (loading) return <GridItem xs={12}><Text horizontalCenter><ShowIcon custom moveleft icon="warning" />Loading workspaces ...</Text></GridItem>
                if (error) return <GridItem xs={12}><Text horizontalCenter><ShowIcon custom moveleft icon="error" />Loading workspaces failed: {extractErrorMessage(error)}</Text></GridItem>
                if (data && data.runconnectorquery && data.runconnectorquery.err) return <GridItem xs={12}><Text horizontalCenter><ShowIcon custom moveleft icon="error" />Loading workspaces failed: {data.runconnectorquery.err}</Text></GridItem>
                if (!data || !data.runconnectorquery || !data.runconnectorquery.choices || data.runconnectorquery.choices.length === 0) return <GridItem xs={12}><Text horizontalCenter ><ShowIcon custom moveleft icon="error" />No workspace found - check credentials</Text></GridItem>

                return <React.Fragment>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="watson.workspaceId"
                      component={renderSelect}
                      label="Workspace"
                      validate={required}
                      data-unique="selWatsonEditWorkspaceId"
                      items={data.runconnectorquery.choices.map(a => {
                        return { key: a.key, label: a.name }
                      })}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.workspaceId']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['watson.workspaceId'])}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="watson.copyWorkspace"
                      component={renderCheckbox}
                      label="Copy Workspace before running tests"
                      type="checkbox"
                      data-unique="chkWatsonEditCopyWorkspace"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.copyWorkspace']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['watson.copyWorkspace'])}
                    />
                  </GridItem>
                </React.Fragment>
              }}
            </Query>)
          } else {
            return null
          }
        }}
      />
    )
  }

  render() {
    const { advanced, disabled, getConnector, capSetCapNames } = this.props

    const connector = getConnector('watson')
    const capUrl = connector.capabilities.find(c => c.name === 'WATSON_URL')
    const capVersion = connector.capabilities.find(c => c.name === 'WATSON_ASSISTANT_VERSION')
    const capUserId = connector.capabilities.find(c => c.name === 'WATSON_ASSISTANT_USER_ID')
    const capTimeout = connector.capabilities.find(c => c.name === 'WATSON_TIMEOUT')

    return (
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          return (
            <GridContainer>
              <GridItem xs={12}>
                <Text>Enter either IAM API Key or Service Username/Password for authentication against your IBM Watson account.</Text>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="watson.apikey"
                  component={renderPasswordField}
                  label="IAM API Key"
                  data-unique="pwWatsonEditSecret"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.apikey']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['watson.apikey'])}
                />
                <Text helperText>
                  IAM API Key for IBM Watson - see <a href="https://cloud.ibm.com/docs/services/watson/getting-started-iam.html" target="_blank" rel="noopener noreferrer">here</a> how to create it for your IBM Watson account). Either the IAM API Key or the Service credentials are required.
                </Text>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Field
                      name="watson.user"
                      component={renderTextField}
                      label="Service Username"
                      data-unique="txtWatsonEditUser"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.user']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['watson.user'])}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      name="watson.pwd"
                      component={renderPasswordField}
                      label="Service Password"
                      data-unique="pwWatsonEditPassword"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.pwd']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['watson.pwd'])}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Text helperText>
                      The Service credentials are different from your IBM Cloud account (see <a href="https://www.ibm.com/watson/developercloud/assistant/api/v1/node.html?node#authentication" target="_blank" rel="noopener noreferrer">IBM Watson Assistant documentation</a>)
                    </Text>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="watson.url"
                  component={renderSelect}
                  label="Service Endpoint"
                  validate={required}
                  data-unique="selWatsonEditUrl"
                  items={capUrl && capUrl.choices.map(u => {
                    return { key: u.key, label: `${u.name}${advanced ? ` (${u.key})` : ''}` }
                  })}
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.url']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['watson.url'])}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <Field
                  name="watson.sdk"
                  component={renderSelect}
                  label="Assistant SDK Version"
                  validate={required}
                  data-unique="selWatsonEditSdk"
                  items={capVersion && capVersion.choices.map(u => {
                    return { key: u.key, label: u.name }
                  })}
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.sdk']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['watson.sdk'])}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <Field
                  name="watson.haswelcome"
                  component={renderCheckbox}
                  label="Trigger Welcome Message"
                  type="checkbox"
                  data-unique="chkWatsonEditHasWelcome"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.haswelcome']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['watson.haswelcome'], 'Trigger a welcome message from IBM Watson by sending some input upfront')}
                />
              </GridItem>
              <GridItem xs={12} sm={9}></GridItem>
              <GridItem xs={12} sm={3}>
                {values.watson.haswelcome &&
                  <Field
                    name="watson.welcome"
                    component={renderTextField}
                    label="Welcome Message"
                    data-unique="txtWatsonEditWelcome"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.welcome']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['watson.welcome'])}
                  />
                }
              </GridItem>

              {this.renderWorkspaceSelector()}

              <GridItem xs={12}></GridItem>
              {advanced && <GridItem xs={12} sm={3}>
                <Field
                  name="watson.userId"
                  component={renderTextField}
                  label={capUserId.label}
                  data-unique="txtWatsonEditUserId"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.userId']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['watson.userId'], capUserId.helperText)}
                />
              </GridItem>}
              {advanced && <GridItem xs={12} sm={3}>
                <Field
                  name="watson.timeout"
                  component={renderIntField}
                  label={capTimeout.label}
                  parse={parseInteger}
                  validate={minValue(0)}
                  data-unique="txtWatsonEditTimeout"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.timeout']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['watson.timeout'], capTimeout.helperText)}
                />
              </GridItem>}
              {advanced && <GridItem xs={12} sm={6}>
                <Field
                  className="CapabilitiesShort"
                  name="watson.initialContext"
                  component={renderCodeArea}
                  options={{ mode: 'application/json' }}
                  label="Initial Watson Context (JSON structure)"
                  validate={json}
                  codeFormat={prettyPrintJson}
                  data-unique="codeWatsonInitialContext"
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['watson.initialContext']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['watson.initialContext'])}
                />
              </GridItem>}
            </GridContainer>
          )
        }}
      />
    )
  }
}

const WatsonEdit = withRouter(connect(
  state => ({ settings: state.settings }),
  { getConnector }
)(WatsonEditUnconnected))
export { WatsonEdit }
