import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, compose, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import {
  renderTextField,
  renderTextArea,
  renderSelect,
  renderCodeArea,
  renderPasswordField,
  renderCheckbox,
  required,
  json,
  url,
  isRsaKey,
  email,
  prettyPrintJson,
  composeValidators,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'

import ShowIcon from 'components/Icon/ShowIcon'
import { LanguageDisplayName } from 'components/Icon/FlagIcon'
import QueryStatus from 'components/Info/QueryStatus'
import { extractErrorMessage } from 'helper/graphHelper'

import { recordAudioStreamingSupported, recordAudioStreaming } from 'helper/browserHelper'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'
import { validateSpeechRecognitionNameUnique } from './validators'

import {
  SPEECHRECOGNITIONPROFILE_QUERY,
  SPEECHRECOGNITIONPROFILES_QUERY,
  CREATE_SPEECHRECOGNITIONPROFILE,
  UPDATE_SPEECHRECOGNITIONPROFILE,
  DELETE_SPEECHRECOGNITIONPROFILE,
  OPEN_SPEECHRECOGNITIONPROFILE_STREAM
} from './gql'
import { SPEECH_STT_LANGUAGES_QUERY } from 'views/TestSets/gql'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ImportPrisma from './ImportPrisma'
import Divider from 'components/Divider/Divider'
import { downloadfileformpost } from 'helper/downloadHelper'
import config from 'config'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Card from 'components/Card/Card'

function AudioStream({ stream }) {
  const [transcript, setTranscript] = useState([])

  useEffect(() => {
    const addTranscript = (msg) => {
      setTranscript(prevTranscript => prevTranscript.length > 20 ? [ msg, ...prevTranscript.slice(0, 20) ] : [ msg, ...prevTranscript])
    }
  
    let ws = null
    try {
      ws = new WebSocket(stream.wsUri)
    } catch (err) {
      addTranscript({ err: `Speech Recognition Failed to open: ${err.message}` })
      return
    }
    ws.onerror = (err) => {
      addTranscript({ err: `Speech Recognition Stream Failure: ${err.message}` })
    }
    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)
        if (data.text && data.final) addTranscript({ text: data.text })
        if (data.err) addTranscript({ err: data.err })
      } catch (err) {
      }
    }

    let recorderStop = null    
    recordAudioStreaming((data) => {
      if (ws && ws.readyState === 1) {
        ws.send(data)
      }
    }).then(recorder => {
      recorder.start()
      recorderStop = recorder.stop
    })

    return () => {
      if (recorderStop) {
        recorderStop()
      }
      if (stream && stream.endUri) {
        fetch(stream.endUri).catch(console.error)
      }
      if (ws && ws.readyState === 1) {
        ws.close()
      }
    }
  }, [stream])

  return <GridContainer>
    {transcript.map((t, i) => <GridItem xs={12} key={i}>{t.err ? <Text warning>{t.err}</Text> : <em>{t.text}</em>}</GridItem>)}
  </GridContainer>
}

class SpeechRecognitionProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showTest: false,
      testStream: null,
      testLoading: false,
      testErr: null
    }
  }

  _testReset () {
    return {
      testStream: null,
      testLoading: false,
      testErr: null
    }
  }

  renderForm(profile) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady, token } = this.props

    const fillProfile = (profile) => {
      if (profile && profile.credentials && profile.engine === 'ibm') {
        const credentials = JSON.parse(profile.credentials)
        profile.ibm_apikey = credentials.apikey
        profile.ibm_serviceUrl = credentials.serviceUrl
      }
      if (profile && profile.credentials && profile.engine === 'google') {
        const credentials = JSON.parse(profile.credentials)
        profile.google_private_key = credentials.private_key
        profile.google_client_email = credentials.client_email
      }
      if (profile && profile.credentials && profile.engine === 'azure') {
        const credentials = JSON.parse(profile.credentials)
        profile.azure_subscription_key = credentials.subscriptionKey
        profile.azure_region = credentials.region
      }
      if (profile && profile.credentials && profile.engine === 'awstranscribe') {
        const credentials = JSON.parse(profile.credentials)
        profile.awstranscribe_region = credentials.region
        profile.awstranscribe_accessKeyId = credentials.accessKeyId
        profile.awstranscribe_secretAccessKey = credentials.secretAccessKey
        profile.awstranscribe_bucket = credentials.bucket
      }
      return profile
    }

    const changeIbm = (change, values) => {
      change('credentials', JSON.stringify({ apikey: values.ibm_apikey, serviceUrl: values.ibm_serviceUrl }))
    }
    const changeGoogle = (change, values) => {
      change('credentials', JSON.stringify({ private_key: values.google_private_key, client_email: values.google_client_email }))
    }
    const changeAzure = (change, values) => {
      change('credentials', JSON.stringify({ subscriptionKey: values.azure_subscription_key, region: values.azure_region }))
    }
    const changeAwstranscribe = (change, values) => {
      change('credentials', JSON.stringify({ region: values.awstranscribe_region, accessKeyId: values.awstranscribe_accessKeyId, secretAccessKey: values.awstranscribe_secretAccessKey, bucket: values.awstranscribe_bucket }))
    }
    fillProfile(profile)

    const _isAudioStreamingSupported = (values) => {
      if (!recordAudioStreamingSupported()) return false
      if (values.engine === 'google') return true
      if (values.engine === 'kaldi') return true
      if (values.engine === 'ibm') return true
      return false
    }
    const _getAudioStreamingTooltip = (values) => {
      if (_isAudioStreamingSupported(values)) return 'Test Configuration with Live Voice Transcription'
      else if (values.engine === 'custom' || values.engine === 'default') return 'Please select a specific provider to test Live Voice Transcription'
      else return 'Live Voice Transcription from Browser not supported for this provider'
    }

    return (<>
      {!profile.id && <>
        <GridItem xs={12} largePadding>
          <Text header>Import from File</Text>
          <ImportPrisma 
            disableUrlUpload 
            label="Select/Drop Speech Recognition File" 
            disableHelper 
            uploadOnDrop
            allowedModel="speechRecognitionProfile"
          />
        </GridItem>
        <GridItem xs={12}>
          <Divider text="or" />
        </GridItem>
        <GridItem xs={12}>
          <Text header>Create a new Speech Recognition Profile</Text>
        </GridItem>
      </>}
      <Mutation
        mutation={profile.id ? UPDATE_SPEECHRECOGNITIONPROFILE : CREATE_SPEECHRECOGNITIONPROFILE}
        refetchQueries={[
          {
            query: SPEECHRECOGNITIONPROFILES_QUERY,
          },
        ]}
      >
        {(mutate, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (profile.id) {
                try {
                  const res = await mutate({
                    variables: {
                      id: profile.id,
                      profile: {
                        name: values.name,
                        engine: values.engine,
                        language: values.language || null,
                        bspEndpoint: values.bspEndpoint || null,
                        bspApiKey: values.bspApiKey || null,
                        bspSkipCache: values.bspEndpoint ? !!values.bspSkipCache : false,
                        credentials: values.credentials || null,
                        config: values.config || null
                      }
                    }
                  })
                  form.initialize(fillProfile(res.data.updateSpeechRecognitionProfile))
                  setAlertSuccessMessage('Profile updated')
                  onReady(profile.id)
                } catch(error) {
                  setAlertErrorMessage('Profile update failed', error)
                }
              } else {
                try {
                  const res = await mutate({
                    variables: {
                      profile: {
                        name: values.name,
                        engine: values.engine,
                        language: values.language || null,
                        bspEndpoint: values.bspEndpoint || null,
                        bspApiKey: values.bspApiKey || null,
                        bspSkipCache: values.bspEndpoint ? !!values.bspSkipCache : false,
                        credentials: values.credentials || null,
                        config: values.config || null
                      }
                    }
                  })
                  form.initialize(fillProfile(res.data.createSpeechRecognitionProfile))
                  setAlertSuccessMessage('Profile registered')
                  onReady(res.data.createSpeechRecognitionProfile.id)
                } catch(error) {
                  setAlertErrorMessage('Profile register failed', error)
                }
              }
            }}
            initialValues={profile}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values,
              form: { change },
              errors
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                <GridItem xs={12}>
                    <Card dense>
                      <CardHeader>
                        <Text header>1. Botium Configuration</Text>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                        <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Profile Name"
                      validate={composeValidators(required, async (value) => {
                        if (value) {
                          return validateSpeechRecognitionNameUnique(this.props.client, profile.id, value)
                        }
                      })}
                      data-unique="txtProfileName"
                    />
                  </GridItem>
                      <GridItem xs={12} sm={8}>
                      <Field
                        name="bspEndpoint"
                        component={renderTextField}
                        label="Custom Speech Processing Endpoint"
                        helperText="Endpoint for your own Botium Speech Processing installation"
                        validate={url}
                        data-unique="txtProfileEndpoint"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="bspApiKey"
                        component={renderPasswordField}
                        label="Custom API Key"
                        disabled={!values.bspEndpoint}
                        helperText="API Key for your own Botium Speech Processing installation"
                        data-unique="txtProfileApiKey"
                      />
                    </GridItem>
                    {values.bspEndpoint &&
                    <GridItem xs={12}>
                      <Field
                        name="bspSkipCache"
                        component={renderCheckbox}
                        label="Skip Caching in Custom Speech Processing installation"
                        type="checkbox"
                        data-unique="chkProfileSkipCache"
                      />
                    </GridItem>
                  }
                    </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12}>
                    <Card dense>
                      <CardHeader>
                        <Text header>2. Provider Configuration</Text>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                       
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="engine"
                      component={renderSelect}
                      label="Speech Recognition Provider"
                      validate={required}
                      data-unique="selProfileEngine"
                      helperText={values.engine === 'default' ? 'As configured in the Speech Processing service' : ''}
                      items={[
                        { key: 'default', label: 'Use default' },
                        { key: 'google', label: 'Google Speech' },
                        { key: 'ibm', label: 'IBM Speech To Text' },
                        { key: 'azure', label: 'Azure Speech' },
                        { key: 'awstranscribe', label: 'Amazon Transcribe' },
                        { key: 'kaldi', label: 'Kaldi' },
                        { key: 'custom', label: 'Custom Endpoint' },
                      ]}
                    />
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer>
                  {values.engine === 'ibm' && <>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="ibm_apikey"
                        component={renderPasswordField}
                        label="IBM Api Key"
                        data-unique="txtProfileIbmApiKey"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="ibm_serviceUrl"
                        component={renderTextField}
                        label="IBM Service Url"
                        data-unique="txtProfileIbmServiceUrl"
                      />
                    </GridItem>
                    <OnChange name="ibm_apikey">{() => changeIbm(change, values)}</OnChange>
                    <OnChange name="ibm_serviceUrl">{() => changeIbm(change, values)}</OnChange>
                  </>}
                  {values.engine === 'google' && <>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="google_client_email"
                        component={renderTextField}
                        label="Google Client Email"
                        helperText="From service account credentials file"
                        data-unique="txtProfileGoogleClientEmail"
                        validate={email}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="google_private_key"
                        component={renderTextArea}
                        label="Google Private Key"
                        helperText="From service account credentials file"
                        data-unique="txtProfileGooglePrivateKey"
                        validate={isRsaKey}
                      />
                    </GridItem>
                    <OnChange name="google_client_email">{() => changeGoogle(change, values)}</OnChange>
                    <OnChange name="google_private_key">{() => changeGoogle(change, values)}</OnChange>
                  </>}
                  {values.engine === 'azure' && <>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="azure_subscription_key"
                        component={renderPasswordField}
                        label="Azure Subscription Key"
                        data-unique="txtProfileAzureSubscriptionKey"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="azure_region"
                        component={renderTextField}
                        label="Azure Region"
                        data-unique="txtProfileAzureRegion"
                      />
                    </GridItem>
                    <OnChange name="azure_subscription_key">{() => changeAzure(change, values)}</OnChange>
                    <OnChange name="azure_region">{() => changeAzure(change, values)}</OnChange>
                  </>}
                  {values.engine === 'awstranscribe' && <>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="awstranscribe_region"
                        component={renderTextField}
                        label="AWS Region"
                        data-unique="txtProfileAwstranscribeRegion"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="awstranscribe_accessKeyId"
                        component={renderTextField}
                        label="AWS Access Key Id"
                        helperText={'Read/Write permissions required for S3 Bucket and Amazon Transcribe (for example "AmazonS3FullAccess" and "AmazonTranscribeFullAccess")'}
                        data-unique="txtProfileAwstranscribeAccessKeyId"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="awstranscribe_secretAccessKey"
                        component={renderPasswordField}
                        label="AWS Secret Access Key"
                        data-unique="txtProfileAwstranscribeSecretAccessKey"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="awstranscribe_bucket"
                        component={renderTextField}
                        label="S3 Bucket Name"
                        data-unique="txtProfileAwstranscribeBucket"
                      />
                    </GridItem>
                    <OnChange name="awstranscribe_region">{() => changeAwstranscribe(change, values)}</OnChange>
                    <OnChange name="awstranscribe_accessKeyId">{() => changeAwstranscribe(change, values)}</OnChange>
                    <OnChange name="awstranscribe_secretAccessKey">{() => changeAwstranscribe(change, values)}</OnChange>
                    <OnChange name="awstranscribe_bucket">{() => changeAwstranscribe(change, values)}</OnChange>
                  </>}   
                  {values.engine === 'custom' &&
                    <GridItem xs={12}>
                      <Field
                        className="CapabilitiesShort"
                        name="credentials"
                        component={renderCodeArea}
                        options={{ mode: 'application/json' }}
                        label="Custom Credentials"
                        codeFormat={prettyPrintJson}
                        validate={json}
                        data-unique="txtProfileCredentials"
                      />
                    </GridItem>
                  }
                  {values.engine !== 'default' && <>
                    <GridItem xs={12}>
                      <Field
                        className="CapabilitiesShort"
                        name="config"
                        component={renderCodeArea}
                        options={{ mode: 'application/json' }}
                        label="Custom Engine Configuration"
                        helperText="Consult the documentation of your STT engine"
                        codeFormat={prettyPrintJson}
                        validate={json}
                        data-unique="txtProfileConfig"
                      />
                    </GridItem>
                    
                  </>}
                  </GridContainer>
                  </GridItem>                           
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12}>
                  <Card>
                    <CardHeader>
                      <Text header>3. Language Profile</Text>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                      <GridItem xs={12} sm={6}>
                    <Query
                      query={SPEECH_STT_LANGUAGES_QUERY}
                      variables={{
                        id: profile && profile.id,
                        profile: {
                          name: '',
                          engine: values.engine,
                          language: '',
                          bspEndpoint: values.bspEndpoint,
                          bspApiKey: values.bspApiKey || null,
                          bspSkipCache: !!values.bspSkipCache,
                          credentials: values.credentials,
                          config: values.config
                        }
                      }}
                      skip={!values.engine || (errors && Object.keys(errors).filter(f => f !== 'name' && f !== 'language').length > 0)}
                    >
                      {({ loading, error, data }) => {
                        const err = error && extractErrorMessage(error)
                        const choices = data && data.speechSTTLanguages

                        if (loading || err || !choices || choices.length === 0) {
                          let endAdornment = null
                          if (loading) endAdornment = <LoadingIndicator />
                          else if (err) endAdornment = <Tooltip title={err}><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>
                          else endAdornment = <Tooltip title="No option found for selection, please enter value manually."><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>

                          return <Field
                            name="language"
                            component={renderTextField}
                            label="Language Code"
                            validate={required}
                            data-unique="txtProfileLanguage"
                            endAdornment={endAdornment}
                          />
                        } else {
                          return <Field
                            name="language"
                            component={renderSelect}
                            label="Language Code"
                            validate={required}
                            data-unique="selProfileLanguage"
                            items={choices.map(l => ({
                              key: l,
                              label: LanguageDisplayName(l),
                              flagIcon: l
                            }))} />
                          }
                        }
                      }
                    </Query>
                  </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                  </GridItem>         
                  
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtons={<>
                        {profile.id && (
                          <Mutation
                            mutation={DELETE_SPEECHRECOGNITIONPROFILE}
                            onCompleted={data => {
                              setAlertSuccessMessage('Profile deleted')
                              onReady()
                            }}
                            onError={error => {
                              setAlertErrorMessage('Profile deletion failed', error)
                            }}
                            refetchQueries={[
                              {
                                query: SPEECHRECOGNITIONPROFILES_QUERY
                              },
                            ]}
                          >
                            {(deleteProfile, { loading, error }) => (
                              <ConfirmationButton
                                confirmationText={`Are you sure you want to delete it ?`}
                                requireCheck={true}
                                onClick={() => {
                                  deleteProfile({
                                    variables: { id: profile.id },
                                  })
                                }}
                                data-unique="btnProfileDelete"
                                secondary
                                danger
                              >
                                <ShowIcon icon="trash" />
                                Delete Profile
                              </ConfirmationButton>
                            )}
                          </Mutation>
                        )}
                        {profile.id && <Button
                          data-unique="btnProfileExport"
                          onClick={() => downloadfileformpost(`${config.api.base}/modelexport/speechRecognitionProfile/${profile.id}`, token).then(() => setAlertSuccessMessage('Export successful')).catch(err => setAlertErrorMessage(err.message))}
                          secondary
                        >
                          <ShowIcon icon="external-link" />
                          Export Profile
                        </Button>}
                      </>}
                      rightButtons={<>
                        <Tooltip title={_getAudioStreamingTooltip(values)}>
                          <Button
                            onClick={() => this.setState({ showTest: true, ...(this._testReset()) })}
                            disabled={submitting || (errors && Object.keys(errors).filter(f => f !== 'name').length > 0) || !_isAudioStreamingSupported(values)}
                            data-unique="btnProfileTest"
                            secondary
                          >
                            <ShowIcon icon="microphone" />
                            Test
                          </Button>
                        </Tooltip>
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnProfileSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}                  
                          Save
                        </Button>
                      </>}
                    />
                    <ConfirmationDialog
                      cancelText="Close"
                      open={!!(this.state.showTest)}
                      onCancel={() => {
                        this.setState({ showTest: false, ...(this._testReset()) })
                      }}
                      title="Test Speech Recognition Profile">
                      <GridContainer>
                        <GridItem xs={4}>
                          <Button
                            onClick={async () => {
                              if (values.engine === 'azure') {
                                this.setState({ testErr: 'Live Voice Transcription from Browser not supported for Azure Speech' })
                                return
                              }
                              if (values.engine === 'awstranscribe') {
                                this.setState({ testErr: 'Live Voice Transcription from Browser not supported for Amazon Transcribe' })
                                return
                              }
                              this.setState({ testLoading: true })
                              try {
                                const engineRecordingConfig = () => {
                                  if (values.engine === 'google') return { ...(values.config ? JSON.parse(values.config) : {}), encoding: 'WEBM_OPUS' }
                                  if (values.engine === 'kaldi') return { ...(values.config ? JSON.parse(values.config) : {}), 'content-type': 'audio/webm' }
                                  if (values.engine === 'ibm') return { ...(values.config ? JSON.parse(values.config) : {}), 'contentType': 'audio/webm' }
                                  return {}
                                }
                                const { data } = await this.props.client.query({
                                  query: OPEN_SPEECHRECOGNITIONPROFILE_STREAM,
                                  variables: {
                                    id: profile && profile.id,
                                    profile: {
                                      name: '',
                                      engine: values.engine,
                                      language: values.language,
                                      bspEndpoint: values.bspEndpoint,
                                      bspApiKey: values.bspApiKey || null,
                                      bspSkipCache: !!values.bspSkipCache,
                                      credentials: values.credentials,
                                      config: JSON.stringify(engineRecordingConfig())
                                    }
                                  },
                                  fetchPolicy: 'network-only'
                                })
                                this.setState({
                                  testLoading: false,
                                  testStream: data.speechRecognitionProfileStream,
                                  testErr: null
                                })
                              } catch (err) {
                                this.setState({
                                  testLoading: false,
                                  testStream: null,
                                  testErr: err
                                })
                              }
                            }}
                            disabled={!!(this.state.testLoading || this.state.testStream)}
                            data-unique="btnProfileTestStart"
                          >
                            {this.state.testStream && <><LoadingIndicator alt /> Live Voice Transcription is running ...</>}
                            {!this.state.testStream && <><ShowIcon icon="microphone" /> Start Live Voice Transcription</>}
                          </Button>
                        </GridItem>
                        <GridItem xs={8}>
                          {this.state.testStream && 'Please speak into your microphone'}
                        </GridItem>
                        {this.state.testStream &&
                          <GridItem xs={12}>
                            <AudioStream stream={this.state.testStream}/>
                          </GridItem>
                        }
                        {this.state.testErr &&
                          <GridItem xs={12}>
                            <ErrorFormat err={this.state.testErr} supress/>
                          </GridItem>
                        }
                      </GridContainer>
                    </ConfirmationDialog>
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation></>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={SPEECHRECOGNITIONPROFILE_QUERY} variables={{ id }} fetchPolicy="network-only">
              {(queryResult) => <QueryStatus {...queryResult} query="speechRecognitionProfile">{(data) => this.renderForm(data.speechRecognitionProfile)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({ engine: 'default' })}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(settingsStyle),
  connect(
    state => ({ user: state.token.user, token: state.token.token }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  )
)(withApollo(SpeechRecognitionProfile))
