import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import moment from 'moment'

import { showTrialCountown } from 'actions/alert'

import featureUpdateStyle from 'assets/jss/material-dashboard-react/components/featureUpdateStyle.jsx'
import upgradeImg from 'assets/img/trial_countdown.png'

class TrialCountdownPopup extends React.Component {

  constructor(props) {
    super(props)
    const { license } = this.props
    this.daysLeft = Math.ceil((moment(license.validity).toDate() - new Date()) / (1000 * 60 * 60 * 24))
  }

  showPopup() {
    const { show } = this.props
    if (_.isNil(show) && this.daysLeft > 0 && this.daysLeft <= 3) {
      return true
    }
    return false
  }

  render() {
    const { showTrialCountown, license, classes } = this.props

    if (license.edition === 'mini' || license.edition === 'minishared') {
      return (<ConfirmationDialog
        open={this.showPopup()}
        cancelText="Continue with Botium"
        onCancel={() => {
          showTrialCountown(false)
        }}
        okText="Buy BOTIUM"
        okButtonClass={classes.featureUpdateButton}
        okButtonIcon="arrow-alt-circle-up"
        onOk={() => {
          window.open('https://info.cyara.com/contact-chatbot-testing-and-assurance-with-botium', '_blank')
          showTrialCountown(false)
        }}
        title="YOUR FEATURE UPRADE WILL EXPIRE SOON!"
        data-unique="dlgTrialCountdown"
      >
        <GridContainer style={{ lineHeight: '36px'}} alignItems="center" justify="center">
          <GridItem xs={12} sm={5} >
            <img alt="Countdown" src={upgradeImg} className={classes.entryImage} />
          </GridItem>
          <GridItem xs={12} sm={7} center>
              You have {this.daysLeft} days left in your Botium Free Trial!
          </GridItem>
        </GridContainer>
      </ConfirmationDialog>)
    }
    return null
  }
}

const TrialCountdownPopupState = connect(
  state => ({
    show: (state.trialcountdown && state.trialcountdown.show),
    license: state.settings.license
  }),
  { showTrialCountown }
)(withStyles(featureUpdateStyle)(withRouter(TrialCountdownPopup)))

export default TrialCountdownPopupState
