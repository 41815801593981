import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import { OnChange } from 'react-final-form-listeners'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, compose, graphql } from 'react-apollo'
// core components
import Tooltip from 'components/Tooltip/Tooltip'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { extractErrorMessage} from 'helper/graphHelper'
import ShowIcon from 'components/Icon/ShowIcon'
import { LanguageDisplayName } from 'components/Icon/FlagIcon'
import Table from 'components/Table/AdvancedTable.jsx'
import DateFormat from 'components/Info/DateFormat'
import ErrorFormat from 'components/Info/ErrorFormat'
import NativeTooltip from 'components/Tooltip/NativeTooltip'
import ShortenedText from 'components/Typography/ShortenedText'
import AudioButton from 'components/Button/AudioButton'
import config from 'config'

import {
  SPEECH_SUPPORTED_VOICES_QUERY,
  SPEECH_EFFECTS_QUERY,
  SPEECH_TTS_LANGUAGES_QUERY,
  SPEECH_JOBS,
  SPEECH_JOB,
  SPEECH_JOB_SUBSCRIPTION
} from '../TestSets/gql'
import { SPEECHSYNTHESISPROFILES_QUERY, SPEECHNOISEPROFILES_QUERY } from '../Settings/gql'

import Text from 'components/Typography/Text.jsx'
import { renderSelect, required } from '../../components/Form/Form.js'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'
import TestSessionProgress from '../TestSessions/TestSessionProgress.jsx'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'

export const SpeechTTSSelectionPanel = compose(
  connect(
    state => ({ license: state.settings.license, features: state.settings.features })
  ),
  graphql(SPEECHSYNTHESISPROFILES_QUERY, {
    skip: (props) => !props.features || !props.features.speechService,
    props: ({ data }) => ({
      speechSynthesisProfiles: data,
    }),
  })
)(({ disabled, values, form: { change }, speechSynthesisProfiles, profileField = 'profile', languageField = 'language', voicesField = 'voices', multiple = true }) => {

  return <GridContainer>
    <GridItem xs={12} sm={6}>
      <Field
        name={profileField}
        component={renderSelect}
        label="Speech Synthesis Profile"
        data-unique="selTestSetVoiceSynthesisProfile"
        disabled={disabled}
        items={speechSynthesisProfiles && speechSynthesisProfiles.speechSynthesisProfiles && speechSynthesisProfiles.speechSynthesisProfiles.map(l => ({
          key: l.id,
          label: l.name
        }))}
      /> 
      <Text helperText>You can create Speech Synthesis Profile here: <NavLink to={`/settings/voice/speechsynthesis`}> Link </NavLink></Text>
    </GridItem>
    <OnChange name={languageField}>
      {() => {
        change(voicesField, [])
      }}
    </OnChange>
    <OnChange name={profileField}>
      {(value) => {
        const ttsProfile = speechSynthesisProfiles && speechSynthesisProfiles.speechSynthesisProfiles && speechSynthesisProfiles.speechSynthesisProfiles.find(p => p.id === value)
        if (ttsProfile) {
          change(languageField, ttsProfile.language)
          setTimeout(() => {
            // Otherwise the OnChange handler for "language" will immediately clear ist
            if (ttsProfile.voice) change(voicesField, multiple ? [ttsProfile.voice] : ttsProfile.voice)
            else change(voicesField, multiple ? [] : null)
          }, 0)
        }
      }}
    </OnChange>
    <GridItem xs={12} sm={6}>
      <Query
        query={SPEECH_TTS_LANGUAGES_QUERY}
        variables={{
          id: values[profileField]
        }}
      >
      {({ loading, error, data }) => {
        const err = error && extractErrorMessage(error)
        const choices = data && data.speechTTSLanguages

        let endAdornment = null
        if (loading) endAdornment = <LoadingIndicator />
        else if (err) endAdornment = <Tooltip title={err}><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>

        return <Field
          name={languageField}
          component={renderSelect}
          label="Text Language"
          disabled={disabled || loading || !choices || choices.length === 0}
          endAdornment={endAdornment}
          validate={required}
          data-unique="selTestSetVoiceSynthesisLanguage"
          items={!choices ? [] : choices.map(l => ({
            key: l,
            label: LanguageDisplayName(l),
            flagIcon: l
          }))}
        />
      }}
      </Query>
    </GridItem>
    <GridItem xs={12}>
      <Query
        query={SPEECH_SUPPORTED_VOICES_QUERY}
        variables={{
          id: values[profileField]
        }}
      >
      {({ loading, error, data }) => {
        const err = error && extractErrorMessage(error)
        const choices = (data && data.speechSupportedVoices && data.speechSupportedVoices.filter(v => values[languageField] && v.language === values[languageField])) || []

        let endAdornment = null
        if (loading) endAdornment = <LoadingIndicator />
        else if (err) endAdornment = <Tooltip title={err}><Text warning><ShowIcon icon="exclamation-circle" /></Text></Tooltip>

        return <Field
          name={voicesField}
          component={renderSelect}
          multiple={!!multiple}
          label={multiple ? 'Select Voices' : 'Select Voice'}
          disabled={disabled || loading || !choices || choices.length === 0}
          endAdornment={endAdornment}
          validate={required}
          data-unique="selTestSetVoiceSynthesisVoices"
          items={choices.map((v, i) => ({
            key: v.name,
            label: `${v.name} - ${v.gender}`,
            flagIcon: v.language
          }))} />
      }}
      </Query>
    </GridItem>
  </GridContainer>
})

export const SpeechEffectsSelectionPanel = compose(
  connect(
    state => ({ license: state.settings.license, features: state.settings.features })
  ),
  graphql(SPEECHNOISEPROFILES_QUERY, {
    skip: (props) => !props.features || !props.features.speechService,
    props: ({ data }) => ({
      speechNoiseProfiles: data,
    }),
  })
)(({ disabled, values, form: { change }, selectEffectsRequired = false, speechNoiseProfiles, profileField = 'effectsProfile', effectsField = 'effects' }) => {

  return <GridContainer>
    <GridItem xs={12} sm={6}>
      <Field
        name={profileField}
        component={renderSelect}
        label="Voice Effects Profile"
        data-unique="selTestSetVoiceEffectsProfile"
        disabled={disabled}
        items={speechNoiseProfiles && speechNoiseProfiles.speechNoiseProfiles && speechNoiseProfiles.speechNoiseProfiles.map(l => ({
          key: l.id,
          label: l.name
        }))}        
      />
      <Text helperText>You can create Voice Effects here: <NavLink to={`/settings/voice/noise`}>Link</NavLink></Text>
    </GridItem>
    <OnChange name={profileField}>
      {(value) => {
        const noiseProfile = speechNoiseProfiles && speechNoiseProfiles.speechNoiseProfiles && speechNoiseProfiles.speechNoiseProfiles.find(p => p.id === value)
        if (noiseProfile) {
          change(effectsField, noiseProfile.steps.map(s => s.effect))
        }
      }}
    </OnChange>
    <GridItem xs={12} sm={6}>
      <Query
        query={SPEECH_EFFECTS_QUERY}
        variables={{
          id: values[profileField]
        }}
      >
      {({ loading, error, data }) => {
        const err = error && extractErrorMessage(error)
        const choices = data && data.speechEffects

        let helperText = null
        if (!loading) {
          if (err) helperText = <Text warning inline><ShowIcon icon="exclamation-circle" /> {err}</Text>
          else if (!choices || choices.length === 0) helperText = <Text warning inline><ShowIcon icon="exclamation-circle" /> No option found for selection.</Text>
        }

        return <>
          <Field
            name={effectsField}
            component={renderSelect}
            label="Select Effects"
            disabled={disabled || !choices || choices.length === 0}
            data-unique="selTestSetVoiceEffectsEffects"
            validate={selectEffectsRequired ? required : null}
            multiple
            helperText={helperText}
            items={choices && choices.length > 0 && choices.map(c => ({
              key: c.name,
              label: `${c.name}${c.description ? ': ' + c.description : ''}`
            }))}
          />
        </>
      }}
      </Query>
    </GridItem>
  </GridContainer>
})

export const SpeechJobsTable = compose(
  connect(
    state => ({ license: state.settings.license, features: state.settings.features })
  ),
  graphql(SPEECH_JOBS, {
    options: (props) => ({
      variables: {
        jobType: props.jobType || null,
        testSetId: props.testSetId || null,
        ids: props.ids || null
      },
      pollInterval: 5000
    }),
    skip: (props) => !props.features || !props.features.speechService,
    props: ({ data }) => ({
      speechJobs: data,
    }),
  })
)(({ name, speechJobs, ids }) => {

  const _isSpeechJobReady = (job) => job && (job.status === 'FAILED' || job.status === 'READY')
  const _renderSpeechJobStatus = (job, jobIndex, loading, err) => {
    if (loading) return <LoadingIndicator />
    if (err) return <Text danger><ShowIcon icon="times" /></Text>

    if (job && job.status === 'FAILED') {
      return <Text danger data-unique={`statusSpeechJob_Err${jobIndex}`}><ShowIcon icon="times" /></Text>
    } else if (job && job.status === 'READY') {
      return <Text success data-unique={`statusSpeechJob_Ready${jobIndex}`}><ShowIcon icon="check" /></Text>
    } else {
      return <Text warning data-unique={`statusSpeechJob_Loading${jobIndex}`}><LoadingIndicator /></Text>
    }
  }
  const _renderSpeechJobDetails = (job, jobIndex, loading, err) => {
    if (err) return <ErrorFormat err={err} suppress/>

    if (job && job.err) {
      return <ErrorFormat err={job.err} suppress/>
    } else if (job && job.outputFile) {
      return <Tooltip title={job.outputFile.join('/')}><AudioButton small audioSrc={`${config.api.base}/filebrowser/${job.outputFile.join('/')}`} /></Tooltip>
    }
    return null
  }
  const _renderSpeechJobSection = (job, jobIndex, fn) => {
    if (_isSpeechJobReady(job)) {
      return fn(job, jobIndex, false, null)
    } else {
      return <TestSessionProgress
        key={`${name}_${job.id}`}
        query={SPEECH_JOB}
        querySelector={data => data.speechJob}
        subscription={SPEECH_JOB_SUBSCRIPTION}
        subscriptionSelector={data => data.speechJobProgress}
        readySelector={_isSpeechJobReady}
        testSession={{ id: job.id }}>
        {({ testSessionProgress: speechJobProgress, testSessionProgressLoading: speechJobLoading, testSessionProgressErr: speechJobErr }) => {
          return fn(speechJobProgress, jobIndex, speechJobLoading, speechJobErr)
        }}
      </TestSessionProgress>
    }
  }

  return (
    <Table
      name={name}
      dataUnique={name}
      tableHeaderColor="primary"
      tableHead={[
        '',
        'Date',
        'Task(s)',
        'Type',
        'Result File(s)',
        ''
      ]}
      pageLoading={speechJobs.loading}
      pageErr={speechJobs.error}
      disableFooter={!!ids}
      tableData={speechJobs && speechJobs.speechJobs && speechJobs.speechJobs.map((job, jobIndex) => [
          () => _renderSpeechJobSection(job, jobIndex, _renderSpeechJobStatus),
          () => <DateFormat fromNow>{job.createdAt}</DateFormat>,
          () => <NativeTooltip title={job.data}><ShortenedText maxlength={200}>{job.title}</ShortenedText></NativeTooltip>,
          () => <ShortenedText maxlength={200}>{job.jobType}</ShortenedText>,
          () => <ShortenedText maxlength={200}>{job.outputFile}</ShortenedText>,
          () => _renderSpeechJobSection(job, jobIndex, _renderSpeechJobDetails)
        ])
      }
      onRefresh={() => speechJobs.refetch()}
    />
  )
})
