// the importing-exporting is complicated here, because webpack is not able to handle some.
// Capability/Helper.js is not able to read any of the imports, just directly DefaultConnector, DynamicConnector, and ConnectorsWithUI.
// Looks as a workaround against this: https://stackoverflow.com/questions/52724312/webpack-typeerror-cannot-read-property-properties-of-undefined

import { default as DefaultConnectorImported } from './DefaultConnector'
import { default as DynamicConnectorImported } from './DynamicConnector'
import { default as ConnectorsWithUIImported } from './ConnectorsWithUI'

export const DefaultConnector = DefaultConnectorImported
export const DynamicConnector = DynamicConnectorImported
export const ConnectorsWithUI = ConnectorsWithUIImported

export default {
  DefaultConnector,
  DynamicConnector,
  ConnectorsWithUI
}
