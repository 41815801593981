import React from 'react'
import { connect } from 'react-redux'
import config from 'config'
import copyToClipboard from 'copy-to-clipboard'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
// apollo
import { Query } from 'react-apollo'
// core components
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  url,
  composeValidators,
  renderTextField,
  renderSelect, renderCodeArea, prettyPrintJson, json
} from 'components/Form/Form'

import { APIKEYS_QUERY } from '../../views/Settings/gql'
import Button from '../Button/Button'
import { setAlertSuccessMessage } from '../../actions/alert'
import { capSetDescription, usedByCapabilitySet } from './Helper'

const PROFILE_SAMPLE = JSON.stringify({
  firstName: 'Bob',
  lastName: 'Franklin',
  age: 45
}, null, 2)

export function oracledaCaps2Form(caps) {
  return {
    oracleda: {
      url: (
        caps.find(c => c.name === 'ORACLE_WEBHOOK_URL') || { stringValue: '' }
      ).stringValue,
      secret: (
        caps.find(c => c.name === 'ORACLE_WEBHOOK_SECRET') || { stringValue: '' }
      ).stringValue,
      userId: (
        caps.find(c => c.name === 'ORACLE_USER_ID') || { stringValue: '' }
      ).stringValue,
      profile: (
        caps.find(c => c.name === 'ORACLE_PROFILE') || { jsonValue: '' }
      ).jsonValue
    }
  }
}

const capNamesMap = {
  'oracleda.url': 'ORACLE_WEBHOOK_URL',
  'oracleda.secret': 'ORACLE_WEBHOOK_SECRET',
  'oracleda.userId': 'ORACLE_USER_ID',
  'oracleda.profile': 'ORACLE_PROFILE'
}

export function oracledaForm2caps(values) {
  return [
    {
      name: 'ORACLE_WEBHOOK_URL',
      type: 'STRING',
      stringValue: values.oracleda.url || '',
    },
    {
      name: 'ORACLE_WEBHOOK_SECRET',
      type: 'STRING',
      stringValue: values.oracleda.secret || '',
    },
    {
      name: 'ORACLE_USER_ID',
      type: 'STRING',
      stringValue: values.oracleda.userId || '',
    },
    {
      name: 'ORACLE_PROFILE',
      type: 'JSON',
      jsonValue: values.oracleda.profile || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'oracleda' }
  ]
}

class OracleDigitalAssistantEditUnconnected extends React.Component {
  render() {
    const { disabled, setAlertSuccessMessage, capSetCapNames } = this.props
    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => {
      return (<React.Fragment>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <Field
              name="oracleda.outgoingUri"
              label="Oracle Outgoing Webhook URI"
              component={renderTextField}
              initialValue={config.api.ext + '/inbound?APIKEY=*Choose an API key*'}
              readOnly
              data-unique="txtOracleDigitalAssistantEditOutgoingUri"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['oracleda.outgoingUri']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['oracleda.outgoingUri'], 'You have to configure your Oracle Webhook Channel to use this URL as Outgoing Webhook URI, adding API Key as query parameter.')}
              endAdornment={
                <Button
                  onClick={() => {
                    copyToClipboard(values.oracleda.outgoingUri)
                    setAlertSuccessMessage(`Oracle Outgoing Webhook URI copied to clipboard`)
                  }}
                  justIcon
                  data-unique="btnOracleDigitalAssistantWebhookEditCopy"
                >
                  <FileCopyIcon />
                </Button>
              }
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Query query={APIKEYS_QUERY}>
              {({ loading, error, data }) => {
                return (
                  <Field
                    name="oracleda.apiKey"
                    component={renderSelect}
                    label="API key"
                    data-unique="selOracleDigitalAssistantEditApiKey"
                    loading={loading}
                    error={error}
                    items={data && data.apikeys && data.apikeys.map(a => {
                      return { key: a.key, label: a.name }
                    })}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['oracleda.apiKey']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['oracleda.apiKey'], 'Choose an API key to generate the Outgoing Webhook URI')}
                  />
                )
              }}
            </Query>
          </GridItem>
          <FormSpy subscription={{ form: true }} render={({ form: { change } }) => (
            <OnChange name="oracleda.apiKey">
              {(value, previous) => {
                if (value) {
                  change('oracleda.outgoingUri', `${config.api.ext}/inbound?APIKEY=${value}`)
                } else {
                  change('oracleda.outgoingUri', `${config.api.ext}/inbound?APIKEY=*Choose an API key*`)
                }
              }}
            </OnChange>
          )} />
          <GridItem xs={12} sm={6}>
            <Field
              name="oracleda.url"
              component={renderTextField}
              label="Webhook Url"
              validate={composeValidators(required, url)}
              data-unique="txtOracleDigitalAssistantEditUrl"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['oracleda.url']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['oracleda.url'], 'The Webhook Url field in your Oracle Webhook Channel.')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="oracleda.secret"
              component={renderTextField}
              label="Webhook Secret Key"
              validate={required}
              data-unique="txtOracleDigitalAssistantEditSecret"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['oracleda.secret']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['oracleda.secret'], 'The Webhook Secret Key field in your Oracle Webhook Channel.')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="oracleda.userId"
              component={renderTextField}
              label="User ID"
              data-unique="txtOracleDigitalAssistantEditUserId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['oracleda.userId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['oracleda.userId'], 'Optionally you are able to set a unique identifier for the user.')}
            />
          </GridItem>
          <GridItem xs={12} >
            <Field
              className="CapabilitiesShort"
              name="oracleda.profile"
              component={renderCodeArea}
              options={{ mode: 'application/json' }}
              label="Profile"
              codeFormat={prettyPrintJson}
              validate={json}
              data-unique="codeOracleDigitalAssistantEditProfile"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['oracleda.userId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['oracleda.userId'], 'Optionally you are able to set properties that represent the user, like firstName and LastName.')}
            />
            <Button data-unique="btnOracleDigitalAssistantEditProfile" link
              onClick={() => change('oracleda.profile', PROFILE_SAMPLE)}>Insert sample profile</Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>)
    }} />)
  }
}

const OracleDigitalAssistantEdit = connect(
  state => ({}),
  { setAlertSuccessMessage }
)(OracleDigitalAssistantEditUnconnected)
export { OracleDigitalAssistantEdit }