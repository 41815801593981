import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
// @material-ui/core components
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import Text from 'components/Typography/Text.jsx'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import { renderTextField, renderSelect, required, FormActionsToolbar } from 'components/Form/Form'

import TestSetContentSelector from 'views/TestSets/TestSetContentSelector'
import { RefetchChatbotQueries, CHATBOT_QUERY } from 'views/Chatbots/gql'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

const EXPORT_INTENTS = gql`
  mutation ExportToWatson(
    $chatbotId: ID!,
    $testSetId: ID!,
    $local: Boolean,
    $repositoryIds: [String!],
    $folderIds: [String!],
    $downloadlinkIds: [String!],
    $uploadmode: String!,
    $newWorkspaceName: String,
    $newWorkspaceLanguage: String
  ) {
    exportToWatson(
      chatbotId: $chatbotId
      testSetId: $testSetId
      local: $local
      repositoryIds: $repositoryIds
      folderIds: $folderIds
      downloadlinkIds: $downloadlinkIds
      uploadmode: $uploadmode
      newWorkspaceName: $newWorkspaceName
      newWorkspaceLanguage: $newWorkspaceLanguage
    ) { chatbotId log }
  }
`

const watsonLanguages = [
  { key: 'en', value: 'English (en)' },
  { key: 'ar', value: 'Arabic (ar)' },
  { key: 'zh-cn', value: 'Chinese (Simplified)' },
  { key: 'zh-tw', value: 'Chinese (Traditional)' },
  { key: 'cs', value: 'Czech (cs)' },
  { key: 'nl', value: 'Dutch (nl)' },
  { key: 'fr', value: 'French (fr)' },
  { key: 'de', value: 'German (de)' },
  { key: 'it', value: 'Italian (it)' },
  { key: 'ja', value: 'Japanese (ja)' },
  { key: 'ko', value: 'Korean (ko)' },
  { key: 'pt-br', value: 'Portuguese (Brazilian) (pt-br)' },
  { key: 'es', value: 'Spanish (es)' }
]

class WatsonExport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
      uploadResult: { }
    }
    this.empty = {
      local: true,
      repositories: [],
      folders: [],
      downloadlinks: [],
      uploadmode: 'new',
      newWorkspaceLanguage: 'en',
      testSetId: this.props.testSetId
    }
  }

  render() {
    const { uploading, uploadResult } = this.state
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props
    const { chatbotId } = this.props

    return (<Query query={CHATBOT_QUERY} variables={{ id: chatbotId }}>
      {({ error, data }) => {
        if (error) return <ErrorFormat err={error} />

        if (!data.chatbot) return null

        const watsonVersion = data.chatbot.capabilities.find(c => c.name === 'WATSON_ASSISTANT_VERSION')
        if (!watsonVersion || watsonVersion.stringValue !== 'V1') return <ErrorFormat err={'Supported for IBM Watson Assistant V1 only'} />

        return (
          <Mutation
            mutation={EXPORT_INTENTS}
            onCompleted={data => {
              this.setState({ uploading: false, uploadResult: data.exportToWatson })
              setAlertSuccessMessage('Conversation model exported')
            }}
            onError={error => {
              this.setState({ uploading: false })
              setAlertErrorMessage(`Exporting conversation model failed`, error)
            }}
            refetchQueries={[
              ...RefetchChatbotQueries()
            ]}
          >
            {(exportToWatson, { loading, error }) => (
              <Form
                onSubmit={values => {
                  this.setState({ uploading: true, uploadResult: {} })
                  const variables = {
                    chatbotId: chatbotId,
                    testSetId: values.testSetId,
                    local: !!values.local,
                    repositoryIds: values.repositories || [],
                    folderIds: values.folders || [],
                    downloadlinkIds: values.downloadlinks || [],
                    uploadmode: values.uploadmode,
                    newWorkspaceName: values.newWorkspaceName || null,
                    newWorkspaceLanguage: values.newWorkspaceLanguage || null
                  }
                  exportToWatson({ variables })
                }}
                initialValues={this.empty}
                validate={(values) => this.state.contentValidate ? this.state.contentValidate(values) : null}
                render={({ handleSubmit, submitting, invalid, values }) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <TestSetContentSelector addFormValidate={(fn) => this.setState({ contentValidate: fn })}/>
                      <GridItem xs={12}>
                        <Field
                          name="uploadmode"
                          component={renderSelect}
                          label="What Test Cases do you want to import ?"
                          validate={required}
                          data-unique="selWatsonEditUploadMode"
                          items={[
                            { key: 'new', label: 'Create a blank IBM Watson Assistant workspace with user examples from Botium' },
                            { key: 'copy', label: 'Copy this IBM Watson Assistant workspace and extend it with user examples from Botium' },
                            { key: 'update', label: 'Extend this IBM Watson Assistant workspace with user examples from Botium' },
                          ]}
                        />
                      </GridItem>
                      {(values.uploadmode === 'new' || values.uploadmode === 'copy') &&
                        <GridItem xs={12}>
                          <Field
                            name="newWorkspaceName"
                            component={renderTextField}
                            label="Workspace Name"
                            data-unique="txtWatsonExportWorkspaceName"
                          />
                        </GridItem>
                      }
                      {values.uploadmode === 'new' &&
                        <GridItem xs={12}>
                          <Field
                            name="newWorkspaceLanguage"
                            component={renderSelect}
                            label="Workspace Language"
                            data-unique="selWatsonExportNewWorkspaceLanguage"
                            items={watsonLanguages.map(l => {
                              return { key: l.key, label: l.value }
                            })}
                          />
                        </GridItem>
                      }
                      <GridItem xs={12} largePadding>
                        <FormActionsToolbar rightButtons={
                          <Button
                            type="submit"
                            disabled={uploading || submitting || invalid}
                            data-unique="btnWatsonExportUploadUserExamples"
                          >
                            {uploading && <><LoadingIndicator /> Upload to IBM Watson Assistant is running ...</>}
                            {!uploading && <><CloudUploadIcon /> Upload user examples to IBM Watson Assistant</>}
                          </Button>
                        }/>
                      </GridItem>
                      {uploadResult && uploadResult.log && uploadResult.log.map((l, i) => <GridItem xs={12} key={i}><Text bold>{l}</Text></GridItem>)}
                      {uploadResult && uploadResult.chatbotId &&
                        <GridItem xs={12}>
                          Connected <NavLink to={`/chatbots/view/${uploadResult.chatbotId}`}>new IBM Watson Assistant workspace</NavLink> to Botium
                        </GridItem>
                      }
                    </GridContainer>
                  </form>
                )}
              />
            )}
          </Mutation>
        )
      }}
    </Query>)
  }
}

WatsonExport.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  testSetId: PropTypes.string
}

export default withRouter(connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(WatsonExport))
