import {
  drawerWidth,
  transition,
  container,
} from 'assets/jss/material-dashboard-react.jsx'

const appStyle = theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    background: theme.colors.backgroundPrimary
  },
  wrapperDark: {
    background: theme.colors.common.backgroundDark
  },
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
    zIndex: 0
  },
  content: {
    marginTop: '10px',
    padding: '10px 5px',
    minHeight: 'calc(100vh - 170px)',
  },
  container,
  entryContainer: {
    ...container,
    height: '100vh'
  },
  map: {
    marginTop: '70px',
  }
})

export default appStyle
