import { HIDE_MAINTENANCE_ID } from '../constants'

export default function maintenancepopup(state = {}, action) {
  switch (action.type) {
    case HIDE_MAINTENANCE_ID:
      return {
        hideMaintenanceIds: [
          ...(state.hideMaintenanceIds || []),
          action.payload.id
        ]
      }
    default:
      return state
  }
}
