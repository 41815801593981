import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import {NavLink} from 'react-router-dom'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import NavbarHeader from 'components/Header/NavbarHeader'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {UTTERANCE_BY_UTTERANCE_KEY_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'
import {EMPTY_UTTERANCE} from './helper'
import {decodeURIComponentWeak} from 'views/helper'

class IntentMismatchProbability extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTable(utteranceStruct) {
    const data = utteranceStruct ? utteranceStruct.intent.actuals : []
    let testSessionId = this.props.match.params.testSessionId

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Intent', 'Confidence']}
        tableData={data.map(entry => {
          return [
            () => <NavLink to={`/nlp/projects/view/${this.props.match.params.testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(entry.name)}`} data-unique={`btnCoachIntentMismatchProbabilityIntentConfidenceName_${encodeURIComponent(entry.name)}`}>
              {entry.name}
            </NavLink>,
            !_.isNil(entry.confidence) ? entry.confidence.toFixed(4) : 'N/A'
          ]
        })}
      />
    )
  }

  render() {
    const {utteranceData} = this.props

    let progressOrError = renderProgressOrError(utteranceData)
    if (progressOrError) {
      return progressOrError
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{this.renderTable(utteranceData.trainerUtterance)}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(UTTERANCE_BY_UTTERANCE_KEY_QUERY, {
    props: ({data}) => ({
      utteranceData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          utteranceKey: decodeURIComponentWeak(props.match.params.utteranceKey)
        },
      }
    }
  })
)(IntentMismatchProbability)

export const IntentMismatchProbabilityNavbarHeader = compose(
  graphql(UTTERANCE_BY_UTTERANCE_KEY_QUERY, {
    props: ({data}) => ({
      utteranceData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          utteranceKey: decodeURIComponentWeak(props.match.params.utteranceKey)
        },
      }
    }
  })
)(({ utteranceData }) => {
  let title = 'Predicted intents of utterance '
  if (utteranceData && utteranceData.trainerUtterance && utteranceData.trainerUtterance.utterance) {
    title += `"${utteranceData.trainerUtterance.utterance}"`
  } else {
    title += EMPTY_UTTERANCE
  }
  return <NavbarHeader text={title} />
})
