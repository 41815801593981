import React from 'react'
import {NavLink} from 'react-router-dom'

import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import dashboardStyle from '../../../../assets/jss/material-dashboard-react/views/dashboardStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import _ from 'lodash'
import Text from 'components/Typography/Text'

export const UtterancePrediction = withStyles(dashboardStyle)(class Keywords extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  renderValue (val, options) {
    const {classes} = this.props
    const renderFormattedVal = (val, options) => {
      const normalizedVal = _.isNumber(val) ? val.toFixed(2) : (_.isNil(val) ? 'N/A' : val)
      if (options && options.bold) {
        return <Text info><b>{normalizedVal}</b></Text>
      } else if (options && options.h7) {
        return <h7 className={classes.cardTitle}>{normalizedVal}</h7>
      } else if (options && options.important) {
        return <Text>{normalizedVal}</Text>
      } else {
        return <Text info>{normalizedVal}</Text>
      }
    }

    return <GridItem xs={6} sm={6} md={6}>{renderFormattedVal(val, options)}</GridItem>
  }

  render() {
    const { classes, utteranceStruct, testSessionId } = this.props
    const renderIntentStat = (utteranceStruct, testSessionId) => {
      const intent = utteranceStruct && utteranceStruct.intent && utteranceStruct.intent.actual
      const intentIncomprehension = utteranceStruct && utteranceStruct.intent && utteranceStruct.intent.incomprehension
      const intentConfidence = utteranceStruct && utteranceStruct.intent && utteranceStruct.intent.confidenceReal

      return (<>
        <GridContainer>
          {this.renderValue('Intent', {important: true})}
          <GridItem xs={6} sm={6}>
            <div className={classes.stats}>
              {!intent && <Text important data-unique={`txtCoachUtterancePredictionIntent`}>N/A</Text>}
              {!!intent && testSessionId && <NavLink to={`/testsessions/cview/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(intent)}`} data-unique={`btnCoachUtterancePredictionIntent`}>	                <Text important data-unique={`txtCoachUtterancePredictionIntent`}>{intent}</Text>
              </NavLink>}
              {!!intent && !testSessionId && <Text important data-unique={`txtCoachUtterancePredictionIntent`}>{intent}</Text>}
            </div>
          </GridItem>
          {this.renderValue('Confidence')}
          {this.renderValue(intentConfidence)}
          {this.renderValue('Incomprehension')}
          {this.renderValue(intentIncomprehension ? 'yes' : (intentIncomprehension === false ? 'no' : 'N/A'))}
        </GridContainer>
      </>)
    }
    const renderEntityStat = (utteranceStruct, testSessionId) => {
      const entities = (utteranceStruct && utteranceStruct.entity) ? utteranceStruct.entity.actual : []

      return (<>
        <GridContainer>
          {!entities.length && this.renderValue('Entity', {important: true})}
          {!entities.length && this.renderValue('N/A')}
          {!!entities.length && entities.map((e, i) => {
            return (<React.Fragment key={i}>
              {entities.length && this.renderValue('Entity', {important: true})}
              <GridItem xs={6} sm={6}>
                <div className={classes.stats}>
                  {!e.name && <Text important>N/A</Text>}
                  {!!e.name && testSessionId && <NavLink to={`/testsessions/cview/${testSessionId}/entityconfidence/entityname/${encodeURIComponent(e.name)}`} data-unique={`btnCoachUtterancePredictionEntity_${encodeURIComponent(e.name)}`}>	                    <Text important>{e.name}</Text>
                  </NavLink>}
                  {!!e.name && !testSessionId && <Text important>e.name</Text>}
                </div>
              </GridItem>
              {this.renderValue('Confidence')}
              {this.renderValue(_.isNumber(e.confidence) ? e.confidence : 'N/A')}
              {this.renderValue('Value')}
              {this.renderValue(e.value || 'N/A')}
              {this.renderValue('')}
            </React.Fragment>)
          })}
        </GridContainer>
      </>)
    }
    if (!utteranceStruct) {
      return <Text warning>No NLP Information in chatbot response</Text>
    } else {
      return <>
        {renderIntentStat(utteranceStruct, testSessionId)}
        &nbsp;
        {renderEntityStat(utteranceStruct, testSessionId)}
      </>
    }
  }
})
