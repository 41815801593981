import token from './token'
import alert from './alert'
import featureupgrade from './featureupgrade'
import trialcountdown from './trialcountdown'
import supportpopup from './supportpopup'
import maintenancepopup from './maintenancepopup'
import table from './table'
import list from './list'
import dashboard from './dashboard'
import settings from './settings'
import feed from './feed'
import activity from './activity'
import testdatawizard from './testdatawizard'
import testcasefilter from './testcasefilter'
import namespace from './namespace'

export default {
  token,
  alert,
  featureupgrade,
  trialcountdown,
  supportpopup,
  maintenancepopup,
  table,
  list,
  dashboard,
  settings,
  feed,
  activity,
  testdatawizard,
  testcasefilter,
  namespace
}
