import React from 'react'
import protoParser from 'proto-parser'
import _ from 'lodash'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
// apollo
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  composeValidators,
  renderTextField,
  renderCodeArea, json, prettyPrintJson, jsonpath, renderSelect
} from 'components/Form/Form'

import Button from '../Button/Button'
import { capSetDescription, ExtractStringCapabilityValue, usedByCapabilitySet} from './Helper'

const REQUEST_MESSAGE_TEMPLATE = JSON.stringify({
  text: '{{msg.messageText}}'
}, null, 2)

export function grpcCaps2Form(caps) {
  let protoPackage = ''
  let services = []
  const grpcProto = caps.find(c => c.name === 'GRPC_PROTO')
  if (grpcProto && grpcProto.stringValue) {
    try {
      const parsedProto = parseProto(grpcProto.stringValue)
      protoPackage = parsedProto.protoPackage
      services = parsedProto.services
    } catch (err) {
    }
  }
  const protoService = ExtractStringCapabilityValue(caps, 'GRPC_PROTO_SERVICE', services.length > 0 ? services[0].name : '')
  const requestMethod = ExtractStringCapabilityValue(caps, 'GRPC_REQUEST_METHOD',
    services.length > 0 && services[0].methods.length > 0 ? services[0].methods[0].name : '')
  const foundService = _.find(services, s => s.name === protoService)

  return {
    grpc: {
      url: ExtractStringCapabilityValue(caps, 'GRPC_URL', ''),
      proto: ExtractStringCapabilityValue(caps, 'GRPC_PROTO', ''),
      protoPackage: ExtractStringCapabilityValue(caps, 'GRPC_PROTO_PACKAGE', protoPackage || ''),
      protoService,
      requestMethod,
      requestMessageTemplate: prettyPrintJson(ExtractStringCapabilityValue(caps, 'GRPC_REQUEST_MESSAGE_TEMPLATE', '')),
      responseTextsJsonPath: ExtractStringCapabilityValue(caps, 'GRPC_RESPONSE_TEXTS_JSONPATH', '$'),
      services: services,
      methods: foundService ? foundService.methods : []
    }
  }
}

const capNamesMap = {
  'grpc.url': 'GRPC_URL',
  'grpc.proto': 'GRPC_PROTO',
  'grpc.protoPackage': 'GRPC_PROTO_PACKAGE',
  'grpc.protoService': 'GRPC_PROTO_SERVICE',
  'grpc.requestMethod': 'GRPC_REQUEST_METHOD',
  'grpc.requestMessageTemplate': 'GRPC_REQUEST_MESSAGE_TEMPLATE',
  'grpc.responseTextsJsonPath': 'GRPC_RESPONSE_TEXTS_JSONPATH'
}

export function grpcForm2caps(values) {
  return [
    {
      name: 'GRPC_URL',
      type: 'STRING',
      stringValue: values.grpc.url || '',
    },
    {
      name: 'GRPC_PROTO',
      type: 'STRING',
      stringValue: values.grpc.proto || '',
    },    {
      name: 'GRPC_PROTO',
      type: 'STRING',
      stringValue: values.grpc.proto || '',
    },
    {
      name: 'GRPC_PROTO_PACKAGE',
      type: 'STRING',
      stringValue: values.grpc.protoPackage || '',
    },
    {
      name: 'GRPC_PROTO_SERVICE',
      type: 'STRING',
      stringValue: values.grpc.protoService || '',
    },
    {
      name: 'GRPC_REQUEST_METHOD',
      type: 'STRING',
      stringValue: values.grpc.requestMethod || '',
    },
    {
      name: 'GRPC_REQUEST_MESSAGE_TEMPLATE',
      type: 'STRING',
      stringValue: values.grpc.requestMessageTemplate || '',
    },
    {
      name: 'GRPC_RESPONSE_TEXTS_JSONPATH',
      type: 'STRING',
      stringValue: values.grpc.responseTextsJsonPath || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'grpc' }
  ]
}

const parseProto = (value) => {
  const protoDoc = protoParser.parse(value)
  let protoObject = protoDoc.root
  const protoPackage = protoDoc.package
  const namespaceDefinitions = protoPackage ? protoPackage.split('.') : []
  for (const nsDef of namespaceDefinitions) {
    protoObject = protoObject.nested[nsDef]
  }

  protoObject = protoObject.nested
  const services = []
  for (const key of _.keys(protoObject)) {
    if (protoObject[key].syntaxType === 'ServiceDefinition') {
      const service = protoObject[key]
      const methods = []
      for (const methodKey of _.keys(service.methods)) {
        methods.push({
          name: service.methods[methodKey].name
        })
      }
      services.push({
        name: protoObject[key].name,
        methods
      })
    }
  }

  return {
    protoPackage,
    services
  }
}

export class GRPCConnectorEdit extends React.Component {
  render() {
    const { disabled, capSetCapNames } = this.props
    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => (
      <GridContainer>
        <GridItem xs={12}>
          <Field
            className="CapabilitiesShort"
            name="grpc.proto"
            component={renderCodeArea}
            label="Proto File Content"
            validate={required}
            data-unique="codeGrpcEditProto"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['grpc.proto']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['grpc.proto'], 'Copy here the content of the proto file')}
          />
        </GridItem>
        <FormSpy subscription={{ form: true }} render={({ form:  { change }}) => (
          <OnChange name="grpc.proto">
            {async (value, previous) => {
              this.setProtoValues(value, previous, change)
            }}
          </OnChange>
        )} />
        <GridItem xs={12} sm={6}>
          <Field
            name="grpc.url"
            label="Server Url"
            component={renderTextField}
            validate={required}
            data-unique="txtGrpcEditUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['grpc.url']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['grpc.url'], 'URI syntax as defined in RFC 3986. E.g.: dns:///127.0.0.1:50051')}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="grpc.protoPackage"
            component={renderTextField}
            label="Package"
            data-unique="txtGrpcEditProtoPackage"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['grpc.protoPackage']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['grpc.protoPackage'], 'Filled automatically from Proto file content')}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="grpc.protoService"
            component={renderSelect}
            label="Service"
            validate={required}
            data-unique="selGrpcEditProtoService"
            items={values.grpc.services.map(s => {
              return { key: s.name }
            })}
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['grpc.protoService']) || disabled || values.grpc.services.length === 0}
            helperText={capSetDescription(capSetCapNames, capNamesMap['grpc.protoService'])}
          />
        </GridItem>
        <FormSpy subscription={{ form: true }} render={({ form:  { change }}) => (
          <OnChange name="grpc.protoService">
            {async (value, previous) => {
              if(value) {
                const foundService = _.find(values.grpc.services, s => s.name === value)
                change('grpc.methods', foundService ? foundService.methods : [])
                change('grpc.requestMethod', foundService && foundService.methods.length > 0 ? foundService.methods[0].name : '')
              } else {
                change('grpc.methods', [])
                change('grpc.requestMethod', '')
              }
            }}
          </OnChange>
        )} />
        <GridItem xs={12} sm={6}>
          <Field
            name="grpc.requestMethod"
            component={renderSelect}
            label="Request Method"
            validate={required}
            data-unique="selGrpcEditProtoService"
            items={values.grpc.methods.map(m => {
              return { key: m.name }
            })}
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['grpc.requestMethod']) || disabled || values.grpc.methods.length === 0}
            helperText={capSetDescription(capSetCapNames, capNamesMap['grpc.requestMethod'])}
          />
        </GridItem>
        <GridItem xs={12}>
          <Field
            className="CapabilitiesShort"
            name="grpc.requestMessageTemplate"
            component={renderCodeArea}
            options={{ mode: 'application/json' }}
            label="Request Message Template (JSON structure)"
            validate={composeValidators(required, json)}
            codeFormat={prettyPrintJson}
            data-unique="codeGrpcEditRequestMessageTemple"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['grpc.requestMessageTemplate']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['grpc.requestMessageTemplate'], 'Moustache template variables supported: msg')}
          />
          <Button data-unique="btnGrpcEditRequestMessageTemplate" link onClick={() => change('grpc.requestMessageTemplate', REQUEST_MESSAGE_TEMPLATE)}>Insert sample request message template</Button>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="grpc.responseTextsJsonPath"
            component={renderTextField}
            label="JSON-Path for bot response"
            validate={composeValidators(required, jsonpath)}
            data-unique="txtGrpcEditResponseTextsJsonPath"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['grpc.responseTextsJsonPath']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['grpc.responseTextsJsonPath'], 'Should return string or array of strings')}
          />
        </GridItem>
      </GridContainer>
    )}/>)
  }

  setProtoValues(value, previous, change) {
    if(!value) {
      change('grpc.protoPackage', '')
      change('grpc.protoService', '')
      change('grpc.requestMethod', '')
      change('grpc.methods', [])
      change('grpc.services', [])
    } else {
      try {
        const {protoPackage, services} = parseProto(value)
        change('grpc.protoPackage', protoPackage)
        change('grpc.services', services.length > 0 ? services : [])
        change('grpc.protoService', services.length > 0 ? services[0].name : '')
        change('grpc.methods', services.length > 0 ? services[0].methods : [])
        change('grpc.requestMethod', services.length > 0 && services[0].methods.length > 0 ? services[0].methods[0].name : '')
      } catch (err) {
        change('grpc.protoPackage', '')
        change('grpc.protoService', '')
        change('grpc.requestMethod', '')
        change('grpc.methods', [])
        change('grpc.services', [])
      }
    }
  }
}
