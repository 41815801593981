import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import _ from 'lodash'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import customTabsStyle from 'assets/jss/material-dashboard-react/components/customTabsStyle.jsx'

import { clearTableSettings, setTableSettings, defaultTableSettings } from 'actions/table'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'

const getTabIndex = (exact, location, tabs) => {
  if (location) {
    if (location.hash && location.hash === '#main') return 0
    for (let index = 0; index < tabs.length; index++) {
      if (
        tabs[index].locationPrefix &&
        (exact ? location.pathname === tabs[index].locationPrefix : location.pathname.startsWith(tabs[index].locationPrefix))
      ) {
        return index
      }
    }
  }
  return -1
}

class CustomTabs extends React.Component {
  constructor(props) {
    super(props)

    const { exactLocationMatch, tabs, location, initialValue, settings } = this.props

    this.state = { value: 0 }

    const tabIndexByLocation = getTabIndex(exactLocationMatch, location, tabs)
    if (!_.isNil(tabIndexByLocation) && tabIndexByLocation >= 0) {
      this.state.value = tabIndexByLocation
    } else if (!_.isNil(initialValue) && initialValue >= 0) {
      this.state.value = initialValue
    } else if (!_.isNil(settings.tabIndex)) {
      this.state.value = settings.tabIndex
    }
    if (this.state.value < 0 || this.state.value >= tabs.length) {
      this.state.value = 0
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { exactLocationMatch, tabs, location, initialValue, settings } = nextProps

    const tabIndexByLocation = getTabIndex(exactLocationMatch, location, tabs)
    if (!_.isNil(tabIndexByLocation) && tabIndexByLocation >= 0) {
      this.setState({
        value: tabIndexByLocation
      })
    } else if (!_.isNil(initialValue) && initialValue >= 0) {
      this.setState({
        value: initialValue
      })
    } else if (!_.isNil(settings.tabIndex)) {
      this.setState({
        value: settings.tabIndex
      })
    }
  }

  componentDidUpdate (prevProps) {
    const { name, exactLocationMatch, tabs, location, settings, setTableSettings, skipPersist} = this.props

    if (prevProps.location.pathname !== this.props.location.pathname) {
      const tabIndexByLocation = getTabIndex(exactLocationMatch, location, tabs)
      if (!_.isNil(tabIndexByLocation) && tabIndexByLocation >= 0) {
        this.setState({ value: tabIndexByLocation })
        if (!skipPersist) {
          setTableSettings(name || window.location.pathname, {
            ...settings,
            tabIndex: tabIndexByLocation
          })
        }
      }
    }
  }

  handleChange(value) {
    const { name, tabs, history, settings, setTableSettings, skipPersist } = this.props

    if (tabs[value].locationPrefix) {
      history.push(tabs[value].locationPrefix)
    } else {
      this.setState({ value })
      if (!skipPersist) {
        setTableSettings(name || window.location.pathname, {
          ...settings,
          tabIndex: value
        })
      }
    }
  }

  render() {
    const {
      classes,
      tabs,
      title,
      rtlActive,
      small
    } = this.props
    const cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleSmall]: small,
      [classes.cardTitleRTL]: rtlActive,
    })
    return (
      <GridContainer>
        <GridItem xs={12}>
          {title !== undefined ? (
            <div className={cardTitle}>{title}</div>
          ) : null}
          <Tabs
            value={this.state.value}
            onChange={(event, value) => this.handleChange(value)}
            classes={{
              root: classes.tabsRoot + (small ? ' ' + classes.tabsRootSmall : ''),
              indicator: classes.displayNone,
              scrollButtons: classes.scrollButtons,
              scrollable: classes.scrollable
            }}
            scrollable
            scrollButtons="auto"
          >
            {tabs.map((prop, key) => {
              const icon = prop.tabIcon ? { icon: prop.tabIcon } : {}
              return (
                <Tab
                  disableRipple
                  classes={{
                    root: classNames({
                      [classes.tabRootButton]: true,
                      [classes.tabRootButtonSmall]: small,
                      [classes.tabRootButtonRight]: prop.tabRight,
                      [classes.tabRootButtonRightMd]: prop.tabRightMd
                    }),
                    labelContainer: classes.tabLabelContainer,
                    label: classes.tabLabel,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  key={key}
                  label={prop.tabName}
                  disabled={prop.disabled}
                  data-unique={prop.dataUnique}
                  {...icon}
                />
              )
            })}
          </Tabs>
          </GridItem>
        <GridItem xs={12}>
          {tabs.map((prop, key) => {
            if (key === this.state.value) {
              return <div key={key}>{prop.tabContent}</div>
            }
            return null
          })}
        </GridItem>
      </GridContainer>
    )
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    }),
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
  initialValue: PropTypes.number,
  exactLocationMatch: PropTypes.bool
}

export default connect(
  (state, ownProps) => ({
    settings: Object.assign(
      {},
      defaultTableSettings,
      state.table[ownProps.name || window.location.pathname],
    ),
  }),
  { clearTableSettings, setTableSettings },
)(withRouter(withStyles(customTabsStyle)(CustomTabs)))
