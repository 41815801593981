import { SHOW_SUPPORT_POPUP } from '../constants'

export default function supportpopup(state = {}, action) {
  switch (action.type) {
    case SHOW_SUPPORT_POPUP:
      return {
        show: action.payload.show
      }
    default:
      return state
  }
}
