import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { compose, graphql, Mutation, Query, withApollo } from 'react-apollo'

import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/Button/Button'
import ErrorFormat from 'components/Info/ErrorFormat'
import AvatarImage from 'components/Avatar/AvatarImage'
import Tooltip from 'components/Tooltip/Tooltip'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import {
  minValue,
  parseInteger,
  renderAutoSuggest,
  renderNamespaceField,
  renderIntField,
  renderSelect,
  renderTextField,
  required
} from 'components/Form/Form'
import ShowIcon from 'components/Icon/ShowIcon'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import Field from 'components/Form/OptionalField'
import LastChange from 'components/Info/LastChange'
import DropdownButton from 'components/Button/DropdownButton'
import Text from 'components/Typography/Text'
import SimpleConfirmationDialog from 'components/Dialog/SimpleConfirmationDialog'

import CrawlerSessionStats from './CrawlerSessionStats'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import {
  CRAWLERPROJECTS_COUNT_PAGINATED_QUERY,
  CRAWLERPROJECTS_PAGINATED_QUERY,
  CRAWLERPROJECTS_QUERY,
  CRAWLERSESSIONS_QUERY,
  CREATE_CRAWLERPROJECT, DELETE_CRAWLERPROJECT,
  RefetchCrawlerProjectQueries
} from './gql'
import { CHATBOTS_DROPDOWN_QUERY } from '../Chatbots/gql'
import { TAGS_QUERY } from '../Settings/gql'

import crawlerprojectsStyle from 'assets/jss/material-dashboard-react/views/crawlerprojectsStyle.jsx'
import { hasAnyPermission, hasPermission } from 'botium-box-shared/security/permissions'
import { setAlertErrorMessage, setAlertSuccessMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable'
import CrawlerSessionIcon from './CrawlerSessionIcon'
import { addNamespaceToWhere, safeGetNamespaceFilteredList } from '../helper'

const emptyCrawlerProject = {
  name: 'New Crawler Project',
  entryPoints: ['hello', 'help'],
  numberOfWelcomeMessages: 0
}

class CrawlerProjects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCrawlerProjectExpanded: true,
      serverSidePagingKey: 0
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['CRAWLERPROJECTS_CREATE', 'CRAWLERPROJECTS_UPDATE'])
  }

  validate(values) {
    if ((values.numberOfWelcomeMessages === null || values.numberOfWelcomeMessages <= 0) && (values.entryPoints === null || values.entryPoints.length === 0)) {
      return {
        entryPoints: 'Either "Conversation Entrypoints" or "Number of Welcome Messages" has to be given',
        numberOfWelcomeMessages: 'Either "Conversation Entrypoints" or "Number of Welcome Messages" has to be given'
      }
    }
  }

  render() {
    const { chatbotsData, setAlertSuccessMessage, setAlertErrorMessage, history, classes, user, client, namespace } = this.props
    const initialNamespace = { namespace: namespace.selected ? namespace.selected.name : undefined}

    const chatbots = safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace)
    const connectorSelectItems = []
    if (chatbots) {
      chatbots.forEach(c => {
        connectorSelectItems.push({
          key: c.id,
          chatbot: c
        })
      })
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          {hasPermission(user, 'CRAWLERPROJECTS_CREATE') &&
            <ExpansionPanel expanded={this.state.newCrawlerProjectExpanded}>
              <ExpansionPanelSummary onClick={() => this.setState({ newCrawlerProjectExpanded: !this.state.newCrawlerProjectExpanded })}>
                Create new Crawler Project
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                <GridContainer nounset>
                  <GridItem xs={12}>
                    <Mutation
                      mutation={CREATE_CRAWLERPROJECT}
                      refetchQueries={[
                        ...RefetchCrawlerProjectQueries(),
                        {
                          query: TAGS_QUERY
                        }
                      ]}
                    >
                      {(mutateCrawlerProject) => (
                        <Form
                          mutators={{ ...arrayMutators }}
                          onSubmit={async (values, form) => {
                            try {
                              const crawlerProject = {
                                name: values.name,
                                namespace: values.namespace || null,
                                chatbot: {
                                  connect: {
                                    id: values.chatbotId,
                                  },
                                },
                                entryPoints: {
                                  set: values.entryPoints,
                                },
                                numberOfWelcomeMessages: values.numberOfWelcomeMessages
                              }
                              const res = await mutateCrawlerProject({
                                variables: {
                                  crawlerProject
                                }
                              })
                              form.initialize(res.data.createCrawlerProject)
                              setAlertSuccessMessage('Crawler Project registered')
                              history.push(`/testdatawizards/crawlerprojects/view/${res.data.createCrawlerProject.id}/settings/execution`)
                            } catch (error) {
                              setAlertErrorMessage(`Crawler Project registration failed`, error)
                            }
                          }}
                          validate={this.validate.bind(this)}
                          initialValues={Object.assign(emptyCrawlerProject, initialNamespace)}
                          render={({
                            handleSubmit,
                            submitting,
                            invalid,
                            form: { change },
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <UnsavedFormSpy />
                                <GridContainer>
                                  <GridItem md={6}>
                                    <Field
                                      name="name"
                                      component={renderTextField}
                                      label="Crawler Project Name"
                                      validate={required}
                                      disabled={!this.hasWritePermission()}
                                      data-unique="txtCrawlerProjectName"
                                    />
                                  </GridItem>
                                  <GridItem md={6}>
                                    <Field
                                      name="namespace"
                                      component={renderNamespaceField}
                                      forWrite
                                      label="Namespace"
                                      disabled={!this.hasWritePermission()}
                                      data-unique="txtCrawlerProjectNamespace"
                                    />
                                  </GridItem>
                                </GridContainer>
                                <GridContainer>
                                  <GridItem md={6} lg={4}>
                                    {chatbots && (
                                      <Field
                                        name="chatbotId"
                                        component={renderSelect}
                                        label="Connect to Chatbot"
                                        validate={required}
                                        data-unique="selCrawlerProjectChatbotId"
                                        items={connectorSelectItems}
                                        disabled={!this.hasWritePermission()}
                                      />
                                    )}
                                  </GridItem>
                                  <GridItem md={6} lg={4}>
                                    <Field
                                      name="entryPoints"
                                      component={renderAutoSuggest}
                                      label="Conversation Entrypoints"
                                      helperText="A list of text messages for Botium to start a conversation with the chatbot"
                                      disabled={!this.hasWritePermission()}
                                      data-unique="txtCrawlerProjectEntryPoints"
                                    />
                                  </GridItem>
                                  <GridItem md={6} lg={4}>
                                    <Field
                                      name="numberOfWelcomeMessages"
                                      component={renderIntField}
                                      label="Number of Welcome Messages"
                                      parse={parseInteger}
                                      format={undefined}
                                      validate={minValue(0)}
                                      disabled={!this.hasWritePermission()}
                                      data-unique="intCrawlerProjectNumberOfWelcomeMessages"
                                      helperText="Number of messages sent by the chatbot when the conversation is started"
                                    />
                                  </GridItem>
                                  <GridItem xs={12} className={classes.quickcreatcrawlerprojectbutton} right>
                                    <Button disabled={submitting || invalid || !this.hasWritePermission()}
                                      data-unique="btnCrawlerProjectSave"
                                      onClick={() => {
                                        change('type', 'custom')
                                        handleSubmit()
                                      }}
                                    >
                                      {submitting && <LoadingIndicator alt />}
                                      {!submitting && <ShowIcon icon="save" />}
                                      Create Crawler Project
                                    </Button>
                                  </GridItem>
                              </GridContainer>
                            </form>
                          )}
                        />
                      )}
                    </Mutation>
                  </GridItem>
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          }
          <Card>
            <CardHeader>
              <Text header>Botium Crawler Projects</Text>
              <Text subheader>
                Listing all Botium Crawler Projects
              </Text>
            </CardHeader>
            <CardBody noPadding>
              {this.state.confirmDeleteCrawlerProject &&
                <SimpleConfirmationDialog
                  danger
                showConfirmationDialog={!!this.state.confirmDeleteCrawlerProject}
                confirmationText={`When deleting the Crawler Project "${this.state.confirmDeleteCrawlerProject.name}", all configuration settings are removed. Crawler Results won't be deleted. Are you sure you want to delete it ?`}
                requireCheck={true}
                onOk={async () => {
                  try {
                    await client.mutate({
                      mutation: DELETE_CRAWLERPROJECT,
                      variables: {
                        id: this.state.confirmDeleteCrawlerProject.id
                      },
                      fetchPolicy: 'no-cache',
                      refetchQueries: [
                        {
                          query: CRAWLERPROJECTS_QUERY
                        }]
                    })
                    removeRecentListEntry({ url: `/testdatawizards/crawlerprojects/view/${this.state.confirmDeleteCrawlerProject.id}` })
                    setAlertSuccessMessage('Crawler Project deleted')
                    this.setState({ serverSidePagingKey: this.state.serverSidePagingKey + 1 })
                  } catch(error) {
                    setAlertErrorMessage('Crawler Project  deletion failed', error)
                  }
                  this.setState({ confirmDeleteCrawlerProject: null })
                }}
                onCancel={() => this.setState({ confirmDeleteCrawlerProject: null })}
              />
              }

                    <React.Fragment>
                      <GridContainer>
                        <GridItem xs={12} skipTableSpace>
                          <ServerSidePagingTable key={`CrawlerProjectsKey_${namespace?.selected?.name}_${this.state.serverSidePagingKey}`}
                            name="CrawlerProjectsTable"
                            gqlQuery={{
                              query: CRAWLERPROJECTS_PAGINATED_QUERY,
                              notifyOnNetworkStatusChange: true
                            }}
                            gqlCountQuery={{
                              query: CRAWLERPROJECTS_COUNT_PAGINATED_QUERY,
                              countValue: data => data.crawlerprojectsCount
                            }}
                            gqlVariables={(filterString) => addNamespaceToWhere(namespace, { where: { OR: [ { name_contains: filterString || '' }, { chatbot: { name_contains: filterString || '' } } ] } })}
                            tableHeaderColor="primary"
                            tableHead={[
                              { name: 'Name', orderByField: 'name', orderByDefault: 'asc' },
                              { name: 'Namespace', orderByField: 'namespace', orderByDefault: 'asc' },
                              { name: 'Last Change', orderByField: 'updatedAt' },
                              'Chatbot',
                              'Status', 'Convos', 'Utterances', 'Warnings', 'Errors',
                              { name: 'Actions', right: true }]
                            }
                            tableData={data =>
                              data && data.crawlerprojects &&
                              data.crawlerprojects.map(t => [
                                {
                                  value: t.name,
                                  href: `/testdatawizards/crawlerprojects/view/${t.id}`,
                                  width: 250
                                },
                                t.namespace ? {
                                  value: <div className={classes.namespace}>{t.namespace}</div>,
                                  href: `/namespaces/${t.namespace}`,
                                } : null,
                                () => {
                                  if(t.crawlerSessions.length > 0 && moment(t.crawlerSessions[0].createdAt).isAfter(moment(t.updatedAt))) {
                                    return <LastChange {...t.crawlerSessions[0]} />
                                  } else {
                                    return <LastChange {...t} />
                                  }
                                },
                                t.chatbot && {
                                  value: <Tooltip title={t.chatbot.name}><AvatarImage avatar={t.chatbot.avatar} containermode={t.chatbot.containermode} chatbotId={t.chatbot.id} /></Tooltip>,
                                  href: '/chatbots/view/' + t.chatbot.id,
                                },
                                () => (this.renderLastCrawlerSessionStatus(t.id)),
                                () => (this.renderLastCrawlerSessionStats(t.id, 'CONVOS')),
                                () => (this.renderLastCrawlerSessionStats(t.id, 'UTTERANCES')),
                                () => (this.renderLastCrawlerSessionStats(t.id, 'WARNINGS')),
                                () => (this.renderLastCrawlerSessionStats(t.id, 'ERRORS')),
                                () => <Tooltip title="Action"><DropdownButton
                                  dots
                                  items={[
                                    {
                                      id: 'open',
                                      icon: 'sitemap',
                                      name: 'Open',
                                      onClick: () => history.push(`/testdatawizards/crawlerprojects/view/${t.id}`)
                                    },
                                    {
                                      id: 'delete',
                                      icon: 'trash',
                                      name: 'Delete',
                                      onClick: () => this.setState({ confirmDeleteCrawlerProject: t })
                                    }
                                  ]} /></Tooltip>
                              ])}
                          />
                        </GridItem>
                      </GridContainer>
                    </React.Fragment>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  renderLastCrawlerSessionStats(crawlerProjectId, type) {
    return (<Query
      query={CRAWLERSESSIONS_QUERY}
      fetchPolicy="network-only"
      variables={{ crawlerProjectId, skip: 0, first: 1, orderBy: 'createdAt_DESC' }}
      notifyOnNetworkStatusChange={true}>
      {({ error, data }) => {
        if (error) {
          return <ErrorFormat err={error} />
        }

        if (data && data.crawlersessions !== undefined && data.crawlersessions.length > 0) {
          const lastCrawlerSession = data.crawlersessions[0]
          return <CrawlerSessionStats crawlerSession={lastCrawlerSession} type={type} />
        }
        return null
      }}
    </Query>)
  }

  renderLastCrawlerSessionStatus(crawlerProjectId) {
    return (<Query
      query={CRAWLERSESSIONS_QUERY}
      fetchPolicy="network-only"
      variables={{ crawlerProjectId, skip: 0, first: 1, orderBy: 'createdAt_DESC' }}
      notifyOnNetworkStatusChange={true}>
      {({ error, data }) => {
        if (error) {
          return <ErrorFormat err={error} />
        }

        if (data && data.crawlersessions !== undefined && data.crawlersessions.length > 0) {
          const lastCrawlerSession = data.crawlersessions[0]
          return <CrawlerSessionIcon crawlerSession={lastCrawlerSession} />
        }
        return null
      }}
    </Query>)
  }
}

export default compose(
  withStyles(crawlerprojectsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace }),
    { setAlertSuccessMessage, setAlertErrorMessage }
  ),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
    fetchPolicy: 'no-cache'
  }),
  withApollo
)(CrawlerProjects)

