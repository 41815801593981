import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
// apollo
import { Query } from 'react-apollo'
// core components
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

import {
  composeValidators,
  url,
  renderTextField,
  required, renderSelect
} from 'components/Form/Form'
import config from 'config'
import copyToClipboard from 'copy-to-clipboard'
import Button from '../Button/Button'
import { setAlertSuccessMessage } from '../../actions/alert'
import { APIKEYS_QUERY } from '../../views/Settings/gql'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function whatsappCaps2Form(caps) {
  return {
    whatsapp: {
      endpoint: (
        caps.find(c => c.name === 'WHATSAPP_ENDPOINT') || { stringValue: 'mychatbot' }
      ).stringValue,
      webhookUrl: (
        caps.find(c => c.name === 'WHATSAPP_WEBHOOKURL') || { stringValue: '' }
      ).stringValue,
      recipientId: (
        caps.find(c => c.name === 'WHATSAPP_RECIPIENTID') || { stringValue: '' }
      ).stringValue
    },
  }
}

const capNamesMap = {
  'whatsapp.endpoint': 'WHATSAPP_ENDPOINT',
  'whatsapp.webhookUrl': 'WHATSAPP_WEBHOOKURL',
  'whatsapp.recipientId': 'WHATSAPP_RECIPIENTID',
}

export function whatsappForm2caps(values) {
  const capabilities = [
    {
      name: 'WHATSAPP_ENDPOINT',
      type: 'STRING',
      stringValue: values.whatsapp.endpoint,
    },
    {
      name: 'WHATSAPP_WEBHOOKURL',
      type: 'STRING',
      stringValue: values.whatsapp.webhookUrl,
    },
    {
      name: 'WHATSAPP_RECIPIENTID',
      type: 'STRING',
      stringValue: values.whatsapp.recipientId || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'whatsapp' }
  ]

  return capabilities
}

class WhatsappEditUnconnected extends React.Component {
  render() {
    const { disabled, setAlertSuccessMessage, capSetCapNames } = this.props
    return (
      <GridContainer>
        <GridItem xs={10} sm={5}>
          <Field
            name="whatsapp.inboundUrl"
            label="Whatsapp Outgoing Webhook URI"
            component={renderTextField}
            initialValue={config.api.ext + '/inbound?APIKEY=*Choose an API key*'}
            readOnly
            data-unique="txtWhatsappWebhookEditInboundUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['whatsapp.inboundUrl']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['whatsapp.inboundUrl'], 'You have to configure your Whatsapp Webhook (the component using the Whatsapp Business API to send Outbound messages) to use this URL (instead of the Whatsapp Business API), adding API Key as query parameter.')}
            endAdornment={<FormSpy
              subscription={{ values: true }}
              render={({ values }) => (
                <Button
                  onClick={() => {
                    copyToClipboard(values.whatsapp.inboundUrl)
                    setAlertSuccessMessage(`Succesful`)
                  }}
                  justIcon
                  data-unique="btnWhatsappWebhookEditCopy"
                >
                  <FileCopyIcon />
                </Button>
              )}
            />}
          />
        </GridItem>
        <GridItem xs={2} sm={1}>

        </GridItem>
        <GridItem xs={12} sm={6}>
          <Query query={APIKEYS_QUERY}>
            {({ loading, error, data }) => {
              return (
                <Field
                  name="whatsapp.apiKey"
                  component={renderSelect}
                  label="API Key"
                  data-unique="selWhatsappWebhookEditApiKey"
                  loading={loading}
                  error={error}
                  items={data && data.apikeys && data.apikeys.map(a => {
                    return { key: a.key, label: a.name }
                  })}
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['whatsapp.apiKey']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['whatsapp.inboundUrl'], 'Choose an API Key to generate the inbound URL')}
                />
              )
            }}
          </Query>
        </GridItem>
        <FormSpy subscription={{ form: true }} render={({ form: { change } }) => (
          <OnChange name="whatsapp.apiKey">
            {(value, previous) => {
              if (value) {
                change('whatsapp.inboundUrl', `${config.api.ext}/inbound?APIKEY=${value}`)
              } else {
                change('whatsapp.inboundUrl', `${config.api.ext}/inbound?APIKEY=*Choose an API key*`)
              }
            }}
          </OnChange>
        )} />
        <GridItem xs={12} sm={6}>
          <Field
            name="whatsapp.webhookUrl"
            component={renderTextField}
            label="Whatsapp Webhook URL"
            validate={composeValidators(required, url)}
            data-unique="txtWhatsappEditWebhookUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['whatsapp.apiKey']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['whatsapp.apiKey'], 'The Url of your Whatsapp Business API Webhook')}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="whatsapp.recipientId"
            component={renderTextField}
            label="Recipient Whatsapp Id"
            data-unique="txtWhatsappEditRecipientId"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['whatsapp.recipientId']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['whatsapp.recipientId'], 'By default, a unique id is generated.')}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

const WhatsappEdit = connect(
  state => ({}),
  { setAlertSuccessMessage }
)(WhatsappEditUnconnected)
export { WhatsappEdit }