import { gql } from 'apollo-boost'
import { FULL_TRAINER_OVERALL_STAT_FRAGMENT, LIST_TRAINER_OVERALL_STAT_FRAGMENT } from '../Coach/gql'


export const TRAINERSESSION_OVERALL_FRAGMENT = gql`
  fragment TrainerSessionOverall on TrainerSession {
    id
    status
    cleanedUp
    err
    overallStat {
      ...FullTrainerOverallStat
    }
  }
  ${FULL_TRAINER_OVERALL_STAT_FRAGMENT}
`

export const TRAINERSESSION_LIST_FRAGMENT = gql`
  fragment TrainerSessionList on TrainerSession {
    id
    status
    cleanedUp
    err
    overallStat {
      ...ListTrainerOverallStat
    }
  }
  ${LIST_TRAINER_OVERALL_STAT_FRAGMENT}
`
