const linkStyle = theme => ({
  link: {
    color: theme.colors.primary,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    display: 'inline',
    margin: 0,
    padding: 0,
    textDecoration: 'underline',
    '&:hover':{
      textDecoration: 'none'
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      padding: 3,
      borderRadius: 5
    },
  },
  gray: {
    color: theme.colors.primary
  },
  noUnderline: {
    textDecoration: 'none!Important'
  },
  hoverUnderline: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

export default linkStyle
