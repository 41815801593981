import React, { PureComponent } from 'react'
import NumberFormat from 'react-number-format'
import {
  Radar, RadarChart, PolarGrid,
  PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip
} from 'recharts'

import _ from 'lodash'

import {uniqueDataKey} from './helper'
export default class Radarchart extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      id: Math.random().toString(36).substring(7)
    }
  }

  getGreenToRedColor(errorPercent) {
    const normalized = errorPercent < 0 ? 0 : (errorPercent > 1 ? 1 : errorPercent)
    const gradient = Math.floor(255 * normalized)
    return '#' + gradient.toString(16).padStart(2, '0') + (255 - gradient).toString(16).padStart(2, '0') + '00'
  }

  percentAsHTML(errorPercent) {
    if (_.isNil(errorPercent)) {
      return null
    }
    return  errorPercent < 0 ? 0 : (errorPercent > 1 ? 1 : Math.round(errorPercent))
  }

  render() {
    const {
      /*true if greater values are better*/
      reversed = false,
      label1,
      valueField1,
      errorPercent1,
      label2,
      valueField2,
      errorPercent2,
      labelField,
      onClick,
      tooltipLabelFormatter
    } = this.props

    let {data} = this.props
    data = uniqueDataKey(data, labelField)
    let {
    tooltipFormatter,
    } = this.props

    const maxVal1 = (data && data.length > 0 && valueField1) ? Math.max.apply(Math, data.map(d => d[valueField1] ? d[valueField1] : 0)) : 0
    const maxVal2 = (data && data.length > 0 && valueField2) ? Math.max.apply(Math, data.map(d => d[valueField2] ? d[valueField2] : 0)) : 0

    if (!tooltipFormatter) {
      tooltipFormatter = (value) => _.isNil(value) ? 'N/A' : <NumberFormat value={value} displayType={'text'} decimalScale={4}/>
    }
    const TiltedAxisTick = (props) => {
      const { x, y, payload, data, errorPercent } = props

      const percent = this.percentAsHTML(data[payload.index][errorPercent])
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            textAnchor={payload.index % (data.length / 2 ) === 0 ? 'middle' : payload.index < data.length / 2 ? 'start' : 'end'}
            dominantBaseline="middle"
            // fill={errorPercent ? (this.getGreenToRedColor(data[payload.index][errorPercent])) : '"#666"'}
            fill={'#666'}
            fontSize={(!_.isNil(percent) ? (90 + 40 * percent) : 100) + '%'}
            fontWeight={(!_.isNil(percent) ? (400 * percent) : 50)}
            //transform="rotate(-45)"
          >
            {payload.value && (payload.value.length > 18 ? payload.value.substring(0, 15) + '...' : payload.value)}
          </text>
        </g>
      )
    }

    // determining the color of the max gradient
    let maxGradientPercent = null
    for (const entry of data) {
      if (!_.isNil(errorPercent1)) {
        if (_.isNil(maxGradientPercent) || (!reversed && data[errorPercent1] > maxGradientPercent) || (reversed && data[errorPercent1] < maxGradientPercent)) {
          maxGradientPercent = entry[errorPercent1]
        }
      }
      if (!_.isNil(errorPercent2)) {
        if (_.isNil(maxGradientPercent) || (!reversed && data[errorPercent2] > maxGradientPercent) || (reversed && data[errorPercent2] < maxGradientPercent)) {
          maxGradientPercent = entry[errorPercent2]
        }
      }
    }
    // supposed: the max peak reaches the area of max gradient. In other words: max peak reaches the edge of the RadarChart
    const maxGradientColor = this.getGreenToRedColor(maxGradientPercent)

    return (
      <ResponsiveContainer minWidth={400} height={300}>
        <RadarChart data={data} onClick={onClick && ((e) => e && onClick(e.activePayload[0].payload))}>
          <defs>
            {/*https://oreillymedia.github.io/Using_SVG/extras/ch12-bounding-boxes.html*/}
            {/* userSpaceOnUse is required to center the radial gradient on RadarChart, not on the Radar.
            And to use circular weight instead of current shape of the Radar

            It looks we define gradient for the whole svg here.
            The RadarChart takes about of the half of the space. So the max of the gradient is about 50%
            If the size of the Radar is changes, then the max should be changed.
            If the Radar is not centered, it should be handled too.
            */}
            <radialGradient id={`radarchartColorToRed_${this.state.id}`} gradientUnits="userSpaceOnUse">
              <stop offset={(1 - maxVal1) * 0.10} stopColor={'green'}/>
              <stop offset={(1 - maxVal1) * 0.90} stopColor={maxGradientColor}/>
            </radialGradient>
            <radialGradient id={`radarchartColorToRed_${this.state.id}_2`} gradientUnits="userSpaceOnUse">
              <stop offset={(1 - maxVal2) * 0.10} stopColor={'green'}/>
              <stop offset={(1 - maxVal2) * 0.90} stopColor={maxGradientColor}/>
            </radialGradient>
          </defs>
          <PolarGrid />
          <PolarAngleAxis
            dataKey={labelField}
            tick={<TiltedAxisTick data={data} errorPercent={errorPercent1}/>}
            onClick={onClick && ((e) => e&& onClick(data[e.index]))}
          />
          <PolarRadiusAxis reversed={reversed} />
          <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter}/>
          <Radar
            name={label1}
            dataKey={valueField1}
            stroke="#8884d8"
            fillOpacity={0.50}
            fill={`url(#radarchartColorToRed_${this.state.id})`}
            style={{cursor: onClick ? 'pointer' : 'default'}}
          />
          {label2 &&
            <Radar
              name={label2}
              dataKey={valueField2}
              fillOpacity={0.30}
              fill={`url(#radarchartColorToRed_${this.state.id}_2)`}
              style={{cursor: onClick ? 'pointer' : 'default'}}
            />
          }
        </RadarChart>
      </ResponsiveContainer>
    )
  }
}
