import React from 'react'
import {compose, Mutation, withApollo} from 'react-apollo'
import {connect} from 'react-redux'

import withStyles from '@material-ui/core/styles/withStyles'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ShowIcon from 'components/Icon/ShowIcon'

import {CRAWLERPROJECT_QUERY, CRAWLERSESSIONS_QUERY, START_CRAWLERPROJECT} from './gql'

import crawlerprojectsStyle from 'assets/jss/material-dashboard-react/views/crawlerprojectsStyle'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'
import DropdownButton from 'components/Button/DropdownButton'
import Button from 'components/Button/Button'


class StartCrawlerSession extends React.Component {

  render() {
    const { license, history, crawlerSession, crawlerProject, onStarting, redirectUrl, noMargin } = this.props
    const crawlerSessionId = crawlerSession && crawlerSession.id

    return (
      <Mutation
        mutation={START_CRAWLERPROJECT}
        onCompleted={() => {
          setAlertSuccessMessage('Crawler session started ...')
        }}
        onError={error => {
          setAlertErrorMessage('Crawler session failed', error)
        }}
        refetchQueries={() => {
          return [
            {
              query: CRAWLERPROJECT_QUERY,
              variables: {id: crawlerProject.id}
            },
            {
              query: CRAWLERSESSIONS_QUERY,
              variables: {
                crawlerProjectId: crawlerProject.id,
                skip: 0,
                first: 1,
                orderBy: 'createdAt_DESC'
              }
            }
          ]
        }}
      >
        {(startCrawlerProject) => (
          <GridContainer noMargin={noMargin}>
            <GridItem xs={12}>
              <Button leftRound
                id="start_crawler_session"
                data-unique="btnCrawlerProjectStartCrawlerSession"
                disabled={crawlerSession && crawlerSession.status !== 'READY' && crawlerSession.status !== 'FAILED' && crawlerSession.status !== 'PARTIALLY_FAILED'}
                onClick={ () => {
                  if (onStarting) { onStarting() }
                  startCrawlerProject({
                    variables: {id: crawlerProject.id, debug: false, crawlerSessionId},
                  })
                  if (redirectUrl) {
                    history.push(redirectUrl)
                  }
                }}
              >
                {crawlerSession ? <ShowIcon icon="redo" /> : <ShowIcon icon="play-circle" />}
                {crawlerSession ? 'Repeat Crawler Session' : 'Start first Crawler Session'}
              </Button>
              <DropdownButton
                rightRound
                items={[{
                  id: 'start_crawler_session_logging',
                  name: crawlerSession ? 'Repeat Crawler Session (Extended Logging)' : 'Start first Crawler Session (Extended Logging)',
                  icon: crawlerSession ? 'redo' : 'play-circle',
                  disabled: !license.detailedReporting,
                  dataUnique: 'btnCrawlerProjectStartCrawlerSessionDebug',
                  onClick: () => {
                    if (onStarting) { onStarting() }
                    startCrawlerProject({
                      variables: {id: crawlerProject.id, debug: true, crawlerSessionId},
                    })
                    if (redirectUrl) {
                      history.push(redirectUrl)
                    }
                  }
                }]}
                disabled={crawlerSession && crawlerSession.status !== 'READY' && crawlerSession.status !== 'FAILED' && crawlerSession.status !== 'PARTIALLY_FAILED'}
                data-unique="ddbtnCrawlerProjectStartCrawlerSession"
              >
              </DropdownButton>
            </GridItem>
          </GridContainer>
        )}
      </Mutation>
    )

  }
}

export default compose(
  withStyles(
    (theme) => ({
      ...crawlerprojectsStyle(theme),
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage }
  ),
  withApollo
)(StartCrawlerSession)
