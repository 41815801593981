import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { compose, withApollo } from 'react-apollo'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import _ from 'lodash'

// @material-ui/core components
import Hidden from '@material-ui/core/Hidden'
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import AddAlert from '@material-ui/icons/AddAlert'
import Snackbar from 'components/Snackbar/Snackbar'
// core components
import Header from 'components/Header/Header.jsx'
import Footer from 'components/Footer/Footer.jsx'
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import Alert from 'components/Info/Alert'
import FeatureUpgradePopup from 'components/FeatureUpgrade/FeatureUpgradePopup'
import withWidth from '@material-ui/core/withWidth'

import { setAvailableConnectors, setAllConnectors, setLicense, setFeatures } from 'actions/settings'

import dashboardRoutes from 'routes/dashboard.jsx'
import GetLicensePage from 'components/Page/GetLicensePage'

import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx'

import { isDarkmode } from 'components/Darkmode/helper'

const DefaultRouteLayout = connect(
  state => ({ user: state.token.user })
)(withStyles(dashboardStyle)(class DefaultRouteLayoutClass extends React.Component {

  render() {
    const { user } = this.props
    const { component: Component, key, keyFunc, path, classes, location, scrollableNodeRef, extraParams, ...rest } = this.props

    return user ? (
      <Route
        path={path}
        {...rest}
        render={matchProps => (
          <React.Fragment>
            <Header {...rest} />
            <div className={classes.content}>
              <div className={classes.container}>
                <Component key={(keyFunc && keyFunc(matchProps)) || key} {...extraParams} {...matchProps} />
              </div>
            </div>
            <Footer />
            <Alert />
            <FeatureUpgradePopup />
          </React.Fragment>
        )}
      />
    ) : (
      <Redirect path={path} to={`/login?forward=${encodeURIComponent(location.pathname + (location.search || ''))}`} />
    )
  }
}))

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      showScreenWarning: true,
      drawerCollapsed: false
    }
    this.scrollableNodeRef = React.createRef()
    this.resizeFunction = this.resizeFunction.bind(this)
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }
  setDrawerCollapsed = collapse => {
    this.setState({
      drawerCollapsed: collapse
    })
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      window.zE && window.zE('webWidget', 'hide')
      this.setState({ mobileOpen: false })
    } else {
      window.zE && window.zE('webWidget', 'hide')
    }
  }

  _getWidgetColors = () => {
    return {
      theme: isDarkmode() ? '#FF69B4' : '#FFFFFF',
      launcher: isDarkmode() ? '#FF9800' : '#FF9800',
      launcherText: '#2B3E53',
      button: '#FF9800',
      resultLists: '#2B3E53',
      header: isDarkmode() ? '#f7f7f7' : '#2B3E53',
      articleLinks: '#2B3E53'
    }
  }

  _updateWidgetColors = (e) => {
    window.zE && window.zE('webWidget', 'updateSettings', {
      webWidget: {
        color: this._getWidgetColors(),
        position: { horizontal: 'right', vertical: 'bottom' }
      }
    })
  }

  _updateZenDeskWidget() {
    let interval = null
    interval = setInterval(() => {
      if (window.zE) {
        if (this.props.user) {
          window.zE('webWidget', 'prefill', {
            name: {
              value: this.props.user.name,
              readOnly: true // optional
            },
            email: {
              value: this.props.user.email,
              readOnly: true // optional
            },
          })
          this._updateWidgetColors()
          window.zE && window.zE('webWidget', 'setLocale', 'en')
          window.zE && window.zE('webWidget', 'hide')
          window.zE && window.zE('webWidget:on', 'close', () => {
            window.zE && window.zE('webWidget', 'hide')
          })
        } else {
          window.zE && window.zE('webWidget', 'hide')
        }
        clearInterval(interval)
      }
    }, 10)
  }

  componentDidMount() {
    this._updateZenDeskWidget()
    document.addEventListener('isDarkmode', this._updateWidgetColors)
    window.addEventListener('resize', this.resizeFunction)
  }
  componentDidUpdate(e) {
    const mainRouteHistory = e.history.location.pathname.split('/').length > 1 && e.history.location.pathname.split('/')[1]
    const mainRoute = e.location.pathname.split('/').length > 1 && e.location.pathname.split('/')[1]
    if (mainRoute !== mainRouteHistory) {
      if (this.scrollableNodeRef && this.scrollableNodeRef.current) {
          this.scrollableNodeRef.current.scrollTop = 0
      }
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }
  componentWillUnmount() {
    this._updateZenDeskWidget()
    document.removeEventListener('isDarkmode', this._updateWidgetColors)
    window.removeEventListener('resize', this.resizeFunction)
  }

  render() {
    const { classes, license, ...rest } = this.props
    const { showScreenWarning } = this.state

    const licenseHint = {}

    if (license && license.edition) {
      const messages = []
      if (license.trial && license.edition && !license.edition.startsWith('mini')) {
        messages.push('Botium Trial License is not eligible for usage in production environment.')
      }
      if (!license.isOperable) {
        if (!license.isVersionValid) {
          messages.push('Your Botium License is from an older Botium version. Please update the Botium License. Switching to Read-Only-Mode - your data is safe!')
        } else {
          messages.push('Your Botium License has expired and is over the graceful shutdown period. Please update the Botium License. Switching to Read-Only-Mode - your data is safe!')
        }
      } else if (!license.isValid) {
        messages.push('Your Botium License has expired, graceful shutdown period is running. Please update the Botium License. Your data is safe!')
      }
      if (!license.isHostnameValid) {
        messages.push('Your Botium License is bound to a different server address.')
      }
      if (messages.length > 0) {
        licenseHint.message = messages.join(' ')
      }
    }

    return (<>
        <Hidden mdUp implementation={window.Cypress ? 'js' : 'css'}>
          <Snackbar
            place="tc"
            color={'danger'}
            icon={AddAlert}
            style={{opacity: 0.8}}
            message={'Botium is optimized for desktop screens. Smaller screen resolutions will break the user experience.'}
            open={showScreenWarning}
            closeNotification={() => { this.setState({showScreenWarning: false}) }}
            close
            data-unique="alertResolutionHint"
          />
        </Hidden>
        {licenseHint && licenseHint.message && (
          <Snackbar
            place="tc"
            color={'danger'}
            icon={AddAlert}
            style={{opacity: 0.8}}
            message={licenseHint.message}
            link={licenseHint.link}
            open={true}
            close={false}
            data-unique="alertLicenseHint"
          />
        )}
        <Sidebar
          routes={dashboardRoutes}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          drawerCollapsed={this.state.drawerCollapsed}
          setDrawerCollapsed={this.setDrawerCollapsed}
          {...rest}
        />
        <SimpleBar style={{ height: '100%' }} scrollableNodeProps={{ ref: this.scrollableNodeRef }}>
          <div className={classes.mainPanel} style={{paddingRight: 55, width: (this.props.width === 'md' || this.props.width === 'lg' || this.props.width === 'xl') ? (this.state.drawerCollapsed ? 'calc(100% - 140px)' : 'calc(100% - 140px)') : '100%'}} ref="mainPanel">
            {license && !license.edition && (<>
              <Header handleDrawerToggle={this.handleDrawerToggle} navbarName="Botium License" />
              <div className={classes.content}>
                <div className={classes.container}>
                  <GetLicensePage />
                </div>
              </div>
              <Footer />
            </>)}
            {license && license.edition && (
              <Switch>
                {/*sort by: The stronger paths (/llm) should be always later as weaker ones (like /llm/factchecker/view/:id)*/}
                {/*required because llm is before the fact checker*/}
                {_.sortBy(dashboardRoutes, prop => -prop.path?.length).map((prop, key) => {
                  if (!prop.path)
                    return null
                  if (prop.checkLicense && (!license || !prop.checkLicense(license)))
                    return null
                  if (prop.redirect)
                    return <Redirect from={prop.path} to={prop.to} key={key} />
                  if (!prop.nested) {
                    return (
                      <DefaultRouteLayout
                        path={prop.path}
                        component={prop.component}
                        key={key}
                        keyFunc={prop.keyFunc}
                        handleDrawerToggle={this.handleDrawerToggle}
                        navbarName={prop.navbarName}
                        helmetName={prop.helmetName}
                        scrollableNodeRef={this.scrollableNodeRef}
                        extraParams={prop.extraParams}
                      />
                    )
                  }
                  return null
                })}
              </Switch>
            )}
          </div>
        </SimpleBar>
      </>)
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withTheme(),
  withStyles(dashboardStyle),
  withWidth(),
  connect(
    state => ({ license: state.settings.license, user: state.token.user }),
    { setAvailableConnectors, setAllConnectors, setLicense, setFeatures }
  )
)(withApollo(Dashboard))
