import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

import SpeechSynthesisProfiles from './SpeechSynthesisProfiles'
import SpeechRecognitionProfiles from './SpeechRecognitionProfiles'
import SpeechNoiseProfiles from './SpeechNoiseProfiles'

import { hasPermission } from 'botium-box-shared/security/permissions'

import ShowIcon from 'components/Icon/ShowIcon'

class VoiceSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sectionExpanded: 'speechsynthesis'
    }
  }

  render() {
    const { user, features } = this.props
    return (<CustomTabsSecondary
      name="tabSettingsVoice"
      headerColor="info"
      tabs={[
        {
          tabName: 'Speech Synthesis (Text-To-Speech)',
          tabIcon: <ShowIcon icon="comment" />,
          disabled: !features.speechService || !hasPermission(user, 'SYSTEMSETTINGS_MANAGE'),
          tabContent: <GridContainer><GridItem xs={12}><SpeechSynthesisProfiles/></GridItem></GridContainer>,
          locationPrefix: '/settings/voice/speechsynthesis',
          dataUnique: 'tabSettingsVoiceSpeechSynthesis'
        },
        {
          tabName: 'Speech Recognition (Speech-To-Text)',
          tabIcon: <ShowIcon icon="microphone" />,
          disabled: !features.speechService || !hasPermission(user, 'SYSTEMSETTINGS_MANAGE'),
          tabContent: <GridContainer><GridItem xs={12}><SpeechRecognitionProfiles/></GridItem></GridContainer>,
          locationPrefix: '/settings/voice/speechrecognition',
          dataUnique: 'tabSettingsVoiceSpeechRecognition'
        },
        {
          tabName: 'Voice Effects',
          tabIcon: <ShowIcon icon="phone" />,
          disabled: !features.speechService || !hasPermission(user, 'SYSTEMSETTINGS_MANAGE'),
          tabContent: <GridContainer><GridItem xs={12}><SpeechNoiseProfiles/></GridItem></GridContainer>,
          locationPrefix: '/settings/voice/noise',
          dataUnique: 'tabSettingsSpeechNoise'
        }
      ]}
    />)
  }
}

export default connect(
  state => ({ user: state.token.user, features: state.settings.features, license: state.settings.license })
)(VoiceSettings)
