import React from 'react'
import _ from 'lodash'
import ErrorFormat from 'components/Info/ErrorFormat'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import moment from 'moment/moment'
import momentDurationFormatSetup from 'moment-duration-format'

momentDurationFormatSetup(moment)

export const renderProgressOrError = (data) => {
  if (!data) {
    return null
  }
  const {loading, error} = data
  if (loading) {
    return <LoadingIndicator />
  }
  if (error) {
    return <ErrorFormat err={error}/>
  }
  return null
}

// url encoding turns _ASC to _asc
export const normalizeOrderBy = (orderBy) => {
  if (orderBy.endsWith('_asc')) {
    return orderBy.substring(0, orderBy.length - 4) + '_ASC'
  }
  if (orderBy.endsWith('_desc')) {
    return orderBy.substring(0, orderBy.length - 5) + '_DESC'
  }

  return orderBy
}

/**
 * decodeURIComponentWeak converts null and undefined to string.
 * And it is not what we except for optional url params like intent filter
 * parse percent sign
 * @param uriComponent
 */
export const decodeURIComponentWeak = (uriComponent) => {
  if (!uriComponent) {
    return uriComponent
  }

  return decodeURIComponent(uriComponent.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'))
}

// works with the gqlVariables property of the ServerSidePagingTable.
export const addNamespaceToWhere = (namespaceStruct, whereContainer) => {
  if (!namespaceStruct?.selected) {
    return whereContainer
  }
  if (!whereContainer) {
    whereContainer = { where: {} }
  } else  if (!whereContainer.where) {
    whereContainer.where = {}
  }

  whereContainer.where = {
    AND: [
      whereContainer.where,
      getNamespaceWhereClause(namespaceStruct.selected.name)
    ]
  }

  return whereContainer
}

export const getNamespaceWhereClause = (namespace) => {
  return {
    OR: [
      { namespace: namespace },
      { namespace_starts_with: `${namespace}.` }
    ]
  }
}

export const safeGetNamespaceFilteredList = (list, namespaceStruct, ids = null) => {
  if (!namespaceStruct || !namespaceStruct.selected || !namespaceStruct.selected.name) return list
  if (!list) return list
  if (!_.isArray(list)) return list

  ids = ids ? _.isArray(ids) ? ids : [ids] : []

  return list.filter(l => (l.namespace && (l.namespace === namespaceStruct.selected.name || l.namespace.startsWith(`${namespaceStruct.selected.name}.`))) || ids.indexOf(l.id) >= 0)
}


export const formatMillisecondsDuration = (milliseconds) => {
  return moment.duration(milliseconds, 'milliseconds').format(milliseconds > 999 ? 'h[h] mm[m] ss[s]' : 'h[h] mm[m] ss[s] SSS[ms]')
}

export const renderTrendingDurationLabel = (trend, inverse = false) => {
  if (_.isNil(trend)) {
    return null
  }
  const trendDuration = inverse ? -trend : trend
  return trendDuration < 0 ? `${formatMillisecondsDuration(trendDuration)}` : `+${formatMillisecondsDuration(trendDuration)}`
}
