import React from 'react'
// @material-ui/core components
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  url,
  composeValidators,
  renderTextField
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function botpressCaps2Form(caps) {
  return {
    botpress: {
      serverUrl: (
        caps.find(c => c.name === 'BOTPRESS_SERVER_URL') || { stringValue: '' }
      ).stringValue,
      botId: (
        caps.find(c => c.name === 'BOTPRESS_BOTID') || { stringValue: '' }
      ).stringValue,
    },
  }
}

const capNamesMap = {
  'botpress.serverUrl': 'BOTPRESS_SERVER_URL',
  'botpress.botId': 'BOTPRESS_BOTID'
}

export function botpressForm2caps(values) {
  const capabilities = [
    {
      name: 'BOTPRESS_SERVER_URL',
      type: 'STRING',
      stringValue: values.botpress.serverUrl || '',
    },
    {
      name: 'BOTPRESS_BOTID',
      type: 'STRING',
      stringValue: values.botpress.botId || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'botpress' }
  ]

  return capabilities
}

export class BotpressEdit extends React.Component {
  render() {
    const { disabled, capSetCapNames } = this.props

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Field
            name="botpress.serverUrl"
            component={renderTextField}
            label="Botpress Server Url"
            validate={composeValidators(required, url)}
            data-unique="txtBotPressServerUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botpress.serverUrl']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['botpress.serverUrl'])}
          />
          <Text helperText>
            The Botpress Server Url (without any path, just http/https, servername, port)
          </Text>
        </GridItem>
        <GridItem xs={12}>
          <Field
            name="botpress.botId"
            component={renderTextField}
            label="Botpress Bot Id"
            validate={required}
            data-unique="txtBotPressBotId"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botpress.botId']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['botpress.botId'])}
          />
          <Text helperText>
            The Botpress Bot Id - you can find it in the Botpress Studio on the bottom left, or in the address bar of your browser (f.e. http://my-botpress-server/studio/*here-is-the-bot-id*/flows/main)
          </Text>
        </GridItem>
      </GridContainer>
    )
  }
}
