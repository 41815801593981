import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import { Query } from 'react-apollo'

import ErrorFormat from 'components/Info/ErrorFormat'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import {PERFORMANCETESTSESSION_STATUS_QUERY} from './gql'
import PerformanceTestSessionProgressIcon from './PerformanceTestSessionProgressIcon'
import {getAggregatedData} from './helper'


class PerformanceTestSessionProgressPolling extends React.Component {

  render () {
    const { performancetestsession, withoutNavLink , classes} = this.props

    return (<React.Fragment>
      <Query
        query={PERFORMANCETESTSESSION_STATUS_QUERY}
        variables={{ id: performancetestsession.id }}
        pollInterval={1000}
      >
        {({ loading, error, data, stopPolling }) => {
          if (error) {
            return <ErrorFormat err={error}/>
          }
          if (data.performancetestsessionstatus && (data.performancetestsessionstatus.status === 'READY' || data.performancetestsessionstatus.status === 'FAILED')) {
            stopPolling()
          }

          const { progressPercent } = getAggregatedData(performancetestsession)
          const showProgress = !data.performancetestsessionstatus || ['PENDING', 'RUNNING'].includes(data.performancetestsessionstatus.status)

          return (<div className={classes.resultIconPositionNavLink}><PerformanceTestSessionProgressIcon
            showProgress={showProgress}
            progressPercent={progressPercent}
            status={data.performancetestsessionstatus && data.performancetestsessionstatus.status}
            withoutNavLink={withoutNavLink}
          /></div>)
        }}
      </Query>
    </React.Fragment>)
  }
}

export default withStyles(testsessionsStyle)(PerformanceTestSessionProgressPolling)
