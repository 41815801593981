import _ from 'lodash'
import { SET_TABLE_SETTINGS, CLEAR_TABLE_SETTINGS } from '../constants'

export const defaultTableSettings = {
  page: 0,
  rowsPerPage: 10,
  tabIndex: 0,
  filterText: null
}

function getTableSettingsFromState(state, name) {
  const table = (state && state.table) || {}
  return Object.assign(
    {},
    defaultTableSettings,
    table[name]
  )
}

export function getTableSettings(name) {
  return (dispatch, getState) => getTableSettingsFromState(getState(), name)
}

export function getTablePaginationVariables(name, filterFields) {
  return (dispatch, getState) => {
    const tableSettings = getTableSettingsFromState(getState(), name)
    const result = {
      skip: tableSettings.page * tableSettings.rowsPerPage,
      first: tableSettings.rowsPerPage + 1
    }
    if (tableSettings && tableSettings.filterText && filterFields && filterFields.length > 0) {
      for (const filterField of filterFields) {
        _.set(result, filterField, tableSettings.filterText)
      }
    }
    return result
  }
}

export function getTablePaginationVariablesPage({ skip, first, filterText, filterFields }) {
  const result = {
    skip,
    first
  }
  if (filterText && filterFields && filterFields.length > 0) {
    for (const filterField of filterFields) {
      _.set(result, filterField, filterText)
    }
  }
  return result
}

export function clearTableSettings(table) {
  return {
    type: CLEAR_TABLE_SETTINGS,
    payload: {
      table,
    },
  }
}

export function setTableSettings(table, settings) {
  return {
    type: SET_TABLE_SETTINGS,
    payload: {
      table,
      settings,
    },
  }
}
