import React from 'react'
// @material-ui/core components
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  url,
  composeValidators,
  renderTextField
} from 'components/Form/Form'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function botkitWebsocketCaps2Form(caps) {
  return {
    botkitWebsocket: {
      serverUrl: (
        caps.find(c => c.name === 'BOTKIT_SERVER_URL') || { stringValue: '' }
      ).stringValue,
    },
  }
}

const capNamesMap = {
  'botkitWebsocket.serverUrl': 'BOTKIT_SERVER_URL'
}

export function botkitWebsocketForm2caps(values) {
  const capabilities = [
    {
      name: 'BOTKIT_SERVER_URL',
      type: 'STRING',
      stringValue: values.botkitWebsocket.serverUrl || '',
    }
  ]

  return capabilities
}

export class BotkitWebsocketEdit extends React.Component {
  render() {
    const { disabled, capSetCapNames } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Field
            name="botkitWebsocket.serverUrl"
            component={renderTextField}
            label="Botkit Server Url"
            validate={composeValidators(required, url)}
            data-unique="txtBotKitWebsocketEditServerUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['botkitWebsocket.serverUrl']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['botkitWebsocket.serverUrl'], 'The Botkit Server Url (without any path, just http/https, servername, port)')}
          />
        </GridItem>
      </GridContainer>
    )
  }
}
