import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, compose, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  renderAutoSuggest,
  renderTextArea,
  required,
  composeValidators,
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import {
  USERROLES_QUERY,
  USERROLE_QUERY,
  CREATE_USERROLE,
  UPDATE_USERROLE,
  DELETE_USERROLE
} from './gql'

import { permissionTypes } from 'botium-box-shared/security/permissions'
import { validateUserRoleNameUnique } from './validators'
import { FormActionsToolbar } from 'components/Form/Form'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class UserRole extends React.Component {

  isSysRole(name) {
    return name === 'ADMIN'
  }

  renderForm(role) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady } = this.props

    return (
      <Mutation
        mutation={role.id ? UPDATE_USERROLE : CREATE_USERROLE}
        refetchQueries={[
          {
            query: USERROLES_QUERY,
          },
        ]}
      >
        {(mutateUserRole, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (role.id) {
                try {
                  const res = await mutateUserRole({
                    variables: {
                      id: role.id,
                      userRole: {
                        name: values.name,
                        description: values.description || '',
                        permissions: {
                          set: values.permissions || []
                        }
                      },
                    },
                  })
                  form.initialize(res.data.updateUserRole)
                  setAlertSuccessMessage('Role updated')
                  onReady(role.id)
                } catch(error) {
                  setAlertErrorMessage('Role update failed', error)
                }
              } else {
                try {
                  const res = await mutateUserRole({
                    variables: {
                      userRole: {
                        name: values.name,
                        description: values.description || '',
                        permissions: {
                          set: values.permissions || []
                        }
                      },
                    },
                  })
                  form.initialize(res.data.createUserRole)
                  setAlertSuccessMessage('Role registered')
                  onReady(res.data.createUserRole.id)
                } catch(error) {
                  setAlertErrorMessage('Role register failed', error)
                }
              }
            }}
            initialValues={role}
            render={({
              handleSubmit,
              submitting,
              invalid
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Role Name"
                      validate={composeValidators(required, async (value) => {
                        if (value) {
                          return validateUserRoleNameUnique(this.props.client, role.id, value)
                        }
                      })}
                      data-unique="txtRoleName"
                      disabled={this.isSysRole(role.name)}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="description"
                      component={renderTextArea}
                      label="Description"
                      rows={3}
                      data-unique="txtRoleDescription"
                      disabled={this.isSysRole(role.name)}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="permissions"
                      component={renderAutoSuggest}
                      label="Select Permissions(s)"
                      suggestions={Object.keys(permissionTypes)}
                      numsuggestions={20}
                      data-unique="asRolePermissionSelection"
                      disabled={this.isSysRole(role.name)}
                    />
                  </GridItem>
                  {!this.isSysRole(role.name) &&
                    <GridItem xs={12} largePadding>
                      <FormActionsToolbar
                        leftButtons={<>
                          {role.id && (
                            <Mutation
                              mutation={DELETE_USERROLE}
                              onCompleted={data => {
                                setAlertSuccessMessage('Role deleted')
                                onReady()
                              }}
                              onError={error => {
                                setAlertErrorMessage('Role deletion failed', error)
                              }}
                              refetchQueries={[
                                {
                                  query: USERROLES_QUERY,
                                },
                              ]}
                            >
                              {(deleteUserRole, { loading, error }) => (
                                <ConfirmationButton
                                  confirmationText={`Are you sure you want to delete it ?`}
                                  requireCheck={true}
                                  onClick={() => {
                                    deleteUserRole({
                                      variables: { id: role.id },
                                    })
                                  }}
                                  data-unique="btnUserRoleDelete"
                                  secondary
                                  danger                                  
                                >
                                  <ShowIcon icon="trash" />
                                  <Text infoText bold>Delete Role</Text>
                                </ConfirmationButton>
                              )}
                            </Mutation>
                          )}                        
                        </>}
                        rightButtons={<>
                          <Button
                            type="submit"
                            disabled={submitting || invalid}
                            data-unique="btnRoleSave"
                          >
                            {submitting && <LoadingIndicator alt />}
                            {!submitting && <ShowIcon icon="save" />}                  
                            Save
                          </Button>                        
                        </>}
                      />                      
                    </GridItem>
                  }
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={USERROLE_QUERY} variables={{ id }} fetchPolicy="network-only">
              {(queryResult) => <QueryStatus {...queryResult} query="userrole">{(data) => this.renderForm(data.userrole)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({})}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(settingsStyle),
  connect(
    state => ({ user: state.token.user }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  withApollo
)(UserRole)
