import {SET_TEST_DATA_WIZARD_DATA, CLEAR_TEST_DATA_WIZARD_DATA} from '../constants'

export default function testdatawizard(state = {}, action) {
  switch (action.type) {
    case SET_TEST_DATA_WIZARD_DATA:
      return action.payload
    case CLEAR_TEST_DATA_WIZARD_DATA:
      return {}
    default:
      return state
  }
}
