import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import Button from 'components/Button/Button'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'
import Utterance from './UtteranceSummary'
import LinkButton from 'components/Button/LinkButton'

import NavbarHeader from 'components/Header/NavbarHeader'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {LIST_UTTERANCES_FOR_ACTUAL_ENTITY_QUERY, UTTERANCES_WITH_ACTUAL_ENTITIES_QUERY, TRAINER_SESSION_ROOT_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'
import {decodeURIComponentWeak} from 'views/helper'

const testsessionLabel = (testsession) => {
  if (testsession) {
    return <React.Fragment>{testsession.name}</React.Fragment>
  }
  return null
}

class EntityConfidence extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  renderTable() {
    const {utteranceByActualEntityIdData, utteranceByEntityNameData, match} = this.props
    const { testSessionId } = match.params

    let progressOrError = renderProgressOrError(utteranceByActualEntityIdData) || renderProgressOrError(utteranceByEntityNameData )
    if (progressOrError) {
      return progressOrError
    }

    let data
    if (match.params.entityName || match.params.actualEntityName) {
      data = utteranceByEntityNameData.trainerUtterances
    } else if (match.params.actualEntityId) {
      data = utteranceByActualEntityIdData.trainerListUtterancesForActualEntity
    } else {
      // should happen never
      data = []
    }

    data = data.map(entry => {
      entry.entity.actual= _.sortBy(entry.entity.actual, (entry) => -entry.confidence)
      return entry
    })
    data = _.sortBy(data, (entry) => entry.entity.length ? entry.entity.actual[0].confidence : 0)

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Utterance', 'Expected Entity', 'Predicted Entity (Confidence)']}
        tableData={data.map(entry => {
          return [
            () => <LinkButton onClick={() => this.setState({ open: true, utterance: entry.utterance, testSessionId: testSessionId })} data-unique={`btnCoachIntentConfidenceUtterance_${encodeURIComponent(entry.utterance)}`}>{entry.utterance}</LinkButton>,

            entry.entity.expectedNames.map(entityName => ({
              value: entityName,
              href: `/nlp/projects/view/${match.params.testProjectId}/results/${match.params.testSessionId}/entityconfidence/entityname/${encodeURIComponent(entityName)}`
            })),
            entry.entity.actual.length ? entry.entity.actual.map(entity => ({
              value: `${entity.name}:${entity.value} (${!_.isNil(entity.confidence) ? entity.confidence.toFixed(4) : 'N/A'})`,
              href: `/nlp/projects/view/${match.params.testProjectId}/results/${match.params.testSessionId}/entityconfidence/actualentityname/${encodeURIComponent(entity.name)}`
            })) : 'N/A'
          ]
        })}
      />
    )
  }

  render() {
    const { match, history, testSessionData} = this.props
    console.log('testSessionData', testSessionData)

    return (<>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
            <GridContainer>
                <GridItem xs={12}>
                    <Button justIcon Border onClick={() => history.goBack()} >
                       <ShowIcon icon="arrow-left" />
                   </Button> <Text inline>BACK / Test Session: {testSessionData && testSessionData.testsession && testsessionLabel(testSessionData.testsession)}</Text>
                </GridItem>
                <GridItem xs={12}>
                  {this.renderTable()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ConfirmationDialog
      maxWidth="lg"
      open={this.state.open}
      onOk={() => this.setState({ open: false })}
      title={`Utterance name: ${this.state.utterance}`}
    >
      <Utterance utterance={this.state.utterance} testSessionId={this.state.testSessionId} testProjectId={match.params.testProjectId} />
    </ConfirmationDialog>
    </>)
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(LIST_UTTERANCES_FOR_ACTUAL_ENTITY_QUERY, {
    props: ({data}) => ({
      utteranceByActualEntityIdData: data,
    }),
    options: (props) => {
      return {
        variables: {
          actualEntityId: props.match.params.actualEntityId,
          orderBy: 'utterance_ASC'
        }
      }
    },
    skip: (props) => !props.match.params.actualEntityId
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      testSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.testSessionId
        },
      }
    }
  }),
  graphql(UTTERANCES_WITH_ACTUAL_ENTITIES_QUERY, {
    props: ({data}) => ({
      utteranceByEntityNameData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          entity: decodeURIComponentWeak(props.match.params.entityName),
          actualEntity: decodeURIComponentWeak(props.match.params.actualEntityName),
          orderBy: 'utterance_ASC'
        }
      }
    },
    skip: (props) => !props.match.params.entityName && !props.match.params.actualEntityName
  })
)(EntityConfidence)

export const EntityConfidenceNavbarHeader = compose(
  graphql(LIST_UTTERANCES_FOR_ACTUAL_ENTITY_QUERY, {
    props: ({data}) => ({
      utteranceByActualEntityIdData: data,
    }),
    options: (props) => {
      return {
        variables: {
          actualEntityId: props.match.params.actualEntityId,
          orderBy: 'utterance_ASC'
        }
      }
    },
    skip: (props) => !props.match.params.actualEntityId
  }),
)(({ utteranceByActualEntityIdData, match }) => {
  let entityName
  if (match.params.entityName) {
    entityName = decodeURIComponentWeak(match.params.entityName)
  } else if (match.params.actualEntityName) {
    entityName = decodeURIComponentWeak(match.params.actualEntityName)
  } else if (match.params.actualEntityId) {
    let progressOrError = renderProgressOrError(utteranceByActualEntityIdData)
    if (progressOrError) {
      return progressOrError
    }
    entityName = utteranceByActualEntityIdData.trainerListUtterancesForActualEntity.length ? utteranceByActualEntityIdData.trainerListUtterancesForActualEntity[0].entity.actual[0].name : 'N/A'
  }
  return <NavbarHeader text={`Entity "${entityName}"`} />
})
