import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { CircularProgress } from '@material-ui/core'
import ShowIcon from './ShowIcon'

const loadingStyles = theme => {
  return {
    colorPrimary: {
      color: theme.colors.primary
    }
  }
}

function LoadingIndicator({ ...props }) {

  const { large, box, alt, classes } = props

  const renderIndicator = () => <React.Fragment>
    {!alt && <>
      {large && <CircularProgress classes={{
        colorPrimary: classes.colorPrimary
      }} color="primary" />}
      {!large && <CircularProgress classes={{
        colorPrimary: classes.colorPrimary
      }} size={20} color="primary" />}
    </>
    }
    {alt && <ShowIcon icon="spinner" spin />}
  </React.Fragment>
  if (box) {
    return <GridContainer>
      <GridItem xs={12} center largePadding={!!large}>
        {renderIndicator()}
      </GridItem>
    </GridContainer>
  }
  return renderIndicator()
}

export default withStyles(loadingStyles)(LoadingIndicator)