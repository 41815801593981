import { gql } from 'apollo-boost'
import { validateUnique } from 'helper/graphHelper'

const CHECK_CONVO_NAME_QUERY = gql`
query CheckConvoNameQuery($testSetId: ID!, $name: String) {
  testsetcompiledconvos(testSetId: $testSetId, name: $name) {
    id
    script { id }
  }
}
`  

const CHECK_UTTERANCE_NAME_QUERY = gql`
query CheckUtteranceNameQuery($testSetId: ID!, $name: String) {
  testsetcompiledutterances(testSetId: $testSetId, name: $name) {
    id
    script { id }
  }
}
`

export async function validateConvoNameUnique (client, testSetId, testSetScriptId, convoName, errMsg = 'Please choose another Test Case Name, this one is already used') {
  return validateUnique(client, CHECK_CONVO_NAME_QUERY, { testSetId, name: convoName }, 'testsetcompiledconvos', c => !testSetScriptId || !c.script || c.script.id !== testSetScriptId, errMsg)
}

export async function validateUtteranceNameUnique (client, testSetId, testSetScriptId, utteranceName, errMsg = 'Please choose another Utterance List Name, this one is already used') {
  return validateUnique(client, CHECK_UTTERANCE_NAME_QUERY, { testSetId, name: utteranceName }, 'testsetcompiledutterances', c => !testSetScriptId || !c.script || c.script.id !== testSetScriptId, errMsg)
}
