import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {IGNORED_TEST_SCRIPTS_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'

class IgnoredTestScripts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTable() {
    const {ignoredScriptsData} = this.props
    let progressOrError = renderProgressOrError(ignoredScriptsData)
    if (progressOrError) {
      return progressOrError
    }

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Name', 'Reason']}
        tableData={(ignoredScriptsData.trainerIgnoredTestScripts || []).map(entry => {
          return [
            entry.testcaseName,
            entry.err,
          ]
        })}
      />
    )
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{this.renderTable()}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(IGNORED_TEST_SCRIPTS_QUERY, {
    props: ({data}) => ({
      ignoredScriptsData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId
        },
      }
    }
  })
)(IgnoredTestScripts)
