import React from 'react'
import regressionImg from 'assets/img/quickstart_regression.png'
import nluImg from 'assets/img/quickstart_nlu.png'
import e2eImg from 'assets/img/quickstart_e2e.png'
import securityImg from 'assets/img/quickstart_security.png'
import monitoringImg from 'assets/img/quickstart_monitoring.png'
import performanceImg from 'assets/img/quickstart_performance.png'
import gdprImg from 'assets/img/quickstart_gdpr.png'
import Text from 'components/Typography/Text'

import {calculateRepeatPerTick} from '../PerformanceTestSessions/PerformanceTestSessionRegisterForm'
import _ from 'lodash'

export function getTechnologyTypeIcon(type) {
  return _.isString(type) ? <Text bggrey bgradius displayInline rightMarginxs bottomMarginsm sm regular>{type}</Text> : null
}

export function getDefaultTestTypes(license, connector) {
  const result = []
  const reg = testTypes.find(t => t.name === 'REGRESSION')
  const nlp = testTypes.find(t => t.name === 'NLP')
  const e2e = testTypes.find(t => t.name === 'E2E')

  if (reg.available(license) && reg.supported(connector)) result.push('REGRESSION')
  if (nlp.available(license) && nlp.supported(connector)) result.push('NLP')
  if (e2e.available(license) && e2e.supported(connector)) result.push('E2E')
  return result
}

export function isTestTypeSupported(testType, connector) {
  const tt = testTypes.find(t => t.name === testType)
  if (tt) return tt.supported(connector)
  else return false
}

export const testTypes = [
  {
    name: 'REGRESSION',
    img: regressionImg,
    header: 'Regression Testing',
    subheader: 'Identify Flaws in the Conversation Flow before going to Production',
    text: [
      'Verifying Context Handling and Conversational Flow of your Chatbot',
      'Quick Feedback Loop for Agile Development in Chatbot Projects',
      'Continuous Chatbot Testing by Integration in your CI/CD Pipeline of Choice'
    ],
    available: () => true,
    supported: (connector) => !connector || !connector.features || connector.features.conversationFlowTesting
  },
  {
    name: 'NLP',
    img: nluImg,
    header: 'NLP Testing',
    subheader: 'Improve your Chatbot Understanding',
    text: (license) => [
      'Understand what the Chatbot understands and why',
      license.coach && 'Compare NLP Performance across Technologies with Botium',
      license.coach && 'Data Augmentation with Paraphrasing, Language Translations and predefined Test Sets'
    ].filter(t => t),
    available: () => true,
    supported: (connector) => connector && connector.features && connector.features.intentResolution
  },
  {
    name: 'E2E',
    img: e2eImg,
    header: 'E2E Testing',
    subheader: 'Verifying the End-User Experience',
    text: [
      'Verifying End-User Experience on Multi-Channel Chatbots',
      'Testing Voice-Enabled Apps, Website Chatbots and Smartphone Apps',
      'Functional Monitoring and Notifications for User-Facing Chatbots'
    ],
    available: () => true,
    supported: (connector) => connector && connector.features && connector.features.e2eTesting,
    notSupportedText: 'E2E Testing is supported by the UI/UX-based and Voice Connectors only'
  },
  {
    name: 'PERFORMANCE',
    img: performanceImg,
    header: 'Performance Testing',
    subheader: 'Ensure your Chatbot is responsive under High Load',
    text: [
      'Find out how many parallel users your Chatbot Infrastructure can handle',
      'Load Testing by simulating constant User Traffic on your Chatbot',
      'Explore your limits by running Stress Tests with increasing User Traffic'
    ],
    available: (license) => !!license.performanceTesting,
    supported: (connector) => !(connector && connector.features && connector.features.e2eTesting)
  },
  {
    name: 'SECURITY',
    img: securityImg,
    header: 'Security Testing with OWASP',
    subheader: 'Making your Chatbot Secure',
    text: [
      'Continuous Security Testing with OWASP ZAP (Zed Attack Proxy)',
      'Continuous Pentests with predefined Attacker Datasets based on OWASP Top 10 Web Application Security Risks',
      'Detect Data Privacy and GDPR Compliance Risks'
    ],
    available: (license) => !!license.securityTesting,
    supported: (connector) => connector && connector.features && connector.features.securityTesting,
  },
  {
    name: 'GDPR',
    img: gdprImg,
    header: 'GDPR Testing',
    subheader: 'Ensure your Chatbot is GDPR compliant',
    text: [
      'Find out if your Chatbot is GDPR compliant',
      'Detect Data Privacy and GDPR Compliance Risks'
    ],
    available: (license) => !!license.gdprTesting,
    supported: () => true
  },
  {
    name: 'MONITORING',
    img: monitoringImg,
    header: 'Live Monitoring',
    subheader: 'Get notified when Problems arise',
    text: [
      'Monitor Test and Production Chatbots',
      'Scheduled Test Execution',
      'Get notified by Email as soon as Problems arise'
    ],
    available: (license) => license.monitoring && license.notifications,
    supported: () => true
  }
]

export const PERFORMANCE_TEST_STEP_DURATION_SEC = 10

export const calculatePerformanceTestingTickRepeatPerTick = (formValues) => {
  return calculateRepeatPerTick(formValues.performanceTestingTickRepeatInitial, formValues.performanceTestingTickRepeatMax, formValues.performanceTestingTickMaxTimeMinutes, PERFORMANCE_TEST_STEP_DURATION_SEC)
}
