import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import SimpleList from 'components/List/SimpleList.jsx'

import ShowIcon from 'components/Icon/ShowIcon'

import Namespace from './Namespace.jsx'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { NAMESPACES_QUERY } from './gql.js'

class Namespaces extends React.Component {
  render() {
    const { history, location } = this.props

    return (
      <GridContainer>
        <GridItem xs={12} sm={4} md={3} noPadding>
          <Query query={NAMESPACES_QUERY} notifyOnNetworkStatusChange={true}>
            {({ loading, error, data, refetch }) => {
              return <SimpleList
                name="tblNamespaces"
                listData={(data && data.namespaces && data.namespaces.map(ns => ({
                    id: ns.id,
                    name: ns.namespace,
                    icon: <ShowIcon icon="folder" />,
                    selected: location.pathname.indexOf(ns.id) >= 0,
                    onClick: () => history.push(`/settings/security/namespaces/${ns.id}`)
                  }))) || []}
                onRefresh={() => refetch()}
                pageLoading={loading}
              />
            }}
          </Query>
        </GridItem>
        <GridItem xs={12} sm={8} md={9} borderLeft>
          <Switch>
            <Route
              path="/settings/security/namespaces/:id"
              render={props => <Namespace id={props.match.params.id} key={props.match.params.id} onReady={(id) => !id && history.push('/settings/security/namespaces')} {...props}/>}
            />
          </Switch>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ license: state.settings.license, user: state.token.user })
)(withStyles(settingsStyle)(withRouter(Namespaces)))
