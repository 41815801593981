import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {IGNORED_TEST_SCRIPT_STEPS_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'

class IgnoredTestScriptSteps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTable() {
    const {stepData} = this.props
    let progressOrError = renderProgressOrError(stepData)
    if (progressOrError) {
      return progressOrError
    }

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Step ID', 'Step index', 'Message(s)']}
        tableData={stepData.trainerIgnoredTestScriptSteps.map(entry => {
          return [
            entry.testCaseResultStep ? entry.testCaseResultStep.id : 'N/A',
            entry.stepIndex,
            entry.err.join(' - ')
          ]
        })}
      />
    )
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{this.renderTable()}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(IGNORED_TEST_SCRIPT_STEPS_QUERY, {
    props: ({data}) => ({
      stepData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId
        },
      }
    }
  })
)(IgnoredTestScriptSteps)
