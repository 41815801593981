import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

import buttonStyle from 'assets/jss/material-dashboard-react/components/buttonStyle.jsx'
import ShowIcon from 'components/Icon/ShowIcon'

function RegularButton({ ...props }) {
  const {
    classes,
    color,
    round,
    children,
    hidden,
    disabled,
    small,
    medium,
    large,
    block,
    link,
    secondary,
    tertiary,
    justIcon,
    justIconSolid,
    iconDark,
    dots,
    dotsSecondary,
    dropdown,
    mini,
    primaryMini,
    danger,
    fullWidth,
    fullWidthLeft,
    inputHeight,
    inputAlign,
    dense,
    spaceRight,
    spaceLeft,
    dashed,
    dashedSecondary,
    solid,
    className,
    muiClasses,
    card,
    noCaret,
    noBorder,
    minWidth,
    noMargin,
    noMarginRight,
    marginBottomTop,
    Border,
    marginSmall,
    dropdownSelect,
    dropdownSelectAvtive,
    customSelectType,
    marginTop,
    leftRound,
    rightRound,
    smallTop,
    largeMarginTop,
    marginUnset,
    darkButton,
    floatRight,
    fullRound,
    ...rest
  } = props
  const btnClasses = classNames({
    [classes.button]: true,
    [classes.round]: round,
    [classes.small]: small,
    [classes.large]: large,
    [classes.medium]: medium,
    [classes.hidden]: hidden,
    [classes.disabled]: disabled,
    [classes.block]: block,
    [classes.fullWidth]: fullWidth,
    [classes.fullWidthLeft]: fullWidthLeft,
    [classes.link]: link,
    [classes.danger]: danger,
    [classes.primary]: !secondary,
    [classes.secondary]: secondary || justIcon || dashed || card || dropdownSelect,
    [classes.secondaryDanger]: danger && secondary,
    [classes.tertiary]: tertiary,
    [classes.justIcon]: justIcon || dots || dotsSecondary,
    [classes.justIconSolid]: justIconSolid,
    [classes.dots]: dots,
    [classes.dotsSecondary]: dotsSecondary,
    [classes.mini]: mini,
    [classes.dense]: dense,
    [classes.dashed]: dashed,
    [classes.dashedSecondary]: dashedSecondary,
    [classes.solid]: solid,
    [classes.inputHeight]: inputHeight,
    [classes.inputAlign]: inputAlign,
    [classes.card]: card,
    [classes.noBorder]: noBorder,
    [classes.Border]: Border,
    [classes.minWidth]: minWidth,
    [classes.noMargin]: noMargin,
    [classes.noMarginRight]: noMarginRight,
    [classes.marginBottomTop]: marginBottomTop,
    [classes.marginSmall]: marginSmall,
    [classes.spaceRight]:spaceRight,
    [classes.spaceLeft]:spaceLeft,
    [classes.iconDark]:iconDark,
    [classes.dropdownSelect]:dropdownSelect,
    [classes.dropdownSelectAvtive]:dropdownSelectAvtive,
    [classes.customSelectType]:customSelectType,
    [classes.marginTop]:marginTop,
    [classes.rightRound]:rightRound,
    [classes.leftRound]:leftRound,
    [classes.primaryMini]:primaryMini,
    [classes.smallTop]:smallTop,
    [classes.largeMarginTop]:largeMarginTop,
    [classes.marginUnset]:marginUnset,
    [classes.darkButton]:darkButton,
    [className]: className,

  })
  return (
    <Button {...rest} classes={muiClasses} className={btnClasses} disableRipple>
      {(dots || dotsSecondary) && <ShowIcon icon="ellipsis-v" />}
      {children}
      {!justIcon && !dots && !dotsSecondary && dropdown && !noCaret && <ShowIcon icon="caret-down" />}
    </Button>
  )
}

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  danger: PropTypes.bool,
  simple: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  secondary: PropTypes.bool,
  round: PropTypes.bool,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  justIconSolid: PropTypes.bool,
  dense: PropTypes.bool,
  spaceRight: PropTypes.bool,
  spaceLeft: PropTypes.bool,
  dots: PropTypes.bool,
  dotsSecondary: PropTypes.bool,
  dropdown: PropTypes.bool,
  card: PropTypes.bool,
  noBorder: PropTypes.bool,
  Border: PropTypes.bool,
  minWidth: PropTypes.bool,
  className: PropTypes.string,
  inputAlign: PropTypes.bool,
  noMargin: PropTypes.bool,
  marginBottomTop: PropTypes.bool,
  iconDark: PropTypes.bool,
  dropdownSelect: PropTypes.bool,
  dropdownSelectAvtive: PropTypes.bool,
  customSelectType: PropTypes.bool,
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
}

export default withStyles(buttonStyle)(RegularButton)
