import React from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { compose, graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import ErrorFormat from 'components/Info/ErrorFormat'
import { setAlertSuccessMessage, setAlertErrorMessage, clearAlert } from 'actions/alert'
import { clearLogin } from 'actions/token'

import logoutPageStyle from 'assets/jss/material-dashboard-react/components/loginPageStyle.jsx'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

const LOGOUT_USER_MUTATION = gql`
  mutation LogoutMutation {
    logout { redirectUrl }
  }
`

class LogoutPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      err: null
    }
  }

  async componentDidMount () {
    const { mutateLogout, clearLogin, setAlertSuccessMessage, client, history } = this.props

    let redirectUrl = null
    try {
      const response = await mutateLogout()
      redirectUrl = _.get(response, 'data.logout.redirectUrl')
    } catch (err) {
      console.warn(`Logout mutation failed: ${err.message}`)
    }
    await clearLogin()
    setAlertSuccessMessage('Logged out.')
    await clearAlert()
    client.clearStore().then(() => {
      if (redirectUrl) {
        window.location.href = redirectUrl
      } else {
        history.push('/')
      }
    })
  }

  render() {
    const { err } = this.state

    if (err) {
      return <ErrorFormat err={err} />
    } else {
      return <LoadingIndicator large />
    }
  }
}

export default compose(
  withApollo,
  withRouter,
  withStyles(logoutPageStyle),
  connect(
    state => ({user: state.token.user}),
    {clearLogin, setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(LOGOUT_USER_MUTATION, {
    props: ({ mutate }) => ({
      mutateLogout: args => mutate(args)
    }),
  })
)(LogoutPage)
