import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FixedScaleAxis } from 'chartist'
import moment from 'moment'
import _ from 'lodash'
import slugify from 'slugify'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// apollo
import { Query, Mutation, compose, graphql } from 'react-apollo'

import { hasPermission, hasAnyPermission, canReadNamespace, canWriteNamespace } from 'botium-box-shared/security/permissions'

// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import DateFormat from 'components/Info/DateFormat'
import ErrorFormat from 'components/Info/ErrorFormat'
import QueryStatus from 'components/Info/QueryStatus'
import LineChart from 'components/Stats/Charts/LineChart'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import Table from 'components/Table/AdvancedTable.jsx'
import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'
import { BrokenLinkError } from 'components/Page/ErrorPage'
import ObjectChips from 'components/Chip/ObjectChips'

import imgJson from 'assets/img/download-files/json.svg'
import imgCsv from 'assets/img/download-files/csv.svg'

import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import config from 'config'
import { downloadfileformpost } from 'helper/downloadHelper'
import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'

import {
  PERFORMANCETESTSESSION_QUERY,
  PERFORMANCETESTSESSION_STATUS_QUERY,
  DELETE_PERFORMANCETESTSESSION,
  CANCEL_PERFORMANCETESTSESSION,
  PERFORMANCETESTSESSIONTREND_QUERY,
  START_PERFORMANCETEST
} from './gql'

import SvgUsersStartNumber from 'assets/img/performance_testing/p-start.svg'
import SvgUsersPlusNumber from 'assets/img/performance_testing/p-plus.svg'
import SvgTestDuration from 'assets/img/performance_testing/TestDuration.svg'
import SvgStepDuration from 'assets/img/performance_testing/Stepduration.svg'
import SvgAcceptableRate from 'assets/img/performance_testing/accaptablerate.svg'
import SvgConvoSession from 'assets/img/performance_testing/sharedconversationsession.svg'
import SvgHelloConvo from 'assets/img/performance_testing/helloconversation.svg'
import SvgRequestTimeout from 'assets/img/performance_testing/requesttimeout.svg'
import SvgEmail from 'assets/img/performance_testing/emailnotification.svg'
import SvgDetailedLogs from 'assets/img/performance_testing/detailed_logs.svg'

import SvgUsersStartNumberDarkmode from 'assets/img/performance_testing/darkmode/p-start.svg'
import SvgUsersPlusNumberDarkmode from 'assets/img/performance_testing/darkmode/p-plus.svg'
import SvgTestDurationDarkmode from 'assets/img/performance_testing/darkmode/TestDuration.svg'
import SvgStepDurationDarkmode from 'assets/img/performance_testing/darkmode/Stepduration.svg'
import SvgAcceptableRateDarkmode from 'assets/img/performance_testing/darkmode/accaptablerate.svg'
import SvgConvoSessionDarkmode from 'assets/img/performance_testing/darkmode/sharedconversationsession.svg'
import SvgHelloConvoDarkmode from 'assets/img/performance_testing/darkmode/helloconversation.svg'
import SvgRequestTimeoutDarkmode from 'assets/img/performance_testing/darkmode/requesttimeout.svg'
import SvgEmailDarkmode from 'assets/img/performance_testing/darkmode/emailnotification.svg'
import SvgDetailedLogsDarkmode from 'assets/img/performance_testing/darkmode/detailed_logs.svg'

import Text from 'components/Typography/Text'

import { getAggregatedData, getChartDataExecDuration, getChartDataUsers, getChartDataFailed } from './helper.jsx'
import ListItem from 'components/List/ListItem/ListItem'
import Divider from 'components/Divider/Divider'
import { isDarkmode } from 'components/Darkmode/helper'
import FileBrowser from 'views/Settings/FileBrowser'
import {formatMillisecondsDuration} from '../helper'
import { getRootPath } from '../TestSessions/helper'


class PerformanceTestSession extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {
        page: 0,
        rowsPerPage: 25
      }
    }
  }

  hasReadPermission() {
    return this.props.hasReadPermissions
  }
  hasWritePermission() {
    return this.props.hasWritePermissions
  }

  renderJobs(performancetestsession) {
    return <Card>
      <CardBody noPadding>
        <Table
          tableHeaderColor="primary"
          tableHead={[
            'Status',
            'Agent',
            'Started',
            'Finished',
            'Error message'
          ]}
          tableData={
            performancetestsession && performancetestsession.jobs &&
            performancetestsession.jobs.map(job => [
              () => job.status === 'FAILED' ? <Text danger>{job.status}</Text> : job.status === 'READY' ? <Text success>{job.status}</Text> : <Text warning>{job.status}</Text>,
              job.agentName,
              () => <DateFormat>{job.started}</DateFormat>,
              () => <DateFormat>{job.finished}</DateFormat>,
              () => <Text danger>{job.err}</Text>
            ])
          }
        />
      </CardBody>
    </Card>
  }

  renderAnalytics(performancetestsession) {
    const { setAlertSuccessMessage, setAlertErrorMessage, classes } = this.props
    const logdir = `resources/performancetests/${slugify(performancetestsession.name || 'unknown')}_${slugify(performancetestsession.id || 'unknown')}`
    return (
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} >
              <GridContainer>
                <GridItem md={4} lg={3} grid>
                  <Card cursor  onClick={async () => {
                    downloadfileformpost(`${config.api.base}/performancebuild/${performancetestsession.id}?REPORTER=json`).then(() => setAlertSuccessMessage('Performance test results exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3} grid>
                          <img src={imgJson} alt="JSON" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Download Test Results</Text>
                          <Text header>AS JSON</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex={-1} data-unique="btnPerformanceTestSessionDownloadTestResultsJson" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={async () => {
                    downloadfileformpost(`${config.api.base}/performancebuild/${performancetestsession.id}?REPORTER=csv`).then(() => setAlertSuccessMessage('Performance test results exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3} grid>
                          <img src={imgCsv} alt="CSV" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Download Test Results</Text>
                          <Text header>AS CSV / Excel</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex={-1} data-unique="btnPerformanceTestSessionDownloadTestResultsCsv" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={async () => {
                    downloadfileformpost(`${config.api.base}/performancebuildfailedconvos/${performancetestsession.id}?REPORTER=json`).then(() => setAlertSuccessMessage('Performance test results exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3} grid>
                          <img src={imgJson} alt="JSON" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Download Failed Convos</Text>
                          <Text header>AS JSON</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex={-1} data-unique="btnPerformanceTestSessionDownloadFailedConvosJson" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={4} lg={3} grid>
                  <Card cursor onClick={async () => {
                    downloadfileformpost(`${config.api.base}/performancebuildfailedconvos/${performancetestsession.id}?REPORTER=csv`).then(() => setAlertSuccessMessage('Performance test results exported')).catch(err => setAlertErrorMessage(err.message))
                  }}>
                    <CardBody tabIndex={0} className={classes.cardLink}>
                      <GridContainer>
                        <GridItem md={3} grid>
                          <img src={imgCsv} alt="CSV" />
                        </GridItem>
                        <GridItem md={7}>
                          <Text subheader>Download Failed Convos</Text>
                          <Text header>AS CSV / Excel</Text>
                        </GridItem>
                        <GridItem md={2} middle noPadding>
                          <Button tabIndex={-1} data-unique="btnPerformanceTestSessionDownloadFailedConvosCsv" justIcon><ShowIcon icon="download" /></Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}><Divider /></GridItem>
            <GridItem xs={12}>
              <Text header>Detailed Logs</Text>
              <FileBrowser disableFileUpload currentPath={logdir} basePath={logdir} />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  }

  renderDangerZone(performancetestsession, performancetestsessionstatus, history, user) {
    const { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry } = this.props

    const isRunning = performancetestsessionstatus && performancetestsessionstatus.status !== 'READY' && performancetestsessionstatus.status !== 'FAILED'

    return (
      <GridContainer key="danger">
        {hasPermission(user, 'PERFORMANCETESTSESSIONS_DELETE') &&
          <React.Fragment>
            <GridItem md={8} lg={4}>
              <ListItem>
                <Text lg danger padding><ShowIcon icon="trash" /></Text>

                <GridContainer>
                  <GridItem md={12}><Text bold>Delete Performance Test Session</Text></GridItem>
                  <GridItem md={12}><Text>This removes the Performance Test Session and its results</Text></GridItem>
                </GridContainer>
                <Mutation
                  mutation={DELETE_PERFORMANCETESTSESSION}
                  onCompleted={data => {
                    removeRecentListEntry({
                      url: `${this.getRootPath()}/projects/view/${performancetestsession.id}`
                    })
                    setAlertSuccessMessage('Performance Test Session deleted')
                    if (performancetestsession.testProject) {
                      history.push(`${this.getRootPath()}/projects/view/${performancetestsession.testProject.id}` )
                    } else {
                      history.push(`${this.getRootPath()}`)
                    }
                  }}
                  onError={error => {
                    setAlertErrorMessage('Performance Test session deletion failed', error)
                  }}
                >
                  {(
                    deletePerformanceTestSession,
                    { loading, error },
                  ) => (
                    <ConfirmationButton
                      confirmationText={`When deleting this Performance Test Session, all it's test results are lost. You have to start another Performance Test Session to get current results. If the session is running, it will be cancelled first. Are you sure you want to delete it ?`}
                      requireCheck={true}
                      danger
                      small
                      minWidth
                      onClick={() => {
                        deletePerformanceTestSession({
                          variables: { id: performancetestsession.id },
                        })
                      }}
                      data-unique="btnPerformanceTestSessionDelete"
                    >
                      Delete
                    </ConfirmationButton>
                  )}
                </Mutation>
              </ListItem>
            </GridItem>
            <GridItem md={8}></GridItem>
          </React.Fragment>
        }
        {hasPermission(user, 'PERFORMANCETESTSESSIONS_DELETE') && hasPermission(user, 'PERFORMANCETESTSESSIONS_CREATE') &&
          <React.Fragment>
            <GridItem md={8} lg={4}><Divider dense /></GridItem>
                              <GridItem md={12} lg={8}></GridItem>
          </React.Fragment>
        }
        {hasPermission(user, 'PERFORMANCETESTSESSIONS_CREATE') &&
          <GridItem md={8} lg={4}>
            <ListItem>
              <Text lg danger padding><ShowIcon icon="power-off" /></Text>
              <GridContainer>
                <GridItem md={12}><Text bold>Send Cancellation Request</Text></GridItem>
                <GridItem md={12}><Text>Send cancellation request to Performance Test Session</Text></GridItem>
              </GridContainer>
              <Mutation
                mutation={CANCEL_PERFORMANCETESTSESSION}
                onCompleted={data => {
                  setAlertSuccessMessage('Sent cancellation request to Performance Test Session')
                }}
                onError={error => {
                  setAlertErrorMessage('Sending cancellation request to Performance Test Session failed', error)
                }}
              >
                {(
                  cancelPerformanceTestSession,
                  { loading, error },
                ) => (
                  <ConfirmationButton
                    confirmationText={`When cancelling a Performance Test Session, all background processing will be stopped and the test results are not complete. Are you sure you want to cancel it ?`}
                    requireCheck={true}
                    danger
                    small
                    minWidth
                    disabled={!isRunning}
                    onClick={() => {
                      cancelPerformanceTestSession({
                        variables: { id: performancetestsession.id },
                      })
                    }}
                    data-unique="btnPerformanceTestSessionCancel"
                  >
                    Send
                  </ConfirmationButton>
                )}
              </Mutation>
            </ListItem>
          </GridItem>
        }

      </GridContainer>
    )
  }

  getRootPath = () => {
    return getRootPath(this.props.location)
  }

  render() {
    const { history, user, match, trendData, classes, theme } = this.props
    return (
      <React.Fragment>
        <Query
          query={PERFORMANCETESTSESSION_QUERY}
          variables={{ id: match.params.id }}
          pollInterval={1000}
        >
          {({ loading: loadingOuter, error: errorOuter, data: dataOuter, stopPolling: stopPollingOuter }) => (
            <Query
              query={PERFORMANCETESTSESSION_STATUS_QUERY}
              variables={{ id: match.params.id }}
              pollInterval={1000}
            >
              {({ loading: loadingInner, error: errorInner, data: dataInner, stopPolling: stopPollingInner }) => {
                if (!loadingOuter && dataOuter && _.isNil(dataOuter.performancetestsession)) throw new BrokenLinkError()
                if (errorOuter) {
                  return <ErrorFormat err={errorOuter} />
                }
                if (errorInner) {
                  return <ErrorFormat err={errorInner} />
                }
                if (dataInner.performancetestsessionstatus && (dataInner.performancetestsessionstatus.status === 'READY' || dataInner.performancetestsessionstatus.status === 'FAILED')) {
                  stopPollingOuter()
                  stopPollingInner()
                }
                const performancetestsession = dataOuter.performancetestsession || { results: [], jobs: [] }

                const { execDurationAvg, execDurationMedian, maxStepIndex, totalExecCount, totalFailedCount, dataStartAtMs, dataEndAtMs } = getAggregatedData(performancetestsession)
                const chartDataUsers = getChartDataUsers(performancetestsession)
                const chartDataExecDuration = getChartDataExecDuration(performancetestsession)
                const chartDataFailed = getChartDataFailed(performancetestsession)
                if (chartDataUsers) {
                  chartDataUsers.series[0].stroke = theme.colors.performancetext
                  chartDataUsers.series[0].fill = theme.colors.performancetext
                }
                if (chartDataExecDuration) {
                  chartDataExecDuration.series[0].stroke = theme.colors.performancetext
                }
                if (chartDataFailed) {
                  chartDataFailed.series[0].stroke = theme.colors.performancetext
                }

                const labelInterpolationFncDuration = (value) => {
                  const duration = value * 1000
                  return `${moment.utc(duration).format('HH:mm:ss')}`
                }
                const labelInterpolationFncMs = (value) => {
                  // To deal with N/A
                  if (!_.isNumber(value)) {
                    return value
                  }

                  return moment.utc(value).format('mm:ss.SSS')
                }

                const labelInterpolationFncPercent = (value) => {
                  // To deal with N/A
                  if (!_.isNumber(value)) {
                    return value
                  }

                  return `${value * 100}%`
                }
                const labelInterpolationFncCount = (value) => {
                  return value
                }
                const jobErrors = (performancetestsession.jobs && _.uniq(performancetestsession.jobs.filter(j => j.status === 'FAILED' && j.err).map(j => j.err))) || []
                return (
                  <React.Fragment>
                    <CustomTabs
                      name={`tabPerformanceTestSession_${match.params.id}`}
                      headerColor="info"
                      plainTabs
                      tabs={[
                        {
                          tabName: 'Overview',
                          tabIcon: <ShowIcon icon="tachometer-alt" />,
                          locationPrefix: `${this.getRootPath()}/projects/view/${performancetestsession.testProject.id}/results/${performancetestsession.id}/overview`,
                          dataUnique: 'tabTestProjectPerformanceTestStatistics',
                          tabContent:
                          <GridContainer>

                            {this.hasReadPermission() && <GridItem xs={12} right middle smallPadding smallMarginRight>
                              <Text primary>Recent Performance Test Sessions</Text><Text paddingLeftRight>  |  </Text><Text primary><DateFormat seconds>{performancetestsession.createdAt}</DateFormat></Text>
                                {
                                  (trendData && trendData.performancetestsessiontrend && trendData.performancetestsessiontrend.previousPerformanceTestSessionId &&
                                    <NavLink
                                      to={`${this.getRootPath()}/projects/view/${performancetestsession.testProject.id}/results/${trendData.performancetestsessiontrend.previousPerformanceTestSessionId}/overview`}>
                                      <Tooltip title="View Previous Performance Test Session">
                                        <Button justIcon Border>
                                          <ShowIcon icon="angle-left" />
                                        </Button>
                                      </Tooltip>
                                    </NavLink>) || <Button justIcon Border disabled><ShowIcon icon="angle-left" /></Button>
                                }
                                {
                                  (trendData && trendData.performancetestsessiontrend && trendData.performancetestsessiontrend.nextPerformanceTestSessionId &&
                                    <NavLink
                                      to={`${this.getRootPath()}/projects/view/${performancetestsession.testProject.id}/results/${trendData.performancetestsessiontrend.nextPerformanceTestSessionId}/overview`}>
                                      <Tooltip title="View Next Performance Test Session">
                                        <Button justIcon Border>
                                          <ShowIcon icon="angle-right" />
                                        </Button>
                                      </Tooltip>
                                    </NavLink>) || <Button justIcon Border disabled><ShowIcon icon="angle-right" /></Button>
                                }
                              <GridItem smallMarginRight ></GridItem>
                              {performancetestsession.testProject &&
                                <Mutation
                                  mutation={START_PERFORMANCETEST}
                                >
                                {(startPerformanceTest, {loading, error}) => {
                                  return <React.Fragment>
                                    <Button fullRound small
                                      id = "performanceTestSessionRestart"
                                      data-unique = "btnPerformanceTestSessionRestart"
                                      disabled =  {!dataInner.performancetestsessionstatus || dataInner.performancetestsessionstatus.status === 'PENDING' || dataInner.performancetestsessionstatus.status === 'RUNNING'}
                                      icon = "play-circle"
                                      onClick={ async () => {
                                        const PATTERN = 'YYYY-MM-DD HH-mm'
                                        let name
                                        if (performancetestsession.name && (performancetestsession.name.length > PATTERN.length) && moment(performancetestsession.name.substring(performancetestsession.name.length - PATTERN.length), PATTERN, true).isValid()) {
                                          name = performancetestsession.name.substring(0, performancetestsession.name.length - 'YYYY-MM-DD HH-mm'.length) + moment().format('YYYY-MM-DD HH-mm')
                                        } else {
                                          name = `${performancetestsession.name} - ${moment().format('YYYY-MM-DD HH-mm')}`
                                        }
                                        try {
                                          const res = await startPerformanceTest({
                                            variables: {
                                              testProjectId: performancetestsession.testProject.id,
                                              options: {
                                                name: name,
                                                description: performancetestsession.description,
                                                parallelJobCount: performancetestsession.parallelJobCount,
                                                tickRepeatInitial: performancetestsession.tickRepeatInitial,
                                                tickRepeatPerTick: performancetestsession.tickRepeatPerTick,
                                                tickMaxTime: performancetestsession.tickMaxTime,
                                                tickTime: performancetestsession.tickTime,
                                                requiredPercentOfSuccesfulUsers: performancetestsession.requiredPercentOfSuccesfulUsers,
                                                shareContainer: performancetestsession.shareContainer,
                                                simpleConvo: performancetestsession.simpleConvo,
                                                waitForBotTimeout: performancetestsession.waitForBotTimeout,
                                                notification: performancetestsession.notification,
                                                detailedReporting: performancetestsession.detailedReporting,
                                                tags: {set: performancetestsession.tags}
                                              }
                                            }
                                          })
                                          setAlertSuccessMessage('Performance test started')
                                          const testSessionId = res.data.createPerformanceTestSession.id
                                          history.push(`${this.getRootPath()}/projects/view/${performancetestsession.testProject.id}/results/${testSessionId}`)
                                        } catch (error) {
                                          setAlertErrorMessage('Performance test failed', error)
                                        }
                                      }}
                                    >
                                      <ShowIcon icon="redo" /> Repeat Test Session
                                    </Button>
                                  </React.Fragment>
                                }}
                                </Mutation>
                              }
                            </GridItem>}
                            {this.hasReadPermission() && <GridItem xs={12} grid>


                                      <GridContainer border borderRadius noMargin>
                                      <GridItem lg borderRight>
                                        <Card noBorder noMarginBottom  noMarginTop>
                                          <CardBody LargePadding>
                                              <GridContainer fullWidth>
                                                <GridItem lg={12} middle noPaddingLeft><Text regular>Processed Convos</Text></GridItem>
                                                <GridItem lg={12} middle className={classes.textLeftBorderDefault}>
                                                  <Text mlg bold>{totalExecCount === 'N/A' ? '?' : totalExecCount}</Text>
                                                </GridItem>
                                              </GridContainer>
                                            </CardBody>
                                          </Card>
                                        </GridItem>
                                        <GridItem lg borderRight>
                                        <Card noBorder noMarginBottom  noMarginTop>
                                          <CardBody LargePadding>
                                              <GridContainer fullWidth>
                                                <GridItem lg={12} middle noPaddingLeft><Text regular>Succeeded</Text></GridItem>
                                                <GridItem lg={12} middle className={classes.textLeftBorderGreen}>
                                                  <Text mlg bold>{totalExecCount === 'N/A' ? '?' : totalExecCount - totalFailedCount}</Text>
                                                </GridItem>
                                              </GridContainer>
                                            </CardBody>
                                          </Card>
                                        </GridItem>
                                        <GridItem lg borderRight>
                                        <Card noBorder noMarginBottom  noMarginTop>
                                          <CardBody LargePadding>
                                              <GridContainer fullWidth>
                                                <GridItem lg={12} middle noPaddingLeft><Text regular>Failed</Text></GridItem>
                                                <GridItem lg={12} middle className={classes.textLeftBorderRed}>
                                                  <Text mlg bold>{totalFailedCount === 'N/A' ? '?' : totalFailedCount}</Text>
                                                </GridItem>
                                              </GridContainer>
                                            </CardBody>
                                          </Card>
                                        </GridItem>
                                        <GridItem lg borderRight>
                                        <Card noBorder noMarginBottom  noMarginTop>
                                          <CardBody LargePadding>
                                              <GridContainer fullWidth>
                                                <GridItem lg={12} middle noPaddingLeft><Text regular>Parallel Users</Text></GridItem>
                                                <GridItem lg={12} middle className={classes.textLeftBorderYellow}>
                                                  <Text mlg bold>{performancetestsession.results && performancetestsession.results.length > 0 ? performancetestsession.results[performancetestsession.results.length-1].execCount : '?'}</Text>
                                                </GridItem>
                                                <GridItem lg={12} middle noPaddingLeft largePaddingTop>
                                                </GridItem>
                                              </GridContainer>
                                            </CardBody>
                                          </Card>
                                        </GridItem>
                                        <GridItem lg borderRight>
                                        <Card noBorder noMarginBottom  noMarginTop>
                                          <CardBody LargePadding>
                                              <GridContainer fullWidth>
                                                <GridItem lg={12} middle noPaddingLeft><Text regular>Average Duration</Text></GridItem>
                                                <GridItem lg={12} middle className={classes.textLeftBorderYellow}>
                                                  <Text mlg bold>{formatMillisecondsDuration(execDurationAvg)}</Text>
                                                </GridItem>
                                                <GridItem lg={12} middle noPaddingLeft largePaddingTop>
                                                </GridItem>
                                              </GridContainer>
                                            </CardBody>
                                          </Card>
                                        </GridItem>
                                        <GridItem lg borderRight>
                                        <Card noBorder noMarginBottom  noMarginTop>
                                          <CardBody LargePadding>
                                              <GridContainer fullWidth>
                                                <GridItem lg={12} middle noPaddingLeft><Text regular>Test Duration</Text></GridItem>
                                                <GridItem lg={12} middle className={classes.textLeftBorderYellow}>
                                                  <Text mlg bold>{(_.isNil(dataStartAtMs) || _.isNil(dataEndAtMs)) ? '?' : formatMillisecondsDuration(dataEndAtMs - dataStartAtMs)}</Text>
                                                </GridItem>
                                                <GridItem lg={12} middle noPaddingLeft largePaddingTop>
                                                </GridItem>
                                              </GridContainer>
                                            </CardBody>
                                          </Card>
                                        </GridItem>
                                      </GridContainer>
                                    </GridItem>

                            }
                            {this.hasReadPermission() &&jobErrors && jobErrors.length > 0 &&
                              <GridItem xs={12}>
                                <Card>
                                  <CardHeader>
                                    <Text header danger>Failure Alerts</Text>
                                  </CardHeader>
                                  <CardBody>
                                    <GridContainer data-unique="txtTestSession_FAILED">
                                      {jobErrors.map((err, index) =>
                                        <GridItem xs={12} key={index}>
                                          <ErrorFormat err={err} suppress split/>
                                        </GridItem>
                                      )}
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            }
                            <GridItem xs={12}>
                              <ObjectChips rootPath={this.getRootPath()} dataUniquePrefix="btnTestSession" testProject={performancetestsession.testProject} chatbot={performancetestsession.chatbot} testSets={performancetestsession.testSets} deviceSets={performancetestsession.deviceSets} />
                            </GridItem>
                            {this.hasReadPermission() &&chartDataFailed && <GridItem xs={12}>
                              <Card>
                                <CardHeader>
                                  <Text header>Failed User Rate</Text>
                                  <Text subheader>This chart shows the rate of the failed users.</Text>
                                </CardHeader>
                                <CardBody>
                                  <LineChart
                                    refresh={true}
                                    data={chartDataFailed}
                                    legend={false}
                                    options={{
                                      showPoint: false,
                                      axisX: {
                                        type: FixedScaleAxis,
                                        low: 0,
                                        high: maxStepIndex,
                                        divisor: 5,
                                        labelInterpolationFnc: labelInterpolationFncDuration
                                      },
                                      axisY: {
                                        offset: 70,
                                        labelInterpolationFnc: labelInterpolationFncPercent
                                      }
                                    }} />
                                </CardBody>
                              </Card>
                            </GridItem>}
                            {this.hasReadPermission() && <GridItem xs={12}>

                              <Card>
                                <CardHeader>
                                  <Text header>Average Convo Duration</Text>
                                  <Text subheader>Average: {labelInterpolationFncMs(execDurationAvg)}</Text>
                                  <Text subheader>Median: {labelInterpolationFncMs(execDurationMedian)}</Text>
                                </CardHeader>
                                <CardBody>
                                  <GridContainer>

                                    <GridItem xs={12}>
                                      <LineChart
                                        refresh={true}
                                        data={chartDataExecDuration}
                                        legend={false}
                                        options={{
                                          showPoint: false,
                                          axisX: {
                                            type: FixedScaleAxis,
                                            low: 0,
                                            high: maxStepIndex,
                                            divisor: 5,
                                            labelInterpolationFnc: labelInterpolationFncDuration
                                          },
                                          axisY: {
                                            offset: 70,
                                            labelInterpolationFnc: labelInterpolationFncMs
                                          }
                                        }} />
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>}
                            {this.hasReadPermission() && <GridItem xs={12}>
                              <Card>
                                <CardHeader>
                                  <Text header>Parallel Users (Expected vs Real)</Text>
                                  <Text subheader>If Real User Number is not the same as Expected, then the current Botium Architecture does not meet your needs. Please contact your Botium Administrator.</Text>
                                </CardHeader>
                                <CardBody>
                                  <GridContainer>
                                    <GridItem xs={12}>
                                      <LineChart
                                        refresh={true}
                                        data={chartDataUsers}
                                        legend={false}
                                        options={{
                                          showPoint: false,
                                          showArea: true,
                                          showLine: false,
                                          axisX: {
                                            type: FixedScaleAxis,
                                            low: 0,
                                            high: maxStepIndex,
                                            divisor: 5,
                                            labelInterpolationFnc: labelInterpolationFncDuration
                                          },
                                          axisY: {
                                            offset: 70,
                                            labelInterpolationFnc: labelInterpolationFncCount
                                          }
                                        }} />
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>}
                          </GridContainer>
                        },
                        this.hasReadPermission() && {
                          tabName: 'Performance Test Jobs',
                          tabIcon: <ShowIcon icon="cogs" />,
                          locationPrefix: `${this.getRootPath()}/projects/view/${performancetestsession.testProject.id}/results/${performancetestsession.id}/jobs`,
                          dataUnique: 'tabTestProjectPerformanceTestJobs',
                          tabContent: !(loadingInner || loadingOuter) && this.renderJobs(performancetestsession)
                        },
                        this.hasReadPermission() && {
                          tabName: 'Parameters',
                          tabIcon: <ShowIcon icon="wrench" />,
                          locationPrefix: `${this.getRootPath()}/projects/view/${performancetestsession.testProject.id}/results/${performancetestsession.id}/parameters`,

                          dataUnique: 'tabTestProjectPerformanceTestParameters',
                          tabContent: (
                            <GridContainer>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgTestDurationDarkmode : SvgTestDuration} alt="Test Duration" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Test Duration</Text>
                                        <Text>{performancetestsession.tickMaxTime / 60000} Minute(s)</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgStepDurationDarkmode : SvgStepDuration} alt="Test Duration" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Maximum Number of Parallel Users</Text>
                                        <Text>{Math.ceil((performancetestsession.tickRepeatInitial + ((performancetestsession.tickMaxTime / 60000 * 60 / (performancetestsession.tickTime / 1000) - 1) * performancetestsession.tickRepeatPerTick)))}</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgStepDurationDarkmode : SvgStepDuration} alt="Test Step Duration" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Test Step Duration</Text>
                                        <Text>{performancetestsession.tickTime / 1000} Second(s)</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgUsersStartNumberDarkmode : SvgUsersStartNumber} alt="Users in First Step" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Initial Users</Text>
                                        <Text>{performancetestsession.tickRepeatInitial}</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgUsersPlusNumberDarkmode : SvgUsersPlusNumber} alt="New Users per Test Step" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>New Users per Test Step</Text>
                                        <Text>{performancetestsession.tickRepeatPerTick}</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgAcceptableRateDarkmode : SvgAcceptableRate} alt="Required percentage of successful users" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Required percentage of successful users</Text>
                                        <Text>{performancetestsession.requiredPercentOfSuccesfulUsers ? performancetestsession.requiredPercentOfSuccesfulUsers + '%' : '0%'}</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgConvoSessionDarkmode : SvgConvoSession} alt="Shared Core Shared Conversation Session" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Use Shared Conversation Session</Text>
                                        <Text>{performancetestsession.shareContainer ? 'Shared' : 'Separated'}</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgHelloConvoDarkmode : SvgHelloConvo} alt="Use simple 'Hello' conversation" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Use simple 'Hello' conversation</Text>
                                        <Text>{performancetestsession.simpleConvo ? 'Enabled' : 'Disabled'}</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgRequestTimeoutDarkmode : SvgRequestTimeout} alt="Request Timeout" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Request Timeout</Text>
                                        <Text>{performancetestsession.waitForBotTimeout ? `${performancetestsession.waitForBotTimeout / 1000} s` : '10 s'}</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img width="80" height="60" src={isDarkmode() ? SvgDetailedLogsDarkmode : SvgDetailedLogs} alt="Detailed Logs" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Detailed Logs</Text>
                                        <Text>{performancetestsession.detailedReporting ? 'Enabled' : 'Disabled'}</Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem md={4} grid>
                                <Card>
                                  <CardBody>
                                    <GridContainer alignItems="center" justify="center" autoHeight>
                                      <GridItem md={4}>
                                        <img src={isDarkmode() ? SvgEmailDarkmode : SvgEmail} alt="Email notification" />
                                      </GridItem>
                                      <GridItem md={8}>
                                        <Text bold>Email notification</Text>
                                        <Text>
                                          {performancetestsession.notification === 'PROJECT_DEFAULT' && 'Project default'}
                                          {performancetestsession.notification === 'NEVER' && 'Never'}
                                          {performancetestsession.notification === 'FAILED' && 'On Failure'}
                                          {performancetestsession.notification === 'ALWAYS' && 'Always'}
                                        </Text>
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          )
                        },
                        this.hasReadPermission() && hasPermission(user, 'PERFORMANCETESTSESSIONS_REPORTS') && {
                          tabName: 'Downloads',
                          tabIcon: <ShowIcon icon="cloud-download-alt" />,
                          dataUnique: 'tabTestProjectPerformanceTestDownloads',
                          locationPrefix: `${this.getRootPath()}/projects/view/${performancetestsession.testProject.id}/results/${performancetestsession.id}/downloads`,
                          tabContent: !(loadingInner || loadingOuter) && this.renderAnalytics(performancetestsession)
                        },
                        this.hasWritePermission() && hasAnyPermission(user, ['PERFORMANCETESTSESSIONS_DELETE', 'PERFORMANCETESTSESSIONS_CREATE']) && {
                          tabName: 'Danger Zone',
                          tabRight: true,
                          tabIcon: <ShowIcon icon="exclamation-triangle" />,
                          locationPrefix: `${this.getRootPath()}/projects/view/${performancetestsession.testProject.id}/results/${performancetestsession.id}/dangerzone`,
                          dataUnique: 'tabTestProjectPerformanceTestDangerZone',
                          tabContent: !(loadingInner || loadingOuter) && this.renderDangerZone(performancetestsession, dataInner.performancetestsessionstatus, history, user)
                        },
                      ].filter(t => t)} />
                  </React.Fragment>
                )
              }}
            </Query>
          )}
        </Query>
      </React.Fragment>
    )
  }
}

const PerformanceTestSessionComponent = compose(
  withStyles(testsessionsStyle, { withTheme: true }),
  connect(
    state => ({ user: state.token.user }),
    { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
  ),
  graphql(PERFORMANCETESTSESSIONTREND_QUERY, {
    props: ({ data }) => ({
      trendData: data,
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.id
        },
      }
    }

  }),
)(PerformanceTestSession)


export default connect(state => ({ user: state.token.user }))(function ({ match, user, ...rest }) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        {match.params && match.params.id && (
          <Query query={PERFORMANCETESTSESSION_QUERY} variables={{ id: match.params.id }}>
            {(queryResult) => <QueryStatus {...queryResult} query="performancetestsession" card>{({ performancetestsession }) => {
              return <PerformanceTestSessionComponent
                match={match}
                hasReadPermissions={hasPermission(user, 'PERFORMANCETESTSESSIONS_SELECT') && canReadNamespace(user, user.namespacePermissions, performancetestsession.testSession.namespace)}
                hasWritePermissions={hasAnyPermission(user, ['PERFORMANCETESTSESSIONS_CREATE', 'PERFORMANCETESTSESSIONS_DELETE']) && canWriteNamespace(user, user.namespacePermissions, performancetestsession.testSession.namespace)}
                {...rest} />
            }}</QueryStatus>}
          </Query>
        )}
      </GridItem>
    </GridContainer>
  )
})
