import dashboardStyle from './dashboardStyle.jsx'

const crawlerprojectsStyle = theme => ({
  ...dashboardStyle(theme),
  quickcreatcrawlerprojectbutton: {
    position: 'relative',
    top: '15px !Important',
  },
  namespace: {
    border: `1px solid ${theme.colors.borderColorhover} !important`,
    borderRadius: 5,
    padding: '3px 10px'
  },
  '@media (max-width: 1280px)': {
    scriptTypeFild: {
      order: 2,
    },
    sourceEditorSwitch: {
      order: 1,
    }
  }

})

export default crawlerprojectsStyle
