/**
 * Gets a resolved convo txt from the result of server.grid.utils.getConvoWithResolvedUtterance function. It is very basic because its
 * enough for generating user responses for open ended questions.
 * @param ctx
 * @param script
 * @returns {Promise<null|{scriptTxt: *}>}
 */
export const getConvoTxtWithResolvedUtterance = async (steps) => {
  if (!steps) {
    console.warn('Cant create resolved convo if there are no steps')
    return ''
  }
  let result = ''
  for (const step of steps) {
    result += `#${step.sender}\n`
    if (step.utteranceSamples && step.utteranceSamples.length) {
      result += step.utteranceSamples[0] + '\n'
    } else if (step.messageText) {
      result += step.messageText + '\n'
    }

    for (const buttonAsserter of (step.asserters || []).filter(asserter => asserter.name === 'BUTTONS')) {
      result += `BUTTONS ${buttonAsserter.args.join('|')}\n`
    }

    for (const buttonUserInput of (step.userInputs || []).filter(userInput => userInput.name === 'BUTTON')) {
      result += `BUTTON ${buttonUserInput.args.join('|')}\n`
    }
  }

  return result.trim()
}

export const redirectToConvoEditorView = (history, crawlerSession, historyView, scriptId, scriptType) => {
  if (scriptType === 'SCRIPTING_TYPE_UTTERANCES') {
    if(historyView) {
      history.push(`/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/history/${crawlerSession.id}/scriptview/scripts/viewutterance/${scriptId}`)
    } else {
      history.push(`/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/scriptview/scripts/viewutterance/${scriptId}`)
    }
  } else {
    if(historyView) {
      history.push(`/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/history/${crawlerSession.id}/scriptview/scripts/viewconvo/${scriptId}`)
    } else {
      history.push(`/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/scriptview/scripts/viewconvo/${scriptId}`)
    }
  }
}

export const redirectToSourceEditorView = (history, crawlerSession, historyView, scriptId) => {
  if(historyView) {
    history.push(`/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/history/${crawlerSession.id}/scriptview/scripts/viewscript/${scriptId}`)
  } else {
    history.push(`/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/scriptview/scripts/viewscript/${scriptId}`)
  }
}
