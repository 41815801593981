import React, { useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import GridItem from '../Grid/GridItem.jsx'
import GridContainer from '../Grid/GridContainer.jsx'
import RegularButton from './Button'
import ConfirmationDialog from '../Dialog/ConfirmationDialog.jsx'
import { CustomCheckbox } from 'components/Form/Form'
import Text from 'components/Typography/Text'

function ConfirmationButton({ ...props }) {
  const {
    confirmationTitle,
    confirmationText,
    confirmationTextFn,
    confirmationBody,
    requireCheck,
    requireCheckText,
    onClick,
    children,
    danger,
    ...rest
  } = props

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [checkingConfirmationText, setCheckingConfirmationText] = useState(false)
  const [finalConfirmationText, setFinalConfirmationText] = useState(confirmationText || '')
  const [checked, setChecked] = useState(false)

  return (<React.Fragment>
    <RegularButton {...rest}
      danger={danger}
      disabled={rest.disabled || (confirmationTextFn && checkingConfirmationText)}
      onClick={async () => {
        if (confirmationTextFn) {
          setCheckingConfirmationText(true)
          try {
            const isRequired = await confirmationTextFn()
            if (isRequired) {
              if (_.isString(isRequired)) setFinalConfirmationText(isRequired)
              setShowConfirmationDialog(true)
            } else {
              onClick()
            }
          } catch (err) {
            setShowConfirmationDialog(true)
          }
          setCheckingConfirmationText(false)
        } else {
          setShowConfirmationDialog(true)
        }
      }}>
      {children}
    </RegularButton>
    <ConfirmationDialog
      danger={danger}
      open={showConfirmationDialog}
      closeDisabled
      okDisabled={requireCheck && !checked}
      okText="Yes, I am sure. Do it!"
      cancelText="Cancel"
      onCancel={() => setShowConfirmationDialog(false)}
      onOk={() => {
        if (!requireCheck || checked) {
          setShowConfirmationDialog(false)
          onClick()
        }
      }}
      title={confirmationTitle}
    >
      <GridContainer>
        <GridItem xs={12} center largeMargin>
          <Text header center>{finalConfirmationText}</Text>
        </GridItem>
        {confirmationBody && <GridItem xs={12} center>
          {confirmationBody}
        </GridItem>}
        {requireCheck &&
          <GridItem xs={12} center>
            <CustomCheckbox
              input={{
                onChange: e => {
                  setChecked(e.target.checked)
                },
                checked
              }}
              label={requireCheckText}
              data-unique="chkConfirmationCheck"
            />
          </GridItem>
        }
      </GridContainer>
    </ConfirmationDialog>
  </React.Fragment>)
}

ConfirmationButton.propTypes = {
  confirmationTitle: PropTypes.string,
  confirmationText: PropTypes.string,
  confirmationTextFn: PropTypes.func,
  confirmationBody: PropTypes.any,
  requireCheck: PropTypes.bool,
  requireCheckText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  ...RegularButton.propTypes
}
ConfirmationButton.defaultProps = {
  confirmationTitle: 'Are you sure?',
  requireCheck: false,
  requireCheckText: 'I am sure.'
}

export default ConfirmationButton
