import React from 'react'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Text from 'components/Typography/Text.jsx'

import WatsonExport from 'components/Capability/WatsonExport.jsx'
import DialogFlowExport from 'components/Capability/DialogFlowExport.jsx'
import LexExport from 'components/Capability/LexExport.jsx'
import AlexaSMAPIExport from 'components/Capability/AlexaSMAPIExport.jsx'
import RasaExport from 'components/Capability/RasaExport.jsx'
import LUISExport from 'components/Capability/LUISExport.jsx'
import QnAMakerExport from 'components/Capability/QnAMakerExport.jsx'
import InbentaExport from 'components/Capability/InbentaExport.jsx'
import DialogFlowCxExport from 'components/Capability/DialogFlowCxExport.jsx'
import GenericExport from 'components/Capability/GenericExport.jsx'
import AzureCLUExport from 'components/Capability/AzureCLUExport.jsx'
import AzureCQAExport from 'components/Capability/AzureCQAExport.jsx'
import NuanceExport from 'components/Capability/NuanceExport.jsx'
import KoreaiWebhookExport from 'components/Capability/KoreaiWebhookExport.jsx'

import { CHATBOT_QUERY } from '../Chatbots/gql'

class NLPUploadWizard extends React.Component {
  render() {
    const { match, testSetId, chatbotData } = this.props

    const wizardTestSetId = testSetId || (match && match.params && match.params.testSetId)
    const chatbot = chatbotData && chatbotData.chatbot

    return (<GridContainer>
      {chatbot && (
        <GridItem md={12} lg={8}>
          <Card>
            <CardHeader color="info">
              <Text header>Export Conversation Model</Text>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  {chatbot.containermode === 'watson' && <WatsonExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'dialogflow' && <DialogFlowExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'luis' && <LUISExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'lex' && <LexExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'rasa' && <RasaExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'alexa-smapi' && <AlexaSMAPIExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'qnamaker' && <QnAMakerExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'echo' && <GenericExport chatbotId={chatbot.id} testSetId={wizardTestSetId} connectorNameSuffix="Echo" connectorName="Echo" />}
                  {chatbot.containermode === 'dialogflowcx' && <DialogFlowCxExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'inbenta' && <InbentaExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'nlpjs' && <GenericExport chatbotId={chatbot.id} testSetId={wizardTestSetId} connectorNameSuffix="Nlpjs" connectorName="NLP.js" />}
                  {chatbot.containermode === 'azure-clu' && <AzureCLUExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'azure-cqa' && <AzureCQAExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'nuance' && <NuanceExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                  {chatbot.containermode === 'koreai-webhook' && <KoreaiWebhookExport chatbotId={chatbot.id} testSetId={wizardTestSetId} />}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      )}
    </GridContainer>)
  }
}

export default compose(
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
  ),
  graphql(CHATBOT_QUERY, {
    props: ({ data }) => ({
      chatbotData: data
    }),
    options: (props) => {
      const { match, chatbotId } = props
      return {
        variables: {
          id: chatbotId || (match && match.params && match.params.chatbotId)
        },
      }
    },
    skip: (props) => {
      const { match, chatbotId } = props
      return !chatbotId && (!match || !match.params || !match.params.chatbotId)
    }
  })
)(NLPUploadWizard)
