import React from 'react'
import {connect} from 'react-redux'
import config from 'config'
import copyToClipboard from 'copy-to-clipboard'
// @material-ui/core components
import {FormSpy} from 'react-final-form'
import Field from 'components/Form/OptionalField'
import {OnChange} from 'react-final-form-listeners'
// apollo
import {Query} from 'react-apollo'
// core components
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  renderTextField,
  renderSelect, renderCodeArea, prettyPrintJson, json, renderPasswordField, renderCheckbox
} from 'components/Form/Form'

import {APIKEYS_QUERY} from '../../views/Settings/gql'
import Button from '../Button/Button'
import {setAlertSuccessMessage} from '../../actions/alert'
import { capSetDescription, usedByCapabilitySet } from './Helper'
import {getConnector} from '../../actions/settings'

const USER_DATA_SAMPLE = JSON.stringify({
  nickname: 'John Doe',
  id: 'john.doe@externalservice.com',
  idType: 'email',
  firstName: 'John',
  lastName: 'Doe'
}, null, 2)

const CUSTOM_ATTRIBUTE_SAMPLE = JSON.stringify({
  customerAccountId: 123456789,
  customerName: 'John Doe',
  department: 'sales',
  device: 'mobile'
}, null, 2)

export function genesysCaps2Form(caps) {
  return {
    genesys: {
      messagingChannel: (
        caps.find(c => c.name === 'GENESYS_MESSAGING_CHANNEL') || {stringValue: 'WEB_MESSAGING'}
      ).stringValue,
      deploymentId: (
        caps.find(c => c.name === 'GENESYS_DEPLOYMENT_ID') || {stringValue: ''}
      ).stringValue,
      awsRegion: (
        caps.find(c => c.name === 'GENESYS_AWS_REGION') || {stringValue: ''}
      ).stringValue,
      clientId: (
        caps.find(c => c.name === 'GENESYS_CLIENT_ID') || {stringValue: ''}
      ).stringValue,
      clientSecret: (
        caps.find(c => c.name === 'GENESYS_CLIENT_SECRET') || {stringValue: ''}
      ).stringValue,
      openMessagingIntegrationId: (
        caps.find(c => c.name === 'GENESYS_OPEN_MESSAGING_INTEGRATION_ID') || {stringValue: ''}
      ).stringValue,
      inboundMessageFlowName: (
        caps.find(c => c.name === 'GENESYS_INBOUND_MESSAGE_FLOW_NAME') || {stringValue: ''}
      ).stringValue,
      userData: (
        caps.find(c => c.name === 'GENESYS_USER_DATA') || {jsonValue: ''}
      ).jsonValue,
      customAttributes: (
        caps.find(c => c.name === 'GENESYS_CUSTOM_ATTRIBUTES') || {jsonValue: ''}
      ).jsonValue,
      nlpAnalytics: (
        caps.find(c => c.name === 'GENESYS_NLP_ANALYTICS') || {booleanValue: false}
      ).booleanValue,
      botFlowAttributeName: (
        caps.find(c => c.name === 'GENESYS_BOT_FLOW_ATTRIBUTE_NAME') || {stringValue: ''}
      ).stringValue,
    }
  }
}

const capNamesMap = {
  'genesys.messagingChannel': 'GENESYS_MESSAGING_CHANNEL',
  'genesys.deploymentId': 'GENESYS_DEPLOYMENT_ID',
  'genesys.awsRegion': 'GENESYS_AWS_REGION',
  'genesys.clientId': 'GENESYS_CLIENT_ID',
  'genesys.clientSecret': 'GENESYS_CLIENT_SECRET',
  'genesys.openMessagingIntegrationId': 'GENESYS_OPEN_MESSAGING_INTEGRATION_ID',
  'genesys.inboundMessageFlowName': 'GENESYS_INBOUND_MESSAGE_FLOW_NAME',
  'genesys.userData': 'GENESYS_USER_DATA',
  'genesys.customAttributes': 'GENESYS_CUSTOM_ATTRIBUTES',
  'genesys.nlpAnalytics': 'GENESYS_NLP_ANALYTICS',
  'genesys.botFlowAttributeName': 'GENESYS_BOT_FLOW_ATTRIBUTE_NAME',
}

export function genesysForm2caps(values) {
  return [
    {
      name: 'GENESYS_MESSAGING_CHANNEL',
      type: 'STRING',
      stringValue: values.genesys.messagingChannel || '',
    },
    {
      name: 'GENESYS_DEPLOYMENT_ID',
      type: 'STRING',
      stringValue: values.genesys.deploymentId || '',
    },
    {
      name: 'GENESYS_AWS_REGION',
      type: 'STRING',
      stringValue: values.genesys.awsRegion || '',
    },
    {
      name: 'GENESYS_CLIENT_ID',
      type: 'STRING',
      stringValue: values.genesys.clientId || '',
    },
    {
      name: 'GENESYS_CLIENT_SECRET',
      type: 'STRING',
      stringValue: values.genesys.clientSecret || '',
    },
    {
      name: 'GENESYS_OPEN_MESSAGING_INTEGRATION_ID',
      type: 'STRING',
      stringValue: values.genesys.openMessagingIntegrationId || '',
    },
    {
      name: 'GENESYS_INBOUND_MESSAGE_FLOW_NAME',
      type: 'STRING',
      stringValue: values.genesys.inboundMessageFlowName || '',
    },
    {
      name: 'GENESYS_USER_DATA',
      type: 'JSON',
      jsonValue: values.genesys.userData || '',
    },
    {
      name: 'GENESYS_CUSTOM_ATTRIBUTES',
      type: 'JSON',
      jsonValue: values.genesys.customAttributes || '',
    },
    {
      name: 'GENESYS_NLP_ANALYTICS',
      type: 'BOOLEAN',
      booleanValue: values.genesys.nlpAnalytics || false,
    },
    {
      name: 'GENESYS_BOT_FLOW_ATTRIBUTE_NAME',
      type: 'STRING',
      stringValue: values.genesys.botFlowAttributeName || '',
    },
    {name: 'CONTAINERMODE', type: 'STRING', stringValue: 'genesys'}
  ]
}

class GenesysEditUnconnected extends React.Component {
  render() {
    const {advanced, disabled, getConnector, setAlertSuccessMessage, capSetCapNames} = this.props
    const connector = getConnector('genesys')
    const capMessagingChannel = connector.capabilities.find(c => c.name === 'GENESYS_MESSAGING_CHANNEL')
    const capAwsRegion = connector.capabilities.find(c => c.name === 'GENESYS_AWS_REGION')

    return (<FormSpy subscription={{values: true, form: true}} render={({values, form: {change}}) => {
      return (<React.Fragment>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <Field
              name="genesys.messagingChannel"
              component={renderSelect}
              label="Genesys messaging channel"
              validate={required}
              data-unique="selGenesysEditMessagingChannel"
              items={capMessagingChannel && capMessagingChannel.choices.map(u => {
                return { key: u.key, label: `${u.name}${advanced ? ` (${u.key})` : ''}` }
              })}
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.messagingChannel']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.messagingChannel'], 'You can choose among different api-s to connect to your chatbot. (Web messaging is recommended to use.)')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="genesys.awsRegion"
              component={renderSelect}
              label="AWS Region"
              validate={required}
              data-unique="selGenesysEditAwsRegion"
              items={capAwsRegion && capAwsRegion.choices.map(u => {
                return { key: u.key, label: `${u.name}${advanced ? ` (${u.key})` : ''}` }
              })}
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.awsRegion']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.awsRegion'])}
            />
          </GridItem>
        </GridContainer>
        {values.genesys.messagingChannel === 'WEB_MESSAGING' && <GridContainer>
          <GridItem xs={12} sm={6}>
            <Field
              name="genesys.deploymentId"
              component={renderTextField}
              label="Deployment ID"
              validate={required}
              data-unique="txtGenesysEditDeploymentId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.deploymentId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.deploymentId'], 'Deployment ID from Genesys Messenger Deployments.')}
            />
          </GridItem>
        </GridContainer>}

        {values.genesys.messagingChannel === 'OPEN_MESSAGING' && <GridContainer>
          <GridItem xs={12} sm={6}>
            <Field
              name="genesys.outgoingUri"
              label="Genesys Outgoing Webhook URI"
              component={renderTextField}
              initialValue={config.api.ext + '/inbound?APIKEY=*Choose an API key*'}
              readOnly
              data-unique="txtGenesysEditOutgoingUri"
              endAdornment={
                <Button
                  onClick={() => {
                    copyToClipboard(values.genesys.outgoingUri)
                    setAlertSuccessMessage(`Genesys Outgoing Webhook URI copied to clipboard`)
                  }}
                  justIcon
                  data-unique="btnGenesysWebhookEditCopy"
                >
                  <FileCopyIcon/>
                </Button>
              }
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.deploymentId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.deploymentId'], 'You have to configure your Genesys Open messaging channel to use this URL as Outbound Webhook URL.')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Query query={APIKEYS_QUERY}>
              {({loading, error, data}) => {
                return (
                  <Field
                    name="genesys.apiKey"
                    component={renderSelect}
                    label="API key"
                    data-unique="selGenesysEditApiKey"
                    loading={loading}
                    error={error}
                    items={data && data.apikeys && data.apikeys.map(a => {
                      return {key: a.key, label: a.name}
                    })}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.apiKey']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.apiKey'], 'Choose an API key to generate the Outgoing Webhook URI')}
                  />
                )
              }}
            </Query>
          </GridItem>
          <FormSpy subscription={{form: true}} render={({form: {change}}) => (
            <OnChange name="genesys.apiKey">
              {(value, previous) => {
                if (value) {
                  change('genesys.outgoingUri', `${config.api.ext}/inbound?APIKEY=${value}`)
                } else {
                  change('genesys.outgoingUri', `${config.api.ext}/inbound?APIKEY=*Choose an API key*`)
                }
              }}
            </OnChange>
          )}/>
          <GridItem xs={12} sm={6}>
            <Field
              name="genesys.openMessagingIntegrationId"
              component={renderTextField}
              label="Open Messaging integration ID"
              validate={required}
              data-unique="txtGenesysEditOpenMessagingIntegrationId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.openMessagingIntegrationId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.openMessagingIntegrationId'], 'ID of the Open Messaging integration.')}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              className="CapabilitiesShort"
              name="genesys.userData"
              component={renderCodeArea}
              options={{mode: 'application/json'}}
              label="User data"
              codeFormat={prettyPrintJson}
              validate={json}
              data-unique="codeGenesysEditProfile"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.userData']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.userData'], 'Optionally you are able to set properties that represent the user, like firstName and LastName.')}
            />
            <Button data-unique="btnGenesysEditUserData" link
                    onClick={() => change('genesys.userData', USER_DATA_SAMPLE)}>Insert sample profile</Button>
          </GridItem>
        </GridContainer>}
        <GridContainer>
          <GridItem xs={12}>
            <Field
              className="CapabilitiesShort"
              name="genesys.customAttributes"
              component={renderCodeArea}
              options={{mode: 'application/json'}}
              label="Custom attributes"
              codeFormat={prettyPrintJson}
              validate={json}
              data-unique="codeGenesysEditProfile"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.customAttributes']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.customAttributes'], 'Optionally you are able to set custom attributes.')}
            />
            <Button data-unique="btnGenesysEditCustomAttributes" link
                    onClick={() => change('genesys.customAttributes', CUSTOM_ATTRIBUTE_SAMPLE)}>Insert sample custom attributes</Button>
          </GridItem>
          <GridItem xs={12}>
            <Field
              name="genesys.nlpAnalytics"
              component={renderCheckbox}
              label="Enable NLP Analytics"
              type="checkbox"
              data-unique="chkGenesysEditNlpAnalytics"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.nlpAnalytics']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.nlpAnalytics'], 'Enabling NLP analytics the intents and entities will be detected. (Attention! NLP analytics can slow down the conversation!)')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="genesys.clientId"
              component={renderTextField}
              label="Client ID"
              validate={values.genesys.messagingChannel === 'OPEN_MESSAGING' || !!values.genesys.nlpAnalytics ? required : undefined}
              data-unique="txtGenesysEditClientId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.clientId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.clientId'], values.genesys.messagingChannel === 'OPEN_MESSAGING' ? 'Client ID from Genesys OAuth integration.' : 'Client ID from Genesys OAuth integration. (This capability needs only for conversation model download)')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="genesys.clientSecret"
              component={renderPasswordField}
              label="Client Secret"
              validate={values.genesys.messagingChannel === 'OPEN_MESSAGING' || !!values.genesys.nlpAnalytics ? required : undefined}
              data-unique="txtGenesysEditClientSecret"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.clientSecret']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.clientSecret'], values.genesys.messagingChannel === 'OPEN_MESSAGING' ? 'Client Secret from Genesys OAuth integration.' : 'Client Secret from Genesys OAuth integration. (This capability needs only for conversation model download)')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="genesys.inboundMessageFlowName"
              component={renderTextField}
              label="Inbound Message Flow Name"
              validate={!!values.genesys.nlpAnalytics ? required : undefined}
              data-unique="txtGenesysEditInboundMessageFlowName"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.inboundMessageFlowName']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.inboundMessageFlowName'], 'Inbound Message flow name from genesys architect view. (This capability needs only for conversation model downloader)')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="genesys.botFlowAttributeName"
              component={renderTextField}
              label="Bot Flow Attribute Name"
              data-unique="txtGenesysEditBotFlowAttributeName"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['genesys.botFlowAttributeName']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['genesys.botFlowAttributeName'], 'If you have multiple bot flows in your inbound message flow in Genesys Architect view, it\'s best to store the name of the current bot flow in a variable in the participant data. This will ensure correct NLP detection. In the \'Bot Flow Attribute Name\' field, enter the name of the attribute that you used in the inbound message flow in Genesys Surface.')}
            />
          </GridItem>
        </GridContainer>
      </React.Fragment>)
    }}/>)
  }
}

const GenesysEdit = connect(
  state => ({}),
  {setAlertSuccessMessage, getConnector}
)(GenesysEditUnconnected)
export {GenesysEdit}
