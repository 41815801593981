import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query, compose, graphql } from 'react-apollo'
// core components
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import SelectableCard from 'components/Card/SelectableCard'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import QueryStatus from 'components/Info/QueryStatus'
import ShortenedText from 'components/Typography/ShortenedText'
import { FormActionsToolbar } from 'components/Form/Form'
import FeatureUpgradeNavLink from 'components/FeatureUpgrade/FeatureUpgradeNavLink'
import ShowIcon from 'components/Icon/ShowIcon'
import Text from 'components/Typography/Text.jsx'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'
import { CHECKFEATURECONFIGURED_QUERY } from 'views/Settings/gql'
import ClientFeatureSection from '../Settings/ClientFeatureSection'
import { NavLink } from 'react-router-dom'


import {
  TESTSET_QUERY,
  TESTSET_EDITABLE_UTTERANCE_QUERY,
  TESTSET_PARAPHRASES,
  UPDATE_TESTSETSCRIPT,
  RefetchTestSetQueries,
} from '../TestSets/gql'


class ParaphraserSuggestions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 'grid',
      submitting: false,
      enabledIndexes: [],
      scriptContent: null
    }
  }

  _updateScriptContent(testsetparaphrases, enabledIndexes) {
    const { utteranceData } = this.props

    const utterance = utteranceData && utteranceData.testseteditableutterance
    if (!utterance) return

    const scriptLines = [
      utterance.name,
      ...(utterance.utterances || []),
      ...(testsetparaphrases ? testsetparaphrases.filter((p, i) => enabledIndexes.indexOf(i) >= 0) : [])
    ]
    this.setState({ scriptContent: scriptLines.join('\n'), enabledIndexes })
  }

  render() {
    const { testSetData, utteranceData, mutateTestSetScript, classes, scriptId, scriptName, featureData } = this.props
    const { view, submitting, enabledIndexes, scriptContent } = this.state

    const testSet = testSetData && testSetData.testset
    const utterance = utteranceData && utteranceData.testseteditableutterance

    return (<GridContainer>
      <GridItem xs={12}>
        <Card noMargin>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} borderBottom largePadding>
                <Text header>Getting Paraphrases for script '{scriptName}'</Text>
                <Text subheader>The Test Set can be augmented with additional semantic equivalent paraphrases of your user examples. You can use any of these user examples or decide to skip them.</Text>
              </GridItem>
              <GridItem xs={12} grey block largePadding>
                <ClientFeatureSection feature="paraphraser">
                  <QueryStatus queries={[featureData]}
                    query={['checkfeatureconfigured']}>{({ checkfeatureconfigured }) => {
                      if (!checkfeatureconfigured.isLicensed) {
                        return <Text bold danger><FeatureUpgradeNavLink>'Required dependency Translate not available in this
                          edition, paraphrasing in english'</FeatureUpgradeNavLink></Text>
                      }
                      return null
                    }}</QueryStatus>

                  {testSet && utterance && utterance.utterances.length === 0 &&
                    <Text bold danger>No user example for paraphrasing found (minimum length: 10 characters)</Text>
                  }
                  {testSet && utterance && utterance.utterances.length > 0 &&
                    <Query query={TESTSET_PARAPHRASES}
                      variables={{ utterances: utterance.utterances, name: utterance.name }}
                      fetchPolicy="network-only"
                      onCompleted={({ testsetparaphrases }) => this._updateScriptContent(testsetparaphrases, _.range(0, testsetparaphrases.length))}
                    >{(queryResult) => <QueryStatus {...queryResult} query="testsetparaphrases">{({ testsetparaphrases }) => {

                      return <GridContainer>
                        <GridItem xs={6} middle>
                          <Text bold>Found {testsetparaphrases.length} paraphrase(s) for {utterance.utterances.length} user example(s)</Text>
                        </GridItem>
                        <GridItem xs={6} right middle>
                          <Button small link data-unique="btnParaphraserWizardSelectAll" onClick={() => this.setState({ enabledIndexes: _.range(0, testsetparaphrases.length) })}>
                            Select All
                          </Button>
                          |
                          <Button small link data-unique="btnParaphraserWizardClear" onClick={() => this.setState({ enabledIndexes: [] })}>
                            Clear Selection
                          </Button>

                          <Button small justIcon secondary={view === 'grid'} data-unique="btnParaphraserWizardGrid" onClick={() => this.setState({ view: 'grid' })}>
                            <ShowIcon icon="th-large" />
                          </Button>
                          <Button small justIcon secondary={view === 'list'} data-unique="btnParaphraserWizardList" onClick={() => this.setState({ view: 'list' })}>
                            <ShowIcon icon="list" />
                          </Button>
                        </GridItem>
                        {testsetparaphrases.map((paraphrase, index) => (<React.Fragment key={`paraphrase_${index}`}>
                          <GridItem smallPadding floatLeft key={`paraphrase_${index}`} {...(view === 'list' ? { xs: 12 } : {})}>
                            <SelectableCard
                              data-unique={`crdParaphraserWizardParaphrase${index}`}
                              selected={enabledIndexes.indexOf(index) >= 0}
                              onClick={() => {
                                const pi = enabledIndexes.indexOf(index)
                                if (pi >= 0) {
                                  this._updateScriptContent(testsetparaphrases, enabledIndexes.filter(p => p !== index))
                                } else {
                                  this._updateScriptContent(testsetparaphrases, [...enabledIndexes, index])
                                }
                              }} {...(enabledIndexes.indexOf(index) >= 0 ? { className: classes.paraphraseSelected } : {})} >
                              <CardBody>
                                <GridContainer>
                                  <GridItem xs={12}><Text muted>Paraphrase #{index + 1}</Text></GridItem>
                                  <GridItem xs={12}><Text bold><ShortenedText
                                    maxlength={100}>{paraphrase}</ShortenedText></Text></GridItem>
                                </GridContainer>
                              </CardBody>
                            </SelectableCard>
                          </GridItem>
                        </React.Fragment>))}
                      </GridContainer>
                    }}</QueryStatus>}
                    </Query>}
                  {(!testSet || !utterance) && <LoadingIndicator />}
                </ClientFeatureSection>
              </GridItem>
              <GridItem xs={12} largePadding>
                <FormActionsToolbar
                  leftButtons={<>
                    <NavLink to="/settings/system/paraphrasing" data-unique="btnChatbotCapabilitySetsLink">
                      <Button secondary data-unique="btnParaphraserAIComnands"><ShowIcon icon="terminal" /> AI Comnands</Button>
                    </NavLink>
                    <div className={classes.paraphraseAiCommands} >
                      <Text>OpenAI command to clean the paraphrased utterances, for example 'Remove utterances shorter than 8 characters.' or 'Make neutral utterances to polite sentences'</Text>
                    </div>
                  </>}
                  rightButtons={<>
                    <Button
                      disabled={enabledIndexes.length === 0 || submitting || !testSet || !utterance}
                      data-unique="btnParaphraserWizardSave"
                      onClick={() => {
                        this.setState({ submitting: true })
                        mutateTestSetScript({
                          variables: {
                            id: scriptId,
                            testSetScript: {
                              script: scriptContent
                            },
                          },
                        })
                      }}
                    >
                      {submitting && <><LoadingIndicator alt /> Adding ({enabledIndexes.length})...</>}
                      {!submitting && <><ShowIcon icon="save" /> Add ({enabledIndexes.length})</>}
                    </Button>
                  </>} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>)
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, settings: state.settings }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(TESTSET_QUERY, {
    options: ({ testSetId }) => ({
      variables: {
        id: testSetId
      },
    }),
    props: ({ data }) => ({
      testSetData: data,
    }),
  }),
  graphql(TESTSET_EDITABLE_UTTERANCE_QUERY, {
    options: ({ scriptId, scriptName }) => ({
      variables: {
        testSetScriptId: scriptId,
        name: scriptName
      }
    }),
    props: ({ data }) => ({
      utteranceData: data,
    }),
  }),
  graphql(UPDATE_TESTSETSCRIPT, {
    props: ({ mutate }) => ({
      mutateTestSetScript: args => mutate(args),
    }),
    options: ({ testSetId, scriptId, scriptName, onReady, license, setAlertErrorMessage }) => ({
      onCompleted: (data) => onReady(),
      onError: (error) => {
        setAlertErrorMessage('Adding paraphrases to utterances failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        {
          query: TESTSET_EDITABLE_UTTERANCE_QUERY,
          variables: {
            testSetScriptId: scriptId,
            name: scriptName
          }
        }
      ]
    })
  }),
  graphql(CHECKFEATURECONFIGURED_QUERY, {
    props: ({ data }) => ({
      featureData: data,
    }),
    options: (props) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          feature: 'translation'
        }
      }
    }
  }),
)(withRouter(ParaphraserSuggestions))
