import React from 'react'
import _ from 'lodash'
import { Field } from 'react-final-form'
import { withStyles } from '@material-ui/core/styles'
import dropzoneStyle from 'assets/jss/material-dashboard-react/components/dropzoneStyle'
import customInputStyle from 'assets/jss/material-dashboard-react/components/customInputStyle'
import selectStyle from 'assets/jss/material-dashboard-react/components/selectStyle'
import Text from '../Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'

const InputField = withStyles(customInputStyle)(Field)
const SelField = withStyles(selectStyle)(Field)
const FileField = withStyles(dropzoneStyle)(Field)

function OptionalField({ optional, name, value, validate, component, label, noLabel, showIndicator, ...props }) {

  const hasRequired = (name, validate) => {
    let ret = false

    if (validate) {
      if(validate.displayName === 'required' || validate.displayName === 'conditional') {
        return true
      }
      /* for composeValidators */
      validate(null, function(v) {
        ret = v.map(v => v.displayName).includes('required')
      })
    }
    return ret
  }

  const isExcludedComponent = () => {
    const excluded = [
      'renderCheckbox',
      'renderFileUpload'
    ]

    return component && excluded.includes(component.displayName)
  }

  const getOptional = () => {
    const optionalLabel = (_.isNil(label)) ? <b>Optional</b> : <b>(Optional)</b>
    return <Text muted style={{ display: 'inline-block', fontSize: '1rem', opacity: '0.6', lineHeight: '1em' }}>{optionalLabel}</Text>
  }

  if (optional || (
      (validate === false || _.isNil(validate) || (validate && !hasRequired(name, validate))) &&
      !name.toLowerCase().includes('filter') && // exclude filter inputs
      !name.toLowerCase().includes('[') && // exclude array fields
      !isExcludedComponent()
    )
  ) {
    label = label ? <>{label} {getOptional()}</> : getOptional()
  } else {
    if (showIndicator) {
      label = <>{label} <Text danger inline style={{ display: 'inline-block', marginBottom: '-10px', fontSize: '10px' }}><ShowIcon icon="circle-exclamation" /></Text></>
    }
  }

  if (noLabel) {
    label = <><div style={{overflow: 'hidden', position: 'absolute', border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, padding: 0, width: 1, }}>{label}</div></>
  }

  if(component && component.displayName === 'renderSelect') {
    return <SelField hasRequired={hasRequired(name, validate)} name={name} label={label} component={component} validate={validate} {...props} />
  }

  if(component && component.displayName === 'renderFileUpload') {
    return <FileField hasRequired={hasRequired(name, validate)} name={name} label={label} component={component} validate={validate} {...props} />
  }

  return <InputField hasRequired={hasRequired(name, validate)} name={name} label={label} component={component} validate={validate} {...props} />
}

export default OptionalField
