import { ADD_RECENT_ACTIVITY, REMOVE_RECENT_ACTIVITY, RECENT_ACTIVITY_LENGTH } from '../constants'

export default function activity(state = {}, action) {
  let recent = state.recent || []
  switch (action.type) {
    case ADD_RECENT_ACTIVITY:
      recent = recent.filter(e => !(e.url === action.payload.url))
      recent.unshift(action.payload)
      if (recent.length > RECENT_ACTIVITY_LENGTH) {
        recent = recent.slice(0, RECENT_ACTIVITY_LENGTH)
      }
      return {
        ...state,
        recent
      }
    case REMOVE_RECENT_ACTIVITY:
      recent = recent.filter(e => !(e.url === action.payload.url))
      return {
        ...state,
        recent
      }
    default:
      return state
  }
}
