import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'

import { showFeatureUpgrade } from 'actions/alert'

import featureUpdateStyle from 'assets/jss/material-dashboard-react/components/featureUpdateStyle.jsx'
import upgradeImg from 'assets/img/feature_upgrade.png'

class FeatureUpgradePopup extends React.Component {
  render() {
    const { showFeatureUpgrade, show, classes } = this.props

    return (<ConfirmationDialog
      open={show}
      okText="OK"
      onOk={() => {
        showFeatureUpgrade(false)
      }}
      title="FEATURE NOT AVAILABLE"
      data-unique="dlgFeatureUpgrade"
    >
      <GridContainer style={{lineHeight: '36px'}} alignItems="center" justify="center">
        <GridItem xs={12} sm={5} align="center">
          <img alt="Upgrade" src={upgradeImg} className={classes.entryImage} />
        </GridItem>
        <GridItem xs={12} sm={7} center>
          Your Botium edition does not have the required privilege to access this feature.<br />
          Please <a href="https://cyara.com/lp/upgrade-your-botium-free-trial-license/" style={{display: 'contents'}} target="blank" rel="noopener noreferrer">upgrade plan</a> to access all the features.
        </GridItem>
      </GridContainer>
    </ConfirmationDialog>)
  }
}

const FeatureUpgradePopupState = connect(
  state => ({
    show: (state.featureupgrade && state.featureupgrade.show) || false,
    license: state.settings.license
  }),
  { showFeatureUpgrade }
)(withStyles(featureUpdateStyle)(withRouter(FeatureUpgradePopup)))

export default FeatureUpgradePopupState
