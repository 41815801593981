const _ = require('lodash')

const prependLogWith = (prefix, text) => (text || '').split('\n').filter(line => line.trim().length > 0).map(line => prefix + line).join('\n')

const removeBuffers = (obj, hideBase64 = true, truncate = true) => {
  if (_.isArray(obj)) {
    return obj.map(i => removeBuffers(i, hideBase64, truncate))
  }
  const jsonString = JSON.stringify(obj, (key, value) => {
    if (_.isBuffer(value)) {
      return '(binary data)'
    } else if (value && value.type && value.type === 'Buffer') {
      return '(binary data)'
    } else if (hideBase64 && key.toLowerCase() === 'base64') {
      return '(base64 data)'
    } else if (hideBase64 && _.isString(value) && value.startsWith('data:') && value.length > 100) {
      return '(base64 data)'
    } else if (truncate && _.isString(value) && value.length > 500) {
      return _.truncate(value, { length: 500 })
    }
    return value
  })
  return JSON.parse(jsonString)
}

const removeBuffers2 = (obj, { hideBuffer = true, hideBase64 = true, truncate = true, hideFunction = true } = {}) => {
  if (_.isArray(obj)) {
    return obj.map(i => removeBuffers2(i, { hideBuffer, hideBase64, truncate, hideFunction }))
  }
  const jsonString = JSON.stringify(obj, (key, value) => {
    if (hideBuffer && _.isBuffer(value)) {
      return '(binary data)'
    } else if (hideBuffer && value && value.type && value.type === 'Buffer') {
      return '(binary data)'
    } else if (hideBase64 && key.toLowerCase() === 'base64') {
      return '(base64 data)'
    } else if (hideBase64 && _.isString(value) && value.startsWith('data:') && value.length > 100) {
      return '(base64 data)'
    } else if (truncate && _.isString(value) && value.length > 500) {
      return _.truncate(value, { length: 500 })
    } else if (hideFunction && _.isFunction(value)) {
      return '(fn)'
    }
    return value
  })
  return JSON.parse(jsonString)
}

const streamJsonArray = (write, wrapperObj, arrayElementName, options = { pretty: false }) => {
  const headerStr = JSON.stringify({ ...wrapperObj, [arrayElementName]: [] }, null, options.pretty ? 2 : null)
  const sep = options.pretty ? `"${arrayElementName}": [` : `"${arrayElementName}":[`

  const [headerPrefixStr, headerSuffixStr] = headerStr.split(sep, 2)

  let isFirst = true
  return {
    begin: () => write(headerPrefixStr + sep),
    end: () => write('\n' + headerSuffixStr),
    append: (arrayEl) => {
      if (isFirst) write('\n')
      else write(',\n')
      write(prependLogWith(options.pretty ? '    ' : '', JSON.stringify(arrayEl, null, options.pretty ? 2 : null)))
      isFirst = false
    }
  }
}

module.exports = {
  prependLogWith,
  removeBuffers,
  removeBuffers2,
  streamJsonArray
}
