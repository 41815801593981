import React from 'react'
import Chip from '../Chip/Chip'

export function dialogflowCxChips({...props}) {
  const {msg} = props

  let result = []
  if (msg.sourceData) {
    if (msg.sender === 'bot') {
      result = [...result, ...(msg.sourceData.webhookStatuses || []).filter(({code}) => code > 0).map((s, index) => {
          return <Chip
            variant="error"
            key={`WebhookStatus${index}`}
            tooltip={s.message}
            avatarCharacter="W"
            label="Webhook Error"
          />
        }
      )]
    }
  }

  return result
}
