import React from 'react'
import PropTypes from 'prop-types'
import download from 'downloadjs'
import _ from 'lodash'

const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onerror = reject
  reader.onload = () => {
    resolve(reader.result)
  }
  reader.readAsDataURL(blob)
})

export async function downloadfile(url, method, body) {
  const response = await fetch(url, {
    method: method || 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: body ? _.isString(body) ? body : JSON.stringify(body) : undefined,
    redirect: 'follow',
    credentials: 'include'
  })
  if (response.ok) {
    const blob = await response.blob()
    const contentDispositionHeader = response.headers.get('Content-disposition')
    if (contentDispositionHeader) {
      const matches = contentDispositionHeader.match(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/)
      const filename = matches[2]
      if (filename) {
        download(blob, filename)
      } else {
        throw new Error('Download failed, no filename identified from Content-disposition header')
      }
    } else {
      throw new Error('Download failed, no filename identified from Content-disposition header')
    }
  } else {
    throw new Error(`Download failed with response code ${response.status}`)
  }
}

export async function downloadfileb64(url) {
  const response = await fetch(url, {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include'
  })
  if (response.ok) {
    const blob = await response.blob()
    const contentDispositionHeader = response.headers.get('Content-disposition')
    if (contentDispositionHeader) {
      const matches = contentDispositionHeader.match(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/)
      const filename = matches[2]
      if (filename) {
        return await convertBlobToBase64(blob)
      } else {
        throw new Error('Download failed, no filename identified from Content-disposition header')
      }
    } else {
      throw new Error('Download failed, no filename identified from Content-disposition header')
    }
  } else {
    throw new Error(`Download failed with response code ${response.status}`)
  }
}

export async function downloadfileformpost(url) {
  const form = document.createElement('form')
  form.setAttribute('method', 'post')
  form.setAttribute('action', url)
  form.setAttribute('target', '_blank')
  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

export class DownloadWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      src: null,
      blob: null,
      err: null
    }
  }

  async componentDidMount() {
    const { url } = this.props

    const response = await fetch(url, {
      method: 'GET',
      redirect: 'follow',
      credentials: 'include'
    })
    if (response.ok) {
      const blob = await response.blob()
      const src = URL.createObjectURL(blob)

      this.setState({
        src,
        blob,
        err: null
      })
    } else {
      this.setState({
        src: null,
        blob: null,
        err: `Download failed with response code ${response.status}`
      })
    }
  }

  render() {
    const { url, children } = this.props
    const { src, err, blob } = this.state

    if (src) {
      return children({ loading: false, src, blob })
    } else if (err) {
      return children({ loading: false, err })
    } else if (url) {
      return children({ loading: true })
    } else {
      return children({ loading: false })
    }
  }
}

DownloadWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
}
