import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, compose, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderNamespaceField,
  renderSelect,
  renderCheckbox,
  required
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'
import ErrorFormat from 'components/Info/ErrorFormat'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import {
  NAMESPACEPERMISSIONS_QUERY,
  NAMESPACEPERMISSION_QUERY,
  CREATE_NAMESPACEPERMISSION,
  UPDATE_NAMESPACEPERMISSION,
  DELETE_NAMESPACEPERMISSION,
  USERROLES_QUERY
} from './gql'

import { FormActionsToolbar } from 'components/Form/Form'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class NamespacePermission extends React.Component {
  renderUserRoleSelection() {
    return (
      <Query query={USERROLES_QUERY}>
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingIndicator/>
          }
          if (error) {
            return <ErrorFormat err={error} />
          }
          return (
            <Field
              name="userRole.id"
              component={renderSelect}
              label="Role"
              validate={required}
              data-unique="selNamespacePermissionUserRoleId"
              items={data &&
                data.userroles &&
                data.userroles.map(r => {
                  return {
                    key: r.id,
                    label: r.name
                  }})
              }
            />
          )
        }}
      </Query>
    )
  }


  renderForm(permission) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady } = this.props

    return (
      <Mutation
        mutation={permission.id ? UPDATE_NAMESPACEPERMISSION : CREATE_NAMESPACEPERMISSION}
        refetchQueries={[
          {
            query: NAMESPACEPERMISSIONS_QUERY,
          },
        ]}
      >
        {(mutateNamespacePermission, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (permission.id) {
                try {
                  const res = await mutateNamespacePermission({
                    variables: {
                      id: permission.id,
                      permission: {
                        userRoleId: values.userRole.id,
                        namespace: values.namespace,
                        canRead: !!values.canRead,
                        canWrite: !!values.canWrite,
                      }
                    }
                  })
                  form.initialize(res.data.updateNamespacePermission)
                  setAlertSuccessMessage('Namespace Permission updated')
                  onReady(permission.id)
                } catch(error) {
                  setAlertErrorMessage('Namespace Permission update failed', error)
                }
              } else {
                try {
                  const res = await mutateNamespacePermission({
                    variables: {
                      permission: {
                        userRoleId: values.userRole.id,
                        namespace: values.namespace,
                        canRead: !!values.canRead,
                        canWrite: !!values.canWrite,
                      }
                    }
                  })
                  form.initialize(res.data.createNamespacePermission)
                  setAlertSuccessMessage('Namespace Permission registered')
                  onReady(res.data.createNamespacePermission.id)
                } catch(error) {
                  setAlertErrorMessage('Namespace Permission register failed', error)
                }
              }
            }}
            initialValues={permission}
            render={({
              handleSubmit,
              submitting,
              invalid
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="namespace"
                      component={renderNamespaceField}
                      label="Namespace"
                      helperText="Permissions apply to this namespace and any sub-namespace"
                      validate={required}
                      data-unique="txtNamespacePermissionNamespace"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    {this.renderUserRoleSelection()}
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="canRead"
                      component={renderCheckbox}
                      label="Permission to read records matching the namespace"
                      type="checkbox"
                      data-unique="checkNamespacePermissionCanRead"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="canWrite"
                      component={renderCheckbox}
                      label="Permission to write records matching the namespace"
                      type="checkbox"
                      data-unique="checkNamespacePermissionCanWrite"
                    />
                  </GridItem>
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtons={<>
                        {permission.id && (
                          <Mutation
                            mutation={DELETE_NAMESPACEPERMISSION}
                            onCompleted={data => {
                              setAlertSuccessMessage('Namespace Permission deleted')
                              onReady()
                            }}
                            onError={error => {
                              setAlertErrorMessage('Namespace Permission deletion failed', error)
                            }}
                            refetchQueries={[
                              {
                                query: NAMESPACEPERMISSIONS_QUERY,
                              },
                            ]}
                          >
                            {(deleteNamespacePermission, { loading, error }) => (
                              <ConfirmationButton
                                confirmationText={`Are you sure you want to delete it ?`}
                                requireCheck={true}
                                onClick={() => {
                                  deleteNamespacePermission({
                                    variables: { id: permission.id },
                                  })
                                }}
                                data-unique="btnNamespacePermissionDelete"
                                secondary
                                danger                                  
                              >
                                <ShowIcon icon="trash" />
                                <Text infoText bold>Delete Namespace Permission</Text>
                              </ConfirmationButton>
                            )}
                          </Mutation>
                        )}                        
                      </>}
                      rightButtons={<>
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnNamespacePermissionSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}                  
                          Save
                        </Button>                        
                      </>}
                    />                      
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={NAMESPACEPERMISSION_QUERY} variables={{ id }} fetchPolicy="network-only">
              {(queryResult) => <QueryStatus {...queryResult} query="namespacepermission">{(data) => this.renderForm(data.namespacepermission)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({})}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(settingsStyle),
  connect(
    state => ({ user: state.token.user }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  withApollo
)(NamespacePermission)
