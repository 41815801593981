import dashboardStyle from './dashboardStyle.jsx'
import convoStyle from '../components/convoStyle.jsx'
import {
  defaultBoxShadow
} from 'assets/jss/material-dashboard-react.jsx'

const convosTreeStyle = theme => ({
  ...dashboardStyle(theme),
  ...convoStyle(theme),
  graphContainer: {
    height: 800,
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 3,
    '& svg':{
      '& path': {
        animation: 'none!Important'
      }
    }
  },
  graphMe: {
    ...defaultBoxShadow,
    transition: 'none',
    backgroundColor: '#F2F2F2',
    borderColor: theme.colors.common.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 15,
    padding: theme.spacing.unit,
    marginLeft: 5,
    width: 400,
    minHeight: 50,
    pointerEvents: 'all'
  },
  graphMeHighlighted: {
    backgroundColor: '#f5bf42'
  },
  graphAvatarMe: {
    float: 'right',
  },
  graphBot: {
    ...defaultBoxShadow,
    transition: 'none',
    backgroundColor: '#2A3F54',
    borderRadius: 15,
    borderColor: theme.colors.common.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
    padding: theme.spacing.unit,
    marginLeft: 5,
    width: 400,
    minHeight: 50,
    pointerEvents: 'all'
  },
  graphBotHighlighted: {
    backgroundColor: '#f5bf42'
  },
  graphSelected: {
    borderColor: 'red'
  },
  graphAvatarBot: {
    float: 'right'
  },
  graphEdge: {

  },
  graphHandle: {
  },
  graphThreedot: {
    margin: '2px 0 0 0',
    padding: '1px 10px 3px 10px',
    float: 'right',
    borderRadius: 3,
    borderWidth: 0,
    maxWidth: 32,
    width: 24,
    height: 24,
    '&,&:hover': {
      color: 'white !important',
    },
    backgroundColor: '#ff980000',
    boxShadow: 'none',
    '&:hover,&:focus': {
      backgroundColor: '#ff980055'
    }
  },
  convosTreeControls: {
    top: 13,
    right: 10,
    display: 'inline-table',
    left: 'inherit',
    alignItems: 'center',
    //bottom: 10,
    //right: 'inherit',
    //left: '10',
    //display: 'flex',
    //boxShadow: 'none'
    '& button': {
      '&:focus-visible': {
        border: `2px solid ${theme.colors.visibleborderColor}`
      },
    }
  }
})

export default convosTreeStyle
