import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import CallIcon from '@material-ui/icons/Call'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
// apollo
import { Query, Mutation, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  renderPasswordField,
  renderSelect,
  composeValidators,
  required,
  url,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import {
  DEVICEPROVIDERS_QUERY,
  DEVICEPROVIDER_QUERY,
  PING_DEVICEPROVIDER,
  CREATE_DEVICEPROVIDER,
  UPDATE_DEVICEPROVIDER,
  DELETE_DEVICEPROVIDER,
} from './gql'

import { hasPermission } from 'botium-box-shared/security/permissions'
import { validateDeviceProviderNameUnique } from './validators'

import deviceProviderIconChrome from 'assets/img/chrome.png'
import deviceProviderIconSelenium from 'assets/img/selenium.png'
import deviceProviderIconPerfecto from 'assets/img/perfecto.png'
import deviceProviderIconSaucelabs from 'assets/img/saucelabs.png'
import deviceProviderIconExperitest from 'assets/img/experitest.png'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

const urlRequired = (type) => type === 'SAUCELABS' || type === 'SAUCELABS_RDC'|| type === 'TESTOBJECTS' || type === 'PERFECTOLAB_DEVICES' || type === 'PERFECTOLAB_DESKTOP' || type === 'EXPERITEST' || type === 'LOCALSELENIUM'
const urlFieldLabel = (type) => ((type === 'PERFECTOLAB_DEVICES' || type === 'PERFECTOLAB_DESKTOP') && 'Perfecto Cloud Url') || 'Selenium/Appium Endpoint'
const urlDefault = (type) => (
  (type === 'SAUCELABS' && 'https://ondemand.us-west-1.saucelabs.com/wd/hub')
  || (type === 'SAUCELABS_RDC' && 'https://ondemand.us-west-1.saucelabs.com/wd/hub')
  || (type === 'TESTOBJECTS' && 'https://appium.testobject.com/wd/hub')
  || (type === 'EXPERITEST' && 'https://cloud.seetest.io/wd/hub')
  || (type === 'PERFECTOLAB_DEVICES' && 'https://mycloudname.perfectomobile.com')
  || (type === 'PERFECTOLAB_DESKTOP' && 'https://mycloudname.perfectomobile.com')
)
const usernameRequired = (type) => type === 'SAUCELABS' || type === 'SAUCELABS_RDC' || type === 'TESTOBJECTS'
const usernameFieldLabel = (type) => ((type === 'SAUCELABS'|| type === 'SAUCELABS_RDC') && 'Saucelabs Username') || (type === 'TESTOBJECTS' && 'Testobjects Username')
const passwordRequired = (type) => type === 'SAUCELABS'|| type === 'SAUCELABS_RDC' || type === 'TESTOBJECTS' || type === 'PERFECTOLAB_DEVICES' || type === 'PERFECTOLAB_DESKTOP' || type === 'EXPERITEST'
const passwordFieldLabel = (type) => ((type === 'SAUCELABS'|| type === 'SAUCELABS_RDC') && 'Saucelabs Access Key') || (type === 'TESTOBJECTS' && 'Testobjects API Key') || ((type === 'PERFECTOLAB_DEVICES' || type === 'PERFECTOLAB_DESKTOP') && 'Perfecto Security Token') || (type === 'EXPERITEST' && 'Experitest Access Key')
const pingPossible = (type) => type === 'SAUCELABS' || type === 'SAUCELABS_RDC' || type === 'TESTOBJECTS' || type === 'PERFECTOLAB_DEVICES' || type === 'PERFECTOLAB_DESKTOP' || type === 'LOCALSELENIUM'
const editPossible = (type) => type !== 'INTEGRATED'

const deviceProviderTypes = [
  {
    type: 'SAUCELABS',
    name: 'Saucelabs.com Desktop Browsers and Device Emulators'
  },
  {
    type: 'SAUCELABS_RDC',
    name: 'Saucelabs.com Real Device Cloud'
  },
  {
    type: 'TESTOBJECTS',
    name: 'Saucelabs.com Real Device Cloud (Legacy)'
  },
  {
    type: 'PERFECTOLAB_DESKTOP',
    name: 'Perfectolabs Desktop Browsers'
  },
  {
    type: 'PERFECTOLAB_DEVICES',
    name: 'Perfectolabs Real Device Cloud'
  },
  {
    type: 'EXPERITEST',
    name: 'experitest Desktop & Real Device Browsers'
  },
  {
    type: 'LOCALSELENIUM',
    name: 'Custom Selenium Endpoint'
  },
  {
    type: 'INTEGRATED',
    name: 'Headless Chrome'
  }
]

export function getDeviceProviderIcon (type) {
  switch (type) {
    case 'SAUCELABS':
    case 'SAUCELABS_RDC':
    case 'TESTOBJECTS':
      return deviceProviderIconSaucelabs
    case 'PERFECTOLAB_DESKTOP':
    case 'PERFECTOLAB_DEVICES':
      return deviceProviderIconPerfecto
    case 'EXPERITEST':
      return deviceProviderIconExperitest
    case 'LOCALSELENIUM':
      return deviceProviderIconSelenium
    default:
      return deviceProviderIconChrome
  }
}

export function getDeviceProviderName (type) {
  const dpt = deviceProviderTypes.find(dpt => dpt.type === type)
  if (dpt) return dpt.name
}

class DeviceProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pingRunning: false
    }
  }

  renderForm(deviceprovider) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady, user, license } = this.props
    const { pingRunning } = this.state

    const deviceProviderTypeSel = deviceProviderTypes.filter(t => {
      if (deviceprovider.id && deviceprovider.type === t.type) {
        return true
      }
      if (license.deviceCloud) {
        return editPossible(t.type)
      } else {
        return t.type === 'LOCALSELENIUM'
      }
    })

    return (
      <Mutation
        mutation={
          deviceprovider.id ? UPDATE_DEVICEPROVIDER : CREATE_DEVICEPROVIDER
        }
        refetchQueries={[
          {
            query: DEVICEPROVIDERS_QUERY,
          },
        ]}
      >
        {(mutateDeviceProvider, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              const varprovider = {
                name: values.name,
                type: values.type,
                url: values.url
              }
              if (usernameRequired(values.type)) {
                varprovider.username = values.username
              } else {
                varprovider.username = null
              }
              if (passwordRequired(values.type)) {
                if (values.password === 'X') {
                  varprovider.password = null
                } else if (values.password && values.password.length > 0) {
                  varprovider.password = values.password
                }
              } else {
                varprovider.password = null
              }

              if (deviceprovider.id) {
                try {
                  const res = await mutateDeviceProvider({
                    variables: {
                      id: deviceprovider.id,
                      deviceProvider: varprovider
                    },
                  })
                  form.initialize(res.data.updateDeviceProvider)
                  setAlertSuccessMessage('Device Provider updated')
                  onReady(deviceprovider.id)
                } catch(error) {
                  setAlertErrorMessage('Device Provider update failed', error)
                }
              } else {
                try {
                  const res = await mutateDeviceProvider({
                    variables: {
                      deviceProvider: varprovider
                    },
                  })
                  form.initialize(res.data.createDeviceProvider)
                  setAlertSuccessMessage('Device Provider registered')
                  onReady(res.data.createDeviceProvider.id)
                } catch(error) {
                  setAlertErrorMessage('Device Provider register failed', error)
                }
              }
            }}
            initialValues={deviceprovider}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values,
              form:  { change }
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <UnsavedFormSpy />
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="name"
                        component={renderTextField}
                        label="Device Provider Name"
                        validate={composeValidators(required, async (value) => {
                          if (value) {
                            return validateDeviceProviderNameUnique(this.props.client, deviceprovider.id, value)
                          }
                        })}  
                        disabled={!hasPermission(user, 'DEVICEPROVIDERS_MANAGE') || !editPossible(values.type)}
                        data-unique="txtDeviceProviderDeviceProviderName"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="type"
                        component={renderSelect}
                        label="Device Provider"
                        disabled={!hasPermission(user, 'DEVICEPROVIDERS_MANAGE') || !editPossible(values.type)}
                        validate={required}
                        data-unique="selDeviceSetType"
                        items={deviceProviderTypeSel.map(dpt => {
                          return {
                            key: dpt.type,
                            label: dpt.name,
                            image: getDeviceProviderIcon(dpt.type)
                          }
                        })}
                      />
                      {!deviceprovider.id && values.type &&
                        <OnChange name={'type'}>
                          {(value, previous) => {
                            if (value && value !== previous && urlRequired(value)) {
                              const defaultUrl = urlDefault(value)
                              if (defaultUrl) {
                                change('url', defaultUrl)
                              }
                            }
                          }}
                        </OnChange>
                      }
                    </GridItem>
                    {urlRequired(values.type) && (
                      <GridItem xs={12}>
                        <Field
                          name="url"
                          component={renderTextField}
                          label={urlFieldLabel(values.type)}
                          validate={composeValidators(required, url)}
                          disabled={!hasPermission(user, 'DEVICEPROVIDERS_MANAGE') || !editPossible(values.type)}
                          data-unique="txtDeviceProviderDeviceProviderUrl"
                        />
                      </GridItem>
                    )}
                    {usernameRequired(values.type) && (
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="username"
                          component={renderTextField}
                          label={usernameFieldLabel(values.type)}
                          validate={required}
                          disabled={!hasPermission(user, 'DEVICEPROVIDERS_MANAGE') || !editPossible(values.type)}
                          data-unique="txtDeviceProviderDeviceProviderUsername"
                        />
                      </GridItem>
                    )}
                    {passwordRequired(values.type) && deviceprovider.id && hasPermission(user, 'DEVICEPROVIDERS_MANAGE') && editPossible(values.type) && (
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="password"
                          component={renderPasswordField}
                          label={`Enter new ${passwordFieldLabel(values.type)}`}
                          data-unique="pwDeviceProviderPassword"
                        />
                      </GridItem>
                    )}
                    {passwordRequired(values.type) && !deviceprovider.id && (
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="password"
                          component={renderPasswordField}
                          label={passwordFieldLabel(values.type)}
                          validate={required}
                          data-unique="pwDeviceProviderPassword"
                        />
                      </GridItem>
                    )}
                    {hasPermission(user, 'DEVICEPROVIDERS_MANAGE') &&
                      <GridItem xs={12} largePadding>
                        <FormActionsToolbar
                          leftButtons={<>
                            {deviceprovider.id && editPossible(values.type) && (
                              <Mutation
                                mutation={DELETE_DEVICEPROVIDER}
                                onCompleted={data => {
                                  setAlertSuccessMessage('Device Provider deleted')
                                  onReady()
                                }}
                                onError={error => {
                                  setAlertErrorMessage(
                                    'Device Provider deletion failed',
                                    error,
                                  )
                                }}
                                refetchQueries={[
                                  {
                                    query: DEVICEPROVIDERS_QUERY,
                                  },
                                ]}
                              >
                                {(deleteDeviceProvider, { loading, error }) => (
                                  <ConfirmationButton
                                    confirmationText={`In case the Device Provider is used for a Device set already, you have to cleanup the Device Sets first. Are you sure you want to delete this Device Provider including all configuration ?`}
                                    requireCheck={true}
                                    onClick={() => {
                                      deleteDeviceProvider({
                                        variables: { id: deviceprovider.id },
                                      })
                                    }}
                                    data-unique="btnDeviceProviderDelete"
                                    secondary
                                    danger                                    
                                  >
                                    <ShowIcon icon="trash" />
                                    Delete
                                  </ConfirmationButton>
                                )}
                              </Mutation>
                            )}
                          </>}
                          rightButtons={<>
                            {deviceprovider.id && pingPossible(values.type) && (
                              <Mutation
                                mutation={PING_DEVICEPROVIDER}
                                onCompleted={data => {
                                  this.setState({ pingRunning: false })
                                  setAlertSuccessMessage('Device Provider connection OK')
                                }}
                                onError={error => {
                                  this.setState({ pingRunning: false })
                                  setAlertErrorMessage(
                                    'Device Provider connection failed',
                                    error,
                                  )
                                }}
                              >
                                {(pingDeviceProvider, { loading, error }) => (
                                  <Button
                                    onClick={() => {
                                      this.setState({ pingRunning: true })
                                      pingDeviceProvider({
                                        variables: { id: deviceprovider.id },
                                      })
                                    }}
                                    disabled={pingRunning}
                                    data-unique="btnDeviceProviderPing"
                                    secondary
                                  >
                                    {pingRunning && <LoadingIndicator alt />}
                                    {!pingRunning && <CallIcon />}
                                    Ping
                                  </Button>
                                )}
                              </Mutation>
                            )}
                            {editPossible(values.type) &&                     
                              <Button
                                type="submit"
                                disabled={submitting || invalid}
                                data-unique="btnDeviceProviderSave"
                              >
                                {submitting && <LoadingIndicator alt />}
                                {!submitting && <ShowIcon icon="save" />}
                                Save
                              </Button>
                            }
                          </>}
                        />                        
                      </GridItem>
                    }
                  </GridContainer>
                </form>
              )
            }}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={DEVICEPROVIDER_QUERY} variables={{ id }}>
              {(queryResult) => <QueryStatus {...queryResult} query="deviceprovider">{(data) => this.renderForm(data.deviceprovider)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({ type: 'LOCALSELENIUM' })}
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage },
)(withStyles(settingsStyle)(withApollo(DeviceProvider)))
