import React from 'react'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'

import {
  CRAWLERSESSION_QUERY,
  CRAWLERSESSION_PROGRESS_SUBSCRIPTION,
} from './gql'

class CrawlerSessionProgress extends React.Component {
  _isMounted = false

  state = {
    subscription: null,
    crawlerSessionProgress: null,
    crawlerSessionProgressLoading: true,
    crawlerSessionProgressErr: null
  }

  componentDidMount() {
    this._isMounted = true
    this.subscribe()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.crawlerSession || prevProps.crawlerSession.id !== this.props.crawlerSession.id || JSON.stringify(prevProps.variables) !== JSON.stringify(this.props.variables)) {
      this.unsubscribe()
      this.subscribe()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.unsubscribe()
  }

  async subscribe() {
    const { client, crawlerSession, query, subscription: subscriptionQuery, variables } = this.props

    if (!crawlerSession || !crawlerSession.id) return

    const thisProps = this.props
    const setStateIfMounted = (...args) => {
      if (!this._isMounted) return
      this.setState(...args)
    }

    try {
      const r = await client
        .query({
          query: query || CRAWLERSESSION_QUERY,
          variables: Object.assign({}, {id: crawlerSession.id}, variables),
          fetchPolicy: 'no-cache'
        })

      const qSel = this.props.querySelector || (data => data.crawlersession)
      const data = qSel(r.data)

      if (data != null) {
        setStateIfMounted({
          crawlerSessionProgress: data,
          crawlerSessionProgressLoading: false,
          crawlerSessionProgressErr: null
        })
        const readySel = this.props.readySelector || (cs => cs && (cs.status === 'READY' || cs.status === 'FAILED' || cs.status === 'PARTIALLY_FAILED' || cs.status === 'CANCELLED'))
        if (readySel(data)) return

        const subscription = client
          .subscribe({
            query: subscriptionQuery || CRAWLERSESSION_PROGRESS_SUBSCRIPTION,
            variables: Object.assign({}, { id: crawlerSession.id }, variables)
          })
          .subscribe({
            next(s) {
              const sSel = thisProps.subscriptionSelector || (data => data.crawlerSessionProgress)

              if (s && s.data && sSel(s.data) != null) {
                setStateIfMounted({
                  crawlerSessionProgressLoading: false,
                  crawlerSessionProgressErr: null,
                  crawlerSessionProgress: sSel(s.data)
                })
              }
            },
            error(err) {
              setStateIfMounted({
                crawlerSessionProgressLoading: false,
                crawlerSessionProgressErr: err
              })
            },
          })
        setStateIfMounted({
          subscription
        })
      } else {
        setStateIfMounted({
          crawlerSessionProgressLoading: false,
          crawlerSessionProgressErr: null
        })
      }
    } catch (err) {
      setStateIfMounted({
        crawlerSessionProgressLoading: false,
        crawlerSessionProgressErr: err
      })
    }
  }

  unsubscribe() {
    if (this.state.subscription) {
      this.state.subscription.unsubscribe()
      this._isMounted && this.setState({
        subscription: null,
        crawlerSessionProgress: null,
        crawlerSessionProgressLoading: true,
        crawlerSessionProgressErr: null
      })
    }
  }

  render () {
    const { crawlerSessionProgress, crawlerSessionProgressLoading, crawlerSessionProgressErr } = this.state
    const { crawlerSession } = this.props

    return this.props.children({
      crawlerSessionProgress: crawlerSessionProgress == null ? crawlerSession : crawlerSessionProgress,
      crawlerSessionProgressLoading,
      crawlerSessionProgressErr,
      subscribe: () => this.subscribe(),
      unsubscribe: () => this.unsubscribe(),
      refetch: () => {
        this.unsubscribe()
        this.subscribe()
      }
    })
  }
}

CrawlerSessionProgress.propTypes = {
  crawlerSession: PropTypes.any,
  children: PropTypes.func.isRequired
}

export default withApollo(CrawlerSessionProgress)
