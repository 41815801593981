import React from 'react'
import { connect } from 'react-redux'
import { withApollo, compose, graphql } from 'react-apollo'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import _ from 'lodash'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import { Form, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import { transcriptToConvo } from 'botium-box-shared/utils/compiler'

// core components
import LinearProgress from 'components/Progress/LinearProgress'
import ListItem from 'components/List/ListItem/ListItem'
import Paginate from 'components/List/Paginate'
import DropdownButton from 'components/Button/DropdownButton'
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { renderSelect, renderTextField, required } from 'components/Form/Form'
import ShowIcon from 'components/Icon/ShowIcon'
import CardBody from 'components/Card/CardBody'
import Card from 'components/Card/Card'
import ErrorFormat from 'components/Info/ErrorFormat'
import Field from 'components/Form/OptionalField'
import Text from 'components/Typography/Text'
import Stepper from 'components/Wizard/Stepper'
import Divider from 'components/Divider/Divider'

import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { setTestDataWizardData, clearTestDataWizardData } from 'actions/testdatawizard'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TEST_DATA_WIZARD_SAMPLES,
  TEST_DATA_WIZARD_SCRIPT,
} from './gql'
import { CHATBOTHELLO_QUERY, CHATBOTS_DROPDOWN_QUERY } from '../Chatbots/gql'

import AITestDataSaveDialog from './AITestDataSaveDialog'
import Transcript from '../../components/Convo/Transcript'
import ConfirmationButton from '../../components/Button/ConfirmationButton'
import SelectableCard from 'components/Card/SelectableCard'
import { safeGetNamespaceFilteredList } from '../helper'

const INITIAL_VALUES = {
  clientId: 0,
  annotatingConvoIndex: 0,
  convos: [],
  customIntents: []
}

class AiTestDataWizardNlu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mesGuessingError: null,
      showCopyDialog: false,
      testDataWizardScripts: null,
      testDataWizardChatbotId: null,
      mesGuessing: false
    }
    // the background job cant read the form.values. So render updates this.values,
    // and background job has uptodate data.
    this.values = null
    this.guessNextConvoWeakIsRunning = false
    this.testDataWizardDataChangedInternally = false
    this._isMounted = false
    this.activeStep = null
  }

  updateConvo = (convo, changes, index, mutators) => {
    Object.assign(convo, changes)
    mutators.update('convos', index, convo)
  }

  guessNextConvoWeak = async (change, mutators) => {
    if (!this._isMounted || this.guessNextConvoWeakIsRunning) {
      return
    }
    const { setAlertErrorMessage } = this.props

    try {
      const nextConvo = () => {
        // if convos parameter is set, then read from it first time. A bit hacky, but is there better idea?
        return this.values.convos.find(c => c.convoState === 'PENDING')
      }
      const updateConvoWeak = (id, convo, mutators) => {
        const index = this.values.convos.findIndex(c => c.id === id)
        if (index < 0) {
          // if the process was cancelled by drop all data button, then the convo list is empty
          return
        }
        const c = this.values.convos[index]
        this.updateConvo(c, convo, index, mutators)
      }

      let convo = nextConvo()
      if (convo) {
        this.guessNextConvoWeakIsRunning = true
      }
      while (convo && this.activeStep === 1 && this._isMounted) {
        const { me, id } = convo
        updateConvoWeak(id, { convoState: 'RUNNING' }, mutators)
        const { err, convo: realConvo } = await this.generateConvo(this.values, me)
        // skipping welcome message(s)
        const meIndex = realConvo && _.findIndex(realConvo, c => c.sender === 'me')
        const botAnswerIndex = realConvo && _.findIndex(realConvo, c => c.sender === 'bot', meIndex)

        const botActual = (botAnswerIndex >= 0) ? realConvo[botAnswerIndex].actual : null

        const intent = (botActual && botActual.nlp) ? botActual.nlp.intent : { name: null, confidence: null }
        updateConvoWeak(id, realConvo ? {
          convoState: 'READY',
          convo: realConvo,
          // just some shortcuts
          intent: intent,
          intentName: intent.name,
          botAnswer: botActual,
          meIndex,
          botAnswerIndex
        } : { convoState: 'FAILED', convo: realConvo, intent, err: `${err}` }, mutators)
        convo = nextConvo()
        const newState = {}
        this.setState(newState)
      }
    } catch (err) {
      console.error(`${err} ${err.stack}`)
      setAlertErrorMessage(err)
    } finally {
      this.guessNextConvoWeakIsRunning = false
    }
  }

  async generateMes({ chatbotDomain, chatbotDescription }) {
    const { client } = this.props
    // fist version we are using booth, even if BL can work with one of them.

    const { data } = await client.query({
      query: TEST_DATA_WIZARD_SCRIPT,
      variables: {
        chatbotDomain: chatbotDomain || 'other',
        chatbotDescription: chatbotDescription || 'Health care chatbot',
        scriptType: 'SCRIPTING_TYPE_UTTERANCES'
      },
      fetchPolicy: 'network-only'
    })

    if (data.testdatawizardscript) {
      return data.testdatawizardscript.split('\n').slice(1)
    } else {
      return []
    }
  }

  async generateConvo(values, me) {
    const { client } = this.props
    try {
      const { data } = await client.query({
        query: CHATBOTHELLO_QUERY,
        variables: {
          id: values.chatbotId,
          text: me,
          capabilities: []
        },
        fetchPolicy: 'network-only'
      })
      if (data.chatbothello.err) {
        return { err: data.chatbothello.err }
      }
      const convo = data && data.chatbothello && data.chatbothello.convo && JSON.parse(data.chatbothello.convo)
      if (!convo || !convo.length) {
        return { err: 'No convo' }
      }

      return { convo, script: data.chatbothello.script, attachments: data.chatbothello.attachments }
    } catch (err) {
      return { err: `${err}` }
    }
  }

  // using this values can be dangerous. It will be different as form.values after time
  startGenerate = async ({ values, change, batch, mutators, reset, submitting, invalid }) => {
    const { setAlertErrorMessage } = this.props
    this.setState({
      mesGuessingError: null,
      mesGuessing: true
    })
    let convos = values.convos
    try {
      const mes = await this.generateMes(values)
      const newMes = mes.filter(me => !convos.find(convo => convo.me === me))
      if (newMes.length === 0) {
        setAlertErrorMessage('No new Test Cases found')
      }
      convos = convos.concat(newMes.map((me, i) => ({ me, id: values.clientId + i, convoState: 'PENDING' })))
      values.clientId += convos.length

      batch(() => {
        change('convos', convos)
        change('clientId', values.clientId)
      })
      // // this is a promise, but its not awaited
      // this.guessNextConvo(change, mutators, convos)
      this.setState({
        mesGuessing: false
      })
    } catch (err) {
      this.setState({
        mesGuessingError: `${err}`,
        mesGuessing: false
      })
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  isIncomprehension(convo, incomprehensionIntent, incomprehensionUtterances) {
    const intent = (convo.botAnswer && convo.botAnswer.nlp) ? convo.botAnswer.nlp.intent : {
      name: null,
      confidence: null
    }
    return intent.incomprehension || (intent.name && intent.name === incomprehensionIntent) || (convo.botAnswer && convo.botAnswer.messageText && incomprehensionUtterances && incomprehensionUtterances.includes(convo.botAnswer.messageText))
  }

  renderRestoreQuestion({ values, change, batch, mutators, reset, submitting, invalid, convoCount, generatedConvoCount, annotatedConvoCount, testDataWizardData }) {
    const { clearTestDataWizardData } = this.props
    const convos = testDataWizardData.values && testDataWizardData.values.convos ? testDataWizardData.values.convos : []
    return <Card><CardBody>
    <GridContainer >
      <GridItem md={8} lg={4}>
        <ListItem>
          <Text lg warning padding><ShowIcon icon="circle-notch" /></Text>

          <GridContainer nounset>
            <GridItem xs={12}> <Text bold>Continue labeling {convos.length - convos.filter(c => c.annotated).length}/{convos.length} Test Cases</Text></GridItem>
            <GridItem xs={12}><Text>labeled {moment(testDataWizardData.updatedAt).fromNow()}</Text></GridItem>
          </GridContainer>
          <Button
            small
            minWidth
            onClick={() => {
              const newValues = Object.assign({}, testDataWizardData.values, testDataWizardData.values.convos.map(c => {
                if (c.convoState === 'RUNNING') {
                  c = Object.assign({}, c, { convoState: 'PENDING' })
                }
                return c
              }))
              reset(newValues)
            }}
            data-unique="btnTestDataWizardNluContinueLabeling"
          >
            <ShowIcon icon="circle-notch" />
            Continue
          </Button>
        </ListItem>
      </GridItem>
      <GridItem md={12} lg={8}></GridItem>
      <GridItem md={8} lg={4}><Divider dense text="or" /></GridItem>
      <GridItem md={12} lg={8}></GridItem>
      <GridItem md={8} lg={4}>
        <ListItem>
          <Text lg danger padding><ShowIcon icon="trash" /></Text>

          <GridContainer nounset>
            <GridItem xs={12}><Text bold>Drop all data and restart</Text></GridItem>
            <GridItem xs={12}><Text>labeled {moment(testDataWizardData.updatedAt).fromNow()}</Text></GridItem>
          </GridContainer>
          <ConfirmationButton
            confirmationText={`You will lose all your previous work! Are you sure?`}
            requireCheck={true}
            danger
            small
            minWidth
            onClick={() => {
              clearTestDataWizardData()
            }}
            data-unique="btnTestDataWizardNluCancelLabeling"
          >
            <ShowIcon icon="trash" />
            Drop all data
          </ConfirmationButton>
        </ListItem>
      </GridItem>
    </GridContainer>
    </CardBody></Card>
  }

  renderAnnotator({ values, change, batch, mutators, reset, submitting, invalid, convoCount, generatedConvoCount, annotatedConvoCount }) {
    const { avatar, clearTestDataWizardData, classes } = this.props
    const { annotatingConvoIndex } = values
    const convo = values.convos[annotatingConvoIndex]
    const intentName = convo && convo.intent && convo.intent.name
    const toSave = values.convos.filter(c => c.annotated && c.annotated.action === 'keep')

    const intents = {}
    let noIntentNotAnnotated = 0
    let ignored = 0
    for (const c of values.convos) {
      const check = intent => {
        if (!intent) {
          return
        }
        if (!intents[intent]) {
          intents[intent] = { notAnnotated: 0, annotated: 0 }
        }
      }
      if (['READY'].includes(c.convoState)) {
        check(c.intent.name)
        if (c.annotated) {
          if (c.annotated.action === 'ignore') {
            ignored++
          } else if (c.annotated.action === 'keep') {
            check(c.annotated.intent)
            intents[c.annotated.intent].annotated++
          }
        } else if (c.intent.name) {
          intents[c.intent.name].notAnnotated++
        } else {
          noIntentNotAnnotated++
        }
      }
    }
    const renderConvo = (convo) => {
      return <Transcript err={convo.err} steps={convo.convo || []} attachments={convo.attachments}
        chatbotId={values.chatbotId} allowHtmlDisplay={true}
        avatar={avatar} />
    }
    const inProgress = !convo || ['RUNNING', 'PENDING'].includes(convo.convoState)
    const setConvo = (newConvo) => {
      const index = values.convos.findIndex(c => c.id === convo.id)
      if (index < 0) {

        throw new Error(`Convo not found by id ${convo.id} V2, ${JSON.stringify(new Error().stack)}`)
      }
      mutators.update('convos', index, newConvo)
    }
    const updateConvo = (changes) => {
      const index = values.convos.findIndex(c => c.id === convo.id)
      if (index < 0) {
        throw new Error(`Convo not found by id ${convo.id} V3, ${JSON.stringify(new Error().stack)}`)
      }
      this.updateConvo(convo, changes, index, mutators)
    }

    const _sortOrder = (intent) => {
      if (values.customIntents && values.customIntents.findIndex(i => i === intent) >= 0) {
        return -10
      }
      if (intentName && intent === intentName) {
        return -5
      }
      return 0
    }

    return <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card>
          <CardBody noPaddingBottom noPaddingTop>
            <GridContainer>
              <Helmet>
                <title>{annotatingConvoIndex < convoCount ? `Labeling ${annotatingConvoIndex + 1}/${convoCount} Test Cases ` : 'Labeling done'}</title>
              </Helmet>

              <GridItem md={12} lg={7} right bottom className={classes.col1}>
                <Button
                  secondary
                  onClick={() => {
                    setConvo({ me: convo.me, id: convo.id, convoState: 'PENDING' })
                  }}
                  disabled={inProgress}
                  data-unique="btnTestDataWizardConvoRefresh"
                >
                  <ShowIcon icon="redo" />
                  Retry Unsupervised Labeling
                </Button>
              </GridItem>
              <GridItem md={12} lg={5} borderLeft className={classes.col2}>
                <Field
                  name="annotatingConvoIndex"
                  component={renderSelect}
                  label={convoCount === 0 ? `Test Case (generating Test Cases...)` : (generatedConvoCount < convoCount) ? 'Test Case (unsupervised labeling running ...)' : 'Test Case'}
                  validate={required}
                  disabled={values.convos.length === 0}
                  data-unique="selTestDataWizardNluAnnotatingConvoIndex"
                  items={values.convos.map((c, i) => {
                    const i2 = c.intentName || (c.convoState === 'READY' ? '- no intent -' : c.convoState === 'FAILED' ? '- failed -' : '...')
                    const intentSection = i2 ? ` (${i2})` : ''
                    return {
                      key: i,
                      label: `${i + 1}: ${c.me}${intentSection}`
                    }
                  })}
                />
              </GridItem>

              <GridItem md={12} lg={7} className={classes.col3}>
                <GridContainer autoHeight>
                  <GridItem xs={12} sm={12} grey>
                    {convo && !inProgress && renderConvo(convo)}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem md={12} lg={5} grey borderLeft className={classes.col4}>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    {inProgress && <LinearProgress variant="indeterminate" />}
                    {!inProgress && <LinearProgress value={100 * annotatedConvoCount / convoCount}
                      valueBuffer={100 * generatedConvoCount / convoCount} variant="buffer" />}
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <Paginate pageCount={convoCount} onChange={(page) => change('annotatingConvoIndex', page)} currentPage={values.annotatingConvoIndex} />
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <GridContainer>
                      <GridItem xs={12} smallPadding>
                        <SelectableCard
                          disabled={inProgress}
                          selected={convo && convo.annotated && convo.annotated.action === 'ignore'}
                          onClick={() => {
                            updateConvo({ annotated: { action: 'ignore' } })
                            if (annotatingConvoIndex + 1 < convoCount) {
                              change('annotatingConvoIndex', annotatingConvoIndex + 1)
                            }
                          }
                          }
                        >
                          <CardBody>
                            <GridContainer>
                              <GridItem xs={8}>
                                {/*muted: because SelectableCard does not looks disabled */}
                                {(!inProgress && (!intentName || convo.convoState === 'FAILED' || this.isIncomprehension(convo))) ?
                                  <Text success={!inProgress} muted={inProgress} bold>Ignore Test Case</Text> :
                                  <Text info={!inProgress} muted={inProgress} bold>Ignore Test Case</Text>
                                }
                              </GridItem>
                              <GridItem xs={2}>
                                <GridContainer>
                                  <GridItem xs={12} center>
                                    <Text bold>{ignored}</Text>
                                  </GridItem>
                                  <GridItem xs={12} center>
                                    <Text>Ignored</Text>
                                  </GridItem>
                                </GridContainer>
                              </GridItem>
                              <GridItem xs={2}>
                                <GridContainer>
                                  <GridItem xs={12} center>
                                    <Text bold>{noIntentNotAnnotated}</Text>
                                  </GridItem>
                                  <GridItem xs={12} center>
                                    <Text>No Intent</Text>
                                  </GridItem>
                                </GridContainer>
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </SelectableCard>
                      </GridItem>
                      {_.sortBy(Object.keys(intents), _sortOrder).map(name =>
                        <GridItem xs={12} key={name} smallPadding>
                          <SelectableCard
                            disabled={inProgress}
                            selected={convo && convo.annotated && convo.annotated.action === 'keep' && convo.annotated.intent === name}
                            onClick={() => {
                              updateConvo({ annotated: { action: 'keep', intent: name } })
                              if (annotatingConvoIndex + 1 < convoCount) {
                                change('annotatingConvoIndex', annotatingConvoIndex + 1)
                              }
                            }
                            }
                          >
                            <CardBody>
                              <GridContainer>
                                <GridItem xs={8}>
                                  {/*muted: because SelectableCard does not looks disabled */}
                                  <Text inline bold muted={inProgress} success={!inProgress && intentName === name && !this.isIncomprehension(convo)}>Label as </Text><Text inline bold italic muted={inProgress} success={!inProgress && intentName === name && !this.isIncomprehension(convo)}>{name}</Text>
                                </GridItem>
                                <GridItem xs={2}>
                                  <GridContainer>
                                    <GridItem xs={12} center>
                                      <Text bold>{intents[name].annotated}</Text>
                                    </GridItem>
                                    <GridItem xs={12} center>
                                      <Text>Labeled</Text>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                                <GridItem xs={2}>
                                  <GridContainer>
                                    <GridItem xs={12} center>
                                      <Text bold>{intents[name].notAnnotated}</Text>
                                    </GridItem>
                                    <GridItem xs={12} center>
                                      <Text>Suggested</Text>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </SelectableCard>
                        </GridItem>
                      )}
                      <GridItem xs={10}>
                        <Field
                          name="newIntent"
                          label="Label as other intent"
                          component={renderTextField}
                          data-unique="txtTestDataWizardNluNewIntent"
                          disabled={!convo || convo.convoState !== 'READY'}
                        />
                      </GridItem>
                      <GridItem xs={2} largePadding bottom>
                        <Button
                          title={'Add'}
                          color={!!values.newIntent ? 'primary' : null}
                          data-unique={'btnTestDataWizardNlpAnnotateNewIntent'}
                          disabled={!convo || convo.convoState !== 'READY' || !values.newIntent}
                          justIconSolid
                          onClick={() => {
                            batch(() => {
                              updateConvo({ annotated: { action: 'keep', intent: values.newIntent } })
                              if (!intents[values.newIntent] && !values.customIntents.find(i => i === values.newIntent)) {
                                mutators.push('customIntents', values.newIntent)
                              }
                              change('newIntent', null)
                              change('annotatingConvoIndex', annotatingConvoIndex + 1)
                            })
                          }}
                        >
                          <ShowIcon icon="plus" />
                        </Button>
                      </GridItem>
                    </GridContainer>


                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem md={12} lg={7}>

              </GridItem>
              <GridItem md={12} lg={5} largePadding borderLeft className={classes.col5}>
                <GridContainer>
                  <GridItem md={6}>
                    <ConfirmationButton
                      confirmationText={`You will lose all your previous work! Are you sure?`}
                      requireCheck={true}
                      secondary
                      danger
                      onClick={() => {
                        clearTestDataWizardData()
                        reset({
                          ...INITIAL_VALUES,
                          chatbotId: values.chatbotId,
                          chatbotDomain: values.chatbotDomain,
                          chatbotDescription: values.chatbotDescription
                        })
                      }}
                      data-unique="btnTestDataWizardConvoCancel"
                    >
                      <ShowIcon icon="trash" />
                      Drop all data
                    </ConfirmationButton>
                  </GridItem>
                  <GridItem md={6} right>
                    <Button
                      type={'submit'}
                      data-unique="btnTestDataWizardNlpFinish"
                      color={'primary'}
                      disabled={!toSave.length > 0}
                    >
                      <ShowIcon icon="save" />
                      {annotatedConvoCount === generatedConvoCount && 'Save Labeled Test Cases'}
                      {annotatedConvoCount !== generatedConvoCount && `Save Labeled Test Cases (skip ${generatedConvoCount - annotatedConvoCount})`}
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  }

  renderInputData({ values, change, batch, mutators, reset, submitting, invalid }) {
    const { classes, chatbotsData, wizardSamplesData } = this.props
    const { mesGuessingError } = this.state
    const chatbotDomains = (wizardSamplesData && wizardSamplesData.testdatawizardsamples && _.uniq(wizardSamplesData.testdatawizardsamples.map(s => s.domain)).sort()) || []
    const descriptionSamples = (wizardSamplesData && wizardSamplesData.testdatawizardsamples && wizardSamplesData.testdatawizardsamples.filter(w => w.domain === values.chatbotDomain).reduce((agg, a) => {
      return [
        ...agg,
        ...a.descriptions.map(d => ({
          id: a.domain + a.language + d,
          name: d,
          onClick: () => change('chatbotDescription', d)
        }))
      ]
    }, [])) || []
    const chatbotDomainItems = chatbotDomains && chatbotDomains.map(d => {
      return {
        key: d
      }
    })
    chatbotDomainItems.push({
      key: 'Other',
      label: 'OTHER'
    })


    return <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card className={classes.denseCard}><CardBody>
          <GridContainer>
            <GridItem md={12} lg={4}>
              <Field
                name="chatbotDomain"
                component={renderSelect}
                label="Select a Chatbot Domain"
                helperText="Required to generate testcases"
                data-unique="selTestDataWizardNluDomain"
                validate={required}
                items={chatbotDomainItems}
              />
            </GridItem>
            <GridItem md={12} lg={4}>
              <Field
                name="chatbotDescription"
                component={renderTextField}
                label="Description of the Chatbot"
                title="Description of the Chatbot"
                validate={required}
                data-unique="txtTestDataWizardNluChatbotDescription"
                helperText={mesGuessingError ? <ErrorFormat
                  err={mesGuessingError} /> : 'Required to generate testcases. Example \'Pizza ordering chatbot, english, 10 intents\''}
                endAdornment={descriptionSamples && descriptionSamples.length > 0 && <DropdownButton
                  data-unique="btnTestDataWizardSamples"
                  justIcon
                  color="transparent"
                  items={descriptionSamples}
                >
                  <ShowIcon icon="lightbulb" />
                </DropdownButton>}
              />
            </GridItem>
            <GridItem md={12} lg={4}>
              <Field
                name="chatbotId"
                component={renderSelect}
                label="Select a Chatbot"
                helperText="Chatbot supporting intent recognition is required."
                validate={required}
                items={chatbotsData && chatbotsData.chatbots && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace).filter(c => c.connector && c.connector.features && c.connector.features.intentResolution).map(c => {
                  return {
                    key: c.id,
                    chatbot: c
                  }
                })
                }
                data-unique="selTestDataWizardNluChatbotId"
              />
            </GridItem>
            <GridItem md></GridItem>
            <GridItem md={2} lg={1} style={{ display: 'flex', alignItems: 'right' }}>
              {<Button
                onClick={async () => {
                  await this.startGenerate({ values, change, batch, mutators, reset, submitting, invalid })
                }}
                fullWidth
                data-unique="btnTestDataWizardNluStartGenerate"
                disabled={submitting || invalid}
              >
                Next »
              </Button>}
            </GridItem>
          </GridContainer>
        </CardBody></Card>
      </GridItem>
    </GridContainer>
  }

  renderCopyScriptsDialog(reset, dirty) {
    const { showCopyDialog, testDataWizardScripts, testDataWizardChatbotId } = this.state
    const { history, clearTestDataWizardData } = this.props

    return <AITestDataSaveDialog
      showCopyDialog={showCopyDialog}
      testDataWizardScripts={testDataWizardScripts}
      testDataWizardChatbotId={testDataWizardChatbotId}
      nlpOnly={false}
      history={history}
      reset={reset}
      onComplete={() => {
        clearTestDataWizardData()
        this.setState({ showCopyDialog: false, testDataWizardScripts: [], testDataWizardChatbotId: null })
      }}
      onCancel={() => {
        this.setState({ showCopyDialog: false })
      }}
      title="Save Labeled Test Cases"
      okText="Save Labeled Test Cases"
    />
  }

  submit(values) {
    const scripts = []
    const utterances = {}
    const addUtterance = (utterance, name) => {
      if(!utterance) {
        console.error(`utterance value is undefined for the '${name}' utterance name`)
        return
      }
      utterance = utterance.replace(/\n/g, ' ').trim()
      if (!utterances[name]) {
        utterances[name] = []
      }
      if (utterance && utterance.length > 0) {
        utterances[name].push(utterance)
      }
    }

    for (const convo of values.convos.filter(c => c.annotated && c.annotated.action === 'keep')) {
      const annotatedIntent = _.get(convo, 'annotated.intent')
      if(!annotatedIntent) {
        console.error('The annotated intent is undefined in convo: ', convo)
        continue
      }
      const name = annotatedIntent.replace(/ /g, '_').toUpperCase()
      const meUtteranceName = `UTT_ME_${name}`
      addUtterance(convo.me, meUtteranceName)

      if (convo.intent.name === convo.annotated.intent) {
        const convoToSave = _.cloneDeep(convo.convo).map(c => transcriptToConvo(c.actual))

        const botUtteranceName = `UTT_BOT_${name}`
        addUtterance(convoToSave[convo.botAnswerIndex].messageText, botUtteranceName)

        if (scripts.findIndex(s => s.name === name) < 0) {
          convoToSave[convo.meIndex].messageText = meUtteranceName
          convoToSave[convo.botAnswerIndex].messageText = botUtteranceName
          scripts.push({
            name,
            convo: JSON.stringify({ name, steps: convoToSave }),
            scriptType: 'SCRIPTING_TYPE_CONVO'
          })
        }
      }
    }

    for (const [name, list] of Object.entries(utterances)) {
      scripts.push({
        name: name,
        script: [name, ...((list && list.length > 0) ? _.uniq(list) : ['*'])].join('\n'),
        scriptType: 'SCRIPTING_TYPE_UTTERANCES'
      })
    }

    this.setState({ showCopyDialog: true, testDataWizardScripts: scripts, testDataWizardChatbotId: values.chatbotId })
  }

  render() {
    const { setTestDataWizardData, testDataWizardData } = this.props
    const { mesGuessing } = this.state
    const valuesToRestore = !this.testDataWizardDataChangedInternally && testDataWizardData && testDataWizardData.values

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Form
            onSubmit={(values) => this.submit(values)}
            mutators={{ ...arrayMutators }}
            initialValues={INITIAL_VALUES}

            render={({
              handleSubmit,
              submitting,
              invalid,
              values,
              form: { change, reset, batch, mutators }
            }) => {
              this.values = values
              this.guessNextConvoWeak(change, mutators)
              const generatedConvoCount = values.convos.filter(c => ['READY', 'FAILED'].includes(c.convoState)).length
              const annotatedConvoCount = values.convos.filter(c => c.annotated).length
              const convoCount = values.convos.length
              if (valuesToRestore) {
                // const annotatedConvoCountStored = testDataWizardData.values.convos.filter(c => c.annotated).length
                // const convoCountStored = testDataWizardData.values.convos.length
                // activeStep = (convoCount === 0) ? 0 : annotatedConvoCountStored < convoCountStored ? 1 : 2
                this.activeStep = -1
              } else if (mesGuessing) {
                this.activeStep = 1
              } else {
                this.activeStep = (convoCount === 0) ? 0 : annotatedConvoCount < convoCount ? 1 : 2
              }
              return <form onSubmit={handleSubmit}>
                <FormSpy subscription={['values']} onChange={state => {
                  if (state.values.convos && state.values.convos.length > 0) {
                    this.testDataWizardDataChangedInternally = true
                    setTestDataWizardData({ values: state.values, updatedAt: new Date().getTime() })
                  }
                }} />
                <Stepper page={this.activeStep} steps={[
                  {
                    label: 'Choose Test Domain',
                    sublabel: 'and description',
                  },
                  {
                    label: 'Supervised Labeling',
                    sublabel: 'Process',
                  },
                  {
                    label: 'Save Labeled Test Cases',
                    sublabel: 'to Test Set',
                  }
                ]} />
                {this.renderCopyScriptsDialog(reset)}
                {valuesToRestore && this.renderRestoreQuestion({
                  values,
                  change,
                  batch,
                  mutators,
                  reset,
                  submitting,
                  invalid,
                  convoCount,
                  annotatedConvoCount,
                  generatedConvoCount,
                  testDataWizardData
                })}
                {this.activeStep === 0 && !valuesToRestore && this.renderInputData({
                  values,
                  change,
                  batch,
                  mutators,
                  reset,
                  submitting,
                  invalid
                })}
                {(this.activeStep === 1 || this.activeStep === 2) && !valuesToRestore && this.renderAnnotator({
                  values,
                  change,
                  batch,
                  mutators,
                  reset,
                  submitting,
                  invalid,
                  convoCount,
                  annotatedConvoCount,
                  generatedConvoCount
                })}
              </form>
            }}
          />
        </GridItem>
      </GridContainer>
    )
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => {
      return { user: state.token.user, license: state.settings.license, namespace: state.namespace, testDataWizardData: state.testdatawizard }
    },
    { setAlertSuccessMessage, setAlertErrorMessage, setTestDataWizardData, clearTestDataWizardData },
  ),
  graphql(TEST_DATA_WIZARD_SAMPLES, {
    props: ({ data }) => ({
      wizardSamplesData: data,
    })
  }),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
  }),
  withApollo
)(AiTestDataWizardNlu)
