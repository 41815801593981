import React from 'react'
import { connect } from 'react-redux'
import { Query } from 'react-apollo'
import { FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import Field from 'components/Form/OptionalField'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'

import {
  renderTextField,
  renderSelect,
  required
} from 'components/Form/Form'
import { TESTSETS_DROPDOWN_QUERY } from './gql'

import { hasPermission } from 'botium-box-shared/security/permissions'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Divider from 'components/Divider/Divider'
import Text from 'components/Typography/Text.jsx'
import { safeGetNamespaceFilteredList } from '../helper'

class TestSetSelector extends React.Component {
  render() {
    const { user, defaultNewTestSetName } = this.props


    return (<FormSpy subscription={{ values: true }} render={({ values }) => (
      <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
            <Text header paddingBottom>General Action Selections</Text>
              <GridContainer>
                {hasPermission(user, 'TESTSETS_CREATE') &&
                    <GridItem xs={12}>
                      <Field
                        name="newTestSetName"
                        component={renderTextField}
                        label="New Test Set Name"
                        disabled={values.testSetId !== 'new' }
                        validate={values.testSetId !== 'new' ? undefined : required}
                        data-unique="txtTestSetSelectorNewTestSetName"
                        defaultValue={defaultNewTestSetName || ''}
                      />
                    </GridItem>
                  }            
                  <GridItem xs>
                    <Divider text="or" orientation="horizontal"  />
                  </GridItem>
                <GridItem xs={12}>
                  <Query query={TESTSETS_DROPDOWN_QUERY}>
                    {({ loading, error, data }) => {
                      if (loading) {
                        return <LoadingIndicator large />
                      }
                      if (error) {
                        return <ErrorFormat err={error} />
                      }

                      const testsetItems = []
                      if (hasPermission(user, 'TESTSETS_CREATE')) {
                        testsetItems.push({ key: 'new', label: 'Register new Test Set' })
                      }
                      safeGetNamespaceFilteredList(data.testsets, this.props.namespace).forEach(a => {
                        testsetItems.push({ key: a.id, label: a.name })
                      })

                      return (
                        <Field
                          name="testSetId"
                          component={renderSelect}
                          label="Select Test Set"
                          //disabled={!!values.newTestSetName}
                          validate={required}
                          data-unique="selTestSetSelectorTestSetId"
                          items={testsetItems}
                          defaultValueInit={hasPermission(user, 'TESTSETS_CREATE') ? 'new' : ''}
                        />
                      )
                    }}
                  </Query>
                </GridItem>
                <FormSpy subscription={{form: true}} render={({form: {change}}) => (
                  <OnChange name="testSetId">
                    {(value, previous) => {
                      if (value !== 'new') {
                        change('newTestSetName', null)
                      }
                    }}
                  </OnChange>
                )}/>
                {values.testSetId !== 'new' &&
                  <GridItem xs={12}>
                    <Field
                      name="importMode"
                      component={renderSelect}
                      label="How to merge into existing Test Set"
                      validate={required}
                      data-unique="selTestSetSelectorImportMode"
                      items={[
                        { key: 'EXTEND', label: 'Merge with existing repository content' },
                        { key: 'DUMP', label: 'Dump existing repository content first' },
                        { key: 'OVERWRITE', label: 'Overwrite existing repository content' },
                      ]}
                    />
                  </GridItem>
                }
              </GridContainer>
            </GridItem>
          </GridContainer>
      </GridItem>)}/>)
  }
}

export default connect(
  state => ({ user: state.token.user, namespace: state.namespace })
)(TestSetSelector)
