import React from 'react'
import _ from 'lodash'
// apollo
import {Query} from 'react-apollo'
// core components
import ErrorFormat from 'components/Info/ErrorFormat'
import LiveChat from '../Chatbots/LiveChat.jsx'

import { TESTPROJECT_QUERY } from './gql'
import { getRootPath } from '../TestSessions/helper'


class TestProjectLiveChat extends React.Component {

  getRootPath = () => {
    return getRootPath(this.props.location)
  }

  render() {
    const {match} = this.props

    return (<Query
      query={TESTPROJECT_QUERY}
      variables={{id: match.params.projectId}}
      >
      {({loading, error, data}) => {
        if (loading) return null
        if (!loading && data && _.isNil(data.testproject)) return <ErrorFormat err="This TestProject doesn't exist anymore!" />
        if (!loading && data && _.isNil(data.testproject.chatbot)) return <ErrorFormat err="This TestProject isn't connected to a Chatbot!" />
        if (error) return <ErrorFormat err={error}/>

        return <LiveChat testProjectId={data.testproject.id} chatbotId={data.testproject.chatbot.id} cancelUri={`${this.getRootPath()}/projects/view/${data.testproject.id}`}/>
      }}
    </Query>
    )
  }
}

export default TestProjectLiveChat
