import React from 'react'
import { connect } from 'react-redux'
import {NavLink, withRouter} from 'react-router-dom'
import { Handle } from 'react-flow-renderer'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'
import LowPriorityIcon from '@material-ui/icons/LowPriority'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Cancel'
import WarningIcon from '@material-ui/icons/WarningRounded'
// apollo
import {compose, Mutation} from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import Chip from 'components/Chip/Chip'
import AvatarImage from 'components/Avatar/AvatarImage'
import { renderUtteranceText } from 'components/Convo/Shared.jsx'
import ComponentChip from 'components/Convo/ComponentChip.jsx'
import DropdownButton from 'components/Button/DropdownButton'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import ConvosTree from 'components/Convo/ConvosTree'
import Text from 'components/Typography/Text'

import CrawlerAnswerDialog from './CrawlerAnswerDialog'
import StartCrawlerSession from './StartCrawlerSession'
import { setAlertSuccessMessage } from 'actions/alert'
import {
  CRAWLERSESSION_QUERY,
  CRAWLERSESSION_TREE_QUERY,
  CREATE_CRAWLERENDOFCONVERSATIONS,
  DELETE_CRAWLERENDOFCONVERSATIONS} from './gql'

import { setTableSettings } from 'actions/table'
import convosTreeStyle from 'assets/jss/material-dashboard-react/views/convosTreeStyle.jsx'
import defaultAvatar from 'assets/img/botium-connector-default-white.png'
import {hasPermission} from 'botium-box-shared/security/permissions'
import { isDarkmode } from 'components/Darkmode/helper'
import ShowIcon from 'components/Icon/ShowIcon'
import Button from 'components/Button/Button'


const defaultTextStyle = {
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
}
const defaultHandleStyle = {
  pointerEvents: 'none'
}


class CrawlerResultTree extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCrawlerAnswerDialog: false,
      showNavToConvoDialog: false,
      convoStep: {}
    }
  }

  renderCrawlerAnswerDialog() {
    const { crawlerSession } = this.props
    const {showCrawlerAnswerDialog, convoStep} = this.state

    if (convoStep && !_.isNil(convoStep.paths)) {
      return <CrawlerAnswerDialog key={convoStep.paths.join('/')}
        showCrawlerAnswerDialog={showCrawlerAnswerDialog}
        scriptData={convoStep}
        crawlerSessionId={crawlerSession.id}
        onComplete={() => {
          this.setState({showCrawlerAnswerDialog: false, convoStep: {}})
        }}
        onCancel={() => this.setState({showCrawlerAnswerDialog: false, convoStep: {}})}
      />
    }
    return null
  }

  renderNavToConvoDialog() {
    const { crawlerSession, historyView } = this.props
    const { showNavToConvoDialog, navToConvos } = this.state
    return <ConfirmationDialog
      open={showNavToConvoDialog}
      onCancel={() => this.setState({showNavToConvoDialog: false})}
      title={'Navigate to convo script'}
    >
      <form onSubmit={() => {}}>
        <GridContainer>
          {navToConvos && navToConvos.map((convo, index) =>
            <GridItem xs={12} key={`convo_${index}`}>
              <NavLink
                key={`convo_${index}`}
                to={historyView ? `/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/history/${crawlerSession.id}/scriptview/scripts/viewconvo/${convo.sourceTag.crawlerSessionScriptId}`
                  : `/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/scriptview/scripts/viewconvo/${convo.sourceTag.crawlerSessionScriptId}`}
                data-unique={`btnNavTo_${convo.sourceTag.crawlerSessionScriptId}`}
                onClick={() => this.setState({showNavToConvoDialog: false}) }
              >
                {convo.header.name}
              </NavLink>
            </GridItem>)
          }
        </GridContainer>
      </form>
    </ConfirmationDialog>
  }

  render() {
    const { crawlerProject, crawlerSession, historyView, classes, history, user, setAlertSuccessMessage, onRefetch } = this.props

    const BotNode = ({selected, data}, highlighted) => {
      const msg = data.msg
      const convos = data.convos
      const leaf = data.leaf
      const stuck = data.convos.some(c => c.sourceTag.stuck)
      return (
        <div style={{position: 'relative'}}>
          <div className={classes.graphBot + ' ' + (highlighted ? classes.graphBotHighlighted : '') + ' ' + (selected ? classes.graphSelected : '')}>
            <Handle type="target" position="top" className={classes.graphHandle} style={defaultHandleStyle} />
            {msg.hasMenu && !historyView &&
              (<Mutation
                mutation={msg.endOfConversation ? DELETE_CRAWLERENDOFCONVERSATIONS : CREATE_CRAWLERENDOFCONVERSATIONS}
                onCompleted={() => {
                  setAlertSuccessMessage(msg.endOfConversation ? 'Unmarked as end of conversation successful' : 'Marked as end of conversation successful')
                }}
                refetchQueries={() => {
                  return [
                    {
                      query: CRAWLERSESSION_TREE_QUERY,
                      variables: { id: crawlerSession.id },
                    },
                    {
                      query: CRAWLERSESSION_QUERY,
                      variables: { id: crawlerSession.id },
                    },
                  ]
                }}
              >
                {(mutateCrawlerEndOfConversation) => <>
                    <DropdownButton
                    title="Action"
                    className={classes.graphThreedot}
                    dotsSecondary
                    data-unique="ddbtnCrawlerResultTreeThreeDot"
                    items={[
                      {
                        id: 'navtoconvo',
                        name: 'Navigate to the Convo',
                        icon: 'list-ul',
                        onClick: () => {
                          if (convos.length === 1) {
                            history.push(historyView
                              ? `/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/history/${crawlerSession.id}/scriptview/scripts/viewconvo/${convos[0].sourceTag.crawlerSessionScriptId}`
                              : `/testdatawizards/crawlerprojects/view/${crawlerSession.crawlerProject.id}/scriptview/scripts/viewconvo/${convos[0].sourceTag.crawlerSessionScriptId}`)
                          } else {
                            this.setState({
                              showNavToConvoDialog: true,
                              navToConvos: convos
                            })
                          }
                        }
                      },
                      {
                        id: 'userResponses',
                        name: 'Add/Edit user responses',
                        icon: 'comment',
                        disabled: !hasPermission(user, 'CRAWLERPROJECTS_UPDATE'),
                        onClick: () => {
                          const pathSet = new Set()
                          for(const convo of convos) {
                            for(const convoStepIndex of convo.convoStepIndices) {
                              if(convo.sourceTag.paths) {
                                const path = convo.sourceTag.paths['' + convoStepIndex]
                                if (path) {
                                  pathSet.add(path)
                                }
                              }
                            }
                          }

                          this.setState({
                            showCrawlerAnswerDialog: true,
                            convoStep: {
                              crawlerAnswer: msg.crawlerAnswer,
                              scriptId: convos[0] ? convos[0].sourceTag.crawlerSessionScriptId : undefined,
                              paths: [...pathSet]
                            }
                          })
                        }
                      },
                      {
                        id: 'endOfConversation',
                        name: msg.endOfConversation ? 'Unmark as end of conversation' : 'Mark as end of conversation',
                        icon: msg.endOfConversation ? 'undo' : 'hand-paper',
                        disabled: !hasPermission(user, 'CRAWLERSESSIONS_CREATE'),
                        onClick: async () => {
                          const pathSet = new Set()
                          for(const convo of convos) {
                            for(const convoStepIndex of convo.convoStepIndices) {
                              if(convo.sourceTag.paths) {
                                const path = convo.sourceTag.paths['' + convoStepIndex]
                                if (path) {
                                  pathSet.add(path)
                                }
                              }
                            }
                          }

                          await mutateCrawlerEndOfConversation({
                            variables: {
                              crawlerEndOfConversation: {
                                crawlerSessionId: crawlerSession.id,
                                paths: [...pathSet]
                              }
                            }
                          })
                          onRefetch()
                        }
                      }
                    ]}
                  >
                </DropdownButton>
                </>}
              </Mutation>
            )}
            <AvatarImage className={classes.graphAvatarBot} avatar={defaultAvatar} />
            {msg && msg.messageText && msg.not && renderUtteranceText('danger', msg, defaultTextStyle)}
            {msg && msg.messageText && !msg.not && renderUtteranceText('white', msg, defaultTextStyle)}
            {msg && msg.optional &&
              <Tooltip title="Optional" key="optional">
                <Chip avatar={<Avatar><LowPriorityIcon/></Avatar>} label=""/>
              </Tooltip>
            }
            {msg && msg.asserters && msg.asserters.map((a, ai) => {
              if ((a.name === 'BUTTONS' || a.name === 'MEDIA') && a.args && a.args.length > 1) return a.args.map((arg, argi) => <ComponentChip key={`${ai}-${argi}`} component={{ name: a.name, args: [arg]}} truncate={'100%'}/>)
              return <ComponentChip key={ai} component={a} truncate={'100%'}/>
            })}
            {msg && msg.crawlerAnswer && msg.crawlerAnswer.answers.map((answer) => {
              return <Chip key={answer} variant="info" avatar={<Avatar><ShowIcon custom icon="userResponse" /></Avatar>}
                    label={_.truncate(answer, {length: 15})}
              />
            })}
            {msg && msg.logicHooks && msg.logicHooks.map((a, ai) => <ComponentChip key={ai} component={a} truncate={'100%'}/>)}
            {msg.endOfConversation && (
              <Tooltip title="Marked as end of conversation">
                <Chip justIcon variant="info" avatar={<Avatar><ShowIcon custom icon="endConvo" /></Avatar>} />
              </Tooltip>
            )}
            <Handle type="source" position="bottom" className={classes.graphHandle} style={defaultHandleStyle} />
          </div>
          {leaf && msg.err && (
            <div style={{position: 'absolute', left: '45.5%', top: '-24px', background: '#ffffff', borderRadius: '50%', border: '1px solid lightgray' , width: '35px', height: '35px', textAlign: 'center'}}>
              <Tooltip title={msg.err}>
                <Text danger style={{marginTop: '5px'}}>
                  <ErrorIcon/>
                </Text>
              </Tooltip>
            </div>
          )}
          {leaf && !msg.err && !stuck && (
            <div style={{position: 'absolute', left: '45.5%', top: '-24px', background: '#ffffff', borderRadius: '50%', border: '1px solid lightgray'  , width: '35px', height: '35px', textAlign: 'center'}}>
              {msg.endOfConversation && <Tooltip title={'Marked as end of conversation'}>
                <Text success style={{marginTop: '5px'}}>
                  <CheckIcon/>
                </Text>
              </Tooltip>}
              {msg.exitCriteriaMatch && <Tooltip title={'Exit criteria has been reached'}>
                <Text success style={{marginTop: '5px'}}>
                  <CheckIcon/>
                </Text>
              </Tooltip>}
              {!msg.endOfConversation && !msg.exitCriteriaMatch && <Tooltip title={'The maximum conversation depth has been reached'}>
                <Text success style={{marginTop: '5px'}}>
                  <CheckIcon/>
                </Text>
              </Tooltip>}
            </div>
          )}
          {leaf && !msg.err && stuck && msg.crawlerAnswer && msg.crawlerAnswer.answers.length > 0 && (
            <div style={{position: 'absolute', left: '45.5%', top: '-24px', background: '#ffffff', borderRadius: '50%', border: '1px solid lightgray', width: '35px', height: '35px', textAlign: 'center'}}>
              <Tooltip title="The maximum conversation depth has not been reached, but user responses are specified.">
                <Text success style={{marginTop: '5px'}}>
                  <CheckIcon/>
                </Text>
              </Tooltip>
            </div>
          )}
          {leaf && !msg.err && stuck && msg.endOfConversation && (
            <div style={{position: 'absolute', left: '45.5%', top: '-24px', background: '#ffffff', borderRadius: '50%', border: '1px solid lightgray', width: '35px', height: '35px', textAlign: 'center'}}>
              <Tooltip title="The maximum conversation depth has not been reached, but marked as end of conversation.">
                <Text success style={{marginTop: '5px'}}>
                  <CheckIcon/>
                </Text>
              </Tooltip>
            </div>
          )}
          {leaf && !msg.err && stuck && (!msg.crawlerAnswer || msg.crawlerAnswer.answers.length === 0) && !msg.endOfConversation && (
            <div style={{position: 'absolute', left: '45.5%', top: '-24px', background: '#ffffff', borderRadius: '50%', border: '1px solid lightgray', width: '35px', height: '35px', textAlign: 'center'}}>
              <Tooltip title="Specify here user responses for next run or mark as end of conversation.">
                <Text warning style={{marginTop: '5px'}}>
                  <WarningIcon/>
                </Text>
              </Tooltip>
            </div>
          )}
        </div>
      )
    }

    return (<React.Fragment>
      {this.renderCrawlerAnswerDialog()}
      {this.renderNavToConvoDialog()}
      {isDarkmode() ? <ConvosTree
        key={`CrawlerSessionTree_darkmode_${crawlerSession.id}`}
        name={`CrawlerSessionTree_${crawlerSession.id}`}
        tree={this.props.tree}
        botNode={BotNode}
        fileName={`${crawlerSession.name} - flowchart.png`}
        autoHeight
        externalControls={!historyView && <>
          <Button dense secondary
            id="refreshTree"
            data-unique="btnCrawlerProjectRefreshTree"
            onClick={ () => {
              this.props.refetch()
            }}
          >
            <ShowIcon icon="redo" /> Refresh Tree
          </Button>
          <StartCrawlerSession crawlerSession={crawlerSession} crawlerProject={crawlerProject} history={history}/>
        </>}
      /> : <ConvosTree
        key={`CrawlerSessionTree_${crawlerSession.id}`}
        name={`CrawlerSessionTree_${crawlerSession.id}`}
        tree={this.props.tree}
        botNode={BotNode}
        fileName={`${crawlerSession.name} - flowchart.png`}
        autoHeight
        externalControls={!historyView && <>
          <Button dense secondary
            id="refreshTree"
            data-unique="btnCrawlerProjectRefreshTree"
            onClick={ () => {
              this.props.refetch()
            }}
          >
            <ShowIcon icon="redo" /> Refresh Tree
          </Button>
          <StartCrawlerSession crawlerSession={crawlerSession} crawlerProject={crawlerProject} history={history}/>
        </>}
      />}
    </React.Fragment>)
  }
}

export default compose(
  withStyles(convosTreeStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, settings: state.table[window.location.pathname] || {}}),
    { setAlertSuccessMessage, setTableSettings },
  )
)(withRouter(CrawlerResultTree))
