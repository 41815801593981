import React from 'react'
// @material-ui/core components
import Field from 'components/Form/OptionalField'
import { renderFileUpload, CustomRenderField } from 'components/Form/Form'
import AvatarImage from 'components/Avatar/AvatarImage'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'

const renderAvatarSelectorImage = ({
  change,
  click,
  onFileLoaded,
  values,
  input,
  children,
  meta,
  helperText,
  label,
  avatar,
  disabled,
  resetClicked,
  formControlProps,
}) => {
  return <CustomRenderField
    input={input}
    meta={meta}
    formControlProps={formControlProps}
    label={label}
    helperText={helperText}
  >
    <AvatarImage style={{marginTop: '5px'}} onClick={() => {
      if(!disabled) { click() }
    }} avatar={avatar} />
    <RotateLeftIcon style={{marginLeft: '5px'}} onClick={() => {if(!disabled) {resetClicked() }}} />
  </CustomRenderField>
}

class AvatarSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showAvatarImage: true
    }
  }

  render() {

    const { label, values, change, helperText, disabled, ...rest } = this.props

    if(this.state.showAvatarImage) {
      return (
        <Field
          avatar={values.avatar}
          click={() => {this.setState({
            showAvatarImage: false
          })}}
          name="avatar"
          label={label}
          component={renderAvatarSelectorImage}
          helperText={helperText}
          disabled={disabled}
          resetClicked={() => {
            change('avatar', null)
          }}
          data-unique={`${rest['data-unique']}`}
        />
      )
    } else {
      return (
        <Field
          values={values}
          change={() => {
            change('avatar', null)
          }}
          name="avatar-selector"
          component={renderFileUpload}
          accept="image/*"
          label={label}
          disabled={disabled}
          onFileLoaded={(filename, filecontent) => {
            this.setState({
              showAvatarImage: true
            })
            change('avatar', filecontent)
          }}
          data-unique={`${rest['data-unique']}`}
        />
      )
    }
  }
}

export default AvatarSelector
