import React, {PureComponent} from 'react'
import {Query} from 'react-apollo'
import _ from 'lodash'

import {
  LineChart, BarChart, Line, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend
} from 'recharts'

import {withStyles} from '@material-ui/core/styles'

import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import { isDarkmode } from 'components/Darkmode/helper'

import ShowIcon from 'components/Icon/ShowIcon'

import {
  CONFIDENCE_THRESHOLDS_QUERY, INTENT_CONFIDENCE_DISTRIBUTIONS
} from '../../gql'
import {renderProgressOrError} from '../../../helper'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import Text from 'components/Typography/Text'

const INTENT_CONFIDENCE_DISTRIBUTIONS_STEP = 5
const renderConfidenceThresholdChartImpl = (confidenceThresholds) => {

  let maxPrecision = 0
  let maxRecall = 0
  let maxF1 = 0
  const chartData = confidenceThresholds.map((entry) => {
    const {truePositive, falsePositive, falseNegative} = entry
    const totalPredictedPostitive = truePositive + falsePositive
    const totalActivePostitive = truePositive + falseNegative
    if (totalPredictedPostitive === 0) {
      entry.precision = 0
    } else {
      entry.precision = (truePositive / totalPredictedPostitive)
    }
    maxPrecision = Math.max(maxPrecision, entry.precision)

    if (totalActivePostitive === 0) {
      entry.recall = 0
    } else {
      entry.recall = (truePositive / totalActivePostitive)
    }
    maxRecall = Math.max(maxRecall, entry.recall)

    if (entry.precision === 0 || entry.recall === 0) {
      entry.F1 = 0
    } else {
      entry.F1 = (2 * ((entry.precision * entry.recall) / (entry.precision + entry.recall)))
    }

    maxF1 = Math.max(maxF1, entry.F1)

    return entry
  })

  return <>
    <ThresholdChart data={chartData} maxPrecision={maxPrecision} maxRecall={maxRecall} maxF1={maxF1}/>
  </>
}

const renderConfidenceReliabitityChartImpl = (confidenceDistributions) => {

  const stepIndexToConfidenceDistributions = {}
  confidenceDistributions.forEach(d => {
    const base = stepIndexToConfidenceDistributions[d.stepIndex] || {
      countPredictedCorrect: 0,
      countPredictedIncorrect: 0
    }

    stepIndexToConfidenceDistributions[d.stepIndex] = {
      countPredictedCorrect: base.countPredictedCorrect + d.countPredictedCorrect,
      countPredictedIncorrect: base.countPredictedIncorrect + d.countPredictedIncorrect
    }
  })

  const chartData = []
  for (let stepIndex = 0; stepIndex < 100 / INTENT_CONFIDENCE_DISTRIBUTIONS_STEP; stepIndex++) {
    if (stepIndexToConfidenceDistributions[stepIndex]) {
      const countPredictedCorrect = _.get(stepIndexToConfidenceDistributions[stepIndex], ['countPredictedCorrect'], 0)
      const countPredictedIncorrect = _.get(stepIndexToConfidenceDistributions[stepIndex], ['countPredictedIncorrect'], 0)
      const sum = countPredictedCorrect + countPredictedIncorrect

      if (sum) {
        const percentPredictedCorrect = 100 * countPredictedCorrect / sum
        const minConfidence = stepIndex * INTENT_CONFIDENCE_DISTRIBUTIONS_STEP / 100
        const maxConfidence = (stepIndex + 1) * INTENT_CONFIDENCE_DISTRIBUTIONS_STEP / 100
        chartData.push({
          confidence: `${minConfidence} - ${maxConfidence}`,
          percentPredictedCorrect: percentPredictedCorrect,
          percentPredictedIncorrect: 100 - percentPredictedCorrect,
          countPredictedCorrect,
          countPredictedIncorrect,
          count: sum
        })
      } else {
        chartData.push({})
      }
    } else {
      chartData.push({})
    }
  }

  return <>
    <ConfidenceRelevanceChart data={chartData}/>
  </>
}

const ConfidenceThresholdChart = withStyles(dashboardStyle)(({...props}) => {
  const {classes, testSessionId, testSessionOverallStat} = props
  if (!testSessionOverallStat.intentConfidenceSupported) {
    return <Text info>Intent confidence score is not supported</Text>
  }
  return (
    <GridContainer>
      <Query
        query={CONFIDENCE_THRESHOLDS_QUERY}
        variables={{testSessionId}}
      >
        {(threshold) => {
          let progressOrError = renderProgressOrError(threshold)

          let testCases = 0
          let first
          let warnNoTestCases
          let warnNoFalsePositive
          let warnNoImpact
          if (!progressOrError && threshold.data.trainerConfidenceThresholds.length) {
            first = threshold.data.trainerConfidenceThresholds[0]
            testCases = first.truePositive + first.trueNegative + first.falsePositive + first.falseNegative
            warnNoTestCases = !testCases
            if (!warnNoTestCases) {
              warnNoImpact = first.truePositive === threshold.data.trainerConfidenceThresholds[threshold.data.trainerConfidenceThresholds.length - 1].truePositive
              warnNoFalsePositive = !first.falsePositive
            }
          }

          return (<GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <Text header>Confidence Threshold <a
                  data-unique="btnCoachDashboardConfidenceThresholdChartConfidenceThresholdWiki"
                  href="https://support.botium.ai/hc/en-us/articles/4973841863183-Confidence-Threshold"
                  target="help" className={classes.cardHeaderIcon}><ShowIcon icon="question-circle" /></a></Text>
                <Text>
                  <ul>
                    <li>
                      Precision should be monotonic progressive, recall should be monotonic degressive.
                      It is not a strict rule, but extreme divergences may be caused by unbalanced chatbot.
                      Those may be investigated further using
                      the Confidence Score Reliability chart below before using this chart.
                    </li>
                    <li>For general chatbots good Confidence Threshold is the max of the F1 Score</li>
                    <li>
                      For a precise chatbot, like a Banking Bot the Confidence Score must be between the max of the F1 Score,
                      and the max of the Precision, to win Precision but lose Recall.
                    </li>
                    <li>
                      And if a 'dont understand' answer is worse than an incorrect answer, like in a FAQ bot, then it must be between F1 Score, and Recall, to win Recall but lose Precision.
                    </li>
                </ul>                    
                </Text>  
                <Text>Involved test cases: {testCases}</Text>
                <Text>Confidence Threshold is the lowest accepted confidence.</Text>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {progressOrError && <GridItem xs={12}>{progressOrError}</GridItem>}
                  {!progressOrError && <>
                    <GridItem xs={12}>
                      {warnNoTestCases && <Text warning>No data to process for Confidence Threshold</Text>}
                      {warnNoImpact && <Text warning>Confidence Threshold has no impact on responses! (Chart is flat)</Text>}
                      {warnNoFalsePositive && <Text warning>There are no false positive cases! (Increasing Confidence Threshold does not lead to better Precision)</Text>}
                    </GridItem>
                    <GridItem xs={12} className={classes.scoreChartPosition}>
                      {renderConfidenceThresholdChartImpl(threshold.data.trainerConfidenceThresholds)}
                    </GridItem>
                  </>}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>)
        }}
      </Query>
      <Query
        query={INTENT_CONFIDENCE_DISTRIBUTIONS}
        variables={{
          where: {
            trainerSession: {
              testSession: {
                id: testSessionId
              }
            },
            step: INTENT_CONFIDENCE_DISTRIBUTIONS_STEP
          }
        }}
      >
        {(confidenceDistributions) => {
          const progressOrError = renderProgressOrError(confidenceDistributions)

          return (<GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <Text header>Confidence Score Reliability <a
                data-unique="btnCoachDashboardConfidenceThresholdChartConfidenceScoreReliabilityWiki"
                  href="https://support.botium.ai/hc/en-us/articles/4973841863183-Confidence-Threshold"
                  target="help" className={classes.cardHeaderIcon}><ShowIcon icon="question-circle" /></a></Text>
                <Text>Confidence Threshold chart can be used just on balanced chatbot, and balanced testset.</Text>
                <Text>If both of them are balanced, then the percent of the correct responses must be increasing. Some flakiness, even fallbacks, are accepted, but the trend must be increasing.</Text>
              </CardHeader>
              <CardBody>
                <GridContainer>              
                  <GridItem xs={12} className={classes.scoreChartPosition}>
                    {progressOrError && <>{progressOrError}</>}
                    {!progressOrError && renderConfidenceReliabitityChartImpl(confidenceDistributions.data.trainerConfidenceDistributions)}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>)
        }}
      </Query>

    </GridContainer>
  )
})

class ThresholdChart extends PureComponent {

  render() {
    const {
      data,
      maxPrecision,
      maxRecall,
      maxF1
    } = this.props
    const ticksX = []
    for (let i = 0; i <= 100; i += 20) {
      ticksX.push(i / 100)
    }
    const ticksY = []
    for (let i = 0; i <= 100; i += 5) {
      ticksY.push(i / 100)
    }
    const renderColorfulLegendText = (value= 'string') => {    
      return <span style={{ color: isDarkmode() ? '#D5D9DD' : '#2B3E53' }}>{value}</span>
    }
    
    const CustomTooltip = ({ active, payload, label}) => {
      if (active && payload && payload.length) {
        return (
          <div style={{background: '#f5f5f5', padding: '3px 20px'}}>
            <p className="label">{`Confidence Threshold: ${payload[0].payload.confidenceThreshold}`}</p>
            <p className="label">{`F1:  ${payload[0].payload.F1}`}</p> 
            <p className="label">{`Precision: ${payload[0].payload.precision}`}</p> 
            <p className="label">{`Recall: ${payload[0].payload.recall}`}</p> 
          </div>
        )
      }
    
      return null
    }


    return (
      <ResponsiveContainer minWidth={400} minHeight={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis
            domain={[0, 1]}
            dataKey={'confidenceThreshold'}
            ticks={ticksY}
            tick={{fill: isDarkmode() ? '#D5D9DD' : '#2B3E53'}}
          />
          <YAxis yAxisId="left" orientation="left" ticks={ticksX} tick={{fill: isDarkmode() ? '#D5D9DD' : '#2B3E53'}}/>
          <Tooltip content={<CustomTooltip />}  />
          <Legend formatter={renderColorfulLegendText}/>
          <Line
            name="F1"
            type="monotone"
            dataKey="F1"
            stroke="#E70B04"
            activeDot={{r: 8}}
            dot={<CustomDot max={maxF1} field={'F1'} color={'#E70B04'}/>}
            yAxisId="left"
          />
          <Line
            name="precision"
            type="monotone"
            dataKey="precision"
            stroke="#008A00"
            dot={<CustomDot max={maxPrecision} field={'precision'} color={'#008A00'}/>}
            yAxisId="left"
          />
          <Line
            name="recall"
            type="monotone"
            dataKey="recall"
            stroke={isDarkmode() ? '#D5D9DD' : '#2B3E53'}
            dot={<CustomDot max={maxRecall} field={'recall'} color={isDarkmode() ? '#D5D9DD' : '#2B3E53'}/>}
            yAxisId="left"
          />
        </LineChart>
      </ResponsiveContainer>
    )
  }
}

class ConfidenceRelevanceChart extends PureComponent {

  render() {
    const {
      data
    } = this.props
    const ticksX = []
    for (let i = 0; i <= 100; i += 20) {
      ticksX.push(i)
    }
    const renderColorfulLegendText = (value= 'string') => {    
      return <span style={{ color: isDarkmode() ? '#D5D9DD' : '#2B3E53' }}>{value}</span>
    }
    const CustomTooltip = ({ active, payload, label}) => {
      if (active && payload && payload.length) {
        console.log('props', payload)
        return (
          <div style={{background: '#f5f5f5', padding: '3px 20px'}}>
            
            <p className="label">{`Confidence: ${payload[0].payload.confidence}`}</p> 
            <p className="label">{`Correct Responses`}</p>
            <div className="label">{`Count: ${payload[0].payload.countPredictedCorrect}`}</div> 
            <div className="label">{`Percecnt: ${payload[0].payload.percentPredictedCorrect}`}</div> 
            <p className="label">{`Incorrect responses`}</p>
            <div className="label">{`Count: ${payload[0].payload.countPredictedIncorrect}`}</div> 
            <div className="label">{`Percecnt: ${payload[0].payload.percentPredictedIncorrect}`}</div> 
          </div>
        )
      }
    
      return null
    }
    return (
      <ResponsiveContainer minWidth={400} minHeight={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis
            dataKey={'confidence'}
            type={'category'}
            tick={{fill: isDarkmode() ? '#D5D9DD' : '#2B3E53'}}
          />
          <YAxis yAxisId="left" orientation="left" ticks={ticksX} tick={{fill: isDarkmode() ? '#D5D9DD' : '#2B3E53'}} />
          <YAxis yAxisId="right" orientation="right" tick={{fill: isDarkmode() ? '#D5D9DD' : '#2B3E53'}}/>
          <Tooltip cursor={{ fill: isDarkmode() ? '#D5D9DD4D' : '#2B3E534D' }}  content={<CustomTooltip />} />
          <Bar name="Percent of correct responses" dataKey="percentPredictedCorrect" yAxisId="left" stackId="a" fill="#008A00"/>
          <Bar name="Percent of incorrect responses" dataKey="percentPredictedIncorrect" yAxisId="left" stackId="a" fill="#E70B04"/>
          <Bar name="Count of test steps" dataKey="count" yAxisId="right" stackId="b" fill="lightgrey"/>
          <Legend formatter={renderColorfulLegendText}/>
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

class CustomDot extends React.Component {
  render() {
    const {cx, cy, payload, max, field, color} = this.props
    if (payload[field] === max) {
      return <circle cx={cx} cy={cy} r={3} stroke={color} strokeWidth={1} fill="white"/>
    }
    return false
  }
}

export const renderConfidenceThresholdChart = (testSessionId, testSessionOverallStat) => {
  return (<ConfidenceThresholdChart testSessionId={testSessionId} testSessionOverallStat={testSessionOverallStat}/>)
}
