import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { withApollo, Mutation } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import {
  renderPasswordField,
  CustomTextField,
  FormActionsToolbar,
  required,
  CustomCheckbox,
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { destroyStore, setDashboardSettings } from 'actions/dashboard'

import ShowIcon from 'components/Icon/ShowIcon'

import userStyle from 'assets/jss/material-dashboard-react/views/userStyle.jsx'

import { CHANGE_PASSWORD } from './gql'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { setAlertSuccessMessage, setAlertErrorMessage, destroyStore, setDashboardSettings, dashboard, history, client, user } = this.props

    return (
      <GridContainer>
        <GridItem xs={12} md={9} lg={6}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Text header>Profile Settings</Text>
                </GridItem>
                <GridItem xs={3}>
                  <CustomTextField
                    input={{
                      value: user.name,
                      readOnly: true,
                    }}
                    disabled
                    label="Username"
                  />
                </GridItem>
                <GridItem xs={6}>
                  <CustomTextField
                    input={{
                      value: user.email,
                      readOnly: true,
                    }}
                    disabled
                    label="Email Address"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={3} lg={6}></GridItem>
        <GridItem xs={12} md={9} lg={6}>
          <Card>
            <CardBody>
              <Mutation mutation={CHANGE_PASSWORD}>{(changePassword, { loading, error }) => (
                <Form
                  onSubmit={async (values, form) => {
                    try {
                      const res = await changePassword({
                        variables: {
                          oldPassword: values.oldPassword,
                          newPassword: values.newPassword
                        },
                      })
                      if (res.data.changePassword) {
                        setAlertSuccessMessage('Password has been changed. Please login again.')
                        form.initialize({})
                        history.push('/login')
                      }
                    } catch(error) {
                      setAlertErrorMessage('Changing password failed', error)
                    }
                  }}
                  initialValues={{ oldPassword: '', newPassword: '', newPasswordCheck: ''}}
                  validate={values => {
                    const errors = {}
                    if (values.newPassword !== values.newPasswordCheck) {
                      errors.newPasswordCheck = 'Must match'
                    }
                    return errors
                  }}
                  render={({
                    handleSubmit,
                    submitting,
                    invalid
                  }) => (
                    <form  onSubmit={handleSubmit}>
                      <UnsavedFormSpy />
                      <GridContainer>
                        <GridItem xs={12}>
                          <Text header>Change Password</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="oldPassword"
                            component={renderPasswordField}
                            label="Current Password"
                            validate={required}
                            data-unique="pwProfileOldPassword"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6}></GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="newPassword"
                            component={renderPasswordField}
                            label="New Password"
                            validate={required}
                            data-unique="pwProfileNewPassword"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <Field
                            name="newPasswordCheck"
                            component={renderPasswordField}
                            label="Confirm new Password"
                            validate={required}
                            data-unique="pwProfileNewPasswordCheck"
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <FormActionsToolbar rightButtons={
                            <Button
                              type="submit"
                              disabled={submitting || invalid}
                              data-unique="btnProfileChangePassword"
                            >
                              {submitting && <LoadingIndicator alt />}
                              {!submitting && <ShowIcon icon="save" />}
                              Change Password And Login Again
                            </Button>
                          } />
                        </GridItem>
                      </GridContainer>
                    </form>
                  )}
                />
              )}</Mutation>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={3} lg={6}></GridItem>
        <GridItem xs={12} md={9} lg={6}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Text header>Dashboard Settings</Text>
                </GridItem>
                <GridItem xs={12} lg={4}>
                  <CustomCheckbox
                    input={{
                      onChange: e => {
                        setDashboardSettings('hideBotiumNews', !!e.target.checked)
                      },
                      checked: dashboard.hideBotiumNews
                    }}
                    label="Hide Botium News on Dashboard"
                    data-unique="chkProfileHideBotiumNews"
                  />
                </GridItem>
                <GridItem xs={12} lg={4} bottom>
                  <ConfirmationButton
                    confirmationText="Your personal application settings (like table filter criteria, paging etc) will be lost. No configuration settings or test projects are lost. Do you want to continue ?"
                    danger
                    secondary
                    onClick={() => {
                      destroyStore()
                      window.location.pathname = '/'
                    }}
                    data-unique="btnDestroyStore"
                  >
                    <ShowIcon icon="trash" />
                    Reset Application Settings
                  </ConfirmationButton>
                </GridItem>
                <GridItem xs={12} lg={4} bottom>
                  <ConfirmationButton
                    confirmationText="The browser cache will be emptied and all data will be reloaded from the Botium server. Do you want to continue ?"
                    danger
                    secondary
                    onClick={async () => {
                      await client.resetStore()
                      setAlertSuccessMessage('Browser cache empty')
                    }}
                    data-unique="btnResetApolloCache"
                  >
                    <ShowIcon icon="trash" />
                    Reset Browser Cache
                  </ConfirmationButton>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, dashboard: state.dashboard }),
  { setAlertSuccessMessage, setAlertErrorMessage, destroyStore, setDashboardSettings },
)(withStyles(userStyle)(withApollo(Profile)))
