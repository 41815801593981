import React from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from 'components/Menu/MenuItem'
import Button from './Button'
import { CustomTextField } from 'components/Form/Form'
import ShowIcon from 'components/Icon/ShowIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Text from 'components/Typography/Text'
import { withStyles } from '@material-ui/core'
import AvatarImage from 'components/Avatar/AvatarImage'
import Divider from 'components/Divider/Divider'
import classNames from 'classnames'
import Tooltip from 'components/Tooltip/Tooltip'

import selectStyle from 'assets/jss/material-dashboard-react/components/selectStyle'

const style = theme => ({
  dividerItem: {
    '&,&:hover,&:focus': {
      backgroundColor: 'transparent !important',
      cursor: 'default !important',
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  ...selectStyle(theme)
})

class DropdownButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuAnchorEl: null,
      inputValue: ''
    }
    this.buttonRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        menuAnchorEl: this.props.open ? this.buttonRef.current : null
      })
    }
  }

  componentDidMount() {
    if (this.props.open) {
      this.setState({
        menuAnchorEl: this.buttonRef.current
      })
    }
  }

  render () {
    const { children, items, header, showFilter, filterMargin, chatbot, classes, onClick, onClose, tooltipTitle, ...rest } = this.props
    const { menuAnchorEl, inputValue } = this.state

    let selectedItems = items
    if (showFilter && inputValue) {
      selectedItems = items.filter(i => i.name && i.name.toLowerCase().indexOf(inputValue) >= 0)
    }

    return (<React.Fragment>
      <Tooltip title={tooltipTitle}><Button buttonRef={this.buttonRef} onClick={(event) => {
        this.setState({ menuAnchorEl: event.currentTarget })
        if(onClick) onClick()
      }} disabled={!items || items.length === 0} dropdown {...rest}>
        {children}
      </Button></Tooltip>
      <Menu
        classes={{
          paper: classes.paper
        }}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          this.setState({ menuAnchorEl: null })
          if (onClose) onClose()
        }}>
        {header &&
          <MenuItem
            style={{background: 'transparent'}}
            disableTouchRipple={true}
            value={''}
          >
            {header}
          </MenuItem>
        }
        {showFilter &&
          <MenuItem
            style={{background: 'transparent'}}
            disableTouchRipple={true}
            key={'filterInput'}
            value={''}
            className={classes.inputposition}
          >
            <CustomTextField
              disableBorder
              input={{
                name: 'columnFilter',
                style: selectedItems.length > 0 && selectedItems[0].icon ? { marginLeft: 15, fontSize: 12 } : { fontSize: 12 },
                value: inputValue,
                onChange: (e) => this.setState({ inputValue: e.target.value.toLowerCase() })
              }}
              placeholder="Filter..."
              data-unique={rest['data-unique'] ? `${rest['data-unique']}Filter` : 'ddbFilter'}
            />
          </MenuItem>
        }
        {selectedItems && selectedItems.length > 0 && selectedItems.filter(t => t && t.id).map(t => {
          if (t.divider) {
            return <MenuItem key={t.id} data-unique={t.dataUnique} className={classes.dividerItem}>
              <Divider />
            </MenuItem>
          }
          if (t.href && !t.disabled) {
            return (
              <a key={t.id} href={t.href} rel="noopener noreferrer" target="_blank" >
                <MenuItem key={t.id} data-unique={t.dataUnique} className={classNames({
                [classes.selectMenuItem]: true,
                [classes.selectMenuItemWithImage]: t.chatbot || t.icon
              })}>
                <GridContainer>
                  {t.icon && <GridItem>
                    <Text primary><ShowIcon icon={t.icon} /></Text>
                  </GridItem>}
                  {t.chatbot && <GridItem>
                    <Text primary><AvatarImage avatar={t.chatbot.avatar} containermode={t.chatbot.containermode} chatbotId={t.chatbot.id}/></Text>
                  </GridItem>}
                  <GridItem>
                    <Text primary bold>{t.name}</Text>
                  </GridItem>
                  <GridItem right flexAuto>
                    <Text primary>{t.secondaryText}</Text>
                  </GridItem>
                </GridContainer>
                </MenuItem>
              </a>
            )
          } else {
            return (
              <MenuItem key={t.id} disabled={!!t.disabled} data-unique={t.dataUnique} data-click="false" onClick={() => {
                this.setState({ menuAnchorEl: null })
                t.onClick && t.onClick()
              }} className={classNames({
                [classes.selectMenuItem]: true,
                [classes.selectMenuItemWithImage]: t.chatbot || t.icon
              })}>
                <GridContainer fullWidth autoHeight noMargin>
                  {t.icon && <GridItem middle>
                    <Text primary><ShowIcon icon={t.icon} /></Text>
                  </GridItem>}
                  {t.chatbot && <GridItem middle>
                    <Text primary><AvatarImage avatar={t.chatbot.avatar} containermode={t.chatbot.containermode} chatbotId={t.chatbot.id}/></Text>
                  </GridItem>}
                  <GridItem middle>
                    <Text primary bold>{t.name}</Text>
                  </GridItem>
                  <GridItem right flexAuto middle>
                    <Text primary>{t.secondaryText}</Text>
                  </GridItem>
                </GridContainer>
              </MenuItem>
            )
          }
        })}
      </Menu>
    </React.Fragment>)
  }
}

DropdownButton.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      href: PropTypes.string,
      name: PropTypes.string,
      divider: PropTypes.bool,
      secondaryText: PropTypes.string,
      icon: PropTypes.any,
      onClick: PropTypes.func,
      dataUnique: PropTypes.string,
    })
  ).isRequired,
  header: PropTypes.any,
  showFilter: PropTypes.bool,
  filterMargin: PropTypes.bool
}


export default withStyles(style)(DropdownButton)