import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import Avatar from '@material-ui/core/Avatar'
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
// apollo
import { Query, Mutation } from 'react-apollo'
// core components
import Tooltip from 'components/Tooltip/Tooltip'
import Chip from 'components/Chip/Chip'
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import DropdownButton from 'components/Button/DropdownButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import {
  renderTextField,
  renderTextArea,
  renderSelect,
  renderAutoSuggest,
  renderTagField,
  renderCodeArea,
  json,
  prettyPrintJson,
  required,
  FormActionsToolbar
} from 'components/Form/Form'
import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import { getDeviceProviderIcon } from './DeviceProvider.jsx'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import ShowIcon from 'components/Icon/ShowIcon'
import { faFirefox, faEdge, faApple, faInternetExplorer, faAndroid, faSafari, faChrome, faWindows } from '@fortawesome/free-brands-svg-icons'
import { faMobile, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import QueryStatus from 'components/Info/QueryStatus'

import devicesetsStyle from 'assets/jss/material-dashboard-react/views/devicesetsStyle.jsx'

import {
  DEVICESETS_QUERY,
  DEVICESETS_DROPDOWN_QUERY,
  DEVICESET_QUERY,
  DEVICESET_DROPDOWN_QUERY,
  CREATE_DEVICESET,
  UPDATE_DEVICESET,
  DELETE_DEVICESET,
} from './gql'
import { TAGS_QUERY, DEVICEPROVIDERS_QUERY } from '../Settings/gql'

import { hasPermission, hasAnyPermission } from 'botium-box-shared/security/permissions'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'

class DeviceSet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDeviceName: null,
      showDeviceIndex: null
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['DEVICESETS_CREATE', 'DEVICESETS_UPDATE'])
  }

  renderDeviceProviderSelection(deviceset) {
    return (
      <Query query={DEVICEPROVIDERS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingIndicator/>
          }
          if (error) {
            return <ErrorFormat err={error} />
          }

          return (
            <Field
              name="provider.id"
              component={renderSelect}
              label="Device Provider"
              formControlProps={{ disabled: deviceset.id ? true : false }}
              validate={required}
              disabled={!this.hasWritePermission()}
              data-unique="selDeviceSetProviderId"
              items={data &&
                data.deviceproviders &&
                data.deviceproviders.map(dp => {
                  return {
                    key: dp.id,
                    label: dp.name,
                    image: getDeviceProviderIcon(dp.type)
                  }})
              }
            />
          )
        }}
      </Query>
    )
  }

  _getIconForDevice(name, type) {
    const lname = (name && name.toLowerCase()) || ''
    if (lname.indexOf('firefox') >= 0) {
      return faFirefox
    } else if (lname.indexOf('chrome') >= 0) {
      return faChrome
    } else if (lname.indexOf('internet explorer') >= 0) {
      return faInternetExplorer
    } else if (lname.indexOf('safari') >= 0) {
      return faSafari
    } else if (lname.indexOf('android') >= 0) {
      return faAndroid
    } else if (lname.indexOf('google') >= 0) {
      return faAndroid
    } else if (lname.indexOf('samsung') >= 0) {
      return faAndroid
    } else if (lname.indexOf('edge') >= 0) {
      return faEdge
    } else if (lname.indexOf('iphone') >= 0) {
      return faApple
    } else if (lname.indexOf('ipad') >= 0) {
      return faApple
    } else if (type === 'DESKTOP') {
      return faWindows
    } else if (type === 'MOBILEBROWSER') {
      return faMobile
    } else if (type === 'MOBILEAPP') {
      return faMobileAlt
    } else {
      return 'question'
    }
  }

  renderDeviceSelection(deviceset, provider) {
    if (!provider || !provider.id) return

    return (
      <Query
        query={DEVICESET_DROPDOWN_QUERY}
        variables={{ provider: provider.id }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingIndicator/>
          }
          if (error) {
            return <ErrorFormat err={error} />
          }

          const tooltipFunc = (s) => {
            if (s.capabilities) {
              try {
                return JSON.stringify(JSON.parse(s.capabilities), null, 2)
              } catch (err) {
                return `${s.capabilities}`
              }
            }
            return null
          }

          return (
            <Field
              name="devices"
              component={renderAutoSuggest}
              label="Select Device(s)"
              suggestions={data && data.availabledevices.map(device => {
                if (device.value) {
                  try {
                    const value = JSON.parse(device.value)

                    return {
                      name: device.name,
                      type: value.type,
                      capabilities: JSON.stringify(value.capabilities),
                    }
                  } catch (err) {
                    console.warn('Failed to parse device capabilities', device.value, err)
                    return null
                  }
                } else {
                  return {
                    name: device.name,
                    type: device.type,
                    capabilities: device.capabilities,
                  }
                }
              }).filter(d => d)}
              numsuggestions={30}
              keyFunc={s => s.name}
              labelFunc={s => s.name}
              iconFunc={s => <ShowIcon icon={this._getIconForDevice(s.name, s.type)} />}
              tooltipFunc={tooltipFunc}
              disabled={!this.hasWritePermission()}
              onClick={(item, index) => this.setState({ showDevice: item, showDeviceIndex: index })}
              data-unique="asDeviceSetSelection"
            />
          )
        }}
      </Query>
    )
  }

  renderForm(deviceset) {
    const { showDeviceName } = this.state
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady, user } = this.props

    return (
      <Mutation
        mutation={deviceset.id ? UPDATE_DEVICESET : CREATE_DEVICESET}
        refetchQueries={[
          {
            query: DEVICESETS_QUERY,
          },
          {
            query: DEVICESETS_DROPDOWN_QUERY,
          },
          {
            query: TAGS_QUERY
          }
        ]}
      >
        {(mutateDeviceSet, { loading, error }) => (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={async (values, form) => {
              const formPost = {
                name: values.name,
                description: values.description || null,
                tags: {
                  set: values.tags,
                },
                provider: {
                  connect: { id: values.provider.id },
                },
                devices: {
                  create:
                    values.devices &&
                    values.devices.map(device => ({
                      name: device.name,
                      type: device.type,
                      capabilities: device.capabilities,
                    }))
                },
              }
              if (deviceset.id) {
                formPost.devices.delete = deviceset.devices.map(d => ({
                  id: d.id,
                }))
              }

              if (deviceset.id) {
                try {
                  const res = await mutateDeviceSet({
                    variables: {
                      id: deviceset.id,
                      deviceSet: formPost,
                    },
                  })
                  form.initialize(res.data.updateDeviceSet)
                  setAlertSuccessMessage('Device Set updated')
                  onReady(deviceset.id)
                } catch(error) {
                  setAlertErrorMessage('Device Set update failed', error)
                }
              } else {
                try {
                  const res = await mutateDeviceSet({
                    variables: {
                      deviceSet: formPost,
                    },
                  })
                  form.initialize(res.data.createDeviceSet)
                  setAlertSuccessMessage('Device Set registered')
                  onReady(res.data.createDeviceSet.id)
                } catch(error) {
                  setAlertErrorMessage('Device Set registration failed', error)
                }
              }
            }}
            initialValues={deviceset}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values,
              errors
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Device Set Name"
                      validate={required}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtDeviceSetName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="tags"
                      component={renderTagField}
                      label="Tags"
                      disabled={!this.hasWritePermission()}
                      data-unique="tagDeviceSetTags"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="description"
                      component={renderTextArea}
                      label="Device Set Description"
                      rows={3}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtDeviceSetDescription"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    {this.renderDeviceProviderSelection(deviceset)}
                  </GridItem>
                  <GridItem xs={12}>
                    <FieldArray name="devices">{({ fields }) => <GridContainer>
                      <GridItem xs={12}>
                        {fields.map((name, index) => {
                          const device = values.devices[index]
                          let tooltip = device.capabilities || null
                          if (device.capabilities) {
                            try {
                              tooltip = JSON.stringify(JSON.parse(device.capabilities), null, 2)
                            } catch (err) {}
                          }

                          return <Chip
                            avatar={<Avatar><ShowIcon icon={this._getIconForDevice(device.name, device.type)}/></Avatar>}
                            tooltip={tooltip}
                            key={index}
                            data-unique={`chip_${device.name}`}
                            tabIndex={-1}
                            label={device.name}
                            onDelete={() => fields.remove(index)}
                            onClick={() => this.setState({ showDeviceName: name, showDeviceIndex: index })}
                          />
                        })}
                      </GridItem>
                      <GridItem xs={12}>
                        {values.provider && values.provider.id &&
                          <Query
                            query={DEVICESET_DROPDOWN_QUERY}
                            variables={{ provider: values.provider.id }}
                          >
                            {({ loading, error, data }) => {
                              if (loading) return <LoadingIndicator />
                              if (error) return <ErrorFormat err={error} />

                              const alldevices = (data && data.availabledevices && data.availabledevices.map(device => {
                                try {
                                  const value = JSON.parse(device.value)
                                  return {
                                    name: device.name,
                                    type: value.type,
                                    capabilities: JSON.stringify(value.capabilities),
                                  }
                                } catch (err) {
                                  console.warn('Failed to parse device capabilities', device.value, err)
                                  return null
                                }                                  
                              }).filter(d => d)) || []

                              return <Tooltip title={alldevices.length > 0 ? 'Select Browser/Device from Device Provider List' : 'Device Provider List empty'}><DropdownButton
                                items={alldevices.map(device => ({
                                  id: device.name,
                                  name: device.name,
                                  icon: <ShowIcon icon={this._getIconForDevice(device.name, device.type)} />,
                                  onClick: () => fields.push(device)
                                }))}
                                showFilter
                                disabled={!this.hasWritePermission() || alldevices.length === 0}
                                justIcon
                                round
                              >
                                <ShowIcon icon="mouse-pointer" />
                              </DropdownButton></Tooltip>
                            }}
                          </Query>
                        }
                        <Tooltip title="Add Custom Browser/Device Configuration"><Button
                          disabled={!this.hasWritePermission()}
                          justIcon
                          onClick={() => {
                            const newDeviceIndex = fields.length || 0
                            fields.push({
                              name: 'Any Android 5.1 (Sample)',
                              type: 'MOBILEAPP',
                              capabilities: JSON.stringify({
                                platformName: 'Android',
                                platformVersion: '5.1'
                              })
                            })
                            this.setState({ showDeviceName: `devices[${newDeviceIndex}]`, showDeviceIndex: newDeviceIndex })
                          }}
                          round
                        >
                          <ShowIcon icon="plus" />
                        </Button></Tooltip>
                        {showDeviceName &&
                          <ConfirmationDialog
                            open={!!showDeviceName}
                            onOk={() => this.setState({ showDeviceName: null, showDeviceIndex: null })}
                            title="Edit Selenium/Appium Desired Capabilities"
                            data-unique="dlgEditDevice"
                            okDisabled={errors && Object.keys(errors).findIndex(k => k.startsWith('devices')) >= 0}
                            closeDisabled
                          >
                            <GridContainer>
                              <GridItem xs={12} sm={6}>
                                <Field
                                  name={`${showDeviceName}.name`}
                                  component={renderTextField}
                                  label="Device Name"
                                  validate={required}
                                  disabled={!this.hasWritePermission()}
                                  data-unique="txtDeviceName"
                                />
                              </GridItem>
                              <GridItem xs={12} sm={6}>
                                <Field
                                  name={`${showDeviceName}.type`}
                                  component={renderSelect}
                                  label="Device Type"
                                  disabled={!this.hasWritePermission()}
                                  validate={required}
                                  data-unique="selDeviceType"
                                  items={[
                                    {
                                      key: 'DESKTOP',
                                      label: 'Desktop Browser'
                                    },
                                    {
                                      key: 'MOBILEBROWSER',
                                      label: 'Mobile Browser'
                                    },
                                    {
                                      key: 'MOBILEAPP',
                                      label: 'Smartphone App'
                                    }
                                  ]}
                                />
                              </GridItem>
                              <GridItem xs={12}>
                                <Field
                                  name={`${showDeviceName}.capabilities`}
                                  component={renderCodeArea}
                                  options={{ mode: 'application/json' }}
                                  label="Selenium/Appium Desired Capabilities"
                                  codeFormat={prettyPrintJson}
                                  validate={json}
                                  disabled={!this.hasWritePermission()}
                                  data-unique="codeDeviceCapabilities"
                                />
                              </GridItem>
                            </GridContainer>
                          </ConfirmationDialog>
                        }
                      </GridItem>
                    </GridContainer>}</FieldArray>
                  </GridItem>
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtons={<>
                        {deviceset.id && hasPermission(user, 'DEVICESETS_DELETE') && (
                          <Mutation
                            mutation={DELETE_DEVICESET}
                            onCompleted={data => {
                              setAlertSuccessMessage('Device Set deleted')
                              onReady()
                            }}
                            onError={error => {
                              setAlertErrorMessage(
                                'Device Set deletion failed',
                                error,
                              )
                            }}
                            refetchQueries={[
                              {
                                query: DEVICESETS_QUERY,
                              },
                              {
                                query: DEVICESETS_DROPDOWN_QUERY,
                              }
                            ]}
                          >
                            {(deleteDeviceSet, { loading, error }) => (
                              <ConfirmationButton
                                confirmationText={`In case the Device Set ${deviceset.name} is already linked to Test Projects, those Test Projects will fail. Are you sure you want to delete this Device Set including all configuration ?`}
                                requireCheck={true}
                                onClick={() => {
                                  deleteDeviceSet({
                                    variables: { id: deviceset.id },
                                  })
                                }}
                                secondary
                                danger
                              >
                                <ShowIcon icon="trash" />
                                Delete
                              </ConfirmationButton>
                            )}
                          </Mutation>
                        )}                      
                      </>}
                      rightButtons={<>
                        {this.hasWritePermission() &&
                          <Button
                            type="submit"
                            disabled={submitting || invalid}
                          >
                            {submitting && <LoadingIndicator alt />}
                            {!submitting && <ShowIcon icon="save" />}                  
                            Save
                          </Button>
                        }                      
                      </>}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={DEVICESET_QUERY} variables={{ id }}>
              {(queryResult) => <QueryStatus {...queryResult} query="deviceset">{(data) => this.renderForm(data.deviceset)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({})}
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user }),
  { setAlertSuccessMessage, setAlertErrorMessage },
)(withStyles(devicesetsStyle)(DeviceSet))
