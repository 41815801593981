import _ from 'lodash'


export const isPostwoman = (asJson) => {
  if (asJson && _.isArray(asJson) && !_.isNil(asJson[0].requests)) {
    return true
  }
  return false
}

export const importPostwoman = (asJson) => {
  const fields = {}

  if (!asJson || !_.isArray(asJson) || !asJson[0].requests || asJson[0].requests.length <= 0 || !asJson[0].requests[0].url) {
    throw new Error('No request found in this file or not a Postwoman file.')
  }

  const request = asJson[0].requests[0]

  let url = request.url
  if (request.path) url = url + request.path

  if (request.auth === 'Basic Auth') {
    const prefix = url.startsWith('https://') ? 'https://' : 'http://'
    url = `${prefix}${request.httpUser || ''}:${request.httpPassword || ''}@${url.substr(prefix.length)}`
  }
  fields['simpleRestConnector.url'] = url

  if (request.method) {
    fields['simpleRestConnector.method'] = request.method
  }

  const headers = {}
  if (request.headers && request.headers.length > 0) {
    request.headers.forEach(h => headers[h.key] = h.value)
  }
  if (request.auth === 'Bearer Token' && request.bearerToken) {
    headers['Authorization'] = `Bearer ${request.bearerToken}`
  }
  if (Object.keys(headers).length > 0) {
    fields['simpleRestConnector.headersTemplate'] = JSON.stringify(headers, null, 2)
  }

  if (request.rawInput && request.rawParams) {
    fields['simpleRestConnector.bodyTemplate'] = request.rawParams
  }
  if (!request.rawInput && request.bodyParams && request.bodyParams.length) {
    const body = request.bodyParams.reduce((b, c) => {
      b[c.key] = c.value
      return b
    }, {})
    fields['simpleRestConnector.bodyTemplate'] = JSON.stringify(body, null, 2)
  }

  return fields
}

export const isPostman = (asJson) => {
  if (asJson && asJson.item) {
    return true
  }
  return false
}

export const importPostman = (asJson) => {
  const fields = {}

  if (!asJson || !asJson.item || asJson.item.length <= 0 || !asJson.item[0].request) {
    throw new Error('No request found in this file or not a Postman file.')
  }

  const request = asJson.item[0].request

  let url = request.url.raw
  if (request.auth && request.auth.type === 'basic' && request.auth.basic) {
    const prefix = url.startsWith('https://') ? 'https://' : 'http://'
    const username = request.auth.basic.find(b => b.key === 'username') || {}
    const password = request.auth.basic.find(b => b.key === 'password') || {}

    url = `${prefix}${username.value || ''}:${password.value || ''}@${url.substr(prefix.length)}`
  }
  fields['simpleRestConnector.url'] = url

  if (request.method) {
    fields['simpleRestConnector.method'] = request.method
  }

  const headers = {}
  if (request.header && request.header.length > 0) {
    request.header.forEach(h => headers[h.key] = h.value)
  }
  if (request.auth && request.auth.type === 'bearer' && request.auth.bearer) {
    const token = request.auth.bearer.find(b => b.key === 'token')
    if (token && token.value) {
      headers['Authorization'] = `Bearer ${token.value}`
    }
  }
  if (Object.keys(headers).length > 0) {
    fields['simpleRestConnector.headersTemplate'] = JSON.stringify(headers, null, 2)
  }

  if (request.body && request.body.mode === 'raw' && request.body.raw) {
    fields['simpleRestConnector.bodyTemplate'] = request.body.raw
  }

  return fields
}
