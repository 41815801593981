import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {UTTERANCES_JUST_EXPECTED_ENTITIES} from '../gql'
import {renderProgressOrError} from '../../helper'

class JustExpectedEntities extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTable() {
    const {utterancesData, match} = this.props
    const {testSessionId} = match.params
    let progressOrError = renderProgressOrError(utterancesData)
    if (progressOrError) {
      return progressOrError
    }

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Utterance', 'Expected Entity', 'Predicted Entity (Confidence)']}
        tableData={_.sortBy(utterancesData.trainerUtterances, (entry) => entry.utterance).map(entry => {
          return [
            {
              value: entry.utterance,
              href: `/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/utterance/${encodeURIComponent(entry.utterance)}`
            },
            entry.entity.expected.map(entity => ({
              value: entity.name,
              href: `/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/entityconfidence/entityname/${encodeURIComponent(entity.name)}`
            })),
            entry.entity.actual.length ? entry.entity.actual.map(entity => ({
              value: `${entity.name}:${entity.value} (${!_.isNil(entity.confidence) ? entity.confidence.toFixed(4) : 'N/A'})`,
              href: `/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/entityconfidence/actualentityname/${encodeURIComponent(entity.name)}`
            })) : 'N/A'
          ]
        })}
      />
    )
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{this.renderTable()}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(UTTERANCES_JUST_EXPECTED_ENTITIES, {
    props: ({data}) => ({
      utterancesData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId
        },
      }
    }
  })
)(JustExpectedEntities)
