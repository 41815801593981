import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import Tooltip from 'components/Tooltip/Tooltip'
import Chip from 'components/Chip/Chip'
import CrawlerSessionProgress from './CrawlerSessionProgress'

import crawlerprojectsStyle from 'assets/jss/material-dashboard-react/views/crawlerprojectsStyle.jsx'

class CrawlerSessionStats extends React.Component {

  render () {
    const { crawlerSession, type } = this.props
    return (
      <CrawlerSessionProgress {...(this.props)} crawlerSession={crawlerSession} key={`convos_${crawlerSession && crawlerSession.id}`}>
        {
          ({crawlerSessionProgress}) => {
            let chartData = {}
            if (crawlerSessionProgress && crawlerSessionProgress.chartData) {
              chartData = JSON.parse(crawlerSessionProgress.chartData)
            }
            if(type === 'CONVOS') {
              return <Tooltip title={`${chartData.convoCount || '0'} Convos`} key={'convos'}>
                <Chip variant="convo" label={chartData.convoCount || 0}/>
              </Tooltip>
            } else if(type === 'UTTERANCES') {
              return <Tooltip title={`${chartData.utteranceCount || '0'} Utterances`} key={'utterances'}>
                <Chip variant="utterance" label={chartData.utteranceCount || 0}/>
              </Tooltip>
            } else if(type === 'WARNINGS') {
              return <Tooltip title={`${chartData.warningConvoCount || '0'} Convos with warning`} key={'warnings'}>
                <Chip variant="warning" label={chartData.warningConvoCount || 0}/>
              </Tooltip>
            } else if(type === 'ERRORS') {
              return <Tooltip title={`${chartData.failedConvoCount || '0'} Failed Convos`} key={'errors'}>
                <Chip variant="error" label={chartData.failedConvoCount || 0}/>
              </Tooltip>
            }
          }
        }
      </CrawlerSessionProgress>)
  }
}

export default withStyles(crawlerprojectsStyle)(CrawlerSessionStats)
