import React from 'react'
import moment from 'moment'
import DateFormat from './DateFormat'
import Tooltip from '../Tooltip/Tooltip'
import StatsText from 'components/Stats/StatsText'

function LastChange({ ...props }) {
  const { createdAt, createdBy, updatedAt, updatedBy } = props

  const tt = []
  if (createdAt && createdBy && createdBy.name) {
    tt.push(`Created at ${moment(createdAt).format('lll')} by ${createdBy.name}`)
  } else if (createdAt) {
    tt.push(`Created at ${moment(createdAt).format('lll')}`)
  }
  if (updatedAt && updatedBy && updatedBy.name) {
    tt.push(`Last Change at ${moment(updatedAt).format('lll')} by ${updatedBy.name}`)
  } else if (updatedAt) {
    tt.push(`Last Change at ${moment(updatedAt).format('lll')}`)
  }

  if (updatedAt && updatedBy && updatedBy.name) {
    return <Tooltip title={tt.join(' | ')}><StatsText datePosition primarySuffix={<DateFormat  fromNow>{updatedAt}</DateFormat>} secondaryText={`by ${updatedBy.name}`} /></Tooltip>
  } else if (updatedAt) {
    return <Tooltip title={tt.join(' | ')}><StatsText  primarySuffix={<DateFormat fromNow>{updatedAt}</DateFormat>} /></Tooltip>
  } else {
    return null
  }
}

export default LastChange
