import React from 'react'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import { withRouter } from 'react-router-dom'
// apollo
import { Mutation, compose, graphql, withApollo } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/Button/Button'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import Field from 'components/Form/OptionalField'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { LanguageDisplayName } from 'components/Icon/FlagIcon'
import { getConnector } from 'actions/settings'

import ShowIcon from 'components/Icon/ShowIcon'
import TestProjectsEmbeddedTable from './TestProjectsEmbeddedTable.jsx'
import {
  renderTextField,
  renderSelect,
  required,
  composeValidators,
  FormActionsToolbar,
  renderAutoSuggest,
  renderCheckbox,
  renderNamespaceField,
  cron
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import testprojectsStyle from 'assets/jss/material-dashboard-react/views/testprojectsStyle.jsx'

import {
  QUICKSTART_TESTPROJECT, RefetchTestProjectQueries,
} from './gql'
import { CHATBOTS_DROPDOWN_QUERY } from '../Chatbots/gql'
import { TESTSETS_DROPDOWN_QUERY, RefetchTestSetQueries } from '../TestSets/gql'
import { DEVICESETS_DROPDOWN_QUERY } from '../Settings/gql'
import { validateTestProjectCodeUnique } from './validators'
import { safeGetNamespaceFilteredList } from '../helper'

import { isLicenseDeviceSetsSupported } from 'botium-box-shared/security/licenseSupport'
import { hasPermission } from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text.jsx'
import SelectableCard from '../../components/Card/SelectableCard'
import Divider from '../../components/Divider/Divider'
import PerformanceTestSessionRegisterForm, {
  buildPerformanceTestStartOptions,
  calculateRepeatPerTick,
} from '../PerformanceTestSessions/PerformanceTestSessionRegisterForm'
import Chip from '../../components/Chip/Chip'
import { projectTypeFlagsToConnectorFeature, urlToProjectTypeFlags, performanceTestTypes } from './helper'
const { PERFORMANCE_TEST_MODE_LOAD_TEST, PERFORMANCE_TEST_MODE_STRESS_TEST, PERFORMANCE_TEST_MODE_ADVANCED_TEST } = performanceTestTypes


class TestProjects extends React.Component {
  constructor(props) {
    super(props)
    const defaultPerformanceTestMode = PERFORMANCE_TEST_MODE_LOAD_TEST
    // some other performance test session to get the parameters from.
    const performanceTestSession = null
    const initialValuesPerformanceTest = Object.assign({}, /*testproject,*/ {
      performanceTest_Type: defaultPerformanceTestMode,
      performanceTest_parallelJobCount: (performanceTestSession && performanceTestSession.parallelJobCount) || 1,
      performanceTest_tickRepeatInitial: (performanceTestSession && performanceTestSession.tickRepeatInitial) || 5,
      // performanceTest_tickRepeatMax: (performanceTestSession && performanceTestSession.tickRepeatPerTick) || 50,
      performanceTest_tickRepeatMax: (performanceTestSession && Math.ceil((performanceTestSession.tickRepeatInitial + ((performanceTestSession.tickMaxTime / 60000 * 60 / (performanceTestSession.tickTime / 1000) - 1) * performanceTestSession.tickRepeatPerTick)))) || 50,
      performanceTest_tickMaxTimeMinutes: (performanceTestSession && performanceTestSession.tickMaxTime / 60000) || 1,
      performanceTest_tickTimeSeconds: (performanceTestSession && performanceTestSession.tickTime / 1000) || 10,
      performanceTest_shareContainer: performanceTestSession ? !!performanceTestSession.shareContainer : true,
      performanceTest_simpleConvo: performanceTestSession ? !!performanceTestSession.simpleConvo : true,
      performanceTest_waitForBotTimeoutSeconds: (performanceTestSession && performanceTestSession.waitForBotTimeout / 1000) || 2,
      performanceTest_requiredPercentOfSuccesfulUsers: (performanceTestSession && performanceTestSession.requiredPercentOfSuccesfulUsers) || 80,
      performanceTest_notification: (performanceTestSession && performanceTestSession.notification) || 'PROJECT_DEFAULT',
      performanceTest_detailedReporting: performanceTestSession ? !!performanceTestSession.detailedReporting : false
    })
    initialValuesPerformanceTest.performanceTest_tickRepeatPerTick = calculateRepeatPerTick(
      initialValuesPerformanceTest.performanceTest_tickRepeatInitial,
      initialValuesPerformanceTest.performanceTest_tickRepeatMax,
      initialValuesPerformanceTest.performanceTest_tickMaxTimeMinutes,
      initialValuesPerformanceTest.performanceTest_tickTimeSeconds
    )

    this.state = {
      newProjectExpanded: false,
      testProjectsEmbeddedTableKey: 0,
      emptyValues: {
        name: 'New Test Project',
        testSets: [],
        chatbot: null,
        ...initialValuesPerformanceTest
      }
    }
  }

  renderStartForm() {
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props
    let startProject = true
    return (
      <Mutation
        mutation={QUICKSTART_TESTPROJECT}
        refetchQueries={() => {
          return [
            ...RefetchTestProjectQueries(),
            ...RefetchTestSetQueries()
          ]
        }}
      >
        {(mutateTestProject, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              try {
                const performanceTest = this.projectFlags.performanceTesting ? buildPerformanceTestStartOptions(values) : undefined
                const testProjectPerformanceTest = {}
                if(performanceTest) {
                  testProjectPerformanceTest.performanceTestType = values.performanceTest_Type ? values.performanceTest_Type.toUpperCase() : 'LOAD'
                  testProjectPerformanceTest.parallelJobCount = performanceTest.parallelJobCount
                  testProjectPerformanceTest.tickRepeatInitial = performanceTest.tickRepeatInitial
                  testProjectPerformanceTest.requiredPercentOfSuccesfulUsers = performanceTest.requiredPercentOfSuccesfulUsers
                  testProjectPerformanceTest.tickMaxTime = performanceTest.tickMaxTime
                  testProjectPerformanceTest.tickRepeatPerTick = performanceTest.tickRepeatPerTick
                  testProjectPerformanceTest.tickTime = performanceTest.tickTime
                  testProjectPerformanceTest.shareContainer = performanceTest.shareContainer
                  testProjectPerformanceTest.simpleConvo = performanceTest.simpleConvo
                  testProjectPerformanceTest.waitForBotTimeout = performanceTest.waitForBotTimeout
                  testProjectPerformanceTest.detailedReporting = performanceTest.detailedReporting
                }

                await mutateTestProject({
                  variables: {
                    testProject: {
                      ...this.projectFlags,
                      ...testProjectPerformanceTest,
                      name: values.name,
                      namespace: values.namespace,
                      cronExpression: values.cronExpression || undefined,
                      code: '',
                      gdprLanguage: values.gdprLanguage,
                      gdprFallbackIntents: {
                        set: values.gdprFallbackIntents
                      },
                      gdprFallbackResponses: {
                        set: values.gdprFallbackResponses
                      },
                      chatbot: {
                        connect: {
                          id: values.chatbotId,
                        },
                      },
                      testSets: {
                        connect: values.testSets &&
                          values.testSets.map(t => {
                            return { id: t.id }
                          }),
                      },
                      deviceSets: {
                        connect:
                          values.deviceSets &&
                          values.deviceSets.map(d => {
                            return { id: d.id }
                          }),
                      }
                    },
                    startProject: startProject,
                    gdprLanguage: values.gdprLanguage,
                    gdprFallbackIntents: values.gdprFallbackIntents || [],
                    gdprFallbackResponses: values.gdprFallbackResponses || [],
                    performanceTest
                  },
                })
                this.setState({testProjectsEmbeddedTableKey: this.state.testProjectsEmbeddedTableKey + 1 })
                form.initialize(this.state.emptyValues)
                setAlertSuccessMessage('Test Project registered')
                this.setState({ newProjectExpanded: false })
              } catch (error) {
                setAlertErrorMessage(`Test Project registration failed`, error)
              }
            }}
            initialValues={this.state.emptyValues}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values,
              form
            }) => {
              return <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                {this.projectFlags.performanceTesting && this.renderStartPerformanceTestFormUi(values, form)}
                {!this.projectFlags.performanceTesting && this.renderStartFormUi(values, form)}
                <GridContainer>
                  <GridItem xs={12}>
                    <FormActionsToolbar
                      rightButtons={<>
                        <Button
                          secondary
                          disabled={submitting}
                          onClick={() => {
                            this.setState({ newProjectExpanded: false })
                          }}
                          data-unique="btnTestProjectRegisterCancel"
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={submitting || invalid}
                          onClick={() => {
                            startProject = false
                            handleSubmit()
                            this.setState({ newProjectExpanded: false })
                          }}
                          data-unique="btnTestProjectRegisterSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          Save
                        </Button>
                        <Button
                          disabled={submitting || invalid}
                          onClick={() => {
                            startProject = true
                            handleSubmit()
                            this.setState({ newProjectExpanded: false })
                          }}
                          data-unique="btnTestProjectRegisterStart"
                        >
                          {submitting && <LoadingIndicator alt />}
                          Start
                        </Button>
                      </>}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            }}
          />
        )}
      </Mutation>
    )
  }

  renderStartPerformanceTestFormUi(values, form) {
    const { chatbotsData, testsetsData } = this.props
    const { classes } = this.props

    const tiles = [
      {
        name: PERFORMANCE_TEST_MODE_LOAD_TEST,
        header: <div className={classes.performanceTestType}>P. Load Test</div>,
        subheader: <><div className={classes.explanationBox}>
          Botium <b>generates</b> Constant Load by <b>simulating a constant number of parallel Users.</b>
        </div>
          <Button mini noMargin fullWidth >
            Load Test
          </Button></>
      },
      {
        name: PERFORMANCE_TEST_MODE_STRESS_TEST,
        header: <div className={classes.performanceTestType}>P. Stress Test</div>,
        subheader: <><div className={classes.explanationBox}>
          Botium generates an Increasing Load by <b>adding new Users every 10 seconds.</b>
        </div>
          <Button mini noMargin fullWidth >
            Stress Test
          </Button></>
      },
      {
        name: PERFORMANCE_TEST_MODE_ADVANCED_TEST,
        header: <div className={classes.performanceTestType}>P. Advanced Test</div>,
        subheader: <><div className={classes.explanationBox}>
          In Advanced mode <b>you can control every parameter.</b> It gives more freedom, but requires more knowledge about Botium Performance Test.
        </div>
          <Button mini noMargin fullWidth >
            Advanced Test
          </Button></>
      }
    ]

    const advanced = values.performanceTest_Type === PERFORMANCE_TEST_MODE_ADVANCED_TEST

    return <Card><CardBody><GridContainer>
      <GridItem largeMarginBottom largeMarginTop lg={12}>
        <Text header>Select Performance Test mode</Text>
      </GridItem>
    </GridContainer>
      <GridContainer>
        {tiles.map((t, key) => {
          const selected = values.performanceTest_Type === t.name
          return <GridItem key={key} md={4} lg={3} style={{ display: 'flex', marginBottom: '30px' }}>
            <div tabIndex={0} className={classes.testTypeCardWarapper} data-unique={`cardPerformanceTestType-${t.name}`}>
              <SelectableCard
                noPadding
                selected={selected}
                data-unique={`cardTestSettings-${t.name}-${selected ? 'enabled' : 'disabled'}`}
                onClick={() => {
                  if (!advanced) form.change('performanceTest_simpleConvo', true)
                  form.change('performanceTest_Type', t.name)
                }}
              >
                <CardBody>
                  <GridContainer>
                    <GridItem lg={12}>
                      {t.header}
                    </GridItem>
                    <GridItem lg={12}>{t.subheader}</GridItem>
                  </GridContainer>
                </CardBody>
              </SelectableCard>
            </div>
          </GridItem>
        })}
        <GridItem largeMarginBottom largeMarginTop lg={12}>
          <Text header>Create Test</Text>
        </GridItem>
        <GridItem md={12} lg={6}>
          <Field
            name="name"
            component={renderTextField}
            label="Test Project Name"
            validate={composeValidators(required, async (value) => {
              const { client } = this.props
              if (value) {
                return validateTestProjectCodeUnique(client, value, undefined, this.projectFlags)
              }
            })}
            data-unique="txtTestProjectRegisterTestProjectName"
          />
        </GridItem>
        <GridItem md={12} lg={6}>
          <Field
            name="chatbotId"
            component={renderSelect}
            label="Select a Chatbot"
            data-unique={`selTestProjectRegisterChatbot`}
            validate={required}
            items={chatbotsData && chatbotsData.chatbots && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace).map(c => {
              return {
                key: c.id,
                chatbot: c
              }
            })
            }
          />
        </GridItem>
        {advanced && <GridItem lg={3}>
          <Field
            name="performanceTest_simpleConvo"
            component={renderCheckbox}
            type="checkbox"
            disabled={!advanced}
            label="Use a simple 'Hello' Conversation?"
            data-unique="selTestProjectPerformanceTestSimpleConvo"
          />
        </GridItem>}
        {advanced && !values.performanceTest_simpleConvo && <GridItem md={12} lg={3}>
          <Field
            name="testSets"
            component={renderSelect}
            label="Select Test Set(s)"
            data-unique="selTestProjectRegisterTestset"
            validate={required}
            disabled={values.performanceTest_simpleConvo}
            loading={testsetsData && testsetsData.loading}
            error={testsetsData && testsetsData.error}
            multiple
            valueKeyMap={t => t.id}
            items={testsetsData && testsetsData.testsets && safeGetNamespaceFilteredList(testsetsData.testsets, this.props.namespace).map(t => {
              return {
                key: t.id,
                label: t.name,
                value: t
              }
            })}
          />
        </GridItem>}
        {advanced && values.performanceTest_simpleConvo && <GridItem md={12} lg={3}>
          <Field
            name="testSets"
            component={renderSelect}
            label="Select Test Set(s)"
            data-unique="selTestProjectRegisterTestset"
            disabled={values.performanceTest_simpleConvo}
            loading={testsetsData && testsetsData.loading}
            error={testsetsData && testsetsData.error}
            multiple
            valueKeyMap={t => t.id}
            items={testsetsData && testsetsData.testsets && safeGetNamespaceFilteredList(testsetsData.testsets, this.props.namespace).map(t => {
              return {
                key: t.id,
                label: t.name,
                value: t
              }
            })}
          />
        </GridItem>}
        {!advanced && <GridItem lg={6}/>}
        <GridItem md={12} lg={6}>
          <Field
            name="namespace"
            component={renderNamespaceField}
            label="Namespace"
            forWrite
            data-unique="txtTestProjectRegisterNamespace"
          />
        </GridItem>
        <GridItem lg={12}>
          <Divider />
          <GridItem largeMarginBottom largeMarginTop lg={12}>
            <Text header>Setup Test Parameters</Text>
          </GridItem>
          <PerformanceTestSessionRegisterForm showUseHello={false}/>
        </GridItem>
      </GridContainer>
    </CardBody></Card>
  }

  renderStartFormUi(values, form) {
    const { getConnector, chatbotsData, testsetsData, deviceSetsData } = this.props
    const getConnectorFeatures = (chatbotId) => {
      if (!chatbotsData || !chatbotsData.chatbots) return false
      const chatbot = chatbotsData.chatbots.find(c => c.id === chatbotId)
      if (!chatbot || !chatbot.containermode) return false

      const connector = getConnector(chatbot.containermode)
      return connector?.features
    }
    const hasDeviceSet = (chatbotId) => {
      return !!getConnectorFeatures(chatbotId)?.deviceSetCapability
    }

    return <Card><CardBody><GridContainer>
      <GridItem largeMarginBottom largeMarginTop lg={12}>
        <Text header>Create Test</Text>
      </GridItem>
      </GridContainer>
      <GridContainer>
      <GridItem md={12} lg={6}>
        <Field
          name="name"
          component={renderTextField}
          label="Test Project Name"
          validate={composeValidators(required, async (value) => {
            const { client } = this.props
            if (value) {
              return validateTestProjectCodeUnique(client, value, undefined, this.projectFlags)
            }
          })}
          data-unique="txtTestProjectRegisterTestProjectName"
        />
      </GridItem>
      <GridItem md={12} lg={6}>
        <Field
          name="chatbotId"
          component={renderSelect}
          label="Select a Chatbot"
          data-unique={`selTestProjectRegisterChatbot`}
          validate={required}
          items={chatbotsData && chatbotsData.chatbots && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace).map(c => {
            return {
              key: c.id,
              chatbot: c
            }
          })
          }
        />
      </GridItem>
      {(!this.projectFlags.gdprTesting && !this.projectFlags.securityCheck) && <GridItem md={12} lg={6}>
        <Field
          name="testSets"
          component={renderSelect}
          label="Select Test Set(s)"
          data-unique="selTestProjectRegisterTestset"
          validate={required}
          loading={testsetsData && testsetsData.loading}
          error={testsetsData && testsetsData.error}
          multiple
          valueKeyMap={t => t.id}
          items={testsetsData && testsetsData.testsets && safeGetNamespaceFilteredList(testsetsData.testsets, this.props.namespace).map(t => {
            return {
              key: t.id,
              label: t.name,
              value: t
            }
          })}
        />
      </GridItem>}

      {this.projectFlags.gdprTesting && <>
        <GridItem md={12} lg={6}>
          <Field
            name="gdprLanguage"
            component={renderSelect}
            label="GDPR Language"
            validate={required}
            data-unique="selTestProjectRegisterGdprLanguage"
            items={['en', 'de'].map(l => ({
              key: l,
              label: LanguageDisplayName(l),
              flagIcon: l
            }))} />
        </GridItem>
        {getConnectorFeatures(values.chatbotId)?.intentResolution &&
          <GridItem md={12} lg={6}>
            <Field
              name="gdprFallbackIntents"
              component={renderAutoSuggest}
              label="Fallback Intent Names"
              helperText="Enter a list of NLU Fallback intents which are triggered in case the Chatbot does not recognize the user intent"
              data-unique="asTestProjectRegisterGdprFallbackIntents"
            />
          </GridItem>
        }
        <GridItem md={12} lg={6}>
          <Field
            name="gdprFallbackResponses"
            component={renderAutoSuggest}
            label="Fallback Responses"
            helperText="Enter a list of text responses which are triggered in case the Chatbot does not recognize the user intent"
            data-unique="asTestProjectRegisterGdprFallbackIntents"
          />
        </GridItem>
      </>}

      <GridItem md={12} lg={6}>
        <Field
          name="namespace"
          component={renderNamespaceField}
          label="Namespace"
          forWrite
          data-unique="txtTestProjectRegisterNamespace"
        />
      </GridItem>

      {values.chatbotId && hasDeviceSet(values.chatbotId) &&
        <GridItem xs={12}>
          <Field
            name="deviceSets"
            component={renderSelect}
            label="Select Device Set(s)"
            data-unique="selTestProjectRegisterDeviceSet"
            loading={deviceSetsData && deviceSetsData.loading}
            error={deviceSetsData && deviceSetsData.error}
            multiple
            valueKeyMap={d => d.id}
            items={deviceSetsData && deviceSetsData.devicesets && deviceSetsData.devicesets.map(d => {
              return {
                key: d.id,
                label: d.name,
                value: d
              }
            })}
          />
        </GridItem>
      }
      <Divider orientation="horizontal"  dividerlgnone />
                <GridItem xs={12}>
                  <Field
                    name="cronExpression"
                    component={renderTextField}
                    label="Cron Expression"
                    helperText="Enter a Unix-style cron expression to schedule automated test runs"
                    validate={cron}
                    data-unique="txtTestProjectTestCronExpression"
                  />
                  <Chip
                    label="Daily at midnight"
                    onClick={(e) => form.change('cronExpression', '0 0 * * *')}
                  />
                  <Chip
                    label="Daily at 9 am"
                    onClick={(e) => form.change('cronExpression', '0 9 * * *')}
                  />
                  <Chip
                    label="Weekly on Monday at 9 am"
                    onClick={(e) => form.change('cronExpression', '0 9 * * 1')}
                  />
                  <Chip
                    label="Monthly on first day at 9 am"
                    onClick={(e) => form.change('cronExpression', '0 9 1 * *')}
                  />
                </GridItem>
    </GridContainer></CardBody></Card>
  }

  render() {
    this.projectFlags = urlToProjectTypeFlags(this.props.match.url)

    if (this.state.newProjectExpanded) {
      return this.renderStartForm()
    }

    return this.renderTableMode()
  }

  renderTableMode() {
    const { user, classes } = this.props

    // if there is no project flag, then consider it as regression test
    const tableFilter =
      this.projectFlags.regressionTesting ?
        {
          OR: [
            this.projectFlags,
            {
              AND: [
                {OR: [{nlpAnalytics: false}, {nlpAnalytics: null}]},
                {OR: [{securityCheck: false}, {securityCheck: null}]},
                {OR: [{performanceTesting: false}, {performanceTesting: null}]},
                {OR: [{gdprTesting: false}, {gdprTesting: null}]},
                {OR: [{e2eTesting: false}, {e2eTesting: null}]},
                {OR: [{regressionTesting: false}, {regressionTesting: null}]},
                {OR: [{monitoring: false}, {monitoring: null}]},
              ]
            }
          ]
        } :
        this.projectFlags

    return (
      <GridContainer noPadding>
        <GridItem xs={12} right>
          <Button noMargin
            onClick={() => this.setState({ newProjectExpanded: true })}
            data-unique="btnQuickstartGoToTestProjects"
            disabled={!hasPermission(user, 'TESTPROJECTS_CREATE')}
          >
            <ShowIcon icon="plus" />
            Create Test
          </Button>
        </GridItem>
        <GridItem xs={12} className={classes.projectsListEmbeddedTable}>
          <TestProjectsEmbeddedTable name="TestProjects" key={`TestProjects${this.state.testProjectsEmbeddedTableKey}`} addNoPaddingTableActionsToolbar={true} variables={ tableFilter } projectFlags={this.projectFlags} />
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(testprojectsStyle),
  connect(
    state => ({ user: state.token.user, settings: state.settings, license: state.settings.license, namespace: state.namespace }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
    options: (props) => {
      const { match } = props
      const projectFlags = urlToProjectTypeFlags(match.url)
      const filter = projectTypeFlagsToConnectorFeature(projectFlags)

      return {
        variables: filter
      }
    }
  }),
  graphql(TESTSETS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      testsetsData: data,
    }),
  }),
  graphql(DEVICESETS_DROPDOWN_QUERY, {
    skip: (props) => !isLicenseDeviceSetsSupported(props.license),
    props: ({ data }) => ({
      deviceSetsData: data,
    }),
  }),
  withApollo
)(withRouter(TestProjects))
