import React from 'react'
import { TablePagination, withStyles } from '@material-ui/core'
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle'

function CustomTablePagination({ classes, ...props }) {
    return (
        <TablePagination
            classes={{
            selectRoot: classes.selectMenuPagination,
            caption: classes.captionPagination,
            select: classes.selectPagination,
            selectIcon: classes.selectIconPagination,
            actions: classes.tablePaginationActions,
            toolbar: classes.tablePaginationToolbar
            }}
            nextIconButtonProps={{
                classes: {
                    root: classes.tablePaginationButton,
                    disabled: classes.tablePaginationButtonDisabled
                }
            }}
            backIconButtonProps={{
                classes: {
                    root: classes.tablePaginationButton,
                    disabled: classes.tablePaginationButtonDisabled
                }
            }}
            {...props}
      />
    )
  }
  
  export default withStyles(tableStyle)(CustomTablePagination)