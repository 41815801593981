import { SHOW_TRIAL_COUNTDOWN } from '../constants'

export default function trialcountdown(state = {}, action) {
  switch (action.type) {
    case SHOW_TRIAL_COUNTDOWN:
      return {
        show: action.payload.show
      }
    default:
      return state
  }
}
