import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'
import {NavLink} from 'react-router-dom'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {UTTERANCES_FOR_INTENT_CONFIDENCE_DISTRIBUTION_QUERY, TRAINER_SESSION_ROOT_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'

import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'
import Button from 'components/Button/Button'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import Utterance from './UtteranceSummary'
import LinkButton from 'components/Button/LinkButton'
import DangerOrSuccessText from 'components/Typography/DangerOrSuccessText'

const testsessionLabel = (testsession) => {
  if (testsession) {
    return <React.Fragment>{testsession.name}</React.Fragment>
  }
  return null
}

class IntentConfidenceDistribution extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  getRootPath = () => {
    const path = this.props.location.pathname.split('/').filter(p => p)
    return '/' + path[0] + '/' + path[1] + '/' + path[2] + '/' + path[3]
  }

  renderTable() {
    const {distributionData, match} = this.props
    let {testSessionId, showDataByExpected}= match.params
    let progressOrError = renderProgressOrError(distributionData)
    if (progressOrError) {
      return progressOrError
    }

    const data = _.sortBy(distributionData.trainerUtterances, (entry) => entry.intent.confidenceReal + entry.utterance)

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Utterance', 'Expected Intent', 'Predicted Intent', 'Prediction Confidence']}
        tableData={data.map(entry => {
          const confidence = showDataByExpected === 'expected' ? entry.intent.confidenceCorrected : entry.intent.confidenceReal
          return [
            () => <LinkButton onClick={() => this.setState({ open: true, utterance: entry.utterance, testSessionId: testSessionId })} data-unique={`btnCoachIntentConfidenceDistributionUtterance_${encodeURIComponent(entry.utterance)}`}>{entry.utterance}</LinkButton>,
            () => <>{entry.intent.expected}</>,
            () => <>{entry.intent.actual}</>,
            () => <><DangerOrSuccessText successful={entry.intent.expected}>{!_.isNil(confidence) ? confidence.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</DangerOrSuccessText></>
          ]
        })}
      />
    )
  }

  render() {
    const {match, testSessionData} = this.props

    return (<>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${match.params.testSessionId}`} data-unique={`btnBack_${match.params.testSessionId}`}>
                    <Button justIcon Border >
                       <ShowIcon icon="arrow-left" />
                    </Button> <Text inline>BACK / Test Session: {testSessionData && testSessionData.testsession && testsessionLabel(testSessionData.testsession)}</Text>
                  </NavLink>
                </GridItem>
                <GridItem xs={12}>

                </GridItem>
                <GridItem xs={12}>
                  {this.renderTable()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ConfirmationDialog
      maxWidth="lg"
      open={this.state.open}
      onOk={() => this.setState({ open: false })}
      title={`Utterance name: ${this.state.utterance}`}
    >
      <Utterance utterance={this.state.utterance} testSessionId={this.state.testSessionId} />
    </ConfirmationDialog>
    </>)
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(UTTERANCES_FOR_INTENT_CONFIDENCE_DISTRIBUTION_QUERY, {
    props: ({data}) => ({
      distributionData: data,
    }),
    options: (props) => {
      const p = props.match.params
      const {testSessionId, showDataByExpected} = p
      const from = parseFloat(p.from)
      let to = parseFloat(p.to)

      const variables = {
        testSessionId,
        isIncomprehension: false
      }
      if (showDataByExpected === 'expected') {
        variables.intentConfidenceCorrectedFrom = from
        variables.intentConfidenceCorrectedTo = to
      } else {
        variables.intentConfidenceRealFrom = from
        variables.intentConfidenceRealTo = to
      }

      return {variables}
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      testSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.secondaryTestSessionId,
          id: props.match.params.testSessionId
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      secondaryTestSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.secondaryTestSessionId
        },
      }
    },
    skip: (props) => !props.match.params.secondaryTestSessionId
  })
)(IntentConfidenceDistribution)
