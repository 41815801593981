import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Chip from 'components/Chip/Chip'
import Tooltip from 'components/Tooltip/Tooltip'
import Table from 'components/Table/AdvancedTable.jsx'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LastChange from 'components/Info/LastChange'
import AvatarImage from 'components/Avatar/AvatarImage'

import TestSessionsEmbeddedTable from '../TestSessions/TestSessionsEmbeddedTable.jsx'
import TestProjectsEmbeddedTable from '../TestProjects/TestProjectsEmbeddedTable.jsx'

import quicksearchStyle from 'assets/jss/material-dashboard-react/views/quicksearchStyle.jsx'
import { setTableSettings } from 'actions/table'

import {
  SEARCH_TESTSESSIONS_QUERY,
  SEARCH_TESTPROJECTS_QUERY,
  SEARCH_TESTSETS_QUERY,
  SEARCH_CHATBOTS_QUERY
} from './gql'


import { hasPermission } from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text.jsx'

class QuickSearch extends React.Component {
  render() {
    const { match, user, classes } = this.props

    const _renderCount = (data, objName, objTitle) => `Found ${(data && data[objName] && data[objName].length) || 0} ${objTitle}${(data && data[objName] && data[objName].length === 1) ? '' : 's'}`

    return (
      <GridContainer>
        <GridItem xs={12}>
          {hasPermission(user, 'TESTSESSIONS_SELECT') &&
            <Query
              query={SEARCH_TESTSESSIONS_QUERY}
              variables={{ searchTerm: match.params.searchTerm }}
              fetchPolicy="network-only"
            >
              {({ loading, error, data }) => (
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Text header>
                      {loading && 'Searching Test Results ...'}
                      {error && 'Searching Test Results failed.'}
                      {!loading && !error && _renderCount(data, 'testsessions', 'Test Result')}
                    </Text>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.expansionPanel}>
                    {data && data.testsessions && data.testsessions.length > 0 && (
                      <GridContainer fullWidth>
                        <GridItem xs={12} className={classes.testSessionsEmbeddedTable}>
                          <TestSessionsEmbeddedTable
                            key={data.testsessions.reduce((prev, curr) => prev + curr.id.slice(0, 3) + curr.id.slice(curr.id.length - 3), '')}
                            variables={{ id_in: data.testsessions.map(t => t.id) }}
                            name="QuickSearch_TestSessions"
                            disableHeader 
                            disableOrderBy
                            disableFooter={false}
                          />
                        </GridItem>
                      </GridContainer>
                      
                    )}
                    {(!data || !data.testsessions || data.testsessions.length === 0) && 'No Test Results found'}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </Query>
          }
          {hasPermission(user, 'TESTPROJECTS_SELECT') &&
            <Query
              query={SEARCH_TESTPROJECTS_QUERY}
              variables={{ searchTerm: match.params.searchTerm }}
              fetchPolicy="network-only"
            >
              {({ loading, error, data }) => (
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Text header>
                      {loading && 'Searching Test Projects ...'}
                      {error && 'Searching Test Projects failed.'}
                      {!loading && !error && _renderCount(data, 'testprojects', 'Test Project')}
                    </Text>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.expansionPanel}>
                    {data && data.testprojects && data.testprojects.length > 0 && (
                      <GridContainer fullWidth>
                        <GridItem xs={12} className={classes.testSessionsEmbeddedTable}>
                          <TestProjectsEmbeddedTable
                            key={data.testprojects.reduce((prev, curr) => prev + curr.id.slice(0, 3) + curr.id.slice(curr.id.length - 3), '')}
                            variables={{ id_in: data.testprojects.map(t => t.id) }}
                            name="QuickSearch_TestProjects"
                            disableHeader 
                            disableOrderBy
                            disableFooter={false}
                          />
                        </GridItem>
                      </GridContainer>
                    )}
                    {(!data || !data.testprojects || data.testprojects.length === 0) && 'No Test Projects found'}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </Query>
          }
          {hasPermission(user, 'TESTSETS_SELECT') &&
            <Query
              query={SEARCH_TESTSETS_QUERY}
              variables={{ searchTerm: match.params.searchTerm }}
              fetchPolicy="network-only"
            >
              {({ loading, error, data }) => (
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Text header>
                      {loading && 'Searching Test Sets ...'}
                      {error && 'Searching Test Sets failed.'}
                      {!loading && !error && _renderCount(data, 'testsets', 'Test Set')}
                    </Text>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.expansionPanel}>
                    {data && data.testsets && data.testsets.length > 0 && (
                      <GridContainer fullWidth>
                        <GridItem xs={12} className={classes.testSessionsEmbeddedTable}>
                          <Table
                            name="QuickSearch_TestSets"
                            tableHeaderColor="primary"
                            tableHead={['Name', 'Last Change', 'Convos', 'Utterances', 'Test Parameter Stores']}
                            tableData={data.testsets.map(t => [
                              {
                                value: t.name,
                                href: '/testsets/view/' + t.id,
                                width: 250
                              },
                              () => <LastChange {...t} />,
                              () => <Tooltip title={`${t.statsConvoCount || '0'} Convos`} key={'convos'}><Chip variant="convo" label={t.statsConvoCount || 0} /></Tooltip>,
                              () => <Tooltip title={`${t.statsUtterancesCount || '0'} Utterances`} key={'utterances'}><Chip variant="utterance" label={t.statsUtterancesCount || 0} /></Tooltip>,
                              () => <Tooltip title={`${t.statsScriptingMemoryCount || '0'} Test Parameter Stores`} key={'scriptingmemories'}><Chip variant="scripting" label={t.statsScriptingMemoryCount || 0} /></Tooltip>,
                            ])}
                            disableHeader 
                            disableOrderBy
                            disableFooter={false}
                            pageSize={5}
                          />
                          </GridItem>
                        </GridContainer>
                    )}
                    {(!data || !data.testsets || data.testsets.length === 0) && 'No Test Sets found'}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </Query>
          }
          {hasPermission(user, 'CHATBOTS_SELECT') &&
            <Query
              query={SEARCH_CHATBOTS_QUERY}
              variables={{ searchTerm: match.params.searchTerm }}
              fetchPolicy="network-only"
            >
              {({ loading, error, data }) => (
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Text header>
                      {loading && 'Searching Chatbots ...'}
                      {error && 'Searching Chatbots failed.'}
                      {!loading && !error &&  _renderCount(data, 'chatbots', 'Chatbot')}
                    </Text>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.expansionPanel}>
                    {data && data.chatbots && data.chatbots.length > 0 && (
                      <GridContainer fullWidth>
                        <GridItem xs={12} className={classes.testSessionsEmbeddedTable}>
                          <Table
                            name="QuickSearch_Chatbots"
                            tableHeaderColor="primary"
                            tableHead={['Name', 'Last Change', 'Chatbot']}
                            tableData={data.chatbots.map(a => [
                              {
                                value: a.name,
                                href: `/chatbots/view/${a.id}/dashboard`,
                              },
                              () => <LastChange {...a} />,
                              {
                                value: <AvatarImage style={{ float: 'right' }} avatar={a.avatar} containermode={a.containermode} chatbotId={a.id} />,
                                href: `/chatbots/view/${a.id}/dashboard`,
                              }
                            ])}
                            disableHeader 
                            disableOrderBy
                            disableFooter={false}
                            pageSize={5}
                          />
                        </GridItem>
                      </GridContainer>
                    )}
                    {(!data || !data.chatbots || data.chatbots.length === 0) && 'No Chatbots found'}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </Query>
          }
        </GridItem>
      </GridContainer>

    )
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setTableSettings }
)(withStyles(quicksearchStyle)(withRouter(QuickSearch)))
