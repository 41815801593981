import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import cardHeaderStyle from 'assets/jss/material-dashboard-react/components/cardHeaderStyle.jsx'

function CardHeaderActions({ children }) {
  return (
    <div style={{ float: 'right' }}>
      {children}
    </div>
  )
}

export default withStyles(cardHeaderStyle)(CardHeaderActions)
