import React from 'react'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/Button/Button'
import Text from 'components/Typography/Text'
import { extractErrorMessage } from 'helper/graphHelper'
import BotiumBoxLogo from 'components/Typography/BotiumBoxLogo'

export class BrokenLinkError extends Error {
  constructor (message) {
    super(message || 'Broken Link')
    this.name = this.constructor.name
    if (Error.captureStackTrace !== undefined) {
      Error.captureStackTrace(this, this.constructor)
   }
  }
}

const extractErrorHeader = (err) => {
  if (err instanceof BrokenLinkError) {
    return 'Congrats, you found absolutely nothing here!'
  } else {
    return 'Congrats, you broke Botium - I hope you had fun :-)'
  }
}
const extractErrorDescription = (err) => {
  if (err instanceof BrokenLinkError) {
    return 'The record has been removed in the meanwhile'
  } else {
    return 'We have already been informed and are working on a solution! Word!'
  }
}

const ErrorPage = ({ error, resetError }) => (
  <div style={{ paddingTop: '30vh' }}>
  <GridContainer autoHeight>
    <GridItem xs={12} center largePadding>
    <BotiumBoxLogo hideText large />
    </GridItem>
    <GridItem xs={12} center largePadding>
      <Text extralg>{extractErrorHeader(error)}</Text>
    </GridItem>
    <GridItem xs={12} center>
      <Text lg>{extractErrorDescription(error)}</Text>
    </GridItem>
    {error &&
      <GridItem xs={12} center>
        <Text lg>Just in case you want to know, the error message is: {extractErrorMessage(error, true, true)}</Text>
      </GridItem>
    }
    <GridItem xs={12} center largePadding>
      <Button onClick={() => {
        resetError && resetError()
        window.location.pathname = '/'
      }}>
        Click here to go back to something that's actually working!
      </Button>
    </GridItem>
  </GridContainer>  
  </div>
)

export default ErrorPage
