import React from 'react'
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import copyToClipboard from 'copy-to-clipboard'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ShowIcon from 'components/Icon/ShowIcon'
import { LanguageDisplayName } from 'components/Icon/FlagIcon'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import {
  renderTextField,
  renderSelect,
  renderFileUpload,
  renderPasswordField,
  renderCodeArea,
  required,
  json,
  prettyPrintJson
} from 'components/Form/Form'

import { renderSpeechRecognitionSelector, renderSpeechSynthesisSelector, RUNCONNECTORACTION_QUERY } from './ConnectorEdit.jsx'
import Text from 'components/Typography/Text.jsx'
import { capSetDescription, usedByCapabilitySet } from './Helper.js'

// AVS https://developer.amazon.com/docs/custom-skills/develop-skills-in-multiple-languages.html
const languages = [
  'ar-SA',
  'de-DE',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IN',
  'en-US',
  'es-ES',
  'es-MX',
  'es-US',
  'fr-CA',
  'fr-FR',
  'hi-IN',
  'it-IT',
  'ja-JP',
  'pt-BR'
]

const _fromCaps = (caps, capsName, def = '') => {
  return (
    caps.find(c => c.name === capsName) || { stringValue: def }
  ).stringValue
}

const _fromJsonCaps = (caps, capsName, def = '') => {
  return (
    caps.find(c => c.name === capsName) || { jsonValue: def }
  ).jsonValue
}

const _toCaps = (caps, capsName, values, fieldName) => {
  caps.push({
    name: capsName,
    type: 'STRING',
    stringValue: values.alexaAvs[fieldName],
  })
}
const _toJsonCaps = (caps, capsName, values, fieldName) => {
  caps.push({
    name: capsName,
    type: 'JSON',
    jsonValue: values.alexaAvs[fieldName],
  })
}

const HOMOPHONES_SAMPLE = JSON.stringify({
  lettuce: ['let us'],
  fairy: ['ferry', 'fair I']
}, null, 2)

export function alexaavsCaps2Form(caps) {
  return {
    alexaAvs: {
      avsProductId: _fromCaps(caps, 'ALEXA_AVS_AVS_PRODUCT_ID'),
      avsClientId: _fromCaps(caps, 'ALEXA_AVS_AVS_CLIENT_ID'),
      avsClientSecret: _fromCaps(caps, 'ALEXA_AVS_AVS_CLIENT_SECRET'),
      avsLanguageCode: _fromCaps(caps, 'ALEXA_AVS_AVS_LANGUAGE_CODE', 'en-US'),
      avsRefreshToken: _fromCaps(caps, 'ALEXA_AVS_AVS_REFRESH_TOKEN'),
      ttsProfile: _fromCaps(caps, 'ALEXA_AVS_TTS', ''),
      sttProfile: _fromCaps(caps, 'ALEXA_AVS_STT', ''),
      homophones: _fromJsonCaps(caps, 'ALEXA_AVS_STT_HOMOPHONES', ''),
    }
  }
}

const capNamesMap = {
  'alexaAvs.avsProductId': 'ALEXA_AVS_AVS_PRODUCT_ID',
  'alexaAvs.avsClientId': 'ALEXA_AVS_AVS_CLIENT_ID',
  'alexaAvs.avsClientSecret': 'ALEXA_AVS_AVS_CLIENT_SECRET',
  'alexaAvs.avsLanguageCode': 'ALEXA_AVS_AVS_LANGUAGE_CODE',
  'alexaAvs.avsRefreshToken': 'ALEXA_AVS_AVS_REFRESH_TOKEN',
  'alexaAvs.ttsProfile': 'ALEXA_AVS_TTS',
  'alexaAvs.sttProfile': 'ALEXA_AVS_STT',
  'alexaAvs.homophones': 'ALEXA_AVS_STT_HOMOPHONES'
}

export function alexaavsForm2caps(values) {
  const caps = [{ name: 'CONTAINERMODE', type: 'STRING', stringValue: 'alexa-avs' }]

  _toCaps(caps, 'ALEXA_AVS_AVS_LANGUAGE_CODE', values, 'avsLanguageCode' )
  _toCaps(caps, 'ALEXA_AVS_AVS_PRODUCT_ID', values, 'avsProductId' )
  _toCaps(caps, 'ALEXA_AVS_AVS_CLIENT_ID', values, 'avsClientId' )
  _toCaps(caps, 'ALEXA_AVS_AVS_CLIENT_SECRET', values, 'avsClientSecret' )
  _toCaps(caps, 'ALEXA_AVS_AVS_REFRESH_TOKEN', values, 'avsRefreshToken' )
  _toCaps(caps, 'ALEXA_AVS_TTS', values, 'ttsProfile' )
  _toCaps(caps, 'ALEXA_AVS_STT', values, 'sttProfile' )
  _toJsonCaps(caps, 'ALEXA_AVS_STT_HOMOPHONES', values, 'homophones')
  return caps
}

class AlexaAVSEditInternal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      avsExpanded: true,
      speechExpanded: false,
      hpExpanded: false,
      importerExpanded: false,
      parseErrorAmazon: null,
      parseOkAmazon: null,
      userCode: null,
      deviceCode: null,
      verificationUri: null,
      verificationUriOpened: false
    }
  }

  async deviceAuthorizationRequest(values, change) {
    const { client, setAlertSuccessMessage, setAlertErrorMessage } = this.props

    try {
      const { data } = await client.query({
        query: RUNCONNECTORACTION_QUERY,
        variables: {
          connectorName: 'alexa-avs',
          action: 'DeviceAuthorizationRequest',
          caps: JSON.stringify({}),
          args: JSON.stringify({
            clientId: values.alexaAvs.avsClientId,
            productId: values.alexaAvs.avsProductId
          })
        },
        fetchPolicy: 'network-only'
      })
      if (data.runconnectoraction.err) {
        setAlertErrorMessage(`Failed to authorize device`, data.runconnectoraction.err)
        return
      }
      setAlertSuccessMessage('Device Authorized')
      const parsedResponse = JSON.parse(data.runconnectoraction.result)
      this.setState({
        userCode: parsedResponse.user_code,
        deviceCode: parsedResponse.device_code,
        verificationUri: parsedResponse.verification_uri,
        verificationUriOpened: false
      })
      change('alexaAvs.avsRefreshToken', null)
    } catch (err) {
      setAlertErrorMessage(`Failed to authorize device`, err)
    }
  }

  openRegisterYourDeviceWindow(values, change) {
    window.open(this.state.verificationUri, '_blank')
    this.setState({ verificationUriOpened: true })
  }

  async refreshTokenRequestAndSendCapabilities(values, change)  {
    const { client, setAlertSuccessMessage, setAlertErrorMessage } = this.props

    let deviceTokenResponse
    try{
      const { data } = await client.query({
        query: RUNCONNECTORACTION_QUERY,
        variables: {
          connectorName: 'alexa-avs',
          action: 'DeviceTokenRequest',
          caps: JSON.stringify({}),
          args: JSON.stringify({
            deviceCode: this.state.deviceCode,
            userCode: this.state.userCode
          })
        },
        fetchPolicy: 'network-only'
      })
      if (data.runconnectoraction.err) {
        setAlertErrorMessage(`Failed to acquire refresh token`, data.runconnectoraction.err)
        return
      }
      deviceTokenResponse = JSON.parse(data.runconnectoraction.result)
      change('alexaAvs.avsRefreshToken', deviceTokenResponse.refresh_token)
    } catch (err) {
      setAlertErrorMessage(`Failed to acquire refresh token`, err)
      return
    }

    try{
      const { data } = await client.query({
        query: RUNCONNECTORACTION_QUERY,
        variables: {
          connectorName: 'alexa-avs',
          action: 'SendCapabilities',
          caps: JSON.stringify({}),
          args: JSON.stringify({
            accessToken: deviceTokenResponse.access_token
          })
        },
        fetchPolicy: 'network-only'
      })
      if (data.runconnectoraction.err) {
        setAlertErrorMessage(`Failed to send Capabilities`, data.runconnectoraction.err)
        return
      }
      setAlertSuccessMessage('Refresh Token acquired')
    } catch (err) {
      setAlertErrorMessage(`Failed to send Capabilities`, err)
    }
    this.setState({
      userCode: null,
      deviceCode: null,
      verificationUri: null,
      verificationUriOpened: false
    })
  }

  renderConfigTab(values, change) {
    const { setAlertSuccessMessage, disabled, capSetCapNames } = this.props

    return (
      <GridContainer nounset>
        <GridItem xs={12} sm={12}>
          <Text subheader>1. Register Virtual Alexa Product</Text>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Text>In the <a href="https://developer.amazon.com/alexa/console/avs/products" target="_blank" rel="noopener noreferrer">Alexa Voice Service Console</a>, create a product and <a href="https://developer.amazon.com/de-DE/docs/alexa/alexa-voice-service/code-based-linking-other-platforms.html#step1" target="_blank" rel="noopener noreferrer">enable Code-Based Linking (Step 1)</a>. Copy&amp;Paste the values here.</Text>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="alexaAvs.avsProductId"
            component={renderTextField}
            label="Product ID"
            validate={required}
            data-unique="txtAlexaAVSEditProductId"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexaAvs.avsProductId']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['alexaAvs.avsProductId'])}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="alexaAvs.avsClientId"
            component={renderTextField}
            label="Client ID"
            validate={required}
            data-unique="txtAlexaAVSEditClientId"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexaAvs.avsClientId']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['alexaAvs.avsClientId'])}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="alexaAvs.avsClientSecret"
            component={renderPasswordField}
            label="Client Secret"
            validate={required}
            data-unique="txtAlexaAVSEditClientSecret"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexaAvs.avsClientSecret']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['alexaAvs.avsClientSecret'])}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Field
            name="alexaAvs.avsLanguageCode"
            component={renderSelect}
            label="Language"
            data-unique="selAlexaAVSEditAvsLanguageCode"
            items={languages.map(l => ({
              key: l,
              label: LanguageDisplayName(l),
              flagIcon: l
            }))} 
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexaAvs.avsLanguageCode']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['alexaAvs.avsLanguageCode'])}
            />
        </GridItem>

        <GridItem xs={12} sm={12}>
          <Text subheader>2. Register Botium as Virtual Alexa Device</Text>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Text>Use the buttons one after the other and follow the instructions.</Text>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Button secondary
            disabled={disabled || !values.alexaAvs.avsClientId || !values.alexaAvs.avsProductId || this.state.userCode}
            onClick={() => {
              this.deviceAuthorizationRequest(values, change)
            }}
            data-unique="btnAlexaAVSEditAuthorizeDevice"
          >
            {this.state.userCode && <ShowIcon icon="check" />}
            2.1. Authorize device
          </Button>
          <Button secondary
            disabled={!!(disabled || !this.state.userCode || this.state.verificationUriOpened)}
            onClick={() => {
              copyToClipboard(this.state.userCode)
              setAlertSuccessMessage(`User Code "${this.state.userCode}" copied to the clipboard - opening Register Your Device window`)
              this.openRegisterYourDeviceWindow(values, change)
            }}
            data-unique="btnAlexaAVSEditRegisterDevice"
          >
            {this.state.verificationUriOpened && <ShowIcon icon="check" />}
            2.2. Register Your Device{this.state.userCode ? ` (Paste the User Code "${this.state.userCode}" from Clipboard)` : ''}
          </Button>
          <Button secondary
            disabled={!!(disabled || !this.state.userCode || !this.state.verificationUriOpened)}
            onClick={() => {
              this.refreshTokenRequestAndSendCapabilities(values, change)
            }}
            data-unique="btnAlexaAVSEditAquireRefreshToken"
          >
            2.3. Acquire Refresh Token
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Field
            name="alexaAvs.avsRefreshToken"
            component={renderTextField}
            label="Refresh Token"
            validate={required}
            data-unique="txtAlexaAVSEditRefreshToken"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexaAvs.avsRefreshToken']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['alexaAvs.avsRefreshToken'])}
          />
        </GridItem>
      </GridContainer>
    )
  }

  renderSpeechTab(values, change) {
    const { disabled, features, capSetCapNames } = this.props

    return (
      <GridContainer nounset>
        <GridItem xs={12} sm={6}>
          {renderSpeechSynthesisSelector({
            features,
            fieldName: 'alexaAvs.ttsProfile',
            dataUnique: 'selAlexaAVSEditTtsProfile',
            disabled: usedByCapabilitySet(capSetCapNames, capNamesMap['alexaAvs.ttsProfile']) || disabled,
            helperText: capSetDescription(capSetCapNames, capNamesMap['alexaAvs.ttsProfile'])
          })}
        </GridItem>
        <GridItem xs={12} sm={6}>
          {renderSpeechRecognitionSelector({
            features,
            fieldName: 'alexaAvs.sttProfile',
            dataUnique: 'selAlexaAVSEditSttProfile',
            disabled: usedByCapabilitySet(capSetCapNames, capNamesMap['alexaAvs.sttProfile']) || disabled,
            helperText: capSetDescription(capSetCapNames, capNamesMap['alexaAvs.sttProfile'])
          })}
        </GridItem>
      </GridContainer>
    )
  }

  renderHomophonesTab(values, change) {
    const { disabled, capSetCapNames } = this.props
    return (
      <GridContainer nounset>
        <GridItem xs={12}>
          <Field
            name="alexaAvs.homophones"
            component={renderCodeArea}
            options={{ mode: 'application/json' }}
            label="Homophones Replacement Map"
            codeFormat={prettyPrintJson}
            validate={json}
            data-unique="codeAlexaAVSEditHomophones"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['alexaAvs.homophones']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['alexaAvs.homophones'])}
          />
          <Button data-unique="btnAlexaAVSEditInsertSampleHomphonesMap" link onClick={() => change('alexaAvs.homophones', HOMOPHONES_SAMPLE)}>Insert sample homophones map</Button>
        </GridItem>
      </GridContainer>
    )
  }

  renderImportTab(values, change) {
    return (
      <GridContainer nounset>
        <GridItem xs={12}>
          <Field
            name="alexaAvs.fileuploadAmazon"
            component={renderFileUpload}
            accept=".json"
            values={values}
            change={change}
            label="Select/Drop Amazon Config JSON File (amazonConfig.json)"
            onFileLoaded={(filename, filecontent) => {
              try {
                const cred = JSON.parse(atob(filecontent))
                if (cred.deviceInfo) {
                  change('alexaAvs.avsClientId', cred.deviceInfo.clientId)
                  change('alexaAvs.avsClientSecret', cred.deviceInfo.clientSecret)
                  change('alexaAvs.avsProductId', cred.deviceInfo.productId)
                }
                this.setState({
                  ...this.state,
                  parseErrorAmazon: null,
                  parseOkAmazon: `Read credentials from file ${filename}`,
                })
              } catch (err) {
                this.setState({
                  ...this.state,
                  parseErrorAmazon: `Reading credentials from file ${filename} failed: ${err}`,
                  parseOkAmazon: null
                })
              }
              change('alexaAvs.fileuploadAmazon', null)
            }}
          />
          {this.state.parseErrorAmazon && <Text danger>{this.state.parseErrorAmazon}</Text>}
          {this.state.parseOkAmazon && <Text success>{this.state.parseOkAmazon}</Text>}
          <Text>
            You can read about Amazon Config JSON File <a href="https://github.com/codeforequity-at/botium-connector-alexa-avs#1-prepare-amazonconfigjson" target="_blank" rel="noopener noreferrer">here</a>
          </Text>
        </GridItem>
      </GridContainer>
    )
  }

  render() {
    const { advanced } = this.props
    const { avsExpanded, speechExpanded, hpExpanded, importerExpanded } = this.state

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => {
      return (<GridContainer>
        {!advanced && <GridItem xs={12} sm={12}>
          {this.renderConfigTab(values, change)}
        </GridItem>}
        {advanced && <GridItem xs={12} sm={12}>
          <ExpansionPanel expanded={avsExpanded} onChange={() => this.setState({avsExpanded: !avsExpanded})} data-unique="pnlAlexaAVSEditAVS">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Alexa Voice Service Configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {this.renderConfigTab(values, change)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={speechExpanded} onChange={() => this.setState({speechExpanded: !speechExpanded})} data-unique="pnlAlexaAVSEditSpeech">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Speech Configuration (Text-To-Speech and Speech-To-Text)
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {this.renderSpeechTab(values, change)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={hpExpanded} onChange={() => this.setState({hpExpanded: !hpExpanded})} data-unique="pnlAlexaAVSEditHomophones">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Homophones Mapping
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {this.renderHomophonesTab(values, change)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={importerExpanded} onChange={() => this.setState({importerExpanded: !importerExpanded})} data-unique="pnlAlexaAVSEditImport">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Credential Importer
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {this.renderImportTab(values, change)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </GridItem>}
      </GridContainer>
      )
    }}/>)
  }
}

const AlexaAVSEdit = withRouter(connect(
  state => ({ features: state.settings.features }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(withApollo(AlexaAVSEditInternal)))
export { AlexaAVSEdit }
