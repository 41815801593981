import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  renderSelect,
  renderTextArea,
  renderCodeArea,
  renderCheckbox,
  required,
  json,
  prettyPrintJson,
  composeValidators,
  renderAutoSuggest,
  FormActionsToolbar
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import {
  REGISTEREDCOMPONENTS_QUERY,
  REGISTEREDCOMPONENT_QUERY,
  VALIDATEREGISTEREDCOMPONENT_QUERY,
  CREATE_REGISTEREDCOMPONENT,
  UPDATE_REGISTEREDCOMPONENT,
  DELETE_REGISTEREDCOMPONENT,
} from './gql'

import { hasPermission } from 'botium-box-shared/security/permissions'
import { validateRegisteredComponentNameUnique } from './validators'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class RegisteredComponent extends React.Component {

  _mapValuesToReq(values) {
    const { license } = this.props
    return {
      name: values.name,
      type: values.type,
      default: values.default,
      description: values.description || null,
      ...(license.registerComponents ? {
        src: values.src,
        ref: values.ref
      } : {}),
      args: values.args || null,
      argsLabel: values.argsLabel || null,
      global: values.global,
      sender: {
        set: values.sender,
      }
    }
  }

  async validate(values) {
    const { client, license, user } = this.props

    if (license.registerComponents && hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')) {
      return client
        .query({
          query: VALIDATEREGISTEREDCOMPONENT_QUERY,
          variables: {
            registeredComponent: this._mapValuesToReq(values)
          },
          fetchPolicy: 'no-cache'
        })
        .then(({ data }) => {
          if (data && data.validateregisteredcomponent) {
            return {
              src: data.validateregisteredcomponent
            }
          }
        })
        .catch(err => {
          return {
            src: err.message
          }
      })
    }
    return {}
  }

  renderForm(registeredcomponent) {
    const { setAlertSuccessMessage, setAlertErrorMessage, license, user, onReady } = this.props

    return (
      <Mutation
        mutation={registeredcomponent.id ? UPDATE_REGISTEREDCOMPONENT : CREATE_REGISTEREDCOMPONENT}
        refetchQueries={[
          {
            query: REGISTEREDCOMPONENTS_QUERY,
          },
        ]}
      >
        {(mutateRegisteredComponent, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (registeredcomponent.id) {
                try {
                  const res = await mutateRegisteredComponent({
                    variables: {
                      id: registeredcomponent.id,
                      registeredComponent: this._mapValuesToReq(values),
                    }
                  })
                  form.initialize(res.data.updateRegisteredComponent)
                  setAlertSuccessMessage('Registered component updated')
                  onReady(registeredcomponent.id)
                } catch(error) {
                  setAlertErrorMessage('Registered component update failed', error)
                }
              } else {
                try {
                  const res = await mutateRegisteredComponent({
                    variables: {
                      registeredComponent: this._mapValuesToReq(values)
                    }
                  })
                  form.initialize(res.data.createRegisteredComponent)
                  setAlertSuccessMessage('Component registered')
                  onReady(res.data.createRegisteredComponent.id)
                } catch(error) {
                  setAlertErrorMessage('Registered component register failed', error)
                }
              }
            }}
            initialValues={registeredcomponent}
            validate={(values) => this.validate(values)}
            render={({
              handleSubmit,
              submitting,
              invalid
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Component Name"
                      validate={composeValidators(required, async (value) => {
                        if (hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE') && value) {
                          return validateRegisteredComponentNameUnique(this.props.client, registeredcomponent.id, value)
                        }
                      })}
                      disabled={!hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="txtRegisteredComponentComponentName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="type"
                      component={renderSelect}
                      label="Component Type"
                      validate={required}
                      disabled={!license.registerComponents || !hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="selRegisteredComponentType"
                      items={[
                        {
                          key: 'ASSERTER',
                          label: 'Test Case Asserter'
                        },
                        {
                          key: 'LOGICHOOK',
                          label: 'Logic Hook'
                        },
                        {
                          key: 'USERINPUT',
                          label: 'User Input Simulation'
                        }
                      ]}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="ref"
                      component={renderTextField}
                      label="Component Ref Code"
                      validate={required}
                      helperText="Reference code to be used in test cases for this component"
                      disabled={!hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="txtRegisteredComponentComponentRefCode"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="src"
                      component={renderTextField}
                      label="Component Source"
                      validate={required}
                      helperText="Identify source to load component from (NPM-Package, Javascript file)"
                      disabled={!license.registerComponents || !hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="txtRegisteredComponentComponentSource"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="default"
                      component={renderCheckbox}
                      label="Include in Default Component Set"
                      type="checkbox"
                      disabled={!hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="chkRegisteredComponentDefault"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="global"
                      component={renderCheckbox}
                      label="Register as global scripting component"
                      type="checkbox"
                      disabled={!hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="chkRegisteredComponentGlobal"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="description"
                      component={renderTextArea}
                      label="Component Description"
                      rows={8}
                      disabled={!hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="txtRegisteredComponentComponentDescription"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="argsLabel"
                      component={renderTextField}
                      label="Args Label"
                      disabled={!hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="txtRegisteredComponentComponentArgsLabel"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="sender"
                      component={renderAutoSuggest}
                      suggestions={['begin', 'me', 'bot', 'end']}
                      numsuggestions={4}
                      label="Sender"
                      disabled={!hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="txtRegisteredComponentComponentSender"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      className="CapabilitiesShort"
                      name="args"
                      component={renderCodeArea}
                      label="Component Configuration (JSON-formatted)"
                      options={{ mode: 'application/json' }}
                      codeFormat={prettyPrintJson}
                      validate={json}
                      disabled={!hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE')}
                      data-unique="codeRegisteredComponentComponentConfig"
                    />
                  </GridItem>
                </GridContainer>
                {hasPermission(user, 'REGISTEREDCOMPONENTS_MANAGE') &&
                  <GridContainer>
                    <GridItem xs={12} largePadding>
                      <FormActionsToolbar
                        leftButtons={<>
                          {license.registerComponents && registeredcomponent.id && (
                            <Mutation
                              mutation={DELETE_REGISTEREDCOMPONENT}
                              onCompleted={data => {
                                setAlertSuccessMessage('Component deleted')
                                onReady()
                              }}
                              onError={error => {
                                setAlertErrorMessage('Component deletion failed', error)
                              }}
                              refetchQueries={[
                                {
                                  query: REGISTEREDCOMPONENTS_QUERY,
                                },
                              ]}
                            >
                              {(deleteRegisteredComponent, { loading, error }) => (
                                <ConfirmationButton
                                  confirmationText={`Test Sets using this component will fail when you delete this component. Are you sure you want to delete it ?`}
                                  requireCheck={true}
                                  onClick={() => {
                                    deleteRegisteredComponent({
                                      variables: { id: registeredcomponent.id },
                                    })
                                  }}
                                  data-unique="btnRegisteredComponentUnregister"
                                  secondary
                                  danger
                                >
                                  <ShowIcon icon="trash" />
                                  <Text infoText bold>Delete Component</Text>
                                </ConfirmationButton>
                              )}
                            </Mutation>
                          )}
                        </>}
                        rightButtons={<>
                          <Button
                            type="submit"
                            disabled={submitting || invalid}
                            data-unique="btnRegisteredComponentSave"
                          >
                            {submitting && <LoadingIndicator alt />}
                            {!submitting && <ShowIcon icon="save" />}                  
                            Save
                          </Button>                        
                        </>}
                      />                      
                    </GridItem>
                  </GridContainer>
                }
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={REGISTEREDCOMPONENT_QUERY} variables={{ id }} fetchPolicy="network-only">
              {(queryResult) => <QueryStatus {...queryResult} query="registeredcomponent">{(data) => this.renderForm(data.registeredcomponent)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm({ default: false, global: false })}
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage },
)(withStyles(settingsStyle)(withApollo(RegisteredComponent)))
