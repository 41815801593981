
import {
  defaultFont,
  dangerColor,
} from 'assets/jss/material-dashboard-react.jsx'

import dropdownStyle from 'assets/jss/material-dashboard-react/dropdownStyle.jsx'

const headerLinksStyle = theme => ({
  ...dropdownStyle(theme),
  search: {
    '& > div': {
      marginTop: '0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 15px !important',
      float: 'none !important',
      paddingTop: '1px',
      paddingBottom: '1px',
      padding: '0!important',
      width: '60%',
      marginTop: '40px',
      '& input': {
        color: '#FFFFFF',
      },
    },
  },
  linkText: {
    zIndex: '4',
    ...defaultFont,
    fontSize: '12px',
    margin: '0px',
  },
  buttonLinkHistory: {
    position: 'relative',
    right: '-5px',
    '& svg': {
      width: '17px !Important',
      height: '17px ',
    }
  },
  buttonLinkUser: {
    '& svg': {
      width: '23px',
      height: '23px  !Important',
    }
  },
  searchButton: {
    [theme.breakpoints.down('sm')]: {
      top: '-50px !important',
      marginRight: '22px',
      float: 'right',
    },
  },
  margin: {
    zIndex: '0',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      zIndex: '4',
    }
  },
  searchIcon: {
    width: '17px',
    zIndex: '4',
  },
  
  notifications: {
    zIndex: '4',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '2px',
      border: '1px solid #FFF',
      right: '4px',
      fontSize: '9px',
      background: dangerColor,
      color: '#FFFFFF',
      minWidth: '16px',
      height: '16px',
      borderRadius: '10px',
      textAlign: 'center',
      lineHeight: '16px',
      verticalAlign: 'middle',
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      ...defaultFont,
      fontSize: '12px',
      marginRight: '8px',
    },
  },
  manager: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'inline-block',
    margin: '12px 0 0 0',
  },
  searchWrapper: {
    position: 'relative',
    top: 10,
    [theme.breakpoints.down('sm')]: {
      width: '-webkit-fill-available',
      //margin: '10px 15px 0',
    },
    '& input':{
      height: '27px!Important'
    },
    display: 'block',
    float: 'left',
    //marginTop: 8
    //paddingTop: 10,
  },
  ThemeToggler: { 
    marginTop: 8,
    position: 'relative',
    right: '-2px',
  },
  safariFix: {
    marginBottom: '-4px'
  },
  syncWithFilterFix: {
    lineHeight: '27px'
  },
  itemIcon: {
    marginRight: 10
  },
  itemIconHelp: {
    marginRight: 10,
    width: '18px!important'
    
  },
  headerLinksLink: {
    color: theme.colors.primary,
    width: '100%',
    '&:hover': {
      color: theme.colors.primary,
    },
    '&:hover > a': {
      color: theme.colors.primary,
    },
    '&:focus': {
      color: theme.colors.primary,
    },
    '&:focus > a': {
      color: theme.colors.primary,
    },
  },
  paperflymenu: {
    padding: 10,
    minWidth: 245,
    background: theme.colors.flymenubg,
    '& li':{
      color: theme.colors.primary,
      '-webkit-tap-highlight-color': theme.colors.visibleborderColor,
    }
  },
  namespaceSelector: {
    float: 'left',
    marginRight: 20,
    marginTop: '-3px',

    '& .filterableSelectRoot':{
      height: '27px!Important'
    }


  }
})

export default headerLinksStyle
