import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import PerfectScrollbar from 'perfect-scrollbar'
import moment from 'moment'
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from 'components/List/ListItem/ListItemText'
import Collapse from '@material-ui/core/Collapse'

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
// core components
import Button from 'components/Button/Button'
import HeaderLinks from 'components/Header/HeaderLinks.jsx'
import TrialCountdownPopup from 'components/Dialog/TrialCountdownPopup'
import MaintenancePopup from 'components/Dialog/MaintenancePopup'
import BotiumBoxLogoSidebar from 'components/Typography/BotiumBoxLogoSidebar'

import sidebarStyle from 'assets/jss/material-dashboard-react/components/sidebarStyle.jsx'

import { hasPermission } from 'botium-box-shared/security/permissions'
import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'
import LinkButton from 'components/Button/LinkButton'

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: null,
      drawerCollapsedCompleted: false,
      drawerOpened: false,
      display: null
    }
    this.sidebarPanelRef = React.createRef()

  }

  isLevelOneRoute = (path) => {
    if (path) {
      const pathComponents = path.split('/').filter(p => p)
      return pathComponents.length === 1
    }
    return false
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(path) {
    if (path) {
      const pathComponents = path.split('/').filter(p => p)
      if (pathComponents.length > 0) {
        return this.props.location.pathname.startsWith('/' + pathComponents[0])
      }
    }
    return false
  }

  // verifies if routeName is the one active (in browser input)
  activeLevelOneRoute(path) {
    if (path) {
      const pathComponents = path.split('/').filter(p => p)
      const currentPathComponents = this.props.location.pathname.split('/').filter(p => p)
      if (pathComponents.length === 1 && currentPathComponents.length === 1) {
        return this.props.location.pathname.startsWith('/' + pathComponents[0])
      }
    }
    return false
  }

  // verifies if routeName is the one active (in browser input)
  activeLevelMoreRoute(path) {
    if (path) {
      const pathComponents = path.split('/').filter(p => p)
      const currentPathComponents = this.props.location.pathname.split('/').filter(p => p)
      if (pathComponents.length > 1 && currentPathComponents.length > 1) {
        return this.props.location.pathname.startsWith('/' + pathComponents[0] + '/' + pathComponents[1])
      }
    }
    return false
  }

  componentDidMount() {
    //if (navigator.platform.indexOf('Win') > -1) {
    if (this.sidebarPanelRef.current) {
      new PerfectScrollbar(this.sidebarPanelRef.current)
    }
  }

  setOpenedMenuItem = (id) => {
    this.setState(state => ({ open: state.open === id ? null : id }))
  }

  setDrawerCollapsed() {
    if(!this.props.drawerCollapsed) {
       this.setState({drawerCollapsedCompleted: true})
    } else {
      this.setState({drawerCollapsedCompleted: false})
    }
  }

  updateDrawerOpened = (open) => {
    if (!open) {
      setTimeout(() => {
      this.setState({drawerOpened: false})
    }, 30)
    } else {
      setTimeout(() => {
        this.setState({drawerOpened: true})
      }, 275)
    }
  }

  render() {
    const { classes, color, routes, user, license, features, drawerCollapsed, setDrawerCollapsed } = this.props
    const categories = [
      'Test Suite', //setup
      'Botium Tools & Settings', //execution
    ]

    const _isRouteVisible = (route) => {
      if (route.checkLicense && (!license || !route.checkLicense(license))) return false
      if (route.checkPermission && !hasPermission(user, route.checkPermission)) return false
      if (route.checkUser && (!user || !route.checkUser(user))) return false
      if (route.checkFeatures && (!features || !route.checkFeatures(features))) return false
      return true
    }
    const _visibleSubMenuItems = (route) => {
      return route.dropdownName ? routes.filter(r => r.dropdownChild === route.dropdownName && _isRouteVisible(r)) : []
    }

    const subMenu = (prop, mobile) => {
      const visibleChildRoutes = _visibleSubMenuItems(prop)

      var listItemClasses = classNames({
        [' ' + classes[color]]: this.activeRoute(prop.path),
      })
      const whiteFontClasses = classNames({
        [' ' + classes.whiteFont]: this.activeRoute(prop.path),
      })

      const _getMainMenuItemLabel = () => (<>
        {!this.activeRoute(prop.path) && prop.navbarName}
        {this.activeRoute(prop.path) && visibleChildRoutes.map((prop2, key) => (
          <React.Fragment key={`submenu_item_label_${key}`}>
            {this.activeRoute(prop.path) && prop.navbarName}
            {this.activeLevelOneRoute(prop2.path) && prop2.navbarName}
            {this.activeLevelMoreRoute(prop2.path) && prop2.navbarName.substring(0, 14) + '...'}
          </React.Fragment>
        ))}
      </>)

      const _getMainMenuItemPath = () => {
        const p = visibleChildRoutes.map((prop2, key) => {
          if (this.activeLevelOneRoute(prop2.path)) return prop2.path
          if (this.activeLevelMoreRoute(prop2.path)) return prop2.path
          return null
        }).filter(p => p)[0]
        return p || prop.path
      }

      const _getSubMenuItems = (prop, key) => {
        if(prop.path) {
          return <Tooltip key={`submenu_item_${key}`} title={drawerCollapsed ? null : 'submenu'}>
            <NavLink
              to={prop.path}
              activeClassName="active"
              data-unique={prop.uniqueName}
              tabIndex={0}
              className={classes.listItemCollapsed}
            >
              <ListItem className={classes.itemSubLink} onClick={() => this.setOpenedMenuItem(null)}>
                <ListItemText
                  className={classes.itemText + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.itemTextHidden : classes.itemTextCollapsed)}
                  disableTypography={true}
                >
                  {drawerCollapsed && <>{prop.navbarName}</>}
                </ListItemText>
              </ListItem>
            </NavLink>
          </Tooltip>
        }
        if(prop.href) {
          return <Tooltip key={`submenu_item_${key}`} title={drawerCollapsed ? null : 'submenu'}>
            <a href={prop.href}
               target="_self"
               rel="noopener noreferrer"
               data-unique={prop.uniqueName}
            >
              <ListItem className={classes.itemSubLink} onClick={() => this.setOpenedMenuItem(null)}>
                <ListItemText
                  className={classes.itemText + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.itemTextHidden : classes.itemTextCollapsed)}
                  disableTypography={true}
                >
                  {drawerCollapsed && <>{prop.navbarName}</>}
                </ListItemText>
              </ListItem>
            </a>
          </Tooltip>
        }
        if(prop.onClick) {
          return <Tooltip key={`submenu_item_${key}`} title={drawerCollapsed ? null : 'submenu'}>
            <LinkButton
              onClick={() => prop.onClick(this.props)}
              data-unique={prop.uniqueName}
              tabIndex={0}
              className={classes.LinkButtonHighlight}
              >
              <ListItem tabIndex={0} className={classes.itemSubLink} onClick={() => this.setOpenedMenuItem(null)}>
                <ListItemText
                  className={classes.itemText + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.itemTextHidden : classes.itemTextCollapsed)}
                  disableTypography={true}
                >
                  {drawerCollapsed && <>{prop.navbarName}</>}
                </ListItemText>
              </ListItem>
            </LinkButton>
          </Tooltip>
        }
        return null
      }

      if (prop.path) {
        return <List className={classes.item}>
          <Tooltip title={drawerCollapsed ? null : prop.dropdownName}>

            <ListItem tabIndex={0} className={classes.itemLinkLeft + listItemClasses} style={drawerCollapsed ? null : {
              paddingLeft: 16,
              paddingRight: 16,
              width: 'auto',
              display: 'block',
              borderRadius: 3
            }}>
              <NavLink
                to={_getMainMenuItemPath()}
                activeClassName="active"
                data-unique={prop.uniqueName}
                className={classes.dropdownNavLink + whiteFontClasses + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.dropdownNavLinkHidden : classes.dropdownNavLinkCollapsed)}
              >
                <ListItemIcon>
                  {prop.icon}
                </ListItemIcon>
                <ListItemText
                  className={classes.itemText + whiteFontClasses + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.itemTextHidden : classes.itemTextCollapsed)}
                  disableTypography={true}
                >
                  {_getMainMenuItemLabel()}
                </ListItemText>
              </NavLink>
            </ListItem>
            <div tabIndex="0" className={classes.itemLinkRight + listItemClasses + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.itemTextHidden : classes.itemTextCollapsed)}
              style={!this.state.drawerOpened && !mobile ? {visibility: 'hidden', display: 'none', opacity: 0} : {display: 'inline-block', opacity: 1}}
                 onClick={() => this.setOpenedMenuItem(prop.dropdownName)}>
              {this.state.open === prop.dropdownName ? <ShowIcon custom icon="menuArrowup"/> :
                <ShowIcon custom icon="menuArrowdown"/>}
            </div>
          </Tooltip>

          <Collapse in={this.state.open === prop.dropdownName} timeout="auto" unmountOnExit>
            <List component="div" disablePadding >
              {visibleChildRoutes.filter(r => !r.hideInDropdown).map(_getSubMenuItems)}
            </List>
          </Collapse>
        </List>
      }
      if (prop.href) {
        return <List className={classes.item} style={{display: 'flex'}}>
          <Tooltip title={drawerCollapsed ? null : prop.dropdownName}>
            <a href={prop.href}
               target="_self"
               rel="noopener noreferrer"
               className={classes.item}
               data-unique={prop.uniqueName}
            >
              <ListItem tabIndex={0} className={classes.itemLinkLeft + listItemClasses} style={drawerCollapsed ? null : {
                paddingLeft: 16,
                paddingRight: 16,
                width: 'auto',
                display: 'block',
                borderRadius: 3
              }}>
                <ListItemIcon>
                  {prop.icon}
                </ListItemIcon>
                <ListItemText
                  className={classes.itemText + whiteFontClasses + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.itemTextHidden : classes.itemTextCollapsed)}
                  disableTypography={true}
                >
                  {_getMainMenuItemLabel()}
                </ListItemText>
              </ListItem>
            </a>
          </Tooltip>

          <Collapse in={this.state.open === prop.dropdownName} timeout="auto" unmountOnExit>
            <List component="div" disablePadding >
              {visibleChildRoutes.filter(r => !r.hideInDropdown).map(_getSubMenuItems)}
            </List>
          </Collapse>
        </List>
      }
      if (prop.onclick) {
        return <List className={classes.item} style={{display: 'flex'}}>
          <Tooltip title={drawerCollapsed ? null : prop.dropdownName}>

            <ListItem className={classes.itemLinkLeft} style={drawerCollapsed ? null : {
              paddingLeft: 16,
              paddingRight: 16,
              width: 'auto',
              display: 'block',
              borderRadius: 3,

            }}>
              <LinkButton tabIndex={0}
                onClick={() => prop.onclick(this.props)}
                data-unique={prop.uniqueName}
                className={classes.dropdownNavLink + whiteFontClasses + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.dropdownNavLinkHidden : classes.dropdownNavLinkCollapsed)}
              >
                <ListItemIcon>
                  {prop.icon}
                </ListItemIcon>
                <ListItemText
                  className={classes.itemText + whiteFontClasses + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.itemTextHidden : classes.itemTextCollapsed)}
                  disableTypography={true}
                >
                  {_getMainMenuItemLabel()}
                </ListItemText>
              </LinkButton>
            </ListItem>
            </Tooltip>
            <div tabIndex={0} className={classes.itemLinkRight + listItemClasses}
            style={!this.state.drawerOpened && !mobile ? {visibility: 'hidden', display: 'none', opacity: 0} : {display: 'inline-block', opacity: 1}}
                 onClick={() => this.setOpenedMenuItem(prop.dropdownName)}>
              {this.state.open === prop.dropdownName ? <ShowIcon custom icon="menuArrowup"/> :
                <ShowIcon custom icon="menuArrowdown"/>}
            </div>
          <Collapse in={this.state.open === prop.dropdownName} timeout="auto" unmountOnExit >
            <List component="div" disablePadding className={classes.listItemCollapsed}>
              {visibleChildRoutes.filter(r => !r.hideInDropdown).map(_getSubMenuItems)}
            </List>
          </Collapse>
        </List>
      }
      return null
    }

    const filteredRoutes = (mobile, filterFunc) => {
      return routes.filter(r => filterFunc(r)).map((prop, key) => {
        if (prop.redirect) return null
        if (_.isFunction(prop.sidebar)) {
          if (!license || !prop.sidebar(license)) return null
        } else if (!prop.sidebar) return null
        if (!_isRouteVisible(prop)) return null
        if (!user && !prop.unprotected) return null

        var activePro = ' '
        var listItemClasses = classNames({
          [' ' + classes[color]]: this.activeRoute(prop.path)
        })
        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: this.activeRoute(prop.path),
        })

        const uniqueName = (mobile) ? `btnSideBarMobile${prop.uniqueName}` : `btnSideBar${prop.uniqueName}`

        const subMenuItems = _visibleSubMenuItems(prop)
        if (prop.dropdownName && subMenuItems.length > 0) {
          return subMenu(prop, mobile)
        } else if (!prop.dropdownChild && prop.path ) {
          return (
            <Tooltip title={drawerCollapsed ? null : prop.title} key={key}>
              <NavLink
                to={prop.path}
                className={activePro + classes.item}
                activeClassName="active"
                key={key}
                data-unique={uniqueName}
                onClick={() => this.setState({open: null})}
              >
                <ListItem className={classes.itemLink + listItemClasses} style={drawerCollapsed ? null : {paddingLeft: 16, paddingRight: 16}}>
                  <ListItemIcon className={classes.itemIcon + whiteFontClasses} >
                    {prop.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.itemText + whiteFontClasses + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.itemTextHidden : classes.itemTextCollapsed)}
                    disableTypography={true}
                  >
                    {(drawerCollapsed || mobile) && (_.isFunction(prop.sidebarName) ? React.createElement(prop.sidebarName) : prop.sidebarName)}
                    <div className={(!drawerCollapsed) ? classes.sidebarNameSmall : classes.sidebarNameSmallCollapsed}>
                      {!drawerCollapsed && !mobile && !_.isUndefined(prop.sidebarNameSmall) && (_.isFunction(prop.sidebarNameSmall) ? React.createElement(prop.sidebarNameSmall) : prop.sidebarNameSmall)}
                    </div>
                  </ListItemText>
                  {prop.secondary && (
                    <ListItemSecondaryAction className={(!drawerCollapsed) ? classes.itemSecondaryFastTransition : classes.itemSecondary} style={!drawerCollapsed && !mobile ? {opacity: 0} : {opacity: 1}}>
                      {prop.secondary}
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </NavLink>
            </Tooltip>
          )
        } else if (!prop.dropdownChild && prop.href) {
          return (
            <Tooltip title={drawerCollapsed ? null : prop.title} key={key}>
              <a href={prop.href}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.item}
                key={key}
                data-unique={uniqueName}
              >
                <ListItem className={classes.itemLink + listItemClasses} style={drawerCollapsed ? null : {paddingLeft: 16, paddingRight: 16}}>
                <ListItemIcon className={classes.itemIcon + whiteFontClasses} >
                  {prop.icon}
                </ListItemIcon>
                  <ListItemText
                    className={classes.itemText + whiteFontClasses + ' ' + ((!this.state.drawerCollapsedCompleted && !mobile) ? classes.itemTextHidden : classes.itemTextCollapsed)}
                    disableTypography={true}
                  >
                  {(drawerCollapsed || mobile) && (_.isFunction(prop.sidebarName) ? React.createElement(prop.sidebarName) : prop.sidebarName)}
                  <div className={(!this.state.drawerCollapsedCompleted) ? classes.itemSecondaryFastTransition : classes.itemSecondary}>
                    {!drawerCollapsed && !mobile && !_.isUndefined(prop.sidebarNameSmall) && (_.isFunction(prop.sidebarNameSmall) ? React.createElement(prop.sidebarNameSmall) : prop.sidebarNameSmall)}
                  </div>
                  </ListItemText>
                </ListItem>
              </a>
            </Tooltip>
          )
        }
        return null
      })
    }

    const links = (mobile) => {
      const routesTop = filteredRoutes(mobile, r => r.position === 'top')
      const routesBottom = filteredRoutes(mobile, r => r.position === 'bottom')

      return (<List className={classes.list}>
        {routesTop.filter(r => r !== null).length > 0 && routesTop}

        {categories.map((cat, key) => {
          const routesCategories = filteredRoutes(mobile, r => r.category === cat)
          const hasCategoryRoutes = routesCategories.filter(r => r !== null).length > 0

          if (hasCategoryRoutes) {
            return (<React.Fragment key={`sidebar_cat_${cat}_${key}`}><span  className={classNames({
              [classes.sidebarSectionHeader]: true,
              [classes.sidebarSectionHeaderSmall]: !this.props.drawerCollapsed && !mobile,
              [classes.sidebarSectionHeaderNoBorder]: !this.props.user
            })}>
              {this.props.drawerCollapsed || mobile ? cat : ''}
            </span>
            {routesCategories.map((r, k) => <React.Fragment key={`sidebar_cat_route_${k}`}>{r}</React.Fragment>)}
            </React.Fragment>)
          }
          return <React.Fragment key={`sidebar_cat_${cat}_${key}`} />
        })}

        {routesBottom.filter(r => r !== null).length > 0 && <><span className={classNames({
          [classes.sidebarSectionHeader]: true,
          [classes.sidebarSectionHeaderSmall]: !this.props.drawerCollapsed && !mobile,
          [classes.sidebarSectionHeaderNoBorder]: !this.props.user
        })}></span>
          {routesBottom}
        </>}

      </List>)
    }

    const daysLeft = license && Math.ceil((moment(license.validity).toDate() - new Date()) / (1000 * 60 * 60 * 24))
    const daysLeftHint = license && (daysLeft > 1 ? `Still ${daysLeft} Trial Days Left` : daysLeft === 1 ? 'Last Trial Day' : 'Trial Over')

    const brand = (mobile) => (
      <div className={classes.logo} style={!drawerCollapsed ? {textAlign: 'center'} : {textAlign: 'right', paddingRight: 80}}>
        {license && user && <TrialCountdownPopup />}
        <Tooltip title="Cyara Botium">
          <BotiumBoxLogoSidebar whitelogoText={!drawerCollapsed} sidebar={!this.state.drawerCollapsedCompleted} sidebarLarge={this.state.drawerCollapsedCompleted} />
        </Tooltip>
        {license && !license.trial && license.edition && license.edition.startsWith('mini') && (
          <Button small danger fullWidth style={drawerCollapsed ? { width: '92%', marginLeft: 0 } : { marginBottom: -6, marginTop: 16, marginLeft: 13, width: '92%' }}>
            {!drawerCollapsed && 'TRIAL'}
            {drawerCollapsed && 'FREE TRIAL'}
          </Button>
        )}
        {license && license.trial && drawerCollapsed &&
          <Button data-unique="btnSideBarTrialHint" small danger fullWidth style={{ marginLeft: -15 }}>
            {daysLeftHint}
          </Button>
        }
        <MaintenancePopup />
      </div>
    )
    return (
      <div>
        {false && <Hidden mdUp implementation={window.Cypress ? 'js' : 'css'}>
          <Drawer
            variant="temporary"
            anchor="right"
            open={this.props.open}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand(true)}
            <div className={classes.sidebarWrapper} ref={this.sidebarPanelRef}>
              <HeaderLinks mobile />
              {links(true)}
            </div>
          </Drawer>
        </Hidden>}
        <Hidden smDown implementation={window.Cypress ? 'js' : 'css'}>
          <Drawer
            tabIndex={0}
            anchor="left"
            variant="permanent"
            open={drawerCollapsed}
            classes={{
              paper: classNames({
                [classes.drawerPaper]: true,
                [classes.drawerOpen]: drawerCollapsed,
                [classes.drawerClose]: !drawerCollapsed,
              }),
            }}
            onMouseEnter={() => {
              setDrawerCollapsed(true)
              this.setState({drawerCollapsedCompleted: true})
              this.updateDrawerOpened(true)
            }}
            onMouseLeave={() => {
              setDrawerCollapsed(false)
              this.setState({drawerCollapsedCompleted: false, open: null})
              this.updateDrawerOpened(false)
            }}
            onFocus={() => {
              setDrawerCollapsed(true)
              this.setState({ drawerCollapsedCompleted: true })
              this.updateDrawerOpened(true)
            }}
          >
            {brand(false)}
            <div ref={this.sidebarPanelRef}>
            {links(false)}
            </div>
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default connect(
  state => ({ features: state.settings.features, user: state.token.user, license: state.settings.license }),
)(withStyles(sidebarStyle)(Sidebar))
