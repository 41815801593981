import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

const style = theme => ({

  paper: {
    //padding: '0 20px 10px 20px',
    background: theme.colors.backgroundPrimary,
    borderRadius: 6,
    marginTop: 5,
  },
  leftButton: {
    marginRight: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: '5px 15px',
  },
  centerButton: {
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 0,
    padding: '5px 15px'
  },
  rightButton: {
    marginLeft: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: '5px 15px'
  },
  checked: {
    '&,&:focus': {
      backgroundColor: theme.colors.common.secondary,
      borderColor: theme.colors.common.secondary,
      color: theme.colors.common.primary,
    },
    '&:hover': {
      backgroundColor: theme.colors.common.secondaryHover,
    }
  }
})

class SwitchButton extends React.Component {

  render() {
    const { classes, onLeftClick, onRightClick, onCenterClick, leftDisabled, rightDisabled, centerDisabled, leftLabel, leftDataUnique, rightLabel, rightDataUnique, centerLabel, centerDataUnique, checked } = this.props

    return (<React.Fragment>
      <Button mini onClick={onLeftClick} disabled={leftDisabled} className={classNames({
        [classes.leftButton]: true,
        [classes.checked]: checked === 'left'
      })} {...(leftDataUnique ? { 'data-unique': leftDataUnique } : {})}>
        {leftLabel}
      </Button>
      {centerLabel && <Button mini onClick={onCenterClick} disabled={centerDisabled} className={classNames({
        [classes.centerButton]: true,
        [classes.checked]: checked === 'center'
      })} {...(centerDataUnique ? { 'data-unique': centerDataUnique } : {})}>
        {centerLabel}
      </Button>}
      <Button mini onClick={onRightClick} disabled={rightDisabled} className={classNames({
        [classes.rightButton]: true,
        [classes.checked]: checked === 'right'
      })} {...(rightDataUnique ? { 'data-unique': rightDataUnique } : {})}>
        {rightLabel}
      </Button>
    </React.Fragment>
    )
  }
}

SwitchButton.propTypes = {
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  onCenterClick: PropTypes.func,
  leftDisabled: PropTypes.bool,
  rightDisabled: PropTypes.bool,
  centerDisabled: PropTypes.bool,
  leftLabel: PropTypes.string,
  leftDataUnique: PropTypes.string,
  rightLabel: PropTypes.string,
  rightDataUnique: PropTypes.string,
  centerLabel: PropTypes.string,
  centerDataUnique: PropTypes.string,
  checked: PropTypes.string
}

export default withStyles(style)(SwitchButton)
