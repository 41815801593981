import { SHOW_FEATURE_UPGRADE } from '../constants'

export default function featureupgrade(state = {}, action) {
  switch (action.type) {
    case SHOW_FEATURE_UPGRADE:
      return {
        show: action.payload.show
      }
    default:
      return state
  }
}
