import React from 'react'

import _ from 'lodash'

import Chip from 'components/Chip/Chip'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ImageTiles from 'components/Convo/ImageTiles'
import AdaptiveCard from 'components/Card/AdaptiveCard'
import { isConfidence, toConfidence } from 'helper/numberHelper'
import Tooltip from 'components/Tooltip/Tooltip'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'

export function safeMessageText(messageText) {
  if (_.isString(messageText)) return messageText
  else return JSON.stringify(messageText, null, 2)
}

export function renderUtteranceText(textType, msg, style) {
  if (msg.utteranceSamples && msg.utteranceSamples.length > 0) {
    return <Tooltip title={`${msg.messageText} - ${msg.utteranceSamples.join(' | ')} (${msg.utteranceCount} user examples)`}>
      <Chip clickable variant="utterance" badge={`${msg.utteranceCount}${msg.not ? ' | NOT' : ''}`} label={safeMessageText(msg.messageText)} />
    </Tooltip>
  } else if (msg.utteranceSet) {
    return <Tooltip title={`${msg.messageText}`}>
    <Chip clickable variant="utterance" badge={msg.utteranceCount} label={safeMessageText(msg.messageText)} />
  </Tooltip>
  } else {
    return <Tooltip title={safeMessageText(msg.messageText || msg.sourceData)}>
      <Chip clickable variant="text" badge={msg.not ? 'NOT' : null} label={safeMessageText(msg.messageText || msg.sourceData)} />
    </Tooltip>
  }
}

export function NLPPrimaryIntentChip({ ...props }) {
  const { msg } = props

  if (msg.sender === 'bot' && msg.nlp && msg.nlp.intent && (msg.nlp.intent.name || isConfidence(msg.nlp.intent.confidence))) {
    let badge = null
    let label = ''
    const title = []
    if (msg.nlp.intent.name) {
      label = msg.nlp.intent.name
      title.push(`Detected intent: ${msg.nlp.intent.name}`)
    }
    if (isConfidence(msg.nlp.intent.confidence)) {
      badge = toConfidence(msg.nlp.intent.confidence)
      title.push(`Confidence: ${toConfidence(msg.nlp.intent.confidence)}`)
    }

    return (
      <Chip
        variant="info"
        key={'nlp_intent_name'}
        tooltip={title.join(' ')}
        avatarImage={<ShowIcon custom icon="altIntent" />}
        badge={badge}
        label={label}
      />
    )
  }
  return null
}

export function NLPAlternateIntentsChip({ ...props }) {
  const { msg } = props

  if (msg.sender === 'bot' && msg.nlp && msg.nlp.intent && msg.nlp.intent.intents) {
    const label = msg.nlp.intent.intents.length === 1 ? '1 alt intent' : `${msg.nlp.intent.intents.length} alt intents`
    const title = msg.nlp.intent.intents.map(i => {
      const result = [
        i.name
      ]
      if (isConfidence(i.confidence)) {
        result.push(`(${toConfidence(i.confidence)})`)
      }
      return result.join(' ')
    })
    return (
      <Chip
        variant="info"
        key={'nlp_intent_alt'}
        tooltip={title.join(' | ')}
        avatarImage={<ShowIcon custom icon="altIntent" />}
        label={label}
      />
    )
  }
  return null
}

export function NLPEntitiesChip({ ...props }) {
  const { msg } = props

  if (msg.sender === 'bot' && msg.nlp && msg.nlp.entities && msg.nlp.entities.length > 0) {
    const label = msg.nlp.entities.length === 1 ? '1 entity' : `${msg.nlp.entities.length} entities`
    const title = msg.nlp.entities.map(e => {
      const result = []
      if (e.name) result.push(e.name)
      if (e.value) result.push(e.value)
      if (isConfidence(e.confidence)) result.push(`(${toConfidence(e.confidence)})`)
      return result.join(' ')
    })
    return (
      <Chip
        variant="info"
        key={'nlp_entities'}
        tooltip={title.join(' | ')}
        avatarImage={<ShowIcon custom icon="entity" />}
        label={label}
      />
    )
  }
  return null
}

function RenderAdaptiveCard({...props}) {
  const {key, submittedFormData, classes, onSubmit} = props
  let card = extractAdaptiveCard(props.card)
  if (submittedFormData && submittedFormData.length) {
    // filling form with values
    const submittedFormDataAsObject = {}
    card = _.cloneDeep(card)
    for (const data of submittedFormData) {
      submittedFormDataAsObject[data.name] = data.value
    }
    for (const entry of card.body) {
      if (entry.id && _.has(submittedFormDataAsObject, entry.id)) {
        entry.value = submittedFormDataAsObject[entry.id]
      }
    }
  }
  return (
    <GridItem xs={12} key={key}>
      <div className={classes.card} >
        <div className={classes.customForm} >
        <AdaptiveCard style={{backgroundColor: 'none'}}
          payload={card}
          onActionSubmit={(action) => onSubmit && onSubmit({ data: action.data, text: action.title })}
        />
        </div>
      </div>
    </GridItem>
  )
}

function isAdaptiveCard(card) {
  return card.sourceData && (
    (card.sourceData.contentType === 'application/vnd.microsoft.card.adaptive' && card.sourceData.content)
    ||
    (card.sourceData.type === 'AdaptiveCard' && card.sourceData.body)
  )
}
function extractAdaptiveCard(sourceData) {
  return (sourceData && sourceData.contentType && sourceData.content) || sourceData
}

export function Card({...props}) {
  const {index, card, submittedFormData, classes, onButtonClick, onSubmit, allowHtmlDisplay} = props
  if (allowHtmlDisplay && isAdaptiveCard(card)) {
    return RenderAdaptiveCard({key: index, card: card.sourceData, submittedFormData, classes, onButtonClick, onSubmit, allowHtmlDisplay})
  }
  return (
    <GridItem xs={12} sm={12} key={index}>
      <div className={classes.card}>
        <GridContainer>
          {card.text && (_.isArray(card.text) ? card.text : [card.text]).map((text, index) =>
            <GridItem xs={12} key={'text_' + index}>
               <Chip
                  key={'header_' + index}
                  variant="info"
                  avatarImage={<ShowIcon custom icon="convoCardHeader" />}
                  label={text}
                  allowHtmlDisplay={allowHtmlDisplay} 
                  tooltip={text}
                  multiline
                />
            </GridItem>
          )}
          {card.subtext && (_.isArray(card.subtext) ? card.subtext : [card.subtext]).map((text, index) =>
            <GridItem xs={12} key={'subtext_' + index}>
              <Chip
                  key={'subheader_' + index}
                  variant="info"
                  avatarImage={<ShowIcon custom icon="convoCardSubHeader" />}
                  allowHtmlDisplay={allowHtmlDisplay} 
                  label={text}
                  tooltip={text}
                  multiline
                />
            </GridItem>
          )}
          {card.image && card.image.mediaUri && (
            <GridItem xs={12}>
              <ImageTiles
                images={[
                  {
                    imageSrc: card.image.mediaUri,
                    title: card.image.altText,
                    mimeType: card.image.mimeType,
                    id: index,
                  },
                ]}
                columns={{xs: 12}}
              />
            </GridItem>
          )}
          {card.content && (_.isArray(card.content) ? card.content : [card.content]).map((c, index) => (
            <GridItem xs={12} key={'content_' + index}>
              <Chip
                  key={'content_' + index}
                  variant="info"
                  avatarImage={<ShowIcon custom icon="text" />}
                  allowHtmlDisplay={allowHtmlDisplay} 
                  label={c}
                  tooltip={c}
                  multiline
                />
              <Text primary common>{c}</Text>
            </GridItem>
          ))}
          {card.media && (
            <GridItem xs={12}>
              <ImageTiles
                images={card.media.map((m, index) => ({
                  imageSrc: m.mediaUri,
                  title: m.altText,
                  mimeType: m.mimeType,
                  id: index,
                }))}
                key={'images'}
                columns={{xs: 12}}
                cards
              />
            </GridItem>
          )}
          {card.forms && card.forms.length > 0 && RenderAdaptiveCard({card: formToAdaptiveCard(card), submittedFormData, classes, onButtonClick, onSubmit, allowHtmlDisplay})}
          {card.cards && card.cards.map((subcard, index) => <Card key={index} card={subcard} submittedFormData={submittedFormData} classes={classes} onButtonClick={onButtonClick} onSubmit={onSubmit} allowHtmlDisplay={allowHtmlDisplay} md={12}/>)}
          {card.buttons && (!card.forms || card.forms.length === 0) && <GridItem xs={12}>
            {card.buttons.map((button, index) =>
              <BotButton key={'button_' + index} button={button} allowHtmlDisplay={allowHtmlDisplay} onButtonClick={onButtonClick} truncate="300px" />
            )}
          </GridItem>}
        </GridContainer>
      </div>
    </GridItem>
  )
}

export function MeButton({ ...props }) {
  const { msg } = props

  if (msg.sender === 'me' && msg.buttons && msg.buttons.length) {
    const { payloadString, textString } = formatButtonText(msg.buttons[0])
    return <Chip variant="info" avatarImage={<ShowIcon custom icon="inputButton" />} truncate="300px" tooltip={payloadString} label={textString} disabled={true}/>
  }
  return null
}

export function BotButton({ button, shorten, allowHtmlDisplay, onButtonClick, ...rest }) {
  const { textString } = formatButtonText(button)

  const buttonText = (shorten && textString && textString.length > 30) ? textString.substring(0, 27) + '...' : textString

  return <Chip
    variant="info"
    avatarImage={<ShowIcon custom icon="inputButton" />}
    label={buttonText}
    allowHtmlDisplay={allowHtmlDisplay}
    tooltip={textString}
    truncate={200}
    onClick={(e) => onButtonClick && onButtonClick(e, button)}
    {...rest}
  />
}

export function formatButtonText({ payload, text}) {
  const payloadString = payload ? _.isString(payload) ? payload : JSON.stringify(payload, null, 2) : null
  const textString = text ? _.isString(text) ? text : JSON.stringify(text) : payload ? _.isString(payload) ? payload : JSON.stringify(payload) : null
  return { payloadString, textString }
}

const formToAdaptiveCard = (cardLike) => {
  const mapFormType = (type, options) => {
    if (type === 'ChoiceSet' || type === 'RadioSet') {
      if (!options || options.findIndex(o => o.value) < 0) return 'Input.Text'
      else return 'Input.ChoiceSet'
    } else if (type === 'Text' || type === 'Number' || type === 'Date' || type === 'Time' || type === 'Toggle') {
      return `Input.${type}`
    } else {
      return 'Input.Text'
    }
  }
  const mapFormExtraProps = (form) => {
    if (form.type === 'ChoiceSet') {
      return { style: 'compact'}
    } else if (form.type === 'RadioSet') {
      return { style: 'expanded'}
    }
  }

  const body = []
  const actions = []

  if (cardLike.forms && cardLike.forms.length) {
    for (const form of cardLike.forms) {
      if (form.type === 'Title') {
        body.push({
          type: 'TextBlock',
          text: form.label || form.name,
          size: 'large'
        })
      } else if (form.type === 'SubTitle') {
        body.push({
          type: 'TextBlock',
          text: form.label || form.name,
          size: 'medium'
        })
      } else if (form.type === 'Label') {
        body.push({
          type: 'TextBlock',
          text: form.label || form.name
        })
      } else if (form.type === 'Button') {
        actions.push({
          type: 'Action.Submit',
          title: form.label || form.name
        })
      } else {
        body.push({
          type: 'TextBlock',
          text: form.label || form.name
        })
        body.push({
          type: mapFormType(form.type, form.options),
          id: form.name,
          label: form.label,
          choices: form.options,
          value: form.value || null,
          ...(mapFormExtraProps(form) || {})
        })
      }
    }
  }
  if (cardLike.buttons && cardLike.buttons.length) {
    for (const button of cardLike.buttons) {
      const { textString } = formatButtonText(button)
      actions.push({
        type: 'Action.Submit',
        title: textString
      })
    }
  }
  if (body.length > 0 && actions.length === 0) {
    actions.push({
      type: 'Action.Submit',
      title: 'Submit'
    })
  }
  if (body.length > 0 && actions.length > 0) {
    return {
      '$schema': 'http://adaptivecards.io/schemas/adaptive-card.json',
      type: 'AdaptiveCard',
      version: '1.0',
      body,
      actions
    }
  }
}

export function Form({...props}) {
  const { msg, submittedFormData, classes, onButtonClick, onSubmit, allowHtmlDisplay } = props

  if (msg.sender === 'bot' && msg.forms && msg.forms.length > 0) {
    const schema = formToAdaptiveCard(msg)
    return RenderAdaptiveCard({card: schema, submittedFormData, classes, onButtonClick, onSubmit, allowHtmlDisplay})
  }
  return null
}
