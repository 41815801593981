import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import {
  required,
  json,
  renderSelect,
  renderCodeArea,
  renderTextField,
  renderCheckbox,
  prettyPrintJson,
  FormActionsToolbar
} from 'components/Form/Form'

import { RefetchTestSetQueries, DeleteTestSetScriptsFromCache } from 'views/TestSets/gql'
import TestSetSelector from 'views/TestSets/TestSetSelector'
import { CHATBOT_QUERY } from 'views/Chatbots/gql'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import Divider from 'components/Divider/Divider'

const IMPORT_INTENTS = gql`
  mutation ImportFromAlexa(
    $chatbotId: ID!
    $testSetId: ID
    $newTestSetName: String,
    $createTestProject: Boolean,
    $importMode: ImportOverwriteMode,
    $buildconvos: Boolean,
    $expandcustomslots: Boolean,
    $expandbuiltinslots: Boolean,
    $expandbuiltinslotsid: String,
    $slotsamples: String,
    $invocation: String
  ) {
    importFromAlexa(
      chatbotId: $chatbotId
      testSetId: $testSetId
      newTestSetName: $newTestSetName,
      createTestProject: $createTestProject,
      importMode: $importMode,
      buildconvos: $buildconvos,
      expandcustomslots: $expandcustomslots,
      expandbuiltinslots: $expandbuiltinslots,
      expandbuiltinslotsid: $expandbuiltinslotsid,
      slotsamples: $slotsamples,
      invocation: $invocation
    ) { id scripts { id } }
  }
`

const slotTypeLanguages = ['en-au', 'en-ca', 'en-gb', 'en-us', 'fr-ca', 'fr-fr', 'de-de', 'hi-in', 'it-it', 'ja-jp', 'pt-br', 'es-es', 'es-mx', 'es-us']

class AlexaSMAPIImport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      importing: false
    }
    this.empty =  {
      buildintents: true,
      buildconvos: false,
      expandcustomslots: false,
      expandbuiltinslots: false,
      expandbuiltinslotsid: 'en-us',
      slotsamples: JSON.stringify({ travelDate: [ 'tomorrow', 'next week' ] }, null, 2),
      testSetId: this.props.testSetId || 'new',
      importMode: 'EXTEND'
    }
  }

  render() {
    const { importing } = this.state
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { chatbotId } = this.props

    return (<Query query={CHATBOT_QUERY} variables={{ id: chatbotId }}>
      {({ error, data }) => {
        if (error) return <ErrorFormat err={error} />

        if (!data.chatbot) return null

        return (
          <Mutation
            mutation={IMPORT_INTENTS}
            onCompleted={data => {
              this.setState({ importing: false })
              setAlertSuccessMessage('Test Cases imported')
              history.push(`/testsets/view/${data.importFromAlexa.id}`)
            }}
            onError={error => {
              this.setState({ importing: false })
              setAlertErrorMessage(`Test Case import failed`, error)
            }}
            refetchQueries={({ data }) => [
              ...RefetchTestSetQueries(data.importFromAlexa.id, license)
            ]}
            update={(store, { data }) => DeleteTestSetScriptsFromCache(store, data.importFromAlexa.scripts.map(s => s.id))}
            awaitRefetchQueries={true}
          >
            {(importFromAlexa, { loading, error }) => (
              <Form
                onSubmit={values => {
                  this.setState({ importing: true })
                  importFromAlexa({
                    variables: {
                      chatbotId: chatbotId,
                      testSetId: values.newTestSetName ? null : values.testSetId,
                      newTestSetName: values.newTestSetName,
                      createTestProject: !!values.createTestProject,
                      importMode: values.importMode,
                      buildconvos: !!values.buildconvos,
                      expandcustomslots: !!values.expandcustomslots,
                      expandbuiltinslots: !!values.expandbuiltinslots,
                      expandbuiltinslotsid: values.expandbuiltinslotsid,
                      slotsamples: values.slotsamples,
                      invocation: values.invocation
                    },
                  })
                }}
                initialValues={this.empty}
                render={({ handleSubmit, submitting, invalid, values }) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <TestSetSelector defaultNewTestSetName={data.chatbot.name + ' - Test Set'} />
                      <GridItem xs>
                          <Divider orientation="horizontal"  dividerlgnone />
                          <Text header topMargin>More Options</Text>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name="buildintents"
                          component={renderCheckbox}
                          label="Get user examples from Alexa Interaction Model intents as utterance lists"
                          type="checkbox"
                          disabled={true}
                          data-unique="chkAlexaSMAPIImportBuildIntents"
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name="buildconvos"
                          component={renderCheckbox}
                          label="Build test case convos for NLU intent assertions"
                          type="checkbox"
                          data-unique="chkAlexaSMAPIImportBuildConvos"
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name="expandcustomslots"
                          component={renderCheckbox}
                          label="Expand custom slot types in utterances with sample values from Alexa Language Model"
                          type="checkbox"
                          data-unique="chkAlexaSMAPIImportExpandCustomSlots"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="expandbuiltinslots"
                          component={renderCheckbox}
                          label="Expand builtin slot types in utterances with sample values"
                          type="checkbox"
                          data-unique="chkAlexaSMAPIImportExpandBuiltinSlots"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="expandbuiltinslotsid"
                          component={renderSelect}
                          label="Slot Language"
                          validate={required}
                          disabled={!values.expandbuiltinslots}
                          data-unique="selSMAPIImportExpandBuiltinSlotId"
                          items={slotTypeLanguages.map(l => {
                            return { key: l }
                          })}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Text helperText>
                          Built-in slot types (for example AMAZON.Airline, AMAZON.US_CITY, ...) are filled with sample values from <a href="https://developer.amazon.com/de/docs/custom-skills/slot-type-reference.html" target="_blank" rel="noopener noreferrer">here</a>, section "List Slot Types", column "Sample List Values" is used for expansion.
                        </Text>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="slotsamples"
                          component={renderCodeArea}
                          options={{ mode: 'application/json' }}
                          label="Slot names and list of sample values (JSON-formatted) to expand in utterances"
                          validate={json}
                          codeFormat={prettyPrintJson}
                          data-unique="codeAlexaSMAPIImportSlotSamples"
                        />
                        <Text helperText>
                          Matching slot names are pre-filled with all the given sample values
                        </Text>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="invocation"
                          component={renderTextField}
                          label={'Prefix each utterance with an invocation sequence'}
                          data-unique="txtAlexaSMAPIImportInvocation"
                        />
                        <Text helperText>
                          For example: "Alexa, tell myskill". For testing with the Skills Management API, you should use "Simulation Phrase" in the connector settings to dynamically add an invocation phrase - useful for NLU testing. If you want to use the test cases for other connectors, this might be required (for example with the Alexa Voice Service connector).
                        </Text>
                      </GridItem>
                      <GridItem xs={12} largePadding>
                        <FormActionsToolbar rightButtons={
                          <Button
                            type="submit"
                            disabled={importing || submitting}
                            data-unique="btnAlexaSMAPIImportDownloadModel"
                          >
                            {importing && <><LoadingIndicator /> Download is running</>}
                            {!importing && <><CloudDownloadIcon /> Download</>}
                          </Button>
                        }/>                        
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
              />
            )}
          </Mutation>
        )
      }}
    </Query>)
  }
}

AlexaSMAPIImport.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  testSetId: PropTypes.string
}

export default withRouter(connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(AlexaSMAPIImport))
