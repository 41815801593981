import React from 'react'
// @material-ui/core components
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderCheckbox,
  required,
  mustBeNumber,
  renderPasswordField,
  renderIntField,
  renderTextField,
  parseInteger
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { capSetDescription, usedByCapabilitySet } from './Helper'

// copy paste from DirectlineEdit.jsx
// + some manual changes, see comments below
export function copilotCaps2Form(caps) {
  return {
    copilot: {
      secret: (
        caps.find(c => c.name === 'COPILOT_SECRET') || { stringValue: '' }
      ).stringValue,
      webSocket: (
        caps.find(c => c.name === 'COPILOT_WEBSOCKET') || {
          booleanValue: false,
        }
      ).booleanValue,
      pollingInterval: (
        caps.find(c => c.name === 'COPILOT_POLLINGINTERVAL') || {
          intValue: 1000,
        }
      ).intValue,
      generateUsername: (
        caps.find(c => c.name === 'COPILOT_GENERATE_USERNAME') || {
          booleanValue: false,
        }
      ).booleanValue,
      buttonType: (
        caps.find(c => c.name === 'COPILOT_BUTTON_TYPE') || { stringValue: '' }
      ).stringValue,
      buttonValueField: (
        caps.find(c => c.name === 'COPILOT_BUTTON_VALUE_FIELD') || { stringValue: '' }
      ).stringValue,
    },
  }
}

const capNamesMap = {
  'copilot.secret': 'COPILOT_SECRET',
  'copilot.webSocket': 'COPILOT_WEBSOCKET',
  'copilot.pollingInterval': 'COPILOT_POLLINGINTERVAL',
  'copilot.generateUsername': 'COPILOT_GENERATE_USERNAME',
  'copilot.buttonType': 'COPILOT_BUTTON_TYPE',
  'copilot.buttonValueField': 'COPILOT_BUTTON_VALUE_FIELD',
}

export function copilotForm2caps(values) {
  const capabilities = [
    {
      name: 'COPILOT_SECRET',
      type: 'STRING',
      stringValue: values.copilot.secret || '',
    },
    {
      name: 'COPILOT_WEBSOCKET',
      type: 'BOOLEAN',
      booleanValue: values.copilot.webSocket || false,
    },
    {
      name: 'COPILOT_POLLINGINTERVAL',
      type: 'INT',
      intValue: values.copilot.pollingInterval || 1000,
    },
    {
      name: 'COPILOT_GENERATE_USERNAME',
      type: 'BOOLEAN',
      booleanValue: values.copilot.generateUsername || false,
    },
    {
      name: 'COPILOT_BUTTON_TYPE',
      type: 'STRING',
      stringValue: values.copilot.buttonType || '',
    },
    {
      name: 'COPILOT_BUTTON_VALUE_FIELD',
      type: 'STRING',
      stringValue: values.copilot.buttonValueField || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'copilot' },
  ]

  return capabilities
}

export class CopilotEdit extends React.Component {
  render() {
    const { advanced, disabled, capSetCapNames } = this.props
    return (<React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Field
            name="copilot.secret"
            component={renderPasswordField}
            // Manual change: instead of directLine.secret, use copilot.secret
            label="Copilot secret"
            validate={required}
            data-unique="pwCopilotEditSecret"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['copilot.secret']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['copilot.secret'])}
          />
          <Text helperText>
            <a
              // Manual change: different URL, and label for the URL
              href="https://learn.microsoft.com/en-us/microsoft-copilot-studio/configure-web-security"
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation how authentication works in copilot
            </a>
          </Text>
        </GridItem>
        <GridItem xs={12} sm={6}>
        </GridItem>
      </GridContainer>
      {advanced && (
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <Field
              name="copilot.generateUsername"
              component={renderCheckbox}
              label="Generate unique username for each conversation"
              type="checkbox"
              data-unique="chkCopilotEditGenerateUsername"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['copilot.generateUsername']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['copilot.generateUsername'])}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              name="copilot.webSocket"
              component={renderCheckbox}
              label="Enable Websocket Transport"
              type="checkbox"
              data-unique="chkCopilotEditWebsocket"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['copilot.webSocket']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['copilot.webSocket'])}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              name="copilot.pollingInterval"
              component={renderIntField}
              label="Polling Interval"
              parse={parseInteger}
              validate={mustBeNumber}
              data-unique="intCopilotEditPollingInterval"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['copilot.webSocket']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['copilot.webSocket'], 'Relevant only if not using Websocket Transport')}
            />
          </GridItem>
        </GridContainer>
      )}
      {advanced && (
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <Field
              name="copilot.buttonType"
              component={renderTextField}
              label="Button Activity Type"
              data-unique="txtCopilotButtonType"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['copilot.buttonType']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['copilot.buttonType'])}
            />
            <Text helperText>
              Activity type to use for simulating button clicks by the user. Depending on your implementation most likely <em>event</em> or <em>message</em>
            </Text>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              name="copilot.buttonValueField"
              component={renderTextField}
              label="Button Activity Value Field"
              data-unique="txtCopilotButtonValueField"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['copilot.buttonValueField']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['copilot.buttonValueField'])}
            />
            <Text helperText>
              Activity field to use for payload when simulating button clicks by the user. Depending on your implementation most likely <em>name</em> or <em>value</em>
            </Text>
          </GridItem>
        </GridContainer>
      )}
    </React.Fragment>)
  }
}
