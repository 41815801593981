import { SET_LOGIN, CLEAR_LOGIN } from '../constants'

export default function token(state = {}, action) {
   switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        expiryDate: action.payload.expiryDate,
        user: action.payload.user,
        lastLogin: new Date()
      }
    case CLEAR_LOGIN:
      const clone = { ...state }
      delete clone.token
      delete clone.expiryDate
      delete clone.user
      return clone
    default:
      return state
  }
}
