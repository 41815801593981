const headerSearchStyle = theme => ({

    topsearchblock: {
        width: 260,
        marginTop: 0,
    },
    searchbutton: {      
        marginTop: 5, 
        marginLeft: '-32px',
        backgroundColor: 'transparent',
        '&:hover' :{
            //backgroundColor: 'transparent',
        },
        '&:focus' :{
            //backgroundColor: 'transparent',
        },
        '& svg' :{
            height: 18,
            width: 'var(--fa-fw-width, 18px) !Important',
            color: theme.colors.primary,
            backgroundColor: 'transparent',
            margin: 0,
        }


    },
    closebutton: { 
        backgroundColor: 'transparent',
        '&:hover' :{
            backgroundColor: 'transparent',
        },
        '&:focus' :{
            backgroundColor: 'transparent',
        },
        '& svg' :{
            height: 18,
            width: 'var(--fa-fw-width, 18px) !Important',
            color: theme.colors.primary,
            backgroundColor: 'transparent',
            margin: 0,
        }
    },


})

export default headerSearchStyle