import React from 'react'
import config from 'config'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import * as jp from 'jsonpath'
// apollo
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/Button/Button'
import DropdownButton from 'components/Button/DropdownButton'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  CustomTextField,
  renderTextField,
  renderSelect,
  renderCodeArea,
  renderCheckbox,
  renderPasswordField,
  required,
  json,
  url,
  jsonpath,
  prettyPrintJson,
  CustomCodeArea,
} from 'components/Form/Form'

import FileSelectorField from 'components/Form/FileSelectorField'

import { APIKEYS_QUERY } from '../../views/Settings/gql'

import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'

import { ExtractJsonCapabilityValue, ExtractStringCapabilityValue, ExtractBooleanCapabilityValue, usedByCapabilitySet, capSetDescription } from './Helper'
import { composeValidators } from 'components/Form/Form'
import _ from 'lodash'
import Text from 'components/Typography/Text'
import Divider from 'components/Divider/Divider'

const httpVerbs = ['GET', 'POST', 'PUT', 'DELETE', 'HEAD', 'PATCH']
const httpVerbHasBody = (verb) => verb !== 'GET' && verb !== 'HEAD'

const DEFAULTS = {
  PING_HEADERS_TEMPLATE: JSON.stringify({
    'X-My-Token': 'this is some token'
  }, null, 2),
  PING_BODY_TEMPLATE: JSON.stringify({
    username: 'botium'
  }, null, 2),
  POLL_HEADERS_TEMPLATE: JSON.stringify({
    'X-Session-ID': '{{context.sessionid}}'
  }, null, 2),
  POLL_BODY_TEMPLATE: JSON.stringify({
    username: 'botium',
    sessionid: '{{context.sessionid}}'
  }, null, 2),
  HEADERS_TEMPLATE: JSON.stringify({
    'X-Botium-Project': '{{msg.header.projectname}}',
    'X-Botium-TestSession': '{{msg.header.testsessionname}}',
    'X-Botium-TestCase': '{{msg.header.name}}'
  }, null, 2),
  BODY_TEMPLATE: JSON.stringify({
    username: 'botium',
    message: '{{msg.messageText}}'
  }, null, 2)
}

const VARS = {
  PING: [
    '{{botium.conversationId}}',
    '{{context.anything}}',
    '{{fnc.now}}',
    '{{#fnc.random}}10{{/fnc.random}}',
    '{{#fnc.env}}MY_ENVIRONMENT_VARIABLE{{/fnc.env}}'
  ],
  REQ: [
    '{{botium.conversationId}}',
    '{{botium.stepId}}',
    '{{msg.messageText}}',
    '{{msg.header.projectname}}',
    '{{msg.header.testsessionname}}',
    '{{msg.header.name}}',
    '{{context.anything}}',
    '{{fnc.now}}',
    '{{#fnc.random}}10{{/fnc.random}}',
    '{{#fnc.env}}MY_ENVIRONMENT_VARIABLE{{/fnc.env}}'
  ]
}
VARS.STOP = VARS.PING
VARS.POLL = VARS.PING
VARS.INBOUND = VARS.PING

const REQHOOK = `
module.exports = ({ requestOptions, context, botium }) => {
  requestOptions.body.myCustomField = context.contextField;
  requestOptions.body.origMessageText = msg.messageText;
  requestOptions.headers.XCONVOID = botium.conversationId;
}
`.trim()

const RESHOOK = `
module.exports = ({ botMsg }) => {
  if (!botMsg.sourceData.intent) {
    botMsg.nlp = {
      intent: {
        incomprehension: true,
        confidence: 1
      }
    }
  } else {
    botMsg.nlp = {
      intent: {
        name: botMsg.sourceData.intent,
        confidence: (botMsg.sourceData.score || 0) / 100
      }
    }
  }
}
`.trim()

const PARSERHOOK = `
module.exports = ({ body, changeBody }) => {
  body.text = JSON.parse(body.text)[0]
}
`.trim()

const INBOUND_JSON = JSON.stringify({
  originalUrl: '/api/inbound/xxxx',
  originalMethod: 'POST',
  body: {
    somebodyvar: 'xxx'
  }
}, null, 2)

const capNamesMap = {
  'simpleRestConnector.initContext': 'SIMPLEREST_INIT_CONTEXT',
  'simpleRestConnector.hasInitText': 'SIMPLEREST_INIT_TEXT',
  'simpleRestConnector.initText': 'SIMPLEREST_INIT_TEXT',
  'simpleRestConnector.initProcessResponse': 'SIMPLEREST_INIT_PROCESS_RESPONSE',
  'simpleRestConnector.contextJsonPath': 'SIMPLEREST_CONTEXT_JSONPATH',
  'simpleRestConnector.pingUrl': 'SIMPLEREST_PING_URL',
  'simpleRestConnector.pingMethod': 'SIMPLEREST_PING_VERB',
  'simpleRestConnector.pingHeadersTemplate': 'SIMPLEREST_PING_HEADERS',
  'simpleRestConnector.pingHeadersTemplateEnabled': 'SIMPLEREST_PING_HEADERS',
  'simpleRestConnector.pingBodyTemplate': 'SIMPLEREST_PING_BODY',
  'simpleRestConnector.pingBodyTemplateEnabled': 'SIMPLEREST_PING_BODY',
  'simpleRestConnector.pingProcessResponse': 'SIMPLEREST_PING_PROCESS_RESPONSE',
  'simpleRestConnector.url': 'SIMPLEREST_URL',
  'simpleRestConnector.method': 'SIMPLEREST_METHOD',
  'simpleRestConnector.headersTemplate': 'SIMPLEREST_HEADERS_TEMPLATE',
  'simpleRestConnector.headersTemplateEnabled': 'SIMPLEREST_HEADERS_TEMPLATE',
  'simpleRestConnector.bodyTemplate': 'SIMPLEREST_BODY_TEMPLATE',
  'simpleRestConnector.bodyTemplateEnabled': 'SIMPLEREST_BODY_TEMPLATE',
  'simpleRestConnector.requestHook': 'SIMPLEREST_REQUEST_HOOK',
  'simpleRestConnector.requestHookEnabled': 'SIMPLEREST_REQUEST_HOOK',
  'simpleRestConnector.parserHook': 'SIMPLEREST_PARSER_HOOK',
  'simpleRestConnector.parserHookEnabled': 'SIMPLEREST_PARSER_HOOK',
  'simpleRestConnector.proxyUrl': 'SIMPLEREST_PROXY_URL',
  'simpleRestConnector.pollUrl': 'SIMPLEREST_POLL_URL',
  'simpleRestConnector.pollMethod': 'SIMPLEREST_POLL_VERB',
  'simpleRestConnector.pollHeadersTemplate': 'SIMPLEREST_POLL_HEADERS',
  'simpleRestConnector.pollHeadersTemplateEnabled': 'SIMPLEREST_POLL_HEADERS',
  'simpleRestConnector.pollBodyTemplate': 'SIMPLEREST_POLL_BODY',
  'simpleRestConnector.pollBodyTemplateEnabled': 'SIMPLEREST_POLL_BODY',
  'simpleRestConnector.inboundJsonPath': 'SIMPLEREST_INBOUND_SELECTOR_JSONPATH',
  'simpleRestConnector.inboundValue': 'SIMPLEREST_INBOUND_SELECTOR_VALUE',
  'simpleRestConnector.bodyJsonPath': 'SIMPLEREST_BODY_JSONPATH',
  'simpleRestConnector.responseJsonPath': 'SIMPLEREST_RESPONSE_JSONPATH',
  'simpleRestConnector.buttonJsonPath': 'SIMPLEREST_BUTTONS_JSONPATH',
  'simpleRestConnector.mediaJsonPath': 'SIMPLEREST_MEDIA_JSONPATH',
  'simpleRestConnector.cardsJsonPath': 'SIMPLEREST_CARDS_JSONPATH',
  'simpleRestConnector.cardTextJsonPath': 'SIMPLEREST_CARD_TEXT_JSONPATH',
  'simpleRestConnector.cardSubtextJsonPath': 'SIMPLEREST_CARD_SUBTEXT_JSONPATH',
  'simpleRestConnector.cardButtonsJsonPath': 'SIMPLEREST_CARD_BUTTONS_JSONPATH',
  'simpleRestConnector.cardAttachmentsJsonPath': 'SIMPLEREST_CARD_ATTACHMENTS_JSONPATH',
  'simpleRestConnector.responseHook': 'SIMPLEREST_RESPONSE_HOOK',
  'simpleRestConnector.responseHookEnabled': 'SIMPLEREST_RESPONSE_HOOK',
  'simpleRestConnector.ignoreEmpty': 'SIMPLEREST_IGNORE_EMPTY'
}


export function simpleRestConnectorCaps2Form(caps) {
  return {
    simpleRestConnector: {
      initContext: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'SIMPLEREST_INIT_CONTEXT')),
      hasInitText: !!caps.find(c => c.name === 'SIMPLEREST_INIT_TEXT'),
      initText: ExtractStringCapabilityValue(caps, 'SIMPLEREST_INIT_TEXT', ''),
      initProcessResponse: ExtractBooleanCapabilityValue(caps, 'SIMPLEREST_INIT_PROCESS_RESPONSE', false),
      contextJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_CONTEXT_JSONPATH', ''),
      pingUrl: ExtractStringCapabilityValue(caps, 'SIMPLEREST_PING_URL', ''),
      pingMethod: ExtractStringCapabilityValue(caps, 'SIMPLEREST_PING_VERB', 'GET'),
      pingHeadersTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'SIMPLEREST_PING_HEADERS', '')),
      pingHeadersTemplateEnabled: !!ExtractJsonCapabilityValue(caps, 'SIMPLEREST_PING_HEADERS', ''),
      pingBodyTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'SIMPLEREST_PING_BODY', '')),
      pingBodyTemplateEnabled: !!ExtractJsonCapabilityValue(caps, 'SIMPLEREST_PING_BODY', ''),
      pingProcessResponse: ExtractBooleanCapabilityValue(caps, 'SIMPLEREST_PING_PROCESS_RESPONSE', false),

      url: ExtractStringCapabilityValue(caps, 'SIMPLEREST_URL', ''),
      method: ExtractStringCapabilityValue(caps, 'SIMPLEREST_METHOD', 'GET'),
      headersTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'SIMPLEREST_HEADERS_TEMPLATE', '')),
      headersTemplateEnabled: !!ExtractJsonCapabilityValue(caps, 'SIMPLEREST_HEADERS_TEMPLATE', ''),
      bodyTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'SIMPLEREST_BODY_TEMPLATE', '')),
      bodyTemplateEnabled: !!ExtractJsonCapabilityValue(caps, 'SIMPLEREST_BODY_TEMPLATE', ''),
      requestHook: ExtractStringCapabilityValue(caps, 'SIMPLEREST_REQUEST_HOOK', ''),
      requestHookEnabled: !!ExtractStringCapabilityValue(caps, 'SIMPLEREST_REQUEST_HOOK', ''),
      parserHook: ExtractStringCapabilityValue(caps, 'SIMPLEREST_PARSER_HOOK', ''),
      parserHookEnabled: !!ExtractStringCapabilityValue(caps, 'SIMPLEREST_PARSER_HOOK', ''),

      proxyUrl: ExtractStringCapabilityValue(caps, 'SIMPLEREST_PROXY_URL', ''),

      pollUrl: ExtractStringCapabilityValue(caps, 'SIMPLEREST_POLL_URL', ''),
      pollMethod: ExtractStringCapabilityValue(caps, 'SIMPLEREST_POLL_VERB', 'GET'),
      pollHeadersTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'SIMPLEREST_POLL_HEADERS', '')),
      pollHeadersTemplateEnabled: !!ExtractJsonCapabilityValue(caps, 'SIMPLEREST_POLL_HEADERS', ''),
      pollBodyTemplate: prettyPrintJson(ExtractJsonCapabilityValue(caps, 'SIMPLEREST_POLL_BODY', '')),
      pollBodyTemplateEnabled: !!ExtractJsonCapabilityValue(caps, 'SIMPLEREST_POLL_BODY', ''),

      inboundJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_INBOUND_SELECTOR_JSONPATH', ''),
      inboundValue: ExtractStringCapabilityValue(caps, 'SIMPLEREST_INBOUND_SELECTOR_VALUE', ''),

      bodyJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_BODY_JSONPATH', ''),
      responseJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_RESPONSE_JSONPATH', '$'),
      buttonJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_BUTTONS_JSONPATH', ''),
      mediaJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_MEDIA_JSONPATH', ''),
      cardsJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_CARDS_JSONPATH', ''),
      cardTextJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_CARD_TEXT_JSONPATH', ''),
      cardSubtextJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_CARD_SUBTEXT_JSONPATH', ''),
      cardButtonsJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_CARD_BUTTONS_JSONPATH', ''),
      cardAttachmentsJsonPath: ExtractStringCapabilityValue(caps, 'SIMPLEREST_CARD_ATTACHMENTS_JSONPATH', ''),
      responseHook: ExtractStringCapabilityValue(caps, 'SIMPLEREST_RESPONSE_HOOK', ''),
      responseHookEnabled: !!ExtractStringCapabilityValue(caps, 'SIMPLEREST_RESPONSE_HOOK', ''),
      ignoreEmpty: ExtractBooleanCapabilityValue(caps, 'SIMPLEREST_IGNORE_EMPTY', true)
    }
  }
}

export function simpleRestConnectorForm2caps(values) {
  const capabilities = [
    {
      name: 'SIMPLEREST_URL',
      type: 'STRING',
      stringValue: values.simpleRestConnector.url,
    },
    {
      name: 'SIMPLEREST_METHOD',
      type: 'STRING',
      stringValue: values.simpleRestConnector.method || 'POST',
    },
    {
      name: 'SIMPLEREST_RESPONSE_JSONPATH',
      type: 'STRING',
      stringValue: values.simpleRestConnector.responseJsonPath,
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'simplerest' }
  ]

  const _addIfEnabled = (cap, fieldName) => {
    if (_.get(values, `${fieldName}Enabled`)) {
      capabilities.push(cap)
    } else {
      capabilities.push({ name: cap.name, type: 'DELETE' })
    }
  }

  // Session Setup
  capabilities.push({
    name: 'SIMPLEREST_PING_URL',
    type: 'STRING',
    stringValue: values.simpleRestConnector.pingUrl || null
  })
  capabilities.push({
    name: 'SIMPLEREST_PING_VERB',
    type: 'STRING',
    stringValue: values.simpleRestConnector.pingMethod || null
  })
  _addIfEnabled({
    name: 'SIMPLEREST_PING_HEADERS',
    type: 'JSON',
    jsonValue: values.simpleRestConnector.pingHeadersTemplate || null
  }, 'simpleRestConnector.pingHeadersTemplate')
  _addIfEnabled({
    name: 'SIMPLEREST_PING_BODY',
    type: 'JSON',
    jsonValue: values.simpleRestConnector.pingBodyTemplate || null
  }, 'simpleRestConnector.pingBodyTemplate')
  capabilities.push({
    name: 'SIMPLEREST_PING_PROCESS_RESPONSE',
    type: 'BOOLEAN',
    booleanValue: !!values.simpleRestConnector.pingProcessResponse
  })

  capabilities.push({
    name: 'SIMPLEREST_INIT_CONTEXT',
    type: 'JSON',
    jsonValue: values.simpleRestConnector.initContext || null
  })

  if (values.simpleRestConnector.hasInitText) {
    capabilities.push({
      name: 'SIMPLEREST_INIT_TEXT',
      type: 'STRING',
      stringValue: values.simpleRestConnector.initText || ''
    })
    capabilities.push({
      name: 'SIMPLEREST_INIT_PROCESS_RESPONSE',
      type: 'BOOLEAN',
      booleanValue: !!values.simpleRestConnector.initProcessResponse
    })
  } else {
    capabilities.push({ name: 'SIMPLEREST_INIT_TEXT', type: 'DELETE' })
    capabilities.push({ name: 'SIMPLEREST_INIT_PROCESS_RESPONSE', type: 'DELETE' })
  }

  capabilities.push({
    name: 'SIMPLEREST_CONTEXT_JSONPATH',
    type: 'STRING',
    stringValue: values.simpleRestConnector.contextJsonPath || null
  })

  // Request
  _addIfEnabled({
    name: 'SIMPLEREST_HEADERS_TEMPLATE',
    type: 'JSON',
    jsonValue: values.simpleRestConnector.headersTemplate || null
  }, 'simpleRestConnector.headersTemplate')
  _addIfEnabled({
    name: 'SIMPLEREST_BODY_TEMPLATE',
    type: 'JSON',
    jsonValue: values.simpleRestConnector.bodyTemplate || null
  }, 'simpleRestConnector.bodyTemplate')
  _addIfEnabled({
    name: 'SIMPLEREST_REQUEST_HOOK',
    type: 'JS',
    stringValue: values.simpleRestConnector.requestHook || null
  }, 'simpleRestConnector.requestHook')
  _addIfEnabled({
    name: 'SIMPLEREST_PARSER_HOOK',
    type: 'JS',
    stringValue: values.simpleRestConnector.parserHook || null
  }, 'simpleRestConnector.parserHook')
  capabilities.push({
    name: 'SIMPLEREST_PROXY_URL',
    type: 'STRING',
    stringValue: values.simpleRestConnector.proxyUrl || null
  })

  // Polling
  capabilities.push({
    name: 'SIMPLEREST_POLL_URL',
    type: 'STRING',
    stringValue: values.simpleRestConnector.pollUrl || null
  })
  capabilities.push({
    name: 'SIMPLEREST_POLL_VERB',
    type: 'STRING',
    stringValue: values.simpleRestConnector.pollMethod || null
  })
  _addIfEnabled({
    name: 'SIMPLEREST_POLL_HEADERS',
    type: 'JSON',
    jsonValue: values.simpleRestConnector.pollHeadersTemplate || null
  }, 'simpleRestConnector.pollHeadersTemplate')
  _addIfEnabled({
    name: 'SIMPLEREST_POLL_BODY',
    type: 'JSON',
    jsonValue: values.simpleRestConnector.pollBodyTemplate || null
  }, 'simpleRestConnector.pollBodyTemplate')

  // Response
  capabilities.push({
    name: 'SIMPLEREST_BODY_JSONPATH',
    type: 'STRING',
    stringValue: values.simpleRestConnector.bodyJsonPath || null
  })
  capabilities.push({
    name: 'SIMPLEREST_MEDIA_JSONPATH',
    type: 'STRING',
    stringValue: values.simpleRestConnector.mediaJsonPath || null
  })
  capabilities.push({
    name: 'SIMPLEREST_BUTTONS_JSONPATH',
    type: 'STRING',
    stringValue: values.simpleRestConnector.buttonJsonPath || null
  })
  _addIfEnabled({
    name: 'SIMPLEREST_RESPONSE_HOOK',
    type: 'JS',
    stringValue: values.simpleRestConnector.responseHook || null
  }, 'simpleRestConnector.responseHook')
  capabilities.push({
    name: 'SIMPLEREST_IGNORE_EMPTY',
    type: 'BOOLEAN',
    booleanValue: !!values.simpleRestConnector.ignoreEmpty
  })
  capabilities.push({
    name: 'SIMPLEREST_CARDS_JSONPATH',
    type: 'STRING',
    stringValue: values.simpleRestConnector.cardsJsonPath || null
  })

  if (_.get(values, 'simpleRestConnector.cardsJsonPath')) {
    capabilities.push({
      name: 'SIMPLEREST_CARD_TEXT_JSONPATH',
      type: 'STRING',
      stringValue: values.simpleRestConnector.cardTextJsonPath,
    })
    capabilities.push({
      name: 'SIMPLEREST_CARD_SUBTEXT_JSONPATH',
      type: 'STRING',
      stringValue: values.simpleRestConnector.cardSubtextJsonPath,
    })
    capabilities.push({
      name: 'SIMPLEREST_CARD_BUTTONS_JSONPATH',
      type: 'STRING',
      stringValue: values.simpleRestConnector.cardButtonsJsonPath,
    })
    capabilities.push({
      name: 'SIMPLEREST_CARD_ATTACHMENTS_JSONPATH',
      type: 'STRING',
      stringValue: values.simpleRestConnector.cardAttachmentsJsonPath,
    })
  } else {
    capabilities.push({ name: 'SIMPLEREST_CARD_TEXT_JSONPATH', type: 'DELETE' })
    capabilities.push({ name: 'SIMPLEREST_CARD_SUBTEXT_JSONPATH', type: 'DELETE' })
    capabilities.push({ name: 'SIMPLEREST_CARD_BUTTONS_JSONPATH', type: 'DELETE' })
    capabilities.push({ name: 'SIMPLEREST_CARD_ATTACHMENTS_JSONPATH', type: 'DELETE' })
  }

  // Inbound
  capabilities.push({
    name: 'SIMPLEREST_INBOUND_SELECTOR_JSONPATH',
    type: 'STRING',
    stringValue: values.simpleRestConnector.inboundJsonPath || null
  })
  capabilities.push({
    name: 'SIMPLEREST_INBOUND_SELECTOR_VALUE',
    type: 'STRING',
    stringValue: values.simpleRestConnector.inboundValue || null
  })

  return capabilities
}

export class SimpleRestConnectorEdit extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      setupExpanded: false,
      httpEndpointExpanded: true,
      httpResEvalExpanded: false,
      pollExpanded: false,
      inboundExpanded: false,
      sampleCodeOpen: false,
      sampleCode: '',
      sampleCodeVars: '',
      jsonPathOpen: false,
      jsonPathSample: '$.messages.*',
      jsonPathText: JSON.stringify({ messages: ['this is some sample text', 'and one more'] }, null, 2),
      jsonPathErr: '',
      jsonPathResult: '',
      showVars: null,
      showBasicAuth: null
    }
    this.state.jsonPathResult = JSON.stringify(jp.query(JSON.parse(this.state.jsonPathText), this.state.jsonPathSample), null, 2)
  }

  evalJsonPath(jsonPathSample, jsonPathText) {
    let jsonPathRoot = null
    try {
      jsonPathRoot = JSON.parse(jsonPathText)
    } catch (err) {
      this.setState({
        jsonPathErr: err.message,
        jsonPathResult: ''
      })
      return
    }
    try {
      const jsonPathResult = jp.query(jsonPathRoot, jsonPathSample)
      this.setState({
        jsonPathErr: '',
        jsonPathResult: JSON.stringify(jsonPathResult, null, 2)
      })
    } catch (err) {
      this.setState({
        jsonPathErr: err.message,
        jsonPathResult: ''
      })
      return
    }
  }

  editors = {}

  renderCodeSection({
    dataUniqueSuffix,
    headerText,
    headerFieldName,
    sampleTooltip,
    onSampleClick,
    varTooltip,
    varVars,
    varEditorName,
    httpBasic
  }, FieldComponent) {
    const { disabled, capSetCapNames } = this.props

    return <FormSpy subscription={{ values: true }} render={({ values }) => (
      <GridContainer>
        <GridItem xs={12}>
          <Field
            name={headerFieldName}
            component={renderCheckbox}
            label={`${_.get(values, headerFieldName) ? 'Disable ' : 'Enable '} ${headerText}`}
            type="checkbox"
            data-unique={`btnSimpleRestConnectorEnable${dataUniqueSuffix}`}
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap[headerFieldName]) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap[headerFieldName])}
          />
        </GridItem>
        {_.get(values, headerFieldName) && <>

          <GridItem xs={12} right style={{ marginBottom: -16 }}>
            {onSampleClick &&
              <Tooltip title={sampleTooltip}>
                <Button justIcon Border marginSmall
                  data-unique={`btnSimpleRestConnectorInsertSample${dataUniqueSuffix}`}
                  onClick={onSampleClick}>
                  <ShowIcon icon="file-alt" />
                </Button>
              </Tooltip>
            }
            {varEditorName &&
              <Tooltip title={varTooltip}>
                <DropdownButton justIcon Border marginSmall
                  items={varVars.map(v => ({
                    id: v,
                    name: v,
                    onClick: () => {
                      if (this.editors[varEditorName]) {
                        const doc = this.editors[varEditorName].getDoc()
                        const cursor = doc.getCursor()
                        doc.replaceRange(v, cursor)
                      }
                    }
                  }))}
                  disabled={disabled}
                  data-unique={`ddbtnSimpleRestConnectorInsertVar${dataUniqueSuffix}`}
                >
                  <ShowIcon icon="file-code" />
                </DropdownButton>
              </Tooltip>
            }
            {httpBasic &&
              <Tooltip title="Enable HTTP/Basic Authentication">
                <Button justIcon Border marginSmall
                  data-unique={`btnSimpleRestConnectorHttpBasic${dataUniqueSuffix}`}
                  onClick={() => this.setState({ showBasicAuth: httpBasic })}>
                  <ShowIcon icon="user-secret" />
                </Button>
              </Tooltip>
            }
            {varVars &&
              <Tooltip title="Show Template Variable Explorer">
                <Button justIcon Border marginSmall
                  data-unique={`btnSimpleRestConnectorShowVariables${dataUniqueSuffix}`}
                  onClick={() => this.setState({ showVars: varVars })}>
                  <ShowIcon icon="question" />
                </Button>
              </Tooltip>
            }
          </GridItem>
          <GridItem xs={12}>
            {FieldComponent}
          </GridItem>
        </>}
      </GridContainer>
    )} />
  }

  render() {
    const { disabled, advanced, capSetCapNames } = this.props
    const { setupExpanded, httpEndpointExpanded, httpResEvalExpanded, pollExpanded, inboundExpanded } = this.state
    const { sampleCode, sampleCodeOpen, sampleCodeVars } = this.state
    const { jsonPathOpen, jsonPathSample, jsonPathText, jsonPathErr, jsonPathResult, showVars, showBasicAuth } = this.state

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => (
      <GridContainer>
        {!advanced && <React.Fragment>
          <GridItem xs={12} sm={9}>
            <Field
              name="simpleRestConnector.url"
              component={renderTextField}
              label="HTTP(S) endpoint of your chatbot"
              validate={composeValidators(required, url)}
              data-unique="txtSimpleRestConnectorEditUrl"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.url']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.url'])}
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <Field
              name="simpleRestConnector.method"
              component={renderSelect}
              label="HTTP method"
              data-unique="selSimpleRestConnectorEditMethod"
              items={httpVerbs.map(v => {
                return { key: v }
              })}
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.method']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.method'])}
            />
          </GridItem>
          {httpVerbHasBody(values.simpleRestConnector.method) &&
            <GridItem xs={12}>
              {this.renderCodeSection(
                {
                  dataUniqueSuffix: 'BodyTemplate',
                  headerText: 'Body Template (JSON structure)',
                  headerFieldName: 'simpleRestConnector.bodyTemplateEnabled',
                  onSampleClick: () => change('simpleRestConnector.bodyTemplate', DEFAULTS.BODY_TEMPLATE),
                  sampleTooltip: 'Insert Sample Body',
                  varEditorName: 'BodyTemplate',
                  varTooltip: 'Insert Template Variable',
                  varVars: VARS.REQ
                },
                <Field
                  className="Capabilities"
                  name="simpleRestConnector.bodyTemplate"
                  label="Body Template (JSON structure)"
                  component={renderCodeArea}
                  options={{ mode: 'application/json' }}
                  validate={json}
                  codeFormat={prettyPrintJson}
                  data-unique="codeSimpleRestConnectorEditBodyTemplate"
                  editorDidMount={editor => { this.editors.BodyTemplate = editor }}
                  disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.bodyTemplate']) || disabled}
                  helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.bodyTemplate'])}
                />
              )}
            </GridItem>
          }
          <GridItem xs={12} sm={4}>
            <Field
              name="simpleRestConnector.responseJsonPath"
              component={renderTextField}
              label="JSON-Path for bot response"
              validate={jsonpath}
              data-unique="txtSimpleRestConnectorEditResponseJsonPath"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.responseJsonPath']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.responseJsonPath'], 'Should return string or array of strings')}
            />
          </GridItem>
        </React.Fragment>}
        {advanced && <GridItem xs={12}>
          <ExpansionPanel expanded={setupExpanded} onChange={() => this.setState({ setupExpanded: !setupExpanded })}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Session Setup Configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={6}>
                  <Field
                    className="Capabilities"
                    name="simpleRestConnector.initContext"
                    component={renderCodeArea}
                    options={{ mode: 'application/json' }}
                    label="Initial Conversation Context (JSON structure)"
                    validate={json}
                    codeFormat={prettyPrintJson}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.initContext']) || disabled}
                    data-unique="codeSimpleRestConnectorEditSessionSetupInitContext"
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.initContext'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        name="simpleRestConnector.hasInitText"
                        component={renderCheckbox}
                        label="Trigger Welcome Message"
                        helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.hasInitText'], 'Trigger a welcome message from the chatbot by sending some input upfront')}
                        type="checkbox"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.hasInitText']) || disabled}
                        data-unique="chkSimpleRestConnectorEditSessionSetupHasInitText"
                      />
                    </GridItem>
                    {values.simpleRestConnector.hasInitText && <>
                      <GridItem xs={12}>
                        <Field
                          name="simpleRestConnector.initText"
                          component={renderTextField}
                          label="Initial conversation text"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.initText']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.initText'], 'User input sent before actual conversation starts')}
                          data-unique="txtSimpleRestConnectorEditSessionSetupInitText"
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name="simpleRestConnector.initProcessResponse"
                          component={renderCheckbox}
                          label="Use this response as welcome messages"
                          helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.initProcessResponse'], 'Activate to use the response to this text as welcome message from the chatbot. Otherwise, it will be ignored.')}
                          type="checkbox"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.initProcessResponse']) || disabled}
                          data-unique="chkSimpleRestConnectorEditInitProcessResponse"
                        />
                      </GridItem>
                    </>}
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <Field
                    name="simpleRestConnector.pingUrl"
                    component={renderTextField}
                    label="HTTP(S) endpoint to call before start"
                    validate={url}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.pingUrl']) || disabled}
                    data-unique="txtSimpleRestConnectorEditSessionSetupPingUrl"
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.pingUrl'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Field
                    name="simpleRestConnector.pingMethod"
                    component={renderSelect}
                    label="HTTP method"
                    data-unique="selSimpleRestConnectorEditSessionSetupPingMethod"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.pingMethod']) || disabled || !values.simpleRestConnector.pingUrl}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.pingMethod'])}
                    items={httpVerbs.map(v => {
                      return { key: v }
                    })}
                  />
                </GridItem>
                {values.simpleRestConnector.pingUrl &&
                  <GridItem xs={12} sm={6}>
                    {this.renderCodeSection(
                      {
                        dataUniqueSuffix: 'PingHeadersTemplate',
                        headerText: 'Header Template (JSON structure)',
                        headerFieldName: 'simpleRestConnector.pingHeadersTemplateEnabled',
                        onSampleClick: () => change('simpleRestConnector.pingHeadersTemplate', DEFAULTS.PING_HEADERS_TEMPLATE),
                        sampleTooltip: 'Insert Sample Headers',
                        varEditorName: 'PingHeadersTemplate',
                        varTooltip: 'Insert Template Variable',
                        varVars: VARS.PING,
                        httpBasic: 'simpleRestConnector.pingHeadersTemplate'
                      },
                      <Field
                        className="Capabilities"
                        name="simpleRestConnector.pingHeadersTemplate"
                        component={renderCodeArea}
                        label="Headers Template (JSON structure)"
                        options={{ mode: 'application/json' }}
                        validate={json}
                        codeFormat={prettyPrintJson}
                        data-unique="codeSimpleRestConnectorEditSessionSetupPingHeadersTemplate"
                        editorDidMount={editor => { this.editors.PingHeadersTemplate = editor }}
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.pingHeadersTemplate']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.pingHeadersTemplate'])}
                      />
                    )}
                  </GridItem>
                }
                {values.simpleRestConnector.pingUrl && httpVerbHasBody(values.simpleRestConnector.pingMethod) &&
                  <GridItem xs={12} sm={6}>
                    {this.renderCodeSection(
                      {
                        dataUniqueSuffix: 'PingBodyTemplate',
                        headerText: 'Body Template (JSON structure)',
                        headerFieldName: 'simpleRestConnector.pingBodyTemplateEnabled',
                        onSampleClick: () => change('simpleRestConnector.pingBodyTemplate', DEFAULTS.PING_BODY_TEMPLATE),
                        sampleTooltip: 'Insert Sample Body',
                        varEditorName: 'PingBodyTemplate',
                        varTooltip: 'Insert Template Variable',
                        varVars: VARS.PING
                      },
                      <Field
                        className="Capabilities"
                        name="simpleRestConnector.pingBodyTemplate"
                        label="Body Template (JSON structure)"
                        component={renderCodeArea}
                        options={{ mode: 'application/json' }}
                        validate={json}
                        codeFormat={prettyPrintJson}
                        data-unique="codeSimpleRestConnectorEditSessionSetupPingBodyTemplate"
                        editorDidMount={editor => { this.editors.PingBodyTemplate = editor }}
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.pingBodyTemplate']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.pingBodyTemplate'])}
                      />
                    )}
                  </GridItem>
                }
                <GridItem xs={12} sm={12} />
                {values.simpleRestConnector.pingUrl &&
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="simpleRestConnector.pingProcessResponse"
                      component={renderCheckbox}
                      label="Use this response as welcome messages"
                      helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.pingProcessResponse'], 'Activate to use the response of this call as welcome message from the chatbot. Otherwise, it will be ignored.')}
                      type="checkbox"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.pingProcessResponse']) || disabled}
                      data-unique="chkSimpleRestConnectorEditPingProcessResponse"
                    />
                  </GridItem>
                }
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={httpEndpointExpanded} onChange={() => this.setState({ httpEndpointExpanded: !httpEndpointExpanded })}>
            <ExpansionPanelSummary indicator={_.isNil(values.simpleRestConnector.url) || values.simpleRestConnector.url === '' ? 'error' : null} expandIcon={<ExpandMoreIcon />}>
              Request Configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={9}>
                  <Field
                    name="simpleRestConnector.url"
                    component={renderTextField}
                    label="HTTP(S) endpoint of your chatbot"
                    validate={composeValidators(required, url)}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.url']) || disabled}
                    data-unique="txtSimpleRestConnectorEditRequestConfigUrl"
                    showIndicator={_.isNil(values.simpleRestConnector.url) || values.simpleRestConnector.url === ''}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.url'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Field
                    name="simpleRestConnector.method"
                    component={renderSelect}
                    label="HTTP method"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.method']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.method'])}
                    data-unique="selSimpleRestConnectorEditRequestConfigMethod"
                    items={httpVerbs.map(v => {
                      return { key: v }
                    })}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  {this.renderCodeSection(
                    {
                      dataUniqueSuffix: 'HeadersTemplate',
                      headerText: 'Header Template (JSON structure)',
                      headerFieldName: 'simpleRestConnector.headersTemplateEnabled',
                      onSampleClick: () => change('simpleRestConnector.headersTemplate', DEFAULTS.HEADERS_TEMPLATE),
                      sampleTooltip: 'Insert Sample Headers',
                      varEditorName: 'HeadersTemplate',
                      varTooltip: 'Insert Template Variable',
                      varVars: VARS.REQ,
                      httpBasic: 'simpleRestConnector.headersTemplate'
                    },
                    <Field
                      className="Capabilities"
                      name="simpleRestConnector.headersTemplate"
                      component={renderCodeArea}
                      label="Header Template (JSON structure)"
                      options={{ mode: 'application/json' }}
                      validate={json}
                      codeFormat={prettyPrintJson}
                      data-unique="codeSimpleRestConnectorEditRequestConfigHeadersTemplate"
                      editorDidMount={editor => { this.editors.HeadersTemplate = editor }}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.headersTemplate']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.headersTemplate'])}
                    />
                  )}
                </GridItem>
                {httpVerbHasBody(values.simpleRestConnector.method) &&
                  <GridItem xs={12} sm={6}>
                    {this.renderCodeSection(
                      {
                        dataUniqueSuffix: 'BodyTemplate',
                        headerText: 'Body Template (JSON structure)',
                        headerFieldName: 'simpleRestConnector.bodyTemplateEnabled',
                        onSampleClick: () => change('simpleRestConnector.bodyTemplate', DEFAULTS.BODY_TEMPLATE),
                        sampleTooltip: 'Insert Sample Body',
                        varEditorName: 'BodyTemplate',
                        varTooltip: 'Insert Template Variable',
                        varVars: VARS.REQ
                      },
                      <Field
                        className="Capabilities"
                        name="simpleRestConnector.bodyTemplate"
                        component={renderCodeArea}
                        label="Body Template (JSON structure)"
                        options={{ mode: 'application/json' }}
                        validate={json}
                        codeFormat={prettyPrintJson}
                        data-unique="codeSimpleRestConnectorEditRequestConfigBodyTemplate"
                        editorDidMount={editor => { this.editors.BodyTemplate = editor }}
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.bodyTemplate']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.bodyTemplate'])}
                      />
                    )}
                  </GridItem>
                }
                <GridItem xs={12} sm={6}>
                  {this.renderCodeSection(
                    {
                      dataUniqueSuffix: 'RequestHook',
                      headerText: 'Request Hook (Javascript File)',
                      headerFieldName: 'simpleRestConnector.requestHookEnabled'
                    },
                    <FileSelectorField
                      name="simpleRestConnector.requestHook"
                      change={change}
                      label="Request Hook (Javascript File)"
                      validate={required}
                      data-unique="codeSimpleRestConnectorEditRequestConfigRequestHook"
                      endAdornment={<>
                        <Tooltip title="Show Sample Code">
                          <Button justIcon dense data-unique="codeSimpleRestConnectorEditRequestConfigRequestHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: REQHOOK, sampleCodeVars: 'Variables available: requestOptions, context, msg, botium' })}>
                            <ShowIcon icon="file-alt" />
                          </Button>
                        </Tooltip>
                      </>}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.requestHook']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.requestHook'], '')}
                      initialPath="resources/scripts"
                      extensionFilter={['.js']}
                      restrictPath
                    />
                  )}
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <Field
                    name="simpleRestConnector.proxyUrl"
                    component={renderTextField}
                    label="HTTP(S) Proxy Url"
                    validate={url}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.proxyUrl']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.proxyUrl'], 'Proxy authentication to be included in the Url (https://username:password@my-proxy.com)')}
                    data-unique="txtSimpleRestConnectorEditRequestConfigProxyUrl"
                  />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={httpResEvalExpanded} onChange={() => this.setState({ httpResEvalExpanded: !httpResEvalExpanded })}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Response Evaluation
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="simpleRestConnector.bodyJsonPath"
                    component={renderTextField}
                    label="JSON-Path for slicing multiple payload elements"
                    validate={jsonpath}
                    data-unique="txtSimpleRestConnectorEditResponseEvaluationBodyJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.bodyJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.bodyJsonPath'], 'Should return JSON struct with attributes. If not given, the whole body is used.')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="simpleRestConnector.contextJsonPath"
                    component={renderTextField}
                    label="JSON-Path for conversation context"
                    validate={jsonpath}
                    data-unique="txtSimpleRestConnectorEditResponseEvaluationContextJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.contextJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.contextJsonPath'], 'Should return JSON struct with attributes. If not given, the whole body is used.')}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Field
                    name="simpleRestConnector.responseJsonPath"
                    component={renderTextField}
                    label="JSON-Path for bot response text"
                    validate={jsonpath}
                    data-unique="txtSimpleRestConnectorEditResponseEvaluationResponseJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.responseJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.responseJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Field
                    name="simpleRestConnector.mediaJsonPath"
                    component={renderTextField}
                    label="JSON-Path for media attachments"
                    validate={jsonpath}
                    data-unique="txtSimpleRestConnectorEditResponseEvaluationMediaJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.mediaJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.mediaJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Field
                    name="simpleRestConnector.buttonJsonPath"
                    component={renderTextField}
                    label="JSON-Path for Quick-Reply Recognition"
                    validate={jsonpath}
                    data-unique="txtSimpleRestConnectorEditResponseEvaluationButtonJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.buttonJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.buttonJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="simpleRestConnector.cardsJsonPath"
                    component={renderTextField}
                    label="JSON-Path for cards root objects"
                    validate={jsonpath}
                    data-unique="txtSimpleRestConnectorEditResponseEvaluationCardsJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.cardsJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.cardsJsonPath'], 'Should return string or array of strings')}
                  />
                </GridItem>
                {_.get(values, 'simpleRestConnector.cardsJsonPath') && <>
                  <GridItem xs={1}>
                  </GridItem>
                  <GridItem xs={11}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Field
                          name="simpleRestConnector.cardTextJsonPath"
                          component={renderTextField}
                          label="JSON-Path for card text"
                          validate={jsonpath}
                          data-unique="txtSimpleRestConnectorEditResponseEvaluationCardTextJsonPath"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.cardTextJsonPath']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.cardTextJsonPath'], 'Relative JSON-Path from cards root, which should return a string')}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <Field
                          name="simpleRestConnector.cardSubtextJsonPath"
                          component={renderTextField}
                          label="JSON-Path for card sub text"
                          validate={jsonpath}
                          data-unique="txtSimpleRestConnectorEditResponseEvaluationCardSubtextJsonPath"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.cardSubtextJsonPath']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.cardSubtextJsonPath'], 'Relative JSON-Path from cards root, which should return a string')}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <Field
                          name="simpleRestConnector.cardButtonsJsonPath"
                          component={renderTextField}
                          label="JSON-Path for card buttons"
                          validate={jsonpath}
                          data-unique="txtSimpleRestConnectorEditResponseEvaluationCardButtonsJsonPath"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.cardButtonsJsonPath']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.cardButtonsJsonPath'], 'Relative JSON-Path from cards root, which should return a string')}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <Field
                          name="simpleRestConnector.cardAttachmentsJsonPath"
                          component={renderTextField}
                          label="JSON-Path for card media attachments"
                          validate={jsonpath}
                          data-unique="txtSimpleRestConnectorEditResponseEvaluationCardAttachmentsJsonPath"
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.cardAttachmentsJsonPath']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.cardAttachmentsJsonPath'], 'Relative JSON-Path from cards root, which should return a string')}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>}
                <GridItem xs={12} sm={12}>
                  <Field
                    name="simpleRestConnector.ignoreEmpty"
                    component={renderCheckbox}
                    label="Ignore empty responses and events"
                    type="checkbox"
                    data-unique="chkSimpleRestConnectorEditIgnoreEmpty"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.ignoreEmpty']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.ignoreEmpty'], 'Activate to ignore empty responses and events (no text, button or media attachment)')}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  {this.renderCodeSection(
                    {
                      dataUniqueSuffix: 'ResponseHook',
                      headerText: 'Response Hook (Javascript File)',
                      headerFieldName: 'simpleRestConnector.responseHookEnabled'
                    },
                    <FileSelectorField
                      name="simpleRestConnector.responseHook"
                      change={change}
                      label="Response Hook (Javascript File)"
                      validate={required}
                      data-unique="codeSimpleRestConnectorEditResponseEvaluationResponseHook"
                      endAdornment={<>
                        <Tooltip title="Show Sample Code">
                          <Button justIcon dense data-unique="codeSimpleRestConnectorEditResponseEvaluationResponseHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: RESHOOK, sampleCodeVars: 'Variables available: botMsg, botMsgRoot, messageTextIndex, container, context, msg, botium' })}>
                            <ShowIcon icon="file-alt" />
                          </Button>
                        </Tooltip>
                      </>}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.responseHook']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.responseHook'], '')}
                      initialPath="resources/scripts"
                      extensionFilter={['.js']}
                      restrictPath
                    />
                  )}
                </GridItem>
                <GridItem xs={12} sm={6}>
                  {this.renderCodeSection(
                    {
                      dataUniqueSuffix: 'ParserHook',
                      headerText: 'Response Parser (Javascript File)',
                      headerFieldName: 'simpleRestConnector.parserHookEnabled'
                    },
                    <FileSelectorField
                      name="simpleRestConnector.parserHook"
                      change={change}
                      label="Response Parser (Javascript File)"
                      validate={required}
                      data-unique="codeSimpleRestConnectorEditResponseEvaluationParserHook"
                      endAdornment={<>
                        <Tooltip title="Show Sample Code">
                          <Button justIcon dense data-unique="codeSimpleRestConnectorEditResponseEvaluationParserHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: PARSERHOOK, sampleCodeVars: 'Variables available: body, changeBody, container, context, mgs, botium' })}>
                            <ShowIcon icon="file-alt" />
                          </Button>
                        </Tooltip>
                      </>}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.parserHook']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.parserHook'], '')}
                      initialPath="resources/scripts"
                      extensionFilter={['.js']}
                      restrictPath
                    />
                  )}
                </GridItem>
                <GridItem xs={12}>
                  <Button data-unique="btnSimpleRestConnectorEditResponseEvaluationOpenJSONPathTester" onClick={() => this.setState({ jsonPathOpen: true })}>Open JSON-Path Tester</Button>
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={pollExpanded} onChange={() => this.setState({ pollExpanded: !pollExpanded })}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Polling Configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={9}>
                  <Field
                    name="simpleRestConnector.pollUrl"
                    component={renderTextField}
                    label="HTTP(S) endpoint to poll"
                    validate={url}
                    data-unique="txtSimpleRestConnectorEditPollingConfigurationPollUrl"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.pollUrl']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.pollUrl'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Field
                    name="simpleRestConnector.pollMethod"
                    component={renderSelect}
                    label="HTTP method"
                    data-unique="selSimpleRestConnectorEditPollingConfigurationPollMethod"
                    items={httpVerbs.map(v => {
                      return { key: v }
                    })}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.pollMethod']) || disabled || !values.simpleRestConnector.pollUrl}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.pollMethod'])}
                  />
                </GridItem>
                {values.simpleRestConnector.pollUrl &&
                  <GridItem xs={12} sm={6}>
                    {this.renderCodeSection(
                      {
                        dataUniqueSuffix: 'PollHeadersTemplate',
                        headerText: 'Header Template (JSON structure)',
                        headerFieldName: 'simpleRestConnector.pollHeadersTemplateEnabled',
                        onSampleClick: () => change('simpleRestConnector.pollHeadersTemplate', DEFAULTS.POLL_HEADERS_TEMPLATE),
                        sampleTooltip: 'Insert Sample Headers',
                        varEditorName: 'PollHeadersTemplate',
                        varTooltip: 'Insert Template Variable',
                        varVars: VARS.POLL,
                        httpBasic: 'simpleRestConnector.pollHeadersTemplate'
                      },
                      <Field
                        className="Capabilities"
                        name="simpleRestConnector.pollHeadersTemplate"
                        component={renderCodeArea}
                        options={{ mode: 'application/json' }}
                        label="Header Template (JSON structure)"
                        validate={json}
                        codeFormat={prettyPrintJson}
                        data-unique="codeSimpleRestConnectorEditPollingConfigurationPollHeadersTemplate"
                        editorDidMount={editor => { this.editors.PollHeadersTemplate = editor }}
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.pollHeadersTemplate']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.pollHeadersTemplate'])}
                      />
                    )}
                  </GridItem>
                }
                {values.simpleRestConnector.pollUrl && httpVerbHasBody(values.simpleRestConnector.pollMethod) &&
                  <GridItem xs={12} sm={6}>
                    {this.renderCodeSection(
                      {
                        dataUniqueSuffix: 'PollBodyTemplate',
                        headerText: 'Body Template (JSON structure)',
                        headerFieldName: 'simpleRestConnector.pollBodyTemplateEnabled',
                        onSampleClick: () => change('simpleRestConnector.pollBodyTemplate', DEFAULTS.POLL_BODY_TEMPLATE),
                        sampleTooltip: 'Insert Sample Body',
                        varEditorName: 'PollBodyTemplate',
                        varTooltip: 'Insert Template Variable',
                        varVars: VARS.POLL
                      },
                      <Field
                        className="Capabilities"
                        name="simpleRestConnector.pollBodyTemplate"
                        component={renderCodeArea}
                        label="Body Template (JSON structure)"
                        options={{ mode: 'application/json' }}
                        validate={json}
                        codeFormat={prettyPrintJson}
                        data-unique="codeSimpleRestConnectorEditPollingConfigurationPollBodyTemplate"
                        editorDidMount={editor => { this.editors.PollBodyTemplate = editor }}
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.pollBodyTemplate']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.pollBodyTemplate'])}
                      />
                    )}
                  </GridItem>
                }
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={inboundExpanded} onChange={() => this.setState({ inboundExpanded: !inboundExpanded })}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Inbound Endpoint Configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="simpleRestConnector.inboundJsonPath"
                    component={renderTextField}
                    label="Inbound Selector JSON-Path Expression"
                    validate={jsonpath}
                    data-unique="txtSimpleRestConnectorEditInboundEndpointConfigurationInboundJsonPath"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.inboundJsonPath']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.inboundJsonPath'], 'When receiving a message on the inbound endpoint, this expression is used to extract the payload to identify the Botium session it belongs to')}
                  />
                  <pre>{INBOUND_JSON}</pre>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="simpleRestConnector.inboundValue"
                    component={renderTextField}
                    label="Inbound Selector Value"
                    validate={values.simpleRestConnector.inboundJsonPath ? required : null}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['simpleRestConnector.inboundValue']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['simpleRestConnector.inboundValue'], 'A Mustache template for comparing the Inbound Selector result')}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Button link data-unique="btnSimpleRestConnectorEditInboundEndpointConfigurationShowTemplateVariablesExplorer" onClick={() => this.setState({ showVars: VARS.INBOUND })}>Show template variables explorer</Button>
                </GridItem>
                <GridItem xs={12}><Divider /></GridItem>
                <GridItem xs={12} sm={8}>
                  <CustomTextField
                    input={{
                      value: `${config.api.ext}/inbound?APIKEY=${values.simpleRestConnector.apiKey || 'SELECT_API_KEY'}`,
                      readOnly: true,
                    }}
                    label={'Inbound Endpoint (for Copy & Paste)'}
                    helperText="Your chatbot should send any responses to this Botium endpoint"
                    data-unique="txtSimpleRestConnectorEditInboundEndpointConfigurationInboundEndpoint"
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Query query={APIKEYS_QUERY}>
                    {({ loading, error, data }) => {
                      return (<Field
                        name="simpleRestConnector.apiKey"
                        component={renderSelect}
                        label="API Key for the Inbound Endpoint"
                        disabled={disabled}
                        data-unique="selSimpleRestConnectorEditInboundEndpointConfigurationApiKey"
                        loading={loading}
                        error={error}
                        items={data && data.apikeys && data.apikeys.map(a => {
                          return { key: a.key, label: a.name }
                        })}
                      />)
                    }}
                  </Query>
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </GridItem>}
        <ConfirmationDialog
          cancelText="Close"
          open={!!sampleCodeOpen}
          onCancel={() => this.setState({ sampleCodeOpen: false })}
          title="Sample Javascript Code">
          <GridContainer>
            <GridItem xs={12}>
              {sampleCodeVars}
            </GridItem>
            <GridItem xs={12}>
              <CustomCodeArea
                label="Sample Javascript Code"
                options={{ mode: 'application/javascript' }}
                input={{
                  value: sampleCode,
                  disabled: true,
                }}
              />
            </GridItem>
          </GridContainer>
        </ConfirmationDialog>
        <ConfirmationDialog
          cancelText="Close"
          open={!!jsonPathOpen}
          onCancel={() => this.setState({ jsonPathOpen: false })}
          title="JSON-Path Tester">
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <CustomCodeArea
                label="JSON Content"
                options={{ mode: 'application/json' }}
                input={{
                  value: jsonPathText,
                  onChange: e => {
                    this.setState({ jsonPathText: e })
                    this.evalJsonPath(jsonPathSample, e)
                  }
                }}
                data-unique="codeSimpleRestConnectorEditJsonPathTesterJsonContent"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomTextField
                    label="JSON-Path Expression"
                    input={{
                      value: jsonPathSample,
                      onChange: e => {
                        this.setState({ jsonPathSample: e.target.value })
                        this.evalJsonPath(e.target.value, jsonPathText)
                      }
                    }}
                    data-unique="txtSimpleRestConnectorEditJsonPathTesterInboundJsonExpression"
                  />
                </GridItem>
                <GridItem xs={12}>
                  Read about JSON-Path Expressions <a href="http://goessner.net/articles/JsonPath/" target="_blank" rel="noopener noreferrer">here</a>.
                </GridItem>
                {jsonPathErr && <GridItem xs={12}>
                  <Text danger>{jsonPathErr}</Text>
                </GridItem>}
                {jsonPathResult && <GridItem xs={12}>
                  <pre>{jsonPathResult}</pre>
                </GridItem>}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </ConfirmationDialog>
        <ConfirmationDialog
          cancelText="Close"
          open={!!showVars}
          onCancel={() => this.setState({ showVars: null })}
          title="Mustache Template Variables Explorer">
          <GridContainer>
            <GridItem xs={12}>
              <b>Some of the template variables available in this context:</b>
            </GridItem>
            <GridItem xs={12}>
              <pre>{showVars && showVars.join('\r\n')}</pre>
            </GridItem>
            <GridItem xs={12}>
              Read about Mustache templates <a href="https://mustache.github.io/" target="_blank" rel="noopener noreferrer">here</a>.
            </GridItem>
            <GridItem xs={12}>
              More information available in the <a href="https://botium-docs.readthedocs.io/en/latest/06_connectors/01_index.html#generic-http-s-json-connector" target="_blank" rel="noopener noreferrer">Botium Docs</a>.
            </GridItem>
          </GridContainer>
        </ConfirmationDialog>
        <ConfirmationDialog
          open={!!showBasicAuth}
          onCancel={() => this.setState({ showBasicAuth: null })}
          okText="Set HTTP/Basic Authentication Headers"
          onOk={() => {
            let headers = _.get(values, showBasicAuth)
            if (headers) {
              try {
                headers = JSON.parse(headers)
              } catch (err) { }
            } else {
              headers = {}
            }
            headers.Authorization = `Basic ${btoa(`${values.httpBasicUsername}:${values.httpBasicPassword}`)}`
            change(showBasicAuth, JSON.stringify(headers, null, 2))
            this.setState({ showBasicAuth: null })
          }}
          title="Enable HTTP/Basic Authentication">
          <GridContainer>
            <GridItem xs={6}>
              <Field
                name="httpBasicUsername"
                component={renderTextField}
                label="Username"
                disabled={disabled}
                data-unique="txtSimpleRestConnectorHttpBasicUsername"
              />
            </GridItem>
            <GridItem xs={6}>
              <Field
                name="httpBasicPassword"
                component={renderPasswordField}
                label="Password"
                disabled={disabled}
                data-unique="pwSimpleRestConnectorHttpBasicPassword"
              />
            </GridItem>
          </GridContainer>
        </ConfirmationDialog>
      </GridContainer>
    )} />)
  }
}
