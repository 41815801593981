import React from 'react'
import { withRouter } from 'react-router-dom'
import ParaphraserSuggestions from './ParaphraserSuggestions.jsx'

export default withRouter(function ({ match, history }) {
  return <ParaphraserSuggestions
    testSetId={match.params.testSetId}
    scriptId={match.params.scriptId}
    scriptName={match.params.scriptName}
    onReady={() => history.push(`/testdatawizards/paraphraser/${match.params.testSetId}/uview/${match.params.scriptId}/${encodeURIComponent(match.params.scriptName)}`)}
    onCancel={() => history.push(`/testdatawizards/paraphraser/${match.params.testSetId}/uview/${match.params.scriptId}/${encodeURIComponent(match.params.scriptName)}`)}
  />
})
