import { whiteColor } from 'assets/jss/material-dashboard-react'
import { secondaryColor } from 'assets/jss/material-dashboard-react'
import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
} from 'assets/jss/material-dashboard-react.jsx'

const sidebarStyle = theme => ({
  drawerOpen: {
    width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      width: 85,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      })
    },
  drawerPaper: {
    position: 'fixed',
    border: 'none',
    overflow: 'unset',
    zIndex: '1000',
    left: 0,
    top: 0,
    ...boxShadow,
    backgroundColor: theme.colors.common.primary,
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      ...boxShadow,
      display: 'flex',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  logo: {
    position: 'relative',
    padding: '10px 15px 15px 15px',
    height: 58,
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'rgba(180, 180, 180, 0.3)',
    },
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: '#000',
      opacity: '.8',
    },
  },
  list: {
    marginTop: 13,
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '135px',
    },
    '& ul': {
      '&:focus-visible': {
      border: `2px solid white`,
      borderRadius: '5px',
      },
    },
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '8px 17px 0 15px',
    padding: 0,
    width: 'calc(100% - 30px)',
    '&:focus-visible': {
      border: `2px solid white`,
      borderRadius: '5px',
    },
  },
  itemLink: {
    height: 34,
    padding: '7px 5px 7px 10px',
    borderRadius: '3px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#FFFFFF1A'
    },
    '& svg': {
      '& path': {
        fill: '#FFFFFF'
      }
    },
    '&:focus-visible': {
      border: `2px solid white`,
      borderRadius: '5px',
    },
  },
  dropdownNavLink: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: '#FFFFFF',
    display: 'block',
  },
  dropdownNavLinkHidden: {
    borderRightWidth: 0,
  },
  dropdownNavLinkCollapsed: {
    borderRightWidth: 1,
  },
  itemLinkLeft: {
    padding: '5px 0 7px 10px',
    borderRadius: '3px 0 0 3px ',
    cursor: 'pointer',
    position: 'relative',
    width: 148,
    height: 34,
    display: 'inline-block',
    '&:hover': {
      backgroundColor: '#FFFFFF1A'
    },
    '& svg': {
      '& path': {
        fill: '#FFFFFF'
      }
    },
    '&:focus-visible': {
      border: `2px solid white`,
      borderRadius: '5px',
    },      
  },
  itemLinkRight: {
    width: 32,
    height: 34,
    //display: 'flex',
    padding: '5px 10px',
    borderRadius: '0 3px 3px 0',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: '#FFFFFF1A'
    },
    '& svg': {
      '& path': {
        fill: '#FFFFFF'
      }
    },
    '&:focus-visible': {
      border: `2px solid white`,
      borderRadius: '5px',
    },     
  },
  itemSubLink: {
    padding: '7px 10px 7px 30px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFFFFF1A'
    },
    '&:focus-visible': {
      border: `2px solid white`,
      borderRadius: '5px',
    },     
    '& svg': {
      '& path': {
        fill: '#FFFFFF'
      }
    }
  },
  customIcon: {
    '& svg': {
      '& path': {
        fill: '#FFFFFF'
      }
    }
  },
  itemIcon: {
    height: '20px',
    float: 'left',
    marginRight: 13,
    marginTop: 2,
    color: '#FFFFFF',
    '& svg': {
      fill: '#FFFFFF'
    }
  },
  itemText: {
    ...defaultFont,
    margin: '0 0 0 10px',
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#FFFFFF',
    padding: 0,
    whiteSpace: 'nowrap',
    display: 'inline-block',
    transition: theme.transitions.create('width 2s', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  itemTextHidden: {
    visibility: 'hidden',
    display: 'none',
    opacity: 0,
    //transition: 'visibility 0s, opacity 0.5s linear'
    transition: 'opacity 0.5s ease 0.3s' 
  },
  itemTextCollapsed: {
    opacity: 1,
    visibility: 'visible',
    display: 'inline-block',
    transition: 'opacity 0.5s ease'
  },
  itemArrowCollapsed: {
    position: 'absolute',
    right: 0,
    height: 34,
    //padding: '0 10px',
    borderRadius: '0 3px 3px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.common.primary,
    '&:hover': {
      backgroundColor: '#FFFFFF1A',
    },
    '& div': {
      width: 32,
      textAlign: 'center',
      '&:hover': {
        backgroundColor: 'none',
        borderLeft: '1px solid white'
      }
    }
  },
  sidebarNameSmall: {
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: 0,
      delay: 300
    }),
  },
  sidebarNameSmallCollapsed: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: 0,
      delay: 0
    }),
  },
  itemSecondaryFastTransition: {
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: 0,
      delay: 0
    }),
  },
  itemSecondary: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: 0,
      delay: 200
    }),
  },
  whiteFont: {
    color: theme.colors.common.primary,
    borderColor: theme.colors.common.primary
  },
  purple: {
    backgroundColor: theme.colors.primary,
    ...primaryBoxShadow,
    '&:hover': {
      //backgroundColor: theme.colors.primary,
      //...primaryBoxShadow,
    },
    
  },
  blue: {
    backgroundColor: secondaryColor,
    '&:hover, &:focus': {
      color: theme.colors.common.primary,
      backgroundColor: theme.colors.common.secondaryHover,
    },
    '& svg': {
      '& path': {
        fill: theme.colors.common.primary
      }
    }
  },
  green: {
    backgroundColor: successColor,
    boxShadow:
      '0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)',
    '&:hover': {
      //backgroundColor: successColor,
      boxShadow:
        '0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)',
    },
  },
  orange: {
    backgroundColor: warningColor,
    boxShadow:
      '0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)',
    '&:hover': {
      //backgroundColor: warningColor,
      boxShadow:
        '0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)',
    },
  },
  red: {
    backgroundColor: dangerColor,
    boxShadow:
      '0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)',
    '&:hover': {
      //backgroundColor: dangerColor,
      boxShadow:
        '0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)',
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: '210px',
    zIndex: '4',
    overflowScrolling: 'touch',
  },
  sidebarSectionHeader: {
    display: 'block',
    color: '#fff',
    fontSize: '0.8em',
    marginLeft: '1em',
    animationIterationCount: 1,
    '&:before': {
      content: '""',
      display:'block',
      height: '1px',
      backgroundColor: 'rgba(180, 180, 180, 0.3)',
      marginBottom: '1em',
      marginTop: '1em',
      width: 182,
      position: 'relative',
      left: 2
    },
  },
  sidebarSectionHeaderSmall: {
    '&:before': {
      marginBottom: '0',
      width: '55px',
    },
    height: 38
  },
  sidebarSectionHeaderNoBorder: {
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  collapseButton: {
    boxShadow: '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
    position: 'absolute',
    right: '-16px',
    top: 50,
    zIndex: 100,
    width: '10px',
    height: '10px',
    fontSize: 9,
    fontWeight: 'bold',
    color: whiteColor,
    backgroundColor: theme.colors.common.primary,
    border: `1px solid #b4b4b44d`,
    padding: '10px',
    '&:hover': {
      color: theme.colors.common.primary
    },
    '&:focus': {
      color: whiteColor,
      backgroundColor: theme.colors.common.primary,
    },
    '&:focus-visible': {
      border: `2px solid white`
    },

  },
  highlightedButton: {
    backgroundColor: 'rgba(0,188,212,.1)',
    boxShadow: '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
    border: '1px solid #00acc1',
    '&:hover': {
      backgroundColor: 'rgba(0,188,212,.1)',
      boxShadow: '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
    }
  },
  highlightedButtonActive: {
    backgroundColor: infoColor,
  },
 
  activePro: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '100%',
      bottom: '13px',
    },
  },
  LinkButtonHighlight: {
    '&:focus-visible': {
        border: `2px solid white`,
        borderRadius: '5px',
      },
    display: 'block',  
  },
  listItemCollapsed: {
    '&:focus-visible': {
        border: `2px solid white`,
        borderRadius: '5px',
      },
    '& div': {
      '&:focus-visible': {
        border: `2px solid white`,
        borderRadius: '5px',
      },
    }  
  }
})

export default sidebarStyle
