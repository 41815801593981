import cardStyle from 'assets/jss/material-dashboard-react/components/cardStyle.jsx'
import { infoColor } from 'assets/jss/material-dashboard-react.jsx'

const tileStyle = theme => ({
  CardCard: {
    padding: 0,
    margin: '0 0 25px 0',
    border: 0,
    minHeight: 240,
    backgroundColor: theme.colors.testdatawizardcardBg,
    cursor: 'pointer',
    '&:hover,&:focus': {
      boxShadow: '0px 0px 10px 0px ' + theme.colors.testdatawizardcardHover,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`
    },
  },
  CardCardDisabled: {
    padding: 0,
    margin: '0 0 25px 0',
    border: 0,
    minHeight: 240,
    backgroundColor: theme.colors.testdatawizardcardBg,
  },
  CardBody: {
    padding: '0 15px',
    margin: 0,
    border: 0,
    backgroundColor: 'transparent',
    
  },
  tileCard: {
    color: theme.colors.primary,
    padding: '5px 20px',
    textAlign: 'center',

  },
  tileCardSelected: {
    boxShadow: '0px 0px 2px 2px ' + infoColor
  },
  tileCardSelectedIcon: {
    float: 'right',
    marginTop: -23,
    marginRight: -28,
    backgroundColor: infoColor,
    height: 15,
    padding: 3,
    borderRadius: 18,
    color: 'white',
    boxSizing: 'border-box'
  },
  tileCardSelectedIcon2: {
    float: 'right',
    marginTop: -18,
    marginRight: -23,
    backgroundColor: infoColor,
    height: 15,
    padding: 3,
    borderRadius: 18,
    color: 'white',
    boxSizing: 'border-box'
  },
  tileCardInactive: {
    color: 'lightgrey',
    '&:hover,&:focus': {
      boxShadow: cardStyle(theme).card.boxShadow
    }
  },
  tileCardImage: {
    height: 'auto',
    position: 'relative',
    maxHeight: 117,
    
  },
  tileCardImageInactive: {
    opacity: '0.4'
  },
  tileHeader: {
    fontSize: '13px',
    overflowWrap: 'anywhere',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  tileHeaderimgCard: {
    fontSize: '16px',
    overflowWrap: 'anywhere',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  tileSubHeaderimgCard: {
    fontSize: '13px',
    overflowWrap: 'anywhere',
    lineHeight: 1.2,
    marginBottom: 20,
    marginTop: 4,
    textAlign: 'center'
  }
})

export default tileStyle
