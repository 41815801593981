import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation, compose, withApollo } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  renderPasswordField,
  renderAutoSuggest,
  required,
  url,
  composeValidators,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'
import { extractErrorMessage } from 'helper/graphHelper'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'
import { validateSpeechNoiseNameUnique } from './validators'

import {
  SPEECHNOISEPROFILE_QUERY,
  SPEECHNOISEPROFILES_QUERY,
  CREATE_SPEECHNOISEPROFILE,
  UPDATE_SPEECHNOISEPROFILE,
  DELETE_SPEECHNOISEPROFILE
} from './gql'
import { SPEECH_EFFECTS_QUERY } from 'views/TestSets/gql'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ImportPrisma from './ImportPrisma'
import Divider from 'components/Divider/Divider'
import { downloadfileformpost } from 'helper/downloadHelper'
import config from 'config'

const NEW_ENTRY = { steps: [] }

class SpeechNoiseProfile extends React.Component {
  renderForm(profile) {
    const { setAlertSuccessMessage, setAlertErrorMessage, onReady, token } = this.props

    const fillEffects = (profile) => {
      profile.effects = _.orderBy(profile.steps || [], [s => s.position], ['asc']).map(s => s.effect)
      return profile
    }
    fillEffects(profile)

    return (<>
        <GridContainer>
      {!profile.id && <>
        <GridItem xs={12} largePadding>
          <Text header>Import from File</Text>
          <ImportPrisma 
            disableUrlUpload 
            label="Select/Drop Voice Effects File" 
            disableHelper 
            uploadOnDrop 
            allowedModel="speechNoiseProfile"
          />
        </GridItem>
        <GridItem xs={12}>
          <Divider text="or" />
        </GridItem>
        <GridItem xs={12}>
          <Text header>Create a new Voice Effects Profile</Text>
        </GridItem>
      </>}
    </GridContainer>
      <Mutation
        mutation={profile.id ? UPDATE_SPEECHNOISEPROFILE : CREATE_SPEECHNOISEPROFILE}
        refetchQueries={[
          {
            query: SPEECHNOISEPROFILES_QUERY
          }
        ]}
      >
        {(mutate, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (profile.id) {
                try {
                  const res = await mutate({
                    variables: {
                      id: profile.id,
                      profile: {
                        name: values.name,
                        bspEndpoint: values.bspEndpoint || null,
                        bspApiKey: values.bspApiKey || null,
                        bspSkipCache: !!values.bspSkipCache,
                        steps: (values.effects || []).map((s, i) => ({ position: i, effect: s, args: null }))
                      }
                    }
                  })
                  form.initialize(fillEffects(res.data.updateSpeechNoiseProfile))
                  setAlertSuccessMessage('Profile updated')
                  onReady(profile.id)
                } catch(error) {
                  setAlertErrorMessage('Profile update failed', error)
                }
              } else {
                try {
                  const res = await mutate({
                    variables: {
                      profile: {
                        name: values.name,
                        bspEndpoint: values.bspEndpoint || null,
                        bspApiKey: values.bspApiKey || null,
                        bspSkipCache: !!values.bspSkipCache,
                      steps: (values.effects || []).map((s, i) => ({ position: i, effect: s, args: null }))
                      }
                    }
                  })
                  form.initialize(fillEffects(res.data.createSpeechNoiseProfile))
                  setAlertSuccessMessage('Profile registered')
                  onReady(res.data.createSpeechNoiseProfile.id)
                } catch(error) {
                  setAlertErrorMessage('Profile register failed', error)
                }
              }
            }}
            initialValues={profile}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values,
              errors
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Profile Name"
                      validate={composeValidators(required, async (value) => {
                        if (value) {
                          return validateSpeechNoiseNameUnique(this.props.client, profile.id, value)
                        }
                      })}
                      data-unique="txtProfileName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}></GridItem>
                  <GridItem xs={12}>
                  <Query
                      query={SPEECH_EFFECTS_QUERY}
                      variables={{
                        id: profile && profile.id,
                        profile: {
                          name: '',
                          bspEndpoint: values.bspEndpoint || null,
                          bspApiKey: values.bspApiKey || null,
                          bspSkipCache: !!values.bspSkipCache
                        }
                      }}
                      skip={(errors && Object.keys(errors).filter(f => f !== 'name' && f !== 'effects').length > 0)}
                    >
                      {({ loading, error, data }) => {
                        const err = error && extractErrorMessage(error)
                        const choices = data && data.speechEffects

                        let helperText = null
                        if (!loading) {
                          if (err) helperText = <Text warning><ShowIcon icon="exclamation-circle" /> {err}</Text>
                          else if (!choices || choices.length === 0) helperText = <Text warning><ShowIcon icon="exclamation-circle" /> No option found for selection.</Text>
                        }

                        return <>
                          <Field
                            name="effects"
                            component={renderAutoSuggest}
                            label="Voice Effects"
                            suggestions={choices && choices.length > 0 && choices.map(c => c.name)}
                            numsuggestions={30}
                            disabled={!choices || choices.length === 0}
                            validate={required}
                            keyFunc={p => p}
                            unique={false}
                            labelFunc={p => {
                              const choice = choices && choices.find(c => c.name === p)
                              if (choice) return `${choice.name}${choice.description ? ': ' + choice.description : ''}`
                              else return p
                            }}
                            data-unique="asProfileEffects"
                          />
                          {helperText}
                        </>
                      }}
                    </Query>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <Field
                      name="bspEndpoint"
                      component={renderTextField}
                      label="Custom Speech Processing Endpoint"
                      helperText="Endpoint for your own Botium Speech Processing installation"
                      validate={url}
                      data-unique="txtProfileEndpoint"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="bspApiKey"
                      component={renderPasswordField}
                      label="Custom API Key"
                      disabled={!values.bspEndpoint}
                      helperText="API Key for your own Botium Speech Processing installation"
                      data-unique="txtProfileApiKey"
                    />
                  </GridItem>
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtons={<>
                        {profile.id && (
                          <Mutation
                            mutation={DELETE_SPEECHNOISEPROFILE}
                            onCompleted={data => {
                              setAlertSuccessMessage('Profile deleted')
                              onReady()
                            }}
                            onError={error => {
                              setAlertErrorMessage('Profile deletion failed', error)
                            }}
                            refetchQueries={[
                              {
                                query: SPEECHNOISEPROFILES_QUERY
                              },
                            ]}
                          >
                            {(deleteProfile, { loading, error }) => (
                              <ConfirmationButton
                                confirmationText={`Are you sure you want to delete it ?`}
                                requireCheck={true}
                                onClick={() => {
                                  deleteProfile({
                                    variables: { id: profile.id },
                                  })
                                }}
                                data-unique="btnProfileDelete"
                                secondary
                                danger
                              >
                                <ShowIcon icon="trash" />
                                Delete Profile
                              </ConfirmationButton>
                            )}
                          </Mutation>
                        )}
                        {profile.id && <Button
                          data-unique="btnProfileExport"
                          onClick={() => downloadfileformpost(`${config.api.base}/modelexport/speechNoiseProfile/${profile.id}`, token).then(() => setAlertSuccessMessage('Export successful')).catch(err => setAlertErrorMessage(err.message))}
                          secondary
                        >
                          <ShowIcon icon="external-link" />
                          Export Profile
                        </Button>}
                      </>}
                      rightButtons={<>
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnProfileSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}
                          Save
                        </Button>
                      </>}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
      </>
    )
  }

  render() {
    const { id } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {id && (
            <Query query={SPEECHNOISEPROFILE_QUERY} variables={{ id }} fetchPolicy="network-only">
              {(queryResult) => <QueryStatus {...queryResult} query="speechNoiseProfile">{(data) => this.renderForm(data.speechNoiseProfile)}</QueryStatus>}
            </Query>
          )}
          {!id && this.renderForm(NEW_ENTRY)}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(settingsStyle),
  connect(
    state => ({ user: state.token.user, token: state.token.token }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  )
)(withApollo(SpeechNoiseProfile))
