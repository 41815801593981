import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import GridItem from 'components/Grid/GridItem.jsx'
import withStyles from '@material-ui/core/styles/withStyles'
import {compose} from 'react-apollo'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import MessageBox from 'components/Info/MessageBox'
import Divider from 'components/Divider/Divider.js'

import SvgUsersNumber from 'assets/img/performance_testing/UsersNumber.svg'
import SvgUsersStartNumber from 'assets/img/performance_testing/p-start.svg'
import SvgUsersEndNumber from 'assets/img/performance_testing/p-end.svg'
import SvgUsersPlusNumber from 'assets/img/performance_testing/p-plus.svg'
import SvgTestDuration from 'assets/img/performance_testing/TestDuration.svg'
import SvgStepDuration from 'assets/img/performance_testing/Stepduration.svg'
import SvgAcceptableRate from 'assets/img/performance_testing/accaptablerate.svg'
import SvgConvoSession from 'assets/img/performance_testing/sharedconversationsession.svg'
import SvgHelloConvo from 'assets/img/performance_testing/helloconversation.svg'
import SvgRequestTimeout from 'assets/img/performance_testing/requesttimeout.svg'
import SvgDetailedLogs from 'assets/img/performance_testing/detailed_logs.svg'

import SvgUsersNumberDarkmode from 'assets/img/performance_testing/darkmode/UsersNumber.svg'
import SvgUsersStartNumberDarkmode from 'assets/img/performance_testing/darkmode/p-start.svg'
import SvgUsersEndNumberDarkmode from 'assets/img/performance_testing/darkmode/p-end.svg'
import SvgUsersPlusNumberDarkmode from 'assets/img/performance_testing/darkmode/p-plus.svg'
import SvgTestDurationDarkmode from 'assets/img/performance_testing/darkmode/TestDuration.svg'
import SvgStepDurationDarkmode from 'assets/img/performance_testing/darkmode/Stepduration.svg'
import SvgAcceptableRateDarkmode from 'assets/img/performance_testing/darkmode/accaptablerate.svg'
import SvgConvoSessionDarkmode from 'assets/img/performance_testing/darkmode/sharedconversationsession.svg'
import SvgHelloConvoDarkmode from 'assets/img/performance_testing/darkmode/helloconversation.svg'
import SvgRequestTimeoutDarkmode from 'assets/img/performance_testing/darkmode/requesttimeout.svg'
import SvgDetailedLogsDarkmode from 'assets/img/performance_testing/darkmode/detailed_logs.svg'

import { OnChange } from 'react-final-form-listeners'
import { isDarkmode } from 'components/Darkmode/helper'

import performanceTestingStyle from 'assets/jss/material-dashboard-react/views/performanceTestingStyle.jsx'

import {
  renderSlider,
  renderCheckbox,
  minValue,
  maxValue,
  composeValidators,
  required,
  parseInteger
} from 'components/Form/Form'
import GridContainer from 'components/Grid/GridContainer'
import Text from 'components/Typography/Text'

export function buildPerformanceTestStartOptions (values) {
  const options = {}
  if (values.performanceTest_Type === 'load') {
    Object.assign(options, {
      name: `Load Test for ${values.name} - ${moment().format('YYYY-MM-DD HH-mm')}`,
      description: `Load Test for ${values.name}, Duration: ${values.performanceTest_tickMaxTimeMinutes}min, Multiplicator: ${values.performanceTest_tickRepeatInitial}`,
      parallelJobCount: 1,
      tickRepeatInitial: values.performanceTest_tickRepeatInitial,
      tickRepeatPerTick: 0,
      tickMaxTime: values.performanceTest_tickMaxTimeMinutes * 60 * 1000,
      tickTime: 10 * 1000,
      requiredPercentOfSuccesfulUsers: values.performanceTest_requiredPercentOfSuccesfulUsers,
      shareContainer: true,
      simpleConvo:true,
      waitForBotTimeout: 2000,
      tags: {
        set: (values.tags || []).concat('Load Test')
      }
    })
  } else if (values.performanceTest_Type === 'stress') {
    Object.assign(options, {
      name: `Stress Test for ${values.name} - ${moment().format('YYYY-MM-DD HH-mm')}`,
      description: `Stress Test for ${values.name}, Duration: ${values.performanceTest_tickMaxTimeMinutes}min, Multiplicator: ${values.performanceTest_tickRepeatInitial}, increased by ${values.performanceTest_tickRepeatPerTick}`,
      parallelJobCount: 1,
      tickRepeatInitial: values.performanceTest_tickRepeatInitial,
      tickRepeatPerTick: values.performanceTest_tickRepeatPerTick,
      tickMaxTime: values.performanceTest_tickMaxTimeMinutes * 60 * 1000,
      tickTime: 10 * 1000,
      requiredPercentOfSuccesfulUsers: values.performanceTest_requiredPercentOfSuccesfulUsers,
      shareContainer: true,
      simpleConvo:true,
      waitForBotTimeout: 2000,
      tags: {
        set: (values.tags || []).concat('Stress Test')
      }
    })
  } else if (values.performanceTest_Type === 'advanced') {
    Object.assign(options, {
      name: values.performanceTest_name || `Advanced ${values.performanceTest_tickRepeatPerTick ? 'Stress' : 'Load'} Test for ${values.name} - ${moment().format('YYYY-MM-DD HH-mm')}`,
      description: values.performanceTest_description || `Advanced ${values.performanceTest_tickRepeatPerTick ? 'Stress' : 'Load'} Test for ${values.name}`,
      parallelJobCount: values.performanceTest_parallelJobCount,
      tickRepeatInitial: values.performanceTest_tickRepeatInitial,
      tickRepeatPerTick: values.performanceTest_tickRepeatPerTick || 0,
      tickMaxTime: values.performanceTest_tickMaxTimeMinutes * 60 * 1000,
      tickTime: values.performanceTest_tickTimeSeconds * 1000,
      requiredPercentOfSuccesfulUsers: values.performanceTest_requiredPercentOfSuccesfulUsers,
      shareContainer: values.performanceTest_shareContainer,
      simpleConvo: values.performanceTest_simpleConvo,
      waitForBotTimeout: values.performanceTest_waitForBotTimeoutSeconds * 1000,
      detailedReporting: values.performanceTest_detailedReporting,
      tags: {
        set: (values.tags || []).concat('Advanced Performance Test')
      }
    })
  }
  return options
}

export const MAX_PARALLEL_USER_NO_LICENSE = 1000
export const MAX_PARALLEL_USER_VISUAL = 100

class PerformanceTestSessionRegisterForm extends React.Component {

  renderLoadTest(form, values, maxConvoCount) {
    const { license, classes} = this.props
    return <GridContainer>
      <GridItem xs={9}>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <div className={classes.sliderContainerRoot}>
              <img src={isDarkmode() ? SvgTestDurationDarkmode : SvgTestDuration} alt="How long should the Test run?" className={classes.sliderContainerImage} />
              <div className={classes.sliderContainer}>
                <Field
                  name="performanceTest_tickMaxTimeMinutes"
                  component={renderSlider}
                  label="How long should the Test run?"
                  helperText="in Minutes"
                  parse={parseInteger}
                  min={1}
                  max={!_.isNil(license.performanceTestingOptions.maxTickMaxTime) ? license.performanceTestingOptions.maxTickMaxTime : 120}
                  validate={composeValidators(required, minValue(1), maxValue(!_.isNil(license.performanceTestingOptions.maxTickMaxTime) ? license.performanceTestingOptions.maxTickMaxTime : 120))}
                  step={1}
                  form={form}
                  data-unique="txtTestProjectPerformanceTestTickMaxTimeMinutes"
                  showInputField
                />
                <OnChange name="performanceTest_tickMaxTimeMinutes">
                  {(value, previous) => {
                    if (value && value !== previous) {
                      calculateAndSetRepeatPerTick(values, form)
                    }
                  }}
                </OnChange>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <div style={{padding: '10px 10px', display: 'flex', alignItems: 'center'}}>
              <img src={isDarkmode() ? SvgUsersNumberDarkmode : SvgUsersNumber} alt="How many parallel users should be simulated?"
                   className={classes.sliderContainerImage}/>
              <div className={classes.sliderContainer}>
                <Field
                  name="performanceTest_tickRepeatInitial"
                  component={renderSlider}
                  label="How many Parallel Users should be simulated?"
                  helperText={`Maximum ${license.performanceTestingOptions.maxTickRepeatInitial || license.performanceTestingOptions.maxTickRepeatMax}`}
                  min={1}
                  max={license.performanceTestingOptions.maxTickRepeatInitial || license.performanceTestingOptions.maxTickRepeatMax}
                  maxSlider={Math.min(license.performanceTestingOptions.maxTickRepeatInitial || license.performanceTestingOptions.maxTickRepeatMax, MAX_PARALLEL_USER_VISUAL)}
                  validate={composeValidators(required, minValue(1), maxValue(license.performanceTestingOptions.maxTickRepeatInitial || license.performanceTestingOptions.maxTickRepeatMax))}
                  step={1}
                  parse={parseInteger}
                  form={form}
                  data-unique="txtTestProjectPerformanceTestTickRepeatInitial"
                  showInputField
                  inputWidth={80}
                />
              </div>
            </div>
          </GridItem>
          <OnChange name="performanceTest_tickRepeatInitial">
            {(value, previous) => {
              if (value && value !== previous) {
                const performanceTest_tickRepeatMax = Math.max(values.performanceTest_tickRepeatInitial, values.performanceTest_tickRepeatMax)
                form.change('performanceTest_tickRepeatMax', performanceTest_tickRepeatMax)
                calculateAndSetRepeatPerTick(Object.assign({}, values, {performanceTest_tickRepeatMax}), form)
              }
            }}
          </OnChange>
          <GridItem xs={12} sm={6}>
            <div className={classes.sliderContainerRoot}>
              <img src={isDarkmode() ? SvgAcceptableRateDarkmode : SvgAcceptableRate} alt="Required percentage of successful users" className={classes.sliderContainerImage} />
              <div className={classes.sliderContainer}>
                <Field
                  name="performanceTest_requiredPercentOfSuccesfulUsers"
                  component={renderSlider}
                  label="Required percentage of Successful Users?"
                  helperText="in Percent"
                  min={0}
                  max={100}
                  validate={composeValidators(required, minValue(0), maxValue(100))}
                  parse={parseInteger}
                  form={form}
                  disabled={license.performanceTestingOptions.tickMaxErrorPercent === false}
                  data-unique="txtTestProjectPerformanceTestRequiredPercentOfSuccesfulUsers"
                  showInputField
                />
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={3}><br />
        <div className={classes.explanationBox}>
          <strong>What is a Load Test?</strong><br />
          The slightest increase in Processing Time can be critical for a long running System like a Chatbot. This can be tested by sending a Constant Load.
          <br />
          Botium generates Constant Load by simulating a constant number of parallel Users.<br/><br/>
          Every User is repeating a simple 'Hello' conversation.<br/><br/>
          To simulate human interaction speed a convo is repeated max once per second, which is usually much more load than an average real life user generates.
        </div><br />
      </GridItem>
      <GridItem xs={12}>
        <MessageBox
          variant="warning"
          title="Additional cost may be involved!"
          text={`Performance test will simulate max ${_.isNumber(maxConvoCount) ? maxConvoCount : 'N/A'} conversations (requests) on your chatbot (Depending on its response time).`}
        />
      </GridItem>
    </GridContainer>
  }

  renderStressTest(form, values, maxConvoCount, maxUsers) {
    const { license, classes} = this.props
    return <GridContainer>
      <GridItem xs={9}>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <div style={{ padding: '10px 10px', display: 'flex', alignItems: 'center'}}>
              <img src={isDarkmode() ? SvgTestDurationDarkmode : SvgTestDuration} alt="Test Duration" className={classes.sliderContainerImage} />
              <div className={classes.sliderContainer}>
                <Field
                  name="performanceTest_tickMaxTimeMinutes"
                  component={renderSlider}
                  label="How long should the Test run?"
                  helperText="in Minutes"
                  parse={parseInteger}
                  min={1}
                  max={license.performanceTestingOptions.maxTickMaxTime ? license.performanceTestingOptions.maxTickMaxTime : 120}
                  validate={composeValidators(required, minValue(1), maxValue(license.performanceTestingOptions.maxTickMaxTime ? license.performanceTestingOptions.maxTickMaxTime : 120))}
                  step={1}
                  form={form}
                  data-unique="txtTestProjectPerformanceTestTickMaxTimeMinutes"
                  showInputField
                />
                <OnChange name="performanceTest_tickMaxTimeMinutes">
                  {(value, previous) => {
                    if (value && value !== previous) {
                      calculateAndSetRepeatPerTick(values, form)
                    }
                  }}
                </OnChange>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <div className={classes.sliderContainerRoot}>
              <img src={isDarkmode() ? SvgUsersStartNumberDarkmode : SvgUsersStartNumber} alt="With how many Parallel Users should the Test start?" className={classes.sliderContainerImage} />
              <div className={classes.sliderContainer}>
                <Field
                  name="performanceTest_tickRepeatInitial"
                  component={renderSlider}
                  label="With how many Parallel Users should the Test start?"
                  helperText={`Maximum ${values.performanceTest_tickRepeatMax}`}
                  min={1}
                  max={values.performanceTest_tickRepeatMax}
                  maxSlider={Math.min(values.performanceTest_tickRepeatMax, MAX_PARALLEL_USER_VISUAL)}
                  step={1}
                  validate={composeValidators(required, minValue(1), maxValue(({values}) => values.performanceTest_tickRepeatMax))}
                  parse={parseInteger}
                  form={form}
                  data-unique="txtTestProjectPerformanceTickRepeatInitial"
                  showInputField
                />
                <OnChange name="performanceTest_tickRepeatInitial">
                  {(value, previous) => {
                    if (value && value !== previous) {
                      calculateAndSetRepeatPerTick(values, form)
                    }
                  }}
                </OnChange>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <div className={classes.sliderContainerRoot}>
              <img src={isDarkmode() ? SvgUsersEndNumberDarkmode : SvgUsersEndNumber} alt="Increase" className={classes.sliderContainerImage} />
              <div className={classes.sliderContainer}>
                <Field
                  name="performanceTest_tickRepeatMax"
                  component={renderSlider}
                  label="With how many Parallel Users should the Test end?"
                  helperText={`Minimum ${values.performanceTest_tickRepeatInitial}, maximum ${license.performanceTestingOptions.maxTickRepeatMax}`}
                  min={values.performanceTest_tickRepeatInitial}
                  max={license.performanceTestingOptions.maxTickRepeatMax}
                  maxSlider={Math.min(values.performanceTest_tickRepeatInitial + MAX_PARALLEL_USER_VISUAL, license.performanceTestingOptions.maxTickRepeatMax)}
                  validate={composeValidators(required, minValue(({values}) => values.performanceTest_tickRepeatInitial), maxValue(license.performanceTestingOptions.maxTickRepeatMax))}
                  parse={parseInteger}
                  form={form}
                  data-unique="txtTestProjectPerformanceTestMaxTickRepeatMax"
                  showInputField
                />
                <OnChange name="performanceTest_tickRepeatMax">
                  {(value, previous) => {
                    if (value && value !== previous) {
                      calculateAndSetRepeatPerTick(values, form)
                    }
                  }}
                </OnChange>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <div className={classes.sliderContainerRoot}>
              <img src={isDarkmode() ? SvgAcceptableRateDarkmode : SvgAcceptableRate} alt="Required percentage of successful users" className={classes.sliderContainerImage} />
              <div className={classes.sliderContainer}>
                <Field
                  name="performanceTest_requiredPercentOfSuccesfulUsers"
                  component={renderSlider}
                  label="Required percentage of Successful Users?"
                  helperText="in Percent"
                  min={0}
                  max={100}
                  validate={composeValidators(required, minValue(0), maxValue(100))}
                  parse={parseInteger}
                  form={form}
                  disabled={license.performanceTestingOptions.tickMaxErrorPercent === false}
                  data-unique="txtTestProjectPerformanceTestRequiredPercentOfSuccesfulUsers"
                  showInputField
                />
              </div>
            </div>
          </GridItem>
          <GridItem xs={12}>
            <strong>Summary</strong><br/>
            <GridContainer nounset>
              <GridItem xs={12} sm={4}>
                <Card>
                  <CardBody>
                    <GridContainer alignItems="center" justify="center">
                      <GridItem xs={4}>
                        <img src={isDarkmode() ? SvgStepDurationDarkmode : SvgStepDuration} alt="Test Steps" />
                      </GridItem>
                      <GridItem xs={8}>
                        <Text primary>
                          <strong>Test Steps</strong><br />
                          <NumberFormat value={Math.ceil(values.performanceTest_tickMaxTimeMinutes * 60 / values.performanceTest_tickTimeSeconds)} displayType={'text'} thousandSeparator/>
                        </Text>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Card>
                  <CardBody>
                    <GridContainer alignItems="center" justify="center">
                      <GridItem xs={4}>
                        <img src={isDarkmode() ? SvgUsersPlusNumberDarkmode : SvgUsersPlusNumber} alt="New Users per Test Step" />
                      </GridItem>
                      <GridItem xs={8}>
                        <Text primary>
                          <strong>New Users per Test Step</strong><br />
                          <NumberFormat value={values.performanceTest_tickRepeatPerTick} displayType={'text'} thousandSeparator/>
                        </Text>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Card>
                  <CardBody>
                    <GridContainer alignItems="center" justify="center">
                      <GridItem xs={4}>
                        <img src={isDarkmode() ? SvgUsersNumberDarkmode : SvgUsersNumber} alt="Total Convos" />
                      </GridItem>
                      <GridItem xs={8}>
                        <Text primary>
                          <strong>Maximum Number of Users</strong><br />
                          {_.isNumber(maxUsers) ? <NumberFormat value={maxUsers} displayType={'text'} thousandSeparator/> : 'N/A'}
                        </Text>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={3}><br />
        <div className={classes.explanationBox}>
          <strong>What is a Stress Test?</strong><br />
          The amount of Parallel Requests can be critical for a System like a Chatbot. This can be tested by sending an Increasing Load.
          <br />
          Botium generates an Increasing Load by adding new Users every 10 seconds.<br/><br/>
          Every User is repeating a simple 'Hello' conversation.<br/><br/>
          To simulate human interaction speed a convo is repeated max once per second, which is usually much more load than an average real life user generates.
        </div>
      </GridItem>
      <GridItem xs={12}>
        <MessageBox
          variant="warning"
          title="Additional cost may be involved!"
          text={`Performance test will simulate max ${_.isNumber(maxConvoCount) ? maxConvoCount : 'N/A'} conversations (requests) on your chatbot (Depending on its response time).`}
        />
      </GridItem>
    </GridContainer>
  }

  renderAdvancedTest(form, values, maxConvoCount, maxUsers, validateMaxUser, calculationPossible, testSteps, maxUsersTooMuch) {
    const { license, classes, showUseHello } = this.props
    return <GridContainer>
      <GridItem xs={12}>
        <Card borderbottom>
          <CardBody noPadding>
            <Text header>Duration</Text>

            <GridContainer alignItems="center">
              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem xs={12} middle>
                    <img src={isDarkmode() ? SvgTestDurationDarkmode : SvgTestDuration} alt="Test Duration" className={classes.sliderContainerImage} />
                    <div className={classes.sliderContainer}>
                      <Field
                        name="performanceTest_tickMaxTimeMinutes"
                        component={renderSlider}
                        label="How long should the Test run?"
                        helperText="in Minutes"
                        min={1}
                        max={license.performanceTestingOptions.maxTickMaxTime || 120}
                        validate={composeValidators(required, validateMaxUser, minValue(1), maxValue(license.performanceTestingOptions.maxTickMaxTime || 120))}
                        parse={parseInteger}
                        form={form}
                        showInputField
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} middle>
                    <img src={isDarkmode() ? SvgStepDurationDarkmode : SvgStepDuration} alt="Step Duration" className={classes.sliderContainerImage} />
                    <div className={classes.sliderContainer}>
                      <Field
                        name="performanceTest_tickTimeSeconds"
                        component={renderSlider}
                        label="How long should the Test Step be set?"
                        helperText="in Seconds"
                        min={1}
                        max={license.performanceTestingOptions.maxTickTime || 100}
                        validate={composeValidators(required, validateMaxUser, minValue(1), maxValue(Math.min(license.performanceTestingOptions.maxTickTime || 100, values.performanceTest_tickMaxTimeMinutes * 60), 'kakás'))}
                        parse={parseInteger}
                        form={form}
                        showInputField
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <div className={classes.explanationBox}>
                      Test Step is used for increasing the Number of Users, and for checking the percentage of Successful Users. (See parameters below)
                    </div>
                  </GridItem>
                  <GridItem xs={12}>
                    &nbsp;
                  </GridItem>
                  <GridItem xs={12}>
                    <div className={classes.explanationBox}>
                      The Performance Test will have <Text info style={{display: 'inline'}}><strong>{_.isNumber(testSteps) ? testSteps : 'N/A'} Test Steps</strong></Text>.
                    </div>
                  </GridItem>
                  {maxUsersTooMuch && <GridItem xs={12}>
                    &nbsp;
                  </GridItem>}
                  {maxUsersTooMuch && <GridItem xs={12}>
                    <div className={classes.warningBox}>
                      {`The Number of Users in Last Step (${maxUsers}) can't exceed ${license.performanceTestingOptions.maxTickRepeatMax}! Please consider lowering the number of test steps!`}
                    </div>
                  </GridItem>}
                  {(values.performanceTest_tickMaxTimeMinutes * 60 < values.performanceTest_tickTimeSeconds) && <GridItem xs={12}>
                    &nbsp;
                  </GridItem>}
                  {(values.performanceTest_tickMaxTimeMinutes * 60 < values.performanceTest_tickTimeSeconds) && <GridItem xs={12}>
                    <div className={classes.warningBox}>
                      {`Test Step Duration (${values.performanceTest_tickTimeSeconds}s) can't exceed Test Duration (${values.performanceTest_tickMaxTimeMinutes}min = ${values.performanceTest_tickMaxTimeMinutes * 60}s)!`}
                    </div>
                  </GridItem>}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card borderbottom>
          <CardBody noPadding>
            <Text header>Users</Text>

            <GridContainer alignItems="center">
              <GridItem xs={12} sm={6} >
                <GridContainer>
                  <GridItem xs={12} middle>
                    <img src={isDarkmode() ? SvgUsersStartNumberDarkmode : SvgUsersStartNumber} alt="With how many Parallel Users should the Test start?" className={classes.sliderContainerImage} />
                    <div className={classes.sliderContainer}>
                      <Field
                        name="performanceTest_tickRepeatInitial"
                        component={renderSlider}
                        label="With how many Parallel Users should the Test start?"
                        helperText={`Maximum ${license.performanceTestingOptions.maxTickRepeatInitial || license.performanceTestingOptions.maxTickRepeatMax}`}
                        min={1}
                        step={1}
                        max={license.performanceTestingOptions.maxTickRepeatInitial || license.performanceTestingOptions.maxTickRepeatMax}
                        maxSlider={Math.min(license.performanceTestingOptions.maxTickRepeatInitial || license.performanceTestingOptions.maxTickRepeatMax, MAX_PARALLEL_USER_VISUAL)}
                        validate={composeValidators(required, validateMaxUser, minValue(1), maxValue(license.performanceTestingOptions.maxTickRepeatInitial || license.performanceTestingOptions.maxTickRepeatMax))}
                        parse={parseInteger}
                        form={form}
                        showInputField
                      />
                    </div>
                    <OnChange name="performanceTest_tickRepeatInitial">
                      {(value, previous) => {
                        if (value && value !== previous) {
                          form.change('performanceTest_tickRepeatMax', Math.max(values.performanceTest_tickRepeatInitial, values.performanceTest_tickRepeatMax))
                        }
                      }}
                    </OnChange>
                  </GridItem>
                  <GridItem xs={12} middle>
                    <img src={isDarkmode() ? SvgUsersPlusNumberDarkmode : SvgUsersPlusNumber} alt="How many new Parallel Users should be added per Test Step" className={classes.sliderContainerImage} />
                    <div className={classes.sliderContainer}>
                      <Field
                        name="performanceTest_tickRepeatPerTick"
                        component={renderSlider}
                        label="How many new Parallel Users should be added per Test Step?"
                        min={0}
                        step={1}
                        max={license.performanceTestingOptions.maxTickRepeatPerTick || 100}
                        validate={composeValidators(required, validateMaxUser, minValue(0), maxValue(license.performanceTestingOptions.maxTickRepeatPerTick || 100))}
                        parse={parseInteger}
                        form={form}
                        showInputField
                      />
                    </div>
                    <OnChange name="performanceTest_tickRepeatPerTick">
                      {(value, previous) => {
                        if (value !== previous) {
                          form.change('performanceTest_tickRepeatMax', Math.max(values.performanceTest_tickRepeatInitial, values.performanceTest_tickRepeatMax))
                        }
                      }}
                    </OnChange>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <div className={classes.explanationBox}>
                      The number of the simulated users will be following:<br/><br/>
                      Step 1: {_.isNumber(values.performanceTest_tickRepeatInitial) ? values.performanceTest_tickRepeatInitial : 'N/A'} User(s)<br/>
                      {calculationPossible && (Math.ceil(values.performanceTest_tickMaxTimeMinutes * 60 / values.performanceTest_tickTimeSeconds)) >= 2 &&
                        <>Step
                          2: {(values.performanceTest_tickRepeatInitial + values.performanceTest_tickRepeatPerTick)} User(s)<br/></>}
                      {calculationPossible && (Math.ceil(values.performanceTest_tickMaxTimeMinutes * 60 / values.performanceTest_tickTimeSeconds)) >= 3 &&
                        <>Step
                          3: {(values.performanceTest_tickRepeatInitial + (2 * values.performanceTest_tickRepeatPerTick))} User(s)<br/></>}
                      {calculationPossible && (Math.ceil(values.performanceTest_tickMaxTimeMinutes * 60 / values.performanceTest_tickTimeSeconds)) >= 4 &&
                        <>...<br/>Step {Math.ceil(values.performanceTest_tickMaxTimeMinutes * 60 / values.performanceTest_tickTimeSeconds)}: <NumberFormat
                          value={maxUsers}
                          displayType={'text'} thousandSeparator/> User(s)<br/></>}
                    </div>
                  </GridItem>
                  {maxUsersTooMuch && <GridItem xs={12}>
                    &nbsp;
                  </GridItem>}
                  {maxUsersTooMuch && <GridItem xs={12}>
                    <div className={classes.warningBox}>
                      {`Number of Users in Last Step (${maxUsers}) can't exceed ${license.performanceTestingOptions.maxTickRepeatMax}! Please consider lowering the number of users!`}
                    </div>
                  </GridItem>}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <GridContainer>
          <GridItem xs={12}>
            <Card borderbottom>
              <CardBody noPadding>
                <Text header>Additional Settings</Text>

                <GridContainer alignItems="center">
                  {showUseHello && <GridItem xs={12} sm={6}>
                    <GridContainer>
                      <GridItem xs={12} middle>
                        <img src={isDarkmode() ? SvgHelloConvoDarkmode : SvgHelloConvo} alt="Use simple 'Hello' conversation"
                             className={classes.sliderContainerImage}/>
                        <div className={classes.sliderContainer}>
                          <Field
                            name="performanceTest_simpleConvo"
                            component={renderCheckbox}
                            label="Use a simple 'Hello' Conversation?"
                            type="checkbox"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>}
                  {showUseHello && <GridItem xs={12} sm={6}>
                    <div className={classes.explanationBox}>
                      To test the overall performance a simple 'hello' conversation is better as the conversations of the Test Project designed for regression test.<br /><br />
                      You can use the conversations of your Project. In this case more detailed server side KPIs might be required.
                    </div>
                  </GridItem>}
                  {showUseHello && <GridItem xs={12}><Divider /></GridItem>}
                  <GridItem xs={12} sm={6} middle>
                    <img src={isDarkmode() ? SvgConvoSessionDarkmode : SvgConvoSession} alt="Shared Conversation Session"
                         className={classes.sliderContainerImage}/>
                    <div className={classes.sliderContainer}>
                      <Field
                        name="performanceTest_shareContainer"
                        component={renderCheckbox}
                        label="Use Shared Conversation Session?"
                        type="checkbox"
                        disabled={license.performanceTestingOptions.shareContainer === false}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <div className={classes.explanationBox}>
                      Shared:

                      <p>Botium uses a session pool for conversations.</p>
                      <ul>
                        <li>
                          Session setup time is only included in the first convo.
                        </li>
                        <li>
                          Test can be broken due to server side state/variables (eg. Testing pizza order flow
                          twice in the same session. During second test, the chatbot thinks there are two orders).
                        </li>
                        <li>
                          There is a chance a timeout error could break the test
                          (eg. A pending response is late and thus, is considered as part of the next conversation).
                        </li>
                      </ul>
                      Separate:

                      <p>Botium isolates every user by creating separate sessions for each one.</p>
                      <ul>
                        <li>Session setup can slow down conversation</li>
                      </ul>
                    </div>
                          </GridItem>
                          <GridItem xs={12}><Divider /></GridItem>
                          <GridItem xs={12} sm={6}>
                          <GridContainer>
                          <GridItem xs={12} middle>
                              <img src={isDarkmode() ? SvgDetailedLogsDarkmode : SvgDetailedLogs} alt="Enable Detailed Logs"
                                className={classes.sliderContainerImage} />
                              <div className={classes.sliderContainer}>
                                <Field
                                  name="performanceTest_detailedReporting"
                                  component={renderCheckbox}
                                  label="Enable Detailed Logs"
                                  type="checkbox"
                                />
                              </div>
                            </GridItem>
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <div className={classes.explanationBox}>
                             Enables more detailed logs on the Agents (it can be good for bug fixing)
                            </div>
                          </GridItem>
                          <GridItem xs={12}><Divider /></GridItem>
                          <GridItem xs={12} sm={6}>
                            <GridContainer>
                              <GridItem xs={12} middle>
                              <img src={isDarkmode() ? SvgRequestTimeoutDarkmode : SvgRequestTimeout} alt="Time to consider a chatbot message failed"
                                   className={classes.sliderContainerImage}/>
                              <div className={classes.sliderContainer}>
                                <Field
                                  name="performanceTest_waitForBotTimeoutSeconds"
                                  component={renderSlider}
                                  label="Time to consider a Chatbot Message failed?"
                                  helperText="in Seconds"
                                  min={1}
                                  max={100}
                                  validate={composeValidators(required, minValue(1), maxValue(100))}
                                  step={1}
                                  form={form}
                                  data-unique="txtTestProjectPerformanceTestWaitForBotTimeout"
                                  showInputField
                                />
                              </div>
                            </GridItem>
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <div className={classes.explanationBox}>
                              The timeout after a conversation considered as failed
                            </div>
                          </GridItem>
                          <GridItem xs={12}><Divider /></GridItem>
                          <GridItem xs={12} sm={6}>
                          <GridContainer>
                              <GridItem xs={12} middle>
                              <img src={isDarkmode() ? SvgAcceptableRateDarkmode : SvgAcceptableRate} alt="Required percentage of successful users" className={classes.sliderContainerImage} />
                              <div className={classes.sliderContainer}>
                                <Field
                                  name="performanceTest_requiredPercentOfSuccesfulUsers"
                                  component={renderSlider}
                                  label="Required percentage of Successful Users per Test Step?"
                                  helperText="in Percent"
                                  min={0}
                                  max={100}
                                  validate={composeValidators(required, minValue(0), maxValue(100))}
                                  parse={parseInteger}
                                  form={form}
                                  disabled={license.performanceTestingOptions.tickMaxErrorPercent === false}
                                  data-unique="txtTestProjectPerformanceTestRequiredPercentOfSuccesfulUsers"
                                  showInputField
                                />
                              </div>
                            </GridItem>
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <div className={classes.explanationBox}>
                              Conversation can fail on following reasons:
                              <ul>
                                <li>There is no response</li>
                                <li>Response is invalid (for example HTTP error code)</li>
                                <li>Response is too slow</li>
                                <li>Botium asserter errors (for example response text is not good)</li>
                              </ul>
                            </div>
                          </GridItem>
                          <GridItem xs={12}><Divider /></GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <MessageBox
                      variant="warning"
                      title="Additional cost may be involved!"
                      text={`Performance test will simulate max ${_.isNumber(maxConvoCount) ? maxConvoCount : 'N/A'} conversations (requests) on your chatbot (Depending on its response time).`}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            }

  render() {
    const { license } = this.props
    return <FormSpy
      subscription={{ form: true, values: true, errors: true }}
      render={({ form, values, errors }) => {
        const calculationPossible = _.isNumber(values.performanceTest_tickMaxTimeMinutes) &&
          _.isNumber(values.performanceTest_tickTimeSeconds) &&
          _.isNumber(values.performanceTest_tickRepeatInitial) &&
          _.isNumber(values.performanceTest_tickRepeatPerTick) &&
          _.isNumber(values.performanceTest_tickRepeatMax)
        license.performanceTestingOptions = Object.assign({}, license.performanceTestingOptions)
        license.performanceTestingOptions.maxTickRepeatMax = license.performanceTestingOptions.maxTickRepeatMax || MAX_PARALLEL_USER_NO_LICENSE
        // values.performanceTest_tickRepeatMax, and maxUsers is nearly the same.
        // values.performanceTest_tickRepeatMax is just in stresstest mode, its managed
        // maxUsers is for every mode.
        const maxUsers = calculationPossible && Math.ceil((values.performanceTest_tickRepeatInitial + ((values.performanceTest_tickMaxTimeMinutes * 60 / values.performanceTest_tickTimeSeconds - 1) * values.performanceTest_tickRepeatPerTick)))

        const maxUsersTooMuch = calculationPossible && (maxUsers > license.performanceTestingOptions.maxTickRepeatMax)
        const testSteps = _.isNumber(values.performanceTest_tickMaxTimeMinutes) && _.isNumber(values.performanceTest_tickTimeSeconds) && Math.ceil(values.performanceTest_tickMaxTimeMinutes * 60 / values.performanceTest_tickTimeSeconds)
        const _calculateMaxConvoCount = () => {
          if (!_.isNumber(testSteps)) {
            return null
          }

          const startOptions = buildPerformanceTestStartOptions(values)
          const {tickTime, tickRepeatInitial, tickRepeatPerTick} = startOptions
          if (!_.isNumber(tickTime) || !_.isNumber(tickRepeatInitial) || !_.isNumber(tickRepeatPerTick)) {
            return null
          }

          let result = 0
          for (let i = 0; i < testSteps; i++) {
            // step: in 1s timeframe we run max 1 convo
            const remainingSec = tickTime / 1000 * (testSteps - i)
            const userCount = i ? tickRepeatPerTick : tickRepeatInitial
            result += remainingSec * userCount
          }
          return result
        }
        const maxConvoCount = _calculateMaxConvoCount()
        const validateMaxUser = () => {
          if (values.performanceTest_Type === 'advanced' && (maxUsersTooMuch)) {
            return undefined //`Users in last step (${maxUsers}) can't exceed ${license.performanceTestingOptions.maxTickRepeatMax}!`
          }
        }

        return (<GridContainer>
          <GridItem xs={12}>
            {values.performanceTest_Type && values.performanceTest_Type ==='load' &&
              this.renderLoadTest(form, values, maxConvoCount)
            }
            {values.performanceTest_Type && values.performanceTest_Type ==='stress' &&
              this.renderStressTest(form, values, maxConvoCount, maxUsers)
            }
            {values.performanceTest_Type && values.performanceTest_Type ==='advanced' &&
              this.renderAdvancedTest(form, values, maxConvoCount, maxUsers, validateMaxUser, calculationPossible, testSteps, maxUsersTooMuch)
            }
          </GridItem>
        </GridContainer>)
      }}/>
  }
}

const calculateAndSetRepeatPerTick = (values, form) => {
  const {performanceTest_tickRepeatInitial, performanceTest_tickRepeatMax, performanceTest_tickMaxTimeMinutes, performanceTest_tickTimeSeconds} = values
  const performanceTest_tickRepeatPerTick = calculateRepeatPerTick(performanceTest_tickRepeatInitial, performanceTest_tickRepeatMax, performanceTest_tickMaxTimeMinutes, performanceTest_tickTimeSeconds)
  form.change('performanceTest_tickRepeatPerTick', performanceTest_tickRepeatPerTick)
}

export const calculateRepeatPerTick = (minUsers, maxUsers, testTimeMinutes, tickTimeSeconds) => {
  if (_.isNumber(maxUsers) && _.isNumber(minUsers) && _.isNumber(testTimeMinutes) && _.isNumber(tickTimeSeconds) && testTimeMinutes > 0 && tickTimeSeconds > 0) {
    return Math.round((maxUsers - minUsers) / (testTimeMinutes * 60 / tickTimeSeconds - 1))
  }

  return false
}

export default compose(
  withStyles(performanceTestingStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license })
  )
)(PerformanceTestSessionRegisterForm)

