import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import Tooltip from 'components/Tooltip/Tooltip'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import Text from 'components/Typography/Text'

class PerformanceTestSessionProgressIcon extends React.Component {

  render () {
    const { progressPercent, status, showProgress } = this.props
    if (status && status === 'READY') {
      return ''
    } else if (status && status === 'CANCELLED') {
      return (
        <Tooltip title={'Cancelled'}>
          <Text dangerBg bgradius data-unique="statusPerformanceSessionProgress_CANCELLED" icon="ban">CANCELLED</Text>
        </Tooltip>
      )
    } else if (status && status === 'FAILED') {
      return (
        <>{false && <Tooltip title={'Failed'}>
          <Text dangerBg bgradius data-unique="statusPerformanceSessionProgress_FAILED" icon="triangle-exclamation">FAILED</Text>
        </Tooltip>}</>
      )
    } else if (status && status !== 'FAILED' && status !== 'READY' && !showProgress) {
      return (
        <Tooltip title={'Pending'}>
          <Text warningBg bgradius data-unique="statusPerformanceSessionProgress_PENDING" icon="hourglass-start">PENDING</Text>
        </Tooltip>
      )
    } else if (status && status !== 'FAILED' && status !== 'READY' && showProgress) {
      return (
        <Tooltip title={`Running ... (${progressPercent.toFixed(2)}%)`}>
          <Text warningBg bgradius iconRotation icon="circle-notch">RUNNING</Text>
        </Tooltip>
      )
    } else {
      return <></>
    }
  }
}

export default withStyles(testsessionsStyle)(PerformanceTestSessionProgressIcon)
