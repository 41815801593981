import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  renderIntField,
  renderSelect,
  renderCheckbox,
  renderAutoSuggest,
  required,
  url,
  parseInteger,
  composeValidators
} from 'components/Form/Form'
import { getConnector } from 'actions/settings'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function webdrivervoiceCaps2Form(caps) {
  const form = {
    webdrivervoice: {
      url: (caps.find(c => c.name === 'WEBDRIVERVOICE_URL') || { stringValue: '' }).stringValue,
      app: (caps.find(c => c.name === 'WEBDRIVERVOICE_APP') || { stringValue: '' }).stringValue,
      appPackage: (caps.find(c => c.name === 'WEBDRIVERVOICE_APPPACKAGE') || { stringValue: '' }).stringValue,
      appActivity: (caps.find(c => c.name === 'WEBDRIVERVOICE_APPACTIVITY') || { stringValue: '' }).stringValue,
      appNoReset: (caps.find(c => c.name === 'WEBDRIVERVOICE_APPNORESET') || { booleanValue: false }).booleanValue,
      language: (caps.find(c => c.name === 'WEBDRIVERVOICE_LANGUAGE') || { stringValue: 'us-english' }).stringValue,
      gender: (caps.find(c => c.name === 'WEBDRIVERVOICE_GENDER') || { stringValue: 'female' }).stringValue,
      skipWait: (caps.find(c => c.name === 'WEBDRIVERVOICE_SKIP_WAITFORCLICKABLE') || { booleanValue: true }).booleanValue,
      outputLanguage: (caps.find(c => c.name === 'WEBDRIVERVOICE_OUTPUT_LANGUAGE') || { stringValue: '' }).stringValue,
      outputTranscribe: (caps.find(c => c.name === 'WEBDRIVERVOICE_OUTPUT_TRANSCRIBE') || { booleanValue: true }).booleanValue,
      outputTimeout: (caps.find(c => c.name === 'WEBDRIVERVOICE_OUTPUT_PLAYTIMEOUT') || { intValue: 10000 }).intValue
    }
  }

  form.webdrivervoice.automation = form.webdrivervoice.appPackage ? 'appium' : 'selenium'

  const parseJsonArrayCap = (capName, formName) => {
    const naviCap = caps.find(c => c.name === capName)
    form.webdrivervoice[formName] = []
    if (naviCap && naviCap.jsonValue) {
      try {
        form.webdrivervoice[formName] = JSON.parse(naviCap.jsonValue)
      } catch (err) {}
    }
  }
  parseJsonArrayCap('WEBDRIVERVOICE_INPUT_NAVIGATION_BUTTONS', 'inputNavigationButtons')
  parseJsonArrayCap('WEBDRIVERVOICE_INPUT_STARTRECORD', 'inputStartRecord')
  parseJsonArrayCap('WEBDRIVERVOICE_INPUT_STOPRECORD', 'inputStopRecord')
  parseJsonArrayCap('WEBDRIVERVOICE_OUTPUT_PLAY', 'outputPlay')

  return form
}

const capNamesMap = {
  'webdrivervoice.url': 'WEBDRIVERVOICE_URL',
  'webdrivervoice.app': 'WEBDRIVERVOICE_APP',
  'webdrivervoice.appPackage': 'WEBDRIVERVOICE_APPPACKAGE',
  'webdrivervoice.appActivity': 'WEBDRIVERVOICE_APPACTIVITY',
  'webdrivervoice.appNoReset': 'WEBDRIVERVOICE_APPNORESET',
  'webdrivervoice.language': 'WEBDRIVERVOICE_LANGUAGE',
  'webdrivervoice.gender': 'WEBDRIVERVOICE_GENDER',
  'webdrivervoice.skipWait': 'WEBDRIVERVOICE_SKIP_WAITFORCLICKABLE',
  'webdrivervoice.outputLanguage': 'WEBDRIVERVOICE_OUTPUT_LANGUAGE',
  'webdrivervoice.outputTranscribe': 'WEBDRIVERVOICE_OUTPUT_TRANSCRIBE',
  'webdrivervoice.outputTimeout': 'WEBDRIVERVOICE_OUTPUT_PLAYTIMEOUT',
  'webdrivervoice.inputNavigationButtons': 'WEBDRIVERVOICE_INPUT_NAVIGATION_BUTTONS',
  'webdrivervoice.inputStartRecord': 'WEBDRIVERVOICE_INPUT_STARTRECORD',
  'webdrivervoice.inputStopRecord': 'WEBDRIVERVOICE_INPUT_STOPRECORD',
  'webdrivervoice.outputPlay': 'WEBDRIVERVOICE_OUTPUT_PLAY'
}

export function webdrivervoiceForm2caps(values) {
  let caps = [
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'webdrivervoice' },
  ]

  if (values.webdrivervoice.automation === 'appium') {
    caps.push({
      name: 'WEBDRIVERVOICE_APP',
      type: 'STRING',
      stringValue: values.webdrivervoice.app
    })
    caps.push({
      name: 'WEBDRIVERVOICE_APPPACKAGE',
      type: 'STRING',
      stringValue: values.webdrivervoice.appPackage
    })
    caps.push({
      name: 'WEBDRIVERVOICE_APPACTIVITY',
      type: 'STRING',
      stringValue: values.webdrivervoice.appActivity
    })
    caps.push({
      name: 'WEBDRIVERVOICE_APPNORESET',
      type: 'BOOLEAN',
      booleanValue: values.webdrivervoice.appNoReset
    })
  } else {
    caps.push({
      name: 'WEBDRIVERVOICE_URL',
      type: 'STRING',
      stringValue: values.webdrivervoice.url
    })
  }

  const setJsonArrayCap = (capName, formName) => {
    if (values.webdrivervoice[formName] && values.webdrivervoice[formName].length > 0) {
      caps.push({
        name: capName,
        type: 'JSON',
        jsonValue: JSON.stringify(values.webdrivervoice[formName])
      })
    } else {
      caps.push({
        name: capName,
        type: 'JSON',
        jsonValue: ''
      })
    }
  }
  setJsonArrayCap('WEBDRIVERVOICE_INPUT_NAVIGATION_BUTTONS', 'inputNavigationButtons')
  setJsonArrayCap('WEBDRIVERVOICE_INPUT_STARTRECORD', 'inputStartRecord')
  setJsonArrayCap('WEBDRIVERVOICE_INPUT_STOPRECORD', 'inputStopRecord')
  setJsonArrayCap('WEBDRIVERVOICE_OUTPUT_PLAY', 'outputPlay')

  caps.push({
    name: 'WEBDRIVERVOICE_SKIP_WAITFORCLICKABLE',
    type: 'BOOLEAN',
    booleanValue: !!values.webdrivervoice.skipWait
  })
  caps.push({
    name: 'WEBDRIVERVOICE_OUTPUT_TRANSCRIBE',
    type: 'BOOLEAN',
    booleanValue: !!values.webdrivervoice.outputTranscribe
  })
  caps.push({
    name: 'WEBDRIVERVOICE_LANGUAGE',
    type: 'STRING',
    stringValue: values.webdrivervoice.language
  })
  caps.push({
    name: 'WEBDRIVERVOICE_GENDER',
    type: 'STRING',
    stringValue: values.webdrivervoice.gender
  })
  caps.push({
    name: 'WEBDRIVERVOICE_OUTPUT_LANGUAGE',
    type: 'STRING',
    stringValue: values.webdrivervoice.outputLanguage
  })
  caps.push({
    name: 'WEBDRIVERVOICE_OUTPUT_PLAYTIMEOUT',
    type: 'INT',
    intValue: values.webdrivervoice.outputTimeout
  })

  return caps
}

class WebdriverVoiceEditUnconnected extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputExpanded: false,
      voiceExpanded: false
    }
  }

  render() {
    const { advanced, disabled, getConnector, capSetCapNames } = this.props
    const { inputExpanded, voiceExpanded } = this.state

    const connector = getConnector('webdrivervoice')
    const capLanguage = connector.capabilities.find(c => c.name === 'WEBDRIVERVOICE_LANGUAGE')
    const capGender = connector.capabilities.find(c => c.name === 'WEBDRIVERVOICE_GENDER')
    const capOutputLanguage = connector.capabilities.find(c => c.name === 'WEBDRIVERVOICE_OUTPUT_LANGUAGE')

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => (
      <GridContainer>
        <GridItem xs={12}>
          <Field
            name="webdrivervoice.automation"
            component={renderSelect}
            label="Automation Technology"
            validate={required}
            data-unique="selWebdriverEditAutomation"
            items={[
              { key: 'selenium', label: 'Use Selenium (for Voicebots published on Desktop or Mobile Websites)' },
              { key: 'appium', label: 'Use Appium (for Voicebots embedded into a Smartphone Apps)' }
            ]}
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.automation']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.automation'])}
          />
        </GridItem>
        <GridItem xs={12} />
        {values.webdrivervoice.automation === 'selenium' && <React.Fragment>
          <GridItem xs={12}>
            <Field
              name="webdrivervoice.url"
              component={renderTextField}
              label="Start Url"
              validate={composeValidators(required, url)}
              data-unique="txtWebdriverUrl"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.url']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.url'])}
            />
          </GridItem>
          <GridItem xs={12} sm={6} />
        </React.Fragment>}
        {values.webdrivervoice.automation === 'appium' && <React.Fragment>
          <GridItem xs={12} sm={4}>
            <Field
              name="webdrivervoice.app"
              component={renderTextField}
              label="Location of the App"
              data-unique="txtWebdriverApp"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.app']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.app'], 'Depends on the device cloud provider. Can be a public accessible Url or a provider-specific app identifier. Can be left empty for accessing a pre-installed app.')}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              name="webdrivervoice.appPackage"
              component={renderTextField}
              label="App Package Name"
              validate={required}
              data-unique="txtWebdriverAppPackage"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.appPackage']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.appPackage'])}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              name="webdrivervoice.appActivity"
              component={renderTextField}
              label="App Activity Name"
              data-unique="txtWebdriverAppActivity"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.appActivity']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.appActivity'])}
            />
          </GridItem>
          {advanced && <React.Fragment>
            <GridItem xs={12} sm={4}>
              <Field
                name="webdrivervoice.appNoReset"
                component={renderCheckbox}
                type="checkbox"
                label="Skip Automatic App State Reset"
                disabled={disabled}
                data-unique="txtWebdriverAppNoReset"
              />
            </GridItem>
            <GridItem xs={12} sm={4} />
          </React.Fragment>}
        </React.Fragment>}
        {advanced &&
          <GridItem xs={12}>
            <ExpansionPanel expanded={inputExpanded} onChange={() => this.setState({inputExpanded: !inputExpanded})} data-unique="pnlWebdriverInput">
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Voice Widget Configuration
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <GridContainer nounset>
                  <GridItem xs={12}>
                    <Field
                      name="webdrivervoice.inputNavigationButtons"
                      component={renderAutoSuggest}
                      label="Navigation Button Selectors"
                      data-unique="asWebdriverEditInputNavigationButtons"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.inputNavigationButtons']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.inputNavigationButtons'], 'A list of Webdriver selectors for elements which will be clicked one after the other to navigate to the actual chatbot widget. Prefixing an element with "iframe:" will switch context to the given iFrame.')}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="webdrivervoice.inputStartRecord"
                      component={renderAutoSuggest}
                      label="Start Voice Recording Selectors"
                      data-unique="asWebdriverEditInputStartRecord"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.inputStartRecord']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.inputStartRecord'], 'A list of Webdriver selectors for elements which will be clicked one after the other to start voice recording.')}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="webdrivervoice.inputStopRecord"
                      component={renderAutoSuggest}
                      label="Stop Voice Recording Selectors"
                      data-unique="asWebdriverEditInputStopRecord"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.inputStopRecord']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.inputStopRecord'], 'A list of Webdriver selectors for elements which will be clicked one after the other to stop voice recording.')}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="webdrivervoice.outputPlay"
                      component={renderAutoSuggest}
                      label="Play Voice Response Selectors"
                      data-unique="asWebdriverEditOutputPlay"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.outputPlay']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.outputPlay'], 'A list of Webdriver selectors for elements which will be clicked one after the other to play voice response.')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdrivervoice.outputTimeout"
                      component={renderIntField}
                      label="Voice Response Recording Duration (milliseconds)"
                      parse={parseInteger}
                      validate={required}
                      data-unique="intWebdriverEditOutputTimeout"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.outputTimeout']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.outputTimeout'])}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdrivervoice.skipWait"
                      component={renderCheckbox}
                      label="Skip waiting for buttons to be enabled (can improve test velocity)"
                      type="checkbox"
                      data-unique="chkWebdriverEditSkipWait"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.skipWait']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.skipWait'])}
                    />
                  </GridItem>
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={voiceExpanded} onChange={() => this.setState({voiceExpanded: !voiceExpanded})} data-unique="pnlWebdriverVoice">
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Voice Processing Configuration
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <GridContainer nounset>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdrivervoice.language"
                      component={renderSelect}
                      label="Text-To-Speech Language"
                      data-unique="selWebdriverEditLanguage"
                      items={capLanguage && capLanguage.choices.map(u => {
                        return { key: u.key, label: u.name }
                      })}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.language']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.language'])}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdrivervoice.gender"
                      component={renderSelect}
                      label="Text-To-Speech Gender"
                      data-unique="selWebdriverEditGender"
                      items={capGender && capGender.choices.map(u => {
                        return { key: u.key, label: u.name }
                      })}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.gender']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.gender'])}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdrivervoice.outputTranscribe"
                      component={renderCheckbox}
                      label="Transcribe Response (Speech-To-Text)"
                      type="checkbox"
                      data-unique="chkWebdriverEditOutputTranscribe"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.outputTranscribe']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.outputTranscribe'])}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdrivervoice.outputLanguage"
                      component={renderSelect}
                      label="Speech-To-Text Language"
                      data-unique="selWebdriverEditOutputLanguage"
                      items={capOutputLanguage && capOutputLanguage.choices.map(u => {
                        return { key: u.key, label: u.name }
                      })}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdrivervoice.outputTranscribe']) || disabled || !values.webdrivervoice.outputTranscribe}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdrivervoice.outputTranscribe'])}
                    />
                  </GridItem>
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </GridItem>
        }
      </GridContainer>)}/>
    )
  }
}

const WebdriverVoiceEdit = connect(
  state => ({ settings: state.settings, license: state.settings.license }),
  { getConnector }
)(WebdriverVoiceEditUnconnected)
export { WebdriverVoiceEdit }
