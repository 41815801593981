import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import logoIcon from 'assets/img/cyara-icon-logo.svg'
import logoText from 'assets/img/cyara-logo_text.svg'

const style = (theme) => ({
  logoLink: {
    height: 58,
  },
  logoWhite: {
    '&,&:hover': {
      color: '#FFFFFF'
    }
  },
  logoPrimary: {
    '&,&:hover': {
      color: theme.colors.primary
    }
  },
  logoImage: {
    height: '33px',
    display: 'inline-block',
    maxHeight: '33px',
    marginLeft: '10px',
    marginRight: '15px',
    border: '1px solid red',
    position: 'relative',
  },
  logoLarge: {
  
  },
  logoanimation: {
    position: 'absolute', 
    opacity: 1,
    left: 0, 
    top: 15,
    '& img': {
      height: 33,
    },
    animation: 'mymove 0.2s normal ease-out',
  },
  '@keyframes mymove': {
    'from': {left: -50, opacity: 0},
    'to': {left: 0, opacity: 1}
    
  }
})

function BotiumBoxLogoSidebar({ classes, className, sidebar, sidebarLarge, hideText, large, white, whiteicon, whitelogoText }) {
  return (
    <div href="https://cyara.com/botium-box/" className={classNames({
      [classes.logoLink]: true,
      [classes.logoText]: !whitelogoText,
      [classes.logoWhite]: !!white,
      [classes.logoPrimary]: !white,
      [classes.logoIcon]: !whiteicon,
      ...(className ? { [className]: true } : {})
    })}>
      <>
        <div className={classes.logoImage + ' ' + large ? classes.logoLarge : ''} style={{ position: 'relative' }}>
          {whitelogoText ? ' ' : <img src={logoText} alt="Cyara Botium" className={classes.logoanimation} />}<img src={logoIcon} alt="Cyara Botium" style={{ height: 33 }} />
        </div>
      </>
    </div>
  )
}

export default withStyles(style)(BotiumBoxLogoSidebar)
