import React from 'react'

import JustActualEntitiesBase from './JustActualEntitiesBase'

export default class JustActualEntitiesChecked extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {match} = this.props
    return (
      <JustActualEntitiesBase checked={false} match={match}/>
    )
  }
}
