import { SET_TESTCASE_FILTER } from '../constants'

export default function testcasefilter(state = {}, action) {
  switch (action.type) {
    case SET_TESTCASE_FILTER:
      return {
        ...state,
        [action.payload.filter]: action.payload.value,
      }
    default:
      return state
  }
}
