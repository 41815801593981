
import React from 'react'
import ShowIcon from 'components/Icon/ShowIcon'

function ShowFileIcon({ item: { type, name, mimeType }, ...props }) {
  if (type === 'FOLDER') {
    return <ShowIcon icon="folder" {...props}/>
  }
  if (mimeType) {
    if (mimeType.includes('csv')) {
      return <ShowIcon icon="file-csv" {...props}/>
    }
    if (mimeType.includes('javascript') || mimeType.includes('json') || mimeType.includes('yaml')) {
      return <ShowIcon icon="file-code" {...props}/>
    }
    if (mimeType.startsWith('image')) {
      return <ShowIcon icon="file-image" {...props}/>
    }
    if (mimeType.startsWith('audio')) {
      return <ShowIcon icon="file-audio" {...props}/>
    }
  }
  if (name) {
    if (name.endsWith('.xlsx') || name.endsWith('.xlsm')) {
      return <ShowIcon icon="file-excel" {...props}/>
    }
  }
  return <ShowIcon icon="file-alt" {...props}/>
}

export default ShowFileIcon
