import React from 'react'
import { withRouter } from 'react-router-dom'
import NLPUploadWizard from '../TestDataWizard/NLPUploadWizard.jsx'

export default withRouter(function ({ match, history }) {
  return <NLPUploadWizard
    testSetId={match.params.id}
    chatbotId={match.params.chatbotId}
  />
})
