import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import CircularProgress from '@material-ui/core/CircularProgress'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import classNames from 'classnames'
// apollo
import { Query, compose, graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
// @material-ui/icons
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import LineChart from 'components/Stats/Charts/LineChart'
import DateFormat from 'components/Info/DateFormat'
import ErrorFormat from 'components/Info/ErrorFormat'
import Feed from 'components/RSS/Feed.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import ShowIcon from 'components/Icon/ShowIcon'
import TestProjectsEmbeddedTable from '../TestProjects/TestProjectsEmbeddedTable.jsx'
import LLMProjectTypes from '../LLMprojects/LLMprojects.jsx'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx'
import typographyStyle from 'assets/jss/material-dashboard-react/components/typographyStyle.jsx'

import { clearDashboardSettings, setDashboardSettings } from 'actions/dashboard'

import { hasPermission } from 'botium-box-shared/security/permissions'

import { TESTPROJECTS_FAILING_QUERY } from '../TestProjects/gql'
import StatsText from 'components/Stats/StatsText'
import Text from 'components/Typography/Text'
import LinkButton from 'components/Button/LinkButton'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

const RATIOBYTESTPROJECTBYDAY_QUERY = gql`
  query ChartRatioByTestProjectByDay($timeFrame: ChartTimeFrame!) {
    chartRatioByTestProjectByDay(timeFrame: $timeFrame) {
      id
      name
      href { testProjectId }
      hrefs { testSessionId }
      data
    }
  }
`

export const TESTPROJECTS_COUNT_QUERY = gql`
  query ChartTestProjectResultCount($timeFrame: ChartTimeFrame!) {
    chartTestProjectResultCount(timeFrame: $timeFrame) {
      id
      name
      href { testProjectId }
      lastRun
      lastTestSessionId
      testCaseLastTotalCount
      testCaseLastSuccessCount
      testCaseLastSuccessRate
      previousRun
      previousTestSessionId
      testCasePreviousTotalCount
      testCasePreviousSuccessCount
      testCasePreviousSuccessRate
      testCaseTrend
    }
  }
`

const RATIOBYDAY_QUERY = gql`
  query ChartRatioByDay(
    $timeFrame: ChartTimeFrame!
  ) {
    chartRatioByDay(timeFrame: $timeFrame) {
      totalCount
      successCount
      failedCount
    }
  }
`

const NLPSCOREBYTESTPROJECTBYDAY_QUERY = gql`
  query ChartNLPScoreByTestProjectByDay(
    $timeFrame: ChartTimeFrame!
  ) {
    chartNLPScoreByTestProjectByDay(timeFrame: $timeFrame) {
      id
      name
      hrefs { coachSessionId }
      data
    }
  }
`

const TESTSESSIONCOUNT_QUERY = gql`
  query ChartTestSessionsCountQuery {
    chartTestSessionsCount {
      testSessionTotalToday
      testSessionFailedToday
      testSessionTotalLastMonth
      testSessionFailedLastMonth
      testSessionTotalLastYear
      testSessionFailedLastYear
      convoTotalToday
      convoFailedToday
      convoTotalLastMonth
      convoFailedLastMonth
      convoTotalLastYear
      convoFailedLastYear
    }
  }
`

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      testProjectActivityMore: false
    }
  }

  renderRssFeed() {
    const rssUrl = 'https://cyara.com/blog/tag/botium/feed/'
    return (
      <Card>
        <CardHeader color="info">
          <Text header>Botium News</Text>
          <Text subheader>
            News from the Botium universe
          </Text>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Feed moreHref="https://cyara.com/blog/?fwp_tags=botium" name="botium-box-feed" url={rssUrl} top={4} sm={12} divider/>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    )
  }

  renderTestProjectActivityCell(tpChart, tpStats, index) {
    const { classes, theme } = this.props

    const chartData = tpChart && {
      labels: tpChart.data.map((d, i) => moment(Date.now()).startOf('day').subtract(7 - (i+1), 'days').format('DD/MM')),
      series: [{
        ...tpChart,
        data: tpChart.data.map(d => ({ meta: _.isNil(d) ? '' : d.toFixed() + '%', value: d })),
        stroke: index % 2 === 0 ? theme.colors.common.turquoiseColor : theme.colors.common.secondary
      }]
    }

    const failingCount = tpStats && (tpStats.testCaseLastTotalCount - tpStats.testCaseLastSuccessCount)
    const failingDiff = tpStats && (tpStats.previousTestSessionId ? failingCount - (tpStats.testCasePreviousTotalCount - tpStats.testCasePreviousSuccessCount) : null)
    const tooltipText = tpStats && `${failingCount} Test Cases failed${failingDiff >= 0 ? (tpStats.testCaseTrend === 'BAD' ? `, ${failingDiff} new failed Test Case(s) compared to previous Test Session` : (tpStats.testCaseTrend === 'CONSTANT' ? ', no new failed Test Case(s) compared to previous Test Session' : '')) : ''}`

    return (
      <Card dense>
        <CardBody>
          <GridContainer>
            {chartData && <GridItem xs={12}>
              <LineChart data={chartData}
                legend={false}
                options={{
                  high: 100,
                  low: 0,
                  fullWidth: true,
                  showLabel: false,
                  chartPadding: {
                    right: 40
                  },
                  axisY: {
                    labelInterpolationFnc: value => value.toFixed() + '%',
                    labelOffset: {
                      x: 0,
                      y: 7
                    }
                  },
                  axisX: {
                    labelOffset: {
                      x: -15,
                      y: 3
                    }
                  }
                }}
              />
            </GridItem>}
          {tpStats && <>
            <GridItem xs={12}>
              <NavLink  className={classes.testProjectCardHeader} to={`/regression/projects/view/${tpStats.id}`} title={tpStats.name}>{tpStats.name}</NavLink>
            </GridItem>
            <GridItem xs={8} bottom>
              <Tooltip title={tooltipText}>
                <NavLink to={`/regression/projects/view/${tpStats.id}/results/${tpStats.lastTestSessionId}`}>
                  <Text header inline success={failingCount === 0} danger={failingCount > 0}>
                    {tpStats.testCaseTrend ==='GOOD' && <ShowIcon icon="arrow-trend-down" />}
                    {tpStats.testCaseTrend ==='BAD' && failingDiff > 0 && <ShowIcon icon="arrow-trend-up" />}
                    {tpStats.testCaseTrend === 'CONSTANT' && <ShowIcon icon="arrow-right-long" />}
                  </Text>
                </NavLink>
                <NavLink to={`/regression/projects/view/${tpStats.id}/results/${tpStats.lastTestSessionId}`}>
                  <Text header inline mlg>
                    {failingCount}{tpStats.previousTestSessionId && (failingDiff > 0 ? `(+${failingDiff})` : (failingDiff === 0 ? '' : `(${failingDiff})`))}
                  </Text>
                </NavLink>
              </Tooltip>
              <NavLink
                to={`/regression/projects/view/${tpStats.id}/results/${tpStats.lastTestSessionId}`}
                className={classes.lastRunLink}
              >
                <DateFormat fromNow>{tpStats.lastRun}</DateFormat>
              </NavLink>
            </GridItem>
            <GridItem xs={4} right bottom>
              <NavLink to={`/regression/projects/view/${tpStats.id}/results/${tpStats.lastTestSessionId}`}>
                {tpStats.testCaseLastTotalCount > 0 &&
                  <StatsText
                    error={!!failingCount}
                    success={!failingCount}
                    small
                    primarySuffix={<><NumberFormat value={100 - tpStats.testCaseLastSuccessRate} displayType={'text'} decimalScale={1}/> %</>}
                    secondaryText="failed"
                  />
                }
                {tpStats.testCaseLastTotalCount === 0 &&
                  <StatsText
                    small
                    primarySuffix="?"
                    secondaryText="unkown"
                  />
                }
            </NavLink>
            </GridItem>
          </>}
          </GridContainer>
        </CardBody>
      </Card>
    )
  }

  renderTestProjectActivity() {
    const { ratioByTestProjectData, testProjectCountData } = this.props

    if (ratioByTestProjectData.error) {
      return <ErrorFormat err={ratioByTestProjectData.error} />
    }
    if (testProjectCountData.error) {
      return <ErrorFormat err={testProjectCountData.error} />
    }

    if (ratioByTestProjectData.chartRatioByTestProjectByDay) {
      return (<GridContainer data-unique="grdTestProjectActivity" style={{ paddingBottom: 15 }}>
        {(this.state.testProjectActivityMore ? ratioByTestProjectData.chartRatioByTestProjectByDay : ratioByTestProjectData.chartRatioByTestProjectByDay.slice(0,4)).map((chart, i) => {
          const stats = testProjectCountData.chartTestProjectResultCount && testProjectCountData.chartTestProjectResultCount.find(stats => chart.href.testProjectId === stats.href.testProjectId)

          return <GridItem md={6} lg={3} grid key={i}>{this.renderTestProjectActivityCell(chart, stats, i)}</GridItem>
        })}
        {ratioByTestProjectData.chartRatioByTestProjectByDay.length > 4 && <GridItem xs={12} center>
          <Tooltip title={this.state.testProjectActivityMore ? 'Show Less' : 'Show More'} placement="bottom" >
            <LinkButton data-unique="btnShowTestProjectsMore" onClick={() => this.setState({ testProjectActivityMore: !this.state.testProjectActivityMore })}>{this.state.testProjectActivityMore ? 'Show Less' : 'Show More'}</LinkButton>
          </Tooltip>
       </GridItem>}
      </GridContainer>)
    } else {
      return null
    }
  }

  renderPassFailHistoryChart() {
    const { theme, ratioByDayData, classes } = this.props

    const _percentage = (d, count) => d.totalCount === 0 ? 0 : (count / d.totalCount) * 100

    const chartData = ratioByDayData.chartRatioByDay && ratioByDayData.chartRatioByDay.length > 0 && {
      labels: ratioByDayData.chartRatioByDay.map((d, i) => moment(Date.now()).startOf('day').subtract(14 - (i+1), 'days').format('YYYY-MM-DD')),
      series: [
        {
          name: 'Passed',
          data: ratioByDayData.chartRatioByDay.map(d => ({ meta: `${d.successCount}/${d.totalCount} Test Cases passed | ${_percentage(d, d.successCount).toFixed()}%`, value: _percentage(d, d.successCount)})),
          className: classNames({ [classes.successText]: true }),
          stroke: theme.colors.common.success,
          fill: theme.colors.common.success
        },
        {
          name: 'Failed',
          data: ratioByDayData.chartRatioByDay.map(d => ({ meta: `${d.failedCount}/${d.totalCount} Test Cases failed | ${_percentage(d, d.failedCount).toFixed()}%`, value: _percentage(d, d.failedCount)})),
          className: classNames({ [classes.dangerText]: true }),
          stroke: theme.colors.common.danger,
          fill: theme.colors.common.danger
        }
      ]
    }

    return (
      <Card>
        <CardHeader color="info">
          <Text header>Pass/Fail Rate Trend</Text>
          <Text subheader>
            Passed vs Failed Tests (Convo Executions), Last 2 Weeks
          </Text>
        </CardHeader>
        <CardBody>
          <div id="dashboard-pass-fail-chart">
          <GridContainer>
            <GridItem xs={12} className={classes.ratioByDayData}>
              {ratioByDayData.error && <ErrorFormat err={ratioByDayData.error} />}
              {!ratioByDayData.error && chartData &&
                <LineChart
                  legend={true}
                  tabIndex={0}
                  data={chartData}
                  options={{
                    high: 100,
                    low: 0,
                    showArea: true,
                    showPoint: true,
                    chartPadding: {
                      right: 40
                    },
                    axisY: {
                      labelInterpolationFnc: value => value.toFixed() + '%'
                    },
                    axisX: {
                      labelOffset: {
                        x: -30,
                        y: 0
                      }
                    }
                  }}/>
                }
            </GridItem>
            <GridItem xs={12}>
              <Text subheader>
                Shows the success vs failed test cases ratio over time (last two weeks) - 100% means all test cases passed.
              </Text>
            </GridItem>
          </GridContainer>
          </div>
        </CardBody>
      </Card>
    )
  }

  renderRecentTestResults() {
    const { classes } = this.props

    return (
      <GridContainer>
        <GridItem md={12}><Text header>Recent Test Projects</Text></GridItem>
        <GridItem md={12} className={classes.projectsListEmbeddedTable}>
          <TestProjectsEmbeddedTable disableFilter disableOrderBy disableFooter pageSize={5} name="TestProjects" />
        </GridItem>
      </GridContainer>
    )
  }

  renderRecentLLMProjects() {

    return (
      <GridContainer>
        <GridItem md={12} marginTop largePaddingBottom><Text header>LLM Test Types</Text></GridItem>
        <GridItem md={12}>
          <LLMProjectTypes />
        </GridItem>
      </GridContainer>
    )
  }

  renderGlobalActivity() {
    return(<>
        <Card cardFloat>
          <CardBody>
            <GridContainer>
              <GridItem><ShowIcon xl custom icon="projects" /></GridItem>
              <GridItem>
                <Text>Projects</Text>
                <Text header>number</Text>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card cardFloat>
          <CardBody>
            <GridContainer>
              <GridItem><ShowIcon xl custom icon="testcases" /></GridItem>
              <GridItem>
                <Text>Tests Executed</Text>
                <Text header>number</Text>
              </GridItem>
            </GridContainer>
          </CardBody>
       </Card>
       <Card cardFloat>
          <CardBody>
            <GridContainer>
              <GridItem><ShowIcon xl custom icon="success" /></GridItem>
              <GridItem>
                <Text>Succeede Test</Text>
                <Text header>number</Text>
              </GridItem>
            </GridContainer>
          </CardBody>
       </Card>
       <Card cardFloat>
          <CardBody>
            <GridContainer>
              <GridItem><ShowIcon xl custom icon="error" /></GridItem>
              <GridItem>
                <Text>Failed Tests</Text>
                <Text header>number</Text>
              </GridItem>
            </GridContainer>
          </CardBody>
       </Card>
       <Card cardFloat>
          <CardBody>
            <GridContainer>
              <GridItem><ShowIcon xl custom icon="testtype"/></GridItem>
              <GridItem>
                <Text>Runned Test Types</Text>
                <Text header>test types</Text>
              </GridItem>
            </GridContainer>
          </CardBody>
       </Card>
       </>)
  }

  render() {
    const { user, dashboard, history } = this.props
    const hasSessionAccess = hasPermission(user, 'TESTSESSIONS_SELECT')

    return (<>
      <GridContainer>
        {hasSessionAccess &&
          <GridItem xs={12}>
            {this.renderRecentTestResults()}
          </GridItem>
        }
        {history &&
          <GridItem xs={12}>
            {this.renderRecentLLMProjects()}
          </GridItem>
        }
        {!dashboard.hideBotiumNews &&
          <GridItem xs={12}>
            {this.renderRssFeed()}
          </GridItem>
        }
      </GridContainer>
    </>)
  }

  ___renderTestProjectHistoryChart() {
    const { classes } = this.props
    console.log('info', this.props)
    return (
      <Card>
        <CardHeader color="info">
          <Text header>Test Execution Trend</Text>
          <Text subheader>
            Conversation Coverage (Percentage of Successful Convo Executions), Last 2 Weeks
          </Text>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Query
                query={RATIOBYTESTPROJECTBYDAY_QUERY}
                variables={{ timeFrame: 'LAST2WEEKS' }}
                fetchPolicy={'network-only'}
                notifyOnNetworkStatusChange={true}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <LoadingIndicator large />
                  }
                  if (error) {
                    return <ErrorFormat err={error} />
                  }

                  if (
                    data.chartRatioByTestProjectByDay &&
                    data.chartRatioByTestProjectByDay.length > 0
                  ) {
                    const chartData = {
                      labels: data.chartRatioByTestProjectByDay[0].data.map((d, i) => moment(Date.now()).startOf('day').subtract(14 - (i+1), 'days').format('YYYY-MM-DD')),
                      series: data.chartRatioByTestProjectByDay,
                    }

                    return <div className={classes.chartLabels}><LineChart data={chartData}
                      options={{
                        high: 100,
                        low: 0,
                        chartPadding: {
                          right: 40
                        },
                        axisY: {
                          labelInterpolationFnc: value => value.toFixed() + '%'
                        },
                        axisX: {
                          labelOffset: {
                            x: -30,
                            y: 0
                          }
                        }
                      }}/></div>
                  } else {
                    return 'No test results available yet'
                  }
                }}
              </Query>
            </GridItem>
            <GridItem xs={12}>
              <Text muted>
                Shows the success vs failed test cases ratio for each selected Test Project over time (last two weeks) - 100% means all test cases passed. The last test session for each Test Project from each day is used as reference value.
              </Text>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    )
  }
  ___renderFailingTestProjectCell(testproject) {
    const { classes } = this.props

    const failingCount = testproject.testCaseLastTotalCount - testproject.testCaseLastSuccessCount
    let failingDiff = testproject.previousTestSessionId ? failingCount - (testproject.testCasePreviousTotalCount - testproject.testCasePreviousSuccessCount) : null

    const tooltipText = `${failingCount} Test Cases failed${failingDiff >= 0 ? (testproject.testCaseTrend === 'BAD' ? `, ${failingDiff} new failed Test Case(s) compared to previous Test Session` : (testproject.testCaseTrend === 'CONSTANT' ? ', no new failed Test Case(s) compared to previous Test Session' : '')) : ''}`

    return (
      <Card dense >
        <CardBody style={{ minHeight: 90 }}>
          <NavLink className={classes.testProjectCardHeader} to={`/regression/projects/view/${testproject.href.testProjectId}`} title={testproject.name}>{testproject.name}</NavLink>
            <h2 className={classes.statsDangerTitle}>
              <Tooltip title={tooltipText}>
                  {testproject.testCaseTrend ==='GOOD' && <TrendingDownIcon />}
                  {testproject.testCaseTrend ==='BAD' && failingDiff > 0 && <TrendingUpIcon />}
                  {testproject.testCaseTrend === 'CONSTANT' && <TrendingFlatIcon />}
                  {failingCount}{testproject.previousTestSessionId && <span style={{ fontSize: 16 }}>{(failingDiff > 0 ? `(+${failingDiff})` : (failingDiff === 0 ? '' : `(${failingDiff})`))}</span>}
              </Tooltip>
              <NavLink
                to={`/regression/projects/view/${testproject.id}/results/${testproject.lastTestSessionId}`}
                className={classes.lastRunLink}
              >
                <DateFormat fromNow>{testproject.lastRun}</DateFormat>
              </NavLink>
              <Text danger className={classes.failedBox}>
                <NumberFormat
                  value={100 - testproject.testCaseLastSuccessRate}
                  displayType={'text'}
                  decimalScale={1}
                />
                % failed
              </Text>
            </h2>
        </CardBody>
      </Card>
    )
  }
  ___renderFailingTestProjects() {
    return (
      <Query
        query={TESTPROJECTS_FAILING_QUERY}
        variables={{ timeFrame: 'LAST2WEEKS' }}
        fetchPolicy={'network-only'}
        notifyOnNetworkStatusChange={true}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingIndicator large />
          }
          if (error) {
            return <ErrorFormat err={error} />
          }
          if (data.chartTestProjectResultCount) {
            return (<GridContainer data-unique="chartTestProjectResultCount" style={{ paddingBottom: 15 }}>
              {data.chartTestProjectResultCount.map((testproject, i) => <GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={i}>{this.renderFailingTestProjectCell(testproject)}</GridItem>)}
            </GridContainer>)
          } else {
            return null
          }
        }}
      </Query>
    )
  }
  ___renderNLPScoreChart() {
    const { license } = this.props
    if (!license.coach) return null

    return (
      <Card>
        <CardHeader color="info">
          <Text header>NLP Score Trend</Text>
          <Text subheader>
            NLP Score for Botium Sessions, Last 2 Weeks
          </Text>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Query
                query={NLPSCOREBYTESTPROJECTBYDAY_QUERY}
                variables={{ timeFrame: 'LAST2WEEKS' }}
                fetchPolicy={'network-only'}
                notifyOnNetworkStatusChange={true}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <LoadingIndicator large />
                  }
                  if (error) {
                    return <ErrorFormat err={error} />
                  }

                  if (
                    data.chartNLPScoreByTestProjectByDay &&
                    data.chartNLPScoreByTestProjectByDay.length > 0
                  ) {
                    const chartData = {
                      labels: data.chartNLPScoreByTestProjectByDay[0].data.map((d, i) => moment(Date.now()).startOf('day').subtract(14 - (i+1), 'days').format('YYYY-MM-DD')),
                      series: data.chartNLPScoreByTestProjectByDay
                    }

                    return <LineChart data={chartData}
                      options={{
                        high: 100,
                        low: 0,
                        chartPadding: {
                          right: 40
                        },
                        axisX: {
                          labelOffset: {
                            x: -30,
                            y: 0
                          }
                        }
                      }}/>
                  } else {
                    return 'No NLP scores available yet'
                  }
                }}
              </Query>
              </GridItem>
            <GridItem xs={12}>
              <Text muted>
                Shows the NLP score for each selected Test Project (with NLP analytics enabled) over time (last two weeks). The last test session for each Test Project from each day is used as reference value.
              </Text>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    )
  }
  ___renderSessionCountChart() {
    const { classes } = this.props

    return (
      <Card>
        <CardHeader color="info">
          <Text header>Botium Activity</Text>
          <Text subheader>Test Sessions and Test Cases</Text>
        </CardHeader>
        <CardBody>
          <Query
            query={TESTSESSIONCOUNT_QUERY}
            fetchPolicy={'network-only'}
            notifyOnNetworkStatusChange={true}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return <LoadingIndicator large />
              }
              if (error) {
                return <ErrorFormat err={error} />
              }

              if (!data || !data.chartTestSessionsCount) {
                return null
              }
              const c = data.chartTestSessionsCount

              return (<GridContainer data-unique="chartTestSessionsCount">
                <GridItem xs={12}>
                  <p className={classes.largeText + ' ' + classes.infoText}>
                    {c.testSessionTotalToday} Test Session(s) today {c.testSessionFailedToday > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.testSessionFailedToday} failed</b>)</React.Fragment>}
                    <br/>
                    {c.testSessionTotalLastMonth} Test Session(s) last month {c.testSessionFailedLastMonth > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.testSessionFailedLastMonth} failed</b>)</React.Fragment>}
                    <br/>
                    {c.testSessionTotalLastYear} Test Session(s) last year {c.testSessionFailedLastYear > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.testSessionFailedLastYear} failed</b>)</React.Fragment>}
                  </p>
                  <p className={classes.largeText + ' ' + classes.infoText}>
                    {c.convoTotalToday} Test Case(s) today {c.convoFailedToday > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.convoFailedToday} failed</b>)</React.Fragment>}
                    <br/>
                    {c.convoTotalLastMonth} Test Case(s) last month {c.convoFailedLastMonth > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.convoFailedLastMonth} failed</b>)</React.Fragment>}
                    <br/>
                    {c.convoTotalLastYear} Test Case(s) last year {c.convoFailedLastYear > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.convoFailedLastYear} failed</b>)</React.Fragment>}
                  </p>
                </GridItem>
              </GridContainer>)
            }}
          </Query>
        </CardBody>
      </Card>
    )
  }
  ___renderActivityCards() {
    const { classes } = this.props

    return (
          <Query
            query={TESTSESSIONCOUNT_QUERY}
            fetchPolicy={'network-only'}
            notifyOnNetworkStatusChange={true}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return <LoadingIndicator large />
              }
              if (error) {
                return <ErrorFormat err={error} />
              }

              if (!data || !data.chartTestSessionsCount) {
                return null
              }
              const c = data.chartTestSessionsCount

              return (<GridContainer data-unique="chartTestSessionsCount">
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.testSessionFailedToday > 0}
                        success={c.testSessionFailedToday === 0}
                        primaryText={c.testSessionFailedToday}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.testSessionTotalToday}</b> Test Session(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.testSessionFailedToday / c.testSessionTotalToday) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Today</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.testSessionFailedLastMonth > 0}
                        success={c.testSessionFailedLastMonth === 0}
                        primaryText={c.testSessionFailedLastMonth}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.testSessionTotalLastMonth}</b> Test Session(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.testSessionFailedLastMonth / c.testSessionTotalLastMonth) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Last Month</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.testSessionFailedLastYear > 0}
                        success={c.testSessionFailedLastYear === 0}
                        primaryText={c.testSessionFailedLastYear}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.testSessionTotalLastYear}</b> Test Session(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.testSessionFailedLastYear / c.testSessionTotalLastYear) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Last Year</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.convoFailedToday > 0}
                        success={c.convoFailedToday === 0}
                        primaryText={c.convoFailedToday}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.convoTotalToday}</b> Convo(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.convoFailedToday / c.convoTotalToday) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Today</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.convoFailedLastMonth > 0}
                        success={c.convoFailedLastMonth === 0}
                        primaryText={c.convoFailedLastMonth}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.convoTotalLastMonth}</b> Convo(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.convoFailedLastMonth / c.convoTotalLastMonth) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Last Month</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.convoFailedLastYear > 0}
                        success={c.convoFailedLastYear === 0}
                        primaryText={c.convoFailedLastYear}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.convoTotalLastYear}</b> Convo(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.convoFailedLastYear / c.convoTotalLastYear) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Last Year</div>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>)
            }}
          </Query>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withTheme(),
  withStyles((theme) => ({ ...dashboardStyle(theme), ...typographyStyle(theme) })),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, dashboard: state.dashboard }),
    { clearDashboardSettings, setDashboardSettings }
  ),
  graphql(TESTPROJECTS_COUNT_QUERY, {
    skip: (props) => !hasPermission(props.user, 'TESTSESSIONS_SELECT'),
    options: {
      variables: { timeFrame: 'LASTWEEK'},
      fetchPolicy: 'network-only'
    },
    props: ({data}) => ({
      testProjectCountData: data,
    }),
  }),
  graphql(RATIOBYTESTPROJECTBYDAY_QUERY, {
    skip: (props) => !hasPermission(props.user, 'TESTSESSIONS_SELECT'),
    options: {
      variables: { timeFrame: 'LASTWEEK'},
      fetchPolicy: 'network-only'
    },
    props: ({data}) => ({
      ratioByTestProjectData: data,
    }),
  }),
  graphql(RATIOBYDAY_QUERY, {
    skip: (props) => !hasPermission(props.user, 'TESTSESSIONS_SELECT'),
    options: {
      variables: { timeFrame: 'LAST2WEEKS'},
      fetchPolicy: 'network-only'
    },
    props: ({data}) => ({
      ratioByDayData: data,
    }),
  })
)(Dashboard)
