import React from 'react'
import {NavLink} from 'react-router-dom'
import GridItem from 'components/Grid/GridItem'
import tokenizer from 'botium-box-shared/utils/tokenizer'
import GridContainer from 'components/Grid/GridContainer'
import dashboardStyle from '../../../../assets/jss/material-dashboard-react/views/dashboardStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import DateFormat from 'components/Info/DateFormat'
import Text from 'components/Typography/Text'

export const UtteranceKeywords = withStyles(dashboardStyle)(class Keywords extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    // forceSingleTestSet=true if displaying keywords just for one testset
    const {classes, listTestSetIdToEmbeddings, intent, utterance, forceSingleTestSet} = this.props

    let data = listTestSetIdToEmbeddings
    let multiTestSetWithoutUtteranceMatch = false
    if (!forceSingleTestSet) {
      if (listTestSetIdToEmbeddings.find(e => e.utteranceFound)) {
        data = listTestSetIdToEmbeddings.filter(e => e.noCoachSession || e.utteranceFound)
      } else {
        multiTestSetWithoutUtteranceMatch = true
      }
    }
    if (multiTestSetWithoutUtteranceMatch) {
      return <Text>
        The Test Session Insight(s) of&nbsp;
        {listTestSetIdToEmbeddings.map((e, index) =>
          <React.Fragment key={index}>
            <NavLink to={`/testsets/view/${e.testSetId}/insights`}>
              {e.testSetName}
            </NavLink>
            &nbsp;
          </React.Fragment>
        )}
        and the Test Result are not in synchron.
      </Text>
    } else {
      return data.map((e, index) => {
        let unigrams
        let bigrams
        let warn
        if (!intent) {
          warn = 'Utterance has no Predicted Intent'
        } else if (e.coachSession && e.coachSession.embeddingsErr) {
          warn = 'Invalid Insights for Test Set'
        } else if (e.noCoachSession) {
          warn = 'Test Set has no Insights'
        } else {
          const entry = (e.chi2 || []).find(e => e.name === intent)
          if (!entry) {
            warn = `Intent '${intent}' not found. Test Set Insights are outdated.`
          } else {
            unigrams = entry.unigrams
            bigrams = entry.bigrams
          }
        }

        const generateWarnOrList = (warn, keywordsList, utterance, entry) => {
          if ((!keywordsList || !keywordsList.length) && !warn) {
            warn = 'Keywords not found'
          }
          if (keywordsList) {
            keywordsList = keywordsList.sort()
          }
          if (warn) {
            return (
              <GridItem xs={6} sm={6}>
                <NavLink to={`/testsets/view/${entry.testSetId}/insights`}
                         data-unique={`btnUttaranceskeywordsToTestSetWarn_${e.testSetName}`}>
                  <Text warning>{warn}</Text>
                </NavLink>
              </GridItem>
            )
          }

          const utteranceTokens = tokenizer.tokenize(utterance)
          const contains = (utteranceTokens, keywords) => {
            const keywordTokens = tokenizer.tokenize(keywords)
            return utteranceTokens.find((e, i) => {
              for (let j = 0; j < keywordTokens.length; j++) {
                if ((i + j >= utteranceTokens.length)) {
                  return false
                }
                if (utteranceTokens[i + j].toLowerCase() !== keywordTokens[j].toLowerCase()) {
                  return false
                }
              }
              return true
            })
          }
          const result = []
          for (let i = 0; i < keywordsList.length; i++) {
            const keywords = keywordsList[i]
            const utteranceHasKeywords = contains(utteranceTokens, keywords)
            result.push((
              <GridItem xs={6} sm={6} key={i}>
                {utteranceHasKeywords && <Text important data-unique={`txtUttarancesKeywordsWordsMatch_${keywords.replace(' ', '_')}`}>{keywords}</Text>}
                {!utteranceHasKeywords && <Text info data-unique={`txtUttarancesKeywordsWordsNotMatch_${keywords.replace(' ', '_')}`}>{keywords}</Text>}
              </GridItem>
            ))
            if (i < keywordsList.length - 1) {
              result.push((
                <GridItem xs={6} sm={6} key={`${i}_filler`}></GridItem>
              ))
            }
          }
          return result
        }
        return <React.Fragment key={index}>
          &nbsp;
          {!forceSingleTestSet && <GridContainer>
            <GridItem xs={6} sm={6}>
              <div className={classes.stats}>
                <NavLink to={`/testsets/view/${e.testSetId}/insights`}
                         data-unique={`btnUttaranceseywordsToTestSet_${e.testSetName}`}>
                  <Text important>{e.testSetName}</Text>
                  <Text info>Insights from: <DateFormat>{e.coachSession && e.coachSession.createdAt ? e.coachSession.createdAt : null}</DateFormat></Text>
                </NavLink>
              </div>
            </GridItem>
          </GridContainer>}
          &nbsp;
          <GridContainer>
            <GridItem xs={6} sm={6}>
              <Text info data-unique={'txtUttarancesKeywordsUnigrams'}>Unigrams</Text>
            </GridItem>
            {generateWarnOrList(warn, unigrams, utterance, e)}
          </GridContainer>
          &nbsp;
          <GridContainer>
            <GridItem xs={6} sm={6}>
              <Text info data-unique={'txtUttarancesKeywordsBigrams'}>Bigrams</Text>
            </GridItem>
            {generateWarnOrList(warn, bigrams, utterance, e)}
          </GridContainer>
        </React.Fragment>
      })
    }
  }
})
