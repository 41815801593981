import React, { PureComponent } from 'react'
import NumberFormat from 'react-number-format'
import {BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip} from 'recharts'
import { isDarkmode } from 'components/Darkmode/helper'
import _ from 'lodash'

export default class Barchart extends PureComponent {

  getGreenToRedColor(errorPercent) {
    const normalized = errorPercent < 0 ? 0 : (errorPercent > 1 ? 1 : errorPercent)
    //we use three fixed colors at gradient becouse the center it was too brown
    if (normalized < 0.5 ) {
      return '#' + Math.floor(51+92 * normalized * 2).toString(16).padStart(2, '0') + Math.floor(175-33 * normalized * 2).toString(16).padStart(2, '0') + Math.floor(88-88 * normalized * 2).toString(16).padStart(2, '0')
    } else {
      return '#' + Math.floor(143+88 * (normalized - 0.5) * 2).toString(16).padStart(2, '0') + Math.floor(142-131 * (normalized - 0.5) * 2).toString(16).padStart(2, '0') + Math.floor(0+4 * (normalized - 0.5) * 2).toString(16).padStart(2, '0')
    }
  }

  
  percentAsHTML(errorPercent) {
    if (_.isNil(errorPercent)) {
      return null
    }
    return  errorPercent < 0 ? 0 : (errorPercent > 1 ? 1 : Math.round(errorPercent))
  }

  render() {
    const {
      data,
      label1,
      valueField1,
      errorPercent1,
      fillColor1,
      fillColor2,
      fillOpacity1,
      fillOpacity2,
      stroke,
      strokeWidth,
      label2,
      valueField2,
      errorPercent2,
      labelField,
      onClick,
      tooltipLabelFormatter,
      xAxisLabel,
      yAxisLabel,
      xAxistickFormatter,
      yAxistickFormatter,
    } = this.props

    let {
      tooltipFormatter,
    } = this.props

    if (!tooltipFormatter) {
      tooltipFormatter = (value) => _.isNil(value) ? 'N/A' : <NumberFormat value={value} displayType={'text'} decimalScale={4}/>
      
    }

    const TiltedAxisTick = (props) => {
      const { x, y, payload } = props

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="middle"
            fill={isDarkmode() ? '#D5D9DD' : '#2B3E53'}
            fontSize={12}
            fontWeight={'normal'}
          >
            {payload.value && (payload.value.length > 18 ? payload.value.substring(0, 15) + '...' : payload.value)}
          </text>
        </g>
      )

    }

    return (
        <ResponsiveContainer minWidth={400} minHeight={300} >
        <BarChart
          data={data}
          margin={{top: 5, right: 30, left: 20, bottom: 5}}
          onClick={onClick && ((e) => e && onClick(e.activePayload[0].payload))}
          
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis
            dataKey={labelField}
            tick={<TiltedAxisTick data={data} errorPercent={errorPercent1}/>}
            onClick={onClick && ((e) => e&& onClick(data[e.index]))}
            label={{ value: xAxisLabel, position: 'insideBottom', dy: -10 }}
            tickFormatter={xAxistickFormatter}

          />
          <YAxis
            stroke={'#2b3e53'}
            tick={{fill: isDarkmode() ? '#D5D9DD' : '#2B3E53'}}
            label={{ value: yAxisLabel, position: 'insideLeft', dy: -10 }}
            tickFormatter={yAxistickFormatter}
          />
          <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} cursor={{ fill: isDarkmode() ? '#D5D9DD4D' : '#2B3E534D' }} />
          <Bar name={label1} dataKey={valueField1} style={{cursor: onClick ? 'pointer' : 'default'}}>
            {
              data.map((entry, index) => (
                <Cell
                  key={`cell-${index}-1`}
                  fill={!_.isNil(errorPercent1) ? this.getGreenToRedColor(entry[errorPercent1]) : fillColor1 || '#8884d8'}
                  fillOpacity={fillOpacity1 || 1.0}
                  stroke={stroke || 'grey'}
                  strokeWidth={strokeWidth || 1}
                />
              ))
            }
          </Bar>
          {label2 &&
            <Bar name={label2} dataKey={valueField2} style={{cursor: onClick ? 'pointer' : 'default'}}>
              {
                data.map((entry, index) => (
                  <Cell
                        key={`cell-${index}-2`}
                        fill={!_.isNil(errorPercent2) ? this.getGreenToRedColor(entry[errorPercent2]) : fillColor2 || '#8884d8'}
                        fillOpacity={fillOpacity2 || 0.8}/>
                ))
              }

            </Bar>
          }
          </BarChart>
      </ResponsiveContainer>
    )
  }
}
