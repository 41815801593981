import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import SimpleList from 'components/List/SimpleList.jsx'
import Button from 'components/Button/Button'

import ShowIcon from 'components/Icon/ShowIcon'

import UserRole from './UserRole.jsx'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { USERROLES_QUERY } from './gql'

import { hasPermission } from 'botium-box-shared/security/permissions'

class UserRoles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCounter: 0
    }
  }
  render() {
    const { history, location, user } = this.props

    return (
      <GridContainer>
        {hasPermission(user, 'USERS_MANAGE') && <GridItem xs={12} borderBottom middle>
          <Button
            secondary
            data-unique="btnNew"
            onClick={() => {
              this.setState({ newCounter: this.state.newCounter + 1, id: 'new' })
              history.push('/settings/security/userroles/new')
            }}
            >
              <ShowIcon icon="plus" /> New
            </Button>
        </GridItem>}
        <GridItem xs={12} sm={4} md={3} noPadding>
          <Query query={USERROLES_QUERY} notifyOnNetworkStatusChange={true}>
            {({ loading, error, data, refetch }) => {
              return <SimpleList
                name="tblUserRoles"
                listData={(data && data.userroles && data.userroles.map(r => ({
                    id: r.id,
                    name: r.name,
                    secondary: (r.permissions && r.permissions.length > 0) ? `${r.permissions.slice(0, 2).join(' | ')} | ...`: '',
                    icon: <ShowIcon icon="shield-alt" />,
                    selected: location.pathname.indexOf(r.id) >= 0,
                    onClick: () => history.push(`/settings/security/userroles/${r.id}`)
                  }))) || []}
                onRefresh={() => refetch()}
                pageLoading={loading}
              />
            }}
          </Query>
        </GridItem>
        <GridItem xs={12} sm={8} md={9} borderLeft>
          <Switch>
            <Route
              path="/settings/security/userroles/new"
              render={props => <UserRole key={`new_${this.state.newCounter}`} onReady={(id) => history.push(`/settings/security/userroles/${id}`)} {...props}/>}
            />
            <Route
              path="/settings/security/userroles/:id"
              render={props => <UserRole id={props.match.params.id} key={props.match.params.id} onReady={(id) => !id && history.push('/settings/security/userroles')} {...props}/>}
            />
          </Switch>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ license: state.settings.license, user: state.token.user })
)(withStyles(settingsStyle)(withRouter(UserRoles)))
