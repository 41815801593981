import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from 'components/Tooltip/Tooltip'
// apollo
import {Query, graphql, compose, Mutation} from 'react-apollo'
// core components
import DropdownButton from 'components/Button/DropdownButton'
import Table from 'components/Table/AdvancedTable.jsx'
import Button from 'components/Button/Button'
import LinkButton from 'components/Button/LinkButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import DateFormat from 'components/Info/DateFormat'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import QueryStatus from 'components/Info/QueryStatus'
import ShowIcon from 'components/Icon/ShowIcon'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import ObjectChips from 'components/Chip/ObjectChips'

import { downloadfileformpost } from 'helper/downloadHelper'
import config from 'config'

import TestSessionProgress from './TestSessionProgress.jsx'
import { getRootPath } from './helper'

import imgJson from 'assets/img/download-files/json.svg'
import imgCsv from 'assets/img/download-files/csv.svg'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'

import {
  TESTSESSION_QUERY,
  TESTSESSION_PROGRESS_SECURITY_QUERY,
  TESTSESSIONPROGRESS_SECURITY_SUBSCRIPTION,
  TESTSESSIONTREND_QUERY,
  DeleteTestSessionListsFromCache,
  RefetchTestSessionQueries, DELETE_TESTSESSION, CANCEL_TESTSESSION
} from './gql'

import {
  START_TESTPROJECT,
  RefetchTestProjectQueriesOnNewTestSession
} from '../TestProjects/gql'

import {hasPermission, canWriteNamespace, hasAnyPermission} from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text'
import ListItem from 'components/List/ListItem/ListItem'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import Divider from 'components/Divider/Divider'

class SecuritySession extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testSessionProgressKey: 0
    }
  }

  hasWritePermission(user, testsession) {
    return hasAnyPermission(user, ['TESTSESSIONS_CREATE', 'TESTSESSIONS_UPDATE']) && canWriteNamespace(user, user.namespacePermissions, testsession.namespace)
  }
  renderDashboard(testsession, loading) {
    const { history, user, license, classes } = this.props
    const { mutateStartTestProject  } = this.props

    return (<GridContainer>
      <TestSessionProgress
        key={this.state.testSessionProgressKey}
        query={TESTSESSION_PROGRESS_SECURITY_QUERY}
        subscription={TESTSESSIONPROGRESS_SECURITY_SUBSCRIPTION}
        testSession={testsession}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
          const totalCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.length) || 0
          const highPrioCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.filter(a => a.risk === 'High').length) || 0
          const mediumPrioCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.filter(a => a.risk === 'Medium').length) || 0
          const lowPrioCount = totalCount - highPrioCount - mediumPrioCount


          return (
            <React.Fragment>
              <GridItem xs={12} right middle smallPadding smallMarginRight>
                    <>
                    {testsession.id && testsession.testProject && <React.Fragment>
                      <Text primary>Recent Test Sessions</Text><Text paddingLeftRight>  |  </Text><Text primary><DateFormat seconds>{testsession.createdAt}</DateFormat></Text>
                      <Query query={TESTSESSIONTREND_QUERY} variables={{ id: testsession.id }} fetchPolicy={'network-only'}>
                        {({ data }) => (<React.Fragment>
                          <Tooltip title="View Previous Test Session">
                            <Button justIcon Border
                              aria-label="View Previous Test Session"
                              disabled={!data || !data.testsessiontrend || !data.testsessiontrend.previousTestSessionId}
                              onClick={ async () => {
                                history.push(`${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${data.testsessiontrend.previousTestSessionId}/dashboard`)
                              }}
                              >
                              <ShowIcon icon="angle-left" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="View Previous Test Session">
                            <Button justIcon Border
                              aria-label="View Previous Test Session"
                              disabled={!data || !data.testsessiontrend || !data.testsessiontrend.nextTestSessionId}
                              onClick={ async () => {
                                history.push(`${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${data.testsessiontrend.nextTestSessionId}/dashboard`)
                              }}
                              >
                              <ShowIcon icon="angle-right" />
                            </Button>
                          </Tooltip>
                        </React.Fragment>)}
                      </Query>
                      </React.Fragment>
                    }
                    </>
                    <GridItem smallMarginRight ></GridItem>
                    {hasPermission(user, 'TESTSESSIONS_CREATE') && canWriteNamespace(user, user.namespacePermissions, testsession.namespace) &&
                      <TestSessionProgress testSession={testsession} key={this.state.testSessionProgressKey}>
                        {({ testSessionProgress }) => {
                          const repeatItems = []

                          if (testsession.testProject && testsession.testProject.id && license.detailedReporting) {
                            repeatItems.push({
                              id: 'repeatTestSessionDebug',
                              name: 'Repeat Full Security Test Session (Extended Logging)',
                              disabled: testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED',
                              icon: 'bug',
                              dataUnique: 'btnRepeatFullTestSessionDebug',
                              onClick: () => {
                                mutateStartTestProject({
                                  variables: { id: testsession.testProject.id, debug: true }
                                })
                              }
                            })
                          }
                          return (<>
                            <Button leftRound
                              id = "repeatTestSession"
                              data-unique = "btnRepeatFullTestSession"
                              disabled = {(!testsession.testProject && !testsession.testProject.id) || (testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED')}
                              icon = "play-circle"
                              onClick={() => {
                                mutateStartTestProject({
                                  variables: { id: testsession.testProject.id, debug: false }
                                })
                              }
                            }
                            ><ShowIcon icon="redo" /> Repeat Test Session</Button>
                            <DropdownButton
                              aria-label="Actions"
                              rightRound
                              data-unique="ddbtnRepeatTestSession"
                              disabled={repeatItems.length === 0 || (testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED')}
                              items={repeatItems}
                            >
                            </DropdownButton>
                            </>
                            )}}
                      </TestSessionProgress>
                    }
              </GridItem>
              <GridItem xs={12} grid>
                <GridContainer border borderRadius noMargin>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom  noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Total</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderDefault}>
                            <Text mlg bold>{isDone ? totalCount : '?'}</Text>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom  noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular >High Prio Issues</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderRed}>
                            {!isDone && <Text mlg bold>?</Text>}
                            {isDone && highPrioCount > 0 && <Text mlg bold>{highPrioCount}</Text>}
                            {isDone && highPrioCount === 0 && <Text mlg bold>{highPrioCount}</Text>}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom  noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular >Medium Prio Issues</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderOrange}>
                            {!isDone && <Text mlg bold>?</Text>}
                            {isDone && mediumPrioCount > 0 && <Text mlg bold>{mediumPrioCount}</Text>}
                            {isDone && mediumPrioCount === 0 && <Text mlg bold>{mediumPrioCount}</Text>}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom  noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular >Low Prio Issues</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderYellow}>
                            {!isDone && <Text mlg bold>?</Text>}
                            {isDone && lowPrioCount > 0 && <Text mlg bold>{lowPrioCount}</Text>}
                            {isDone && lowPrioCount === 0 && <Text mlg bold>{lowPrioCount}</Text>}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </React.Fragment>
          )
        }}
      </TestSessionProgress>
      <GridItem xs={12}>
        <ObjectChips rootPath={this.getRootPath()} dataUniquePrefix="btnTestSession" testProject={testsession.testProject} chatbot={testsession.chatbot} testSets={testsession.testSets} deviceSets={testsession.deviceSets} />
      </GridItem>
      <GridItem xs={12}>
        <TestSessionProgress
          key={this.state.testSessionProgressKey}
          query={TESTSESSION_PROGRESS_SECURITY_QUERY}
          subscription={TESTSESSIONPROGRESS_SECURITY_SUBSCRIPTION}
          testSession={testsession}>
            {({ testSessionProgress, testSessionProgressLoading, testSessionProgressErr }) => {
              const loading = testSessionProgressLoading || !testSessionProgress || (testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED')
              const alerts = (testSessionProgress && testSessionProgress.securityAlerts) || []

              const getSortVal = (i, orderBy) => {
                const val = _.get(i, orderBy)
                if (orderBy === 'risk') {
                  if (val === 'High') return 0
                  if (val === 'Medium') return 1
                  if (val === 'Low') return 2
                  return 3
                }
                if (_.isString(val)) return val.toLowerCase()
                if (_.isNumber(val)) return val
                return 0.0
              }

              return <Table
                tableHeaderColor="primary"
                tableHead={[
                  { name: 'Risk', orderByField: 'risk', orderByDefault: 'asc', orderByDirection: 'asc' },
                  { name: 'Confidence', orderByField: 'confidence' },
                  { name: 'HTTP-Method', orderByField: 'method' },
                  { name: 'Alert', orderByField: 'alert' },
                  { name: 'Evidence', orderByField: 'evidence' },
                  { name: 'Solution', orderByField: 'solution' },
                  { name: 'Reference Links' }
                ]}
                pageLoading={loading}
                pageErr={testSessionProgressErr}
                name={`TestSession_${testsession.id}_SecurityAlerts`}
                tableData={({ orderByField, orderByOrder }) => _.orderBy(alerts, [(i) => getSortVal(i, orderByField || 'risk')], [orderByOrder || 'asc']).map(alert => {
                  return [
                    alert.risk || 'Unknown',
                    alert.confidence,
                    alert.method,
                    () => <>{_.truncate(alert.alert, { length: 50 })} {alert.alert && alert.alert.length > 50 && <LinkButton onClick={() => this.setState({detailText: alert.alert})}><ShowIcon icon="ellipsis-h" /></LinkButton>}</>,
                    () => <>{_.truncate(alert.evidence, { length: 50 })} {alert.evidence && alert.evidence.length > 50 && <LinkButton onClick={() => this.setState({detailText: alert.evidence})}><ShowIcon icon="ellipsis-h" /></LinkButton>}</>,
                    () => <>{_.truncate(alert.solution, { length: 50 })} {alert.solution && alert.solution.length > 50 && <LinkButton onClick={() => this.setState({detailText: alert.solution})}><ShowIcon icon="ellipsis-h" /></LinkButton>}</>,
                    () => alert.references.map((reference, i) => <React.Fragment key={i} ><LinkButton onClick={() => window.open(reference, '_blank')}>Link {i + 1}</LinkButton>{i === alert.references.length - 1 ? '' : ' | '}</React.Fragment>)
                  ]
                })}
              />
            }}
        </TestSessionProgress>
        <ConfirmationDialog
          open={!!this.state.detailText}
          onOk={() => this.setState({detailText: ''})}
          title={`Security Scan Details`}
        >
          <Text>{this.state.detailText || ''}</Text>
        </ConfirmationDialog>
      </GridItem>
    </GridContainer>)
  }

  renderAnalytics(testsession) {
    const { setAlertErrorMessage, token } = this.props

    return (
      <GridContainer>
        <GridItem md={4} lg={3}>
          <Card cursor onClick={() => {
            downloadfileformpost(`${config.api.base}/securitybuild/${testsession.id}?REPORTER=csv`, token).catch(err => setAlertErrorMessage(err.message))
          }}>
            <CardBody>
              <GridContainer>
                <GridItem md={3}>
                  <img src={imgCsv} alt="CSV" />
                </GridItem>
                <GridItem md={7}>
                  <Text subheader>Security Risks</Text>
                  <Text header>AS CSV</Text>
                </GridItem>
                <GridItem md={2} middle noPadding>
                  <Button justIcon><ShowIcon icon="download" /></Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} lg={3}>
          <Card cursor onClick={() => {
            downloadfileformpost(`${config.api.base}/securitybuild/${testsession.id}?REPORTER=json`, token).catch(err => setAlertErrorMessage(err.message))
          }}>
            <CardBody>
              <GridContainer>
                <GridItem md={3}>
                  <img src={imgJson} alt="JSON" />
                </GridItem>
                <GridItem md={7}>
                  <Text subheader>Security Risks</Text>
                  <Text header>AS JSON</Text>
                </GridItem>
                <GridItem md={2} middle noPadding>
                  <Button justIcon><ShowIcon icon="download" /></Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  getRootPath = () => {
    return getRootPath(this.props.location)
  }

  render() {
    const { user, license, history } = this.props

    const { match } = this.props
    const testSessionId = match.params.id

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Query query={TESTSESSION_QUERY} variables={{ id: testSessionId }}>
            {(queryResult) => <QueryStatus {...queryResult} query="testsession" card loadable>{({ testsession, loading }) => {
              if (!testsession) testsession = { id: testSessionId, results: [], jobs: [], testProject: { nlpAnalytics: false } }
              return (
                <CustomTabs
                  name={`tabSecuritySession_${testSessionId}`}
                  headerColor="info"
                  plainTabs
                  tabs={[
                    {
                      tabName: 'Overview',
                      tabIcon: <ShowIcon icon="shield-alt" />,
                      tabContent: this.renderDashboard(testsession, loading),
                      locationPrefix: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testSessionId}/dashboard`,
                      dataUnique: 'btnTestSessionNavigationDashboard'
                    },
                    {
                      tabName: 'Downloads',
                      tabIcon: <ShowIcon icon="cloud-download-alt" />,
                      disabled: !hasPermission(user, 'TESTSESSIONS_REPORTS'),
                      tabContent: this.renderAnalytics(testsession),
                      locationPrefix: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testSessionId}/downloads`,
                      dataUnique: 'btnTestSessionNavigationDownloads'
                    },
                    this.hasWritePermission(user, testsession) && hasPermission(user, 'TESTSESSIONS_DELETE') && {
                      tabName: 'Danger Zone',
                      tabRight: true,
                      tabIcon: <ShowIcon icon="exclamation-triangle" />,
                      locationPrefix: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testSessionId}/danger`,
                      dataUnique: 'btnTestSessionNavigationDangerZone',
                      tabContent: (
                        <GridContainer key="danger">
                          {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_DELETE') &&
                            <React.Fragment>
                              <GridItem md={8} lg={4}>
                                <ListItem>
                                  <Text lg danger padding><ShowIcon icon="trash" /></Text>

                                  <GridContainer nounset>
                                    <GridItem md={12}><Text bold>Delete Test Session</Text></GridItem>
                                    <GridItem md={12}><Text>This removes the Test Session and its results</Text></GridItem>
                                  </GridContainer>
                                  <Mutation
                                    mutation={DELETE_TESTSESSION}
                                    onCompleted={data => {
                                      removeRecentListEntry({
                                        url: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testsession.id}`
                                      })
                                      setAlertSuccessMessage('Test Session deleted')
                                      history.push(`${this.getRootPath()}/projects/view/${testsession.testProject.id}`)
                                    }}
                                    onError={error => {
                                      setAlertErrorMessage('Test session deletion failed', error)
                                    }}
                                    update={DeleteTestSessionListsFromCache}
                                  >
                                    {(
                                      deleteTestSession,
                                      { loading, error },
                                    ) => (
                                      <ConfirmationButton
                                        confirmationText={`When deleting this Test Session, all it's test results are lost. You have to start another Test Session to get current results. If the session is running, it will be cancelled first. Are you sure you want to delete it ?`}
                                        requireCheck={true}
                                        danger
                                        small
                                        minWidth
                                        onClick={() => {
                                          deleteTestSession({
                                            variables: { id: testsession.id },
                                          })
                                        }}
                                        data-unique="btnTestSessionDelete"
                                      >

                                        Delete
                                      </ConfirmationButton>
                                    )}
                                  </Mutation>
                                </ListItem>
                              </GridItem>
                              <GridItem md={8}></GridItem>
                            </React.Fragment>
                          }
                          {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_DELETE') && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                            <React.Fragment>
                              <GridItem md={8} lg={4}><Divider dense /></GridItem>
                              <GridItem md={12} lg={8}></GridItem>
                            </React.Fragment>
                          }
                          {hasPermission(user, 'TESTSESSIONS_CREATE') &&
                            <React.Fragment>
                              <GridItem md={8} lg={4}>
                                <ListItem>
                                  <Text lg danger padding><ShowIcon icon="power-off" /></Text>
                                  <GridContainer nounset>
                                    <GridItem md={12}><Text bold>Send Cancellation Request</Text></GridItem>
                                    <GridItem md={12}><Text>Send cancellation request to Test Session</Text></GridItem>
                                  </GridContainer>
                                  <Mutation
                                    mutation={CANCEL_TESTSESSION}
                                    onCompleted={data => {
                                      setAlertSuccessMessage('Sent cancellation request to Test Session')
                                      this.setState({ testSessionProgressKey: this.state.testSessionProgressKey + 1 })
                                    }}
                                    onError={error => {
                                      setAlertErrorMessage('Sending cancellation request to Test Session failed', error)
                                    }}
                                  >
                                    {(
                                      cancelTestSession,
                                      { loading, error },
                                    ) => (
                                      <TestSessionProgress testSession={testsession}>
                                        {({ testSessionProgress }) => (
                                          <ConfirmationButton
                                            confirmationText={`When cancelling a Test Session, all background processing will be stopped and the test results are not complete. Are you sure you want to cancel it ?`}
                                            requireCheck={true}
                                            danger
                                            small
                                            minWidth
                                            disabled={testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED'}
                                            onClick={() => {
                                              cancelTestSession({
                                                variables: { id: testsession.id },
                                              })
                                            }}
                                            data-unique="btnTestSessionCancel"
                                          >
                                            Send
                                          </ConfirmationButton>
                                        )}
                                      </TestSessionProgress>
                                    )}
                                  </Mutation>
                                </ListItem>
                              </GridItem>
                              <GridItem xs={8}></GridItem>
                            </React.Fragment>
                          }
                          {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                            <React.Fragment>
                              <GridItem xs={4}><Divider dense /></GridItem>
                              <GridItem xs={8}></GridItem>
                            </React.Fragment>
                          }
                        </GridContainer>
                      ),
                    }
                  ].filter(t => t)}
                />
              )
            }}</QueryStatus>}
          </Query>
        </GridItem>
      </GridContainer>
    )
  }
}

const SecuritySessionWithGraphql = compose(
  graphql(START_TESTPROJECT, {
    props: ({ mutate }) => ({
      mutateStartTestProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.startTestProject.id
        props.setAlertSuccessMessage('Test session started ...')
        props.history.push(`${getRootPath(props.location)}/projects/view/${props.match.params.projectId}/results/${testSessionId}`)

      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestProjectQueriesOnNewTestSession(data.startTestProject.testProject.id),
        ...RefetchTestSessionQueries(data.startTestProject.id)
      ],
      update: DeleteTestSessionListsFromCache
    })
  })
)(SecuritySession)

export default connect(
  state => ({ token: state.token.token, user: state.token.user, license: state.settings.license, features: state.settings.features }),
  { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
)(withStyles(testsessionsStyle, { withTheme: true })(SecuritySessionWithGraphql))
