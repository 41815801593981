import React from 'react'
import { compose, graphql, Mutation, withApollo } from 'react-apollo'
import { connect } from 'react-redux'

import withStyles from '@material-ui/core/styles/withStyles'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import Field from 'components/Form/OptionalField'
import Text from 'components/Typography/Text'
import FeatureUpgradeNavLink from 'components/FeatureUpgrade/FeatureUpgradeNavLink'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import { hasAnyPermission, hasPermission } from 'botium-box-shared/security/permissions'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary'
import ListItem from 'components/List/ListItem/ListItem'
import Divider from 'components/Divider/Divider'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Chip from 'components/Chip/Chip'


import {
  email,
  FormActionsToolbar, minValue, parseInteger, renderAutoSuggest, renderCheckbox, renderIntField, renderSelect,
  renderTagField,
  renderTextArea,
  renderTextField,
  renderNamespaceField,
  required
} from 'components/Form/Form'

import {
  CANCEL_CRAWLERSESSION,
  CRAWLERPROJECTS_QUERY,
  CRAWLERPROJECT_QUERY,
  RefetchCrawlerProjectQueries,
  DELETE_CRAWLERPROJECT, DELETE_CRAWLERSESSION, DeleteCrawlerSessionListsFromCache,
  UPDATE_CRAWLERPROJECT, CRAWLERSESSION_QUERY, CRAWLERSESSIONS_QUERY
} from './gql'

import crawlerprojectsStyle from 'assets/jss/material-dashboard-react/views/crawlerprojectsStyle'
import { setAlertErrorMessage, setAlertSuccessMessage } from 'actions/alert'
import { DEVICESETS_QUERY, AGENTS_DROPDOWN_QUERY, TAGS_QUERY } from '../Settings/gql'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { formatCreatedByAndLastChange } from '../../helper/authHelper'
import { isLicenseDeviceSetsSupported } from 'botium-box-shared/security/licenseSupport'
import { getConnector } from 'actions/settings'

class CrawlerSettingsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      crawlerSessionDebugMode: false,
      reloadSearchAndReplaceRulesOnDelete: false
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['CRAWLERROJECTS_CREATE', 'CRAWLERPROJECTS_UPDATE'])
  }

  isNotificationsEdition() {
    const { license } = this.props
    return license && (license.notifications)
  }

  validate(values) {
    if ((values.numberOfWelcomeMessages === null || values.numberOfWelcomeMessages <= 0) && (values.entryPoints === null || values.entryPoints.length === 0)) {
      return {
        entryPoints: 'Either "Conversation Entrypoints" or "Number of Welcome Messages" has to be given',
        numberOfWelcomeMessages: 'Either "Conversation Entrypoints" or "Number of Welcome Messages" has to be given'
      }
    }
    if(values.searchAndReplaceRule && (values.searchAndReplaceRule.search || values.searchAndReplaceRule.replace)) {
      return {
        searchAndReplaceRule: {
          search: 'Fill both search and replace field and click on add button, or remove both of them before save',
          replace: 'Fill both search and replace field and click on add button, or remove both of them before save'
        }
      }
    }
  }

  render() {
    const {
      getConnector,
      crawlerProject,
      crawlerSession,
      setAlertSuccessMessage,
      setAlertErrorMessage,
      history,
      user,
      license,
      agentsData,
      deviceSetsData
    } = this.props

    const connector = getConnector(crawlerProject.chatbot.containermode)

    const devices = (!deviceSetsData || !deviceSetsData.devicesets || deviceSetsData.devicesets.length === 0) ? [] :
      deviceSetsData.devicesets.reduce((agg, ds) => {
        if (connector && connector.features && connector.features.deviceProviderTypes && connector.features.deviceProviderTypes.length > 0) {
          if (connector.features.deviceProviderTypes.indexOf(ds.provider.type) < 0) return agg
        }
        return [
          ...agg,
          ...ds.devices.map(d => ({
            id: d.id,
            name: `${ds.name} / ${d.name}`
          }))
        ]
      }, [])

    const hasDeviceSet = !!(connector && connector.features && connector.features.deviceSetCapability)

    const _renderFormWrapper = (renderChildSectionFn) =>
      <Mutation
        mutation={UPDATE_CRAWLERPROJECT}
        refetchQueries={[
          ...RefetchCrawlerProjectQueries(crawlerProject.id),
          {
            query: CRAWLERPROJECTS_QUERY
          },
          {
            query: TAGS_QUERY
          }
        ]}
      >
        {(updateCrawlerProject) => (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={async (values) => {
              const request = {
                variables: {
                  id: values.id,
                  crawlerProject: {
                    name: values.name,
                    namespace: values.namespace || null,
                    description: values.description || null,
                    tags: {
                      set: values.tags
                    },
                    entryPoints: {
                      set: values.entryPoints
                    },
                    exitCriteria: {
                      set: values.exitCriteria
                    },
                    ignoreButtons: {
                      set: values.ignoreButtons
                    },
                    dynamicWelcomeMessages: values.dynamicWelcomeMessages,
                    numberOfWelcomeMessages: values.numberOfWelcomeMessages,
                    depth: values.depth,
                    waitForPrompt: values.waitForPrompt,
                    generateUtterances: values.generateUtterances,
                    mergeUtterances: values.mergeUtterances,
                    notificationReceiversEmail: {
                      set: values.notificationReceiversEmail
                    },
                    searchAndReplaceRules: values.searchAndReplaceRules ? values.searchAndReplaceRules.map(sar => ({id: sar.id, search: sar.search, replace: sar.replace})) : []
                  },
                  applySearchAndReplace: values.applySearchAndReplace
                }
              }

              if (values.device && values.device.id) {
                request.variables.crawlerProject.device = {
                  connect: {
                    id: values.device.id
                  }
                }
              } else {
                request.variables.crawlerProject.device = {
                  disconnect: true
                }
              }
              if (values.agent && values.agent.id) {
                request.variables.crawlerProject.agent = {
                  connect: { id: values.agent.id }
                }
              } else if (crawlerProject.agent) {
                request.variables.crawlerProject.agent = {
                  disconnect: true
                }
              }

              try {
                await updateCrawlerProject(request)
                setAlertSuccessMessage('Crawler Project updated')
              } catch (error) {
                setAlertErrorMessage('Crawler Project update failed', error)
              }
            }}
            validate={this.validate.bind(this)}
            initialValues={crawlerProject}
            render={(formArgs) => <form onSubmit={formArgs.handleSubmit}>
              <UnsavedFormSpy />
              <GridContainer>
                <GridItem lg={8}>
                  {renderChildSectionFn(formArgs)}
                </GridItem>
              </GridContainer>
            </form>}
          />
        )}
      </Mutation>

    const renderSubmitButton = ({ submitting, invalid, handleSubmit, loading, values, ...formArgs }, searchAndReplace) => {
      return (
        <GridItem xs={12} largePadding>
          <FormActionsToolbar
            rightButtons={this.hasWritePermission() &&
              <Button
                type="submit"
                disabled={submitting || invalid}
                data-unique="btnCrawlerProjectSave"
              >
                {submitting && <LoadingIndicator alt/>}
                {!submitting && <ShowIcon icon="save"/>}
                Save
              </Button>
            }
            leftButtons={this.hasWritePermission() && searchAndReplace &&
              <Button data-unique="btnCrawlerProjectSaveAndApplyReplaceRules"
                      secondary
                      disabled={submitting || invalid}
                      onClick={() => {
                        values.applySearchAndReplace = true
                        handleSubmit()
                      }}>
                {submitting && <LoadingIndicator alt/>}
                {!submitting && <ShowIcon icon="save"/>}
                Save and Apply Replace Rules
              </Button>
            }
          />
        </GridItem>
      )
    }

    const renderGeneralSettings = ({ ...formArgs }) => (
      <GridContainer nounset>
        <GridItem xs={12} sm={6}>
          <Field
            name="name"
            component={renderTextField}
            label="Name"
            validate={required}
            disabled={!this.hasWritePermission()}
            data-unique="txtCrawlerProjectName"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="namespace"
            component={renderNamespaceField}
            forWrite
            label="Namespace"
            disabled={!this.hasWritePermission()}
            data-unique="txtCrawlerProjectNamespace"
          />
        </GridItem>
        <GridItem xs={12} >
          <Field
            name="tags"
            component={renderTagField}
            label="Tags"
            disabled={!this.hasWritePermission()}
            data-unique="tagCrawlerProjectTags"
          />
        </GridItem>

        <GridItem xs={12}>
          <Field
            name="description"
            component={renderTextArea}
            label="Description"
            rows={3}
            disabled={!this.hasWritePermission()}
            data-unique="txtCrawlerProjectDescription"
          />
        </GridItem>
        <GridItem xs={12}>
          <Text muted>{formatCreatedByAndLastChange(crawlerProject)}</Text>
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )

    const renderExecutionSettings = ( formArgs ) => {
      const values = formArgs.values
      return (<GridContainer nounset>
        <GridItem xs={12} sm={6}>
          <Field
            name="entryPoints"
            component={renderAutoSuggest}
            label="Conversation Entrypoints"
            helperText="A list of text messages for Botium to start a conversation with the chatbot"
            disabled={!this.hasWritePermission()}
            data-unique="txtCrawlerProjectEntryPoints"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="depth"
            component={renderIntField}
            label="Maximum Conversation Depth"
            helperText="Maximum number of question/answer steps Botium should follow"
            parse={parseInteger}
            format={undefined}
            validate={minValue(2)}
            disabled={!this.hasWritePermission()}
            data-unique="intCrawlerProjectCrawlerProcessDepth"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <React.Fragment>
            <GridContainer>
              <GridItem sm={6}>
                <Field
                  name="dynamicWelcomeMessages"
                  component={renderCheckbox}
                  label="Recognize Dynamic Welcome Messages"
                  type="checkbox"
                  data-unique="chkCrawlerProjectDynamicWelcomeMessages"
                  helperText="Enable it if the chatbot presents different welcome messages for each conversation start. Be aware that this can lead a slower crawler session."
                />
              </GridItem>
              <GridItem sm={6}>
                <Field
                  name="numberOfWelcomeMessages"
                  component={renderIntField}
                  label="Number of Welcome Messages"
                  parse={parseInteger}
                  format={undefined}
                  validate={minValue(0)}
                  disabled={!this.hasWritePermission() || values.dynamicWelcomeMessages}
                  data-unique="intCrawlerProjectNumberOfWelcomeMessages"
                  helperText="Number of messages sent by the chatbot when the conversation is started"
                />
              </GridItem>
            </GridContainer>
          </React.Fragment>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="waitForPrompt"
            component={renderIntField}
            label="Wait for Chatbot Messages"
            parse={parseInteger}
            format={undefined}
            disabled={!this.hasWritePermission()}
            data-unique="intCrawlerProjectWaitForPrompt"
            helperText="Milliseconds to wait for the chatbot to present the response"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="generateUtterances"
            component={renderCheckbox}
            label="Generate Utterance Lists"
            type="checkbox"
            data-unique="chkCrawlerProjectGenerateUtterances"
            helperText="Botium will generate Utterance Lists for each conversation step (recommended)"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="mergeUtterances"
            component={renderCheckbox}
            label="Merge Utterance Lists and User Examples"
            type="checkbox"
            disabled={!values.generateUtterances}
            data-unique="chkCrawlerProjectMergeUtterances"
            helperText="Botium will recognize non-unique Utterance Lists and merge them into one Utterance List"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="exitCriteria"
            component={renderAutoSuggest}
            label="Exit Criteria"
            helperText="Exit the conversation at the point where the chatbot text response or any of the button labels match with any of the exit criteria expression"
            disabled={!this.hasWritePermission()}
            data-unique="txtCrawlerProjectExitCriteria"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="ignoreButtons"
            component={renderAutoSuggest}
            label="Ignore Buttons"
            helperText="Ignore those buttons where the button text or payload match with any of the ignore buttons expression"
            disabled={!this.hasWritePermission()}
            data-unique="txtCrawlerProjectIgnoreButtons"
          />
        </GridItem>
        {hasDeviceSet &&
          <GridItem xs={12} sm={6}>
            <Field
              name="device.id"
              component={renderSelect}
              label="Connect Device"
              disabled={!this.hasWritePermission()}
              data-unique="selCrawlerProjectDeviceId"
              items={devices.map(d => {
                return {
                  key: d.id,
                  label: d.name,
                  value: d
                }
              })}
            />
          </GridItem>
        }
        {!license.shared &&
          <GridItem xs={12} sm={6}>
            <Field
              name="agent.id"
              component={renderSelect}
              label="Botium Agent"
              disabled={!this.hasWritePermission()}
              data-unique="selCrawlerProjectAgentId"
              loading={agentsData && agentsData.loading}
              error={agentsData && agentsData.error}
              items={
                (agentsData && agentsData.agents && agentsData.agents.map(a => {
                  return { key: a.id, agent: a }
                })) || []
              }
            />
          </GridItem>
        }
        <GridItem xs={12}>
          <Text muted>{formatCreatedByAndLastChange(crawlerProject)}</Text>
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )}

    const renderNamingConventionSettings = (formArgs) => {
      return (<GridContainer>
        <GridItem md={12}>
          <Text header>
            Search and Replace rules.
          </Text>
        </GridItem>
        <GridItem md={12}>
          <Text info>
            You can define search and replace rules for the crawler scripts result. If you re-run the crawler session, these rules will be saved here and you will need to re-apply them if required.
          </Text>
        </GridItem>
        <GridItem md={5} lg={5}>
          <Field
            name="searchAndReplaceRule.search"
            component={renderTextField}
            label="Search"
            disabled={!this.hasWritePermission()}
            data-unique={`txtCrawlerProjectSearch`}
          />
        </GridItem>
        <GridItem md={5} lg={5}>
          <Field
            name="searchAndReplaceRule.replace"
            component={renderTextField}
            label="Replace"
            disabled={!this.hasWritePermission()}
            data-unique={`txtCrawlerProjectReplace`}
          />
        </GridItem>
        <GridItem md={2} lg={2}>
          <Button
            noMargin
            largeMarginTop
            secondary
            dashedSecondary
            data-unique="btnAddSearchAndReplaceRule"
            onClick={() => {
              if(formArgs.values.searchAndReplaceRule && formArgs.values.searchAndReplaceRule.search && formArgs.values.searchAndReplaceRule.replace) {
                let searchAndReplaceRules = []
                if(formArgs.values.searchAndReplaceRules) {
                  searchAndReplaceRules = formArgs.values.searchAndReplaceRules
                }
                searchAndReplaceRules.push({
                  search: formArgs.values.searchAndReplaceRule.search,
                  replace: formArgs.values.searchAndReplaceRule.replace
                })
                formArgs.form.change('searchAndReplaceRules', searchAndReplaceRules)
                formArgs.form.change('searchAndReplaceRule.search', null)
                formArgs.form.change('searchAndReplaceRule.replace', null)
              }
            }}
          >
            <ShowIcon icon="add" />
          </Button>
        </GridItem>
        <GridItem md={12}>
          {formArgs.values.searchAndReplaceRules && formArgs.values.searchAndReplaceRules.map((searchAndReplaceRule, index) => {
            return (
              <Chip
                variant="searchAndReplace"
                key={`seachAndReplaceRule_${index}`}
                data-unique={`seachAndReplaceRule_${index}`}
                label={`"${searchAndReplaceRule.search}" replace with "${searchAndReplaceRule.replace}"`}
                onDelete={() => {
                  const searchAndReplaceRules = formArgs.values.searchAndReplaceRules
                  searchAndReplaceRules.splice(index, 1)
                  formArgs.form.change('searchAndReplaceRules', searchAndReplaceRules)
                  this.setState({reloadSearchAndReplaceRulesOnDelete: !this.state.reloadSearchAndReplaceRulesOnDelete})
                }}
              />
            )
          })}
        </GridItem>
        {renderSubmitButton(formArgs, true)}
      </GridContainer>
    )}

    const renderNotificationSettings = ({ ...formArgs }) => (
      <GridContainer nounset>
        {!this.isNotificationsEdition() &&
          <GridItem xs={12}>
            <Text warning><FeatureUpgradeNavLink>Email Notifications are not available in this
              Edition.</FeatureUpgradeNavLink></Text>
          </GridItem>
        }
        <GridItem xs={12}>
          <Field
            name="notificationReceiversEmail"
            component={renderAutoSuggest}
            label="Email notification receivers"
            helperText="Enter a list of email addresses to be notified on crawler session completion"
            validate={email}
            disabled={!this.hasWritePermission() || !this.isNotificationsEdition()}
            data-unique="asCrawlerProjectNotificationReceiverEmailSelection"
          />
        </GridItem>
        <GridItem xs={12}>
          <Text muted>{formatCreatedByAndLastChange(crawlerProject)}</Text>
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )

    const renderDangerZone = () => (
      <GridContainer nounset>
        <GridItem md={8} lg={4}>
          <ListItem>
            <Text lg danger padding><ShowIcon icon="trash" /></Text>

            <GridContainer>
              <GridItem xs={12}><Text bold>Delete Crawler Project</Text></GridItem>
              <GridItem xs={12}><Text>This removes the Crawler Project, its configuration & results</Text></GridItem>
            </GridContainer>
            <Mutation
              mutation={DELETE_CRAWLERPROJECT}
              onCompleted={() => {
                setAlertSuccessMessage('Crawler Project deleted')
                history.push('/testdatawizards/crawlerprojects')
              }}
              onError={error => {
                setAlertErrorMessage(
                  'Crawler Project deletion failed',
                  error,
                )
              }}
              refetchQueries={[
                {
                  query: CRAWLERPROJECTS_QUERY,
                },
              ]}
            >
              {(deleteCrawlerProject) => (
                <ConfirmationButton
                  confirmationText={`When deleting the Crawler Project "${crawlerProject.name}", all configuration settings are removed. Crawler Results won't be deleted. Are you sure you want to delete it ?`}
                  requireCheck={true}
                  danger
                  small
                  minWidth
                  disabled={!hasPermission(user, 'CRAWLERPROJECTS_DELETE')}
                  onClick={() => {
                    deleteCrawlerProject({
                      variables: { id: crawlerProject.id },
                    })
                  }}
                  data-unique="btnCrawlerProjectDelete"
                >
                  Delete
                </ConfirmationButton>
              )}
            </Mutation>
          </ListItem>
        </GridItem>
        <GridItem xs={8} />
        {crawlerSession && hasPermission(user, 'CRAWLERSESSIONS_DELETE') &&
          <React.Fragment>
            <GridItem md={8} lg={4}><Divider dense /></GridItem>
            <GridItem md={8} />
            <GridItem md={8} lg={4}>
              <ListItem>
                <Text lg danger padding><ShowIcon icon="trash" /></Text>

                <GridContainer>
                  <GridItem xs={12}><Text bold>Delete Crawler Session</Text></GridItem>
                  <GridItem xs={12}><Text>This removes the Crawler Session and its results</Text></GridItem>
                </GridContainer>
                <Mutation
                  mutation={DELETE_CRAWLERSESSION}
                  refetchQueries={[
                    {
                      query: CRAWLERPROJECT_QUERY,
                      variables: { id: crawlerProject.id }
                    },
                    {
                      query: CRAWLERSESSIONS_QUERY,
                      variables: {
                        crawlerProjectId: crawlerProject.id,
                        skip: 0,
                        first: 1,
                        orderBy: 'createdAt_DESC'
                      }
                    },
                  ]}
                  onCompleted={() => {
                    setAlertSuccessMessage(
                      'Crawler Session deleted',
                    )
                  }}
                  onError={error => {
                    setAlertErrorMessage(
                      'Crawler session deletion failed',
                      error,
                    )
                  }}
                  update={DeleteCrawlerSessionListsFromCache}
                >
                  {(
                    deleteCrawlerSession
                  ) => (
                    <ConfirmationButton
                      confirmationText={`When deleting this Crawler Session, all it's results are lost. You have to start another Crawler Session to get current results. If the session is running, it will be cancelled first. Are you sure you want to delete it ?`}
                      requireCheck={true}
                      danger
                      small
                      minWidth
                      onClick={async () => {
                        await deleteCrawlerSession({
                          variables: { id: crawlerSession.id },
                        })
                      }}
                      data-unique="btnCrawlerSessionDelete"
                    >
                      Delete
                    </ConfirmationButton>
                  )}
                </Mutation>
              </ListItem>
            </GridItem>
            <GridItem md={8} />
          </React.Fragment>
        }
        {crawlerSession && hasPermission(user, 'CRAWLERSESSIONS_CREATE') &&
          <React.Fragment>
            <GridItem md={8} lg={4}><Divider dense /></GridItem>
            <GridItem md={8} />
            <GridItem md={8} lg={4}>
              <ListItem>
                <Text lg danger padding><ShowIcon icon="trash" /></Text>

                <GridContainer>
                  <GridItem xs={12}><Text bold>Send Cancellation Request</Text></GridItem>
                  <GridItem xs={12}><Text>Send cancellation request to Crawler Session</Text></GridItem>
                </GridContainer>
                <Mutation
                  mutation={CANCEL_CRAWLERSESSION}
                  refetchQueries={[
                    {
                      query: CRAWLERPROJECT_QUERY,
                      variables: { id: crawlerProject.id }
                    },
                    {
                      query: CRAWLERSESSION_QUERY,
                      variables: { id: crawlerSession.id }
                    },
                  ]}
                  onCompleted={() => {
                    setAlertSuccessMessage(
                      'Sent cancellation request to Crawler Session',
                    )
                  }}
                  onError={error => {
                    setAlertErrorMessage(
                      'Sending cancellation request to Crawler Session failed',
                      error,
                    )
                  }}
                >
                  {(
                    cancelCrawlerSession
                  ) => (
                    <ConfirmationButton
                      confirmationText={`When cancelling a Crawler Session, all background processing will be stopped and the results are not complete. Are you sure you want to cancel it ?`}
                      requireCheck={true}
                      danger
                      small
                      minWidth
                      disabled={crawlerSession.status === 'READY' || crawlerSession.status === 'FAILED' || crawlerSession.status === 'PARTIALLY_FAILED' || crawlerSession.status === 'CANCELLED'}
                      onClick={() => {
                        cancelCrawlerSession({
                          variables: { id: crawlerSession.id },
                        })
                      }}
                      data-unique="btnCrawlerSessionCancel"
                    >
                      Send
                    </ConfirmationButton>
                  )}
                </Mutation>
              </ListItem>
            </GridItem>
          </React.Fragment>
        }
      </GridContainer>
    )

    return <GridContainer>
        <GridItem xs={12}>
          <CustomTabsSecondary
            name={`tabCrawlerProjectSettings_${crawlerProject.id}`}
            tabs={[
              {
                tabName: 'General',
                tabContent: _renderFormWrapper(renderGeneralSettings),
                locationPrefix: `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/settings/general`,
                dataUnique: 'tabCrawlerProjectSettingsGeneral'
              },
              {
                tabName: 'Execution',
                tabContent: _renderFormWrapper(renderExecutionSettings),
                locationPrefix: `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/settings/execution`,
                dataUnique: 'tabCrawlerProjectSettingsExecution'
              },
              {
                tabName: 'Naming Convention',
                tabContent: _renderFormWrapper(renderNamingConventionSettings),
                locationPrefix: `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/settings/naming`,
                dataUnique: 'tabCrawlerProjectSettingsNaming'
              },
              {
                tabName: 'Notification',
                tabContent: _renderFormWrapper(renderNotificationSettings),
                locationPrefix: `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/settings/notification`,
                dataUnique: 'tabCrawlerProjectSettingsNotification'
              },
              {
                tabName: 'Danger Zone',
                tabContent: renderDangerZone(),
                locationPrefix: `/testdatawizards/crawlerprojects/view/${crawlerProject.id}/settings/danger`,
                dataUnique: 'tabCrawlerProjectSettingsDanger'
              },
            ].filter(t => t)}
          />
        </GridItem>
    </GridContainer>
  }
}

export default compose(
  withApollo,
  withStyles(
    (theme) => ({
      ...crawlerprojectsStyle(theme),
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage }
  ),
  graphql(DEVICESETS_QUERY, {
    skip: (props) => !isLicenseDeviceSetsSupported(props.license),
    props: ({ data }) => ({
      deviceSetsData: data,
    })
  }),
  graphql(AGENTS_DROPDOWN_QUERY, {
    skip: (props) => props.license.shared,
    options: {
      fetchPolicy: 'network-only'
    },
    props: ({ data }) => ({
      agentsData: data,
    }),
  })
)(CrawlerSettingsForm)
