import React from 'react'
import PropTypes from 'prop-types'
import Field from 'components/Form/OptionalField'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import MediaSelectionDialog from 'components/Dialog/MediaSelectionDialog.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import { renderTextField } from 'components/Form/Form'

class FileSelectorField extends React.Component {
  state = {
    showDialog: false
  }

  render() {
    const { name, change, initialPath, extensionFilter, restrictPath, endAdornment, ...rest } = this.props

    return <>
      <Field
        name={name}
        component={renderTextField}
        data-unique={rest['data-unique']}
        endAdornment={<>
          <Tooltip title="Clear">
            <Button justIcon dense data-unique={`${rest['data-unique']}_Clear`} onClick={() => change(name, '')}>
              <ShowIcon icon="times" />
            </Button>
          </Tooltip>
          <Tooltip title="Select File">
            <Button justIcon dense data-unique={`${rest['data-unique']}_Dialog`} onClick={() => this.setState({ showDialog: true  })}>
              <ShowIcon icon="folder" />
            </Button>
          </Tooltip>
          {endAdornment}
        </>}
        {...rest}
      />
      <MediaSelectionDialog
        allowFileSelection
        multiple={false}
        initialPath={initialPath}
        extensionFilter={extensionFilter}
        restrictPath={restrictPath}
        open={!!this.state.showDialog}
        onCancel={() => this.setState({ showDialog: false })}
        onOk={async ({ selectedFiles }) => {
          if (selectedFiles.length > 0) {
            const initialPathParts = initialPath.split('/').filter(p => p).length
            change(name, selectedFiles[0].slice(initialPathParts).join('/'))
          }
          this.setState({ showDialog: false })
        }}
        title="Select File"
      />              
      </>
  }
}

FileSelectorField.propTypes = {
  name: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  'data-unique': PropTypes.string.isRequired,
  endAdornment: PropTypes.any,
  initialPath: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  extensionFilter: PropTypes.arrayOf(PropTypes.string),
  restrictPath: PropTypes.bool
}

export default FileSelectorField