import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { compose, withApollo } from 'react-apollo'
import Hidden from '@material-ui/core/Hidden'
import withStyles from '@material-ui/core/styles/withStyles'
import Alert from 'components/Info/Alert'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ShowIcon from 'components/Icon/ShowIcon'
import Text from 'components/Typography/Text'
import BotiumBoxLogo from 'components/Typography/BotiumBoxLogo'
import loginBackground from 'assets/img/login-bg.png'
import loginImage from 'assets/img/login-image.png'

import entryRoutes from 'routes/entry.jsx'

import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx'
import { isDarkmode } from 'components/Darkmode/helper'

class Entry extends React.Component {

  render() {
    const { classes } = this.props

    return (<>
        <div className={classes.entryContainer}>
          <GridContainer autoHeight>
            <GridItem xs={12} md={6} largePadding>
              <GridContainer>
                <GridItem xs={6} middle>
                  <BotiumBoxLogo sidebar white={isDarkmode()} />
                </GridItem>
                <GridItem xs={6} middle right>
                  <a href="https://support.botium.ai" target="_blank" rel="noopener noreferrer">
                    <Text primary><ShowIcon icon="question-circle" /> Knowledge Center</Text>
                  </a>
                </GridItem>
                <GridItem xs={12}>
                  <Switch>
                    {entryRoutes.map((prop, key) => <Route path={prop.path} component={prop.component} key={key} />)}
                  </Switch>
                </GridItem>
              </GridContainer>
            </GridItem>
            
              <GridItem xs={12} md={6}>
              <Hidden smDown>
                <GridContainer autoHeight>
                  <GridItem xs={12} middle center style={{ backgroundImage: `url('${loginBackground}')` }}>
                    <img src={loginImage} alt="Welcome on the Botium Login Page" width="60%"  />
                  </GridItem>
                </GridContainer>
                </Hidden>
              </GridItem>
            
          </GridContainer>
        </div>
      <Alert />
    </>)
  }
}

Entry.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    state => ({ user: state.token.user })
  )
)(withApollo(Entry))
