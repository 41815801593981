import React from 'react'
import { connect } from 'react-redux'
import { Query } from 'react-apollo'
import { withRouter, Switch, Route } from 'react-router-dom'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import SimpleList from 'components/List/SimpleList.jsx'
import Button from 'components/Button/Button'
import { getDeviceProviderIcon, getDeviceProviderName } from './DeviceProvider.jsx'

import ShowIcon from 'components/Icon/ShowIcon'

import DeviceProvider from './DeviceProvider.jsx'

import { DEVICEPROVIDERS_QUERY } from './gql'

import { hasPermission } from 'botium-box-shared/security/permissions'

class DeviceProviders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCounter: 0
    }
  }

  render() {
    const { history, location, user } = this.props
    return (
      <GridContainer>
        {hasPermission(user, 'DEVICEPROVIDERS_MANAGE') && <GridItem xs={12} borderBottom middle>
          <Button
            secondary
            data-unique="btnNew"
            onClick={() => {
              this.setState({ newCounter: this.state.newCounter + 1, id: 'new' })
              history.push('/settings/devicecloud/deviceproviders/new')
            }}
            >
              <ShowIcon icon="plus" /> New
            </Button>
        </GridItem>}        
        <GridItem xs={12} sm={4} md={3} noPadding>
          
          <Query query={DEVICEPROVIDERS_QUERY} notifyOnNetworkStatusChange={true}>
            {({ loading, error, data, refetch }) => {
              return <SimpleList
                name="tblDeviceProviders"
                listData={(data && data.deviceproviders && data.deviceproviders.map(a => ({
                    id: a.id,
                    name: a.name,
                    secondary: getDeviceProviderName(a.type),
                    icon: <img alt={a.name} style={{maxWidth: '16px'}} src={getDeviceProviderIcon(a.type)} />,
                    selected: location.pathname.indexOf(a.id) >= 0,
                    onClick: () => history.push(`/settings/devicecloud/deviceproviders/${a.id}`)
                  }))) || []}
                onRefresh={() => refetch()}
                pageLoading={loading}
              />
            }}
          </Query>
        </GridItem>
        <GridItem xs={12} sm={8} md={9} borderLeft>
          <Switch>
            <Route
              path="/settings/devicecloud/deviceproviders/new"
              render={props => <DeviceProvider key={`new_${this.state.newCounter}`} onReady={(id) => history.push(`/settings/devicecloud/deviceproviders/${id}`)} {...props}/>}
            />
            <Route
              path="/settings/devicecloud/deviceproviders/:id"
              render={props => <DeviceProvider id={props.match.params.id} key={props.match.params.id} onReady={(id) => !id && history.push('/settings/devicecloud/deviceproviders')} {...props}/>}
            />
          </Switch>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user })
)(withRouter(DeviceProviders))
