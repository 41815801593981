import { createStore, combineReducers, applyMiddleware } from 'redux'
import { createLogger as reduxLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'
import reducers from '../reducers'
import { DESTROY_STORE } from '../constants'

const loadState = () => {
  // We need the try block because user may not permit our accessing localStorage.
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      // The key 'state' does not exist.
      return undefined // Let our reducer initialize the app.
    }

    return JSON.parse(serializedState)
  } catch (error) {
    console.warn(error)
    return undefined // Let our reducer initialize the app.
  }
}

const saveState = state => {
  try {
    // Serialize the state. Redux store is recommended to be serializable.
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (error) {
    console.warn(error)
  }
}

export default function configureStore(initialState) {
  const middlewares = [ thunk ]
  if (process.env.NODE_ENV === `development`) {
    middlewares.push(reduxLogger({
      level: 'debug',
      collapsed: true,
    }))
  }
  const middleware = applyMiddleware(...middlewares)
  const appReducer = combineReducers(reducers)
  const rootReducer = (state, action) => {
    if(action.type === DESTROY_STORE) {
       state = Object.assign({}, initialState)
    }
    return appReducer(state, action)
  }

  const persistedState = Object.assign({}, initialState, loadState())

  const store = createStore(rootReducer, persistedState, middleware)
  store.subscribe(
    throttle(() => {
      saveState(store.getState())
    }, 1000),
  ) // At most once this length of time.

  return store
}
