import React from 'react'
import ReactPaginate from 'react-paginate'
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

const style = theme => ({
  root: {
    //marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    listStyleType: 'none',
    padding: 0
  },
  pageLink: {
    backgroundColor: theme.colors.backgroundGray,
    color: theme.colors.common.primary,
    borderRadius: '3px',
    padding: '0.2rem 0.5rem',
    cursor: 'pointer',
    fontWeight: 'bold',
    margin: '0px 3px'
  },
  pageLinkActive: {
    backgroundColor: theme.colors.common.secondary,
    color: theme.colors.common.primary,
    cursor: 'default',
    '&:hover,&:focus': {
      color: '#FFFFFF'
    },
  },
  pageLinkInactive: {
  }
})

function Paginate({ classes, onChange, currentPage, pageCount }) {
  return <ReactPaginate
    breakLabel="..."
    nextLabel=">"
    onPageChange={(event) => onChange(event.selected)}
    forcePage={currentPage}
    pageRangeDisplayed={3}
    marginPagesDisplayed={3}
    pageCount={pageCount}
    previousLabel="<"
    renderOnZeroPageCount={null}
    //pageClassName="page-item"
    pageLinkClassName={classes.pageLink}
    //previousClassName="page-item"
    previousLinkClassName={classNames({ [classes.pageLink]: true, [classes.pageLinkInactive]: true })}
    //nextClassName="page-item"
    nextLinkClassName={classNames({ [classes.pageLink]: true, [classes.pageLinkInactive]: true })}
    //breakClassName="page-item"
    breakLinkClassName={classNames({ [classes.pageLink]: true, [classes.pageLinkInactive]: true })}
    containerClassName={classes.root}
    activeLinkClassName={classes.pageLinkActive}
  />
}

export default withStyles(style)(Paginate)