import React from 'react'
import { connect } from 'react-redux'
import copyToClipboard from 'copy-to-clipboard'
// @material-ui/core components
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query } from 'react-apollo'
// core components
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  url,
  composeValidators,
  renderTextField,
  CustomTextField,
  renderSelect, renderCodeArea, prettyPrintJson, json, renderCheckbox
} from 'components/Form/Form'

import { APIKEYS_QUERY } from '../../views/Settings/gql'
import Button from '../Button/Button'
import { setAlertSuccessMessage } from 'actions/alert'
import config from 'config'
import { capSetDescription, usedByCapabilitySet } from './Helper'

const SESSION_DATA_SAMPLE = JSON.stringify({
  email: 'support@chatlayer.ai',
  zipCode: '2000'
}, null, 2)

export function chatlayerCaps2Form(caps) {
  return {
    chatlayer: {
      url: (
        caps.find(c => c.name === 'CHATLAYER_URL') || { stringValue: '' }
      ).stringValue,
      accessToken: (
        caps.find(c => c.name === 'CHATLAYER_ACCESS_TOKEN') || { stringValue: '' }
      ).stringValue,
      verifyToken: (
        caps.find(c => c.name === 'CHATLAYER_VERIFY_TOKEN') || { stringValue: '' }
      ).stringValue,
      sessionData: (
        caps.find(c => c.name === 'CHATLAYER_SESSION_DATA') || { jsonValue: '' }
      ).jsonValue,
      welcomeMessage: (
        caps.find(c => c.name === 'CHATLAYER_WELCOME_MESSAGE') || { booleanValue: false }
      ).booleanValue,
      botId: (
        caps.find(c => c.name === 'CHATLAYER_BOT_ID') || { stringValue: '' }
      ).stringValue,
      version: (
        caps.find(c => c.name === 'CHATLAYER_VERSION') || { stringValue: 'DRAFT' }
      ).stringValue,
      language: (
        caps.find(c => c.name === 'CHATLAYER_LANGUAGE') || { stringValue: 'en' }
      ).stringValue,
    }
  }
}

const capNamesMap = {
  'chatlayer.url': 'CHATLAYER_URL',
  'chatlayer.accessToken': 'CHATLAYER_ACCESS_TOKEN',
  'chatlayer.verifyToken': 'CHATLAYER_VERIFY_TOKEN',
  'chatlayer.sessionData': 'CHATLAYER_SESSION_DATA',
  'chatlayer.welcomeMessage': 'CHATLAYER_WELCOME_MESSAGE',
  'chatlayer.botId': 'CHATLAYER_BOT_ID',
  'chatlayer.version': 'CHATLAYER_VERSION',
  'chatlayer.language': 'CHATLAYER_LANGUAGE'
}

export function chatlayerForm2caps(values) {
  return [
    {
      name: 'CHATLAYER_URL',
      type: 'STRING',
      stringValue: values.chatlayer.url || '',
    },
    {
      name: 'CHATLAYER_CHANNEL_ID',
      type: 'STRING',
      stringValue: values.chatlayer.channelId || '',
    },
    {
      name: 'CHATLAYER_ACCESS_TOKEN',
      type: 'STRING',
      stringValue: values.chatlayer.accessToken || '',
    },
    {
      name: 'CHATLAYER_VERIFY_TOKEN',
      type: 'STRING',
      stringValue: values.chatlayer.verifyToken || '',
    },
    {
      name: 'CHATLAYER_SESSION_DATA',
      type: 'JSON',
      jsonValue: values.chatlayer.sessionData || '',
    },
    {
      name: 'CHATLAYER_WELCOME_MESSAGE',
      type: 'BOOLEAN',
      booleanValue: values.chatlayer.welcomeMessage || false,
    },
    {
      name: 'CHATLAYER_BOT_ID',
      type: 'STRING',
      stringValue: values.chatlayer.botId || '',
    },
    {
      name: 'CHATLAYER_VERSION',
      type: 'STRING',
      stringValue: values.chatlayer.version || '',
    },
    {
      name: 'CHATLAYER_LANGUAGE',
      type: 'STRING',
      stringValue: values.chatlayer.language || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'chatlayer' }
  ]
}

class ChatlayerEditUnconnected extends React.Component {
  render() {
    const { disabled, setAlertSuccessMessage, capSetCapNames } = this.props

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => {
      const webhookUrl = `${config.api.ext}/inbound?APIKEY=${values.chatlayer.apiKey || 'SELECT_API_KEY'}`
      return (
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomTextField
              input={{
                value: webhookUrl,
                readOnly: true,
              }}
              label="Botium Webhook Url (for Copy&Paste)"
              data-unique="txtChatlayerEditWebhookUrl"
              endAdornment={
                <Button
                  onClick={() => {
                    copyToClipboard(webhookUrl)
                    setAlertSuccessMessage(`Botium Webhook Url copied to the clipboard`)
                  }}
                  justIcon
                  data-unique="btnChatlayerWebhookEditCopy"
                >
                  <FileCopyIcon />
                </Button>
              }
              helperText="You have to configure your Chatlayer Webhook Channel to use this URL as Webhook url, adding API Key as query parameter."
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Query query={APIKEYS_QUERY}>
              {({ loading, error, data }) => {
                return (
                  <Field
                    name="chatlayer.apiKey"
                    component={renderSelect}
                    label="API Key"
                    disabled={disabled}
                    data-unique="selChatlayerEditApiKey"
                    loading={loading}
                    error={error}
                    items={data && data.apikeys && data.apikeys.map(a => {
                      return { key: a.key, label: a.name }
                    })}
                    helperText="Choose an API Key to generate the Botium Webhook Url"
                  />
                )
              }}
            </Query>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="chatlayer.url"
              component={renderTextField}
              label="Chatlayer API Url"
              validate={composeValidators(required, url)}
              data-unique="txtChatlayerEditUrl"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['chatlayer.url']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['chatlayer.url'], 'Copy here the API Url from Chatlayer webhook setting dialog.')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="chatlayer.accessToken"
              component={renderTextField}
              label="Chatlayer Access Token"
              validate={required}
              data-unique="txtChatlayerAccessToken"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['chatlayer.accessToken']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['chatlayer.accessToken'], 'Copy from the Chatlayer surface from the Tokens menu')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="chatlayer.verifyToken"
              component={renderTextField}
              label="Chatlayer Verify Token"
              validate={required}
              data-unique="txtChatlayerVerifyToken"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['chatlayer.verifyToken']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['chatlayer.verifyToken'], 'Copy from Chatlayer webhook dialog from "Webhook verify token" field')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="chatlayer.botId"
              component={renderTextField}
              label="Chatlayer Bot ID"
              data-unique="txtChatlayerBotId"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['chatlayer.botId']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['chatlayer.botId'], 'Optionally you can copy the Bot ID from the browser url of your bot on the Chatlayer surface. The Bot ID is the string after the /bots/ resource. The Bot ID is only needed for the detailed NLP data.')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="chatlayer.version"
              component={renderSelect}
              label="Chatlayer Version"
              data-unique="selChatlayerVersion"
              items={[
                { key: 'DRAFT' }, { key: 'LIVE' }
              ]}
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['chatlayer.version']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['chatlayer.version'], 'Select the version of your bot')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="chatlayer.language"
              component={renderTextField}
              label="Chatlayer Language Code"
              validate={required}
              data-unique="txtChatlayerLanguage"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['chatlayer.language']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['chatlayer.language'], 'Copy from bot setting the primary language code or one of the extra languages code. The language code is needed for the detailed NLP data.')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              className="CapabilitiesShort"
              name="chatlayer.sessionData"
              component={renderCodeArea}
              options={{ mode: 'application/json' }}
              label="Session data"
              codeFormat={prettyPrintJson}
              validate={json}
              data-unique="codeChatlayerEditSessionData"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['chatlayer.sessionData']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['chatlayer.sessionData'], 'Optionally you are able to set properties for the session')}
            />
            <Button data-unique="btnChatlayerEditSessionData" link
              onClick={() => change('chatlayer.sessionData', SESSION_DATA_SAMPLE)}>Insert sample session data</Button>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              name="chatlayer.welcomeMessage"
              component={renderCheckbox}
              label="Trigger Welcome Message"
              type="checkbox"
              data-unique="chkChatlayerWelcomeMessage"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['chatlayer.welcomeMessage']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['chatlayer.welcomeMessage'])}
            />
          </GridItem>
        </GridContainer>
      )
    }} />)
  }
}

const ChatlayerEdit = connect(
  state => ({}),
  { setAlertSuccessMessage }
)(ChatlayerEditUnconnected)
export { ChatlayerEdit }