import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import SimpleList from 'components/List/SimpleList.jsx'
import Button from 'components/Button/Button'

import ShowIcon from 'components/Icon/ShowIcon'

import ApiKey from './ApiKey.jsx'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { APIKEYS_QUERY } from './gql'

import { hasPermission } from 'botium-box-shared/security/permissions'

class ApiKeys extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCounter: 0
    }
  }
  render() {
    const { history, location, user } = this.props

    return (
      <GridContainer>
        {hasPermission(user, 'APIKEYS_MANAGE') && <GridItem xs={12} borderBottom middle>
          <Button
            secondary
            data-unique="btnNew"
            onClick={() => {
              this.setState({ newCounter: this.state.newCounter + 1, id: 'new' })
              history.push('/settings/security/apikeys/new')
            }} 
            >
              <ShowIcon icon="plus" /> New
            </Button>
        </GridItem>}        
        <GridItem xs={12} sm={4} md={3} noPadding>
          <Query query={APIKEYS_QUERY} notifyOnNetworkStatusChange={true}>
            {({ loading, error, data, refetch }) => {
              return <SimpleList
                name="tblApiKeys"
                listData={(data && data.apikeys && data.apikeys.map(a => ({
                    id: a.id,
                    name: a.name,
                    secondary: `Last Active: ${a.lastActivityAt ? moment(a.lastActivityAt).fromNow() : 'never'} - ${(a.validFrom && moment(a.validFrom).format('L')) || '*'} - ${(a.validTo && moment(a.validTo).format('L')) || '*'}`,
                    icon: <ShowIcon icon="key" />,
                    selected: location.pathname.indexOf(a.id) >= 0,
                    onClick: () => history.push(`/settings/security/apikeys/${a.id}`)
                  }))) || []}
                onRefresh={() => refetch()}
                pageLoading={loading}
              />
            }}
          </Query>
        </GridItem>
        <GridItem xs={12} sm={8} md={9} borderLeft>
        <Switch>
            <Route
              path="/settings/security/apikeys/new"
              render={props => <ApiKey key={`new_${this.state.newCounter}`} onReady={(id) => history.push(`/settings/security/apikeys/${id}`)} {...props}/>}
            />
            <Route
              path="/settings/security/apikeys/:id"
              render={props => <ApiKey id={props.match.params.id} key={props.match.params.id} onReady={(id) => !id && history.push('/settings/security/apikeys')} {...props}/>}
            />
          </Switch>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user })
)(withStyles(settingsStyle)(withRouter(ApiKeys)))
