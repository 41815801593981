import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
// @material-ui/core components
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'

import ShowIcon from 'components/Icon/ShowIcon'
import stepperStyle from 'assets/jss/material-dashboard-react/components/stepperStyle'

export default withStyles(stepperStyle)(({ classes, page, steps, hasError }) => {

  return (<Stepper activeStep={page} classes={{
    root: classes.stepperRoot
  }} connector={<StepConnector orientation="horizontal" classes={{
    horizontal: classes.stepConnectorHorizontal,
    lineHorizontal: classes.stepConnectorLineHorizontal
  }} />}>
    {steps.map((step, index) => {
      const props = {}
      const labelProps = {}
      if (step.disabled) {
        props.disabled = true
      }
      // external error, or internal validation error
      if (step.error || (hasError && hasError(index))) {
        labelProps.error = true
      }
      if (step.sublabel) {
        labelProps.optional = <span className={classNames({
          [classes.stepSubLabel]: true,
          [classes.stepSubLabelError]: labelProps.error
        })}>{step.sublabel}</span>
      }
      return (
        <Step key={index} {...props}>
          <StepLabel icon={<>
            <span className={classNames({
              [classes.stepIconLabel]: true,
              [classes.stepIconLabelActive]: index <= page,
            })}>{index + 1}</span>
            <ShowIcon icon="square" className={classNames({
              [classes.stepIcon]: true,
              [classes.stepIconDone]: index <= page,
              [classes.stepIconError]: labelProps.error
            })} />
          </>} {...labelProps}>
            <span className={classNames({
              [classes.stepLabel]: true
            })}>{step.label}</span>
          </StepLabel>
        </Step>
      )
    })}
  </Stepper>)
})
