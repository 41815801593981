import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
// core components
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle'

function CustomTable({ ...props }) {
  const { classes, tableHead, tableData, tableHeaderColor, print } = props
  const dataUnique = props.dataUnique || props.name

  if (print) {
    return <table className={classes.tablePrint}><tbody>
      {tableHead !== undefined ? <tr>{tableHead.map((prop, key) => <th className={classes.tableCell + ' ' + classes.tableHeadCell} key={key}>{prop}</th>)}</tr> : null}
      {tableData.map((prop, key) => <tr key={key}>{prop.map((prop, key) => <td className={classes.tableCell} key={key}>{prop}</td>)}</tr>)}
    </tbody></table>
  }


  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table} data-unique={dataUnique} data-length={tableHead ? tableHead.length : null}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow key={key}>
                {prop.map((prop, key) => {
                  return (
                    <TableCell className={classes.tableCell} key={key}>
                      {prop}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
}

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
}

export default withStyles(tableStyle)(CustomTable)
