import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { compose, graphql } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import SimpleList from 'components/List/SimpleList.jsx'
import QueryStatus from 'components/Info/QueryStatus'
import ShortenedText from 'components/Typography/ShortenedText'
import { formatLastChange } from 'helper/authHelper'

import ShowIcon from 'components/Icon/ShowIcon'
import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TESTSET_QUERY,
  TESTSETSOURCES_SCRIPTS_QUERY,
  TESTSET_EDITABLE_UTTERANCE_QUERY
} from '../TestSets/gql'
import Text from 'components/Typography/Text.jsx'


const ParaphraserWizardUtterances = compose(
  withStyles(testsetsStyle),
  graphql(TESTSET_EDITABLE_UTTERANCE_QUERY, {
    options: (props) => ({
      variables: {
        testSetScriptId: props.scriptId,
        name: props.scriptName
      }
    }),
    props: ({ data }) => ({
      utteranceData: data,
    }),
  }),
)(({ utteranceData, classes, onStartParaphrase }) => <QueryStatus {...utteranceData} query="testseteditableutterance">{({ testseteditableutterance }) => {
  return <GridContainer>
    <GridItem xs={12} borderBottom className={classes.paraphraseUtterancesHeader}>
      <GridContainer>
        <GridItem xs={12} right middle>
          <Button
            dashed
            onClick={onStartParaphrase}
            data-unique="btnTestSetUtteranceParaphrase"
          >
            <ShowIcon icon="infinity" />
            Paraphrase it!
          </Button>
        </GridItem>
      </GridContainer>
    </GridItem>
    <GridItem xs={12} grey block>
      <GridContainer>
        {testseteditableutterance.utterances.map((utterance, index) => (<React.Fragment key={`utterance_${index}`}>
          <GridItem floatLeft key={`utterance_${index}`}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem className={classes.testsetsUtteranceFieldGrid}>
                    <Text muted>User Example #{index + 1}</Text>
                    <Text bold><ShortenedText maxlength={100}>{utterance}</ShortenedText></Text>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </React.Fragment>))}
      </GridContainer>
    </GridItem>
  </GridContainer>
}}</QueryStatus>)

class ParaphraserWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scriptId: props.match.params.scriptId || null,
      scriptName: props.match.params.scriptName || null
    }
  }

  render() {
    const { testSetData, testSetScriptsData, history, location, match } = this.props

    const testSet = testSetData && testSetData.testset
    const { loading, testsetscripts, refetch } = testSetScriptsData

    let utterances = []
    if (testSet && testsetscripts && testsetscripts.length > 0) {
      testsetscripts.filter(t => t.scriptType === 'SCRIPTING_TYPE_UTTERANCES').forEach(t => {
        const utt = {
          id: t.id,
          name: t.name,
          secondary: formatLastChange(t),
          selected: location.pathname.indexOf(t.id) > 0,
          icon: <ShowIcon icon="file-alt" />,
          actions: [
            {
              id: `open_${t.id}`,
              icon: 'file-alt',
              name: 'Edit Utterance',
              onClick: () => setTimeout(() => history.push(`/testsets/view/${testSet.id}/testcases/viewutterance/${t.id}/${encodeURIComponent(t.name)}`), 0)
            }
          ],
          onClick: () => history.push(`/testdatawizards/paraphraser/${testSet.id}/uview/${t.id}/${encodeURIComponent(t.name)}`)
        }
        utterances.push(utt)
      })
    }
    utterances = _.sortBy(utterances, [s => s.name.toLowerCase()])

    return (<GridContainer>
      {testSet && <GridItem xs={12}>
        <Card noMargin>
          <CardBody noPaddingTop noPaddingBottom>
            <GridContainer>
              <GridItem xs={12} sm={4} md={3} noPadding>
                <SimpleList
                  name="ParaphraserWizard_TestCases"
                  listData={utterances}
                  onRefresh={() => refetch()}
                  pageLoading={loading}
                />
              </GridItem>
              <GridItem xs={12} sm={8} md={9} borderLeft>
                {match.params.scriptId &&
                  <ParaphraserWizardUtterances key={match.params.scriptId + match.params.scriptName}
                    testSet={testSet}
                    scriptId={match.params.scriptId}
                    scriptName={match.params.scriptName}
                    onStartParaphrase={() => history.push(`/testdatawizards/paraphraser/${testSet.id}/pview/${match.params.scriptId}/${encodeURIComponent(match.params.scriptName)}`)}
                  />}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>}
    </GridContainer>)
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, settings: state.settings })
  ),
  graphql(TESTSET_QUERY, {
    options: (props) => ({
      variables: {
        id: props.testSetId || props.match.params.testSetId
      },
    }),
    props: ({ data }) => ({
      testSetData: data,
    }),
  }),
  graphql(TESTSETSOURCES_SCRIPTS_QUERY, {
    options: (props) => ({
      variables: {
        testSetId: props.testSetId || props.match.params.testSetId
      },
    }),
    props: ({ data }) => ({
      testSetScriptsData: data,
    }),
  }),
)(ParaphraserWizard)
