import React from 'react'
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import {Mutation, compose, graphql, Query} from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Text from 'components/Typography/Text.jsx'

import {
  renderTextField,
  renderSelect,
  renderSlider,
  parseInteger,
  composeValidators,
  required,
  minValue,
  maxValue,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import TestSetContentSelector from '../TestSets/TestSetContentSelector.jsx'
import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TESTSET_QUERY,
  CLONETESTSETOPTIONS_QUERY,
  CLONE_MODIFY_TESTSET,
  RefetchTestSetQueries,
  CLONE_AND_MODIFY_TESTSET_HUMANIFIABLE_UTTERANCE_COUNT_QUERY
} from '../TestSets/gql'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'
import Divider from '../../components/Divider/Divider'

class HumanificationWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emptyHumanification: {
        testSetId: props.testSetId || props.match.params.testSetId,
        outputToFolder: false,
        cloneHumanifiers: [],
        clonePercentToHumanify: 100,
        local: true,
        repositories: [],
        folders: [],
        downloadlinks: []
      }
    }
  }

  render() {
    const { testSetData, cloneOptionsData, setAlertSuccessMessage, setAlertErrorMessage, history } = this.props
    const { emptyHumanification, humanificationRunning } = this.state

    return (<GridContainer>
      <GridItem md={12} lg={8}><Card><CardBody><QueryStatus queries={[testSetData, cloneOptionsData]} query={['testset', 'clonetestsetoptions']}>{({ testset, clonetestsetoptions }) => {
        if (!emptyHumanification.name) emptyHumanification.name = `${testset.name} - Humanified`

        return <Mutation
          mutation={CLONE_MODIFY_TESTSET}
          refetchQueries={() => {
            return [
              ...RefetchTestSetQueries()
            ]
          }}
        >
          {(cloneAndModifyTestSet, {loading, error}) => (
            <Form
              onSubmit={async (values, form) => {
                this.setState({ humanificationRunning: true })
                try {
                  const res = await cloneAndModifyTestSet({
                    variables: {
                      id: testset.id,
                      options: {
                        name: values.name,
                        output: values.outputToFolder ? 'FOLDER' : 'LOCAL',
                        humanifiersToExecute: values.cloneHumanifiers,
                        percentToHumanify: (values.clonePercentToHumanify || 0) / 100,
                        local: true,
                        repositoryIds: values.repositories || [],
                        folderIds: values.folders || [],
                        downloadlinkIds: values.downloadlinks || []
                      }
                    }
                  })
                  form.initialize(emptyHumanification)
                  this.setState({ humanificationRunning: false })
                  setAlertSuccessMessage('Test Set humanification completed')
                  history.push(`/testsets/view/${res.data.cloneAndModifyTestSet.id}`)
                } catch(error) {
                  this.setState({ humanificationRunning: false })
                  setAlertErrorMessage(`Test Set humanification failed`, error)
                }
              }}
              initialValues={emptyHumanification}
              validate={(values) => this.state.contentValidate ? this.state.contentValidate(values) : null}
              render={({ form, handleSubmit, submitting, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Text submitting bottomMarginsm bold500 labelText>Utterances</Text>
                    </GridItem>
                    <TestSetContentSelector allowChangeTestSet={false} showConvos={false} showUtterances={true} addFormValidate={(fn) => this.setState({ contentValidate: fn })}/>
                    <GridItem xs={12} largeMarginTop>
                      <Text header>Configuration</Text>
                    </GridItem>
                    <GridItem xs={12} sm={6} largeMarginTop>
                      <Field
                        name="cloneHumanifiers"
                        component={renderSelect}
                        label="Select Humanification Algorithm(s)"
                        multiple
                        items={clonetestsetoptions.humanifiers.map(h => {
                          return { key: h, label: h }
                        })}
                        validate={required}
                        disabled={clonetestsetoptions.humanifiers.length === 0}
                        helperText={'The Test Set will be mutated with various algorithms to make the the test cases appear more "human"'}
                        data-unique="selTestSetHumanificationSelection"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} largeMarginTop>
                      <Query
                        query={CLONE_AND_MODIFY_TESTSET_HUMANIFIABLE_UTTERANCE_COUNT_QUERY}
                        variables={{ testSetId: testset.id }}
                        fetchPolicy="network-only"
                      >
                        {(queryResult) => <QueryStatus {...queryResult} query="cloneAndModifyTestSetHumanifiableUtteranceCount">{({ cloneAndModifyTestSetHumanifiableUtteranceCount }) => {
                          const uttCount = cloneAndModifyTestSetHumanifiableUtteranceCount
                          const humanified = Math.round(uttCount * values.clonePercentToHumanify / 100) || 1
                          return <Field
                            name="clonePercentToHumanify"
                            component={renderSlider}
                            label="Percentage to Humanify"
                            helperText={
                              <>
                                <Text>Expected number of generated utterances: {humanified}</Text>
                                <Text muted>All the humanification algorithms will only be applied to utterances, not convo files. You can set a percentage of how many of the utterances will undergo the humanification.</Text>
                              </>
                            }
                            parse={parseInteger}
                            min={0}
                            max={100}
                            validate={composeValidators(
                              required,
                              minValue(1),
                              maxValue(100),
                            )}
                            step={1}
                            form={form}
                            disabled={clonetestsetoptions.humanifiers.length === 0}
                            data-unique="intTestSetClonePercentToHumanify"
                            showInputField
                          />
                        }}
                        </QueryStatus>}
                      </Query>
                    </GridItem>
                    <GridItem xs={12}>
                      <Divider />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name="name"
                        component={renderTextField}
                        label="New Test Set Name"
                        validate={required}
                        data-unique="txtTestSetTransformationNewTestSetName"
                      />
                    </GridItem>
                    <GridItem xs={12} largePadding>
                      <FormActionsToolbar rightButtons={
                        <Button
                          type="submit"
                          disabled={humanificationRunning || submitting || invalid}
                          data-unique="btnTestSetDownloadTestSetAndHumanification"
                        >
                          {humanificationRunning && <><LoadingIndicator alt /> Humanification is running ...</>}
                          {!humanificationRunning && <><ShowIcon icon="play-circle" />Add Humanification</>}
                        </Button>
                      }/>
                    </GridItem>
                  </GridContainer>
                </form>
              )}
            />
          )}
        </Mutation>
      }}</QueryStatus></CardBody></Card></GridItem>
    </GridContainer>)
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, settings: state.settings }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(TESTSET_QUERY, {
    options: (props) => ({
      variables: {
        id: props.testSetId || props.match.params.testSetId
      },
    }),
    props: ({ data }) => ({
      testSetData: data,
    }),
  }),
  graphql(CLONETESTSETOPTIONS_QUERY, {
    props: ({ data }) => ({
      cloneOptionsData: data,
    }),
  })
)(HumanificationWizard)
