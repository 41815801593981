import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'
import {NavLink} from 'react-router-dom'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {UTTERANCES_INCOMPREHENSION_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'
import {toFixedSafe} from './helper'

class NotResolvedToAnyIntent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTable() {
    const {utterancesData, match} = this.props
    let testSessionId = match.params.testSessionId

    let progressOrError = renderProgressOrError(utterancesData)
    if (progressOrError) {
      return progressOrError
    }

    let data = utterancesData.trainerUtterances
    data = _.sortBy(data, (entry) => entry.intent.confidenceReal)

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Utterance', 'Predicted Intent', 'Expected Intent', 'Prediction Confidence']}
        tableData={data.map(entry => {
          return [
            () => <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/utterance/${encodeURIComponent(entry.utterance)}`} data-unique={`btnCoachNotResolvedToAnyIntentUtterance_${encodeURIComponent(entry.utterance)}`}>
              {entry.utterance}
            </NavLink>,
            () => (
              <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(entry.intent.actual)}`} data-unique={`btnCoachNotResolvedToAnyIntentActual_${encodeURIComponent(entry.intent.actual)}`}>
                {entry.intent.actual}
              </NavLink>
            ),
            () => <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(entry.intent.expected)}`} data-unique={`btnCoachNotResolvedToAnyIntentExpected_${encodeURIComponent(entry.intent.actual)}`}>
              {entry.intent.expected}
            </NavLink>,

            toFixedSafe(entry.intent.confidenceReal)
          ]
        })}
      />
    )
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{this.renderTable()}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(UTTERANCES_INCOMPREHENSION_QUERY, {
    props: ({data}) => ({
      utterancesData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId
        },
      }
    }
  })
)(NotResolvedToAnyIntent)
