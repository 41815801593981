import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HttpLink, InMemoryCache, ApolloClient } from 'apollo-boost'
import { WebSocketLink } from 'apollo-link-ws'
import { onError } from 'apollo-link-error'
import { ApolloLink, split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { ApolloProvider } from 'react-apollo'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import RootContainer from './components/RootContainer'
import store from './store/store'
import { getUser } from 'actions/token'
import { clearLogin } from 'actions/token'
import { setAlertSuccessMessage } from 'actions/alert'
import config from './config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { CustomThemeProvider } from 'themeUtils'

import 'tachyons'
import 'font-awesome/css/font-awesome.css'
import 'assets/css/chartist.css'
import 'flag-icon-css/css/flag-icon.css'
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/yaml/yaml'
import 'assets/css/material-dashboard-react.css?v=1.4.1'

library.add(fas)
library.add(far)

const httpLink = new HttpLink({
  uri: config.graphql.uri,
  credentials: 'include'
})

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
    }
  }) 
  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  let doLogout = false
  if (graphQLErrors) {
    for (const { code, message, locations, path } of graphQLErrors) {
      if (code === 'logged_out') doLogout = true
      if (code !== 'APPLICATION_ERROR') {
        console.error(`[GraphQL error]: Code: ${code}, Message: ${message}, Location: ${locations}, Path: ${path}`)
        Sentry.captureMessage(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      }
    }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    const shortenedBodyText = networkError.bodyText && _.truncate(networkError.bodyText, { length: 300 })
    if (shortenedBodyText) console.error(`[Network error Body Text]: ${shortenedBodyText}`)

    Sentry.captureMessage(`[Network error]: ${networkError} ${shortenedBodyText ? ` \r\nBODY TEXT: ${shortenedBodyText}` : ''}`)
  }

  const { response } = operation.getContext()
  if (doLogout || (response && response.status === 401)) {
    console.warn('[Logging out ...]')
    client.clearStore()
    .catch(err => console.warn('[Failed clearStore] ', err.message))
    .then(() => {
      store.dispatch(clearLogin())
      store.dispatch(setAlertSuccessMessage('Logged out.'))
      window.location.pathname = '/login'
    })
  }
})

// authenticated httplink
const httpLinkAuth = middlewareLink.concat(errorLink).concat(httpLink)

const wsLink = new WebSocketLink({
  uri: config.graphql.ws,
  options: {
    reconnect: true,
    connectionParams: () => {
      return {}
    },
  },
})

let prevUser = store.dispatch(getUser())
store.subscribe(() => {
  const newState = store.getState()
  const newUser = newState.token && newState.token.user
  if (!newUser || (prevUser && prevUser.id !== newUser.id)) {
    try {
      wsLink.subscriptionClient.close(false, false)
    } catch (err) {
      console.error(`[Subscription error]: ${err}`)
    }
    prevUser = newUser
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLinkAuth,
)

// apollo client setup
const client = new ApolloClient({
  link: ApolloLink.from([link]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  addTypename: true
})

if (window.Cypress) {
  client.defaultOptions = {
    mutate: {
      awaitRefetchQueries: true
    }
  }
}

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <CustomThemeProvider theme="lightTheme">
        <RootContainer />
      </CustomThemeProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root'),
)

if (window.Cypress) {
  window.store = store
  window.apolloClient = client
}