import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import { renderCheckbox, FormActionsToolbar, renderSelect, required} from 'components/Form/Form'

import { RefetchTestSetQueries, DeleteTestSetScriptsFromCache } from 'views/TestSets/gql'
import TestSetSelector from 'views/TestSets/TestSetSelector'
import { CHATBOT_QUERY } from 'views/Chatbots/gql'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import { AZURE_CLU_LANGUAGES } from './Helper'

import Divider from 'components/Divider/Divider'
import Text from 'components/Typography/Text.jsx'

const IMPORT_INTENTS = gql`
  mutation ImportFromAzureCLU(
    $chatbotId: ID!
    $testSetId: ID
    $newTestSetName: String,
    $createTestProject: Boolean,
    $importMode: ImportOverwriteMode,
    $buildconvos: Boolean,
    $dataset: String, 
    $language: String
  ) {
    importFromAzureCLU(
      chatbotId: $chatbotId
      testSetId: $testSetId
      newTestSetName: $newTestSetName,
      createTestProject: $createTestProject,
      importMode: $importMode,
      buildconvos: $buildconvos,
      dataset: $dataset 
      language: $language
    ) { id scripts { id } }
  }
`

class AzureCLUImport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      importing: false
    }
    this.empty = {
      buildintents: true,
      buildconvos: false,
      testSetId: this.props.testSetId || 'new',
      importMode: 'EXTEND',
      dataset: 'Train',
      language: null
    }
  }

  render() {
    const { importing } = this.state
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { chatbotId } = this.props

    return (<Query query={CHATBOT_QUERY} variables={{ id: chatbotId }}>
      {({ error, data }) => {
        if (error) return <ErrorFormat err={error} />

        if (!data.chatbot) return null

        return (
          <Mutation
            mutation={IMPORT_INTENTS}
            onCompleted={data => {
              this.setState({ importing: false })
              setAlertSuccessMessage('Test Cases imported')
              history.push(`/testsets/view/${data.importFromAzureCLU.id}`)
            }}
            onError={error => {
              this.setState({ importing: false })
              setAlertErrorMessage(`Test Case import failed`, error)
            }}
            refetchQueries={({ data }) => [
              ...RefetchTestSetQueries(data.importFromAzureCLU.id, license)
            ]}
            update={(store, { data }) => DeleteTestSetScriptsFromCache(store, data.importFromAzureCLU.scripts.map(s => s.id))}
            awaitRefetchQueries={true}
          >
            {(importFromAzureCLU, { loading, error }) => (
              <Form
                onSubmit={values => {
                  this.setState({ importing: true })
                  importFromAzureCLU({
                    variables: {
                      chatbotId: chatbotId,
                      testSetId: values.newTestSetName ? null : values.testSetId,
                      newTestSetName: values.newTestSetName,
                      createTestProject: !!values.createTestProject,
                      importMode: values.importMode,
                      buildconvos: values.buildconvos,
                      dataset: values.dataset || 'Train',
                      language: values.language
                    }
                  })
                }}
                initialValues={this.empty}
                render={({ handleSubmit, submitting, invalid }) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <TestSetSelector defaultNewTestSetName={data.chatbot.name + ' - Test Set'} />
                      <GridItem xs>
                          <Divider orientation="horizontal"  dividerlgnone />
                          <Text header topMargin>More Options</Text>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name="buildintents"
                          component={renderCheckbox}
                          label="Get user examples from Azure CLU intents as utterance lists"
                          type="checkbox"
                          disabled={true}
                          data-unique="chkAzureCLUImportBuildIntents"
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name="buildconvos"
                          component={renderCheckbox}
                          label="Build test case convos for NLU intent assertions"
                          type="checkbox"
                          data-unique="chkAzureCLUImportBuildConvos"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="language"
                          component={renderSelect}
                          label="Choose the Azure CLU language?"
                          data-unique="selAzureCLULanguage"
                          helperText="You can leave this empty to download all languages"
                          filterable={true}
                          items={AZURE_CLU_LANGUAGES}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="dataset"
                          component={renderSelect}
                          label="Choose the Azure CLU dataset?"
                          data-unique="selAzureCLUDataset"
                          filterable={false}
                          validate={required}
                          items={[
                            { key: 'Train', label: 'Training data' },
                            { key: 'Test', label: 'Test data' }
                          ]}
                        />
                      </GridItem>
                      <GridItem xs={12} largePadding>
                        <FormActionsToolbar rightButtons={
                          <Button
                            type="submit"
                            disabled={importing || submitting || invalid}
                            data-unique="btnAzureCLUImportDownload"
                          >
                            {importing && <><LoadingIndicator /> Download is running</>}
                            {!importing && <><CloudDownloadIcon /> Download</>}
                          </Button>
                        }/>
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
              />
            )}
          </Mutation>
        )
      }}
    </Query>)
  }
}

AzureCLUImport.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  testSetId: PropTypes.string
}

export default withRouter(connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(AzureCLUImport))
