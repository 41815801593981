import React from 'react'
import PropTypes from 'prop-types'
import unescapeJs from 'unescape-js'
// @material-ui/core components
import AddIcon from '@material-ui/icons/Add'
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { FieldArray } from 'react-final-form-arrays'
import { OnChange } from 'react-final-form-listeners'
import Divider from 'components/Divider/Divider.js'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  renderTextField,
  renderTextArea,
  renderIntField,
  renderSelect,
  renderCheckbox,
  renderCodeArea,
  required,
  json,
  mustBeNumber,
  composeValidators,
  Condition,
  parseInteger,
  prettyPrintJson
} from 'components/Form/Form'
import FileSelectorField from 'components/Form/FileSelectorField'

import ShowIcon from 'components/Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'

class CapabilitiesEdit extends React.Component {
  render() {
    const { field, disabled } = this.props
    let sectionName = 'Capability'
    if (field === 'sources') sectionName = 'Source Setting'
    else if (field === 'envs') sectionName = 'Environment Variable'

    return (
      <FormSpy
        subscription={{ form: true }}
        render={({ form:  { change }}) => (
          <GridContainer>
            <FieldArray name={field}>
              {({ fields }) =>
                fields.map((name, index) => (
                  <GridItem xs={12} key={index}>
                    <OnChange name={`${name}.type`}>
                      {(value, previous) => {
                        if (value !== 'STRING' && value !== 'TEXT' && value !== 'JS') change(`${name}.stringValue`, null)
                        if (value !== 'INT') change(`${name}.intValue`, null)
                        if (value !== 'BOOLEAN') change(`${name}.booleanValue`, null)
                        if (value !== 'JSON') change(`${name}.jsonValue`, null)
                      }}
                    </OnChange>
                    <GridContainer>
                      <GridItem sm={7}>
                        <Field
                          name={`${name}.name`}
                          component={renderTextField}
                          label={`${sectionName} #${index + 1}`}
                          validate={required}
                          disabled={disabled}
                          data-unique={`txtCapabilitiesEditCapabilityName_${index}`}
                        />
                      </GridItem>
                      <GridItem sm={4}>
                        <Field
                          name={`${name}.type`}
                          component={renderSelect}
                          label="Type"
                          disabled={disabled}
                          validate={required}
                          data-unique={`selCapabilitiesEditCapabilityType_${index}`}
                          items={[
                            { key: 'STRING', label: 'Text' },
                            { key: 'TEXT', label: 'Multiline Text' },
                            { key: 'INT', label: 'Number' },
                            { key: 'BOOLEAN', label: 'Checkbox' },
                            { key: 'JSON', label: 'JSON Structure' },
                            { key: 'JS', label: 'Javascript File' },
                          ]}
                        />
                      </GridItem>
                      {!disabled &&
                        <GridItem sm={1} bottom largePadding>
                          <Tooltip title="Delete">
                            <Button
                              aria-label="Delete"
                              onClick={() => fields.remove(index)}
                              justIcon
                              data-unique="btnCapabilitiesEditRemove"
                            >
                              <ShowIcon icon="trash" />
                            </Button>
                          </Tooltip>
                        </GridItem>
                      }
                      <GridItem sm={11}>
                        <Condition when={`${name}.type`} is="STRING">
                          <Field
                            name={`${name}.stringValue`}
                            component={renderTextField}
                            label={`${sectionName} Value`}
                            disabled={disabled}
                            data-unique={`txtCapabilitiesEditCapabilityStringValue_${index}`}
                          />
                        </Condition>
                        <Condition when={`${name}.type`} is="TEXT">
                          <Field
                            name={`${name}.stringValue`}
                            component={renderTextArea}
                            rows={5}
                            label={`${sectionName} Value`}
                            parse={value => value && unescapeJs(value)}
                            disabled={disabled}
                            data-unique={`txtCapabilitiesEditCapabilityStringValue_${index}`}
                          />
                        </Condition>
                        <Condition when={`${name}.type`} is="INT">
                          <Field
                            name={`${name}.intValue`}
                            component={renderIntField}
                            label={`${sectionName} Value`}
                            parse={parseInteger}
                            validate={composeValidators(mustBeNumber)}
                            disabled={disabled}
                            data-unique={`intCapabilitiesEditCapabilityIntValue_${index}`}
                          />
                        </Condition>
                        <Condition when={`${name}.type`} is="BOOLEAN">
                          <Field
                            name={`${name}.booleanValue`}
                            component={renderCheckbox}
                            label={`${sectionName} Value`}
                            type="checkbox"
                            disabled={disabled}
                            data-unique={`chkCapabilitiesEditCapabilityBoolean_${index}`}
                          />
                        </Condition>
                        <Condition when={`${name}.type`} is="JSON">
                          <Field
                            className="Capabilities"
                            name={`${name}.jsonValue`}
                            component={renderCodeArea}
                            options={{ mode: 'application/json' }}
                            label={`${sectionName} Value`}
                            codeFormat={prettyPrintJson}
                            validate={json}
                            disabled={disabled}
                            data-unique={`codeCapabilitiesEditJsonValue_${index}`}
                          />
                        </Condition>
                        <Condition when={`${name}.type`} is="JS">
                          <FileSelectorField
                            name={`${name}.stringValue`}
                            change={change}
                            label={`${sectionName} Value`}
                            disabled={disabled}
                            data-unique={`codeCapabilitiesEditStringValue_${index}`}
                            initialPath="resources/scripts"
                            extensionFilter={['.js']}
                            restrictPath
                          />
                        </Condition>
                      </GridItem>
                    </GridContainer>
                    <Divider orientation="horizontal" />
                  </GridItem>
                ))
              }
            </FieldArray>
            {!disabled &&
              <GridItem xs={12}>
                <Button
                  onClick={() => this.props.push(field, { type: 'STRING' })}
                  data-unique="btnCapabilitiesEditRegister"
                  secondary
                  dashed
                  fullWidth
                  noMargin
                >
                  <AddIcon />
                  Register new {sectionName}
                </Button>
              </GridItem>
            }
          </GridContainer>
        )}
      />
    )
  }
}

CapabilitiesEdit.propTypes = {
  push: PropTypes.func.isRequired,
  pop: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default CapabilitiesEdit
