import { DESTROY_STORE, SET_DASHBOARD_SETTINGS, CLEAR_DASHBOARD_SETTINGS } from '../constants'

export function destroyStore() {
  return {
    type: DESTROY_STORE
  }
}

export function clearDashboardSettings(section) {
  return {
    type: CLEAR_DASHBOARD_SETTINGS,
    payload: {
      section,
    },
  }
}

export function setDashboardSettings(section, settings) {
  return {
    type: SET_DASHBOARD_SETTINGS,
    payload: {
      section,
      settings,
    },
  }
}
