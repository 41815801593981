import React from 'react'
import { FormSpy } from 'react-final-form'
import { IsDirtyContext } from 'components/RootContainer'
import { Prompt } from 'react-router-dom'
import Beforeunload from 'react-beforeunload'

class UnsavedFormSpy extends React.Component {
  render() {
    const message = 'Are you sure you want to leave without saving?'

    return (
      <IsDirtyContext.Consumer>
        {({ isDirty, setDirty }) => (
          <React.Fragment>
            <Prompt
              when={isDirty}
              message={message}
              onConfirm={() => setDirty(false)}
            />
            <Beforeunload onBeforeunload={(e) => {
              if (isDirty) {
                e.preventDefault()
                return message
              }
            }} />
            <FormSpy
              subscription={{ pristine: true }}
              onChange={props => {
                setDirty(!props.pristine)
              }}
            />
          </React.Fragment>
        )}
      </IsDirtyContext.Consumer>
    )
  }
}

export default UnsavedFormSpy
