import React from 'react'
import {NavLink} from 'react-router-dom'

import dashboardStyle from '../../../../assets/jss/material-dashboard-react/views/dashboardStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import crypto from 'crypto'
import ScatterChart from 'components/Stats/Charts/ScatterChart'
import ShowIcon from 'components/Icon/ShowIcon'
import CustomTabs from 'components/Tabs/CustomTabs'
import ErrorFormat from 'components/Info/ErrorFormat'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

export const UtteranceSimilarityVisualisation = withStyles(dashboardStyle)(class Keywords extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { listTestSetIdToEmbeddings, listTestSetIdToEmbeddingsError, utterance } = this.props

    const renderEmbeddings = ({testSetId, testSetName, embeddings, noCoachSession, utteranceFound, coachSession}) => {
      const warnings = []
      if (coachSession && coachSession.embeddingsErr) warnings.push('Invalid Insights for Test Set')
      if (noCoachSession) warnings.push('Test Set Insights not available')
      if (!noCoachSession && !utteranceFound) warnings.push('Utterance not found in Test Set')

      if (warnings.length > 0) {
        return <Text><NavLink to={`/testsets/view/${testSetId}/insights`} data-unique={`btnUtterancesKeywordsToTestSet_${testSetName}`}>
          {warnings.join(' | ')}
        </NavLink></Text>
      }

      embeddings = embeddings.map(e => {
        e.hasUtterance = !!e.examples.find(ex => ex.phrase.toLowerCase() === utterance.toLowerCase())
        return e
      })

      const strToColor = (str) => {
        return '#' + crypto.createHash('md5').update(str).digest('hex').substr(0, 6)
      }

      return (<ScatterChart
        data={embeddings.map(e => {
          return {
            name: e.name,
            // does not matter
            color: strToColor(e.name),
            shape: e.hasUtterance ? <ShowIcon icon="circle" color={strToColor(e.name)} /> : <ShowIcon icon="times" color={strToColor(e.name)} />,
            values: e.examples.map(ex => ({
              x: ex.x,
              y: ex.y,
              // there is 2 sizes, 100 and 110, so they will be converted to the min and the max score of the z axis
              // so if i have just 2 numbers the numbers itself does not matter, they are just markers for min and max.
              size: !e.hasUtterance ? 100 : (ex.phrase === utterance ? 110 : 100),
              phrase: ex.phrase,
              intent: e.name
            }))
          }
        })}
        tooltipFormatter={payload => ([
          {
            name: 'Intent',
            value: payload[0].payload.intent
          },
          {
            name: 'Example',
            value: payload[0].payload.phrase
          }
        ])}
      />)
    }

    if (listTestSetIdToEmbeddingsError) {
      return <ErrorFormat err={listTestSetIdToEmbeddingsError} />
    } else if (!listTestSetIdToEmbeddings) {
      return <LoadingIndicator />
    } else {
      const listTestSetIdToEmbeddingsToDisplay = (listTestSetIdToEmbeddings.find(e => e.utteranceFound)) ? listTestSetIdToEmbeddings.filter(e => e.utteranceFound || e.noCoachSession) : listTestSetIdToEmbeddings
      if (listTestSetIdToEmbeddingsToDisplay.length === 1) {
        return renderEmbeddings(listTestSetIdToEmbeddingsToDisplay[0])
      } else {
        return (<CustomTabs
          skipPersist
          tabs={listTestSetIdToEmbeddingsToDisplay.map(struct => {
            return {
              tabName: 'Test Set ' + struct.testSetName,
              tabContent: renderEmbeddings(struct)
            }
          })}
        />)
      }
    }
  }
})
