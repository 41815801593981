import { SET_NAMESPACE, CLEAR_NAMESPACE } from '../constants'

export default function namespace(state = {}, action) {
   switch (action.type) {
    case SET_NAMESPACE:
      return {
        ...state,
        selected: action.payload.selected,
      }
     case CLEAR_NAMESPACE:
      return {}
    default:
      return state
  }
}
