import React from 'react'
import { connect } from 'react-redux'
import { Query, withApollo, compose } from 'react-apollo'
import { NavLink } from 'react-router-dom'
import { Form } from 'react-final-form'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import Field from 'components/Form/OptionalField'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import { getConnector } from 'actions/settings'
import ErrorFormat from 'components/Info/ErrorFormat'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import FeatureUpgradeNavLink from 'components/FeatureUpgrade/FeatureUpgradeNavLink'
import { renderSelect, renderTextField, required, FormActionsToolbar } from 'components/Form/Form'
import DateFormat from 'components/Info/DateFormat'

import {
  TESTSETFIRSTCOACHSESSION_QUERY,
  TESTSETCOACHSESSIONSECTION_QUERY, TESTSETS_DROPDOWN_QUERY
} from '../../TestSets/gql'
import { renderProgressOrError, safeGetNamespaceFilteredList } from '../../helper'
import { UtteranceKeywords } from './components/UtteranceKeywords'
import { UtterancePrediction } from './components/UtterancePrediction'
import { CHATBOTHELLO_QUERY, CHATBOTS_DROPDOWN_QUERY } from '../../Chatbots/gql'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class UtteranceInsights extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testSetId: null,
      chatbotId: null,
      utterance: null
    }
  }

  renderPrediction(nlp) {
    const { match } = this.props

    const utteranceStruct = nlp ? {
      intent: {
        actual: nlp.intent.name,
        confidenceReal: nlp.intent.confidence,
        incomprehension: nlp.intent.incomprehension
      },
      entity: {
        actual: nlp.entities || []
      }
    } : null
    return <Card>
      <CardHeader color="info">
        <Text header>Prediction Statistics</Text>
      </CardHeader>
      <CardBody>
        <UtterancePrediction
          utteranceStruct={utteranceStruct}
          testSessionId={match.params.testSessionId}
          match={match}
        />
      </CardBody>
    </Card>
  }

  renderKeywords(nlp) {

    if (!this.state.testSetId) {
      return null
    }
    return <Query query={TESTSETFIRSTCOACHSESSION_QUERY}
      variables={{
        testSetId: this.state.testSetId
      }}>
      {(coachSessionStruct) => {
        const coachSession = coachSessionStruct.data && coachSessionStruct.data.testsetcoachsessions
          && coachSessionStruct.data.testsetcoachsessions.length > 0 && coachSessionStruct.data.testsetcoachsessions[0]

        return <Query query={TESTSETCOACHSESSIONSECTION_QUERY}
          variables={{
            id: coachSession && coachSession.id,
            section: 'chi2'
          }}
          fetchPolicy={'network-only'}
          skip={!coachSession}
        >
          {(sectionStruct) => {
            let progressOrError =
              // some error happened earlier and stored in db
              (coachSession && coachSession.embeddingsErr && (<NavLink to={`/testsets/view/${this.state.testSetId}/insights`}
                data-unique={`btnUttarancesKeywordsToTestSetError_${this.state.testSetId}`}>
                <Text warning data-unique={`txtCoachUtteranceInsightsNoTestSetErrorInsights`}>Invalid Insights for Test Set</Text>
              </NavLink>)) ||
              renderProgressOrError(coachSessionStruct) ||
              renderProgressOrError(sectionStruct) ||
              (!coachSession ? (<NavLink to={`/testsets/view/${this.state.testSetId}/insights`}
                data-unique={`btnUttarancesKeywordsToTestSetNoInsights_${this.state.testSetId}`}>
                <Text warning data-unique={`txtCoachUtteranceInsightsNoTestSetNoInsightsInsights`}>Insights not found for Test Set</Text>
              </NavLink>)
                : null) ||
              (!nlp.intent.name ? <Text warning data-unique={`txtCoachUtteranceInsightsNoPredictedIntent`}>Keyword analytics requires predicted intent</Text> : null)

            return <Card data-unique={`crdCoachUtteranceInsightsKeywords`}>
              <CardHeader color="info">
                <Text header>Keywords of the Intent '{nlp.intent.name ? nlp.intent.name : 'N/A'}' (Using Test Set Insights from: <DateFormat>{coachSession && coachSession.createdAt ? coachSession.createdAt : null}</DateFormat>)</Text>
              </CardHeader>
              <CardBody>
                <Text header>
                  {progressOrError}
                  {!progressOrError && <UtteranceKeywords
                    // testset name is not required here, we have only one, and UtteranceKeywords does not displays it in this case
                    listTestSetIdToEmbeddings={[{
                      testSetId: this.state.testSetId,
                      testSetName: null,
                      ...JSON.parse(sectionStruct.data.testsetcoachsessionsection)
                    }]}
                    intent={nlp.intent.name}
                    utterance={this.state.utterance}
                    forceSingleTestSet={true}
                  />}
                </Text>
              </CardBody>
            </Card>
          }}
        </Query>
      }}
    </Query>
  }

  renderBody() {
    const { chatbotId, utterance } = this.state

    if (!chatbotId || !utterance) {
      return null
    }

    return <Query query={CHATBOTHELLO_QUERY}
      variables={{
        id: chatbotId,
        text: utterance,
        capabilities: []
      }}
      fetchPolicy={'network-only'}>
      {({ loading, error, data }) => {
        const _renderMessage = (message, color = 'info') =>
          <Card>
            <CardHeader>
              <Text header color={color}>Utterance Analytics</Text>
            </CardHeader>
            <CardBody>
              {message}
            </CardBody>
          </Card>

        if (loading) {
          return _renderMessage(<LoadingIndicator large />)
        }
        if (error) {
          return _renderMessage(<ErrorFormat err={error} />, 'danger')
        }

        if (data && data.chatbothello) {
          if (data.chatbothello.err) {
            return _renderMessage(<ErrorFormat err={data.chatbothello.err} />, 'danger')
          }

          if (data.chatbothello.convo) {
            // chatbot response struct to database struct
            const convo = JSON.parse(data.chatbothello && data.chatbothello.convo)
            if (convo && convo.length && convo[0].err) {
              return _renderMessage(<ErrorFormat err={convo[0].err} />, 'danger')
            }
            const nlp = convo && convo.length > 1 && convo[1].actual && convo[1].actual.nlp
            if (!nlp) {
              return _renderMessage(<Text warning>No NLP Information in chatbot response</Text>, 'warning')
            }
            return <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                {this.renderPrediction(nlp)}
              </GridItem>
              {nlp && <GridItem xs={6} sm={6} md={6}>
                {this.renderKeywords(nlp)}
              </GridItem>}
            </GridContainer>
          }
        }
        return null
      }}
    </Query>
  }

  renderForm() {
    const { match, getConnector } = this.props
    return (<Form
      onSubmit={({ testSetId, chatbotId, utterance }) => {
        this.setState({ testSetId, chatbotId, utterance })
      }}
      initialValues={{
        testProjectId: this.state.testProjectId || match.params.testProjectId || '',
        testSetId: this.state.testSetId || match.params.testSetId || '',
        chatbotId: this.state.chatbotId || match.params.chatbotId || '',
        utterance: this.state.utterance || match.params.utterance || ''
      }}

      render={({ handleSubmit, submitting, invalid }) => (
        <Card>
          <CardBody>
            <Text header>
              <GridContainer>
                <GridItem xs={4}>
                  <Field
                    name="utterance"
                    component={renderTextField}
                    label="Utterance"
                    validate={required}
                    data-unique="txtUtteranceInsightsDialogUtterance"
                  />
                </GridItem>
                <GridItem xs={4}>
                  <Query query={CHATBOTS_DROPDOWN_QUERY}
                    variables={{}}
                    fetchPolicy={'network-only'}>
                    {({ loading, error, data }) => {
                      if (loading) {
                        return <LoadingIndicator large />
                      }
                      if (error) {
                        return <ErrorFormat err={error} />
                      }

                      const items = data && data.chatbots && safeGetNamespaceFilteredList(data.chatbots, this.props.namespace).filter(c => {
                        const connector = getConnector(c.containermode)
                        return connector && connector.features && connector.features.intentResolution
                      }).map(c =>
                      ({
                        key: c.id,
                        chatbot: c
                      })
                      )

                      return (
                        <Field
                          name="chatbotId"
                          component={renderSelect}
                          label="Select Chatbot to run NLP Analytics"
                          validate={required}
                          data-unique="selUtteranceInsightsDialogChatbotId"
                          items={items || []}
                        />
                      )
                    }}
                  </Query>
                </GridItem>
                <GridItem xs={4}>
                  <Query query={TESTSETS_DROPDOWN_QUERY}
                    variables={{}}
                    fetchPolicy={'network-only'}>
                    {({ loading, error, data }) => {
                      if (loading) {
                        return <LoadingIndicator large />
                      }
                      if (error) {
                        return <ErrorFormat err={error} />
                      }

                      const items = data && data.testsets && safeGetNamespaceFilteredList(data.testsets, this.props.namespace).map(ts => ({
                        key: ts.id,
                        label: ts.name
                      }))

                      return (
                        <Field
                          name="testSetId"
                          component={renderSelect}
                          label="Select Test Set for Keyword Analytics"
                          data-unique="selUtteranceInsightsDialogTestSetId"
                          items={items || []}
                        />
                      )
                    }}
                  </Query>
                </GridItem>
                <GridItem xs={12}>
                  <FormActionsToolbar rightButtons={
                    <Button
                      type="submit"
                      disabled={submitting || invalid}
                      data-unique="btnUtteranceInsightsSubmit"
                      onClick={() => handleSubmit()}
                    >
                      {!submitting && <ShowIcon icon="save" />}
                      {submitting && <LoadingIndicator alt/>}
                      Get Utterance Insights
                    </Button>
                  }/>
                </GridItem>
              </GridContainer>
            </Text>
          </CardBody>
        </Card>
      )}
    />)
  }

  render() {
    const { license } = this.props

    if (!license.coach) {
      return <FeatureUpgradeNavLink>Utterance Analytics is not available in this Edition</FeatureUpgradeNavLink>
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderForm()}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderBody()}
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    state => ({
      license: state.settings.license, namespace: state.namespace
    }),
    { getConnector },
  ),
  withApollo,
)(UtteranceInsights)
