import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import ListItemText from '@material-ui/core/ListItemText'

// core components
import listStyle from 'assets/jss/material-dashboard-react/components/listStyle.jsx'

function CustomListItemText({ ...props }) {
  const { children, classes, ...rest } = props
  return (
    <ListItemText classes={{
      primary: classes.listItemTextSmallPrimary,
      secondary: classes.listItemTextSmallSecondary,
      dense: classes.listItemTextSmallDense
    }} {...rest}>
      {children}
    </ListItemText>
  )
}

export default withStyles(listStyle)(CustomListItemText)
