import React from 'react'
// @material-ui/core components
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import {
  required,
  url,
  composeValidators,
  renderTextField
} from 'components/Form/Form'
import Text from 'components/Typography/Text'
import { capSetDescription, usedByCapabilitySet } from './Helper'

export function wechatCaps2Form(caps) {
  return {
    wechat: {
      webhookUrl: (
        caps.find(c => c.name === 'WECHAT_WEBHOOK_URL') || { stringValue: '' }
      ).stringValue,
      token: (
        caps.find(c => c.name === 'WECHAT_TOKEN') || { stringValue: '' }
      ).stringValue,
    },
  }
}

const capNamesMap = {
  'wechat.webhookUrl': 'WECHAT_WEBHOOK_URL',
  'wechat.token': 'WECHAT_TOKEN'
}

export function wechatForm2caps(values) {
  const capabilities = [
    {
      name: 'WECHAT_WEBHOOK_URL',
      type: 'STRING',
      stringValue: values.wechat.webhookUrl || '',
    },
    {
      name: 'WECHAT_TOKEN',
      type: 'STRING',
      stringValue: values.wechat.token || '',
    },
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'wechat' }
  ]

  return capabilities
}

export class WechatEdit extends React.Component {
  render() {
    const { disabled, capSetCapNames } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Text>
            See the <a href="https://github.com/codeforequity-at/botium-connector-wechat" target="_blank" rel="noopener noreferrer">Github Repository</a> of the connector for detailed instructions.
          </Text>
        </GridItem>
        <GridItem xs={12}>
          <Field
            name="wechat.webhookUrl"
            component={renderTextField}
            label="Wechat Webhook Url"
            validate={composeValidators(required, url)}
            data-unique="txtWeChatWebhookUrl"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['wechat.webhookUrl']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['wechat.webhookUrl'])}
          />
        </GridItem>
        <GridItem xs={12}>
          <Field
            name="wechat.token"
            component={renderTextField}
            label="Wechat Webhook Token"
            validate={required}
            data-unique="txtWeChatWebhookToken"
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['wechat.token']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['wechat.token'])}
          />
        </GridItem>
      </GridContainer>
    )
  }
}
