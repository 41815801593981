import { cardBorderColor } from 'assets/jss/material-dashboard-react'

const cardStyle = theme => ({
  card: {
    marginBottom: '20px',
    marginTop: '20px',
    borderRadius: 6,
    border: `1px solid ${theme.colors.tableRow} !important`,
    color: theme.palette.danger,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0',
    wordWrap: 'break-word',
    fontSize: 12,
    background: theme.colors.backgroundPrimary,
  },
  hoverlight: {
    '&:hover': {
      background: theme.colors.cardlinkHoverLight
    }
  },
  hoverdark: {
    backgroundColor: 'transparent',
    '&:hover': {
      background: theme.colors.cardlinkHoverDark
    }
  },
  cursor: {
    cursor: 'pointer'
  },
  cardDense: {
    marginTop: '5px'
  },
  cardNoMargin: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  cardNoPadding: {
    paddingTop: 0,
    paddingBottom: '0!Important'
  },
  cardSmallMargin: {
    marginBottom: '10px',
    marginTop: '10px',
    marginLeft: '10px',
    marginRight: '10px'
  },
  cardSmallPadding: {
    padding: '5px!Important',
    border: '1px solid red'
  },
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none',
  },
  cardProfile: {
    marginTop: '30px',
    textAlign: 'center',
  },
  cardChart: {
    '& p': {
      marginTop: '0px',
      paddingTop: '0px',
    },
  },
  selectableCard: {
    cursor: 'pointer',
    backgroundColor: theme.colors.backgroundPrimary,
    marginTop: 0,
    marginBottom: 0,
    border: `1px solid ${cardBorderColor}`,
    height: '100%',
    '&:hover': {
      border: `1px solid ${theme.colors.common.secondary} !important`,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor} !important`
    },
  },
  selectableCardDisabled: {
    backgroundColor: theme.colors.backgroundPrimary,
    marginTop: 0,
    marginBottom: 0,
    height: '100%',
    border: `1px solid ${cardBorderColor}`,
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor} !important`
    },
  },
  selectableCardAutoHeight: {
    height: '100%'
  },
  selectableCardContent: {
    paddingLeft: 30,
    //color: theme.colors.primary
    color: theme.colors.expansionbordercontenttext,
  },
  selectableCheck: {
    left: 9,
    top: 9,
    width: 24,
    borderRadius: 24,
    position: 'absolute',
    textAlign: 'center',
    backgroundColor: 'transparent',
    //backgroundColor: theme.colors.cardfooterbg,
    color: 'transparent'
 
  },
  selectableCheckSelected: {
    backgroundColor: theme.colors.common.secondary,
    color: theme.colors.common.white,
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor} !important`
    },
  },
  selectableCardSelected: {
    border: `1px solid ${theme.colors.common.secondary} !important`,
  },
  selectableCardFooter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 21,
    width: '100%',
    padding: 2,
    backgroundColor: theme.colors.cardfooterbg,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    textAlign: 'right',
    '& svg': {
      '& path': {
      //fill: theme.colors.expansionbordercontenttext,
      fill: theme.colors.expansionbordercontenttext,
      }
    }
  },
  selectableCardTopBar: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 5,
  },
  selectbotcontainericonbg: {
    backgroundColor: theme.colors.cardfooterbg,
    display: 'inline-block',
    textAlign: 'center',
    maxWidth: 40,
    padding: 3,
    borderRadius: 6,
    maxHeight: 40,
    '& img' :{
      width: '36px !Important',
      maxWidth: '36px !Important',
      height: '36px !Important',
      paddingTop: '0 !Important'
    }
  },
  cardSecondary: {
    backgroundColor: theme.colors.cardSecondaryBG,
  },
  cardNoBorder: {
    border: 'none !Important'
  },
  cardBorderBottom: {
    border: 'none!Important',
    paddingBottom: 25,
    borderBottom: `1px solid ${theme.colors.tableRow} !important`,
    borderRadius: '0 !Important',
  },
  cardFullHeight: {
    height: '100%',
  },
  topbottomMargin: {
    marginTop: 15,
    marginBottom: 15
  },
  noMarginTop: {
    marginTop: 0
  },
  smallMarginTop: {
    marginTop: 10
  },
  noMarginBottom: {
    marginBottom: 0
  },
  cardWidthAuto: {
    width: 'auto'
  },
  borderRadiusRight: {
    borderRadius: '6px 0 0 6px'
  },
  borderRadiusLeft: {
    borderRadius: '0 6px 6px 0'
  },
  noBorderRadius: {
    borderRadius: '0'
  },  
  cardFloat: {
    display: 'inline-block',
    width: 'auto',
    marginRight: 15
  },
  borderSecondary: {
    border: `1px solid ${theme.colors.borderColor} !important`,
  }
})

export default cardStyle
