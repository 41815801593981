module.exports = {
  MATCHING_MODE_WILDCARD: 'wildcard',
  MATCHING_MODE_WILDCARDIGNORECASE: 'wildcardIgnoreCase',
  MATCHING_MODE_WILDCARDEXACT: 'wildcardExact',
  MATCHING_MODE_WILDCARDEXACTIGNORECASE: 'wildcardExactIgnoreCase',
  MATCHING_MODE_REGEXP: 'regexp',
  MATCHING_MODE_REGEXPIGNORECASE: 'regexpIgnoreCase',
  MATCHING_MODE_INCLUDE: 'include',
  MATCHING_MODE_INCLUDEIGNORECASE: 'includeIgnoreCase',
  MATCHING_MODE_INCLUDELOWERCASE: 'includeIgnoreCase',
  MATCHING_MODE_EQUALS: 'equals',
  MATCHING_MODE_EQUALSIGNORECASE: 'equalsIgnoreCase',
  MATCHING_MODE_WER: 'wer'
}
