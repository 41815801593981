import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

import tileStyle from 'assets/jss/material-dashboard-react/components/tileStyle'
import imgNLPDownload from 'assets/img/wizards/download_data_from_nlp_engine_provider.png'
import imgExcel from 'assets/img/wizards/import_from_excel_csv.png'
import imgAITestGenerator from 'assets/img/wizards/ai-assisted_test_generator.png'
import imgAITestGeneratorNlu from 'assets/img/wizards/ai-assisted_test_generator_nlu.png'
import imgCrawlerWizard from 'assets/img/wizards/crawler_wizard.png'
import imgHumanification from 'assets/img/wizards/humanification_transformer.png'
import imgLiveChat from 'assets/img/wizards/live_chat_recorder.png'
import imgTestSetSplitter from 'assets/img/wizards/test_set_splitter.png'
import imgTranslator from 'assets/img/wizards/test_set_translator.png'
import imgParaphraser from 'assets/img/wizards/the_paraphraser_.png'
import imgVoicWizard from 'assets/img/wizards/the_voice_wizard.png'

import { hasPermission, hasAnyPermission } from 'botium-box-shared/security/permissions'

class TestDataWizards extends React.Component {

  hasTestSetPermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }
  hasCreatePermission() {
    const { user } = this.props
    return hasPermission(user, 'TESTSETS_CREATE')
  }

  render() {
    const { history, license, features, classes, user } = this.props

    const tiles = [
      {
        unique: 'cardNLPDownload',
        onClick: () => history.push('/testdatawizards/nlpdownload'),
        active: this.hasTestSetPermission() && license.testsetImport,
        img: imgNLPDownload,
        header: 'Conversation Model Downloader',
        text: 'Downloads the language model to generate tests and calculate training data insights'
      },
      {
        unique: 'cardExcelImport',
        onClick: () => history.push('/testdatawizards/fileupload'),
        active: this.hasCreatePermission() && license.excel,
        img: imgExcel,
        header: 'Import from File',
        text: 'Import your existing tests from other sources (ZIP-File, Excel, CSV, ...)'
      },
      {
        unique: 'cardAITestGenerator',
        onClick: () => history.push('/testdatawizards/aisimple'),
        active: this.hasTestSetPermission() && license.testDataWizard,
        img: imgAITestGenerator,
        header: 'AI-powered Data Generator',
        text: 'Create real life test scenarios based on state of the art natural language generation technologies'
      },
      {
        unique: 'cardAITestLabeler',
        onClick: () => history.push('/testdatawizards/ainlu'),
        active: this.hasTestSetPermission() && license.testDataWizard,
        img: imgAITestGeneratorNlu,
        header: 'AI-powered Test Generator',
        text: 'Create and label real life test scenarios based on state of the art natural language generation technologies'
      },
      {
        unique: 'cardParaphraser',
        onClick: () => history.push('/testdatawizards/paraphraser'),
        active: this.hasTestSetPermission() && license.paraphrasing,
        img: imgParaphraser,
        header: 'Paraphraser',
        text: 'Generate new user examples to extend your test data and increase the test coverage'
      },
      {
        unique: 'cardTranslater',
        onClick: () => history.push('/testdatawizards/translator'),
        active: this.hasCreatePermission() && license.translation,
        img: imgTranslator,
        header: 'Test Set Translator',
        text: 'Translate your test sets into any desired language'
      },
      {
        unique: 'cardHumanification',
        onClick: () => history.push('/testdatawizards/humanification'),
        active: this.hasCreatePermission() && license.humanification,
        img: imgHumanification,
        header: 'Humanification Transformer',
        text: 'Test your bot for typical human behavior including misspellings, typing speed, background noise and emotions'
      },
      {
        unique: 'cardTestSetSplitter',
        onClick: () => history.push('/testdatawizards/splitter'),
        active: this.hasCreatePermission(),
        img: imgTestSetSplitter,
        header: 'Test Set Splitter',
        text: 'Split large test sets into smaller chunks to use them for training and testing purposes'
      },
      {
        unique: 'cardCrawler',
        onClick: () => history.push('/testdatawizards/crawlerprojects'),
        active: license.crawler,
        img: imgCrawlerWizard,
        header: 'Crawler Wizard',
        text: 'Detect all paths in your chatbots conversation flow and export them as a test set for regression'
      },
      {
        unique: 'cardLiveChat',
        onClick: () => history.push('/testdatawizards/livechat'),
        active: hasPermission(user, 'CHATBOTS_LIVECHAT'),
        img: imgLiveChat,
        header: 'Live Chat Recorder',
        text: 'Record a conversation with your bot and simply save it as a test set'
      },
      {
        unique: 'cardVoiceWizard',
        onClick: () => history.push('/testdatawizards/voice'),
        active: features.speechService && this.hasTestSetPermission() && hasPermission(user, 'FILESYSTEM_WRITE'),
        img: imgVoicWizard,
        header: 'Voice Wizard',
        text: 'Record your own voice or synthesize a transcript using different voices, speakers and real-life effects'
      }
    ]

    return (
      <GridContainer>
        {tiles.map((t, index) => (<GridItem key={index} md={6} lg={3}>
          <div data-unique={t.unique}>
            <Card onClick={() => t.active && t.onClick()} className={t.active ? classes.CardCard : classes.CardCardDisabled} tabIndex={0}>
              <CardBody className={classes.CardBody}>
                <GridContainer classes={{
                    grid: classes.tileCard
                  }}>
                  <GridItem xs={12}><img alt={t.header} src={t.img} className={classNames({ [classes.tileCardImage]: true, [classes.tileCardImageInactive]: !t.active })}/></GridItem>
                  <GridItem xs={12}>
                    <div className={classes.tileHeader + ' ' + classes.tileHeaderimgCard}>{t.header}</div>
                  </GridItem>
                  <GridItem xs={12} className={classes.tileSubHeaderimgCard}>{t.text}</GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        </GridItem>))}
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license, features: state.settings.features })
)(withStyles(tileStyle)(TestDataWizards))
