const _ = require('lodash')

const getNLPScoreForTestSession = (ts) => {
  if (hasConfusionMatrix(ts)) return getConfusionMatrixScore(ts)
  if (hasIncomprehensionScore(ts)) return getIncomprehensionScore(ts)
  return undefined
}
const getNLPScoreForOverallStat = (overallStat) => getNLPScoreForTestSession({ trainerSession: { overallStat } })

const hasConfusionMatrix = (ts) => {
  if (!ts || !ts.trainerSession || !ts.trainerSession.overallStat) return false

  if (!_.isNil(ts.trainerSession.overallStat.F1 && ts.trainerSession.overallStat.confusionMatrixStrength !== 0)) {
    return true
  }
  return false
}

const getConfusionMatrixScore = (ts) => {
  if (hasConfusionMatrix(ts)) {
    return ts.trainerSession.overallStat.F1
  }
}

const hasIncomprehensionScore = (ts) => {
  if (!ts || !ts.trainerSession || !ts.trainerSession.overallStat) return false

  if (!_.isNil(ts.trainerSession.overallStat.incomprehensionIntents) &&
    !_.isNil(ts.trainerSession.overallStat.count) && ts.trainerSession.overallStat.count > 0) {
    return true
  }
  return false
}

const getIncomprehensionScore = (ts) => {
  if (hasIncomprehensionScore(ts)) {
    const incomp = ts.trainerSession.overallStat.incomprehensionIntents
    const count = ts.trainerSession.overallStat.count
    return (count - incomp) / count
  }
}

module.exports = {
  getNLPScoreForTestSession,
  getNLPScoreForOverallStat,
  hasConfusionMatrix,
  getConfusionMatrixScore,
  hasIncomprehensionScore,
  getIncomprehensionScore
}
