const tooltipStyle = theme => ({
  root: {
  },
  underline: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
})

export default tooltipStyle
