import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Mutation, Query } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import SimpleList from 'components/List/SimpleList.jsx'
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import QueryStatus from 'components/Info/QueryStatus'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'
import ShowIcon from 'components/Icon/ShowIcon'

import NamespacePermission from './NamespacePermission.jsx'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { NAMESPACEPERMISSIONS_QUERY, SYSTEMSETTINGS_QUERY, UPDATE_SYSTEMSETTINGS } from './gql.js'

import { hasPermission } from 'botium-box-shared/security/permissions'

class NamespacePermissions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCounter: 0
    }
  }

  renderNamespaceButton(systemsettings) {
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props
    
    if (systemsettings.checkNamespacePermissions) {
      return <Mutation
        mutation={UPDATE_SYSTEMSETTINGS}
        refetchQueries={[
          {
            query: SYSTEMSETTINGS_QUERY
          }
        ]}
      >
        {(mutateSystemSettings, { loading, error }) => (
          <ConfirmationButton
            key={`btnDisableNamespacePermissions_${this.state.newCounter}`}
            confirmationText={`When disabling namespace permissions, users might get access to test projects and other records that they should not have access to. Are you sure you want to disable it ?`}
            requireCheck={true}
            danger
            minWidth
            disabled={loading}
            onClick={async () => {
              this.setState({ newCounter: this.state.newCounter + 1 })
              try {
                await mutateSystemSettings({
                  variables: {
                    systemSettings: {
                      checkNamespacePermissions: false
                    }
                  }
                })
                setAlertSuccessMessage('Namespace permissions disabled')
              } catch (error) {
                setAlertErrorMessage('Disabling namespace permissions failed', error)
              }
            }}
            data-unique="btnDisableNamespacePermissions"
          >
            {loading && <><LoadingIndicator alt />Disable Namespace Permissions</>}
            {!loading && <><ShowIcon icon="times" />Disable Namespace Permissions</>}
          </ConfirmationButton>
        )}
      </Mutation>
    } else {
      return <Mutation
        mutation={UPDATE_SYSTEMSETTINGS}
        refetchQueries={[
          {
            query: SYSTEMSETTINGS_QUERY
          }
        ]}
      >
        {(mutateSystemSettings, { loading, error }) => (
          <ConfirmationButton
            key={`btnEnableNamespacePermissions_${this.state.newCounter}`}
            confirmationText={`When enabling namespace permissions, users might lose access to test projects and other records that are assigned to namespaces, if no explicit permissions are granted here. Are you sure you want to enable it ?`}
            requireCheck={true}
            danger
            minWidth
            disabled={loading}
            onClick={async () => {
              this.setState({ newCounter: this.state.newCounter + 1 })
              try {
                await mutateSystemSettings({
                  variables: {
                    systemSettings: {
                      checkNamespacePermissions: true
                    }
                  }
                })
                setAlertSuccessMessage('Namespace permissions enabled')
              } catch (error) {
                setAlertErrorMessage('Enabling namespace permissions failed', error)
              }
            }}
            data-unique="btnEnableNamespacePermissions"
          >
            {loading && <><LoadingIndicator alt />Enable Namespace Permissions</>}
            {!loading && <><ShowIcon icon="key" />Enable Namespace Permissions</>}
          </ConfirmationButton>
        )}
      </Mutation>
    }
  }

  renderHome() {
    return <GridContainer><GridItem xs={12} middle><Query query={SYSTEMSETTINGS_QUERY}>
      {(queryResult) => <QueryStatus {...queryResult} query="systemsettings" plain>{(data) => this.renderNamespaceButton(data.systemsettings)}</QueryStatus>}
    </Query></GridItem></GridContainer>
  }

  render() {
    const { history, location, user } = this.props

    return (
      <GridContainer>
        {hasPermission(user, 'USERS_MANAGE') && <GridItem xs={12} borderBottom middle>
          <Button
            secondary
            data-unique="btnNew"
            onClick={() => {
              this.setState({ newCounter: this.state.newCounter + 1, id: 'new' })
              history.push('/settings/security/namespacepermissions/new')
            }}
            >
              <ShowIcon icon="plus" /> New
            </Button>
        </GridItem>}
        <GridItem xs={12} sm={4} md={3} noPadding>
          <Query query={NAMESPACEPERMISSIONS_QUERY} notifyOnNetworkStatusChange={true}>
            {({ loading, error, data, refetch }) => {
              return <SimpleList
                name="tblNamespacePermissions"
                listData={(data && data.namespacepermissions && data.namespacepermissions.map(r => ({
                  id: r.id,
                  name: `"${r.namespace}" for ${r.userRole.name}`,
                  secondary: [r.canRead ? 'READ' : null, r.canWrite ? 'WRITE' : null].filter(r => r).join(' | '),
                  icon: <ShowIcon icon="shield-alt" />,
                  selected: location.pathname.indexOf(r.id) >= 0,
                  onClick: () => history.push(`/settings/security/namespacepermissions/${r.id}`)
                }))) || []}
                onRefresh={() => refetch()}
                pageLoading={loading}
              />
            }}
          </Query>
        </GridItem>
        <GridItem xs={12} sm={8} md={9} borderLeft>
          <Switch>
            <Route
              path="/settings/security/namespacepermissions/new"
              render={props => <NamespacePermission key={`new_${this.state.newCounter}`} onReady={(id) => history.push(`/settings/security/namespacepermissions/${id}`)} {...props}/>}
            />
            <Route
              path="/settings/security/namespacepermissions/:id"
              render={props => <NamespacePermission id={props.match.params.id} key={props.match.params.id} onReady={(id) => !id && history.push('/settings/security/namespacepermissions')} {...props}/>}
            />
            <Route
              
              render={() => this.renderHome()}
            />
          </Switch>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ license: state.settings.license, user: state.token.user }),
  { setAlertSuccessMessage, setAlertErrorMessage }
)(withStyles(settingsStyle)(withRouter(NamespacePermissions)))
