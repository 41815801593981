import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { Helmet } from 'react-helmet'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
// @material-ui/icons
import Menu from '@material-ui/icons/Menu'
// core components
import HeaderLinks from './HeaderLinks'
import Text from 'components/Typography/Text'


import headerStyle from 'assets/jss/material-dashboard-react/components/headerStyle.jsx'
import ShowIcon from 'components/Icon/ShowIcon'
import dashboardRoutes from 'routes/dashboard.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Tooltip from 'components/Tooltip/Tooltip'


const withBreadcrumbsFn = withBreadcrumbs(
  dashboardRoutes.filter(r => r.path && (_.has(r, 'breadcrumb') ? r.breadcrumb : !!r.navbarName)).map(r => ({
    ...r,
    breadcrumb: r.breadcrumb || r.navbarName,
  })),
  { disableDefaults: true }
)

function Header({ ...props }) {
  const { classes, breadcrumbs, color, navbarName, helmetName, hideLinks } = props
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [' ' + classes[color]]: color,
  })

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <GridContainer className={classes.gridAreas}>
          <GridItem lg={7} md={12} grid className={classes.col1}>
            <div className={classes.breadcrumb}>
            <Tooltip title="Back Home" placement="bottom" >
              <NavLink aria-label="Navigate to the home Page" to="/dashboard" className={classes.breadcrumbActive} ><ShowIcon icon="home" /></NavLink>
              </Tooltip>
              {breadcrumbs.length > 0 && <span className={classes.breadcrumbActive}>/</span>}
              {breadcrumbs.map(({ match, breadcrumb }, i) => {
                return <React.Fragment key={i}>
                  {i !== breadcrumbs.length - 1 ? <NavLink to={match.url} className={classes.breadcrumbActive}>{breadcrumb}</NavLink> : <Text regular displayInline >{breadcrumb}</Text>}
                  {i !== breadcrumbs.length - 1 && <span className={classes.breadcrumbActive}>/</span>}
                </React.Fragment>
              })}
            </div>
          </GridItem>
          <GridItem lg={5} md={12} grid right className={classes.col2}>
            {!hideLinks && <React.Fragment>
            <Hidden smDown implementation={window.Cypress ? 'js' : 'css'} className={classes.smDown}>
              <HeaderLinks />
            </Hidden>
            <Hidden mdUp implementation={window.Cypress ? 'js' : 'css'} className={classes.mdUp}>
              <IconButton className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </React.Fragment>}
          </GridItem>
          <GridItem xs={12} className={classes.col3}>
            
              {navbarName &&
                <h1 className={classes.title} data-unique="titlePage" tabIndex={1} aria-label={navbarName} aria-live={navbarName}>
                  {_.isFunction(navbarName) ? React.createElement(navbarName) : navbarName}
                </h1>
              }
              {helmetName &&
                <Helmet>
                  <title>{helmetName}</title>
                </Helmet>
              }
              {!helmetName && navbarName && _.isString(navbarName) &&
                <Helmet>
                  <title>{navbarName}</title>
                </Helmet>
              }                  
            
          </GridItem>
        </GridContainer>
        
        
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  navbarName: PropTypes.any,
  helmetName: PropTypes.any,
  hideLinks: PropTypes.bool
}

export default withStyles(headerStyle)(withBreadcrumbsFn(Header))
