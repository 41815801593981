import {SET_TEST_DATA_WIZARD_DATA, CLEAR_TEST_DATA_WIZARD_DATA} from '../constants'

export function setTestDataWizardData(testdatawizard) {
  return {
    type: SET_TEST_DATA_WIZARD_DATA,
    payload:
      testdatawizard,
  }
}

export function clearTestDataWizardData() {
  return {
    type: CLEAR_TEST_DATA_WIZARD_DATA
  }
}
