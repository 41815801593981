import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import ListItem from '@material-ui/core/ListItem'

// core components
import listStyle from 'assets/jss/material-dashboard-react/components/listStyle.jsx'
import classNames from 'classnames'

function CustomListItem({ ...props }) {
  const { children, classes, small, noPaddingVertical, compactPadding, noPaddingBottom, ...rest } = props
  return (
    <ListItem  classes={{
      dense: classes.listItemDense,
      gutters: classes.listItemGutters,
      root: classNames({
        [classes.listItemRoot]: true,
        [classes.listItemSmallRoot]: small,
        [classes.listItemNoPaddingVertical]: noPaddingVertical,
        [classes.listItemNoPaddingBottom]: noPaddingBottom,
        [classes.listItemCompactPadding]: compactPadding
      }),
      selected: classes.listItemSelected
      
    }} {...rest}>
      {children}
    </ListItem>
  )
}

export default withStyles(listStyle)(CustomListItem)
