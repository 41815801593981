import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import TablePagination from 'components/Table/CustomTablePagination'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import { Query, Mutation } from 'react-apollo'
import { graphql, compose, withApollo } from 'react-apollo'
// core components
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ExpansionPanel from 'components/Expansion/ExpansionPanel.jsx'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary.jsx'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails.jsx'
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import LinkButton from 'components/Button/LinkButton'
import { renderFileUpload, renderTextField, CustomTextField, CustomRenderField, CustomCheckbox, TableActionsToolbar } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { setTableSettings } from 'actions/table'
import DateFormat from 'components/Info/DateFormat'
import {downloadfile, downloadfileformpost} from 'helper/downloadHelper'
import { isAudioFileItem } from 'helper/audioHelper'
import { extractErrorMessage } from 'helper/graphHelper'
import prettyBytes from 'pretty-bytes'
import { recordAudioSupported, recordAudio, openInNewTab } from 'helper/browserHelper'
import MediaSelectionDialog from 'components/Dialog/MediaSelectionDialog.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'

import ShowIcon from 'components/Icon/ShowIcon'
import ShowFileIcon from 'components/Dialog/ShowFileIcon'
import AudioButton from 'components/Button/AudioButton'
import Text from 'components/Typography/Text.jsx'

import config from 'config'

import settingsStyle from 'assets/jss/material-dashboard-react/views/settingsStyle.jsx'

import { FILESYSTEM_QUERY, FILESYSTEM_HASCONTENT_QUERY, FILESYSTEM_UPLOAD_CONTENT, FILESYSTEM_DELETE_CONTENT, FILESYSTEM_CREATE_FOLDER, FILESYSTEM_DELETE_FOLDER, FILESYSTEM_MOVE_CONTENT, FILESYSTEM_COPY_CONTENT, FILESYSTEM_RENAME_CONTENT, FILESYSTEM_DELETE_BULK } from './gql'
import { SPEECH_RUN_EFFECT } from 'views/Chatbots/gql'

import { hasPermission, canWriteBotiumFolder } from 'botium-box-shared/security/permissions'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Tooltip from 'components/Tooltip/Tooltip'

class FileBrowser extends React.PureComponent {
  constructor(props) {
    super(props)

    const _getInitPath = () => {
      if (props.location && props.location.search) {
        const query = queryString.parse(props.location.search)
        if (query.path) {
          return query.path.split('/')
        }
      }
      if (props.currentPath) {
        if (_.isString(props.currentPath)) {
          return props.currentPath.split('/')
        } else if (_.isArray(props.currentPath)) {
          return props.currentPath
        }
      }
      if (props.tableSettings && props.tableSettings.filebrowser) {
        return props.tableSettings.filebrowser.currentPath || []
      }
    }
    const _getBasePath = () => {
      if (props.location && props.location.search) {
        const query = queryString.parse(props.location.search)
        if (query.basepath) {
          return query.basepath.split('/')
        }
      }
      if (props.basePath) {
        if (_.isString(props.basePath)) {
          return props.basePath.split('/')
        } else if (_.isArray(props.basePath)) {
          return props.basePath
        }
      }
    }

    this.state = {
      uploadExpanded: false,
      uploading: false,
      uploadingZip: false,
      currentPath: _getInitPath() || [],
      basePath: _getBasePath() || null,
      sort: [],
      filter: '',
      page: 0,
      pageSize: 25,
      isRecording: false,
      selectedItems: [],
      showDirectorySelectionDialogCopy: false,
      showDirectorySelectionDialogMove: false,
      showForceDelete: false,
      showForceDeleteCallback: null,
      renameNewFilename: '',
    }
    this.recorder = null
  }

  async initRecorder() {
    this.recorder = await recordAudio()
  }

  handleGoto(dirNames) {
    const { basePath } = this.state
    if (basePath) {
      if (!dirNames.join('/').startsWith(basePath.join('/'))) return
    }

    this.setState({ currentPath: dirNames, selectedItems: [], filter: '', page: 0 })
    this.props.setTableSettings('filebrowser', { currentPath: dirNames })
  }

  render() {
    const { theme, disableFileUpload, disableAudioRecording, acceptUpload, setAlertSuccessMessage, setAlertErrorMessage, client, user, mutateSpeechRunConversion, classes, expanded } = this.props
    const { basePath, currentPath, filter, sort, page, pageSize } = this.state

    const hasWritePermission = () => {
      return canWriteBotiumFolder(user, currentPath)
    }

    return (<Query
      query={FILESYSTEM_QUERY}
      variables={{ path: currentPath }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data, refetch }) => {
        const currentPath = (data && data.fileBrowserGetContent && data.fileBrowserGetContent.path) || []
        const allContentList = _.orderBy(
          [].concat((data && data.fileBrowserGetContent && data.fileBrowserGetContent.content) || []).filter(c => {
            if (!filter) return true
            if (!c.name) return false
            return (c.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
          }),
          sort && sort.length > 0 ? sort.map(s => (l) => {
            if (_.isString(l[s.field])) return l[s.field].toLowerCase()
            if (_.isNil(l[s.field])) return -1
            return l[s.field]
          }) : ['type', 'name'],
          sort && sort.length > 0 ? sort.map(s => s.direction) : ['desc', 'asc']
        )
        const contentList = allContentList.slice(page * pageSize, page * pageSize + pageSize)

        const rootButton = () => {
          if (basePath) return <Text key={'/'} muted inline>Botium</Text>
          if (currentPath.length === 1) {
            return <LinkButton gray data-unique=".." key={'/'} onClick={() => this.handleGoto([])}>Botium</LinkButton>
          }
          return <LinkButton gray key={'/'} data-unique="btnGoto_root" onClick={() => this.handleGoto([])}>Botium</LinkButton>
        }

        const getRowStyle = (index) => {
          const defaultStyle = {
            display: 'flex',
            alignItems: 'center'
          }
          return (index % 2) ? defaultStyle : Object.assign(defaultStyle, { backgroundColor: theme.colors.backgroundTableRow })
        }

        const isSelected = (name) => {
          return this.state.selectedItems.filter(a => a === name).length > 0
        }

        const handleSelection = (name) => {
          if (isSelected(name)) {
            this.setState({
              selectedItems: this.state.selectedItems.filter(a => a !== name)
            })
          } else {
            this.setState({
              selectedItems: [...this.state.selectedItems, name]
            })
          }
        }

        const handleSelectAll = (contentList) => {
          if (this.state.selectedItems.length !== contentList.length) {
            this.setState({
              selectedItems: contentList.map(a => a.name)
            })
          } else {
            this.setState({
              selectedItems: []
            })
          }
        }

        const getHeaderIcon = (field) => {
          const existingSort = sort.find(s => s.field === field)
          if (existingSort) {
            if (existingSort.direction === 'asc') return <ShowIcon icon="sort-up" />
            if (existingSort.direction === 'desc') return <ShowIcon icon="sort-down" />
          }
          return <ShowIcon icon="sort-up" hidden />
        }

        const handleClickHeader = (field) => {
          const existingSort = sort.find(s => s.field === field)
          if (existingSort) {
            if (existingSort.direction === 'asc') {
              existingSort.direction = 'desc'
              this.setState({ sort: [...sort] })
            } else {
              this.setState({ sort: [...sort.filter(s => s.field !== field)] })
            }
          } else {
            sort.push({
              field,
              direction: 'asc'
            })
            this.setState({ sort: [...sort] })
          }
        }

        return (<GridContainer>
          {!disableFileUpload && <GridItem xs={12} largeMarginTop>
            <ExpansionPanel expanded={this.state.uploadExpanded ? false : expanded} data-unique="pnlFileUpload">
              <ExpansionPanelSummary onClick={() => this.setState({ uploadExpanded: !this.state.uploadExpanded })}>
                {disableAudioRecording && 'File Upload'}
                {!disableAudioRecording && 'File Upload and Audio Recording'}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <GridContainer nounset justify="space-between">
                  <GridItem md={12} lg={6}>
                    <Mutation
                      mutation={FILESYSTEM_UPLOAD_CONTENT}
                      onCompleted={data => {
                        setAlertSuccessMessage('File(s) uploaded')
                        this.setState({ uploading: false })
                        refetch()
                        if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'uploadContent' })
                      }}
                      onError={error => {
                        setAlertErrorMessage(`File upload failed: ${extractErrorMessage(error)}`)
                        this.setState({ uploading: false })
                      }}
                    >
                      {(uploadContent, { loading, error }) => (
                        <Form
                          onSubmit={values => {
                            uploadContent({
                              variables: {
                                path: currentPath,
                                filename: values.filename,
                                filecontent: values.filecontent,
                                unzip: false
                              }
                            })
                          }}
                          render={({
                            handleSubmit,
                            form: {
                              change,
                              reset
                            },
                            values,
                          }) => (
                            <form onSubmit={async event => {
                              await handleSubmit(event)
                              setTimeout(reset, 0)
                            }}>
                              <GridContainer>
                                <GridItem xs={12}>
                                  <Field
                                    name="fileupload"
                                    component={renderFileUpload}
                                    data-unique="fileFileBrowserUpload"
                                    label={`Upload File(s) to Botium${acceptUpload ? ` (${acceptUpload.split(',').map(s => '*' + s).join(',')})` : ''}`}
                                    values={values}
                                    change={change}
                                    multiple={true}
                                    accept={acceptUpload || undefined}
                                    processingFiles={this.state.uploading}
                                    onDrop={() => this.setState({ uploading: true })}
                                    disabled={!hasWritePermission()}
                                    onFileLoaded={async (filename, filecontent) => {
                                      change('filename', filename)
                                      change('filecontent', filecontent)
                                      await handleSubmit()
                                      setTimeout(reset, 0)
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </form>
                          )}
                        />)}
                    </Mutation>
                  </GridItem>
                  <GridItem md={12} lg={6}>
                    <Mutation
                      mutation={FILESYSTEM_UPLOAD_CONTENT}
                      onCompleted={data => {
                        setAlertSuccessMessage('File uploaded and unzipped')
                        this.setState({ uploadingZip: false })
                        refetch()
                        if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'uploadContent' })
                      }}
                      onError={error => {
                        setAlertErrorMessage(`File upload failed: ${extractErrorMessage(error)}`)
                        this.setState({ uploadingZip: false })
                      }}
                    >
                      {(uploadContent, { loading, error }) => (
                        <Form
                          onSubmit={values => {
                            uploadContent({
                              variables: {
                                path: currentPath,
                                filename: values.filename,
                                filecontent: values.filecontent,
                                unzip: true
                              }
                            })
                          }}
                          render={({
                            handleSubmit,
                            form: {
                              change,
                              reset
                            },
                            values,
                          }) => (
                            <form onSubmit={async event => {
                              await handleSubmit(event)
                              setTimeout(reset, 0)
                            }}>
                              <GridContainer>
                                <GridItem xs={12}>
                                  <Field
                                    name="fileupload"
                                    component={renderFileUpload}
                                    data-unique="fileFileBrowserUploadZip"
                                    label="Upload & Unpack ZIP Files to Botium (*.zip)"
                                    accept=".zip"
                                    values={values}
                                    change={change}
                                    multiple={true}
                                    processingFiles={this.state.uploadingZip}
                                    onDrop={() => this.setState({ uploadingZip: true })}
                                    disabled={!hasWritePermission()}
                                    onFileLoaded={async (filename, filecontent) => {
                                      if (filename.endsWith('.zip')) {
                                        change('filename', filename)
                                        change('filecontent', filecontent)
                                        await handleSubmit()
                                        setTimeout(reset, 0)
                                      } else {
                                        setAlertErrorMessage(`Only *.zip files allowed`)
                                        change('filename', null)
                                        change('filecontent', null)
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </form>
                          )}
                        />)}
                    </Mutation>
                  </GridItem>
                  {!disableAudioRecording &&
                    <GridItem xs={12}>
                      <Mutation
                        mutation={FILESYSTEM_UPLOAD_CONTENT}
                      >
                        {(uploadContent) => (
                          <Form
                            onSubmit={async (values, form) => {
                              try {
                                await uploadContent({
                                  variables: {
                                    path: currentPath,
                                    filename: values.filename,
                                    filecontent: values.filecontent,
                                    unzip: false
                                  }
                                })
                                setAlertSuccessMessage('Audio Recording uploaded')
                                form.initialize({})
                                refetch()
                                if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'uploadContent' })
                              } catch (err) {
                                setAlertErrorMessage('Upload Audio Recording failed', err)
                              }
                            }}
                            render={({
                              handleSubmit,
                              form: {
                                change
                              },
                              submitting,
                              values
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <CustomRenderField label="Record Audio File" >
                                  <GridContainer>
                                    <GridItem xs={12} left largePaddingLeft>
                                      <TableActionsToolbar>
                                        <Tooltip title={!this.state.isRecording ? 'Record Audio' : 'Record Stop'}>
                                          <Button
                                            disabled={!recordAudioSupported()}
                                            round
                                            justIcon
                                            data-click={false}
                                            onClick={async () => {
                                              if (!this.state.isRecording) {
                                                await this.initRecorder()
                                                this.recorder.start()
                                                change('filecontent', '')
                                                this.setState({
                                                  isRecording: true
                                                })
                                              } else {
                                                const data = await this.recorder.stop()
                                                const response = await mutateSpeechRunConversion({
                                                  variables: {
                                                    convData: {
                                                      base64: data.base64,
                                                      mimeType: data.mimeType,
                                                      effects: ['WEBMTOMONOWAV']
                                                    }
                                                  }
                                                })
                                                change('filecontent', 'data:audio/wav;base64,' + response.data.speechRunEffect)
                                                this.setState({
                                                  isRecording: false
                                                })
                                              }
                                            }}
                                            data-unique="btnLiveChatRecordAudio"
                                          >

                                            {!this.state.isRecording && <><ShowIcon icon="microphone" /></>}
                                            {this.state.isRecording && <><ShowIcon icon="stop" /></>}
                                          </Button>
                                        </Tooltip>
                                        <Tooltip title="Play Audio File">
                                          <AudioButton
                                            aria-label="Play Audio File"
                                            data-unique="btnRecordPlay"
                                            justIcon
                                            audioBase64={values.filecontent}
                                            mimeType="audio/wav"
                                          />
                                        </Tooltip>
                                        <Field
                                          name="filename"
                                          component={renderTextField}
                                          label="Audio File Name"
                                          placeholder="Audio File Name"
                                          data-unique="txtRecordName"
                                          disabled={!hasWritePermission()}
                                          noLabel
                                        />
                                        <Tooltip title="Save">
                                          <Button
                                            aria-label="Save"
                                            type="submit"
                                            disabled={!hasWritePermission() || !values.filecontent || !values.filename || submitting}
                                            round
                                            justIcon
                                            data-unique="btnRecordSave"
                                            data-click={false}
                                            onClick={async () => {
                                              if (values.filename && !values.filename.endsWith('.wav')) {
                                                change('filename', `${values.filename}.wav`)
                                              }
                                              const parts = values.filecontent.split(',')
                                              if (parts.length > 1) {
                                                change('filecontent', parts[1])
                                              }
                                              await handleSubmit()
                                            }}>
                                            {submitting && <LoadingIndicator alt />}
                                            {!submitting && <ShowIcon icon="save" />}
                                          </Button>
                                        </Tooltip>
                                      </TableActionsToolbar>
                                    </GridItem>
                                  </GridContainer>
                                </CustomRenderField>
                              </form>
                            )}
                          />)}
                      </Mutation>
                    </GridItem>
                  }
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </GridItem>}
          <GridItem xs={12}><Card><CardBody>
            <GridContainer>
              <GridItem md={12} lg={8}>
                {[
                  rootButton(),
                  ...((currentPath && currentPath.map((p, i) => {
                    const dirNames = currentPath.slice(0, i + 1)
                    if (basePath && !dirNames.join('/').startsWith(basePath.join('/'))) {
                      return <Text key={p} muted inline>{p}</Text>
                    }
                    if (i === currentPath.length - 2) {
                      return <LinkButton gray data-unique=".." key={p} onClick={() => this.handleGoto(dirNames)}>{p}</LinkButton>
                    }
                    return <LinkButton gray key={p} data-unique={`btnGoto_${p}`} onClick={() => this.handleGoto(dirNames)}>{p}</LinkButton>
                  })) || [])
                ].reduce((result, item, i) => result.concat([<Text inline key={'/' + i}> / </Text>, item]), [])}
              </GridItem>
              <GridItem md={12} lg={4} right>
                <TableActionsToolbar right>
                  <CustomTextField
                    input={{
                      name: 'txtFilterInput',
                      value: filter,
                      onChange: (e) => this.setState({ filter: e.target.value })
                    }}
                    placeholder="Search..."
                    data-unique="txtFilter"
                  />
                  <Button
                    smallTop
                    round
                    justIcon
                    aria-label="Refresh"
                    title="Refresh"
                    data-click={false}
                    onClick={() => refetch()} data-unique="btnRefresh">
                    <RefreshIcon />
                  </Button>
                </TableActionsToolbar>
              </GridItem>
              {hasPermission(user, 'FILESYSTEM_WRITE') && currentPath.length > 0 && this.state.selectedItems.length > 0 &&
                <GridItem xs={12} primary center smallMarginBottom>
                  <Button
                    data-unique={`btnMultipleFileDownload`}
                    onClick={async () => {
                      try {
                        const files = []
                        for (const selectedItem of this.state.selectedItems) {
                          files.push(`${currentPath.join('/')}/${selectedItem}`)
                        }
                        let fileName
                        if (this.state.selectedItems.length === 1) {
                          fileName = `${this.state.selectedItems[0]}_${moment().format('YYYY-MM-DD')}.zip`
                        } else {
                          fileName = currentPath && currentPath.length > 0 ? `${currentPath[currentPath.length - 1]}_${moment().format('YYYY-MM-DD')}.zip` : undefined
                        }
                        await downloadfile(`${config.api.base}/filebrowser/multiplefiles`, 'POST', {files, fileName})
                        this.setState({selectedItems: []})
                        setAlertSuccessMessage('Files and Folders are downloading')
                      } catch (e) {
                        setAlertErrorMessage('Downloading Files and Folders failed', e)
                      }
                    }}
                    secondary
                  ><ShowIcon icon="download" />Download {this.state.selectedItems.length > 1 ? `(${this.state.selectedItems.length})` : ''}</Button>
                  <Mutation
                    mutation={FILESYSTEM_COPY_CONTENT}
                    onCompleted={data => {
                      setAlertSuccessMessage('Files and Folders are copied')
                      refetch()
                      if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'copyDirectoryContent' })
                    }}
                    onError={error => {
                      setAlertErrorMessage('Copying Files and Folders failed', error)
                    }}
                  >
                    {(copyDirectoryContent) => (
                      <><Button
                        data-unique={`btnFileBrowserCopyTo`}
                        onClick={() => {
                          this.setState({ showDirectorySelectionDialogCopy: true })
                        }}
                        secondary
                      ><ShowIcon icon="copy" />Copy {this.state.selectedItems.length > 1 ? `(${this.state.selectedItems.length})` : ''}</Button>
                        <MediaSelectionDialog allowFolderSelection
                          initialPath={currentPath}
                          open={!!this.state.showDirectorySelectionDialogCopy}
                          onCancel={() => this.setState({ showDirectorySelectionDialogCopy: false })}
                          onOk={({ selectedFolders }) => {
                            copyDirectoryContent({
                              variables: {
                                path: currentPath,
                                names: this.state.selectedItems,
                                destinationPath: selectedFolders[0]
                              }
                            })
                            this.setState({ showDirectorySelectionDialogCopy: false, selectedItems: [] })
                          }}
                          title="Select Destination Folder"
                        /></>
                    )}
                  </Mutation>
                  <Mutation
                    mutation={FILESYSTEM_MOVE_CONTENT}
                    onCompleted={data => {
                      setAlertSuccessMessage('Files and Folders are moved')
                      refetch()
                      if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'moveDirectoryContent' })
                    }}
                    onError={error => {
                      setAlertErrorMessage('Moving Files and Folders failed', error)
                    }}
                  >
                    {(moveDirectoryContent) => (
                      <><Button
                        data-unique={`btnFileBrowserMoveTo`}
                        disabled={!hasWritePermission()}
                        onClick={() => {
                          this.setState({ showDirectorySelectionDialogMove: true })
                        }}
                        secondary
                      ><ShowIcon icon="file-export" />Move {this.state.selectedItems.length > 1 ? `(${this.state.selectedItems.length})` : ''}</Button>
                        <MediaSelectionDialog allowFolderSelection
                          initialPath={currentPath}
                          open={!!this.state.showDirectorySelectionDialogMove}
                          onCancel={() => this.setState({ showDirectorySelectionDialogMove: false })}
                          onOk={({ selectedFolders }) => {
                            moveDirectoryContent({
                              variables: {
                                path: currentPath,
                                names: this.state.selectedItems,
                                destinationPath: selectedFolders[0]
                              }
                            })
                            this.setState({ showDirectorySelectionDialogMove: false, selectedItems: [] })
                          }}
                          title="Select Destination Folder"
                        /></>
                    )}

                  </Mutation>
                  <Mutation
                    mutation={FILESYSTEM_DELETE_BULK}
                    onCompleted={data => {
                      setAlertSuccessMessage('Files and Folders are removed')
                      this.setState({ selectedItems: [] })
                      refetch()
                      if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'deleteBulk' })
                    }}
                    onError={error => {
                      setAlertErrorMessage('Removing Files and Folders failed', error)
                    }}
                  >
                    {(deleteBulk) => (
                      <ConfirmationButton
                        confirmationText="Are you sure you want to irrevocably delete the selected files / folders including content ?"
                        data-unique="btnFileBrowserDeleteBulk"
                        disabled={!hasWritePermission()}
                        onClick={() => {
                          deleteBulk({
                            variables: {
                              path: currentPath,
                              names: this.state.selectedItems,
                              force: true
                            }
                          })
                        }}
                        secondary
                        danger
                      ><ShowIcon icon="trash" />
                        <Text info bold muted={!hasWritePermission()}>Delete {this.state.selectedItems.length > 1 ? `(${this.state.selectedItems.length})` : ''}</Text>
                      </ConfirmationButton>
                    )}
                  </Mutation>
                </GridItem>
              }
              {hasPermission(user, 'FILESYSTEM_WRITE') && (currentPath.length === 0 || this.state.selectedItems.length === 0) &&
                <GridItem xs={12} smallMarginBottom>
                  <Button hidden>HIDDEN</Button>
                </GridItem>
              }
              <GridItem xs={12}>
                <GridContainer>
                <GridItem xs={5}>
                  <GridContainer>
                  <GridItem>
                      {hasPermission(user, 'FILESYSTEM_WRITE') && currentPath.length > 0 && contentList.length > 0 &&
                        <CustomCheckbox
                          useCheckbox
                          dense
                          input={{
                            onChange: event => {
                              handleSelectAll(contentList)
                            },

                            checked: this.state.selectedItems.length === contentList.length
                          }}
                          key={`chkFilebrowserFileSelectionAll`}
                          data-unique={`chkFilebrowserFileSelectionAll`}
                        />
                      }
                  </GridItem>
                  <GridItem xs={5}>
                    <LinkButton gray onClick={() => handleClickHeader('name')}>Name {getHeaderIcon('name')}</LinkButton>
                  </GridItem>
                  </GridContainer>
                </GridItem>
                  <GridItem xs={3}>
                    <LinkButton gray onClick={() => handleClickHeader('updatedAt')}>Date Modified {getHeaderIcon('updatedAt')}</LinkButton>
                  </GridItem>
                  <GridItem xs={1} right>
                    <LinkButton gray onClick={() => handleClickHeader('size')}>Size {getHeaderIcon('size')}</LinkButton>
                  </GridItem>
                  <GridItem xs={3}></GridItem>
                  {loading && <GridItem xs={12}><Text><LoadingIndicator /></Text></GridItem>}
                  {!loading && error && <GridItem xs={12}><Text danger>{extractErrorMessage(error)}</Text></GridItem>}
                  {!loading && !error && contentList.length === 0 && filter && <GridItem xs={12}><Text muted>No file or folder found</Text></GridItem>}
                  {!loading && !error && contentList.length === 0 && !filter && <GridItem xs={12}><Text muted>Folder empty</Text></GridItem>}
                  {!loading && !error && contentList.length > 0 && contentList.map((a, index) => <React.Fragment key={index}>
                    <GridItem xs={5} style={getRowStyle(index)}>
                      {hasPermission(user, 'FILESYSTEM_WRITE') && currentPath.length > 0 &&
                        <CustomCheckbox
                          useCheckbox
                          dense
                          input={{
                            onChange: event => {
                              handleSelection(a.name)
                            },
                            checked: this.state.selectedItems.filter(name => a.name === name).length > 0
                          }}
                          key={`chkFilebrowserFileSelection_${a.name}`}
                          data-unique={`chkFilebrowserFileSelection_${a.name}`}
                        />
                      }
                      {a.type === 'FOLDER' && <LinkButton gray className={classes.filenameButton} data-unique={a.name} onClick={() => this.handleGoto(a.goto || [...currentPath, a.name])}><ShowFileIcon item={a} size="lg" /> {a.name}</LinkButton>}
                      {a.type === 'FILE' && <LinkButton gray className={classes.filenameButton} data-unique={a.name} onClick={async () => {
                        try {
                          openInNewTab(`${config.api.base}/filebrowser/${currentPath.join('/')}/${a.name}`)
                        } catch(err) {
                          setAlertErrorMessage(err.message)
                        }
                      }}><ShowFileIcon item={a} size="lg" /> {a.name}</LinkButton>}
                    </GridItem>
                    <GridItem xs={3} style={getRowStyle(index)}><Text>{a.updatedAt && (<React.Fragment><DateFormat fromNow>{a.updatedAt}</DateFormat>&nbsp;(<DateFormat format="L">{a.updatedAt}</DateFormat>)</React.Fragment>)}</Text></GridItem>
                    <GridItem xs={1} style={getRowStyle(index)} right><Text>{!_.isNil(a.size) ? prettyBytes(a.size) : ''}</Text></GridItem>
                    <GridItem xs={3} style={getRowStyle(index)} right>
                      {isAudioFileItem(a) &&
                      <Tooltip title="Play File">
                        <AudioButton
                          data-unique={`btnFileBrowserPlayFile_${a.name}`}
                          justIcon
                          audioSrc={`${config.api.base}/filebrowser/${currentPath.join('/')}/${a.name}`}
                          mimeType={a.mimeType}
                        />
                      </Tooltip>
                      }

                      {a.type === 'FILE' && (
                       <Tooltip title="Download">
                        <LinkButton gray data-unique={a.name} onClick={() => downloadfileformpost(`${config.api.base}/filebrowser/${currentPath.join('/')}/${a.name}`).catch(err => setAlertErrorMessage(err.message))}>
                          <ShowIcon icon="download" />
                        </LinkButton>
                        </Tooltip>
                      )}

                      <Mutation
                        mutation={FILESYSTEM_RENAME_CONTENT}
                        onCompleted={data => {
                          setAlertSuccessMessage('File / Folder renamed')
                          refetch()
                          if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'renameDirectoryContent' })
                        }}
                        onError={error => {
                          setAlertErrorMessage('Renaming File / Folder failed', error)
                        }}
                      >
                        {(renameDirectoryContent) => (
                          <>
                          <Tooltip title="Rename">
                            <Button
                              aria-label="Rename"
                              disabled={!hasWritePermission() || currentPath.length === 0}
                              data-unique={`btnFileBrowserRename_${a.name}`}
                              onClick={() => {
                                this.setState({ [`showDialogRename_${a.name}`]: true, renameNewFilename: a.name })
                              }}
                              title="Rename"
                              justIcon
                            >
                              <ShowIcon icon="edit" />
                            </Button>
                          </Tooltip>
                            <ConfirmationDialog
                              open={!!this.state[`showDialogRename_${a.name}`]}
                              onCancel={() => this.setState({ [`showDialogRename_${a.name}`]: false })}
                              onOk={() => {
                                renameDirectoryContent({
                                  variables: {
                                    path: currentPath,
                                    oldname: a.name,
                                    newname: this.state.renameNewFilename
                                  }
                                })
                                this.setState({ [`showDialogRename_${a.name}`]: false, selectedItems: [], renameNewFilename: '' })
                              }}
                              title={`Rename ${a.name}`}
                            >
                              <CustomTextField
                                label="Enter the new name"
                                input={{
                                  name: 'renamenewfilename',
                                  key: 'txtRenameNewFilename',
                                  value: this.state.renameNewFilename,
                                  onChange: (e, child) => {
                                    this.setState({
                                      renameNewFilename: e.target.value
                                    })
                                  },
                                }}
                                data-unique="txtRenameNewFilename"
                              />
                            </ConfirmationDialog></>
                        )}
                      </Mutation>

                      {a.type === 'FOLDER' && (
                        <Mutation
                          mutation={FILESYSTEM_DELETE_FOLDER}
                          onCompleted={data => {
                            setAlertSuccessMessage('Folder deleted')
                            refetch()
                            if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'deleteFolder' })
                          }}
                          onError={error => {
                            setAlertErrorMessage('Folder deletion failed', error)
                          }}
                        >
                          {(deleteFolder) => (
                           <Tooltip title="Delete Folder">
                            <ConfirmationButton
                              disabled={!hasWritePermission() || currentPath.length === 0}
                              data-unique={`btnFileBrowserDeleteFolder_${a.name}`}
                              requireCheck
                              confirmationTextFn={async () => {
                                const { data } = await client.query({
                                  query: FILESYSTEM_HASCONTENT_QUERY,
                                  variables: {
                                    path: currentPath, names: [a.name]
                                  },
                                  fetchPolicy: 'network-only'
                                })
                                if (data.fileBrowserHasContent) {
                                  return 'Are you sure you want to irrevocably delete the selected folder including content ?'
                                }
                              }}
                              onClick={() => {
                                deleteFolder({
                                  variables: { path: currentPath, dirname: a.name, force: true }
                                })
                              }}
                              aria-label="Delete Folder"
                              title="Delete Folder"
                              justIcon
                            ><ShowIcon icon="trash" /></ConfirmationButton></Tooltip>
                          )}
                        </Mutation>
                      )}
                      {a.type === 'FILE' && (
                        <Mutation
                          mutation={FILESYSTEM_DELETE_CONTENT}
                          onCompleted={data => {
                            setAlertSuccessMessage('File deleted')
                            refetch()
                            if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'deleteContent' })
                          }}
                          onError={error => {
                            setAlertErrorMessage('File deletion failed', error)
                          }}
                        >
                          {(deleteContent) => (
                           <Tooltip title="Delete File">
                            <ConfirmationButton
                              disabled={!hasWritePermission()}
                              confirmationText="Are you sure you want to irrevocably delete this file ?"
                              onClick={() => {
                                deleteContent({
                                  variables: { path: currentPath, filename: a.name }
                                })
                              }}
                              data-unique={`btnFileBrowserDeleteFile_${a.name}`}
                              title="Delete File"
                              justIcon
                            >
                              <ShowIcon icon="trash" />
                            </ConfirmationButton></Tooltip>
                          )}
                        </Mutation>
                      )}
                    </GridItem>
                  </React.Fragment>)}
                  <GridItem xs={6} left>
                    {hasWritePermission() && <>
                      <Mutation mutation={FILESYSTEM_CREATE_FOLDER}>
                        {(createFolder) => (
                          <Form onSubmit={async (values, form) => {
                            try {
                              await createFolder({
                                variables: {
                                  path: currentPath,
                                  dirname: values.dirname
                                }
                              })
                              setAlertSuccessMessage('Folder created')
                              form.initialize({})
                              refetch()
                              if (this.props.onFileBrowserAction) this.props.onFileBrowserAction({ action: 'createFolder' })
                            } catch (err) {
                              setAlertErrorMessage('Folder creation failed', err)
                            }
                          }}
                            render={({
                              handleSubmit,
                              submitting,
                              pristine,
                              invalid
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <TableActionsToolbar>
                                  <Field
                                    name="dirname"
                                    component={renderTextField}
                                    placeholder="Enter new Folder Name"
                                    data-unique="txtFileBrowserNewFolderName"
                                    noLabel
                                  />
                                  <Button aria-label="Create Folder" title="Create Folder" type="submit" disabled={submitting || pristine || invalid} round justIcon data-unique="btnFileBrowserCreateFolder">
                                    {submitting && <LoadingIndicator alt />}
                                    {!submitting && <ShowIcon icon="folder-plus" />}
                                  </Button>
                                </TableActionsToolbar>
                              </form>
                            )}
                          />
                        )}
                      </Mutation>
                    </>}
                  </GridItem>
                  <GridItem xs={6} >
                    <TablePagination
                      component="div"
                      count={allContentList.length}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      page={page}
                      onChangePage={(e, page) => this.setState({ page, selectedItems: [] })}
                      onChangeRowsPerPage={(e) => this.setState({ pageSize: e.target.value })}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody></Card></GridItem>
        </GridContainer>)
      }}
    </Query>)
  }
}

export default withRouter(
  compose(
    withStyles(settingsStyle, { withTheme: true }),
    connect(
      state => ({ user: state.token.user, tableSettings: state.table }),
      { setAlertSuccessMessage, setAlertErrorMessage, setTableSettings },
    ),
    graphql(SPEECH_RUN_EFFECT, {
      props: ({ mutate }) => ({
        mutateSpeechRunConversion: args => mutate(args)
      }),
    }),
    graphql(FILESYSTEM_DELETE_FOLDER, {
      props: ({ mutate }) => ({
        mutateDeleteFolder: args => mutate(args)
      }),
    }),
    graphql(FILESYSTEM_DELETE_BULK, {
      props: ({ mutate }) => ({
        mutateDeleteBulk: args => mutate(args)
      }),
    }),
  )
    (withApollo(FileBrowser)),
)
