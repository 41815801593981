import React from 'react'
import { connect } from 'react-redux'
import ShowIcon from 'components/Icon/ShowIcon'
import Button from 'components/Button/Button'
import { downloadfileb64 } from 'helper/downloadHelper'
import { setAlertErrorMessage } from 'actions/alert'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

class AudioButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      audio: null,
      playing: false,
      failed: null
    }
  }

  componentWillUnmount() {
    const { audio } = this.state
    if (audio) audio.pause()
  }

  render () {
    const { setAlertErrorMessage, onError, audioBase64, audioSrc, mimeType, iconProps = {}, dispatch, ...props } = this.props
    const { audio, playing, failed } = this.state

    if (failed) {
      return <Button
        justIcon
        {...props}
        disabled
        ><ShowIcon icon="times" {...iconProps}/>
      </Button>
    } else if (audio) {
      if (playing) {
        return <Button
          justIcon
          {...props}
          danger
          onClick={() => {
            if (audio) audio.pause()
            this.setState({ audio: null, playing: false, failed: null })
          }}
          ><ShowIcon icon="stop" {...iconProps}/>
        </Button>
      } else {
        return <Button
          justIcon
          {...props}
          disabled
          ><LoadingIndicator />
        </Button>
      }
    } else if (audioSrc || audioBase64) {
      return <Button
        justIcon
        {...props}
        onClick={() => {
          const audioElement = new Audio()
          audioElement.autoplay = true
          audioElement.addEventListener('playing', () => {
            this.setState({ playing: true, failed: null })
          })
          audioElement.addEventListener('error', (err) => {
            this.setState({ playing: false, failed: true })
            if(onError) {
              onError(err)
            } else {
              setAlertErrorMessage('Playing audio stream failed', err)
            }
          })
          audioElement.addEventListener('ended', () => {
            this.setState({ audio: null, playing: false, failed: null })
          })
          this.setState({ audio: audioElement, playing: false, failed: null })

          if (audioBase64) {
            audioElement.src = audioBase64.replace('audio/mpeg3', 'audio/mpeg')
          } else {
            downloadfileb64(audioSrc).then(b64 => {
              const { audio } = this.state
              if (audio) {
                audio.src = b64
              }
            }).catch(err => {
              this.setState({ audio: null, playing: false, failed: true })
              if(onError) {
                onError(err)
              } else {
                setAlertErrorMessage('Playing audio stream failed', err)
              }
            })
          }
        }}
        ><ShowIcon icon="play" {...iconProps}/>
      </Button>
    } else {
      return <Button
        justIcon
        {...props}
        disabled
        ><ShowIcon icon="play" {...iconProps}/>
      </Button>
    }
  }
}

export default connect(
  state => ({}),
  { setAlertErrorMessage }
)(AudioButton)
