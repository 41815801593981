import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

import tooltipStyle from 'assets/jss/material-dashboard-react/components/tooltipStyle.jsx'

/**
 * Maybe it is just because its displayed even for disabled components?
 * @param props
 * @return {JSX.Element|null}
 * @constructor
 */
function NativeTooltip({ ...props }) {
  const { classes, title, underline } = props

  if (window.Cypress) {
    return <span data-tooltip-title={title}>{props.children}</span>
  } else if (props.children) {
    return <span data-tooltip-title={title} title={title} className={classNames({
      [classes.root]: true,
      [classes.underline]: underline
    })}>{props.children}</span>
  }
  return null
}

NativeTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  underline: PropTypes.bool
}

export default withStyles(tooltipStyle)(NativeTooltip)
