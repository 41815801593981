import React from 'react'
import { NavLink } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import Text from 'components/Typography/Text.jsx'

import TestSessionProgress from './TestSessionProgress.jsx'

class SecuritySessionProgressIcon extends React.Component {

  render() {
    const { testSession, variables, withoutNavLink, classes } = this.props

    return (
      <TestSessionProgress {...(this.props)} key={`sicon_${testSession && testSession.id}_${variables && JSON.stringify(variables)}`}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'WARNING')
          const allCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.length) || 0
          const dangerCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.filter(a => a.risk === 'High' || a.risk === 'Medium').length) || 0

          

          let dataStatus = 'READY'
          let resultIcon = ''
          
          if (!isDone) {
            dataStatus = 'RUNNING'
            resultIcon = <Text warningBg bgradius iconRotation marginRight displayInline icon="circle-notch">RUNNING</Text>
          }
          if (dangerCount > 0) {
            dataStatus = 'FAILED'
            resultIcon = <Text dangerBg bgradius marginRight displayInline icon="triangle-exclamation">FAILED</Text>
          } else if (allCount > 0) {
            dataStatus = 'WARNING'
            resultIcon = <Text dangerBg bgradius marginRight displayInline icon="triangle-exclamation">FAILED</Text>
          }  

          if(withoutNavLink) {
            return <div className={classes.resultIconPositionNavLink} data-unique={`btnSecuritySessionProgressIcon_${testSessionProgress.id}`} data-status={dataStatus}>
              {resultIcon}
            </div>
          } else {
            return <NavLink tabIndex={-1} to={`/security/projects/view/${testSession.testProject.id}/results/$${testSessionProgress.id}`} data-unique={`btnSecuritySessionProgressIcon_${testSessionProgress.id}`} data-status={dataStatus}>
              {resultIcon}
            </NavLink>
          }
        }}
      </TestSessionProgress>
    )
  }
}

export default withStyles(testsessionsStyle)(SecuritySessionProgressIcon)
