import customInputStyle from './customInputStyle.jsx'

const downshipMultipleStyle = theme => ({
  ...customInputStyle(theme),
  root: {
    flexGrow: 1,
    position: 'relative',
  },

  container: {
    position: 'relative',
    flexGrow: 1,
    '& div': {
      '& div': {
        '&:before': {
          borderBottom: '0'
        },
        '&:hover': {
          borderBottom: '0',
          '&:before': {
            borderBottom: '0 !Important'
          },
        }
      }
    }
  },
  customformContor: {
    marginTop: '10px !Important',
    marginBottom: 0,
    '& label': {
      paddingBottom: 7
    }
  },

  formControl: {
    '&:before': {
      borderWidth: '3 !Important',
    },
    '&:hover': {
      '&:before': {
        borderWidth: '0 !Important',
      }
    }
  },
  
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    borderRadius: 6,
    padding: 10,
    minwidth: 260,
    color: theme.colors.common.primary,
    '& div': {
      color: theme.colors.common.primary,
      '&:hover': {
        background: `${theme.colors.dropdownListHover} !important`,
      },
      '&:active': {
        color: `${theme.colors.dropdownListActive} !important`,
        background: `${theme.colors.chipbgHover} !important`,
      }
    },
  },

  customdraggable: {
    marginTop: 43,
  },
  chip: {
    margin: `3px ${theme.spacing.unit / 3}px`,
    padding: '10px 0',
    background: theme.colors.chipbg,
    color: theme.colors.common.primary,
    fontSize: 13,
    fontWeight: 400,
    '& svg': {
      fill: theme.colors.common.primary,
    },
    '&:hover': {
      background: `${theme.colors.chipbgHover} !important`,
    },
  },
  placeholder: {
    //content: 'aaaaaa'
  },
  input: {
    position: 'absolute',
    top: -13,
    width: '100%',
    height: '44px !Important',
    paddingLeft: 25,
    fontSize: 13,
    fontWeight: 500,
    color: theme.colors.primary,
    border: `1px solid ${theme.colors.borderColor}`,
    borderRadius: 6,
    '&:hover': {
      border: `1px solid ${theme.colors.primary}`,
      '&:before': {
        borderWidth: '0 !Important',
      }
    },
    '&::placeholder':{
      fontWeight: 400,
      color: '#5e6c84',
      opacity: 1
    },

    '&:-ms-input-placeholder':{
      fontWeight: 400,
      color: '#5e6c84',
      opacity: 1
    },

    '&::-ms-input-placeholder':{
      fontWeight: 400,
      color: '#5e6c84',
      opacity: 1
    }
  },
  inputRoot: {
    flexWrap: 'wrap',
    fontSize: 12,
    
  },
 
  divider: {
    height: theme.spacing.unit * 2,
  },
})

export default downshipMultipleStyle
