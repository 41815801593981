import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FormSpy } from 'react-final-form'
import Field from 'components/Form/OptionalField'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/Button/Button'
import DropdownButton from 'components/Button/DropdownButton'
import ShowIcon from '../Icon/ShowIcon'
import Tooltip from 'components/Tooltip/Tooltip'
import {
  renderTextField,
  renderCodeArea,
  renderIntField,
  renderSelect,
  renderCheckbox,
  renderAutoSuggest,
  required,
  url,
  json,
  prettyPrintJson,
  Condition,
  parseInteger,
  composeValidators,
  CustomCodeArea
} from 'components/Form/Form'
import FileSelectorField from 'components/Form/FileSelectorField'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import { getConnector, getConnectorFromSettings } from 'actions/settings'
import _ from 'lodash'
import { capSetDescription, usedByCapabilitySet } from './Helper'
import Text from '../Typography/Text'

const VIEWPORT_SIZE_SD = {
  width: 640,
  height: 480
}
const VIEWPORT_SIZE_HD = {
  width: 1280,
  height: 720
}
const VIEWPORT_SIZE_FULL_HD = {
  width: 1920,
  height: 1080
}
const VIEWPORT_SIZE_QUAD_HD = {
  width: 2560,
  height: 1440
}

const VIEWPORT_SIZE_ULTRA_HD = {
  width: 3840,
  height: 2160
}

export function webdriverCaps2Form(caps, allconnectors) {
  const connector = getConnectorFromSettings({ allconnectors }, 'webdriverio')
  const viewportSize = JSON.parse((caps.find(c => c.name === 'WEBDRIVERIO_VIEWPORT_SIZE') || { jsonValue: JSON.stringify({ width: 640, height: 480 }, null, 2) }).jsonValue)
  const form = {
    webdriver: {
      url: (caps.find(c => c.name === 'WEBDRIVERIO_URL') || { stringValue: '' }).stringValue,
      app: (caps.find(c => c.name === 'WEBDRIVERIO_APP') || { stringValue: '' }).stringValue,
      appPackage: (caps.find(c => c.name === 'WEBDRIVERIO_APPPACKAGE') || { stringValue: '' }).stringValue,
      appActivity: (caps.find(c => c.name === 'WEBDRIVERIO_APPACTIVITY') || { stringValue: '' }).stringValue,
      appNoReset: (caps.find(c => c.name === 'WEBDRIVERIO_APPNORESET') || { booleanValue: false }).booleanValue,
      appPrefix: (caps.find(c => c.name === 'WEBDRIVERIO_USE_APPIUM_PREFIX') || { stringValue: '' }).stringValue,
      profile: (caps.find(c => c.name === 'WEBDRIVERIO_PROFILE') || { stringValue: 'other' }).stringValue || 'other',
      openBot: (caps.find(c => c.name === 'WEBDRIVERIO_OPENBOT') || { stringValue: '' }).stringValue,
      openBotPause: (caps.find(c => c.name === 'WEBDRIVERIO_OPENBOTPAUSE') || { intValue: null }).intValue,
      inputElement: (caps.find(c => c.name === 'WEBDRIVERIO_INPUT_ELEMENT') || { stringValue: '' }).stringValue,
      inputElementWait: !(caps.find(c => c.name === 'WEBDRIVERIO_INPUT_ELEMENT_SKIP_WAITFORDISPLAYED') || { booleanValue: false }).booleanValue,
      inputElementVisibleTimeout: (caps.find(c => c.name === 'WEBDRIVERIO_INPUT_ELEMENT_VISIBLE_TIMEOUT') || { intValue: 10000 }).intValue,
      inputElementSendButton: (caps.find(c => c.name === 'WEBDRIVERIO_INPUT_ELEMENT_SENDBUTTON') || { stringValue: '' }).stringValue,
      inputPause: (caps.find(c => c.name === 'WEBDRIVERIO_INPUTPAUSE') || { intValue: null }).intValue,
      outputElement: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT') || { stringValue: '' }).stringValue,
      outputElementText: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_TEXT') || { stringValue: '' }).stringValue,
      outputElementButtons: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_BUTTONS') || { stringValue: '' }).stringValue,
      outputElementButtonsNested: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_BUTTONS_NESTED') || { booleanValue: true }).booleanValue,
      outputElementCard: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD') || { stringValue: '' }).stringValue,
      outputElementCardKeyAttr: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_KEY_ATTRIBUTE') || { stringValue: '' }).stringValue,
      outputElementCardText: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_TEXT') || { stringValue: '' }).stringValue,
      outputElementCardSubtext: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_SUBTEXT') || { stringValue: '' }).stringValue,
      outputElementCardMedia: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_MEDIA') || { stringValue: '' }).stringValue,
      outputElementCardButtons: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_BUTTONS') || { stringValue: '' }).stringValue,
      outputElementFormsOptionText: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_FORMS_OPTIONTEXT') || { booleanValue: false }).booleanValue,
      ignoreUpfrontMessages: (caps.find(c => c.name === 'WEBDRIVERIO_IGNOREUPFRONTMESSAGES') || { booleanValue: false }).booleanValue,
      ignoreWelcomeMessages: (caps.find(c => c.name === 'WEBDRIVERIO_IGNOREWELCOMEMESSAGES') || { intValue: null }).intValue,
      outputElementHash: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH') || { stringValue: 'TEXT' }).stringValue,
      outputElementHashAttr: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_ATTRIBUTE') || { stringValue: null }).stringValue,
      outputElementHashSel: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_SELECTOR') || { stringValue: null }).stringValue,
      screenshots: (caps.find(c => c.name === 'WEBDRIVERIO_SCREENSHOTS') || { stringValue: 'none' }).stringValue,
      dumpPageSource: (caps.find(c => c.name === 'WEBDRIVERIO_OUTPUT_ELEMENT_DEBUG_HTML') || { booleanValue: false }).booleanValue,
      additionalCapabilities: (caps.find(c => c.name === 'WEBDRIVERIO_ADDITIONAL_CAPABILITIES') || { jsonValue: '{}' }).jsonValue,
      contact: (caps.find(c => c.name === 'WEBDRIVERIO_CONTACT') || { stringValue: '' }).stringValue,
      language: (caps.find(c => c.name === 'WEBDRIVERIO_LANGUAGE') || { stringValue: '' }).stringValue,
      viewportSizeWidth: viewportSize.width,
      viewportSizeHeight: viewportSize.height
    }
  }

  const capProfileAppium = connector && connector.capabilities.find(c => c.name === 'WEBDRIVERIO_PROFILE_APPIUM')
  if (form.webdriver.profile && form.webdriver.profile !== 'other') {
    if (capProfileAppium && capProfileAppium.choices && capProfileAppium.choices.find(c => c.key === form.webdriver.profile)) {
      form.webdriver.automation = 'appium'
    } else {
      form.webdriver.automation = 'selenium'
    }
  } else {
    form.webdriver.automation = form.webdriver.appPackage ? 'appium' : 'selenium'
  }

  const naviCap = caps.find(c => c.name === 'WEBDRIVERIO_INPUT_NAVIGATION_BUTTONS')
  form.webdriver.inputNavigationButtons = []
  if (naviCap && naviCap.jsonValue) {
    try {
      form.webdriver.inputNavigationButtons = JSON.parse(naviCap.jsonValue)
    } catch (err) { }
  }

  if (form.webdriver.outputElementHash === 'HASH') {
    if (form.webdriver.outputElementHashAttr) {
      form.webdriver.outputElementHash = 'HASH_ATTR'
    } else {
      form.webdriver.outputElementHash = 'HASH_SEL'
    }
  }
  return form
}

const capNamesMap = {
  'webdriver.url': 'WEBDRIVERIO_URL',
  'webdriver.browser': 'WEBDRIVERIO_BROWSER',
  'webdriver.browserVersion': 'WEBDRIVERIO_BROWSER_VERSION',
  'webdriver.platform': 'WEBDRIVERIO_PLATFORM',
  'webdriver.platformVersion': 'WEBDRIVERIO_PLATFORM_VERSION',
  'webdriver.deviceName': 'WEBDRIVERIO_DEVICE_NAME',
  'webdriver.deviceOrientation': 'WEBDRIVERIO_DEVICE_ORIENTATION',
  'webdriver.appPackage': 'WEBDRIVERIO_APP_PACKAGE',
  'webdriver.appActivity': 'WEBDRIVERIO_APP_ACTIVITY',
  'webdriver.appWaitActivity': 'WEBDRIVERIO_APP_WAIT_ACTIVITY',
  'webdriver.appWaitPackage': 'WEBDRIVERIO_APP_WAIT_PACKAGE',
  'webdriver.appWaitDuration': 'WEBDRIVERIO_APP_WAIT_DURATION',
  'webdriver.app': 'WEBDRIVERIO_APP',
  'webdriver.appiumVersion': 'WEBDRIVERIO_APPIUM_VERSION',
  'webdriver.automationName': 'WEBDRIVERIO_AUTOMATION_NAME',
  'webdriver.automation': 'WEBDRIVERIO_AUTOMATION',
  'webdriver.profile': 'WEBDRIVERIO_PROFILE',
  'webdriver.inputElementWait': 'WEBDRIVERIO_INPUT_ELEMENT_WAITFORDISPLAYED',
  'webdriver.inputElementVisibleTimeout': 'WEBDRIVERIO_INPUT_ELEMENT_VISIBLE_TIMEOUT',
  'webdriver.inputElementSendButton': 'WEBDRIVERIO_INPUT_ELEMENT_SENDBUTTON',
  'webdriver.inputPause': 'WEBDRIVERIO_INPUTPAUSE',
  'webdriver.outputElement': 'WEBDRIVERIO_OUTPUT_ELEMENT',
  'webdriver.outputElementText': 'WEBDRIVERIO_OUTPUT_ELEMENT_TEXT',
  'webdriver.outputElementButtons': 'WEBDRIVERIO_OUTPUT_ELEMENT_BUTTONS',
  'webdriver.outputElementButtonsNested': 'WEBDRIVERIO_OUTPUT_ELEMENT_BUTTONS_NESTED',
  'webdriver.outputElementCard': 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD',
  'webdriver.outputElementCardKeyAttr': 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_KEY_ATTRIBUTE',
  'webdriver.outputElementCardText': 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_TEXT',
  'webdriver.outputElementCardSubtext': 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_SUBTEXT',
  'webdriver.outputElementCardMedia': 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_MEDIA',
  'webdriver.outputElementCardButtons': 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_BUTTONS',
  'webdriver.outputElementFormsOptionText': 'WEBDRIVERIO_OUTPUT_ELEMENT_FORMS_OPTIONTEXT',
  'webdriver.ignoreUpfrontMessages': 'WEBDRIVERIO_IGNOREUPFRONTMESSAGES',
  'webdriver.ignoreWelcomeMessages': 'WEBDRIVERIO_IGNOREWELCOMEMESSAGES',
  'webdriver.outputElementHash': 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH',
  'webdriver.outputElementHashAttr': 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_ATTRIBUTE',
  'webdriver.outputElementHashSel': 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_SELECTOR',
  'webdriver.screenshots': 'WEBDRIVERIO_SCREENSHOTS',
  'webdriver.dumpPageSource': 'WEBDRIVERIO_OUTPUT_ELEMENT_DEBUG_HTML',
  'webdriver.additionalCapabilities': 'WEBDRIVERIO_ADDITIONAL_CAPABILITIES',
  'webdriver.contact': 'WEBDRIVERIO_CONTACT',
  'webdriver.language': 'WEBDRIVERIO_LANGUAGE',
  'webdriver.viewportSize': 'WEBDRIVERIO_VIEWPORT_SIZE',
}

export function webdriverForm2caps(values) {
  let caps = [
    { name: 'CONTAINERMODE', type: 'STRING', stringValue: 'webdriverio' },
    {
      name: 'WEBDRIVERIO_SCREENSHOTS',
      type: 'STRING',
      stringValue: values.webdriver.screenshots
    },
    {
      name: 'WEBDRIVERIO_OUTPUT_ELEMENT_DEBUG_HTML',
      type: 'BOOLEAN',
      booleanValue: !!values.webdriver.dumpPageSource
    },
    {
      name: 'WEBDRIVERIO_VIEWPORT_SIZE',
      type: 'JSON',
      jsonValue: JSON.stringify({ width: values.webdriver.viewportSizeWidth, height: values.webdriver.viewportSizeHeight }, null, 2)
    }
  ]

  if (values.webdriver.automation === 'appium') {
    caps.push({
      name: 'WEBDRIVERIO_APP',
      type: 'STRING',
      stringValue: values.webdriver.app
    })
    caps.push({
      name: 'WEBDRIVERIO_APPPACKAGE',
      type: 'STRING',
      stringValue: values.webdriver.appPackage
    })
    caps.push({
      name: 'WEBDRIVERIO_APPACTIVITY',
      type: 'STRING',
      stringValue: values.webdriver.appActivity
    })
    caps.push({
      name: 'WEBDRIVERIO_APPNORESET',
      type: 'BOOLEAN',
      booleanValue: !!values.webdriver.appNoReset
    })
    caps.push({
      name: 'WEBDRIVERIO_USE_APPIUM_PREFIX',
      type: 'STRING',
      stringValue: values.webdriver.appPrefix
    })
  } else {
    caps.push({
      name: 'WEBDRIVERIO_URL',
      type: 'STRING',
      stringValue: values.webdriver.url
    })
  }

  if (values.webdriver.profile === 'other') {
    caps.push({
      name: 'WEBDRIVERIO_PROFILE',
      type: 'STRING',
      stringValue: ''
    })
    if (values.webdriver.openBot) {
      caps.push({
        name: 'WEBDRIVERIO_OPENBOT',
        type: 'JS',
        stringValue: values.webdriver.openBot
      })
    } else {
      caps.push({
        name: 'WEBDRIVERIO_OPENBOT',
        type: 'JS',
        stringValue: ''
      })
    }

    if (values.webdriver.outputElementHash === 'HASH_ATTR') {
      caps = caps.concat([
        {
          name: 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH',
          type: 'STRING',
          stringValue: 'HASH'
        },
        {
          name: 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_ATTRIBUTE',
          type: 'STRING',
          stringValue: values.webdriver.outputElementHashAttr
        },
        {
          name: 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_SELECTOR',
          type: 'STRING',
          stringValue: null
        }
      ])
    } else if (values.webdriver.outputElementHash === 'HASH_SEL') {
      caps = caps.concat([
        {
          name: 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH',
          type: 'STRING',
          stringValue: 'HASH'
        },
        {
          name: 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_ATTRIBUTE',
          type: 'STRING',
          stringValue: null
        },
        {
          name: 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_SELECTOR',
          type: 'STRING',
          stringValue: values.webdriver.outputElementHashSel
        }
      ])
    } else {
      caps = caps.concat([
        {
          name: 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH',
          type: 'STRING',
          stringValue: values.webdriver.outputElementHash
        },
        {
          name: 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_ATTRIBUTE',
          type: 'STRING',
          stringValue: null
        },
        {
          name: 'WEBDRIVERIO_OUTPUT_ELEMENT_HASH_SELECTOR',
          type: 'STRING',
          stringValue: null
        }
      ])
    }

    if (values.webdriver.inputNavigationButtons && values.webdriver.inputNavigationButtons.length > 0) {
      caps.push({
        name: 'WEBDRIVERIO_INPUT_NAVIGATION_BUTTONS',
        type: 'JSON',
        jsonValue: JSON.stringify(values.webdriver.inputNavigationButtons)
      })
    } else {
      caps.push({
        name: 'WEBDRIVERIO_INPUT_NAVIGATION_BUTTONS',
        type: 'JSON',
        jsonValue: ''
      })
    }
    if (values.webdriver.openBotPause) {
      caps.push({
        name: 'WEBDRIVERIO_OPENBOTPAUSE',
        type: 'INT',
        intValue: values.webdriver.openBotPause
      })
    } else {
      caps.push({
        name: 'WEBDRIVERIO_OPENBOTPAUSE',
        type: 'INT',
        intValue: null
      })
    }
    if (values.webdriver.inputPause) {
      caps.push({
        name: 'WEBDRIVERIO_INPUTPAUSE',
        type: 'INT',
        intValue: values.webdriver.inputPause
      })
    } else {
      caps.push({
        name: 'WEBDRIVERIO_INPUTPAUSE',
        type: 'INT',
        intValue: null
      })
    }
    caps = caps.concat([
      {
        name: 'WEBDRIVERIO_INPUT_ELEMENT',
        type: 'STRING',
        stringValue: values.webdriver.inputElement
      },
      {
        name: 'WEBDRIVERIO_INPUT_ELEMENT_SKIP_WAITFORDISPLAYED',
        type: 'BOOLEAN',
        booleanValue: !values.webdriver.inputElementWait
      },
      {
        name: 'WEBDRIVERIO_INPUT_ELEMENT_VISIBLE_TIMEOUT',
        type: 'INT',
        intValue: values.webdriver.inputElementVisibleTimeout
      },
      {
        name: 'WEBDRIVERIO_INPUT_ELEMENT_SENDBUTTON',
        type: 'STRING',
        stringValue: values.webdriver.inputElementSendButton
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT',
        type: 'STRING',
        stringValue: values.webdriver.outputElement
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_BUTTONS',
        type: 'STRING',
        stringValue: values.webdriver.outputElementButtons
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_BUTTONS_NESTED',
        type: 'BOOLEAN',
        booleanValue: !!values.webdriver.outputElementButtonsNested
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_TEXT',
        type: 'STRING',
        stringValue: values.webdriver.outputElementText
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD',
        type: 'STRING',
        stringValue: values.webdriver.outputElementCard
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_KEY_ATTRIBUTE',
        type: 'STRING',
        stringValue: values.webdriver.outputElementCardKeyAttr
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_TEXT',
        type: 'STRING',
        stringValue: values.webdriver.outputElementCardText
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_SUBTEXT',
        type: 'STRING',
        stringValue: values.webdriver.outputElementCardSubtext
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_MEDIA',
        type: 'STRING',
        stringValue: values.webdriver.outputElementCardMedia
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_CARD_BUTTONS',
        type: 'STRING',
        stringValue: values.webdriver.outputElementCardButtons
      },
      {
        name: 'WEBDRIVERIO_OUTPUT_ELEMENT_FORMS_OPTIONTEXT',
        type: 'BOOLEAN',
        booleanValue: !!values.webdriver.outputElementFormsOptionText
      },
      {
        name: 'WEBDRIVERIO_IGNOREUPFRONTMESSAGES',
        type: 'BOOLEAN',
        booleanValue: !!values.webdriver.ignoreUpfrontMessages
      },
      {
        name: 'WEBDRIVERIO_IGNOREWELCOMEMESSAGES',
        type: 'INT',
        intValue: values.webdriver.ignoreWelcomeMessages || 0
      }
    ])
  } else {
    caps.push({
      name: 'WEBDRIVERIO_PROFILE',
      type: 'STRING',
      stringValue: values.webdriver.profile
    })
  }

  caps.push({
    name: 'WEBDRIVERIO_ADDITIONAL_CAPABILITIES',
    type: 'JSON',
    jsonValue: values.webdriver.additionalCapabilities
  })
  caps.push({
    name: 'WEBDRIVERIO_CONTACT',
    type: 'STRING',
    stringValue: values.webdriver.contact
  })
  caps.push({
    name: 'WEBDRIVERIO_LANGUAGE',
    type: 'STRING',
    stringValue: values.webdriver.language
  })

  return caps
}

const OPENBOT = `
module.exports = async (container, browser) => {
  const btnCc = await browser.$('#onetrust-accept-btn-handler')
  await btnCc.waitForClickable({ timeout: 5000 })
  await btnCc.click()
  const startChat = await browser.$('.chat-start-icon')
  await startChat.waitForClickable({ timeout: 5000 })
  await startChat.click()
  // const html = await browser.getPageSource()
  // console.log('Page Source', html)
}
`.trim()

class WebdriverEditUnconnected extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openBotExpanded: false,
      inputExpanded: false,
      outputExpanded: false,
      screenshotsExpanded: false,
      paramsExpanded: false,
      showVars: null,
      sampleCodeOpen: false,
      sampleCode: '',
      sampleCodeVars: ''
    }
  }

  render() {
    const { advanced, disabled, getConnector, capSetCapNames } = this.props
    const { openBotExpanded, inputExpanded, outputExpanded, screenshotsExpanded, paramsExpanded } = this.state
    const { sampleCode, sampleCodeOpen, sampleCodeVars } = this.state

    const connector = getConnector('webdriverio')
    const capProfileSelenium = connector && connector.capabilities.find(c => c.name === 'WEBDRIVERIO_PROFILE_SELENIUM')
    const capProfileAppium = connector && connector.capabilities.find(c => c.name === 'WEBDRIVERIO_PROFILE_APPIUM')
    const capLanguage = connector && connector.capabilities.find(c => c.name === 'WEBDRIVERIO_LANGUAGE')

    return (<FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => (
      <GridContainer>
        <GridItem xs={12}>
          <Field
            name="webdriver.automation"
            component={renderSelect}
            label="Automation Technology"
            validate={required}
            data-unique="selWebdriverEditAutomation"
            items={[
              { key: 'selenium', label: 'Use Selenium (for Chatbots published on Desktop or Mobile Websites)' },
              { key: 'appium', label: 'Use Appium (for Chatbots embedded into a Smartphone Apps)' },
            ]}
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.automation']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.automation'])}
          />
        </GridItem>
        {values.webdriver.automation === 'selenium' && <React.Fragment>
          <GridItem xs={12} >
            <Field
              name="webdriver.url"
              component={renderTextField}
              label="Start Url"
              validate={composeValidators(required, url)}
              data-unique="txtWebdriverUrl"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.url']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.url'])}
            />
          </GridItem>

          <GridItem xs={12} >

            <React.Fragment>
              <GridContainer>
                <GridItem xs={6}>
                  <Text dropdownLabel>Viewport Size</Text>
                  <DropdownButton
                    fullWidthLeft
                    noMargin
                    customSelectType
                    data-unique="ddbtnWebdriverViewport"
                    items={[
                      {
                        id: 'SD',
                        name: 'SD (640 x 480)',
                        dataUnique: 'btnWebdriverEditViewportSizeSD',
                        onClick: () => {
                          change('webdriver.viewportSizeWidth', VIEWPORT_SIZE_SD.width)
                          change('webdriver.viewportSizeHeight', VIEWPORT_SIZE_SD.height)
                        }
                      },
                      {
                        id: 'HD',
                        name: 'HD (1280 x 720)',
                        dataUnique: 'btnWebdriverEditViewportSizeHD',
                        onClick: () => {
                          change('webdriver.viewportSizeWidth', VIEWPORT_SIZE_HD.width)
                          change('webdriver.viewportSizeHeight', VIEWPORT_SIZE_HD.height)
                        }
                      },
                      {
                        id: 'FHD',
                        name: 'Full HD (1920 x 1080)',
                        dataUnique: 'btnWebdriverEditViewportSizeFHD',
                        onClick: () => {
                          change('webdriver.viewportSizeWidth', VIEWPORT_SIZE_FULL_HD.width)
                          change('webdriver.viewportSizeHeight', VIEWPORT_SIZE_FULL_HD.height)
                        }
                      },
                      {
                        id: 'Quad_HD',
                        name: 'Quad HD (2560 x 1440)',
                        dataUnique: 'btnWebdriverEditViewportSizeQuadHD',
                        onClick: () => {
                          change('webdriver.viewportSizeWidth', VIEWPORT_SIZE_QUAD_HD.width)
                          change('webdriver.viewportSizeHeight', VIEWPORT_SIZE_QUAD_HD.height)
                        }
                      },
                      {
                        id: 'Ultra_HD',
                        name: 'Ultra HD (3840 x 2160)',
                        dataUnique: 'btnWebdriverEditViewportSizeUltraHD',
                        onClick: () => {
                          change('webdriver.viewportSizeWidth', VIEWPORT_SIZE_ULTRA_HD.width)
                          change('webdriver.viewportSizeHeight', VIEWPORT_SIZE_ULTRA_HD.height)
                        }
                      },
                    ]}
                  >
                    <ShowIcon icon="paste" /> Insert from standard resolutions
                  </DropdownButton>
                </GridItem>
                <GridItem xs={3}>
                  <Field
                    name="webdriver.viewportSizeWidth"
                    component={renderTextField}
                    label="Width"
                    data-unique="txtWebdriverViewportSizeWidth"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.viewportSize']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.viewportSize'])}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <Field
                    name="webdriver.viewportSizeHeight"
                    component={renderTextField}
                    label="Height"
                    data-unique="txtWebdriverViewportSizeHeight"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.viewportSize']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.viewportSize'])}
                  />
                </GridItem>
              </GridContainer>
            </React.Fragment>
          </GridItem>


        </React.Fragment>}
        {values.webdriver.automation === 'appium' && <React.Fragment>
          <GridItem xs={12} sm={4}>
            <Field
              name="webdriver.app"
              component={renderTextField}
              label="Location of the App"
              data-unique="txtWebdriverApp"
              disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.app']) || disabled}
              helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.app'], 'Depends on the device cloud provider. Can be a public accessible Url or a provider-specific app identifier. Can be left empty for accessing a pre-installed app.')}
            />
          </GridItem>
          <Condition when="webdriver.profile" is="other">
            <GridItem xs={12} sm={4}>
              <Field
                name="webdriver.appPackage"
                component={renderTextField}
                label="App Package Name"
                validate={required}
                data-unique="txtWebdriverAppPackage"
                disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.profile']) || disabled}
                helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.profile'])}
              />
            </GridItem>
          </Condition>
          <Condition when="webdriver.profile" is="other">
            <GridItem xs={12} sm={4}>
              <Field
                name="webdriver.appActivity"
                component={renderTextField}
                label="App Activity Name"
                data-unique="txtWebdriverAppActivity"
                disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.appActivity']) || disabled}
                helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.appActivity'])}
              />
            </GridItem>
          </Condition>
          {advanced && <React.Fragment>
            <GridItem xs={12} sm={4}>
              <Field
                name="webdriver.appNoReset"
                component={renderCheckbox}
                type="checkbox"
                label="Skip Automatic App State Reset"
                data-unique="txtWebdriverAppNoReset"
                disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.appNoReset']) || disabled}
                helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.appNoReset'])}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="webdriver.appPrefix"
                component={renderTextField}
                label="Appium Capability Prefix"
                data-unique="txtWebdriverAppPrefix"
                disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.appPrefix']) || disabled}
                helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.appPrefix'], 'Depending on the Selenium infrastructure an "appium:" prefix for the Appium-specific capabilities might be required')}
              />
            </GridItem>
          </React.Fragment>}
          <GridItem xs={12}></GridItem>
        </React.Fragment>}
        <GridItem xs={12}>
          <Field
            name="webdriver.profile"
            component={renderSelect}
            label="Webdriver Script"
            data-unique="selWebdriverEditProfile"
            validate={required}
            items={[
              ...((values.webdriver.automation === 'selenium' && capProfileSelenium && capProfileSelenium.choices.map(u => {
                return { key: u.key, label: u.name }
              })) || []),
              ...((values.webdriver.automation === 'appium' && capProfileAppium && capProfileAppium.choices.map(u => {
                return { key: u.key, label: u.name }
              })) || []),
              { key: 'other', label: 'Specify Custom Selenium Scripts and Selectors' }
            ]}
            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.profile']) || disabled}
            helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.profile'])}
          />
        </GridItem>
        <GridItem xs={12} sm={6} />
        <GridItem xs={12}>
          {advanced && <Condition when="webdriver.profile" is="other">
            <ExpansionPanel expanded={openBotExpanded} onChange={() => this.setState({ openBotExpanded: !openBotExpanded })} data-unique="pnlWebdriverOpenBot">
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Chatbot Widget Navigation Configuration
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <GridContainer nounset>
                  <GridItem xs={12}>
                    <Field
                      name="webdriver.inputNavigationButtons"
                      component={renderAutoSuggest}
                      label="Navigation Button Selectors"
                      data-unique="asWebdriverEditInputNavigationButtons"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.inputNavigationButtons']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.inputNavigationButtons'], 'A list of Webdriver selectors for elements which will be clicked one after the other to navigate to the actual chatbot widget. Prefixing an element with "iframe:" will switch context to the given iFrame.')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <FileSelectorField
                      name="webdriver.openBot"
                      change={change}
                      label="OpenBot Hook (Javascript File)"
                      data-unique="codeWebdriverEditOpenBotHook"
                      endAdornment={<>
                        <Tooltip title="Show Sample Code">
                          <Button justIcon dense data-unique="codeWebdriverEditOpenBotHook_Code" onClick={() => this.setState({ sampleCodeOpen: true, sampleCode: OPENBOT, sampleCodeVars: 'Variables available: container, browser' })}>
                            <ShowIcon icon="file-alt" />
                          </Button>
                        </Tooltip>
                      </>}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.openBot']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.openBot'], '')}
                      initialPath="resources/scripts"
                      extensionFilter={['.js']}
                      restrictPath
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdriver.openBotPause"
                      component={renderIntField}
                      label="Pause after bot opened"
                      parse={parseInteger}
                      data-unique="intWebdriverEditOpenBotPause"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.openBotPause']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.openBotPause'], 'Pause execution for given amount of milliseconds after the chatbot is visible (maybe waiting for initialization).')}
                    />
                  </GridItem>

                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={inputExpanded} onChange={() => this.setState({ inputExpanded: !inputExpanded })} data-unique="pnlWebdriverInput">
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                Chatbot Widget User Input Configuration
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <GridContainer nounset>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdriver.inputElement"
                      component={renderTextField}
                      label="Input Element Selector"
                      data-unique="txtWebdriverEditInputElement"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.openBotPause']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.openBotPause'], 'Webdriver selector for the input text field')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <Field
                      name="webdriver.inputElementWait"
                      component={renderCheckbox}
                      label="Check Visibility"
                      type="checkbox"
                      data-unique="txtWebdriverEditInputElementSkipWait"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.inputElementWait']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.inputElementWait'], 'Wait for the input element to become visible before starting tests')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <Field
                      name="webdriver.inputElementVisibleTimeout"
                      component={renderIntField}
                      label="Visibility Timeout"
                      parse={parseInteger}
                      data-unique="intWebdriverEditInputElementVisibleTimeout"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.inputElementVisibleTimeout']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.inputElementVisibleTimeout'], 'If input element not visible within this amount of milliseconds, test fails')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdriver.inputElementSendButton"
                      component={renderTextField}
                      label="Send Button Selector"
                      data-unique="txtWebdriverEditInputElementSendButton"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.inputElementSendButton']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.inputElementSendButton'], 'Webdriver selector for the send button')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="webdriver.inputPause"
                      component={renderIntField}
                      label="Pause after User Input"
                      parse={parseInteger}
                      data-unique="intWebdriverEditInputPause"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.inputPause']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.inputPause'], 'In some situations it might be required after a user input to wait for a short amount of time to wait for the user interface. In this case you can use this capability to specify an amount of milliseconds to pause execution shortly.')}
                    />
                  </GridItem>
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={outputExpanded} onChange={() => this.setState({ outputExpanded: !outputExpanded })} data-unique="pnlWebdriverOutput">
              <ExpansionPanelSummary indicator={_.isNil(values.webdriver.outputElement) || values.webdriver.outputElement === '' ? 'error' : null} expandIcon={<ExpandMoreIcon />}>
                Chatbot Widget Bot Response Configuration
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <GridContainer nounset>
                  <GridItem xs={12}>
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="webdriver.outputElement"
                      component={renderTextField}
                      label="Chatbot Output Element Container Selector"
                      validate={required}
                      data-unique="txtWebdriverEditOutputElement"
                      showIndicator={_.isNil(values.webdriver.outputElement) || values.webdriver.outputElement === ''}
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElement']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElement'], 'Webdriver selector for the chatbot output element container')}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="webdriver.outputElementText"
                      component={renderTextField}
                      label="Chatbot Text Element Selector"
                      data-unique="txtWebdriverEditOutputElementText"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementText']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementText'], 'Webdriver selector for the chatbot text element, relative to the output element container')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <Field
                      name="webdriver.outputElementButtons"
                      component={renderTextField}
                      label="Chatbot Quick-Reply Element Selector"
                      data-unique="txtWebdriverEditOutputElementButtons"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementButtons']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementButtons'], 'Webdriver selector for the chatbot Quick-Reply container')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="webdriver.outputElementButtonsNested"
                      component={renderCheckbox}
                      label="Quick-Replies are relative to the output element container"
                      type="checkbox"
                      data-unique="chkWebdriverEditOutputElementButtonsNested"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementButtonsNested']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementButtonsNested'])}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <Field
                      name="webdriver.outputElementCard"
                      component={renderTextField}
                      label="Chatbot Card Element Selector"
                      data-unique="txtWebdriverEditOutputElementCard"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementCard']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementCard'], 'Webdriver selector for the chatbot card container')}
                    />
                  </GridItem>
                  <Condition when="webdriver.outputElementCard">
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="webdriver.outputElementCardKeyAttr"
                        component={renderTextField}
                        label="Card Key Attribute"
                        data-unique="txtWebdriverEditOutputElementCardKeyAttr"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementCardKeyAttr']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementCardKeyAttr'])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="webdriver.outputElementCardText"
                        component={renderTextField}
                        label="Card Text Selector"
                        data-unique="txtWebdriverEditOutputElementCardText"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementCardText']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementCardText'])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="webdriver.outputElementCardSubtext"
                        component={renderTextField}
                        label="Card Subtext Selector"
                        data-unique="txtWebdriverEditOutputElementCardSubtext"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementCardSubtext']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementCardSubtext'])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="webdriver.outputElementCardMedia"
                        component={renderTextField}
                        label="Card Media Selector"
                        data-unique="txtWebdriverEditOutputElementCardMedia"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementCardMedia']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementCardMedia'])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="webdriver.outputElementCardButtons"
                        component={renderTextField}
                        label="Card Button Selector"
                        data-unique="txtWebdriverEditOutputElementCardButtons"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementCardButtons']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementCardButtons'])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="webdriver.outputElementFormsOptionText"
                        component={renderCheckbox}
                        label="Use Visible Text for Dropdown List"
                        type="checkbox"
                        data-unique="chkWebdriverEditOutputElementFormsOptionText"
                        disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementFormsOptionText']) || disabled}
                        helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementFormsOptionText'])}
                      />
                    </GridItem>
                  </Condition>
                  <GridItem xs={12}></GridItem>
                  <GridItem xs={12} sm={4}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <Field
                          name="webdriver.outputElementHash"
                          component={renderSelect}
                          label="Chatbot Response Identificator"
                          validate={required}
                          data-unique="selWebdriverEditOutputElementHash"
                          items={[
                            { key: 'ELEMENTID', label: 'Webdriver element identifier' },
                            { key: 'INDEX', label: 'Position in the output DOM' },
                            { key: 'HASH_ATTR', label: 'Get identifier from HTML attribute' },
                            { key: 'HASH_SEL', label: 'Get identifier from HTML output' },
                            { key: 'TEXT', label: 'Use response text as identifier' },
                          ]}
                          disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementHash']) || disabled}
                          helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementHash'], 'The algorithm used to calculate a unique identifier for an identified output element. This is needed for not processing a bot response multiple times')}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Condition when="webdriver.outputElementHash" is="HASH_SEL">
                          <Field
                            name="webdriver.outputElementHashSel"
                            component={renderTextField}
                            label="Relative Webdriver selector for getting identifier"
                            validate={required}
                            data-unique="txtWebdriverEditOutputElementHashSel"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementHashSel']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementHashSel'])}
                          />
                        </Condition>
                        <Condition when="webdriver.outputElementHash" is="HASH_ATTR">
                          <Field
                            name="webdriver.outputElementHashAttr"
                            component={renderTextField}
                            label="HTML Attribute for getting identifier"
                            validate={required}
                            data-unique="txtWebdriverEditOutputElementHashAttr"
                            disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.outputElementHashAttr']) || disabled}
                            helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.outputElementHashAttr'])}
                          />
                        </Condition>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="webdriver.ignoreUpfrontMessages"
                      component={renderCheckbox}
                      label="Ignore Bot Messages before first input"
                      type="checkbox"
                      data-unique="chkWebdriverEditIgnoreUpfrontMessages"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.ignoreUpfrontMessages']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.ignoreUpfrontMessages'], 'If active, all chatbot responses received before first message is sent are ignored. This is useful for ignoring welcome messages and other things sent upfront from the chatbot (usage instructions, welcome back, ...)')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="webdriver.ignoreWelcomeMessages"
                      component={renderIntField}
                      label="Ignore Bot Welcome Messages"
                      parse={parseInteger}
                      data-unique="txtWebdriverEditIgnoreWelcomeMessages"
                      disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.ignoreWelcomeMessages']) || disabled}
                      helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.ignoreWelcomeMessages'], 'Ignore a fixed number of welcome messages received from the chatbot.')}
                    />
                  </GridItem>
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Condition>}
          {advanced && <ExpansionPanel expanded={screenshotsExpanded} onChange={() => this.setState({ screenshotsExpanded: !screenshotsExpanded })} data-unique="pnlWebdriverScreenshots">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Screenshot/HTML Configuration
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="webdriver.screenshots"
                    component={renderSelect}
                    label="Save Browser Screenshots"
                    data-unique="selWebdriverEditScreenshots"
                    items={[
                      { key: 'none', label: 'No Screenshots' },
                      { key: 'always', label: 'Always (after sending or receiving from Chatbot)' },
                      { key: 'onbotsays', label: 'On Bot Message (after receiving from Chatbot)' },
                      { key: 'onstop', label: 'On End Session' },
                    ]}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.screenshots']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.screenshots'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="webdriver.dumpPageSource"
                    component={renderCheckbox}
                    label="Show Page Source (as HTML/XHTML/XML)"
                    type="checkbox"
                    data-unique="chkWebdriverEditDumpPageSource"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.dumpPageSource']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.dumpPageSource'], 'If active, the current page source will be shown in Botium for each conversation step')}
                  />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>}
          {advanced && <ExpansionPanel expanded={paramsExpanded} onChange={() => this.setState({ paramsExpanded: !paramsExpanded })} data-unique="pnlWebdriverParams">
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Selenium Script Parameters
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer nounset>
                <GridItem xs={12} sm={4}>
                  <Field
                    name="webdriver.contact"
                    component={renderTextField}
                    label="Whatsapp Contact Name"
                    data-unique="txtWebdriverEditContact"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.contact']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.contact'])}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Field
                    name="webdriver.language"
                    component={renderSelect}
                    label="Whatsapp Language"
                    data-unique="selWebdriverEditContact"
                    items={capLanguage && capLanguage.choices.map(u => {
                      return { key: u.key, label: u.name }
                    })}
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.language']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.language'])}
                  />
                </GridItem>
                <GridItem xs={12}></GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    className="CapabilitiesShort"
                    name="webdriver.additionalCapabilities"
                    component={renderCodeArea}
                    options={{ mode: 'application/json' }}
                    label="Additional Selenium/Appium Desired Capabilities (as JSON)"
                    codeFormat={prettyPrintJson}
                    validate={json}
                    data-unique="codeWebdriverEditAdditionalCapabilities"
                    disabled={usedByCapabilitySet(capSetCapNames, capNamesMap['webdriver.additionalCapabilities']) || disabled}
                    helperText={capSetDescription(capSetCapNames, capNamesMap['webdriver.additionalCapabilities'])}
                  />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>}
          <ConfirmationDialog
            cancelText="Close"
            open={!!sampleCodeOpen}
            onCancel={() => this.setState({ sampleCodeOpen: false })}
            title="Sample Javascript Code">
            <GridContainer>
              <GridItem xs={12}>
                {sampleCodeVars}
              </GridItem>
              <GridItem xs={12}>
                <CustomCodeArea
                  label="Sample Javascript Code"
                  options={{ mode: 'application/javascript' }}
                  input={{
                    value: sampleCode,
                    disabled: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </ConfirmationDialog>
        </GridItem>
      </GridContainer>)} />
    )
  }
}

const WebdriverEdit = connect(
  state => ({ settings: state.settings, license: state.settings.license }),
  { getConnector }
)(WebdriverEditUnconnected)
export { WebdriverEdit }
