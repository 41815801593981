import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
// @material-ui/core components
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import {Form, FormSpy} from 'react-final-form'
import Field from 'components/Form/OptionalField'
// apollo
import {Query, Mutation} from 'react-apollo'
import {gql} from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import {setAlertSuccessMessage, setAlertErrorMessage} from 'actions/alert'

import {
  renderCheckbox,
  FormActionsToolbar,
  renderSelect,
  required,
  renderTextField,
  renderPasswordField
} from 'components/Form/Form'

import {RefetchTestSetQueries, DeleteTestSetScriptsFromCache} from 'views/TestSets/gql'
import TestSetSelector from 'views/TestSets/TestSetSelector'
import {CHATBOT_QUERY} from 'views/Chatbots/gql'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Divider from 'components/Divider/Divider'
import Text from 'components/Typography/Text.jsx'
import {genesysCaps2Form} from './GenesysEdit.jsx'

import {GENESYS_LANGUAGES} from './Helper'
import {RUNCONNECTORQUERY_QUERY} from './ConnectorEdit'
import {extractErrorMessage} from '../../helper/graphHelper'

const IMPORT_INTENTS = gql`
  mutation ImportFromGenesys(
    $chatbotId: ID!
    $testSetId: ID
    $newTestSetName: String,
    $createTestProject: Boolean,
    $importMode: ImportOverwriteMode,
    $buildconvos: Boolean,
    $inboundMessageFlowName: String!,
    $botFlowId: String,
    $clientId: String!,
    $clientSecret: String!,
    $language: String
  ) {
    importFromGenesys(
      chatbotId: $chatbotId
      testSetId: $testSetId
      newTestSetName: $newTestSetName,
      createTestProject: $createTestProject,
      importMode: $importMode,
      buildconvos: $buildconvos,
      inboundMessageFlowName: $inboundMessageFlowName,
      botFlowId: $botFlowId,
      clientId: $clientId,
      clientSecret: $clientSecret,
      language: $language
    ) { id scripts { id } }
  }
`

class GenesysImport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      importing: false
    }
  }

  renderBotFlowSelector(capabilities) {
    return (
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          return (<Query
            fetchPolicy="network-only"
            query={RUNCONNECTORQUERY_QUERY}
            variables={{
              connectorName: 'genesys',
              field: 'GENESYS_BOT_FLOW',
              caps: JSON.stringify({
                GENESYS_AWS_REGION: capabilities.genesys.awsRegion,
                GENESYS_CLIENT_ID: values.clientId,
                GENESYS_CLIENT_SECRET: values.clientSecret,
                GENESYS_INBOUND_MESSAGE_FLOW_NAME: values.inboundMessageFlowName
              })
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return <GridItem xs={12}><Text danger>Loading bot flows ...</Text></GridItem>
              if (error) return <GridItem xs={12}><Text danger>Loading bot flows failed: {extractErrorMessage(error)}</Text></GridItem>
              if (data && data.runconnectorquery && data.runconnectorquery.err) return <GridItem xs={12}><Text danger>Loading bot flows failed: {data.runconnectorquery.err}</Text></GridItem>
              if (!data || !data.runconnectorquery || !data.runconnectorquery.choices || data.runconnectorquery.choices.length === 0) return <GridItem xs={12}><Text danger>No bot flows found - check credentials and inbound message flow name</Text></GridItem>

              return <React.Fragment>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="botFlowId"
                    component={renderSelect}
                    label="Bot Flow"
                    data-unique="selGenesysImportBotFlow"
                    items={[{ key: 'all', label: 'Download and merge from all bot flow' } ,...data.runconnectorquery.choices.map(a => ({ key: a.key, label: a.name }))]}
                  />
                </GridItem>
              </React.Fragment>
            }}
          </Query>)
        }}
      />
    )
  }

  render() {
    const {importing} = this.state
    const {setAlertSuccessMessage, setAlertErrorMessage, history, license} = this.props
    const {chatbotId} = this.props

    return (<Query query={CHATBOT_QUERY} variables={{id: chatbotId}}>
      {({error, data}) => {
        if (error) return <ErrorFormat err={error}/>

        if (!data.chatbot) return null
        const capabilities = genesysCaps2Form(data.chatbot.capabilities)
        const initData = {
          buildintents: true,
          buildconvos: false,
          testSetId: this.props.testSetId || 'new',
          importMode: 'EXTEND',
          inboundMessageFlowName: capabilities.genesys.inboundMessageFlowName || '',
          botFlowId: 'all',
          clientId: capabilities.genesys.clientId || '',
          clientSecret: capabilities.genesys.clientSecret || '',
          language: null
        }

        return (
          <Mutation
            mutation={IMPORT_INTENTS}
            onCompleted={data => {
              this.setState({importing: false})
              setAlertSuccessMessage('Test Cases imported')
              history.push(`/testsets/view/${data.importFromGenesys.id}`)
            }}
            onError={error => {
              this.setState({importing: false})
              setAlertErrorMessage(`Test Case import failed`, error)
            }}
            refetchQueries={({data}) => [
              ...RefetchTestSetQueries(data.importFromGenesys.id, license)
            ]}
            update={(store, {data}) => DeleteTestSetScriptsFromCache(store, data.importFromGenesys.scripts.map(s => s.id))}
            awaitRefetchQueries={true}
          >
            {(importFromGenesys, {loading, error}) => (
              <Form
                onSubmit={values => {
                  this.setState({importing: true})
                  importFromGenesys({
                    variables: {
                      chatbotId: chatbotId,
                      testSetId: values.newTestSetName ? null : values.testSetId,
                      newTestSetName: values.newTestSetName,
                      createTestProject: !!values.createTestProject,
                      importMode: values.importMode,
                      buildconvos: values.buildconvos,
                      inboundMessageFlowName: values.inboundMessageFlowName,
                      botFlowId: values.botFlowId !== 'all' ? values.botFlowId : undefined,
                      clientId: values.clientId,
                      clientSecret: values.clientSecret,
                      language: values.language
                    }
                  })
                }}
                initialValues={initData}
                render={({handleSubmit, submitting, invalid}) => (
                  <form onSubmit={handleSubmit}>
                    <GridContainer>
                      <TestSetSelector defaultNewTestSetName={data.chatbot.name + ' - Test Set'}/>
                      <GridItem xs>
                        <Divider orientation="horizontal" dividerlgnone/>
                        <Text header topMargin>More Options</Text>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name="buildintents"
                          component={renderCheckbox}
                          label="Get user examples from Genesys intents as utterance lists"
                          type="checkbox"
                          disabled={true}
                          data-unique="chkGenesysImportBuildIntents"
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name="buildconvos"
                          component={renderCheckbox}
                          label="Build test case convos for NLU intent assertions"
                          type="checkbox"
                          data-unique="chkGenesysImportBuildConvos"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="language"
                          component={renderSelect}
                          label="Choose the Genesys language"
                          data-unique="selGenesysLanguage"
                          helperText="You can leave this empty to download all languages"
                          filterable={true}
                          items={GENESYS_LANGUAGES}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="clientId"
                          component={renderTextField}
                          label="Client ID"
                          validate={required}
                          data-unique="txtGenesysImportClientId"
                          helperText="Client ID from Genesys OAuth integration."
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="clientSecret"
                          component={renderPasswordField}
                          label="Client Secret"
                          validate={required}
                          data-unique="txtGenesysImportClientSecret"
                          helperText="Client Secret from Genesys OAuth integration."
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name="inboundMessageFlowName"
                          component={renderTextField}
                          label="Inbound Message Flow Name"
                          validate={required}
                          data-unique="txtGenesysEditInboundMessageFlowName"
                          helperText="Inbound Message flow name from genesys architect view."
                        />
                      </GridItem>
                      {this.renderBotFlowSelector(capabilities)}
                      <GridItem xs={12} largePadding>
                        <FormActionsToolbar rightButtons={
                          <Button
                            type="submit"
                            disabled={importing || submitting || invalid}
                            data-unique="btnGenesysImportDownload"
                          >
                            {importing && <><LoadingIndicator/> Download is running</>}
                            {!importing && <><CloudDownloadIcon/> Download</>}
                          </Button>
                        }/>
                      </GridItem>
                    </GridContainer>
                  </form>
                )}
              />
            )}
          </Mutation>
        )
      }}
    </Query>)
  }
}

GenesysImport.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  testSetId: PropTypes.string
}

export default withRouter(connect(
  state => ({user: state.token.user, license: state.settings.license}),
  {setAlertSuccessMessage, setAlertErrorMessage}
)(GenesysImport))
