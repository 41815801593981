import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import expansionStyle from 'assets/jss/material-dashboard-react/components/expansionStyle'
import ExpansionPanelCore from '@material-ui/core/ExpansionPanel'

function ExpansionPanel({ ...props }) {
  const { classes, children, ...rest } = props
  return <ExpansionPanelCore
      classes={{
        root: classes.ExpansionPanelroot,
      }} 
      CollapseProps={{
        classes: {
          container: classes.ExpansionPanelContainer,
          entered: classes.ExpansionPanelEntered,
        }
      }}
      {...rest} 
    >{children}</ExpansionPanelCore>
}

export default withStyles(expansionStyle)(ExpansionPanel)
