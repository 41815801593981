import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import AddAlert from '@material-ui/icons/AddAlert'
import Snackbar from 'components/Snackbar/Snackbar'
import ShowIcon from 'components/Icon/ShowIcon'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { extractErrorMessage } from 'helper/graphHelper'

import dialogStyle from 'assets/jss/material-dashboard-react/components/dialogStyle.jsx'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import PerfectScrollbar from 'perfect-scrollbar'

const DialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  }
}))(props => {
  const { children, classes, danger, onClose } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <GridContainer>
        <GridItem xs={12} center danger={danger}>
          {danger && <ShowIcon icon="exclamation-triangle" size="7x"/>}
          {!danger && <Text header center>{children}</Text>}
          {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </GridItem>
        {danger &&
          <GridItem xs={12} center largeMargin>
            <Text header center danger>{children}</Text>
          </GridItem>
        }
      </GridContainer>
    </MuiDialogTitle>
  )
})

class ConfirmationDialog extends React.Component {

  constructor(props) {
    super(props)
    this.contentRef = createRef()
  }

  render() {
    const { classes, disablePerfectScrollbar, children, open, onEntered, title, closeDisabled, cancelText, okText, isWorking, onCancel, onOk, cancelDisabled, okDisabled, okButtonClass, cancelButtonClass, okButtonIcon, cancelButtonIcon, extraButton, extraButtonLeft, showError, clearError, danger, ...rest } = this.props
    
    return (
      <Dialog
        disableEscapeKeyDown
        disableEnforceFocus
        maxWidth="md"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={open}

        classes={{
          paper: danger ? classes.dangerPaper : classes.paper
        }}
        onEntered={e => {
          if(this.contentRef && this.contentRef.current && !disablePerfectScrollbar) {
            new PerfectScrollbar(this.contentRef.current, {suppressScrollX: true})
          }
          if(onEntered) onEntered(e)
        }}
        {...rest}
      >
        {title &&
          <DialogTitle classes={{
            root: danger ? classes.confirmationDialogTitleRoot : ''
          }} danger={danger} id="confirmation-dialog-title" onClose={closeDisabled ? null : onCancel ? () => onCancel() : null}>
            <Text info header>{title}</Text>
          </DialogTitle>
        }

        <div ref={this.contentRef} className={classes.dialogContentRoot}>
          {children}
          {showError &&
            <Snackbar
              place="bc"
              color="danger"
              icon={AddAlert}
              message={extractErrorMessage(showError)}
              open={true}
              closeNotification={clearError && (() => clearError())}
              close
              data-unique="btnConfirmationDialogAlert"
            />
          }
          </div>

        {(onCancel || onOk) &&
          <GridContainer noMargin>
            <GridItem xs={6} middle>
              <DialogActions>
                {extraButtonLeft}
                {extraButton}
              </DialogActions>
            </GridItem>
            <GridItem xs={6} largeMarginTop>
            <DialogActions>
              {onCancel &&
                <Button data-unique="btnConfirmationDialogCancel" onClick={onCancel} secondary disabled={isWorking || cancelDisabled} {...(cancelButtonClass ? { className: cancelButtonClass }: {})}>
                  {cancelButtonIcon && <ShowIcon icon={cancelButtonIcon} />}
                  {cancelText || 'Cancel'}
                </Button>
              }
              {onOk &&
                <Button medium danger={danger} data-unique="btnConfirmationDialogOk" onClick={onOk} disabled={isWorking || okDisabled} {...(okButtonClass ? { className: okButtonClass }: {})}>
                  {isWorking && okButtonIcon && <LoadingIndicator alt />}
                  {!isWorking && okButtonIcon && <ShowIcon icon={okButtonIcon} />}
                  {okText || 'Done'}
                </Button>
              }
              </DialogActions>
            </GridItem>
          </GridContainer>
  
            
          
        }
      </Dialog>
    )
  }
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  title: PropTypes.string,
  closeDisabled: PropTypes.bool,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  isWorking: PropTypes.bool,
  okDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  okButtonClass: PropTypes.string,
  cancelButtonClass: PropTypes.string,
  okButtonIcon: PropTypes.string,
  cancelButtonIcon: PropTypes.string,
  extraButton: PropTypes.any,
  showError: PropTypes.any,
  clearError: PropTypes.func,
  danger: PropTypes.bool
}

export default withStyles(dialogStyle)(ConfirmationDialog)
