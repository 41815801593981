import React from 'react'

import CompareIntentBase from './CompareIntentBase'

export default class CompareUtterancesMissing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {match, history} = this.props
    return (
      <CompareIntentBase fieldToProcess="justSecondary" match={match} history={history}/>
    )
  }
}
