import customInputStyle from './customInputStyle'

const dropzoneStyle = theme => ({
    ...customInputStyle(theme),
    root: {
      position: 'relative',
      maxWidth: '100%',
      height: 'auto',
      padding: 15,
      borderWidth: 1,
      //borderColor: theme.colors.primary,
      color: theme.colors.primary,
      borderColor: theme.colors.withStyles,
      textAlign: 'center',
      borderStyle: 'dashed',
      borderRadius: 5,
      marginTop: 10,
      cursor: 'pointer',
      //paddingTop: 15,
      fontWeight: 400,
      '&:focus-visible': {
        border: `2px solid ${theme.colors.visibleborderColor}`,
        borderRadius: 5,
      },
    },
    disabled: {
      borderColor: `${theme.colors.primary50} !important`,
      cursor: 'default !important',
      '& svg': {
        '& path': {
          fill: `${theme.colors.primary50} !important`,
        }
      }
    },
    cloudicon: {
      //position: 'absolute',
      //top: -17,
      //left: 'calc(50% - 15px)',
      //backgroundColor: theme.colors.backgroundSecondary,
      color: theme.colors.common.secondary,
      //padding: 5
    },
    mutedText: {
       marginTop: 13,
       fontWeight: 400
    }
  })

  export default dropzoneStyle