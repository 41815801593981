import React from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from 'components/Menu/MenuItem'
import Button from './Button'
import {CustomCheckbox, CustomTextField} from 'components/Form/Form'
import {withStyles} from '@material-ui/core'
import Divider from 'components/Divider/Divider'
import selectStyle from 'assets/jss/material-dashboard-react/components/selectStyle'

const style = theme => ({
  dividerItem: {
    '&,&:hover,&:focus': {
      backgroundColor: 'transparent !important',
      cursor: 'default !important',
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  ...selectStyle(theme)
})

class DropdownCheckbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuAnchorEl: null,
      filterValue: ''
    }
    this.buttonRef = React.createRef()
    this.filterInputRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        menuAnchorEl: this.props.open ? this.buttonRef.current : null
      })
    }
  }

  componentDidMount() {
    if (this.props.open) {
      this.setState({
        menuAnchorEl: this.buttonRef.current
      })
    }
  }

  render () {
    const { children, items, classes, onClick, onClose, filterable, ...rest } = this.props
    const { menuAnchorEl, filterValue } = this.state

    let selectedItems = items

    return (<React.Fragment>
      <Button buttonRef={this.buttonRef} onClick={(event) => {
        this.setState({ menuAnchorEl: event.currentTarget })
        if(onClick) onClick()
      }} disabled={!items || items.length === 0} dropdown {...rest}>
        {children}
      </Button>
      <Menu
        classes={{
          paper: classes.paper
        }}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          this.setState({ menuAnchorEl: null })
          if (onClose) onClose()
        }}>
        {filterable && <MenuItem
          className={classes.filterMenuItem}
          disableTouchRipple={true}
          key={'filterInput'}
          value={''}
          id={'filterInput'}
        >
          <CustomTextField
            input={{
              ref: this.filterInputRef,
              name: 'selectorFilter',
              value: this.state.inputValue,
              onChange: (e) => {
                this.setState({ filterValue: e.target.value.toLowerCase() })
              },
              onKeyDown: e => {
                if (e.key === 'ArrowDown') {
                  this.filterInputRef.current.parentElement.parentElement.parentElement.focus()
                }
              }
            }}
            disableBorder
            placeholder="Filter..."
            data-unique={`${rest['data-unique']}FilterInput`}
          />
        </MenuItem>}
        {selectedItems && selectedItems.length > 0 && selectedItems.filter(t => t && t.id && (!filterValue || t.label?.startsWith(filterValue))).map(t => {
          if (t.divider) {
            return <MenuItem key={t.id} data-unique={t.dataUnique} className={classes.dividerItem}>
              <Divider />
            </MenuItem>
          }
          return (
            <MenuItem
              key={t.id}
              disabled={!!t.disabled}
              data-unique={t.dataUnique}
              data-click="false"
              onClick={() => {
                t.onClick && t.onClick()
              }}
              className={classes.selectMenuItem + ' ' + classes.selectMenuItemWithImage + ' ' + classes.selectMenuItemMarginTopBottom}
            >
              <CustomCheckbox
                useCheckbox
                input={t.input}
                data-unique={t.dataUnique || t.id}
                label={<><strong>{t.label}</strong> <em>{t.subLabel}</em></>}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </React.Fragment>)
  }
}

DropdownCheckbox.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      name: PropTypes.string,
      label: PropTypes.string,
      subLabel: PropTypes.string,
      divider: PropTypes.bool,
      onClick: PropTypes.func,
      dataUnique: PropTypes.string,
      input: PropTypes.object,
      filterable: PropTypes.bool
    })
  ).isRequired,
}


export default withStyles(style)(DropdownCheckbox)
