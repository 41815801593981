import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import MenuItem from '@material-ui/core/MenuItem'

// core components
import menuStyle from 'assets/jss/material-dashboard-react/components/menuStyle.jsx'

function CustomMenuItem({ ...props }) {
  const { children, classes, ...rest } = props
  return (
    <MenuItem classes={{
      root: classes.menuItemSmallRoot,
      selected: classes.custumSelected,
    }} {...rest}>
      {children}
    </MenuItem>
  )
}

export default withStyles(menuStyle)(CustomMenuItem)
