import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'
import {NavLink} from 'react-router-dom'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {renderProgressOrError} from '../../helper'
import {decodeURIComponentWeak} from 'views/helper'
import {renderTrendingLabel, renderTrendingColor } from './helper'
import {UTTERANCES_AND_ACTUAL_INTENTS_QUERY} from '../gql'

class IntentMismatchProbabilities extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTable() {
    const {intentData, secondaryIntentData, match} = this.props
    const {testSessionId, secondaryTestSessionId} = match.params

    let progressOrError = renderProgressOrError(intentData) || renderProgressOrError(secondaryIntentData)
    if (progressOrError) {
      return progressOrError
    }

    let data = {}
    const updateData = (perUtterance, isPrimary) => {
      for (const entry of perUtterance) {
        if (entry.intent.actuals && entry.intent.actuals.length > 1) {
          const firstIntentConfidence = entry.intent.actuals[0].confidence
          const secondIntentConfidence = entry.intent.actuals[1].confidence
          const mismatchProbability = 1 - firstIntentConfidence + secondIntentConfidence
          data[entry.utteranceKey] = Object.assign(
            data[entry.utteranceKey] || {},
            {
              [isPrimary ? 'primary' : 'secondary']: {
                mismatchProbability,
                firstIntentName: entry.intent.actuals[0].name,
                firstIntentConfidence,
                secondIntentName: entry.intent.actuals[1].name,
                secondIntentConfidence,
              },
              name: entry.utterance,
              utteranceKey: entry.utteranceKey
            }
          )
        }
      }
    }
    updateData(intentData.trainerUtterances, true)
    if (secondaryTestSessionId) {
      updateData(secondaryIntentData.trainerUtterances, false)
    }

    data = _.sortBy(Object.values(data), (result) => (result.primary ? -result.primary.mismatchProbability : 0) + ((result.secondary && result.secondary.mismatchProbability) ? 1 / result.secondary.mismatchProbability : 0))

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Utterance', 'Mismatch Probability', 'Predicted Intent', 'Prediction Confidence', 'Predicted Intent (2nd)', 'Prediction Confidence (2nd)']}
        tableData={data.map(e => {
          const trend = (e.primary && e.secondary) ? e.primary.mismatchProbability - e.secondary.mismatchProbability : null
          return [
            () => {
              return (<>
                  {e.primary && <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/intentmismatchprobability/${encodeURIComponent(e.utteranceKey)}`} data-unique={`btnCoachIntentMismatchProbabilitiesPrimaryName_${e.utteranceKey}`}>
                  {e.name}
                  </NavLink>}
                  {!e.primary && e.name}
                </>)
            },
            () => {
              return (renderTrendingColor(
                <>
                  {e.primary && <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/intentmismatchprobability/${encodeURIComponent(e.utteranceKey)}`} data-unique={`btnCoachIntentMismatchProbabilitiesPrimaryValue_${e.utteranceKey}`}>
                  {e.primary.mismatchProbability.toFixed(4)}
                  </NavLink>}
                  {!e.primary && 'N/A'}
                  {secondaryTestSessionId && e.secondary &&
                  <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${secondaryTestSessionId}/intentmismatchprobability/${encodeURIComponent(e.utteranceKey)}`} data-unique={`btnCoachIntentMismatchProbabilitiesSecondaryValue_${e.utteranceKey}`}>
                    {e.primary ? ` (${renderTrendingLabel(trend, false, 4)})` : ` (${e.secondary.mismatchProbability.toFixed(4)})`}
                  </NavLink>}
                  {secondaryTestSessionId && !e.secondary && ' (N/A)'}
                </>,
                -trend
              ))
            },
            () => {
              return (<>
                {e.primary && <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(e.primary.firstIntentName)}`} data-unique={`btnCoachIntentMismatchProbabilitiesConfidencePrimaryFirstIntentName_${e.utteranceKey}`}>
                {e.primary.firstIntentName}
                </NavLink>}
                {!e.primary && 'N/A'}
                {secondaryTestSessionId && e.secondary &&
                <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${secondaryTestSessionId}/intentconfidence/intentname/${encodeURIComponent(e.primary.firstIntentName)}`} data-unique={`btnCoachIntentMismatchProbabilitiesConfidenceSecondaryFirstIntentName_${e.utteranceKey}`}>
                  {` (${e.secondary.firstIntentName})`}
                </NavLink>}
                {secondaryTestSessionId && !e.secondary && ' (N/A)'}
              </>)
            },
            () => {
              return (<>
                {e.primary && e.primary.firstIntentConfidence.toFixed(4)}
                {!e.primary && 'N/A'}
                {secondaryTestSessionId && e.secondary && ` (${e.secondary.firstIntentConfidence.toFixed(4)})`}
                {secondaryTestSessionId && !e.secondary && ' (N/A)'}
              </>)
            },
            () => {
              return (<>
                {e.primary && <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(e.primary.secondIntentName)}`} data-unique={`btnCoachIntentMismatchProbabilitiesConfidencePrimarySecondIntentName_${e.utteranceKey}`}>
                {e.primary.secondIntentName}
                </NavLink>}
                {!e.primary && 'N/A'}
                {secondaryTestSessionId && e.secondary &&
                <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${secondaryTestSessionId}/intentconfidence/intentname/${encodeURIComponent(e.primary.secondIntentName)}`} data-unique={`btnCoachIntentMismatchProbabilitiesConfidenceSecondarySecondIntentName_${e.utteranceKey}`}>
                  {` (${e.secondary.secondIntentName})`}
                </NavLink>}
                {secondaryTestSessionId && !e.secondary && ' (N/A)'}
              </>)
            },
            () => {
              return (<>
                {e.primary && e.primary.secondIntentConfidence.toFixed(4)}
                {!e.primary && 'N/A'}
                {secondaryTestSessionId && e.secondary && ` (${e.secondary.secondIntentConfidence.toFixed(4)})`}
                {secondaryTestSessionId && !e.secondary && ' (N/A)'}
              </>)
            }
          ]
        })}
      />
    )
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{this.renderTable()}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(UTTERANCES_AND_ACTUAL_INTENTS_QUERY, {
    props: ({data}) => ({
      intentData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          intentFilterActual: decodeURIComponentWeak(props.match.params.intentFilter)
        },
      }
    }
  }),
  graphql(UTTERANCES_AND_ACTUAL_INTENTS_QUERY, {
    props: ({data}) => ({
      secondaryIntentData: data
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.secondaryTestSessionId,
          intentFilterActual: decodeURIComponentWeak(props.match.params.intentFilter)
        },
      }
    },
    skip: (props) => !props.match.params.secondaryTestSessionId
  })
)(IntentMismatchProbabilities)
