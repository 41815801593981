import React from 'react'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import {Query, compose, graphql, withApollo  } from 'react-apollo'
import {NavLink} from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import AdvancedTable from 'components/Table/AdvancedTable'
import AvatarImage from 'components/Avatar/AvatarImage'
import LastChange from 'components/Info/LastChange'
import DropdownButton from 'components/Button/DropdownButton'
import Tooltip from 'components/Tooltip/Tooltip'
import Text from 'components/Typography/Text'
import SwitchButton from 'components/Button/SwitchButton'
import SimpleConfirmationDialog from 'components/Dialog/SimpleConfirmationDialog'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import CrawlerSessionIcon from './CrawlerSessionIcon'
import CrawlerSessionStats from './CrawlerSessionStats'

import {
  CRAWLERPROJECT_QUERY,
  CRAWLERSESSIONS_QUERY, DELETE_CRAWLERSESSION,
} from './gql'

import crawlerprojectsStyle from 'assets/jss/material-dashboard-react/views/crawlerprojectsStyle'
import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import {setAlertSuccessMessage, setAlertErrorMessage} from 'actions/alert'
import {putRecentListEntry, removeRecentListEntry} from 'actions/activity'

class CrawlerProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDeleteCrawlerSession: null
    }
  }

  hasWritePermission() {
    const {user} = this.props
    return hasAnyPermission(user, ['CRAWLERROJECTS_CREATE', 'CRAWLERPROJECTS_UPDATE'])
  }

  renderCrawlerHistory(crawlerproject) {
    const { history, client, setAlertSuccessMessage, setAlertErrorMessage } = this.props
    return (<React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          {this.state.confirmDeleteCrawlerSession &&
          <SimpleConfirmationDialog
            danger
            showConfirmationDialog={!!this.state.confirmDeleteCrawlerSession}
            confirmationText={`When deleting this Crawler Session, all it's results are lost. You have to start another Crawler Session to get current results. If the session is running, it will be cancelled first. Are you sure you want to delete it ?`}
            requireCheck={true}
            onOk={async () => {
              try {
                await client.mutate({
                  mutation: DELETE_CRAWLERSESSION,
                  variables: {
                    id: this.state.confirmDeleteCrawlerSession.id
                  },
                  fetchPolicy: 'no-cache',
                  refetchQueries: [
                      {
                        query: CRAWLERSESSIONS_QUERY,
                        variables: {
                          crawlerProjectId: crawlerproject.id,
                          skip: 1,
                          orderBy: 'createdAt_DESC'
                        }
                      }]
                })
                removeRecentListEntry({ url: `/testdatawizards/crawlerprojects/view/${crawlerproject.id}/history/${this.state.confirmDeleteCrawlerSession.id}` })
                setAlertSuccessMessage('Crawler Session deleted')
              } catch(error) {
                setAlertErrorMessage('Crawler Session  deletion failed', error)
              }
              this.setState({ confirmDeleteCrawlerSession: null })
            }}
            onCancel={() => this.setState({ confirmDeleteCrawlerSession: null })}
          />
          }

          <Query
            query={CRAWLERSESSIONS_QUERY}
            variables={{crawlerProjectId: crawlerproject.id, orderBy: 'createdAt_DESC', skip: 1}}
            fetchPolicy={'network-only'}
            notifyOnNetworkStatusChange={true}
          >
            {({loading, error, refetch, fetchMore, data}) => {
              if (error) {
                return <ErrorFormat err={error}/>
              }

              return (
                <AdvancedTable
                  name={`CrawlerProject_CrawlerSessions`}
                  tableHeaderColor="primary"
                  tableHead={['Name', 'Last Change', 'Result', 'Convos', 'Utterances', 'Warnings', 'Errors', 'Chatbot', { name: 'Actions', right: true }]}
                  pageLoading={loading}
                  onRefresh={() => refetch()}
                  tableData={
                    data && data.crawlersessions &&
                    data.crawlersessions.map(cs => [
                      () => {
                        return <NavLink to={`/testdatawizards/crawlerprojects/view/${crawlerproject.id}/history/${cs.id}`}>
                          {cs.name}
                        </NavLink>
                      },
                      () => <LastChange {...cs} />,
                      () => (
                        <CrawlerSessionIcon crawlerSession={cs} />
                      ),
                      () => (<CrawlerSessionStats crawlerSession={cs} type={'CONVOS'}/>),
                      () => (<CrawlerSessionStats crawlerSession={cs} type={'UTTERANCES'}/>),
                      () => (<CrawlerSessionStats crawlerSession={cs} type={'WARNINGS'}/>),
                      () => (<CrawlerSessionStats crawlerSession={cs} type={'ERRORS'}/>),
                      crawlerproject.chatbot && {
                        value: <Tooltip title={crawlerproject.chatbot.name}><AvatarImage avatar={crawlerproject.chatbot.avatar} containermode={crawlerproject.chatbot.containermode} chatbotId={crawlerproject.chatbot.id} /></Tooltip>,
                        href: '/chatbots/view/' + crawlerproject.chatbot.id,
                      },
                      () => <DropdownButton
                        dots
                        items={[
                          {
                            id: 'open',
                            icon: 'tasks',
                            name: 'Open',
                            onClick: () => history.push(`/testdatawizards/crawlerprojects/view/${crawlerproject.id}/history/${cs.id}`)
                          },
                          {
                            id: 'delete',
                            icon: 'trash',
                            name: 'Delete',
                            onClick: () => this.setState({ confirmDeleteCrawlerSession: cs})
                          }
                        ]}/>
                    ])
                  }
                  onPageChange={async (skip, first) => {
                    try {
                      await fetchMore({
                        variables: {crawlerProjectId: crawlerproject.id, skip: skip + 1, first},
                        updateQuery: (prev, {fetchMoreResult}) => {
                          if (!fetchMoreResult) return prev
                          return fetchMoreResult
                        },
                      })
                    } catch (err) { console.warn('fetchMore failed', err) }
                  }}
                />
              )
            }}
          </Query>
        </GridItem>
      </GridContainer>
    </React.Fragment>)
  }

  render() {
    const { crawlerProjectData, history } = this.props
    if (crawlerProjectData && crawlerProjectData.crawlerproject) {
      putRecentListEntry({name: crawlerProjectData.crawlerproject.name, url: `/testdatawizards/crawlerprojects/view/${crawlerProjectData.crawlerproject.id}/history`, type: 'CrawlerProject'})
    }

    return (
      <GridContainer>
        <Helmet>
          <title>Crawler Project ...</title>
        </Helmet>
        <GridItem xs={12} sm={12} md={12}>
          {crawlerProjectData && crawlerProjectData.loading &&
          <Card>
            <CardHeader color="info">
              <Text header>
                Crawler Project ...
              </Text>
              <Text subheader>Loading</Text>
            </CardHeader>
            <CardBody>
              <LoadingIndicator/>
            </CardBody>
          </Card>
          }

          {crawlerProjectData && crawlerProjectData.error && <ErrorFormat err={crawlerProjectData.error}/>}

          {crawlerProjectData && crawlerProjectData.crawlerproject &&
          <React.Fragment>
            <GridContainer>
              <GridItem xs={9}>
                <Helmet>
                  <title>
                    {crawlerProjectData.crawlerproject && `${crawlerProjectData.crawlerproject.name} - History`}
                  </title>
                </Helmet>
              </GridItem>
              <GridItem right xs={3}>
                <SwitchButton
                  leftLabel="Latest Result"
                  rightLabel="History"
                  checked="right"
                  onRightClick={() => {
                    history.push(`/testdatawizards/crawlerprojects/view/${crawlerProjectData.crawlerproject.id}/history`)
                  }}
                  onLeftClick={() => {
                    history.push(`/testdatawizards/crawlerprojects/view/${crawlerProjectData.crawlerproject.id}`)
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                {this.renderCrawlerHistory(crawlerProjectData.crawlerproject)}
              </GridItem>
            </GridContainer>
          </React.Fragment>
          }
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(crawlerprojectsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(CRAWLERPROJECT_QUERY, {
    props: ({data}) => ({
      crawlerProjectData: data,
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.projectId,
        }
      }
    },
    skip: (props) => !props.match.params.projectId
  }),
  withApollo
)(CrawlerProject)
