import React from 'react'
import { connect } from 'react-redux'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

import RegisteredComponents from './RegisteredComponents'
import RegisteredAggregators from './RegisteredAggregators'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'

import ShowIcon from 'components/Icon/ShowIcon'
class ComponentSettings extends React.Component {
  render() {
    const { user } = this.props

    return (<CustomTabsSecondary
      name="tabSettingsDeviceCloud"
      headerColor="info"
      tabs={[
        {
          tabName: 'Test Execution Components',
          tabIcon: <ShowIcon icon="code" />,
          disabled: !hasAnyPermission(user, ['REGISTEREDCOMPONENTS_SELECT', 'REGISTEREDCOMPONENTS_MANAGE']),
          tabContent: <GridContainer><GridItem xs={12}><RegisteredComponents/></GridItem></GridContainer>,
          locationPrefix: '/settings/components/registeredcomponents',
          dataUnique: 'tabSettingsComponentsComponents'
        },
        {
          tabName: 'Test Metrics',
          tabIcon: <ShowIcon icon="ruler" />,
          disabled: !hasAnyPermission(user, ['REGISTEREDCOMPONENTS_SELECT', 'REGISTEREDCOMPONENTS_MANAGE']),
          tabContent: <GridContainer><GridItem xs={12}><RegisteredAggregators/></GridItem></GridContainer>,
          locationPrefix: '/settings/components/registeredaggregators',
          dataUnique: 'tabSettingsComponentsAggregators'
        }
      ]}
    />)    
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license })
)(ComponentSettings)
